import Chip from "@material-ui/core/Chip";
import React from "react";
function SuccessChip({ chipLabel }) {
  return (
    <React.Fragment>
      <Chip
        label={chipLabel}
        style={{
          backgroundColor: "lightgreen",
          border: "1px solid darkgreen",
          color: "darkgreen",
        }}
      />
    </React.Fragment>
  );
}
export default SuccessChip;
