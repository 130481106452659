import navConfigAdmin from "./navConfigAdmin";
import navConfigPublisher from "./navConfigPublisher";
import icon_dashboard_unselected from "../../../assets/image-public/images/icon_images/icon_dashboard_unselected.svg";
import icon_review_unselected from "../../../assets/image-public/images/icon_images/icon_review_unselected.svg";
import icon_konfigurasi_unselected from "../../../assets/image-public/images/icon_images/icon_konfigurasi_unselected.svg";
import icon_master_unselected from "../../../assets/image-public/images/icon_images/icon_master_unselected.svg";
import icon_kontrak_unselected from "../../../assets/image-public/images/icon_images/icon_kontrak_unselected.svg";
import icon_berita_unselected from "../../../assets/image-public/images/icon_images/icon_berita_unselected.svg";


let navItemUsed = [];
let roleUsed = "all";

switch (roleUsed) {
  case "admin":
    navItemUsed = navConfigAdmin;
    break;
  case "publisher":
    navItemUsed = navConfigPublisher;
    break;
  default:
    navItemUsed = [
      {
        subheader: "Publisher",
        items: [
          {
            title: "Dashboard",
            href: "/publisher/dashboard",
            icon: (() => (
              <img
                style={{
                  width: "20px",
                  marginRight: "10px"
                }}
                alt="publisher"
                src={icon_dashboard_unselected}
              />
            ))
          },
          {
            title: "Review Penggunaan Lagu",
            href: "/publisher/review-lagu",
            icon: (() => (
              <img
                style={{
                  width: "20px",
                  marginRight: "10px"
                }}
                alt="review"
                src={icon_review_unselected}
              />
            ))
          },
          {
            title: "Konfigurasi",
            href: "/publisher/konfigurasi/personalisasi",
            icon: (() => (
              <img
                style={{
                  width: "20px",
                  marginRight: "10px"
                }}
                alt="konfigurasi"
                src={icon_konfigurasi_unselected}
              />
            ))
          },
          {
            title: "Master",
            href: "/publisher/master/pencipta",
            icon: (() => (
              <img
                style={{
                  width: "20px",
                  marginRight: "10px"
                }}
                alt="master"
                src={icon_master_unselected}
              />
            ))
          },
          {
            title: "Kontrak",
            href: "/publisher/kontrak",
            icon: (() => (
              <img
                style={{
                  width: "20px",
                  marginRight: "10px"
                }}
                alt="kontrak"
                src={icon_kontrak_unselected}
              />
            ))
          },
          {
            title: "Berita & Iklan",
            href: "/publisher/berita",
            icon: (() => (
              <img
                style={{
                  width: "20px",
                  marginRight: "10px"
                }}
                alt="berita"
                src={icon_berita_unselected}
              />
            ))
          },
        ]
      },
      {
        subheader: "Admin",
        items: [
          {
            title: "Konfigurasi",
            href: "/admin/konfigurasi/sistem",
            icon: (() => (
              <img
                alt="konfIcon"
                style={{
                  width: "20px",
                  marginRight: "10px"
                }}
                src={icon_konfigurasi_unselected}
              />
            ))
          },
          {
            title: "Master",
            href: "/admin/master/publisher",
            icon: (() => (
              <img
                alt="pubIcon"
                style={{
                  width: "20px",
                  marginRight: "10px"
                }}
                src={icon_master_unselected}
              />
            ))
          },
          {
            title: "Berita & Iklan",
            href: null,
            icon: (() => (
              <img
                alt="newsIcon"
                style={{
                  width: "20px",
                  marginRight: "10px"
                }}
                src={icon_berita_unselected}
              />
            )),
            items: [
              {
                title: "Finalisasi Berita Iklan",
                href: "/admin/finalisasi-berita"
              },
              {
                title: "Daftar Berita Iklan",
                href: "/admin/berita"
              }
            ]
          },
        ]
      },
    ];
}

const items = navItemUsed;

export default items;
