import {
  Backdrop,
  Button,
  Card,
  Checkbox,
  Chip,
  CircularProgress,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  Menu,
  MenuItem,
  OutlinedInput,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import moment from "moment";
import randomColor from "randomcolor";
import { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { useHistory } from "react-router-dom";
import { Page, PrimaryButton, SelectInput, YearPicker } from "components";
import {
  hardBaseUrl,
  hardSubDomain,
  hardTypeWeb,
} from "../../../services/urlConstant";
import { getCookie } from "../../../utils/constants";
import ChartStacked from "./ChartStacked";
import ChartStackedPerc from "./ChartStackedPerc";
import { Box } from "@mui/material";
import { HeaderTitle } from "layouts";

const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const useStyles = makeStyles(() => ({
  root: {
    padding: "24px 0",
  },
  divider: {
    margin: "16px 0",
    borderTop: "1px solid #e1e1e1",
  },
  results: {
    marginTop: "16px",
  },
  backdrop: {
    zIndex: 99,
    color: "#fff",
  },
  title: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "28px",
  },
  subTitle: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "20px",
  },
  label: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontWeight: "500px",
    fontSize: "14px",
    lineHeight: "20px",
    width: "140px",
    height: "20px",
  },
  fixedHeight: {
    height: 240,
  },
  breadcrumbs: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#111827",
  },
  breadCrumbsActive: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#687083",
  },
  boxColor: {
    height: "20px",
    width: "20px",
  },
  inputFilter: {
    width: "211px",
    height: "44px",
    border: "1px solid #D1D5DC",
    borderRadius: "6px",
    marginRight: "10px",
  },
  menu: {
    marginTop: "100px",
    cursor: "pointer",
  },
  containerChildrenMenu: {
    width: "300px",
    height: "100px",
  },
  selectMenuItem: {
    width: "300px",
  },
  chipContainer: {
    border: "1px solid #E4E7EB",
    boxShadow: "none",
    height: "auto",
    minHeight: "120px",
    overflow: "auto",
    display: "flex",
    margin: "auto",
  },
  headText: {
    fontSize: "12px",
    color: "#687083",
    fontWeight: "bold",
  },
  bodyTextBlack: {
    fontSize: "14px",
    fontWeight: "regular",
    color: "#111827",
    fontStyle: "normal",
  },
  bodyTextGrey: {
    fontSize: "14px",
    fontWeight: "regular",
    color: "#687083",
    fontStyle: "normal",
  },
  tableRow: {
    height: 20,
  },
  conTextBlack: {
    fontSize: "14px",
    fontWeight: "bold",
  },
  dropdown: {
    color: "black",
  },
  subtitle: {
    fontSize: "18px",
    fontWeight: "bold",
    lineHeight: "28px",
  },
  table: {
    border: "1px solid #ebebeb",
  },
  pointer: {
    cursor: "pointer",
  },
  btnRincian: {
    backgroundColor: "black",
    color: "white",
    padding: "10px 28px",
    borderRadius: "6px",
    width: "140px",
    height: "36px",

    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
  containerCheckBox: {
    width: "300px",
    marginTop: "40px",
    borderTop: "1px solid #E4E7EB",
  },
  ib: {
    paddingRight: "7px",
    paddingLeft: "6px",
    paddingTop: "1px",
    paddingBottom: "2px",
    backgroundColor: "#FCF3F2",
    border: "1px solid #F1A69E",
    "&:focus": {
      color: "blue",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "pink",
      borderColor: "#005cbf",
    },
    "&:hover": {
      boxShadow: "none",
    },
  },
  myCheckBox: {
    "&.MuiCheckbox-root": {
      color: "black",
      "&.Mui-checked": {
        color: "black",
      },
    },
  },
  gridCaris: {
    marginTop: "20px",
    marginBottom: "20px",
  },
  chip: {
    margin: "10px",
    color: "black",
  },
  chipColor: {
    width: "10px",
  },
  scrollingCard: {
    border: "1px solid #E4E7EB",
    boxShadow: "none",
    height: "auto",
    minHeight: "120px",
    overflow: "auto",
    display: "flex",
    margin: "auto",
    "&::-webkit-scrollbar": {
      width: 1,
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "lightgrey",
      outline: "1px solid slategrey",
    },
  },
  chipMenu: {
    margin: "5px",
  },
  dialogPaper: {
    width: "420px",
    maxWidth: "420px",
  },
  buttonCheckDetail: {
    textTransform: "none",
    color: "white",
    boxShadow: "none",
    width: "136px",
    height: "36px",
    borderRadius: "6px",
  },
  textCheckDetail: {
    color: "white",
    whiteSpace: "nowrap",
    fontSize: "14px",
    fontWeight: "500",
  },
  textSeeMore: {
    color: "#111827",
    fontSize: "14px",
    marginRight: "10px",
  },
  iconSeeMore: {
    color: "#111827",
    fontSize: "14px",
    marginTop: "5px",
  },
  box: {
    border: "1px solid #ebebeb",
    borderRadius: "5px",
    paddingBottom: "50px",
    paddingTop: "20px",
    paddingLeft: "20px",
    paddingRight: "20px",
  },
  flexContainer: {
    display: "flex",
    justifyContent: "start",
    marginBottom: "20px",
    flexWrap: "wrap",
  },
  chipMargin: {
    marginRight: "10px",
    marginTop: "10px",
    marginBottom: "10px",
    marginLeft: "10px",
    color: "black",
  },
  textTrend: {
    fontSize: "18px",
    lineHeight: "28px",
    fontWeight: "700",
    fontStyle: "normal",
  },
  flexEnd: {
    display: "flex",
    justifyContent: "flex-end",
    marginRight: "120px",
    marginTop: "10px",
  },
  tableCell: {
    width: "1px",
    whiteSpace: "nowrap",
  },
  noData: {
    display: "flex",
    justifyContent: "center",
  },
  selectComposer: {
    width: "300px",
  },
  moreDetails: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "10px",
    marginBottom: "10px",
    cursor: "pointer",
  },
  iconStyle: {
    color: "#111827",
    fontSize: "14px",
    marginTop: "5px",
  },
  moreDetailsText: {
    color: "#111827",
    fontSize: "14px",
    marginRight: "10px",
  },
}));

const breadcrumbData = [
  {
    label: "Home",
    link: "/admin/dashboard-association",
  },
  {
    label: "Dashboard",
    active: true,
  },
];

function DashboardAssoc() {
  const history = useHistory();
  const classes = useStyles();
  const [loadingCheck, setLoadingCheck] = useState(false);
  const [dashboardOption, setDashboardOption] = useState([]);
  const [chipSubPubArray, setChipSubPubArray] = useState([]);
  const [selectedDsp, setSelectedDsp] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [optionOriginalPublisher, setOptionOriginalPublisher] = useState([]);
  const [fetchVariable] = useState(null);
  const [getChart, setGetChart] = useState(false);
  const [getTableData, setGetTableData] = useState(false);
  const [getYear, setGetYear] = useState(moment().year());
  const [yearList, setYearList] = useState([]);
  const [getColor, setGetColor] = useState("");
  const [publisherName, setPublisherName] = useState("");
  const [newDataForChart, setNewDataForChart] = useState({
    labels: [],
    datasets: [
      {
        label: "",
        data: [],
        borderColor: "#6ceb98",
        backgroundColor: "#6ceb98",
        fill: false,
        lineTension: 0.2,
        borderDash: [],
        borderDashOffset: 0.0,
        pointBackgroundColor: "#6ceb98",
        pointBorderColor: "#6ceb98",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "#6ceb98",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
      },
    ],
  });

  const [tableDataComposer, setTableDataComposer] = useState([]);
  const [compOptions, setCompOptions] = useState([]);
  const [getYearComposer, setGetYearComposer] = useState(moment().year());
  const [openMenuPubComp, setOpenMenuPubComp] = useState(false);
  const [chipSubPubArrayComp, setChipSubPubArrayComp] = useState([]);
  const [selectedSubPubComp, setSelectedSubPubComp] = useState([]);
  const [arrSubPubIdComp, setArrSubPubIdComp] = useState([]);
  const [
    optionOriginalPublisherComp,
    setOptionOriginalPublisherComp,
  ] = useState([]);
  const [compOptionValue, setCompOptionValue] = useState("none");
  const [, setOpenConf] = useState(false);
  const [, setShowSongTraffic] = useState(false);
  const [, setShowSongRevenue] = useState(false);
  const [revenueType, setRevenueType] = useState("revenue");
  const [chartType, setChartType] = useState("stacked_number");
  const [dspSingle, setDspSingle] = useState("");
  const [colorByPub, setColorByPub] = useState([]);

  const getDataComposertrend = () => {
    let arrToStringSubPubComp = arrSubPubIdComp.toString();
    const token = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const url = `${hardBaseUrl}/publisher/trend-of-using-composer-songs?original_publisher_id=${arrToStringSubPubComp}&composer_id=${compOptionValue}&year=${getYearComposer}`;

    axios.get(url, { headers }).then(res => {
      setTableDataComposer(res.data.data);
    });
  };

  const getCompOptions = () => {
    const token = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const url = `${hardBaseUrl}/publisher/trend-of-using-composer-songs`;

    axios.get(url, { headers }).then(res => {
      setCompOptions(res.data.data);
    });
  };

  useEffect(() => {
    if (localStorage.getItem("typeWeb") === "caris") {
      getDataComposertrend();
      getCompOptions();
    }
  }, [getYearComposer, arrSubPubIdComp, compOptionValue]);

  const clickMenuPublisherComp = event => {
    setOpenMenuPubComp(event.currentTarget);
  };
  const handleCloseMenuPublisherComp = () => {
    setOpenMenuPubComp(false);
  };

  const handleChangeYearComposer = event => {
    setGetYearComposer(event.target.value);
  };

  const handleChangeCompOption = event => {
    setCompOptionValue(event.target.value);
  };
  const userLogin = getCookie("auth", `${process.env.REACT_APP_NAME}_user`);
  useEffect(() => {
    const role = userLogin?.role?.type;
    const reminder = localStorage.getItem("reminder");
    if (reminder !== null) {
      if (hardTypeWeb === "mpis") {
        if (role === "publisher") {
          setOpenConf(false);
        } else {
          setOpenConf(false);
          localStorage.removeItem("reminder");
        }
      } else {
        if (hardSubDomain === null) {
          setOpenConf(true);
        } else {
          setOpenConf(false);
        }
      }
    } else {
      setOpenConf(false);
    }
  }, []);

  useEffect(() => {
    const thisYear = moment().year();
    for (let i = thisYear; i >= 2000; i--) {
      setYearList(yearList => [...yearList, i]);
    }
  }, []);

  const getFillSubPub = item => {
    return (
      <Box display="flex">
        <Box
          marginLeft="10px"
          color="black"
          display="flex"
          justifyContent="center"
          fontSize="14px"
        >
          {item?.length > 0 && (
            <>
              {item[0].name.substring(0, 6)}
              {" ..."}
            </>
          )}
        </Box>
      </Box>
    );
  };

  const getChartData = async () => {
    try {
      setLoadingCheck(true);
      const token = localStorage.getItem("token");
      const url = `${hardBaseUrl}/association/dashboard`;
      const params = {
        dsp_id: dspSingle,
        year: getYear,
        filter_type: revenueType,
      };

      const res = await axios.get(url, {
        params,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      let lgt = res?.data?.data[0]?.chart?.length || 0;
      let outer = [];
      for (let index = 0; index < lgt; index++) {
        let inner = [];
        res.data.data.forEach(item => {
          if (revenueType === "usage") {
            let numb = item.chart[index].listener;
            inner.push(numb);
          } else {
            let numb = item.chart[index].royalty;
            inner.push(numb);
          }
        });
        outer.push(inner);
      }
      let totalinner = [];
      outer.forEach(num => {
        const sum = num.reduce((partialSum, a) => partialSum + a, 0);
        totalinner.push(sum);
      });
      let newArr = [];
      (res.data.data || []).forEach(itm => {
        let newObjChart = [];
        itm.chart.forEach((newItm, idx) => {
          let newObjArr = {
            ...newItm,
            total_inner: totalinner[idx],
          };
          newObjChart.push(newObjArr);
        });
        let newObj = {
          ...itm,
          chart: newObjChart,
        };
        newArr.push(newObj);
      });
      let dateArr = [];
      (res?.data?.data[0]?.chart || []).forEach(data => {
        if (data.type === "month") {
          dateArr.push(data.date);
        }
      });
      const date = dateArr;

      const month = date.map(data => data.split("-")[1]);

      const year = date.map(data => data.split("-")[0]);
      const year2 = year.map(data => data.slice(-2));

      const getDate = data => {
        switch (data) {
          case "01":
            return "Jan";
          case "02":
            return "Feb";
          case "03":
            return "Mar";
          case "04":
            return "Apr";
          case "05":
            return "May";
          case "06":
            return "Jun";
          case "07":
            return "Jul";
          case "08":
            return "Aug";
          case "09":
            return "Sep";
          case "10":
            return "Oct";
          case "11":
            return "Nov";
          case "12":
            return "Dec";
          default:
            return "Total";
        }
      };

      const monthYearArray = month.map((data, index) => {
        return `${getDate(data)} ${year2[index]}`;
      });
      monthYearArray.push(getYear);

      let theArrData = [];
      newArr.forEach((theItem, index) => {
        let color = randomColor();
        if (colorByPub?.length > 0) {
          color = colorByPub[index].publisher.from_generated_color;
        }
        let setTheItem = {};
        if (chartType === "stacked") {
          setTheItem = {
            label: theItem?.publisher.name,
            dataListener: theItem?.chart.map(data => {
              if (revenueType === "usage") {
                return data.listener;
              } else {
                return data.royalty;
              }
            }),
            data: theItem?.chart.map(data => {
              if (revenueType === "usage") {
                return data.listener;
              } else {
                return data.royalty;
              }
            }),

            borderColor: color,
            backgroundColor: color,
            pointBackgroundColor: color,
            pointBorderColor: color,
            pointHoverBackgroundColor: "#fff",
            pointHoverBorderColor: color,
            fill: false,
            lineTension: 0.2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
          };
        } else {
          setTheItem = {
            label: theItem.publisher.name,
            dataListener: theItem?.chart.map(data => {
              if (revenueType === "usage") {
                if (data.listener > 0) {
                  let newNum = (data.listener / data.total_inner) * 100;
                  let fixNum = newNum.toFixed(2);
                  return fixNum;
                } else {
                  return 0;
                }
              } else {
                if (data.royalty > 0) {
                  let newNum = (data.royalty / data.total_inner) * 100;
                  let fixNum = newNum.toFixed(1);
                  return fixNum;
                } else {
                  return 0;
                }
              }
            }),
            data: theItem?.chart.map(data => {
              if (revenueType === "usage") {
                if (data.listener > 0) {
                  let newNum = (data.listener / data.total_inner) * 100;
                  let fixNum = newNum.toFixed(2);
                  return fixNum;
                } else {
                  return 0;
                }
              } else {
                if (data.royalty > 0) {
                  let newNum = (data.royalty / data.total_inner) * 100;
                  let fixNum = newNum.toFixed(1);
                  return fixNum;
                } else {
                  return 0;
                }
              }
            }),
            borderColor: color,
            backgroundColor: color,
            pointBackgroundColor: color,
            pointBorderColor: color,
            pointHoverBackgroundColor: "#fff",
            pointHoverBorderColor: color,

            fill: false,
            lineTension: 0.2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
          };
        }

        theArrData.push(setTheItem);
      });
      const theDataForChart = {
        labels: monthYearArray,
        datasets: theArrData,
      };
      setNewDataForChart(theDataForChart);
      setGetChart(false);
      setTimeout(() => {
        setLoadingCheck(false);
      }, 800);
    } catch (e) {
      new Error(e);
      setGetChart(false);
      setLoadingCheck(false);
    }
  };

  useEffect(() => {
    const getUnderTable = async () => {
      try {
        const token = localStorage.getItem("token");
        const url = `${hardBaseUrl}/association/dashboard/table?dsp_id=${dspSingle}&year=${getYear}&type=${revenueType}`;
        const res = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        let addColor = [];
        if (res.data.data !== null) {
          res.data.data.forEach(item => {
            let color = randomColor();
            item.publisher.theme_color = color;

            if (typeof item.publisher.from_generated_color === "undefined") {
              item.publisher.from_generated_color = color;
            }
            addColor.push(item);
          });
          setTableData(res.data.data);
          if (colorByPub?.length === 0) {
            setColorByPub(addColor);
          }
          setSelectedDsp(addColor);
        }
      } catch (err) {
        new Error(err);
      }
    };

    if (getChart) {
      getChartData();
      getUnderTable();
    }
  }, [getChart]);

  useEffect(() => {
    const getMainDspId = async () => {
      try {
        const token = localStorage.getItem("token");
        const url = `${hardBaseUrl}/me`;
        const res = await axios.get(url, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        if (hardSubDomain !== null) {
          setGetColor("#000");

          setPublisherName(res.data.data.publisher.name);
        } else {
          if (res.data.data.publisher !== null) {
            setGetColor("#000");

            setPublisherName(res.data.data.publisher.name);
          } else {
            setGetColor("#000");
          }
        }
      } catch (e) {
        new Error(e);
      }
    };
    getMainDspId();
  }, [getTableData]);

  const getOptionDashboard = () => {
    const token = localStorage.getItem("token");
    const url = `${hardBaseUrl}/association/publishers`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios.get(url, { headers }).then(res => {
      const newDataSubPub = res.data.data.map(item => ({
        ...item,
        checked: true,
      }));
      setOptionOriginalPublisher(newDataSubPub);
      setOptionOriginalPublisherComp(newDataSubPub);
    });
  };

  const getOptionDsp = () => {
    const token = localStorage.getItem("token");
    const url = `${hardBaseUrl}/association/dsps`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios.get(url, { headers }).then(res => {
      const newDataSubPub = res.data.data.map(item => ({
        ...item,

        checked: true,
      }));
      setOptionOriginalPublisher(newDataSubPub);
      setOptionOriginalPublisherComp(newDataSubPub);
      setChipSubPubArray(newDataSubPub);
      setChipSubPubArrayComp(newDataSubPub);

      const newData = res.data.data.map((item, i) => {
        if (i < 3) {
          return {
            ...item,
            checked: true,
          };
        } else {
          return {
            ...item,
            checked: false,
          };
        }
      });

      let arrDspId = [];
      newData.forEach(item => {
        if (item.checked === true) {
          arrDspId.push(item.dsp_id);
        }
      });
      setDashboardOption(newData);
      setGetChart(true);
      setGetTableData(true);
    });
  };

  useEffect(() => {
    getOptionDashboard();
    getOptionDsp();
  }, [fetchVariable]);

  const makeCheckPubComp = async (checked, id) => {
    let listCheckPub = optionOriginalPublisherComp;
    let newArr = [];
    let newItem = null;

    listCheckPub.forEach(item => {
      if (item.original_publisher_id === Number(id)) {
        newItem = {
          ...item,
          checked: checked,
        };
      } else {
        newItem = {
          ...item,
        };
      }
      newArr.push(newItem);
    });
    setOptionOriginalPublisherComp(newArr);
    setOptionOriginalPublisherComp(newArr);
  };

  const handleChangeCheckBoxPublisherComp = async (event, item, index) => {
    const { id, name, checked } = event.target;

    if (checked === false) {
      makeCheckPubComp(checked, id, name, item, index);

      setChipSubPubArrayComp(
        chipSubPubArrayComp.filter(chip => chip.name !== name)
      );
      setSelectedSubPubComp(
        chipSubPubArrayComp.filter(chip => chip.name !== name)
      );
      const newDataSubPub = arrSubPubIdComp.filter(
        item => Number(item) !== Number(id)
      );
      setArrSubPubIdComp(newDataSubPub);
    } else {
      makeCheckPubComp(checked, id, name, item, index);

      let currentSubPub = arrSubPubIdComp;
      let newSubPubArr = [...currentSubPub, id];
      setArrSubPubIdComp(newSubPubArr);

      setChipSubPubArrayComp([...chipSubPubArrayComp, item]);
    }
  };

  const handleChipDeletePublisherComp = chipToDelete => () => {
    setChipSubPubArrayComp(
      chipSubPubArrayComp.filter(chip => chip.name !== chipToDelete.name)
    );
    const newData = optionOriginalPublisher.map(item => {
      if (item.name === chipToDelete.name) {
        item.checked = false;
      }
      return item;
    });
    setOptionOriginalPublisherComp(newData);
    setSelectedSubPubComp(
      selectedSubPubComp.filter(chip => chip.name !== chipToDelete.name)
    );
    const newDataSubPub = arrSubPubIdComp.filter(
      item => Number(item) !== chipToDelete.original_publisher_id
    );
    setArrSubPubIdComp(newDataSubPub);
  };

  const handleChangeYear = value => {
    setGetYear(value);
    setGetChart(true);
    setGetTableData(true);
    setShowSongRevenue(true);
    setShowSongTraffic(true);
  };
  const handleChangeRev = event => {
    setRevenueType(event.target.value);
    setGetChart(true);
  };
  const handleChangeChart = event => {
    setChartType(event.target.value);
    setGetChart(true);
  };
  const handleChangeDspSingle = event => {
    setDspSingle(event.target.value);
    setGetChart(true);
  };

  const handleRincian = (e, customer, id, name, color) => {
    let payload = {
      publisher_id: id,
      dsp_id: dspSingle,
      year: getYear,
      type: revenueType,
      publisher: customer,
      royalty: customer.royalty,
      listener: customer.listener,
      advance: customer.advance,
    };
    e.preventDefault();

    localStorage.setItem("idDsp", dspSingle);
    localStorage.setItem("year", getYear);
    localStorage.setItem("publisher_id", id);
    localStorage.setItem("type_revenue", revenueType);
    localStorage.setItem("dspColor", color);
    history.push({
      pathname: "/admin/review-lagu-association",
      state: payload,
    });
  };

  const handleRincianComp = (e, compId, customer) => {
    e.preventDefault();
    localStorage.setItem("compId", compId);
    localStorage.setItem("idDsp", dspSingle);
    history.push({
      pathname: "/admin/review-lagu-association",
      state: {
        dsp_id: dspSingle,
        year: getYear,
        type: revenueType,
        royalty: customer.royalty,
        listener: customer.listener,
        advance: customer.advance,
        publisher: customer,
      },
    });
  };

  const goToComposerTrendPage = e => {
    e.preventDefault();
    history.push("/admin/composer-trend");
  };

  return (
    <Page className={classes.root} title="Dashboard Association">
      {loadingCheck ? (
        <>
          <Backdrop className={classes.backdrop} open={loadingCheck}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </>
      ) : (
        <Container maxWidth={false}>
          <HeaderTitle
            title={`Dashboard ${publisherName && " | " + publisherName}`}
            breadcrumbData={breadcrumbData}
          />
          <Divider className={classes.divider} />
          <Box mt={2} marginBottom="25px">
            <Grid container justifyContent="flex-end" spacing={1}>
              <Grid item>
                <SelectInput
                  value={chartType}
                  onChange={handleChangeChart}
                  options={[
                    { id: "stacked_number", name: "Stacked Bar 100%" },
                    { id: "stacked", name: "Stacked Bar" },
                  ]}
                  optionKey="id"
                  optionLabel="name"
                  width={210}
                />
              </Grid>
              <Grid item>
                <SelectInput
                  label="DSP"
                  value={dspSingle}
                  placeholder="None"
                  onChange={handleChangeDspSingle}
                  options={dashboardOption}
                  optionKey="dsp_id"
                  optionLabel="name"
                  width={150}
                />
              </Grid>
              <Grid item>
                <SelectInput
                  value={revenueType}
                  onChange={handleChangeRev}
                  options={[
                    { id: "revenue", name: "Revenue" },
                    { id: "usage", name: "Traffic" },
                  ]}
                  optionKey="id"
                  optionLabel="name"
                  width={150}
                />
              </Grid>
              <Grid item>
                <YearPicker
                  label="Year"
                  onChange={handleChangeYear}
                  value={getYear}
                  width={150}
                />
              </Grid>
            </Grid>
          </Box>
          <Grid container>
            <Grid item xs={12} md={12} sm={12} lg={12} xl={12}>
              <Box
                border="1px solid #ebebeb"
                borderRadius="5px"
                paddingBottom="50px"
                paddingTop="20px"
                paddingLeft="20px"
                paddingRight="20px"
              >
                <Box
                  display="flex"
                  justifyContent="start"
                  marginBottom="20px"
                  flexWrap="wrap"
                >
                  {selectedDsp.map((item, index) => {
                    return (
                      <Chip
                        key={index}
                        size="small"
                        label={item.publisher.name}
                        avatar={
                          <FiberManualRecordIcon
                            className={classes.chipColor}
                            style={{
                              color:
                                colorByPub[index].publisher
                                  .from_generated_color,
                            }}
                          />
                        }
                        className={classes.chip}
                        style={{
                          backgroundColor:
                            colorByPub[index].publisher.from_generated_color,
                          border: `1px thin ${colorByPub[index].publisher.from_generated_color}`,
                        }}
                      />
                    );
                  })}
                </Box>
                {chartType === "stacked" ? (
                  <ChartStacked data={newDataForChart} />
                ) : (
                  <ChartStackedPerc data={newDataForChart} />
                )}
              </Box>
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
          <Grid container>
            <Grid item xs={12} md={12} sm={12} lg={12} xl={12}>
              <Table id="data-tableData" size="small" className={classes.table}>
                <TableHead>
                  <TableRow>
                    <ThemeProvider theme={theme}>
                      <TableCell />
                      <TableCell />
                      <TableCell className={classes.headText}>
                        Publisher
                      </TableCell>
                      <TableCell className={classes.headText} align="right">
                        Traffic
                      </TableCell>
                      <TableCell className={classes.headText} align="right">
                        Revenue
                      </TableCell>
                      <TableCell className={classes.headText} align="right">
                        Advance DSP
                      </TableCell>
                    </ThemeProvider>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData.map((customer, index) => {
                    return (
                      <TableRow
                        hover
                        key={`${customer.publisher.publisher_id}`}
                      >
                        <TableCell>
                          <PrimaryButton
                            label="See Details"
                            onClick={e =>
                              handleRincian(
                                e,
                                customer,
                                customer.publisher.publisher_id,
                                customer.publisher.name,
                                colorByPub[index]?.publisher
                                  .from_generated_color
                              )
                            }
                            width={110}
                          />
                        </TableCell>
                        <TableCell>
                          <Box
                            className={classes.boxColor}
                            style={{
                              backgroundColor:
                                colorByPub[index]?.publisher
                                  .from_generated_color,
                            }}
                          />
                        </TableCell>
                        <ThemeProvider theme={theme}>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={classes.bodyTextBlack}
                            >
                              {customer.publisher.name}
                            </Typography>
                          </TableCell>
                          <TableCell align="right">
                            <Typography
                              variant="subtitle2"
                              className={classes.bodyTextBlack}
                            >
                              <NumberFormat
                                displayType="text"
                                value={+customer?.listener}
                                thousandSeparator={true}
                                decimalScale={2}
                              />
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={classes.bodyTextBlack}
                              align="right"
                            >
                              <NumberFormat
                                displayType="text"
                                value={customer.royalty}
                                thousandSeparator={true}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                suffix=",-"
                                prefix="Rp"
                              />
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={classes.bodyTextBlack}
                              align="right"
                            >
                              <NumberFormat
                                displayType="text"
                                value={customer.advance}
                                thousandSeparator={true}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                suffix=",-"
                                prefix="Rp"
                              />
                            </Typography>
                          </TableCell>
                        </ThemeProvider>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Grid>

            {localStorage.getItem("typeWeb") === "caris" && (
              <Box>
                <Grid
                  alignItems="flex-end"
                  container
                  justifyContent="space-between"
                  spacing={3}
                  className={classes.gridCaris}
                >
                  <Grid item xs={12} md={12} sm={12} lg={4} xl={4}>
                    <ThemeProvider theme={theme}>
                      <Typography
                        component="span"
                        className={classes.textTrend}
                      >
                        Trend of Using Composer/Author Songs
                      </Typography>
                    </ThemeProvider>
                  </Grid>

                  <Grid item xs={12} md={12} sm={12} lg={8} xl={8}>
                    <Box
                      display="flex"
                      justifyContent="flex-end"
                      marginRight="120px"
                      marginTop="10px"
                    >
                      <Grid>
                        <FormControl
                          size="small"
                          variant="outlined"
                          className={classes.formControl}
                          fullWidth={true}
                        >
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            className={classes.inputFilter}
                            onChange={e => handleChangeCompOption(e)}
                            value={compOptionValue}
                            autoWidth={true}
                            defaultValue={"none"}
                            startAdornment={
                              <InputAdornment position="start">
                                <div>Composer/Author:</div>
                              </InputAdornment>
                            }
                          >
                            <MenuItem
                              value={"none"}
                              className={classes.selectComposer}
                            >
                              <em>None</em>
                            </MenuItem>
                            {compOptions.map((item, index) => (
                              <MenuItem key={index} value={item.composer_id}>
                                {item.composer_name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid>
                        <FormControl
                          fullWidth
                          className={classes.margin}
                          variant="outlined"
                        >
                          <OutlinedInput
                            variant="outlined"
                            id="outlined-adornment-amount"
                            size="small"
                            onClick={clickMenuPublisherComp}
                            className={classes.inputFilter}
                            startAdornment={
                              <InputAdornment position="start">
                                Publisher:
                                <div>{getFillSubPub(chipSubPubArray)}</div>
                              </InputAdornment>
                            }
                            endAdornment={
                              <InputAdornment position="end">
                                <ArrowDropDownIcon
                                  onClick={clickMenuPublisherComp}
                                  className={classes.pointer}
                                />
                              </InputAdornment>
                            }
                            fullWidth={false}
                          />

                          <Menu
                            id="simple-menu"
                            anchorEl={openMenuPubComp}
                            keepMounted
                            className={classes.menu}
                            open={Boolean(openMenuPubComp)}
                            onClose={handleCloseMenuPublisherComp}
                          >
                            <Box display="flex" flexDirection="column">
                              <Container
                                className={classes.containerChildrenMenu}
                              >
                                <Card className={classes.scrollingCard}>
                                  <Box width="auto" height="123px">
                                    {chipSubPubArrayComp.map((item, index) => (
                                      <Chip
                                        key={index}
                                        label={item.name}
                                        onDelete={() =>
                                          handleChipDeletePublisherComp(item)
                                        }
                                        className={classes.chipMenu}
                                      />
                                    ))}
                                  </Box>
                                </Card>
                              </Container>

                              <Container className={classes.containerCheckBox}>
                                <FormControl>
                                  {optionOriginalPublisherComp.map(
                                    (item, index) => {
                                      return (
                                        <FormControlLabel
                                          key={index}
                                          control={
                                            <Checkbox
                                              className={classes.myCheckBox}
                                              id={`${item.original_publisher_id}`}
                                              key={index}
                                              checked={item.checked}
                                              name={item.name}
                                              value={item.original_publisher_id}
                                              onChange={e =>
                                                handleChangeCheckBoxPublisherComp(
                                                  e,
                                                  item,
                                                  index
                                                )
                                              }
                                            />
                                          }
                                          label={item.name}
                                        />
                                      );
                                    }
                                  )}
                                </FormControl>
                              </Container>
                            </Box>
                          </Menu>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={12} sm={12} lg={2} xl={2}>
                        <FormControl
                          size="small"
                          variant="outlined"
                          className={classes.formControl}
                          fullWidth={true}
                        >
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            className={classes.inputFilter}
                            onChange={e => handleChangeYearComposer(e)}
                            value={getYearComposer}
                            autoWidth={true}
                            startAdornment={
                              <InputAdornment position="start">
                                <div>Year:</div>
                              </InputAdornment>
                            }
                          >
                            <MenuItem
                              value=""
                              className={classes.selectMenuItem}
                            >
                              <em>None</em>
                            </MenuItem>
                            {yearList.map((item, index) => (
                              <MenuItem key={index} value={item}>
                                {item}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={12} sm={12} lg={12} xl={12}>
                  <Table size="small" className={classes.table}>
                    <TableHead>
                      <TableRow>
                        <ThemeProvider theme={theme}>
                          <TableCell />

                          <TableCell className={classes.headText}>
                            Composer/Author
                          </TableCell>
                          <TableCell className={classes.headText}>
                            Traffic
                          </TableCell>
                          <TableCell className={classes.headText} align="right">
                            Revenue
                          </TableCell>
                          <TableCell className={classes.headText} align="right">
                            Advance Composer/Author
                          </TableCell>
                          <TableCell className={classes.headText} align="right">
                            Balance
                          </TableCell>
                        </ThemeProvider>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {tableDataComposer?.length > 0 ? (
                        tableDataComposer.map((customer, index) => {
                          return (
                            <TableRow hover key={index}>
                              <TableCell>
                                <Button
                                  variant="contained"
                                  className={classes.buttonCheckDetail}
                                  style={{
                                    backgroundColor: getColor,
                                  }}
                                  onClick={e =>
                                    handleRincianComp(
                                      e,
                                      customer.composer_id,
                                      customer
                                    )
                                  }
                                >
                                  <ThemeProvider theme={theme}>
                                    <Typography
                                      className={classes.textCheckDetail}
                                    >
                                      See Details
                                    </Typography>
                                  </ThemeProvider>
                                </Button>
                              </TableCell>

                              <ThemeProvider theme={theme}>
                                <TableCell>
                                  <Typography
                                    variant="subtitle2"
                                    className={classes.bodyTextBlack}
                                  >
                                    {customer.composer_name}
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography
                                    variant="subtitle2"
                                    className={classes.bodyTextBlack}
                                  >
                                    ini kah ??
                                    <NumberFormat
                                      displayType="text"
                                      value={customer.listener}
                                      thousandSeparator={true}
                                      decimalScale={2}
                                    />
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography
                                    variant="subtitle2"
                                    className={classes.bodyTextBlack}
                                    align="right"
                                  >
                                    <NumberFormat
                                      displayType="text"
                                      value={customer.royalty}
                                      thousandSeparator={true}
                                      decimalScale={2}
                                      prefix="Rp"
                                    />
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography
                                    variant="subtitle2"
                                    className={classes.bodyTextBlack}
                                    align="right"
                                  >
                                    <NumberFormat
                                      displayType="text"
                                      value={customer.advance}
                                      thousandSeparator={true}
                                      decimalScale={2}
                                      prefix="Rp"
                                    />
                                  </Typography>
                                </TableCell>

                                <TableCell>
                                  <Typography
                                    variant="subtitle2"
                                    className={classes.bodyTextBlack}
                                    align="right"
                                  >
                                    {customer.balance}
                                  </Typography>
                                </TableCell>
                              </ThemeProvider>
                            </TableRow>
                          );
                        })
                      ) : (
                        <TableRow key="0">
                          <TableCell
                            colSpan={6}
                            style={{
                              height: "50px",
                            }}
                          >
                            <Box display="flex" justifyContent="center">
                              <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                              >
                                <ThemeProvider theme={theme}>
                                  <Typography>No Data</Typography>
                                </ThemeProvider>
                              </Box>
                            </Box>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    marginTop="10px"
                    marginBottom="10px"
                    cursor="pointer"
                    onClick={e => {
                      goToComposerTrendPage(e);
                    }}
                  >
                    <ThemeProvider theme={theme}>
                      <Typography className={classes.textSeeMore}>
                        See More
                      </Typography>
                    </ThemeProvider>
                    <ArrowForwardIcon className={classes.iconSeeMore} />
                  </Box>
                </Grid>
              </Box>
            )}
          </Grid>
        </Container>
      )}
    </Page>
  );
}

export default DashboardAssoc;
