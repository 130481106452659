import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import updateIcon from "../../../../assets/img/pencilVector.svg";
import { ButtonWithModalPerItem } from "../../../../components/atoms/Button";
import TableEditBar from "../../../../components/molecules/Table/TableEditBar";
import { hardBaseUrl } from "../../../../services/urlConstant";

const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0,
    border: "1px solid #e0e0e0",
  },
  inner: {
    minWidth: 700,
  },
  nameCell: {
    display: "flex",
    alignItems: "center",
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1),
  },
  actions: {
    padding: theme.spacing(1),
    justifyContent: "flex-end",
  },
  mypadding: {
    "& .MuiSelect-outlined": {
      paddingTop: "14px",
      paddingBottom: "14px",
      paddingLeft: "20px",
      paddingRight: "30px",
    },
  },
  headText: {
    fontSize: "12px",
    color: "#687083",
    fontWeight: "bold",
  },
  bodyTextBlack: {
    fontSize: "14px",
    fontWeight: "regular",
    color: "#111827",
    fontStyle: "normal",
  },
  bodyTextGrey: {
    fontSize: "14px",
    fontWeight: "regular",
    color: "#687083",
    fontStyle: "normal",
  },
  tableRow: {
    height: 20,
  },
  conTextBlack: {
    fontSize: "14px",
    fontWeight: "bold",
  },
  dropdown: {
    color: "black",
  },
  subtitle: {
    fontSize: "18px",
    fontWeight: "bold",
    lineHeight: "28px",
  },
  btnEdit: {
    backgroundColor: "#111827",
    color: "white",
    width: "30px",
    height: "35px",
    padding: "6px",
    borderRadius: "6px",
  },
  btnDelete: {
    backgroundColor: "#111827",
    color: "white",
    width: "35px",
    height: "35px",
    padding: "6px",
    borderRadius: "6px",
    marginLeft: "10px",
    cursor: "pointer",
  },
  pagination: {
    "& .Mui-selected": {
      backgroundColor: "black",
      color: "white",
    },
  },
  pageSize: {
    height: "30px",
    borderRadius: "6px",
    border: "1px solid #D1D5DC",
    paddingLeft: "5px",
    paddingRight: "5px",
    marginTop: "5px",
  },
  paginationText: {
    fontSize: "14px",
    fontWeight: "normal",
    fontStyle: "normal",
    color: "#687083",
  },
}));

function Results({
  className,
  handleChangePage,
  pageSizePagination,
  handleDelete,
  items,
  page,
  totalData,
  ...rest
}) {
  const classes = useStyles();
  const [selectedCustomers] = useState([]);

  const [getColor, setGetColor] = useState("");

  const getMainDsp = async () => {
    try {
      const token = localStorage.getItem("token");
      const url = `${hardBaseUrl}/me`;
      const res = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setGetColor(res.data.data.publisher.theme_color);
    } catch (e) {}
  };

  useEffect(() => {
    getMainDsp();
  }, []);

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <>
        <div>
          <div className={classes.inner}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <ThemeProvider theme={theme}>
                    <TableCell className={classes.headText}>Actions</TableCell>
                    <TableCell>
                      <Typography
                        variant="subtitle2"
                        className={classes.headText}
                      >
                        Fee Type Code
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="subtitle2"
                        className={classes.headText}
                      >
                        Fee Type Name
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="subtitle2"
                        className={classes.headText}
                      >
                        Description
                      </Typography>
                    </TableCell>
                  </ThemeProvider>
                  {}
                </TableRow>
              </TableHead>
              <TableBody>
                {items?.length > 0 ? (
                  items.map(customer => (
                    <TableRow hover key={`${customer.cost_type_id}`}>
                      <ThemeProvider theme={theme}>
                        <TableCell align="right">
                          <>
                            <div style={{ display: "flex" }}>
                              <Tooltip title={"Update"} placement="bottom">
                                <IconButton
                                  className={classes.btnEdit}
                                  style={{
                                    backgroundColor: getColor,
                                  }}
                                >
                                  <RouterLink
                                    to={{
                                      pathname: `/admin/konfigurasi/edit-cost-type/${customer.cost_type_id}`,
                                      state: {
                                        customer: customer,
                                      },
                                    }}
                                  >
                                    <img
                                      src={updateIcon}
                                      alt="update-icon"
                                      style={{
                                        filter: " brightness(0) invert(1)",
                                      }}
                                    />
                                  </RouterLink>
                                </IconButton>
                              </Tooltip>
                              <Tooltip title={"Delete"} placement="bottom">
                                <ButtonWithModalPerItem
                                  item={customer}
                                  title={"Remove Cost Type"}
                                  dialogTitle={"Remove Cost Type"}
                                  subTitle={
                                    "Are you sure you want to remove this cost type?"
                                  }
                                  handleDelete={() => {
                                    handleDelete(customer.cost_type_id);
                                  }}
                                />
                              </Tooltip>
                            </div>
                          </>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={classes.bodyTextBlack}
                          >
                            {customer.fee_type_code}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={classes.bodyTextBlack}
                          >
                            {customer.fee_type_name}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={classes.bodyTextBlack}
                          >
                            {customer.description}
                          </Typography>
                        </TableCell>
                      </ThemeProvider>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={7}>
                      <div
                        style={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Typography>Data not found</Typography>
                      </div>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>

            {items?.length > 0 ? (
              <Grid
                alignContent="space-between"
                container
                justifyContent="space-between"
                style={{ margin: "10px" }}
              >
                <Grid item>
                  <div style={{ display: "flex" }}>
                    <div style={{ marginTop: "7px" }}>
                      <ThemeProvider theme={theme}>
                        <Typography className={classes.paginationText}>
                          Item per page:
                        </Typography>
                      </ThemeProvider>
                    </div>{" "}
                    <div style={{ marginLeft: "10px" }}>
                      {pageSizePagination}
                    </div>
                  </div>
                </Grid>

                <Grid item>
                  <Pagination
                    count={totalData}
                    shape="rounded"
                    className={classes.pagination}
                    page={page}
                    onChange={handleChangePage}
                  />
                </Grid>
              </Grid>
            ) : null}
          </div>
        </div>
      </>
      <TableEditBar selected={selectedCustomers} />
    </div>
  );
}

Results.propTypes = {
  className: PropTypes.string,
  customers: PropTypes.array,
};

Results.defaultProps = {
  customers: [],
};

export default Results;
