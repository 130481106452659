import { Button, Card, CardContent, Grid, Typography } from "@material-ui/core";
import { useStyles } from "../../style";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import { InnoTableV2 } from "inno-ui";
import moment from "moment";
import NumberFormat from "react-number-format";
import { StatusChip } from "components";

const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const ImportCatalogue = ({
  sizeChipForImportCatalogue,
  client,
  columnTable,
  handleChangePage,
  handleChangeRowsPerPage,
  listError,
  tablePagination,
  pagesCount,
  activeTab,
  setActiveTab,
}) => {
  const classes = useStyles();
  const formatedDate = date => moment(date).format("DD MMM YYYY");
  return (
    <Grid container>
      <Grid item xs={12} md={8} lg={8} xl={8}>
        <Card id="card-of-success" className={classes.cardCatalogue}>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={12} xl={12}>
                <div id="tes-pdf">
                  <ThemeProvider theme={theme}>
                    <Typography className={classes.label}>File Name</Typography>
                  </ThemeProvider>
                </div>
                <div>
                  <ThemeProvider theme={theme}>
                    <Typography className={classes.label}>
                      {client?.process_files?.find(
                        file => file?.id === activeTab
                      )?.filename || client?.process_name}
                    </Typography>
                  </ThemeProvider>
                </div>
              </Grid>

              <Grid item xs={6} md={6} lg={6} xl={6}>
                <div>
                  <ThemeProvider theme={theme}>
                    <Typography className={classes.label}>Upload ID</Typography>
                  </ThemeProvider>
                </div>
                <div>
                  <ThemeProvider theme={theme}>
                    <Typography className={classes.label}>
                      {client?.id || 10}
                    </Typography>
                  </ThemeProvider>
                </div>
              </Grid>

              <Grid item xs={6} md={6} lg={6} xl={6}>
                <div>
                  <ThemeProvider theme={theme}>
                    <Typography className={classes.label}>
                      Upload Date
                    </Typography>
                  </ThemeProvider>
                </div>
                <div>
                  <ThemeProvider theme={theme}>
                    <Typography className={classes.label}>
                      {formatedDate(client?.created_at)}
                    </Typography>
                  </ThemeProvider>
                </div>
              </Grid>

              <Grid
                item
                xs={sizeChipForImportCatalogue}
                md={sizeChipForImportCatalogue}
                lg={sizeChipForImportCatalogue}
                xl={sizeChipForImportCatalogue}
              >
                <StatusChip
                  label={client?.status}
                  type={
                    client?.status === "Finish"
                      ? "success"
                      : client?.status === "Error"
                        ? "danger"
                        : client?.status === "Started" ||
                          client?.status === "Initialize" ||
                          client?.status === "Running"
                          ? "warning"
                          : ""
                  }
                />
              </Grid>

              <Grid item xs={6} md={6} lg={6} xl={6}>
                <div>
                  <ThemeProvider theme={theme}>
                    <Typography className={classes.label}>
                      Number of Record
                    </Typography>
                  </ThemeProvider>
                </div>
                <div>
                  <ThemeProvider theme={theme}>
                    <Typography className={classes.label}>
                      <NumberFormat
                        displayType="text"
                        value={client?.record_count}
                        thousandSeparator={true}
                      />
                    </Typography>
                  </ThemeProvider>
                </div>
              </Grid>

              <Grid item xs={12} md={12} lg={12} xl={12}>
                <Grid container spacing={2}>
                  {client?.process_files?.map(({ id }, index) => {
                    const isActive = activeTab === id;
                    return (
                      <Grid item key={id}>
                        <Button
                          onClick={() => setActiveTab(id)}
                          className={isActive ? classes?.blackButton : ""}
                          variant="outlined"
                        >
                          {`File ${index + 1}`}
                        </Button>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                xl={12}
                className={classes.table}
              >
                <InnoTableV2
                  columns={columnTable}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  idColumnName={"id"}
                  isLoading={false}
                  isUsingCheckbox={false}
                  items={listError?.data || []}
                  loadingColor={""}
                  page={tablePagination?.page}
                  rowsPerPage={tablePagination?.size}
                  totalPage={pagesCount}
                />
              </Grid>
            </Grid>

            <Grid container justifyContent="space-between"></Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={6} md={4} lg={4} xl={4}></Grid>
    </Grid>
  );
};

export default ImportCatalogue;
