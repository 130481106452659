import { Breadcrumbs, Grid, Link, Typography } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import PropTypes from "prop-types";

const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});
const useStyles = makeStyles(() => ({
  myLink: {
    color: "#263238",
    "&:hover": {
      color: "#263238",
    },
  },
}));

function HeaderPage({ className, title, breadcrumbs, ...rest }) {
  const classes = useStyles();

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Grid
        alignItems="flex-end"
        container
        justifyContent="space-between"
        spacing={3}
      >
        <Grid item>
          <ThemeProvider theme={theme}>
            <Typography
              style={{
                fontSize: "24px",
                fontWeight: "700",
                lineHeight: "32px",
              }}
            >
              {title}
            </Typography>
          </ThemeProvider>
        </Grid>
        <Grid item>
          <Breadcrumbs aria-label="breadcrumb">
            {breadcrumbs.map((item, idx) => {
              return (
                <div key={idx}>
                  {typeof item === "string" ? (
                    <Typography color="textPrimary" key={item}>
                      {item}
                    </Typography>
                  ) : (
                    <div>
                      {item.url === null ? (
                        <Typography color="textPrimary" key={item.name}>
                          {item.name}
                        </Typography>
                      ) : (
                        <Link
                          className={classes.myLink}
                          key={item.name}
                          color="inherit"
                          href={item.url}
                          underline="none"
                        >
                          {item.name}
                        </Link>
                      )}
                    </div>
                  )}
                </div>
              );
            })}
          </Breadcrumbs>
        </Grid>
      </Grid>
    </div>
  );
}

HeaderPage.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
};

export default HeaderPage;
