import {
  Backdrop,
  Button,
  CircularProgress,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  Radio,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  ThemeProvider,
  createTheme,
  withStyles,
} from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import { ContentState, EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { useDropzone } from "react-dropzone";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import "../../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import HeaderPageWithLink from "../../../../../layouts/Header/HeaderPageWithLink";
import { Page } from "components";
import { hardBaseUrl } from "../../../../../services/urlConstant";
import moment from "moment";

const fontInter = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const theme = createTheme({
  typography: {
    fontFamily: ["Helvetica Neue"].join(","),
  },
});

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  results: {
    marginTop: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  btnSubmit: {
    backgroundColor: "black",
    marginRight: "10px",
    color: "white",
    width: "150px",
    textTransform: "none",
    "&:hover": {
      color: "white",
      backgroundColor: "black",
    },
  },
  btnPreview: {
    border: "1px solid grey",
    width: "150px",
    color: "white",
    backgroundColor: "black",
    textTransform: "none",
    "&:hover": {
      color: "white",
      backgroundColor: "black",
    },
  },
  error: {
    color: "red",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const BlackRadio = withStyles({
  root: {
    color: "#303030",
    "&$checked": {
      color: "#000000",
    },
  },
  checked: {},
})(props => <Radio color="default" {...props} />);

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "50px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#303030",
  borderStyle: "solid",
  backgroundColor: "white",
  color: "black",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
  marginLeft: "auto",
  marginRight: "auto",
  borderRadius: "6px",
};

function EditBerita() {
  const classes = useStyles();
  const { state } = useLocation();

  const blocksFromHtml = htmlToDraft(state.customer.content);
  const { contentBlocks, entityMap } = blocksFromHtml;
  const contentState = ContentState.createFromBlockArray(
    contentBlocks,
    entityMap
  );
  const editorState = EditorState.createWithContent(contentState);

  const breadcrumbs = [
    { name: "Home", url: null },
    { name: "News & Ads", url: "/admin/berita" },
    { name: "Edit News & Ads", url: null },
  ];
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [selectedValue, setSelectedValue] = useState(
    localStorage.getItem("type") === null
      ? state.customer.type
      : localStorage.getItem("type")
  );
  const [textEditor, setTextEditor] = useState(editorState);
  const [judul, setJudul] = useState(
    localStorage.getItem("title") === null
      ? state.customer.title
      : localStorage.getItem("title")
  );

  const [gambar, setGambar] = useState([]);
  const [imageURL, setImageURL] = useState("");
  const [publisherName, setPublisherName] = useState("");
  const [imagesPrev, setImagesPrev] = useState("");
  const [showPreviewButton] = useState(true);
  const [notShow] = useState(true);

  const [error, setError] = useState({
    judul: "",
    publisherName: "",
    gambar: "",
    textEditor: "",
  });
  const handleDrop = useCallback(async acceptedFiles => {
    setGambar(acceptedFiles);
    let tesRes = await agetBase64(acceptedFiles[0]);
    let resAfter = tesRes.replace(/^data:image\/(png|jpg|jpeg);base64,/, "");
    localStorage.setItem("image_logo_bin_64_nofront", resAfter);
    setImagesPrev(tesRes);
  }, []);

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ accept: "image/*", onDrop: handleDrop });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const agetBase64 = file => {
    return new Promise(resolve => {
      let baseURL = "";

      let reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = () => {
        baseURL = reader.result;

        resolve(baseURL);
      };
    });
  };

  const handleChangeText = e => {
    const name = e.target.name;
    const value = e.target.value;
    switch (name) {
      case "judul":
        setJudul(value);
        break;
      default:
        break;
    }
  };

  const handleChange = event => {
    setSelectedValue(event.target.value);
  };

  const handleSubmit = e => {
    e.preventDefault();
    setLoadingDelete(true);
    if (judul === "") {
      setError({
        ...error,
        judul: "Title cannot be empty",
      });
    } else if (textEditor.getCurrentContent().hasText() === false) {
      setError({
        ...error,
        textEditor: "Contents cannot be empty",
      });
    } else if (
      gambar.length === 0 ||
      localStorage.getItem("image_logo") !== null
    ) {
      setError({
        ...error,
        gambar: "Image cannot be empty",
      });
    }

    if (
      (judul !== "" &&
        textEditor.getCurrentContent().hasText() &&
        gambar.length !== 0) ||
      localStorage.getItem("image_logo") !== null
    ) {
      const rawContentState = convertToRaw(textEditor.getCurrentContent());

      const markup = draftToHtml(rawContentState);

      const formData = new FormData();

      formData.append("title", judul);
      formData.append("type", selectedValue);
      formData.append("content", markup);

      if (acceptedFiles.length > 0) {
        formData.append("image_logo", gambar[0]);
      } else {
        var bs = atob(localStorage.getItem("image_logo_bin_64_nofront"));
        var buffer = new ArrayBuffer(bs.length);
        var ba = new Uint8Array(buffer);
        for (var i = 0; i < bs.length; i++) {
          ba[i] = bs.charCodeAt(i);
        }
        var thefile = new Blob([ba], { type: "image/png" });
        formData.append("image_logo", thefile);
      }

      const url = `${hardBaseUrl}/article/${state.customer.article_id}`;
      let token = localStorage.getItem("token");

      const config = {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      };

      axios
        .put(url, formData, config)
        .then(() => {
          localStorage.removeItem("title");
          localStorage.removeItem("content");
          localStorage.removeItem("image_logo");
          localStorage.removeItem("raw_content");
          localStorage.removeItem("raw_img");
          localStorage.removeItem("image_logo_bin_64_nofront");
          localStorage.removeItem("recentDate");
          localStorage.removeItem("article_id");
          localStorage.removeItem("type");
          localStorage.removeItem("fromPreview");
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Article modified successfully",
          });
          window.history.back();
        })
        .catch(() => { });
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Failed to change article!",
      });
    }
  };

  const handlePreview = async () => {
    setLoadingDelete(true);
    if (judul === "") {
      setError({
        ...error,
        judul: "Title cannot be empty",
      });
    } else if (textEditor.getCurrentContent().hasText() === false) {
      setError({
        ...error,
        textEditor: "Contents cannot be empty",
      });
    } else if (gambar.length === 0) {
      if (localStorage.getItem("image_logo") === null) {
        setError({
          ...error,
          gambar: "Image cannot be empty",
        });
      }
    }

    if (
      (judul !== "" &&
        textEditor.getCurrentContent().hasText() &&
        gambar.length !== 0) ||
      localStorage.getItem("image_logo") !== null
    ) {
      const recentDate = moment().toDate();

      const date = recentDate.getDate();

      const month = recentDate.toLocaleString("default", { month: "long" });

      const year = recentDate.getFullYear();

      const dateString = `${date} ${month} ${year}`;

      const rawContentState = convertToRaw(textEditor.getCurrentContent());
      const markup = draftToHtml(rawContentState);
      localStorage.setItem("title", judul);
      localStorage.setItem("type", selectedValue);
      localStorage.setItem("content", markup);
      localStorage.setItem("article_id", state.customer.article_id);

      localStorage.setItem(
        "raw_content",
        convertToRaw(textEditor.getCurrentContent())
      );
      localStorage.setItem("recentDate", dateString);
      localStorage.setItem("publisherName", publisherName);
      if (gambar.length > 0) {
        localStorage.setItem(
          "raw_img",
          `${gambar[0].name} - ${gambar[0].size} bytes`
        );
        const convertFileToBase64 = file =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
          });

        const database64 = await convertFileToBase64(gambar[0]);

        setImageURL(database64);

        localStorage.setItem("image_logo", database64);
      } else {
        localStorage.setItem("image_logo", localStorage.getItem("image_logo"));
      }

      window.location.href = "/admin/berita/edit/preview";
    }
  };

  const getPublisherName = () => {
    const theToken = localStorage.getItem("token");
    const url = `${hardBaseUrl}/me`;
    const config = {
      headers: {
        Authorization: `Bearer ${theToken}`,
      },
    };
    axios
      .get(url, config)
      .then(res => {
        setPublisherName(res.data.data.role.name);
      })
      .catch(() => { });
  };

  useEffect(() => {
    getPublisherName();
  }, [imageURL]);

  useEffect(() => {
    const getBase64Imagea = async (imgUrl, callback) => {
      var img = new Image();

      img.onload = function () {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);

        let dataURLnoFront = canvas.toDataURL("image/png");
        let newDataURLnoFront = dataURLnoFront.replace(
          /^data:image\/(png|jpg);base64,/,
          ""
        );

        callback(newDataURLnoFront);
      };

      img.setAttribute("crossOrigin", "anonymous");
      img.src = imgUrl;
    };

    const getBase64ImageWithFront = async (imgUrl, callback) => {
      var img = new Image();

      img.onload = function () {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);

        var dataURL = canvas.toDataURL("image/png");

        callback(dataURL);
      };

      img.setAttribute("crossOrigin", "anonymous");
      img.src = imgUrl;
    };

    let the_image_logo = localStorage.getItem("image_logo");
    if (the_image_logo) {
      getBase64Imagea(state.customer.banner_image, function (base64image) {
        localStorage.setItem("image_logo_bin_64_nofront", base64image);
      });
      getBase64ImageWithFront(state.customer.banner_image, function (
        base64imageWithFront
      ) {
        localStorage.setItem("image_logo", base64imageWithFront);
      });
    }
  }, []);

  useEffect(() => { }, [gambar]);

  return (
    <Page className={classes.root} title="Edit Berita">
      <Backdrop className={classes.backdrop} open={loadingDelete}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container maxWidth={false}>
        <HeaderPageWithLink title="Edit Berita" breadcrumbs={breadcrumbs} />
        <Divider className={classes.divider} />
        <form onSubmit={e => handleSubmit(e)}>
          <Grid container>
            <Grid item xs={8} md={8} sm={8} lg={8} xl={8}>
              <div>
                <ThemeProvider theme={fontInter}>
                  <Typography
                    style={{
                      fontSize: "18px",
                      lineHeght: "28px",
                      fontWeight: 700,
                    }}
                  >
                    Cover
                  </Typography>
                </ThemeProvider>
              </div>
              <div
                style={{
                  marginTop: "8px",
                }}
              >
                <ThemeProvider theme={theme}>
                  <Typography
                    style={{
                      color: "#687083",
                      fontSize: "14px",
                      lineHeght: "20px",
                    }}
                  >
                    Add images for your News & Ad content
                  </Typography>
                </ThemeProvider>
              </div>
              <div
                style={{
                  border: "1px solid #9AA2B1",
                  borderRadius: "8px",
                  padding: "16px",
                  marginTop: "16px",
                }}
              >
                <div
                  style={{
                    marginBottom: "6px",
                  }}
                >
                  <span>Banner</span>
                </div>
                <div
                  {...getRootProps({ style })}
                  style={{
                    paddingTop: "8px",
                    paddingBottom: "8px",
                    position: "relative",

                    height: "200px",
                    backgroundColor: "#F9FAFB",
                    border: "1px solid #E4E7EB",
                    borderRadius: "6px",
                  }}
                >
                  <input {...getInputProps()} />
                  <div
                    style={{
                      marginTop: "8px",
                      marginBottom: "8px",
                      display: "contents",
                      textAlign: "center",
                      width: "335px",
                      height: "185px",
                      border: "0px solid #E4E7EB",
                    }}
                  >
                    {gambar.length > 0 ? (
                      <>
                        {localStorage.getItem("image_logo") !== null ? (
                          <img src={imagesPrev} style={img} alt="aa" />
                        ) : (
                          <img src={imagesPrev} style={img} alt="bb" />
                        )}
                      </>
                    ) : (
                      <>
                        {localStorage.getItem("image_logo") !== null ? (
                          <img
                            src={localStorage.getItem("image_logo")}
                            style={img}
                            alt="cc"
                          />
                        ) : (
                          <img
                            src={state.customer.banner_image}
                            style={img}
                            alt="dd"
                          />
                        )}
                      </>
                    )}
                  </div>
                </div>
                <div>
                  <aside>
                    <Typography
                      component="span"
                      style={{
                        fontSize: "12px",
                        color: "#687083",
                      }}
                    >
                      Make sure the image size is 1000 x 500
                    </Typography>

                    {gambar.length === 0 ? (
                      <Typography
                        component="h1"
                        variant="h5"
                        className={classes.error}
                      >
                        {error.gambar}
                      </Typography>
                    ) : (
                      ""
                    )}
                  </aside>
                </div>
              </div>
              <Divider className={classes.divider} />
            </Grid>
            <Grid item xs={4} md={4} sm={4} lg={4} xl={4}></Grid>
          </Grid>

          <Grid container>
            <Grid item xs={8} md={8} sm={8} lg={8} xl={8}>
              <div>
                <Typography component="h1" variant="h3">
                  Content
                </Typography>
              </div>
              <div
                style={{
                  marginTop: "6px",
                }}
              >
                <ThemeProvider theme={theme}>
                  <Typography
                    style={{
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontSize: "14px",
                      lineHeight: "20px",
                      color: "#687083",
                    }}
                  >
                    Create content for your News & Ads{" "}
                  </Typography>
                </ThemeProvider>
              </div>

              <div
                style={{
                  borderRadius: "8px",
                  border: "1px solid #9AA2B1",
                  padding: "16px",
                  marginTop: "16px",
                }}
              >
                <div
                  style={{
                    marginTop: "5px",
                    marginBottom: "5px",
                  }}
                >
                  <div
                    style={{
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    <ThemeProvider theme={theme}>
                      <Typography>Title</Typography>
                    </ThemeProvider>
                  </div>
                  <div
                    style={{
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      fullWidth={true}
                      margin="dense"
                      onChange={handleChangeText}
                      name="judul"
                      value={judul}
                    />
                  </div>
                  {judul === "" && (
                    <Typography
                      component="h1"
                      variant="h5"
                      className={classes.error}
                    >
                      {error.judul}
                    </Typography>
                  )}
                </div>
                <div
                  style={{
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                >
                  <div
                    style={{
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    <ThemeProvider theme={theme}>
                      <Typography>Type</Typography>
                    </ThemeProvider>
                  </div>
                  <div
                    style={{
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    <FormControlLabel
                      value="end"
                      control={
                        <BlackRadio
                          checked={selectedValue === "berita"}
                          onChange={handleChange}
                          value="berita"
                          name="berita"
                          inputProps={{ "aria-label": "A" }}
                        />
                      }
                      label="News"
                    />
                    {notShow ? (
                      <>{""}</>
                    ) : (
                      <FormControlLabel
                        value="end"
                        control={
                          <BlackRadio
                            checked={selectedValue === "iklan"}
                            onChange={handleChange}
                            value="iklan"
                            name="iklan"
                            inputProps={{ "aria-label": "B" }}
                          />
                        }
                        label="Ads"
                      />
                    )}
                    <FormControlLabel
                      value="end"
                      control={
                        <BlackRadio
                          checked={selectedValue === "edukasi"}
                          onChange={handleChange}
                          value="edukasi"
                          name="edukasi"
                          inputProps={{ "aria-label": "C" }}
                        />
                      }
                      label="Education"
                    />
                    {notShow ? (
                      <>{""}</>
                    ) : (
                      <FormControlLabel
                        value="end"
                        control={
                          <BlackRadio
                            checked={selectedValue === "layanan"}
                            onChange={handleChange}
                            value="layanan"
                            name="layanan"
                            inputProps={{ "aria-label": "D" }}
                          />
                        }
                        label="Service"
                      />
                    )}
                  </div>
                </div>
                <div
                  style={{
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                >
                  <div
                    style={{
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    <ThemeProvider theme={theme}>
                      <Typography>Content</Typography>
                    </ThemeProvider>
                  </div>
                  <div
                    style={{
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    <Editor
                      editorState={textEditor}
                      wrapperClassName="wrapper-class"
                      editorClassName="editor-class input_description"
                      editorStyle={{
                        height: "60vH",
                        backgroundColor: "white",
                        paddingLeft: "10px",
                        paddingRight: "10px",
                      }}
                      toolbarClassName="toolbar-class"
                      wrapperStyle={{
                        border: "1px solid #ccc",
                        paddingLeft: 0,
                        paddingRight: 0,
                      }}
                      toolbar={{
                        options: [
                          "inline",
                          "blockType",
                          "fontSize",
                          "list",
                          "textAlign",
                          "colorPicker",
                          "link",
                          "remove",
                          "history",
                        ],
                      }}
                      onEditorStateChange={setTextEditor}
                    />
                    {textEditor.getCurrentContent().hasText() === false && (
                      <Typography
                        component="h1"
                        variant="h5"
                        className={classes.error}
                      >
                        {error.textEditor}
                      </Typography>
                    )}
                  </div>
                </div>
              </div>
              <div
                style={{
                  marginTop: "24px",
                }}
              >
                <Button className={classes.btnSubmit} type="submit">
                  <ThemeProvider theme={theme}>
                    <Typography>Save</Typography>
                  </ThemeProvider>
                </Button>
                {showPreviewButton ? (
                  <Button
                    className={classes.btnPreview}
                    onClick={handlePreview}
                  >
                    <ThemeProvider theme={theme}>
                      <Typography>Preview</Typography>
                    </ThemeProvider>
                  </Button>
                ) : (
                  <>{""}</>
                )}
              </div>
            </Grid>
            <Grid item xs={4} md={4} sm={4} lg={4} xl={4}></Grid>
          </Grid>
        </form>
      </Container>
    </Page>
  );
}

export default EditBerita;
