import { Typography, Box } from "@mui/material";

const FormLabel = ({ label, required, error }) => {
  return (
    <Box display="flex" gap="5px">
      <Typography
        fontSize="14px"
        fontWeight={500}
        mb="6px"
        color={error && "red"}
      >
        {label}
      </Typography>
      {required && <Typography color="red">*</Typography>}
    </Box>
  );
};

export default FormLabel;
