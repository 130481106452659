import axios from "axios";
import React, { useState } from "react";
import { useHistory } from "react-router";
import { ModalError, ModalSuccess } from "components";
import { hardBaseUrl } from "../../../services/urlConstant";
import FormInvoice from "./Components/FormInvoice";
import { getErrors } from "utils";

function AddInvoice() {
  const history = useHistory();
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const [loadingPage, setLoadingPage] = useState(false);

  const handlSubmitData = async payload => {
    try {
      setLoadingPage(true);
      await axios.post(`${hardBaseUrl}/publisher/transaction`, payload, {
        headers,
      });
      ModalSuccess("Invoice has been created").then(() => history.goBack());
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };

  return (
    <FormInvoice
      pageTitle="Create Invoice"
      pageSubTitle="Create your transaction here"
      loadingPage={loadingPage}
      onSubmitPayload={handlSubmitData}
    />
  );
}

export default AddInvoice;
