import {
  Breadcrumbs,
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  OutlinedInput,
  Paper,
  Select,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import TableContainer from "@material-ui/core/TableContainer";
import AddIcon from "@material-ui/icons/Add";
import MuiAlert from "@material-ui/lab/Alert";
import moment from "moment";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { useLocation } from "react-router";
import Swal from "sweetalert2";
import { Page } from "components";
import { hardBaseUrl } from "../../../../../services/urlConstant";
import gradients from "../../../../../theme/gradients";

import Pagination from "@material-ui/lab/Pagination";

const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isNumericString
    />
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  card: {
    width: theme.breakpoints.values.md,
    maxWidth: "100%",
    overflow: "visible",
    display: "flex",
    position: "relative",
    "& > *": {
      flexGrow: 1,
      flexBasis: "50%",
      width: "50%",
    },
  },
  content: {
    padding: theme.spacing(8, 4, 3, 4),
  },
  media: {
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    padding: theme.spacing(3),
    color: theme.palette.common.white,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  avatar_large: {
    width: theme.spacing(16),
    height: theme.spacing(16),
  },
  icon: {
    backgroundImage: gradients.green,
    color: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    position: "absolute",
    top: -32,
    left: theme.spacing(3),
    height: 64,
    width: 64,
    fontSize: 32,
  },
  loginForm: {
    marginTop: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  person: {
    marginTop: theme.spacing(2),
    display: "flex",
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  title: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "28px",
  },

  tambahLagu: {
    backgroundColor: "black",

    color: "white",
    width: "32px",
    height: "29px",
    borderRadius: "6px",
    cursor: "pointer",

    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
  textTambahLagu: {
    marginLeft: "10px",
    marginTop: "5px",
    fontSize: "14px",
    color: "#687083",
  },
  btnDialog: {
    backgroundColor: "black",

    color: "white",
    padding: "10px 28px",

    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
  btnSubmit: {
    backgroundColor: "black",

    color: "white",
    padding: "10px 28px",

    height: "40px",
    borderRadius: "6px",

    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
  headText: {
    fontSize: "12px",
    color: "#687083",
    fontWeight: "bold",
  },
  bodyTextBlack: {
    fontSize: "14px",
    fontWeight: "regular",
    color: "#111827",
  },
  bodyTextGrey: {
    fontSize: "14px",
    fontWeight: "regular",
    color: "#687083",
  },
  tableRow: {
    height: 20,
  },
  conTextBlack: {
    fontSize: "14px",
    fontWeight: "bold",
  },
  dropdown: {
    color: "black",
  },
  label: {
    color: "#364052",
    fontSize: "14px",
    fontWeight: 500,
  },
  subTitle: {
    fontSize: "18px",
    fontWeight: 700,
    color: " #111827",
  },
  breadcrumbs: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#111827",
  },
  breadCrumbsActive: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#687083",
  },
  pageSize: {
    height: "30px",
    borderRadius: "6px",
    border: "1px solid #D1D5DC",
    paddingLeft: "5px",
    paddingRight: "5px",
    marginTop: "5px",
  },
  paginationText: {
    fontSize: "14px",
    fontWeight: "normal",
    fontStyle: "normal",
    color: "#687083",
  },
  pagination: {
    "& .Mui-selected": {
      backgroundColor: "black",
      color: "white",
    },
  },
}));

function EditKontrak() {
  const classes = useStyles();
  const state = useLocation();

  const [open, setOpen] = useState(false);
  const [songList, setSongList] = useState([]);

  const [checked] = useState(false);
  const [penciptaList, setPenciptaList] = useState([]);

  const [songId, setSongId] = useState(null);
  const [startDate, setStartDate] = useState(moment().toString());
  const [endDate, setEndDate] = useState(moment().toString());
  const [dateRange, setDateRange] = useState(0);

  const [keterangan, setKeterangan] = useState("");
  const [openSnack, setOpenSnack] = useState(false);

  const [typeSnackbar, setTypeSnackbar] = useState("success");
  const [message, setMessage] = useState("This is a message!");
  const [wordsSong, setWordsSong] = useState("");
  const [getColor, setGetColor] = useState("");
  const [timePeriod, setTimePeriod] = useState("1");

  const [contractNumber, setContractNumber] = useState("");
  const [sharePercentage, setSharePercentage] = useState("");

  const [isLoadSong, setIsLoadSong] = useState(true);

  const [page, setPage] = useState(1);
  const [pagePublisherShare, setPagePublisherShare] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rowsPerPagePublisherShare, setRowsPerPagePublisherShare] = useState(
    10
  );
  const [publisherShareArray, setPublisherShareArray] = useState([]);

  const pagesCount = Math.ceil(penciptaList?.length / rowsPerPage);
  const pagesCountPublisherShare = Math.ceil(
    publisherShareArray?.length / rowsPerPagePublisherShare
  );

  const penciptaListSlice = penciptaList.slice(
    (page - 1) * rowsPerPage,
    (page - 1) * rowsPerPage + rowsPerPage
  );
  const publisherShareArraySlice = publisherShareArray.slice(
    (pagePublisherShare - 1) * rowsPerPagePublisherShare,
    (pagePublisherShare - 1) * rowsPerPagePublisherShare +
    rowsPerPagePublisherShare
  );

  const openModalLagu = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleReset = () => {
    setOpen(false);
  };

  useEffect(() => { }, [state.state.id]);

  const getDataEdit = () => {
    const token = localStorage.getItem("token");
    const id = state.state.id;
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .get(`${hardBaseUrl}/publisher/contract/composer-main/${id}`, config)
      .then(res => {
        let startingDate = res.data.data.contract.start_date.split(" ");
        let startingDate2 = startingDate[0].split("-");
        let startingDate3 =
          startingDate2[1] + "/" + startingDate2[0] + "/" + startingDate2[2];
        let endingDate = res.data.data.contract.end_date.split(" ");
        let endingDate2 = endingDate[0].split("-");
        let endingDate3 =
          endingDate2[1] + "/" + endingDate2[0] + "/" + endingDate2[2];

        let startingDate4 = new Date(startingDate3);
        let endingDate4 = new Date(endingDate3);

        setStartDate(startingDate4);
        setEndDate(endingDate4);

        setSongId(res.data.data.composer.composer_id);

        setKeterangan(res.data.data.contract.description);

        setContractNumber(res.data.data.contract.contract_number);

        setSharePercentage(res.data.data.contract.composer_percentage);
      })

      .catch(() => { });
  };

  useEffect(() => {
    getDataEdit();
  }, []);

  const getResponse = async () => {
    try {
      const token = localStorage.getItem("token");

      const url =
        hardBaseUrl + `/publisher/composer?page=1&size=10&search=${wordsSong}`;

      const options = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      const res = await axios.get(url, options);

      const newData = res.data.data.map(item => ({
        ...item,
        checked: false,
      }));
      setSongList(newData);

      setIsLoadSong(false);
    } catch (error) {
      new Error(error);
      setIsLoadSong(false);
    }
  };

  useEffect(() => {
    getResponse();
    const getMainDsp = async () => {
      try {
        const token = localStorage.getItem("token");
        const url = `${hardBaseUrl}/me`;
        const res = await axios.get(url, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        setGetColor(res.data.data.publisher.theme_color);
      } catch (e) {
        new Error(e);
      }
    };
    getMainDsp();
  }, [checked, startDate, endDate, wordsSong]);

  const handleDateChangeStartDate = date => {
    if (date > endDate) {
      setEndDate(date);
      setStartDate(endDate);
      setMessage("Start date must be less than end date");
      setTypeSnackbar("error");
      setOpenSnack(true);
    } else {
      setStartDate(date);
    }
  };

  const handleDateChangeEndDate = date => {
    if (date < startDate) {
      setStartDate(date);
      setMessage("End date must be greater than start date");
      setTypeSnackbar("error");
      setOpenSnack(true);
    } else {
      setEndDate(date);
    }
  };

  const handleDateRangeChange = event => {
    setEndDate(
      new Date(startDate.getTime() + event.target.value * 24 * 60 * 60 * 1000)
    );

    setDateRange(event.target.value);
  };

  const handleDateRangeChangeYear = event => {
    setEndDate(
      new Date(
        startDate.getTime() + event.target.value * 365 * 24 * 60 * 60 * 1000
      )
    );
    setDateRange(event.target.value);
  };

  const getDateFormatForFilter = date => {
    let dateFormat = new Date(date);
    let day = dateFormat
      .getDate()
      .toString()
      .padStart(2, "0");
    let month = (dateFormat.getMonth() + 1).toString().padStart(2, "0");
    let year = dateFormat.getFullYear();
    let dateString = `${year}-${month}-${day}`;
    return dateString;
  };

  const handleButtonSimpan = async e => {
    e.preventDefault();

    try {
      const token = localStorage.getItem("token");

      if (songId === null) {
        setTypeSnackbar("error");
        setMessage("lagu Tidak Boleh Kosong.");
        setOpenSnack(true);
      }
      if (startDate === "" || null || undefined) {
        setTypeSnackbar("error");
        setMessage("Tanggal Mulai Tidak Boleh Kosong.");
        setOpenSnack(true);
      }
      if (endDate === "" || null || undefined) {
        setTypeSnackbar("error");
        setMessage("Tanggal Selesai Tidak Boleh Kosong.");
        setOpenSnack(true);
      }

      if (songId !== null && startDate !== null && endDate !== null) {
        const formData = new FormData();
        formData.append("composer_id", songId);
        formData.append("date_periode", Number(dateRange));
        formData.append("start_date", getDateFormatForFilter(startDate));
        formData.append("end_date", getDateFormatForFilter(endDate));
        formData.append("description", keterangan);
        formData.append("contract_number", contractNumber);
        formData.append("composer_percentage", Number(sharePercentage));

        let baseOfUrl = hardBaseUrl;
        const id = state.state.id;
        let urlUse = baseOfUrl + `/publisher/contract/composer-main/${id}`;

        let resultAdd = await axios.put(urlUse, formData, {
          headers: {
            Authorization: "Bearer " + token,
          },
        });

        if (
          resultAdd.data.message === "success" &&
          resultAdd.data.meta.http_status === 200
        ) {
          Swal.fire({
            title: "Success!",
            text: "Data has been updated",
            icon: "success",
            confirmButtonText: "OK",
          }).then(result => {
            if (result.value) {
              window.location.href = "/admin/kontrak-komposer";
            }
          });
        } else {
          Swal.fire({
            title: "Error!",
            text: "Data failed to update",
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      }
    } catch (err) {
      Swal.fire({
        title: "Error!",
        text: err.response.data.errors[0].message,
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  const handleChangeText = e => {
    setKeterangan(e.target.value);
  };
  const handleCloseSnackbar = () => {
    setOpenSnack(false);
  };

  const handleTimePeriodYear = () => {
    setTimePeriod("2");
    setDateRange(0);
  };

  const handleTimePeriodUnlimited = () => {
    setTimePeriod("1");
    getDataEdit();
    const totalDistance = (endDate - startDate) / (1000 * 60 * 60 * 24);

    let totalDistanceEven =
      totalDistance % 2 === 0 ? totalDistance : totalDistance + 1;
    const totalEven = Math.floor(totalDistanceEven);
    setDateRange(totalEven);
  };

  const changeSearchSong = e => {
    setIsLoadSong(true);
    setWordsSong(e);
  };

  const handleChangeMultipleCheckbox = (event, id) => {
    songList.map(item => {
      if (item.composer_id === id && event.target.checked === true) {
        item.checked = event.target.checked;
        setPenciptaList([...penciptaList, item]);
        const newValue = {
          ...item,
          value: 0,
        };
        setPublisherShareArray([...publisherShareArray, newValue]);
      } else if (item.composer_id === id && event.target.checked === false) {
        item.checked = event.target.checked;
        const filtered = penciptaList.filter(item => item.composer_id !== id);
        setPenciptaList(filtered);
        const filteredPublisherShare = publisherShareArray.filter(
          item => item.composer_id !== id
        );
        setPublisherShareArray(filteredPublisherShare);
      }
    });
  };

  const handleChangeRowsPerPage = event => {
    event.preventDefault();
    setRowsPerPage(event.target.value);

    setPage(1);
  };

  const pageSizePagination = () => {
    return (
      <div className={classes.pageSize}>
        <select
          style={{
            backgroundColor: "transparent",
            border: "none",
            outline: "none",
            marginTop: "4px",
          }}
          value={rowsPerPage}
          onChange={handleChangeRowsPerPage}
        >
          {[10, 20, 50].map(pageSize => {
            return (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            );
          })}
        </select>
      </div>
    );
  };

  const handleChangePage = (event, newPage) => {
    event.preventDefault();
    setPage(newPage);
  };

  const handleChangeSharePublisher = (e, id) => {
    const newData = [...publisherShareArray];
    const index = publisherShareArray.findIndex(
      item => item.composer_id === id
    );
    if (e.target.value <= 100) {
      newData[index].value = e.target.value;
    } else {
      newData[index].value = 0;
    }

    setPublisherShareArray(newData);
  };

  const handleChangeRowsPerPagePublisherShare = event => {
    event.preventDefault();
    setRowsPerPagePublisherShare(event.target.value);

    setPagePublisherShare(1);
  };

  const pageSizePaginationPublisherShare = () => {
    return (
      <div className={classes.pageSize}>
        <select
          style={{
            backgroundColor: "transparent",
            border: "none",
            outline: "none",
            marginTop: "4px",
          }}
          value={rowsPerPagePublisherShare}
          onChange={handleChangeRowsPerPagePublisherShare}
        >
          {[10, 20, 50].map(pageSize => {
            return (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            );
          })}
        </select>
      </div>
    );
  };

  const handleChangePagePublisherShare = (event, newPage) => {
    event.preventDefault();
    setPagePublisherShare(newPage);
  };
  return (
    <Page className={classes.root} title="Tambah Kontrak">
      <Container maxWidth={false}>
        <Snackbar
          open={openSnack}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert onClose={handleCloseSnackbar} severity={typeSnackbar}>
            {message}
          </Alert>
        </Snackbar>
        <Grid
          alignItems="flex-end"
          container
          justifyContent="space-between"
          spacing={3}
        >
          <Grid item>
            <ThemeProvider theme={theme}>
              <Typography
                style={{
                  fontSize: "24px",
                  fontWeight: "700",
                  lineHeight: "32px",
                }}
              >
                Update Contract
              </Typography>
            </ThemeProvider>
          </Grid>
          <Grid item>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography className={classes.breadCrumbs}>Home</Typography>

              <Typography className={classes.breadCrumbs}>Contract</Typography>
              <Typography className={classes.breadCrumbsActive}>
                Update Contract
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
        <Divider className={classes.divider} />

        <Grid container>
          <div>
            <ThemeProvider theme={theme}>
              <Typography
                component="h1"
                variant="h3"
                className={classes.subTitle}
              >
                Song
              </Typography>
            </ThemeProvider>
            <ThemeProvider theme={theme}>
              <p
                style={{
                  marginTop: "5px",
                  color: "#8f8f8f",
                  fontSize: "14px",
                }}
              >
                This feature can add multiple songs in one contract
              </p>
            </ThemeProvider>
          </div>
        </Grid>

        <Grid container>
          <Grid item xs={8} md={8} sm={8} lg={8} xl={8}>
            <Grid container>
              <Grid item xs={12} md={12} sm={12} lg={12} xl={12}>
                <div
                  style={{
                    marginTop: "5px",
                    marginBottom: "5px",
                  }}
                >
                  <div
                    style={{
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    <Paper
                      style={{
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        backgroundColor: "#F9FAFB",
                        cursor: "pointer",
                      }}
                      onClick={openModalLagu}
                    >
                      children=
                      {
                        <Container>
                          <div style={{ display: "flex" }}>
                            <IconButton
                              onClick={openModalLagu}
                              className={classes.tambahLagu}
                              style={{
                                backgroundColor: " #111827",
                              }}
                            >
                              <AddIcon />
                            </IconButton>
                            <ThemeProvider theme={theme}>
                              <Typography
                                variant="subtitle2"
                                className={classes.textTambahLagu}
                              >
                                Click to add Composer
                              </Typography>
                            </ThemeProvider>
                          </div>
                        </Container>
                      }
                    </Paper>
                    <Dialog
                      fullWidth={true}
                      maxWidth={"md"}
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="max-width-dialog-title"
                    >
                      <DialogTitle id="max-width-dialog-title">
                        Search Composer
                      </DialogTitle>
                      <Grid container>
                        <Grid item xs={12} md={12} sm={12} lg={12} xl={12}>
                          <Container>
                            <div style={{ display: "flex" }}>
                              <FormControl
                                variant="outlined"
                                style={{ minWidth: 120 }}
                              >
                                <Select
                                  native
                                  value="name"
                                  onChange={() => { }}
                                  disabled
                                  inputProps={{
                                    name: "age",
                                    id: "outlined-age-native-simple",
                                  }}
                                >
                                  <option
                                    value="name"
                                    style={{ color: "#687083" }}
                                  >
                                    Composer
                                  </option>
                                </Select>
                              </FormControl>
                              <FormControl variant="outlined" fullWidth={true}>
                                <OutlinedInput
                                  id="outlined-adornment-password"
                                  placeholder="Search"
                                  value={wordsSong}
                                  onChange={e => {
                                    changeSearchSong(e.target.value);
                                  }}
                                />
                              </FormControl>
                            </div>

                            <Grid container>
                              <Grid item xs={8} md={8} sm={8} lg={8} xl={8}>
                                <Divider className={classes.divider} />
                              </Grid>
                            </Grid>

                            <TableContainer style={{ maxHeight: 200 }}>
                              <Table stickyHeader size="small">
                                <TableHead>
                                  <TableRow>
                                    <ThemeProvider theme={theme}>
                                      <TableCell>
                                        <Typography
                                          variant="subtitle2"
                                          className={classes.headText}
                                        >
                                          Composer/Author Name
                                        </Typography>
                                      </TableCell>
                                    </ThemeProvider>
                                    <TableCell></TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {isLoadSong ? (
                                    <>
                                      <TableRow>
                                        <TableCell align="center" colSpan={3}>
                                          <CircularProgress />
                                        </TableCell>
                                      </TableRow>
                                    </>
                                  ) : (
                                    <>
                                      {songList.map(customer => (
                                        <TableRow hover key={customer.song_id}>
                                          <ThemeProvider theme={theme}>
                                            <TableCell
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                              }}
                                            >
                                              <Checkbox
                                                color="primary"
                                                onChange={e =>
                                                  handleChangeMultipleCheckbox(
                                                    e,
                                                    customer.composer_id
                                                  )
                                                }
                                                checked={customer.checked}
                                              />
                                              <Typography
                                                variant="subtitle2"
                                                className={
                                                  classes.bodyTextBlack
                                                }
                                              >
                                                { }
                                                {customer.sure_name}
                                              </Typography>
                                            </TableCell>
                                          </ThemeProvider>
                                          { }
                                        </TableRow>
                                      ))}
                                    </>
                                  )}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Container>
                        </Grid>
                      </Grid>
                      <DialogContent></DialogContent>
                      <DialogActions>
                        <Button
                          onClick={handleReset}
                          className={classes.btnDialog}
                          style={{
                            backgroundColor: getColor,
                          }}
                        >
                          <ThemeProvider theme={theme}>
                            <Typography
                              style={{
                                textTransform: "none",
                                color: "#FFFFFF",
                                fontSize: "14px",
                                fontWeight: 400,
                                lineHeight: "20px",
                                textDecoration: "none",
                              }}
                            >
                              Cancel
                            </Typography>
                          </ThemeProvider>
                        </Button>
                        <Button
                          onClick={handleClose}
                          className={classes.btnDialog}
                          variant="contained"
                          style={{
                            backgroundColor: getColor,
                          }}
                        >
                          <ThemeProvider theme={theme}>
                            <Typography
                              style={{
                                textTransform: "none",
                                color: "#FFFFFF",
                                fontSize: "14px",
                                fontWeight: 400,
                                lineHeight: "20px",
                                textDecoration: "none",
                              }}
                            >
                              Choose
                            </Typography>
                          </ThemeProvider>
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={8} md={8} sm={8} lg={8} xl={8}>
            <Grid container>
              <Grid item xs={12} md={12} sm={12} lg={12} xl={12}>
                <Card
                  style={{
                    border: "1px solid #9AA2B1",
                    borderRadius: "6px",
                    boxShadow: "none",
                  }}
                >
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <ThemeProvider theme={theme}>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={classes.headText}
                            >
                              Composer/Author Name
                            </Typography>
                          </TableCell>
                        </ThemeProvider>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {penciptaListSlice !== null ? (
                        penciptaListSlice?.length > 0 ? (
                          penciptaListSlice.map((customer, idx) => (
                            <TableRow hover key={`${idx}`}>
                              <TableCell>
                                <Typography
                                  variant="subtitle2"
                                  className={classes.bodyTextBlack}
                                >
                                  {customer.sure_name}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={4}>
                              <center>
                                <ThemeProvider theme={theme}>
                                  <Typography
                                    variant="h6"
                                    style={{
                                      fontWeight: 400,
                                      color: "#687083",
                                      fontSize: "14px",
                                    }}
                                  >
                                    no data
                                  </Typography>
                                </ThemeProvider>
                              </center>
                            </TableCell>
                          </TableRow>
                        )
                      ) : (
                        <TableRow>
                          <TableCell colSpan={3}>
                            <center>
                              <ThemeProvider theme={theme}>
                                <Typography
                                  variant="h6"
                                  style={{
                                    fontWeight: 400,
                                    color: "#687083",
                                    fontSize: "14px",
                                  }}
                                >
                                  no data
                                </Typography>
                              </ThemeProvider>
                            </center>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                  {penciptaListSlice?.length > 0 ? (
                    <CardActions className={classes.actions}>
                      <Grid
                        alignContent="space-between"
                        container
                        justifyContent="space-between"
                        spacing={3}
                      >
                        <Grid item>
                          <div style={{ display: "flex" }}>
                            <div style={{ marginTop: "7px" }}>
                              <ThemeProvider theme={theme}>
                                <Typography className={classes.paginationText}>
                                  Item per page:
                                </Typography>
                              </ThemeProvider>
                            </div>{" "}
                            <div style={{ marginLeft: "10px" }}>
                              {pageSizePagination()}
                            </div>
                          </div>
                        </Grid>

                        <Grid item>
                          <Pagination
                            count={pagesCount}
                            shape="rounded"
                            className={classes.pagination}
                            page={page}
                            onChange={handleChangePage}
                          />
                        </Grid>
                      </Grid>
                    </CardActions>
                  ) : null}
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={8} md={8} sm={8} lg={8} xl={8}>
            <Divider className={classes.divider} />
          </Grid>
        </Grid>

        <Grid container>
          <div>
            <ThemeProvider theme={theme}>
              <Typography
                component="h1"
                variant="h3"
                className={classes.subTitle}
              >
                Information Contract
              </Typography>
            </ThemeProvider>
            <ThemeProvider theme={theme}>
              <p
                style={{
                  marginTop: "5px",
                  color: "#8f8f8f",
                  fontSize: "14px",
                }}
              >
                Form to fill out information data related to the contract to be
                made
              </p>
            </ThemeProvider>
          </div>
        </Grid>
        <div
          style={{
            marginBottom: "20px",
          }}
        >
          <Button
            onClick={handleTimePeriodUnlimited}
            className={classes.btnDialog}
            style={{
              backgroundColor: timePeriod === "1" ? " #111827" : "#FFFFFF",
              marginRight: "10px",
              borderColor: timePeriod === "1" ? "#FFFFFF" : "black",
              border: "1px solid #9AA2B1",
            }}
          >
            <ThemeProvider theme={theme}>
              <Typography
                style={{
                  textTransform: "none",
                  color: timePeriod === "1" ? "#FFFFFF" : " #111827",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "20px",
                  textDecoration: "none",
                }}
              >
                Unlimited
              </Typography>
            </ThemeProvider>
          </Button>
          <Button
            onClick={handleTimePeriodYear}
            className={classes.btnDialog}
            variant="contained"
            style={{
              backgroundColor: timePeriod === "2" ? " #111827" : "#FFFFFF",
              borderColor: timePeriod === "2" ? "#FFFFFF" : "black",
              border: "1px solid #9AA2B1",
            }}
          >
            <ThemeProvider theme={theme}>
              <Typography
                style={{
                  textTransform: "none",
                  color: timePeriod === "2" ? "#FFFFFF" : " #111827",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "20px",
                  textDecoration: "none",
                }}
              >
                Year
              </Typography>
            </ThemeProvider>
          </Button>
        </div>

        <Grid container>
          <Grid item xs={8} md={8} sm={8} lg={8} xl={8}>
            <Card
              style={{
                border: "1px solid #9AA2B1",
                borderRadius: "6px",
                boxShadow: "none",
              }}
            >
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={6} md={6} sm={6} lg={6} xl={6}>
                    <div
                      style={{
                        marginTop: "5px",
                        marginBottom: "5px",
                      }}
                    >
                      <div
                        style={{
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        <ThemeProvider theme={theme}>
                          <Typography
                            variant="subtitle2"
                            className={classes.label}
                          >
                            Contract Number
                          </Typography>
                        </ThemeProvider>
                      </div>
                      <div
                        style={{
                          marginTop: "-10px",
                          marginBottom: "10px",
                        }}
                      >
                        <TextField
                          variant="outlined"
                          disabled
                          name="numberformat"
                          id="formatted-numberformat-input"
                          margin="dense"
                          fullWidth={true}
                          value={contractNumber}
                        />
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={6} md={6} sm={6} lg={6} xl={6}>
                    <div
                      style={{
                        marginTop: "5px",
                        marginBottom: "5px",
                      }}
                    >
                      <div
                        style={{
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        <ThemeProvider theme={theme}>
                          <Typography
                            variant="subtitle2"
                            className={classes.label}
                          >
                            Time Period
                          </Typography>
                        </ThemeProvider>
                      </div>
                      <div
                        style={{
                          marginTop: "-10px",
                          marginBottom: "10px",
                        }}
                      >
                        <TextField
                          defaultValue={0}
                          variant="outlined"
                          value={dateRange}
                          onChange={
                            timePeriod === "1"
                              ? handleDateRangeChange
                              : handleDateRangeChangeYear
                          }
                          name="numberformat"
                          id="formatted-numberformat-input"
                          margin="dense"
                          fullWidth={true}
                          InputProps={{
                            inputComponent: NumberFormatCustom,
                            endAdornment: (
                              <InputAdornment position="end">
                                {timePeriod === "1" ? "Day" : "Year"}
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                    </div>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6} md={6} sm={6} lg={6} xl={6}>
                    <div
                      style={{
                        marginTop: "5px",
                        marginBottom: "5px",
                      }}
                    >
                      <div
                        style={{
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        <ThemeProvider theme={theme}>
                          <Typography
                            variant="subtitle2"
                            className={classes.label}
                          >
                            Start Date
                          </Typography>
                        </ThemeProvider>
                      </div>
                      <div
                        style={{
                          marginTop: "-10px",
                          marginBottom: "10px",
                        }}
                      >
                        <KeyboardDatePicker
                          id="outlined-basic"
                          variant="outlined"
                          onChange={handleDateChangeStartDate}
                          value={startDate}
                          name="startDate"
                          format="YYYY-MM-DD"
                          inputVariant="outlined"
                          fullWidth={true}
                          margin="dense"
                        />
                      </div>
                    </div>
                  </Grid>

                  <Grid item xs={6} md={6} sm={6} lg={6} xl={6}>
                    <div
                      style={{
                        marginTop: "5px",
                        marginBottom: "5px",
                      }}
                    >
                      <div
                        style={{
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        <ThemeProvider theme={theme}>
                          <Typography
                            variant="subtitle2"
                            className={classes.label}
                          >
                            End Date
                          </Typography>
                        </ThemeProvider>
                      </div>
                      <div
                        style={{
                          marginTop: "-10px",
                          marginBottom: "10px",
                        }}
                      >
                        <KeyboardDatePicker
                          id="outlined-basic"
                          value={endDate}
                          onChange={handleDateChangeEndDate}
                          format="YYYY-MM-DD"
                          name="endDate"
                          inputVariant="outlined"
                          fullWidth={true}
                          margin="dense"
                          placeholder="Tanggal Selesai"
                        />
                      </div>
                    </div>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6} md={6} sm={6} lg={6} xl={6}>
                    <div
                      style={{
                        marginTop: "10px",
                        marginBottom: "5px",
                      }}
                    >
                      <div
                        style={{
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        <ThemeProvider theme={theme}>
                          <Typography
                            variant="subtitle2"
                            className={classes.label}
                          >
                            Composer Share Percentage
                          </Typography>
                        </ThemeProvider>
                      </div>
                      <div
                        style={{
                          marginTop: "-10px",
                          marginBottom: "10px",
                        }}
                      >
                        <TextField
                          defaultValue={0}
                          variant="outlined"
                          name="numberformat"
                          id="formatted-numberformat-input"
                          value={sharePercentage}
                          disabled
                          margin="dense"
                          fullWidth={true}
                          InputProps={{
                            inputComponent: NumberFormatCustom,
                            endAdornment: (
                              <InputAdornment position="end">
                                {"%"}
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={6} md={6} sm={6} lg={6} xl={6}></Grid>
                </Grid>

                <Grid item xs={12} md={12} sm={12} lg={12} xl={12}>
                  <div
                    style={{
                      marginTop: "5px",
                      marginBottom: "5px",
                    }}
                  >
                    <div
                      style={{
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      <ThemeProvider theme={theme}>
                        <Typography
                          variant="subtitle2"
                          className={classes.label}
                        >
                          Description
                        </Typography>
                      </ThemeProvider>
                    </div>
                    <div
                      style={{
                        marginTop: "-10px",
                        marginBottom: "10px",
                      }}
                    >
                      <TextField
                        multiline={true}
                        minRows={10}
                        id="outlined-basic"
                        placeholder="Add Description Here..."
                        value={keterangan}
                        variant="outlined"
                        fullWidth={true}
                        margin="dense"
                        name="keterangan"
                        onChange={e => handleChangeText(e)}
                      />
                    </div>
                  </div>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={4} md={4} sm={4} lg={4} xl={4}></Grid>
        </Grid>

        <Divider className={classes.divider} />
        <Grid container>
          <Grid item xs={8} md={8} sm={8} lg={8} xl={8}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div>
                <ThemeProvider theme={theme}>
                  <Typography
                    component="h1"
                    variant="h3"
                    className={classes.subTitle}
                  >
                    Publisher Share Percentage
                  </Typography>
                </ThemeProvider>
                <ThemeProvider theme={theme}>
                  <p
                    style={{
                      marginTop: "5px",
                      color: "#8f8f8f",
                      fontSize: "14px",
                    }}
                  >
                    Feature to set publisher share percentage
                  </p>
                </ThemeProvider>
              </div>

              { }
            </div>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={8} md={8} sm={8} lg={8} xl={8}>
            <Grid container>
              <Grid item xs={12} md={12} sm={12} lg={12} xl={12}>
                <Card
                  style={{
                    border: "1px solid #9AA2B1",
                    borderRadius: "6px",
                    boxShadow: "none",
                  }}
                >
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <ThemeProvider theme={theme}>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={classes.headText}
                            >
                              Composer/Author Name
                            </Typography>
                          </TableCell>
                          <TableCell></TableCell>
                        </ThemeProvider>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {publisherShareArraySlice !== null ? (
                        publisherShareArraySlice?.length > 0 ? (
                          publisherShareArraySlice.map(customer => (
                            <TableRow hover key={customer.composer_id}>
                              <TableCell>
                                <Typography
                                  variant="subtitle2"
                                  className={classes.bodyTextBlack}
                                >
                                  {customer.sure_name}
                                </Typography>
                              </TableCell>
                              <TableCell
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <TextField
                                  value={customer.value}
                                  variant="outlined"
                                  onChange={e =>
                                    handleChangeSharePublisher(
                                      e,
                                      customer.composer_id
                                    )
                                  }
                                  name="numberformat"
                                  id="formatted-numberformat-input"
                                  margin="dense"
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        {"%"}
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={4}>
                              <center>
                                <ThemeProvider theme={theme}>
                                  <Typography
                                    variant="h6"
                                    style={{
                                      fontWeight: 400,
                                      color: "#687083",
                                      fontSize: "14px",
                                    }}
                                  >
                                    no data
                                  </Typography>
                                </ThemeProvider>
                              </center>
                            </TableCell>
                          </TableRow>
                        )
                      ) : (
                        <TableRow>
                          <TableCell colSpan={3}>
                            <center>
                              <ThemeProvider theme={theme}>
                                <Typography
                                  variant="h6"
                                  style={{
                                    fontWeight: 400,
                                    color: "#687083",
                                    fontSize: "14px",
                                  }}
                                >
                                  no data
                                </Typography>
                              </ThemeProvider>
                            </center>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                  {publisherShareArraySlice?.length > 0 ? (
                    <CardActions className={classes.actions}>
                      <Grid
                        alignContent="space-between"
                        container
                        justifyContent="space-between"
                        spacing={3}
                      >
                        <Grid item>
                          <div style={{ display: "flex" }}>
                            <div style={{ marginTop: "7px" }}>
                              <ThemeProvider theme={theme}>
                                <Typography className={classes.paginationText}>
                                  Item per page:
                                </Typography>
                              </ThemeProvider>
                            </div>{" "}
                            <div style={{ marginLeft: "10px" }}>
                              {pageSizePaginationPublisherShare()}
                            </div>
                          </div>
                        </Grid>

                        <Grid item>
                          <Pagination
                            count={pagesCountPublisherShare}
                            shape="rounded"
                            className={classes.pagination}
                            page={pagePublisherShare}
                            onChange={handleChangePagePublisherShare}
                          />
                        </Grid>
                      </Grid>
                    </CardActions>
                  ) : null}
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Divider className={classes.divider} />

        <Grid container>
          <Button
            variant="contained"
            onClick={e => handleButtonSimpan(e)}
            className={classes.btnSubmit}
            style={{
              backgroundColor: " #111827",
            }}
          >
            <ThemeProvider theme={theme}>
              <Typography
                style={{
                  textTransform: "none",
                  color: "#FFFFFF",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "20px",
                }}
              >
                Update Contract
              </Typography>
            </ThemeProvider>
          </Button>
        </Grid>
      </Container>
    </Page>
  );
}

export default EditKontrak;
