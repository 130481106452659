export const getTotalPage = (total, size) => Math.ceil(total / size);
export const calculateSingleTax = (tax, totalRevenue) =>
  (tax?.rate / 100) * (tax?.tax_bases / 100) * Number(totalRevenue || 0);
export const calculateTotalRevenue = songs => {
  return songs?.reduce((acc, song) => {
    const songTotal = song?.qty * song?.revenue;
    return acc + songTotal;
  }, 0);
};
export const calculateTotalTax = (taxes, totalRevenue) => {
  return taxes?.reduce((total, tax) => {
    const afterCountTax =
      (tax.rate / 100) * (tax.tax_bases / 100) * Number(totalRevenue || 0);
    return tax.method === "addition"
      ? total + afterCountTax
      : total - afterCountTax;
  }, 0);
};

export default {
  getTotalPage,
  calculateSingleTax,
  calculateTotalRevenue,
  calculateTotalTax,
};
