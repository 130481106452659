import { IconButton, styled, Tooltip } from "@mui/material";
import { getCookie } from "utils";
import { cloneElement } from "react";

const PrimaryIconButton = ({
  icon,
  onClick,
  disabled,
  color,
  width,
  height,
  size,
  tooltipTitle,
}) => {
  const userLogin = getCookie("auth", `${process.env.REACT_APP_NAME}_user`);
  const adjustedIcon = cloneElement(icon, {
    style: { fontSize: size, color: "white" },
  });
  return (
    <Tooltip title={tooltipTitle} placement="bottom">
      <CustomButton
        onClick={onClick}
        disabled={disabled}
        customColor={color || userLogin?.publisher?.theme_color}
        customWidt={width}
        customHeight={height}
        size={size}
      >
        {adjustedIcon}
      </CustomButton>
    </Tooltip>
  );
};

const CustomButton = styled(IconButton)(
  ({ customColor, customWidth, customHeight }) => ({
    backgroundColor: customColor || "#111827",
    color: "white",
    textTransform: "none",
    whiteSpace: "nowrap",
    height: customHeight || 30,
    width: customWidth || 30,
    borderRadius: 6,
    padding: 0,
    "&:hover": {
      backgroundColor: `${customColor || "#111827"}`,
      color: "white",
      boxShadow: `0px 2px 4px ${customColor || "#111827"}`,
    },
    "&.MuiButton-root.Mui-disabled": {
      backgroundColor: "#9ca3af",
    },
  })
);
export default PrimaryIconButton;
