import { useEffect, useState } from "react";
import axios from "axios";
import { hardBaseUrl } from "../../../services/urlConstant";
import { MonthYearRangePicker, Page } from "components";
import {
  Backdrop,
  CircularProgress,
  Container,
  Divider,
  Grid,
  createTheme,
  FormControl,
  MenuItem,
  TextField,
  Checkbox,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { InnoTable } from "inno-ui";
import moment from "moment-timezone";
import { HeaderTitle } from "layouts";

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  results: {
    marginTop: theme.spacing(3),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  pageSize: {
    height: "30px",
    borderRadius: "6px",
    border: "1px solid #D1D5DC",
    paddingLeft: "5px",
    paddingRight: "5px",
    marginTop: "5px",
  },
  paginationText: {
    fontSize: "14px",
    fontWeight: "normal",
    fontStyle: "normal",
  },
  breadcrumbs: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#111827",
  },
  breadCrumbsActive: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#687083",
  },
  placeholder: {
    "&::placeholder": {
      color: "#9AA2B1",
    },
  },
  select: {
    "& .MuiSelect-outlined": {
      paddingTop: "14px",
      paddingBottom: "14px",
      paddingLeft: "20px",
      paddingRight: "30px",
      width: "180px",
    },
  },
  dropdown: {
    width: "195px",
    marginTop: 3,
    height: "44px",
    borderRadius: "6px",
  },
  datePicker: {
    width: "195px",
    marginTop: 3,
    height: "44px",
    borderRadius: "6px",
  },
  typography: {
    fontSize: "24px",
    fontWeight: "700",
    lineHeight: "32px",
  },
  bottomFilter: {
    marginBottom: 20,
  },
}));

const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const SubmitDSP = () => {
  const classes = useStyles();
  const token = localStorage.getItem("token");
  const headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const [periodFilter, setPeriodFilter] = useState({
    startDate: moment(),
    endDate: moment(),
  });
  const [state, setState] = useState({
    loading: false,
    rowsPerPage: 10,
    optionDsp: [],
    page: 1,
    publisher_id: null,
    listSubmitDsp: [],
    filter: {
      dsp: "none",
      status: "none",
      period: null,
    },
    listSelectedSubmitDSP: [],
  });

  const {
    rowsPerPage,
    optionDsp,
    filter,
    page,
    publisher_id,
    listSubmitDsp,
    loading,
    listSelectedSubmitDSP,
  } = state;
  const { dsp, period, status } = filter;

  const handleChangePeriodFilter = (value, key) => {
    setPeriodFilter(currentState => ({
      ...currentState,
      [key]: value,
    }));
  };
  const handleChangeFilter = (value, key) =>
    setState(state => ({
      ...state,
      filter: {
        ...state.filter,
        [key]: value,
      },
    }));

  const handleChangeStartDate = date => {
    handleChangePeriodFilter(date, "startDate");
    setState(state => ({
      ...state,
      filter: {
        ...state.filter,
        period: {
          ...state?.filter?.period,
          start_date: date.format("YYYY-MM"),
        },
      },
    }));
  };

  const handleChangeEndDate = date => {
    handleChangePeriodFilter(date, "endDate");
    setState(state => ({
      ...state,
      filter: {
        ...state.filter,
        period: {
          ...state?.filter?.period,
          end_date: date.format("YYYY-MM"),
        },
      },
    }));
  };

  const handleChangePage = () => {
    return false;
  };

  const handleChangeRowsPerPage = event => {
    event.persist();
    return false;
  };

  const fetchOptionDsp = async () => {
    try {
      const get_publisher_id = await axios.get(`${hardBaseUrl}/me`, headers);
      const { publisher_id } = get_publisher_id?.data?.data?.publisher;
      const url = `${hardBaseUrl}/publishers/list/${publisher_id}/dsps`;
      const res = await axios.get(url, headers);
      setState(state => ({
        ...state,
        publisher_id,
        optionDsp: res.data.data || [],
      }));
    } catch (error) {
      new Error(error);
    }
  };
  console.log({ period });
  const columns = [
    {
      name: "all",
      renderText: ({ file_path }) => {
        const isChecked = listSelectedCCID.some(
          itemCCID => itemCCID === file_path
        );
        return (
          <Checkbox
            color="default"
            checked={isChecked}
            onChange={() => handleValueCheckBox(file_path)}
          />
        );
      },
    },
    {
      name: "file_name",
      title: "DSP",
      renderText: item => {
        return item || "-";
      },
    },
    {
      name: "file_size",
      title: "Period",
      renderText: item => {
        return item || "-";
      },
    },
    {
      name: "file_size",
      title: "Total Traffic",
      renderText: item => {
        return item || "-";
      },
    },
    {
      name: "file_size",
      title: "Period",
      renderText: item => {
        return item || "-";
      },
    },
    {
      name: "file_size",
      title: "Total Revenue",
      renderText: item => {
        return item || "-";
      },
    },
    {
      name: "file_size",
      title: "Overdue",
      renderText: item => {
        return item || "-";
      },
    },
    {
      name: "file_size",
      title: "Status",
      renderText: item => {
        return item || "-";
      },
    },
  ];

  useEffect(() => {
    fetchOptionDsp();
  }, []);

  return (
    <Page className={classes.root} title="Submit to DSP">
      <Backdrop className={classes.backdrop} open={false}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container maxWidth={false}>
        <HeaderTitle title="Submit to DSP" breadcrumbData={breadcrumbData} />
        <Divider className={classes.divider} />
        <Grid
          id="bottom-filter"
          container
          spacing={4}
          className={classes.bottomFilter}
        >
          <Grid item>
            Period
            <MonthYearRangePicker
              startDate={periodFilter?.startDate}
              handleChangeStartDate={handleChangeStartDate}
              endDate={periodFilter?.endDate}
              handleChangeEndDate={handleChangeEndDate}
            />
          </Grid>
          <Grid item>
            DSP
            <FormControl fullWidth variant="outlined">
              <TextField
                size="small"
                className={classes.dropdown}
                onChange={e => handleChangeFilter(e.target.value, "dsp")}
                value={dsp}
                select
                variant="outlined"
                defaultValue=""
              >
                <MenuItem value="none">
                  <em>None</em>
                </MenuItem>
                {(optionDsp || [])?.map(({ name, dsp_id }) => (
                  <MenuItem key={dsp_id} value={dsp_id}>
                    {name}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Grid>
          <Grid item>
            Status
            <FormControl fullWidth variant="outlined">
              <TextField
                size="small"
                className={classes.dropdown}
                onChange={e => handleChangeFilter(e.target.value, "status")}
                value={status}
                select
                variant="outlined"
                defaultValue=""
              >
                <MenuItem value="none">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="Need Submit">Need Submit</MenuItem>
                <MenuItem value="Submited">Submited</MenuItem>
              </TextField>
            </FormControl>
          </Grid>

          <Grid item lg={12}>
            <InnoTable
              columns={columns}
              items={[]}
              isLoading={false}
              handleChangePage={handleChangePage}
              page={1}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              rowsPerPage={1}
              totalPage={10}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};
const breadcrumbData = [
  {
    label: "Home",
    link: "/admin/dashboard",
  },
  {
    label: "Submit DSP",
    active: true,
  },
];

export default SubmitDSP;
