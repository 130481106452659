import { Container, Divider } from "@material-ui/core";
import { Grid } from "@mui/material";
import axios from "axios";
import {
  AutoCompleteWithSearch,
  CurrencyDisplay,
  ModalError,
  MultipleSelectInputWithTags,
  Page,
  PrimaryButton,
  SkeletonComponent,
  YearPicker,
} from "components";
import { InnoTableV2 } from "inno-ui";
import { HeaderTitle } from "layouts";
import { getTotalPage } from "lib";
import { debounce, isEqual } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { globalStyles } from "styles";
import {
  allOptionsSelected,
  arrayToCommaSeparatedString,
  fetchAndFilterOptions,
  getErrors,
  handleChangeFilterAllAfterSearch,
} from "utils";
import { hardBaseUrl } from "../../../../services/urlConstant";

const TrendComposer = () => {
  const classes = globalStyles();
  const history = useHistory();
  const location = useLocation();
  const userRole = localStorage?.getItem("role");
  const rolePublisher = userRole === "publisher";
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const urlParams = new URLSearchParams(location.search);
  const paramsPage = urlParams.get("page");
  const paramsSize = urlParams.get("size");
  const paramsDSP = urlParams.get("dsp_id");
  const paramsPublisher = urlParams.get("publisher_id");
  const paramsOriginalPublisher = urlParams.get("original_publisher_id");
  const paramsYear = urlParams.get("year");
  const [loadingPage, setLoadingPage] = useState(false);
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [queryParams, setQueryParams] = useState({
    page: Number(paramsPage) || 1,
    size: Number(paramsSize) || 10,
    dsp_id: paramsDSP || "",
    publisher_id: paramsPublisher || "",
    original_publisher_id: paramsOriginalPublisher || "",
    composer_id: "",
    year: Number(paramsYear) || moment().format("YYYY"),
  });
  const [tableTotalPage, setTableTotalPage] = useState(1);
  const [searchComposer, setSearchComposer] = useState("");
  const [optionComposer, setOptionComposer] = useState([]);
  const [optionPublisher, setOptionPublisher] = useState([]);
  const [optionOriginalPublisher, setOptionOriginalPublisher] = useState([]);
  const [selectedPublisher, setSelectedPublisher] = useState([]);
  const [selectedOriginalPublisher, setSelectedOriginalPublisher] = useState(
    []
  );
  const [optionPublisherParams, setOptionPublisherParams] = useState({
    page: 1,
    search: "",
  });
  const [
    optionOriginalPublisherParams,
    setOptionOriginalPublisherParams,
  ] = useState({
    publisher_id: "",
    page: 1,
    size: 10,
    search: "",
  });
  const prevPublisherPagination = usePrevious(optionPublisherParams.search);
  const prevOriginalPublisherPagination = usePrevious(
    optionOriginalPublisherParams.search
  );
  const prevParamsPublisher = usePrevious(queryParams?.publisher_id);
  const prevOptionOriginalPublisher = usePrevious(optionOriginalPublisher);

  const handleChangeQueryParams = (value, key) => {
    setQueryParams(currentState => ({
      ...currentState,
      ...(key !== "page" && { page: 1 }),
      [key]: value,
    }));
    handleChangePageParams(value, key);
    if (key !== "page") {
      handleChangePageParams(1, "page");
    }
  };
  const handleChangePageParams = (value, key) => {
    urlParams.set(key, value);
    history.push({ search: urlParams.toString() });
  };
  const handleChangeFilterPublisher = (event, option) => {
    const { checked } = event?.target || false;
    setSelectedPublisher(
      handleChangeFilterAllAfterSearch({
        selectedList: selectedPublisher,
        checked,
        option,
        key: "publisher_id",
        list: optionPublisher,
        handleChangeQueryParams,
        params: optionPublisherParams,
      })
    );
  };
  const handleSearchFilterPublisher = value => {
    setOptionPublisherParams(prev => ({
      ...prev,
      search: value,
      page: 1,
    }));
  };
  const handleChangeFilterOriginalPublisher = (event, option) => {
    const { checked } = event?.target || false;
    setSelectedOriginalPublisher(
      handleChangeFilterAllAfterSearch({
        selectedList: selectedOriginalPublisher,
        checked,
        option,
        key: "original_publisher_id",
        list: optionOriginalPublisher,
        handleChangeQueryParams: handleChangeQueryParams,
        params: optionPublisherParams,
      })
    );
  };
  const handleSearchFilterOriginalPublisher = value => {
    setOptionOriginalPublisherParams(prev => ({
      ...prev,
      search: value,
      page: 1,
    }));
  };

  const getOptionComposer = async () => {
    try {
      setLoadingFilter(true);
      const res = await axios.get(`${hardBaseUrl}/publisher/composer`, {
        headers,
        params: {
          page: 1,
          size: 50,
          ...(searchComposer && { search: searchComposer }),
        },
      });
      const modifiedData = res?.data?.data?.map(item => ({
        ...item,
        id: item?.composer_id,
        label: item?.sure_name,
      }));
      setOptionComposer(modifiedData);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingFilter(false);
    }
  };
  const debounceOptionComposer = useCallback(
    debounce(() => {
      getOptionComposer();
    }, 500),
    [searchComposer]
  );
  const debounceOptionPublisher = useCallback(
    debounce(() => {
      fetchAndFilterOptions({
        headers,
        endpoint: "/dashboard/options/publisher",
        params: optionPublisherParams,
        label: "name",
        key: "publisher_id",
        setLoading: setLoadingFilter,
        paramsCondition: optionPublisherParams.search,
        prevPagination: prevPublisherPagination,
        setOptions: setOptionPublisher,
        selectedOptions: selectedPublisher,
        setSelectedOptions: setSelectedPublisher,
      });
    }, 500),
    [optionPublisherParams]
  );
  const getOriginalPublisherList = () =>
    fetchAndFilterOptions({
      headers,
      endpoint: "/dashboard/options/original_publisher",
      params: optionOriginalPublisherParams,
      label: "name",
      key: "original_publisher_id",
      setLoading: setLoadingFilter,
      paramsCondition: optionOriginalPublisherParams.search,
      prevPagination: prevOriginalPublisherPagination,
      setOptions: setOptionOriginalPublisher,
      selectedOptions: selectedOriginalPublisher,
      setSelectedOptions: setSelectedOriginalPublisher,
    });
  const debounceOptionOriginalPublisher = useCallback(
    debounce(() => {
      getOriginalPublisherList();
    }, 500),
    [optionOriginalPublisherParams]
  );
  const getDataTable = async () => {
    try {
      setLoadingPage(true);
      const res = await axios.get(
        `${hardBaseUrl}/publisher/trend-of-using-composer-songs`,
        {
          headers,
          params: queryParams,
        }
      );
      const { data, meta } = res?.data;
      setDataTable(data || []);
      const pageCount = getTotalPage(meta?.total, queryParams?.size);
      setTableTotalPage(pageCount || 1);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };

  useEffect(() => {
    debounceOptionComposer();
    return () => {
      debounceOptionComposer.cancel();
    };
  }, [searchComposer, debounceOptionComposer]);
  useEffect(() => {
    debounceOptionPublisher();
    return () => {
      debounceOptionPublisher.cancel();
    };
  }, [optionPublisherParams, debounceOptionPublisher]);
  useEffect(() => {
    if (optionOriginalPublisherParams?.publisher_id) {
      if (optionOriginalPublisherParams?.search) {
        debounceOptionOriginalPublisher();
        return () => {
          debounceOptionOriginalPublisher.cancel();
        };
      } else {
        getOriginalPublisherList();
      }
    }
  }, [
    optionOriginalPublisherParams?.publisher_id,
    optionOriginalPublisherParams,
    debounceOptionOriginalPublisher,
  ]);
  useEffect(() => {
    getDataTable();
    if (queryParams?.publisher_id) {
      setOptionOriginalPublisherParams(prev => ({
        ...prev,
        publisher_id: queryParams?.publisher_id,
      }));
    }
  }, [queryParams]);
  useEffect(() => {
    if (!selectedPublisher.length) {
      setSelectedOriginalPublisher([]);
      if (
        prevParamsPublisher &&
        !isEqual(prevParamsPublisher, queryParams?.publisher_id)
      ) {
        handleChangeQueryParams("", "original_publisher_id");
      }
      return;
    }
    const isPaginationEqual = isEqual(
      prevOriginalPublisherPagination,
      optionOriginalPublisherParams?.search
    );
    if (!isPaginationEqual) return;

    if (!optionOriginalPublisherParams?.search || paramsOriginalPublisher) {
      const filteredOptions = optionOriginalPublisher.filter(
        item => item?.original_publisher_id !== "all"
      );
      const updatedSelected = selectedOriginalPublisher
        .filter(item => item?.original_publisher_id !== "all")
        .filter(item =>
          filteredOptions.some(
            option =>
              option.original_publisher_id === item.original_publisher_id
          )
        );

      const selectedId = paramsOriginalPublisher
        ?.split(",")
        ?.map(id => id.trim());
      const selected = filteredOptions.filter(option =>
        selectedId?.includes(option.original_publisher_id.toString())
      );
      const allSelected = allOptionsSelected({
        list: filteredOptions,
        key: "original_publisher_id",
        selectedFilter: paramsOriginalPublisher
          ? selected
          : selectedOriginalPublisher,
      });
      if (
        prevOptionOriginalPublisher &&
        !isEqual(prevOptionOriginalPublisher, optionOriginalPublisher)
      ) {
        handleChangeQueryParams(
          arrayToCommaSeparatedString(updatedSelected, "original_publisher_id")
        );
      }
      setSelectedOriginalPublisher(
        paramsOriginalPublisher
          ? allSelected
            ? optionOriginalPublisher
            : selected
          : allSelected
          ? optionOriginalPublisher
          : updatedSelected
      );
    }
  }, [
    selectedPublisher,
    optionOriginalPublisherParams,
    optionOriginalPublisher,
  ]);
  useEffect(() => {
    if (optionPublisher && optionPublisher.length > 0 && paramsPublisher) {
      const selectedId = paramsPublisher?.split(",")?.map(id => id.trim());
      const selected = optionPublisher.filter(option =>
        selectedId.includes(option.publisher_id.toString())
      );
      setSelectedPublisher(
        allOptionsSelected({
          list: optionPublisher,
          key: "publisher_id",
          selectedFilter: selected,
        })
          ? optionPublisher
          : selected
      );
    }
  }, [optionPublisher]);

  return (
    <Page className={classes.root} title="Composer Trend">
      {loadingPage ? (
        <SkeletonComponent variant="wave" />
      ) : (
        <Container maxWidth={false}>
          <HeaderTitle
            title="Trend Of Using Compser Songs"
            breadcrumbData={breadcrumbData}
            backButton
          />
          <Divider className={classes.divider} />
          <Grid
            container
            justifyContent="right"
            alignItems="center"
            spacing={1}
            my="16px"
          >
            <Grid item>
              <AutoCompleteWithSearch
                label="Composer/Author"
                options={optionComposer}
                optionLabel="sure_name"
                loading={loadingFilter}
                inputValue={searchComposer}
                onInputChange={setSearchComposer}
                value={
                  optionComposer?.find(
                    option => option?.composer_id === queryParams?.composer_id
                  ) || null
                }
                onChange={option =>
                  handleChangeQueryParams(option?.composer_id, "composer_id")
                }
                width={255}
              />
            </Grid>
            <Grid item>
              <MultipleSelectInputWithTags
                value={selectedPublisher}
                textValue="Publisher"
                options={optionPublisher}
                optionKey="publisher_id"
                optionLabel="name"
                onChange={handleChangeFilterPublisher}
                loading={loadingFilter}
                disabled={loadingFilter}
                search
                onChangeSearch={handleSearchFilterPublisher}
                searchValue={optionPublisherParams?.search}
                group={rolePublisher}
                width={255}
              />
            </Grid>
            <Grid item>
              <MultipleSelectInputWithTags
                value={selectedOriginalPublisher}
                textValue="Original Publisher"
                options={optionOriginalPublisher}
                optionKey="original_publisher_id"
                optionLabel="name"
                onChange={handleChangeFilterOriginalPublisher}
                loading={loadingFilter}
                search
                onChangeSearch={handleSearchFilterOriginalPublisher}
                searchValue={optionOriginalPublisherParams?.search}
                disabled={selectedPublisher?.length === 0 || loadingFilter}
                width={255}
              />
            </Grid>
            <Grid item>
              <YearPicker
                value={queryParams?.year}
                onChange={year => handleChangeQueryParams(year, "year")}
                width={200}
              />
            </Grid>
            <Grid item>
              <CSVLink data={dataTable} filename="Trend of Using Compser Songs">
                <PrimaryButton label="Download Report" />
              </CSVLink>
            </Grid>
          </Grid>
          <InnoTableV2
            isLoading={false}
            columns={columnTable}
            items={dataTable}
            page={queryParams?.page}
            rowsPerPage={queryParams?.size}
            totalPage={tableTotalPage}
            handleChangePage={(_, page) =>
              handleChangeQueryParams(page, "page")
            }
            handleChangeRowsPerPage={e =>
              handleChangeQueryParams(e?.target?.value, "page")
            }
            isHaveAction
            handleView={item =>
              history.push(
                `/admin/review-lagu?dsp_id=${queryParams?.dsp_id}&publisher_id=${queryParams?.publisher_id}&original_publisher_id=${queryParams?.original_publisher_id}&year=${queryParams?.year}&composer_id=${item?.composer_id}`
              )
            }
          />
        </Container>
      )}
    </Page>
  );
};

const usePrevious = value => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};
const breadcrumbData = [
  {
    label: "Home",
    link: "/admin/dashboard",
  },
  {
    label: "Dashboard",
    link: "/admin/dashboard",
  },
  {
    label: "Trend Of Using Compser Songs",
    active: true,
  },
];
const columnTable = [
  {
    name: "composer_name",
    title: " Composer/Author",
  },
  {
    name: "listener",
    title: "Traffic",
    headerAlign: "right",
    renderText: item => <CurrencyDisplay value={item} />,
  },
  {
    name: "royalty",
    title: "Revenue",
    headerAlign: "right",
    renderText: item => <CurrencyDisplay value={item} />,
  },
  {
    name: "advance",
    title: "Advance Composer/Author",
    headerAlign: "right",
    renderText: item => <CurrencyDisplay value={item} />,
  },
  {
    name: "balance",
    title: "Balance",
    headerAlign: "right",
    renderText: item => <CurrencyDisplay value={item} />,
  },
];
export default TrendComposer;
