import { Button, styled } from "@mui/material";
import { getCookie } from "utils";
import { cloneElement } from "react";

const SecondaryButton = ({
  label,
  onClick,
  startIcon,
  endIcon,
  disabled,
  width,
  size,
  color,
  borderColor,
  textColor,
  children,
}) => {
  const userLogin = getCookie("auth", `${process.env.REACT_APP_NAME}_user`);
  const adjustedIcon = icon =>
    cloneElement(icon, {
      style: {
        fontSize: size,
        color: color || userLogin?.publisher?.theme_color,
      },
    });
  return (
    <CustomButton
      variant="outlined"
      onClick={onClick}
      disabled={disabled}
      customWidth={width}
      customColor={color}
      borderColor={borderColor}
      textColor={textColor}
      startIcon={startIcon && adjustedIcon(startIcon)}
      endIcon={endIcon && adjustedIcon(endIcon)}
      size={size}
    >
      {label || children}
    </CustomButton>
  );
};

const CustomButton = styled(Button)(
  ({ customWidth, borderColor, textColor }) => ({
    textTransform: "none",
    whiteSpace: "nowrap",
    width: customWidth,
    minWidth: 80,
    border: `1px solid ${borderColor || "#111827"}`,
    color: textColor || "#111827",
    "&.MuiButton-root.Mui-disabled": {
      border: "1px solid #D1D5DC",
      color: "#D1D5DC",
    },
    "&:hover": {
      border: `1px solid ${borderColor || "#111827"}`,
      color: textColor || "#111827",
    },
  })
);

export default SecondaryButton;
