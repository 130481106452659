import {
  Container,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import { useEffect, useState } from "react";
import { MonthYearPicker, Page, PrimaryButton } from "components";
import Results from "./Results";
import moment from "moment";
import { hardBaseUrl } from "../../../../services/urlConstant";
import { Divider, Grid, styled, Typography } from "@mui/material";
import { HeaderTitle } from "layouts";

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  pageSize: {
    height: "30px",
    borderRadius: "6px",
    border: "1px solid #D1D5DC",
    paddingLeft: "5px",
    paddingRight: "5px",
    marginTop: "5px",
  },
}));

const PaymentMutation = () => {
  const classes = useStyles();
  const [filterDate, setFilterDate] = useState(moment());
  const [response, setResponse] = useState([]);
  const [pageTotal, setPageTotal] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);

  const getResponse = () => {
    const token = localStorage.getItem("token");
    if (!filterDate) {
      setResponse([]);
      setPageTotal(0);
      setPage(1);
    } else {
      const url = `${hardBaseUrl}/publisher/transaction/payment/mutation?month=${filterDate.format(
        "MM"
      )}&year=${filterDate.format(
        "YYYY"
      )}&composer=&page=${page}&per_page=${rowsPerPage}`;
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then(res => {
          setResponse(res.data.data.data);
          setPageTotal(res.data.data.total_page);
          setPage(res.data.data.page);
        })
        .catch(err => {
          new Error(err);
        });
    }
  };
  useEffect(() => {
    const getResponseReset = () => {
      const token = localStorage.getItem("token");
      if (!filterDate) {
        setResponse([]);
        setPageTotal(0);
        setPage(1);
      } else {
        const url = `${hardBaseUrl}/publisher/transaction/payment/mutation?month=${filterDate.format(
          "MM"
        )}&year=${filterDate.format(
          "YYYY"
        )}&composer=&page=1&per_page=${rowsPerPage}`;
        axios
          .get(url, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          })
          .then(res => {
            setResponse(res.data.data.data);
            setPageTotal(res.data.data.total_page);
            setPage(res.data.data.page);
          })
          .catch(err => {
            new Error(err);
          });
      }
    };
    getResponseReset();
  }, [filterDate]);
  const handleChangePage = (_, data) => {
    setPage(data);
  };
  useEffect(() => {
    getResponse();
  }, [rowsPerPage, page]);
  const handleChangeRowsPerPage = event => {
    event.preventDefault();
    setRowsPerPage(event.target.value);

    setPage(1);
  };
  const pageSizePagination = () => {
    return (
      <div className={classes.pageSize}>
        <select
          style={{
            backgroundColor: "transparent",
            border: "none",
            outline: "none",
            marginTop: "4px",
          }}
          value={rowsPerPage}
          onChange={handleChangeRowsPerPage}
        >
          {[10, 20, 50].map(pageSize => {
            return (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            );
          })}
        </select>
      </div>
    );
  };
  const handleDownloadExcel = () => {
    const token = localStorage.getItem("token");
    const url = `${hardBaseUrl}/download/balance_composer?month=${filterDate.format(
      "MM"
    )}&year=${filterDate.format("YYYY")}`;
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        responseType: "blob",
      })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `balance-mutation-${filterDate.format("MMMM")}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch(err => {
        new Error(err);
      });
  };

  return (
    <Page className={classes.root} title="Balance Mutation">
      <Container maxWidth={false}>
        <HeaderTitle
          title="Payment Transaction"
          breadcrumbData={breadcrumbData}
        />
        <Divider
          sx={{
            my: 2,
            borderTop: "1px solid #e1e1e1",
          }}
        />

        <Grid container justifyContent="space-between">
          <Grid item>
            <TableTitle>Balance Mutation</TableTitle>
            <Typography variant="body2" mb={2}>
              Feature for showing balance mutation in payment transaction
            </Typography>
          </Grid>
          <Grid item>
            <Grid container columnSpacing={1}>
              <Grid item>
                <MonthYearPicker
                  label="Filter Month"
                  value={filterDate}
                  onChange={date => setFilterDate(date)}
                />
              </Grid>
              <Grid item>
                <PrimaryButton
                  label="Download As Excel"
                  onClick={handleDownloadExcel}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Results
          items={response}
          handleChangePage={handleChangePage}
          totalData={pageTotal}
          page={page}
          pageSizePagination={pageSizePagination()}
        />
      </Container>
    </Page>
  );
};

const TableTitle = styled(Typography)(() => ({
  fontSize: 18,
  fontWeight: 600,
}));
const breadcrumbData = [
  {
    label: "Home",
    link: "/admin/dashboard",
  },
  {
    label: "Transaction",
    link: "/admin/payment",
  },
  {
    label: "Payment",
    link: "/admin/payment",
  },
  {
    label: "Balance Mutation",
    active: true,
  },
];
export default PaymentMutation;
