import Swal from "sweetalert2";

const ModalSuccess = (message, title, props) =>
  Swal.fire({
    title: title ? title : "Success",
    text: message ? message : "Data has been saved",
    icon: "success",
    confirmButtonText: "OK",
    ...props
  });

export default ModalSuccess;
