import {
  Card,
  CardActions,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link as RouterLink } from "react-router-dom";
import updateIcon from "../../../../assets/img/pencilVector.svg";
import { ButtonWithModalPerItem } from "../../../../components/atoms/Button";
import TableEditBar from "../../../../components/molecules/Table/TableEditBar";

const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0,
    border: "1px solid #e0e0e0",
  },
  inner: {
    minWidth: 700,
  },
  nameCell: {
    display: "flex",
    alignItems: "center",
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1),
  },
  actions: {
    padding: theme.spacing(1),
    justifyContent: "flex-end",
  },
  mypadding: {
    "& .MuiSelect-outlined": {
      paddingTop: "14px",
      paddingBottom: "14px",
      paddingLeft: "20px",
      paddingRight: "30px",
    },
  },
  headText: {
    fontSize: "12px",
    color: "#687083",
    fontWeight: "bold",
  },
  bodyTextBlack: {
    fontSize: "14px",
    fontWeight: "regular",
    color: "#111827",
    fontStyle: "normal",
  },
  bodyTextGrey: {
    fontSize: "14px",
    fontWeight: "regular",
    color: "#687083",
    fontStyle: "normal",
  },
  tableRow: {
    height: 20,
  },
  conTextBlack: {
    fontSize: "14px",
    fontWeight: "bold",
  },
  dropdown: {
    color: "black",
  },
  subtitle: {
    fontSize: "18px",
    fontWeight: "bold",
    lineHeight: "28px",
  },
  btnEdit: {
    backgroundColor: "#111827",
    color: "white",
    width: "30px",
    height: "30px",
    padding: "6px",
    borderRadius: "6px",
  },
  btnDelete: {
    backgroundColor: "#111827",
    color: "white",
    width: "35px",
    height: "35px",
    padding: "6px",
    borderRadius: "6px",
    marginLeft: "10px",
    cursor: "pointer",
  },
  pagination: {
    "& .Mui-selected": {
      backgroundColor: "black",
      color: "white",
    },
  },
  pageSize: {
    height: "30px",
    borderRadius: "6px",
    border: "1px solid #D1D5DC",
    paddingLeft: "5px",
    paddingRight: "5px",
    marginTop: "5px",
  },
  paginationText: {
    fontSize: "14px",
    fontWeight: "normal",
    fontStyle: "normal",
  },
}));

function Results({
  className,
  handleChangePage,
  handleChangeRowsPerPage,
  pageSizePagination,
  handleDelete,
  items,
  page,
  onChangePage,
  pagesCount,
  totalData,
  handleEditMode,
  ...rest
}) {
  const classes = useStyles();

  const selectedCustomers = [];

  const truncate = (str, n) => {
    return str?.length > n ? str.substr(0, n - 1) + "..." : str;
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <>
        <div className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Card>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <ThemeProvider theme={theme}>
                        <TableCell style={{ width: "20px" }}>
                          <Typography
                            variant="subtitle2"
                            className={classes.headText}
                          >
                            Action
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={classes.headText}
                          >
                            Code
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={classes.headText}
                          >
                            Description
                          </Typography>
                        </TableCell>
                      </ThemeProvider>
                      {}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {items.map((customer, i) => (
                      <TableRow hover key={`${customer.tax_id}`}>
                        <ThemeProvider theme={theme}>
                          <TableCell align="right">
                            <>
                              <div style={{ display: "flex" }}>
                                <Tooltip title={"Update"} placement="bottom">
                                  <IconButton
                                    className={classes.btnEdit}
                                    style={{
                                      backgroundColor: "black",
                                    }}
                                  >
                                    <RouterLink
                                      to={{
                                        pathname: `/admin/master/currency/edit/${customer.id}`,
                                        state: {
                                          customer: customer,
                                        },
                                      }}
                                    >
                                      <img
                                        src={updateIcon}
                                        alt="update-icon"
                                        style={{
                                          filter: " brightness(0) invert(1)",
                                        }}
                                      />
                                    </RouterLink>
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title={"Delete"} placement="bottom">
                                  <ButtonWithModalPerItem
                                    item={customer}
                                    title={"Remove Tax"}
                                    dialogTitle={"Remove Tax"}
                                    subTitle={
                                      "Are you sure you want to remove this tax?"
                                    }
                                    handleDelete={() => {
                                      handleDelete(customer.id);
                                    }}
                                  />
                                </Tooltip>
                              </div>
                            </>
                          </TableCell>
                          <TableCell component="th" scope="row" width={220}>
                            <Typography
                              variant="subtitle2"
                              className={classes.bodyTextBlack}
                            >
                              <>
                                <span>{customer.code}</span>
                              </>
                            </Typography>
                          </TableCell>

                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={classes.bodyTextBlack}
                            >
                              {truncate(customer.description, 50)}
                            </Typography>
                          </TableCell>
                        </ThemeProvider>
                        {}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <CardActions className={classes.actions}>
                  <Grid
                    alignContent="space-between"
                    container
                    justifyContent="space-between"
                    spacing={3}
                  >
                    <Grid item>
                      <div style={{ display: "flex" }}>
                        <div style={{ marginTop: "7px" }}>
                          <ThemeProvider theme={theme}>
                            <Typography className={classes.paginationText}>
                              Item per page:
                            </Typography>
                          </ThemeProvider>
                        </div>{" "}
                        <div style={{ marginLeft: "10px" }}>
                          {pageSizePagination}
                        </div>
                      </div>
                    </Grid>

                    <Grid item>
                      <Pagination
                        count={totalData}
                        shape="rounded"
                        className={classes.pagination}
                        page={page}
                        onChange={handleChangePage}
                      />
                    </Grid>
                  </Grid>
                </CardActions>
              </Card>
            </div>
          </PerfectScrollbar>
        </div>
      </>
      <TableEditBar selected={selectedCustomers} />
    </div>
  );
}

Results.propTypes = {
  className: PropTypes.string,
  customers: PropTypes.array,
};

Results.defaultProps = {
  customers: [],
};

export default Results;
