import {
  Container,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  Snackbar,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import { InnoTableV2 } from "inno-ui";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import PencilVector from "../../../../assets/img/pencilVector.svg";
import RefreshVector from "../../../../assets/img/refresh-cw.svg";
import {
  ButtonWithModalPerItem,
  PrimaryButton,
} from "../../../../components/atoms/Button";
import { ButtonGroupTop } from "../../../../components/atoms/Button";
import HeaderPage from "../../../../layouts/Header/HeaderPage";
import { Page, SkeletonComponent } from "components";
import { hardBaseUrl } from "../../../../services/urlConstant";
import { emptyText } from "../../../../utils";
import DialogEditUserPencipta from "./DialogEditUserPencipta";
import DialogTambahUserPencipta from "./DialogTambahUserPencipta";


const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  formControl: {
    width: 180,
  },
  results: {
    marginTop: theme.spacing(3),
  },
  content: {
    marginTop: "20px",
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  pageSize: {
    height: "30px",
    borderRadius: "6px",
    border: "1px solid #D1D5DC",
    paddingLeft: "5px",
    paddingRight: "5px",
    marginTop: "5px",
  },
  wrapperFilter: {
    marginBottom: 20,
  },
  inputFields: {
    maxWidth: 268,
    "& .MuiOutlinedInput-input": {
      padding: "0px",
      height: "44px",
    },
  },
  selectPadding: {
    "& .MuiSelect-outlined": {
      paddingTop: "14px",
      paddingBottom: "14px",
      paddingLeft: "20px",
      paddingRight: "30px",
    },
  },
  buttonAdd: {
    backgroundColor: "black",
    color: "white",
    paddingTop: "15px",
    paddingBottom: "15px",
    width: "auto",
    height: "44px",
  },
  textAdd: {
    fontSize: "14px",
    fontWeight: "400",
    fontStyle: "normal",
    textTransform: "none",
    lineHeight: "24px",
  },
  btnnEdit: {
    padding: "4px",
    color: "white",
    backgroundColor: "black",
    borderRadius: "6px",
    "&:focus": {
      color: "white",
      backgroundColor: "black",
    },
    "&:active": {
      boxShadow: "none",
      color: "white",
      backgroundColor: "black",
    },
    "&:hover": {
      color: "white",
      backgroundColor: "black",
      boxShadow: "none",
    },
    "&:hover, &.MuiIconButton": {
      background: "#374151 !important",
    },
  },
  btnRefresh: {
    padding: "4px",
    color: "white",
    marginLeft: "8px",
    backgroundColor: "black",
    borderRadius: "6px",
    "&:focus": {
      color: "white",
      backgroundColor: "black",
    },
    "&:active": {
      boxShadow: "none",
      color: "white",
      backgroundColor: "black",
    },
    "&:hover": {
      color: "white",
      backgroundColor: "black",

      boxShadow: "none",
    },
    "&:hover, &.MuiIconButton": {
      background: "#374151 !important",
    },
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function UserPencipta() {
  let theToken = localStorage.getItem("token");
  const classes = useStyles();
  const [response, setResponse] = useState([]);
  const [page, setPage] = useState(1);
  const [searchKey, setSearchKey] = useState("");
  const [type, setType] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalData, setTotalData] = useState(1);
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [name, setName] = useState("");
  const [role, setRole] = useState(null);
  const [publisher, setPublisher] = useState(null);
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confrimPassword, setConfrimPassword] = useState("");

  const [id, setId] = useState();
  const [roleList, setRoleList] = useState([]);
  const [publisherList, setPublisherList] = useState([]);
  const [openSnack, setOpenSnack] = useState(false);
  const [typeSnackbar] = useState("success");
  const [message] = useState("This is a message!");
  const [loadingTable, setLoadingTable] = useState(false);

  const [pencipta, setPencipta] = useState(null);
  const [penciptaList, setPenciptaList] = useState([]);

  const handleOpen = () => {
    setOpen(true);
    setName("");
    setRole(null);
    setPublisher(null);
    setPhone("");
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenEdit = () => {
    setOpenEdit(true);
  };
  const handleCloseEdit = () => {
    setOpenEdit(false);
    setName("");
    setRole(null);
    setPublisher(null);
    setPhone("");
    setEmail("");
    setPencipta(null);
  };

  const pagesCount = Math.ceil(totalData / rowsPerPage);

  const handleCloseSnackbar = () => {
    setOpenSnack(false);
  };

  const getResponse = () => {
    setLoadingTable(true);
    const url = `${hardBaseUrl}/user?type=composer&page=${page}&size=${rowsPerPage}&search=${searchKey}&role_id=${type}`;
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${theToken}`,
        },
      })
      .then(res => {
        setResponse(res.data.data);
        setTotalData(res.data.meta.total);
        setPage(res.data.meta.page);
        setLoadingTable(false);
      })
      .catch(() => {
        setLoadingTable(false);
      });
  };

  const handleChangeSelect = event => {
    setType(event.target.value);
    setPage(1);
  };

  const handleChangeRowsPerPage = event => {
    event.preventDefault();
    setRowsPerPage(event.target.value);

    setPage(1);
  };

  const handleChange = event => {
    const name = event.target.name;
    const value = event.target.value;
    if (name === "name") {
      setName(value);
    } else if (name === "role") {
      setRole(value);
    } else if (name === "pencipta") {
      setPencipta(value);
    } else if (name === "phone") {
      setPhone(value);
    } else if (name === "email") {
      setEmail(value);
    } else if (name === "password") {
      setPassword(value);
    } else if (name === "konfirmasiPassword") {
      setConfrimPassword(value);
    }
  };

  const handleChangeSelectRole = e => {
    setRole(e.target.value);
  };

  const handleChangeSelectPublisher = e => {
    setPublisher(e.target.value);
  };

  const handleChangeSelectPencipta = e => {
    setPencipta(e.target.value);
  };
  const notifError = (text, confrimed = () => setOpen(true)) =>
    Swal.fire({
      title: "Oops…",
      icon: "error",
      text: text,
      showCancelButton: false,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK",
    }).then(result => {
      if (result.isConfirmed) {
        confrimed();
      }
    });
  const regexEmail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  useEffect(() => {
    if (!open) {
      setPassword("");
    }
  }, [open]);
  const onSubmitAdd = e => {
    e.preventDefault();
    setOpen(false);

    if (name === "") {
      return notifError("Username can't be empty");
    }
    if (pencipta === null) {
      return notifError("Composer can't be empty");
    }
    if (role === null) {
      return notifError("Role can't be empty");
    }
    if (password === "") {
      return notifError("Password can't be empty");
    }
    if (confrimPassword === "") {
      return notifError("Confirm password can't be empty");
    }
    if (email === "") {
      return notifError("Email address can't be empty");
    }
    if (!regexEmail.test(email)) {
      return notifError(
        "Please enter your email address in format: yourname@example.com"
      );
    }
    if (password !== confrimPassword) {
      return notifError("The confirmation password does not match");
    }
    if (
      name !== "" &&
      email !== "" &&
      password !== "" &&
      role !== null &&
      confrimPassword &&
      password === confrimPassword &&
      pencipta !== null
    ) {
      const url = `${hardBaseUrl}/user`;
      const data = {
        name: name,
        email: email,
        phone: phone,
        role_id: role,
        composer_id: pencipta,
        password: password,
        publisher_id: null,
      };
      axios
        .post(url, data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${theToken}`,
          },
        })
        .then(res => {
          if (
            res.data.message === "success" &&
            res.data.meta.http_status === 200
          ) {
            Swal.fire({
              icon: "success",
              title: "Berhasil",
              text: "Data added successfully",
            });

            setOpen(false);
          }
          getResponse();
        })
        .catch(err => {
          if (err.response.data.errors.length) {
            notifError(err.response.data.errors[0]?.message);
          }
        });
    }
  };

  const handleEditMode = id => {
    setId(id);
    const url = `${hardBaseUrl}/user/${id}`;
    let theToken = localStorage.getItem("token");
    handleOpenEdit();
    axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${theToken}`,
        },
      })
      .then(res => {
        setName(res.data.data.name);
        setPencipta(res.data.data.composer_id);
        setRole(res.data.data.role_id);
        setPhone(res.data.data.phone);
        setEmail(res.data.data.email);
      })
      .catch(error => {
        new Error(error);
      });
  };

  const handleDelete = id => {
    const url = `${hardBaseUrl}/user/${id}`;

    let theToken = localStorage.getItem("token");
    axios
      .delete(url, {
        headers: {
          Authorization: `Bearer ${theToken}`,
        },
      })
      .then(res => {
        if (
          res.data.message === "success" &&
          res.data.meta.http_status === 200
        ) {
          Swal.fire({
            icon: "success",
            title: "Deleted",
            text: "Data deleted successfully",
          }).then(result => {
            if (result.isConfirmed === true) {
              getResponse();
            }
          });
        }
      })
      .catch(err => {
        new Error(err);
      });
  };

  const handleRecovery = id => {
    const url = `${hardBaseUrl}/user-recovery/${id}`;

    let theToken = localStorage.getItem("token");
    const formData = new FormData();
    axios
      .put(url, formData, {
        headers: {
          Authorization: `Bearer ${theToken}`,
        },
      })
      .then(res => {
        if (
          res.data.message === "success" &&
          res.data.meta.http_status === 200
        ) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "data recovery successfully",
          }).then(result => {
            if (result.isConfirmed === true) {
              getResponse();
            }
          });
        }
      })
      .catch(err => {
        new Error(err);
      });
  };

  const handleChangePage = (event, data) => {
    setPage(data);
  };

  const handleUpdate = e => {
    e.preventDefault();

    setOpenEdit(false);

    if (name === "") {
      return notifError("Username can't be empty", () => setOpenEdit(true));
    }
    if (pencipta === null) {
      return notifError("Composer can't be empty", () => setOpenEdit(true));
    }
    if (role === null) {
      return notifError("Role can't be empty", () => setOpenEdit(true));
    }
    if (email === "") {
      return notifError("Email address can't be empty", () =>
        setOpenEdit(true)
      );
    }
    if (!regexEmail.test(email)) {
      return notifError(
        "Please enter your email address in format: yourname@example.com",
        () => setOpenEdit(true)
      );
    }

    if (name !== "" && email !== "" && role !== "" && publisher !== "") {
      const url = `${hardBaseUrl}/user/${id}`;

      const data = {
        name: name,
        email: email,
        phone: phone,
        role_id: role,
        publisher_id: publisher,
        composer_id: pencipta,
      };

      axios
        .put(url, data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${theToken}`,
          },
        })
        .then(res => {
          if (
            res.data.message === "success" &&
            res.data.meta.http_status === 202
          ) {
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "Data changed successfully",
            }).then(result => {
              if (result.isConfirmed === true) {
                getResponse();
              }
            });
          }
        })
        .catch(err => {
          if (err.response.data) {
            notifError(err?.response?.data?.errors[0]?.message, () =>
              setOpenEdit(true)
            );
          }
        });
    }
  };
  const handleChangeSearch = event => {
    setPage(1);
    setSearchKey(event.target.value);
  };

  const getRoleList = () => {
    const url = `${hardBaseUrl}/role?type=composer`;
    let theToken = localStorage.getItem("token");
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${theToken}`,
        },
      })
      .then(res => {
        setRoleList(res.data.data);
      })
      .catch(err => {
        new Error(err);
      });
  };
  const getPenciptaList = () => {
    const url = `${hardBaseUrl}/composer-option`;
    let theToken = localStorage.getItem("token");
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${theToken}`,
        },
      })
      .then(res => {
        setPenciptaList(res.data.data);
      })
      .catch(err => {
        new Error(err);
      });
  };

  const getPublisherList = () => {
    const url = `${hardBaseUrl}/publisher`;
    let theToken = localStorage.getItem("token");

    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${theToken}`,
        },
      })
      .then(res => {
        setPublisherList(res.data.data);
      })
      .catch(err => {
        new Error(err);
      });
  };

  useEffect(() => {
    getResponse();
  }, [rowsPerPage, page, searchKey, type]);

  useEffect(() => {
    getRoleList();
    getPublisherList();
    getPenciptaList();
  }, []);

  const customAction = item => {
    return (
      <>
        <IconButton
          classes={{
            root: classes.btnnEdit,
          }}
          onClick={() => {
            console.log({ item });
            handleEditMode(item?.user_id);
          }}
        >
          <img alt="edit-btn" src={PencilVector} />
        </IconButton>
        {item?.is_active_flag ? (
          <ButtonWithModalPerItem
            item={item}
            title={"Delete User Publisher"}
            dialogTitle={"Delete User Publisher"}
            subTitle={"Are you sure you want to delete this data"}
            handleDelete={() => {
              handleDelete(item?.user_id);
            }}
          />
        ) : (
          <IconButton
            classes={{
              root: classes.btnRefresh,
            }}
            style={{
              backgroundColor: " #111827",
            }}
            onClick={() => {
              handleRecovery(item?.user_id);
            }}
          >
            <img alt="delete-btn" src={RefreshVector} />
          </IconButton>
        )}
      </>
    );
  };
  const columnTable = [
    {
      name: "all",
      title: "Action",
      renderText: item => customAction(item),
    },
    {
      name: "name",
      title: "User Name",
    },
    {
      name: "all",
      title: "Role",
      renderText: item => emptyText(item?.role?.name),
    },
    {
      name: "phone",
      title: "Phone Number",
    },
    {
      name: "email",
      title: "Email",
    },
  ];
  return (
    <Page className={classes.root} title={"Composer User"}>
      <Snackbar
        open={openSnack}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleCloseSnackbar} severity={typeSnackbar}>
          {message}
        </Alert>
      </Snackbar>
      {loadingTable ? (
        <SkeletonComponent variant="wave" />
      ) : (
        <Container maxWidth={false}>
          <HeaderPage
            title="Parameter"
            breadcrumbs={["Parameter", "User Composer"]}
          />
          <ButtonGroupTop />
          <Divider className={classes.divider} />
          <Grid container className={classes.wrapperFilter} spacing={1}>
            <Grid item xs={12} md={6} sm={4} lg={7} xl={7}>
              <FormControl variant="outlined">
                <OutlinedInput
                  id="outlined-adornment-amount"
                  className={classes.inputFields}
                  value={searchKey}
                  onChange={event => handleChangeSearch(event)}
                  placeholder="Search"
                  startAdornment={
                    <InputAdornment position="start">
                      <img
                        alt="Logo"
                        src={
                          require("assets/image-public/images/search.svg")
                            .default
                        }
                      />
                    </InputAdornment>
                  }
                  fullWidth={false}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} sm={8} lg={5} xl={5}>
              <Grid container justifyContent="flex-end" spacing={2}>
                <Grid item>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <Select
                      displayEmpty={true}
                      className={classes.selectPadding}
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={type}
                      defaultValue={""}
                      onChange={handleChangeSelect}
                    >
                      <MenuItem value={""}>All Role</MenuItem>
                      {roleList.map(item => {
                        return (
                          <MenuItem key={item.role_id} value={item.role_id}>
                            {item.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item>
                  <PrimaryButton
                    onClick={handleOpen}
                    label="Add User Composer"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <InnoTableV2
            columns={columnTable}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            idColumnName={"performer_id"}
            isLoading={loadingTable}
            isUsingCheckbox={false}
            items={response}
            loadingColor={""}
            page={page}
            rowsPerPage={rowsPerPage}
            totalPage={pagesCount}
          />

          <DialogTambahUserPencipta
            open={open}
            onClose={handleClose}
            role={role}
            composer={pencipta}
            handleChangeSelectRole={handleChangeSelectRole}
            handleChange={handleChange}
            onSubmit={onSubmitAdd}
            publisher={publisher}
            handleChangeSelectPencipta={handleChangeSelectPencipta}
            penciptaItems={penciptaList}
            roleItems={roleList}
            name={name}
            phone={phone}
            email={email}
          />

          <DialogEditUserPencipta
            open={openEdit}
            onClose={handleCloseEdit}
            role={role}
            handleChangeSelectRole={handleChangeSelectRole}
            handleChange={handleChange}
            publisher={publisher}
            handleChangeSelectPublisher={handleChangeSelectPublisher}
            name={name}
            phone={phone}
            onSubmit={handleUpdate}
            email={email}
            publisherItems={publisherList}
            roleItems={roleList}
            composer={pencipta}
            penciptaItems={penciptaList}
            handleChangeSelectPencipta={handleChangeSelectPencipta}
          />
        </Container>
      )}
    </Page>
  );
}

export default UserPencipta;
