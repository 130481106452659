import {
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { useRef } from "react";
import { Line } from "react-chartjs-2";
import { useLocation } from "react-router-dom";
import { Page } from "components";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  card: {
    borderLeft: "1px solid gray",
    boxShadow: "none",
    borderRadius: "0px",
  },
  insideCard: {
    padding: "20px",

    wordWrap: "break-word",
  },
  cardArea: {
    marginTop: "20px",
    marginBottom: "20px",
    borderRadius: "0px",
  },
  publisherMargin: {
    marginTop: "40px",
  },
  infoArea: {
    marginTop: "20px",
    marginBottom: "60px",
  },
  label: {
    fontSize: "16px",
    fontWeight: 500,
    color: "#364052",
  },
  labelValue: {
    fontSize: "16px",
    fontWeight: 400,
    color: "#111827",
  },
  headText: {
    fontSize: "12px",
    color: "#687083",
    fontWeight: "bold",
  },
  bodyTextBlack: {
    fontSize: "14px",
    fontWeight: "regular",
    color: "#111827",
    fontStyle: "normal",
  },
  bodyTextGrey: {
    fontSize: "14px",
    fontWeight: "regular",
    color: "#687083",
    fontStyle: "normal",
  },
  tableRow: {
    height: 20,
  },
  conTextBlack: {
    fontSize: "14px",
    fontWeight: "bold",
  },
  dropdown: {
    color: "black",
  },
  subtitle: {
    fontSize: "18px",
    fontWeight: "bold",
    lineHeight: "28px",
  },
  reportText: {
    fontSize: "14px",
    fontWeight: 500,
    color: "#687083",
    marginBottom: "15px",
    width: "300px",
  },
  reportValue: {
    fontSize: "24px",
    fontWeight: 500,
    color: "#111827",
  },
  breadcrumbs: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#111827",
  },
  breadCrumbsActive: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#687083",
  },
  table: {
    marginTop: "10px",
    border: "1px solid #D1D5DC",
    borderRadius: "6px",
  },
  thead: {
    "& th:first-child": {
      borderRadius: "6px",
    },
    "& th:last-child": {
      borderRadius: "6px",
    },
  },
}));

const PrintReportDetailReview = () => {
  const { state } = useLocation();
  const classes = useStyles();
  const inputRef = useRef(null);

  const monthNames = {
    "01": "January",
    "02": "February",
    "03": "March",
    "04": "April",
    "05": "May",
    "06": "June",
    "07": "July",
    "08": "August",
    "09": "September",
    "10": "October",
    "11": "November",
    "12": "December",
  };

  const getMonthName = data => {
    const monthList = data.split("-")[1];

    return monthNames[monthList] || "January";
  };

  const getYearList = data => {
    const year = data.split("-")[0];
    const year2 = year.slice(-4);

    return ` ${year2}`;
  };

  const formatter = new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 0,
  });

  const formatter2 = new Intl.NumberFormat("id-ID", {
    minimumFractionDigits: 0,
  });

  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    responsive: true,

    interaction: {
      mode: "index",
      intersect: false,
    },
    hover: {
      mode: "nearest",
      intersect: true,
    },

    plugins: {
      layout: {},
      legend: {
        display: false,
      },
      title: {
        display: false,
        text: "Chart.js Line Chart",
      },
      subtitle: {
        display: true,
        text: "Custom Chart Subtitle",
      },

      tooltip: {
        events: ["click", "mouseout"],
        enabled: false,
      },
    },
  };

  const handlePrintPDF = () => {
    html2canvas(inputRef.current).then(canvas => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "px", "a4");
      pdf.height = "600";
      pdf.width = "300";
      pdf.addImage(imgData, "PNG", 30, 30, 400, 450);
      pdf.save("download.pdf");
    });
  };
  return (
    <div>
      <Page className={classes.root} title="Review Penggunaan Lagu">
        <div ref={inputRef}>
          <Container maxWidth={false}>
            <Grid
              alignItems="flex-end"
              container
              justifyContent="space-between"
              spacing={3}
            >
              <Grid item>
                <ThemeProvider theme={theme}>
                  <Typography variant="h5">
                    Song Title:{" "}
                    <span
                      style={{
                        fontWeight: 700,
                      }}
                    >
                      {state.title}
                    </span>
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "18px",
                      fontWeight: "400",
                      lineHeight: "28px",
                    }}
                  >
                    Year of Song Usage: <span>{state.year}</span>
                  </Typography>
                </ThemeProvider>
              </Grid>
            </Grid>
            <Divider className={classes.divider} />
            <div className={classes.infoArea}>
              <Grid container lg={12} xl={12}>
                <Card
                  style={{
                    width: "100%",
                    border: "1px solid #D1D5DC",
                    borderRadius: "6px",
                    height: "120px",
                    boxShadow: "none",
                  }}
                >
                  <CardContent>
                    <ThemeProvider theme={theme}>
                      <Grid container spacing={1} lg={12} xl={12}>
                        <Grid item lg={2} xl={2}>
                          <Typography
                            variant="subtitle2"
                            className={classes.label}
                          >
                            ISWC Code
                          </Typography>
                        </Grid>
                        <Grid item lg={10} xl={10}>
                          <Typography
                            variant="subtitle2"
                            className={classes.labelValue}
                          >
                            {state.isrc}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container spacing={1} lg={12} xl={12}>
                        <Grid item lg={2} xl={2}>
                          <Typography
                            variant="subtitle2"
                            className={classes.label}
                          >
                            Publisher
                          </Typography>
                        </Grid>
                        <Grid item lg={10} xl={10}>
                          <Typography
                            variant="subtitle2"
                            className={classes.labelValue}
                          >
                            {state.publisherName}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container spacing={1} lg={12} xl={12}>
                        <Grid item lg={2} xl={2}>
                          <Typography
                            variant="subtitle2"
                            className={classes.label}
                          >
                            Composer
                          </Typography>
                        </Grid>
                        <Grid item lg={10} xl={10}>
                          <Typography
                            variant="subtitle2"
                            className={classes.labelValue}
                          >
                            {state.penciptaList.join(", ")}
                          </Typography>
                        </Grid>
                      </Grid>
                    </ThemeProvider>
                  </CardContent>
                </Card>
              </Grid>
            </div>

            <Grid container>
              <Grid item xs={12} md={12} sm={12} lg={6} xl={6}>
                <ThemeProvider theme={theme}>
                  <Typography
                    variant="h5"
                    style={{
                      fontWeight: "bold",
                      marginBottom: "20px",
                    }}
                  >
                    Trend of Song Usage
                  </Typography>
                </ThemeProvider>
              </Grid>
            </Grid>

            <Grid item xs={12} md={12} sm={12} lg={12} xl={12}>
              <div
                style={{
                  paddingBottom: "50px",
                  paddingTop: "50px",
                  paddingLeft: "20px",
                  paddingRight: "20px",
                  marginBottom: "50px",
                  width: "100%",
                  border: "1px solid #D1D5DC",
                  borderRadius: "6px",
                }}
              >
                <Line options={options} data={state.chartData} plugins={[]} />
              </div>
            </Grid>

            <div className={classes.cardArea}>
              <Grid container spacing={2}>
                <ThemeProvider theme={theme}>
                  <Grid item xs={3} md={3}>
                    <Card className={classes.card}>
                      <div className={classes.insideCard}>
                        <Typography
                          component="p"
                          variant="caption"
                          className={classes.reportText}
                        >{`${state.getMonth} Traffic`}</Typography>
                        <Typography
                          variant="h5"
                          className={classes.reportValue}
                        >
                          {state.trafficListenerPerMonth}
                        </Typography>
                      </div>
                    </Card>
                  </Grid>
                  <Grid item xs={3} md={3}>
                    <Card className={classes.card}>
                      <div className={classes.insideCard}>
                        <Typography
                          component="p"
                          variant="caption"
                          className={classes.reportText}
                        >{`${state.getMonth} Revenue`}</Typography>
                        <Typography
                          variant="h5"
                          className={classes.reportValue}
                        >
                          {`${state.royaltyPerMonth},-`}
                        </Typography>
                      </div>
                    </Card>
                  </Grid>
                  <Grid item xs={3} md={3}>
                    <Card className={classes.card}>
                      <div className={classes.insideCard}>
                        <Typography
                          component="p"
                          variant="caption"
                          className={classes.reportText}
                        >
                          Total Traffic
                        </Typography>
                        <Typography
                          variant="h5"
                          className={classes.reportValue}
                        >
                          {state.trafficListenerAll}
                        </Typography>
                      </div>
                    </Card>
                  </Grid>
                  <Grid item xs={3} md={3}>
                    <Card className={classes.card}>
                      <div className={classes.insideCard}>
                        <Typography
                          component="p"
                          variant="caption"
                          className={classes.reportText}
                        >
                          Total Revenue
                        </Typography>
                        <Typography
                          variant="h5"
                          className={classes.reportValue}
                        >
                          {`${state.royaltyAll},-`}
                        </Typography>
                      </div>
                    </Card>
                  </Grid>
                </ThemeProvider>
              </Grid>
            </div>

            <Grid item xs={12} md={12} sm={12} lg={12} xl={12}>
              <Table size="small" className={classes.table}>
                <TableHead classes={{ root: classes.thead }}>
                  <TableRow>
                    <ThemeProvider theme={theme}>
                      <TableCell
                        style={{
                          width: "400px",
                        }}
                      >
                        <Typography
                          variant="subtitle2"
                          className={classes.headText}
                        >
                          Month
                        </Typography>
                      </TableCell>

                      <TableCell>
                        <Typography
                          variant="subtitle2"
                          className={classes.headText}
                        >
                          Year
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography
                          variant="subtitle2"
                          className={classes.headText}
                        >
                          Traffic
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography
                          variant="subtitle2"
                          className={classes.headText}
                        >
                          Revenue
                        </Typography>
                      </TableCell>
                    </ThemeProvider>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {state.tableData.map(data => (
                    <TableRow hover key={data.id}>
                      <ThemeProvider theme={theme}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={classes.bodyTextBlack}
                          >
                            {getMonthName(data.date)}
                          </Typography>
                        </TableCell>

                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={classes.bodyTextBlack}
                          >
                            {getYearList(data.date)}
                          </Typography>
                        </TableCell>

                        <TableCell align="right">
                          <Typography
                            variant="subtitle2"
                            className={classes.bodyTextBlack}
                          >
                            {formatter2.format(data.listener)}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography
                            variant="subtitle2"
                            className={classes.bodyTextBlack}
                          >
                            {`${formatter.format(data.royalty)},-`}
                          </Typography>
                        </TableCell>
                      </ThemeProvider>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>
          </Container>

          <div
            style={{
              display: "flex",
              justifyContent: "end",
              marginTop: "20px",
              marginRight: "20px",
            }}
          >
            <Button
              variant="contained"
              style={{
                textTransform: "none",
                backgroundColor: "#111827",
                color: "white",
                boxShadow: "none",
                marginBottom: "20px",
                marginLeft: "20px",
                width: "136px",
                height: "36px",
                borderRadius: "6px",
              }}
              onClick={handlePrintPDF}
            >
              <ThemeProvider theme={theme}>
                <Typography
                  style={{
                    color: "white",
                    whiteSpace: "nowrap",
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                >
                  Print Report
                </Typography>
              </ThemeProvider>
            </Button>
          </div>
        </div>
      </Page>
    </div>
  );
};

export default PrintReportDetailReview;
