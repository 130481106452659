import { Button, Divider, Typography } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import { useRef } from "react";

const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  rootRadio: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  results: {
    marginTop: theme.spacing(3),
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  btnBack: {
    backgroundColor: "#25383c",
    color: "#fff",
    marginLeft: "10px",
    marginRight: "20px",
  },
  btnSubmit: {
    border: "1px solid lightgrey",
  },
  icon: {
    borderRadius: "50%",
    width: 16,
    height: 16,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: "#111827",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
      content: "",
    },
    "input:hover ~ &": {
      backgroundColor: "#111827",
    },
  },
}));

function StyledRadio(props) {
  const classes = useStyles();

  return (
    <Radio
      className={classes.rootRadio}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}

const DialogNonAktifkanKontrak = ({
  open,
  onClose,
  onSubmit,
  radioValue,
  handleChange,
  dateValue,
  handleChangeDate,
}) => {
  const classes = useStyles();
  const dialogRef = useRef();

  return (
    <div>
      <Dialog
        size="small"
        open={open}
        onClose={onClose}
        aria-labelledby="responsive-dialog-title"
        ref={dialogRef}
      >
        <DialogTitle id="responsive-dialog-title">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <ThemeProvider theme={theme}>
              <Typography
                style={{
                  fontSize: "16px",
                  fontWeight: 700,
                }}
              >
                {"Non-Active Contract"}
              </Typography>
            </ThemeProvider>

            <CloseOutlinedIcon
              onClick={onClose}
              style={{
                color: "#9AA2B1",
                cursor: "pointer",
                width: "12px",
                height: "12px",
                marginTop: "8px",
              }}
            />
          </div>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <ThemeProvider theme={theme}>
            <Typography
              style={{
                fontSize: "12px",
                fontWeight: 400,
                color: "#687083",
                marginBottom: "10px",
              }}
            >
              Choose the date when the contract will be deactivated
            </Typography>

            <label
              style={{
                fontSize: "14px",
                fontWeight: 500,
                color: "#364052",
              }}
              htmlFor="date"
            >
              Tanggal Dinonaktifkan
            </label>
            <br />
            <KeyboardDatePicker
              id="outlined-basic"
              value={dateValue}
              onChange={handleChangeDate}
              format="YYYY-MM-DD"
              disabled={true}
              inputVariant="outlined"
              minDate={new Date()}
              fullWidth={true}
              margin="dense"
              style={{
                width: "100%",
                marginTop: "10px",
                border: "1px solid #D1D5DC",
                borderRadius: "6px",
              }}
            />
            {}
            <RadioGroup
              aria-label="gender"
              name="gender1"
              value={radioValue}
              onChange={handleChange}
            >
              <FormControlLabel
                value="3day"
                control={<StyledRadio />}
                label={
                  <Typography
                    style={{
                      fontSize: "14px",
                      fontWeight: 400,
                    }}
                  >
                    3 Day{" "}
                  </Typography>
                }
              />
              <FormControlLabel
                value="1week"
                control={<StyledRadio />}
                label={
                  <Typography
                    style={{
                      fontSize: "14px",
                      fontWeight: 400,
                    }}
                  >
                    1 Week{" "}
                  </Typography>
                }
              />
              <FormControlLabel
                value="2week"
                control={<StyledRadio />}
                label={
                  <Typography
                    style={{
                      fontSize: "14px",
                      fontWeight: 400,
                    }}
                  >
                    2 Week{" "}
                  </Typography>
                }
              />
            </RadioGroup>
          </ThemeProvider>
        </DialogContent>
        <Divider className={classes.divider} />
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            marginBottom: "20px",
          }}
        >
          <Button
            className={classes.btnSubmit}
            variant="outlined"
            onClick={onClose}
          >
            Cancel
          </Button>

          <Button className={classes.btnBack} onClick={onSubmit}>
            Save
          </Button>
        </div>
      </Dialog>
    </div>
  );
};

export default DialogNonAktifkanKontrak;
