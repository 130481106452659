import { Chip } from "@material-ui/core";
import { Box, Grid, styled, Tooltip } from "@mui/material";
import { CurrencyDisplay, CurrencyNumberInput } from "components";
import { formatDate } from "utils";

const TableCellMultipleRow = ({
  list,
  itemKey,
  type,
  prefix,
  prefixKey,
  tooltipKey,
  suffix,
  onChange,
  startAdornment,
  endAdornment,
  alignCenter,
}) => {
  const getDefaultValue = value => (typeof value === "string" ? "-" : 0);

  return (
    <Grid container direction="column">
      {list?.length > 0 ? (
        (list || []).map((item, index) => (
          <Grid item key={index}>
            {list?.length > 1 && index > 0 && <CellBorder />}
            <Tooltip title={item?.[tooltipKey]} placement="bottom">
              <Box height={alignCenter && "40px"} alignContent="center">
                {type === "number" ? (
                  <CurrencyDisplay
                    value={item?.[itemKey] || 0}
                    decimalScale={prefix && item?.[itemKey] ? 2 : 0}
                    prefix={prefixKey ? `${item?.[prefixKey]}` : prefix}
                    suffix={suffix}
                  />
                ) : type === "date" ? (
                  formatDate(item?.[itemKey])
                ) : type === "chip" ? (
                  <Chip
                    label={item?.[itemKey] || "-"}
                    size="small"
                    variant="outlined"
                  />
                ) : type === "numberInput" ? (
                  <CurrencyNumberInput
                    value={item?.[itemKey]}
                    onChange={value => onChange({ value, item })}
                    startAdornment={startAdornment}
                    endAdornment={endAdornment}
                  />
                ) : (
                  item?.[itemKey] || getDefaultValue(item?.[itemKey])
                )}
              </Box>
            </Tooltip>
          </Grid>
        ))
      ) : (
        <Grid item textAlign={type === "number" && "right"}>
          {type === "number" ? (prefix || prefixKey ? "Rp0.00" : 0) : "-"}
        </Grid>
      )}
    </Grid>
  );
};

const CellBorder = styled(Box)({
  margin: "8px -16px",
  height: "1px",
  backgroundColor: "#e0e0e0",
});
export default TableCellMultipleRow;
