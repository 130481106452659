import {
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Box
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { makeStyles } from "@material-ui/styles";
import { useState } from "react";
import { PrimaryButton } from "components";


const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  formControl: {},
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  results: {
    marginTop: theme.spacing(3),
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  gap4: {
    display: "flex",
    gap: "4px",
    marginTop: "10px",
    justifyContent: "flex-end"
  },
}));

const DialogTambahUserPublisher = ({
  open,
  onClose,
  onSubmit,
  handleChangeSelectRole,
  handleChange,
  role,
  loading,
  handleChangeSelectPublisher,
  publisher,
  publisherItems,
  roleItems,
  name,
  email,
  phone,
}) => {
  const classes = useStyles();
  const [passwordToggle, setPasswordToggle] = useState(false);
  const [konfirmasiPasswordToggle, setKonfirmasiPasswordToggle] = useState(
    false
  );

  const handleVisiblePassword = () => {
    setPasswordToggle(!passwordToggle);
  };
  const handleVisibleKonfirmasiPassword = () => {
    setKonfirmasiPasswordToggle(!konfirmasiPasswordToggle);
  };

  return (
    <Dialog
      fullWidth={true}
      open={open}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
    >
      <form onSubmit={onSubmit}>
        <DialogTitle id="responsive-dialog-title">
          Add User Publisher
        </DialogTitle>

        <DialogContent>
          <DialogContentText>User Name</DialogContentText>
          <FormControl
            fullWidth
            className={classes.margin}
            variant="outlined"
          >
            <OutlinedInput
              id="outlined-adornment-amount-name"
              onChange={handleChange}
              name="name"
              disabled={loading}
              type="text"
              fullWidth={false}
              value={name}
            />
          </FormControl>
        </DialogContent>
        <DialogContent>
          <DialogContentText>Role</DialogContentText>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            fullWidth={true}
          >
            <InputLabel
              id="demo-simple-select-outlined-label"
              shrink={false}
            >
              {role === "" && "Role"}
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              onChange={handleChangeSelectRole}
              autoWidth={true}
              fullWidth={true}
              disabled={loading}
              defaultValue=""
              value={role}
            >
              <MenuItem value="">Role</MenuItem>
              {roleItems.map(item => {
                return (
                  <MenuItem key={item.role_id} value={item.role_id}>
                    {item.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </DialogContent>

        <DialogContent>
          <DialogContentText>Publisher</DialogContentText>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            fullWidth={true}
          >
            <InputLabel
              id="demo-simple-select-outlined-label"
              shrink={false}
            >
              {publisher === "" && "Publisher"}
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              onChange={handleChangeSelectPublisher}
              autoWidth={true}
              disabled={loading}
              fullWidth={true}
              defaultValue=""
              value={publisher}
            >
              <MenuItem value="">Publisher</MenuItem>
              {publisherItems.map(item => {
                return (
                  <MenuItem
                    key={item.publisher_id}
                    value={item.publisher_id}
                  >
                    {item.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogContent>
          <DialogContentText>Password</DialogContentText>
          <FormControl
            fullWidth
            className={classes.margin}
            variant="outlined"
          >
            <TextField
              id="outlined-adornment-amount-pass"
              onChange={handleChange}
              name="password"
              variant="outlined"
              type={passwordToggle ? "text" : "password"}
              fullWidth={false}
              disabled={loading}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    onClick={handleVisiblePassword}
                  >
                    {passwordToggle ? (
                      <VisibilityIcon />
                    ) : (
                      <VisibilityOff />
                    )}
                  </InputAdornment>
                ),
              }}
              autoComplete="new-password"
            />
          </FormControl>
        </DialogContent>
        <DialogContent>
          <DialogContentText>Confirm Password</DialogContentText>
          <FormControl
            fullWidth
            className={classes.margin}
            variant="outlined"
          >
            <TextField
              id="outlined-adornment-amount-passconf-new"
              onChange={handleChange}
              disabled={loading}
              name="konfirmasiPassword"
              variant="outlined"
              type={konfirmasiPasswordToggle ? "text" : "password"}
              fullWidth={false}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    onClick={handleVisibleKonfirmasiPassword}
                  >
                    {konfirmasiPasswordToggle ? (
                      <VisibilityIcon />
                    ) : (
                      <VisibilityOff />
                    )}
                  </InputAdornment>
                ),
              }}
              autoComplete="new-confirmation-password"
            />
          </FormControl>
        </DialogContent>
        <DialogContent>
          <DialogContentText>Phone Number</DialogContentText>
          <FormControl
            fullWidth
            className={classes.margin}
            variant="outlined"
            disabled={loading}
          >
            <OutlinedInput
              id="outlined-adornment-amount-phone"
              onChange={handleChange}
              disabled={loading}
              name="phone"
              type="number"
              fullWidth={true}
              value={phone}
            />
          </FormControl>
        </DialogContent>

        <DialogContent>
          <DialogContentText>Email</DialogContentText>
          <FormControl
            fullWidth
            className={classes.margin}
            variant="outlined"
          >
            <OutlinedInput
              id="outlined-adornment-amount-email"
              onChange={handleChange}
              name="email"
              type="text"
              disabled={loading}
              fullWidth={true}
              value={email}
              autoComplete="new-email"
            />
          </FormControl>
        </DialogContent>
        <DialogContent>
          <Box className={classes.gap4}>
            <PrimaryButton disabled={loading} height={35} size="small" onClick={onClose} label="Cancel" />
            <PrimaryButton disabled={loading} height={35} size="small" onClick={onSubmit} label="Save" />
          </Box>
        </DialogContent>
      </form>
    </Dialog>
  );
};

export default DialogTambahUserPublisher;
