import { PrimaryButton } from "components";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Dialog,
  DialogActions,
  DialogContent,
  makeStyles,
} from "@material-ui/core";
import { Cancel, CheckCircle, ExpandMore, Warning } from "@material-ui/icons";
import { InnoTableV2 } from "inno-ui";
import { Grid, Typography } from "@mui/material";

const ReportModalWithTable = ({ modalVisible, onClose, detailReport }) => {
  const { status, message, status_details } = detailReport;
  const classes = useStyles({ status });

  return (
    <Dialog open={modalVisible} onClose={onClose} fullWidth maxWidth="md">
      <DialogContent>
        <Grid container justifyContent="center">
          {status === "Success" ? (
            <CheckCircle className={classes?.statusIcon} />
          ) : status === "Partial Success" ? (
            <Warning className={classes?.statusIcon} />
          ) : (
            <Cancel className={classes?.statusIcon} />
          )}
        </Grid>
        <Typography
          fontSize={24}
          fontWeight={500}
          align="center"
          m="12px 0 20px"
        >
          {message}
        </Typography>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography fontWeight={500}>See Details</Typography>
          </AccordionSummary>
          <AccordionDetails className={classes?.acordionDetails}>
            <InnoTableV2
              columns={columnTable}
              items={status_details || []}
              isLoading={false}
            />
          </AccordionDetails>
        </Accordion>
      </DialogContent>
      <DialogActions className={classes?.dialogActions}>
        <PrimaryButton label="Close" onClick={onClose} />
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles(() => ({
  dialogActions: {
    padding: "8px 24px",
  },
  statusIcon: props => ({
    color:
      props?.status === "Success"
        ? "#27e65a"
        : props?.status === "Partial Success"
        ? "#f0ec05"
        : "red",
    fontSize: 140,
  }),
  acordionDetails: {
    display: "block",
  },
}));

const columnTable = [
  {
    name: "song_title",
    title: "Song Title",
  },
  {
    name: "status",
    title: "Status",
  },
  {
    name: "message",
    title: "Message",
  },
];

export default ReportModalWithTable;
