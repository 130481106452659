import { IconButton } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const interFont = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

export const PartnerSlide = props => {
  return (
    <div
      style={{
        height: "585px",
        width: "385px",
      }}
    >
      <div
        style={{
          width: "385px",
          height: "223px",
          backgroundColor: "#642C8C",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <img
          key={props.index}
          alt={props.name}
          src={props?.image}
          style={{
            height: "223px",
          }}
        />
      </div>
      <div
        style={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
          marginTop: "40px",
        }}
      >
        <div
          style={{
            width: "250px",
            height: "300px",
            padding: "32px",
            border: "3px solid #E80088",
            color: "#E80088",
            background: "white",
            textAlign: "center",
          }}
        >
          <ThemeProvider theme={interFont}>
            {[props?.name, props?.position].map((item, index) => (
              <Typography
                key={index}
                style={{
                  fontWeight: 700,
                  fontSize: "18px",
                  lineHeight: "28px",
                }}
              >
                {index === 1 ? "(" + item + ")" : item}
              </Typography>
            ))}
            <Typography
              style={{
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "20px",
                display: "-webkit-box",
                overflow: "hidden",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 7,
                marginTop: "24px",
              }}
            >
              {props?.content}
            </Typography>
          </ThemeProvider>

          <IconButton
            onClick={() => { }}
            style={{
              position: "absolute",
              bottom: "-30px",
              left: "50%",
              transform: "translate(-50%)",
            }}
          >
            <img
              alt="button-detail"
              src={require("assets/image-public/images/Button-right-purple.png")}
              style={{
                zIndex: 2,
              }}
            />
          </IconButton>
        </div>
      </div>
    </div>
  );
};
