import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";
import {
  Box,
  Grow,
  InputAdornment,
  Paper,
  Popover,
  styled,
  TextField,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { PhotoshopPicker } from "react-color";

const ColorPickerInput = ({
  label,
  placeholder,
  disabled,
  width,
  onChange,
  value,
  ...rest
}) => {
  const inputRef = useRef(null);
  const paperRef = useRef(null);
  const [selectedColor, setSelectedColor] = useState("#00000");
  const [anchorEl, setAnchorEl] = useState(null);

  const handleShowMenu = event => setAnchorEl(event.currentTarget);
  const handleClickAway = () => {
    setAnchorEl(null);
  };
  const onAccept = () => {
    onChange(selectedColor);
    handleClickAway();
  };
  const onCancel = () => {
    setSelectedColor(value);
    handleClickAway();
  };
  useEffect(() => {
    setSelectedColor(value);
  }, [value]);
  return (
    <Box>
      <CustomTextField
        customWidth={width}
        ref={inputRef}
        size="small"
        label={label}
        focused={anchorEl}
        value={value || ""}
        placeholder={placeholder}
        disabled={disabled}
        onClick={disabled ? null : handleShowMenu}
        InputProps={{
          readOnly: true,
          fullWidth: true,
          startAdornment: (
            <InputAdornment position="start">
              <Box width={40} height={25} bgcolor={value || "#000000"} />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              {anchorEl ? <ArrowDropUp /> : <ArrowDropDown />}
            </InputAdornment>
          ),
        }}
        fullWidth
        {...rest}
      />
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClickAway}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        TransitionComponent={Grow}
      >
        <PaperContent
          customWidth={inputRef?.current?.offsetWidth}
          ref={paperRef}
        >
          <PhotoshopPicker
            color={selectedColor || "#000000"}
            onAccept={onAccept}
            onCancel={onCancel}
            onChange={updateColor => setSelectedColor(updateColor.hex)}
          />
        </PaperContent>
      </Popover>
    </Box>
  );
};

const CustomTextField = styled(TextField)(({ customWidth }) => ({
  cursor: "pointer !important",
  color: "red",
  width: customWidth,
  "& .MuiInputBase-root": {
    paddingRight: "8px",
    cursor: "pointer !important",
    "&.Mui-disabled": {
      cursor: "not-allowed !important",
      "&:hover": {
        cursor: "not-allowed !important",
      },
    },
  },
  "& .MuiInputBase-input": {
    cursor: "pointer !important",
    "&:hover": {
      cursor: "pointer !important",
    },
    "&.Mui-disabled": {
      cursor: "not-allowed !important",
      "&:hover": {
        cursor: "not-allowed !important",
      },
    },
  },
}));
const PaperContent = styled(Paper)(({ customWidth }) => ({
  minWidth: customWidth,
}));
export default ColorPickerInput;
