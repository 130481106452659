import {
  Breadcrumbs,
  Button,
  Container,
  Divider,
  Grid,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import { Page } from "components";
import { hardBaseUrl } from "../../../../services/urlConstant";
import { useStyles } from "../style";
import { columnTableDsp, columnTable } from "../constant";
import {
  ImportCatalogue,
  ImportComposer,
  ImportCWR,
  UploadDSP,
} from "./TypeMonitoring";
import { Skeleton } from "@material-ui/lab";

const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const DetailMonitor = props => {
  const classes = useStyles();

  const usedClassForChip = {
    Finish: classes.activeChip,
    Error: classes.nonActiveChip,
    Started: classes.progresChip,
    Initialize: classes.progresChip,
    Running: classes.progresChip,
  };

  const usedClassForChipIndicator = {
    Finish: classes.activeChipIndicator,
    Error: classes.nonActiveChipIndicator,
    Started: classes.progresChipIndicator,
    Initialize: classes.progresChipIndicator,
    Running: classes.progresChipIndicator,
  };
  const history = useHistory();
  const inputRef = useRef(null);
  const [client, setClient] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [processTypeFlag, setProcessTypeFlag] = useState();
  const [listError, setListError] = useState({
    data: [],
    total: 0,
  });
  const [loading, setLoading] = useState({
    loadingPage: false,
    loadingTable: false,
  });
  const { loadingPage } = loading;
  const [tablePagination, setTablePagination] = useState({
    page: 1,
    size: 10,
  });
  const { page, size } = tablePagination;
  const pagesCount = Math.ceil(listError?.total / tablePagination?.size);
  let token = localStorage.getItem("token");
  const headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const isCatalogue = client.type === "Import Catalogue";
  const isDSP = client.type === "Upload DSP";
  const isComposer = client.type === "Import Composer";
  const usedUploadType = client?.type;
  const isImportCatalogue = client?.type?.toLowerCase() === "import catalogue";
  const sizeChipForImportCatalogue = isImportCatalogue ? 6 : 12;

  const getListError = async (page, size, process_file_id) => {
    const urlListError = `${hardBaseUrl}/process/error_detail`;

    // Mulai loading
    setLoading(currentState => ({
      ...currentState,
      loadingTable: true,
    }));

    // Membuat params secara dinamis
    const params = {
      page,
      size,
      sort: "id",
      DESC: "",
      process_id: props.match.params.id,
      search: "",
    };

    // Hanya tambahkan process_file_id jika processTypeFlag adalah "UD"
    if (processTypeFlag === "UD") {
      params.process_file_id = process_file_id;
    }

    try {
      const response = await axios.get(urlListError, {
        params,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setListError({
        data: response?.data?.data || [],
        total: response?.data?.meta?.total,
      });
    } catch (error) {
      console.error("Error fetching list:", error);
      // Tambahkan error handling sesuai kebutuhan
    } finally {
      // Mengakhiri loading
      setLoading(currentState => ({
        ...currentState,
        loadingTable: false,
      }));
    }
  };

  const getDetailUploadMonitor = async () => {
    setLoading(currentState => ({
      ...currentState,
      loadingPage: true,
    }));
    const urlClientList = `${hardBaseUrl}/process/detail/${props.match.params.id}`;
    try {
      const getResponse = await axios.get(urlClientList, headers);
      setClient(currentState => ({
        ...currentState,
        ...getResponse?.data?.data,
      }));
      setLoading(currentState => ({
        ...currentState,
        loadingPage: false,
      }));
      setProcessTypeFlag(getResponse?.data?.data?.process_type_flag);
      setActiveTab(
        currentState =>
          getResponse?.data?.data?.process_files[0]?.id || currentState
      );
    } catch (err) {
      setLoading(currentState => ({
        ...currentState,
        loadingPage: false,
      }));
      new Error(err);
    }
  };

  const handleChangeRowsPerPage = event => {
    event.preventDefault();
    setTablePagination({ page: 1, size: event?.target?.value });
  };

  const handleChangePage = (_, data) => {
    setTablePagination({ ...tablePagination, page: data });
  };

  const downloadErrorDetail = async type => {
    let token = localStorage.getItem("token");
    const urlClientList = `${hardBaseUrl}/upload-dsrf/error/download/${props.match.params.id}`;
    await axios
      .get(urlClientList, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          type,
        },
      })
      .then(res => window.open(res.data.data));
  };

  const handleAdd = async () => {
    history.push("/admin/upload-monitoring");
  };

  useEffect(() => {
    getDetailUploadMonitor();
  }, []);

  useEffect(() => {
    getListError(page, size, activeTab);
  }, [activeTab, page, size]);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Page className={classes.root} title={`${usedUploadType} Data`}>
      {loadingPage ? (
        <Skeleton animation="wave" />
      ) : (
        <Container maxWidth={false}>
          <Grid
            alignItems="flex-end"
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <ThemeProvider theme={theme}>
                <Typography className={classes.typographyTitle}>
                  {`View ${usedUploadType} Data`}
                </Typography>
              </ThemeProvider>
            </Grid>
            <Grid item>
              <Breadcrumbs aria-label="breadcrumb">
                <Typography className={classes.breadCrumbs}>Home</Typography>
                <Typography className={classes.breadCrumbs}>
                  Upload Monitoring
                </Typography>
                <Typography className={classes.breadCrumbs}>
                  {`View ${usedUploadType}`}
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
          <Grid container>
            <Grid item xs={12} md={8} lg={8} xl={8}>
              <Grid
                ref={inputRef}
                alignItems="center"
                container
                justifyContent="space-between"
              >
                <Grid item>
                  <Grid container>
                    <div>
                      <ThemeProvider theme={theme}>
                        <span className={classes.titleSpan}>
                          <b>{`Detail of ${usedUploadType} Data`}</b>
                        </span>
                      </ThemeProvider>
                    </div>
                  </Grid>
                  <Grid container>
                    <div>
                      <ThemeProvider theme={theme}>
                        <p className={classes.subTitleSpan}>
                          {`Features to view detail of ${usedUploadType} Data`}
                        </p>
                      </ThemeProvider>
                    </div>
                  </Grid>
                </Grid>
                {client?.status !== "success" && (
                  <Grid item>
                    <Button
                      onClick={handleClick}
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      className={classes.btnDownloadError}
                    >
                      <ThemeProvider theme={theme}>
                        <Typography
                          component="span"
                          className={classes.labelBtnDownloadError}
                        >
                          Download Error Detail
                        </Typography>
                      </ThemeProvider>
                    </Button>
                    <Menu
                      id="simple-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                    >
                      <MenuItem
                        disabled={listError?.data?.length === 0}
                        onClick={() => downloadErrorDetail("csv")}
                      >
                        CSV
                      </MenuItem>
                      <MenuItem
                        disabled={listError?.data?.length === 0}
                        onClick={() => downloadErrorDetail("pdf")}
                      >
                        PDF
                      </MenuItem>
                    </Menu>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>

          {isCatalogue ? (
            <ImportCatalogue
              sizeChipForImportCatalogue={sizeChipForImportCatalogue}
              client={client}
              columnTable={columnTable}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              listError={listError}
              tablePagination={tablePagination}
              pagesCount={pagesCount}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              usedClassForChipIndicator={usedClassForChipIndicator}
              usedClassForChip={usedClassForChip}
            />
          ) : isDSP ? (
            <UploadDSP
              sizeChipForImportCatalogue={sizeChipForImportCatalogue}
              client={client}
              columnTableDsp={columnTableDsp}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              listError={listError}
              tablePagination={tablePagination}
              pagesCount={pagesCount}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              usedClassForChipIndicator={usedClassForChipIndicator}
              usedClassForChip={usedClassForChip}
            />
          ) : isComposer ? (
            <ImportComposer
              sizeChipForImportCatalogue={sizeChipForImportCatalogue}
              client={client}
              columnTable={columnTable}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              listError={listError}
              tablePagination={tablePagination}
              pagesCount={pagesCount}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              usedClassForChipIndicator={usedClassForChipIndicator}
              usedClassForChip={usedClassForChip}
            />
          ) : (
            <ImportCWR
              sizeChipForImportCatalogue={sizeChipForImportCatalogue}
              client={client}
              columnTable={columnTable}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              listError={listError}
              tablePagination={tablePagination}
              pagesCount={pagesCount}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              usedClassForChipIndicator={usedClassForChipIndicator}
              usedClassForChip={usedClassForChip}
            />
          )}
          <Grid container>
            <Grid item xs={6} md={8} lg={8} xl={8}>
              <Grid container justifyContent="flex-end">
                <div className={classes.mt}>
                  <Button onClick={handleAdd} className={classes.buttonBack}>
                    <ThemeProvider theme={theme}>
                      <Typography
                        component="span"
                        className={classes.labelBack}
                      >
                        Back
                      </Typography>
                    </ThemeProvider>
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      )}
    </Page>
  );
};

export default DetailMonitor;
