import {
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecordRounded";
import Pagination from "@material-ui/lab/Pagination";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import { hardBaseUrl } from "../../../../services/urlConstant";

const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },

  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  formControl: {},
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  results: {
    marginTop: theme.spacing(3),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  pageSize: {
    height: "30px",
    borderRadius: "6px",
    border: "1px solid #D1D5DC",
    paddingLeft: "5px",
    paddingRight: "5px",
    marginTop: "5px",
  },
  paginationText: {
    fontSize: "14px",
    fontWeight: "normal",
    fontStyle: "normal",
  },
  breadcrumbs: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#111827",
  },
  breadCrumbsActive: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#687083",
  },
  placeholder: {
    "&::placeholder": {
      color: "#9AA2B1",
    },
  },
  conTextBlack: {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    marginBottom: "10px",
  },
  label: {
    color: "#364052",
    fontSize: "14px",
    fontWeight: 500,
    marginBottom: "5px",
  },
  subTitle: {
    fontSize: "18px",
    fontWeight: 700,
    color: " #111827",
  },
  btnSubmit: {
    backgroundColor: "black",

    color: "white",
    padding: "10px 28px",

    height: "40px",
    borderRadius: "6px",

    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
  pagination: {
    "& .Mui-selected": {
      backgroundColor: "black",
      color: "white",
    },
  },
  headText: {
    fontSize: "12px",
    color: "#687083",
    fontWeight: "bold",
  },
  bodyTextBlack: {
    fontSize: "14px",
    fontWeight: "regular",
    color: "#111827",
  },
  bodyTextGrey: {
    fontSize: "14px",
    fontWeight: "regular",
    color: "#687083",
  },
  tableRow: {
    height: 20,
  },

  dropdown: {
    color: "black",
  },
}));

const DetailSongContract = ({
  noKontrak,
  tglMulai,
  publisher,
  status,
  tglSelesai,
  idPublisher,
  keterangan,
  approvalStatus,
  approvalStatusUpdate,
  agentList,
  penciptaList,
  advanceValue,
  ownershipShareList,
  ownershipShareDropdownList,
  publisherShareList,
  advanceFilterValueList,
  agentFilterValueList,
  publisherShareFilterList,
  perpuityContract,
}) => {
  const { state } = useLocation();
  const classes = useStyles();

  const [advanceFilterValue, setAdvanceFilterValue] = useState(0);
  const [sharePercentageValue, setSharePercentageValue] = useState(0);
  const [agentFilterValue, setAgentFilterValue] = useState(0);
  const [publisherShareFilterValue, setPublisherShareFilterValue] = useState(0);
  const [pageAdvanceValue, setPageAdvanceValue] = useState(1);
  const [pageSharePercentage, setPageSharePercentage] = useState(1);
  const [pagePublisherShare, setPagePublisherShare] = useState(1);
  const [pageAgent, setPageAgent] = useState(1);
  const [rowsPerPageAdvanceValue, setRowsPerPageAdvanceValue] = useState(5);
  const [rowsPerPageSharePercentage, setRowsPerPageSharePercentage] = useState(
    5
  );
  const [rowsPerPagePublisherShare, setRowsPerPagePublisherShare] = useState(5);
  const [rowsPerPageAgent, setRowsPerPageAgent] = useState(5);
  const [roleFor, setRoleFor] = useState("");

  const advanceValueSlice = advanceValue.slice(
    (pageAdvanceValue - 1) * rowsPerPageAdvanceValue,
    pageAdvanceValue * rowsPerPageAdvanceValue
  );

  const sharePercentageSlice = ownershipShareList.slice(
    (pageSharePercentage - 1) * rowsPerPageSharePercentage,
    pageSharePercentage * rowsPerPageSharePercentage
  );

  const publisherShareSlice = publisherShareList
    .flat(1)
    .slice(
      (pagePublisherShare - 1) * rowsPerPagePublisherShare,
      pagePublisherShare * rowsPerPagePublisherShare
    );
  const agentSlice = agentList.slice(
    (pageAgent - 1) * rowsPerPageAgent,
    pageAgent * rowsPerPageAgent
  );

  const handleChangeRowsPerPageAdvanceValue = event => {
    event.preventDefault();
    setRowsPerPageAdvanceValue(event.target.value);

    setPageAdvanceValue(1);
  };

  const pageSizePaginationAdvanceValue = () => {
    return (
      <div className={classes.pageSize}>
        <select
          style={{
            backgroundColor: "transparent",
            border: "none",
            outline: "none",
            marginTop: "4px",
          }}
          value={rowsPerPageAdvanceValue}
          onChange={handleChangeRowsPerPageAdvanceValue}
        >
          {[5, 20, 50].map(pageSize => {
            return (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            );
          })}
        </select>
      </div>
    );
  };

  const handleChangeRowsPerPageSharePercentage = event => {
    event.preventDefault();
    setRowsPerPageSharePercentage(event.target.value);

    setPageSharePercentage(1);
  };

  const pageSizePaginationSharePercentage = () => {
    return (
      <div className={classes.pageSize}>
        <select
          style={{
            backgroundColor: "transparent",
            border: "none",
            outline: "none",
            marginTop: "4px",
          }}
          value={rowsPerPageSharePercentage}
          onChange={handleChangeRowsPerPageSharePercentage}
        >
          {[5, 20, 50].map(pageSize => {
            return (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            );
          })}
        </select>
      </div>
    );
  };

  const handleChangeRowsPerPagePublisherShare = event => {
    event.preventDefault();
    setRowsPerPagePublisherShare(event.target.value);

    setPagePublisherShare(1);
  };

  const pageSizePaginationPublisherShare = () => {
    return (
      <div className={classes.pageSize}>
        <select
          style={{
            backgroundColor: "transparent",
            border: "none",
            outline: "none",
            marginTop: "4px",
          }}
          value={rowsPerPagePublisherShare}
          onChange={handleChangeRowsPerPagePublisherShare}
        >
          {[5, 20, 50].map(pageSize => {
            return (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            );
          })}
        </select>
      </div>
    );
  };

  const handleChangeRowsPerPageAgent = event => {
    event.preventDefault();
    setRowsPerPageAgent(event.target.value);

    setPageAgent(1);
  };

  const pageSizePaginationAgent = () => {
    return (
      <div className={classes.pageSize}>
        <select
          style={{
            backgroundColor: "transparent",
            border: "none",
            outline: "none",
            marginTop: "4px",
          }}
          value={rowsPerPageAgent}
          onChange={handleChangeRowsPerPageAgent}
        >
          {[5, 20, 50].map(pageSize => {
            return (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            );
          })}
        </select>
      </div>
    );
  };

  const pagesCountAdvance = song_list => {
    return Math.ceil(song_list.length / rowsPerPageAdvanceValue);
  };
  const pagesCountSharePercentage = song_composer => {
    console.info(
      Math.ceil(song_composer.length / rowsPerPageSharePercentage),
      song_composer,
      "<<<< counting"
    );
    return Math.ceil(song_composer.length / rowsPerPageSharePercentage);
  };
  const pagesCountPublisherShare = publisherShareList => {
    console.info(
      publisherShareList,
      Math.ceil(publisherShareList.length / rowsPerPagePublisherShare),
      "<<< counting"
    );
    return Math.ceil(publisherShareList.length / rowsPerPagePublisherShare);
  };

  const pagesCountAgent = song_list => {
    return Math.ceil(song_list.length / rowsPerPageAgent);
  };

  const handleChangePageAdvanceValue = (event, newPage) => {
    event.preventDefault();
    setPageAdvanceValue(newPage);
  };
  const handleChangePageSharePercentage = (event, newPage) => {
    event.preventDefault();
    setPageSharePercentage(newPage);
  };
  const handleChangePagePublisherShare = (event, newPage) => {
    event.preventDefault();

    setPagePublisherShare(newPage);
  };

  const handleChangePageAgent = (event, newPage) => {
    setPageAgent(newPage);
  };

  const handleChangeAgentFilter = event => {
    setAgentFilterValue(event.target.value);
  };

  const handleChangePublisherShareFilter = event => {
    setPublisherShareFilterValue(event.target.value);
  };

  const handleChangeComposerSharePercentage = event => {
    setSharePercentageValue(event.target.value);
  };

  const handleChangeAdvanceFilter = event => {
    setAdvanceFilterValue(event.target.value);
  };

  const getChipStatus = status => {
    if (status === "approved") {
      return (
        <Chip
          avatar={
            <FiberManualRecordIcon
              style={{ width: "10px", color: "#34774C" }}
            />
          }
          style={{
            margin: "4px",
            color: "#34774C",
            backgroundColor: "#8DE5AB",
          }}
          label={"Approved"}
        />
      );
    } else if (status === "rejected") {
      return (
        <Chip
          avatar={
            <FiberManualRecordIcon
              style={{ width: "10px", color: "#E53E3E" }}
            />
          }
          style={{
            margin: "4px",
            color: "#E53E3E",
            backgroundColor: "#FED7D7",
          }}
          label={"Rejected"}
        />
      );
    } else if (status === "waiting_for_approval") {
      return (
        <Chip
          avatar={
            <FiberManualRecordIcon
              style={{ width: "10px", color: "#F6AD55" }}
            />
          }
          style={{ color: "#F6AD55", backgroundColor: "#FEEBC8" }}
          label={"Waiting for Approval"}
        />
      );
    } else if (status === "waiting_for_editing_approval") {
      return (
        <Chip
          avatar={
            <FiberManualRecordIcon
              style={{ width: "10px", color: "#F6AD55" }}
            />
          }
          style={{ color: "#F6AD55", backgroundColor: "#FEEBC8" }}
          label={" Waiting for Editing Approval"}
        />
      );
    }
  };

  const handleApprove = () => {
    const token = localStorage.getItem("token");
    const url = `${hardBaseUrl}/publisher/contract/approval-status/${state.customer.contract_id}`;

    const data = {
      approval_status: "approved",
    };
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, approve it!",
    }).then(result => {
      if (result.isConfirmed) {
        axios
          .put(url, data, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then(() => {
            Swal.fire({
              icon: "success",
              title: "Approved!",
              text: "Your contract has been approved.",
              showConfirmButton: true,
            }).then(result => {
              if (result.isConfirmed) {
                window.location.reload();
              }
            });
          })
          .catch(err => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: err.response.data.errors[0].message,
              confirmButtonColor: "#3085d6",
            });
          });
      }
    });
  };
  const handleReject = () => {
    const token = localStorage.getItem("token");
    const url = `${hardBaseUrl}/publisher/contract/approval-status/${state.customer.contract_id}`;
    const data = {
      approval_status: "rejected",
    };
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, reject",
    }).then(result => {
      if (result.isConfirmed) {
        axios
          .put(url, data, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then(() => {
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "Contract has been rejected",
              confirmButtonColor: "#3085d6",
            }).then(result => {
              if (result.isConfirmed) {
                window.location.reload();
              }
            });
          })
          .catch(err => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: err.response.data.errors[0].message,
              confirmButtonColor: "#3085d6",
            });
          });
      }
    });
  };

  const formatter = new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 0,
  });

  const listPenciptaChip = data => {
    return (
      <div>
        {data?.map(pencipta => (
          <Chip
            key={pencipta.composer_id}
            label={pencipta.composer_name}
            className={classes.tableChip}
            size="small"
          />
        ))}
      </div>
    );
  };

  const moneyFormatter = number => {
    return formatter.format(number);
  };

  const dateFormatter = date => {
    const newDate = date
      .split("-")
      .reverse()
      .join("-");
    const day = newDate.split("-")[0];
    const month = newDate.split("-")[1];
    const year = newDate.split("-")[2];
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const monthName = monthNames[month - 1];

    return `${day} ${monthName} ${year}`;
  };

  const getDateFormat1 = date => {
    let dateFormat = new Date(date);
    let day = dateFormat.getDate();
    let month = dateFormat.getMonth();
    let year = dateFormat.getFullYear();
    let months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "Mei",
      "Jun",
      "Jul",
      "Agu",
      "Sept",
      "Okt",
      "Nov",
      "Des",
    ];
    return day + " " + months[month] + " " + year;
  };

  const getFeeTypeAgent = feeType => {
    if (feeType === "sales_amount") {
      return "Sales Amount";
    } else if (feeType === "net_revenue_amount") {
      return "Net Revenue Amount";
    }
  };

  const getMe = () => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const url = `${hardBaseUrl}/me`;
    axios
      .get(url, config)
      .then(res => {
        setRoleFor(res.data.data.role.roles_for);
      })
      .catch(() => {});
  };
  useEffect(() => {
    getMe();
  }, []);

  return (
    <div>
      <div>
        {roleFor === "finance" ? (
          <div>
            <Grid container>
              <div>
                <ThemeProvider theme={theme}>
                  <Typography
                    component="h1"
                    variant="h3"
                    className={classes.subTitle}
                  >
                    {approvalStatusUpdate === "waiting_for_approval" ||
                    approvalStatusUpdate === "approved" ? (
                      <>Edit</>
                    ) : (
                      <>Create</>
                    )}{" "}
                    Submission
                  </Typography>
                </ThemeProvider>
                <ThemeProvider theme={theme}>
                  <p
                    style={{
                      marginTop: "5px",
                      color: "#8f8f8f",
                      fontSize: "14px",
                    }}
                  >
                    Features for contract editing validation purposes
                  </p>
                </ThemeProvider>
              </div>
            </Grid>
            <Grid container>
              <Grid item xs={12} md={8} lg={8} xl={8}>
                <Card
                  style={{
                    border: "1px solid #9AA2B1",
                    borderRadius: "6px",
                    boxShadow: "none",
                    marginBottom: "20px",
                  }}
                >
                  <CardContent>
                    <Grid container>
                      <Grid item xs={6} md={6} lg={6} xl={6}>
                        <Typography className={classes.label}>
                          Status
                        </Typography>
                        {approvalStatusUpdate !== "" ? (
                          <>{getChipStatus(approvalStatusUpdate)}</>
                        ) : (
                          <>{getChipStatus(approvalStatus) || "-"}</>
                        )}
                      </Grid>
                      <Grid item xs={6} md={6} lg={6} xl={6}>
                        <>
                          {approvalStatus === "rejected" ||
                          approvalStatus === "approved" ||
                          approvalStatus === "" ? (
                            <>{""}</>
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <Button
                                variant="contained"
                                className={classes.btnSubmit}
                                style={{
                                  backgroundColor: "white",
                                  color: "black",
                                  border: "1px solid #9AA2B1",
                                }}
                                onClick={handleReject}
                              >
                                <ThemeProvider theme={theme}>
                                  <Typography
                                    style={{
                                      textTransform: "none",
                                      color: "black",
                                      fontSize: "14px",
                                      fontWeight: 400,
                                      lineHeight: "20px",
                                    }}
                                  >
                                    Reject
                                  </Typography>
                                </ThemeProvider>
                              </Button>

                              <Button
                                variant="contained"
                                className={classes.btnSubmit}
                                style={{
                                  backgroundColor: "black",
                                  marginLeft: "10px",
                                }}
                                onClick={handleApprove}
                              >
                                <ThemeProvider theme={theme}>
                                  <Typography
                                    style={{
                                      textTransform: "none",
                                      color: "#FFFFFF",
                                      fontSize: "14px",
                                      fontWeight: 400,
                                      lineHeight: "20px",
                                    }}
                                  >
                                    Approve
                                  </Typography>
                                </ThemeProvider>
                              </Button>
                            </div>
                          )}
                        </>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={4} lg={4} xl={4}></Grid>
            </Grid>
          </div>
        ) : (
          <div>
            <Grid container>
              <div>
                <ThemeProvider theme={theme}>
                  <Typography
                    component="h1"
                    variant="h3"
                    className={classes.subTitle}
                  >
                    {approvalStatusUpdate === "waiting_for_approval" ||
                    approvalStatusUpdate === "approved" ? (
                      <>Edit</>
                    ) : (
                      <>Create</>
                    )}{" "}
                    Submission
                  </Typography>
                </ThemeProvider>
                <ThemeProvider theme={theme}>
                  <p
                    style={{
                      marginTop: "5px",
                      color: "#8f8f8f",
                      fontSize: "14px",
                    }}
                  >
                    Features for contract editing validation purposes
                  </p>
                </ThemeProvider>
              </div>
            </Grid>
            <Grid container>
              <Grid item xs={12} md={8} lg={8} xl={8}>
                <Card
                  style={{
                    border: "1px solid #9AA2B1",
                    borderRadius: "6px",
                    boxShadow: "none",
                    marginBottom: "20px",
                  }}
                >
                  <CardContent>
                    <Typography className={classes.label}>Status</Typography>
                    {getChipStatus(approvalStatus) || "-"}
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={4} lg={4} xl={4}></Grid>
            </Grid>
          </div>
        )}

        <Grid container>
          <div>
            <ThemeProvider theme={theme}>
              <Typography
                component="h1"
                variant="h3"
                className={classes.subTitle}
              >
                Song Contract Information
              </Typography>
            </ThemeProvider>
            <ThemeProvider theme={theme}>
              <p
                style={{
                  marginTop: "5px",
                  color: "#8f8f8f",
                  fontSize: "14px",
                }}
              >
                Contract information that has been made.
              </p>
            </ThemeProvider>
          </div>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={8} lg={8} xl={8}>
            <Card
              style={{
                border: "1px solid #9AA2B1",
                borderRadius: "6px",
                boxShadow: "none",
              }}
            >
              <CardContent>
                <ThemeProvider theme={theme}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6} lg={6} xl={6}>
                      <Typography className={classes.label}>
                        Contract Number
                      </Typography>
                      <Typography className={classes.conTextBlack}>
                        {noKontrak || "-"}
                      </Typography>
                      <Typography className={classes.label}>
                        Start Date
                      </Typography>
                      <Typography className={classes.conTextBlack}>
                        {getDateFormat1(tglMulai) || "-"}
                      </Typography>
                      <Typography className={classes.label}>
                        Publisher
                      </Typography>
                      <Typography className={classes.conTextBlack}>
                        {publisher || "-"}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6} xl={6}>
                      <Typography className={classes.label}>Status</Typography>
                      <Typography className={classes.conTextBlack}>
                        {status ? (
                          <Chip
                            avatar={
                              <FiberManualRecordIcon
                                style={{ width: "10px", color: "#34774C" }}
                              />
                            }
                            style={{
                              margin: "4px",
                              color: "#34774C",
                              backgroundColor: "#8DE5AB",
                            }}
                            label={"Active"}
                          />
                        ) : (
                          <Chip
                            style={{
                              margin: "4px",
                              color: "#F87171",
                              backgroundColor: "#FEE2E2",
                            }}
                            label={"Inactive"}
                          />
                        )}
                      </Typography>
                      <Typography className={classes.label}>
                        End Date
                      </Typography>
                      <Typography className={classes.conTextBlack}>
                        {perpuityContract
                          ? "-"
                          : getDateFormat1(tglSelesai) || "-"}
                      </Typography>
                      <Typography className={classes.label}>
                        ID Publisher
                      </Typography>
                      <Typography className={classes.conTextBlack}>
                        {idPublisher || "-"}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} xl={12}>
                      <Typography className={classes.label}>
                        Description
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "16px",
                          fontWeight: 400,
                          color: "#687083",
                        }}
                      >
                        {keterangan || "-"}
                      </Typography>
                    </Grid>
                  </Grid>
                </ThemeProvider>
              </CardContent>
            </Card>
            <Divider className={classes.divider} />
          </Grid>
          <Grid item xs={12} md={4} lg={4} xl={4}></Grid>
        </Grid>
      </div>

      <div>
        <Grid container>
          <div>
            <ThemeProvider theme={theme}>
              <Typography
                component="h1"
                variant="h3"
                className={classes.subTitle}
              >
                Song Information
              </Typography>
            </ThemeProvider>
            <ThemeProvider theme={theme}>
              <p
                style={{
                  marginTop: "5px",
                  color: "#8f8f8f",
                  fontSize: "14px",
                }}
              >
                Detail song information.
              </p>
            </ThemeProvider>
          </div>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={8} lg={8} xl={8}>
            <Card
              style={{
                border: "1px solid #9AA2B1",
                borderRadius: "6px",
                boxShadow: "none",
              }}
            >
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <ThemeProvider theme={theme}>
                      <TableCell>
                        <Typography
                          variant="subtitle2"
                          className={classes.headText}
                        >
                          Song
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="subtitle2"
                          className={classes.headText}
                        >
                          Composer/Author Name
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="subtitle2"
                          className={classes.headText}
                        >
                          ISWC Code
                        </Typography>
                      </TableCell>
                    </ThemeProvider>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {penciptaList !== null ? (
                    penciptaList?.length > 0 ? (
                      penciptaList.map(customer => (
                        <TableRow hover key={customer.composer_id}>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={classes.bodyTextBlack}
                            >
                              {customer.title_song}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={classes.bodyTextBlack}
                            >
                              {listPenciptaChip(
                                customer.composers,
                                customer.song_id
                              )}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={classes.bodyTextGrey}
                            >
                              {customer.iswc_code === ""
                                ? "-"
                                : customer.iswc_code}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={4}>
                          <center>
                            <ThemeProvider theme={theme}>
                              <Typography
                                variant="h6"
                                style={{
                                  fontWeight: 400,
                                  color: "#687083",
                                  fontSize: "14px",
                                }}
                              >
                                no data
                              </Typography>
                            </ThemeProvider>
                          </center>
                        </TableCell>
                      </TableRow>
                    )
                  ) : (
                    <TableRow>
                      <TableCell colSpan={3}>
                        <center>
                          <ThemeProvider theme={theme}>
                            <Typography
                              variant="h6"
                              style={{
                                fontWeight: 400,
                                color: "#687083",
                                fontSize: "14px",
                              }}
                            >
                              no data
                            </Typography>
                          </ThemeProvider>
                        </center>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Card>
            <Divider className={classes.divider} />
          </Grid>
          <Grid item xs={12} md={4} lg={4} xl={4}></Grid>
        </Grid>
      </div>

      <div>
        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
          <div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <Typography
                  component="h1"
                  variant="h3"
                  className={classes.subTitle}
                >
                  Advance Value
                </Typography>
                <ThemeProvider theme={theme}>
                  <p
                    style={{
                      marginTop: "5px",
                      color: "#8f8f8f",
                      fontSize: "14px",
                    }}
                  >
                    Feature to set Advance Payment
                  </p>
                </ThemeProvider>
              </div>

              <div>
                <FormControl
                  className={classes.formControl}
                  fullWidth={true}
                  id="dsp-filter"
                  style={{ width: "300px" }}
                >
                  <TextField
                    fullWidth={true}
                    size="small"
                    className={classes.select}
                    onChange={handleChangeAdvanceFilter}
                    value={advanceFilterValue}
                    select
                    variant="outlined"
                  >
                    <MenuItem value={0}>Select Composer</MenuItem>
                    {advanceFilterValueList?.map((option, index) => (
                      <MenuItem key={index} value={option.composer_id}>
                        {option.sure_name}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </div>
            </div>
            {advanceValue?.length > 0 && advanceFilterValue !== 0 ? (
              <Card
                style={{
                  border: "1px solid #9AA2B1",
                  borderRadius: "6px",
                  boxShadow: "none",
                  marginTop: "10px",
                }}
              >
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <ThemeProvider theme={theme}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={classes.headText}
                          >
                            Song
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography
                            variant="subtitle2"
                            className={classes.headText}
                          >
                            Advance Value
                          </Typography>
                        </TableCell>

                        <TableCell align="left">
                          <Typography
                            variant="subtitle2"
                            className={classes.headText}
                          >
                            Start Period
                          </Typography>
                        </TableCell>

                        <TableCell align="left">
                          <Typography
                            variant="subtitle2"
                            className={classes.headText}
                          >
                            End Period
                          </Typography>
                        </TableCell>
                      </ThemeProvider>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {advanceValue?.length > 0 ? (
                      advanceValue
                        .flat(1)
                        .filter(el => el.composer_id === advanceFilterValue)
                        .map((customer, index) => (
                          <TableRow hover key={customer.song_composer_id}>
                            <TableCell>
                              <Typography
                                variant="subtitle2"
                                className={classes.bodyTextBlack}
                              >
                                {customer.song_title}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              {`${moneyFormatter(
                                customer.song_balance.balance
                              )},-`}
                              {}
                            </TableCell>

                            <TableCell>
                              {}
                              {dateFormatter(
                                customer.song_balance.start_period
                              )}
                            </TableCell>

                            <TableCell>
                              {}
                              {dateFormatter(customer.song_balance.end_period)}
                            </TableCell>
                          </TableRow>
                        ))
                        .slice(
                          (pageAdvanceValue - 1) * rowsPerPageAdvanceValue,
                          pageAdvanceValue * rowsPerPageAdvanceValue
                        )
                    ) : (
                      <TableRow>
                        <TableCell colSpan={4}>
                          <center>
                            <ThemeProvider theme={theme}>
                              <Typography
                                variant="h6"
                                style={{
                                  fontWeight: 400,
                                  color: "#687083",
                                  fontSize: "14px",
                                }}
                              >
                                No Composer Selected Yet
                              </Typography>
                            </ThemeProvider>
                          </center>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>

                {advanceValueSlice?.length > 0 ? (
                  <CardActions className={classes.actions}>
                    <Grid
                      alignContent="space-between"
                      container
                      justifyContent="space-between"
                      spacing={3}
                    >
                      <Grid item>
                        <div style={{ display: "flex" }}>
                          <div style={{ marginTop: "7px" }}>
                            <ThemeProvider theme={theme}>
                              <Typography className={classes.paginationText}>
                                Item per page:
                              </Typography>
                            </ThemeProvider>
                          </div>{" "}
                          <div style={{ marginLeft: "10px" }}>
                            {pageSizePaginationAdvanceValue()}
                          </div>
                        </div>
                      </Grid>

                      <Grid item>
                        <Pagination
                          count={pagesCountAdvance(advanceValue)}
                          shape="rounded"
                          className={classes.pagination}
                          page={pageAdvanceValue}
                          onChange={handleChangePageAdvanceValue}
                        />
                      </Grid>
                    </Grid>
                  </CardActions>
                ) : null}
              </Card>
            ) : (
              <Card
                style={{
                  border: "1px solid #9AA2B1",
                  borderRadius: "6px",
                  boxShadow: "none",
                  marginTop: "10px",
                }}
              >
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <ThemeProvider theme={theme}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={classes.headText}
                          >
                            Song
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography
                            variant="subtitle2"
                            className={classes.headText}
                          >
                            Advance Value
                          </Typography>
                        </TableCell>

                        <TableCell align="left">
                          <Typography
                            variant="subtitle2"
                            className={classes.headText}
                          >
                            Start Period
                          </Typography>
                        </TableCell>

                        <TableCell align="left">
                          <Typography
                            variant="subtitle2"
                            className={classes.headText}
                          >
                            End Period
                          </Typography>
                        </TableCell>
                      </ThemeProvider>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={4}>
                        <center>
                          <ThemeProvider theme={theme}>
                            <Typography
                              variant="h6"
                              style={{
                                fontWeight: 400,
                                color: "#687083",
                                fontSize: "14px",
                              }}
                            >
                              No Composer Selected Yet
                            </Typography>
                          </ThemeProvider>
                        </center>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Card>
            )}
          </div>
        </Grid>
      </div>

      <Grid
        container
        style={{
          marginTop: "20px",
        }}
      >
        <Grid item xs={4} md={4} sm={4} lg={8} xl={8}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <ThemeProvider theme={theme}>
                <Typography variant="h3" className={classes.subTitle}>
                  Ownership Share Percentage
                </Typography>
              </ThemeProvider>
              <ThemeProvider theme={theme}>
                <p
                  style={{
                    marginTop: "5px",
                    color: "#8f8f8f",
                    fontSize: "14px",
                  }}
                >
                  Feature to set ownership share percentage
                </p>
              </ThemeProvider>
            </div>

            <div>
              <FormControl
                className={classes.formControl}
                fullWidth={true}
                id="dsp-filter"
                style={{ width: "300px" }}
              >
                <TextField
                  fullWidth={true}
                  size="small"
                  className={classes.select}
                  onChange={handleChangeComposerSharePercentage}
                  value={sharePercentageValue}
                  select
                  variant="outlined"
                >
                  <MenuItem value={0}>Select Song</MenuItem>
                  {ownershipShareDropdownList?.map((option, index) => (
                    <MenuItem key={index} value={option.song_id}>
                      {option.title}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </div>
          </div>

          {ownershipShareList?.length > 0 && sharePercentageValue !== 0 ? (
            ownershipShareList?.map((item, index) => (
              <Card
                key={index}
                style={
                  item.song_id !== sharePercentageValue
                    ? { display: "none" }
                    : {
                        border: "1px solid #9AA2B1",
                        borderRadius: "6px",
                        boxShadow: "none",
                        marginTop: "10px",
                      }
                }
              >
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <ThemeProvider theme={theme}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={classes.headText}
                          >
                            Composer/Author Name
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={classes.headText}
                          >
                            Share Percentage
                          </Typography>
                        </TableCell>
                      </ThemeProvider>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {item.composers?.length > 0 ? (
                      item.composers
                        .map(customer => {
                          return (
                            <TableRow
                              hover
                              key={item.song_id + customer.composer_id}
                            >
                              <TableCell>
                                <Typography
                                  variant="subtitle2"
                                  className={classes.bodyTextBlack}
                                >
                                  {customer.composer_name}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                {}
                                {`${customer.ownership_percentage}%`}
                              </TableCell>
                            </TableRow>
                          );
                        })

                        .slice(
                          (pageSharePercentage - 1) *
                            rowsPerPageSharePercentage,
                          pageSharePercentage * rowsPerPageSharePercentage
                        )
                    ) : (
                      <TableRow>
                        <TableCell colSpan={4}>
                          <center>
                            <ThemeProvider theme={theme}>
                              <Typography
                                variant="h6"
                                style={{
                                  fontWeight: 400,
                                  color: "#687083",
                                  fontSize: "14px",
                                }}
                              >
                                no data
                              </Typography>
                            </ThemeProvider>
                          </center>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>

                {sharePercentageSlice?.length > 0 ? (
                  <CardActions
                    className={classes.actions}
                    style={
                      item.song_id !== sharePercentageValue
                        ? { display: "none" }
                        : null
                    }
                  >
                    <Grid
                      alignContent="space-between"
                      container
                      justifyContent="space-between"
                      spacing={3}
                    >
                      <Grid item>
                        <div style={{ display: "flex" }}>
                          <div style={{ marginTop: "7px" }}>
                            <ThemeProvider theme={theme}>
                              <Typography className={classes.paginationText}>
                                Item per page:
                              </Typography>
                            </ThemeProvider>
                          </div>{" "}
                          <div style={{ marginLeft: "10px" }}>
                            {pageSizePaginationSharePercentage()}
                          </div>
                        </div>
                      </Grid>

                      <Grid item>
                        <Pagination
                          count={pagesCountSharePercentage(item.composers)}
                          shape="rounded"
                          className={classes.pagination}
                          page={pageSharePercentage}
                          onChange={handleChangePageSharePercentage}
                        />
                      </Grid>
                    </Grid>
                  </CardActions>
                ) : null}
              </Card>
            ))
          ) : (
            <Card
              style={{
                border: "1px solid #9AA2B1",
                borderRadius: "6px",
                boxShadow: "none",
                marginTop: "10px",
              }}
            >
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <ThemeProvider theme={theme}>
                      <TableCell>
                        <Typography
                          variant="subtitle2"
                          className={classes.headText}
                        >
                          Composer/Author Name
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="subtitle2"
                          className={classes.headText}
                        >
                          Share Percentage
                        </Typography>
                      </TableCell>
                    </ThemeProvider>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={4}>
                      <center>
                        <ThemeProvider theme={theme}>
                          <Typography
                            variant="h6"
                            style={{
                              fontWeight: 400,
                              color: "#687083",
                              fontSize: "14px",
                            }}
                          >
                            No Song Selected Yet
                          </Typography>
                        </ThemeProvider>
                      </center>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Card>
          )}
        </Grid>

        <Grid
          container
          style={{
            marginTop: "20px",
          }}
        >
          <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <Typography
                  component="h1"
                  variant="h3"
                  className={classes.subTitle}
                >
                  Publisher Share
                </Typography>
                <ThemeProvider theme={theme}>
                  <p
                    style={{
                      marginTop: "5px",
                      color: "#8f8f8f",
                      fontSize: "14px",
                    }}
                  >
                    Feature to see the publisher share
                  </p>
                </ThemeProvider>
              </div>

              <div>
                <FormControl
                  className={classes.formControl}
                  fullWidth={true}
                  id="dsp-filter"
                  style={{ width: "300px" }}
                >
                  <TextField
                    fullWidth={true}
                    size="small"
                    className={classes.select}
                    onChange={handleChangePublisherShareFilter}
                    value={publisherShareFilterValue}
                    select
                    variant="outlined"
                  >
                    <MenuItem value={0}>Select Composer</MenuItem>
                    {publisherShareFilterList?.map((option, index) => (
                      <MenuItem key={index} value={option.composer_id}>
                        {option.sure_name}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </div>
            </div>

            {publisherShareList?.length > 0 &&
            publisherShareFilterValue !== 0 ? (
              <Card
                style={{
                  border: "1px solid #9AA2B1",
                  borderRadius: "6px",
                  boxShadow: "none",
                  marginTop: "10px",
                }}
              >
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <ThemeProvider theme={theme}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={classes.headText}
                          >
                            Song
                          </Typography>
                        </TableCell>

                        <TableCell align="left">
                          <Typography
                            variant="subtitle2"
                            className={classes.headText}
                          >
                            Share Percentage
                          </Typography>
                        </TableCell>
                      </ThemeProvider>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {publisherShareList?.length > 0 ? (
                      publisherShareList
                        ?.flat(1)
                        .filter(
                          el => el.composer_id === publisherShareFilterValue
                        )
                        ?.map(customer => (
                          <TableRow hover key={customer?.song_id}>
                            {}
                            {}
                            <TableCell>
                              <Typography
                                variant="subtitle2"
                                className={classes.bodyTextBlack}
                              >
                                {customer?.song_title}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              {}
                              {customer?.publisher_share === 0
                                ? "-"
                                : `${customer?.publisher_share}%`}
                            </TableCell>
                          </TableRow>
                        ))
                        .slice(
                          (pagePublisherShare - 1) * rowsPerPagePublisherShare,
                          pagePublisherShare * rowsPerPagePublisherShare
                        )
                    ) : (
                      <TableRow>
                        <TableCell colSpan={4}>
                          <center>
                            <ThemeProvider theme={theme}>
                              <Typography
                                variant="h6"
                                style={{
                                  fontWeight: 400,
                                  color: "#687083",
                                  fontSize: "14px",
                                }}
                              >
                                no data
                              </Typography>
                            </ThemeProvider>
                          </center>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>

                {publisherShareSlice?.length > 0 ? (
                  <CardActions className={classes.actions}>
                    <Grid
                      alignContent="space-between"
                      container
                      justifyContent="space-between"
                      spacing={3}
                    >
                      <Grid item>
                        <div style={{ display: "flex" }}>
                          <div style={{ marginTop: "7px" }}>
                            <ThemeProvider theme={theme}>
                              <Typography className={classes.paginationText}>
                                Item per page:
                              </Typography>
                            </ThemeProvider>
                          </div>{" "}
                          <div style={{ marginLeft: "10px" }}>
                            {pageSizePaginationPublisherShare()}
                          </div>
                        </div>
                      </Grid>

                      <Grid item>
                        {}
                        <Pagination
                          count={pagesCountPublisherShare(publisherShareList)}
                          shape="rounded"
                          className={classes.pagination}
                          page={pagePublisherShare}
                          onChange={handleChangePagePublisherShare}
                        />
                      </Grid>
                    </Grid>
                  </CardActions>
                ) : null}
              </Card>
            ) : (
              <Card
                style={{
                  border: "1px solid #9AA2B1",
                  borderRadius: "6px",
                  boxShadow: "none",
                  marginTop: "10px",
                }}
              >
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <ThemeProvider theme={theme}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={classes.headText}
                          >
                            Song
                          </Typography>
                        </TableCell>

                        <TableCell align="left">
                          <Typography
                            variant="subtitle2"
                            className={classes.headText}
                          >
                            Share Percentage
                          </Typography>
                        </TableCell>
                      </ThemeProvider>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={4}>
                        <center>
                          <ThemeProvider theme={theme}>
                            <Typography
                              variant="h6"
                              style={{
                                fontWeight: 400,
                                color: "#687083",
                                fontSize: "14px",
                              }}
                            >
                              No Composer Selected Yet
                            </Typography>
                          </ThemeProvider>
                        </center>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Card>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}></Grid>
        </Grid>

        <Grid
          container
          style={{
            marginTop: "20px",
          }}
        >
          <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <Typography
                  component="h1"
                  variant="h3"
                  className={classes.subTitle}
                >
                  Agent
                </Typography>
                <ThemeProvider theme={theme}>
                  <p
                    style={{
                      marginTop: "5px",
                      color: "#8f8f8f",
                      fontSize: "14px",
                    }}
                  >
                    Feature to set Agent & fee Agent
                  </p>
                </ThemeProvider>
              </div>

              <div>
                <FormControl
                  className={classes.formControl}
                  fullWidth={true}
                  id="dsp-filter"
                  style={{ width: "300px" }}
                >
                  <TextField
                    fullWidth={true}
                    size="small"
                    className={classes.select}
                    onChange={handleChangeAgentFilter}
                    value={agentFilterValue}
                    select
                    variant="outlined"
                  >
                    <MenuItem value={0}>Select Composer</MenuItem>
                    {agentFilterValueList?.map((option, index) => (
                      <MenuItem key={index} value={option.composer_id}>
                        {option.sure_name}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </div>
            </div>

            {agentList?.length > 0 && agentFilterValue !== 0 ? (
              agentList?.map((item, index) => (
                <Card
                  key={index}
                  style={
                    item.composer_id === agentFilterValue
                      ? {
                          border: "1px solid #9AA2B1",
                          borderRadius: "6px",
                          boxShadow: "none",
                          marginTop: "10px",
                        }
                      : { display: "none" }
                  }
                >
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <ThemeProvider theme={theme}>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={classes.headText}
                            >
                              Song
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography
                              variant="subtitle2"
                              className={classes.headText}
                            >
                              Agent
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography
                              variant="subtitle2"
                              className={classes.headText}
                            >
                              Agent Fee
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography
                              variant="subtitle2"
                              className={classes.headText}
                            >
                              Fee Calculation Basis
                            </Typography>
                          </TableCell>
                        </ThemeProvider>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {item.agents?.length > 0 ? (
                        item.agents
                          ?.map(customer => (
                            <TableRow hover key={customer?.song_id}>
                              {console.info(customer, "<<<< customerssdkj")}
                              <TableCell>
                                <Typography
                                  variant="subtitle2"
                                  className={classes.bodyTextBlack}
                                >
                                  {customer?.song_title}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                {}
                                {customer?.name}
                                {}
                              </TableCell>
                              <TableCell>
                                {}
                                {customer?.fee === 0
                                  ? "-"
                                  : `${customer?.fee}%`}
                              </TableCell>
                              <TableCell>
                                {}
                                {getFeeTypeAgent(customer.fee_type)}
                              </TableCell>
                            </TableRow>
                          ))
                          .slice(
                            (pageAgent - 1) * rowsPerPageAgent,
                            pageAgent * rowsPerPageAgent
                          )
                      ) : (
                        <TableRow>
                          <TableCell colSpan={4}>
                            <center>
                              <ThemeProvider theme={theme}>
                                <Typography
                                  variant="h6"
                                  style={{
                                    fontWeight: 400,
                                    color: "#687083",
                                    fontSize: "14px",
                                  }}
                                >
                                  no data
                                </Typography>
                              </ThemeProvider>
                            </center>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>

                  {agentSlice.length > 0 ? (
                    <CardActions className={classes.actions}>
                      <Grid
                        alignContent="space-between"
                        container
                        justifyContent="space-between"
                        spacing={3}
                      >
                        <Grid item>
                          <div style={{ display: "flex" }}>
                            <div style={{ marginTop: "7px" }}>
                              <ThemeProvider theme={theme}>
                                <Typography className={classes.paginationText}>
                                  Item per page:
                                </Typography>
                              </ThemeProvider>
                            </div>{" "}
                            <div style={{ marginLeft: "10px" }}>
                              {pageSizePaginationAgent()}
                            </div>
                          </div>
                        </Grid>

                        <Grid item>
                          <Pagination
                            count={pagesCountAgent(item?.agents)}
                            shape="rounded"
                            className={classes.pagination}
                            page={pageAgent}
                            onChange={handleChangePageAgent}
                          />
                        </Grid>
                      </Grid>
                    </CardActions>
                  ) : null}
                </Card>
              ))
            ) : (
              <Card
                style={{
                  border: "1px solid #9AA2B1",
                  borderRadius: "6px",
                  boxShadow: "none",
                  marginTop: "10px",
                }}
              >
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <ThemeProvider theme={theme}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={classes.headText}
                          >
                            Song
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography
                            variant="subtitle2"
                            className={classes.headText}
                          >
                            Agent
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography
                            variant="subtitle2"
                            className={classes.headText}
                          >
                            Agent Fee
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography
                            variant="subtitle2"
                            className={classes.headText}
                          >
                            Fee Calculation Basis
                          </Typography>
                        </TableCell>
                      </ThemeProvider>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={4}>
                        <center>
                          <ThemeProvider theme={theme}>
                            <Typography
                              variant="h6"
                              style={{
                                fontWeight: 400,
                                color: "#687083",
                                fontSize: "14px",
                              }}
                            >
                              No Composer Selected Yet
                            </Typography>
                          </ThemeProvider>
                        </center>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Card>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}></Grid>
        </Grid>

        <Grid item xs={4} md={4} sm={4} lg={4} xl={4}></Grid>
      </Grid>
    </div>
  );
};

export default DetailSongContract;
