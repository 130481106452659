import { Divider } from "@material-ui/core";
import { Box, Container, Grid, Typography } from "@mui/material";
import axios from "axios";
import {
  DateTimeDisplay,
  ModalError,
  Page,
  PrimaryButton,
  PrimaryTextButton,
  SearchTextInput,
  SkeletonComponent,
} from "components";
import { InnoTableV2 } from "inno-ui";
import { HeaderTitle } from "layouts";
import { getTotalPage } from "lib";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { getErrors } from "utils";
import { hardBaseUrl } from "../../../services/urlConstant";
import { globalStyles } from "styles";

function DownloadMonitoring() {
  const classes = globalStyles();
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const [loadingPage, setLoadingPage] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [queryParams, setQueryParams] = useState({
    page: 1,
    per_page: 10,
    search: "",
  });
  const [tableTotalPage, setTableTotalPage] = useState(1);

  const handleChangeQueryParams = (value, key) => {
    setQueryParams(currentState => ({
      ...currentState,
      ...(key !== "page" && { page: 1 }),
      [key]: value,
    }));
  };
  const onClickDownload = download_url =>
    Swal.fire({
      icon: "success",
      title: "Data can only be downloaded for 7 days",
      confirmButtonText: "Close",
      didRender: () => window.open(download_url),
    });
  const makeTextFile = id => {
    const text = "Please Inform this error message to Asaba Team";
    const data = new Blob([text], { type: "text/plain" });
    const downloadLink = window.URL.createObjectURL(data);
    const a = document.createElement("a");
    a.href = downloadLink;
    a.download = `error_message_download_id: ${id}.txt`;
    a.textContent = "Download Error Message";
    a.click();
  };

  const getDataTable = async () => {
    setLoadingPage(true);
    try {
      const response = await axios.get(
        `${hardBaseUrl}/download-monitoring/table`,
        {
          headers,
          params: queryParams,
        }
      );
      const { data, meta } = response?.data;
      setDataTable(data);
      const pageCount = getTotalPage(meta?.total, queryParams?.per_page || 1);
      setTableTotalPage(pageCount);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  const debounceDataTable = useCallback(
    debounce(() => {
      getDataTable();
    }, 500),
    [queryParams]
  );

  useEffect(() => {
    const isSearching = Boolean(queryParams?.search);
    if (isSearching) {
      debounceDataTable();
      return () => {
        debounceDataTable.cancel();
      };
    } else {
      getDataTable();
    }
  }, [queryParams, debounceDataTable]);

  return (
    <Page className={classes.root} title="Download Monitoring">
      {loadingPage ? (
        <SkeletonComponent variant="wave" />
      ) : (
        <Container maxWidth={false}>
          <HeaderTitle
            title="Download Monitoring"
            breadcrumbData={breadcrumbData}
          />
          <Divider className={classes?.divider} />
          <Box mb="16px">
            <SearchTextInput
              placeholder="Search"
              value={queryParams?.search}
              onChange={e =>
                handleChangeQueryParams(e?.target?.value, "search")
              }
              width="auto"
            />
          </Box>
          <InnoTableV2
            isLoading={false}
            columns={columnTable({ makeTextFile, onClickDownload })}
            items={dataTable}
            page={queryParams?.page}
            rowsPerPage={queryParams?.per_page}
            totalPage={tableTotalPage}
            handleChangePage={(_, page) =>
              handleChangeQueryParams(page, "page")
            }
            handleChangeRowsPerPage={event =>
              handleChangeQueryParams(event?.target?.value, "per_page")
            }
          />
        </Container>
      )}
    </Page>
  );
}

const status_progress_condition = {
  failed: "Failed",
  "ready-to-download": "Ready to Download",
  "in-progress": "In Progress",
  archived: "Archived",
};
const download_type_condition = {
  "song-list": "Song List",
  dashboard: "Dashboard",
  "song-usage": "Song Usage",
  report: "Report",
  "double-claim": "Double Claim",
};
const breadcrumbData = [
  {
    label: "Home",
    link: "/admin/dashboard",
  },
  {
    label: "Download Monitoring",
    active: true,
  },
];
const columnTable = ({ makeTextFile, onClickDownload }) => [
  {
    name: "download_id",
    title: "Download ID",
  },
  {
    name: "download_type",
    title: "Download Type",
    renderText: item => download_type_condition[item] || item,
  },
  {
    name: "start_date",
    title: "Start Date",
    renderText: item => <DateTimeDisplay date={item} />,
  },
  {
    name: "end_date",
    title: "End Date",
    renderText: item => <DateTimeDisplay date={item} />,
  },
  {
    name: "all",
    title: "Status",
    renderText: item => {
      const { process_status } = item;
      return status_progress_condition[process_status];
    },
  },
  {
    name: "all",
    title: "Download",
    renderText: item => {
      const { process_status, download_url, end_date } = item;
      const isInProgress = process_status === "in-progress";
      const isFailed = process_status === "failed";

      // Calculate the difference in days between the end date and today
      const endDate = new Date(end_date);
      const today = new Date();
      const differenceInDays = Math.ceil(
        (today - endDate) / (1000 * 60 * 60 * 24)
      );

      return (
        <Grid container direction="column">
          {isFailed ? (
            <Grid item>
              <PrimaryButton
                label="Detail"
                onClick={() =>
                  Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: "Please Inform this error message to Asaba Team",
                  })
                }
              />
            </Grid>
          ) : (
            <>
              <Grid item>
                <PrimaryButton
                  disabled={
                    !isInProgress && !isFailed && differenceInDays <= 7
                      ? false
                      : true
                  }
                  label="Download"
                  onClick={() => onClickDownload(download_url)}
                />
              </Grid>
              <Grid item>
                <Typography variant="caption" mt={1}>
                  Only Available for 7 days
                </Typography>
              </Grid>
            </>
          )}
        </Grid>
      );
    },
  },
];

export default DownloadMonitoring;
