import {
  faEnvelope,
  faMapMarkerAlt,
  faPhoneAlt,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { Button, Col, Modal, Pagination, Row, Select } from "antd";
import Axios from "axios";
import { useEffect, useState } from "react";
import "react-multi-carousel/lib/styles.css";
import { useLocation } from "react-router-dom";
import pencipta from "../../../assets/img/pencipta.png";
import Layout from "../../../mpisLandingComponent/Layout";
import { hardBaseUrl, hardSubDomain } from "../../../services/urlConstant";
import "./DetailLagu.css";

const { Option } = Select;

export default function DetailLagu() {
  let data = useLocation();
  const [active, setActive] = useState(0);
  const [visible, setVisible] = useState(false);
  const [dataLagu, setDataLagu] = useState({
    data: [],
    meta: {
      total: 0,
    },
  });
  const [dataComposer, setDataComposer] = useState([]);
  const [dataModal, setDataModal] = useState();
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState(data.state.search);
  const initState = {
    sure_name: "",
    total_song: 0,
    songs: [],
  };
  const [composerSearch, setComposerSearch] = useState(initState);
  const [detailComposer, setDetailComposer] = useState({});
  const [selectSearch, setSelectSearch] = useState([]);
  const [currentPageApi, setCurrentPageApi] = useState(1);
  const totalSizeApi = 10;
  const rowsPerPage = 10;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));

  const getDataSearchComposser = async () => {
    try {
      const response = await Axios.get(
        hardBaseUrl +
          `/search/song/composer?search=${search}&subdomain=${hardSubDomain}`
      );
      setDataComposer(response.data.data === null ? [] : response.data.data);
    } catch (error) {
      new Error(error);
    }
  };

  const getDataSearch = async () => {
    setComposerSearch(initState);
    const url =
      hardBaseUrl +
      `/search/song/result?search=${search}&field=${data.state.field}&page=${page}&size=10&subdomain=${hardSubDomain}`;
    try {
      const response = await Axios.get(url);
      setDataLagu(
        response.data.data === null
          ? {
              data: [],
              meta: {
                total: 0,
              },
            }
          : response.data
      );
    } catch (error) {
      new Error(error);
    }
  };

  const searchComposser = async res => {
    setActive(0);
    const url =
      hardBaseUrl +
      `/search/song/composer/${
        res ? res.composer.composer_id : detailComposer.composer.composer_id
      }?subdomain=${hardSubDomain}&page=${page}&size=${rowsPerPage}`;
    try {
      const response = await Axios.get(url);
      setComposerSearch(response.data.data);
    } catch (error) {
      new Error(error);
    }
  };

  const resultLagu =
    composerSearch.sure_name === "" ? dataLagu.data : composerSearch.songs;

  const options = selectSearch.map(d => <Option key={d}>{`${d}`}</Option>);

  const handleSearch = async e => {
    try {
      const response = await Axios.get(
        hardBaseUrl +
          `/search/song/suggestion?search=${e}&subdomain=${hardSubDomain}`
      );
      setSelectSearch(response.data.data[data.state.field]);
    } catch (error) {
      new Error(error);
    }
  };

  const handleDetailComposer = () => {
    setVisible(true);
    setDataModal(composerSearch);
  };

  const hanldeCloseModal = () => {
    setVisible(false);
    setDataModal();
  };

  const onChangePage = value => {
    setCurrentPageApi(value + 1);
  };

  useEffect(() => {
    if (data.state.search || data.state.field) {
      getDataSearchComposser();
    }
  }, [search]);

  useEffect(() => {
    if (data.state.search || data.state.field) {
      getDataSearch();
    }
  }, [page, search, currentPageApi, totalSizeApi, rowsPerPage]);

  const truncate = (str, n) => {
    return str?.length > n ? str.substr(0, n - 1) + "..." : str;
  };

  return (
    <Layout>
      <Modal
        title={false}
        centered
        visible={visible}
        onOk={hanldeCloseModal}
        onCancel={hanldeCloseModal}
        width={800}
        footer={false}
        header={false}
      >
        {dataModal && (
          <div className={isMobile ? "" : "modal-custom"}>
            <div className="head-modal">
              {dataModal["song"] ? (
                isMobile ? null : (
                  <div className="icon-lagu">
                    <i
                      className="fa fa-music"
                      style={{ color: "#687083", fontSize: "24px" }}
                    />
                  </div>
                )
              ) : (
                <img
                  src={pencipta}
                  alt=""
                  style={{
                    width: "80px",
                    height: "80px",
                    objectFit: "contain",
                  }}
                />
              )}
              <div
                style={
                  isMobile
                    ? null
                    : {
                        height: "40px",
                        marginLeft: "20px",
                        color: "#687083",
                      }
                }
              >
                <h3 style={{ margin: 0 }}>
                  {dataModal["song"]
                    ? dataModal.performer.first_name +
                      " " +
                      dataModal.performer.last_name +
                      " - " +
                      dataModal.song.song_title
                    : dataModal.sure_name}
                </h3>
                {dataModal["song"] ? (
                  <p>
                    Pencipta{" "}
                    {dataModal.composers.map(
                      val => "'" + val.alias_name + "' "
                    )}
                  </p>
                ) : (
                  <p>Pencipta Music</p>
                )}
              </div>
            </div>
            {isMobile ? null : <hr style={{ borderColor: "#F4ECFD" }} />}

            {dataModal["song"] && (
              <>
                <div
                  className="body-modal"
                  style={{ marginTop: "18px", marginBottom: "18px" }}
                >
                  <Row style={{ fontSize: "16px", color: "#687083" }}>
                    <Col span={6}>Performer</Col>
                    <Col span={18}>: {dataModal.performer.first_name}</Col>
                  </Row>
                  <Row style={{ fontSize: "16px", color: "#687083" }}>
                    <Col span={6}>Publisher</Col>
                    <Col span={18}>: {dataModal.publisher.name}</Col>
                  </Row>
                </div>
                <hr style={{ borderColor: "#F4ECFD", fontSize: "16px" }} />
              </>
            )}
            <div
              className="footer"
              style={{ marginTop: "24px", color: "#687083" }}
            >
              <h2 style={{ marginTop: -20 }}>
                Informasi Publisher
                {}
              </h2>
              {dataModal["song"] && (
                <p>
                  <FontAwesomeIcon
                    icon={faMapMarkerAlt}
                    style={{ marginRight: "10px" }}
                  />
                  {dataModal.publisher.address}
                </p>
              )}
              <p>
                <FontAwesomeIcon
                  icon={faPhoneAlt}
                  style={{ marginRight: "10px" }}
                />
                {dataModal["song"]
                  ? dataModal.publisher.phone
                  : "(021) 3807236 / (021) 3440934"}
              </p>
              <p>
                <FontAwesomeIcon
                  icon={faEnvelope}
                  style={{ marginRight: "10px" }}
                />
                {dataModal["song"]
                  ? dataModal.publisher.email
                  : "tamawicitra@sample.com"}
              </p>
            </div>
          </div>
        )}
      </Modal>
      <div className="detail-lagu-wrapper">
        <div
          className={isMobile ? "detail-lagu" : "detail-lagu container"}
          style={
            isMobile
              ? {
                  paddingLeft: "20px",
                  paddingRight: "20px",
                  paddingTop: "50px",
                }
              : { paddingTop: "50px" }
          }
        >
          <Select
            suffixIcon={<FontAwesomeIcon icon={faSearch} />}
            style={{ width: "100%", height: "35px" }}
            size="large"
            showSearch
            defaultActiveFirstOption={false}
            filterOption={false}
            onSearch={handleSearch}
            value={search}
            onSelect={e => setSearch(e)}
            notFoundContent={null}
          >
            {options}
          </Select>

          <div className="button-group">
            {composerSearch.sure_name === "" ? (
              <>
                <Button
                  className={active === 0 && "active"}
                  onClick={() => setActive(0)}
                >{`All(${dataComposer?.length + dataLagu.meta.total})`}</Button>
                <Button
                  className={active === 1 && "active"}
                  onClick={() => setActive(1)}
                >{`Composer(${dataComposer?.length})`}</Button>
                <Button
                  className={active === 2 && "active"}
                  onClick={() => setActive(2)}
                >{`Song(${dataLagu.meta.total})`}</Button>
              </>
            ) : (
              <Button
                className={"active"}
                onClick={() => setComposerSearch(initState)}
              >
                Cancel
              </Button>
            )}
          </div>
          <hr style={{ border: "1px solid #E4E7EB" }} />

          {dataComposer?.length > 0 ? (
            <>
              {(active === 0 || active === 1) && (
                <div className="pencipta">
                  {composerSearch.alias_name === "" && (
                    <h1 style={{ fontSize: "30px", margin: 0 }}>Composer</h1>
                  )}
                  {composerSearch.sure_name === "" ? (
                    <div
                      style={
                        isMobile
                          ? { display: "flex", justifyContent: "center" }
                          : null
                      }
                    >
                      {dataComposer.map((res, i) => {
                        return (
                          <div
                            className="content-carousel"
                            onDragStart={e => e.preventDefault()}
                            onClick={() => {
                              setDetailComposer(res);
                              searchComposser(res);
                            }}
                            key={i}
                          >
                            <div
                              className="content"
                              style={{ textAlign: "center" }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  height: "150px",
                                }}
                              >
                                <img src={pencipta} alt="" />
                              </div>
                              <h2 style={{ marginBottom: "0px" }}>
                                {res.composer.alias_name}
                              </h2>
                              <p
                                style={{ color: "#9AA2B1" }}
                              >{`${res.total_song} Lagu`}</p>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <div className="composer-detail">
                      <img src={pencipta} alt="" />
                      <div className="content">
                        <h1
                          className="detail-composer"
                          onClick={handleDetailComposer}
                        >
                          {composerSearch.sure_name}
                        </h1>
                        <p>Pencipta Lagu</p>
                        <p
                          style={{ color: "#9545EB" }}
                        >{`Merilis ${composerSearch.total_song} Song`}</p>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </>
          ) : (
            (active === 0 || active === 1) && "No Data"
          )}
          {dataLagu?.data?.length > 0 ? (
            <>
              {(active === 0 || active === 2) && (
                <div className="lagu">
                  {isMobile ? (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <h1 style={{ fontSize: "30px", margin: 0 }}>Song</h1>
                    </div>
                  ) : isTablet ? (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <h1 style={{ fontSize: "30px", margin: 0 }}>Song</h1>
                    </div>
                  ) : (
                    <h1 style={{ fontSize: "30px", margin: 0 }}>Song</h1>
                  )}

                  <div
                    style={
                      isMobile
                        ? { width: "100%", overflowX: "auto", display: "flex" }
                        : isTablet
                        ? { display: "flex", justifyContent: "center" }
                        : null
                    }
                  >
                    <div>
                      {resultLagu.map((res, i) => {
                        let composer = "";
                        res.composers.map(
                          (val, index) =>
                            (composer =
                              composer +
                              val.alias_name +
                              (index !== res?.composers?.length - 1
                                ? ", "
                                : ""))
                        );
                        return (
                          <div
                            key={i}
                            style={{ marginTop: "25px", marginBottom: "16px" }}
                            onClick={() => {
                              setVisible(true);
                              setDataModal(res);
                            }}
                          >
                            <div
                              className={
                                isMobile
                                  ? "content-mobile"
                                  : isTablet
                                  ? "content-mobile"
                                  : "content"
                              }
                            >
                              <Row
                                style={
                                  isMobile
                                    ? {}
                                    : {
                                        width: "100%",
                                        display: "flex",
                                        alignItems: "center",
                                      }
                                }
                              >
                                {isMobile ? null : isTablet ? null : (
                                  <Col span={2}>
                                    <div className="icon-lagu">
                                      <i
                                        className="fa fa-music"
                                        style={{
                                          color: "#687083",
                                          fontSize: "22px",
                                        }}
                                      />
                                    </div>
                                  </Col>
                                )}

                                <Col span={4}>
                                  {isMobile ? (
                                    <div>
                                      <p
                                        style={{
                                          fontWeight: "bold",
                                          paddingTop: "25px",
                                          paddingLeft: "10px",
                                        }}
                                      >
                                        {truncate(res.song.song_title, 13)}
                                      </p>
                                    </div>
                                  ) : isTablet ? (
                                    <div>
                                      <p
                                        style={{
                                          fontWeight: "bold",
                                          paddingTop: "25px",
                                          paddingLeft: "10px",
                                        }}
                                      >
                                        {truncate(res.song.song_title, 13)}
                                      </p>
                                    </div>
                                  ) : (
                                    <h2
                                      style={{
                                        marginLeft: "16px",
                                        margin: 0,
                                        width: "90%",
                                        marginRight: "10px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {res.song.song_title}
                                    </h2>
                                  )}
                                </Col>
                                <Col span={isMobile ? 3 : isTablet ? 3 : 2}>
                                  {isMobile ? (
                                    <div style={{ paddingTop: "20px" }}>
                                      <p
                                        style={{
                                          margin: 0,
                                          fontSize: "13px",
                                          fontWeight: "bold",
                                          color: "#000",
                                          marginRight: "10px",
                                        }}
                                      >
                                        Composer
                                      </p>

                                      <p
                                        style={{
                                          margin: 0,

                                          color: "#000",
                                        }}
                                      >
                                        {truncate(composer, 15)}
                                      </p>
                                    </div>
                                  ) : isTablet ? (
                                    <div style={{ paddingTop: "18px" }}>
                                      <p
                                        style={{
                                          margin: 0,
                                          fontSize: "13px",
                                          fontWeight: "bold",
                                          color: "#000",
                                          marginRight: "10px",
                                        }}
                                      >
                                        Composer
                                      </p>

                                      <p
                                        style={{
                                          margin: 0,

                                          color: "#000",
                                        }}
                                      >
                                        {truncate(composer, 15)}
                                      </p>
                                    </div>
                                  ) : (
                                    <div>
                                      <p
                                        style={{
                                          margin: 0,
                                          fontSize: "13px",

                                          color: "#000",
                                          marginRight: "10px",
                                        }}
                                      >
                                        Composer
                                      </p>

                                      <p
                                        style={{
                                          margin: 0,
                                          fontSize: "13px",
                                          fontWeight: "bold",
                                          color: "#000",
                                          marginRight: "10px",
                                        }}
                                      >
                                        {composer?.length > 26
                                          ? composer.substring(0, 26) + "..."
                                          : composer}
                                      </p>
                                    </div>
                                  )}
                                </Col>
                                <Col span={isMobile ? 4 : isTablet ? 4 : 3}>
                                  {isMobile ? (
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        paddingTop: "20px",
                                      }}
                                    >
                                      <div>
                                        <p
                                          style={{
                                            margin: 0,
                                            fontSize: "13px",
                                            fontWeight: "bold",
                                            color: "#000",
                                          }}
                                        >
                                          Performer
                                        </p>
                                        <p
                                          style={{
                                            margin: 0,
                                            fontSize: "17px",
                                            fontWeight: "bold",
                                            color: "#000",
                                            width: "90%",
                                          }}
                                        >
                                          {"-"}
                                        </p>
                                      </div>
                                    </div>
                                  ) : isTablet ? (
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <div>
                                        <p
                                          style={{
                                            margin: 0,
                                            fontSize: "13px",
                                            fontWeight: "bold",
                                            color: "#000",
                                          }}
                                        >
                                          Performer
                                        </p>
                                        <p
                                          style={{
                                            margin: 0,
                                            fontSize: "17px",
                                            fontWeight: "bold",
                                            color: "#000",
                                            width: "90%",
                                          }}
                                        >
                                          {"-"}
                                        </p>
                                      </div>
                                    </div>
                                  ) : (
                                    <div>
                                      <p
                                        style={{
                                          margin: 0,
                                          fontSize: "13px",

                                          color: "#000",
                                        }}
                                      >
                                        Performer
                                      </p>
                                      <p
                                        style={{
                                          margin: 0,
                                          fontSize: "17px",
                                          fontWeight: "bold",
                                          color: "#000",
                                          width: "90%",
                                        }}
                                      >
                                        {"-"}
                                      </p>
                                    </div>
                                  )}
                                </Col>

                                <Col span={6}>
                                  {isMobile ? (
                                    <div style={{ paddingTop: "20px" }}>
                                      <p
                                        style={{
                                          margin: 0,
                                          fontSize: "13px",
                                          fontWeight: "bold",
                                          color: "#000",
                                        }}
                                      >
                                        Publisher
                                      </p>
                                      <p
                                        style={{
                                          margin: 0,
                                          color: "#000",
                                          width: "90%",
                                        }}
                                      >
                                        {truncate(res.publisher.name, 15)}
                                      </p>
                                    </div>
                                  ) : isTablet ? (
                                    <div>
                                      <p
                                        style={{
                                          margin: 0,
                                          fontSize: "13px",
                                          fontWeight: "bold",
                                          color: "#000",
                                        }}
                                      >
                                        Publisher
                                      </p>
                                      <p
                                        style={{
                                          margin: 0,
                                          color: "#000",
                                          width: "90%",
                                        }}
                                      >
                                        {truncate(res.publisher.name, 15)}
                                      </p>
                                    </div>
                                  ) : (
                                    <div>
                                      <p
                                        style={{
                                          margin: 0,
                                          fontSize: "13px",
                                          color: "#000",
                                        }}
                                      >
                                        Publisher
                                      </p>
                                      <p
                                        style={{
                                          margin: 0,
                                          color: "#000",
                                          width: "90%",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {truncate(res.publisher.name, 25)}
                                      </p>
                                    </div>
                                  )}
                                </Col>
                                <Col span={4}>
                                  {isMobile ? (
                                    <div style={{ paddingTop: "20px" }}>
                                      <p
                                        style={{
                                          margin: 0,
                                          fontSize: "13px",
                                          fontWeight: "bold",
                                          color: "#000",
                                        }}
                                      >
                                        Ownership Percentage
                                      </p>
                                      <p
                                        style={{
                                          margin: 0,
                                          fontSize: "17px",
                                          fontWeight: "bold",
                                          color: "#000",
                                        }}
                                      >
                                        {"-"}
                                      </p>
                                    </div>
                                  ) : isTablet ? (
                                    <div>
                                      <p
                                        style={{
                                          margin: 0,
                                          fontSize: "13px",
                                          fontWeight: "bold",
                                          color: "#000",
                                        }}
                                      >
                                        Ownership Percentage
                                      </p>
                                      <p
                                        style={{
                                          margin: 0,
                                          fontSize: "17px",
                                          fontWeight: "bold",
                                          color: "#000",
                                        }}
                                      >
                                        {"-"}
                                      </p>
                                    </div>
                                  ) : (
                                    <div>
                                      <p
                                        style={{
                                          margin: 0,
                                          fontSize: "13px",
                                          color: "#000",
                                        }}
                                      >
                                        Ownership Percentage
                                      </p>
                                      <p
                                        style={{
                                          margin: 0,
                                          fontSize: "17px",
                                          fontWeight: "bold",
                                          color: "#000",
                                        }}
                                      >
                                        {"-"}
                                      </p>
                                    </div>
                                  )}
                                </Col>
                                <Col span={3}>
                                  {isMobile ? (
                                    <div style={{ paddingTop: "20px" }}>
                                      <p
                                        style={{
                                          margin: 0,
                                          fontSize: "13px",
                                          fontWeight: "bold",

                                          color: "#000",
                                        }}
                                      >
                                        Ownership Indicator
                                      </p>
                                      <p
                                        style={{
                                          margin: 0,
                                          fontSize: "17px",
                                          fontWeight: "bold",
                                          color: "#000",
                                        }}
                                      >
                                        {"-"}
                                      </p>
                                    </div>
                                  ) : isTablet ? (
                                    <div>
                                      <p
                                        style={{
                                          margin: 0,
                                          fontSize: "13px",
                                          fontWeight: "bold",

                                          color: "#000",
                                        }}
                                      >
                                        Ownership Indicator
                                      </p>
                                      <p
                                        style={{
                                          margin: 0,
                                          fontSize: "17px",
                                          fontWeight: "bold",
                                          color: "#000",
                                        }}
                                      >
                                        {"-"}
                                      </p>
                                    </div>
                                  ) : (
                                    <div>
                                      <p
                                        style={{
                                          margin: 0,
                                          fontSize: "13px",

                                          color: "#000",
                                        }}
                                      >
                                        Ownership Indicator
                                      </p>
                                      <p
                                        style={{
                                          margin: 0,
                                          fontSize: "17px",
                                          fontWeight: "bold",
                                          color: "#000",
                                        }}
                                      >
                                        {"-"}
                                      </p>
                                    </div>
                                  )}
                                </Col>
                              </Row>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "24px",
                    }}
                  >
                    {composerSearch.sure_name === "" ? (
                      <Pagination
                        defaultCurrent={1}
                        total={dataLagu.meta.total}
                        onChange={a => setPage(a)}
                      />
                    ) : (
                      <Pagination
                        defaultCurrent={1}
                        total={composerSearch.total_song}
                        onChange={onChangePage}
                      />
                    )}
                  </div>
                </div>
              )}
            </>
          ) : (
            active === 2 && "No Data"
          )}
        </div>
      </div>
    </Layout>
  );
}
