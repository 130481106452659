import {
  Breadcrumbs,
  Card,
  CardContent,
  Container,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  Typography,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import { InnoImage } from "inno-ui";
import _ from "lodash";
import { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { useLocation } from "react-router-dom";
import { Page } from "components";
import { hardBaseUrl } from "../../../../services/urlConstant";

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  label: {
    fontWeight: 500,
    fontSize: "14px",
    color: "#364052",
    fontStyle: "normal",
    lineHeight: "20px",
    marginBottom: "10px",
  },
  body: {
    fontWeight: 400,
    fontSize: "16px",
    color: "#111827",
    lineHeight: "24px",
    marginBottom: "10px",
  },

  subTitle: {
    fontSize: "18px",
    fontWeight: 700,
    color: " #111827",
  },
  breadcrumbs: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#111827",
  },
  breadCrumbsActive: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#687083",
  },
  button: {
    marginBottom: "20px",
    marginTop: "20px",
    backgroundColor: "black",
    color: "white",
    width: "135px",
    height: "40px",
    textTransform: "none",
    "&:hover": {
      color: "white",
      backgroundColor: "black",
    },
  },
  rootSwitch: {
    width: "40px",
    height: "20px",
    padding: "0px",
    borderRadius: "10px",

    marginRight: "8px",
    position: "relative",
  },
  switchBase: {
    padding: "10px",
    color: "white",
    "&.Mui-checked": {},
    "&.Mui-checked + $track": {
      border: "none",
      color: "white",
      backgroundColor: "black",
      opacity: 1,
    },
  },
  checked: {},
  thumb: {
    color: "white",
    position: "absolute",
    width: "16px",
    height: "16px",
  },
  track: {
    backgroundColor: "#E4E7EB",
    borderRadius: "25px",
    border: "0px solid #606060",
    height: "auto",
    opacity: 1,
  },
  inputFields: {
    height: "44px",
    paddingTop: "0px",
    paddingBottom: "0px",
  },
  myTextField: {
    height: "44px",
    paddingBottom: "0px",
    paddingTop: "0px",
  },
  selectPadding: {
    "& .MuiSelect-outlined": {
      paddingTop: "11px",
      paddingBottom: "11px",
      paddingLeft: "20px",
      paddingRight: "30px",
    },
  },
}));

const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

export default function DetailDSP() {
  const classes = useStyles();
  const dataDSP = useLocation().state;

  const [clientName, setClientName] = useState("");
  const [logo, setLogo] = useState("");
  const [color, setColor] = useState("");
  const [penanggungJawab, setPenanggungJawab] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const [clientType, setClientType] = useState("");

  const [alamat, setAlamat] = useState("");
  const [clientTypeList, setClientTypeList] = useState([]);

  const [trans_fee, setTrans_fee] = useState(0);
  const [curr_name, setCurr_name] = useState("");
  const [clientTypeNew, setClientTypeNew] = useState("");

  const [checkedVal, setcheckedVal] = useState(false);
  const [clientClassification, setClientClassification] = useState("");
  const [domicile, setDomicile] = useState("");

  const getDetail = () => {
    const token = localStorage.getItem("token");
    const url = `${hardBaseUrl}/dsp/${dataDSP.row.dsp_id}`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(url, { headers })
      .then(res => {
        setClientName(res.data.data.name);
        setDomicile(res?.data?.data?.domicile);
        setLogo(res.data.data.profile_image);
        setColor(res.data.data.color);
        setPenanggungJawab(res.data.data.person_name);
        setEmail(res.data.data.person_email);
        setPhone(res.data.data.person_phone);

        setClientType(
          res.data.data.client_classification.client_classification_id
        );

        setAlamat(res.data.data.address);
        setcheckedVal(true);
        setTrans_fee(res.data.data.transaction_fee);

        setClientClassification(
          res.data.data.client_classification.classification_name
        );
        setClientTypeNew(res.data.data.type);

        const the_token = localStorage.getItem("token");

        const currency_url = `${hardBaseUrl}/currency/datatable`;
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${the_token}`,
        };
        axios.get(currency_url, { headers }).then(resCurr => {
          let namCurr = _.find(resCurr.data.data, function(o) {
            return o.id === res.data.data.currency_id;
          });
          if (typeof namCurr !== "undefined") {
            setCurr_name(namCurr.code);
          }
        });
      })
      .catch(() => {});
  };

  const getListClient = () => {
    const token = localStorage.getItem("token");
    const url = `${hardBaseUrl}/publisher/classification/index`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(url, { headers })
      .then(res => {
        setClientTypeList(res.data.data);
      })
      .catch(() => {});
  };
  useEffect(() => {
    getListClient();

    getDetail();
  }, []);

  const handleChecked = () => {
    setcheckedVal(prev => !prev);
  };

  const getClientTypeName = id => {
    const data = clientTypeList.find(
      item => item.client_classification_id === id
    );
    return data ? data.classification_name : "-";
  };
  return (
    <Page className={classes.root} title="Personalisasi">
      <Container maxWidth={false}>
        <Grid
          alignItems="flex-end"
          container
          justifyContent="space-between"
          spacing={3}
        >
          <Grid item>
            <ThemeProvider theme={theme}>
              <Typography
                style={{
                  fontSize: "24px",
                  fontWeight: "700",
                  lineHeight: "32px",
                }}
              >
                Client Detail
              </Typography>
            </ThemeProvider>
          </Grid>
          <Grid item>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography className={classes.breadCrumbs}>Home</Typography>

              <Typography className={classes.breadCrumbs}>Parameter</Typography>

              <Typography className={classes.breadCrumbs}>Client</Typography>

              <Typography className={classes.breadCrumbsActive}>
                Client Detail
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
        <hr style={{ border: "1px solid #E4E7EB", marginTop: "32px" }} />
        <Grid container spacing={2} style={{ marginTop: "32px" }}>
          <Grid item xs={4} sm={6} md={6} lg={6} xl={6}>
            <div>
              <ThemeProvider theme={theme}>
                <Typography variant="h5" className={classes.subTitle}>
                  Information DSP
                </Typography>
              </ThemeProvider>
              <ThemeProvider theme={theme}>
                <p
                  style={{
                    marginTop: "5px",
                    color: "#8f8f8f",
                    fontSize: "14px",
                  }}
                >
                  Information about DSP that has been joined to us
                </p>
              </ThemeProvider>
            </div>

            <div>
              <ThemeProvider theme={theme}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <div>
                      <Typography variant="subtitle2" className={classes.label}>
                        Client Type
                      </Typography>
                      <Typography
                        variant="body1"
                        className={classes.body}
                        style={{
                          display: "none",
                        }}
                      >
                        {getClientTypeName(clientType)}
                      </Typography>
                      <Typography
                        variant="body1"
                        className={classes.body}
                        style={{
                          textTransform: "uppercase",
                        }}
                      >
                        {clientTypeNew}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Typography variant="subtitle2" className={classes.label}>
                      Client Classification
                    </Typography>
                    <Typography variant="body1" className={classes.body}>
                      {clientClassification !== "" ? clientClassification : "-"}
                    </Typography>
                  </Grid>
                </Grid>
                {}
              </ThemeProvider>
            </div>
            <div>
              <Card
                style={{
                  border: "1px solid #9AA2B1",
                  borderRadius: "6px",
                  boxShadow: "none",
                }}
              >
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                      <ThemeProvider theme={theme}>
                        <Typography
                          variant="subtitle2"
                          className={classes.label}
                        >
                          Client Name
                        </Typography>
                        <Typography variant="body1" className={classes.body}>
                          {clientName || "-"}
                        </Typography>
                        {logo !== "" ? (
                          <>
                            <Typography
                              variant="subtitle2"
                              className={classes.label}
                            >
                              Logo
                            </Typography>
                            <a href={dataDSP.link}>
                              <InnoImage
                                src={logo}
                                alt=""
                                styleImageContainer={{
                                  padding: 0,
                                }}
                                styleImage={{
                                  width: "160px",
                                  height: "160px",
                                }}
                                fill={true}
                              />
                            </a>
                          </>
                        ) : null}
                      </ThemeProvider>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                      <ThemeProvider theme={theme}>
                        <Typography
                          variant="subtitle2"
                          className={classes.label}
                        >
                          Domicile
                        </Typography>
                        <Typography variant="body1" className={classes.body}>
                          {domicile || "-"}
                        </Typography>
                      </ThemeProvider>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                      <ThemeProvider theme={theme}>
                        <Typography
                          variant="subtitle2"
                          className={classes.label}
                        >
                          Identity Color
                        </Typography>
                        <div
                          style={{
                            width: "48px",
                            height: "24px",
                            backgroundColor: color,
                            marginTop: "5px",
                          }}
                        />
                      </ThemeProvider>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              {}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <button
                style={{
                  width: "86px",
                  height: "36px",
                  backgroundColor: "#111827",
                  color: "white",
                  marginTop: "20px",
                  borderRadius: "6px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  window.location.href = "/admin/parameter/dsp";
                }}
              >
                Close
              </button>
            </div>
          </Grid>
          <Grid item xs={4} sm={6} md={6} lg={6} xl={6}>
            <div>
              <ThemeProvider theme={theme}>
                <Typography variant="h5" className={classes.subTitle}>
                  PIC
                </Typography>
              </ThemeProvider>
              <ThemeProvider theme={theme}>
                <p
                  style={{
                    marginTop: "5px",
                    color: "#8f8f8f",
                    fontSize: "14px",
                  }}
                >
                  Information about the person responsible
                </p>
              </ThemeProvider>
            </div>

            <div
              style={{
                marginTop: "45px",
              }}
            >
              <Card
                style={{
                  border: "1px solid #9AA2B1",
                  borderRadius: "6px",
                  boxShadow: "none",
                }}
              >
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                      <ThemeProvider theme={theme}>
                        <Typography
                          variant="subtitle2"
                          className={classes.label}
                        >
                          PIC Name
                        </Typography>
                        <Typography variant="body1" className={classes.body}>
                          {penanggungJawab}
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          className={classes.label}
                        >
                          Email
                        </Typography>
                        <Typography variant="body1" className={classes.body}>
                          {email}
                        </Typography>
                      </ThemeProvider>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                      <ThemeProvider theme={theme}>
                        <Typography
                          variant="subtitle2"
                          className={classes.label}
                        >
                          Phone Number
                        </Typography>
                        <Typography variant="body1" className={classes.body}>
                          {phone}
                        </Typography>
                        {}
                      </ThemeProvider>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <ThemeProvider theme={theme}>
                        <Typography
                          variant="subtitle2"
                          className={classes.label}
                        >
                          Address
                        </Typography>
                        <Typography variant="body1" className={classes.body}>
                          {alamat || "-"}
                        </Typography>
                        {}
                      </ThemeProvider>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <div
                style={{
                  marginTop: "45px",
                }}
              >
                <ThemeProvider theme={theme}>
                  <Typography variant="h5" className={classes.subTitle}>
                    Transfer Fee & Currency
                  </Typography>
                </ThemeProvider>
                <ThemeProvider theme={theme}>
                  <p
                    style={{
                      marginTop: "5px",
                      color: "#8f8f8f",
                      fontSize: "14px",
                    }}
                  >
                    To Settings Transfer Fee & Currency of Client
                  </p>
                </ThemeProvider>
              </div>
              <Card
                style={{
                  border: "1px solid #9AA2B1",
                  borderRadius: "6px",
                  boxShadow: "none",
                  marginTop: "20px",
                }}
              >
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                      <ThemeProvider theme={theme}>
                        <Typography
                          variant="subtitle2"
                          className={classes.label}
                        >
                          Currency
                        </Typography>
                      </ThemeProvider>
                      <ThemeProvider theme={theme}>
                        <Typography
                          variant="subtitle2"
                          className={classes.label}
                        >
                          {curr_name === "" ? <>{"-"}</> : <>{curr_name}</>}
                        </Typography>
                      </ThemeProvider>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                      <ThemeProvider theme={theme}>
                        <Typography
                          variant="subtitle2"
                          className={classes.label}
                        >
                          Transfer Fee
                        </Typography>
                      </ThemeProvider>
                      <NumberFormat
                        value={trans_fee}
                        displayType={"text"}
                        thousandSeparator="."
                        decimalSeparator=","
                        thousandsGroupStyle="thousand"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}></Grid>
                  </Grid>
                </CardContent>
              </Card>
              <div
                style={{
                  marginLeft: "10px",
                  marginTop: "12px",
                  display: "none",
                }}
              >
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        color={"default"}
                        focusVisibleClassName={classes.focusVisible}
                        disableRipple
                        classes={{
                          root: classes.rootSwitch,
                          switchBase: classes.switchBase,
                          thumb: classes.thumb,
                          track: classes.track,
                          checked: classes.checked,
                        }}
                        checked={checkedVal}
                        onChange={handleChecked}
                        id={`${dataDSP.row.dsp_id}`}
                      />
                    }
                    label="View Transaction"
                  />
                </FormGroup>
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
