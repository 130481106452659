import {
  AppBar,
  Avatar,
  Badge,
  Button,
  ClickAwayListener,
  Divider,
  Grow,
  Hidden,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Toolbar,
  Typography,
  colors,
  createTheme,
} from "@material-ui/core";
import { ExitToApp, Person, Settings } from "@material-ui/icons";
import MenuIcon from "@material-ui/icons/Menu";
import UnfoldMoreIcon from "@material-ui/icons/UnfoldMore";
import { ThemeProvider, makeStyles } from "@material-ui/styles";
import Axios from "axios";
import clsx from "clsx";
import { InnoImage } from "inno-ui";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import BellBottom from "../../assets/img/bellBottom.png";
import BellPng from "../../assets/img/bellPng.png";
import MpisNewLogo from "../../assets/img/mpisNewLogo.png";
import PricingModal from "../../components/molecules/Modal/PricingModal";
import NotificationsPopover from "../../components/molecules/NotificationsPopover";
import { logout } from "../../redux/actions/auth";
import { hardBaseUrl, hardSubDomain } from "../../services/urlConstant";
import { clearCookie } from "../../utils/constants";
import ChatBar from "./ChatBar";

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: "none",
  },
  containerTopBar: {
    width: "208px",
    height: "64px",
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
  },
  flexGrow: {
    flexGrow: 1,
  },
  search: {
    backgroundColor: "rgba(255,255,255, 0.1)",
    borderRadius: 4,
    flexBasis: 300,
    height: 36,
    padding: theme.spacing(0, 2),
    display: "flex",
    alignItems: "center",
  },
  searchIcon: {
    marginRight: theme.spacing(2),
    color: "inherit",
  },
  searchInput: {
    flexGrow: 1,
    color: "inherit",
    "& input::placeholder": {
      opacity: 1,
      color: "inherit",
    },
  },
  searchPopper: {
    zIndex: theme.zIndex.appBar + 100,
  },
  searchPopperContent: {
    marginTop: theme.spacing(1),
  },
  trialButton: {
    marginLeft: theme.spacing(2),
    color: theme.palette.common.white,
    backgroundColor: colors.green[600],
    "&:hover": {
      backgroundColor: colors.green[900],
    },
  },
  trialIcon: {
    marginRight: theme.spacing(1),
  },
  menuButton: {
    marginRight: theme.spacing(1),
    color: "#111827",
  },
  chatButton: {
    marginLeft: theme.spacing(1),
  },
  notificationsButton: {
    marginLeft: theme.spacing(1),
    color: "#787a78",
  },
  notificationsBadge: {
    backgroundColor: colors.orange[600],
    color: "white",
  },
  logoutButton: {
    marginLeft: theme.spacing(1),
  },
  logoutIcon: {
    marginRight: theme.spacing(1),
  },
  profile: {
    paddingTop: theme.spacing(1.3),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  name: {
    fontSize: "16px",
    fontWeight: 500,
  },
  email: {
    fontSize: "14px",
    fontWeight: 400,
    color: "#737373",
    marginBottom: "6px",
  },
  avatar: {
    marginLeft: theme.spacing(1.5),
  },
}));
const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

function TopBar({
  onOpenNavBarMobile,
  className,
  userProfileImage,
  userFirstLetter,
  notifications,
  notificationStatus,
  theRole,
  theName,
  nameOfRole,
  imgOfPublisher,
  userLogin,
  ...rest
}) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const notificationsRef = useRef(null);
  const [openNotifications, setOpenNotifications] = useState(false);
  const [openChatBar, setOpenChatBar] = useState(false);
  const [pricingModalOpen, setPricingModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElComposer, setAnchorElComposer] = React.useState(null);
  const [composerList, setComposerist] = useState([]);
  const isComposer = theRole === "composer";
  const typeWeb = localStorage.getItem("typeWeb") || "mpis";

  const handleLogout = async () => {
    await dispatch(logout());
    const defaultOptions = {
      baseURL: hardBaseUrl + "/auth/logout",
      headers: {
        "Content-Type": "application/json",
      },
    };

    let url_after_logout = localStorage.getItem("url_login");
    let instance = Axios.create(defaultOptions);

    // Set the AUTH token for any request
    instance.interceptors.request.use(function (config) {
      const token = localStorage.getItem("token");
      config.headers.Authorization = token ? `Bearer ${token}` : "";
      return config;
    });
    const response = await instance.post();
    if (response.data.message === "success") {
      clearCookie();
      history.push(url_after_logout);
    }
  };

  const handlePricingModalClose = () => {
    setPricingModalOpen(false);
  };

  const handleChatBarClose = () => {
    setOpenChatBar(false);
  };

  const handleNotificationsOpen = () => {
    setOpenNotifications(true);
  };

  const handleNotificationsClose = () => {
    setOpenNotifications(false);
  };

  const getDetail = () => {
    const token = localStorage.getItem("token");
    const url = "/composer-manager/composers";

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    // history.push("/pencipta/dashboard-pencipta");
    Axios.get(hardBaseUrl + url, { headers })
      .then(res => {
        let theArr = res.data.data;
        let newArr = [];
        theArr.forEach(item => {
          let tempObj = {
            composer_id: item.composer_id,
            composer_name: item.composer_name,
            is_current_composer: item.is_current_composer,
            selected: false,
          };
          newArr.push(tempObj);
        });
        setComposerist(newArr);
      })
      .catch(() => { });
  };
  useEffect(() => {
    if (userLogin !== null) {
      if (userLogin.role.type === "composer") {
        getDetail();
      }
    }
  }, [userLogin]);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickComposer = event => {
    setAnchorElComposer(event.currentTarget);
  };

  const handleCloseComposer = () => {
    setAnchorElComposer(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickProfile = () => {
    setAnchorEl(null);
    if (userLogin?.role?.type === "composer") {
      history.push("/pencipta/profile-pencipta");
    } else {
      history.push("/admin/profile");
    }
  };

  const handleKonfigurasi = e => {
    if (userLogin?.role?.type === "admin") {
      history.push("/admin/konfigurasi/sistem");
    } else if (userLogin?.role?.type === "publisher") {
      if (hardSubDomain !== null) {
        history.push("/admin/konfigurasi/personalisasi");
      } else {
        e.preventDefault();
      }
    } else {
      e.preventDefault();
    }
  };

  const getLogo = () => {
    if (typeWeb === "caris") {
      if (
        userLogin?.role?.type === "publisher" ||
        userLogin?.role?.type === "composer"
      ) {
        if (nameOfRole === "Role Pencipta" || nameOfRole === "Pencipta") {
          return (
            <img
              alt="Logos"
              src={imgOfPublisher}
              style={{
                height: "56px",
                margin: "auto",
              }}
            />
          );
        } else {
          if (userLogin?.publisher?.profile_image === "") {
            return (
              <div>
                <span>{theName}</span>
              </div>
            );
          } else {
            return (
              <InnoImage
                alt="Logo"
                src={userLogin?.publisher?.profile_image}
                styleImage={{
                  maxWidth: "174px",
                  width: "100%",
                  height: "auto",
                  maxHeight: "80px",
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginTop: "auto",
                  marginBottom: "auto",
                }}
              />
            );
          }
        }
      } else {
        return (
          <img
            alt="Logos"
            src={MpisNewLogo}
            style={{
              margin: "auto",
            }}
          />
        );
      }
    } else {
      return <img alt="Logos" src={MpisNewLogo} rel="preload" />;
    }
  };

  const GetKonfigurasiMenu = () => {
    if (typeWeb === "mpis") {
      if (userLogin?.role?.type === "publisher") {
        return null;
      } else if (userLogin?.role?.type === "admin") {
        return (
          <MenuItem onClick={e => handleKonfigurasi(e)}>
            <Settings style={{ marginRight: "8px", color: "#a3a3a3" }} />
            Konfigurasi
          </MenuItem>
        );
      }
    } else {
      if (userLogin?.role?.type === "composer") {
        return null;
      } else {
        return (
          <MenuItem onClick={e => handleKonfigurasi(e)}>
            <Settings style={{ marginRight: "8px", color: "#a3a3a3" }} />
            Konfigurasi
          </MenuItem>
        );
      }
    }
  };

  const handleChangeComposer = async id => {
    try {
      let token = localStorage.getItem("token");
      let resultLogin = await Axios.put(
        hardBaseUrl + `/composer-manager/set-composer/${id}`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      if (resultLogin.data.message === "success") {
        setAnchorElComposer(null);
        localStorage.setItem("token", resultLogin.data.data.access_token);
        if (history.location.pathname === "/pencipta/dashboard-pencipta") {
          window.location.reload();
        } else {
          history.push("/pencipta/dashboard-pencipta");
        }
      }
    } catch (error) {
      new Error(error);
    }
  };

  const handleRedirectAddPayment = () => {
    history.push({
      pathname: "/admin/payment/add",
    });
    handleNotificationsClose();
  };

  const handleChangeRole = id => {
    let token = localStorage.getItem("token");
    let url = hardBaseUrl + "/switch-role";
    const payload = {
      role_id: id,
    };
    const roleId = localStorage?.getItem("role_id");
    if (roleId !== String(id)) {
      localStorage.setItem("role_id", id);
      Axios.post(url, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (history.location.pathname === "/admin/dashboard") {
        window.location.reload();
      } else {
        history.push("/admin/dashboard");
      }
    } else {
      handleCloseComposer();
    }
  };
  const RenderSwitchRole = () => {
    const listRole = isComposer ? composerList : userLogin?.user_roles;
    return (
      <MenuList style={{ minWidth: "195px" }} id="simple-menu-composer">
        {listRole?.map((item, idx) => (
          <div
            key={`${isComposer ? item?.composer_id : item?.role_id}-${idx}`}
            style={{ textTransform: "capitalize" }}
          >
            <MenuItem
              onClick={() =>
                isComposer
                  ? handleChangeComposer(item?.composer_id)
                  : handleChangeRole(item?.role_id)
              }
            >
              {isComposer
                ? item?.composer_name.toLowerCase()
                : item?.name.toLowerCase()}{" "}
            </MenuItem>
          </div>
        ))}
      </MenuList>
    );
  };

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
      style={{
        backgroundColor: "#FFFFFF",
        borderBottom: "1px solid #E4E7EB",
      }}
    >
      <Toolbar>
        <Hidden lgUp>
          <IconButton
            className={classes.menuButton}
            color="inherit"
            onClick={onOpenNavBarMobile}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
        <div className={classes.containerTopBar}>
          <RouterLink
            to={
              isComposer ? "/pencipta/dashboard-pencipta" : "/admin/dashboard"
            }
          >
            <div>
              {getLogo()}
            </div>
          </RouterLink>
        </div>
        <div className={classes.flexGrow} />
        <Hidden mdDown>
          <Button
            onClick={handleClickComposer}
            variant="outlined"
            style={{
              height: "50px",
              borderRadius: "8px",
            }}
          >
            <ThemeProvider theme={theme}>
              <Typography
                style={{
                  fontSize: "16px",
                  fontWeight: 600,
                  textTransform: "capitalize",
                }}
              >
                {isComposer
                  ? userLogin?.name.toLowerCase()
                  : userLogin?.role?.name.toLowerCase()}{" "}
              </Typography>
            </ThemeProvider>
            <UnfoldMoreIcon
              fontSize="small"
              style={{ marginLeft: "8px", color: "#9ca3af" }}
            />
          </Button>

          <IconButton
            className={classes.notificationsButton}
            color="inherit"
            onClick={handleNotificationsOpen}
            ref={notificationsRef}
          >
            <Badge
              badgeContent={notificationStatus}
              classes={{ badge: classes.notificationsBadge }}
            >
              <div style={{ marginTop: "-15px" }}>
                <div>
                  <img src={BellPng} alt="notification" />
                </div>
                <div style={{ marginTop: "-25px", marginBottom: "-15px" }}>
                  <img src={BellBottom} alt="notification" />
                </div>
              </div>
            </Badge>
          </IconButton>
          {userProfileImage !== null ? (
            <Avatar
              className={classes.avatar}
              onClick={handleClick}
              data-testid="data-test-button-open-profile"
              alt="Remy Sharp"
              src={userProfileImage}
            />
          ) : (
            <Avatar className={classes.avatar} onClick={handleClick} data-testid="data-test-button-open-profile">
              {userFirstLetter}
            </Avatar>
          )}
          <Popper
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            role={undefined}
            placement="bottom-end"
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom-start" ? "left top" : "left bottom",
                }}
              >
                <Paper>
                  <div className={classes.profile}>
                    <Typography className={classes.name}>
                      {userLogin?.name}
                    </Typography>
                    <Typography className={classes.email}>
                      {userLogin?.email}
                    </Typography>
                    <Divider />
                  </div>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList style={{ paddingLeft: "-10px" }} id="simple-menu">
                      <MenuItem onClick={handleClickProfile}>
                        <Person
                          style={{ marginRight: "8px", color: "#a3a3a3" }}
                        />
                        Profile
                      </MenuItem>
                      <GetKonfigurasiMenu />
                      {/* <MenuItem onClick={handleChangePassword}>Change Password</MenuItem> */}
                      <MenuItem style={{ color: "red" }} onClick={handleLogout} data-testid="data-test-button-logout">
                        <ExitToApp style={{ marginRight: "8px" }} />
                        Logout
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>

          <Popper
            open={Boolean(anchorElComposer)}
            anchorEl={anchorElComposer}
            role={undefined}
            placement="bottom-start"
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom-start" ? "left top" : "left bottom",
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleCloseComposer}>
                    <RenderSwitchRole />
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Hidden>
      </Toolbar>

      <NotificationsPopover
        anchorEl={notificationsRef.current}
        notifications={notifications}
        onClose={handleNotificationsClose}
        open={openNotifications}
        // markAsRead={markAsRead}
        markAsRead={handleRedirectAddPayment}
      />
      <PricingModal onClose={handlePricingModalClose} open={pricingModalOpen} />
      <ChatBar onClose={handleChatBarClose} open={openChatBar} />
    </AppBar>
  );
}

TopBar.propTypes = {
  userFirstLetter: PropTypes.string,
  userProfileImage: PropTypes.string,
  className: PropTypes.string,
  onOpenNavBarMobile: PropTypes.func,
  // loadingComponent: PropTypes.bool,
};

export default TopBar;
