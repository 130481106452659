import { Container, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Grid } from "@mui/material";
import axios from "axios";
import {
  AutoCompleteComponent,
  ButtonGroupTop,
  ModalError,
  ModalSuccess,
  ModalWarning,
  Page,
  PrimaryButton,
  SecondaryButton,
  SectionLabel,
  SkeletonComponent,
  StatusChip,
} from "components";
import { InnoTable } from "inno-ui";
import { HeaderTitle } from "layouts";
import { getTotalPage } from "lib";
import { useEffect, useState } from "react";
import { formatDate, getErrors, wordCapitalize, getCookie } from "utils";
import { hardBaseUrl } from "../../../../services/urlConstant";

function SubReportingApproval() {
  const classes = useStyles();
  const userLogin = getCookie("auth", `${process.env.REACT_APP_NAME}_user`);
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const [loadingPage, setLoadingPage] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [queryParams, setQueryParams] = useState({
    page: 1,
    limit: 10,
    search: "",
    dsp_id: "",
    publisher_id: "",
    order: "",
  });
  const [tableTotalPage, setTableTotalPage] = useState(1);
  const [optionDsp, setOptionDsp] = useState([]);
  const [optionPublisher, setOptionPublisher] = useState([]);

  const handleChangeQueryParams = (value, key) => {
    setQueryParams(currentState => ({
      ...currentState,
      ...(key !== "page" && { page: 1 }),
      [key]: value,
    }));
  };

  const getDataTable = async () => {
    try {
      setLoadingPage(true);
      const res = await axios.get(
        `${hardBaseUrl}/publisher/sub-reporting/approval-list`,
        {
          headers,
          params: queryParams,
        }
      );
      const { data, meta } = res?.data;
      setDataTable(data || []);
      const pageCount = getTotalPage(meta?.total, queryParams?.limit || 1);
      setTableTotalPage(pageCount);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  const getOptionDSP = async () => {
    try {
      setLoadingPage(true);
      const res = await axios.get(
        `${hardBaseUrl}/publishers/list/${userLogin?.publisher?.publisher_id}/dsps`,
        { headers }
      );
      const modifiedData = res?.data?.data?.map(item => ({
        ...item,
        id: item?.dsp_id,
        label: item?.name,
      }));
      setOptionDsp(modifiedData);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  const getOptionPublisher = async () => {
    try {
      setLoadingPage(true);
      const res = await axios.get(`${hardBaseUrl}/publisher/list-publisher`, {
        headers,
      });
      const modifiedData = res?.data?.data?.map(item => ({
        ...item,
        id: item?.publisher_id,
        label: item?.name,
      }));
      setOptionPublisher(modifiedData);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  const handleApproval = async (item, status) => {
    await axios
      .put(
        `${hardBaseUrl}/publisher/sub-reporting/approval/${item?.publisher_sub_reporting_id}`,
        { status },
        { headers }
      )
      .then(() => {
        ModalSuccess(`Succesfully ${status} Sub Reporting`).then(
          res => res.isConfirmed && window.location.reload()
        );
      })
      .catch(error => ModalError(getErrors(error?.response)));
  };

  useEffect(() => {
    getOptionDSP();
    getOptionPublisher();
  }, []);

  useEffect(() => {
    getDataTable();
  }, [queryParams]);

  return (
    <Page className={classes.root} title="Sub Reporting Approval">
      {loadingPage ? (
        <SkeletonComponent variant="wave" />
      ) : (
        <Container maxWidth={false}>
          <HeaderTitle title="Configuration" breadcrumbData={breadcrumbData} />
          <ButtonGroupTop />
          <Divider className={classes.divider} />
          <SectionLabel
            title="Sub Reporting Approval"
            subTitle="Approve & Reject Sub Reporting request from MPIS Publisher"
          />
          <Grid container justifyContent="right" columnSpacing={2} my="16px">
            <Grid>
              <AutoCompleteComponent
                options={optionDsp}
                label="Select DSP"
                value={
                  optionDsp?.find(
                    option => option.id === queryParams?.dsp_id
                  ) || null
                }
                onChange={value => handleChangeQueryParams(value, "dsp_id")}
                size="small"
              />
            </Grid>
            <Grid item>
              <AutoCompleteComponent
                options={optionPublisher}
                label="Select Publisher"
                value={
                  optionPublisher?.find(
                    option => option.id === queryParams?.publisher_id
                  ) || null
                }
                onChange={value =>
                  handleChangeQueryParams(value, "publisher_id")
                }
                size="small"
              />
            </Grid>
          </Grid>
          <InnoTable
            isLoading={false}
            columns={columnTable({ handleApproval })}
            items={dataTable}
            page={queryParams?.page}
            rowsPerPage={queryParams?.limit}
            totalPage={tableTotalPage}
            handleChangePage={(_, page) =>
              handleChangeQueryParams(page, "page")
            }
            handleChangeRowsPerPage={e =>
              handleChangeQueryParams(e?.target?.value, "limit")
            }
          />
        </Container>
      )}
    </Page>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
}));
const breadcrumbData = [
  {
    label: "Home",
    link: "/admin/dashboard",
  },
  {
    label: "Configuration",
    link: "/admin/konfigurasi/sub-reporting-approval",
  },
  {
    label: "Sub Reporting Approval",
    active: true,
  },
];
const columnTable = ({ handleApproval }) => [
  {
    name: "publisher_name",
    title: "Publisher",
  },
  {
    name: "dsp_name",
    title: "DSP",
  },
  {
    name: "start_date",
    title: "Start Date",
    renderText: item => formatDate(item),
  },
  {
    name: "end_date",
    title: "End Date",
    renderText: item => formatDate(item),
  },
  {
    name: "status",
    title: "Approval Status",
    renderText: status => (
      <StatusChip
        type={
          status === "approved"
            ? "success"
            : status === "rejected"
              ? "danger"
              : status === "waiting"
                ? "warning"
                : ""
        }
        label={
          status == "waiting" ? "Waiting for Approval" : wordCapitalize(status)
        }
      />
    ),
  },
  {
    name: "all",
    title: "Action",
    renderText: item => {
      const isWaiting = item?.status === "waiting";
      return isWaiting ? (
        <Grid container spacing={1}>
          <Grid item>
            <PrimaryButton
              label="Approve"
              onClick={() =>
                ModalWarning(
                  "Approve Sub Reporting",
                  "Are you sure to approve this sub reporting ?"
                ).then(res => {
                  if (res?.isConfirmed) {
                    handleApproval(item, "approved");
                  }
                })
              }
            />
          </Grid>
          <Grid item>
            <SecondaryButton
              label="Reject"
              onClick={() =>
                ModalWarning(
                  "Reject Sub Reporting",
                  "Are you sure to reject this sub reporting ?"
                ).then(res => {
                  if (res?.isConfirmed) {
                    handleApproval(item, "rejected");
                  }
                })
              }
            />
          </Grid>
        </Grid>
      ) : (
        "-"
      );
    },
  },
];
export default SubReportingApproval;
