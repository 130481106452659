import { Button, Divider, Typography } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { BackupOutlined } from "@material-ui/icons";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import { makeStyles } from "@material-ui/styles";
import { useRef } from "react";

const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  results: {
    marginTop: theme.spacing(3),
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  btnBack: {
    backgroundColor: "#25383c",
    color: "#fff",
    marginLeft: "10px",
    marginRight: "20px",
  },
  btnSubmit: {
    border: "1px solid lightgrey",
  },
}));

const DialogImportDokumen = ({
  open,
  onClose,
  onSubmit,
  getRootProps,
  getInputProps,
  isDragActive,
  files,
}) => {
  const classes = useStyles();
  const dialogRef = useRef();

  return (
    <div>
      <Dialog
        size="small"
        open={open}
        onClose={onClose}
        aria-labelledby="responsive-dialog-title"
        ref={dialogRef}
      >
        <DialogTitle id="responsive-dialog-title">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <ThemeProvider theme={theme}>
              <Typography
                style={{
                  fontSize: "16px",
                  fontWeight: 700,
                }}
              >
                {"Import Document"}
              </Typography>
            </ThemeProvider>

            <CloseOutlinedIcon
              onClick={onClose}
              style={{
                color: "#9AA2B1",
                cursor: "pointer",
                width: "12px",
                height: "12px",
                marginTop: "8px",
              }}
            />
          </div>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <DialogContentText>
            <ThemeProvider theme={theme}>
              <Typography
                style={{
                  fontSize: "14px",
                  fontWeight: 500,
                }}
              >
                Upload Document
              </Typography>
            </ThemeProvider>
          </DialogContentText>
          <div className="">
            <div {...getRootProps}>
              <input {...getInputProps()} />
              {}

              {isDragActive ? (
                <>
                  <BackupOutlined style={{ color: "grey", fontSize: "40px" }} />
                  <p>Drop the files here ...</p>
                </>
              ) : (
                <>
                  <BackupOutlined style={{ color: "grey", fontSize: "40px" }} />
                  <Typography component="p" variant="h6">
                    <p>
                      <b>Search File</b>, Drag Document Here
                    </p>
                  </Typography>
                </>
              )}
            </div>
            <div
              style={{
                marginTop: "20px",
              }}
            >
              <aside>
                <ThemeProvider theme={theme}>
                  <Typography
                    style={{
                      fontSize: "14px",
                      fontWeight: 500,
                      marginBottom: "10px",
                    }}
                  >
                    Uploaded File
                  </Typography>
                  <ul
                    style={{
                      listStyle: "none",
                    }}
                  >
                    {files}
                  </ul>
                </ThemeProvider>
              </aside>
            </div>
          </div>
        </DialogContent>
        <Divider className={classes.divider} />
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            marginBottom: "20px",
          }}
        >
          <Button
            className={classes.btnSubmit}
            variant="outlined"
            onClick={onClose}
          >
            Cancel
          </Button>

          <Button className={classes.btnBack} onClick={onSubmit}>
            Upload
          </Button>
        </div>
      </Dialog>
    </div>
  );
};

export default DialogImportDokumen;
