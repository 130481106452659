import { Fragment, useEffect, useRef, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Skeleton,
  styled,
  Typography,
} from "@mui/material";
import axios from "axios";
import {
  AutoCompleteComponent,
  DatePicker,
  FormLabel,
  ModalError,
  PrimaryButton,
  SecondaryButton,
} from "components";
import moment from "moment";
import { getErrors } from "utils";
import { hardBaseUrl } from "../../../../services/urlConstant";
import Swal from "sweetalert2";

const DialogForm = ({
  open,
  onClose,
  optionPublisher,
  optionDsp,
  reFetch,
  preloadId,
}) => {
  const dialogRef = useRef();
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const [loadingPage, setLoadingPage] = useState(false);
  const [payload, setPayload] = useState({
    sub_reporting_publisher_id: 0,
    dsp_id: 0,
    start_date: moment().startOf("month"),
    end_date: moment(),
  });
  const { sub_reporting_publisher_id, dsp_id, start_date, end_date } = payload;

  const handleChangePayload = (value, key) => {
    setPayload(currentState => ({
      ...currentState,
      [key]: value,
    }));
  };
  const onChangeStartDate = date => {
    if (end_date && moment(date).isAfter(end_date)) {
      handleChangePayload(date, "end_date");
    }
    handleChangePayload(date, "start_date");
  };
  const onChangeEndDate = date => {
    if (start_date && moment(date).isBefore(start_date)) {
      handleChangePayload(date, "start_date");
    }
    handleChangePayload(date, "end_date");
  };
  const validatePayload = () => {
    const errors = [];
    const errorEmpty = error => `${error} can't be empty.`;

    Object.entries(payload).forEach(([key, value]) => {
      switch (key) {
        case "sub_reporting_publisher_id":
          if (!value) errors.push(errorEmpty("Publisher"));
          break;
        case "dsp_id":
          if (!value) errors.push(errorEmpty("DSP"));
          break;
        default:
          break;
      }
    });
    return errors;
  };

  const getDetail = async () => {
    try {
      setLoadingPage(true);
      const res = await axios.get(
        `${hardBaseUrl}/publisher/sub-reporting/${preloadId}`,
        { headers }
      );
      const { data } = res?.data;
      setPayload({
        sub_reporting_publisher_id: data?.sub_reporting_publisher_id,
        dsp_id: data?.dsp_id,
        start_date: moment(data?.start_date),
        end_date: moment(data?.end_date),
      });
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  const handeSubmit = async () => {
    const errors = validatePayload();
    const method = preloadId ? axios.put : axios.post;
    if (errors?.length) {
      ModalError(errors[0]);
      return;
    }
    const newPayload = {
      ...payload,
      start_date: moment(payload?.start_date).format("YYYY-MM-DD"),
      end_date: moment(payload?.end_date).format("YYYY-MM-DD"),
    };

    try {
      await method(
        `${hardBaseUrl}/publisher/sub-reporting${preloadId ? "/" + preloadId : ""
        }`,
        newPayload,
        { headers }
      );
      Swal.fire({
        text: `Succesfully ${preloadId ? "Save" : "Create"} Sub Reporting`,
        onOk: reFetch(),
        icon: "success",
        title: "Success"
      });
    } catch (error) {
      ModalError(getErrors(error?.response));
    }
  };

  useEffect(() => {
    if (preloadId) {
      getDetail();
    } else {
      setPayload({
        sub_reporting_publisher_id: 0,
        dsp_id: 0,
        start_date: moment().startOf("month"),
        end_date: moment(),
      });
    }
  }, [preloadId]);

  return (
    <Dialog size="small" open={open} ref={dialogRef}>
      {loadingPage ? (
        <Skeleton variant="rounded" height={460} width={600} animation="wave" />
      ) : (
        <Fragment>
          <ModalTitle>
            <Typography fontSize={18} fontWeight={600}>{`${preloadId ? "Edit" : "Add"
              } Sub Reporting`}</Typography>
          </ModalTitle>
          <DialogContent>
            <Grid container rowSpacing={2}>
              <Grid item xs={12}>
                <FormLabel label="Publisher" required />
                <AutoCompleteComponent
                  label={sub_reporting_publisher_id ? null : "Publisher"}
                  value={
                    optionPublisher.find(
                      option => option.id === sub_reporting_publisher_id
                    ) || null
                  }
                  onChange={value =>
                    handleChangePayload(value, "sub_reporting_publisher_id")
                  }
                  options={optionPublisher}
                  size="small"
                  width="100%"
                />
              </Grid>
              <Grid item xs={12}>
                <FormLabel label="DSP" required />
                <AutoCompleteComponent
                  label={dsp_id ? null : "DSP"}
                  value={optionDsp.find(option => option.id === dsp_id) || null}
                  onChange={value => handleChangePayload(value, "dsp_id")}
                  options={optionDsp}
                  size="small"
                  width="100%"
                />
              </Grid>
              <Grid item xs={12}>
                <FormLabel label="Start Date" required />
                <DatePicker value={start_date} onChange={onChangeStartDate} />
              </Grid>
              <Grid item xs={12}>
                <FormLabel label="End Date" required />
                <DatePicker value={end_date} onChange={onChangeEndDate} />
              </Grid>
            </Grid>
          </DialogContent>
          <ModalAction>
            <SecondaryButton label="Cancel" onClick={onClose} />
            <PrimaryButton
              label={preloadId ? "Save" : "Add"}
              onClick={handeSubmit}
            />
          </ModalAction>
        </Fragment>
      )}
    </Dialog>
  );
};
const ModalTitle = styled(DialogTitle)(() => ({
  borderBottom: "1px solid #e1e1e1",
  padding: "16px 24px 8px",
  marginBottom: "16px",
}));
const ModalAction = styled(DialogActions)(() => ({
  borderTop: "1px solid #e1e1e1",
}));
export default DialogForm;
