import {
  Container,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  Box,
  Snackbar,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import { InnoTable } from "inno-ui";
import React, { useEffect, useState } from "react";
import { Page, SkeletonComponent } from "components";
import {
  ButtonWithModalPerItem,
  PrimaryButton,
} from "../../../../components/atoms/Button";
import { ButtonGroupTop } from "../../../../components/atoms/Button";
import HeaderPage from "../../../../layouts/Header/HeaderPage";
import { emptyText } from "../../../../utils";
import Swal from "sweetalert2";
import RefreshVector from "../../../../assets/img/refresh-cw.svg";
import { hardBaseUrl } from "../../../../services/urlConstant";
import DialogEditUserPublisher from "./DialogEditUserPublisher";
import DialogTambahUserPublisher from "./DialogTambahUserPublisher";
import { getCookie } from "../../../../utils/constants";

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  results: {
    marginTop: theme.spacing(3),
  },
  content: {
    marginTop: "20px",
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  formControl: {
    width: "180px",
  },
  pageSize: {
    height: "30px",
    borderRadius: "6px",
    border: "1px solid #D1D5DC",
    paddingLeft: "5px",
    paddingRight: "5px",
    marginTop: "5px",
  },
  paginationText: {
    fontSize: "14px",
    fontWeight: "normal",
    fontStyle: "normal",
  },
  inputFields: {
    maxWidth: 268,
    "& .MuiOutlinedInput-input": {
      padding: "0px",
      height: "44px",
    },
  },
  selectPadding: {
    "& .MuiSelect-outlined": {
      paddingTop: "14px",
      paddingBottom: "14px",
      paddingLeft: "20px",
      paddingRight: "30px",
    },
  },
  notif: {
    zIndex: "20000 !important"
  },
  buttonAdd: {
    backgroundColor: "black",
    color: "white",
    paddingTop: "15px",
    paddingBottom: "15px",
    width: "auto",
    height: "44px",
  },
  textAdd: {
    fontSize: "14px",
    fontWeight: "400",
    fontStyle: "normal",
    textTransform: "none",
    lineHeight: "24px",
  },
  labelSelect: {
    "& .MuiInputLabel-outlined": {
      zIndex: "1",
      pointerEvents: "none",
    },
  },
  ib: {
    marginLeft: "8px",
    padding: "4px",
    color: "white",
    backgroundColor: "black",
    borderRadius: "6px",
    "&:focus": {
      color: "white",
      backgroundColor: "black",
    },
    "&:active": {
      boxShadow: "none",
      color: "white",
      backgroundColor: "black",
    },
    "&:hover": {
      color: "white",
      backgroundColor: "black",
      boxShadow: "none",
    },
    "&:hover, &.MuiIconButton": {
      background: "#374151 !important",
    },
  },
  mt20: {
    marginTop: 20,
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function UserPublisher() {
  let theToken = localStorage.getItem("token");
  const classes = useStyles();
  const [response, setResponse] = useState([]);
  const [page, setPage] = useState(1);
  const [searchKey, setSearchKey] = useState("");
  const [type, setType] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalData, setTotalData] = useState(1);
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [name, setName] = useState("");
  const [role, setRole] = useState("");
  const [publisher, setPublisher] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confrimPassword, setConfrimPassword] = useState("");

  const [id] = useState();
  const [roleList, setRoleList] = useState([]);
  const [publisherList, setPublisherList] = useState([]);
  const [openSnack, setOpenSnack] = useState(false);
  const [typeSnackbar, setTypeSnackbar] = useState("success");
  const [message, setMessage] = useState("This is a message!");
  const [loadingTable, setLoadingTable] = useState(false);
  const userLogin = getCookie("auth", `${process.env.REACT_APP_NAME}_user`);

  const handleOpen = () => {
    setOpen(true);
    setName("");
    setRole("");
    setPublisher("");
    setPhone("");
    setEmail("");
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const pagesCount = Math.ceil(totalData / rowsPerPage);

  const handleCloseSnackbar = () => {
    setOpenSnack(false);
  };

  const getResponse = () => {
    setLoadingTable(true);
    const url = `${hardBaseUrl}/user`;
    const params = {
      page,
      size: rowsPerPage,
      search: searchKey,
      role_id: type,
      type: "publisher",
    };
    axios
      .get(url, {
        params,
        headers: {
          Authorization: `Bearer ${theToken}`,
        },
      })
      .then(res => {
        setResponse(res.data.data);
        setTotalData(res.data.meta.total);
        setPage(res.data.meta.page);
        setLoadingTable(false);
      })
      .catch(() => {
        setLoadingTable(false);
      });
  };

  const handleChangeSelect = event => {
    setType(event.target.value);
    setPage(1);
  };

  const handleChangeRowsPerPage = event => {
    event.preventDefault();
    setRowsPerPage(event.target.value);

    setPage(1);
  };

  const handleChange = event => {
    const name = event.target.name;
    const value = event.target.value;
    if (name === "name") {
      setName(value);
    } else if (name === "role") {
      setRole(value);
    } else if (name === "publisher") {
      setPublisher(value);
    } else if (name === "phone") {
      setPhone(value);
    } else if (name === "email") {
      setEmail(value);
    } else if (name === "password") {
      setPassword(value);
    } else if (name === "konfirmasiPassword") {
      setConfrimPassword(value);
    }
  };

  const handleChangeSelectRole = e => {
    setRole(e.target.value);
  };

  const handleChangeSelectPublisher = e => {
    setPublisher(e.target.value);
  };
  const notifError = (text, confrimed = () => setOpen(true)) =>
    Swal.fire({
      title: "Oops…",
      icon: "error",
      text: text,
      showCancelButton: false,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK",
      zIndex: 1000,
      customClass: {
        container: classes.notif
      }
    }).then(result => {
      if (result.isConfirmed) {
        confrimed();
      }
    });

  const regexEmail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

  const onSubmitAdd = e => {
    e.preventDefault();
    if (name === "") {
      return notifError("Username can't be empty");
    }
    if (!role) {
      return notifError("Role can't be empty");
    }
    if (!publisher) {
      return notifError("Publisher can't be empty");
    }

    if (password === "") {
      return notifError("Password can't be empty");
    }
    if (confrimPassword === "") {
      return notifError("Confirm password can't be empty");
    }
    if (email === "") {
      return notifError("Email address can't be empty");
    }
    if (!regexEmail.test(email)) {
      return notifError(
        "Please enter your email address in format: yourname@example.com"
      );
    }
    if (password !== confrimPassword) {
      return notifError("The confirmation password does not match");
    }
    if (
      name !== "" &&
      email !== "" &&
      password !== "" &&
      role !== "" &&
      publisher !== "" &&
      confrimPassword &&
      password === confrimPassword &&
      regexEmail.test(email)
    ) {
      setLoadingTable(true);
      const url = `${hardBaseUrl}/user`;
      const data = {
        name: name,
        email: email,
        phone: phone,
        role_id: role,
        publisher_id: publisher,
        password: password,
      };
      axios
        .post(url, data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${theToken}`,
          },
        })
        .then(() => {
          Swal.fire({
            icon: "success",
            title: "Berhasil",
            text: "Data added successfully",
          }).then(result => {
            if (result.isConfirmed === true) {
              getResponse();
            }
          });
        })
        .catch((err) => {
          const textError = err?.response?.data?.errors[0]?.message || err;
          Swal.fire({
            icon: "error",
            title: "Error",
            text: textError || "Something Wrong",
            customClass: {
              container: classes.notif
            }
          });
          new Error(err);
        })
        .finally(() => {
          setLoadingTable(false);
          setOpen(false);
        });
    }
  };

  const handleDelete = id => {
    const url = `${hardBaseUrl}/user/${id}`;

    let theToken = localStorage.getItem("token");
    axios
      .delete(url, {
        headers: {
          Authorization: `Bearer ${theToken}`,
        },
      })
      .then(res => {
        if (
          res.data.message === "success" &&
          res.data.meta.http_status === 200
        ) {
          Swal.fire({
            icon: "success",
            title: "Berhasil",
            text: "Data deleted successfully",
          }).then(result => {
            if (result.isConfirmed === true) {
              getResponse();
            }
          });
        }
      })
      .catch(err => {
        new Error(err);
      });
  };

  const handleRecovery = id => {
    const url = `${hardBaseUrl}/user-recovery/${id}`;
    setLoadingTable(true);
    let theToken = localStorage.getItem("token");
    const formData = new FormData();
    axios
      .put(url, formData, {
        headers: {
          Authorization: `Bearer ${theToken}`,
        },
      })
      .then(res => {
        if (
          res.data.message === "success" &&
          res.data.meta.http_status === 200
        ) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Data recovered successfully",
          }).then(result => {
            if (result.isConfirmed === true) {
              getResponse();
            }
          });
        }
      })
      .catch(err => {
        new Error(err);
      })
      .finally(() => setLoadingTable(false));
  };

  const handleUpdate = e => {
    e.preventDefault();

    setOpenEdit(false);

    if (publisher === "") {
      setTypeSnackbar("error");
      setMessage("Publisher Cannot Be Empty.");
      setOpenSnack(true);
      setOpenEdit(true);
    }
    if (role === "") {
      setTypeSnackbar("error");
      setMessage("Role Cannot Be Empty.");
      setOpenSnack(true);
      setOpenEdit(true);
    }

    if (phone === "") {
      setTypeSnackbar("error");
      setMessage("Phone Number Cannot Be Empty.");
      setOpenSnack(true);
      setOpenEdit(true);
    }
    if (email === "") {
      setTypeSnackbar("error");
      setMessage("Email Cannot Be Empty.");
      setOpenSnack(true);
      setOpenEdit(true);
    }
    if (name === "") {
      setTypeSnackbar("error");
      setMessage("Name Cannot Be Empty.");
      setOpenSnack(true);
      setOpenEdit(true);
    }

    if (
      name !== "" &&
      email !== "" &&
      phone !== "" &&
      role !== "" &&
      publisher !== ""
    ) {
      const url = `${hardBaseUrl}/user/${id}`;

      const data = {
        name: name,
        email: email,
        phone: phone,
        role_id: role,
        publisher_id: publisher,
      };

      axios
        .put(url, data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${theToken}`,
          },
        })
        .then(() => {
          Swal.fire({
            icon: "success",
            title: "Berhasil",
            text: "Data changed successfully",
          }).then(result => {
            if (result.isConfirmed === true) {
              getResponse();
            }
          });
        })
        .catch(err => {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: err?.data?.response[0]?.message,
            customClass: {
              container: classes.notif
            }
          });
          new Error(err);
        });
    }
  };
  const handleChangeSearch = event => {
    setPage(1);
    setSearchKey(event.target.value);
  };

  const getRoleList = () => {
    const url = `${hardBaseUrl}/role?type=publisher`;
    let theToken = localStorage.getItem("token");
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${theToken}`,
        },
      })
      .then(res => {
        setRoleList(res.data.data || []);
      })
      .catch(err => {
        new Error(err);
      });
  };

  const getPublisherList = () => {
    const url = `${hardBaseUrl}/publisher`;
    let theToken = localStorage.getItem("token");
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${theToken}`,
        },
      })
      .then(res => {
        setPublisherList(res.data.data || []);
      })
      .catch(err => {
        new Error(err);
      });
  };

  useEffect(() => {
    getResponse();
  }, [rowsPerPage, page, searchKey, type]);

  useEffect(() => {
    getRoleList();
    getPublisherList();
  }, []);

  const handleChangePage = (_, page) => {
    setPage(page);
  };

  const customAction = item => {
    return (
      <>
        {item?.is_active_flag ? (
          <ButtonWithModalPerItem
            userLogin={userLogin}
            item={item}
            title={"Delete User Publisher"}
            dialogTitle={"Delete User Publisher"}
            subTitle={"Are you sure you want to delete this data"}
            handleDelete={() => {
              handleDelete(item?.user_id);
            }}
          />
        ) : (
          <IconButton
            classes={{
              root: classes.ib,
            }}
            onClick={() => {
              handleRecovery(item?.user_id);
            }}
          >
            <img alt="delete-btn" src={RefreshVector} />
          </IconButton>
        )}
      </>
    );
  };
  const columnTable = [
    {
      name: "all",
      title: "Action",
      renderText: item => customAction(item),
    },
    {
      name: "name",
      title: "User Name",
    },
    {
      name: "all",
      title: "Role",
      renderText: item => emptyText(item?.role?.name),
    },
    {
      name: "all",
      title: "Publisher",
      renderText: item => emptyText(item?.publisher?.name),
    },
    {
      name: "phone",
      title: "Phone Number",
    },
    {
      name: "email",
      title: "Email",
    },
  ];

  return (
    <Page className={classes.root} title="Publisher User">
      <Snackbar
        open={openSnack}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleCloseSnackbar} severity={typeSnackbar}>
          {message}
        </Alert>
      </Snackbar>
      <Container maxWidth={false}>
        <HeaderPage
          title="Parameter"
          breadcrumbs={["Parameter", "User Publisher"]}
        />
        <ButtonGroupTop />
        <Divider className={classes.divider} />
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} sm={4} lg={7} xl={7}>
            <FormControl variant="outlined" disabled={loadingTable}>
              <OutlinedInput
                id="outlined-adornment-amount"
                className={classes.inputFields}
                value={searchKey}
                disabled={loadingTable}
                onChange={event => handleChangeSearch(event)}
                placeholder="Search"
                startAdornment={
                  <InputAdornment position="start">
                    <img
                      alt="Logo"
                      src={
                        require("assets/image-public/images/search.svg")
                          .default
                      }
                    />
                  </InputAdornment>
                }
                fullWidth={false}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6} sm={8} lg={5} xl={5}>
            <Grid container justifyContent="flex-end" spacing={2}>
              <Grid item>
                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  disabled={loadingTable}
                >
                  <Select
                    displayEmpty={true}
                    className={classes.selectPadding}
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={type}
                    disabled={loadingTable}
                    defaultValue={""}
                    onChange={handleChangeSelect}
                  >
                    <MenuItem value={""}>All Role</MenuItem>
                    {roleList.map(item => {
                      return (
                        <MenuItem key={item.role_id} value={item.role_id}>
                          {item.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                <PrimaryButton
                  onClick={handleOpen}
                  disabled={loadingTable}
                  label="Add User Publisher"
                  height={44}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {loadingTable ? (
          <SkeletonComponent variant="rect" />
        ) : (
          <Box className={classes.mt20}>
            <InnoTable
              columns={columnTable}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              idColumnName={"performer_id"}
              isLoading={loadingTable}
              isUsingCheckbox={false}
              items={response}
              loadingColor={""}
              page={page}
              rowsPerPage={rowsPerPage}
              totalPage={pagesCount}
            />
          </Box>
        )}
        <DialogTambahUserPublisher
          open={open}
          onClose={handleClose}
          role={role}
          loading={loadingTable}
          handleChangeSelectRole={handleChangeSelectRole}
          handleChange={handleChange}
          onSubmit={onSubmitAdd}
          publisher={publisher}
          handleChangeSelectPublisher={handleChangeSelectPublisher}
          publisherItems={publisherList}
          roleItems={roleList}
          name={name}
          phone={phone}
          email={email}
        />

        {publisher !== null && (
          <DialogEditUserPublisher
            open={openEdit}
            onClose={handleCloseEdit}
            role={role}
            handleChangeSelectRole={handleChangeSelectRole}
            handleChange={handleChange}
            publisher={publisher}
            handleChangeSelectPublisher={handleChangeSelectPublisher}
            name={name}
            phone={phone}
            onSubmit={handleUpdate}
            email={email}
            publisherItems={publisherList}
            roleItems={roleList}
          />
        )}
      </Container>

    </Page>
  );
}

export default UserPublisher;
