import AppBar from "@material-ui/core/AppBar";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/styles";
const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    backgroundColor: "#f5f5f5",
    color: "#000",
    boxShadow: "none",
    borderBottom: "1px solid #e0e0e0",
  },
  textHeader: {
    fontSize: "25px",

    marginLeft: "10px",
  },
  iconHeader: {
    fontSize: "29px",
    marginTop: "5px",
  },
  backButton: {
    cursor: "pointer",
    "&:hover": {
      color: "gray",
    },
  },
  displayFlex: {
    display: "flex",
    width: "100%",
  },
}));
const ReviewHeader = () => {
  const theme = createTheme({
    typography: {
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        "Segoe UI",
        "Roboto",
        "Helvetica Neue",
        "Arial",
        "sans-serif",
        "Apple Color Emoji",
        "Segoe UI Emoji",
        "Segoe UI Symbol",
      ].join(","),
    },
  });
  const classes = useStyles();
  const handleBack = e => {
    e.preventDefault();
    window.history.back();
  };
  return (
    <div>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <ThemeProvider theme={theme}>
            <Typography
              className={classes.backButton}
              variant="h6"
              onClick={e => handleBack(e)}
            >
              <div className={classes.displayFlex}>
                <CloseIcon className={classes.iconHeader} />
                <div className={classes.textHeader}>Preview</div>
              </div>
            </Typography>
          </ThemeProvider>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default ReviewHeader;
