import {
  Card,
  Chip,
  Container,
  Divider,
  FormControl,
  Grid,
  List,
  ListItem,
  MenuItem,
  Box,
  Select,
  Typography,
} from "@material-ui/core";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import NotifSign from "../../assets/img/notif-sign.svg";
import { hardBaseUrl } from "../../services/urlConstant";
import gradients from "../../theme/gradients";

const formatText = text => {
  return text
    .split("_") // Split by underscores
    .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter
    .join(" "); // Join with spaces
};

const theme = createTheme({
  typography: {
    fontFamily: ["Helvetica Neue"].join(","),
  },
});
const useStyles = makeStyles(theme => ({
  root: {
    padding: "24px 0px",
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  chip: {
    backgroundColor: "#FCF3F2",
    color: "#A63124",
    fontSize: "12px",
    fontWeight: "500",
    borderRadius: "11px",
    border: "1px solid #A63124",
    marginTop: 5,
  },
  formControl: {
    width: "200px",
  },
  textNoData: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100px",
  },
  textTitle: {
    fontSize: "24px",
    fontWeight: "700",
    lineHeight: "32px",
  },
  image: {
    width: "20px",
    marginTop: 5,
    alignSelf: "flex-start",
  },
  menuItem: {
    width: "200px",
  },
  listItem: {
    "&:hover": {
      backgroundColor: theme.palette.background.default,
    },
    borderBottom: "1px solid #e1e1e1",
    cursor: "default",
    padding: "1rem",
  },
  avatarBlue: {
    backgroundImage: gradients.blue,
  },
  avatarGreen: {
    backgroundImage: gradients.green,
  },
  avatarOrange: {
    backgroundImage: gradients.orange,
  },
  avatarIndigo: {
    backgroundImage: gradients.indigo,
  },
}));

const NotificationPage = () => {
  const classes = useStyles();
  const [selectedFilterValue, setSelectedFilterValue] = useState(1);
  const [notificationList, setNotificationList] = useState([]);

  const handleChangeSelectFIlterValue = e => {
    setSelectedFilterValue(e.target.value);
  };
  const getNotificationList = () => {
    const token = localStorage.getItem("token");
    const url = `${hardBaseUrl}/notification`;
    const params = {
      page: 1,
      size: 10,
    };
    try {
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params,
        })
        .then(res => {
          setNotificationList(res?.data?.data || []);
        });
    } catch (error) {
      new Error(error);
    }
  };

  useEffect(() => {
    getNotificationList();
  }, []);
  return (
    <Box className={classes.root}>
      <Container maxWidth={false}>
        <Grid
          alignItems="flex-end"
          container
          justifyContent="space-between"
          spacing={3}
        >
          <Grid item>
            <ThemeProvider theme={theme}>
              <Typography className={classes.textTitle}>
                Notification
              </Typography>
            </ThemeProvider>
          </Grid>
          <Grid item>
            <FormControl
              size="small"
              variant="outlined"
              className={classes.formControl}
              fullWidth={true}
            >
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                onChange={handleChangeSelectFIlterValue}
                autoWidth={true}
                value={selectedFilterValue}
              >
                <MenuItem value={0} className={classes.menuItem}>
                  <em>None</em>
                </MenuItem>
                <MenuItem value={1} className={classes.menuItem}>
                  Latest
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Divider className={classes.divider} />
        {notificationList < 1 ? (
          <Card className={classes.textNoData}>
            <Typography component="span" variant="h6">
              There&apos;s nothing here...
            </Typography>
          </Card>
        ) : (
          <Card>
            <List disablePadding>
              {notificationList.map(
                ({
                  notification_id,
                  title,
                  is_new,
                  is_read,
                  created_at,
                  type,
                }) => (
                  <ListItem
                    className={classes.listItem}
                    component={RouterLink}
                    key={notification_id}
                    to="#"
                  >
                    <Grid container alignItems="center">
                      <Grid item sm={8}>
                        <Box display="flex" gridGap="1rem">
                          <img
                            src={NotifSign}
                            className={classes.image}
                            alt="notif sign"
                          />
                          <Box display="grid">
                            <Typography component="span" variant="subtitle1">
                              {formatText(type) || ""}
                            </Typography>
                            <Typography component="span" variant="subtitle2">
                              {title || ""}
                            </Typography>
                          </Box>
                          {is_new && is_read === false && (
                            <Chip
                              label="NEW"
                              size="small"
                              className={classes.chip}
                            />
                          )}
                        </Box>
                      </Grid>
                      <Grid item sm={4}>
                        <Box display="flex" justifyContent="flex-end">
                          <Typography component="span" variant="body2">
                            {moment(created_at).fromNow()}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </ListItem>
                )
              )}
            </List>
          </Card>
        )}
      </Container>
    </Box>
  );
};

export default NotificationPage;
