import { Box, Typography } from "@mui/material";
import { DateTimeDisplay } from "components";
import { InnoTableV2 } from "inno-ui";
import moment from "moment-timezone";

const ContractActivityForm = ({
  dataTable,
  queryParams,
  tableTotalPage,
  handleChangeQueryParams,
}) => {
  return (
    <InnoTableV2
      isLoading={false}
      columns={columnTable}
      items={dataTable}
      page={Number(queryParams?.page)}
      rowsPerPage={Number(queryParams?.size)}
      totalPage={tableTotalPage}
      handleChangePage={(_, page) => handleChangeQueryParams(page, "page")}
      handleChangeRowsPerPage={e =>
        handleChangeQueryParams(e?.target?.value, "size")
      }
    />
  );
};

const columnTable = [
  {
    name: "all",
    title: "Date & Time",
    renderText: item => (
      <DateTimeDisplay date={moment(`${item?.date} ${item?.time}`)} />
    ),
  },
  {
    name: "all",
    title: "Account",
    renderText: item => (
      <Box>
        {item?.created_by_name}
        <Typography fontSize={12} color="#687083">
          {item?.created_by_email}
        </Typography>
      </Box>
    ),
  },
  {
    name: "action",
    title: "Action",
    renderText: item => item || "-",
  },
  {
    name: "slug_action",
    title: "Information",
    renderText: item => item || "-",
  },
];

export default ContractActivityForm;
