import {
  Button,
  Checkbox,
  Chip,
  FormControl,
  Grid,
  InputAdornment,
  Modal,
  OutlinedInput,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import * as Sentry from "@sentry/react";
import axios from "axios";
import { useEffect, useState } from "react";
import { hardBaseUrl } from "../../../services/urlConstant";
import { getErrors } from "utils";
export default function AddSongModal({
  open,
  handleClose,
  startDate = "",
  endDate = "",
  items,
  dsp_id = "",
  composer_id = "",
}) {
  const [dataSong, setDataSong] = useState({
    data: [],
    meta: {
      total: 0,
    },
  });
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [selected, setSelected] = useState([]);
  const [dataSearch, setDataSearch] = useState("");
  useEffect(() => {
    const getSongList = () => {
      const token = localStorage.getItem("token");
      const urlSongList = `${hardBaseUrl}/publisher/transaction-song?page=${page +
        1}&size=${size}&dsp_id=${dsp_id}&start_date=${startDate}&end_date=${endDate}&composer_id=${composer_id}&search=${dataSearch}`;
      axios
        .get(urlSongList, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(res => {
          setDataSong(res.data);
        })
        .catch(err => {
          let errMessage = getErrors(err.response);
          Sentry.captureMessage(
            "Error catch /publisher/transaction-song src/components/AddSongModal.js : " +
              errMessage
          );
        });
    };
    getSongList();
  }, [page, size, dataSearch]);
  useEffect(() => {
    setSelected(items);
  }, [items]);
  return (
    <Modal
      open={open}
      onClose={() => handleClose(selected)}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Grid
        style={{
          backgroundColor: "white",
        }}
      >
        <Grid
          style={{
            alignItems: "flex-end",
            padding: "24px",
            borderBottom: "1px solid #e1e1e1",
          }}
          container
        >
          <FormControl fullWidth variant="outlined">
            <OutlinedInput
              placeholder="Search"
              id="outlined-adornment-amount"
              fullWidth={false}
              onChange={e => setDataSearch(e.target.value)}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon style={{ color: "#9AA2B1" }} />
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>
        <Grid
          style={{
            maxHeight: "420px",
            overflow: "auto",
            padding: "24px",
          }}
        >
          <TableContainer style={{ width: "fit-content" }}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell align="right">Song</TableCell>
                  <TableCell align="right" width={400}>
                    Compose/Author
                  </TableCell>
                  <TableCell align="right" width={400}>
                    Alias Name
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dataSong?.data.map((val, index) => {
                  return (
                    <TableRow key={`${index}`}>
                      <TableCell align="right">
                        <Checkbox
                          checked={
                            selected.filter(res => res.song_id === val.song_id)
                              .length > 0
                          }
                          onClick={({ target }) => {
                            if (target.checked) {
                              setSelected([...selected, val]);
                            } else {
                              let data = [...selected];
                              setSelected(
                                data.filter(res => res.song_id !== val.song_id)
                              );
                            }
                          }}
                        />
                      </TableCell>
                      <TableCell align="right">{val.song_title}</TableCell>
                      <TableCell align="right" style={{ padding: 0 }}>
                        {val?.composer_names?.map((res, index) => {
                          return (
                            <Chip
                              key={`${index}`}
                              style={{ margin: "4px" }}
                              label={res}
                            />
                          );
                        })}
                      </TableCell>
                      <TableCell align="right" style={{ padding: 0 }}>
                        {val?.composers?.map((res, index) => {
                          return (
                            <Chip
                              key={`${index}`}
                              style={{ margin: "4px" }}
                              label={res.alias_name}
                            />
                          );
                        })}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid
          style={{
            borderTop: "1px solid #e1e1e1",
          }}
        >
          <TablePagination
            component="div"
            count={dataSong.meta.total}
            page={page}
            onPageChange={v => {
              setPage(v);
            }}
            rowsPerPage={size}
            onRowsPerPageChange={e => {
              setPage(0);
              setSize(parseInt(e.target.value, 10));
            }}
          />
          <Grid container justifyContent="flex-end" style={{ padding: "24px" }}>
            <div>
              <Button
                onClick={() => handleClose(items)}
                style={{
                  borderRadius: "6px",
                  width: "84px",
                  height: "40px",
                  border: "1px solid #D1D5DC",
                  textTransform: "none",
                }}
              >
                Cancel
              </Button>
            </div>
            <div>
              <Button
                onClick={() => handleClose(selected)}
                style={{
                  marginLeft: "10px",
                  backgroundColor: "#111827",
                  borderRadius: "6px",
                  width: "84px",
                  height: "40px",
                  color: "#FFFFFF",
                  textTransform: "none",
                }}
              >
                Add
              </Button>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
}
