import {
  Button,
  Checkbox,
  ClickAwayListener,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  InputAdornment,
  OutlinedInput,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import { ArrowDropDown } from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/styles";

const theme = createTheme({
  typography: {
    fontFamily: ["Helvetica Neue"].join(","),
  },
});
const fontInter = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  results: {
    marginTop: theme.spacing(3),
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const DialogEditUser = ({
  open,
  onClose,
  onSubmit,
  handleChangeSelectRole,
  handleChange,
  name,
  email,
  phone,
  openMenu,
  handleClickAway,
  clickMenu,
  formatRoleNames,
  selectedRoles,
  handleChangeSearchRole,
  filteredRoles,
}) => {
  const classes = useStyles();

  return (
    <div>
      <Dialog
        fullWidth={true}
        open={open}
        onClose={onClose}
        aria-labelledby="responsive-dialog-title"
      >
        <form onSubmit={onSubmit}>
          <DialogTitle
            id="responsive-dialog-title"
            style={{
              height: "fit-content",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  height: "fit-content",
                }}
              >
                <Typography
                  style={{
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: 700,
                    fontSize: "16px",
                    lineHeight: "24px",
                    color: "#111827",
                  }}
                >
                  Edit User
                </Typography>
              </div>
              <div>
                <CloseIcon onClick={onClose} />
              </div>
            </div>
          </DialogTitle>
          <Divider />
          <DialogContent>
            <DialogContentText>User Name</DialogContentText>
            <div>
              <FormControl
                fullWidth
                className={classes.margin}
                variant="outlined"
              >
                <OutlinedInput
                  id="outlined-adornment-amount"
                  onChange={handleChange}
                  name="name"
                  type="text"
                  fullWidth={false}
                  value={name}
                />
              </FormControl>
            </div>
          </DialogContent>
          <DialogContent>
            <DialogContentText>Role</DialogContentText>
            <ClickAwayListener onClickAway={handleClickAway}>
              <div>
                <OutlinedInput
                  id="outlined-adornment-amount"
                  size="small"
                  onClick={clickMenu}
                  style={{
                    width: "100%",
                    marginRight: "20px",
                    height: "44px",
                    border: "1px solid #D1D5DC",
                    borderRadius: "6px",
                  }}
                  startAdornment={
                    <InputAdornment position="start">
                      {formatRoleNames(selectedRoles)}
                    </InputAdornment>
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <ArrowDropDown
                        onClick={clickMenu}
                        style={{ cursor: "pointer" }}
                      />
                    </InputAdornment>
                  }
                  fullWidth={false}
                  readOnly={true}
                />
                {openMenu && (
                  <Paper
                    variant="outlined"
                    style={{
                      boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                      display: "flex",
                      flexDirection: "column",
                      background: "white",
                      position: "absolute",
                      "& > *": {
                        margin: theme.spacing(1),
                        width: theme.spacing(16),
                        height: theme.spacing(16),
                      },
                      zIndex: "1000",
                    }}
                  >
                    <Container
                      style={{
                        width: "300px",
                        minHeight: "100px",
                        maxHeight: "300px",
                        marginTop: "40px",
                        borderTop: "1px solid #E4E7EB",
                        overflow: "scroll",
                      }}
                    >
                      <FormControl
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "10px",
                        }}
                      >
                        <TextField
                          autoFocus={true}
                          id="fliterText"
                          name="filterText"
                          className={classes.inputFields}
                          variant="outlined"
                          placeholder="Search"
                          onChange={handleChangeSearchRole}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <img alt="Logo" src={require("assets/image-public/images/search.svg").default} />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </FormControl>
                      <FormControl>
                        {filteredRoles?.map((item, index) => {
                          return (
                            <FormControlLabel
                              key={index}
                              control={
                                <Checkbox
                                  className={classes.myCheckBox}
                                  id={`${item?.dsp_id}`}
                                  key={index}
                                  checked={item?.checked}
                                  name={item?.name}
                                  value={item?.dsp_id}
                                  onChange={e =>
                                    handleChangeSelectRole(e, item, index)
                                  }
                                />
                              }
                              label={item?.name}
                            />
                          );
                        })}
                      </FormControl>
                    </Container>
                  </Paper>
                )}
              </div>
            </ClickAwayListener>
          </DialogContent>

          <DialogContent>
            <DialogContentText>Phone Number</DialogContentText>
            <div>
              <FormControl
                fullWidth
                className={classes.margin}
                variant="outlined"
              >
                <OutlinedInput
                  id="outlined-adornment-amount"
                  onChange={handleChange}
                  name="phone"
                  type="number"
                  fullWidth={true}
                  value={phone}
                />
              </FormControl>
            </div>
          </DialogContent>

          <DialogContent>
            <DialogContentText>Email</DialogContentText>
            <div>
              <FormControl
                fullWidth
                className={classes.margin}
                variant="outlined"
              >
                <OutlinedInput
                  id="outlined-adornment-amount"
                  onChange={handleChange}
                  name="email"
                  type="text"
                  fullWidth={true}
                  value={email}
                />
              </FormControl>
            </div>
          </DialogContent>
          <Divider />

          <DialogActions>
            <Button
              autoFocus
              onClick={onClose}
              variant="outlined"
              style={{
                backgroundColor: "black",
                color: "white",
                textTransform: "none",
              }}
            >
              <ThemeProvider theme={fontInter}>
                <Typography
                  style={{
                    color: "white",
                    fontSize: "14px",
                  }}
                >
                  Cancel
                </Typography>
              </ThemeProvider>
            </Button>
            <Button
              type="submit"
              autoFocus
              variant="outlined"
              style={{
                backgroundColor: "black",
                color: "white",
                marginRight: "16px",
                textTransform: "none",
              }}
            >
              <ThemeProvider theme={fontInter}>
                <Typography
                  style={{
                    color: "white",
                    fontSize: "14px",
                  }}
                >
                  Save
                </Typography>
              </ThemeProvider>
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default DialogEditUser;
