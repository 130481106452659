import { Autocomplete, TextField } from "@mui/material";

const AutoCompleteComponent = ({
  id,
  options,
  label,
  value,
  onChange,
  width = 300,
  className,
  size,
  ...rest
}) => {
  return (
    <Autocomplete
      id={id}
      size={size}
      className={className}
      options={options || []}
      getOptionLabel={option => option.label}
      style={{ width }}
      renderInput={params => (
        <TextField {...params} label={label} variant="outlined" />
      )}
      value={value || null}
      onChange={(event, newValue) =>
        onChange(newValue ? newValue.id : "", event)
      }
      {...rest}
    />
  );
};

export default AutoCompleteComponent;
