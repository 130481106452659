import { Container, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Box, Grid } from "@mui/material";
import axios from "axios";
import {
  ModalError,
  MonthYearPicker,
  Page,
  PrimaryButton,
  SearchTextInput,
  SkeletonComponent,
  TableCellMultipleRow,
} from "components";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { InnoTableV2 } from "inno-ui";
import { HeaderTitle } from "layouts";
import { getTotalPage } from "lib";
import { debounce } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { useLocation } from "react-router-dom";
import { getErrors } from "utils";
import { hardBaseUrl } from "../../../../services/urlConstant";

function DetailReport() {
  const classes = useStyles();
  const { state } = useLocation();
  const { id } = useParams();
  const token = localStorage.getItem("token");
  const previewReportRef = useRef(null);
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const [loadingPage, setLoadingPage] = useState(false);
  const [loadingButton, setLoadingButton] = useState({
    pdf: false,
    xlsx: false,
  });
  const [dataTable, setDataTable] = useState([]);
  const [queryParams, setQueryParams] = useState({
    page: 1,
    size: 10,
    search: "",
    filterDate: state?.date ? formatGetPeriodParams(state?.date) : moment(),
  });
  const [tableTotalPage, setTableTotalPage] = useState(1);
  const { filterDate, ...otherParams } = queryParams;
  const formatParamsMonth = date => date.format("M");
  const formatParamsYear = date => date.format("YYYY");
  const handleChangeQueryParams = (value, key) => {
    setQueryParams(currentState => ({
      ...currentState,
      ...(key !== "page" && { page: 1 }),
      [key]: value,
    }));
  };

  const handleDownloadXlsx = async () => {
    try {
      setLoadingButton({ ...loadingButton, xlsx: true });
      const { data } = await axios.get(
        `${hardBaseUrl}/publisher/report/download/${id}`,
        {
          headers,
          params: {
            file_type: "xlsx",
            search: queryParams?.search,
            month: formatParamsMonth(filterDate),
            year: formatParamsYear(filterDate),
          },
          responseType: "blob", // tambahkan ini untuk menerima data sebagai Blob
        }
      );

      // Membuat objek Blob URL untuk mengunduh file
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `report_${id}.xlsx`); // Nama file yang akan didownload
      document.body.appendChild(link);
      link.click();

      // Membersihkan URL Blob dan elemen link setelah digunakan
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingButton({ ...loadingButton, xlsx: false });
    }
  };

  const handleDownloadPdf = async () => {
    try {
      setLoadingButton({ ...loadingButton, pdf: true });
      const { data } = await axios.get(
        `${hardBaseUrl}/publisher/report/download/${id}`,
        {
          headers,
          params: {
            file_type: "pdf",
            search: queryParams?.search,
            month: formatParamsMonth(filterDate),
            year: formatParamsYear(filterDate),
          },
          responseType: "blob", // tambahkan ini untuk menerima data sebagai Blob
        }
      );

      // Membuat objek Blob URL untuk mengunduh file
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `report_${id}.pdf`); // Nama file yang akan didownload
      document.body.appendChild(link);
      link.click();

      // Membersihkan URL Blob dan elemen link setelah digunakan
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingButton({ ...loadingButton, pdf: false });
    }
  };

  const getDataTable = async () => {
    try {
      setLoadingPage(true);
      const res = await axios.get(`${hardBaseUrl}/publisher/report/${id}`, {
        headers,
        params: {
          ...otherParams,
          month: formatParamsMonth(filterDate),
          year: formatParamsYear(filterDate),
        },
      });
      const { data, meta } = res?.data;
      const modifiedData = (data?.data || [])?.map(item => ({
        ...item,
        usage_on_dsp: (item?.usage_on_dsp || [])?.map(dsp => ({
          ...dsp,
          product_type: dsp?.summaries[0]?.product_type || "",
        })),
      }));
      setDataTable(modifiedData || []);
      const pageCount = getTotalPage(meta?.total, queryParams?.size || 1);
      setTableTotalPage(pageCount);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  const debounceDataTable = useCallback(
    debounce(() => {
      getDataTable();
    }, 500),
    [queryParams]
  );

  useEffect(() => {
    debounceDataTable();
    return () => {
      debounceDataTable.cancel();
    };
  }, [queryParams, debounceDataTable]);

  const columnTable = [
    {
      name: "song_title",
      title: "Song",
    },
    {
      name: "usage_on_dsp",
      title: "DSP",
      renderText: item => (
        <Box minWidth={100}>
          <TableCellMultipleRow list={item} itemKey="dsp_name" />
        </Box>
      ),
    },
    {
      name: "usage_on_dsp",
      title: <span className={classes.rightAlign}>Traffic</span>,
      renderText: item => (
        <TableCellMultipleRow list={item} itemKey="traffic" type="number" />
      ),
    },
    {
      name: "usage_on_dsp",
      title: <span className={classes.rightAlign}>Income</span>,
      renderText: item => (
        <TableCellMultipleRow
          list={item}
          itemKey="revenue"
          type="number"
          prefix="Rp "
        />
      ),
    },
    {
      name: "usage_on_dsp",
      title: <span className={classes.rightAlign}>Market Share (Traffic)</span>,
      renderText: item => (
        <TableCellMultipleRow list={item} itemKey="traffic_percentage" />
      ),
    },
    {
      name: "usage_on_dsp",
      title: <span className={classes.rightAlign}>Market Share (Revenue)</span>,
      renderText: item => (
        <TableCellMultipleRow list={item} itemKey="revenue_percentage" />
      ),
    },
    {
      name: "usage_on_dsp",
      title: "Product Type",
      renderText: item => (
        <TableCellMultipleRow list={item} itemKey="product_type" />
      ),
    },
  ];

  return (
    <Page className={classes.root} title="Detail Report">
      {loadingPage ? (
        <SkeletonComponent variant="wave" />
      ) : (
        <Container maxWidth={false} id={"container-detail-report"}>
          <HeaderTitle
            title="Detail Report"
            breadcrumbData={breadcrumbData}
            backButton
          />
          <Divider className={classes.divider} />
          <Grid container justifyContent="space-between" mb="16px">
            <Grid item>
              <SearchTextInput
                placeholder="Search"
                value={queryParams?.search}
                onChange={e =>
                  handleChangeQueryParams(e?.targe?.value, "search")
                }
              />
            </Grid>
            <Grid item display="flex" gap={1}>
              <MonthYearPicker
                value={queryParams?.filterDate}
                onChange={date => handleChangeQueryParams(date, "filterDate")}
              />
              <PrimaryButton
                height={40}
                label="Download PDF"
                onClick={handleDownloadPdf}
                disabled={loadingButton?.pdf}
                loading={loadingButton?.pdf}
              />
              <PrimaryButton
                height={40}
                label="Download XLSX"
                onClick={handleDownloadXlsx}
                disabled={loadingButton?.xlsx}
                loading={loadingButton?.xlsx}
              />
            </Grid>
          </Grid>
          <InnoTableV2
            isLoading={false}
            columns={columnTable}
            items={dataTable}
            page={queryParams?.page}
            rowsPerPage={queryParams?.size}
            totalPage={tableTotalPage}
            handleChangePage={(_, page) =>
              handleChangeQueryParams(page, "page")
            }
            handleChangeRowsPerPage={e =>
              handleChangeQueryParams(e?.target?.value, "size")
            }
          />
        </Container>
      )}
    </Page>
  );
}

const formatParamsYear = date => date.format("YYYY");
const formatGetPeriodParams = date => moment(date, "M-YYYY");
const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  rightAlign: {
    display: "flex",
    justifyContent: "flex-end", // membuat teks rata kanan
  },
}));
const breadcrumbData = [
  {
    label: "Home",
    link: "/admin/dashboard",
  },
  {
    label: "Report",
    link: "/admin/reports",
  },
  {
    label: "Detail Report",
    active: true,
  },
];

export default DetailReport;
