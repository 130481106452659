import { Box, Chip, styled } from "@mui/material";
import { Cancel } from "@material-ui/icons";
import { stringTruncate } from "utils";

const ArrayChipWithRemove = ({ selectionsList, value, handleDelete }) => {
  return (
    <ChipContainer>
      {selectionsList?.map((item, index) => {
        return (
          <Chip
            key={index}
            label={stringTruncate(item?.[value], 18)}
            size="small"
            onDelete={() => handleDelete(item)}
            style={{
              color: item?.color,
              backgroundColor:
                item?.color === "#FFF" ? "#6b7280" : `${item?.color}10`,
            }}
            deleteIcon={
              <Cancel
                style={{
                  color: item?.color,
                }}
              />
            }
          />
        );
      })}
    </ChipContainer>
  );
};

const ChipContainer = styled(Box)(() => ({
  border: "1px solid #E4E7EB",
  display: "flex",
  flexWrap: "wrap",
  gap: "8px",
  padding: 8,
  maxHeight: "calc(24px * 2 + 8px * 3)",
  maxWidth: 450,
  overflowY: "auto",
  zIndex: 10,
  "&::-webkit-scrollbar": {
    width: "6px",
  },
  "&::-webkit-scrollbar-track": {
    background: "#f1f1f1",
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#888",
    borderRadius: "10px",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    background: "#555",
  },
}));

export default ArrayChipWithRemove;
