import React, { useState, useRef, useEffect } from "react";
import {
  Grid,
  Backdrop,
  CircularProgress,
  Container,
  Typography,
  Breadcrumbs,
  Divider,
  Box,
  Card,
  CardContent,
  TextField,
  Button,
  IconButton,
  InputAdornment,
  MenuItem,
  InputLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { MonthYearRangePicker, Page } from "components";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import Swal from "sweetalert2";
import { hardBaseUrl } from "../../../../services/urlConstant";
import NumberFormat from "react-number-format";
import {
  AddCircleOutlineRounded,
  Close,
  CloudUpload,
  Description,
  DescriptionRounded,
  Edit,
  Delete,
} from "@material-ui/icons";
import { KeyboardDatePicker } from "@material-ui/pickers";
import moment from "moment";
import { useParams, useHistory } from "react-router";
import { useSelector } from "react-redux";

function formatBytes(bytes, decimals = 2) {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "Kb", "Mb", "Gb", "Tb", "Pb", "Eb", "Zb", "Yb"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  textTitle: {
    fontSize: "24px",
    fontWeight: "700",
    lineHeight: "32px",
  },
  divider: {
    margin: "1rem 0px",
    borderTop: "1px solid #e1e1e1",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  breadcrumbs: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#111827",
  },
  prefixCurrencyType: {
    backgroundColor: theme.palette.divider,
    padding: "20.5px 14px",
    borderTopLeftRadius: theme.shape.borderRadius + "px",
    borderBottomLeftRadius: theme.shape.borderRadius + "px",
  },
  warningRequired: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "red",
  },
  textSecondary: {
    color: "grey",
  },
  fieldRevenueType: {
    margin: "8px 0px 0px 0px",
    height: "2.3rem",
    width: "100%",
  },
  cardContent: {
    border: "1px solid #D1D5DC",
    borderRadius: "6px",
    boxShadow: "none",
    marginTop: "1rem",
  },
  textField: {
    width: "100%",
    margin: "8px 0px 0px 0px",
    paddingLeft: 0,
    "& .MuiOutlinedInput-root": {
      paddingLeft: 0,
    },
  },
  bodyTextWhite: {
    fontSize: "14px",
    fontWeight: "regular",
    fontStyle: "normal",
    color: "white",
    background: "black",
    transition: "all 0.2s ease-in",
    textTransform: "capitalize",
    width: "fit-content",
    "&:hover": {
      background: "white",
      color: "black",
    },
  },
  bodyTextBlack: {
    fontSize: "14px",
    fontWeight: "regular",
    fontStyle: "normal",
    background: "black",
    transition: "all 0.2s ease-in",
    textTransform: "capitalize",
    width: "fit-content",
    height: "40px",
    borderRadius: "6px",
    border: "1px solid #D1D5DC",
    color: "#111827",
    backgroundColor: "white",
    "&:hover": {
      background: "black",
      color: "white",
    },
  },
  addIconBox: {
    display: "flex",
    justifyContent: "flex-end",
  },
  prefixText: {
    background: "grey",
  },
  datePickerRange: {
    margin: "8px 0px 0px 0px",
    borderRadius: 6,
  },
  wrapperDialog: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    minHeight: "70vh",
  },
  boxUpload: {
    borderStyle: "dotted",
    padding: "1rem 5rem",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    cursor: "pointer",
  },
  ellipsis: {
    textOverflow: "ellipsis",
  },
  bold: {
    fontWeight: "bold",
  },
  contentDialogLeft: {
    marginLeft: ".5rem",
  },
  contentDialogRight: {
    gap: 5,
  },
  buttonError: {
    background: "red",
    color: "white",
    transition: "all 0.2s ease-in",
    "&:hover": {
      background: "white",
      color: "red",
    },
  },
  boldAndPointer: {
    fontWeight: "bold",
    cursor: "pointer",
  },
  iconCRUD: {
    color: "grey",
    cursor: "pointer",
  },
  dialogAction: {
    borderTop: "solid 1px grey",
  },
  buttonBottom: {
    marginTop: "1rem",
  },
  buttonBottomCancel: {
    marginRight: "1rem",
    fontSize: "14px",
    fontWeight: "regular",
    fontStyle: "normal",
    background: "black",
    transition: "all 0.2s ease-in",
    textTransform: "capitalize",
    width: "fit-content",
    height: "40px",
    borderRadius: "6px",
    border: "1px solid #D1D5DC",
    color: "#111827",
    backgroundColor: "white",
    "&:hover": {
      background: "black",
      color: "white",
    },
  },
}));

const dictionaryForForm = {
  upload_date: "Upload Date",
  dsp: "DSP",
  period: "Period",
  currency: "Currency",
  exchange_rate: "Exchange Rate",
  transfer_fee: "Transfer Fee",
};

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={onChange}
      thousandSeparator
    />
  );
}

const initialFieldRevenueType = {
  selectedRevenueType: null,
  file: null,
};
function UploadDSPData() {
  const classes = useStyles();
  const { id } = useParams();
  const history = useHistory();
  const { publisherId } = useSelector(({ publisherId }) => publisherId);
  const token = localStorage.getItem("token");
  const buttonUpload = useRef(null);
  const [payloadPeriod, setPayloadPeriod] = useState({
    startDate: moment().startOf("month"),
    endDate: moment().startOf("month"),
  });
  const [state, setState] = useState({
    urlTemplate: "",
    keyOfOpenedDialog: null,
    listCurrency: [],
    useError: false,
    loading: false,
    loadingTemplate: false,
    fieldRevenueType: [
      {
        ...initialFieldRevenueType,
        index: 0,
      },
    ],
    listRevenueType: [],
    data: {
      upload_date: {
        value: moment(),
      },
      dsp: {
        value: "",
      },
      period: {
        value: "",
      },
      currency: {
        value: 0,
      },
      exchange_rate: {
        value: "",
      },
      transfer_fee: {
        value: "",
      },
    },
  });
  const {
    keyOfOpenedDialog,
    data,
    listRevenueType,
    listCurrency,
    loadingTemplate,
    loading,
    urlTemplate,
    useError,
    fieldRevenueType,
  } = state;
  const selectedSymbol = listCurrency.find(({ id }) => id === data?.currency?.value)?.currency_symbol_code || "Rp";
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const dialogIsOpen = Boolean(typeof keyOfOpenedDialog === "number");
  const [tempDataValueCurrencyFee, setTempDataValueCurrencyFee] = useState({
    currency_id: "",
    transfer_fee: 0,
  });
  const openDialog = key => setValue(key, "keyOfOpenedDialog");
  const closeDialog = () => setValue(false, "keyOfOpenedDialog");
  const openFileToUpload = () => buttonUpload?.current?.click();
  const setValue = (value, key) =>
    setState(currentState => ({
      ...currentState,
      [key]: value,
    }));
  const setDataValue = (value, key, subKey) =>
    setState(currentState => ({
      ...currentState,
      data: {
        ...currentState.data,
        [key]: {
          ...currentState.data[key],
          [subKey]: value,
        },
      },
    }));
  const handleChangePeriodPayload = (value, key) => {
    setPayloadPeriod(currentState => ({
      ...currentState,
      [key]: value,
    }));
  };

  const setFieldRevenue = (index, key, value) => {
    const newValue = {
      ...fieldRevenueType[index],
      [key]: value,
    };
    const getInitalFieldRevenue = [...fieldRevenueType].filter(
      (_, key) => index !== key
    );
    setState(currentState => ({
      ...currentState,
      fieldRevenueType: [...getInitalFieldRevenue, newValue].sort(
        (a, b) => a.index - b.index
      ),
    }));
  };
  const removeFiles = key => setFieldRevenue(key, "file", null);
  const validateForm = [
    "upload_date",
    "dsp",
    "period",
    "currency",
    "exchange_rate",
  ].some(item => data[item]?.value);
  const getAllFiles = fieldRevenueType.map(({ file }) => file);
  const getAllRevenueType = fieldRevenueType.map(
    ({ selectedRevenueType }) => selectedRevenueType
  );
  const validateValueWithButton =
    data?.currency?.value !== 0 &&
    [...getAllFiles, ...getAllRevenueType].every(item => item);
  const onSubmit = async e => {
    e?.preventDefault();
    setValue(true, "useError");
    setValue(true, "loading");
    if (validateForm && validateValueWithButton) {
      setValue(true, "loading");
      const {
        currency,
        exchange_rate,
        transfer_fee,
        upload_date,
      } = data;
      const start_date_formatted = payloadPeriod?.startDate.format(
        "YYYY-MM-DD"
      );
      const end_date_formatted = payloadPeriod?.endDate.format("YYYY-MM-DD");
      const upload_date_formated = moment(upload_date.value).format(
        "YYYY-MM-DD"
      );
      let body = new FormData();
      body.append("dsp_id", id);
      body.append("start_date", start_date_formatted);
      body.append("end_date", end_date_formatted);
      body.append("admin_fee", parseInt(transfer_fee?.value, 10));
      body.append("currency_id", currency.value);
      body.append("publisher_id", publisherId);
      body.append("exchange_rate", parseInt(exchange_rate.value, 10));
      body.append("upload_date", upload_date_formated);
      getAllFiles.forEach(item => body.append("file", item));
      getAllRevenueType.forEach(item => body.append("revenue_type_id", item));
      const url = `${hardBaseUrl}/process/upload-dsrf`;
      const configToPost = {
        ...config,
        headers: {
          ...config.headers,
          "content-type": "multipart/form-data",
        },
      };
      axios
        .post(url, body, configToPost)
        .then(() => {
          setValue(false, "loading");
          Swal.fire({
            icon: "success",
            title: "success",
          }).then(() => {
            history.goBack();
          });
        })
        .catch(errors => {
          const listError = {
            message: errors?.response?.statusText,
            title: errors?.response?.status,
          };
          setValue(false, "loading");
          Swal.fire({
            icon: "error",
            title: listError?.title || "Something Went Wrong",
            text: listError?.message || "Something Went Wrong",
          });
        });
    }
  };

  const getExchangeRate = async id => {
    setValue(true, "loading");
    try {
      const url = `${hardBaseUrl}/exchange-rate/value/${id}`;
      const { data } = await axios.get(url, config);
      setDataValue(data?.data?.value || 0, "exchange_rate", "value");
      setValue(false, "loading");
    } catch (err) {
      setDataValue(0, "exchange_rate", "value");
      setValue(false, "loading");
    }
  };

  const downloadHandle = () => {
    urlTemplate?.forEach(async (item, index) => {
      let blob = await fetch(item).then(r => r.blob());
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `template-${index}.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
  };

  const getTemplate = async () => {
    const urlClientList = `${hardBaseUrl}/upload-dsrf/example/download?dsp_id=${id}`;
    let token = localStorage.getItem("token");
    setValue(true, "loadingTemplate");
    await axios
      .get(urlClientList, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(res => {
        if (res?.data?.data) {
          let arrUrl = [];

          let newArrUrl = res.data.data;
          if (newArrUrl) {
            newArrUrl.forEach(item => {
              let urlName = item.url;
              arrUrl.push(urlName);
            });
          }
          setValue(arrUrl, "urlTemplate");
        }
        setValue(false, "loadingTemplate");
      })
      .catch(err => {
        Swal.fire({
          icon: "error",
          title: err || "Error Get Template",
          text: err || "Something Went Wrong",
        });
        setValue(false, "loadingTemplate");
      });
  };

  const addButtonUpload = index => {
    setState(({ fieldRevenueType, ...state }) => ({
      ...state,
      fieldRevenueType: [
        ...fieldRevenueType,
        {
          initialFieldRevenueType,
          index: index + 1,
        },
      ],
    }));
  };

  const deleteButtonUpload = key => {
    const filterData = [...fieldRevenueType].filter(
      (_, index) => index !== key
    );
    setState(state => ({
      ...state,
      fieldRevenueType: filterData,
    }));
  };

  const getData = async () => {
    const url = `${hardBaseUrl}/dsp/${id}`;
    const { data } = await axios.get(url, config);
    setDataValue(data?.data?.name || "", "dsp", "value");
    setTempDataValueCurrencyFee({
      currency_id: data?.data?.fee_currency?.id,
      transfer_fee: data?.data?.transaction_fee,
    });
  };

  useEffect(() => {
    if (data["currency"].value === tempDataValueCurrencyFee.currency_id) {
      setDataValue(
        tempDataValueCurrencyFee.transfer_fee,
        "transfer_fee",
        "value"
      );
    } else {
      setDataValue(0, "transfer_fee", "value");
    }
  }, [data["currency"].value]);

  useEffect(() => {
    const getListCurrency = async () => {
      setValue(true, "loading");
      const url = `${hardBaseUrl}/currency/datatable?per_page=99&page=1`;
      const { data } = await axios.get(url, config);
      setValue(data.data || [], "listCurrency");
      setValue(false, "loading");
    };
    const getListRevenueType = async () => {
      setValue(true, "loading");
      const url = `${hardBaseUrl}/revenue-type/table?dsp_id=${id}`;
      const { data } = await axios.get(url, config);
      setValue(data.data || [], "listRevenueType");
      setValue(false, "loading");
    };

    getData();
    getListRevenueType();
    getListCurrency();
    getTemplate();
  }, []);

  const listForm = Object.keys(data) || [];
  const openedFile = fieldRevenueType[keyOfOpenedDialog]?.file;
  const typeAcceptenceDocument =
    ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel";
  return (
    <Page className={classes.root} title="Upload DSP Data">
      <Backdrop className={classes.backdrop} open={loading || loadingTemplate}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog maxWidth="lg" open={dialogIsOpen} onClose={closeDialog}>
        <DialogTitle>
          <Box display="flex" alignItems="center">
            <Box flexGrow={1}>Upload Data</Box>
            <IconButton onClick={closeDialog}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent className={classes.wrapperDialog}>
          <Box className={classes.boxUpload} onClick={openFileToUpload}>
            <input
              type="file"
              required
              ref={buttonUpload}
              hidden
              accept={typeAcceptenceDocument}
              onChange={e =>
                setFieldRevenue(keyOfOpenedDialog, "file", e.target.files[0])
              }
            />
            <CloudUpload />
            <Typography>Upload Data of DSP, Search File</Typography>
          </Box>
          <Typography className={classes.bold}>Document Attached</Typography>
          {openedFile && (
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                <Box flexDirection="row" display="flex" alignItems="center">
                  <Description />
                  <Box className={classes.contentDialogLeft}>
                    <Typography className={classes.ellipsis}>
                      {openedFile?.name}
                    </Typography>
                    <Typography>
                      {formatBytes(openedFile?.size || 0)}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                  className={classes.contentDialogRight}
                >
                  <IconButton onClick={openFileToUpload}>
                    <Edit className={classes.iconCRUD} />
                  </IconButton>
                  <IconButton onClick={() => removeFiles(keyOfOpenedDialog)}>
                    <Delete className={classes.iconCRUD} />
                  </IconButton>
                </Box>
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions className={classes.dialogAction}>
          <Button onClick={closeDialog} className={classes.bodyTextBlack}>
            Cancel
          </Button>
          <Button
            onClick={closeDialog}
            autoFocus
            className={classes.bodyTextWhite}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Container maxWidth={false}>
        <Grid
          alignItems="flex-end"
          container
          justifyContent="space-between"
          spacing={3}
        >
          <Grid item>
            <ThemeProvider theme={theme}>
              <Typography className={classes.textTitle}>
                Upload DSP Data
              </Typography>
            </ThemeProvider>
          </Grid>
          <Grid item>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography className={classes.breadCrumbs}>Home</Typography>
              <Typography className={classes.breadCrumbs}>
                Transaction
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        <Typography className={classes.textSecondary}>
          Feature to Upload DSP Data
        </Typography>
        <Card className={classes.cardContent}>
          <CardContent>
            {listForm.map((key, index) => {
              const revenueField = fieldRevenueType[index];
              const showRevenue = typeof revenueField === "object";
              const showDeleteField = index < 5 && fieldRevenueType.length > 1;
              const file = revenueField?.file;
              return (
                <Grid key={key} container direction="row" spacing={4}>
                  <Grid item lg={5} md={5} xs={5}>
                    <Box display="flex" gridGap={3}>
                      <Typography className={classes.breadCrumbs}>
                        {dictionaryForForm[key] || ""}
                      </Typography>
                      {key !== "transfer_fee" && (
                        <Typography className={classes.warningRequired}>
                          *
                        </Typography>
                      )}
                    </Box>
                    {key === "upload_date" ? (
                      <KeyboardDatePicker
                        id="outlined-basic"
                        variant="outlined"
                        format="DD-MM-YYYY"
                        className={classes.textField}
                        inputVariant="outlined"
                        disabled
                        required
                        fullWidth={true}
                        value={data[key]?.value}
                        onChange={e => setDataValue(e, key, "value")}
                        margin="dense"
                      />
                    ) : key === "period" ? (
                      <Box className={classes.datePickerRange}>
                        <MonthYearRangePicker
                          startDate={payloadPeriod?.startDate}
                          handleChangeStartDate={date =>
                            handleChangePeriodPayload(date, "startDate")
                          }
                          endDate={payloadPeriod?.endDate}
                          handleChangeEndDate={date =>
                            handleChangePeriodPayload(date, "endDate")
                          }
                          width="100%"
                        />
                      </Box>
                    ) : (
                      <TextField
                        size="small"
                        variant="outlined"
                        select={key === "currency"}
                        value={data[key]?.value}
                        required
                        disabled={
                          key === "dsp" ||
                          (key === "exchange_rate" && !data?.currency?.value)
                        }
                        error={
                          key !== "transfer_fee" &&
                          useError &&
                          Boolean(!data[key]?.value)
                        }
                        className={classes.textField}
                        onChange={e => {
                          const value = e?.target?.value || e?.value;
                          const isCurrency = key === "currency";
                          if (isCurrency) {
                            const isEmpty = !value === 0;
                            if (isEmpty) {
                              setDataValue(0, "exchange_rate", "value");
                            } else {
                              const getValue = listCurrency.find(
                                item => item.id === value
                              );
                              getExchangeRate(getValue?.id);
                            }
                          }
                          setDataValue(value, key, "value");
                        }}
                        InputProps={
                          key !== "dsp" && {
                            startAdornment: (key === "exchange_rate" ||
                              key === "transfer_fee") && (
                                <InputAdornment
                                  className={classes.prefixCurrencyType}
                                  position="start"
                                >
                                  <Typography>{key === "transfer_fee" ? selectedSymbol : "Rp"}</Typography>
                                </InputAdornment>
                              ),
                            inputComponent: NumberFormatCustom,
                          }
                        }
                      >
                        <MenuItem value={0}>None</MenuItem>
                        {key === "currency" &&
                          listCurrency.map(
                            ({ currency_iso_code, id }, index) => {
                              return (
                                <MenuItem key={index} value={id}>
                                  {currency_iso_code}
                                </MenuItem>
                              );
                            }
                          )}
                      </TextField>
                    )}
                  </Grid>
                  {index < 5 && (
                    <Grid
                      item
                      lg={6}
                      md={6}
                      xs={6}
                      style={{ display: "flex", alignItems: "flex-end" }}
                    >
                      {showRevenue ? (
                        <Grid container spacing={2} alignItems="flex-end">
                          <Grid item lg={6} xs={6}>
                            <Box
                              display="flex"
                              alignItems="flex-end"
                              gridGap={3}
                            >
                              <InputLabel className={classes.breadCrumbs}>
                                Revenue Type
                              </InputLabel>
                              <Typography className={classes.warningRequired}>
                                *
                              </Typography>
                            </Box>
                            <TextField
                              size="small"
                              placeholder="Select Revenue Type"
                              select
                              value={
                                revenueField?.selectedRevenueType ||
                                "Select Revenue Type"
                              }
                              variant="outlined"
                              required
                              onChange={e => {
                                const value = e?.target?.value;
                                const isEmpty = value === "Select Revenue Type";
                                if (isEmpty) {
                                  setFieldRevenue(
                                    index,
                                    "selectedRevenueType",
                                    value
                                  );
                                } else {
                                  setFieldRevenue(
                                    index,
                                    "selectedRevenueType",
                                    value
                                  );
                                }
                              }}
                              className={classes.fieldRevenueType}
                            >
                              <MenuItem value="Select Revenue Type">
                                Select Revenue Type
                              </MenuItem>
                              {listRevenueType.map(
                                ({ revenue_type_id, name }) => (
                                  <MenuItem
                                    key={revenue_type_id}
                                    value={revenue_type_id}
                                  >
                                    {name}
                                  </MenuItem>
                                )
                              )}
                            </TextField>
                          </Grid>
                          <Grid item lg={6} xs={6}>
                            <Box display="flex" alignItems="flex-end">
                              {!file ? (
                                <Button
                                  className={classes.bodyTextWhite}
                                  style={{ height: "2.3rem" }}
                                  variant="contained"
                                  onClick={() => openDialog(index)}
                                >
                                  <Typography style={{ color: "white" }}>
                                    Upload Data
                                  </Typography>
                                </Button>
                              ) : (
                                <IconButton onClick={() => openDialog(index)}>
                                  <DescriptionRounded />
                                </IconButton>
                              )}
                              {showDeleteField && (
                                <IconButton
                                  onClick={() => deleteButtonUpload(index)}
                                  size="medium"
                                >
                                  <Delete />
                                </IconButton>
                              )}
                            </Box>
                          </Grid>
                        </Grid>
                      ) : (
                        <Grid container alignItems="flex-end">
                          <Grid item>
                            {index === fieldRevenueType?.length && (
                              <Button
                                onClick={() => addButtonUpload(index)}
                                className={classes.TextField}
                                style={{ height: "2.3rem" }}
                              >
                                <Box display="flex" gridGap={2}>
                                  <AddCircleOutlineRounded />
                                  Add More Field
                                </Box>
                              </Button>
                            )}
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  )}
                </Grid>
              );
            })}
            <Grid
              container
              spacing={4}
              alignItems="flex-end"
              justifyContent="flex-end"
            >
              <Grid item lg={3}>
                <Button
                  onClick={downloadHandle}
                  className={classes.bodyTextWhite}
                  variant="contained"
                  disabled={loadingTemplate}
                >
                  Download Template
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent="flex-end"
          className={classes.buttonBottom}
        >
          <Grid item lg={3}>
            <Button
              onClick={() => history.goBack()}
              className={classes.buttonBottomCancel}
              variant="contained"
            >
              Cancel
            </Button>
            <Button
              onClick={onSubmit}
              type="submit"
              disabled={!validateValueWithButton || loading}
              className={classes.bodyTextWhite}
              variant="contained"
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}

export default UploadDSPData;
