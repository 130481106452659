import {
  Backdrop,
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Divider,
  FormControl,
  Grid,
  OutlinedInput,
  TextField,
  Typography,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import { useEffect, useState } from "react";
import moment from "moment";
import NumberFormat from "react-number-format";
import { useHistory } from "react-router";
import Swal from "sweetalert2";
import DatePickerComponent from "../../../../components/molecules/DatePicker/DatePickerComponent";
import { Page } from "components";
import { hardBaseUrl } from "../../../../services/urlConstant";
let token = localStorage.getItem("token");

const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  results: {
    marginTop: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  formControl: {},
  mypadding: {
    "& .MuiSelect-outlined": {
      paddingTop: "10.5px",
      paddingBottom: "10.5px",
    },
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  pagination: {
    "& .Mui-selected": {
      backgroundColor: "black",
      color: "white",
    },
  },
  inputNum: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "0px 6px 6px 0px",
      height: "44px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderLeft: "1px solid #d1d5dc",
      borderRight: "1px solid #d1d5dc",
      borderTop: "1px solid #d1d5dc",
      borderBottom: "1px solid #d1d5dc",
    },
    "& .MuiOutlinedInput-input": {
      paddingTop: "10.5px",
      paddingBottom: "10.5px",
    },
    "& input.Mui-disabled": {
      color: "black",
    },
  },
  pageSize: {
    height: "30px",
    borderRadius: "6px",
    border: "1px solid #D1D5DC",
    paddingLeft: "5px",
    paddingRight: "5px",
    marginTop: "5px",
  },
  paginationText: {
    fontSize: "14px",
    fontWeight: "normal",
    fontStyle: "normal",
    color: "#687083",
  },
  breadcrumbs: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#111827",
  },
  breadCrumbsActive: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#687083",
  },
  placeholder: {
    "&::placeholder": {
      color: "#9AA2B1",
    },
  },
  title: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "28px",
  },
  label: {
    color: "#364052",
    fontSize: "14px",
    fontWeight: 500,
  },
  subTitle: {
    fontSize: "18px",
    fontWeight: 700,
    color: " #111827",
  },
  myinput: {
    "& .MuiOutlinedInput-input": {
      paddingTop: "11px",
      paddingLeft: "12px",
      paddingRight: "12px",
      paddingBottom: "11px",
    },
  },
  backdrop1: {
    zIndex: 100,
    color: "#fff",
  },
  mydateinput: {
    "& .MuiOutlinedInput-root": {
      paddingTop: "0px",
      paddingBottom: "0px",
      height: "44px",
    },
  },
}));

const dateNow = moment();

// Get the month, ensure it's two digits
const month = (dateNow.month() + 1).toString().padStart(2, "0");

// Get the date, ensure it's two digits
const date = dateNow.date().toString().padStart(2, "0");
const newDate = date.length > 1 ? date : "0" + date;

const AddPlatformShare = () => {
  const classes = useStyles();
  const history = useHistory();
  const [files, setFiles] = useState(null);
  const [submiting, setSubmiting] = useState(false);
  const [switchYear, setSwitchYear] = useState(false);
  const [periodDate, setPeriodDate] = useState({
    start_month: "",
    start_year: "",
    end_month: "",
    end_year: "",
  });
  const [body, setBody] = useState({
    transaction_name: "advanced",
    transaction_number: "",
    dsp_id: "all",
    type: "flat",
    start_date: "",
    end_date: "",
    revenue: 0,
    song_id: [],
    is_all_song: false,
    invoice_date: `${dateNow.getFullYear()}-${month}-${newDate}`,
  });

  const postTransaction = async newBody => {
    const urlTransactionNumber = `${hardBaseUrl}/publisher/transaction`;

    try {
      const response = await axios.post(urlTransactionNumber, newBody, {
        headers: { Authorization: `Bearer ${token}` },
      });

      return response.data;
    } catch (error) {
      throw error.response?.data?.errors[0]?.message ||
        "An error occurred during transaction posting.";
    }
  };

  const uploadDocuments = async publisherTransactionId => {
    const uploadUrl = `${hardBaseUrl}/publisher/transaction/document`;

    try {
      const formData = new FormData();
      formData.append("publisher_transaction_id", publisherTransactionId);

      files.forEach(res => {
        formData.append("documents", res);
      });

      const response = await axios.post(uploadUrl, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });

      return response.data;
    } catch (error) {
      throw error.response?.data?.errors[0]?.message ||
        "An error occurred during document upload.";
    }
  };

  const handleAdd = async () => {
    try {
      setSubmiting(true);
      const money = "";
      let newBody = body;
      let tempMoney = money;
      let convertMoney = tempMoney.replaceAll(",", "");
      newBody.revenue = Number(convertMoney);

      const resultOne = await postTransaction(newBody);

      let message = resultOne?.message || "Error in transaction";

      if (resultOne?.message === "success" && files?.length > 0) {
        const resultUpload = await uploadDocuments(
          resultOne.data.publisher_transaction_id
        );
        message = resultUpload?.message || "Error in document upload";
      }

      Swal.fire({
        icon: resultOne?.message === "success" ? "success" : "error",
        title: resultOne?.message === "success" ? "Success" : "Error",
        text: message,
      }).then(() => {
        setSubmiting(false);
        window.location.href = "/finance/advance";
      });
    } catch (error) {
      setSubmiting(false);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error,
      });
    }
  };

  const onChangePeriode = items => {
    if (items.name === "start_month") {
      let newDate = "";
      if (items.value < 11) {
        newDate = `0${items.value}`;
      } else {
        newDate = `${items.value}`;
      }
      let currentPeriod = periodDate;
      setPeriodDate({
        ...currentPeriod,
        start_month: newDate,
      });
    } else if (items.name === "start_year") {
      let currentPeriod = periodDate;
      setPeriodDate({
        ...currentPeriod,
        start_year: items.value,
      });
    } else if (items.name === "end_month") {
      let newDate = "";
      if (items.value < 11) {
        newDate = `0${items.value}`;
      } else {
        newDate = `${items.value}`;
      }
      let currentPeriod = periodDate;
      setPeriodDate({
        ...currentPeriod,
        end_month: newDate,
      });
    } else if (items.name === "end_year") {
      let currentPeriod = periodDate;
      setPeriodDate({
        ...currentPeriod,
        end_year: items.value,
      });
    }
  };
  useEffect(() => {
    if (periodDate.start_year !== "" && periodDate.end_year !== "") {
      if (periodDate.start_year > periodDate.end_year) {
        setSwitchYear(true);
      }
    }
  }, [periodDate]);

  useEffect(() => {
    if (switchYear) {
      let currentPeriod = periodDate;
      setPeriodDate({
        ...currentPeriod,
        start_year: currentPeriod.end_year,
        end_year: currentPeriod.start_year,
      });
      setSwitchYear(false);
    }
  }, [switchYear]);

  const handleFiles = async e => {
    e.preventDefault();

    if (e.target.files.length > 0) {
      const data = [];
      Array.from(e.target.files).forEach(file => {
        data.push(file);
      });
      setFiles(data);
    } else {
      setFiles(null);
    }
  };
  const handleClickFiles = e => {
    const { target = {} } = e || {};
    target.value = "";
  };

  return (
    <Page className={classes.root} title="Platform Share Add">
      <Backdrop className={classes.backdrop1} open={submiting}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container maxWidth={false}>
        <Grid
          alignItems="flex-end"
          container
          justifyContent="space-between"
          spacing={3}
        >
          <Grid item>
            <ThemeProvider theme={theme}>
              <Typography
                style={{
                  fontSize: "24px",
                  fontWeight: "700",
                  lineHeight: "32px",
                }}
              >
                Create Payment
              </Typography>
            </ThemeProvider>
          </Grid>
          <Grid item>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography className={classes.breadCrumbs}>Home</Typography>

              <Typography className={classes.breadCrumbs}>
                Platform Share
              </Typography>

              <Typography className={classes.breadCrumbsActive}>
                Create Payment
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>

        <Divider className={classes.divider} />

        <Grid container>
          <div>
            <ThemeProvider theme={theme}>
              <p
                style={{
                  marginTop: "5px",
                  color: "#8f8f8f",
                  fontSize: "14px",
                }}
              >
                Feature for create platform share payment
              </p>
            </ThemeProvider>
          </div>
        </Grid>

        <Grid container>
          <Grid item xs={12} md={8} lg={8} xl={8}>
            <Card
              style={{
                border: "1px solid #D1D5DC",
                borderRadius: "6px",
                boxShadow: "none",
              }}
            >
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6} lg={6} xl={6}>
                    <div
                      style={{
                        marginTop: "5px",
                        marginBottom: "5px",
                      }}
                    >
                      <div
                        style={{
                          marginTop: "12px",
                        }}
                      >
                        <ThemeProvider theme={theme}>
                          <Typography className={classes.label}>
                            Create Date
                          </Typography>
                        </ThemeProvider>
                      </div>
                      <div
                        style={{
                          marginTop: "6px",
                          marginBottom: "10px",
                        }}
                      >
                        <Grid style={{ display: "flex" }}>
                          <KeyboardDatePicker
                            fullWidth
                            variant="outlined"
                            format="DD-MM-YYYY"
                            multiline
                            value={body?.invoice_date}
                            onChange={e =>
                              setBody({
                                ...body,
                                invoice_date: e.format("DD MMM YYYY"),
                              })
                            }
                            inputVariant="outlined"
                            className={classes.mydateinput}
                          />
                        </Grid>
                      </div>

                      <div
                        style={{
                          marginTop: "12px",
                        }}
                      >
                        <ThemeProvider theme={theme}>
                          <Typography className={classes.label}>
                            Platform Share
                          </Typography>
                        </ThemeProvider>
                      </div>
                      <div
                        style={{
                          marginTop: "6px",
                          marginBottom: "10px",
                        }}
                      >
                        <FormControl fullWidth variant="outlined">
                          <OutlinedInput
                            placeholder="Platform Share"
                            id="outlined-adornment-amount"
                            className={classes.inputNum}
                            style={{
                              height: "44px",

                              borderRadius: "6px",
                            }}
                            inputProps={{
                              classes: {
                                input: classes.placeholder,
                              },
                            }}
                            fullWidth={true}
                          />
                        </FormControl>
                      </div>
                    </div>
                  </Grid>

                  <Grid item xs={12} md={6} lg={6} xl={6}>
                    <div
                      style={{
                        marginTop: "5px",
                        marginBottom: "5px",
                      }}
                    >
                      <div
                        style={{
                          marginTop: "12px",
                        }}
                      >
                        <ThemeProvider theme={theme}>
                          <Typography
                            variant="subtitle2"
                            className={classes.label}
                          >
                            Period
                          </Typography>
                        </ThemeProvider>
                      </div>
                      <div
                        style={{
                          marginTop: "6px",
                          marginBottom: "10px",
                        }}
                      >
                        <DatePickerComponent
                          onChange={e => setBody({ ...body, ...e })}
                          datePeriod={periodDate}
                          changePeriode={onChangePeriode}
                        />
                      </div>

                      <div
                        style={{
                          marginTop: "12px",
                        }}
                      >
                        <ThemeProvider theme={theme}>
                          <Typography className={classes.label}>
                            Payment
                          </Typography>
                        </ThemeProvider>
                      </div>
                      <div
                        style={{
                          marginTop: "6px",
                          marginBottom: "10px",
                        }}
                      >
                        <Grid style={{ display: "flex" }}>
                          <Grid
                            style={{
                              width: "48px",
                              height: "44px",
                              marginRight: "-2px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "6px 0px 0px 6px",
                              backgroundColor: "#F9FAFB",
                              border: " 1px solid #D1D5DC",
                              color: "#9AA2B",
                              borderRight: "none",
                            }}
                          >
                            Rp
                          </Grid>
                          <NumberFormat
                            disabled={false}
                            customInput={TextField}
                            variant="outlined"
                            thousandSeparator={true}
                            autoComplete="off"
                            className={classes.inputNum}
                            style={{
                              paddingLeft: "0px",
                              height: "44px",
                            }}
                            size="small"
                            fullWidth
                          />
                        </Grid>
                      </div>
                    </div>
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid item xs={12} md={12} lg={12} xl={12}>
                    <div
                      style={{
                        background: "#F9FAFB",
                        border: "1px dashed #D1D5DC",
                        borderRadius: "8px",
                        padding: "16px",
                        marginTop: "16px",
                        position: "relative",
                      }}
                    >
                      <input
                        type="file"
                        multiple
                        onChange={e => handleFiles(e)}
                        onClick={e => {
                          handleClickFiles(e);
                        }}
                        style={{
                          opacity: 0.0,
                          position: "absolute",
                          top: 0,
                          left: 0,
                          bottom: 0,
                          right: 0,
                          width: "100%",
                          height: "100%",
                        }}
                      />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          alt="Logo"
                          src={
                            require("assets/image-public/images/vector-upload-trans.png")
                              .default
                          }
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "Inter",
                            fontStyle: "normal",
                            fontWeight: "600",
                            fontSize: "14px",
                            lineHeight: "20px",
                            color: "#364052",
                          }}
                        >
                          Search File,&nbsp;
                        </span>
                        <span
                          style={{
                            fontFamily: "Inter",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "14px",
                            lineHeight: "20px",
                            color: "#111827",
                          }}
                        >
                          Drag supporting document here{" "}
                        </span>
                      </div>
                    </div>
                  </Grid>
                </Grid>

                <Grid container justifyContent="flex-end">
                  <div
                    style={{
                      marginTop: "32px",
                    }}
                  >
                    <Button
                      onClick={() => {
                        history.push("/admin/platform-share");
                      }}
                      style={{
                        borderRadius: "6px",
                        width: "84px",
                        height: "40px",
                        border: "1px solid #D1D5DC",
                        textTransform: "none",
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                  <div
                    style={{
                      marginTop: "32px",
                    }}
                  >
                    <Button
                      onClick={handleAdd}
                      style={{
                        marginLeft: "10px",
                        backgroundColor: "#111827",
                        borderRadius: "6px",
                        width: "84px",
                        height: "40px",
                        color: "#FFFFFF",
                        textTransform: "none",
                      }}
                    >
                      Add
                    </Button>
                  </div>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4} lg={4} xl={4}></Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default AddPlatformShare;
