import {
  Backdrop,
  Breadcrumbs,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useState } from "react";
import { ButtonGroupTop } from "../../components/atoms/Button";
import { Page } from "components";
import BlogPosting from "./BlogPosting";
import DashboardLandingPage from "./DashboardLandingPage";
import FooterLandingPage from "./FooterLandingPage";
import OurPartner from "./OurPartner";
import UserTestimony from "./UserTestimony";

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  breadcrumbs: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#111827",
  },
  breadCrumbsActive: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#687083",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  title: {
    fontSize: "24px",
    fontWeight: "700",
    lineHeight: "32px",
  },
}));

const listItems = [
  {
    id: 0,
    title: "Dashboard",
    selected: true,
  },
  {
    id: 1,
    title: "Footer",
    selected: false,
  },
  {
    id: 2,
    title: "Our Partner",
    selected: false,
  },
  {
    id: 3,
    title: "User Testimony",
    selected: false,
  },
  {
    id: 4,
    title: "Blog Posting",
    selected: false,
  },
];
function AdminLandingPage() {
  const classes = useStyles();
  const [selectedPage, setSelectedPage] = useState(0);
  const dictionaryPage = {
    "0": () => <DashboardLandingPage />,
    "1": () => <FooterLandingPage />,
    "2": () => <OurPartner />,
    "3": () => <UserTestimony />,
    "4": () => <BlogPosting />,
  };
  const UsedPage = dictionaryPage[selectedPage] || <span>Dashboard</span>;
  return (
    <Page className={classes.root} title="Landing Page">
      <Backdrop className={classes.backdrop} open={false}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container maxWidth={false}>
        <Grid
          alignItems="flex-end"
          container
          justifyContent="space-between"
          spacing={3}
        >
          <Grid item>
            <Typography className={classes.title}>Landing Page</Typography>
          </Grid>
          <Grid item>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography className={classes.breadCrumbs}>
                Landing Page
              </Typography>
              <Typography className={classes.breadCrumbs}>System</Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
        <ButtonGroupTop
          items={listItems}
          onSelected={i => setSelectedPage(i)}
          selectedPage={selectedPage}
        />
        <Divider className={classes.divider} />
        <UsedPage />
      </Container>
    </Page>
  );
}

export default AdminLandingPage;
