import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { IconButton } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  root: {
    "& > *": {
      margin: "0px",
      fontFamily: ["Helvetica Neue"].join(","),
      textTransform: "none",

      textAlign: "center",
      marginLeft: "10px",
    },
  },
  ib: {
    padding: "0px",
    color: "blue",

    "&:focus": {
      color: "blue",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "pink",
      borderColor: "#005cbf",
    },
    "&:hover": {
      boxShadow: "none",
    },
  },
  label: {
    textTransform: "capitalize",
    color: "black",
    fontFamily: ["Helvetica Neue"].join(","),
  },
}));

function ButtonAction({
  labelText,
  idSelected,
  handleClick,
  actionType,
  iconSelected,
}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div>
        <IconButton
          classes={{
            root: classes.ib,
          }}
          aria-label="add to shopping cart"
          onClick={() =>
            handleClick({ id: idSelected, actionType: actionType })
          }
          label="fufu"
        >
          {iconSelected}
        </IconButton>
      </div>
      <div className={classes.label}>
        <span>{labelText}</span>
      </div>
    </div>
  );
}

ButtonAction.propTypes = {
  actionType: PropTypes.string,
  idSelected: PropTypes.number,
};

export default ButtonAction;
