import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { makeStyles } from "@material-ui/styles";
import {
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    Typography,
    Box,
    CircularProgress,
    IconButton,
    MenuItem,
    TextField,
    InputAdornment,
    OutlinedInput
} from "@material-ui/core";
import { PrimaryButton } from "components";
import { Close, VisibilityOutlined, VisibilityOffOutlined } from "@material-ui/icons";

const useStyles = makeStyles(() => ({
    inputFields: {
        height: "44px",
        paddingTop: "0px",
        paddingBottom: "0px",
        "& .MuiOutlinedInput-root": {
            minWidth: 250,
            height: "44px",
            paddingTop: "0px",
            paddingBottom: "0px",
        },
    },
    buttonAdd: {
        width: "auto",
        fontStyle: "normal",
        backgroundColor: "black",
        color: "white",
        textTransform: "none",
    },
    required: {
        color: "red"
    },
    buttonCancel: {
        color: "black",
        marginLeft: 10,
        textTransform: "none",
    },
    buttonLoading: {
        color: "grey",
        marginLeft: 10,
        textTransform: "none",
    },
}));

const initialValue = {
    username: "",
    email: "",
    phone: "",
    society_id: "",
    role_id: "",
    password: "",
    confirm_password: ""
};

function AddOrEditSociety({ isOpen, onClose, listRoles, listSociety, selectedUserSociety, submit, loading }, ref) {
    const classes = useStyles();
    const [state, setState] = useState({
        value: initialValue,
        isFormInvalid: false,
        showPassword: false,
        showConfirmPassword: false
    });
    const { value, showPassword, showConfirmPassword } = state;
    const isEditing = Boolean(selectedUserSociety?.user_id);

    const changeValue = (key, value) => setState((currentState) => ({
        ...currentState,
        value: {
            ...currentState.value,
            [key]: value
        }
    }));

    const changeState = (key, value) => setState((currentState) => ({
        ...currentState,
        [key]: value
    }));
    const closeModal = () => {
        onClose();
        setState((currentState) => ({
            ...currentState,
            value: initialValue,
            showPassword: false,
            showConfirmPassword: false
        }));
    };

    useImperativeHandle(ref, () => ({
        closeModal: closeModal
    }));

    useEffect(() => {
        setState((currentState) => ({
            ...currentState,
            value: {
                ...currentState?.value,
                username: selectedUserSociety?.name,
                email: selectedUserSociety?.email,
                phone: selectedUserSociety?.phone,
                society_id: selectedUserSociety?.party_data?.id,
                role_id: selectedUserSociety?.role_id,
            }
        }));
    }, [selectedUserSociety]);
    return (
        <Dialog open={isOpen} onClose={closeModal} fullWidth maxWidth="xs" keepMounted={false}>
            <DialogTitle disableTypography>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="h3">{`${isEditing ? "Edit" : "Add"} User Society`}</Typography>
                    <IconButton onClick={closeModal}>
                        <Close />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box gridGap={2} display="grid">
                            <Box display="flex" gridGap={2}>
                                <Typography>User Name</Typography>
                                <Typography className={classes.required}>*</Typography>
                            </Box>
                            <TextField
                                placeholder="User name"
                                type="text"
                                className={classes.inputFields}
                                variant="outlined"
                                fullWidth
                                value={value?.username}
                                onChange={(e) => changeValue("username", e?.target?.value)}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box gridGap={2} display="grid">
                            <Box display="flex" gridGap={2}>
                                <Typography>Email</Typography>
                                <Typography className={classes.required}>*</Typography>
                            </Box>
                            <TextField
                                placeholder="Email"
                                type="email"
                                className={classes.inputFields}
                                variant="outlined"
                                fullWidth
                                value={value?.email}
                                onChange={(e) => changeValue("email", e?.target?.value)}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box gridGap={2} display="grid">
                            <Box display="flex" gridGap={2}>
                                <Typography>Phone Number</Typography>
                                <Typography className={classes.required}>*</Typography>
                            </Box>
                            <TextField
                                placeholder="Phone Number"
                                type="number"
                                className={classes.inputFields}
                                variant="outlined"
                                fullWidth
                                value={value?.phone}
                                onChange={(e) => changeValue("phone", e?.target?.value)}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box gridGap={2} display="grid">
                            <Box display="flex" gridGap={2}>
                                <Typography>Society</Typography>
                                <Typography className={classes.required}>*</Typography>
                            </Box>
                            <TextField
                                className={classes.inputFields}
                                variant="outlined"
                                select
                                fullWidth
                                value={value?.society_id || ""}
                                onChange={(e) => changeValue("society_id", e?.target?.value)}
                            >
                                {(listSociety || []).map(({ society_name, id }) => (
                                    <MenuItem value={id} key={id}>{society_name}</MenuItem>
                                ))}

                            </TextField>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box gridGap={2} display="grid">
                            <Box display="flex" gridGap={2}>
                                <Typography>Role</Typography>
                                <Typography className={classes.required}>*</Typography>
                            </Box>
                            <TextField
                                placeholder="Role"
                                className={classes.inputFields}
                                variant="outlined"
                                onChange={(e) => changeValue("role_id", e?.target?.value)}
                                select
                                fullWidth
                                value={value?.role_id || ""}
                            >
                                {(listRoles || []).map(({ role_id, name }) => (
                                    <MenuItem value={role_id} key={role_id}>{name}</MenuItem>
                                ))}

                            </TextField>
                        </Box>
                    </Grid>
                    {!isEditing && (
                        <Grid item xs={12}>
                            <Box gridGap={2} display="grid">
                                <Box display="flex" gridGap={2}>
                                    <Typography>Password</Typography>
                                    <Typography className={classes.required}>*</Typography>
                                </Box>
                                <OutlinedInput
                                    type={showPassword ? "text" : "password"}
                                    autoComplete="new-password"
                                    className={classes.inputFields}
                                    onChange={(e) => changeValue("password", e?.target?.value)}
                                    variant="outlined"
                                    fullWidth
                                    value={value?.password}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => changeState("showPassword", !showPassword)}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </Box>
                        </Grid>
                    )}
                    {!isEditing && (
                        <Grid item xs={12}>
                            <Box gridGap={2} display="grid">
                                <Box display="flex" gridGap={2}>
                                    <Typography>Confirm Password</Typography>
                                    <Typography className={classes.required}>*</Typography>
                                </Box>
                                <OutlinedInput
                                    type={showConfirmPassword ? "text" : "password"}
                                    autoComplete="new-password"
                                    value={value?.confirm_password}
                                    className={classes.inputFields}
                                    variant="outlined"
                                    fullWidth
                                    onChange={(e) => changeValue("confirm_password", e?.target?.value)}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => changeState("showConfirmPassword", !showConfirmPassword)}
                                                edge="end"
                                            >
                                                {showConfirmPassword ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </Box>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <Grid container spacing={2} justifyContent="flex-end">
                            <Grid item>
                                <PrimaryButton
                                    onClick={closeModal}
                                    variant="outlined"
                                    className={classes.buttonCancel}
                                    color="white"
                                    textColor="black"
                                >
                                    Cancel
                                </PrimaryButton>
                            </Grid>
                            <Grid item>
                                <PrimaryButton
                                    disabled={loading}
                                    onClick={() => submit(value)}
                                    variant="outlined"
                                    color={loading ? "grey" : "black"}
                                    textColor="white"
                                    height="-webkit-fill-available"
                                    className={loading ? classes.buttonLoading : classes.buttonAdd}
                                >
                                    {loading ? (
                                        <Box alignItems="center" display="flex">
                                            <CircularProgress size="20px" color="white" />
                                        </Box>
                                    ) : "Save"}
                                </PrimaryButton>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
            </DialogContent>

        </Dialog>
    );
}

const Components = forwardRef(AddOrEditSociety);

export default Components;