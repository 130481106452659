import { Container, Divider } from "@material-ui/core";
import { Box, Grid, Typography } from "@mui/material";
import {
  Page,
  PrimaryButton,
  SecondaryButton,
  SongUsageTrendChart,
  SongUsageTrendSummarytCard,
  TableCellMultipleRow,
} from "components";
import { InnoTableV2 } from "inno-ui";
import { HeaderTitle } from "layouts";
import moment from "moment-timezone";
import { useRef, useState } from "react";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { globalStyles } from "styles";

const PrintReportDetailReview = () => {
  const classes = globalStyles();
  const history = useHistory();
  const { state } = useLocation();
  const {
    song,
    queryParams,
    songDetailList,
    chartData,
    chartReport,
    dataTable,
  } = state;

  const inputRef = useRef(null);
  const [loadingButton, setLoadingButton] = useState(false);

  const handlePrintPDF = useReactToPrint({
    content: () => inputRef.current,
    onBeforeGetContent: () => setLoadingButton(true),
    onAfterPrint: () => setLoadingButton(false),
  });

  return (
    <Page className={classes.root} title="Review of Song Usage Detail">
      <Container maxWidth={false} id="content-to-print" ref={inputRef}>
        <HeaderTitle
          title={
            <Box>
              <Typography fontSize="24px">
                Song Title:{" "}
                <Typography component="span" fontSize="24px" fontWeight={700}>
                  {song?.title}
                </Typography>
              </Typography>
              <Typography fontWeight={400} fontSize="18px">
                {`Year of Song Usage: ${queryParams?.year}`}
              </Typography>
            </Box>
          }
        />
        <Divider className={classes.divider} />
        <Box border="1px solid #D1D5DC" borderRadius="6px" p="24px">
          {songDetailList?.map((item, index) => (
            <Grid container key={index}>
              <Grid item xs={2}>
                <Typography>{item?.title}</Typography>
              </Grid>
              <Grid item xs={10}>
                <Box display="flex">
                  {item?.title === "Composer"
                    ? (item?.name || [])?.map((composer, index) => (
                        <Typography
                          key={index}
                          fontWeight={composer?.is_on_this_publisher && 700}
                        >
                          {`${index > 0 ? "," : ""} ${composer?.name}`}
                        </Typography>
                      ))
                    : item?.name || "-"}
                </Box>
              </Grid>
            </Grid>
          ))}
        </Box>
        <Typography fontSize="24px" fontWeight={500} my="24px">
          Trend of Song Usage
        </Typography>

        <Box py="20px" border="1px solid #D1D5DC" borderRadius="6px">
          <SongUsageTrendChart chartData={chartData} preview />
        </Box>
        <SongUsageTrendSummarytCard chartReport={chartReport} />
        <InnoTableV2
          columns={columnTable || []}
          items={dataTable}
          isLoading={false}
        />
      </Container>
      <Box justifyContent="flex-end" display="flex" gap={1} m={3}>
        <SecondaryButton label="Back" onClick={() => history.goBack()} />
        <PrimaryButton
          label={loadingButton ? "Please Wait ..." : "Print Report"}
          loading={loadingButton}
          disabled={loadingButton}
          onClick={handlePrintPDF}
        />
      </Box>
    </Page>
  );
};

const typeWeb = localStorage.getItem("typeWeb");
const isCaris = typeWeb === "caris";
const columnTable = [
  {
    name: "date",
    title: "Month",
    renderText: value => moment(value).format("MMMM"),
  },
  {
    name: "date",
    title: "Year",
    renderText: value => moment(value).format("YYYY"),
  },
  {
    name: "summaries",
    title: "Traffic",
    headerAlign: "right",
    renderText: item => (
      <TableCellMultipleRow list={item} itemKey="listener" type="number" />
    ),
  },
  {
    name: "summaries",
    title: "Revenue",
    headerAlign: "right",
    renderText: item => (
      <TableCellMultipleRow
        list={item}
        itemKey="base_currency_revenue"
        type="number"
        prefix="Rp"
      />
    ),
  },
  ...(isCaris
    ? [
        {
          name: "summaries",
          title: "Composer Revenue",
          headerAlign: "right",
          renderText: item => (
            <TableCellMultipleRow
              list={item}
              itemKey="base_currency_composer_revenue"
              type="number"
              prefix="Rp"
            />
          ),
        },
        {
          name: "summaries",
          title: "Publisher Revenue",
          headerAlign: "right",
          renderText: item => (
            <TableCellMultipleRow
              list={item}
              itemKey="base_currency_publisher_revenue"
              type="number"
              prefix="Rp"
            />
          ),
        },
      ]
    : []),
  {
    name: "summaries",
    title: "Product Type",
    renderText: item => (
      <TableCellMultipleRow list={item} itemKey="product_type" />
    ),
  },
];
export default PrintReportDetailReview;
