import {
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  Checkbox,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Menu,
  OutlinedInput,
  TextField,
  Typography,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import "../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Page } from "components";
import { hardBaseUrl } from "../../../../services/urlConstant";

const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

function DetailGroup(props) {
  const ref = useRef(null);
  const [error, setError] = useState({
    agentName: false,
    email: false,
    phone: false,
  });
  const [chipArray, setChipArray] = useState([]);
  const [openMenu, setOpenMenu] = useState(null);
  const [memberOption, setMemberOption] = useState([]);
  const [memberId, setMemberId] = useState([]);
  const [selectedMember, setSelectedMember] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const groupName = "";

  const clickMenu = event => {
    setOpenMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenu(null);
  };
  const getMemberOption = () => {
    const token = localStorage.getItem("token");
    const url = `${hardBaseUrl}/publisher/composer?page=1&size=10&search=${searchKey}`;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .get(url, config)
      .then(res => {
        const newMemberOption = res.data.data.map(item => {
          return {
            name: item.sure_name,
            member_id: item.composer_id,
            checked: selectedMember.some(
              selected => selected.member_id === item.composer_id
            ),
          };
        });

        setMemberOption(newMemberOption);
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      });
  };

  useEffect(() => {
    getMemberOption();
  }, [searchKey]);

  const handleChange = e => {
    const value = e.target.value;
    const name = e.target.name;

    if (name === "agentName") {
      if (value === "") {
        setError({
          ...error,
          agentName: true,
        });
      } else {
        setError({
          ...error,
          agentName: false,
        });
      }
    } else if (name === "email") {
      if (value === "") {
        setError({
          ...error,
          email: true,
        });
      } else {
        setError({
          ...error,
          email: false,
        });
      }
    } else if (name === "phone") {
      if (value === "") {
        setError({
          ...error,
          phone: true,
        });
      } else {
        setError({
          ...error,
          phone: false,
        });
      }
    }
  };

  const handleSaveData = e => {
    e.preventDefault();
    const url = `${hardBaseUrl}/publisher/group/create`;
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const newSelectedMember = selectedMember.map(item => {
      return item.member_id;
    });

    const data = {
      group_name: groupName,
      composer_id: newSelectedMember,
    };

    axios
      .post(url, data, config)
      .then(() => {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Data has been saved",
        });
        props.history.push("/admin/parameter/group");
      })
      .catch(err => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: err.response.data.errors[0].message,
        });
      });
  };

  const handleChangeSearchDsp = e => {
    setSearchKey(e.target.value);
  };

  const makeCheck = async (checked, id) => {
    let listCheckDsp = memberOption;
    let newArr = [];
    let newItem = null;

    listCheckDsp.forEach(item => {
      if (item.member_id === Number(id)) {
        newItem = {
          ...item,
          checked: checked,
        };
      } else {
        newItem = {
          ...item,
        };
      }
      newArr.push(newItem);
    });
    setMemberOption(newArr);
  };

  const handleChangeCheckBox = async (event, item) => {
    const { id, name, checked } = event.target;

    if (checked === false) {
      makeCheck(checked, id);
      setChipArray(chipArray.filter(chip => chip.name !== name));
      setSelectedMember(selectedMember.filter(chip => chip.name !== name));

      const newDataMember = memberId.filter(
        item => Number(item) !== Number(id)
      );

      setMemberId(newDataMember);
    } else {
      makeCheck(checked, id);
      let currentMember = memberId;
      let newMemberArr = [...currentMember, id];
      setMemberId(newMemberArr);
      setChipArray([...chipArray, item]);
      setSelectedMember([...selectedMember, item]);
    }
  };

  const useStyles = makeStyles(theme => ({
    root: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    thePercent: {
      fontSize: "30px",
    },
    results: {
      marginTop: theme.spacing(3),
    },
    divider: {
      margin: theme.spacing(2, 0),
      borderTop: "1px solid #e1e1e1",
    },
    btnSubmit: {
      backgroundColor: "black",

      color: "white",
      padding: "10px 28px",
      width: "106px",
      height: "40px",
      borderRadius: "6px",

      "&:hover": {
        backgroundColor: "black",
        color: "white",
      },
    },
    btnPengaturanPencipta: {
      backgroundColor: "black",

      color: "white",
      padding: "10px 28px",
      borderRadius: "6px",
      "&:hover": {
        backgroundColor: "black",
        color: "white",
      },
    },
    btnPreview: {
      backgroundColor: "black",
      marginLeft: "10px",
      color: "white",
      padding: "10px 28px",
      width: "106px",
      height: "40px",
      borderRadius: "6px",

      "&:hover": {
        backgroundColor: "black",
        color: "white",
      },
    },
    btnDialog: {
      backgroundColor: "black",
      marginLeft: "10px",
      color: "white",
      padding: "10px 28px",
      width: "150px",

      "&:hover": {
        backgroundColor: "black",
        color: "white",
      },
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    headText: {
      fontSize: "12px",
      color: "#687083",
      fontWeight: "bold",
    },
    bodyTextBlack: {
      fontSize: "14px",
      fontWeight: "regular",
      color: "#111827",
      fontStyle: "normal",
    },
    dropdown: {
      color: "black",
    },
    conTextBlack: {
      fontSize: "14px",
      fontWeight: "bold",
    },
    label: {
      color: "#364052",
      fontSize: "14px",
      fontWeight: 500,
    },
    subTitle: {
      fontSize: "18px",
      fontWeight: 700,
      color: " #111827",
    },
    breadcrumbs: {
      fontSize: "14px",
      fontWeight: 400,
      fontStyle: "regular",
      color: "#111827",
    },
    breadCrumbsActive: {
      fontSize: "14px",
      fontWeight: 400,
      fontStyle: "regular",
      color: "#687083",
    },
    textField: {
      "& .MuiOutlinedInput-input": {
        height: "1px",
      },
    },
    select: {
      "& .MuiSelect-root": {
        height: "4px",
      },
      "& .MuiSelect-select": {
        height: "4px",
      },
    },
    scrollingCard: {
      "&::-webkit-scrollbar": {
        width: 1,
      },
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "lightgrey",
        outline: "1px solid slategrey",
      },
    },
    myCheckBox: {
      "&.MuiCheckbox-root": {
        color: "black",

        "&.Mui-checked": {
          color: "black",
        },
      },
    },
    font12: {
      fontSize: "12px",
      color: "#A63124",
    },
    font24: {
      fontSize: "24px",
      fontWeight: "700",
      lineHeight: "32px",
    },
    textStyle: {
      marginTop: "5px",
      color: "#8f8f8f",
      fontSize: "14px",
    },
    card: {
      border: "1px solid #9AA2B1",
      borderRadius: "6px",
      boxShadow: "none",
    },
    mt5: {
      marginTop: "5px",
    },
    mtMin5: {
      marginTop: "-5px",
    },
    mt10: {
      marginTop: "10px",
    },
    mtMin10: {
      marginTop: "-10px",
    },
    mb5: {
      marginBottom: "5px",
    },
    mbMin5: {
      marginBottom: "-5px",
    },
    mb10: {
      marginBottom: "10px",
    },
    mbMin10: {
      marginBottom: "-10px",
    },
    mb15: {
      marginBottom: "15px",
    },
    mr5: {
      marginRight: "5px",
    },
    ml5: {
      marginLeft: "5px",
    },
    flex: {
      display: "flex",
    },
    between: {
      justifyContent: "space-between",
    },
    center: {
      justifyContent: "center",
    },
    color687083: {
      color: "#687083",
    },
    outline: {
      width: "100%",
      marginRight: "20px",
      height: "44px",
      border: "1px solid #D1D5DC",
      borderRadius: "6px",
    },
    pointer: {
      cursor: "pointer",
    },
    paper: {
      boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
      display: "flex",
      flexDirection: "column",
      background: "white",
      position: "absolute",
      "& > *": {
        margin: theme.spacing(1),
        width: theme.spacing(16),
        height: theme.spacing(16),
      },
      zIndex: "1000",
    },
    containerForm: {
      width: "300px",
      minHeight: "100px",
      maxHeight: "300px",
      marginTop: "40px",
      borderTop: "1px solid #E4E7EB",
      overflow: "scroll",
    },
    gap4: {
      display: "flex",
      gap: "4px",
      marginTop: "10px",
    },
    colorRed: {
      color: "red",
    },
    displayNone: {
      display: "none",
    },
    height38: {
      height: "38px",
    },
    mt100: {
      marginTop: "100px",
    },
    flexColumn: {
      display: "flex",
      flexDirection: "column",
    },
    width300: {
      width: "300px",
    },
    flexCenter: {
      display: "flex",
      justifyContent: "center",
      marginTop: "10px",
    },
  }));
  const classes = useStyles();

  const getChipArrayLengthStyle = item => {
    return (
      <div className={classes.flex}>
        <IconButton
          classes={{
            root: classes.ib,
          }}
        >
          <ThemeProvider theme={theme}>
            <Typography className={classes.font12}>{item.length}</Typography>
          </ThemeProvider>
        </IconButton>
      </div>
    );
  };

  return (
    <Page className={classes.root} title="Add Group">
      <Container maxWidth={false}>
        <form ref={ref}>
          <Grid
            alignItems="flex-end"
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <ThemeProvider theme={theme}>
                <Typography className={classes.font24}>Add Group</Typography>
              </ThemeProvider>
            </Grid>
            <Grid item>
              <Breadcrumbs aria-label="breadcrumb">
                <Typography className={classes.breadCrumbs}>Home</Typography>

                <Typography className={classes.breadCrumbs}>
                  Parameter
                </Typography>

                <Typography className={classes.breadCrumbs}>Group</Typography>

                <Typography className={classes.breadCrumbsActive}>
                  Add Group
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
          <Grid container>
            <div>
              <ThemeProvider theme={theme}>
                <Typography
                  component="h1"
                  variant="h3"
                  className={classes.subTitle}
                >
                  Group Information
                </Typography>
              </ThemeProvider>

              <ThemeProvider theme={theme}>
                <p className={classes.textStyle}>{"To add Group's data."}</p>
              </ThemeProvider>
            </div>
          </Grid>

          <Grid container>
            <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
              <Card className={classes.card}>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <div className={(classes.mt5, classes.mb5)}>
                        <div className={(classes.mt10, classes.mb10)}>
                          <ThemeProvider theme={theme}>
                            <Typography className={classes.label}>
                              Name
                            </Typography>
                          </ThemeProvider>
                        </div>
                        <div className={(classes.mt10, classes.mb10)}>
                          <TextField
                            id="outlined-basic"
                            placeholder="Name"
                            className={classes.textField}
                            variant="outlined"
                            fullWidth={true}
                            name="groupName"
                            onChange={handleChange}
                          />
                          <small className={classes.colorRed}>
                            {error.agentName ? "Agent Name is required" : ""}
                          </small>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <>
                        <div className={(classes.mt5, classes.mb5)}>
                          <div className={(classes.mt10, classes.mb10)}>
                            <ThemeProvider theme={theme}>
                              <Typography className={classes.label}>
                                Member
                              </Typography>
                            </ThemeProvider>
                          </div>
                          <FormControl
                            fullWidth
                            className={classes.margin}
                            variant="outlined"
                          >
                            <OutlinedInput
                              variant="outlined"
                              id="outlined-adornment-amount"
                              size="small"
                              onClick={clickMenu}
                              className={
                                (classes.inputFilter, classes.height38)
                              }
                              startAdornment={
                                <InputAdornment position="start">
                                  <div>Select Member</div>
                                </InputAdornment>
                              }
                              endAdornment={
                                <InputAdornment position="end">
                                  <div>
                                    {getChipArrayLengthStyle(chipArray)}
                                  </div>
                                  <ArrowDropDownIcon
                                    onClick={clickMenu}
                                    className={classes.pointer}
                                  />
                                </InputAdornment>
                              }
                              fullWidth={false}
                            />

                            <Menu
                              id="simple-menu"
                              anchorEl={openMenu}
                              keepMounted
                              className={(classes.mt100, classes.pointer)}
                              open={Boolean(openMenu)}
                              onClose={handleCloseMenu}
                            >
                              <div className={classes.flexColumn}>
                                <Container className={classes.width300}>
                                  <FormControl className={classes.flexCenter}>
                                    <TextField
                                      className={classes.inputFields}
                                      type="text"
                                      variant="outlined"
                                      value={searchKey}
                                      placeholder="Search"
                                      onChange={event =>
                                        handleChangeSearchDsp(event)
                                      }
                                      InputProps={{
                                        startAdornment: (
                                          <InputAdornment position="start">
                                            <img
                                              alt="Logo"
                                              src={
                                                require("assets/image-public/images/search.svg")
                                                  .default
                                              }
                                            />
                                          </InputAdornment>
                                        ),
                                      }}
                                    />
                                  </FormControl>
                                  <div>
                                    <FormControl>
                                      {memberOption.map((item, index) => {
                                        return (
                                          <FormControlLabel
                                            key={index}
                                            control={
                                              <Checkbox
                                                className={classes.myCheckBox}
                                                id={`${item.member_id}`}
                                                key={index}
                                                checked={item.checked}
                                                name={item.name}
                                                value={item.member_id}
                                                onChange={e =>
                                                  handleChangeCheckBox(
                                                    e,
                                                    item,
                                                    index
                                                  )
                                                }
                                              />
                                            }
                                            label={item.name}
                                          />
                                        );
                                      })}
                                    </FormControl>
                                  </div>
                                </Container>
                              </div>
                            </Menu>
                          </FormControl>
                        </div>
                      </>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={4} md={4} sm={4} lg={4} xl={4}></Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
              <Divider className={classes.divider} />
            </Grid>
          </Grid>

          <Grid container>
            <div className={classes.gap4}>
              <PrimaryButton onClick={e => handleSaveData(e)} label="Save" />
              <PrimaryButton
                onClick={() => window.history.back()}
                label="Back"
              />
            </div>
          </Grid>
        </form>
      </Container>
      {}
    </Page>
  );
}

export default DetailGroup;
