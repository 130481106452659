import { useEffect, useRef, useState, useCallback } from "react";
import { Input } from "antd";
import Axios from "axios";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import { Box, Typography } from "@material-ui/core";
import Swal from "sweetalert2";
import logoMpis from "../../../assets/img/big-logo-mpis.svg";
import CARIS from "../../../assets/img/newCarisLogo.webp";
import LayoutNoHeader from "../../../mpisLandingComponent/LayoutNoHeader";
import { hardBaseUrl, hardTypeWeb } from "../../../services/urlConstant";
import "./Otp.css";
import { Timer } from "utils";

const useStyles = makeStyles(() => ({
  logo: {
    marginBottom: "50px",
    width: props => (props.isMpis ? "200px" : "256px"),
  },
  container: {
    boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
    padding: "30px",
    borderRadius: "6px",
  },
  title: {
    fontSize: "30px",
    marginTop: "5px",
  },
  description: {
    color: "#687083",
  },
  colorRed: {
    color: "red"
  },
  colorGrey: {
    color: "#687083",
  },
  input: {
    height: "50px",
    width: "50px",
    borderRadius: "5px",
    marginTop: "20px",
    marginLeft: "5px",
    marginRight: "5px",
    fontSize: "24px",
    textAlign: "center",
  },
  resendButton: {
    border: "none",
    backgroundColor: "transparent",
    color: props => (props.disableResend ? "#D1D5DC" : "#9545EB"),
    cursor: props => (props.disableResend ? "not-allowed" : "pointer"),
  },
  otpContainer: {
    display: "flex",
    justifyContent: "center",
  },
  timerContainer: {
    marginTop: "20px",
    display: "flex",
    justifyContent: "space-between",
  },
}));

const formatTime = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  return `${minutes < 10 ? "0" : ""}${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
};

export default function Otp() {
  let history = useHistory();
  const { state } = useLocation();
  const message = useSelector((state) => state.auth.message);
  const isMpis = hardTypeWeb === "mpis";
  const [disableResend, setDisableResend] = useState(true);
  const [time, setTime] = useState(120);
  const [loginDisabled, setLoginDisabled] = useState(false);
  const classes = useStyles({ isMpis, disableResend });
  const refTwo = useRef(null);
  const refThree = useRef(null);
  const refFour = useRef(null);
  const refFive = useRef(null);
  const refSix = useRef(null);
  const [inOne, setInOne] = useState("");
  const [inTwo, setInTwo] = useState("");
  const [inThree, setInThree] = useState("");
  const [inFour, setInFour] = useState("");
  const [inFive, setInFive] = useState("");
  const [inSix, setInSix] = useState("");

  useEffect(() => {
    if (message !== null) {
      window.location.reload();
    }
  }, [message]);

  useEffect(() => {
    if (!state?.email) {
      history.push("/");
    }
  }, [state]);

  const handleLogin = async (e) => {
    e?.preventDefault();
    const isFilled = inOne && inTwo && inThree && inFour && inFive && inSix;
    const otp = inOne + inTwo + inThree + inFour + inFive + inSix;
    if (otp.length === 6 && isFilled) {
      setLoginDisabled(true);
      const payload = { otp };
      try {
        const resultLogin = await Axios.post(
          hardBaseUrl + "/auth/verify-otp",
          payload,
          { headers: { "Content-Type": "application/json" } }
        );
        history.push("/redirect/" + resultLogin.data.data.access_token);
      } catch (err) {
        Swal.fire({
          icon: "error",
          title: "Failed!",
          text: err.response.data.errors[0].message,
          showCloseButton: true,
        }).then((res) => {
          if (res.isConfirmed) {
            setInOne("");
            setInTwo("");
            setInThree("");
            setInFour("");
            setInFive("");
            setInSix("");
          }
        });
      } finally {
        setLoginDisabled(false);
      }
    }
  };

  const onFillOne = (e) => {
    setInOne(e);
    refTwo.current.focus();
  };
  const onFillTwo = (e) => {
    setInTwo(e);
    refThree.current.focus();
  };
  const onFillThree = (e) => {
    setInThree(e);
    refFour.current.focus();
  };
  const onFillFour = (e) => {
    setInFour(e);
    refFive.current.focus();
  };
  const onFillFive = (e) => {
    setInFive(e);
    refSix.current.focus();
  };
  const onFillSix = (e) => setInSix(e);

  useEffect(() => {
    const isFilled = inOne && inTwo && inThree && inFour && inFive && inSix;
    if (isFilled) {
      handleLogin();
    }
  }, [inOne, inTwo, inThree, inFour, inFive, inSix]);

  const onResend = async () => {
    const url = `${hardBaseUrl}/auth/resend-otp`;
    setLoginDisabled(true);
    setDisableResend(true);
    if (state?.email) {
      try {
        const result = await Axios.post(url, { email: state?.email });
        if (result?.status === 200) {
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: "OTP has been sent to your email.",
            showCloseButton: true,
          });
          setTime(120);
          setLoginDisabled(false);
          setDisableResend(true);
        }
      } catch (err) {
        Swal.fire({
          icon: "error",
          title: "Failed!",
          text: err?.response?.data?.errors[0]?.message || "Something went wrong!",
          showCloseButton: true,
        });
      }
    }
  };

  const onTimeEnd = useCallback(() => {
    setDisableResend(false);
    setTime(0);
  }, []);

  return (
    <LayoutNoHeader>
      <Box className="login">
        <Box className="content">
          <Box className="header">
            <img src={isMpis ? logoMpis : CARIS} alt="logo" className={classes.logo} />
          </Box>
          <Box className={classes.container}>
            <h1 className={classes.title}>Confirmation</h1>
            <h3 className={classes.description}>
              {`Enter the OTP code that has been sent to email ${state?.email || ""}`}
            </h3>
            <form onSubmit={handleLogin}>
              <Box className={classes.otpContainer}>
                <Input onChange={(e) => onFillOne(e.target.value)} value={inOne} maxLength={1} autoFocus className={classes.input} disabled={loginDisabled} />
                <Input ref={refTwo} onChange={(e) => onFillTwo(e.target.value)} value={inTwo} className={classes.input} disabled={loginDisabled} />
                <Input ref={refThree} onChange={(e) => onFillThree(e.target.value)} value={inThree} className={classes.input} disabled={loginDisabled} />
                <Input ref={refFour} onChange={(e) => onFillFour(e.target.value)} value={inFour} className={classes.input} disabled={loginDisabled} />
                <Input ref={refFive} onChange={(e) => onFillFive(e.target.value)} value={inFive} className={classes.input} disabled={loginDisabled} />
                <Input ref={refSix} onChange={(e) => onFillSix(e.target.value)} value={inSix} className={classes.input} disabled={loginDisabled} />
              </Box>
              <Box className={classes.timerContainer}>
                <Timer
                  time={time}
                  onTimeEnd={onTimeEnd}
                  renderText={(value) => (
                    <Box display="flex" alignItems="center">
                      <Typography component="span" className={classes.colorGrey}>
                        Time remaining
                      </Typography>
                      <Box ml={0.5}>
                        <Typography className={time === 0 ? classes.colorRed : classes.colorGrey} component="span">
                          {formatTime(value)}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                />
                <button className={classes.resendButton} onClick={onResend} disabled={disableResend}>Resend</button>
              </Box>
            </form>
          </Box>
        </Box>
      </Box>
    </LayoutNoHeader>
  );
}
