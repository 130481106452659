import {
  Backdrop,
  Breadcrumbs,
  Button,
  CircularProgress,
  Container,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import { useState } from "react";
import { useHistory } from "react-router";
import { Page } from "components";
const fontInter = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
    h5: {
      fontWeight: 700,
    },
  },
});
const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  colorPickerArea: {},
  buttonCancel: {
    textTransform: "none",
    marginTop: "16px",
    backgroundColor: "white",
    color: "black",
    width: "135px",
    height: "40px",
    "&:hover": {
      backgroundColor: "white",
      color: "black",
    },
  },
  button: {
    textTransform: "none",
    marginTop: "16px",
    backgroundColor: "black",
    color: "white",
    width: "135px",
    height: "40px",
    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
  imgPreview: {
    maxHeight: "120px",
  },
  fileUploadArea: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  title: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "28px",
  },
  subTitle: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#6e6e6e",
  },
  formColor: {},
  inputFields: {
    height: "44px",
    paddingTop: "0px",
    paddingBottom: "0px",
  },
  inputFieldsUrl: {
    height: "44px",
    paddingTop: "0px",
    paddingBottom: "0px",
    borderRight: "1px solid #D1D5DC",
  },
  inputFieldsTlp: {
    height: "44px",
    paddingTop: "0px",
    paddingBottom: "0px",
    borderLeft: "1px solid #D1D5DC",
    paddingLeft: "14px",
  },
  myTextField: {
    height: "44px",
    paddingBottom: "0px",
    paddingTop: "0px",
  },
  label: {
    fontStyle: "normal",
    fontWeight: "500px",
    fontSize: "14px",
    lineHeight: "20px",
    width: "140px",
    height: "20px",
  },
  autoComplete: {
    "& .MuiOutlinedInput-root": {
      padding: "0px",
      height: "44px",
    },
  },
  inputNum: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "6px 0px 0px 6px",
      height: "44px",
      "&:hover fieldset": {
        borderColor: "#d1d5dc",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderLeft: "1px solid #d1d5dc",
      borderRight: "0px solid #d1d5dc",
      borderTop: "1px solid #d1d5dc",
      borderBottom: "1px solid #d1d5dc",
    },
    "& .MuiOutlinedInput-input": {
      paddingTop: "10.5px",
      paddingBottom: "10.5px",
    },
    "& input.Mui-disabled": {
      color: "black",
    },
  },
}));
const messageList = [
  {
    id: 0,
    value: 0,
    name: "Alert Success Message",
  },
  {
    id: 1,
    value: 1,
    name: "Alert Error Message",
  },
];

const codeList = ["aasd3edssf", "8a8xcafdjkhkdfs", "8csd3jcdad", "2khkjh74mjg"];
const EditPajak = () => {
  const history = useHistory();

  const [selectedMethod, setSelectedMethod] = useState("");
  const classes = useStyles();
  const theme = createTheme({
    typography: {
      fontFamily: ["Helvetica Neue"].join(","),
    },
  });

  return (
    <Page className={classes.root} title="Add Message">
      <Backdrop className={classes.backdrop} open={false}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container maxWidth={false}>
        <Grid
          alignItems="flex-end"
          container
          justifyContent="space-between"
          spacing={3}
        >
          <Grid item>
            <ThemeProvider theme={theme}>
              <Typography
                style={{
                  fontSize: "24px",
                  fontWeight: "700",
                  lineHeight: "32px",
                }}
              >
                Add Message
              </Typography>
            </ThemeProvider>
          </Grid>
          <Grid item>
            <Breadcrumbs aria-label="breadcrumb">
              <a href="/admin/konfigurasi/pajak">Master</a>
              <a href="/admin/konfigurasi/pajak">Administration</a>
              <Typography>Add Message</Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <ThemeProvider theme={theme}>
              <Typography variant="h5" className={classes.title}>
                Message Information
              </Typography>
              <Typography
                variant="caption"
                className={classes.subTitle}
                style={{
                  marginTop: "8px",
                }}
              >
                Customize and manage message for the feature
              </Typography>
            </ThemeProvider>
            <Grid
              style={{
                border: "1px solid #9AA2B1",
                padding: "16px",
                borderRadius: "8px",
                marginTop: "16px",
              }}
              container
              spacing={2}
            >
              <Grid item xs={12}>
                <ThemeProvider theme={fontInter}>
                  <Typography
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    Code
                  </Typography>
                </ThemeProvider>
                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  fullWidth={true}
                >
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    autoWidth={true}
                    placeholder="Select Code"
                    fullWidth={true}
                    style={{
                      height: "44px",
                      marginTop: "6px",
                    }}
                  >
                    {codeList.map(item => {
                      return (
                        <MenuItem key={item} value={item}>
                          {item}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <ThemeProvider theme={fontInter}>
                  <Typography
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    Message Type
                  </Typography>
                </ThemeProvider>
                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  fullWidth={true}
                >
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    onChange={e => setSelectedMethod(e.target.value)}
                    autoWidth={true}
                    fullWidth={true}
                    placeholder="Select Message Type"
                    value={selectedMethod || null}
                    style={{
                      height: "44px",
                      marginTop: "6px",
                    }}
                  >
                    {messageList.map(item => {
                      return (
                        <MenuItem key={item.id} value={item.value}>
                          {item.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <ThemeProvider theme={fontInter}>
                  <Typography
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    Message Content
                  </Typography>
                </ThemeProvider>
                <TextField
                  style={{
                    marginTop: "6px",
                  }}
                  fullWidth
                  margin="normal"
                  placeholder="Write Your Message Content Here"
                  type="text"
                  variant="outlined"
                  rows={4}
                  multiline
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Grid container justifyContent="flex-end">
              <Button
                onClick={() => {
                  history.push("/admin/konfigurasi/message");
                }}
                variant="outlined"
                style={{
                  marginRight: "8px",
                }}
                className={classes.buttonCancel}
              >
                <ThemeProvider theme={fontInter}>
                  <Typography
                    style={{
                      fontWeight: 500,
                      fontSize: "14px",
                      lineHeight: "20px",
                    }}
                  >
                    Cancel
                  </Typography>
                </ThemeProvider>
              </Button>
              <Button
                variant="contained"
                type="submit"
                className={classes.button}
              >
                <ThemeProvider theme={fontInter}>
                  <Typography
                    style={{
                      color: "white",
                      fontWeight: 500,
                      fontSize: "14px",
                      lineHeight: "20px",
                    }}
                  >
                    Save Changes
                  </Typography>
                </ThemeProvider>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};
export default EditPajak;
