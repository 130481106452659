import {
  Button,
  Divider,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CARIS from "../../../assets/img/newCarisLogo.webp";
import logoMpis from "../../../assets/img/newMpisLogo.webp";
import LayoutNoHeader from "../../../mpisLandingComponent/LayoutNoHeader";
import { hardSubDomain } from "../../../services/urlConstant";

export default function ManageRole() {
  const message = useSelector(state => state.auth.message);
  const [roleList, setRoleList] = useState([]);

  const [selectedIndex, setSelectedIndex] = useState("");
  const [selectedName, setSelectedName] = useState("");

  useEffect(() => {
    setRoleList([
      {
        composer_id: 1862,
        composer_name: "Administrator",
        is_current_composer: true,
      },
      {
        composer_id: 15599,
        composer_name: "Publisher",
        is_current_composer: true,
      },
    ]);
  }, []);

  useEffect(() => {
    if (message !== null) {
      window.location.reload();
    }
  }, [message]);

  const getLogo = () => {
    if (hardSubDomain === null) {
      return <img src={logoMpis} alt="logo" className="logo" width={"400px"} />;
    } else if (hardSubDomain === "mpis") {
      return <img src={logoMpis} alt="logo" className="logo" width={"200px"} />;
    } else {
      return <img src={CARIS} alt="" width="206px" />;
    }
  };

  const selectName = (id, theindex) => {
    setSelectedName(roleList[theindex].composer_name);
    let theSelected = roleList[theindex];
    let allItems = roleList;
    let theSelectedIndex = selectedIndex;
    if (theSelectedIndex !== "") {
      if (allItems[theSelectedIndex].selected === true) {
        allItems[theSelectedIndex].selected = false;
      } else {
        allItems[theSelectedIndex].selected = true;
      }
    }
    if (theSelected.selected === true) {
      allItems[theindex].selected = false;
    } else {
      allItems[theindex].selected = true;
    }

    setSelectedIndex(theindex);
    setRoleList(allItems);
  };

  return (
    <LayoutNoHeader>
      <div className="login">
        <div className="content">
          <div className="header">{getLogo()}</div>
          <div
            style={{
              boxShadow: "0 0 50px #ccc",
              marginTop: "30px",
              borderRadius: "4px",
            }}
          >
            <List component="nav" aria-label="main mailbox folders">
              <ListItem>
                <div>
                  <b>Select Role</b>
                </div>
              </ListItem>
            </List>
            <Divider />
            <List component="nav" aria-label="main mailbox folders">
              {roleList?.map((customer, idx) => (
                <div key={customer?.composer_id}>
                  <ListItem
                    key={customer?.composer_id}
                    button
                    onClick={() => selectName(customer?.composer_id, idx)}
                    selected={customer?.selected}
                  >
                    <ListItemText primary={`${customer?.composer_name}`} />
                  </ListItem>
                </div>
              ))}
            </List>
            <Divider />
            <List component="nav" aria-label="secondary mailbox folders">
              <ListItem
                style={{
                  justifyContent: "right",
                }}
              >
                <div>
                  <Button
                    disabled={selectedName === ""}
                    style={{
                      backgroundColor: selectedName === "" ? "grey" : "black",
                      border: "none",
                      width: "100%",
                      borderRadius: 5,
                      color: "white",
                    }}
                  >
                    Select
                  </Button>
                </div>
              </ListItem>
            </List>
            { }
          </div>
        </div>
      </div>
    </LayoutNoHeader>
  );
}
