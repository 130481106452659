import React, { useState } from "react";
import "../../../components/templates/LandingPage/LandingStyle.css";

const PublisherCard = ({ publisher }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const words = publisher?.publisher_name.split(" ");
  const maxWords = 2;
  const initials = words
    .slice(0, maxWords)
    .map(word => word.charAt(0))
    .join("");

  return (
    <div
      className="publisher-card"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {publisher?.logo ? (
        <img
          alt={publisher?.publisher_name}
          // src={`/images/partner/${publisher?.logo}`}
          src={require(`assets/image-public/images/partner/${publisher?.logo}`)}
          style={{
            cursor: "pointer",
          }}
        />
      ) : (
        <div className="card-initials">{initials}</div>
      )}
      {isHovered && publisher?.publisher_name && (
        <div className="publisher-card-hover">
          <div className="quotation" />
          <p className="publisher-name">{publisher?.publisher_name || "-"}</p>
          <p className="publisher-address">{publisher?.address || "-"}</p>
        </div>
      )}
    </div>
  );
};

export default PublisherCard;
