import { Button, Carousel, Col, Pagination, Row } from "antd";
import Axios from "axios";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import Chip from "../../../mpisLandingComponent/Chip/Chip";
import Layout from "../../../mpisLandingComponent/Layout";
import Publisher from "../../../mpisLandingComponent/Publisher";
import { hardBaseUrl } from "../../../services/urlConstant";
import "./Berita.css";

const initialState = {
  data: [],
  message: "",
  meta: {
    total: 0,
  },
};
export default function ViewBerita() {
  const history = useHistory();
  const [berita, setBerita] = useState(initialState);
  const [pageBerita, setPageBerita] = useState(1);

  useEffect(() => {
    getBerita();
  }, [pageBerita]);

  const getBerita = async () => {
    try {
      const response = await Axios.get(
        hardBaseUrl + `/article/public?type=berita&page=${pageBerita}&size=4`
      );
      setBerita(response.data);
    } catch (error) {
      new Error(error);
    }
  };

  const handleDetailBerita = res => {
    history.push({
      pathname: `/artikel/detail/${res.article_id}`,
      state: res,
    });
  };

  return (
    <Layout>
      <div className="berita-page" id="berita-page">
        <Carousel autoplay>
          {berita.data?.map((res, index) => {
            return (
              <div key={index} className="banner">
                <img src={res.banner_image} alt=""></img>
                <div className="gradient" />
                <div className="content">
                  <h1>{res.title}</h1>
                  <p
                    dangerouslySetInnerHTML={{
                      __html:
                        res?.content?.length > 300
                          ? res.content.substring(0, 300) + "..."
                          : res.content,
                    }}
                  ></p>
                  <Button
                    className="button"
                    onClick={() => handleDetailBerita(res)}
                  >
                    <text>See more</text>
                  </Button>
                </div>
              </div>
            );
          })}
        </Carousel>
        {berita?.data?.length > 0 && (
          <div className="berita-atas container">
            <Row>
              <Col span={14} style={{ padding: 0 }}>
                <Row>
                  <Col span={12} className="berita-left">
                    <div
                      className="left"
                      onClick={() => handleDetailBerita(berita.data[0])}
                    >
                      <div className="image-wrapper">
                        <img
                          src={berita.data[0].banner_image}
                          alt=""
                          style={{ height: "279px" }}
                        />
                      </div>
                      <div className="desc">
                        <Chip
                          type={berita.data[0].type === "berita" ? "News" : ""}
                        />
                        <h2>
                          {berita.data[0].title?.length > 50
                            ? berita.data[0].title.substring(0, 50) + "..."
                            : berita.data[0].title}
                        </h2>
                        <Publisher
                          publisher={berita.data[0].publisher_name}
                          day={berita.data[0].range_date}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div
                      className="right"
                      onClick={() => handleDetailBerita(berita.data[1])}
                    >
                      <div className="image-wrapper">
                        <img
                          src={berita.data[1].banner_image}
                          alt=""
                          style={{ height: "279px" }}
                        />
                      </div>
                      <div className="desc">
                        <Chip
                          type={berita.data[1].type === "berita" ? "News" : ""}
                        />
                        <h2>
                          {berita.data[1].title?.length > 50
                            ? berita.data[1].title.substring(0, 50) + "..."
                            : berita.data[1].title}
                        </h2>
                        <Publisher
                          publisher={berita.data[1].publisher_name}
                          day={berita.data[1].range_date}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col
                span={10}
                style={{
                  padding: 0,
                  paddingLeft: "14px",
                }}
                className="berita-right"
              >
                {berita.data?.length > 0 &&
                  berita.data?.map((res, i) => {
                    if (i > 1) {
                      return (
                        <Row
                          key={i}
                          style={{ height: "121px", marginBottom: "35px" }}
                          className="wrapper"
                          onClick={() => handleDetailBerita(res)}
                        >
                          <Col span={8} style={{ height: "100%" }}>
                            <img
                              src={res.banner_image}
                              alt=""
                              style={{ height: "100%" }}
                            />
                          </Col>
                          <Col
                            span={16}
                            className="content"
                            style={{ paddingLeft: "10px" }}
                          >
                            <Chip type={res.type === "berita" ? "News" : ""} />
                            <h3 style={{ marginTop: 10 }}>
                              {res.title.length > 50
                                ? res.title.substring(0, 50) + "..."
                                : res.title}
                            </h3>
                            <Publisher
                              publisher={res.publisher_name}
                              day={res.range_date}
                              fontSize="14px"
                            />
                          </Col>
                        </Row>
                      );
                    }
                  })}
              </Col>
            </Row>
          </div>
        )}
        <div className="berita-terbaru container">
          <h1>Latest News</h1>
          <Row gutter={15}>
            {berita.data?.length > 0 &&
              berita.data?.map((res, index) => {
                return (
                  <Col
                    key={index}
                    span={6}
                    style={{ paddingBottom: "15px" }}
                    onClick={() => handleDetailBerita(res)}
                  >
                    <div className="content">
                      <img src={res.banner_image} alt="" />
                      <div className="desc">
                        <Chip type={res.type === "berita" ? "News" : ""} />
                        <h2>
                          {res.title.length > 50
                            ? res.title.substring(0, 40) + "..."
                            : res.title}
                        </h2>
                        <Publisher
                          fontSize="14px"
                          publisher={res.publisher_name}
                          day={res.range_date}
                        />
                      </div>
                    </div>
                  </Col>
                );
              })}
          </Row>
          <div className="berita-lainya">
            <Pagination
              simple
              current={pageBerita}
              total={berita.meta.total}
              onChange={val => setPageBerita(val)}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
}
