import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment-timezone";

const MonthYearPicker = ({
  value,
  onChange,
  label,
  width,
  minDate,
  maxDate,
  ...rest
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker
        label={label}
        views={["month", "year"]}
        value={value}
        onChange={onChange}
        minDate={minDate || moment().subtract(10, "years")}
        maxDate={maxDate || moment()}
        slotProps={{
          textField: { size: "small", style: { width: width || 220 } },
          field: { clearable: true },
        }}
        {...rest}
      />
    </LocalizationProvider>
  );
};

export default MonthYearPicker;
