import { GetApp } from "@material-ui/icons";
import React from "react";
// USE THIS !!!!!!!
const items = [
  {
    subheader: "",
    items: [
      {
        title: "Dashboard",
        href: "/admin/dashboard-association",
        show: true,
        icon: () => (
          <img
            style={{
              width: "20px",
              marginRight: "10px",
            }}
            alt={"dashboard"}
            src={require("assets/image-public/images/icon_images/icon_dashboard_unselected.svg").default}
          />
        ),
        selectedicon: () => (
          <img
            style={{
              width: "20px",
              marginRight: "10px",
            }}
            alt={"dashboard"}
            src={require("assets/image-public/images/icon_images/icon_dashboard.svg").default}
          />
        ),
      },
      {
        title: "Song Usage",
        href: "/admin/review-lagu-association",
        show: true,
        icon: () => (
          <img
            style={{
              width: "20px",
              marginRight: "10px",
            }}
            alt="review"
            src={require("assets/image-public/images/icon_images/icon_review_unselected.svg").default}
          />
        ),
        selectedicon: () => (
          <img
            style={{
              width: "20px",
              marginRight: "10px",
            }}
            alt="review"
            src={require("assets/image-public/images/icon_images/reviewSelected.svg").default}
          />
        ),
      },
      {
        title: "Configuration",
        href: "/admin/konfigurasi/aktifitas",
        show: true,
        icon: () => (
          <img
            style={{
              width: "20px",
              marginRight: "10px",
            }}
            alt="konfigurasi"
            src={require("assets/image-public/images/icon_images/icon_konfigurasi_unselected.svg").default}
          />
        ),
        selectedicon: () => (
          <img
            style={{
              width: "20px",
              marginRight: "10px",
            }}
            alt="konfigurasi"
            src={require("assets/image-public/images/admin-master-logo-svg/Setting.svg").default}
          />
        ),
      },
      {
        title: "Parameter",
        href: "/admin/parameter/lagu",
        show: true,
        icon: () => (
          <img
            style={{
              width: "20px",
              marginRight: "10px",
            }}
            alt="master"
            src={require("assets/image-public/images/icon_images/icon_master_unselected.svg").default}
          />
        ),
        selectedicon: () => (
          <img
            style={{
              width: "20px",
              marginRight: "10px",
            }}
            alt="master"
            src={require("assets/image-public/images/admin-master-logo-svg/ProgramingData.svg").default}
          />
        ),
      },
      {
        title: "Song Claim",
        href: "/admin/publisher/double-claim",
        show: true,
        key: "Song Claim",
        icon: () => (
          <img
            style={{
              width: "20px",
              marginRight: "10px",
            }}
            alt="Conf"
            src={require("assets/image-public/images/song_claim_unselected.svg").default}
          />
        ),
        selectedicon: () => (
          <img
            style={{
              width: "20px",
              marginRight: "10px",
            }}
            alt="Select file"
            src={require("assets/image-public/images/song_claim_selected.svg").default}
          />
        ),
      },
      {
        title: "Upload Monitoring",
        href: "/admin/upload-monitoring",
        show: true,
        icon: () => (
          <img
            style={{
              width: "20px",
              marginRight: "10px",
            }}
            alt="monitoring"
            src={require("assets/image-public/images/icon_images/upload-monitoring-unselected.svg").default}
          />
        ),
        selectedicon: () => (
          <img
            style={{
              width: "20px",
              marginRight: "10px",
            }}
            alt="monitoring"
            src={require("assets/image-public/images/icon_images/upload-monitoring-selected.svg").default}
          />
        ),
      },
      {
        title: "Download Monitoring",
        href: "/admin/download-monitoring",
        show: true,
        icon: () => (
          <GetApp
            style={{
              width: "20px",
              marginRight: "10px",
              color: "grey",
            }}
          />
        ),
        selectedicon: () => (
          <GetApp
            style={{
              width: "20px",
              marginRight: "10px",
              color: "white",
            }}
          />
        ),
      },
    ],
  },
];

export default items;
