import { makeStyles, Typography } from "@material-ui/core";
import { InnoTableV2 } from "inno-ui";

const TableWithTitle = ({
  label,
  columns,
  items,
  isLoading,
  page,
  rowsPerPage,
  totalPage,
  handleChangePage,
  handleChangeRowsPerPage,
  onRequestSort,
  isHaveAction,
  renderAction,
}) => {
  const classes = useStyles();
  return (
    <div>
      {label && (
        <Typography variant="h4" className={classes?.titleContainer}>
          {label}
        </Typography>
      )}
      <InnoTableV2
        isHaveAction={isHaveAction}
        renderAction={renderAction}
        columns={columns}
        onRequestSort={onRequestSort}
        items={items || []}
        isLoading={isLoading}
        page={page}
        rowsPerPage={rowsPerPage}
        totalPage={totalPage}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </div>
  );
};

const useStyles = makeStyles(() => ({
  titleContainer: {
    margin: "20px 0",
  },
}));
export default TableWithTitle;
