import {
  Backdrop,
  Breadcrumbs,
  Button,
  CircularProgress,
  Container,
  Divider,
  FormControl,
  Grid,
  Link,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useHistory } from "react-router";
import "../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import HeaderPage from "../../../../layouts/Header/HeaderPage";
import InputPassword from "../../../../components/atoms/Input/InputPassword";
import { Page } from "components";
import { hardBaseUrl, hardToken } from "../../../../services/urlConstant";
const theme = createTheme({
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      "Segoe UI",
      "Roboto",
      "Helvetica Neue",
      "Arial",
      "sans-serif",
      "Apple Color Emoji",
      "Segoe UI Emoji",
      "Segoe UI Symbol",
    ].join(","),
  },
});
const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  results: {
    marginTop: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  btnSubmit: {
    backgroundColor: "#1a3040",
    marginRight: "10px",
    color: "white",
    width: "150px",
    "&:hover": {
      backgroundColor: "grey",
      color: "black",
    },
  },
  btnPreview: {
    width: "150px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  title: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "28px",
  },
  subTitle: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#6e6e6e",
  },
  label: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontWeight: "500px",
    fontSize: "14px",
    lineHeight: "20px",
    width: "140px",
    height: "20px",
  },
}));
const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "50px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#303030",
  borderStyle: "solid",
  backgroundColor: "white",
  color: "black",
  outline: "none",
  transition: "border .24s ease-in-out",
};
const activeStyle = {
  borderColor: "#2196f3",
};
const acceptStyle = {
  borderColor: "#00e676",
};
const rejectStyle = {
  borderColor: "#ff1744",
};
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function TambahUser() {
  const classes = useStyles();
  const history = useHistory();
  const [listRole, setListRole] = useState([]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [roleName, setRoleName] = useState("");
  const [displayPictureUrl] = useState("");
  const [loadingPage, setLoadingPage] = useState(true);
  const [openSnack, setOpenSnack] = useState(false);
  const [typeSnackbar, setTypeSnackbar] = useState("success");
  const [message, setMessage] = useState("This is a message!");
  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ accept: "image/*" });
  const files = acceptedFiles.map(file => (
    <li key={file.name}>
      {file.name} - {file.size} bytes
    </li>
  ));
  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );
  useEffect(() => {
    setLoadingPage(false);
    const fetchRoles = async () => {
      try {
        let baseOfUrl = hardBaseUrl;
        const url = baseOfUrl + "/role?type=admin";
        const options = {
          headers: {
            Authorization: "Bearer " + hardToken,
          },
        };
        const response = await axios.get(url, options);
        setListRole(response.data.data);
        setLoadingPage(false);
      } catch (error) {
        new Error(error);
      }
    };
    fetchRoles();
  }, []);
  const handleChangeName = item => {
    setName(item.target.value);
  };
  const handleChangeEmail = item => {
    setEmail(item.target.value);
  };
  const handleChangePhone = item => {
    setPhone(item.target.value);
  };
  const handleChangeRoleName = item => {
    setRoleName(item.target.value);
  };
  const handleChangePassword = item => {
    setPassword(item.target.value);
  };
  const handleButtonSimpan = async () => {
    if (name === "") {
      setTypeSnackbar("error");
      setMessage("Nama Tidak Boleh Kosong.");
      setOpenSnack(true);
    }
    if (email === "") {
      setTypeSnackbar("error");
      setMessage("Email Tidak Boleh Kosong.");
      setOpenSnack(true);
    }
    if (phone === "") {
      setTypeSnackbar("error");
      setMessage("Phone Tidak Boleh Kosong.");
      setOpenSnack(true);
    }
    if (password === "") {
      setTypeSnackbar("error");
      setMessage("Password Tidak Boleh Kosong.");
      setOpenSnack(true);
    }
    if (roleName === "") {
      setTypeSnackbar("error");
      setMessage("Role Tidak Boleh Kosong.");
      setOpenSnack(true);
    }
    if (
      name !== "" &&
      email !== "" &&
      phone !== "" &&
      password !== "" &&
      roleName !== ""
    ) {
      setLoadingPage(true);
      let payload = {
        name: name,
        email: email,
        phone: phone,
        role_id: roleName,
        password: password,
        display_picture_url: displayPictureUrl,
      };
      let baseOfUrl = hardBaseUrl;
      let urlUse = baseOfUrl + "/user";
      let resultAdd = await axios.post(urlUse, payload, {
        headers: {
          Authorization: "Bearer " + hardToken,
        },
      });
      if (
        resultAdd.data.message === "success" &&
        resultAdd.data.meta.http_status === 200
      ) {
        setLoadingPage(false);
        setTypeSnackbar("success");
        setMessage("Tambah User Berhasil.");
        setOpenSnack(true);
        setTimeout(() => {
          history.push("/admin/parameter/user-admin");
        }, 3000);
      }
    }
  };
  const handleCloseSnackbar = () => {
    setOpenSnack(false);
  };
  return (
    <Page className={classes.root} title="Tambah User">
      <div style={{ float: "right", marginRight: "20px" }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            underline="hover"
            color="inherit"
            href="/admin/parameter/publisher"
          >
            Master
          </Link>
          <Link underline="hover" color="inherit" href="/admin/parameter/user">
            User
          </Link>
          <Typography color="text.primary">Tambah User</Typography>
        </Breadcrumbs>
      </div>
      <Backdrop className={classes.backdrop} open={loadingPage}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container maxWidth={false}>
        <Snackbar
          open={openSnack}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert onClose={handleCloseSnackbar} severity={typeSnackbar}>
            {message}
          </Alert>
        </Snackbar>
        <HeaderPage title="Tambah User" breadcrumbs={[]} />
        <Divider className={classes.divider} />
        <Grid container>
          <Grid item xs={4} md={4} sm={4} lg={4} xl={4}>
            <ThemeProvider theme={theme}>
              <Typography variant="h5" className={classes.title}>
                Photo
              </Typography>
            </ThemeProvider>
          </Grid>
          <Grid item xs={8} md={8} sm={8} lg={8} xl={8}>
            <div className="container">
              <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                <Typography component="h1" variant="h5">
                  <p>
                    <b>Cari File</b>, Letakkan gambar disini
                  </p>
                </Typography>
              </div>
              <div
                style={{
                  marginTop: "20px",
                }}
              >
                <aside>
                  <Typography component="h1" variant="h5">
                    Files :
                  </Typography>
                  <ul>
                    <Typography component="h1" variant="h5">
                      {files}
                    </Typography>
                  </ul>
                </aside>
              </div>
            </div>
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        <Grid container>
          <Grid item xs={4} md={4} sm={4} lg={4} xl={4}>
            <ThemeProvider theme={theme}>
              <Typography variant="h5" className={classes.title}>
                User Info
              </Typography>
            </ThemeProvider>
          </Grid>
          <Grid item xs={8} md={8} sm={8} lg={8} xl={8}>
            <div
              style={{
                marginTop: "5px",
                marginBottom: "5px",
              }}
            >
              <div
                style={{
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                <ThemeProvider theme={theme}>
                  <label className={classes.label}>Name</label>
                </ThemeProvider>
              </div>
              <div
                style={{
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  fullWidth={true}
                  margin="dense"
                  value={name}
                  onChange={event => handleChangeName(event)}
                />
              </div>
              <div
                style={{
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                <ThemeProvider theme={theme}>
                  <label className={classes.label}>Email</label>
                </ThemeProvider>
              </div>
              <div
                style={{
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  fullWidth={true}
                  margin="dense"
                  value={email}
                  type="email"
                  onChange={event => handleChangeEmail(event)}
                  autoComplete="new-email"
                />
              </div>
              <div
                style={{
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                <ThemeProvider theme={theme}>
                  <label className={classes.label}>Password</label>
                </ThemeProvider>
              </div>
              <div
                style={{
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                <InputPassword
                  id="outlined-basic"
                  variant="outlined"
                  fullWidth={true}
                  margin="dense"
                  value={password}
                  type="password"
                  onChange={event => handleChangePassword(event)}
                  autoComplete="new-password"
                />
              </div>
              <div
                style={{
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                <ThemeProvider theme={theme}>
                  <label className={classes.label}>Phone</label>
                </ThemeProvider>
              </div>
              <div
                style={{
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  fullWidth={true}
                  margin="dense"
                  value={phone}
                  onChange={event => handleChangePhone(event)}
                />
              </div>
              <div
                style={{
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                <ThemeProvider theme={theme}>
                  <label className={classes.label}>Role Name</label>
                </ThemeProvider>
              </div>
              <div
                style={{
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                <FormControl variant="outlined" fullWidth={true}>
                  <Select
                    className={classes.select}
                    id="demo-simple-select-outlined"
                    value={roleName}
                    onChange={handleChangeRoleName}
                    autoWidth={true}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {listRole.map(item => (
                      <MenuItem value={item.role_id} key={item.role_id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
            <Button className={classes.btnSubmit} onClick={handleButtonSimpan}>
              Simpan
            </Button>
            <Button
              className={classes.btnPreview}
              variant="outlined"
              href="/admin/parameter/user-admin"
            >
              Kembali
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
export default TambahUser;
