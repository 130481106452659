import { GET_IMAGE_COVER_BERITA_PUBLISHER } from "../../../constants/types";

const initialState = {
  imageCoverBeritaPublisher: [],
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_IMAGE_COVER_BERITA_PUBLISHER:
      return {
        ...state,
        imageCoverBeritaPublisher: action.payload,
      };
    default:
      return state;
  }
}
