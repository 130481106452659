import { SET_VALUE_BERITA_DAN_IKLAN, DELETE_VALUE_BERITA_DAN_IKLAN, DELETE_ALL_VALUES_BERITA_DAN_IKLAN } from "../../constants/types";

export const setValueBeritaDanIklan = (payload) => {
    return {
        type: SET_VALUE_BERITA_DAN_IKLAN,
        payload,
    };
};

export const deleteValueBeritaDanIklan = (keysToDelete) => {
    return {
        type: DELETE_VALUE_BERITA_DAN_IKLAN,
        payload: keysToDelete,
    };
};


export const deleteAllValuesBeritaDanIklan = () => {
    return {
        type: DELETE_ALL_VALUES_BERITA_DAN_IKLAN,
    };
};