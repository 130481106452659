import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import TrashVector from "../../../assets/img/trashVector.svg";
import { Tooltip } from "@material-ui/core";

const interFont = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const useStyles = makeStyles(() => ({
  root: {},
  divider: {},
  results: {},
  backdrop: {},
  ib: {
    padding: "4px",
    color: "white",
    backgroundColor: "black",
    borderRadius: "6px",
    width: "30px",
    height: "30px",
    "&:focus": {
      color: "white",
      backgroundColor: "black",
    },
    "&:active": {
      boxShadow: "none",
      color: "white",
      backgroundColor: "black",
    },
    "&:hover": {
      color: "white",
      backgroundColor: "black",
      boxShadow: "none",
    },
    "&:hover, &.MuiIconButton": {
      background: "#374151 !important"
    },
  },
}));

function ButtonWithModalPerItem({
  item,
  subTitle,
  dialogTitle,
  btnDelTitle,
  handleDelete,
  tooltTipCaption,
  btnIcon,
  className,
  title,
  style = { marginLeft: "8px" },
}) {
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);

  const handleClose = () => setOpenModal(false);

  const onClickDelete = () => {
    setOpenModal(false);
    handleDelete(item);
  };
  return (
    <React.Fragment>
      <Tooltip title={tooltTipCaption || title || "Delete"} placement="bottom">
        <IconButton
          style={{
            ...style,
            backgroundColor: " #111827",
          }}
          classes={{
            root: className ? className : classes.ib,
          }}
          onClick={() => setOpenModal(true)}
        >
          {btnIcon ? (
            { ...btnIcon }
          ) : (
            <img alt="theDataImage" src={TrashVector} />
          )}
        </IconButton>
      </Tooltip>
      <Dialog
        open={openModal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <ThemeProvider theme={interFont}>
            <Typography
              style={{
                fontWeight: 700,
                fontSize: "16px",
                lineHeight: "24px",
              }}
            >
              {typeof dialogTitle !== "undefined" ? (
                <>{dialogTitle}</>
              ) : (
                <>{"-"}</>
              )}
            </Typography>
          </ThemeProvider>
        </DialogTitle>
        <DialogContent>
          {typeof item.name === "undefined" ? (
            <DialogContentText id="alert-dialog-description">
              {subTitle} ?
            </DialogContentText>
          ) : (
            <DialogContentText id="alert-dialog-description">
              {subTitle} <b>{`${item.name}`}</b> ?
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenModal(false)}
            style={{
              height: "40px",
              width: "90px",
              borderRadius: "6px",
              border: "1px solid #D1D5DC",
              color: "#111827",
              backgroundColor: "white",
              textTransform: "none",
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              onClickDelete();
            }}
            autoFocus
            style={{
              height: "40px",
              width: "90px",
              borderRadius: "6px",
              border: "0px solid #D1D5DC",
              color: "white",
              backgroundColor: "#111827",
              textTransform: "none",
            }}
          >
            {typeof btnDelTitle !== "undefined" ? (
              <>{btnDelTitle}</>
            ) : (
              <>{"Remove"}</>
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default ButtonWithModalPerItem;
