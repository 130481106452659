import {
  Button,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import { InnoTableV2 } from "inno-ui";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { ModalError, ModalSuccess, Page } from "components";
import { hardBaseUrl } from "../../../../../services/urlConstant";
import { getTotalPage } from "lib";
import { formatPeriodDate, getErrors } from "utils";
import { HeaderTitle } from "layouts";

function DetailUnclaimMonitoring() {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const [loadingPage, setLoadingPage] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [queryParams, setQueryParams] = useState({
    page: 1,
    size: 10,
  });
  const [tableData, setTableData] = useState([]);
  const [detailUnclaimMonitoring, setDetailUnclaimMonitoring] = useState({});
  const [tablePageCount, setTablePageCount] = useState(1);
  const breadcrumbData = [
    {
      label: "Home",
      link: "/admin/dashboard",
    },
    {
      label: "Unclaim Monitoring",
      link: "/admin/publisher/unclaim-monitoring",
    },
    {
      label: "View Unclaim Monitoring",
      active: true,
    },
  ];
  const columnTable = [
    {
      name: "data_content",
      title: "Song Title",
      renderText: item => (
        <Typography className={classes?.semiBold}>
          {item?.song_title}
        </Typography>
      ),
    },
    {
      name: "data_content",
      title: "ISRC Code",
      renderText: item => item?.isrc_code,
    },
    {
      name: "data_content",
      title: "Number Of Stream",
      renderText: item => item?.number_of_streams,
    },
    {
      name: "data_content",
      title: "DSP Name",
      renderText: item => item?.dsp_name,
    },
    {
      name: "data_content",
      title: "Period Date",
      renderText: item => formatPeriodDate(item?.start_date, item?.end_date),
    },
  ];

  const handleChangeQueryParams = (value, key) => {
    setQueryParams(currentState => ({
      ...currentState,
      [key]: value,
    }));
  };
  const handleResetPage = () => handleChangeQueryParams(1, "page");

  const getDetail = async () => {
    setLoadingPage(true);
    const url = `${hardBaseUrl}/process/detail/${id}`;
    try {
      const res = await axios.get(url, { headers });
      const { data } = res?.data;
      setDetailUnclaimMonitoring(data);
    } catch (error) {
      ModalError(getErrors(error));
    } finally {
      setLoadingPage(false);
    }
  };
  const getDataTable = async () => {
    setLoadingPage(true);
    const url = `${hardBaseUrl}/process/results`;
    const params = {
      ...queryParams,
      process_id: id,
    };
    try {
      const res = await axios.get(url, { headers, params });
      const { data, meta } = res?.data;
      setTableData(data);
      setTablePageCount(getTotalPage(meta?.total, queryParams?.size));
    } catch (error) {
      ModalError(getErrors(error));
    } finally {
      setLoadingPage(false);
    }
  };
  const handleRetryUnclaimProcess = async () => {
    setButtonLoading(true);
    await axios
      .post(`${hardBaseUrl}/unclaim/retry/${id}`, {}, { headers })
      .then(() => {
        ModalSuccess("Successfully Retry Unclaim Process").then(() =>
          history.goBack()
        );
      })
      .catch(error => ModalError(getErrors(error)))
      .finally(() => setButtonLoading(false));
  };

  useEffect(() => {
    getDataTable();
  }, []);
  useEffect(() => {
    getDetail();
  }, [queryParams]);

  return (
    <Page className={classes?.root} title="Song Claim">
      <Container maxWidth={false}>
        <HeaderTitle
          title="Detail Unclaim Monitoring"
          breadcrumbData={breadcrumbData}
          backButton
        />
        <Divider className={classes?.divider} />
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          className={classes?.tableCaptionContainer}
        >
          <Grid item>
            <Typography className={classes?.tableTitle}>
              Detail Of Unclaim Monitoring
            </Typography>
            <Typography variant="subtitle2">
              View the detail of unclaim monitoring
            </Typography>
          </Grid>
          {detailUnclaimMonitoring?.status === "Error" && (
            <Grid item>
              <Button
                startIcon={
                  buttonLoading && (
                    <CircularProgress
                      size={16}
                      className={classes?.circularButton}
                    />
                  )
                }
                disabled={buttonLoading}
                className={classes?.buttonBlack}
                onClick={handleRetryUnclaimProcess}
              >
                {`${buttonLoading ? "Retrying ..." : "Retry Unclaim Process"}`}
              </Button>
            </Grid>
          )}
        </Grid>
        <InnoTableV2
          columns={columnTable}
          handleChangePage={(_, value) =>
            handleChangeQueryParams(value, "page")
          }
          handleChangeRowsPerPage={event => {
            handleChangeQueryParams(event?.target?.value, "size");
            handleResetPage();
          }}
          isLoading={loadingPage}
          items={tableData || []}
          page={queryParams?.page}
          rowsPerPage={queryParams?.size}
          totalPage={tablePageCount}
        />
      </Container>
    </Page>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  pageTitle: {
    fontSize: 24,
    fontWeight: 700,
    lineHeight: "32px",
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  buttonBlack: {
    backgroundColor: "#111827",
    color: "white",
    textTransform: "none",
    whiteSpace: "nowrap",
    height: 38,
    width: 187,
    "&:hover": {
      backgroundColor: "#111827",
      color: "white",
    },
    "&.MuiButton-root.Mui-disabled": {
      backgroundColor: "#9ca3af",
      color: "white",
    },
  },
  circularButton: {
    color: "white",
  },
  buttonContainer: {
    marginTop: 24,
  },
  tableCaptionContainer: {
    margin: "8px 0 24px 0",
  },
  tableTitle: {
    fontSize: 18,
    fontWeight: 600,
    marginBottom: 8,
  },
  semiBold: {
    fontWeight: 500,
  },
}));

export default DetailUnclaimMonitoring;
