import {
  Breadcrumbs,
  Button,
  Container,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import { Page, PrimaryButton } from "components";
import { hardBaseUrl } from "../../../../services/urlConstant";

const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  results: {
    marginTop: theme.spacing(3),
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  button: {
    padding: "10px 28px 10px 28px",
    backgroundColor: "black",
    color: "white",
    width: "213px",
    height: "40px",
    borderRadius: "6px",
    textTransform: "none",
    whiteSpace: "nowrap",
    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
  subtitle: {
    fontSize: "18px",
    fontWeight: "bold",
    lineHeight: "28px",
  },
  breadcrumbs: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#111827",
  },
  breadCrumbsActive: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#687083",
  },
  selectPadding: {
    height: "80px",
    "& .MuiInputBase-fullWidth": {
      height: "55px",
    },
  },
  rootSwitch: {
    width: "40px",
    height: "20px",
    padding: "0px",
    borderRadius: "10px",

    marginRight: "8px",
    position: "relative",
  },
  switchBase: {
    padding: "10px",
    color: "white",
    "&.Mui-checked": {},
    "&.Mui-checked + $track": {
      border: "none",
      color: "white",
      backgroundColor: "black",
      opacity: 1,
    },
  },
  checked: {},
  thumb: {
    color: "white",
    position: "absolute",
    width: "16px",
    height: "16px",
  },
  track: {
    backgroundColor: "#E4E7EB",
    borderRadius: "25px",
    border: "0px solid #606060",
    height: "auto",
    opacity: 1,
  },
  font24: {
    fontSize: "24px",
    fontWeight: "700",
    lineHeight: "32px",
  },
  flex: {
    displa: "flex",
  },
  flexCenter: {
    displa: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  flexBetween: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "12px",
    border: "1px solid grey",
    padding: "8px",
    borderRadius: "8px",
  },
  flexBetween1: {
    display: "flex",
    justifyContent: "space-between",
  },
  displayNone: {
    display: "none",
  },
  divContainer: {
    border: "1px solid #9AA2B1",
    borderRadius: "8px",
    padding: "16px",
    marginTop: "16px",
  },
  imgContainer: {
    width: "160px",
    height: "160px",
    border: "1px solid #D1D5DC",
    borderRadius: "6px",
  },
  textCenter: {
    textAlign: "center",
    borderRadius: "6px",
    marginTop: "6px",
  },
  font14: {
    marginTop: "5px",
    color: "#8f8f8f",
    fontSize: "14px",
  },
  pointer: {
    cursor: "pointer",
  },
  font18: {
    color: "black",
    fontSize: "18px",
  },
  block415: {
    display: "block",
    width: "415px",
  },
  gap4: {
    display: "flex",
    justifyContent: "right",
    marginTop: "10px",
    gap: "4px",
  },
}));

const EditTax = () => {
  const classes = useStyles();
  const history = useHistory();
  const { state } = useLocation();
  const [code, setCode] = useState("");
  const [description, setDescription] = useState("");
  const [name, setName] = useState("");
  const [symbolCode, setSymbolCode] = useState("");

  const handleChange = event => {
    const value = event.target.value;
    const name = event.target.name;
    if (name === "code") {
      setCode(value);
    } else if (name === "symbol code") {
      setSymbolCode(value);
    } else if (name === "description") {
      setDescription(value);
    } else if (name === "name") {
      setName(value);
    }
  };

  const getDetail = () => {
    const token = localStorage.getItem("token");
    const url = `${hardBaseUrl}/currency/detail/${state.customer.id}`;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .get(url, config)
      .then(res => {
        setCode(res.data.data.currency_iso_code);
        setName(res.data.data.currency_name);
        setSymbolCode(res.data.data.currency_symbol_code);
        setDescription(res.data.data.description);
      })
      .catch(err => {
        Swal.fire({
          title: "Error!",
          text: err.response.data.errors[0].message,
          icon: "error",
          confirmButtonText: "Ok",
        });
      });
  };

  useEffect(() => {
    getDetail();
  }, []);

  const onSaveData = () => {
    if (code === "") {
      Swal.fire({
        icon: "error",
        title: "Oops…",
        text: "Curreny Code can't be empty",
      });
      return;
    }

    if (!name) {
      Swal.fire({
        icon: "error",
        title: "Oops…",
        text: "Currency name can't be empty",
      });
      return;
    }
    if (!symbolCode) {
      Swal.fire({
        icon: "error",
        title: "Oops…",
        text: "Curreny symbol code can't be empty",
      });
      return;
    }
    if (name && code && symbolCode) {
      const token = localStorage.getItem("token");
      const url = `${hardBaseUrl}/currency/update/${state.customer.id}`;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const data = {
        currency_iso_code: code,
        currency_name: name,
        currency_symbol_code: symbolCode,
        description: description,
      };
      axios
        .put(url, data, config)
        .then(() => {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Currency has been updated",
          }).then(result => {
            if (result.isConfirmed) {
              history.push("/admin/parameter/admin-currency");
            }
          });
        })
        .catch(err => {
          Swal.fire({
            title: "Oops…",
            text: err?.response?.data?.errors[0]?.message,
            icon: "error",
            confirmButtonText: "Ok",
          });
        });
    }
  };

  return (
    <Page className={classes.root} title={"Edit Currency"}>
      <Container maxWidth={false}>
        <Grid
          alignItems="flex-end"
          container
          justifyContent="space-between"
          spacing={3}
        >
          <Grid item>
            <ThemeProvider theme={theme}>
              <Typography className={classes.font24}>Edit Currency</Typography>
            </ThemeProvider>
          </Grid>
          <Grid item>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography className={classes.breadCrumbs}>Parameter</Typography>
              <Typography className={classes.breadCrumbs}>Currency</Typography>
              <Typography className={classes.breadCrumbsActive}>
                Edit Currency
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>

        <Divider className={classes.divider} />
        <Grid container>
          <Grid item xs={12} md={12} sm={12} lg={6} xl={6}>
            <ThemeProvider theme={theme}>
              <Typography variant="h3" className={classes.subtitle}>
                Currency
              </Typography>
            </ThemeProvider>
            <ThemeProvider theme={theme}>
              <p className={classes.font14}>
                Customize and manage the currency to make transaction.
              </p>
            </ThemeProvider>

            <Grid container spacing={1} className={classes.divContainer}>
              <Grid item xs={12} md={12} sm={12} lg={12} xl={12}>
                <div>
                  <ThemeProvider theme={theme}>
                    <label>Currency Code</label>
                  </ThemeProvider>

                  <TextField
                    variant="outlined"
                    onChange={handleChange}
                    name="code"
                    id="formatted-numberformat-input"
                    margin="dense"
                    fullWidth={true}
                    value={code}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={12} sm={12} lg={12} xl={12}>
                <div>
                  <ThemeProvider theme={theme}>
                    <label>Currency Name</label>
                  </ThemeProvider>

                  <TextField
                    variant="outlined"
                    onChange={handleChange}
                    name="name"
                    id="formatted-numberformat-input"
                    margin="dense"
                    fullWidth={true}
                    value={name}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={12} sm={12} lg={12} xl={12}>
                <div>
                  <ThemeProvider theme={theme}>
                    <label>Currency Symbol Code</label>
                  </ThemeProvider>

                  <TextField
                    inputProps={{ maxLength: 5 }}
                    variant="outlined"
                    onChange={handleChange}
                    name="symbol code"
                    id="formatted-numberformat-input"
                    margin="dense"
                    fullWidth={true}
                    value={symbolCode}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={12} sm={12} lg={12} xl={12}>
                <div>
                  <ThemeProvider theme={theme}>
                    <label>Description</label>
                  </ThemeProvider>

                  <TextField
                    variant="outlined"
                    onChange={handleChange}
                    name="description"
                    id="formatted-numberformat-input"
                    margin="dense"
                    fullWidth={true}
                    rows={4}
                    multiline
                    value={description}
                  />
                </div>
              </Grid>
            </Grid>
            <div className={classes.gap4}>
              <PrimaryButton
                onClick={() => window.history.back()}
                label="Cancel"
              />
              <PrimaryButton onClick={e => onSaveData(e)} label="Save" />
            </div>
          </Grid>

          <Grid item xs={4} md={4} sm={4} lg={4} xl={4}></Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default EditTax;
