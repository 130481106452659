import {
  Breadcrumbs,
  Button,
  Container,
  Divider,
  FormControl,
  Grid,
  OutlinedInput,
  TextField,
  Typography,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { ButtonGroupTop } from "../../../../../components/atoms/Button";
import { Page } from "components";

import axios from "axios";
import Swal from "sweetalert2";
import { hardBaseUrl } from "../../../../../services/urlConstant";

const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  results: {
    marginTop: theme.spacing(3),
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  button: {
    padding: "10px 28px 10px 28px",
    backgroundColor: "black",
    color: "white",
    width: "213px",
    height: "40px",
    borderRadius: "6px",
    textTransform: "none",
    whiteSpace: "nowrap",
    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
  subtitle: {
    fontSize: "18px",
    fontWeight: "bold",
    lineHeight: "28px",
  },
  breadcrumbs: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#111827",
  },
  breadCrumbsActive: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#687083",
  },
  selectPadding: {
    height: "80px",
    "& .MuiInputBase-fullWidth": {
      height: "55px",
    },
  },
  rootSwitch: {
    width: "40px",
    height: "20px",
    padding: "0px",
    borderRadius: "10px",

    marginRight: "8px",
    position: "relative",
  },
  switchBase: {
    padding: "10px",
    color: "white",
    "&.Mui-checked": {},
    "&.Mui-checked + $track": {
      border: "none",
      color: "white",
      backgroundColor: "black",
      opacity: 1,
    },
  },
  checked: {},
  thumb: {
    color: "white",
    position: "absolute",
    width: "16px",
    height: "16px",
  },
  track: {
    backgroundColor: "#E4E7EB",
    borderRadius: "25px",
    border: "0px solid #606060",
    height: "auto",
    opacity: 1,
  },
}));

const TambahCostType = () => {
  const classes = useStyles();
  const history = useHistory();
  const initIndex = 0;

  const topMenuButton = [
    {
      id: 0,
      title: "Personalization",
      link: "/admin/konfigurasi/personalisasi",
      selected: false,
    },
    {
      id: 1,
      title: "Payment",
      link: "/admin/konfigurasi/pembayaran",
      selected: false,
    },
    {
      id: 2,
      title: "Origin Publisher",
      link: "/admin/konfigurasi/original-publisher",
      selected: false,
    },
    {
      id: 3,
      title: "Activity",
      link: "/admin/konfigurasi/aktifitas",
      selected: false,
    },
    {
      id: 4,
      title: "Invoice",
      link: "/admin/konfigurasi/invoice",
      selected: false,
    },
    {
      id: 5,
      title: "Reminder",
      link: "/admin/konfigurasi/contract-reminder",
      selected: false,
    },
    {
      id: 6,
      title: "Cost Type",
      link: "/admin/konfigurasi/cost-type",
      selected: true,
    },
  ];
  const [code, setCode] = useState("");
  const [name, setName] = useState("");
  const [desc, setDesc] = useState("");
  const [publisherId, setPublisherId] = useState("");

  const handleOnSelectedButton = value => {
    history.push(value.data[value.newButtonSelected].link);
  };

  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    if (name === "code") {
      setCode(value);
    } else if (name === "name") {
      setName(value);
    } else if (name === "desc") {
      setDesc(value);
    }
  };

  const getMe = () => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const url = `${hardBaseUrl}/me`;
    axios
      .get(url, config)
      .then(res => {
        setPublisherId(res.data.data.publisher.publisher_id);
      })
      .catch(() => {});
  };

  useEffect(() => {
    getMe();
  }, []);

  const onSave = e => {
    e.preventDefault();

    if (code === "") {
      Swal.fire({
        title: "Error",
        text: "Fee Type Code cannot be empty",
        icon: "error",
        confirmButtonText: "OK",
      });
      return;
    } else if (name === "") {
      Swal.fire({
        title: "Error",
        text: "Fee Type Name cannot be empty",
        icon: "error",
        confirmButtonText: "OK",
      });
      return;
    }

    if (code !== "" && name !== "") {
      const token = localStorage.getItem("token");
      const url = `${hardBaseUrl}/cost-type/create`;
      const payload = {
        description: desc,
        fee_type_code: code,
        fee_type_name: name,
        publisher_id: Number(publisherId),
        updated_by: 0,
      };

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      axios
        .post(url, payload, config)
        .then(() => {
          Swal.fire({
            title: "Success",
            text: "Cost Type has been created",
            icon: "success",
            confirmButtonText: "OK",
          }).then(result => {
            if (result.isConfirmed) {
              history.push("/admin/konfigurasi/cost-type");
            }
          });
        })
        .catch(err => {
          Swal.fire({
            title: "Error",
            text: err.response.data.errors[0].message,
            icon: "error",
            confirmButtonText: "OK",
          });
        });
    }
  };

  return (
    <Page className={classes.root} title="Cost Type">
      <Container maxWidth={false}>
        <Grid
          alignItems="flex-end"
          container
          justifyContent="space-between"
          spacing={3}
        >
          <Grid item>
            <ThemeProvider theme={theme}>
              <Typography
                style={{
                  fontSize: "24px",
                  fontWeight: "700",
                  lineHeight: "32px",
                }}
              >
                Configuration
              </Typography>
            </ThemeProvider>
          </Grid>
          <Grid item>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography className={classes.breadCrumbs}>Home</Typography>

              <Typography className={classes.breadCrumbs}>
                Configuration
              </Typography>

              <Typography className={classes.breadCrumbs}>Cost Type</Typography>

              <Typography className={classes.breadCrumbsActive}>
                Add Cost Type
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
        <ButtonGroupTop
          items={topMenuButton}
          selectedButton={handleOnSelectedButton}
          selectedIndex={initIndex}
        />
        <Divider className={classes.divider} />
        <Grid container>
          <Grid item xs={4} md={4} sm={4} lg={8} xl={8}>
            <ThemeProvider theme={theme}>
              <Typography variant="h3" className={classes.subtitle}>
                Create Fee Type
              </Typography>
            </ThemeProvider>
            <ThemeProvider theme={theme}>
              <p
                style={{
                  marginTop: "5px",
                  color: "#8f8f8f",
                  fontSize: "14px",
                }}
              >
                This feature is to create tax settings for composer/author
              </p>
            </ThemeProvider>

            <Grid container spacing={1}>
              <Grid item xs={6} md={6} sm={6} lg={6} xl={6}>
                <div>
                  <ThemeProvider theme={theme}>
                    <label>Fee Type Code</label>
                  </ThemeProvider>

                  <FormControl
                    fullWidth
                    className={classes.margin}
                    variant="outlined"
                  >
                    <OutlinedInput
                      id="outlined-adornment-amount"
                      name="code"
                      fullWidth={true}
                      placeholder="Fee Type Code"
                      onChange={handleChange}
                    />
                  </FormControl>
                </div>
              </Grid>
              <Grid item xs={6} md={6} sm={6} lg={6} xl={6}>
                <div>
                  <ThemeProvider theme={theme}>
                    <label>Fee Type Name</label>
                  </ThemeProvider>

                  <FormControl
                    fullWidth
                    className={classes.margin}
                    variant="outlined"
                  >
                    <OutlinedInput
                      id="outlined-adornment-amount"
                      name="name"
                      fullWidth={true}
                      placeholder="Fee Type Name"
                      onChange={handleChange}
                    />
                  </FormControl>
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={1}>
              <Grid item xs={12} md={12} sm={12} lg={12} xl={12}>
                <TextField
                  multiline={true}
                  minRows={10}
                  id="outlined-basic"
                  placeholder="Add Description Here..."
                  variant="outlined"
                  fullWidth={true}
                  margin="dense"
                  name="desc"
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <div
              style={{
                marginTop: "20px",
                display: "flex",
                justifyContent: "end",
              }}
            >
              <Button
                variant="outlined"
                style={{
                  textTransform: "none",
                  backgroundColor: "white",
                  color: "white",
                  width: "129px",
                  height: "40px",
                  border: "1px solid black",
                }}
                href="/admin/konfigurasi/cost-type"
              >
                <ThemeProvider theme={theme}>
                  <Typography
                    style={{
                      color: "black",
                      fontStyle: "normal",
                      fontWeight: 500,
                      fontSize: "14px",
                      lineHeight: "20px",
                    }}
                  >
                    Cancel
                  </Typography>
                </ThemeProvider>
              </Button>
              <Button
                variant="outlined"
                style={{
                  textTransform: "none",
                  backgroundColor: "black",
                  color: "white",
                  width: "129px",
                  height: "40px",
                  marginLeft: "20px",
                }}
                onClick={e => onSave(e)}
              >
                <ThemeProvider theme={theme}>
                  <Typography
                    style={{
                      color: "white",
                      fontStyle: "normal",
                      fontWeight: 500,
                      fontSize: "14px",
                      lineHeight: "20px",
                    }}
                  >
                    Save
                  </Typography>
                </ThemeProvider>
              </Button>
            </div>
          </Grid>

          <Grid item xs={4} md={4} sm={4} lg={4} xl={4}></Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default TambahCostType;
