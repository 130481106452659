import Swal from "sweetalert2";

const ModalWarning = (message, title) =>
  Swal.fire({
    title: title ? title : "Are you sure?",
    text: message ? message : "Data has been saved",
    icon: "warning",
    showCancelButton: true,
    confirmButtonText: "Proceed",
    allowEscapeKey: false,
    allowOutsideClick: false,
    reverseButtons: true,
  });

export default ModalWarning;
