export const publisherLists = [
  {
    id: 2,
    logo: "Clip-path-group.png",
    publisher_name: "Aquarius",
    address:
      "Jl. Batu Tulis XIII No.17, RT.7/RW.2, Kb. Klp., Kecamatan Gambir, Kota Jakarta Pusat, Daerah Khusus Ibukota Jakarta 10120",
  },
  {
    id: 0,
    logo: "Clip-path-group(1).png",
    publisher_name: "Digital Rumah Publishindo",
    address: "",
  },
  {
    id: 27,
    logo: "Clip-path-group-(2).png",
    publisher_name: "Pustaka Cipta Kreasi",
    address:
      "Jl. Suryopranoto No.2, RT.2/RW.8, Petojo Utara, Kecamatan Gambir, Kota Jakarta Pusat, Daerah Khusus Ibukota Jakarta 10130",
  },
  {
    id: 3,
    logo: "Clip-path-group-(3).png",
    publisher_name: "Arga Swara",
    address:
      "Boulevard, Ruko Elang Laut (4), Jl. Pantai Indah Selatan No.52, RT.3/RW.3, Kamal Muara, Kec. Penjaringan, Jkt Utara, Daerah Khusus Ibukota Jakarta 14470",
  },
  {
    id: 0,
    logo: "Clip-path-group-(4).png",
    publisher_name: "",
    address: "",
  },
  {
    id: 0,
    logo: "Clip-path-group-(5).png",
    publisher_name: "Musica Publisher Indonesia",
    address: "",
  },
  {
    id: 17,
    logo: "Clip-path-group-(6).png",
    publisher_name: "Mahar Pustaka Nusantara",
    address:
      "Jl. Cideng Barat No.54, RT.12/RW.1, Cideng, Kecamatan Gambir, Kota Jakarta Pusat, Daerah Khusus Ibukota Jakarta 10150",
  },
  {
    id: 0,
    logo: "Clip-path-group-(7).png",
    publisher_name: "",
    address: "",
  },
  {
    id: 0,
    logo: "Clip-path-group-(8).png",
    publisher_name: "MD Publikasi",
    address: "",
  },
  {
    id: 0,
    logo: "Clip-path-group-(9).png",
    publisher_name: "Tupa",
    address: "",
  },
  {
    id: 0,
    logo: "Clip-path-group-(10).png",
    publisher_name: "Sony Music",
    address: "",
  },
  {
    id: 29,
    logo: "Clip-path-group-(11).png",
    publisher_name: "Harmoni Digital Publisherindo",
    address:
      "RT.17/RW.11, South Mangga Dua, Sawah Besar, Central Jakarta City, Jakarta 10730",
  },
];
