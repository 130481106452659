import { Container, Divider } from "@material-ui/core";
import { Box, Grid } from "@mui/material";
import axios from "axios";
import {
  AddSongModal,
  ArrayChip,
  CurrencyNumberInput,
  DatePicker,
  FormLabel,
  ModalError,
  ModalSuccess,
  MonthYearRangePicker,
  Page,
  PrimaryButton,
  SectionLabel,
  SelectInput,
  SkeletonComponent,
  SupportingDocumentForm,
  TableAction,
} from "components";
import { InnoTable } from "inno-ui";
import { HeaderTitle } from "layouts";
import moment from "moment";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { globalStyles } from "styles";
import { getErrors } from "utils";
import { hardBaseUrl } from "../../../../services/urlConstant";

const AddAdvancedFinance = () => {
  const classes = globalStyles();
  const history = useHistory();
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const [loadingPage, setLoadingPage] = useState(false);
  const [optionDsp, setOptionDsp] = useState([]);
  const [selectedSongs, setSelectedSongs] = useState([]);
  const [modalSong, setModalSong] = useState(false);
  const [documentFiles, setDocumentFiles] = useState([]);
  const [selectedDSP, setSelectedDSP] = useState({});
  const [payload, setPayload] = useState({
    transaction_name: "advanced",
    transaction_number: "",
    dsp_id: 0,
    type: "flat",
    start_date: moment(),
    end_date: moment(),
    revenue: 0,
    exchange_rate: 0,
    song_id: [],
    is_all_song: false,
    invoice_date: moment(),
  });
  const isForeignCurrency =
    selectedDSP?.dsp_currency !== null &&
    selectedDSP?.dsp_currency?.currency_iso_code !== "IDR";

  const handleChangePayload = (value, key) => {
    setPayload(currentState => ({
      ...currentState,
      [key]: value,
    }));
  };
  const handleChangeClient = async e => {
    const selectedDspId = e?.target.value;
    const choosenDSP = optionDsp?.find(el => el?.dsp_id === selectedDspId);
    const getForeignCurrency =
      choosenDSP?.dsp_currency !== null &&
      choosenDSP?.dsp_currency?.currency_iso_code !== "IDR";
    const dspCurrencyId = choosenDSP?.dsp_currency?.id;
    handleChangePayload(selectedDspId, "dsp_id");
    setSelectedDSP(choosenDSP);
    if (getForeignCurrency) {
      getCurrency(dspCurrencyId);
    }
  };
  const handleDelete = item => {
    setSelectedSongs(prevItems =>
      prevItems.filter(val => val.song_id !== item.song_id)
    );
  };
  const validatePayload = () => {
    const errors = [];
    const errorEmpty = err => `${err} can't be empty.`;
    Object.entries(payload).forEach(([key, value]) => {
      switch (key) {
        case "dsp_id":
          if (!value) errors.push(errorEmpty("Client Name"));
          break;
        case "revenue":
          if (!value) errors.push(errorEmpty("Revenue Value"));
          break;
        case "exchange_rate":
          if (!value && isForeignCurrency)
            errors.push(errorEmpty("Currency Name"));
          break;
        default:
          break;
      }
    });
    if (selectedSongs.length === 0) {
      errors.push(errorEmpty("Song"));
    }
    return errors;
  };

  const getClientList = async () => {
    try {
      setLoadingPage(true);
      const res = await axios.get(`${hardBaseUrl}/master/all-client`, {
        headers,
      });
      const resClientList = res?.data?.data;
      const choosenDSP = resClientList?.find(
        el => el?.dsp_id === resClientList?.[0]?.dsp_id
      );
      const dspCurrencyId = choosenDSP?.dsp_currency?.id;
      setOptionDsp(resClientList);
      handleChangePayload(resClientList?.[0]?.dsp_id, "dsp_id");
      setSelectedDSP(choosenDSP);
      if (isForeignCurrency) {
        getCurrency(dspCurrencyId);
      }
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  const getCurrency = async id => {
    try {
      const res = await axios.get(`${hardBaseUrl}/exchange-rate/code`, {
        headers,
        params: {
          currency_id: id,
          upload_date: new Date().toISOString().split("T")[0],
        },
      });
      handleChangePayload(res?.data?.data?.value, "exchange_rate");
    } catch (error) {
      ModalError(getErrors(error?.response));
    }
  };
  const getInvoiceNumber = async () => {
    const { type } = payload;
    try {
      const res = await axios.get(
        `${hardBaseUrl}/publisher/transaction-number/advanced`,
        { headers, params: { type } }
      );
      handleChangePayload(res?.data?.data, "transaction_number");
    } catch (error) {
      ModalError(getErrors(error?.response));
    }
  };
  const handleSubmit = async () => {
    const errors = validatePayload();
    if (errors?.length) {
      ModalError(errors[0]);
      return;
    }
    try {
      setLoadingPage(true);
      const modifiedPayload = {
        ...payload,
        song_id: selectedSongs?.map(item => item.song_id),
        start_date: formatPayloadPeriod(payload?.start_date),
        end_date: formatPayloadPeriod(payload?.end_date),
        invoice_date: payload?.invoice_date.format("YYYY-MM-DD"),
        revenue: Number(payload?.revenue),
        exchange_rate: isForeignCurrency ? Number(payload?.exchange_rate) : 1,
      };
      const res = await axios.post(
        `${hardBaseUrl}/publisher/transaction`,
        modifiedPayload,
        { headers }
      );

      if (documentFiles?.length > 0) {
        const formData = new FormData();
        formData.append(
          "publisher_transaction_id",
          res?.data?.data?.publisher_transaction_id
        );
        documentFiles.forEach(file => formData.append("documents", file));

        await axios.post(
          `${hardBaseUrl}/publisher/transaction/document`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }
      ModalSuccess(
        res?.data?.data || "Successfully Create Transaction"
      ).then(() => history.goBack());
    } catch (error) {
      console.log({ error });
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };

  useEffect(() => {
    getClientList();
  }, []);
  useEffect(() => {
    getInvoiceNumber();
  }, [payload?.type]);
  console.log({ selectedSongs });
  return (
    <Page className={classes?.root} title="Add Advance Transaction">
      {loadingPage ? (
        <SkeletonComponent variant="wave" />
      ) : (
        <Container maxWidth={false}>
          <HeaderTitle
            title="Advance Transaction"
            breadcrumbData={breadcrumbData}
            backButton
          />
          <Divider className={classes?.divider} />
          <SectionLabel
            subTitle="Feature for creating song's advanced transaction"
            my="16px"
          />
          <FormLabel label="Client Name" required />
          <SelectInput
            value={payload?.dsp_id}
            onChange={handleChangeClient}
            options={optionDsp}
            optionKey="dsp_id"
            optionLabel="dsp_name"
            width={350}
          />
          <Box border="1px solid #ebebeb" borderRadius="5px" p="24px" mt="16px">
            <Grid container columnSpacing={2} rowSpacing={1}>
              <Grid item xs={12} md={6}>
                <FormLabel label="Date" required />
                <DatePicker
                  value={payload?.invoice_date}
                  onChange={date => handleChangePayload(date, "invoice_date")}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormLabel label="Type" required />
                <SelectInput
                  value={payload?.type}
                  onChange={e => handleChangePayload(e?.target?.value, "type")}
                  options={selectionsType}
                  optionKey="id"
                  optionLabel="label"
                  width="100%"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormLabel label="Period" required />
                <MonthYearRangePicker
                  placeholder="Period"
                  startDate={payload?.start_date}
                  handleChangeStartDate={date =>
                    handleChangePayload(date, "start_date")
                  }
                  endDate={payload?.end_date}
                  handleChangeEndDate={date =>
                    handleChangePayload(date, "end_date")
                  }
                  width="100%"
                  endMaxDate={moment().add(5, "years")}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormLabel label="Revenue Value" required />
                <CurrencyNumberInput
                  value={payload?.revenue || 0}
                  onChange={data => handleChangePayload(data, "revenue")}
                  startAdornment={
                    isForeignCurrency
                      ? selectedDSP?.dsp_currency?.currency_symbol_code
                      : "Rp"
                  }
                />
              </Grid>
              {isForeignCurrency && (
                <Grid item xs={12} md={6}>
                  <FormLabel label="Exchange Rate" required />
                  <CurrencyNumberInput
                    value={payload?.exchange_rate || 0}
                    onChange={data =>
                      handleChangePayload(data, "exchange_rate")
                    }
                    startAdornment="Rp"
                  />
                </Grid>
              )}
            </Grid>
            <Grid
              container
              justifyContent="space-between"
              alignItems="flex-end"
            >
              <Grid item>
                <SectionLabel title="Song Detail" />
              </Grid>
              <Grid item>
                <PrimaryButton
                  label="Add Song"
                  onClick={() => setModalSong(true)}
                />
              </Grid>
            </Grid>
            <Box my="16px">
              <InnoTable
                columns={columnTableSongDetail({ handleDelete })}
                isLoading={false}
                isUsingCheckbox={false}
                items={selectedSongs || []}
                loadingColor={""}
              />
            </Box>
            <SupportingDocumentForm
              label="Upload proof of transfer"
              supportingDocumentFiles={documentFiles}
              setSupportingDocumentFiles={setDocumentFiles}
              multiple
              fileType={[".pdf", ".xls", ".xlsx", "image/*"]}
            />
          </Box>
          <Grid container justifyContent="right" spacing={1} mt="16px">
            <PrimaryButton
              label="Add"
              onClick={handleSubmit}
              disabled={loadingPage}
            />
          </Grid>
        </Container>
      )}
      <AddSongModal
        open={modalSong}
        handleClose={val => {
          setModalSong(false);
          setSelectedSongs(val);
        }}
        items={selectedSongs}
        body={payload}
        dsp_id={payload.dsp_id}
        startDate={payload.start_date}
        endDate={payload.end_date}
      />
    </Page>
  );
};

const formatPayloadPeriod = date => date.format("YYYY-MM");
const breadcrumbData = [
  {
    label: "Transaction",
    link: "/admin/advance",
  },
  {
    label: "Advance",
    link: "/admin/advance",
  },
  {
    label: "Create Transaction",
    active: true,
  },
];
const selectionsType = [
  { id: "recoupment", label: "Recoupment" },
  { id: "flat", label: "Flat" },
];
const columnTableSongDetail = ({ handleDelete }) => [
  {
    name: "song_title",
    title: "Song Title",
  },
  {
    name: "composer_names",
    title: "Composer/Author Name",
    renderText: item => <ArrayChip list={item} />,
  },
  {
    name: "composers",
    title: "Alias Name",
    renderText: item => (
      <ArrayChip list={item?.map(composer => composer?.alias_name)} />
    ),
  },
  {
    name: "all",
    title: "Action",
    renderText: item => <TableAction handleDelete={() => handleDelete(item)} />,
  },
];
export default AddAdvancedFinance;
