import {
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { CSVLink } from "react-csv";
import { Link, useLocation } from "react-router-dom";
import { Page } from "components";
import { hardBaseUrl } from "../../../../services/urlConstant";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  card: {
    borderLeft: "1px solid gray",
    boxShadow: "none",
    borderRadius: "0px",
  },
  insideCard: {
    padding: "20px",

    wordWrap: "break-word",
  },
  cardArea: {
    marginTop: "20px",
    marginBottom: "20px",
    borderRadius: "0px",
  },
  publisherMargin: {
    marginTop: "40px",
  },
  infoArea: {
    marginTop: "20px",
    marginBottom: "60px",
  },
  label: {
    fontSize: "16px",
    fontWeight: 500,
    color: "#364052",
  },
  labelValue: {
    fontSize: "16px",
    fontWeight: 400,
    color: "#111827",
  },
  headText: {
    fontSize: "12px",
    color: "#687083",
    fontWeight: "bold",
  },
  bodyTextBlack: {
    fontSize: "14px",
    fontWeight: "regular",
    color: "#111827",
    fontStyle: "normal",
  },
  bodyTextGrey: {
    fontSize: "14px",
    fontWeight: "regular",
    color: "#687083",
    fontStyle: "normal",
  },
  tableRow: {
    height: 20,
  },
  conTextBlack: {
    fontSize: "14px",
    fontWeight: "bold",
  },
  dropdown: {
    color: "black",
  },
  subtitle: {
    fontSize: "18px",
    fontWeight: "bold",
    lineHeight: "28px",
  },
  reportText: {
    fontSize: "14px",
    fontWeight: 500,
    color: "#687083",
    marginBottom: "15px",

    width: "300px",
  },
  reportValue: {
    fontSize: "24px",
    fontWeight: 500,
    color: "#111827",
  },
  breadcrumbs: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#111827",
  },
  breadCrumbsActive: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#687083",
  },
  table: {
    marginTop: "10px",
    border: "1px solid #D1D5DC",
    borderRadius: "6px",
  },
  thead: {
    "& th:first-child": {
      borderRadius: "6px",
    },
    "& th:last-child": {
      borderRadius: "6px",
    },
  },
}));

const PenciptaDetailReviewLagu = () => {
  const { state } = useLocation();
  const classes = useStyles();
  const [chartData, setChartData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [getLabel, setGetLabel] = useState([]);
  const [trafficListenerPerMonth, setTrafficListenerPerMonth] = useState(0);
  const [royaltyPerMonth, setRoyaltyPerMonth] = useState(0);
  const [trafficListenerAll, setTrafficListenerAll] = useState();
  const [royaltyAll, setRoyaltyAll] = useState();
  const [csvArray, setCsvArray] = useState([]);

  const [getMonth, setGetMonth] = useState("-");
  const [isrc, setIsrc] = useState("-");
  const [publisherName, setPublisherName] = useState("-");
  const [penciptaList, setPenciptaList] = useState([]);

  const getResponse = async () => {
    try {
      const token = localStorage.getItem("token");
      const url = `/publisher/song-usage/${state.lagu.song_id}?year=${state.lagu.year
        }&dsp_id=${localStorage.getItem("idDsp")}`;
      const res = await axios.get(hardBaseUrl + url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setChartData(res.data.data.usages);
      setTableData(res.data.data.usages);
      setIsrc(res.data.data.song.isrc_code || "-");
      setPenciptaList(res.data.data.composer_names);
      setPublisherName(res.data.data.publisher.name);

      const sumEveryListener = res.data.data.usages.reduce(
        (acc, cur) => acc + cur.listener,
        0
      );
      const formatter = new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR",
        minimumFractionDigits: 0,
      });

      const formatter2 = new Intl.NumberFormat("id-ID", {
        minimumFractionDigits: 0,
      });
      setTrafficListenerAll(formatter2.format(sumEveryListener));

      const sumEveryRoyalty = res.data.data.usages.reduce(
        (acc, cur) => acc + cur.royalty,
        0
      );

      setRoyaltyAll(formatter.format(sumEveryRoyalty));

      const date = res.data.data.usages.map(data => data.date);

      const month = date.map(data => data.split("-")[1]);

      const year = date.map(data => data.split("-")[0]);

      const year2 = year.map(data => data.slice(-2));

      const getDate = data => {
        switch (data) {
          case "01":
            return "Jan";
          case "02":
            return "Feb";
          case "03":
            return "Mar";
          case "04":
            return "Apr";
          case "05":
            return "May";
          case "06":
            return "Jun";
          case "07":
            return "Jul";
          case "08":
            return "Aug";
          case "09":
            return "Sep";
          case "10":
            return "Oct";
          case "11":
            return "Nov";
          case "12":
            return "Dec";
          default:
            return "Jan";
        }
      };

      const monthYearArray = month.map((data, index) => {
        return `${getDate(data)} ${year2[index]}`;
      });

      setGetLabel(monthYearArray);
    } catch (err) {
      new Error(err);
    }
  };
  const getDate = data => {
    switch (data) {
      case "01":
        return "January";
      case "02":
        return "February";
      case "03":
        return "March";
      case "04":
        return "April";
      case "05":
        return "May";
      case "06":
        return "June";
      case "07":
        return "July";
      case "08":
        return "August";
      case "09":
        return "September";
      case "10":
        return "October";
      case "11":
        return "November";
      case "12":
        return "December";
      default:
        return "January";
    }
  };

  const getMonthName = data => {
    const monthList = data.split("-")[1];

    return `${getDate(monthList)} `;
  };

  const getYearList = data => {
    const year = data.split("-")[0];
    const year2 = year.slice(-4);

    return ` ${year2}`;
  };

  const formatter = new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 0,
  });

  const formatter2 = new Intl.NumberFormat("id-ID", {
    minimumFractionDigits: 0,
  });

  useEffect(() => {
    getResponse();
  }, []);

  const data = {
    labels: getLabel,
    datasets: [
      {
        label: "",
        data: chartData.map(data => {
          return data.royalty;
        }),

        borderColor: "#9545EB",
        backgroundColor: "#9545EB",
        fill: false,
        lineTension: 0.2,
        borderDash: [],
        borderDashOffset: 0.0,
        pointBackgroundColor: "#9545EB",
        pointBorderColor: "#9545EB",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "#9545EB",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    responsive: true,

    interaction: {
      mode: "index",
      intersect: false,
    },
    hover: {
      mode: "nearest",
      intersect: true,
    },

    plugins: {
      layout: {},
      legend: {
        display: false,
      },
      title: {
        display: false,
        text: "Chart.js Line Chart",
      },
      subtitle: {
        display: true,
        text: "Custom Chart Subtitle",
      },

      tooltip: {
        events: ["click", "mouseout"],
        enabled: false,
        external: context => {
          let tooltipEl = document.getElementById("chartjs-tooltip");

          if (!tooltipEl) {
            tooltipEl = document.createElement("div");
            tooltipEl.id = "chartjs-tooltip";
            tooltipEl.innerHTML = "<table></table>";
            document.body.appendChild(tooltipEl);
          }

          const tooltipModel = context.tooltip;
          if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = "0";
            return;
          }

          tooltipEl.classList.remove("below", "no-transform");

          const month = context.tooltip.title[0];

          let monthName = month.substring(0, 3);

          if (monthName === "Jan") {
            monthName = "JANUARY";
          } else if (monthName === "Feb") {
            monthName = "FEBRUARY";
          } else if (monthName === "Mar") {
            monthName = "MARCH";
          } else if (monthName === "Apr") {
            monthName = "APRIL";
          } else if (monthName === "May") {
            monthName = "MAY";
          } else if (monthName === "Jun") {
            monthName = "JUNI";
          } else if (monthName === "Jul") {
            monthName = "JULY";
          } else if (monthName === "Aug") {
            monthName = "AUGUST";
          } else if (monthName === "Sep") {
            monthName = "SEPTEMBER";
          } else if (monthName === "Oct") {
            monthName = "OCTOBER";
          } else if (monthName === "Nov") {
            monthName = "NOVEMBER";
          } else if (monthName === "Dec") {
            monthName = "DECEMBER";
          }

          if (monthName === "JANUARY") {
            setTrafficListenerPerMonth(
              formatter2.format(chartData[0].listener)
            );
            setRoyaltyPerMonth(formatter.format(chartData[0].royalty));
            setGetMonth("January");
          } else if (monthName === "FEBRUARY") {
            setTrafficListenerPerMonth(
              formatter2.format(chartData[1].listener)
            );
            setRoyaltyPerMonth(formatter.format(chartData[1].royalty));
            setGetMonth("February");
          } else if (monthName === "MARCH") {
            setTrafficListenerPerMonth(
              formatter2.format(chartData[2].listener)
            );
            setRoyaltyPerMonth(formatter.format(chartData[2].royalty));
            setGetMonth("March");
          } else if (monthName === "APRIL") {
            setTrafficListenerPerMonth(
              formatter2.format(chartData[3].listener)
            );
            setRoyaltyPerMonth(formatter.format(chartData[3].royalty));
            setGetMonth("April");
          } else if (monthName === "MAY") {
            setTrafficListenerPerMonth(
              formatter2.format(chartData[4].listener)
            );
            setRoyaltyPerMonth(formatter.format(chartData[4].royalty));
            setGetMonth("May");
          } else if (monthName === "JUNI") {
            setTrafficListenerPerMonth(
              formatter2.format(chartData[5].listener)
            );
            setRoyaltyPerMonth(formatter.format(chartData[5].royalty));
            setGetMonth("Juni");
          } else if (monthName === "JULY") {
            setTrafficListenerPerMonth(
              formatter2.format(chartData[6].listener)
            );
            setRoyaltyPerMonth(formatter.format(chartData[6].royalty));
            setGetMonth("July");
          } else if (monthName === "AUGUST") {
            setTrafficListenerPerMonth(
              formatter2.format(chartData[7].listener)
            );
            setRoyaltyPerMonth(formatter.format(chartData[7].royalty));
            setGetMonth("August");
          } else if (monthName === "SEPTEMBER") {
            setTrafficListenerPerMonth(
              formatter2.format(chartData[8].listener)
            );
            setRoyaltyPerMonth(formatter.format(chartData[8].royalty));
            setGetMonth("September");
          } else if (monthName === "OCTOBER") {
            setTrafficListenerPerMonth(
              formatter2.format(chartData[9].listener)
            );
            setRoyaltyPerMonth(formatter.format(chartData[9].royalty));
            setGetMonth("October");
          } else if (monthName === "NOVEMBER") {
            setTrafficListenerPerMonth(
              formatter2.format(chartData[10].listener)
            );
            setRoyaltyPerMonth(formatter.format(chartData[10].royalty));
            setGetMonth("November");
          } else if (monthName === "DECEMBER") {
            setTrafficListenerPerMonth(
              formatter2.format(chartData[11].listener)
            );
            setRoyaltyPerMonth(formatter.format(chartData[11].royalty));
            setGetMonth("December");
          }

          if (tooltipModel.body) {
            const dataMap = data => {
              let dataMapping = data
                .map(() => {
                  const getDataListener = data => {
                    if (monthName === "JANUARY") {
                      return data[0].listener;
                    } else if (monthName === "FEBRUARY") {
                      return data[1].listener;
                    } else if (monthName === "MARCH") {
                      return data[2].listener;
                    } else if (monthName === "APRIL") {
                      return data[3].listener;
                    } else if (monthName === "MAY") {
                      return data[4].listener;
                    } else if (monthName === "JUNI") {
                      return data[5].listener;
                    } else if (monthName === "JULY") {
                      return data[6].listener;
                    } else if (monthName === "AUGUST") {
                      return data[7].listener;
                    } else if (monthName === "SEPTEMBER") {
                      return data[8].listener;
                    } else if (monthName === "OCTOBER") {
                      return data[9].listener;
                    } else if (monthName === "NOVEMBER") {
                      return data[10].listener;
                    } else if (monthName === "DECEMBER") {
                      return data[11].listener;
                    }
                  };

                  const getDataRoyalty = data => {
                    if (monthName === "JANUARY") {
                      return data[0].royalty;
                    } else if (monthName === "FEBRUARY") {
                      return data[1].royalty;
                    } else if (monthName === "MARCH") {
                      return data[2].royalty;
                    } else if (monthName === "APRIL") {
                      return data[3].royalty;
                    } else if (monthName === "MAY") {
                      return data[4].royalty;
                    } else if (monthName === "JUNI") {
                      return data[5].royalty;
                    } else if (monthName === "JULY") {
                      return data[6].royalty;
                    } else if (monthName === "AUGUST") {
                      return data[7].royalty;
                    } else if (monthName === "SEPTEMBER") {
                      return data[8].royalty;
                    } else if (monthName === "OCTOBER") {
                      return data[9].royalty;
                    } else if (monthName === "NOVEMBER") {
                      return data[10].royalty;
                    } else if (monthName === "DECEMBER") {
                      return data[11].royalty;
                    }
                  };
                  const formatter = new Intl.NumberFormat("id-ID", {
                    style: "currency",
                    currency: "IDR",
                    minimumFractionDigits: 0,
                  });

                  const formatter2 = new Intl.NumberFormat("id-ID", {
                    minimumFractionDigits: 0,
                  });

                  return `
                  <div style="margin-bottom:10px; ">
                    
                  <div style="display: flex; flex-direction: row;  padding-left: 1.2rem;">
                  <div style="color:#687083; font-size:12px; font-weight:400; font-family: 'Inter';display: flex; margin-right: 1.2rem;align-items: center;">Traffic:</div>
                  <div style="color:#364052; font-size:12px; font-weight:500; font-family: 'Inter';display: flex;  flex-direction: column;  font-family: 'Inter';  margin-left: 5px; ">${formatter2.format(
                    getDataListener(chartData)
                  )}</div>
                  </div>

                  <div style="display: flex; flex-direction: row;  padding-left: 1.2rem;">
                  <div style="color:#687083; font-size:12px; font-weight:400; font-family: 'Inter'; display: flex; flex-direction: column; margin-right: 1.2rem;align-items: center;">Revenue:</div>
                  <div style="color:#364052; font-size:12px; font-weight:500; display: flex;  flex-direction: column;  font-family: 'Inter'; ">${formatter.format(
                    getDataRoyalty(chartData)
                  )},-</div>
                  </div>

                  </div>
              `;
                })
                .join("");
              return dataMapping;
            };
            const innerHtml = `
                      <div style="background-color: white;padding-bottom: 10px;width: 200px;  border-radius: 10px; box-shadow: 0 6px 12px rgba(0,0,0,.175);">

                          <div style=" padding-top: 5px; padding-bottom: 10px; padding-left: 1.2rem; color: #364052; font-family: 'Inter'; font-size: 12px; font-weight; 500;">
                             ${monthName} ${state.lagu.year}
                          </div>
                          ${dataMap(context.tooltip._tooltipItems)}
                       </div>
                  `;

            tooltipEl.querySelector("table").innerHTML = innerHtml;
          }

          const position = context.chart.canvas.getBoundingClientRect();

          if (monthName === "NOVEMBER" || monthName === "DECEMBER") {
            tooltipEl.style.opacity = "1";
            tooltipEl.style.position = "absolute";
            tooltipEl.style.right =
              position.right + window.pageXOffset + tooltipModel.caretX + "px";
            tooltipEl.style.top =
              position.top + window.pageYOffset + tooltipModel.caretY + "px";
            tooltipEl.style.padding =
              tooltipModel.padding + "px " + tooltipModel.padding + "px";
            tooltipEl.style.pointerEvents = "none";
          } else {
            tooltipEl.style.opacity = "1";
            tooltipEl.style.position = "absolute";
            tooltipEl.style.left =
              position.left + window.pageXOffset + tooltipModel.caretX + "px";
            tooltipEl.style.top =
              position.top + window.pageYOffset + tooltipModel.caretY + "px";
            tooltipEl.style.padding =
              tooltipModel.padding + "px " + tooltipModel.padding + "px";
            tooltipEl.style.pointerEvents = "none";
          }
        },
        backgroundColor: "#fff",
        titleFontColor: "#333",
        bodyColor: "#333",
        titleColor: "#333",
        footerColor: "#333",
        titleMarginBottom: 20,

        titleFont: {
          weight: "bold",
          size: 17,
        },

        boxHeight: 10,
        boxPadding: 10,
        caretPadding: 50,
        mode: "index",
        intersect: false,
        bodySpacing: 5,
        borderColor: "#333",
        borderWidth: 1,

        bodyFont: {
          size: 15,
        },
        padding: {
          right: 10,
          left: 15,
          top: 15,
          bottom: 15,
        },
      },
    },
  };

  const getCsvArray = () => {
    const url = `${hardBaseUrl}/publisher/song-usage/${state.lagu.song_id
      }/download?year=${state.lagu.year}&dsp_id=${localStorage.getItem("idDsp")}`;
    const token = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(url, { headers })
      .then(res => {
        if (res.data.data !== null) {
          setCsvArray(res.data.data);
        }
      })
      .catch(() => { });
  };
  useEffect(() => {
    getCsvArray();
  }, []);
  return (
    <div>
      <Page className={classes.root} title="Review Penggunaan Lagu">
        <Container maxWidth={false}>
          { }
          <Grid
            alignItems="flex-end"
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <ThemeProvider theme={theme}>
                <Typography variant="h5">
                  Song Title:{" "}
                  <span
                    style={{
                      fontWeight: 700,
                    }}
                  >
                    {state.lagu.title}
                  </span>
                </Typography>
                <Typography
                  style={{
                    fontSize: "18px",
                    fontWeight: "400",
                    lineHeight: "28px",
                  }}
                >
                  Year of Song Usage: <span>{state.lagu.year}</span>
                </Typography>
              </ThemeProvider>
            </Grid>
            <Grid item>
              <Breadcrumbs aria-label="breadcrumb">
                <Typography color="textPrimary" className={classes.breadCrumbs}>
                  Home
                </Typography>
                <Typography color="textPrimary">Song Usage</Typography>
                <Typography
                  color="textPrimary"
                  className={classes.breadCrumbsActive}
                >
                  Song Detail
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
          <div className={classes.infoArea}>
            <Grid container lg={12} xl={12}>
              <Card
                style={{
                  width: "100%",
                  border: "1px solid #D1D5DC",
                  borderRadius: "6px",
                  height: "120px",
                  boxShadow: "none",
                }}
              >
                <CardContent>
                  <ThemeProvider theme={theme}>
                    <Grid container spacing={1} lg={12} xl={12}>
                      <Grid item lg={2} xl={2}>
                        <Typography
                          variant="subtitle2"
                          className={classes.label}
                        >
                          ISWC Code
                        </Typography>
                      </Grid>
                      <Grid item lg={10} xl={10}>
                        <Typography
                          variant="subtitle2"
                          className={classes.labelValue}
                        >
                          {isrc}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container spacing={1} lg={12} xl={12}>
                      <Grid item lg={2} xl={2}>
                        <Typography
                          variant="subtitle2"
                          className={classes.label}
                        >
                          Publisher
                        </Typography>
                      </Grid>
                      <Grid item lg={10} xl={10}>
                        <Typography
                          variant="subtitle2"
                          className={classes.labelValue}
                        >
                          {publisherName}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container spacing={1} lg={12} xl={12}>
                      <Grid item lg={2} xl={2}>
                        <Typography
                          variant="subtitle2"
                          className={classes.label}
                        >
                          Composer
                        </Typography>
                      </Grid>
                      <Grid item lg={10} xl={10}>
                        <Typography
                          variant="subtitle2"
                          className={classes.labelValue}
                        >
                          {penciptaList.join(", ")}
                        </Typography>
                      </Grid>
                    </Grid>
                  </ThemeProvider>
                </CardContent>
              </Card>
            </Grid>
          </div>

          <Grid container>
            <Grid item xs={12} md={12} sm={12} lg={6} xl={6}>
              <ThemeProvider theme={theme}>
                <Typography
                  variant="h5"
                  style={{
                    fontWeight: "bold",
                    marginBottom: "20px",
                  }}
                >
                  Trend of Song Usage
                </Typography>
              </ThemeProvider>
            </Grid>
          </Grid>

          <Grid item xs={12} md={12} sm={12} lg={12} xl={12}>
            <div
              style={{
                paddingBottom: "50px",
                paddingTop: "50px",
                paddingLeft: "20px",
                paddingRight: "20px",
                marginBottom: "50px",
                width: "100%",
                border: "1px solid #D1D5DC",
                borderRadius: "6px",
              }}
            >
              <Line options={options} data={data} plugins={[]} />
            </div>
          </Grid>

          <div className={classes.cardArea}>
            <Grid container spacing={2}>
              <ThemeProvider theme={theme}>
                <Grid item xs={3} md={3}>
                  <Card className={classes.card}>
                    <div className={classes.insideCard}>
                      <Typography
                        component="p"
                        variant="caption"
                        className={classes.reportText}
                      >{`${getMonth} Traffic`}</Typography>
                      <Typography variant="h5" className={classes.reportValue}>
                        {trafficListenerPerMonth}
                      </Typography>
                    </div>
                  </Card>
                </Grid>
                <Grid item xs={3} md={3}>
                  <Card className={classes.card}>
                    <div className={classes.insideCard}>
                      <Typography
                        component="p"
                        variant="caption"
                        className={classes.reportText}
                      >{`${getMonth} Revenue`}</Typography>
                      <Typography variant="h5" className={classes.reportValue}>
                        {`${royaltyPerMonth},-`}
                      </Typography>
                    </div>
                  </Card>
                </Grid>
                <Grid item xs={3} md={3}>
                  <Card className={classes.card}>
                    <div className={classes.insideCard}>
                      <Typography
                        component="p"
                        variant="caption"
                        className={classes.reportText}
                      >
                        Total Traffic
                      </Typography>
                      <Typography variant="h5" className={classes.reportValue}>
                        {trafficListenerAll}
                      </Typography>
                    </div>
                  </Card>
                </Grid>
                <Grid item xs={3} md={3}>
                  <Card className={classes.card}>
                    <div className={classes.insideCard}>
                      <Typography
                        component="p"
                        variant="caption"
                        className={classes.reportText}
                      >
                        Total Revenue
                      </Typography>
                      <Typography variant="h5" className={classes.reportValue}>
                        {`${royaltyAll},-`}
                      </Typography>
                    </div>
                  </Card>
                </Grid>
              </ThemeProvider>
            </Grid>
          </div>

          <Grid item xs={12} md={12} sm={12} lg={12} xl={12}>
            <div>
              <Button
                variant="contained"
                className={classes.btnRincian}
                style={{
                  backgroundColor: "#000",
                  marginRight: "10px",
                  marginTop: "17px",
                }}
                component={Link}
                to={{
                  pathname: "/admin/review-lagu/detail/print-report",
                  state: {
                    getMonth: getMonth,

                    trafficListenerPerMonth: trafficListenerPerMonth,
                    trafficListenerAll: trafficListenerAll,
                    royaltyPerMonth: royaltyPerMonth,
                    royaltyAll: royaltyAll,

                    chartData: data,
                    tableData: tableData,

                    title: state.lagu.title,
                    year: state.lagu.year,
                    isrc: isrc,
                    publisherName: publisherName,
                    penciptaList: penciptaList,
                  },
                }}
              >
                <ThemeProvider theme={theme}>
                  <Typography
                    style={{
                      color: "white",
                      fontSize: "14px",
                      fontWeight: "500",
                      lineHeight: "24px",
                      textTransform: "none",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Download PDF
                  </Typography>
                </ThemeProvider>{" "}
              </Button>
              <CSVLink data={csvArray} filename="song-detail.csv">
                <Button
                  variant="contained"
                  className={classes.btnRincian}
                  style={{
                    backgroundColor: "#000",
                    marginRight: "10px",
                    marginTop: "17px",
                  }}
                >
                  <ThemeProvider theme={theme}>
                    <Typography
                      style={{
                        color: "white",
                        fontSize: "14px",
                        fontWeight: "500",
                        lineHeight: "24px",
                        textTransform: "none",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Download CSV
                    </Typography>
                  </ThemeProvider>{" "}
                </Button>
              </CSVLink>
            </div>
            <Table size="small" className={classes.table}>
              <TableHead classes={{ root: classes.thead }}>
                <TableRow>
                  <ThemeProvider theme={theme}>
                    <TableCell
                      style={{
                        width: "400px",
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        className={classes.headText}
                      >
                        Month
                      </Typography>
                    </TableCell>

                    <TableCell>
                      <Typography
                        variant="subtitle2"
                        className={classes.headText}
                      >
                        Year
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography
                        variant="subtitle2"
                        className={classes.headText}
                      >
                        Traffic
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography
                        variant="subtitle2"
                        className={classes.headText}
                      >
                        Revenue
                      </Typography>
                    </TableCell>
                  </ThemeProvider>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.map(data => (
                  <TableRow hover key={data.id}>
                    <ThemeProvider theme={theme}>
                      <TableCell>
                        <Typography
                          variant="subtitle2"
                          className={classes.bodyTextBlack}
                        >
                          {getMonthName(data.date)}
                        </Typography>
                      </TableCell>

                      <TableCell>
                        <Typography
                          variant="subtitle2"
                          className={classes.bodyTextBlack}
                        >
                          {getYearList(data.date)}
                        </Typography>
                      </TableCell>

                      <TableCell align="right">
                        <Typography
                          variant="subtitle2"
                          className={classes.bodyTextBlack}
                        >
                          {formatter2.format(data.listener)}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography
                          variant="subtitle2"
                          className={classes.bodyTextBlack}
                        >
                          {`${formatter.format(data.royalty)},-`}
                        </Typography>
                      </TableCell>
                    </ThemeProvider>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
        </Container>
      </Page>
    </div>
  );
};

export default PenciptaDetailReviewLagu;
