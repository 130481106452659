import { Container, Divider } from "@material-ui/core";
import { Box, Grid } from "@mui/material";
import axios from "axios";
import {
  FormLabel,
  ModalError,
  ModalSuccess,
  Page,
  PrimaryButton,
  SectionLabel,
  TextInput,
} from "components";
import { HeaderTitle } from "layouts";
import { useState } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import { globalStyles } from "styles";
import { getErrors } from "utils";
import { hardBaseUrl } from "../../../../services/urlConstant";

const CreateOrEditRevenueType = () => {
  const classes = globalStyles();
  const history = useHistory();
  const { state } = useLocation();
  const { id } = useParams();
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const [loadingButton, setLoadingButton] = useState(false);
  const [payload, setPayload] = useState({
    name: state?.revenue?.name || "",
    description: state?.revenue?.description || "",
  });

  const handleChangePayload = (value, key) => {
    setPayload(currentState => ({
      ...currentState,
      [key]: value,
    }));
  };
  const handleSubmit = async () => {
    const method = id ? axios.put : axios.post;
    if (!payload?.name) {
      ModalError("Name can't be empty");
      return;
    }
    try {
      setLoadingButton(true);
      await method(
        `${hardBaseUrl}/revenue-type/${id ? `update/${id}` : "create"}`,
        payload,
        { headers }
      );
      ModalSuccess(
        `Successfully ${id ? "Update" : "Create"} Revenue Type`
      ).then(() => history.goBack());
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingButton(false);
    }
  };

  return (
    <Page
      className={classes.root}
      title={`${id ? "Edit" : "Add"} Revenue Type`}
    >
      <Container maxWidth={false}>
        <HeaderTitle
          title={`${id ? "Edit" : "Add"} Revenue Type`}
          breadcrumbData={breadcrumbData(id)}
          backButton
        />
        <Divider className={classes.divider} />
        <SectionLabel
          title="Revenue Type"
          subTitle="Customize and manage Revenue Type for transaction."
        />
        <Box maxWidth="50%">
          <Box border="1px solid #ebebeb" borderRadius="5px" p="24px" my="16px">
            <Grid container direction="column" rowSpacing={1}>
              <Grid item>
                <FormLabel label="Revenue Type Name" required />
                <TextInput
                  value={payload?.name}
                  onChange={e => handleChangePayload(e?.target?.value, "name")}
                  placeholder="Name"
                  disabled={loadingButton}
                />
              </Grid>
              <Grid item>
                <FormLabel label="Description" />
                <TextInput
                  multiline
                  rows={4}
                  value={payload?.description}
                  onChange={e =>
                    handleChangePayload(e?.target?.value, "description")
                  }
                  placeholder="Description"
                  disabled={loadingButton}
                />
              </Grid>
            </Grid>
          </Box>
          <Grid container justifyContent="right">
            <PrimaryButton
              label={
                loadingButton
                  ? id
                    ? "Saving"
                    : "Submitting"
                  : id
                  ? "Save"
                  : "Add"
              }
              onClick={handleSubmit}
              loading={loadingButton}
              disabled={loadingButton}
            />
          </Grid>
        </Box>
      </Container>
    </Page>
  );
};

const breadcrumbData = id => [
  {
    label: "Parameter",
    link: "/admin/parameter/admin-revenue-type",
  },
  {
    label: "Revenue Type",
    link: "/admin/parameter/admin-revenue-type",
  },
  {
    label: `${id ? "Edit" : "Add"} Revenue Type`,
    active: true,
  },
];

export default CreateOrEditRevenueType;
