import React, { useState, useEffect, useRef } from "react";
import { Page, SkeletonComponent, PrimaryButton } from "components";
import HeaderPage from "../../../../layouts/Header/HeaderPage";
import { ButtonGroupTop } from "../../../../components/atoms/Button";
import { Add } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import Swal from "sweetalert2";
import { hardBaseUrl } from "../../../../services/urlConstant";
import {
  Container,
  TextField,
  InputAdornment,
  Typography,
  Box,
  Grid,
} from "@material-ui/core";
import AddOrEditSociety from "./AddOrEditUserSociety";
import { InnoTable } from "inno-ui";

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  content: {
    marginTop: "20px",
  },
  containerSwal: {
    zIndex: 1400,
  },
  contentAdd: {
    marginTop: "20px",
    marginLeft: "20px",
    marginRight: "20px",
    fontFamily: ["Inter"].join(","),
  },
  logoColumn: {
    maxWidth: 50,
  },
  buttonDelete: {
    fontSize: "16px",
    backgroundColor: "black",
    color: "white",
    marginLeft: 10,
    textTransform: "none",
    "&:hover": {
      backgroundColor: "white",
      color: "black",
    },
  },
  buttonCancel: {
    color: "black",
    marginLeft: 10,
    textTransform: "none",
    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
  buttonAction: {
    backgroundColor: "#111827",
    color: "white",
    width: "35px",
    height: "35px",
    padding: "6px",
    borderRadius: "6px",
    marginLeft: "10px",
    cursor: "pointer",
    transition: "all 0.2s ease-in",
    "&:hover, &.MuiIconButton": {
      background: "#374151 !important",
    },
  },
  selectPadding: {
    "& .MuiSelect-outlined": {
      paddingTop: "14px",
      paddingBottom: "14px",
      paddingLeft: "20px",
      paddingRight: "30px",
    },
  },
  inputFields: {
    height: "44px",
    paddingTop: "0px",
    paddingBottom: "0px",
    "& .MuiOutlinedInput-root": {
      minWidth: 250,
      height: "44px",
      paddingTop: "0px",
      paddingBottom: "0px",
    },
  },
  boxAddButton: {
    margin: "20px 0px",
  },
  containerModal: {
    padding: "1rem",
  },
}));

const columns = [
  {
    name: "name",
    title: "Username",
  },
  {
    name: "email",
    title: "Email",
  },
  {
    name: "phone",
    title: "Phone",
  },
  {
    name: "party_data",
    title: "Society",
    renderText: party_data => (
      <Typography>{party_data?.society_name || "-"}</Typography>
    ),
  },
];


function UserSociety() {
  const classes = useStyles();
  const token = localStorage.getItem("token");
  const closeModalFromChild = useRef();
  const [state, setState] = useState({
    page: 1,
    search: "",
    size: 10,
    listUserSociety: [],
    listSociety: [],
    listRoles: [],
    totalData: 0,
    loading: false,
    openModalDelete: false,
    openModal: false,
    selectedUserSociety: {},
    itemToDelete: null,
  });
  const {
    page,
    search,
    size,
    listUserSociety,
    totalData,
    loading,
    selectedUserSociety,
    openModal,
    listSociety,
    listRoles,
  } = state;


  const closeDialog = type =>
    setState(currentState => ({
      ...currentState,
      [type]: false,
    }));

  const changeValue = (key, value) =>
    setState(currentState => ({
      ...currentState,
      value: {
        ...currentState.value,
        [key]: value,
      },
    }));

  const changeState = (key, value) =>
    setState(currentState => ({
      ...currentState,
      [key]: value,
    }));

  const deleteSociety = id => {
    changeState("loading", true);
    const url = `${hardBaseUrl}/user/${id}`;
    axios
      .delete(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        Swal.fire({
          title: "Success",
          text: "This user society successfully deleted",
          icon: "success",
          confirmButtonText: "Ok",
        });
        getlistUserSociety(page, size, search);
      })
      .catch(err => {
        Swal.fire({
          title: "Error",
          text: err,
          icon: "error",
          confirmButtonText: "Ok",
        });
      })
      .finally(() => {
        changeValue("itemToDelete", null);
        closeDialog("openModalDelete");
        changeState("loading", false);
      });
  };

  const getlistUserSociety = async (page, size, search) => {
    changeState("loading", true);
    const url = `${hardBaseUrl}/user/datatable`;
    const params = {
      page,
      per_page: size,
      search,
      party_type: "society",
    };
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params,
      });
      changeState("listUserSociety", response?.data?.data || []);
      changeState("totalData", response?.data?.meta?.total);
      changeState("loading", false);
    } catch (err) {
      changeState("loading", false);
    }
  };

  const getlistSociety = async () => {
    changeState("loading", true);
    const url = `${hardBaseUrl}/admin/society/?page=1&per_page=10&sort=&search=`;
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      changeState("listSociety", response?.data?.data || []);
      changeState("loading", false);
    } catch (err) {
      changeState("loading", false);
    }
  };

  const getlistRoles = async () => {
    changeState("loading", true);
    const url = `${hardBaseUrl}/role`;
    const params = {
      page: 1,
      per_page: -1,
      type: "society",
    };
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params,
      });
      changeState("listRoles", response?.data?.data || []);
      changeState("loading", false);
    } catch (err) {
      changeState("loading", false);
    }
  };

  const addUserSociety = async value => {
    changeState("loading", true);
    const { username, email, phone, password, role_id, society_id, confirm_password } = value;
    const url = `${hardBaseUrl}/user/register`;
    const payload = {
      username,
      email,
      phone,
      password,
      role_id,
      society_id,
      confirm_password
    };
    axios
      .post(url, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        closeModalFromChild?.current?.closeModal();
        Swal.fire({
          title: "Success",
          text: "This user society successfully added",
          icon: "success",
          confirmButtonText: "Ok",
        });
        getlistUserSociety(page, size, search);
      })
      .catch(({ response }) => {
        Swal.fire({
          title: "Error",
          text: response?.data?.errors[0]?.message || "Something wrong",
          icon: "error",
          confirmButtonText: "Ok",
          customClass: {
            container: classes.containerSwal,
          },
        });
      })
      .finally(() => {
        changeState("loadingDialog", false);
        changeState("loading", false);
      });
  };
  const editUserSociety = value => {
    changeState("loading", true);
    const { username, email, phone, password, role_id, society_id, confirm_password } = value;
    const url = `${hardBaseUrl}/user/update/${selectedUserSociety?.user_id}`;
    const payload = {
      username,
      email,
      phone,
      password,
      confirm_password,
      role_id,
      society_id,
    };
    const filterPayload = Object.fromEntries(
      Object.entries(payload).filter(item => item[1])
    );
    axios
      .put(url, filterPayload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        closeModalFromChild?.current?.closeModal();
        Swal.fire({
          title: "Success",
          text: "This user society successfully updated",
          icon: "success",
          confirmButtonText: "Ok",
        });
        getlistUserSociety(page, size, search);
      })
      .catch(({ response }) => {
        Swal.fire({
          title: "Error",
          text: response?.data?.errors[0]?.message || "Something wrong",
          icon: "error",
          confirmButtonText: "Ok",
          customClass: {
            container: classes.containerSwal,
          },
        });
      })
      .finally(() => {
        changeState("loadingDialog", false);
        changeState("loading", false);
      });
  };

  useEffect(() => {
    getlistUserSociety(page, size, search);
    getlistRoles();
    getlistSociety();
  }, []);

  useEffect(() => {
    getlistUserSociety(page, size, search);
  }, [page, size, search]);
  const isEditing = Boolean(selectedUserSociety?.user_id);
  const pagesCount = Math.ceil(totalData / size);
  return (
    <Page className={classes.root} title="User Society">
      <AddOrEditSociety
        selectedUserSociety={selectedUserSociety}
        isOpen={openModal}
        loading={loading}
        listRoles={listRoles}
        submit={isEditing ? editUserSociety : addUserSociety}
        listSociety={listSociety}
        onClose={() => {
          changeState("openModal", false);
          changeState("selectedUserSociety", {});
        }}
        ref={closeModalFromChild}
      />
      <Container maxWidth={false}>
        <HeaderPage
          title="Parameter"
          breadcrumbs={["Parameter", "User Society"]}
        />
        <ButtonGroupTop />
        <Box className={classes.content}>
          <Grid container className={classes.boxAddButton} justifyContent="space-between">
            <Grid item>
              <TextField
                variant="outlined"
                autoComplete="search"
                className={classes.inputFields}
                type="text"
                value={search}
                onChange={e => changeState("search", e?.target?.value)}
                placeholder="Search"
                InputProps={{
                  autoComplete: "search",
                  startAdornment: (
                    <InputAdornment position="start">
                      <img
                        src={
                          require("assets/image-public/images/search.svg")
                            .default
                        }
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item>
              <PrimaryButton
                onClick={() => changeState("openModal", true)}
                variant="contained"
                height={44}
                startIcon={<Add />}
              >
                Add User Society
              </PrimaryButton>
            </Grid>
          </Grid>
          {loading ? (
            <SkeletonComponent variant="wave" />
          ) : (
            <InnoTable
              idColumnName="id"
              deleteName="name"
              page={page}
              handleChangePage={(_, page) => changeState("page", page)}
              handleChangeRowsPerPage={e =>
                changeState("size", e?.target?.value)
              }
              rowsPerPage={size}
              handleEdit={item => {
                changeState("openModal", true);
                changeState("selectedUserSociety", item);
              }}
              handleDelete={item => deleteSociety(item?.user_id)}
              totalPage={pagesCount}
              isHaveAction
              isLoading={loading}
              columns={columns}
              items={listUserSociety}
            />
          )}

        </Box>
      </Container>

    </Page>
  );
}

export default UserSociety;
