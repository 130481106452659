import {
  Button,
  Card,
  Grid,
  Modal,
  TextareaAutosize,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useEffect, useState } from "react";
import InputFileImage from "../../../components/atoms/Input/InputFileImage";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    padding: 16,
    alignItems: "center",
    justifyContent: "center",
    border: "1px dashed #9AA2B1",
    cursor: "pointer",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid #D1D5DC",
  },
  paper: {
    position: "absolute",
    width: 500,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  title: {
    fontSize: "20px",
    fontWeight: "500",
    lineHeight: "32px",
  },
  subTitle: {
    fontSize: "14px",
    lineHeight: "20px",
    color: "#687083",
    fontWeight: "400",
  },
  field: {
    width: "100%",
    paddingLeft: "0px !important",
  },
  inputFields: {
    height: "44px",
    marginLeft: "0px !important",
    marginTop: 2,
    paddingTop: "0px",
    paddingBottom: "0px",
    width: "100%",
    "& .MuiOutlinedInput-root": {
      width: "100%",
      height: "44px",
      paddingTop: "0px",
      paddingBottom: "0px",
    },
  },
  inputFieldsArea: {
    padding: "10px 14px 10px 14px",
    borderRadius: "6px",
    gap: 10,
    marginTop: 2,
    height: "100px",
    marginLeft: "0px !important",
    paddingTop: "0px",
    paddingBottom: "0px",
    width: "100%",
    "& .MuiOutlinedInput-root": {
      width: "80%",
      height: "44px",
      paddingTop: "0px",
      paddingBottom: "0px",
    },
  },
  secondary: {
    background: "black",
    color: "white",
    marginLeft: 10,
    "&:hover": {
      background: "white",
      color: "black",
      border: "1px solid black",
    },
  },
}));

function AddOrEditPartner({
  open,
  onClose,
  handleChange,
  value,
  onSubmit,
  isEditing,
}) {
  const { name, address, image, status } = value;
  const classes = useStyles();
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    const checkImageFromUrlOrFile = async file => {
      if (typeof file === "object") {
        const checkImageFromUrlOrFile = async file => {
          if (typeof file === "object") {
            const readURL = async file => {
              return new Promise((res, rej) => {
                const reader = new FileReader();
                reader.onload = e => res(e.target.result);
                reader.onerror = e => rej(e);
                reader.readAsDataURL(file);
              });
            };
            return readURL(file);
          } else {
            return file;
          }
        };
        const result = await checkImageFromUrlOrFile(file);
        setSelectedImage(result);
      } else {
        setSelectedImage(file);
      }
    };
    checkImageFromUrlOrFile(image);
  }, [image]);

  const submit = () => {
    onSubmit(name, address, selectedImage, value?.id, status);
  };
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={classes.modal}
    >
      <Card className={classes.paper}>
        <Typography className={classes.title}>
          {isEditing ? "Edit Partner" : "Create Partner"}
        </Typography>
        <Typography className={classes.subTitle}>
          This page s for {isEditing ? "edit" : "create"} partner
        </Typography>
        <Grid
          container
          direction="column"
          spacing={3}
          style={{ margin: "20px 0px" }}
        >
          <Grid item className={classes.field}>
            <Typography>Publisher Name</Typography>
            <TextField
              className={classes.inputFields}
              variant="outlined"
              placeholder="Publisher Name"
              value={name}
              onChange={e => handleChange("name", e?.target?.value)}
            />
          </Grid>
          <Grid item className={classes.field}>
            <Typography>Publisher Address</Typography>
            <TextareaAutosize
              className={classes.inputFieldsArea}
              minRows={3}
              variant="outlined"
              placeholder="Publisher Address"
              value={address}
              onChange={e => handleChange("address", e?.target?.value)}
            />
          </Grid>
          <Grid item className={classes.field}>
            <Typography>Publisher Image</Typography>
            <InputFileImage
              onChange={e => handleChange("image", e.target.files[0])}
              selectedImage={selectedImage}
            />
          </Grid>
        </Grid>
        <Grid container justifyContent="flex-end" spacing={3}>
          <Button onClick={onClose} variant="outlined">
            Cancel
          </Button>
          <Button onClick={submit} className={classes.secondary}>
            Save Changes
          </Button>
        </Grid>
      </Card>
    </Modal>
  );
}

export default AddOrEditPartner;
