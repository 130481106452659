import { Container, Divider } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { Box, Grid } from "@mui/material";
import axios from "axios";
import {
  AutoCompleteComponent,
  BalanceCard,
  CurrencyDisplay,
  ModalError,
  Page,
  PrimaryButton,
  SearchTextInput,
  SelectInput,
  SkeletonComponent,
} from "components";
import { InnoTableV2 } from "inno-ui";
import { HeaderTitle } from "layouts";
import { getTotalPage } from "lib";
import { debounce } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { ButtonGroupTop } from "components/atoms/Button";
import { globalStyles } from "styles";
import { emptyText, getErrors, textCapitalization } from "utils";
import { hardBaseUrl } from "../../../services/urlConstant";

const AdvancedPageFinance = () => {
  const classes = globalStyles();
  const history = useHistory();
  const location = useLocation();
  const typeWeb = localStorage.getItem("typeWeb");
  const isMpis = typeWeb === "mpis";
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const urlParams = new URLSearchParams(location.search);
  const paramsSearch = urlParams.get("search");
  const paramsPage = urlParams.get("page");
  const paramsSize = urlParams.get("size");
  const paramsDSP = urlParams.get("dsp_id");
  const paramsStatus = urlParams.get("status");

  const [loadingPage, setLoadingPage] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [queryParams, setQueryParams] = useState({
    page: Number(paramsPage) || 1,
    size: Number(paramsSize) || 10,
    search: paramsSearch || "",
    dsp_id: Number(paramsDSP) || "",
    status: paramsStatus || "",
  });
  const [tableTotalPage, setTableTotalPage] = useState(1);
  const [optionDSP, setOptionDSP] = useState([]);
  const [dataSummary, setDataSummary] = useState({});

  const handleChangePageParams = (value, key) => {
    urlParams.set(key, value);
    history.push({ search: urlParams.toString() });
  };
  const handleChangeQueryParams = (value, key) => {
    setQueryParams(currentState => ({
      ...currentState,
      ...(key !== "page" && { page: 1 }),
      [key]: value,
    }));
    handleChangePageParams(value, key);
    if (key !== "page") {
      handleChangePageParams(1, "page");
    }
  };

  const getOptionDSP = async () => {
    try {
      setLoadingPage(true);
      const res = await axios.get(`${hardBaseUrl}/master/all-client`, {
        headers,
      });

      const { data } = res?.data;
      const modifiedData = data?.map(item => ({
        ...item,
        id: item?.dsp_id,
        label: item?.dsp_name,
      }));

      setOptionDSP(modifiedData);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  const getDataTable = async () => {
    try {
      setLoadingPage(true);
      const res = await axios.get(
        `${hardBaseUrl}/publisher/transaction/advanced`,
        {
          headers,
          params: queryParams,
        }
      );

      const { data, meta } = res?.data;
      const modifiedData = data?.map(({ period, ...item }) => {
        const [startMonth, endMonth] = period.split(" - ");
        const formattedPeriod = `${formatDatePeriod(
          startMonth
        )} - ${formatDatePeriod(endMonth)}`;
        return {
          period: formattedPeriod,
          ...item,
        };
      });
      setDataTable(modifiedData);
      const pageCount = getTotalPage(meta?.total, queryParams?.size || 1);
      setTableTotalPage(pageCount);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  const getDataSummary = async () => {
    try {
      setLoadingPage(true);
      const res = await axios.get(
        `${hardBaseUrl}/publisher/transaction/summary/advanced`,
        {
          headers,
          params: {
            dsp_id: queryParams?.dsp_id,
            search: queryParams?.search,
          },
        }
      );
      setDataSummary(res?.data?.data);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  const debounceDataTable = useCallback(
    debounce(() => {
      getDataTable();
    }, 500),
    [queryParams]
  );

  useEffect(() => {
    getOptionDSP();
  }, []);
  useEffect(() => {
    const isSearching = Boolean(queryParams?.search);
    if (isSearching) {
      debounceDataTable();
      return () => {
        debounceDataTable.cancel();
      };
    } else {
      getDataTable();
      getDataSummary();
    }
  }, [queryParams, debounceDataTable]);

  return (
    <Page className={classes.root} title="Advance Transaction">
      <Container maxWidth={false}>
        <HeaderTitle
          title="Advance Transaction"
          breadcrumbData={breadcrumbData}
        />
        <ButtonGroupTop />
        <Box marginBottom="20px">
          <Divider />
        </Box>
        <AutoCompleteComponent
          label={queryParams?.dsp_id === "" ? "All Client" : "Client"}
          options={optionDSP}
          value={
            optionDSP.find(
              option => option.id === Number(queryParams?.dsp_id)
            ) || null
          }
          onChange={e => handleChangeQueryParams(e, "dsp_id")}
          size="small"
        />
        <Box mt="16px">
          <BalanceCard
            value={dataSummary.revenue}
            clientLabel={dataSummary?.dsp_name}
            periodLabel={dataSummary?.period}
          />
        </Box>
        <Grid container justifyContent="space-between" my="16px">
          <Grid item>
            <SearchTextInput
              placeholder="Search"
              value={queryParams?.search}
              onChange={e =>
                handleChangeQueryParams(e?.target?.value, "search")
              }
            />
          </Grid>
          <Grid item>
            <Grid container columnSpacing={1} alignItems="center">
              <Grid item>
                <SelectInput
                  label="Status"
                  options={optionStatus}
                  optionKey="key"
                  optionLabel="value"
                  value={queryParams?.status || ""}
                  onChange={e =>
                    handleChangeQueryParams(e?.target?.value, "status")
                  }
                  placeholder="All"
                  width={200}
                />
              </Grid>
              <Grid item>
                <PrimaryButton
                  label="Create Transaction"
                  startIcon={<Add />}
                  onClick={() => history.push("/admin/advance/add")}
                  size="large"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {loadingPage ? (
          <SkeletonComponent variant="wave" />
        ) : (
          <InnoTableV2
            isLoading={false}
            columns={columnTable}
            items={dataTable}
            page={queryParams?.page}
            rowsPerPage={queryParams?.size}
            totalPage={tableTotalPage}
            handleChangePage={(_, page) =>
              handleChangeQueryParams(page, "page")
            }
            handleChangeRowsPerPage={e =>
              handleChangeQueryParams(e?.target?.value, "size")
            }
            isHaveAction
            handleView={item =>
              history.push({
                pathname: `/${isMpis ? "admin" : "finance"}/advance/${item?.publisher_transaction_id
                  }`,
                state: { customer: item },
              })
            }
          />
        )}
      </Container>
    </Page>
  );
};

const formatDatePeriod = date => moment(date, "MMMM YYYY").format("MMM YYYY");
const breadcrumbData = [
  {
    label: "Home",
    link: "/admin/dashboard",
  },
  {
    label: "Transaction",
    link: "/admin/advance",
  },
  {
    label: "Advance Transaction",
    active: true,
  },
];
const optionStatus = [
  { key: "paid", value: "Paid" },
  { key: "unpaid", value: "Unpaid" },
];
const columnTable = [
  {
    name: "dsp_name",
    title: "DSP",
  },
  {
    name: "period",
    title: "Period",
  },
  {
    name: "song_title",
    title: "Song",
    renderText: item => emptyText(item),
  },
  {
    name: "all",
    title: "Type",
    renderText: item => textCapitalization(item?.type),
  },
  {
    name: "all",
    title: "Exchange Rate",
    headerAlign: "right",
    renderText: item => (
      <CurrencyDisplay
        value={item?.exchange_rate}
        decimalScale={2}
        prefix="Rp"
      />
    ),
  },
  {
    name: "all",
    title: "Base Currency Fee",
    headerAlign: "right",
    renderText: item => (
      <CurrencyDisplay
        value={item?.base_currency_revenue}
        decimalScale={2}
        prefix="Rp"
      />
    ),
  },
  {
    name: "all",
    title: "Origin Currency Fee",
    headerAlign: "right",
    renderText: item => (
      <CurrencyDisplay
        value={item?.original_currency_revenue}
        decimalScale={2}
        prefix={`${item?.currency_symbol_code}`}
      />
    ),
  },
  {
    name: "status",
    title: "Status",
    renderText: item => textCapitalization(item),
  },
];
export default AdvancedPageFinance;
