import { Container, Divider } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { Grid } from "@mui/material";
import axios from "axios";
import {
  ButtonGroupTop,
  ModalError,
  ModalSuccess,
  Page,
  PrimaryButton,
  SearchTextInput,
  SectionLabel,
  SkeletonComponent,
} from "components";
import { InnoTableV2 } from "inno-ui";
import { HeaderTitle } from "layouts";
import { getTotalPage } from "lib";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { globalStyles } from "styles";
import { getErrors, textCapitalization } from "utils";
import { hardBaseUrl } from "../../../../services/urlConstant";

function Pajak() {
  const classes = globalStyles();
  const history = useHistory();
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const [loadingPage, setLoadingPage] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [queryParams, setQueryParams] = useState({
    page: 1,
    per_page: 10,
    search: "",
  });
  const [tableTotalPage, setTableTotalPage] = useState(1);

  const handleChangeQueryParams = (value, key) => {
    setQueryParams(currentState => ({
      ...currentState,
      ...(key !== "page" && { page: 1 }),
      [key]: value,
    }));
  };

  const getDataTable = async () => {
    try {
      setLoadingPage(true);
      const res = await axios.get(`${hardBaseUrl}/tax/datatable`, {
        headers,
        params: queryParams,
      });

      const { data, meta } = res?.data;
      setDataTable(data || []);
      const pageCount = getTotalPage(meta?.total, queryParams?.per_page || 1);
      setTableTotalPage(pageCount);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  const debounceDataTable = useCallback(
    debounce(() => {
      getDataTable();
    }, 500),
    [queryParams]
  );
  const deleteTax = async item => {
    try {
      setLoadingPage(true);
      await axios.delete(`${hardBaseUrl}/tax/delete/${item?.id}`, {
        headers,
      });
      ModalSuccess("Tax Deleted Sucesfully").then(() => getDataTable());
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };

  useEffect(() => {
    debounceDataTable();
    return () => {
      debounceDataTable.cancel();
    };
  }, [queryParams, debounceDataTable]);

  return (
    <Page className={classes.root} title="Tax">
      {loadingPage ? (
        <SkeletonComponent variant="wave" />
      ) : (
        <Container maxWidth={false}>
          <HeaderTitle title="Configuration" breadcrumbData={breadcrumbData} />
          <ButtonGroupTop />
          <Divider className={classes.flatDivider} />
          <SectionLabel
            title="Tax"
            subTitle="This feature is used to settings and manage Client's Tax"
            mt="16px"
          />
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            my="16px"
          >
            <Grid item>
              <SearchTextInput
                placeholder="Search"
                value={queryParams?.search}
                onChange={e =>
                  handleChangeQueryParams(e?.target?.value, "search")
                }
              />
            </Grid>
            <Grid item>
              <PrimaryButton
                label="Add Tax"
                onClick={() => history.push("/admin/konfigurasi/pajak/add")}
                startIcon={<Add />}
                size="large"
              />
            </Grid>
          </Grid>
          <InnoTableV2
            isLoading={false}
            columns={columnTable}
            items={dataTable}
            page={queryParams?.page}
            rowsPerPage={queryParams?.per_page}
            totalPage={tableTotalPage}
            handleChangePage={(_, page) =>
              handleChangeQueryParams(page, "page")
            }
            handleChangeRowsPerPage={e =>
              handleChangeQueryParams(e?.target?.value, "per_page")
            }
            isHaveAction
            handleEdit={item =>
              history.push({
                pathname: `/admin/konfigurasi/pajak/edit/${item.id}`,
                state: { customer: item },
              })
            }
            handleDelete={item => deleteTax(item)}
            isUsingDeleteConfirmation={true}
            deleteName="code"
          />
        </Container>
      )}
    </Page>
  );
}
const breadcrumbData = [
  {
    label: "Configuration",
    link: "/admin/konfigurasi/pajak",
  },
  {
    label: "Tax",
    active: true,
  },
];
const columnTable = [
  {
    name: "code",
    title: "Code",
  },
  {
    name: "description",
    title: "Description",
  },
  {
    name: "rate",
    title: "Rate",
    renderText: item => `${item} %`,
  },
  {
    name: "tax_bases",
    title: "Bases",
    renderText: item => `${item} %`,
  },
  {
    name: "method",
    title: "Method",
    renderText: item => textCapitalization(item),
  },
  {
    name: "object",
    title: "Object",
    renderText: item => textCapitalization(item),
  },
];

export default Pajak;
