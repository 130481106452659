import { useEffect, useState } from "react";

const Timer = ({ time, onTimeEnd, className, renderText }) => {
  const [seconds, setSeconds] = useState(time);

  useEffect(() => {
    setSeconds(time); // Reset seconds whenever the time prop changes
  }, [time]);

  useEffect(() => {
    const timerId = setTimeout(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      } else {
        onTimeEnd(); // Call onTimeEnd when time is up
      }
    }, 1000);

    return () => clearTimeout(timerId); // Cleanup on unmount
  }, [seconds, onTimeEnd]);

  const format = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    return `${minutes < 10 ? "0" : ""}${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };
  if (renderText) {
    return renderText(seconds);
  } else {
    return (
      <div className={className || "timer"}>
        <span>{format(seconds)}</span>
      </div>
    );
  }

};

export default Timer;
