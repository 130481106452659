import {
  LOGIN_SUCCESS,
  LOGOUT,
  TOKEN_VALID,
  TOKEN_INVALID,
} from "../../constants/types";
import AuthService from "../../services/auth.service";
export const SESSION_LOGOUT = "SESSION_LOGOUT";

export const logout = () => dispatch =>
  dispatch({
    type: SESSION_LOGOUT,
  });

export const tokenInfo = token => dispatch => {
  return AuthService.tokenInfo(token).then(
    data => {
      localStorage.setItem("token", token);
      localStorage.setItem("role", data.data.data.role.type);
      dispatch({
        type: TOKEN_VALID,
        payload: { user: data.data.data },
      });
    },
    () => {
      dispatch({
        type: TOKEN_INVALID,
        payload: null,
      });
    }
  );
};

export const login = data => dispatch => {
  dispatch({
    type: LOGIN_SUCCESS,
    payload: { user: data },
  });
};
