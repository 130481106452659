import { Close } from "@material-ui/icons";
import {
  Box,
  Card,
  ClickAwayListener,
  Divider,
  Grid,
  Grow,
  IconButton,
  InputAdornment,
  Paper,
  Popover,
  styled,
  TextField,
} from "@mui/material";
import { DateRangeIcon } from "@mui/x-date-pickers";
import {
  DateCalendar,
  FormLabel,
  PrimaryButton,
  SecondaryButton,
} from "components";
import moment from "moment-timezone";
import { useRef, useState } from "react";
import { formatPeriodMonthYear } from "utils";

const MonthYearRangePicker = ({
  label,
  startDate,
  endDate,
  handleChangeStartDate,
  handleChangeEndDate,
  disabled,
  clear,
  width,
  syncYear,
  placeholder,
  startMinDate,
  startMaxDate,
  endMinDate,
  endMaxDate,
}) => {
  const inputRef = useRef(null);
  const emptyDate = !startDate && !endDate;
  const [anchorEl, setAnchorEl] = useState(null);
  const [period, setPeriod] = useState({
    start: startDate,
    end: endDate,
  });

  const yearDifference = (start, end) =>
    moment(start).year() !== moment(end).year();
  const togglePopper = event => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const handleClickAway = () => {
    setAnchorEl(null);
    if (anchorEl) {
      handleReset();
    }
  };
  const handleClear = () => {
    handleChangeStartDate(null);
    handleChangeEndDate(null);
  };
  const handleReset = () => {
    handleChangePeriod(startDate, "start");
    handleChangePeriod(endDate, "end");
  };
  const handleChangePeriod = (value, key) =>
    setPeriod(state => ({
      ...state,
      [key]: value,
    }));

  const synchronizeYears = (newDate, key) => {
    const otherKey = key === "start" ? "end" : "start";
    const otherDate = moment(period[otherKey]);
    if (yearDifference(otherDate, newDate)) {
      const adjustedDate = otherDate.year(moment(newDate).year());
      handleChangePeriod(adjustedDate, otherKey);
    }
  };

  const onChangeStartDate = date => {
    const startPeriod = moment(date).startOf("month");
    if (syncYear) {
      synchronizeYears(startPeriod, "start");
    }
    const endPeriod = moment(period.end);
    if (startPeriod.isAfter(endPeriod)) {
      const adjustedEndPeriod = moment(period.end)
        .year(startPeriod.year())
        .startOf("month");
      if (syncYear && yearDifference(startPeriod, endPeriod)) {
        handleChangePeriod(adjustedEndPeriod, "end");
      } else {
        handleChangePeriod(startPeriod, "end");
      }
    }
    handleChangePeriod(startPeriod, "start");
  };

  const onChangeEndDate = date => {
    const endPeriod = moment(date).startOf("month");
    if (syncYear) {
      synchronizeYears(endPeriod, "end");
    }
    const startPeriod = moment(period.start);
    if (endPeriod.isBefore(startPeriod)) {
      const adjustedStartPeriod = moment(period.start)
        .year(endPeriod.year())
        .startOf("month");
      if (syncYear && yearDifference(startPeriod, endPeriod)) {
        handleChangePeriod(adjustedStartPeriod, "start");
      } else {
        handleChangePeriod(endPeriod, "start");
      }
    }
    handleChangePeriod(endPeriod, "end");
  };
  const handleSubmit = () => {
    handleChangeStartDate(period?.start);
    handleChangeEndDate(period?.end);
    setAnchorEl(null);
  };

  return (
    <Box>
      <CustomTextField
        ref={inputRef}
        customWidth={width}
        size="small"
        label={label}
        placeholder={placeholder}
        value={emptyDate ? "" : formatPeriodMonthYear(startDate, endDate)}
        onClick={togglePopper}
        InputProps={{
          readOnly: true,
          shrink: !emptyDate,
          fullWidth: true,
          endAdornment: (
            <InputAdornment position="end">
              {clear && (startDate || endDate) && (
                <IconButton onClick={handleClear}>
                  <Close />
                </IconButton>
              )}
              <IconButton onClick={togglePopper}>
                <DateRangeIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        disabled={disabled}
      />
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClickAway}
        anchorOrigin={{
          vertical: "bottom",
        }}
        transformOrigin={{
          vertical: "top",
        }}
        TransitionComponent={Grow}
      >
        <ClickAwayListener onClickAway={handleClickAway}>
          <Paper
            elevation={3}
            sx={{
              padding: 2,
            }}
          >
            <Grid container spacing={3} direction="row" wrap="nowrap">
              <Grid item>
                <FormLabel label="Start Period" />
                <Card>
                  <DateCalendar
                    views={["month", "year"]}
                    openTo="month"
                    value={period?.start}
                    onChange={onChangeStartDate}
                    minDate={startMinDate}
                    maxDate={startMaxDate}
                  />
                </Card>
              </Grid>
              <Grid item>
                <FormLabel label="End Period" />
                <Card>
                  <DateCalendar
                    views={["month", "year"]}
                    openTo="month"
                    value={period?.end}
                    onChange={onChangeEndDate}
                    minDate={endMinDate}
                    maxDate={endMaxDate}
                  />
                </Card>
              </Grid>
            </Grid>
            <Divider sx={{ my: 2 }} />
            <Grid container justifyContent="end" spacing={1}>
              <Grid item>
                <SecondaryButton
                  size="small"
                  onClick={togglePopper}
                  label="Cancel"
                />
              </Grid>
              <Grid item>
                <PrimaryButton
                  size="small"
                  onClick={handleSubmit}
                  label="Apply"
                />
              </Grid>
            </Grid>
          </Paper>
        </ClickAwayListener>
      </Popover>
    </Box>
  );
};

const CustomTextField = styled(TextField)(({ customWidth }) => ({
  width: customWidth || 300,
}));
export default MonthYearRangePicker;
