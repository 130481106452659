import { Backdrop, CircularProgress } from "@material-ui/core";

const TableLoading = ({ open, color, backdropStyle }) => {
  return (
    <Backdrop open={open} style={backdropStyle}>
      <CircularProgress color={color} />
    </Backdrop>
  );
};

export default TableLoading;
