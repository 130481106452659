import {
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  Chip,
  Container,
  Divider,
  Grid,
  Typography,
} from "@material-ui/core";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { useEffect, useRef, useState } from "react";
import NumberFormat from "react-number-format";
import { useLocation } from "react-router-dom";
import { Page } from "components";
import { hardBaseUrl } from "../../../../services/urlConstant";

const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  results: {
    marginTop: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  formControl: {},
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  pagination: {
    "& .Mui-selected": {
      backgroundColor: "black",
      color: "white",
    },
  },
  pageSize: {
    height: "30px",
    borderRadius: "6px",
    border: "1px solid #D1D5DC",
    paddingLeft: "5px",
    paddingRight: "5px",
    marginTop: "5px",
  },
  paginationText: {
    fontSize: "14px",
    fontWeight: "normal",
    fontStyle: "normal",
    color: "#687083",
  },
  breadcrumbs: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#111827",
  },
  breadCrumbsActive: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#687083",
  },
  placeholder: {
    "&::placeholder": {
      color: "#9AA2B1",
    },
  },
  subTitle: {
    fontSize: "18px",
    fontWeight: 700,
    color: " #111827",
  },
  headText: {
    fontSize: "12px",
    color: "#687083",
    fontWeight: "bold",
  },
}));

const PenciptaDetailPayment = () => {
  const classes = useStyles();
  const { state } = useLocation();
  const inputRef = useRef(null);
  const [status, setStatus] = useState("");
  const [img, setImg] = useState("");
  const [address, setAddress] = useState("");
  const [publisher, setPublisher] = useState("");
  const [date, setDate] = useState("");
  const [composerName, setComposerName] = useState("");

  const [value, setValue] = useState("");
  const [notes, setNotes] = useState("");
  const [remainingBalance] = useState("");
  const [taxes, setTaxes] = useState([]);

  const [totalrevenue, setTotalRevenue] = useState("");
  const [costAllocation, setCostAllocation] = useState("");
  const [costAllocationPercentage, setCostAllocationPercentage] = useState("");
  const [agent, setAgent] = useState([]);

  const getDataDetail = () => {
    const token = localStorage.getItem("token");
    const url = `${hardBaseUrl}/composer/transaction/payment/${state.customer.publisher_transaction_id}`;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .get(url, config)
      .then(res => {
        setStatus(res.data.data.status);
        setAddress(res.data.data.publisher_address);
        setPublisher(res.data.data.publisher_name);
        setDate(res.data.data.date);
        setComposerName(res.data.data.composer_name);

        setValue(res.data.data.transaction_value);
        setNotes(res.data.data.notes);

        setTaxes(res.data.data.transaction_payment_detail.taxes);

        setTotalRevenue(res.data.data.transaction_payment_detail.total_revenue);
        setCostAllocation(
          res.data.data.transaction_payment_detail.cost_allocation
            .cost_allocation_value
        );
        setCostAllocationPercentage(
          res.data.data.transaction_payment_detail.cost_allocation
            .cost_allocation_percentage
        );
        setAgent(res.data.data.transaction_payment_detail.agent_fee);
      })
      .catch(() => {});
  };

  const getMe = () => {
    const token = localStorage.getItem("token");

    axios
      .get(hardBaseUrl + "/me", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(response => {
        setImg(response.data.data.publisher.profile_image);
      })
      .catch(() => {});
  };

  useEffect(() => {
    getDataDetail();
    getMe();
  }, []);

  const chipColor = status => {
    if (status === "paid") {
      return "#8DE5AB";
    } else if (status === "unpaid") {
      return "#ff000010";
    } else if (status === "waiting_for_approval") {
      return "#F6C962";
    }
  };
  const chipText = status => {
    if (status === "paid") {
      return "#34774C";
    } else if (status === "unpaid") {
      return "#A63124";
    } else if (status === "waiting_for_approval") {
      return "#A84D20";
    }
  };

  const chipLabel = status => {
    if (status === "paid") {
      return "Paid";
    } else if (status === "unpaid") {
      return "Unpaid";
    } else if (status === "waiting_for_approval") {
      return "Waiting for approval";
    }
  };

  const handlePrintPDF = () => {
    html2canvas(inputRef.current, { useCORS: true }).then(canvas => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "px", "a4");
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save("download.pdf");
    });
  };
  return (
    <Page className={classes.root} title="User">
      <Container maxWidth={false}>
        <Grid
          alignItems="flex-end"
          container
          justifyContent="space-between"
          spacing={3}
        >
          <Grid item>
            <ThemeProvider theme={theme}>
              <Typography
                style={{
                  fontSize: "24px",
                  fontWeight: "700",
                  lineHeight: "32px",
                }}
              >
                Payment Transaction
              </Typography>
            </ThemeProvider>
          </Grid>
          <Grid item>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography className={classes.breadCrumbs}>Home</Typography>

              <Typography className={classes.breadCrumbs}>
                Transaction
              </Typography>

              <Typography className={classes.breadCrumbsActive}>
                Detail Transaction
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>

        <Divider className={classes.divider} />

        {}
        {}

        <Grid container>
          <Grid item xs={12} md={8} lg={8} xl={8}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={6} xl={6}>
                <div>
                  <ThemeProvider theme={theme}>
                    <Typography
                      component="h1"
                      variant="h3"
                      className={classes.subTitle}
                    >
                      Payment Detail
                    </Typography>
                  </ThemeProvider>
                  <ThemeProvider theme={theme}>
                    <p
                      style={{
                        marginTop: "5px",
                        color: "#8f8f8f",
                        fontSize: "14px",
                      }}
                    >
                      This is detail credit note information, in payment
                      transaction
                    </p>
                  </ThemeProvider>
                </div>
              </Grid>
              <Grid item xs={12} md={6} lg={6} xl={6}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Chip
                    label={chipLabel(status)}
                    style={{
                      backgroundColor: chipColor(status),
                      border: "1px thin #fff",
                      color: chipText(status),
                    }}
                  />
                </div>
              </Grid>
            </Grid>
            <Card
              style={{
                border: "1px solid #9AA2B1",
                borderRadius: "6px",
                boxShadow: "none",
              }}
              ref={inputRef}
            >
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6} lg={6} xl={6}>
                    {img === "" ? (
                      <div></div>
                    ) : (
                      <img
                        src={img}
                        alt="img"
                        style={{
                          width: "155.52px",
                          height: "50px",
                          objectFit: "contain",
                        }}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} md={6} lg={6} xl={6}>
                    <div
                      style={{
                        textAlign: "right",
                      }}
                    >
                      <div>
                        <ThemeProvider theme={theme}>
                          <Typography
                            style={{
                              fontSize: "14px",
                              fontWeight: "600",
                              lineHeight: "20px",
                            }}
                          >
                            {composerName}
                          </Typography>
                          <Typography
                            style={{
                              fontSize: "14px",
                              fontWeight: "400",
                              lineHeight: "17px",
                              color: "#6F6F84",
                              wordWrap: "break-word",
                            }}
                          >
                            {address}
                          </Typography>
                        </ThemeProvider>
                      </div>
                    </div>
                  </Grid>
                </Grid>

                <Divider className={classes.divider} />

                <Grid container spacing={3}>
                  <Grid item xs={12} md={6} lg={6} xl={6}>
                    <ThemeProvider theme={theme}>
                      <Typography
                        style={{
                          fontSize: "14px",
                          fontWeight: "400",
                          lineHeight: "16.94px",
                        }}
                      >
                        Request Payment to:
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "14px",
                          fontWeight: "600",
                          lineHeight: "16.94px",
                        }}
                      >
                        {publisher}
                      </Typography>
                    </ThemeProvider>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6} xl={6}>
                    <ThemeProvider theme={theme}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          style={{
                            fontSize: "14px",
                            fontWeight: "400",
                            lineHeight: "16.94px",
                          }}
                        >
                          Date:
                        </Typography>

                        <Typography
                          style={{
                            fontSize: "14px",
                            fontWeight: "400",
                            lineHeight: "16.94px",
                          }}
                        >
                          {date}
                        </Typography>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: "5px",
                        }}
                      >
                        <Typography
                          style={{
                            fontSize: "14px",
                            fontWeight: "400",
                            lineHeight: "16.94px",
                          }}
                        >
                          Composer:
                        </Typography>

                        <Typography
                          style={{
                            fontSize: "14px",
                            fontWeight: "400",
                            lineHeight: "16.94px",
                          }}
                        >
                          {composerName}
                        </Typography>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: "5px",
                        }}
                      >
                        <Typography
                          style={{
                            fontSize: "14px",
                            fontWeight: "400",
                            lineHeight: "16.94px",
                          }}
                        >
                          Value:
                        </Typography>

                        <Typography
                          style={{
                            fontSize: "14px",
                            fontWeight: "400",
                            lineHeight: "16.94px",
                          }}
                        >
                          <NumberFormat
                            displayType="text"
                            value={value}
                            thousandSeparator={true}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            prefix="Rp"
                          />
                        </Typography>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: "5px",
                        }}
                      >
                        <Typography
                          style={{
                            fontSize: "14px",
                            fontWeight: "400",
                            lineHeight: "16.94px",
                          }}
                        >
                          Remaining Balance:
                        </Typography>

                        <Typography
                          style={{
                            fontSize: "14px",
                            fontWeight: "400",
                            lineHeight: "16.94px",
                          }}
                        >
                          {remainingBalance || "-"}
                        </Typography>
                      </div>
                    </ThemeProvider>
                  </Grid>
                </Grid>
                <Divider className={classes.divider} />

                <Grid container>
                  <div>
                    <ThemeProvider theme={theme}>
                      <Typography
                        style={{
                          fontSize: "14px",
                          fontWeight: "600",
                        }}
                      >
                        Notes:
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        {notes}
                      </Typography>
                    </ThemeProvider>
                  </div>
                </Grid>

                <div
                  style={{
                    backgroundColor: "#f2f2f2",
                    borderRadius: "10px",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    marginTop: "20px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      borderBottom: "1px solid #e1e1e1",
                      paddingBottom: "20px",
                    }}
                  >
                    <div
                      style={{
                        marginTop: "10px",
                        marginLeft: "10px",
                      }}
                    >
                      Value
                    </div>
                    <div
                      style={{
                        marginTop: "10px",
                        marginRight: "10px",
                        fontSize: "16px",
                        fontWeight: "600",
                      }}
                    >
                      <NumberFormat
                        displayType="text"
                        value={value}
                        thousandSeparator={true}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        prefix="Rp"
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      borderBottom: "0px solid #e1e1e1",
                      paddingBottom: "20px",
                    }}
                  >
                    {taxes?.length &&
                      taxes.map((el, idx) => {
                        return (
                          <Grid key={idx} container>
                            <Grid item xs={4} md={4} lg={4} xl={4}>
                              <div
                                style={{
                                  marginTop: "10px",
                                  marginLeft: "10px",
                                }}
                              >
                                {el?.code}
                              </div>
                            </Grid>
                            <Grid item xs={4} md={4} lg={4} xl={4}>
                              <div
                                style={{
                                  marginTop: "10px",
                                  textAlign: "center",
                                }}
                              >
                                {`${el?.rate}% (${el?.method})`}
                              </div>
                            </Grid>
                            <Grid item xs={4} md={4} lg={4} xl={4}>
                              <div
                                style={{
                                  marginTop: "10px",
                                  marginRight: "10px",
                                  fontSize: "14px",
                                  fontWeight: "600",
                                  textAlign: "right",
                                }}
                              >
                                {}
                                <NumberFormat
                                  displayType="text"
                                  value={el?.value}
                                  thousandSeparator={true}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                  prefix="Rp"
                                />
                              </div>
                            </Grid>
                          </Grid>
                        );
                      })}
                    {costAllocation === "" &&
                    costAllocationPercentage === "" ? (
                      <>{""}</>
                    ) : (
                      <>
                        <Grid container>
                          <Grid item xs={4} md={4} lg={4} xl={4}>
                            <div
                              style={{
                                marginTop: "10px",
                                marginLeft: "10px",
                              }}
                            >
                              Cost Allocation
                            </div>
                          </Grid>
                          <Grid item xs={4} md={4} lg={4} xl={4}>
                            <div
                              style={{
                                marginTop: "10px",
                                textAlign: "center",
                              }}
                            >
                              {costAllocationPercentage !== "" ? (
                                <>{`${costAllocationPercentage}`}</>
                              ) : (
                                <>{"-"}</>
                              )}
                            </div>
                          </Grid>
                          <Grid item xs={4} md={4} lg={4} xl={4}>
                            <div
                              style={{
                                marginTop: "10px",
                                marginRight: "10px",
                                fontSize: "14px",
                                fontWeight: "600",
                                textAlign: "right",
                              }}
                            >
                              <NumberFormat
                                displayType="text"
                                value={costAllocation}
                                thousandSeparator={true}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                prefix="Rp"
                              />
                              {}
                            </div>
                          </Grid>
                        </Grid>
                      </>
                    )}

                    {agent?.length > 0 ? (
                      <>
                        {agent.map((customer, index) => {
                          return (
                            <div
                              key={index}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                borderBottom: "0px solid #e1e1e1",
                                paddingBottom: "20px",
                              }}
                            >
                              <Grid container>
                                <Grid item xs={4} md={4} lg={4} xl={4}>
                                  <div
                                    style={{
                                      marginTop: "10px",
                                      marginLeft: "10px",
                                    }}
                                  >
                                    Agent Fee
                                    {}
                                  </div>
                                </Grid>
                                <Grid item xs={4} md={4} lg={4} xl={4}>
                                  <div
                                    style={{
                                      marginTop: "10px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {customer.agent_percentage}
                                  </div>
                                </Grid>
                                <Grid item xs={4} md={4} lg={4} xl={4}>
                                  <div
                                    style={{
                                      marginTop: "10px",
                                      marginRight: "10px",
                                      fontSize: "14px",
                                      fontWeight: "600",
                                      textAlign: "right",
                                    }}
                                  >
                                    <NumberFormat
                                      displayType="text"
                                      value={customer.agent_value}
                                      thousandSeparator={true}
                                      decimalScale={2}
                                      fixedDecimalScale={true}
                                      prefix="Rp"
                                    />
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                          );
                        })}
                      </>
                    ) : (
                      <>{""}</>
                    )}
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      borderBottom: "0px solid #e1e1e1",
                      paddingBottom: "20px",
                    }}
                  >
                    <div
                      style={{
                        marginTop: "10px",
                        marginLeft: "10px",
                      }}
                    >
                      Total Value
                    </div>
                    <div
                      style={{
                        marginTop: "10px",
                        marginRight: "10px",
                        fontSize: "16px",
                        fontWeight: "600",
                      }}
                    >
                      <NumberFormat
                        displayType="text"
                        value={totalrevenue}
                        thousandSeparator={true}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        prefix="Rp"
                      />
                    </div>
                  </div>
                </div>
              </CardContent>
            </Card>
            <div
              style={{
                marginTop: "20px",
                display: "flex",
                justifyContent: "end",
              }}
            >
              {status === "paid" ? (
                <Button
                  style={{
                    backgroundColor: "#111827",
                    color: "white",
                    padding: "10px 28px 10px 28px",
                    borderRadius: "6px",
                  }}
                  variant="contained"
                  size="large"
                  onClick={handlePrintPDF}
                >
                  <ThemeProvider theme={theme}>
                    <Typography
                      component="span"
                      style={{
                        fontSize: "14px",
                        lineHeight: "20px",
                        fontWeight: "500",
                        fontStyle: "normal",
                        textTransform: "none",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Download As PDF
                    </Typography>
                  </ThemeProvider>
                </Button>
              ) : null}
            </div>
          </Grid>
          <Grid item xs={12} md={4} lg={4} xl={4}></Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default PenciptaDetailPayment;
