import {
  Breadcrumbs,
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import PencilVectorGrey from "../../../../assets/img/pencilVectorGrey.svg";
import TrashVectorGrey from "../../../../assets/img/trashVectorGrey.svg";
import CustomModal from "../../../../components/molecules/Modal/CustomModal";
import { Page } from "components";
import { hardBaseUrl } from "../../../../services/urlConstant";
import { formatFileSize } from "../../../../utils";

const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  results: {
    marginTop: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  formControl: {
    width: "340px",
    border: "0px solid grey",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  pagination: {
    "& .Mui-selected": {
      backgroundColor: "black",
      color: "white",
    },
  },
  selectPadding: {
    "& .MuiSelect-outlined": {
      paddingTop: "14px",
      paddingBottom: "14px",
      paddingLeft: "20px",
      paddingRight: "30px",
    },
  },
  pageSize: {
    height: "30px",
    borderRadius: "6px",
    border: "1px solid #D1D5DC",
    paddingLeft: "5px",
    paddingRight: "5px",
    marginTop: "5px",
  },
  paginationText: {
    fontSize: "14px",
    fontWeight: "normal",
    fontStyle: "normal",
    color: "#687083",
  },
  breadcrumbs: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#111827",
  },
  breadCrumbsActive: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#687083",
  },
  placeholder: {
    "&::placeholder": {
      color: "#9AA2B1",
    },
  },
}));

const Detail = props => {
  const classes = useStyles();
  const { state } = useLocation();
  const inputRef = useRef(null);
  const [dataDet, setDataDet] = useState(null);
  const [dialogConfirm, setDialogConfirm] = useState(false);
  const [files, setFiles] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        let theToken = localStorage.getItem("token");
        let baseOfUrl = hardBaseUrl;

        const url = baseOfUrl + "/billing/detail/" + props.match.params.id;
        const options = {
          headers: {
            Authorization: "Bearer " + theToken,
          },
        };
        const response = await axios.get(url, options);
        setDataDet(response.data.data);
      } catch (error) {
        new Error(error);
      }
    };
    fetchUsers();
  }, []);

  const handlePrintPDF = () => {
    html2canvas(inputRef.current, { useCORS: true }).then(canvas => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "px", "a4");
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save("download.pdf");
    });
  };
  const handleFiles = e => {
    const data = [];
    Array.from(e.target.files).forEach(file => {
      data.push(file);
    });
    setFiles(data);
  };
  const handleUpload = () => {
    const token = localStorage.getItem("token");
    setDialogConfirm(false);
    const formData = new FormData();

    formData.append("id", props.match.params.id);
    files.map(val => {
      formData.append("file", val);
    });

    const url = `${hardBaseUrl}/billing/pay`;
    axios
      .post(url, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(response => {
        Swal.fire({
          icon: response?.data?.message === "success" ? "success" : "error",
          title: response?.data?.message === "success" ? "Success" : "Error",
          text: response?.data?.message,
        }).then(() => (window.location.href = "/finance/payment"));
      })
      .catch(() => { });
  };
  return (
    <Page className={classes.root} title="Billing Detail">
      <Container maxWidth={false}>
        <Grid
          alignItems="flex-end"
          container
          justifyContent="space-between"
          spacing={3}
        >
          <Grid item>
            <ThemeProvider theme={theme}>
              <Typography
                style={{
                  fontSize: "24px",
                  fontWeight: "700",
                  lineHeight: "32px",
                }}
              >
                Billing
              </Typography>
            </ThemeProvider>
          </Grid>
          <Grid item>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography className={classes.breadCrumbs}>Home</Typography>

              <Typography className={classes.breadCrumbs}>Billing</Typography>
              <Typography className={classes.breadCrumbs}>Detail</Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>

        <Divider className={classes.divider} />

        <div
          style={{
            marginTop: "24px",
          }}
        >
          <div>
            <span
              style={{
                fontSize: "16px",
              }}
            >
              <b>Billing Detail</b>
            </span>
          </div>
          <div>
            <span>Feature to view bill details to be paid by publisher</span>
          </div>
        </div>

        <Grid container spacing={3} ref={inputRef}>
          <Grid item xs={8}>
            <div
              style={{
                border: "1px solid #9AA2B1",
                padding: "16px",
                borderRadius: "8px",
                marginTop: "16px",
              }}
            >
              <Grid container>
                <Grid item xs={12} md={6} lg={6} xl={6}>
                  <img
                    alt="img"
                    style={{
                      width: "155.52px",
                      height: "50px",
                      objectFit: "contain",
                      display: "none",
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6} xl={6}>
                  <div
                    style={{
                      textAlign: "right",
                    }}
                  >
                    <div>
                      <ThemeProvider theme={theme}>
                        <Typography
                          style={{
                            fontSize: "14px",
                            fontWeight: "600",
                            lineHeight: "20px",
                          }}
                        >
                          MPIS Indonesia
                        </Typography>
                        <Typography
                          style={{
                            fontSize: "14px",
                            fontWeight: "400",
                            lineHeight: "17px",
                            color: "#6F6F84",
                            wordWrap: "break-word",
                          }}
                        >
                          Jl. Setia Budi, RT 05 RW 05, Karet Kuningan, South
                          Jakarta
                        </Typography>
                      </ThemeProvider>
                    </div>
                  </div>
                </Grid>
              </Grid>

              <Divider className={classes.divider} />

              <Grid container>
                <Grid item xs={12} md={4} lg={4} xl={4}>
                  <div>Billing To :</div>
                  <div>
                    <b>
                      {dataDet !== null ? <>{dataDet.billing_to}</> : <>{""}</>}
                    </b>
                  </div>
                </Grid>
                <Grid item xs={12} md={4} lg={4} xl={4}>
                  <div
                    style={{
                      textAlign: "right",
                    }}
                  >
                    <div>Invoice Number :</div>
                    <div>Date :</div>
                    <div>Period :</div>
                  </div>
                </Grid>
                <Grid item xs={12} md={4} lg={4} xl={4}>
                  <div
                    style={{
                      textAlign: "right",
                    }}
                  >
                    <div>
                      <ThemeProvider theme={theme}>
                        <Typography
                          style={{
                            fontSize: "14px",

                            lineHeight: "20px",
                          }}
                        >
                          {dataDet !== null ? (
                            <>{dataDet.transaction_number}</>
                          ) : (
                            <>{""}</>
                          )}
                        </Typography>
                      </ThemeProvider>
                    </div>
                    <div>
                      <ThemeProvider theme={theme}>
                        <Typography
                          style={{
                            fontSize: "14px",

                            lineHeight: "20px",
                          }}
                        >
                          {dataDet !== null ? <>{dataDet.date}</> : <>{""}</>}
                        </Typography>
                      </ThemeProvider>
                    </div>
                    <div>
                      <ThemeProvider theme={theme}>
                        <Typography
                          style={{
                            fontSize: "14px",

                            lineHeight: "20px",
                          }}
                        >
                          {dataDet !== null ? <>{dataDet.period}</> : <>{""}</>}
                        </Typography>
                      </ThemeProvider>
                    </div>
                  </div>
                </Grid>
              </Grid>

              <Divider className={classes.divider} />

              <Grid container>
                <Grid item xs={12} md={6} lg={6} xl={6}>
                  <div>Billing Value</div>
                  <div>Cost Service</div>
                  <div>
                    <ThemeProvider theme={theme}>
                      <Typography
                        style={{
                          fontSize: "12px",
                          fontWeight: "400",
                          lineHeight: "17px",
                          color: "#6F6F84",
                          wordWrap: "break-word",
                        }}
                      >
                        The invoice that we pay to song users (can be to DSP /
                        non DSP), 3% is taken
                      </Typography>
                    </ThemeProvider>
                  </div>
                </Grid>
                <Grid item xs={12} md={6} lg={6} xl={6}>
                  <div
                    style={{
                      textAlign: "right",
                    }}
                  >
                    <div>
                      <ThemeProvider theme={theme}>
                        <Typography
                          style={{
                            fontSize: "14px",
                            fontWeight: "600",
                            lineHeight: "20px",
                          }}
                        >
                          {dataDet !== null ? (
                            <>{dataDet.billing_total}</>
                          ) : (
                            <>{""}</>
                          )}
                        </Typography>
                      </ThemeProvider>
                    </div>
                    <div>
                      <ThemeProvider theme={theme}>
                        <Typography
                          style={{
                            fontSize: "14px",
                            fontWeight: "600",
                            lineHeight: "20px",
                          }}
                        >
                          {dataDet !== null ? (
                            <>{dataDet.cost_service}</>
                          ) : (
                            <>{""}</>
                          )}
                        </Typography>
                      </ThemeProvider>
                    </div>
                  </div>
                </Grid>
              </Grid>

              <Divider className={classes.divider} />

              <Grid container>
                <Grid item xs={12} md={6} lg={6} xl={6}>
                  <div>
                    <ThemeProvider theme={theme}>
                      <Typography
                        style={{
                          fontSize: "14px",

                          lineHeight: "20px",
                        }}
                      >
                        Billing Total
                      </Typography>
                    </ThemeProvider>
                  </div>
                </Grid>
                <Grid item xs={12} md={6} lg={6} xl={6}>
                  <div
                    style={{
                      textAlign: "right",
                    }}
                  >
                    <div>
                      <ThemeProvider theme={theme}>
                        <Typography
                          style={{
                            fontSize: "18px",
                            fontWeight: "600",
                            lineHeight: "20px",
                          }}
                        >
                          {dataDet !== null ? (
                            <>{dataDet.billing_total}</>
                          ) : (
                            <>{""}</>
                          )}
                        </Typography>
                      </ThemeProvider>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={8}>
            <div
              style={{
                marginTop: "18px",
                textAlign: "right",
              }}
            >
              {state.status === "unpaid" ? (
                <>
                  <Button
                    style={{
                      backgroundColor: "#111827",
                      color: "white",
                      padding: "10px 28px 10px 28px",
                      borderRadius: "6px",
                      marginRight: "7px",
                    }}
                    variant="contained"
                    size="large"
                    onClick={() => setDialogConfirm(true)}
                  >
                    <ThemeProvider theme={theme}>
                      <Typography
                        component="span"
                        style={{
                          fontSize: "14px",
                          lineHeight: "20px",
                          fontWeight: "500",
                          fontStyle: "normal",
                          textTransform: "none",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Confirm Payment
                      </Typography>
                    </ThemeProvider>
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    style={{
                      backgroundColor: "#111827",
                      color: "white",
                      padding: "10px 28px 10px 28px",
                      borderRadius: "6px",
                      marginRight: "7px",
                    }}
                    variant="contained"
                    size="large"
                    onClick={handlePrintPDF}
                  >
                    <ThemeProvider theme={theme}>
                      <Typography
                        component="span"
                        style={{
                          fontSize: "14px",
                          lineHeight: "20px",
                          fontWeight: "500",
                          fontStyle: "normal",
                          textTransform: "none",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Download As PDF
                      </Typography>
                    </ThemeProvider>
                  </Button>
                </>
              )}
            </div>
          </Grid>
          <Grid item xs={4}></Grid>
        </Grid>
      </Container>
      <CustomModal
        open={dialogConfirm}
        handleClose={() => {
          setDialogConfirm(false);
        }}
        width="460px"
        noPadding
      >
        <Grid
          style={{
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "1px solid #E4E7EB",
            padding: "20px",
          }}
        >
          <h1
            style={{
              fontWeight: 700,
              fontSize: "20px",
              lineHeight: "30px",
            }}
          >
            Upload Payment Proof
          </h1>
          <IconButton
            style={{ padding: 0 }}
            onClick={() => setDialogConfirm(false)}
          >
            <CloseIcon />
          </IconButton>
        </Grid>
        <Grid
          style={{
            padding: "24px",
          }}
        >
          <Grid
            style={{
              border: "1px dashed #9AA2B1",
              width: "100%",
              paddingTop: "16px",
              paddingBottom: "16px",
              textAlign: "center",
              backgroundColor: "#F9FAFB",
              borderRadius: "8px",
              position: "relative",
            }}
          >
            <input
              type="file"
              multiple
              onChange={handleFiles}
              style={{
                opacity: 0.0,
                position: "absolute",
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                width: "100%",
                height: "100%",
              }}
            />
            <img
              alt="Logo"
              src={
                require("assets/image-public/images/vector-upload-trans.png")
                  .default
              }
            />
            <h3
              style={{
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "20px",
                marginTop: "8px",
              }}
            >
              Upload Proof Of Payment, <strong>Search File</strong>{" "}
            </h3>
          </Grid>
        </Grid>
        <Grid
          style={{
            padding: "24px",
          }}
        >
          <h3>Document Attached</h3>
          {files.map((res, i) => {
            return (
              <Grid
                key={i}
                style={{
                  marginTop: "12px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  alt="Logo"
                  src={
                    require("assets/image-public/images/vector-doc-icon.png")
                      .default
                  }
                  style={{
                    width: "24px",
                    height: "24px",
                    objectFit: "contain",
                  }}
                />
                <Grid
                  style={{
                    fontWeight: 400,
                    fontSize: "14px",
                    width: "100%",
                    paddingLeft: "16px",
                  }}
                >
                  <p
                    style={{
                      lineHeight: "20px",
                      margin: 0,
                      marginBottom: "4px",
                    }}
                  >
                    {res?.name}
                  </p>
                  <span
                    style={{
                      color: "#687083",
                      lineHeight: "8px",
                    }}
                  >
                    {formatFileSize(res?.size)}
                  </span>
                </Grid>
                <Grid
                  container
                  style={{
                    justifyContent: "flex-end",
                    alignItems: "center",
                    gap: "12px",
                  }}
                >
                  <img alt="Logo" src={PencilVectorGrey} />
                  <IconButton
                    onClick={() =>
                      setFiles([...files].filter(val => val !== res))
                    }
                  >
                    <img alt="LogoTrash" src={TrashVectorGrey} />
                  </IconButton>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
        <Grid
          style={{
            padding: "24px",
            borderTop: "1px solid #E4E7EB",
          }}
          container
          justifyContent="flex-end"
        >
          <Button
            onClick={() => {
              setDialogConfirm(false);
            }}
            className={classes.buttonWhite}
          >
            Cancel
          </Button>
          <Button onClick={handleUpload} className={classes.buttonBlack}>
            Upload
          </Button>
        </Grid>
      </CustomModal>
    </Page>
  );
};

export default Detail;
