import { Button, Divider, Grid, Typography } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { BackupOutlined } from "@material-ui/icons";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import { useRef } from "react";
import TrashVectorGrey from "../../../../assets/img/trashVectorGrey.svg";
import { hardBaseUrl } from "../../../../services/urlConstant";
const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});
const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  results: {
    marginTop: theme.spacing(3),
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  btnBack: {
    backgroundColor: "#25383c",
    color: "#fff",
    marginLeft: "10px",
    marginRight: "20px",
    textTransform: "none",
    "&.MuiButton-root:hover": {
      backgroundColor: "#25383c",
    },
  },
  btnSubmit: {
    border: "1px solid lightgrey",
    textTransform: "none",
  },
}));
const DialogUploadSong = ({
  open,
  onClose,
  onSubmit,
  getRootProps,
  getInputProps,
  isDragActive,
  files,
  handleRemoveFile,
}) => {
  const classes = useStyles();
  const dialogRef = useRef();
  const delTemp = item => {
    const myArray = files;
    delete myArray[item];
    handleRemoveFile(item);
  };
  const downloadTemplate = () => {
    const url = `${hardBaseUrl}/publisher/transaction-song/template`;
    const token = localStorage.getItem("token");
    axios
      .get(url, {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(res => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "template.xlsx");
        document.body.appendChild(link);
        link.click();
      });
  };
  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={open}
        onClose={onClose}
        aria-labelledby="dialog-upload-song"
        disableEscapeKeyDown={true}
        ref={dialogRef}
      >
        <DialogTitle id="responsive-dialog-title">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <ThemeProvider theme={theme}>
              <Typography
                style={{
                  fontSize: "16px",
                  fontWeight: 700,
                }}
              >
                {"Upload Song"}
              </Typography>
            </ThemeProvider>
            <CloseOutlinedIcon
              onClick={onClose}
              style={{
                color: "#9AA2B1",
                cursor: "pointer",
                width: "12px",
                height: "12px",
                marginTop: "8px",
              }}
            />
          </div>
        </DialogTitle>
        <Divider />
        <DialogContent>
          {}
          <div className="">
            <div {...getRootProps}>
              <input {...getInputProps()} />
              { }
              {isDragActive ? (
                <>
                  <BackupOutlined style={{ color: "grey", fontSize: "40px" }} />
                  <p>Drop the files here ...</p>
                </>
              ) : (
                <>
                  <BackupOutlined style={{ color: "grey", fontSize: "40px" }} />
                  <Typography component="p" variant="h6">
                    Upload Invoice File <b>Search File</b>
                  </Typography>
                </>
              )}
            </div>
            <div
              style={{
                color: "grey",
                marginTop: "4px",
              }}
            >
              <p>File format must be .xlsx</p>
            </div>
            <div>
              <Button
                id="add-song-outer-download"
                style={{
                  backgroundColor: "#111827",
                  borderRadius: "6px",
                  height: "40px",
                  color: "#FFFFFF",
                  marginBottom: "10px",
                  textTransform: "none",
                }}
                onClick={downloadTemplate}
              >
                Download Template
              </Button>
            </div>
            <div
              style={{
                marginTop: "20px",
              }}
            >
              <aside>
                <ThemeProvider theme={theme}>
                  <Typography
                    style={{
                      fontSize: "14px",
                      fontWeight: 500,
                      marginBottom: "10px",
                    }}
                  >
                    Document Attached
                  </Typography>
                  <ul
                    style={{
                      listStyle: "none",
                    }}
                  >
                    {files?.map((res, index) => {
                      return (
                        <Grid
                          key={`${index}`}
                          item
                          xs={12}
                          md={12}
                          lg={12}
                          xl={12}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginTop: "12px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                }}
                              >
                                <img
                                  alt="Logo"
                                  src={require("assets/image-public/images/vector-doc-icon.png").default}
                                />
                              </div>
                              <div
                                style={{
                                  marginLeft: "15px",
                                }}
                              >
                                <div>
                                  <span
                                    style={{
                                      fontFamily: "Inter",
                                      fontStyle: "normal",
                                      fontWeight: "400",
                                      fontSize: "14px",
                                      lineHeight: "20px",
                                      color: "#111827",
                                    }}
                                  >
                                    {res.name}
                                  </span>
                                </div>
                                <div>
                                  <span
                                    style={{
                                      fontFamily: "Inter",
                                      fontStyle: "normal",
                                      fontWeight: "400",
                                      fontSize: "12px",
                                      lineHeight: "18px",
                                      color: "#687083",
                                    }}
                                  >
                                    { }
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                }}
                              >
                                { }
                              </div>
                              {}
                              <div
                                onClick={() => delTemp(index)}
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  marginLeft: "14px",
                                }}
                              >
                                <img alt="LogoTrash" src={TrashVectorGrey} />
                              </div>
                            </div>
                          </div>
                        </Grid>
                      );
                    })}
                  </ul>
                </ThemeProvider>
              </aside>
            </div>
          </div>
        </DialogContent>
        <Divider className={classes.divider} />
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            marginBottom: "20px",
          }}
        >
          <Button
            className={classes.btnSubmit}
            variant="outlined"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button className={classes.btnBack} onClick={onSubmit}>
            Upload
          </Button>
        </div>
      </Dialog>
    </div>
  );
};
export default DialogUploadSong;
