export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const TOKEN_VALID = "TOKEN_VALID";
export const TOKEN_INVALID = "TOKEN_INVALID";

export const SET_MESSAGE_LOGIN_SUCCESS = "SET_MESSAGE_LOGIN_SUCCESS";
export const SET_MESSAGE_LOGIN_FAIL = "SET_MESSAGE_LOGIN_FAIL";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

export const SET_KONFIGURASI_MENU = "SET_KONFIGURASI_MENU";
export const SET_MASTER_MENU = "SET_MASTER_MENU";

export const SET_PUBLISHER_ID = "SET_PUBLISHER_ID";

export const GET_TENTANG_KAMI_TEXT = "GET_TENTANG_KAMI_TEXT";
export const GET_VISI_KAMI_TEXT = "GET_VISI_KAMI_TEXT";
export const GET_DSP_NAME = "GET_DSP_NAME";
export const GET_DSP_LOGO = "GET_DSP_LOGO";
export const SET_IMAGE_TENTANG_KAMI_1 = "SET_IMAGE_TENTANG_KAMI_1";
export const SET_IMAGE_TENTANG_KAMI_2 = "SET_IMAGE_TENTANG_KAMI_2";
export const SET_IMAGE_VISI_KAMI_1 = "SET_IMAGE_VISI_KAMI_1";
export const SET_IMAGE_VISI_KAMI_2 = "SET_IMAGE_VISI_KAMI_2";
export const SET_IMAGE_PUBLISHER_1 = "SET_IMAGE_PUBLISHER_1";
export const SET_IMAGE_PUBLISHER_2 = "SET_IMAGE_PUBLISHER_2";
export const SET_IMAGE_PUBLISHER_3 = "SET_IMAGE_PUBLISHER_3";
export const SET_IMAGE_PUBLISHER_4 = "SET_IMAGE_PUBLISHER_4";
export const SET_IMAGE_PUBLISHER_5 = "SET_IMAGE_PUBLISHER_5";
export const SET_IMAGE_PUBLISHER_6 = "SET_IMAGE_PUBLISHER_6";


export const GET_IMAGE_TENTANG_KAMI_1 = "GET_IMAGE_TENTANG_KAMI_1";
export const GET_IMAGE_TENTANG_KAMI_2 = "GET_IMAGE_TENTANG_KAMI_2";
export const GET_IMAGE_VISI_KAMI_1 = "GET_IMAGE_TENTANG_KAMI_1";
export const GET_IMAGE_VISI_KAMI_2 = "GET_IMAGE_TENTANG_KAMI_2";
export const GET_IMAGE_PUBLISHER_1 = "GET_IMAGE_TENTANG_KAMI_1";
export const GET_IMAGE_PUBLISHER_2 = "GET_IMAGE_TENTANG_KAMI_2";
export const GET_IMAGE_PUBLISHER_3 = "GET_IMAGE_TENTANG_KAMI_3";
export const GET_IMAGE_PUBLISHER_4 = "GET_IMAGE_TENTANG_KAMI_4";
export const GET_IMAGE_PUBLISHER_5 = "GET_IMAGE_TENTANG_KAMI_5";
export const GET_IMAGE_PUBLISHER_6 = "GET_IMAGE_TENTANG_KAMI_6";

export const GET_IMAGE_COVER_BERITA_PUBLISHER = "GET_IMAGE_COVER_BERITA_PUBLISHER";

export const SET_VALUE_BERITA_DAN_IKLAN = "SET_VALUE_BERITA_DAN_IKLAN";
export const DELETE_VALUE_BERITA_DAN_IKLAN = "DELETE_VALUE_BERITA_DAN_IKLAN";
export const DELETE_ALL_VALUES_BERITA_DAN_IKLAN = "DELETE_ALL_VALUES_BERITA_DAN_IKLAN";
