import { Box, Button, ButtonGroup } from "@material-ui/core";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { globalStyles } from "styles";
function GroupButtonTop({ items, onSelected, selectedPage }) {
  const classes = globalStyles();
  const history = useHistory();
  const location = useLocation();
  const { konfigurasiMenu } = useSelector(state => state?.konfigurasiMenu);
  const findMenu = konfigurasiMenu?.find(({ children }) => children?.find(item => item?.href === location?.pathname))?.children || [];
  const reMapMenu = findMenu?.map(({ href, ...props }) => ({
    link: href,
    ...props
  }));

  const usedItems = items?.length ? items : reMapMenu;
  return (
    <Box
      alignItems="flex-end"
      justifyContent="space-between"
      spacing={3}
      className={clsx(classes.groupButtonRootContainer)}
    >
      <Box className={classes.groupButtonScrollContainer}>
        <ButtonGroup aria-label="outlined primary button group">
          {usedItems?.map(({ title, id, link }) => {
            const selected =
              link === history.location.pathname || selectedPage === id;
            return (
              <Button
                className={
                  selected ? classes.groupButtonSelected : classes?.groupButton
                }
                key={id}
                id={id}
                onClick={() => {
                  console.log({ link });
                  if (!link && onSelected) {
                    onSelected(id);
                  } else {
                    history.push(link);
                  }
                }}
              >
                {title}
              </Button>
            );
          })}
        </ButtonGroup>
      </Box>
    </Box>
  );
}

GroupButtonTop.propTypes = {
  items: PropTypes.array,
};

export default GroupButtonTop;
