import manWeb from "../../../assets/img/manwebsvg.svg";
import icon_dashboard_unselected from "../../../assets/image-public/images/icon_images/icon_dashboard_unselected.svg";
import icon_dashboard from "../../../assets/image-public/images/icon_images/icon_dashboard.svg";
import icon_review_unselected from "../../../assets/image-public/images/icon_images/icon_review_unselected.svg";
import icon_master_unselected from "../../../assets/image-public/images/icon_images/icon_master_unselected.svg";
import icon_kontrak_unselected from "../../../assets/image-public/images/icon_images/icon_kontrak_unselected.svg";
import icon_berita_unselected from "../../../assets/image-public/images/icon_images/icon_berita_unselected.svg";
import reviewSelected from "../../../assets/image-public/images/icon_images/reviewSelected.svg";
import icon_konfigurasi_unselected from "../../../assets/image-public/images/icon_images/icon_konfigurasi_unselected.svg";
import kontrakSelected from "../../../assets/image-public/images/icon_images/kontrakSelected.svg";
import manajemenWebSelected from "../../../assets/image-public/images/icon_images/manajemenWebSelected.svg";
import Setting from "../../../assets/image-public/images/admin-master-logo-svg/Setting.svg";
import ProgramingData from "../../../assets/image-public/images/admin-master-logo-svg/ProgramingData.svg";
import BookOpen from "../../../assets/image-public/images/admin-master-logo-svg/BookOpen.png";

let navItemUsed = [];

navItemUsed = [
  {
    subheader: "",
    items: [
      {
        title: "Dashboard",
        href: "/pencipta/dashboard-pencipta",
        show: true,
        icon: () => (
          <img
            style={{
              width: "20px",
              marginRight: "10px",
            }}
            alt={"dashboard"}
            src={icon_dashboard_unselected}
          />
        ),
        selectedicon: () => (
          <img
            style={{
              width: "20px",
              marginRight: "10px",
            }}
            alt={"dashboard"}
            src={icon_dashboard}
          />
        ),
      },
      {
        title: "Song Usage",
        href: "/pencipta/review-lagu",
        show: true,
        icon: () => (
          <img
            style={{
              width: "20px",
              marginRight: "10px",
            }}
            alt="review"
            src={icon_review_unselected}
          />
        ),
        selectedicon: () => (
          <img
            style={{
              width: "20px",
              marginRight: "10px",
            }}
            alt="review"
            src={reviewSelected}
          />
        ),
      },
      {
        title: "Konfigurasi",
        href: "/admin/konfigurasi/personalisasi",
        show: true,
        icon: () => (
          <img
            style={{
              width: "20px",
              marginRight: "10px",
            }}
            alt="konfigurasi"
            src={icon_konfigurasi_unselected}
          />
        ),
        selectedicon: () => (
          <img
            style={{
              width: "20px",
              marginRight: "10px",
            }}
            alt="konfigurasi"
            src={Setting}
          />
        ),
      },
      {
        title: "Contract",
        href: "/pencipta/kontrak",
        show: true,
        icon: () => (
          <img
            style={{
              width: "20px",
              marginRight: "10px",
            }}
            alt="kontrak"
            src={icon_kontrak_unselected}
          />
        ),
        selectedicon: () => (
          <img
            style={{
              width: "20px",
              marginRight: "10px",
            }}
            alt="kontrak"
            src={kontrakSelected}
          />
        ),
      },
      {
        title: "Transaction",
        href: "/admin/kontrak",
        show: true,
        icon: () => (
          <img
            style={{
              width: "20px",
              marginRight: "10px",
            }}
            alt="kontrak"
            src={icon_kontrak_unselected}
          />
        ),
        selectedicon: () => (
          <img
            style={{
              width: "20px",
              marginRight: "10px",
            }}
            alt="kontrak"
            src={kontrakSelected}
          />
        ),
        items: [
          {
            title: "Payment",
            href: "/pencipta/payment",
            key: "advanced",
            show: true,
          },
          {
            title: "Loan",
            href: "/pencipta/loan",
            key: "Finalisasi Berita Iklan",
            show: true,
          },
        ],
      },
      {
        title: "Master",
        href: "/admin/master/pencipta",
        show: true,
        icon: () => (
          <img
            style={{
              width: "20px",
              marginRight: "10px",
            }}
            alt="master"
            src={icon_master_unselected}
          />
        ),
        selectedicon: () => (
          <img
            style={{
              width: "20px",
              marginRight: "10px",
            }}
            alt="master"
            src={ProgramingData}
          />
        ),
      },

      {
        title: "Berita & Iklan",
        href: "/admin/berita-publisher",
        show: true,
        icon: () => (
          <img
            style={{
              width: "20px",
              marginRight: "10px",
            }}
            alt="berita"
            src={icon_berita_unselected}
          />
        ),
        selectedicon: () => (
          <img
            style={{
              width: "20px",
              marginRight: "10px",
            }}
            alt="berita"
            src={BookOpen}
          />
        ),
      },
      {
        title: "Manajemen Web",
        href: "/admin/manajemen-web",
        show: true,
        icon: () => (
          <img
            style={{
              width: "17px",
              marginRight: "10px",
              marginLeft: "3px",
            }}
            src={manWeb}
          />
        ),
        selectedicon: () => (
          <img
            style={{
              width: "17px",
              marginRight: "10px",
              marginLeft: "3px",
            }}
            src={manajemenWebSelected}
          />
        ),
      },
    ],
  },
];

const items = navItemUsed;

export default items;
