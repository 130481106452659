import { useEffect, useState } from "react";
import { Container, Divider, Typography, Box, Grid } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import axios from "axios";
import {
  ArrayChip,
  ButtonGroupTop,
  ModalError,
  ModalSuccess,
  ModalWarning,
  Page,
  PrimaryButton,
  PrimaryIconButton,
  SectionLabel,
  SkeletonComponent,
} from "components";
import { InnoTable } from "inno-ui";
import { HeaderTitle } from "layouts";
import { useHistory } from "react-router";
import { globalStyles } from "styles";
import { getErrors, wordCapitalize } from "utils";
import EditIcon from "../../../../assets/img/pencilVector.svg";
import RefreshIcon from "../../../../assets/img/refresh-cw.svg";
import DeleteIcon from "../../../../assets/img/trashVector.svg";
import { hardBaseUrl } from "../../../../services/urlConstant";

function RoleUser() {
  const classes = globalStyles();
  const history = useHistory();
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: "Bearer " + token,
  };
  const [loadingPage, setLoadingPage] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [totalData, setTotalData] = useState(1);
  const [params, setParams] = useState({
    page: 1,
    per_page: 10,
    search: "",
    sort: ""
  });
  const { page, per_page } = params;
  const pagesCount = Math.ceil(totalData / per_page);

  const changePayload = (key, value) => setParams((state) => ({
    ...state,
    [key]: value
  }));


  const getDataTable = async (page, per_page, search, sort) => {
    try {
      setLoadingPage(true);
      const res = await axios.get(`${hardBaseUrl}/role`, {
        headers,
        params: {
          page,
          per_page,
          search,
          sort
        }
      });
      const total = res?.data?.meta?.total || 0;
      const dataTable = res?.data?.data || [];
      setTotalData(total);
      setDataTable(dataTable);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };

  const handleActivate = item => {
    const { is_active, name } = item;
    ModalWarning(
      `Are you sure to ${is_active ? "deactivate" : "restore"} role ${name} ?`,
      `${is_active ? "Deactivate" : "Restore"} Role`
    ).then(res => res?.isConfirmed && activateRole(item));
  };

  const activateRole = async item => {
    const { is_active, role_id } = item;
    try {
      await axios.put(
        `${hardBaseUrl}/role/${role_id}/status`,
        { is_active: !is_active },
        { headers }
      );
      ModalSuccess(
        `Successfully ${is_active ? "Deactivate" : "Restore"} Role`
      ).then(res => res?.isConfirmed && getDataTable());
    } catch (error) {
      ModalError(getErrors(error?.response));
    }
  };

  const actionButton = {
    name: "all",
    title: "Action",
    renderText: (item) => {
      const { role_id, is_active } = item;
      return (
        <Grid container spacing={1}>
          <Grid item>
            <PrimaryIconButton
              tooltipTitle="Edit"
              icon={<img src={EditIcon} alt="edit" />}
              onClick={() =>
                history.push(
                  `/admin/konfigurasi/role-user/edit/${role_id}`
                )
              }
            />
          </Grid>
          <Grid item>
            <PrimaryIconButton
              tooltipTitle={
                is_active ? "Deactivate" : "Restore"
              }
              icon={
                is_active ? (
                  <img src={DeleteIcon} alt="update-icon" />
                ) : (
                  <img src={RefreshIcon} alt="refresh" />
                )
              }
              onClick={() => handleActivate(item)}
            />
          </Grid>
        </Grid>
      );
    }
  };

  useEffect(() => {
    getDataTable(page, per_page);
  }, []);

  useEffect(() => {
    getDataTable(page, per_page);
  }, [page, per_page]);

  return (
    <Page className={classes.root} title="User Role">
      <Container maxWidth={false}>
        <HeaderTitle title="Configuration" breadcrumbData={breadcrumbData} />
        <ButtonGroupTop />
        <Divider className={classes.flatDivider} />
        <Box mt="16px">
          <SectionLabel title="User Role" subTitle="Display a list of a roles available in the MPIS application. Roles are made for needs of grouping users based on work functions" />
          <Box margin="10px 0px">
            <PrimaryButton
              label="Add User Roles"
              onClick={() => history.push("/admin/konfigurasi/role-user/add")}
              startIcon={<Add />}
              width={200}
            />
          </Box>
          <Box my="16px">
            {loadingPage ? (
              <SkeletonComponent variant="wave" />
            ) : (
              <InnoTable
                isLoading={false}
                columns={[actionButton, ...columnTable]}
                items={dataTable}
                handleChangePage={(_, data) => changePayload("page", data)}
                handleChangeRowsPerPage={(event) => changePayload("per_page", event?.target?.value)}
                page={page}
                rowsPerPage={per_page}
                totalPage={pagesCount}
              />
            )}
          </Box>
        </Box>
      </Container>

    </Page>
  );
}

const breadcrumbData = [
  {
    label: "Configuration",
    link: "/admin/konfigurasi/role-user",
  },
  {
    label: "User Role",
    active: true,
  },
];
const columnTable = [
  {
    name: "name",
    title: "Role Name",
  },
  {
    name: "type",
    title: "Role Type",
    renderText: (type) => wordCapitalize(type)
  },
  {
    name: "applications",
    title: "Access Type",
    renderText: (access_type) => <Typography>{access_type}</Typography>
  },
  {
    name: "permissions",
    title: "Access Right",
    renderText: item => <ArrayChip list={item} />,
  },
];
export default RoleUser;
