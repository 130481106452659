import axios from "axios";
import { hardBaseUrl } from "./urlConstant";
import { clearCookie } from "../utils/constants";
import { API_ENDPOINTS } from "constants/endpoint";

const API_URL = hardBaseUrl;

const tokenInfo = token => {
  return axios.get(API_ENDPOINTS.GET_ME, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

const register = (username, email, password) => {
  return axios.post(API_URL + "signup", {
    username,
    email,
    password,
  });
};

const logout = () => {
  clearCookie();
  localStorage.removeItem("user");
};

export default {
  register,
  logout,
  tokenInfo,
};
