import {
  Breadcrumbs,
  Button,
  Container,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@material-ui/core";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";
import { makeStyles } from "@material-ui/styles";
import { useState } from "react";
import { Page } from "components";
import Results from "./Results";
const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});
const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  results: {
    marginTop: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  formControl: {},
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  pagination: {
    "& .Mui-selected": {
      backgroundColor: "black",
      color: "white",
    },
  },
  pageSize: {
    height: "30px",
    borderRadius: "6px",
    border: "1px solid #D1D5DC",
    paddingLeft: "5px",
    paddingRight: "5px",
    marginTop: "5px",
  },
  paginationText: {
    fontSize: "14px",
    fontWeight: "normal",
    fontStyle: "normal",
    color: "#687083",
  },
  breadcrumbs: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#111827",
  },
  breadCrumbsActive: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#687083",
  },
  placeholder: {
    "&::placeholder": {
      color: "#9AA2B1",
    },
  },
}));
const ClaimPage = () => {
  const classes = useStyles();
  const composerOptions = "";
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const totalData = 1;
  const [page, setPage] = useState(1);
  const response = [
    {
      loan_id: 1,
      composer_name: "Tulus",
      loan_date: "5 July 2020",
      loan_tenor: "1 month",
      loan_value: "Rp. 1.000.000",
      is_active_flag: true,
      address: "Jl. Setia Budi, RT 05 RW 05, Karet Kuningan, South Jakarta",
      img: "https://pbs.twimg.com/media/Cgzp3W1UoAA3psU.jpg",
      request_loan: "PT. AQUARIUS PUSTAKA MUSIK",
      notes:
        " The pain itself is love, the main storage system. For example, my deck needs a lot of fun. But always as a team. Tortor tincidunt bow The pain is not as tough as the arrows. But tomorrow there will not be a cartoon but the members and the environment.",
      loan_array: [
        {
          id: 1,
          subject: "Request Loan",
          tenor: "1 month",
          value: "Rp. 1.000.000",
        },
      ],
      total_loan: "Rp. 1.000.000",
      request_date: "January, 29 2020",
    },
    {
      loan_id: 2,
      composer_name: "Ari Lasso",
      loan_date: "5 July 2020",
      loan_tenor: "2 month",
      loan_value: "Rp. 1.000.000",
      is_active_flag: false,
      address: "Jl. Setia Budi, RT 05 RW 05, Karet Kuningan, South Jakarta",
      img:
        "https://pbs.twimg.com/profile_images/378800000787283895/901ba40fe2aab13f232e69b293a31e90_400x400.jpeg",
      request_loan: "PT. AQUARIUS PUSTAKA MUSIK",
      notes:
        " The pain itself is love, the main storage system. For example, my deck needs a lot of fun. But always as a team. Tortor tincidunt bow The pain is not as tough as the arrows. But tomorrow there will not be a cartoon but the members and the environment.",
      loan_array: [
        {
          id: 1,
          subject: "Request Loan",
          tenor: "1 month",
          value: "Rp. 1.000.000",
        },
      ],
      total_loan: "Rp. 1.000.000",
      request_date: "January, 29 2020",
    },
    {
      loan_id: 3,
      composer_name: "Didi Kempot",
      loan_date: "5 July 2020",
      loan_tenor: "1 month",
      loan_value: "Rp. 1.000.000",
      is_active_flag: true,
      address: "Jl. Setia Budi, RT 05 RW 05, Karet Kuningan, South Jakarta",
      img:
        "https://cdn.dribbble.com/users/5384669/screenshots/11604043/didi_kempot_1x.jpg",
      request_loan: "PT. AQUARIUS PUSTAKA MUSIK",
      notes:
        " The pain itself is love, the main storage system. For example, my deck needs a lot of fun. But always as a team. Tortor tincidunt bow The pain is not as tough as the arrows. But tomorrow there will not be a cartoon but the members and the environment.",
      loan_array: [
        {
          id: 1,
          subject: "Request Loan",
          tenor: "1 month",
          value: "Rp. 1.000.000",
        },
      ],
      total_loan: "Rp. 1.000.000",
      request_date: "January, 29 2020",
    },
    {
      loan_id: 4,
      composer_name: "Deddy Dukun",
      loan_date: "5 July 2020",
      loan_tenor: "1 month",
      loan_value: "Rp. 1.000.000",
      is_active_flag: false,
      address: "Jl. Setia Budi, RT 05 RW 05, Karet Kuningan, South Jakarta",
      img: "https://i.scdn.co/image/ab67616d0000b273b97a8f9c007f407aad7c7d4d",
      request_loan: "PT. AQUARIUS PUSTAKA MUSIK",
      notes:
        " The pain itself is love, the main storage system. For example, my deck needs a lot of fun. But always as a team. Tortor tincidunt bow The pain is not as tough as the arrows. But tomorrow there will not be a cartoon but the members and the environment.",
      loan_array: [
        {
          id: 1,
          subject: "Request Loan",
          tenor: "1 month",
          value: "Rp. 1.000.000",
        },
      ],
      total_loan: "Rp. 1.000.000",
      request_date: "January, 29 2020",
    },
  ];
  const pagesCount = Math.ceil(totalData / rowsPerPage);
  const handleChangeRowsPerPage = event => {
    event.preventDefault();
    setRowsPerPage(event.target.value);
    setPage(1);
  };
  const pageSizePagination = () => {
    return (
      <div className={classes.pageSize}>
        <select
          style={{
            backgroundColor: "transparent",
            border: "none",
            outline: "none",
            marginTop: "4px",
          }}
          value={rowsPerPage}
          onChange={handleChangeRowsPerPage}
        >
          {[10, 20, 50].map(pageSize => {
            return (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            );
          })}
        </select>
      </div>
    );
  };
  const handleChangePage = (event, data) => {
    setPage(data);
  };
  return (
    <Page className={classes.root} title="Claim">
      <Container maxWidth={false}>
        <Grid
          alignItems="flex-end"
          container
          justifyContent="space-between"
          spacing={3}
        >
          <Grid item>
            <ThemeProvider theme={theme}>
              <Typography
                style={{
                  fontSize: "24px",
                  fontWeight: "700",
                  lineHeight: "32px",
                }}
              >
                Claim
              </Typography>
            </ThemeProvider>
          </Grid>
          <Grid item>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography className={classes.breadCrumbs}>Home</Typography>
              <Typography className={classes.breadCrumbs}>
                Transaction
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
            }}
          >
            <>
              <Button
                href="/admin/transaction/claim/add"
                style={{
                  backgroundColor: "#111827",
                  color: "white",
                  padding: "10px 28px 10px 28px",
                  marginRight: "10px",
                  width: "300px",
                }}
                variant="contained"
                size="large"
                startIcon={<AddIcon />}
              >
                <ThemeProvider theme={theme}>
                  <Typography
                    component="span"
                    style={{
                      fontSize: "14px",
                      lineHeight: "20px",
                      fontWeight: "500",
                      fontStyle: "normal",
                      textTransform: "none",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Add Invoice
                  </Typography>
                </ThemeProvider>
              </Button>
            </>
            <FormControl
              fullWidth
              className={classes.margin}
              variant="outlined"
            >
              <OutlinedInput
                placeholder="Pencarian"
                id="outlined-adornment-amount"
                style={{
                  height: "44px",
                  border: "1px solid #D1D5DC",
                  borderRadius: "6px",
                }}
                inputProps={{
                  classes: {
                    input: classes.placeholder,
                  },
                }}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon style={{ color: "#9AA2B1" }} />
                  </InputAdornment>
                }
                fullWidth={false}
              />
            </FormControl>
          </div>
          <div>
            <FormControl
              variant="outlined"
              className={classes.formControl}
              fullWidth={true}
            >
              <InputLabel
                id="demo-simple-select-outlined-label"
                shrink={false}
                style={{
                  marginTop: "-5px",
                }}
              >
                {composerOptions !== "" ? null : "Composer"}
              </InputLabel>
              <Select
                style={{
                  width: "180px",
                  height: "44px",
                  border: "1px solid #D1D5DC",
                  borderRadius: "6px",
                }}
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={composerOptions}
                autoWidth={true}
              >
                <MenuItem value={""}>All Composer</MenuItem>
                <MenuItem value={"layanan"}>Layanan</MenuItem>
                <MenuItem value={"berita"}>Berita</MenuItem>
                <MenuItem value={"iklan"}>Iklan</MenuItem>
                <MenuItem value={"edukasi"}>Edukasi</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        <Divider className={classes.divider} />
        {response && (
          <Results
            className={classes.results}
            items={response}
            pageSizePagination={pageSizePagination()}
            totalData={pagesCount}
            page={page}
            handleChangePage={handleChangePage}
          />
        )}
      </Container>
    </Page>
  );
};
export default ClaimPage;
