import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  styled,
} from "@mui/material";
import { getCookie } from "utils";

const RadioButtonInput = ({
  label,
  options,
  optionKey,
  optionLabel,
  value,
  onChange,
  size,
  color,
}) => {
  const userLogin = getCookie("auth", `${process.env.REACT_APP_NAME}_user`);
  return (
    <FormControl>
      <FormLabel>{label}</FormLabel>
      <RadioGroup value={value} onChange={onChange}>
        {options?.map((option, index) => (
          <FormControlLabel
            key={index}
            value={option?.[optionKey]}
            control={
              <CustomRadio
                size={size}
                customColor={color || userLogin?.publisher?.theme_color}
              />
            }
            label={option?.[optionLabel]}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

const CustomRadio = styled(Radio)(({ customColor }) => ({
  "&.MuiRadio-root": {
    "&.Mui-checked": {
      color: customColor,
    },
  },
}));

export default RadioButtonInput;
