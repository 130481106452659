import { Container, Divider } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { Box, Grid, styled, Typography } from "@mui/material";
import axios from "axios";
import {
  FormLabel,
  ModalBankAccount,
  ModalError,
  ModalSuccess,
  NumberInput,
  Page,
  PasswordInput,
  PrimaryButton,
  SectionLabel,
  SelectInput,
  SkeletonComponent,
  TableAction,
  TextInput,
} from "components";
import { InnoTableV2 } from "inno-ui";
import { HeaderTitle } from "layouts";
import { useEffect, useState } from "react";
import { globalStyles } from "styles";
import { getErrors } from "utils";
import { hardBaseUrl } from "../../services/urlConstant";
import { getCookie } from "../../utils/constants";

function ProfilePencipta() {
  const classes = globalStyles();
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const [loadingPage, setLoadingPage] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [payload, setPayload] = useState({
    sure_name: "",
    email: "",
    phone_number: "",
    alias_name: "",
    npwp: "",
    nik: "",
    composer_banks: [],
  });
  const [selectedPublisherId, setSelectedPublisherId] = useState("");
  const [optionPublisher, setOptionPublisher] = useState([]);
  const [modalFormVisible, setModalFormVisible] = useState(false);
  const [optionBank, setOptionBank] = useState([]);
  const [preload, setPreload] = useState(null);
  const [formPassword, setFormPassword] = useState({
    old_password: "",
    new_password: "",
    confirm_password: "",
  });

  const handleChangePayload = (value, key) => {
    setPayload(currentState => ({
      ...currentState,
      [key]: value,
    }));
  };
  const handleChangeFormPassword = (value, key) => {
    setFormPassword(currentState => ({
      ...currentState,
      [key]: value,
    }));
  };
  const handleCloseModal = (_, reason) => {
    if (reason && (reason === "backdropClick" || reason === "escapeKeyDown")) {
      return;
    }
    setModalFormVisible(false);
    setPreload(null);
  };
  const validatePassword = () => {
    const errors = [];
    const errorEmpty = err => `${err} can't be empty.`;
    const errorMinLength = (err, minLength) =>
      `${err} must be at least ${minLength} characters long.`;

    Object.entries(formPassword).forEach(([key, value]) => {
      switch (key) {
        case "old_password":
          if (!value) errors.push(errorEmpty("Old Password"));
          break;
        case "new_password":
          if (!value) {
            errors.push(errorEmpty("New Password"));
          } else if (value.length < 8) {
            errors.push(errorMinLength("New Password", 8));
          }
          break;
        case "confirm_password":
          if (!value) errors.push(errorEmpty("Confirm New Password"));
          break;
        default:
          break;
      }
    });
    if (formPassword?.new_password !== formPassword?.confirm_password) {
      errors.push("New password didn't match");
    }

    return errors;
  };

  const getComposerDetail = async () => {
    try {
      const { data } = await axios.get(`${hardBaseUrl}/composer/get-profile`, {
        headers,
      });
      const {
        sure_name,
        alias_name,
        email,
        phone,
        npwp,
        nik,
        composer_banks,
      } = data?.data;
      setPayload({
        sure_name,
        alias_name,
        email,
        npwp,
        nik,
        phone_number: phone,
        composer_banks,
      });
      localStorage.setItem("composer_bank", JSON.stringify(composer_banks));
    } catch (error) {
      ModalError(getErrors(error?.response));
    }
  };
  const getPublisherDetail = async () => {
    try {
      const res = await axios.get(`${hardBaseUrl}/current/publisher`, {
        headers,
      });
      setSelectedPublisherId(res?.data?.data?.publisher_id);
    } catch (error) {
      ModalError(getErrors(error?.response));
    }
  };
  const getOptionPublisher = async () => {
    try {
      const res = await axios.get(`${hardBaseUrl}/list/publisher`, {
        headers,
      });
      setOptionPublisher(res?.data?.data);
    } catch (error) {
      ModalError(getErrors(error?.response));
    }
  };
  const getOptionBank = async () => {
    try {
      const res = await axios.get(
        `${hardBaseUrl}/composer/get-profile/bank-option`,
        { headers }
      );
      setOptionBank(res.data.data.banks);
    } catch (error) {
      ModalError(getErrors(error?.response));
    }
  };
  const fetchData = async () => {
    setLoadingPage(true);
    const promises = [];
    promises.push(getPublisherDetail());
    promises.push(getComposerDetail());
    promises.push(getOptionPublisher());
    promises.push(getOptionBank());
    try {
      await Promise.all(promises);
    } catch (error) {
      ModalError(error, "Error fetching data");
    } finally {
      setLoadingPage(false);
    }
  };
  const handleChangePublisher = async () => {
    try {
      setLoadingPage(true);
      await axios.put(
        `${hardBaseUrl}/change/publisher`,
        { publisher_id: selectedPublisherId },
        { headers }
      );
      ModalSuccess("Succesfully update publisher").then(() => fetchData());
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  const handleSubmit = async () => {
    const { composer_banks, ...restPayload } = payload;
    const bank = (composer_banks || []).map(
      ({ bank, account_name, account_number }) => ({
        account_name,
        account_number,
        bank_id: bank?.bank_id,
      })
    );
    const modifiedPayload = {
      ...restPayload,
      bank,
    };
    try {
      const res = await axios.put(
        `${hardBaseUrl}/composer/update-profile`,
        modifiedPayload,
        { headers }
      );
      ModalSuccess(res.data.message).then(() => fetchData());
    } catch (error) {
      ModalError(getErrors(error?.response));
    }
  };
  const handleSubmitBank = async payload => {
    const method = preload ? axios.put : axios.post;
    try {
      setLoadingButton(true);
      await method(
        `${hardBaseUrl}/composer/profile/composer-bank${
          preload ? `/${preload?.composer_bank_id}` : ""
        }`,
        payload,
        { headers }
      );
      handleCloseModal();
      ModalSuccess(`Successfully ${preload ? "update" : "add"} bank`).then(() =>
        fetchData()
      );
    } catch (error) {
      handleCloseModal();
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingButton(false);
    }
  };
  const handleDeleteBank = async item => {
    try {
      await axios.delete(
        `${hardBaseUrl}/composer/profile/composer-bank/${item?.composer_bank_id}`,
        { headers }
      );
      ModalSuccess("Successfully delete bank").then(() => fetchData());
    } catch (error) {
      ModalError(getErrors(error?.response));
    }
  };
  const handleChangePassword = async () => {
    const { old_password, new_password } = formPassword;
    const modifiedPayload = {
      token,
      old_password,
      new_password,
    };
    const errors = validatePassword();
    if (errors?.length) {
      ModalError(errors[0]);
      return;
    }
    try {
      await axios.post(`${hardBaseUrl}/auth/reset-password`, modifiedPayload, {
        headers,
      });
      ModalSuccess("Successfully Reset Password").then(() => fetchData());
    } catch (error) {
      ModalError(getErrors(error?.response));
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Page className={classes.root} title="Profile">
      {loadingPage ? (
        <SkeletonComponent variant="wave" />
      ) : (
        <Container maxWidth={false}>
          <HeaderTitle title="Profile" breadcrumbData={breadcrumbData} />
          <Divider className={classes.divider} />
          <SectionLabel
            title="Change Publisher"
            subTitle="Change your Publisher."
          />
          <FormCard>
            <FormLabel label="Publisher" />
            <SelectInput
              options={optionPublisher}
              optionKey="publisher_id"
              optionLabel="name"
              value={selectedPublisherId}
              onChange={e => setSelectedPublisherId(e?.target?.value)}
              width="35%"
            />
          </FormCard>
          <Grid container justifyContent="right" m="16px 0 24px">
            <PrimaryButton
              label="Save Changes"
              onClick={handleChangePublisher}
            />
          </Grid>
          <Divider className={classes?.divider} />
          <SectionLabel
            title="Composer Profile"
            subTitle="Customize content to remind creators with time the contract will expire."
          />
          <FormCard>
            <Grid container columnSpacing={2} rowSpacing={1}>
              <Grid item xs={12} md={6}>
                <FormLabel label="Composer Name" />
                <TextInput
                  placeholder="Composer Name"
                  value={payload?.sure_name}
                  onChange={e =>
                    handleChangePayload(e?.target?.value, "sure_name")
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormLabel label="Alias Name" />
                <TextInput
                  placeholder="Alias Name"
                  value={payload?.alias_name}
                  onChange={e =>
                    handleChangePayload(e?.target?.value, "alias_name")
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormLabel label="Email" />
                <TextInput
                  placeholder="Email"
                  value={payload?.email}
                  onChange={e => handleChangePayload(e?.target?.value, "email")}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormLabel label="Phone Number" />
                <NumberInput
                  placeholder="Phone Number"
                  value={payload?.phone_number}
                  onChange={e =>
                    handleChangePayload(e?.target?.value, "phone_number")
                  }
                  startAdornment="+62"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormLabel label="NPWP" />
                <TextInput
                  placeholder="NPWP"
                  value={payload?.npwp}
                  onChange={e => handleChangePayload(e?.target?.value, "npwp")}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormLabel label="NIK" />
                <TextInput
                  placeholder="NIK"
                  value={payload?.nik}
                  onChange={e => handleChangePayload(e?.target?.value, "nik")}
                />
              </Grid>
            </Grid>
          </FormCard>
          <Grid container justifyContent="right" m="16px 0 24px">
            <PrimaryButton label="Save Changes" onClick={handleSubmit} />
          </Grid>
          <Divider className={classes?.divider} />
          <Grid container justifyContent="space-between" my="16px">
            <Grid item>
              <SectionLabel
                title="Bank Account"
                subTitle="Customize composer bank account."
              />
            </Grid>
            <Grid item>
              <PrimaryButton
                label="Add Bank Account"
                startIcon={<Add />}
                onClick={() => setModalFormVisible(true)}
              />
            </Grid>
          </Grid>
          <InnoTableV2
            isLoading={false}
            columns={columnTableBank}
            items={payload?.composer_banks}
            isHaveAction
            renderAction={item => (
              <TableAction
                handleEdit={() => {
                  setModalFormVisible(true);
                  setPreload(item);
                }}
                handleDelete={handleDeleteBank}
                deleteConfirmation
                deleteConfirmationKey={`account number ${item?.account_number}`}
                deleteConfirmationTitle="Delete Bank"
              />
            )}
          />
          <Divider className={classes?.divider} />
          <SectionLabel
            title="Change Password"
            subTitle="Change the password of your account."
          />
          <FormCard>
            <Grid container columnSpacing={2} rowSpacing={2}>
              <Grid xs={12} md={6} item>
                <FormLabel label="Old Password" required />
                <PasswordInput
                  value={formPassword?.old_password}
                  onChange={e =>
                    handleChangeFormPassword(e?.target?.value, "old_password")
                  }
                  placeholder="Old Password"
                />
              </Grid>
              <Grid item xs={0} md={6} />
              <Grid xs={12} md={6} item>
                <FormLabel label="New Password" required />
                <PasswordInput
                  value={formPassword?.new_password}
                  onChange={e =>
                    handleChangeFormPassword(e?.target?.value, "new_password")
                  }
                  placeholder="New Password"
                />
              </Grid>
              <Grid xs={12} md={6} item>
                <FormLabel label="Confirm New Password" required />
                <PasswordInput
                  value={formPassword?.confirm_password}
                  onChange={e =>
                    handleChangeFormPassword(
                      e?.target?.value,
                      "confirm_password"
                    )
                  }
                  placeholder="Confirm New Password"
                />
              </Grid>
              <Grid item xs={12}>
                <SectionLabel subTitle="Minimum 8 characters" />
              </Grid>
            </Grid>
          </FormCard>
          <Grid container justifyContent="right" m="16px 0 24px">
            <PrimaryButton
              label="Save Changes"
              onClick={handleChangePassword}
            />
          </Grid>
          <Divider className={classes?.divider} />
          <SectionLabel title="CARIS Version" mb="16px" />
          <InnoTableV2
            columns={columnTableVersion}
            items={dataTableVersion}
            isLoading={false}
          />
          <Divider className={classes?.divider} />
        </Container>
      )}
      <ModalBankAccount
        open={modalFormVisible}
        onClose={handleCloseModal}
        preload={preload}
        optionBank={optionBank}
        handleSubmit={handleSubmitBank}
        loadingButton={loadingButton}
      />
    </Page>
  );
}

const FormCard = styled(Box)({
  border: "1px solid #ebebeb",
  borderRadius: "5px",
  padding: "24px",
  margin: "16px 0",
});
const breadcrumbData = [
  {
    label: "Home",
    link: "/pencipta/dashboard-pencipta",
  },
  {
    label: "Composer Profile",
    active: true,
  },
];
const columnTableBank = [
  {
    name: "bank",
    title: "Bank",
    renderText: item => (
      <img alt="bank" src={item?.profile_image} height="30px" />
    ),
  },
  {
    name: "account_number",
    title: "Account Number",
  },
  {
    name: "account_name",
    title: "Name",
  },
];
const columnTableVersion = [
  {
    name: "name",
    title: "Version",
    renderText: item => (
      <Typography fontSize={14} fontWeight="bold">
        {item}
      </Typography>
    ),
  },
  {
    name: "version",
    title: "",
  },
];
const dataTableVersion = [
  {
    name: "Front End Version",
    version: getCookie("authVersion", `${process.env.REACT_APP_NAME}_fe`),
  },
  {
    name: "Back End Version",
    version: getCookie("authVersion", `${process.env.REACT_APP_NAME}_be`),
  },
];
export default ProfilePencipta;
