import {
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  Chip,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import React, { useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useHistory } from "react-router";
import { Link as RouterLink, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import PencilVectorGrey from "../../../../assets/img/pencilVectorGrey.svg";
import TrashVectorGrey from "../../../../assets/img/trashVectorGrey.svg";
import CustomModal from "../../../../components/molecules/Modal/CustomModal";
import { Page } from "components";
import { hardBaseUrl } from "../../../../services/urlConstant";
import { formatFileSize } from "../../../../utils";

const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const interFont = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),

    paddingBottom: theme.spacing(3),
  },

  results: {
    marginTop: theme.spacing(3),
  },

  divider: {
    margin: theme.spacing(2, 0),

    borderTop: "1px solid #e1e1e1",
  },

  formControl: {},

  selectEmpty: {
    marginTop: theme.spacing(2),
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,

    color: "#fff",
  },

  pagination: {
    "& .Mui-selected": {
      backgroundColor: "black",

      color: "white",
    },
  },

  pageSize: {
    height: "30px",

    borderRadius: "6px",

    border: "1px solid #D1D5DC",

    paddingLeft: "5px",

    paddingRight: "5px",

    marginTop: "5px",
  },

  paginationText: {
    fontSize: "14px",

    fontWeight: "normal",

    fontStyle: "normal",

    color: "#687083",
  },

  breadcrumbs: {
    fontSize: "14px",

    fontWeight: 400,

    fontStyle: "regular",

    color: "#111827",
  },

  breadCrumbsActive: {
    fontSize: "14px",

    fontWeight: 400,

    fontStyle: "regular",

    color: "#687083",
  },

  placeholder: {
    "&::placeholder": {
      color: "#9AA2B1",
    },
  },

  subTitle: {
    fontSize: "18px",

    fontWeight: 700,

    color: " #111827",
  },

  headText: {
    fontSize: "12px",

    color: "#687083",

    fontWeight: "bold",
  },

  ib: {
    padding: "4px",

    color: "white",

    backgroundColor: "black",

    borderRadius: "6px",

    "&:focus": {
      color: "white",

      backgroundColor: "black",
    },

    "&:active": {
      boxShadow: "none",

      color: "white",

      backgroundColor: "black",
    },

    "&:hover": {
      color: "white",

      backgroundColor: "black",

      boxShadow: "none",
    },
  },
}));

const DetailLoan = props => {
  const classes = useStyles();

  const history = useHistory();

  const { state } = useLocation();

  const [composerName, setComposerName] = useState("");

  const [date, setDate] = useState("");

  const [publisherAddress, setPublisherAddress] = useState("");
  const [publisherImages, setPublisherImages] = useState("");

  const [note, setNote] = useState("");

  const [revenue, setRevenue] = useState("");
  const [tenor, setTenor] = useState("");

  const [openModal, setOpenModal] = useState(false);
  const [claimStatus, setClaimStatus] = useState("");

  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [openModalReject, setOpenModalReject] = useState(false);
  const [modalPayment, setModalPayment] = useState(false);
  const [files, setFiles] = useState([]);
  const [itemsActive] = useState([]);

  const handleOpenModalReject = () => {
    setOpenModalReject(true);
  };
  const handleCloseReject = () => {
    setOpenModalReject(false);
  };
  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleClose = () => {
    setOpenModal(false);
  };
  const handleOpenModalConfirm = () => {
    setModalPayment(true);
  };
  const handleCloseConfirm = () => {
    setModalPayment(false);
  };

  const getResponse = () => {
    const url = `${hardBaseUrl}/publisher/transaction/detail/${state.customer.publisher_transaction_id}`;
    const theToken = localStorage.getItem("token");
    axios

      .get(url, {
        headers: {
          Authorization: `Bearer ${theToken}`,
        },
      })

      .then(res => {
        setComposerName(res.data.data.composer_name);

        setDate(res.data.data.invoice_date);

        setPublisherAddress(res.data.data.publisher.address);

        setNote(res.data.data.note);

        setRevenue(res.data.data.revenue);
        setClaimStatus(res.data.data.status);
        setTenor(state.customer.tenor);
        setPublisherImages(res.data.data.publisher.profile_image);
      })

      .catch(() => {});
  };

  useEffect(() => {
    getResponse();
  }, []);

  const rendComp = (role, status) => {
    if (role === "finance") {
      if (status === "waiting_for_approval") {
        return (
          <>
            <Button
              style={{
                borderRadius: "6px",
                width: "84px",
                height: "40px",
                border: "1px solid #D1D5DC",
                textTransform: "none",
              }}
              onClick={handleOpenModalReject}
            >
              Reject
            </Button>
            <Button
              style={{
                marginLeft: "10px",
                backgroundColor: "#111827",
                borderRadius: "6px",
                width: "84px",
                height: "40px",
                color: "#FFFFFF",
                textTransform: "none",
              }}
              onClick={handleOpenModal}
            >
              Approve
            </Button>
          </>
        );
      } else if (status === "approved") {
        return (
          <>
            <Button
              style={{
                marginLeft: "10px",
                backgroundColor: "#111827",
                borderRadius: "6px",
                width: "84px",
                height: "40px",
                color: "#FFFFFF",
                textTransform: "none",
              }}
              onClick={handleOpenModalConfirm}
            >
              Confirm
            </Button>
          </>
        );
      } else if (status === "rejected") {
        return <>{""}</>;
      } else if (status === "active") {
        return (
          <>
            <Grid container>
              <Grid item xs={12} md={8} lg={8} xl={8}>
                <Card>
                  <CardContent className={classes.content}>
                    <PerfectScrollbar>
                      <div className={classes.inner}>
                        <Table size="small">
                          <TableHead>
                            <TableRow>
                              <ThemeProvider theme={theme}>
                                <TableCell>
                                  <Typography
                                    variant="subtitle2"
                                    className={classes.headText}
                                  >
                                    Installment Number
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography
                                    variant="subtitle2"
                                    className={classes.headText}
                                  >
                                    Due Date
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography
                                    variant="subtitle2"
                                    className={classes.headText}
                                  >
                                    Installment Value
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography
                                    variant="subtitle2"
                                    className={classes.headText}
                                  >
                                    Status
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography
                                    variant="subtitle2"
                                    className={classes.headText}
                                  >
                                    Action
                                  </Typography>
                                </TableCell>
                              </ThemeProvider>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {itemsActive?.length > 0 ? (
                              itemsActive.map(customer => (
                                <TableRow
                                  hover
                                  key={customer.publisher_transaction_id}
                                >
                                  <ThemeProvider theme={theme}>
                                    <TableCell>
                                      <Typography
                                        variant="subtitle2"
                                        className={classes.bodyTextBlack}
                                      >
                                        Ins Number
                                      </Typography>
                                    </TableCell>
                                    <TableCell>Due Date</TableCell>
                                    <TableCell>
                                      <Typography
                                        variant="subtitle2"
                                        className={classes.bodyTextBlack}
                                      >
                                        Ins Value
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography
                                        variant="subtitle2"
                                        className={classes.bodyTextBlack}
                                      >
                                        Status
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Tooltip
                                        title={"Update"}
                                        placement="bottom"
                                      >
                                        <IconButton
                                          className={classes.btnDetail}
                                        >
                                          <RouterLink>
                                            <VisibilityOutlinedIcon
                                              style={{
                                                filter:
                                                  " brightness(0) invert(1)",
                                                marginTop: "10px",
                                              }}
                                            />
                                          </RouterLink>
                                        </IconButton>
                                      </Tooltip>
                                    </TableCell>
                                  </ThemeProvider>
                                </TableRow>
                              ))
                            ) : (
                              <>
                                <TableRow>
                                  <TableCell colSpan={6}>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <Typography variant="subtitle1">
                                        No data available
                                      </Typography>
                                    </div>
                                  </TableCell>
                                </TableRow>
                              </>
                            )}
                          </TableBody>
                        </Table>
                      </div>
                    </PerfectScrollbar>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </>
        );
      } else if (status === "paid") {
        return <>{""}</>;
      }
    } else {
      return <>{""}</>;
    }
  };

  const chipColor = status => {
    if (status === "paid") {
      return "#8DE5AB";
    } else if (status === "unpaid") {
      return "#ff000010";
    } else if (status === "waiting_for_approval") {
      return "#F6C962";
    } else if (status === "approved") {
      return "#EFFCF4";
    } else if (status === "rejected") {
      return "#FCF3F2";
    } else if (status === "active") {
      return "#EFFCF4";
    } else if (status === "paid") {
      return "#EFFCF4";
    }
  };
  const chipText = status => {
    if (status === "paid") {
      return "#34774C";
    } else if (status === "unpaid") {
      return "#A63124";
    } else if (status === "waiting_for_approval") {
      return "#A84D20";
    } else if (status === "approved") {
      return "#34774C";
    } else if (status === "rejected") {
      return "#A63124";
    } else if (status === "active") {
      return "#34774C";
    } else if (status === "paid") {
      return "#34774C";
    }
  };

  const chipLabel = status => {
    if (status === "paid") {
      return "Paid";
    } else if (status === "unpaid") {
      return "Unpaid";
    } else if (status === "waiting_for_approval") {
      return "Waiting for Approval";
    } else if (status === "approved") {
      return "Approved";
    } else if (status === "rejected") {
      return "Rejected";
    } else if (status === "active") {
      return "Active";
    } else if (status === "paid") {
      return "Paid";
    }
  };

  const updateStatus = statusToGo => {
    if (statusToGo === "approved") {
      const url = `${hardBaseUrl}/transaction/proof/${state.customer.publisher_transaction_id}`;

      const theToken = localStorage.getItem("token");

      const formData = new FormData();
      formData.append("status", "approved");

      axios
        .put(url, formData, {
          headers: {
            Authorization: `Bearer ${theToken}`,
          },
        })
        .then(res => {
          setOpenModal(false);
          if (
            res.data.message === "success" &&
            res.data.meta.http_status === 200
          ) {
            Swal.fire({
              title: "Success",

              text: "Updated",

              icon: "success",

              confirmButtonText: "Ok",
            }).then(result => {
              if (result.value) {
                history.push("/admin/loan");
              }
            });
          }
        })
        .catch(() => {});
    } else if (statusToGo === "rejected") {
      const url = `${hardBaseUrl}/transaction/proof/${state.customer.publisher_transaction_id}`;

      const theToken = localStorage.getItem("token");

      const formData = new FormData();
      formData.append("status", "rejected");

      axios
        .put(url, formData, {
          headers: {
            Authorization: `Bearer ${theToken}`,
          },
        })
        .then(res => {
          if (
            res.data.message === "success" &&
            res.data.meta.http_status === 200
          ) {
            setOpenModalReject(false);
            Swal.fire({
              title: "Success",

              text: "Rejected",

              icon: "success",

              confirmButtonText: "Ok",
            }).then(result => {
              if (result.value) {
                history.push("/admin/loan");
              }
            });
          }
        })
        .catch(() => {});
    }
  };

  const uploadProof = () => {};

  const handleFiles = e => {
    const data = [];
    Array.from(e.target.files).forEach(file => {
      data.push(file);
    });
    setFiles(data);
  };

  const handleUpload = () => {
    setModalPayment(false);
    const formData = new FormData();
    formData.append("status", "active");
    files.map(val => {
      formData.append("file_name", val);
    });
    const url = `${hardBaseUrl}/transaction/proof/${state.customer.publisher_transaction_id}`;
    const theToken = localStorage.getItem("token");
    axios
      .put(url, formData, {
        headers: {
          Authorization: `Bearer ${theToken}`,
        },
      })
      .then(response => {
        Swal.fire({
          icon: response?.data?.message === "success" ? "success" : "error",
          title: response?.data?.message === "success" ? "Success" : "Error",
          text: response?.data?.message,
        }).then(() => {
          history.push("/admin/loan");
        });
      })
      .catch(() => {});
  };
  const handleChangeNotes = event => {
    setNote(event.target.value);
  };
  return (
    <Page className={classes.root} title="Loan">
      <Container maxWidth={false}>
        <Grid
          alignItems="flex-end"
          container
          justifyContent="space-between"
          spacing={3}
        >
          <Grid item>
            <ThemeProvider theme={theme}>
              <Typography
                style={{
                  fontSize: "24px",

                  fontWeight: "700",

                  lineHeight: "32px",
                }}
              >
                Loan Transaction
              </Typography>
            </ThemeProvider>
          </Grid>

          <Grid item>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography className={classes.breadCrumbs}>Home</Typography>

              <Typography className={classes.breadCrumbs}>
                Transaction
              </Typography>

              <Typography className={classes.breadCrumbsActive}>
                Detail Transaction
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>

        <Divider className={classes.divider} />

        <Grid container>
          <Grid item xs={12} md={8} lg={8} xl={8}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={6} xl={6}>
                <div>
                  <ThemeProvider theme={theme}>
                    <Typography
                      component="h1"
                      variant="h3"
                      className={classes.subTitle}
                    >
                      Loan Detail
                    </Typography>
                  </ThemeProvider>
                  <ThemeProvider theme={theme}>
                    <p
                      style={{
                        marginTop: "5px",
                        color: "#8f8f8f",
                        fontSize: "14px",
                      }}
                    >
                      View details of the loan.
                    </p>
                  </ThemeProvider>
                </div>
              </Grid>
              <Grid item xs={12} md={6} lg={6} xl={6}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Chip
                    label={chipLabel(claimStatus)}
                    style={{
                      backgroundColor: chipColor(claimStatus),
                      border: "1px thin #fff",
                      color: chipText(claimStatus),
                    }}
                  />
                </div>
              </Grid>
            </Grid>
            <Card
              style={{
                border: "1px solid #9AA2B1",

                borderRadius: "6px",

                boxShadow: "none",
              }}
            >
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6} lg={6} xl={6}>
                    <img
                      src={publisherImages}
                      alt="img"
                      style={{
                        width: "155.52px",
                        height: "80px",
                        objectFit: "contain",
                      }}
                    />
                    {}
                  </Grid>

                  <Grid item xs={12} md={6} lg={6} xl={6}>
                    <div
                      style={{
                        textAlign: "right",
                      }}
                    >
                      <div>
                        <ThemeProvider theme={theme}>
                          <Typography
                            style={{
                              fontSize: "14px",

                              fontWeight: "600",

                              lineHeight: "20px",
                            }}
                          >
                            {publisherAddress}
                          </Typography>
                        </ThemeProvider>
                      </div>
                    </div>
                  </Grid>
                </Grid>

                <Divider className={classes.divider} />

                <Grid container spacing={3}>
                  <Grid item xs={12} md={6} lg={6} xl={6}>
                    <ThemeProvider theme={theme}>
                      <Typography
                        style={{
                          fontSize: "14px",

                          fontWeight: "400",

                          lineHeight: "16.94px",
                        }}
                      >
                        Give Loan to:
                      </Typography>

                      <Typography
                        style={{
                          fontSize: "14px",

                          fontWeight: "600",

                          lineHeight: "16.94px",
                        }}
                      >
                        {composerName}
                      </Typography>
                    </ThemeProvider>
                  </Grid>

                  <Grid item xs={12} md={6} lg={6} xl={6}>
                    <ThemeProvider theme={theme}>
                      <div>
                        <div
                          style={{
                            display: "flex",

                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            style={{
                              fontSize: "14px",

                              fontWeight: "400",

                              lineHeight: "16.94px",
                            }}
                          >
                            Date:
                          </Typography>

                          <Typography
                            style={{
                              fontSize: "14px",

                              fontWeight: "400",

                              lineHeight: "16.94px",
                            }}
                          >
                            {date || "-"}
                          </Typography>
                        </div>

                        <div
                          style={{
                            display: "flex",

                            justifyContent: "space-between",

                            marginTop: "5px",
                          }}
                        >
                          <Typography
                            style={{
                              fontSize: "14px",

                              fontWeight: "400",

                              lineHeight: "16.94px",
                            }}
                          >
                            Composer/Author:
                          </Typography>

                          <Typography
                            style={{
                              fontSize: "14px",

                              fontWeight: "400",

                              lineHeight: "16.94px",
                            }}
                          >
                            {composerName || "-"}
                          </Typography>
                        </div>
                      </div>

                      <div
                        style={{
                          marginTop: "10px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",

                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            style={{
                              fontSize: "14px",

                              fontWeight: "400",

                              lineHeight: "16.94px",
                            }}
                          >
                            Tenor:
                          </Typography>

                          <Typography
                            style={{
                              fontSize: "14px",

                              fontWeight: "400",

                              lineHeight: "16.94px",
                            }}
                          >
                            {tenor || "-"}
                          </Typography>
                        </div>

                        <div
                          style={{
                            display: "flex",

                            justifyContent: "space-between",

                            marginTop: "5px",
                          }}
                        >
                          <Typography
                            style={{
                              fontSize: "14px",

                              fontWeight: "400",

                              lineHeight: "16.94px",
                            }}
                          >
                            Loan Value:
                          </Typography>

                          <Typography
                            style={{
                              fontSize: "14px",

                              fontWeight: "400",

                              lineHeight: "16.94px",
                            }}
                          >
                            {`Rp ${revenue},-` || "-"}
                          </Typography>
                        </div>
                      </div>
                    </ThemeProvider>
                  </Grid>
                </Grid>

                <Divider className={classes.divider} />

                <Grid container>
                  <div>
                    <ThemeProvider theme={theme}>
                      <Typography
                        style={{
                          fontSize: "14px",

                          fontWeight: "600",
                        }}
                      >
                        Notes:
                      </Typography>
                      {state.customer.status === "active" ? (
                        <>
                          <div
                            style={{
                              marginTop: "10px",

                              marginBottom: "10px",
                            }}
                          >
                            <TextField
                              disabled={false}
                              value={note}
                              id="outlined-basic"
                              name="notes"
                              variant="outlined"
                              fullWidth={true}
                              multiline
                              rows={4}
                              margin="dense"
                              type="text"
                              onChange={event => handleChangeNotes(event)}
                              placeholder="Notes"
                            />
                          </div>
                        </>
                      ) : (
                        <>
                          <Typography
                            style={{
                              fontSize: "14px",

                              fontWeight: "400",
                            }}
                          >
                            {state.customer.notes}
                          </Typography>
                        </>
                      )}
                    </ThemeProvider>
                  </div>
                </Grid>

                <div
                  style={{
                    marginTop: "20px",
                  }}
                >
                  <ThemeProvider theme={theme}>
                    <Typography
                      style={{
                        fontSize: "14px",

                        fontWeight: "400",

                        textAlign: "right",
                      }}
                    >
                      Total Loan:
                    </Typography>

                    <Typography
                      style={{
                        fontSize: "24px",

                        fontWeight: "600",

                        textAlign: "right",
                      }}
                    >
                      {`Rp ${revenue},-` || "-"}
                    </Typography>
                  </ThemeProvider>
                </div>
              </CardContent>
            </Card>

            <Grid
              container
              justifyContent="flex-end"
              style={{
                marginTop: "20px",
              }}
            >
              {rendComp(props.rolesFor, claimStatus)}

              <React.Fragment>
                <Dialog
                  open={openModalConfirm}
                  onClose={handleCloseConfirm}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    <ThemeProvider theme={interFont}>
                      <Typography
                        style={{
                          fontWeight: 700,

                          fontSize: "16px",

                          lineHeight: "24px",
                        }}
                      >
                        {"Upload Payment Proof"}
                      </Typography>
                    </ThemeProvider>
                  </DialogTitle>

                  <DialogContent>
                    <div>Upload an</div>
                    <div>Document Attached</div>
                  </DialogContent>

                  <DialogActions>
                    <Button
                      onClick={() => {
                        setOpenModalConfirm(false);
                      }}
                      style={{
                        height: "40px",

                        width: "90px",

                        borderRadius: "6px",

                        border: "1px solid #D1D5DC",

                        color: "#111827",

                        backgroundColor: "white",

                        textTransform: "none",
                      }}
                    >
                      Cancel
                    </Button>

                    <Button
                      onClick={() => {
                        uploadProof();
                      }}
                      autoFocus
                      style={{
                        height: "40px",

                        width: "90px",

                        borderRadius: "6px",

                        border: "0px solid #D1D5DC",

                        color: "white",

                        backgroundColor: "#111827",

                        textTransform: "none",
                      }}
                    >
                      Upload
                    </Button>
                  </DialogActions>
                </Dialog>
              </React.Fragment>

              <React.Fragment>
                <Dialog
                  open={openModal}
                  onClose={handleClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    <ThemeProvider theme={interFont}>
                      <Typography
                        style={{
                          fontWeight: 700,

                          fontSize: "16px",

                          lineHeight: "24px",
                        }}
                      >
                        {"Approve Loan Request"}
                      </Typography>
                    </ThemeProvider>
                  </DialogTitle>

                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      {"Are you sure to approve this loan request?"}
                    </DialogContentText>
                  </DialogContent>

                  <DialogActions>
                    <Button
                      onClick={() => {
                        setOpenModal(false);
                      }}
                      style={{
                        height: "40px",

                        width: "90px",

                        borderRadius: "6px",

                        border: "1px solid #D1D5DC",

                        color: "#111827",

                        backgroundColor: "white",

                        textTransform: "none",
                      }}
                    >
                      Cancel
                    </Button>

                    <Button
                      onClick={() => {
                        updateStatus("approved");
                      }}
                      autoFocus
                      style={{
                        height: "40px",

                        width: "90px",

                        borderRadius: "6px",

                        border: "0px solid #D1D5DC",

                        color: "white",

                        backgroundColor: "#111827",

                        textTransform: "none",
                      }}
                    >
                      Approve
                    </Button>
                  </DialogActions>
                </Dialog>
              </React.Fragment>

              <React.Fragment>
                <Dialog
                  open={openModalReject}
                  onClose={handleCloseReject}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    <ThemeProvider theme={interFont}>
                      <Typography
                        style={{
                          fontWeight: 700,

                          fontSize: "16px",

                          lineHeight: "24px",
                        }}
                      >
                        {"Reject Loan Request"}
                      </Typography>
                    </ThemeProvider>
                  </DialogTitle>

                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      {"Are you sure to reject this loan request?"}
                    </DialogContentText>
                  </DialogContent>

                  <DialogActions>
                    <Button
                      onClick={() => {
                        setOpenModalReject(false);
                      }}
                      style={{
                        height: "40px",

                        width: "90px",

                        borderRadius: "6px",

                        border: "1px solid #D1D5DC",

                        color: "#111827",

                        backgroundColor: "white",

                        textTransform: "none",
                      }}
                    >
                      Cancel
                    </Button>

                    <Button
                      onClick={() => {
                        updateStatus("rejected");
                      }}
                      autoFocus
                      style={{
                        height: "40px",

                        width: "90px",

                        borderRadius: "6px",

                        border: "0px solid #D1D5DC",

                        color: "white",

                        backgroundColor: "#111827",

                        textTransform: "none",
                      }}
                    >
                      Reject
                    </Button>
                  </DialogActions>
                </Dialog>
              </React.Fragment>

              <React.Fragment>
                <CustomModal
                  open={modalPayment}
                  handleClose={() => {
                    setModalPayment(false);
                  }}
                  width="460px"
                  noPadding
                >
                  <Grid
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      borderBottom: "1px solid #E4E7EB",
                      padding: "20px",
                    }}
                  >
                    <h1
                      style={{
                        fontWeight: 700,
                        fontSize: "20px",
                        lineHeight: "30px",
                      }}
                    >
                      Upload Payment Proof
                    </h1>
                    <IconButton
                      style={{ padding: 0 }}
                      onClick={() => setModalPayment(false)}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                  <Grid
                    style={{
                      padding: "24px",
                    }}
                  >
                    <Grid
                      style={{
                        border: "1px dashed #9AA2B1",
                        width: "100%",
                        paddingTop: "16px",
                        paddingBottom: "16px",
                        textAlign: "center",
                        backgroundColor: "#F9FAFB",
                        borderRadius: "8px",
                        position: "relative",
                      }}
                    >
                      <input
                        type="file"
                        multiple
                        onChange={handleFiles}
                        style={{
                          opacity: 0.0,
                          position: "absolute",
                          top: 0,
                          left: 0,
                          bottom: 0,
                          right: 0,
                          width: "100%",
                          height: "100%",
                        }}
                      />
                      <img
                        alt="Logo"
                        src={
                          require("assets/image-public/images/vector-upload-trans.png")
                            .default
                        }
                      />
                      <h3
                        style={{
                          fontWeight: 400,
                          fontSize: "14px",
                          lineHeight: "20px",
                          marginTop: "8px",
                        }}
                      >
                        Upload Proof Of Payment, <strong>Search File</strong>{" "}
                      </h3>
                    </Grid>
                  </Grid>
                  <Grid
                    style={{
                      padding: "24px",
                    }}
                  >
                    <h3>Document Attached</h3>
                    {files.map((res, i) => {
                      return (
                        <Grid
                          key={i}
                          style={{
                            marginTop: "12px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <img
                            alt="Logo"
                            src={
                              require("assets/image-public/images/vector-doc-icon.png")
                                .default
                            }
                            style={{
                              width: "24px",
                              height: "24px",
                              objectFit: "contain",
                            }}
                          />
                          <Grid
                            style={{
                              fontWeight: 400,
                              fontSize: "14px",
                              width: "100%",
                              paddingLeft: "16px",
                            }}
                          >
                            <p
                              style={{
                                lineHeight: "20px",
                                margin: 0,
                                marginBottom: "4px",
                              }}
                            >
                              {res?.name}
                            </p>
                            <span
                              style={{
                                color: "#687083",
                                lineHeight: "8px",
                              }}
                            >
                              {formatFileSize(res?.size)}
                            </span>
                          </Grid>
                          <Grid
                            container
                            style={{
                              justifyContent: "flex-end",
                              alignItems: "center",
                              gap: "12px",
                            }}
                          >
                            {}
                            <img alt="Logo" src={PencilVectorGrey} />
                            <IconButton
                              onClick={() =>
                                setFiles([...files].filter(val => val !== res))
                              }
                            >
                              <img alt="LogoTrash" src={TrashVectorGrey} />
                            </IconButton>
                          </Grid>
                        </Grid>
                      );
                    })}
                  </Grid>
                  <Grid
                    style={{
                      padding: "24px",
                      borderTop: "1px solid #E4E7EB",
                    }}
                    container
                    justifyContent="flex-end"
                  >
                    <Button
                      onClick={() => {
                        setModalPayment(false);
                      }}
                      className={classes.buttonWhite}
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={handleUpload}
                      className={classes.buttonBlack}
                    >
                      Upload
                    </Button>
                  </Grid>
                </CustomModal>
              </React.Fragment>
            </Grid>
          </Grid>

          <Grid item xs={12} md={4} lg={4} xl={4}></Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default DetailLoan;
