import {
  Breadcrumbs,
  Button,
  Container,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { Page } from "components";
import { hardBaseUrl } from "../../../services/urlConstant";
import Results from "./Results";

const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  results: {
    marginTop: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  formControl: {},
  newFormControl: {
    backgroundColor: "#111827",
    borderRadius: "6px",
    color: "#FFFFFF",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  pagination: {
    "& .Mui-selected": {
      backgroundColor: "black",
      color: "white",
    },
  },
  pageSize: {
    height: "30px",
    borderRadius: "6px",
    border: "1px solid #D1D5DC",
    paddingLeft: "5px",
    paddingRight: "5px",
    marginTop: "5px",
  },
  paginationText: {
    fontSize: "14px",
    fontWeight: "normal",
    fontStyle: "normal",
    color: "#687083",
  },
  breadcrumbs: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#111827",
  },
  breadCrumbsActive: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#687083",
  },
  placeholder: {
    "&::placeholder": {
      color: "#9AA2B1",
    },
  },
  FieldInput: {
    border: "1px solid #FFFFFF",
    borderRadius: "4px",
    width: "200px",
    "& input": {},
  },
  mypadding2: {
    "& .MuiSelect-outlined": {
      paddingTop: "15.5px",
      paddingBottom: "10.5px",
    },
    width: "200px",
  },
  balance: {
    margin: 0,
    "&:hover": {
      cursor: "pointer",
      color: "blue",
    },
  },
}));

const PlatformShare = () => {
  const classes = useStyles();

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalData, setTotalData] = useState(42);
  const [page, setPage] = useState(1);
  const [response, setResponse] = useState([]);
  const [selectedComp] = useState({});
  const [dataSearch, setDataSearch] = useState("");
  const [dataSummary, setDataSummary] = useState({});
  const [dataSummaryAdv, setDataSummaryAdv] = useState({});
  const [statusFilter, setStatusFilter] = useState("");
  const listStatus = [
    "Years",
    "Approved",
    "Paid",
    "Unpaid",
    "Waiting for Approval",
  ];
  const [loadingTable, setLoadingTable] = useState(false);

  const getDateFormatForFilterStartTemp = () => {
    const fff = moment()
      .subtract(3, "months")
      .format("YYYY-M-D");
    return fff;
  };

  const getDateFormatForFilter = date => {
    let dateFormat = new Date(date);
    let day = dateFormat
      .getDate()
      .toString()
      .padStart(2, "0");
    let month = (dateFormat.getMonth() + 1).toString().padStart(2, "0");
    let year = dateFormat.getFullYear();
    let dateString = `${year}-${month}-${day}`;

    return dateString;
  };

  const [startPeriodValue] = useState(
    getDateFormatForFilterStartTemp(Date.now())
  );
  const [endPeriodValue] = useState(getDateFormatForFilter(Date.now()));

  const pagesCount = Math.ceil(totalData / rowsPerPage);

  const handleChangeStatusFilter = ({ target }) => {
    let status = target.value;
    let values =
      status === "Approved"
        ? "approved"
        : status === "Paid"
        ? "paid"
        : status === "Unpaid"
        ? "unpaid"
        : status === "Waiting for Approval"
        ? "waiting_for_approval"
        : "";
    setStatusFilter(values);
    setPage(1);
  };

  const handleChangeRowsPerPage = event => {
    event.preventDefault();
    setRowsPerPage(event.target.value);

    setPage(1);
  };

  const pageSizePagination = () => {
    return (
      <div className={classes.pageSize}>
        <select
          style={{
            backgroundColor: "transparent",
            border: "none",
            outline: "none",
            marginTop: "4px",
          }}
          value={rowsPerPage}
          onChange={handleChangeRowsPerPage}
        >
          {[10, 20, 50].map(pageSize => {
            return (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            );
          })}
        </select>
      </div>
    );
  };

  const handleChangePage = (event, data) => {
    setPage(data);
  };

  const getDataTable = () => {
    setLoadingTable(true);
    const token = localStorage.getItem("token");

    const url = `${hardBaseUrl}/publisher/transaction/payment?page=${page}&size=${rowsPerPage}&search=${dataSearch}&composer_id=${selectedComp?.composer_id ||
      ""}&status=${statusFilter}&filter_start_date=${startPeriodValue}&filter_end_date=${endPeriodValue}`;

    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(res => {
        setTotalData(res.data.meta.total || 1);
        setResponse(res.data.data);
      })
      .catch(() => {})
      .finally(() => {
        setLoadingTable(false);
      });
  };

  const getDataSummary = () => {
    const token = localStorage.getItem("token");
    let choosComp = selectedComp;
    if (typeof choosComp === "undefined") {
      choosComp = "";
    }
    const url = `${hardBaseUrl}/balance/composer?composer_id=${selectedComp?.composer_id ||
      ""}&start_date=${startPeriodValue}&end_date=${endPeriodValue}`;

    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(res => {
        setDataSummary(res.data.data);
      })
      .catch(() => {});
  };

  const getDataSummaryAdv = () => {
    const token = localStorage.getItem("token");
    const url = `${hardBaseUrl}/payment/composer/advance?composer_id=${selectedComp?.composer_id ||
      ""}&start_date=${startPeriodValue}&end_date=${endPeriodValue}`;

    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(res => {
        setDataSummaryAdv(res.data.data);
      })
      .catch(() => {});
  };

  useEffect(() => {
    getDataTable();
    getDataSummary();
    getDataSummaryAdv();
  }, [
    selectedComp,
    dataSearch,
    rowsPerPage,
    page,
    statusFilter,
    endPeriodValue,
    startPeriodValue,
  ]);

  const handleChangeSearch = event => {
    setDataSearch(event.target.value);
    setPage(1);
  };

  return (
    <Page className={classes.root} title="Payment">
      <Container maxWidth={false}>
        <Grid
          alignItems="flex-end"
          container
          justifyContent="space-between"
          spacing={3}
        >
          <Grid item>
            <ThemeProvider theme={theme}>
              <Typography
                style={{
                  fontSize: "24px",
                  fontWeight: "700",
                  lineHeight: "32px",
                }}
              >
                Platform Share
              </Typography>
            </ThemeProvider>
          </Grid>
          <Grid item>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography className={classes.breadCrumbs}>Home</Typography>
              <Typography className={classes.breadCrumbsActive}>
                Platform Share
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>

        <Divider className={classes.divider} />

        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              <span
                style={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "16px",
                  lineHeight: "24px",
                  color: "#687083",
                }}
              >
                Feature to view Publisher&apos;s must paid bill.
              </span>
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
          }}
        >
          <div
            style={{
              marginTop: "16px",
              border: "1px solid #D1D5DC",
              borderRadius: "10px",
              padding: "16px",
              width: "340px",
              height: "100px",
              backgroundColor: "#111827",
              position: "relative",
            }}
          >
            <img
              style={{
                position: "absolute",
                right: 40,
              }}
              src={
                require("assets/image-public/images/rectangle_opacity.png")
                  .default
              }
              alt=""
            />
            <img
              style={{
                position: "absolute",
                right: -20,
                height: "64px",
              }}
              src={
                require("assets/image-public/images/rectangle_opacity.png")
                  .default
              }
              alt=""
            />
            <img
              style={{
                position: "absolute",
                right: -2,
                height: "64px",
                top: -15,
              }}
              src={
                require("assets/image-public/images/rectangle_opacity.png")
                  .default
              }
              alt=""
            />
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div>
                <img
                  alt="Logo"
                  src={
                    require("assets/image-public/images/icon_images/ri_money-dollar-circle-fill.svg")
                      .default
                  }
                />
              </div>
              <div
                style={{
                  marginLeft: "18px",
                }}
              >
                <div>
                  <span
                    style={{
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: "14px",
                      lineHeight: "20px",
                      color: "#9AA2B1",
                    }}
                  >
                    Total Revenue
                  </span>
                </div>
                <div
                  style={{
                    marginTop: "8px",
                  }}
                >
                  <span
                    style={{
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: "700",
                      fontSize: "24px",
                      lineHeight: "32px",
                      color: "white",
                    }}
                  >
                    {}
                    Rp
                    {dataSummary?.total
                      ?.toString()
                      .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
                    ,-
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              marginTop: "16px",
              marginLeft: "8px",
              border: "1px solid #D1D5DC",
              borderRadius: "10px",
              padding: "16px",
              width: "340px",
              height: "100px",
              backgroundColor: "#111827",
              position: "relative",
            }}
          >
            <img
              style={{
                position: "absolute",
                right: 40,
              }}
              src={
                require("assets/image-public/images/rectangle_opacity.png")
                  .default
              }
              alt=""
            />
            <img
              style={{
                position: "absolute",
                right: -20,
                height: "64px",
              }}
              src={
                require("assets/image-public/images/rectangle_opacity.png")
                  .default
              }
              alt=""
            />
            <img
              style={{
                position: "absolute",
                right: -2,
                height: "64px",
                top: -15,
              }}
              src={
                require("assets/image-public/images/rectangle_opacity.png")
                  .default
              }
              alt=""
            />
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div>
                <img
                  alt="Logo"
                  src={
                    require("assets/image-public/images/icon_images/ri_money-dollar-circle-fill.svg")
                      .default
                  }
                />
              </div>
              <div
                style={{
                  marginLeft: "18px",
                }}
              >
                <div>
                  <span
                    style={{
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: "14px",
                      lineHeight: "20px",
                      color: "#9AA2B1",
                    }}
                  >
                    Composer Payment
                  </span>
                </div>
                <div
                  style={{
                    marginTop: "8px",
                  }}
                >
                  <span
                    style={{
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: "700",
                      fontSize: "24px",
                      lineHeight: "32px",
                      color: "white",
                    }}
                  >
                    Rp
                    {dataSummaryAdv?.total
                      ?.toString()
                      .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
                    ,-
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              marginTop: "16px",
              marginLeft: "8px",
              border: "1px solid #D1D5DC",
              borderRadius: "10px",
              padding: "16px",
              width: "340px",
              height: "100px",
              backgroundColor: "#111827",
              position: "relative",
            }}
          >
            <img
              style={{
                position: "absolute",
                right: 40,
              }}
              src={
                require("assets/image-public/images/rectangle_opacity.png")
                  .default
              }
              alt=""
            />
            <img
              style={{
                position: "absolute",
                right: -20,
                height: "64px",
              }}
              src={
                require("assets/image-public/images/rectangle_opacity.png")
                  .default
              }
              alt=""
            />
            <img
              style={{
                position: "absolute",
                right: -2,
                height: "64px",
                top: -15,
              }}
              src={
                require("assets/image-public/images/rectangle_opacity.png")
                  .default
              }
              alt=""
            />
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div>
                <img
                  alt="Logo"
                  src={
                    require("assets/image-public/images/icon_images/ri_money-dollar-circle-fill.svg")
                      .default
                  }
                />
              </div>
              <div
                style={{
                  marginLeft: "18px",
                }}
              >
                <div>
                  <span
                    style={{
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: "14px",
                      lineHeight: "20px",
                      color: "#9AA2B1",
                    }}
                  >
                    Platform Share
                  </span>
                </div>
                <div
                  style={{
                    marginTop: "8px",
                  }}
                >
                  <span
                    style={{
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: "700",
                      fontSize: "24px",
                      lineHeight: "32px",
                      color: "white",
                    }}
                  >
                    {}
                    Rp
                    {dataSummary?.total
                      ?.toString()
                      .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
                    ,-
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              marginTop: "16px",
              marginLeft: "8px",
              border: "1px solid #D1D5DC",
              borderRadius: "10px",
              padding: "16px",
              width: "340px",
              height: "100px",
              backgroundColor: "#111827",
              position: "relative",
            }}
          >
            <img
              style={{
                position: "absolute",
                right: 40,
              }}
              src={
                require("assets/image-public/images/rectangle_opacity.png")
                  .default
              }
              alt=""
            />
            <img
              style={{
                position: "absolute",
                right: -20,
                height: "64px",
              }}
              src={
                require("assets/image-public/images/rectangle_opacity.png")
                  .default
              }
              alt=""
            />
            <img
              style={{
                position: "absolute",
                right: -2,
                height: "64px",
                top: -15,
              }}
              src={
                require("assets/image-public/images/rectangle_opacity.png")
                  .default
              }
              alt=""
            />
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div>
                <img
                  alt="Logo"
                  src={
                    require("assets/image-public/images/icon_images/ri_money-dollar-circle-fill.svg")
                      .default
                  }
                />
              </div>
              <div
                style={{
                  marginLeft: "18px",
                }}
              >
                <div>
                  <span
                    style={{
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: "14px",
                      lineHeight: "20px",
                      color: "#9AA2B1",
                    }}
                  >
                    Paid
                  </span>
                </div>
                <div
                  style={{
                    marginTop: "8px",
                  }}
                >
                  <span
                    style={{
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: "700",
                      fontSize: "24px",
                      lineHeight: "32px",
                      color: "white",
                    }}
                  >
                    Rp
                    {dataSummaryAdv?.total
                      ?.toString()
                      .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
                    ,-
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            marginTop: "24px",
            display: "flex",
            alignItems: "center",
          }}
          onClick={() => (window.location.href = "/admin/payment-mutation")}
        >
          <h1 className={classes.balance}>
            <b>2023</b>
          </h1>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "20px",
          }}
        >
          <div
            style={{
              display: "flex",
            }}
          >
            <Button
              href="/admin/platform-share/add"
              style={{
                backgroundColor: "#111827",
                color: "white",
                padding: "10px 28px 10px 28px",
                marginRight: "10px",
                width: "300px",
                height: "42px",
              }}
              variant="contained"
              size="large"
              startIcon={<AddIcon />}
            >
              <ThemeProvider theme={theme}>
                <Typography
                  component="span"
                  style={{
                    fontSize: "14px",
                    lineHeight: "20px",
                    fontWeight: "500",
                    fontStyle: "normal",
                    textTransform: "none",
                    whiteSpace: "nowrap",
                  }}
                >
                  Create Payment
                </Typography>
              </ThemeProvider>
            </Button>

            <FormControl
              fullWidth
              className={classes.margin}
              variant="outlined"
            >
              <OutlinedInput
                placeholder="Search"
                id="outlined-adornment-amount"
                style={{
                  width: "200px",
                  height: "44px",
                  border: "1px solid #D1D5DC",
                  borderRadius: "6px",
                }}
                onChange={event => handleChangeSearch(event)}
                inputProps={{
                  classes: {
                    input: classes.placeholder,
                  },
                }}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon style={{ color: "#9AA2B1" }} />
                  </InputAdornment>
                }
                fullWidth={false}
              />
            </FormControl>
          </div>

          <div
            style={{
              display: "flex",
            }}
          >
            <FormControl
              variant="outlined"
              style={{
                marginLeft: "10px",
              }}
              fullWidth={true}
            >
              <Select
                className={classes.mypadding2}
                id="demo-simple-select-outlined"
                onChange={handleChangeStatusFilter}
                autoWidth={true}
                defaultValue={listStatus[0]}
              >
                {listStatus.map((item, idx) => (
                  <MenuItem value={item} key={idx}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
        <Divider className={classes.divider} />

        {response && (
          <Results
            className={classes.results}
            items={response}
            pageSizePagination={pageSizePagination()}
            totalData={pagesCount}
            page={page}
            handleChangePage={handleChangePage}
            isLoading={loadingTable}
          />
        )}
      </Container>
    </Page>
  );
};

export default PlatformShare;
