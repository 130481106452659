import { Button } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import LockIcon from "@material-ui/icons/Lock";
import Axios from "axios";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import CARIS from "../../assets/img/newCarisLogo.webp";
import InputPassword from "../../components/atoms/Input/InputPassword";
import { hardBaseUrl } from "../../services/urlConstant";

export default function InvitationPassword(params) {
  let history = useHistory();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [tokenExp, setTokenExp] = useState(false);

  useEffect(() => {
    const checkToken = async () => {
      const url = `${hardBaseUrl}/check-token-composer-invitation`;
      let payload = {
        token: `${params.match.params.token}`,
      };
      const res = await Axios.post(url, payload);
      setTokenExp(res.data.data.is_token_valid);
    };
    checkToken();
  }, []);

  const handleChangePassword = e => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Password anda tidak sama",
      });
    } else {
      Swal.fire({
        title: "Apakah anda yakin?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Confirm",
      }).then(result => {
        if (result.isConfirmed) {
          Axios.post(
            `${hardBaseUrl}/auth/invitation/reset-password`,
            {
              token: `${params.match.params.token}`,
              new_password: newPassword,
            },
            {
              headers: {},
            }
          ).then(() => {
            Swal.fire("Sukses!", "Sukses simpan password.", "success").then(
              newresult => {
                if (newresult.isConfirmed) {
                  history.push("/login");
                }
              }
            );
          });
        }
      });
    }
  };

  return (
    <>
      {tokenExp ? (
        <>
          <div
            style={{
              height: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "grid",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img
                  src={CARIS}
                  alt="caris"
                  style={{
                    width: "22%",
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "32px",
                }}
              >
                Settings Your Password
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "12px",
                  color: "gray",
                }}
              >
                Settings your password to continue the invitation
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  color: "gray",
                }}
              >
                progres
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "16px",
                  color: "gray",
                  marginTop: "12px",
                }}
              >
                <form onSubmit={handleChangePassword}>
                  <InputPassword
                    onChange={e => setNewPassword(e.target.value)}
                    type="password"
                    fullWidth
                    placeholder="Password"
                    variant="outlined"
                    style={{
                      marginTop: "10px",
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LockIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <InputPassword
                    onChange={e => setConfirmPassword(e.target.value)}
                    type="password"
                    fullWidth
                    placeholder="Confirm Password"
                    variant="outlined"
                    style={{
                      marginTop: "10px",
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LockIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      fontSize: "16px",
                      color: "gray",
                    }}
                  >
                    <Button
                      type="submit"
                      variant="contained"
                      style={{
                        textTransform: "none",
                        backgroundColor: "black",
                        color: "white",
                        marginTop: "20px",
                        width: "100%",
                      }}
                    >
                      Save Password
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            style={{
              height: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "grid",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img
                  src={CARIS}
                  alt="caris"
                  style={{
                    width: "30%",
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "32px",
                }}
              >
                Link Already Expired
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "16px",
                  color: "gray",
                  marginTop: "12px",
                }}
              >
                the link you got has expired, please resend it to get
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "16px",
                  color: "gray",
                }}
              >
                the latest link
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
