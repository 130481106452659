export const getSubdomain = () => {
  const hostname = window.location.hostname;
  const parts = hostname.split(".");
  if (
    parts[0] === "nadaku" ||
    parts[0] === "musica" ||
    parts[0] === "aquarius" ||
    parts[0] === "mymusic" ||
    parts[0] === "argaswarakencanamusik" ||
    parts[0] === "jagaddhita" ||
    parts[0] === "epublishing" ||
    parts[0] === "karyaanakhoki" ||
    parts[0] === "ciptasimphoniindah" ||
    parts[0] === "prodigi" ||
    parts[0] === "alfapustaka" ||
    parts[0] === "gmpublishing" ||
    parts[0] === "sci" ||
    parts[0] === "mahakaryabagus" ||
    parts[0] === "jk-records" ||
    parts[0] === "indosemar" ||
    parts[0] === "27musik" ||
    parts[0] === "limaduabelas" ||
    parts[0] === "rpm" ||
    parts[0] === "gpmi" ||
    parts[0] === "damarlangit" ||
    parts[0] === "tbw" ||
    parts[0] === "harmonidwiselaras" ||
    parts[0] === "harmoni" ||
    parts[0] === "jawara" ||
    parts[0] === "starcipta" ||
    parts[0] === "logiskreatifpublisherindo" ||
    parts[0] === "maharpustakanusantara"
  ) {
    localStorage.setItem("subDomain", parts[0]);
    localStorage.setItem("typeWeb", "caris");
    return parts[0];
  } else {
    localStorage.setItem("subDomain", "mpis");
    localStorage.setItem("typeWeb", "mpis");
  }
  return "mpis";
};

export default { getSubdomain };
