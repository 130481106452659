import { SET_VALUE_BERITA_DAN_IKLAN, DELETE_ALL_VALUES_BERITA_DAN_IKLAN, DELETE_VALUE_BERITA_DAN_IKLAN } from "../../../constants/types";

const initialState = {
    title: "",
    type: "",
    content: "",
    recentDate: "",
    publisherName: "",
    adminName: "",
    image_logo: ""
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_VALUE_BERITA_DAN_IKLAN:
            return {
                ...state,
                ...action.payload,
            };
        case DELETE_ALL_VALUES_BERITA_DAN_IKLAN:
            return initialState;
        case DELETE_VALUE_BERITA_DAN_IKLAN:
            {
                let newState = { ...state };
                action.payload.forEach(key => {
                    if (newState[key] !== undefined) {
                        newState[key] = "";
                    }
                });
                return newState;
            }
        default:
            return state;
    }
}
