import { Container, Divider } from "@material-ui/core";
import { Box, Grid, Typography } from "@mui/material";
import axios from "axios";
import {
  AttachmentFilesCard,
  DateTimeDisplay,
  ModalError,
  Page,
  SectionLabel,
  SkeletonComponent,
} from "components";
import { HeaderTitle } from "layouts";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { globalStyles } from "styles";
import { getErrors, wordCapitalize } from "utils";
import "../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { hardBaseUrl } from "../../../../services/urlConstant";

const DetailAnnouncementManagement = () => {
  const classes = globalStyles();
  const { id } = useParams();
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const [loadingPage, setLoadingPage] = useState(false);
  const [pageDetail, setPageDetail] = useState({});
  const isEmail = pageDetail?.type === "email";
  const announcementFiles = pageDetail?.announcement_files;

  const getDetail = async () => {
    try {
      setLoadingPage(true);
      const res = await axios.get(
        `${hardBaseUrl}/admin/annoucement/detail/${id}`,
        { headers }
      );
      setPageDetail(res?.data?.data);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };

  useEffect(() => {
    getDetail();
  }, []);
  return (
    <Page className={classes.root} title="Announcement Detail">
      {loadingPage ? (
        <SkeletonComponent variant="wave" />
      ) : (
        <Container maxWidth={false}>
          <HeaderTitle
            title="Announcement Detail"
            breadcrumbData={breadcrumbData}
            backButton
          />
          <Divider className={classes.divider} />
          <SectionLabel
            title="Announcement Management Type"
            subTitle={
              isEmail ? "Email Announcement" : "Announcement Website Content"
            }
          />
          <Box border="1px solid #ebebeb" borderRadius="5px" p="24px" mt="16px">
            <Grid container rowSpacing={2}>
              <Grid item xs={6}>
                <Typography fontWeight={600}>
                  {isEmail ? "Email Delivery Time" : "Start Date"}
                </Typography>
                <DateTimeDisplay
                  date={
                    isEmail
                      ? pageDetail?.email_delivery_time
                      : pageDetail?.web_start_date
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <Typography fontWeight={600}>
                  {isEmail ? "Recipient" : "End Date"}{" "}
                </Typography>
                {isEmail ? (
                  wordCapitalize(pageDetail?.email_recipient)
                ) : (
                  <DateTimeDisplay date={pageDetail?.web_end_date} />
                )}
              </Grid>
              <Grid item xs={12}>
                <Typography fontWeight={600}>Subject</Typography>
                {pageDetail?.subject}
              </Grid>
              <Grid item xs={12}>
                <Typography fontWeight={600}>Uploaded File / Image</Typography>
                <Grid
                  container
                  direction="column"
                  rowSpacing={1}
                  maxWidth="70%"
                  mt={1}
                >
                  {announcementFiles?.length > 0 &&
                    announcementFiles?.map(
                      ({ file_name, file_path, size }, index) => (
                        <Grid item key={index}>
                          <AttachmentFilesCard
                            fileName={file_name}
                            fileSize={size}
                            filePath={file_path}
                            tooltipPreview="View File"
                          />
                        </Grid>
                      )
                    )}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography fontWeight={600}>Announcement Content</Typography>
                <Box
                  dangerouslySetInnerHTML={{
                    __html: pageDetail?.content,
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Container>
      )}
    </Page>
  );
};

const breadcrumbData = [
  {
    label: "Configuration",
    link: "/admin/konfigurasi/announcement-management",
  },
  {
    label: "Announcement Management",
    link: "/admin/konfigurasi/announcement-management",
  },
  {
    label: "Announcement Detail",
    active: true,
  },
];
export default DetailAnnouncementManagement;
