import { StatusChip } from "../../../../components";
import { wordCapitalize } from "../../../../utils";

const ContractStatusChip = ({ status }) => {
  return (
    <StatusChip
      type={
        status === "approved"
          ? "success"
          : status === "rejected"
          ? "danger"
          : status === "waiting_for_approval" ||
            status === "waiting_for_editing_approval"
          ? "warning"
          : ""
      }
      label={
        status === "waiting_for_approval"
          ? "Waiting for Approval"
          : status === "waiting_for_editing_approval"
          ? "Waiting for Editing Approval"
          : wordCapitalize(status) || " - "
      }
    />
  );
};

export default ContractStatusChip;
