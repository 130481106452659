import {
  FormControl,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  OutlinedInput,
  Select,
} from "@material-ui/core";
import "@material-ui/core/styles";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import { makeStyles } from "@material-ui/styles";
import { useRef, useState } from "react";
import moment from "moment";

const useStyles = makeStyles(() => ({
  theSelect: {
    "& .MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input": {
      backgroundColor: "transparent",
    },
  },
  myOutlinedInput: {
    height: "44px",
  },
}));

export default function DatePickerComponent({
  disabled,
  datePeriod,
  changePeriode,
}) {
  const classes = useStyles();
  const inputEl = useRef(null);
  const [open, setOpen] = useState(false);

  var times = moment().year + 1;
  var Year = [];

  for (var i = times - 22; i < times; i++) {
    Year.push(i);
  }

  Year = Year.sort((a, b) => b - a);

  const handleChangeMonthStart = e => {
    changePeriode(e.target);
  };
  const handleChangeYearStart = e => {
    changePeriode(e.target);
  };
  const handleChangeMonthEnd = e => {
    changePeriode(e.target);
  };
  const handleChangeYearEnd = e => {
    changePeriode(e.target);
  };
  const monthList = [
    {
      id: 0,
      name: "Select Month",
    },
    {
      id: 1,
      name: "January",
    },
    {
      id: 2,
      name: "February",
    },
    {
      id: 3,
      name: "March",
    },
    {
      id: 4,
      name: "April",
    },
    {
      id: 5,
      name: "May",
    },
    {
      id: 6,
      name: "June",
    },
    {
      id: 7,
      name: "July",
    },
    {
      id: 8,
      name: "August",
    },
    {
      id: 9,
      name: "September",
    },
    {
      id: 10,
      name: "October",
    },
    {
      id: 11,
      name: "November",
    },
    {
      id: 12,
      name: "December",
    },
  ];
  return (
    <div>
      <FormControl variant="outlined" fullWidth={true}>
        <OutlinedInput
          readOnly
          className={classes.myOutlinedInput}
          ref={inputEl}
          disabled={disabled}
          value={`${datePeriod.start_month}-${datePeriod.start_year} - ${datePeriod.end_month}-${datePeriod.end_year}`}
          onClick={() => setOpen(true)}
          endAdornment={
            <IconButton
              disabled={disabled}
              style={{ padding: "12px" }}
              onClick={() => setOpen(true)}
            >
              <CalendarTodayIcon />
            </IconButton>
          }
          aria-describedby="outlined-weight-helper-text"
          inputProps={{
            style: {
              padding: 0,
              paddingLeft: "14px",
              height: "44px",
              paddingRight: "14px",
              width: "100%",
            },
            ref: inputEl,
          }}
        />
      </FormControl>
      <Menu
        anchorEl={inputEl.current}
        keepMounted
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <Grid container style={{ padding: "20px" }}>
          <Grid style={{ marginBottom: "8px" }} container>
            <strong>
              <label>Start Period</label>
            </strong>
          </Grid>
          <Grid item md={6} style={{ paddingRight: "8px" }}>
            <Grid>
              <label>Month</label>
            </Grid>
            <FormControl variant="outlined">
              {monthList?.length > 0 ? (
                <>
                  <Select
                    className={classes.theSelect}
                    name="start_month"
                    labelId="demo-simple-select-outlined-label"
                    id="id-start-month"
                    style={{ width: "180px", height: "36px" }}
                    onChange={e => handleChangeMonthStart(e)}
                    value={`${Number(datePeriod.start_month)}`}
                  >
                    {monthList.map((val, i) => {
                      return (
                        <MenuItem key={i} value={val.id}>
                          {val.id === 0 ? (
                            <>
                              <span style={{ fontStyle: "italic" }}>
                                {val.name}
                              </span>
                            </>
                          ) : (
                            <>{val.name}</>
                          )}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </>
              ) : null}
            </FormControl>
          </Grid>
          <Grid item md={6} style={{ paddingLeft: "8px" }}>
            <Grid>
              <label>Year</label>
            </Grid>
            <FormControl variant="outlined">
              <Select
                className={classes.theSelect}
                displayEmpty={true}
                labelId="demo-simple-select-outlined-label"
                name="start_year"
                id="id-start-year"
                style={{ width: "180px", height: "36px" }}
                onChange={e => handleChangeYearStart(e)}
                defaultValue={""}
                value={`${datePeriod.start_year}`}
              >
                <MenuItem value="">
                  <em>Select Year</em>
                </MenuItem>
                {Year.map((val, i) => {
                  return (
                    <MenuItem key={i} value={val}>
                      {val}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container style={{ padding: "20px" }}>
          <Grid style={{ marginBottom: "8px" }} container>
            <strong>
              <label>End Period</label>
            </strong>
          </Grid>
          <Grid item md={6} style={{ paddingRight: "8px" }}>
            <Grid>
              <label>Month</label>
            </Grid>
            <FormControl variant="outlined">
              <Select
                className={classes.theSelect}
                displayEmpty={true}
                labelId="demo-simple-select-outlined-label"
                name="end_month"
                id="id-end-month"
                style={{ width: "180px", height: "36px" }}
                onChange={e => handleChangeMonthEnd(e)}
                value={`${Number(datePeriod.end_month)}`}
              >
                {monthList.map((val, i) => {
                  return (
                    <MenuItem key={i} value={val.id}>
                      {val.id === 0 ? (
                        <>
                          <span style={{ fontStyle: "italic" }}>
                            {val.name}
                          </span>
                        </>
                      ) : (
                        <>{val.name}</>
                      )}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={6} style={{ paddingLeft: "8px" }}>
            <Grid>
              <label>Year</label>
            </Grid>
            <FormControl variant="outlined">
              <Select
                className={classes.theSelect}
                displayEmpty={true}
                labelId="demo-simple-select-outlined-label"
                name="end_year"
                id="id-end-year"
                style={{ width: "180px", height: "36px" }}
                onChange={e => handleChangeYearEnd(e)}
                defaultValue={""}
                value={`${datePeriod.end_year}`}
              >
                <MenuItem value="">
                  <em>Select Year</em>
                </MenuItem>
                {Year.map((val, i) => {
                  return (
                    <MenuItem key={i} value={val}>
                      {val}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Menu>
    </div>
  );
}
