import React from "react";
import axios from "axios";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import {
  Popover,
  CardHeader,
  CardActions,
  Button,
  colors,
} from "@material-ui/core";
import NotificationList from "./NotificationList";
import Placeholder from "./Placeholder";
import { hardBaseUrl } from "../../../services/urlConstant";

const useStyles = makeStyles(() => ({
  root: {
    width: 350,
    maxWidth: "100%",
  },
  actions: {
    backgroundColor: colors.grey[50],
    justifyContent: "left",
  },
  pointer: {
    cursor: "pointer",
  },
}));

function NotificationsPopover({
  notifications,
  markAsRead,
  anchorEl,
  ...rest
}) {
  const classes = useStyles();
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const onChangeMarkAll = () => {
    try {
      axios
        .put(
          hardBaseUrl + `/notification`,
          {},
          {
            headers,
          }
        )
        .then(res => {
          if (res.data.message === "success") {
            window.location.reload();
          }
        });
    } catch (error) {
      throw error;
    }
  };
  return (
    <Popover
      {...rest}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
    >
      <div className={classes.root}>
        <CardHeader
          title={
            <div onClick={() => onChangeMarkAll()} className={classes.pointer}>
              <span>Notification</span>
              <span
                style={{
                  float: "right",
                  color: "#9545EB",
                }}
              >
                Mark all as read
              </span>
            </div>
          }
        />

        {notifications?.length > 0 ? (
          <NotificationList
            notifications={notifications}
            onClick={markAsRead}
          />
        ) : (
          <Placeholder />
        )}

        <CardActions className={classes.actions}>
          <Button
            component={RouterLink}
            size="small"
            to="/admin/notifikasi"
            style={{
              textTransform: "none",
              color: "#9545EB",
            }}
          >
            View All Notifications
          </Button>
        </CardActions>
      </div>
    </Popover>
  );
}

NotificationsPopover.propTypes = {
  anchorEl: PropTypes.any,
  className: PropTypes.string,
  notifications: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default NotificationsPopover;
