import {
  Backdrop,
  Breadcrumbs,
  Button,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Link,
  Snackbar,
  TextField,
  Typography,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import { useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useHistory } from "react-router";
import Swal from "sweetalert2";
import "../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import HeaderPage from "../../../../layouts/Header/HeaderPage";
import { Page } from "components";
import { hardBaseUrl } from "../../../../services/urlConstant";
let theToken = localStorage.getItem("token");
const theme = createTheme({
  typography: {
    fontFamily: ["Helvetica Neue"].join(","),
  },
});

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  results: {
    marginTop: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  btnSubmit: {
    backgroundColor: "black",
    marginRight: "10px",
    color: "white",
    width: "150px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
  btnPreview: {
    backgroundColor: "black",
    marginRight: "10px",
    color: "white",
    width: "150px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  title: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "28px",
  },
  subTitle: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#6e6e6e",
  },
  label: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontWeight: "500px",
    fontSize: "14px",
    lineHeight: "20px",
  },
}));

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "50px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#303030",
  borderStyle: "solid",
  backgroundColor: "white",
  color: "black",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function TambahBank() {
  const classes = useStyles();
  let history = useHistory();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [loadingPage, setLoadingPage] = useState(true);
  const [openSnack, setOpenSnack] = useState(false);
  const [typeSnackbar, setTypeSnackbar] = useState("success");
  const [message, setMessage] = useState("This is a message!");
  const [imageLogo, setImageLogo] = useState("");
  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: "image/*",
    onDrop: acceptedFiles => {
      setImageLogo(acceptedFiles[0]);
    },
  });

  const files = acceptedFiles.map(file => (
    <li key={file.name}>
      {file.name} - {file.size} bytes
    </li>
  ));

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const handleChangeName = item => {
    setName(item.target.value);
  };
  const handleChangeEmail = item => {
    setEmail(item.target.value);
  };
  const handleButtonSimpan = async () => {
    if (name === "") {
      setTypeSnackbar("error");
      setMessage("Nama Bank Tidak Boleh Kosong.");
      setOpenSnack(true);
    }
    if (email === "") {
      setTypeSnackbar("error");
      setMessage("Code Bank Tidak Boleh Kosong.");
      setOpenSnack(true);
    }

    if (name !== "" && email !== "" && imageLogo !== "") {
      setLoadingPage(true);
      const formData = new FormData();
      formData.append("name", name);
      formData.append("code", email);
      formData.append("image_logo", imageLogo);

      let baseOfUrl = hardBaseUrl;
      let urlUse = baseOfUrl + "/bank";
      let resultAdd = await axios.post(urlUse, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + theToken,
        },
      });

      if (
        resultAdd.data.message === "success" &&
        resultAdd.data.meta.http_status === 200
      ) {
        setLoadingPage(false);
        Swal.fire({
          title: "Success",
          text: "Tambah Bank Berhasil.",
          icon: "success",
          confirmButtonText: "Ok",
        }).then(result => {
          if (result.isConfirmed) {
            history.push("/admin/parameter/bank-admin");
          }
        });
      }
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnack(false);
  };

  return (
    <Page className={classes.root} title="Tambah Bank">
      <div style={{ float: "right", marginRight: "20px" }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            underline="hover"
            color="inherit"
            href="/admin/parameter/publisher"
          >
            Master
          </Link>
          <Link
            underline="hover"
            color="inherit"
            href="/admin/parameter/bank-admin"
          >
            Bank
          </Link>
          <Typography>Tambah Bank</Typography>
        </Breadcrumbs>
      </div>
      <Backdrop className={classes.backdrop} open={loadingPage}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container maxWidth={false}>
        <Snackbar
          open={openSnack}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert onClose={handleCloseSnackbar} severity={typeSnackbar}>
            {message}
          </Alert>
        </Snackbar>
        <HeaderPage title="Tambah Bank" breadcrumbs={[]} />
        <Divider className={classes.divider} />
        <Grid container>
          <Grid item xs={4} md={4} sm={4} lg={4} xl={4}>
            <ThemeProvider theme={theme}>
              <Typography variant="h5" className={classes.title}>
                Logo Bank
              </Typography>
            </ThemeProvider>
          </Grid>
          <Grid item xs={8} md={8} sm={8} lg={8} xl={8}>
            <div className="container">
              <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                <Typography component="h1" variant="h5">
                  <p>
                    <b>Cari File</b>, Letakkan gambar disini
                  </p>
                </Typography>
              </div>
              <div
                style={{
                  marginTop: "20px",
                }}
              >
                <aside>
                  <Typography component="h1" variant="h5">
                    Files :
                  </Typography>
                  <ul>
                    <Typography component="h1" variant="h5">
                      {files}
                    </Typography>
                  </ul>
                </aside>
              </div>
            </div>
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        <Grid container>
          <Grid item xs={4} md={4} sm={4} lg={4} xl={4}>
            <ThemeProvider theme={theme}>
              <Typography variant="h5" className={classes.title}>
                Bank Info
              </Typography>
            </ThemeProvider>
          </Grid>
          <Grid item xs={8} md={8} sm={8} lg={8} xl={8}>
            <div
              style={{
                marginTop: "5px",
                marginBottom: "5px",
              }}
            >
              <div
                style={{
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                <ThemeProvider theme={theme}>
                  <Typography>Nama Bank</Typography>
                </ThemeProvider>
              </div>
              <div
                style={{
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  fullWidth={true}
                  margin="dense"
                  value={name}
                  onChange={event => handleChangeName(event)}
                />
              </div>
              <div
                style={{
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                <ThemeProvider theme={theme}>
                  <Typography>Kode Bank</Typography>
                </ThemeProvider>
              </div>
              <div
                style={{
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  fullWidth={true}
                  margin="dense"
                  value={email}
                  type="email"
                  onChange={event => handleChangeEmail(event)}
                />
              </div>
            </div>

            <Button className={classes.btnSubmit} onClick={handleButtonSimpan}>
              <ThemeProvider theme={theme}>
                <Typography
                  style={{
                    color: "white",
                  }}
                >
                  Simpan
                </Typography>
              </ThemeProvider>
            </Button>
            <Button
              className={classes.btnPreview}
              variant="outlined"
              href="/admin/parameter/bank-admin"
            >
              <ThemeProvider theme={theme}>
                <Typography
                  style={{
                    color: "white",
                  }}
                >
                  Kembali
                </Typography>
              </ThemeProvider>
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}

export default TambahBank;
