import { createTheme } from "@material-ui/core/styles";
import overrides from "./overrides";
import palette from "./palette";
import typography from "./typography";

const baseTheme = {
  palette,
  typography,
  overrides,
};

export const theme = createTheme(baseTheme);
export const themeWithRtl = createTheme({ ...baseTheme, direction: "rtl" });
