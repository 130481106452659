import axios from "axios";
import { useEffect, useState } from "react";
import { hardBaseUrl } from "../../../services/urlConstant";

const PdfReader = () => {
  const [pdf, setPdf] = useState(null);

  const getPdf = () => {
    const url = `${hardBaseUrl}/resource/info`;
    axios.get(url).then(res => {
      setPdf(res.data.data);
    });
  };

  useEffect(() => {
    getPdf();
  }, []);

  return (
    <div style={{ width: "100%", height: "200%" }}>
      <iframe
        src={pdf}
        style={{ position: "absolute", width: "100%", height: "100%" }}
      />
    </div>
  );
};

export default PdfReader;
