import {
  Card,
  CardActions,
  CardContent,
  Chip,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import Pagination from "@material-ui/lab/Pagination";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link as RouterLink } from "react-router-dom";
import TableEditBar from "../../../components/molecules/Table/TableEditBar";
import { hardBaseUrl } from "../../../services/urlConstant";

const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

function Results({
  className,
  pageSizePagination,
  page,
  totalData,
  handleChangePage,
  items,
  ...rest
}) {
  const [selectedCustomers] = useState([]);
  const [getColor, setGetColor] = useState("");

  useEffect(() => {
    const getMainDsp = async () => {
      try {
        const token = localStorage.getItem("token");
        const url = `${hardBaseUrl}/me`;
        const res = await axios.get(url, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        setGetColor(res.data.data.publisher.theme_color);
      } catch (e) {
        new Error(e);
      }
    };
    getMainDsp();
  }, []);

  const getDateFormat = date => {
    let dateFormat = new Date(date);
    let day = dateFormat.getDate();
    let month = dateFormat.getMonth();
    let year = dateFormat.getFullYear();
    let months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "Mei",
      "Jun",
      "Jul",
      "Agu",
      "Sept",
      "Okt",
      "Nov",
      "Des",
    ];
    return day + " " + months[month] + " " + year;
  };
  const useStyles = makeStyles(theme => ({
    root: {},
    content: {
      padding: 0,
    },
    inner: {
      minWidth: 700,
    },
    nameCell: {
      display: "flex",
      alignItems: "center",
    },
    avatar: {
      height: 42,
      width: 42,
      marginRight: theme.spacing(1),
    },
    actions: {
      padding: theme.spacing(1),
      justifyContent: "flex-end",
    },
    pagination: {
      "& .Mui-selected": {
        backgroundColor: getColor,
        color: "white",
      },
    },
    pageSize: {
      height: "30px",
      borderRadius: "6px",
      border: "1px solid #D1D5DC",
      paddingLeft: "5px",
      paddingRight: "5px",
      marginTop: "5px",
    },
    paginationText: {
      fontSize: "14px",
      fontWeight: "normal",
      fontStyle: "normal",
      color: "#687083",
    },
    headText: {
      fontSize: "12px",
      color: "#687083",
      fontWeight: "bold",
    },
    bodyTextBlack: {
      fontSize: "14px",
      fontWeight: "regular",
      color: "#111827",
    },
    bodyTextGrey: {
      fontSize: "14px",
      fontWeight: "regular",
      color: "#687083",
    },
    tableRow: {
      height: 20,
    },
    conTextBlack: {
      fontSize: "14px",
      fontWeight: "bold",
    },
    dropdown: {
      color: "black",
    },
    activeChip: {
      backgroundColor: "#8DE5AB10",
      color: "#34774C",
      fontSize: "14px",
      fontWeight: "500",
    },
    activeChipIndicator: {
      color: "#34774C",
      fontSize: "12px",
    },
    nonActiveChip: {
      backgroundColor: "#D1D5DC10",
      color: "#364052",
      fontSize: "14px",
      fontWeight: "500",
    },
    nonActiveChipIndicator: {
      color: "#364052",
      fontSize: "12px",
    },
    tableChip: {
      marginRight: "5px",
      backgroundColor: "#F9FAFB",
      border: "1px solid #D1D5DC",
    },
    btnDetail: {
      backgroundColor: "#111827",
      color: "white",
      width: "30px",
      height: "30px",
      padding: "6px",
      borderRadius: "6px",
    },
  }));

  const classes = useStyles();

  const formatter = new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 0,
  });
  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Card>
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <ThemeProvider theme={theme}>
                      <TableCell>
                        <Typography
                          variant="subtitle2"
                          className={classes.headText}
                        >
                          Aksi
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="subtitle2"
                          className={classes.headText}
                        >
                          Kode Transaksi
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="subtitle2"
                          className={classes.headText}
                        >
                          Publisher
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="subtitle2"
                          className={classes.headText}
                        >
                          Pengajuan Pembayaran
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="subtitle2"
                          className={classes.headText}
                        >
                          Tanggal Pengajuan
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="subtitle2"
                          className={classes.headText}
                        >
                          Status
                        </Typography>
                      </TableCell>
                    </ThemeProvider>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {items?.length > 0 ? (
                    items.map(customer => (
                      <TableRow
                        hover
                        key={customer.contract_id}
                        selected={
                          selectedCustomers.indexOf(customer.transaction_id) !==
                          -1
                        }
                      >
                        <ThemeProvider theme={theme}>
                          <TableCell>
                            <Tooltip title={"Update"} placement="bottom">
                              <IconButton
                                className={classes.btnDetail}
                                style={{
                                  backgroundColor: getColor,
                                }}
                              >
                                <RouterLink
                                  to={{
                                    pathname: `/admin/kontrak/detail/${customer.transaction_id}`,
                                    state: {
                                      customer: customer,
                                    },
                                  }}
                                >
                                  <VisibilityOutlinedIcon
                                    style={{
                                      filter: " brightness(0) invert(1)",
                                      marginTop: "10px",
                                    }}
                                  />
                                </RouterLink>
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={classes.bodyTextBlack}
                            >
                              {customer.transaction_id}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={classes.bodyTextBlack}
                            >
                              {customer.publisher_name}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            {formatter.format(customer.payment_submission)}
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={classes.bodyTextBlack}
                            >
                              {getDateFormat(customer.date)}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            {customer.is_active_flag ? (
                              <Chip
                                icon={
                                  <FiberManualRecordIcon
                                    fontSize="small"
                                    className={classes.activeChipIndicator}
                                  />
                                }
                                label="Aktif"
                                className={classes.activeChip}
                              />
                            ) : (
                              <Chip
                                icon={
                                  <FiberManualRecordIcon
                                    fontSize="small"
                                    className={classes.nonActiveChipIndicator}
                                  />
                                }
                                label="Non Aktif"
                                className={classes.nonActiveChip}
                              />
                            )}
                          </TableCell>
                        </ThemeProvider>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={6}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <Typography variant="subtitle1">
                            Tidak ada data untuk ditampilkan
                          </Typography>
                        </div>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <Grid
            alignContent="space-between"
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <div style={{ display: "flex" }}>
                <div style={{ marginTop: "7px" }}>
                  <ThemeProvider theme={theme}>
                    <Typography className={classes.paginationText}>
                      Item per halaman:
                    </Typography>
                  </ThemeProvider>
                </div>{" "}
                <div style={{ marginLeft: "10px" }}>{pageSizePagination}</div>
              </div>
            </Grid>

            <Grid item>
              <Pagination
                count={totalData}
                shape="rounded"
                className={classes.pagination}
                page={page}
                onChange={handleChangePage}
              />
            </Grid>
          </Grid>
        </CardActions>
      </Card>
      <TableEditBar selected={selectedCustomers} />
    </div>
  );
}

Results.propTypes = {
  className: PropTypes.string,
  customers: PropTypes.array,
};

Results.defaultProps = {
  customers: [],
};

export default Results;
