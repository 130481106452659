import Dialog from "@material-ui/core/Dialog";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { Button } from "antd";
import Axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import CARIS from "../../../assets/img/newCarisLogo.webp";
import logoMpis from "../../../assets/img/newMpisLogo.webp";
import LayoutNoHeader from "../../../mpisLandingComponent/LayoutNoHeader";
import { hardBaseUrl, hardSubDomain } from "../../../services/urlConstant";
import "./ManageComposer.css";

export default function ManageComposer() {
  let history = useHistory();
  const message = useSelector(state => state.auth.message);
  const [arrList, setArrList] = useState([]);
  const [selectedPeople, setSelectedPeople] = useState("");
  const [selectedIndex, setSelectedIndex] = useState("");
  const [selectedName, setSelectedName] = useState("");
  const [open, setOpen] = useState(false);

  const styles = theme => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });
  const DialogContent = withStyles(theme => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiDialogContent);

  const DialogActions = withStyles(theme => ({
    root: {
      margin: 0,
      padding: theme.spacing(1),
    },
  }))(MuiDialogActions);
  const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

  const getDetail = () => {
    const token = localStorage.getItem("token");
    const url = "/composer-manager/composers";
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    Axios.get(hardBaseUrl + url, { headers })
      .then(res => {
        let theArr = res.data.data;
        let newArr = [];
        theArr.forEach(item => {
          let tempObj = {
            composer_id: item.composer_id,
            composer_name: item.composer_name,
            is_current_composer: item.is_current_composer,
            selected: false,
          };
          newArr.push(tempObj);
        });
        setArrList(newArr);
      })
      .catch(err => {
        new Error(err);
      });
  };

  useEffect(() => {
    getDetail();
  }, []);

  useEffect(() => {
    if (message !== null) {
      window.location.reload();
    }
  }, [message]);

  const getLogo = () => {
    if (hardSubDomain === null) {
      return <img src={logoMpis} alt="logo" className="logo" width={"400px"} />;
    } else if (hardSubDomain === "mpis") {
      return <img src={logoMpis} alt="logo" className="logo" width={"200px"} />;
    } else {
      return <img src={CARIS} alt="" width="206px" />;
    }
  };

  const selectName = (id, theindex) => {
    setSelectedName(arrList[theindex].composer_name);
    let theSelected = arrList[theindex];
    let allItems = arrList;
    let theSelectedIndex = selectedIndex;
    if (theSelectedIndex !== "") {
      if (allItems[theSelectedIndex].selected === true) {
        allItems[theSelectedIndex].selected = false;
      } else {
        allItems[theSelectedIndex].selected = true;
      }
    }
    if (theSelected.selected === true) {
      allItems[theindex].selected = false;
    } else {
      allItems[theindex].selected = true;
    }
    setSelectedPeople(id);
    setSelectedIndex(theindex);
    setArrList(allItems);
  };

  const nextSelect = async () => {
    try {
      let token = localStorage.getItem("token");
      let resultLogin = await Axios.put(
        hardBaseUrl + `/composer-manager/set-composer/${selectedPeople}`,
        {},
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      if (resultLogin.data.message === "success") {
        localStorage.setItem("token", resultLogin.data.data.access_token);
        history.push("/pencipta/dashboard-pencipta");
      }
    } catch (err) {
      new Error(err);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <LayoutNoHeader>
      <div className="login">
        <div className="content">
          <div className="header">{getLogo()}</div>
          <div
            style={{
              boxShadow: "0 0 50px #ccc",
              marginTop: "30px",
              borderRadius: "4px",
            }}
          >
            <List component="nav" aria-label="main mailbox folders">
              <ListItem>
                <div>
                  <b>Select Composer/Author</b>
                </div>
              </ListItem>
            </List>
            <Divider />
            <List component="nav" aria-label="main mailbox folders">
              {arrList.map((customer, idx) => (
                <div key={customer.composer_id}>
                  <ListItem
                    key={customer.composer_id}
                    button
                    onClick={() => selectName(customer.composer_id, idx)}
                    selected={customer.selected}
                  >
                    <ListItemText primary={`${customer.composer_name}`} />
                  </ListItem>
                </div>
              ))}
            </List>
            <Divider />
            <List component="nav" aria-label="secondary mailbox folders">
              <ListItem
                style={{
                  justifyContent: "right",
                }}
              >
                <div>
                  {selectedName === "" ? (
                    <>
                      <Button
                        disabled={selectedName === ""}
                        style={{
                          backgroundColor: "grey",
                          border: "none",
                          width: "100%",
                          borderRadius: 5,
                          color: "white",
                        }}
                      >
                        Select
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        onClick={() => handleClickOpen()}
                        disabled={selectedName === ""}
                        style={{
                          backgroundColor: "black",
                          border: "none",
                          width: "100%",
                          borderRadius: 5,
                          color: "white",
                        }}
                      >
                        Select
                      </Button>
                    </>
                  )}
                </div>
              </ListItem>
            </List>
            <Dialog
              onClose={handleClose}
              aria-labelledby="customized-dialog-title"
              open={open}
            >
              <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                <b>Select Composer/Author</b>
              </DialogTitle>
              <DialogContent dividers>
                <Typography gutterBottom>
                  Are you sure you want continue as {selectedName} ?
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  style={{
                    borderRadius: "6px",
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => nextSelect()}
                  style={{
                    backgroundColor: "black",
                    color: "white",
                    borderRadius: "6px",
                  }}
                >
                  Next
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
      </div>
    </LayoutNoHeader>
  );
}
