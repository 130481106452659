import React, { useState, useEffect, useRef } from "react";
import { Page, PrimaryButton } from "components";
import HeaderPage from "../../../../layouts/Header/HeaderPage";
import { ExpandMore, DeleteOutline } from "@material-ui/icons";
import { useHistory, useParams } from "react-router";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import { InnoImage } from "inno-ui";
import UnggahLogo from "../../../../assets/img/unggahLogo.svg";
import { PhotoshopPicker } from "react-color";
import Swal from "sweetalert2";
import { hardBaseUrl } from "../../../../services/urlConstant";
import {
  Container,
  TextField,
  InputAdornment,
  Backdrop,
  Divider,
  Button,
  Card,
  Typography,
  CircularProgress,
  Box,
  MenuItem,
  Select,
  Grid,
} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  trashIcon: {
    color: "#9AA2B1",
    cursor: "pointer",
  },
  imgProfile: {
    maxWidth: "100%",
    objectFit: "fill"
  },
  content: {
    marginTop: "20px",
  },
  container: {
    padding: "1rem",
  },
  contentAdd: {
    marginTop: "20px",
    marginLeft: "20px",
    marginRight: "20px",
    fontFamily: ["Inter"].join(","),
  },
  logoColumn: {
    maxWidth: 50,
  },
  textGrey: {
    color: "grey",
  },
  card: {
    padding: ".7rem",
    marginBottom: "1rem",
  },
  boxUpload: {
    cursor: "pointer",
  },
  cardSocietyFee: {
    padding: ".7rem",
    marginBottom: "1rem",
    display: "grid",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  textAdd: {
    cursor: "pointer",
    color: "#9545EB",
  },
  colorPicker: {
    width: "400px !important",
    "& .photoshop-picker": {
      width: "200px !important"
    },
  },
  buttonAdd: {
    width: "auto",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "24px",
    backgroundColor: "black",
    color: "white",
    textTransform: "none",
  },
  selectPadding: {
    "& .MuiSelect-outlined": {
      paddingTop: "14px",
      paddingBottom: "14px",
      paddingLeft: "20px",
      paddingRight: "30px",
    },
  },
  required: {
    color: "red",
  },
  textTitleField: {
    marginBottom: 5,
  },
  inputFields: {
    height: "44px",
    paddingTop: "0px",
    paddingBottom: "0px",
    "& .MuiOutlinedInput-root": {
      minWidth: 250,
      height: "44px",
      paddingTop: "0px",
      paddingBottom: "0px",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  imgPreview: {
    maxHeight: "120px",
  },
  inputTextArea: {
    minHeight: 100,
    paddingTop: "0px",
    paddingBottom: "0px",
    "& .MuiOutlinedInput-root": {
      minWidth: 250,
      height: "44px",
      paddingTop: "0px",
      paddingBottom: "0px",
    },
  },
  deleteTerritory: {
    justifyContent: "flex-end",
    display: "flex",
  },
  boxAddButton: {
    display: "flex",
    margin: "20px 0px",
  },
}));

const listRoyalty = [
  {
    title: "PR Collection Share",
    value: "is_pr_collection_flag",
  },
  {
    title: "MR Collection Share",
    value: "is_mr_collection_flag",
  },
  {
    title: "SR Collection Share",
    value: "is_sr_collection_flag",
  },
];

const isMemberOption = [
  {
    title: "Member",
    value: "member",
  },
  {
    title: "Non-Member",
    value: "non-member",
  },
];

const fieldOfSocietyProfile = [
  {
    title: "Profile Society Name",
    key: "society_name",
    required: true,
  },
  {
    title: "Status",
    key: "is_member",
  },
  {
    title: "IPI Name Number",
    key: "ipi_name_number",
    required: true,
  },
  {
    title: "IPI Base Number",
    key: "ipi_base_number",
  },
  {
    title: "Transmitter Code",
    key: "society_code",
  },
  {
    title: "Email",
    key: "email",
    required: true,
  },
  {
    title: "Official Website",
    key: "offcial_website_url",
  },
  {
    title: "Royalty Type",
    key: "royalty",
    listSelect: listRoyalty,
    required: true,
  },
  {
    title: "Country",
    key: "country_id",
    required: true,
  },
  {
    title: "Phone Number",
    key: "phone_number",
    required: true,
  },
  {
    title: "Address",
    key: "address",
    required: true,
  },
];

async function urlToBlob(url) {
  const response = await fetch(url);
  const blob = await response.blob();
  return blob;
}

function blobToFile(blob, fileName) {
  const file = new File([blob], fileName, { type: blob.type });
  return file;
}

function AddOrEditSociety() {
  const classes = useStyles();
  const params = useParams();
  const isEditing = params?.id;
  const history = useHistory();
  const uploadLogo = useRef();
  const token = localStorage.getItem("token");
  const [state, setState] = useState({
    value: {
      society_name: "",
      is_member: "",
      ipi_name_number: "",
      ipi_base_number: "",
      society_code: "",
      email: "",
      offcial_website_url: "",
      royalty: "",
      country_id: "",
      phone_number: "",
      address: "",
      image_logo: {},
      profile_image_url: "",
      theme_color: "#2E3643",
      territory_ids: [],
      society_fees: [],
      territory_details: [
        {
          index: 0,
          territory_id: "",
          society_fee: "",
        },
      ],
    },
    loading: false,
    listCountry: [],
    listTerritory: [],

    showColorPicker: false,
  });
  const { value, loading, listCountry, showColorPicker, listTerritory } = state;
  const changeValue = (key, value) =>
    setState(currentState => ({
      ...currentState,
      value: {
        ...currentState.value,
        [key]: value,
      },
    }));

  const changeState = (key, value) =>
    setState(currentState => ({
      ...currentState,
      [key]: value,
    }));

  const changeValueTerritoryDetails = (index, key, newValue) => {
    const filterTerritory_details = (value?.territory_details || []).filter(
      (_, newIndex) => newIndex !== index
    );
    setState(currentState => ({
      ...currentState,
      value: {
        ...currentState?.value,
        territory_details: [
          ...filterTerritory_details,
          {
            ...currentState?.value?.territory_details[index],
            [key]: newValue,
          },
        ],
      },
    }));
  };

  const deleteListSocietyFee = index => {
    const newListSocietyFee = [...value?.territory_details].filter(
      (_, newIndex) => newIndex !== index
    );
    changeValue("territory_details", newListSocietyFee);
  };
  const colorPreview = makeStyles(() => ({
    changeColor: {
      backgroundColor: value?.theme_color,
      padding: "10px",
      width: "48px",
      height: "24px",
    },
  }));

  const handleChangePhotoButton = e => {
    e.preventDefault();
    uploadLogo?.current?.click();
  };

  const addSocietyField = () =>
    setState(currentState => ({
      ...currentState,
      value: {
        ...currentState?.value,
        territory_details: [
          ...currentState?.value?.territory_details,
          {
            index: currentState?.value?.territory_details.length - 1,
            territory_id: "",
            society_fee: "",
          },
        ],
      },
    }));

  const onChangeFile = e => {
    e.preventDefault();
    const data = e.target.files[0];
    if (data) {
      const object = URL.createObjectURL(data);
      changeValue("profile_image_url", object);
      changeValue("image_logo", data);
    }
  };
  const colorPrev = colorPreview();

  const getListCountry = async () => {
    const url = `${hardBaseUrl}/country/table`;
    const params = {
      page: 1,
      per_page: -1,
    };
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params,
    });
    changeState("listCountry", response?.data?.data || []);
  };

  const getListTerritory = async () => {
    changeState("loading", true);
    const url = `${hardBaseUrl}/admin/territory`;
    const params = {
      page: 1,
      size: -1,
    };
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params,
      });
      changeState("listTerritory", response?.data?.data || []);
      changeState("loading", false);
    } catch (err) {
      changeState("loading", false);
    }
  };

  const getDetailSociety = async id => {
    changeState("loading", true);
    const url = `${hardBaseUrl}/admin/society/${id}`;
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = response?.data?.data;
      const findValueRoyalty = {
        is_mr_collection_flag: data?.is_mr_collection_flag,
        is_pr_collection_flag: data?.is_pr_collection_flag,
        is_sr_collection_flag: data?.is_sr_collection_flag,
      };
      const mappingRoyalty = Object.entries(findValueRoyalty);
      const resultRoyalty = mappingRoyalty.every(item => item[1] === false)
        ? ""
        : mappingRoyalty?.find(item => item[1])[0];
      const fileName = data?.profile_image_url?.substring(
        data?.profile_image_url?.lastIndexOf("/") + 1
      );
      const convertUrlToFile = await urlToBlob(data?.profile_image_url)
        .then(blob => blobToFile(blob, fileName))
        .catch(error => {
          new Error(error);
        });
      setState(currentState => ({
        ...currentState,
        value: {
          ...data,
          country: data?.country_id,
          is_member: data?.is_member ? "member" : "non-member",
          royalty: resultRoyalty || "",
          image_logo: convertUrlToFile,
          territory_details: data?.territory_details.map((item, index) => ({
            ...item,
            index,
          })),
        },
      }));
      changeState("loading", false);
    } catch (err) {
      new Error(err);
      changeState("loading", false);
    }
  };

  const handleAdd = value => {
    changeState("loading", true);
    const territory_ids = (value?.territory_details || [])
      .map(({ territory_id }) => territory_id)
      .join(", ");
    const society_fees = (value?.territory_details || [])
      .map(({ society_fee }) => society_fee)
      .join(", ");
    let formData = new FormData();
    formData.append("territory_ids", territory_ids);
    formData.append("society_fees", society_fees);
    formData.append("society_name", value?.society_name);
    formData.append("society_code", value?.society_code);
    formData.append("ipi_name_number", value?.ipi_name_number);
    formData.append("ipi_base_number", value?.ipi_base_number);
    formData.append("country_id", value?.country_id);
    formData.append("phone_number", value?.phone_number);
    formData.append("email", value?.email);
    formData.append("address", value?.address);
    formData.append("offcial_website_url", value?.offcial_website_url);
    formData.append("theme_color", value?.theme_color);
    formData.append(
      "is_member",
      value?.is_member === "non-member" ? false : true
    );
    formData.append(
      "is_pr_collection_flag",
      value?.royalty === "is_pr_collection_flag"
    );
    formData.append(
      "is_mr_collection_flag",
      value?.royalty === "is_mr_collection_flag"
    );
    formData.append(
      "is_sr_collection_flag",
      value?.royalty === "is_sr_collection_flag"
    );
    formData.append("image_logo", value?.image_logo);
    if (isEditing) {
      const urlEdit = `${hardBaseUrl}/admin/society/${params?.id}`;
      axios
        .put(urlEdit, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          Swal.fire({
            icon: "success",
            title: "Sukses",
            text: `Edit ${value?.society_name} berhasil.`,
          }).then(() => {
            history.goBack();
          });
        })
        .catch(err => {
          Swal.fire({
            icon: "error",
            title: "Failed",
            text:
              err?.response?.data?.message ||
              err?.response?.data?.errors[0]?.message ||
              "Something Wrong",
          });
        })
        .finally(() => changeState("loading", false));
    } else {
      const urlAdd = `${hardBaseUrl}/admin/society/`;
      axios
        .post(urlAdd, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          Swal.fire({
            icon: "success",
            title: "Sukses",
            text: "Tambah Society berhasil.",
          }).then(() => {
            history.goBack();
          });
        })
        .catch(err => {
          Swal.fire({
            icon: "error",
            title: "Failed",
            text:
              err?.response?.data?.message ||
              err?.response?.data?.errors[0]?.message ||
              "Something Wrong",
          });
        })
        .finally(() => changeState("loading", false));
    }
  };
  useEffect(() => {
    if (params?.id) {
      getDetailSociety(params?.id);
    }
    getListCountry();
    getListTerritory();
  }, []);

  const acceptedImage =
    "image/jpeg, image/png, image/gif, image/bmp, image/webp, image/svg+xml, image/tiff, image/x-icon";
  return (
    <Page className={classes.root} title="Society">
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <input
        type="file"
        accept={acceptedImage}
        onChange={onChangeFile}
        hidden
        name="logo"
        ref={uploadLogo}
      />
      <Container maxWidth={false}>
        <HeaderPage
          title={isEditing ? "Edit Society" : "Add Society"}
          breadcrumbs={[
            "Parameter",
            "Society",
            `${isEditing ? "Edit" : "Add"} Society`,
          ]}
        />
        <Grid container spacing={2} className={classes.content}>
          <Grid item xs={6}>
            <Box display="grid" marginBottom={2} gridGap={5}>
              <Typography variant="h4">Society profile</Typography>
              <Typography className={classes.textGrey}>
                Input the data requirement to create the society profile
              </Typography>
            </Box>
            <Card className={classes.card}>
              <Grid container direction="column" spacing={2}>
                {fieldOfSocietyProfile.map(({ title, key, required }) => {
                  const useSelect = ["country_id", "royalty", "is_member"].some(
                    item => item === key
                  );
                  const conditionSelect = {
                    country_id: listCountry,
                    royalty: listRoyalty,
                    is_member: isMemberOption,
                  };
                  const conditionType = {
                    phone_number: "number",
                    ipi_base_number: "number",
                    email: "email",
                  };
                  const listSelect = conditionSelect[key] || [];
                  return (
                    <Grid item key={key}>
                      <Box display="grid">
                        <Typography className={classes.textTitleField}>
                          {`${title} `}
                          {required && (
                            <span className={classes.required}>*</span>
                          )}
                        </Typography>
                        {useSelect ? (
                          <Select
                            name={key}
                            value={value[key]}
                            onChange={e => changeValue(key, e?.target?.value)}
                            variant="outlined"
                          >
                            {listSelect?.map((item, index) => (
                              <MenuItem
                                key={index}
                                value={item?.value || item?.id}
                              >
                                {item?.title || item?.country_name}
                              </MenuItem>
                            ))}
                          </Select>
                        ) : (
                          <TextField
                            variant="outlined"
                            disabled={loading}
                            onChange={e => {
                              const newValue = e.target.value;
                              changeValue(key, newValue);
                            }}
                            className={
                              key !== "address" ? classes.inputFields : ""
                            }
                            multiline={key === "address"}
                            type={conditionType[key] || "text"}
                            value={value[key]}
                            InputProps={{
                              endAdornment: key === "offcial_website_url" &&
                                <InputAdornment position="end">
                                  <Typography>
                                    {key === "caris_subdomain"
                                      ? "caris.id"
                                      : ".com"}
                                  </Typography>
                                </InputAdornment>
                              ,
                            }}
                          />
                        )}
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Box display="grid" gridGap={5} marginBottom={2}>
              <Typography variant="h4">Appearance</Typography>
              <Typography className={classes.textGrey}>
                Choose your company logo and customize the color theme
              </Typography>
            </Box>
            <Card className={classes.card}>
              <Typography className={classes.textTitleField}>
                Society Logo <span className={classes.required}>*</span>
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={7}>
                  <Card display="grid">
                    {value?.profile_image_url ? (
                      <img
                        src={value?.profile_image_url}
                        className={classes.imgProfile}
                      />
                    ) : (
                      <Box
                        justifyContent="center"
                        className={classes.boxUpload}
                        display="flex"
                        alignContent="center"
                        onClick={handleChangePhotoButton}
                      >
                        <InnoImage
                          src={UnggahLogo}
                          alt="Preview"
                          className={classes.imgPreview}
                          styleImageContainer={{
                            boxShadow: "none",
                          }}
                          fill
                        />
                      </Box>
                    )}
                  </Card>
                </Grid>
                <Grid item>
                  <Button
                    className={classes.buttonAdd}
                    onClick={handleChangePhotoButton}
                  >
                    Change Logo
                  </Button>
                </Grid>
                <Grid item xs={10}>
                  <Typography className={classes.textTitleField}>
                    Color Theme
                  </Typography>
                  <Box
                    onClick={() =>
                      changeState("showColorPicker", !showColorPicker)
                    }
                  >
                    <TextField
                      fullWidth={true}
                      name="color"
                      onChange={e =>
                        changeValue("theme_color", e?.target?.value)
                      }
                      value={value?.theme_color}
                      variant="outlined"
                      className={classes.inputFields}
                      disabled
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Box className={colorPrev.changeColor} />
                            <Divider
                              className={classes.colorDivider}
                              orientation="vertical"
                            />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <ExpandMore />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                  <Box>
                    {showColorPicker && (
                      <PhotoshopPicker
                        className={classes.colorPicker}
                        color={value?.theme_color}
                        onAccept={() => changeState("showColorPicker", !showColorPicker)}
                        onCancel={() => changeState("showColorPicker", !showColorPicker)}
                        onChange={updateColor =>
                          changeValue("theme_color", updateColor.hex)
                        }
                      />
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Card>
            <Grid container spacing={2} direction="column">
              <Grid item>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box display="grid" gridGap={5} marginTop={2}>
                    <Typography variant="h4">Society Fee</Typography>
                    <Typography className={classes.textGrey}>
                      Input your society fee for the data requirement
                    </Typography>
                  </Box>
                  <Typography
                    className={classes.textAdd}
                    onClick={addSocietyField}
                  >
                    + add society fee
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                {(
                  value?.territory_details.sort(
                    (a, b) => a?.index - b?.index
                  ) || []
                )?.map(({ territory_id, society_fee }, index) => {
                  return (
                    <Card className={classes.cardSocietyFee} key={index}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography>
                            {"Territory "}
                            <span className={classes.required}>*</span>
                          </Typography>
                          <TextField
                            variant="outlined"
                            disabled={loading}
                            fullWidth
                            onChange={e =>
                              changeValueTerritoryDetails(
                                index,
                                "territory_id",
                                e?.target?.value
                              )
                            }
                            className={classes.inputFields}
                            value={territory_id}
                            select
                          >
                            {listTerritory.map(({ territory_code, id }) => (
                              <MenuItem key={id} value={id}>
                                {territory_code}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography>
                            Society Fee
                            <span className={classes.required}>*</span>
                          </Typography>
                          <TextField
                            variant="outlined"
                            disabled={loading}
                            type="number"
                            fullWidth
                            value={society_fee}
                            onChange={e =>
                              changeValueTerritoryDetails(
                                index,
                                "society_fee",
                                e?.target?.value
                              )
                            }
                            className={classes.inputFields}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  %
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        {value?.territory_details?.length > 1 && (
                          <Grid item xs={12} className={classes.deleteTerritory}>
                            <Box onClick={() => deleteListSocietyFee(index)}>
                              <DeleteOutline className={classes.trashIcon} />
                            </Box>
                          </Grid>
                        )}
                      </Grid>
                    </Card>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Box width="100%" justifyContent="flex-end" display="flex">
              <PrimaryButton
                onClick={() => handleAdd(value)}
                className={loading ? "" : classes.buttonAdd}
                disabled={loading}
              >
                {isEditing ? "Save Changes" : "Save"}
              </PrimaryButton>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}

export default AddOrEditSociety;
