import {
  DateCalendar as MuiDateCalendar,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment-timezone";
const DateCalendar = ({
  value,
  onChange,
  defaultValue,
  minDate,
  maxDate,
  views,
  openTo,
  ...rest
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <MuiDateCalendar
        value={value}
        onChange={onChange}
        minDate={minDate || moment().subtract(10, "years")}
        maxDate={maxDate || moment()}
        openTo={openTo}
        views={views || ["year", "month", "day"]}
        defaultValue={defaultValue}
        format="DD MMM YYYY"
        {...rest}
      />
    </LocalizationProvider>
  );
};

export default DateCalendar;
