import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useHistory } from "react-router";
import { hardBaseUrl } from "../../../../services/urlConstant";

const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

function Results({
  className,
  items,
  isLoading,
  handleChangePage,
  pageSizePagination,
  page,
  totalData,
  ...rest
}) {
  const [getColor, setGetColor] = useState("");
  const history = useHistory();

  const getMainDsp = async () => {
    try {
      const token = localStorage.getItem("token");
      const url = `${hardBaseUrl}/me`;
      const res = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setGetColor(res.data.data.publisher.theme_color);
    } catch (e) {}
  };

  useEffect(() => {
    getMainDsp();
  }, []);

  const useStyles = makeStyles(theme => ({
    root: {},
    content: {
      padding: 0,
    },
    inner: {
      minWidth: 700,
    },
    nameCell: {
      display: "flex",
      alignItems: "center",
    },
    avatar: {
      height: 42,
      width: 42,
      marginRight: theme.spacing(1),
    },
    actions: {
      padding: theme.spacing(1),
      justifyContent: "flex-end",
    },
    pagination: {
      "& .Mui-selected": {
        backgroundColor: getColor,
        color: "white",
      },
    },
    pageSize: {
      height: "30px",
      borderRadius: "6px",
      border: "1px solid #D1D5DC",
      paddingLeft: "5px",
      paddingRight: "5px",
      marginTop: "5px",
    },
    paginationText: {
      fontSize: "14px",
      fontWeight: "normal",
      fontStyle: "normal",
      color: "#687083",
    },
    mypadding: {
      "& .MuiSelect-outlined": {
        paddingTop: "14px",
        paddingBottom: "14px",
        paddingLeft: "20px",
        paddingRight: "30px",
      },
    },
    headText: {
      fontSize: "12px",
      color: "#687083",
      fontWeight: "bold",
    },
    bodyTextBlack: {
      fontSize: "14px",
      fontWeight: "regular",
      color: "#111827",
    },
    bodyTextGrey: {
      fontSize: "14px",
      fontWeight: "regular",
      color: "#687083",
    },
    tableRow: {
      height: 20,
    },
    conTextBlack: {
      fontSize: "14px",
      fontWeight: "bold",
    },
    dropdown: {
      color: "black",
    },
    btnEdit: {
      backgroundColor: "#111827",
      color: "white",
      width: "30px",
      height: "30px",
      padding: "6px",
      borderRadius: "6px",
    },
    btnDelete: {
      backgroundColor: "#111827",
      color: "white",
      width: "35px",
      height: "35px",
      padding: "6px",
      borderRadius: "6px",
      marginLeft: "10px",
      cursor: "pointer",
    },
    btnRincian: {
      backgroundColor: "black",

      color: "white",
      padding: "10px 12px",

      "&:hover": {
        backgroundColor: "black",
        color: "white",
      },
    },
  }));

  const classes = useStyles();
  const handleRedirect = res => {
    history.push({
      pathname: `/admin/song-claim/${res.id}`,
      state: res,
    });
  };
  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Card>
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <ThemeProvider theme={theme}>
                      <TableCell>
                        <Typography
                          variant="subtitle2"
                          className={classes.headText}
                        >
                          Song Title
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="subtitle2"
                          className={classes.headText}
                        >
                          Composer/Author
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="subtitle2"
                          className={classes.headText}
                        >
                          ISWC Code
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="subtitle2"
                          className={classes.headText}
                        >
                          Status
                        </Typography>
                      </TableCell>
                      <TableCell style={{ width: "10%" }}>
                        <Typography
                          variant="subtitle2"
                          className={classes.headText}
                        >
                          Action
                        </Typography>
                      </TableCell>
                    </ThemeProvider>
                  </TableRow>
                </TableHead>
                {isLoading ? (
                  <TableBody>
                    <TableRow>
                      <TableCell>. . . Loading . . .</TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <>
                    {items.length > 0 ? (
                      <>
                        <TableBody>
                          {items.map((customer, idx) => (
                            <TableRow hover key={idx}>
                              <ThemeProvider theme={theme}>
                                <TableCell>
                                  <div>
                                    <>
                                      <Button
                                        key={`${customer.id}`}
                                        onClick={() => handleRedirect(customer)}
                                        variant="contained"
                                        size="large"
                                        className={classes.btnRincian}
                                        style={{
                                          backgroundColor: getColor,
                                        }}
                                      >
                                        <ThemeProvider theme={theme}>
                                          <Typography
                                            style={{
                                              textTransform: "none",
                                              color: "#FFFFFF",
                                              fontSize: "12px",
                                            }}
                                          >
                                            See Detail
                                          </Typography>
                                        </ThemeProvider>
                                      </Button>
                                    </>
                                  </div>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography
                                    variant="subtitle2"
                                    className={classes.bodyTextBlack}
                                  >
                                    {customer.song_title}
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography
                                    variant="subtitle2"
                                    className={classes.bodyTextBlack}
                                  >
                                    {customer.composer}
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography
                                    variant="subtitle2"
                                    className={classes.bodyTextBlack}
                                  >
                                    {customer.iswc}
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography
                                    variant="subtitle2"
                                    className={classes.bodyTextBlack}
                                  >
                                    {customer.iswc}
                                  </Typography>
                                </TableCell>
                              </ThemeProvider>
                            </TableRow>
                          ))}
                        </TableBody>
                      </>
                    ) : (
                      <>
                        <TableBody>
                          <TableRow>
                            <TableCell align="center" colSpan={9}>
                              <Typography
                                variant="subtitle2"
                                className={classes.bodyTextBlack}
                              >
                                No Data
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </>
                    )}
                  </>
                )}
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <Grid
            alignContent="space-between"
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <ThemeProvider theme={theme}>
                <div style={{ display: "flex" }}>
                  <div style={{ marginTop: "7px" }}>
                    <Typography className={classes.paginationText}>
                      Item per halaman:
                    </Typography>
                  </div>{" "}
                  <div style={{ marginLeft: "10px" }}>{pageSizePagination}</div>
                </div>
              </ThemeProvider>
            </Grid>

            <Grid item>
              <Pagination
                count={totalData}
                shape="rounded"
                className={classes.pagination}
                page={page}
                onChange={(event, page) => handleChangePage(page)}
              />
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    </div>
  );
}

Results.propTypes = {
  className: PropTypes.string,
  customers: PropTypes.array,
};

Results.defaultProps = {
  customers: [],
};

export default Results;
