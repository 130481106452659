import { InputAdornment, styled, TextField } from "@mui/material";

const NumberInput = ({
  label,
  required,
  value,
  onChange,
  startAdornment,
  endAdornment,
  width,
  placeholder,
  disabled,
}) => {
  return (
    <CustomTextField
      size="small"
      fullWidth
      value={value ? value : ""}
      onChange={onChange}
      customWidth={width}
      placeholder={placeholder || "0"}
      disabled={disabled}
      label={label}
      required={required}
      InputProps={{
        startAdornment: startAdornment && (
          <InputAdornment position="start">{startAdornment}</InputAdornment>
        ),
        endAdornment: endAdornment && (
          <InputAdornment position="end">{endAdornment}</InputAdornment>
        ),
        inputMode: "number",
      }}
    />
  );
};

const CustomTextField = styled(TextField)(({ customWidth }) => ({
  width: customWidth,
}));
export default NumberInput;
