import { Box, Typography } from "@mui/material";

const SectionLabel = ({ title, subTitle, ...rest }) => (
  <Box {...rest}>
    {title && (
      <Typography fontSize="18px" fontWeight={700}>
        {title}
      </Typography>
    )}
    {subTitle && (
      <Typography fontSize="14px" color="#8F8F8F">
        {subTitle}
      </Typography>
    )}
  </Box>
);

export default SectionLabel;
