import { Card, CardContent, Chip, Container, Divider } from "@material-ui/core";
import { FiberManualRecord } from "@material-ui/icons";
import { Box, Grid } from "@mui/material";
import axios from "axios";
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import {
  ArrayChip,
  AutoCompleteComponent,
  CurrencyDisplay,
  ModalError,
  Page,
  PrimaryButton,
  SearchTextInput,
  SectionLabel,
  SelectInput,
  SkeletonComponent,
  SongUsageTrendSummarytCard,
  YearPicker,
} from "components";
import { InnoTableV2 } from "inno-ui";
import { HeaderTitle } from "layouts";
import { getTotalPage } from "lib";
import _, { debounce } from "lodash";
import moment from "moment-timezone";
import { useCallback, useEffect, useRef, useState } from "react";
import { Bar } from "react-chartjs-2";
import { CSVLink } from "react-csv";
import { useHistory } from "react-router";
import { globalStyles } from "styles";
import { getErrors } from "utils";
import { hardBaseUrl } from "../../../services/urlConstant";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const ReviewLagu = () => {
  const classes = globalStyles();
  const history = useHistory();
  const isFirstLoad = useRef(true);
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const [loadingPage, setLoadingPage] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [queryParams, setQueryParams] = useState({
    page: 1,
    size: 10,
    search: "",
    sort: "song_title",
    dsp_id: localStorage.getItem("idDsp") || "",
    publisher_id: localStorage.getItem("publisher_id") || "",
    month: "",
    year: moment().format("YYYY"),
  });
  const [tableTotalPage, setTableTotalPage] = useState(1);
  const [chartReport, setChartReport] = useState({
    revenue: 0,
    traffic: 0,
    month: "",
    totalRevenue: 0,
    totalTraffic: 0,
  });
  const [optionDsp, setOptionDsp] = useState([]);
  const [dspInfo, setDspInfo] = useState();
  const [optionPublisher, setOptionPublisher] = useState([]);
  const [csvArray, setCsvArray] = useState([]);
  const [dataSet, setDataSet] = useState({
    labels: [],
    datasets: [],
  });
  let selectedDsp = optionDsp?.find(
    dsp => dsp.dsp_id === Number(queryParams?.dsp_id)
  );
  let dspColor = selectedDsp?.color || "#111827";
  let dspName = selectedDsp?.name;

  const options = {
    parsing: {
      header: true,
      dynamicTyping: true,
      skipEmptyLines: true,
      delimiter: ",",
    },
    responsive: true,

    interaction: {
      mode: "index",
      intersect: false,
    },
    hover: {
      mode: "nearest",
      intersect: true,
    },

    plugins: {
      datalabels: {
        formatter: function() {
          return "";
        },
      },
      layout: {},
      legend: {
        display: false,
      },
      title: {
        display: false,
        text: "Chart.js Line Chart",
      },
      subtitle: {
        display: false,
        text: "Custom Chart Subtitle",
      },
      tooltip: {},
    },
  };

  const handleChangeQueryParams = (value, key) => {
    setQueryParams(currentState => ({
      ...currentState,
      ...(key !== "page" && { page: 1 }),
      [key]: value,
    }));
  };

  const getDataTable = async () => {
    try {
      const res = await axios.get(`${hardBaseUrl}/publisher/song-usage`, {
        headers,
        params: queryParams,
      });
      const { data, meta } = res?.data;
      setDataTable(data || []);
      const pageCount = getTotalPage(meta?.total, queryParams?.size);
      setTableTotalPage(pageCount || 1);
    } catch (error) {
      ModalError(getErrors(error?.response));
    }
  };
  const debounceDataTable = useCallback(
    debounce(() => {
      getDataTable();
    }, 500),
    [queryParams]
  );
  const getCsvArray = async () => {
    try {
      setLoadingPage(true);
      const res = await axios.get(
        `${hardBaseUrl}/publisher/song-usage/download`,
        {
          headers,
          params: queryParams,
        }
      );
      setCsvArray(res?.data?.data || []);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  const getOptionDashboard = async () => {
    try {
      const res = await axios.get(`${hardBaseUrl}/association/publishers`, {
        headers,
      });
      const modifiedData = res?.data?.data?.map(item => ({
        ...item,
        id: item?.publisher_id,
        label: item?.name,
      }));
      setOptionPublisher(modifiedData);
    } catch (error) {
      ModalError(getErrors(error?.response));
    }
  };
  const getOptionDsp = async () => {
    try {
      const res = await axios.get(`${hardBaseUrl}/association/dsps`, {
        headers,
      });
      setOptionDsp(res.data.data);
    } catch (error) {
      ModalError(getErrors(error?.response));
    }
  };
  const getChartData = async () => {
    try {
      const res = await axios.get(`${hardBaseUrl}/association/dashboard`, {
        headers,
        params: {
          dsp_id: queryParams?.dsp_id,
          year: queryParams?.year,
          publisher_id: queryParams?.publisher_id,
        },
      });
      if (res.data.data) {
        let newFind = await _.find(res.data.data, function(o) {
          return o.publisher.publisher_id === Number(queryParams?.publisher_id);
        });
        let newArrFind = [];
        newArrFind.push(newFind);
        setDspInfo(newFind.publisher.name);
        let tempSelectedChart = await _.find(newFind.chart, function(o) {
          return o.type === "total";
        });
        let getTotalListener = tempSelectedChart.listener;
        const sumEveryListener = getTotalListener;

        let getTotalRoyalty = tempSelectedChart.royalty;
        const sumEveryRoyalty = getTotalRoyalty;

        setChartReport(prev => ({
          ...prev,
          totalRevenue: sumEveryRoyalty,
          totalTraffic: sumEveryListener,
        }));

        const date = newFind.chart.map(data => data.date);

        const month = date.map(data => data.split("-")[1]);

        const year = date.map(data => data.split("-")[0]);
        const yearNow = queryParams?.year;
        const year2 = year.map(data => data.slice(-2));

        const getDateOld = monthCode => {
          const monthMap = {
            "01": "Jan",
            "02": "Feb",
            "03": "Mar",
            "04": "Apr",
            "05": "May",
            "06": "Jun",
            "07": "Jul",
            "08": "Aug",
            "09": "Sep",
            "10": "Oct",
            "11": "Nov",
            "12": "Dec",
          };
          return monthMap[monthCode] || "Jan";
        };

        let lengthOfData = newFind.chart.length;
        let last = lengthOfData - 1;
        const monthYearArray = month.map((data, index) => {
          let checkName = "";
          if (index === last) {
            checkName = `Total ${yearNow}`;
          } else {
            checkName = `${getDateOld(data)} ${year2[index]}`;
          }
          return checkName;
        });
        let resultFromType = [];
        if (localStorage.getItem("type_revenue") === "revenue") {
          resultFromType = newFind.chart.map(data => {
            return data.royalty;
          });
        } else {
          resultFromType = newFind.chart.map(data => {
            return data.listener;
          });
        }

        const data = {
          labels: monthYearArray,
          datasets: [
            {
              label: dspInfo,
              data: resultFromType,
              borderColor: dspColor,
              backgroundColor: dspColor,
              fill: false,
              lineTension: 0.2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: "#54B371",
              pointBorderColor: "#54B371",
              pointBorderWidth: 1,
              pointHoverRadius: 5,
              pointHoverBackgroundColor: "#fff",
              pointHoverBorderColor: "#54B371",
              pointHoverBorderWidth: 2,
              pointRadius: 1,
              pointHitRadius: 10,
            },
          ],
        };
        setDataSet(data);
      } else {
        setDataSet({
          labels: [],
          datasets: [],
        });
      }
    } catch (error) {
      new Error(error);
    }
  };
  const fetchData = async () => {
    setLoadingPage(true);
    const promises = [];
    if (isFirstLoad.current) {
      promises.push(getOptionDsp());
      promises.push(getOptionDashboard());
    }
    promises.push(getChartData());
    promises.push(getDataTable());

    try {
      await Promise.all(promises);
    } catch (error) {
      ModalError(error, "Error fetching data");
    } finally {
      setLoadingPage(false);
      isFirstLoad.current = false;
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    if (!isFirstLoad.current) {
      const isSearching = Boolean(queryParams?.search);
      if (isSearching) {
        debounceDataTable();
        return () => {
          debounceDataTable.cancel();
        };
      } else {
        fetchData();
      }
    }
  }, [queryParams, debounceDataTable]);

  return (
    <Page className={classes.root} title="Song Usage Review">
      {loadingPage ? (
        <SkeletonComponent variant="wave" />
      ) : (
        <Container maxWidth={false}>
          <HeaderTitle title="Song Usage" breadcrumbData={breadcrumbData} />
          <Divider className={classes.divider} />
          <Card className={classes.containerFilter}>
            <CardContent>
              <Grid container spacing={1}>
                <Grid item>
                  <SelectInput
                    label="DSP"
                    value={queryParams?.dsp_id}
                    placeholder="None"
                    onChange={e =>
                      handleChangeQueryParams(e?.target?.value, "dsp_id")
                    }
                    options={optionDsp}
                    optionKey="dsp_id"
                    optionLabel="name"
                    width={300}
                  />
                </Grid>
                <Grid item>
                  <AutoCompleteComponent
                    label="Publisher"
                    options={optionPublisher}
                    value={
                      optionPublisher.find(
                        option => option.id === queryParams?.publisher_id
                      ) || null
                    }
                    onChange={value =>
                      handleChangeQueryParams(value, "publisher_id")
                    }
                    size="small"
                    width={350}
                  />
                </Grid>
                <Grid item>
                  <YearPicker
                    label="Year"
                    onChange={date => handleChangeQueryParams(date, "year")}
                    value={queryParams?.year}
                    width={300}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Box my="24px">
            <SectionLabel title="Trend of Song Usage" />
          </Box>
          <Box
            border="1px solid #ebebeb"
            borderRadius="5px"
            padding="50px 20px"
          >
            <Box marginBottom="20px">
              {queryParams?.dsp_id && (
                <Chip
                  size="small"
                  label={dspName}
                  icon={
                    <FiberManualRecord
                      className={classes?.chipIndicator}
                      style={{ color: dspColor }}
                    />
                  }
                  style={{
                    color: dspColor,
                    backgroundColor: `${dspColor}10`,
                  }}
                />
              )}
            </Box>
            <Bar options={options} data={dataSet} plugins={[]} />
          </Box>
          <SongUsageTrendSummarytCard chartReport={chartReport} />
          <Grid container spacing={1} justifyContent="space-between" my="16px">
            <Grid item>
              <SearchTextInput
                placeholder="Song title, Composer"
                value={queryParams?.search}
                onChange={e =>
                  handleChangeQueryParams(e?.target?.value, "search")
                }
              />
            </Grid>
            <Grid item>
              <Grid container columnSpacing={1} alignItems="center">
                <Grid item>
                  <SelectInput
                    label="Month"
                    value={queryParams?.month}
                    placeholder="None"
                    onChange={e =>
                      handleChangeQueryParams(e?.target?.value, "month")
                    }
                    options={monthList}
                    optionKey="id"
                    optionLabel="name"
                    width={200}
                  />
                </Grid>
                <Grid item>
                  <SelectInput
                    label="Sort"
                    value={queryParams?.sort}
                    placeholder="None"
                    onChange={e =>
                      handleChangeQueryParams(e?.target?.value, "sort")
                    }
                    options={sortList}
                    optionKey="value"
                    optionLabel="title"
                    width={200}
                  />
                </Grid>
                <Grid item>
                  <PrimaryButton
                    size="large"
                    label="Download PDF"
                    onClick={() =>
                      history.push({
                        pathname: "/admin/review-lagu-association/print-report",
                        state: {
                          chartReport,
                          queryParams,
                          dataTable,
                          dspColor,
                          dspName,
                          dataSet,
                        },
                      })
                    }
                  />
                </Grid>
                <Grid item>
                  <CSVLink data={csvArray} filename="song_usage">
                    <PrimaryButton
                      size="large"
                      label="Download CSV"
                      onClick={getCsvArray}
                    />
                  </CSVLink>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Box my="16px">
            <InnoTableV2
              isLoading={false}
              columns={columnTable}
              items={dataTable}
              page={queryParams?.page}
              rowsPerPage={queryParams?.size}
              totalPage={tableTotalPage}
              handleChangePage={(_, page) =>
                handleChangeQueryParams(page, "page")
              }
              handleChangeRowsPerPage={e =>
                handleChangeQueryParams(e?.target?.value, "size")
              }
              isHaveAction
              renderAction={item => (
                <PrimaryButton
                  label="See Detail"
                  onClick={() =>
                    history.push({
                      pathname: `/admin/review-lagu-association/${item?.song_id}`,
                      state: {
                        song: item,
                        queryParams,
                      },
                    })
                  }
                  width={110}
                />
              )}
            />
          </Box>
        </Container>
      )}
    </Page>
  );
};

const breadcrumbData = [
  {
    label: "Home",
    link: "/admin/dashboard-association",
  },
  {
    label: "Song Usage",
    active: true,
  },
];
const columnTable = [
  {
    name: "title",
    title: "Song Title",
    renderText: item => item || "-",
  },
  {
    name: "iswc_code",
    title: "ISWC",
    renderText: item => item || "-",
  },
  {
    name: "composers",
    title: "Composer/Author",
    renderText: item => <ArrayChip list={item} max={2} />,
  },
  {
    name: "listener",
    title: "Traffic",
    headerAlign: "right",
    renderText: item => <CurrencyDisplay value={item} />,
  },
  {
    name: "base_currency_revenue",
    title: "Revenue",
    headerAlign: "right",
    renderText: item => (
      <CurrencyDisplay value={item} decimalScale={2} prefix="Rp" />
    ),
  },
];
const sortList = [
  {
    id: 0,
    title: "Song Title ASC",
    value: "song_title",
  },
  {
    id: 1,
    title: "Song Title DSC",
    value: "song_title-",
  },
  {
    id: 2,
    title: "Revenue ASC",
    value: "revenue",
  },
  {
    id: 3,
    title: "Revenue DSC",
    value: "revenue-",
  },
];
const monthList = [
  {
    id: 1,
    name: "January",
  },
  {
    id: 2,
    name: "February",
  },
  {
    id: 3,
    name: "March",
  },
  {
    id: 4,
    name: "April",
  },
  {
    id: 5,
    name: "May",
  },
  {
    id: 6,
    name: "June",
  },
  {
    id: 7,
    name: "July",
  },
  {
    id: 8,
    name: "August",
  },
  {
    id: 9,
    name: "September",
  },
  {
    id: 10,
    name: "October",
  },
  {
    id: 11,
    name: "November",
  },
  {
    id: 12,
    name: "December",
  },
];

export default ReviewLagu;
