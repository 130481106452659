import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, Typography, Tooltip } from "@material-ui/core";
import { Image } from "@material-ui/icons";
const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    padding: 16,
    alignItems: "center",
    justifyContent: "center",
    border: "1px dashed #9AA2B1",
    cursor: "pointer",
  },
  input: {
    display: "none",
  },
  image: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
}));
function InputFileImage({ onChange, selectedImage }) {
  const classes = useStyles();
  const classForImage = selectedImage ? classes.image : "";
  return (
    <>
      <input
        accept="image/*"
        className={classes.input}
        id="contained-button-file"
        required
        type="file"
        onChange={onChange}
      />
      <label htmlFor="contained-button-file" className={classForImage}>
        {selectedImage ? (
          <Tooltip title="Click to Change Image">
            <Card>
              <img src={selectedImage} alt="Selected" width="200" />
            </Card>
          </Tooltip>
        ) : (
          <Card className={classes.root}>
            <Image />
            <Typography>Upload Picture or Search Image</Typography>
          </Card>
        )}
      </label>
    </>
  );
}
export default InputFileImage;
