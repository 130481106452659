import { Container, Divider } from "@material-ui/core";
import axios from "axios";
import { ModalError, ModalSuccess, Page, SkeletonComponent } from "components";
import { HeaderTitle } from "layouts";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { globalStyles } from "styles";
import { getErrors } from "utils";
import { hardBaseUrl } from "../../../services/urlConstant";
import ContractOriginalPublisherForm from "./Components/ContractOriginalPublisherForm";

function EditContract() {
  const classes = globalStyles();
  const history = useHistory();
  const { id } = useParams();
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const [loadingPage, setLoadingPage] = useState(false);
  const [pageDetail, setPageDetail] = useState({});

  const getDetail = async () => {
    try {
      setLoadingPage(true);
      const res = await axios.get(
        `${hardBaseUrl}/publisher/contract/original-publisher/${id}`,
        { headers }
      );
      setPageDetail(res?.data?.data);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  const handlSubmitData = async ({ modifiedPayload }) => {
    try {
      setLoadingPage(true);
      await axios.put(
        `${hardBaseUrl}/publisher/contract/original-publisher/${id}`,
        modifiedPayload,
        { headers }
      );
      ModalSuccess("Successfully Update Contract").then(() => history.goBack());
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  useEffect(() => {
    getDetail();
  }, []);

  return (
    <Page className={classes.root} title="Edit Contract Original Publisher">
      {loadingPage ? (
        <SkeletonComponent variant="wave" />
      ) : (
        <Container maxWidth={false}>
          <HeaderTitle
            title="Update Contract"
            breadcrumbData={breadcrumbData}
            backButton
          />
          <Divider className={classes.divider} />
          <ContractOriginalPublisherForm
            handlSubmitData={handlSubmitData}
            preload={pageDetail}
          />
        </Container>
      )}
    </Page>
  );
}
const breadcrumbData = [
  {
    label: "Home",
    link: "/admin/dashboard",
  },
  {
    label: "Contract Original Publisher",
    link: "/admin/kontrak-original-publisher",
  },
  {
    label: "Update Contract",
    active: true,
  },
];

export default EditContract;
