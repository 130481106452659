import { ArrowRightOutlined } from "@ant-design/icons";
import { useMediaQuery, useTheme } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Col, Row, Spin } from "antd";
import Axios from "axios";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { hardBaseUrl, hardSubDomain } from "../../services/urlConstant";
import Chip from "../Chip/Chip";
import Publisher from "../Publisher";
import "./Berita.css";
const getColorClass = () => {
  let postFix = "#9545EB";
  if (hardSubDomain === "nadaku") {
    postFix = "#EB0606";
  }
  return postFix;
};
const useStyles = makeStyles(() => ({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 190,
  },
}));
export default function Berita(params) {
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { dataNews } = params;
  const [berita, setBerita] = useState([]);
  const [button, setButton] = useState("Semua Kategori");
  const [loading, setLoading] = useState(false);
  const [dataHead, setDataHead] = useState({});
  useEffect(() => {
    const getBerita = async () => {
      let url = "/article/public?size=3";
      url =
        button === "Semua Kategori"
          ? url
          : button === "Berita"
            ? "/article/public?type=berita"
            : button === "Iklan"
              ? "/article/public?type=iklan"
              : button === "Layanan"
                ? "/article/public?type=layanan"
                : url;
      try {
        setLoading(true);
        const response = await Axios.get(hardBaseUrl + url);
        if (response.data.message === "success") {
          if (response.data.data !== null) {
            setDataHead(response.data.data[0]);
            setBerita(response.data.data);
          }
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        }
      } catch (error) {
        new Error(error);
      }
    };
    getBerita();
  }, [button]);
  const handleDetailBerita = () => {
    history.push({
      pathname: `/artikel/detail/${dataHead.article_id}`,
      state: dataHead,
    });
  };
  const handleDetailBeritaMobile = res => {
    setDataHead(res);
    history.push({
      pathname: `/artikel/detail/${dataHead.article_id}`,
      state: dataHead,
    });
  };
  const isLoading = {
    display: "flex",
    alignItem: "center",
    justifyContent: "center",
  };
  const Container = ({
    img,
    type,
    title,
    publisher_name,
    range_date,
    onClick,
  }) => {
    return (
      <div style={{ width: "514px", margin: "16px" }} onClick={onClick}>
        <Card
          style={{ height: "500px", width: "300px", border: "1px solid black" }}
        >
          <CardMedia className={classes.media} image={img} title={img} />
          <CardContent>
            <Chip title={"News"} type={type} />
            <h2
              style={{
                marginTop: "6px",
                fontWeight: 500,
                fontSize: "20px",
                lineHeight: "30px",
              }}
            >
              {title}
            </h2>
            <div style={{ marginTop: "-5px" }}>
              <Publisher
                fontSize="14px"
                publisher={publisher_name}
                day={range_date}
              />
            </div>
          </CardContent>
        </Card>
      </div>
    );
  };
  return (
    <div
      className={isMobile ? null : "container"}
      style={isMobile ? { backgroundColor: "white" } : null}
    >
      <div className="berita">
        <div style={isMobile ? { paddingLeft: "20px" } : null}>
          <p
            className="title"
            style={{
              color: getColorClass(),
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: "16px",
              lineHeight: "24px",
            }}
          >
            NEWS
          </p>
          <p
            style={{
              fontWeight: 500,
              fontSize: "36px",
              lineHeight: "44px",
              color: "#111827",
              marginTop: "7px",
              marginBottom: "35px",
            }}
          >
            Latest news about music
          </p>
        </div>

        {isMobile ? null : (
          <Row style={loading && isLoading}>
            {loading ? (
              <Spin size="large" />
            ) : (
              <>
                {berita?.length > 0 ? (
                  <div
                    onClick={handleDetailBerita}
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      width: "100%",
                    }}
                  >
                    <Col
                      span={9}
                      style={{ width: "100%", paddingRight: "5px" }}
                    >
                      <img
                        src={dataHead.banner_image}
                        alt=""
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          height: "300px",
                          objectFit: "cover",
                        }}
                      />
                    </Col>
                    <Col
                      span={15}
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div className="content-right">
                        <Chip
                          title={dataHead.type === "berita" ? "News" : ""}
                          type={dataHead.type}
                        />
                        <h2>{dataHead.title}</h2>
                        <p
                          className="desc"
                          dangerouslySetInnerHTML={{
                            __html:
                              dataHead?.content?.length > 700
                                ? dataHead.content.substring(0, 700) +
                                "<a href=\"\"> see more...</a>"
                                : dataHead.content,
                          }}
                        />
                        <Publisher
                          publisher={dataHead.publisher_name}
                          day={dataHead.range_date}
                          fontSize="14px"
                          dot={4}
                        />
                      </div>
                    </Col>
                  </div>
                ) : (
                  "Tidak ada berita"
                )}
              </>
            )}
          </Row>
        )}
        {!dataNews && (
          <div style={{ marginTop: "50px", marginBottom: "50px" }}>
            <hr style={{ border: "1px solid #E4E7EB", marginBottom: "24px" }} />
            {["Semua Kategori", "Berita", "Iklan", "Layanan"].map(res => {
              return (
                <Button
                  style={{ marginRight: "15px", borderRadius: "20px" }}
                  type={button === res ? "" : "text"}
                  onClick={() => setButton(res)}
                  key={res}
                  className="button-page"
                >
                  {res}
                </Button>
              );
            })}
            <hr style={{ border: "1px solid #E4E7EB", marginTop: "24px" }} />
          </div>
        )}

        {isMobile ? (
          <div
            style={{
              width: "100%",
              overflowX: "auto",
              display: "flex",
              height: "600px",
            }}
          >
            {loading ? (
              <Spin size="large" />
            ) : (
              <>
                {berita.map((res, i) => {
                  return (
                    <Container
                      onClick={() => handleDetailBeritaMobile(res)}
                      key={i}
                      img={res.banner_image}
                      type={res.type}
                      title={res.title}
                      publisher_name={res.publisher_name}
                      range_date={res.range_date}
                    />
                  );
                })}
              </>
            )}
          </div>
        ) : (
          <Row
            style={loading ? isLoading : { marginTop: "0px", height: "480px" }}
            gutter={8}
          >
            {loading ? (
              <Spin size="large" />
            ) : (
              <>
                {berita.map((res, i) => {
                  return (
                    <Col
                      span={8}
                      key={i}
                      style={{ paddingTop: 30, cursor: "pointer" }}
                      onClick={() => setDataHead(res)}
                    >
                      <div className="wrapper">
                        <img
                          src={res.banner_image}
                          alt=""
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            height: "279px",
                            objectFit: "cover",
                          }}
                        />
                        <div style={{ marginTop: "15px" }}>
                          <Chip title={"News"} type={res.type} />
                          <h2
                            style={{
                              marginTop: "6px",
                              fontWeight: 500,
                              fontSize: "20px",
                              lineHeight: "30px",
                            }}
                          >
                            {res.title}
                          </h2>
                          <div style={{ marginTop: "-5px" }}>
                            <Publisher
                              fontSize="14px"
                              publisher={res.publisher_name}
                              day={res.range_date}
                            />
                          </div>
                        </div>
                      </div>
                    </Col>
                  );
                })}
              </>
            )}
          </Row>
        )}
        {dataNews && (
          <h3
            style={{
              textAlign: "center",
              marginTop: "0px",
              marginBottom: "-50px",
            }}
          >
            <Link to={"/berita"} style={{ color: getColorClass() }}>
              See more news <ArrowRightOutlined />
            </Link>
          </h3>
        )}
      </div>
    </div>
  );
}
