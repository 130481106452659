import { Container } from "@material-ui/core";
import { Add, ArrowForward } from "@material-ui/icons";
import { Box, Divider, Grid } from "@mui/material";
import axios from "axios";
import {
  BalanceCard,
  ButtonGroupTop,
  CurrencyDisplay,
  ModalError,
  Page,
  PrimaryButton,
  PrimaryTextButton,
  SectionLabel,
  SkeletonComponent,
  TableAction,
} from "components";
import { InnoTableV2 } from "inno-ui";
import { HeaderTitle } from "layouts";
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import { globalStyles } from "styles";
import { formatDate, getErrors, textCapitalization } from "utils";
import { hardBaseUrl } from "../../../services/urlConstant";

const PaymentPencipta = () => {
  const classes = globalStyles();
  const history = useHistory();
  const isFirstLoad = useRef(true);
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const [loadingPage, setLoadingPage] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [queryParams, setQueryParams] = useState({
    page: 1,
    per_page: 10,
  });
  const [tableTotalPage, setTableTotalPage] = useState(1);
  const [balance, setBalance] = useState(0);

  const handleChangeQueryParams = (value, key) => {
    setQueryParams(currentState => ({
      ...currentState,
      ...(key !== "page" && { page: 1 }),
      [key]: value,
    }));
  };

  const getPaymentBalance = async () => {
    try {
      const res = await axios.get(
        `${hardBaseUrl}/composer/transaction/payment/get-balance`,
        { headers }
      );
      setBalance(res?.data?.data?.balance_number);
    } catch (error) {
      ModalError(getErrors(error?.response));
    }
  };
  const getDataTable = async () => {
    try {
      setLoadingPage(true);
      const res = await axios.get(
        `${hardBaseUrl}/composer/transaction/payment`,
        { headers, params: queryParams }
      );
      const { payment_data, query } = res?.data?.data;
      setDataTable(payment_data || []);
      setTableTotalPage(query?.total_page || 1);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  const fetchData = async () => {
    setLoadingPage(true);
    const promises = [];
    promises.push(getPaymentBalance());
    promises.push(getDataTable());

    try {
      await Promise.all(promises);
    } catch (error) {
      ModalError(error, "Error fetching data");
    } finally {
      setLoadingPage(false);
      isFirstLoad.current = false;
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    if (!isFirstLoad.current) {
      getDataTable();
    }
  }, [queryParams]);

  return (
    <Page className={classes.root} title="Payment">
      {loadingPage ? (
        <SkeletonComponent variant="wave" />
      ) : (
        <Container maxWidth={false}>
          <HeaderTitle title="Transaction" breadcrumbData={breadcrumbData} />
          <ButtonGroupTop />
          <Divider className={classes?.flatDivider} />
          <SectionLabel title="Payment Transaction" my="16px" />
          <BalanceCard value={balance} />
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            my="16px"
          >
            <PrimaryTextButton
              label="See Balance Mutation"
              onClick={() =>
                history.push({ pathname: "/pencipta/payment-mutation" })
              }
              endIcon={<ArrowForward />}
            />
            <PrimaryButton
              label="Create Transaction"
              onClick={() => history.push("/pencipta/payment/add")}
              startIcon={<Add />}
              size="large"
            />
          </Grid>
          <InnoTableV2
            isLoading={false}
            columns={columnTable}
            items={dataTable}
            page={queryParams?.page}
            rowsPerPage={queryParams?.per_page}
            totalPage={tableTotalPage}
            handleChangePage={(_, page) =>
              handleChangeQueryParams(page, "page")
            }
            handleChangeRowsPerPage={e =>
              handleChangeQueryParams(e?.target?.value, "per_page")
            }
            isHaveAction
            renderAction={item => (
              <TableAction
                handleView={() =>
                  history.push({
                    pathname: `/pencipta/payment/${item?.publisher_transaction_id}`,
                    state: {
                      customer: item,
                    },
                  })
                }
                tooltipView="View"
              />
            )}
          />
        </Container>
      )}
    </Page>
  );
};

const breadcrumbData = [
  {
    label: "Transaction",
    link: "/pencipta/payment",
  },
  {
    label: "Payment",
    active: true,
  },
];
const labelStatus = {
  paid: "Paid",
  unpaid: "Unpaid",
  waiting_for_approval: "Waiting for Approval",
  active: "Active",
  approved: "Approved",
  rejected: "Rejected",
};
const columnTable = [
  {
    name: "date",
    title: "Date",
    renderText: item => item && formatDate(item),
  },
  {
    name: "publisher_name",
    title: "Publisher",
    renderText: item => item || "-",
  },
  {
    name: "value",
    title: <Box textAlign="right">Value</Box>,
    renderText: item => <CurrencyDisplay value={item} prefix="Rp" />,
  },
  {
    name: "remaining_balance",
    title: "Remaining Balance",
    renderText: item => item || "-",
  },
  {
    name: "note",
    title: "Notes",
    renderText: item => item || "-",
  },
  {
    name: "status",
    title: "Status",
    renderText: item => labelStatus?.[item] || textCapitalization(item) || "-",
  },
];
export default PaymentPencipta;
