export const currencyFormatting = ({ value, currency }) =>
  new Intl.NumberFormat("us-US", {
    style: "currency",
    currency: currency || "IDR",
    minimumFractionDigits: 0,
  }).format(value);

export const numberFormatting = ({ value }) =>
  new Intl.NumberFormat("us-US", {
    minimumFractionDigits: 0,
  }).format(value);

export default {
  currencyFormatting,
  numberFormatting,
};
