import React, { useState, useEffect } from "react";
import { Page, SkeletonComponent } from "components";
import HeaderPage from "../../../../layouts/Header/HeaderPage";
import { ButtonGroupTop } from "../../../../components/atoms/Button";
import { Add } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import Swal from "sweetalert2";
import { hardBaseUrl } from "../../../../services/urlConstant";
import {
  Container,
  TextField,
  InputAdornment,
  Button,
  Typography,
  FormControl,
  Box,
  Grid,
} from "@material-ui/core";
import { InnoTableV2 } from "inno-ui";
import { useHistory } from "react-router";

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  content: {
    marginTop: "20px",
  },
  trashIcon: {
    color: "#9AA2B1",
    cursor: "pointer",
  },
  contentAdd: {
    marginTop: "20px",
    marginLeft: "20px",
    marginRight: "20px",
    fontFamily: ["Inter"].join(","),
  },
  buttonAdd: {
    height: 44,
    width: "auto",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "24px",
    backgroundColor: "black",
    color: "white",
    textTransform: "none",
    "&:hover, &.MuiIconButton": {
      background: "#374151 !important",
    },
  },
  buttonDelete: {
    fontSize: "16px",
    backgroundColor: "black",
    color: "white",
    marginLeft: 10,
    textTransform: "none",
    "&:hover, &.MuiIconButton": {
      background: "#374151 !important",
    },
  },
  buttonCancel: {
    color: "black",
    marginLeft: 10,
    textTransform: "none",
    "&:hover, &.MuiIconButton": {
      background: "#374151 !important",
    },
  },
  buttonAction: {
    backgroundColor: "#111827",
    color: "white",
    width: "30px",
    height: "30px",
    padding: "6px",
    borderRadius: "6px",
    "&:hover, &.MuiIconButton": {
      background: "#374151 !important",
    },
  },
  selectPadding: {
    "& .MuiSelect-outlined": {
      paddingTop: "14px",
      paddingBottom: "14px",
      paddingLeft: "20px",
      paddingRight: "30px",
    },
  },
  inputFields: {
    height: "44px",
    paddingTop: "0px",
    paddingBottom: "0px",
    "& .MuiOutlinedInput-root": {
      minWidth: 250,
      height: "44px",
      paddingTop: "0px",
      paddingBottom: "0px",
    },
  },
  boxAddButton: {
    display: "flex",
    margin: "20px 0px",
  },
  containerModal: {
    padding: "1rem",
  },
}));

const columns = [
  {
    name: "territory_name",
    title: "Territory Name",
  },
  {
    name: "territory_type_flag",
    title: "Territory Type",
  },
  {
    name: "territory_code",
    title: "TIS-A",
  },
  {
    name: "territory_ext_code",
    title: "TIS-A-EXT",
    renderText: territory_ext_code =>
      territory_ext_code ? <Typography>{territory_ext_code}</Typography> : "-",
  },
  {
    name: "countries",
    title: "Country",
  },
];

function Territory() {
  const classes = useStyles();
  const history = useHistory();
  const token = localStorage.getItem("token");
  const [state, setState] = useState({
    value: {
      territory_code: "",
      territory_ext_code: "",
      country: [],
    },
    page: 1,
    search: "",
    size: 10,
    listTerritory: [],
    openModal: false,
    totalData: 0,
    loading: false,
    loadingDialog: false,
    openModalDelete: false,
    itemToDelete: null,
  });
  const {
    page,
    search,
    size,
    listTerritory,
    totalData,
    loading,
  } = state;

  const closeDialog = type =>
    setState(currentState => ({
      ...currentState,
      [type]: false,
    }));


  const changeValue = (key, value) =>
    setState(currentState => ({
      ...currentState,
      value: {
        ...currentState.value,
        [key]: value,
      },
    }));

  const changeState = (key, value) =>
    setState(currentState => ({
      ...currentState,
      [key]: value,
    }));

  const deleteTerritory = id => {
    const url = `${hardBaseUrl}/admin/territory/${id}`;
    axios
      .delete(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        Swal.fire({
          title: "Success",
          text: "This territory successfully deleted",
          icon: "success",
          confirmButtonText: "Ok",
        });
        getListTerritory(page, size, search);
      })
      .catch(err => {
        Swal.fire({
          title: "Error",
          text: err,
          icon: "error",
          confirmButtonText: "Ok",
        });
      })
      .finally(() => {
        changeValue("itemToDelete", null);
        closeDialog("openModalDelete");
      });
  };
  const getListTerritory = async (page, size, search) => {
    changeState("loading", true);
    const url = `${hardBaseUrl}/admin/territory`;
    const params = {
      page,
      size,
      search,
    };
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params,
      });
      changeState("listTerritory", response?.data?.data || []);
      changeState("totalData", response?.data?.meta?.total);
      changeState("loading", false);
    } catch (err) {
      changeState("loading", false);
    }
  };

  useEffect(() => {
    getListTerritory(page, size, search);
  }, []);

  useEffect(() => {
    getListTerritory(page, size, search);
  }, [page, size, search]);

  const pagesCount = Math.ceil(totalData / size);
  return (
    <Page className={classes.root} title="Territory">
      {/* <ConfirmationDelete
        openModal={openModalDelete}
        onClose={() => closeDialog("openModalDelete")}
        onSubmit={() => deleteTerritory(itemToDelete)}
      /> */}
      <Container maxWidth={false}>
        <HeaderPage
          title="Parameter"
          breadcrumbs={["Parameter", "Territory"]}
        />
        <ButtonGroupTop />
        <Box className={classes.content}>
          <Grid container justifyContent="space-between" className={classes.boxAddButton}>
            <Grid item>
              <FormControl className={classes.margin} variant="outlined">
                <TextField
                  id="input-with-icon-textfield"
                  variant="outlined"
                  className={classes.inputFields}
                  onChange={e => changeState("search", e?.target?.value)}
                  placeholder="Search"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img
                          alt="Logo"
                          src={
                            require("assets/image-public/images/search.svg")
                              .default
                          }
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item>
              <Button
                onClick={() => history.push("/admin/parameter/territory/add")}
                variant="contained"
                className={classes.buttonAdd}
                startIcon={<Add />}
              >
                Add Territory
              </Button>
            </Grid>
          </Grid>
          {loading ? (
            <SkeletonComponent variant="wave" />
          ) : (
            <InnoTableV2
              idColumnName={"id"}
              page={page}
              deleteName="territory_name"
              handleChangePage={(_, page) => changeState("page", page)}
              handleChangeRowsPerPage={e =>
                changeState("size", e?.target?.value)
              }
              rowsPerPage={size}
              handleEdit={item => {
                changeValue("id", item?.id);
                history.push(`/admin/parameter/territory/edit/${item?.id}`);
              }}
              handleDelete={item => deleteTerritory(item?.id)}
              totalPage={pagesCount}
              isLoading={loading}
              isHaveAction
              columns={columns}
              items={listTerritory}
            />
          )}
        </Box>
      </Container>
    </Page>
  );
}

export default Territory;
