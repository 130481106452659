export const arrayToCommaSeparatedString = (array, key) =>
  key
    ? array?.map(item => item?.[key]).join(",")
    : array?.map(item => item).join(",");

export const filterArrayByKeyValue = (array, value, key) =>
  key
    ? array?.filter(item => item?.[key] !== value?.[key])
    : array?.filter(item => item !== value);

export const prioritizeAndLimitList = (
  fullList,
  selectedItems,
  key,
  maxSize
) => {
  const selectedIds = new Set(selectedItems.map(item => item?.[key]));
  const filteredList = fullList.filter(item => !selectedIds.has(item?.[key]));
  const limitedFilteredList = filteredList.slice(
    0,
    maxSize - selectedItems.length
  );
  return [...selectedItems, ...limitedFilteredList];
};
export const createAllFilterOption = ({ label, key }) => ({
  [key]: "all",
  [label]: "All",
});
export const allOptionsSelected = ({ list, key, selectedFilter }) =>
  list
    .filter(item => item?.[key] !== "all")
    .every(option =>
      selectedFilter.some(selectedItem => selectedItem?.[key] === option?.[key])
    );
export const handleChangeFilterAll = ({
  selectedList,
  checked,
  option,
  key,
  list,
  handleChangeQueryParams,
}) => {
  if (option?.[key] === "all") {
    if (checked) {
      const filteredList = list.filter(item => item?.[key] !== "all");
      const filterParams = arrayToCommaSeparatedString(filteredList, key);
      handleChangeQueryParams(filterParams, key);

      return [...list];
    } else {
      handleChangeQueryParams("", key);
      return [];
    }
  } else {
    let selectedFilter;

    if (checked) {
      selectedFilter = [...selectedList, option];
    } else {
      selectedFilter = filterArrayByKeyValue(selectedList, option, key);
      if (selectedList?.some(item => item?.[key] === "all")) {
        selectedFilter = selectedFilter.filter(item => item?.[key] !== "all");
      }
    }
    if (!checked && selectedList?.some(item => item?.[key] === "all")) {
      selectedFilter = selectedFilter.filter(item => item?.[key] !== "all");
    }

    if (
      allOptionsSelected({ list, key, selectedFilter }) &&
      !selectedFilter.some(item => item?.[key] === "all")
    ) {
      selectedFilter = [...selectedFilter, { [key]: "all" }];
    }

    const filterParams = arrayToCommaSeparatedString(
      selectedFilter.filter(item => item?.[key] !== "all"),
      key
    );
    handleChangeQueryParams(filterParams, key);
    return selectedFilter;
  }
};

export const handleChangeFilterAllAfterSearch = ({
  selectedList,
  checked,
  option,
  key,
  list,
  handleChangeQueryParams,
  params,
}) => {
  let updatedSelection = handleChangeFilterAll({
    selectedList,
    checked,
    option,
    key,
    list,
    handleChangeQueryParams,
  });
  if ([params]?.search) {
    if (checked) {
      const allVisibleOptionsSelected = allOptionsSelected({
        list,
        key,
        selectedFilter: updatedSelection,
      });
      if (
        allVisibleOptionsSelected &&
        !updatedSelection.some(item => item?.[key] === "all")
      ) {
        updatedSelection = [...updatedSelection, { [key]: "all" }];
      }
    } else {
      updatedSelection = updatedSelection.filter(item => item?.[key] !== "all");
    }
  }
  return updatedSelection;
};

export const paginateDataTable = ({ data, page, size }) => {
  const startIndex = (page - 1) * size;
  const endIndex = startIndex + size;
  return data?.slice(startIndex, endIndex);
};

export default {
  arrayToCommaSeparatedString,
  filterArrayByKeyValue,
  prioritizeAndLimitList,
  handleChangeFilterAll,
  handleChangeFilterAllAfterSearch,
  createAllFilterOption,
  allOptionsSelected,
  paginateDataTable,
};
