import { Button, ButtonGroup, Container, Divider } from "@material-ui/core";
import { Box } from "@mui/material";
import axios from "axios";
import { ModalError, Page, SkeletonComponent } from "components";
import { HeaderTitle } from "layouts";
import { getTotalPage } from "lib";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import { globalStyles } from "styles";
import { getErrors } from "utils";
import { hardBaseUrl } from "../../../services/urlConstant";
import ContractActivityForm from "./Components/ContractActivityForm";
import ContractDetailForm from "./Components/ContractDetailForm";
import SupportingDocument from "./Components/SupportingDocument";

const ContractDetail = () => {
  const classes = globalStyles();
  const { id, songId } = useParams();
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const [selectedMenu, setSelectedMenu] = useState(1);
  const [loadingPage, setLoadingPage] = useState(false);
  const [contractDetails, setContractDetails] = useState({});
  const [dataTableDocuments, setDataTableDocuments] = useState([]);
  const [dataTableActivity, setDataTableActivity] = useState([]);
  const [tableTotalPageActivity, setTableTotalPageActivity] = useState(1);
  const [queryParamsDocument, setQueryParamsDocument] = useState({
    page: 1,
    limit: 10,
    sort: "-created_at",
    search: "",
  });
  const [queryParamsActivity, setQueryParamsActivity] = useState({
    page: 1,
    size: 10,
    search: "",
  });
  const handleChangeQueryParamsDocument = (value, key) => {
    setQueryParamsDocument(currentState => ({
      ...currentState,
      [key]: value,
    }));
  };
  const handleChangeQueryParamsActivity = (value, key) => {
    setQueryParamsActivity(currentState => ({
      ...currentState,
      [key]: value,
    }));
  };

  const getDetail = async () => {
    try {
      const res = await axios.get(`${hardBaseUrl}/composer/contract/${id}`, {
        headers,
        params: {
          song_id: songId,
        },
      });
      setContractDetails(res?.data?.data);
    } catch (error) {
      ModalError(getErrors(error?.response));
    }
  };
  const getDataTableDocuments = async () => {
    try {
      setLoadingPage(true);
      const res = await axios.get(
        `${hardBaseUrl}/composer/contract-document/${id}`,
        {
          headers,
          params: queryParamsDocument,
        }
      );
      const { data } = res?.data;
      setDataTableDocuments(data || []);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  const debounceDataTableDocuments = useCallback(
    debounce(() => {
      getDataTableDocuments();
    }, 500),
    [queryParamsDocument]
  );
  const getDataTableActivity = async () => {
    try {
      setLoadingPage(true);
      const res = await axios.get(
        `${hardBaseUrl}/composer/contract-log/${id}`,
        {
          headers,
          params: queryParamsActivity,
        }
      );
      const { data, meta } = res?.data;
      setDataTableActivity(data);
      const pageCount = getTotalPage(meta?.total, queryParamsDocument?.size);
      setTableTotalPageActivity(pageCount || 1);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  const fetchData = async () => {
    setLoadingPage(true);
    const promises = [];
    promises.push(getDetail());
    promises.push(getDataTableDocuments());
    promises.push(getDataTableActivity());

    try {
      await Promise.all(promises);
    } catch (error) {
      ModalError(error, "Error fetching data");
    } finally {
      setLoadingPage(false);
    }
  };

  const renderSelectedMenu = () => {
    switch (selectedMenu) {
      case 1:
        return <ContractDetailForm preload={contractDetails} />;
      case 2:
        return (
          <SupportingDocument
            dataTable={dataTableDocuments}
            queryParams={queryParamsDocument}
            handleChangeQueryParams={handleChangeQueryParamsDocument}
          />
        );
      case 3:
        return (
          <ContractActivityForm
            dataTable={dataTableActivity}
            handleChangeQueryParams={handleChangeQueryParamsActivity}
            queryParams={queryParamsActivity}
            tableTotalPage={tableTotalPageActivity}
          />
        );
      default:
        return "Invalid Menu";
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    const isSearching = Boolean(queryParamsDocument?.search);
    if (isSearching) {
      debounceDataTableDocuments();
      return () => {
        debounceDataTableDocuments.cancel();
      };
    } else {
      getDataTableDocuments();
    }
  }, [queryParamsDocument, debounceDataTableDocuments]);
  useEffect(() => {
    getDataTableActivity();
  }, [queryParamsActivity]);
  
  return (
    <Page className={classes.root} title="Contract Detail">
      {loadingPage ? (
        <SkeletonComponent variant="wave" />
      ) : (
        <Container maxWidth={false}>
          <HeaderTitle
            title="Contract Detail"
            breadcrumbData={breadcrumbData}
            backButton
          />
          <Box my="16px">
            <ButtonGroup>
              {menuContract?.map(({ title, id }) => {
                const selected = id === selectedMenu;
                return (
                  <Button
                    className={
                      selected
                        ? classes.groupButtonSelected
                        : classes?.groupButton
                    }
                    key={id}
                    onClick={() => {
                      setSelectedMenu(id);
                    }}
                  >
                    {title}
                  </Button>
                );
              })}
            </ButtonGroup>
          </Box>
          <Divider className={classes?.divider} />
          {renderSelectedMenu()}
        </Container>
      )}
    </Page>
  );
};

const breadcrumbData = [
  {
    label: "Home",
    link: "/pencipta/dashboard-pencipta",
  },
  {
    label: "Contract",
    link: "/pencipta/kontrak",
  },
  {
    label: "Contract Detail",
    active: true,
  },
];
const menuContract = [
  {
    id: 1,
    title: "Detail",
  },
  {
    id: 2,
    title: "Supporting Documents",
  },
  {
    id: 3,
    title: "Activity",
  },
];

export default ContractDetail;
