import { Container, Divider } from "@material-ui/core";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { useRef } from "react";
import { useLocation } from "react-router-dom";
import {
  ArrayTagChip,
  CurrencyDisplay,
  Page,
  PrimaryButton,
  SecondaryButton,
  SongUsageTrendChart,
  TableCellMultipleRow,
} from "components";
import { HeaderTitle } from "layouts";
import { InnoTableV2 } from "inno-ui";
import { Box } from "@mui/material";
import { useHistory } from "react-router";
import { globalStyles } from "styles";

const PrintReportPage = () => {
  const classes = globalStyles();
  const history = useHistory();
  const inputRef = useRef(null);
  const { state } = useLocation();
  const { chartData, selectedDSP, tableData } = state;

  const handlePrintPDF = async () => {
    html2canvas(inputRef.current).then(canvas => {
      const imgData = canvas.toDataURL("image/png", 1.0);

      const pdf = new jsPDF({
        orientation: "p",
        unit: "px",
        format: "a4",
      });
      pdf.addImage(imgData, "PNG", 0, 60, 440, 300);
      pdf.save("download.pdf");
    });
  };

  const columnTable = [
    {
      name: "dsp",
      title: "DSP",
      renderText: item => (
        <Box gap={1} display="flex">
          <Box width={20} height={20} backgroundColor={item?.color} />
          {item?.name}
        </Box>
      ),
    },

    {
      name: "summaries",
      title: "Product Type",
      renderText: item => (
        <TableCellMultipleRow list={item} itemKey="product_type" />
      ),
    },
    {
      name: "listener",
      title: <span className={classes.rightAlign}>Traffic</span>,
      renderText: item => <CurrencyDisplay value={item} />,
    },
    {
      name: "summaries",
      title: "Original Currency",
      sort_key: "original_currency_revenue",
      headerAlign: "right",
      useSort: true,
      renderText: item => (
        <TableCellMultipleRow
          list={item}
          itemKey="original_currency_revenue"
          type="number"
          prefix={item[0]?.currency_symbol_code}
        />
      ),
    },
    {
      name: "summaries",
      title: "Base Currency",
      sort_key: "base_currency_revenue",
      headerAlign: "right",
      useSort: true,
      renderText: item => (
        <TableCellMultipleRow
          list={item}
          itemKey="base_currency_revenue"
          type="number"
          prefix={item[0]?.currency_symbol_code}
        />
      ),
    },
    {
      name: "summaries",
      title: "Advance DSP",
      sort_key: "advance",
      headerAlign: "right",
      useSort: true,
      renderText: item => (
        <TableCellMultipleRow
          list={item}
          itemKey="advance"
          type="number"
          prefix={item[0]?.currency_symbol_code}
        />
      ),
    },
  ];

  return (
    <Page className={classes.root} title="Print Report">
      <div id="pdfPrint" ref={inputRef}>
        <Container maxWidth={false}>
          <HeaderTitle title="Trend of Song Usage" />
          <Box my={3}>
            <div className={classes?.chartCard}>
              <Box mb={2}>
                {chartData?.datasets.length > 0 && selectedDSP?.length > 0 && (
                  <ArrayTagChip
                    selectionsList={
                      selectedDSP.some(item => item.dsp_id === "all")
                        ? [{ dsp_id: "all", name: "All" }]
                        : selectedDSP
                    }
                    value="name"
                  />
                )}
              </Box>
              <SongUsageTrendChart chartData={chartData} preview />
            </div>
          </Box>
          <Divider className={classes.divider} />
          <InnoTableV2
            columns={columnTable}
            items={tableData}
            isLoading={false}
          />
        </Container>
      </div>
      <Box display="flex" justifyContent="flex-end" gap={2} m={3}>
        <SecondaryButton label="Back" onClick={() => history.goBack()} />
        <PrimaryButton label="Print Report" onClick={handlePrintPDF} />
      </Box>
    </Page>
  );
};

export default PrintReportPage;
