import { Box } from "@mui/material";

export const validString = str => typeof str === "string";
export const emptyText = text => text || "-";
export const wordCapitalize = str =>
  validString(str) ? str.charAt(0).toUpperCase() + str.slice(1) : "-";
export const textCapitalization = text => (
  <Box textTransform="capitalize">{text}</Box>
);

export const getInitials = (name = "") => {
  const words = name
    .trim()
    .replace(/\s+/g, " ")
    .split(" ")
    .slice(0, 2);
  const initials = words.map(word => word.charAt(0).toUpperCase());
  return initials.join("");
};
export const stringTruncate = (str, maxLength) =>
  !str || str.length <= maxLength ? str : `${str.substring(0, maxLength)}...`;

export default {
  validString,
  emptyText,
  wordCapitalize,
  getInitials,
  stringTruncate,
};
