import { Button, ButtonGroup, Container, Divider } from "@material-ui/core";
import { Grid, Tooltip } from "@mui/material";
import axios from "axios";
import {
  ModalError,
  ModalSuccess,
  ModalWarning,
  Page,
  PrimaryButton,
  SecondaryButton,
  SkeletonComponent,
} from "components";
import { HeaderTitle } from "layouts";
import { debounce } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router";
import { useLocation } from "react-router-dom";
import { globalStyles } from "styles";
import { getErrors } from "utils";
import { hardBaseUrl } from "../../../services/urlConstant";
import ContractDetailForm from "./Components/ContractDetailForm";
import SupportingDocuments from "./Components/SupportingDocuments";

const ContractDetail = () => {
  const classes = globalStyles();
  const history = useHistory();
  const { id } = useParams();
  const { state } = useLocation();
  const isFirstLoad = useRef(true);
  const { is_editable, is_active_flag, category } = state?.customer;
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const isSongContract = category === "song";
  const [selectedMenu, setSelectedMenu] = useState(1);
  const [loadingPage, setLoadingPage] = useState(false);
  const [userRole, setUserRole] = useState();
  const [pageDetail, setPageDetail] = useState({
    contract: {},
    songs: [],
    agents: [],
  });
  const [dataTableAdvance, setDataTableAdvance] = useState([]);
  const [dataTableAgents, setDataTableAgents] = useState([]);
  const [queryParams, setQueryParams] = useState({
    page: 1,
    size: 10,
    type: "-created_at",
    search: "",
  });
  const [dataTable, setDataTable] = useState([]);

  const handleDeactivateContract = () =>
    ModalWarning(
      "Are you sure you want to deactivate this contract?",
      "Deactivate Contract"
    ).then(res => res?.isConfirmed && deactivateContract());
  const handleChangeQueryParams = (value, key) => {
    setQueryParams(currentState => ({
      ...currentState,
      [key]: value,
    }));
  };

  const getMe = async () => {
    try {
      const roleId = localStorage?.getItem("role_id");
      const res = await axios.get(`${hardBaseUrl}/me/${roleId}`, { headers });
      setUserRole(res?.data?.data?.role?.roles_for);
    } catch (error) {
      ModalError(getErrors(error?.response));
    }
  };
  const getDetail = async () => {
    try {
      const res = await axios.get(
        `${hardBaseUrl}/publisher/contract/${
          isSongContract ? "" : "composer-main/"
        }${id}`,
        { headers }
      );
      const { data } = res?.data;
      const modifiedData = {
        ...data,
        songs: data?.songs
          ? data?.songs?.map(song => ({
              ...song,
              composers: song.composers?.map(composer => ({
                ...composer,
                balance: composer?.song_balance?.balance,
                start_period: composer?.song_balance?.start_period,
                end_period: composer?.song_balance?.end_period,
              })),
            }))
          : [],
        agents: data?.agents || [],
      };
      setPageDetail(modifiedData);
      setDataTableAdvance(
        isSongContract
          ? modifiedData?.songs
          : [modifiedData?.contract?.contract_composer_balance]
      );
      setDataTableAgents(
        isSongContract
          ? modifiedData?.agents
          : [modifiedData?.contract?.contract_composer_agent]
      );
    } catch (error) {
      ModalError(getErrors(error?.response));
    }
  };
  const getDataTable = async () => {
    try {
      setLoadingPage(true);
      const res = await axios.get(
        `${hardBaseUrl}/publisher/contract-document/${state.customer.contract_id}`,
        { headers, params: queryParams }
      );
      setDataTable(res?.data?.data || []);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  const debounceDataTable = useCallback(
    debounce(() => {
      getDataTable();
    }, 500),
    [queryParams]
  );
  const deactivateContract = async () => {
    try {
      setLoadingPage(true);
      await axios.put(
        `${hardBaseUrl}/publisher/contract/set-inactive/${id}`,
        {},
        { headers }
      );
      ModalSuccess("Status Has Been Updated").then(() => history.goBack());
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  const fetchData = async () => {
    setLoadingPage(true);
    const promises = [];
    promises.push(getMe());
    promises.push(getDetail());
    promises.push(getDataTable());

    try {
      await Promise.all(promises);
    } catch (error) {
      ModalError(error, "Error fetching data");
    } finally {
      setLoadingPage(false);
      isFirstLoad.current = false;
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    const isSearching = Boolean(queryParams?.search);
    if (isSearching) {
      debounceDataTable();
      return () => {
        debounceDataTable.cancel();
      };
    } else {
      if (!isFirstLoad.current) {
        getDataTable();
      }
    }
  }, [queryParams, debounceDataTable]);

  return (
    <Page className={classes.root} title="Contract Detail">
      {loadingPage ? (
        <SkeletonComponent variant="wave" />
      ) : (
        <Container maxWidth={false}>
          <HeaderTitle
            title="Contract Detail"
            breadcrumbData={breadcrumbData}
            backButton
          />
          <Grid container justifyContent="space-between" mt="20px">
            <Grid item>
              <ButtonGroup>
                {menuContract?.map(({ title, id }) => {
                  const selected = id === selectedMenu;
                  return (
                    <Button
                      className={
                        selected
                          ? classes.groupButtonSelected
                          : classes?.groupButton
                      }
                      key={id}
                      onClick={() => {
                        setSelectedMenu(id);
                      }}
                    >
                      {title}
                    </Button>
                  );
                })}
              </ButtonGroup>
            </Grid>
            <Grid item>
              <Grid container columnSpacing={1}>
                <Grid item>
                  {is_active_flag && (
                    <PrimaryButton
                      label="Deactivate"
                      onClick={handleDeactivateContract}
                    />
                  )}
                </Grid>
                <Tooltip title={!is_editable && "Contract is not editable"}>
                  <Grid item>
                    <SecondaryButton
                      label="Edit Contract"
                      onClick={() =>
                        history.push(`/admin/kontrak-composer/edit/${id}`)
                      }
                      disabled={!is_editable}
                    />
                  </Grid>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
          {selectedMenu === 1 ? (
            <ContractDetailForm
              preload={state?.customer}
              pageDetail={pageDetail}
              userRole={userRole}
              dataTableAdvance={dataTableAdvance}
              dataTableAgents={dataTableAgents}
              getDetail={fetchData}
              setLoadingPage={setLoadingPage}
            />
          ) : (
            <SupportingDocuments
              idKontrak={state.customer.contract_id}
              queryParams={queryParams}
              handleChangeQueryParams={handleChangeQueryParams}
              getDataTable={getDataTable}
              dataTable={dataTable}
            />
          )}
        </Container>
      )}
    </Page>
  );
};
const breadcrumbData = [
  {
    label: "Home",
    link: "/admin/dashboard",
  },
  {
    label: "Contract",
    link: "/admin/kontrak-composer",
  },
  {
    label: "Contract Detail",
    active: true,
  },
];
const menuContract = [
  {
    id: 1,
    title: "Detail",
  },
  {
    id: 2,
    title: "Supporting Document",
  },
];

export default ContractDetail;
