import {
  Backdrop,
  Breadcrumbs,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Divider,
  Grid,
  TextField,
  Typography,
  Box,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import Swal from "sweetalert2";
import "../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  Page,
  SelectInput,
  PrimaryButton,
  SkeletonComponent,
} from "components";
import { hardBaseUrl } from "../../../../services/urlConstant";
import { getCookie } from "utils/constants";
import { getErrors } from "../../../../utils";

const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  containerForm: {
    marginTop: 10,
  },
  results: {
    marginTop: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  btnCancel: {
    backgroundColor: "white",
    color: "black",
    padding: "10px 28px",
    width: "106px",
    height: "40px",
    borderRadius: "6px",
  },
  btnPreview: {
    color: "#FFFFFF",
    padding: "10px 28px",
    width: "106px",
    height: "40px",
    borderRadius: "6px",
    backgroundColor: props => (props.loadingPage ? "grey" : "black"),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  label: {
    color: "#364052",
    fontSize: "14px",
    fontWeight: 500,
  },
  subTitle: {
    fontSize: "18px",
    fontWeight: 700,
    color: "#111827",
  },
  breadcrumbs: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#111827",
  },
  breadCrumbsActive: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#687083",
  },
  required: {
    color: "red",
  },
  card: {
    color: "#9AA2B1",
    border: "1px solid #9AA2B1",
    borderRadius: "6px",
    boxShadow: "none",
  },
  inputField: {
    padding: 0,
    paddingLeft: "1rem",
    height: "44px",
  },
  cardSection: {
    marginTop: "5px",
    marginBottom: "5px",
  },
  cardTitle: {
    marginTop: "10px",
    marginBottom: "10px",
  },
  formControl: {
    marginTop: "-10px",
    marginBottom: "10px",
  },
  pageTitle: {
    fontSize: "24px",
    fontWeight: "700",
    lineHeight: "32px",
  },
  pageDescription: {
    marginTop: "5px",
    color: "#8f8f8f",
    fontSize: "14px",
  },
  buttonGroup: {
    justifyContent: "flex-end",
  },
}));

function TambahPerformer() {
  const [name, setName] = useState("");
  const [publisherId, setPublisherId] = useState("");
  const [optionOriginalPublisher, setOptionOriginalPublisher] = useState([]);
  const [loadingPage, setLoadingPage] = useState(false);
  const [getColor, setGetColor] = useState("");
  const [nik, setNik] = useState("");
  const classes = useStyles({ loadingPage, getColor });
  const history = useHistory();
  const userLogin = getCookie("auth", `${process.env.REACT_APP_NAME}_user`);
  const isAllowed =
    userLogin?.role?.type === "association" ||
    userLogin?.role?.type === "society";
  const getOptionPublisher = async () => {
    try {
      const token = localStorage.getItem("token");
      const url = `${hardBaseUrl}/publisher`;
      const headers = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await axios.get(url, headers);
      const { data } = res?.data;
      const modifiedData = [
        {
          publisher_id: "",
          name: "None",
        },
        ...data,
      ];
      setOptionOriginalPublisher(modifiedData);
    } catch (error) {
      new Error(error);
    }
  };

  const getMainDsp = async () => {
    try {
      const token = localStorage.getItem("token");
      const url = `${hardBaseUrl}/me`;
      const res = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setGetColor(res.data.data.publisher.theme_color);
    } catch (e) {
      new Error(e);
    }
  };

  useEffect(() => {
    setLoadingPage(false);
    getMainDsp();
    if (isAllowed) {
      getOptionPublisher();
    }
  }, []);

  const handleChangeNik = event => {
    const maxValue = event?.target?.value?.length <= 16;
    if (maxValue) {
      setNik(event.target.value);
    }
  };
  const handleChangeName = event => {
    setName(event.target.value);
  };

  const handleButtonSimpan = async () => {
    setLoadingPage(true);
    const payload = {
      first_name: name,
      nik: nik,
      is_active_flag: true,
      publisher_id: publisherId,
    };
    const filterPayload = Object.fromEntries(
      Object.entries(payload).filter(item => item[1])
    );
    const baseOfUrl = `${hardBaseUrl}/publisher/performer`;
    try {
      let theToken = localStorage.getItem("token");
      await axios.post(baseOfUrl, filterPayload, {
        headers: {
          Authorization: "Bearer " + theToken,
        },
      });
      setLoadingPage(false);
      Swal.fire({
        title: "Success",
        text: "",
        icon: "success",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
      }).then(result => {
        if (result.isConfirmed) {
          history.push("/admin/parameter/performer");
        }
      });
    } catch (err) {
      setLoadingPage(false);
      const errMessage = getErrors(err.response);
      Swal.fire({
        title: "Oops…",
        text: errMessage,
        icon: "error",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
        allowEscapeKey: false,
        allowOutsideClick: false,
      });
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Page className={classes.root} title="Tambah Performer">
        {loadingPage ? (
          <SkeletonComponent variant="wave" />
        ) : (
          <Container maxWidth={false}>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography
                color="textPrimary"
                className={classes.breadCrumbsActive}
              >
                Parameter
              </Typography>
              <Typography
                color="textPrimary"
                className={classes.breadCrumbsActive}
              >
                Performer
              </Typography>
              <Typography color="textPrimary" className={classes.breadcrumbs}>
                Add Performer
              </Typography>
            </Breadcrumbs>
            <Divider className={classes.divider} />
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography className={classes.pageTitle}>
                  Add Performer
                </Typography>
                <Typography className={classes.pageDescription}>
                  Please fill out the form below to add a new performer.
                </Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Card className={classes.card}>
                  <CardContent>
                    <Typography className={classes.subTitle}>
                      Performer Info
                    </Typography>
                    <Grid
                      container
                      spacing={3}
                      className={classes.containerForm}
                    >
                      <Grid item xs={12} sm={6}>
                        <Box display="flex" gridGap={2}>
                          <Typography className={classes.label}>NIK</Typography>
                        </Box>
                        <TextField
                          variant="outlined"
                          fullWidth
                          type="number"
                          inputProps={{
                            className: classes.inputField,
                          }}
                          value={nik}
                          max
                          onChange={handleChangeNik}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Box display="flex" gridGap={2}>
                          <Typography className={classes.label}>
                            Performer Name
                          </Typography>
                          <Typography
                            variant="span"
                            className={classes.required}
                          >
                            *
                          </Typography>
                        </Box>
                        <TextField
                          variant="outlined"
                          fullWidth
                          inputProps={{
                            className: classes.inputField,
                          }}
                          value={name}
                          onChange={handleChangeName}
                        />
                      </Grid>
                      {isAllowed && (
                        <Grid item xs={12} sm={6}>
                          <Box display="flex" gridGap={2}>
                            <Typography className={classes.label}>
                              Publisher
                            </Typography>
                            <Typography
                              variant="span"
                              className={classes.required}
                            >
                              *
                            </Typography>
                          </Box>
                          <SelectInput
                            value={publisherId}
                            onChange={e => setPublisherId(e?.target?.value)}
                            options={optionOriginalPublisher}
                            optionKey="publisher_id"
                            optionLabel="name"
                            width="100%"
                          />
                        </Grid>
                      )}
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Box marginTop="1rem">
                  <Grid container spacing={2} justifyContent="flex-end">
                    <Grid item>
                      <PrimaryButton
                        color="white"
                        textColor="black"
                        height={40}
                        width="106px"
                        variant="contained"
                        onClick={() =>
                          history.push("/admin/parameter/performer")
                        }
                      >
                        Cancel
                      </PrimaryButton>
                    </Grid>
                    <Grid item>
                      <PrimaryButton
                        className={classes.btnPreview}
                        height={40}
                        variant="contained"
                        color="black"
                        textColor="white"
                        width="106px"
                        onClick={handleButtonSimpan}
                        disabled={loadingPage}
                      >
                        Save
                      </PrimaryButton>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Container>
        )}
      </Page>
    </ThemeProvider>
  );
}

export default TambahPerformer;
