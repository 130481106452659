import {
  Button,
  Card,
  CardContent,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { makeStyles } from "@material-ui/styles";
import React, { useRef } from "react";
import ErrorChip from "../../../../components/atoms/Chip/ErrorChip";
import SuccessChip from "../../../../components/atoms/Chip/SuccessChip";

const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  results: {
    marginTop: theme.spacing(3),
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  headText: {
    marginTop: "10px",
    marginBottom: "10px",
    fontSize: "12px",
    color: "#687083",
    fontWeight: "bold",
  },
  content: {
    padding: 0,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  btnBack: {
    backgroundColor: "#25383c",
    color: "#fff",
    marginLeft: "10px",
    marginRight: "20px",
    textTransform: "none",
  },
  btnSubmit: {
    border: "1px solid lightgrey",
    textTransform: "none",
  },
}));

const DialogResult = ({ open, onClose, object }) => {
  const classes = useStyles();
  const dialogRef = useRef();
  const [details, setDetails] = useState([]);

  React.useEffect(() => {
    if (object !== null) {
      let invalids = object.invalid_songs.map(item => {
        return {
          song_title: item.song_title,
          status: "invalid",
          message: item.message,
          line_number: `${item.line_number}`,
        };
      });

      let valids = object.valid_songs.map(item => {
        return {
          song_title: item.song_title,
          status: "success",
          message: "Success",
          line_number: "-",
        };
      });

      let mergeResult = [...invalids, ...valids];

      setDetails(mergeResult);
    }
  }, [object]);

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth="lg"
        open={open}
        onClose={onClose}
        aria-labelledby="dialog-result"
        disableEscapeKeyDown={true}
        ref={dialogRef}
      >
        <DialogTitle id="responsive-dialog-title">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <ThemeProvider theme={theme}>
              <Typography
                style={{
                  fontSize: "16px",
                  fontWeight: 700,
                }}
              >
                {"Results"}
              </Typography>
            </ThemeProvider>

            <CloseOutlinedIcon
              onClick={onClose}
              style={{
                color: "#9AA2B1",
                cursor: "pointer",
                width: "12px",
                height: "12px",
                marginTop: "8px",
              }}
            />
          </div>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Card>
            <CardContent className={classes.content}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography
                        variant="subtitle2"
                        className={classes.headText}
                      >
                        Song Title
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="subtitle2"
                        className={classes.headText}
                      >
                        Status
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="subtitle2"
                        className={classes.headText}
                      >
                        Message
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="subtitle2"
                        className={classes.headText}
                      >
                        Line Number
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {details.map((customer, idx) => (
                    <TableRow hover key={`${idx}`}>
                      <TableCell align="left">
                        <Typography
                          variant="subtitle2"
                          className={classes.bodyTextBlack}
                        >
                          {customer.song_title}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        {customer.status === "invalid" ? (
                          <>
                            <Typography
                              variant="subtitle2"
                              className={classes.bodyTextBlack}
                            >
                              <HighlightOffIcon
                                style={{
                                  color: "red",
                                }}
                              />
                            </Typography>
                          </>
                        ) : null}
                        {customer.status === "success" ? (
                          <>
                            <Typography
                              variant="subtitle2"
                              className={classes.bodyTextBlack}
                            >
                              <CheckCircleOutlineIcon
                                style={{
                                  color: "green",
                                }}
                              />
                            </Typography>
                          </>
                        ) : null}
                      </TableCell>
                      <TableCell align="left">
                        <Typography
                          variant="subtitle2"
                          className={classes.bodyTextBlack}
                        >
                          {customer.status === "success" ? (
                            <>
                              <SuccessChip
                                chipLabel={customer.message}
                                key={`${idx}`}
                              />
                            </>
                          ) : (
                            <>
                              <ErrorChip
                                chipLabel={customer.message}
                                key={`${idx}`}
                              />
                            </>
                          )}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography
                          variant="subtitle2"
                          className={classes.bodyTextBlack}
                        >
                          {customer.line_number}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        </DialogContent>
        <Divider className={classes.divider} />
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            marginBottom: "20px",
            marginRight: "20px",
          }}
        >
          <Button
            className={classes.btnSubmit}
            variant="outlined"
            onClick={onClose}
          >
            Ok
          </Button>

          {}
        </div>
      </Dialog>
    </div>
  );
};

export default DialogResult;
