import React from "react";
import { makeStyles } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(2),
  },
  filterGroup: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  filterRow: {
    display: "flex",
    gap: theme.spacing(100),
    borderRadius: "8px",
  },
  filterRow1: {
    display: "flex",
    gap: theme.spacing(50),
    borderRadius: "8px",
  },
  filterRow2: {
    display: "flex",
    gap: theme.spacing(2),
    width: "45%",
    borderRadius: "8px",
  },
  filterItemRight: {
    display: "flex",
    gap: theme.spacing(4),
    width: "40%",
    borderRadius: "8px",
  },
  filterItemLargeLeft: {
    flex: 2,
    width: "30%",
    borderRadius: "8px",
  },
  filterItemLarge: {
    flex: 2,
    borderRadius: "8px",
    width: "40%",
  },
  filterItemMedium: {
    flex: 1,
    height: "160px",
    borderRadius: "8px",
  },
  tableSkeleton: {
    border: "1px solid #ddd",
    borderRadius: "8px",
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  tableHeader: {
    display: "flex",
    borderRadius: "8px",

    gap: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  tableRow: {
    display: "flex",
    marginBottom: theme.spacing(1),

    borderRadius: "8px",
  },
  tableCell: {
    flex: 1,
    borderRadius: "8px",
  },
  tableCellItem: {
    flex: 1,
    borderRadius: "8px",
    marginLeft: "28px",
    marginRight: "28px",
  },
}));

function SkeletonComponent() {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {/* Filter Section */}
      <div className={classes.filterGroup}>
        {/* Row with Two Large Boxes */}
        <div className={classes.filterRow}>
          <Skeleton variant="rounded" className={classes.filterItemLarge} />
          <Skeleton variant="rounded" className={classes.filterItemLarge} />
        </div>
        <div className={classes.filterRow1}>
          <Skeleton variant="text" className={classes.filterItemLarge} />
          <div className={classes.filterItemRight}>
            <Skeleton variant="rounded" className={classes.filterItemLarge} />
            <Skeleton variant="rounded" className={classes.filterItemLarge} />
          </div>
        </div>
        {/* Row with Three Medium Boxes */}
        <div className={classes.filterRow2}>
          <Skeleton variant="rounded" className={classes.filterItemMedium} />
          <Skeleton variant="rounded" className={classes.filterItemMedium} />
          <Skeleton variant="rounded" className={classes.filterItemMedium} />
        </div>
      </div>

      {/* Table Skeleton */}
      <div className={classes.tableSkeleton}>
        {/* Table Header with Four Long Cells */}
        <div className={classes.tableHeader}>
          {[...Array(7)].map((_, index) => (
            <Skeleton
              key={index}
              variant="rounded"
              className={classes.tableCell}
              height={30}
            />
          ))}
        </div>

        {/* Table Rows with Six Smaller Cells */}
        {[...Array(10)].map((_, rowIndex) => (
          <div key={rowIndex} className={classes.tableRow}>
            {[...Array(7)].map((_, cellIndex) => (
              <Skeleton
                key={cellIndex}
                variant="rounded"
                className={classes.tableCellItem}
                height={30}
              />
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

export default SkeletonComponent;
