import { Chip, Grid } from "@material-ui/core";
import { FiberManualRecord } from "@material-ui/icons";
import { styled } from "@mui/material";
import { stringTruncate } from "utils";

const ArrayTagChip = ({ selectionsList, value }) => {
  return (
    <Grid container spacing={1}>
      {selectionsList?.map((item, index) => {
        return (
          <Grid key={index} item>
            <CustomChip
              icon={<ChipIcon customColor={item?.color} />}
              label={stringTruncate(item?.[value], 18)}
              size="small"
              customColor={item?.color}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

const CustomChip = styled(Chip)(({ customColor }) => ({
  color: customColor,
  backgroundColor: customColor === "#FFF" ? "#6b7280" : `${customColor}10`,
}));
const ChipIcon = styled(FiberManualRecord)(({ customColor }) => ({
  height: 8,
  width: 8,
  color: customColor,
}));

export default ArrayTagChip;
