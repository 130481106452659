import {
  SET_IMAGE_TENTANG_KAMI_1,
  SET_IMAGE_TENTANG_KAMI_2,
  SET_IMAGE_VISI_KAMI_1,
  SET_IMAGE_VISI_KAMI_2,
  SET_IMAGE_PUBLISHER_1,
  SET_IMAGE_PUBLISHER_2,
  SET_IMAGE_PUBLISHER_3,
  SET_IMAGE_PUBLISHER_4,
  SET_IMAGE_PUBLISHER_5,
  SET_IMAGE_PUBLISHER_6,
  GET_TENTANG_KAMI_TEXT,
  GET_VISI_KAMI_TEXT,
  GET_DSP_NAME,
  GET_DSP_LOGO,
} from "../types";

export const setTentangKamiImage1 = image => {
  return {
    type: SET_IMAGE_TENTANG_KAMI_1,
    payload: image,
  };
};

export const setTentangKamiImage2 = image => {
  return {
    type: SET_IMAGE_TENTANG_KAMI_2,
    payload: image,
  };
};

export const setVisiKamiImage1 = image => {
  return {
    type: SET_IMAGE_VISI_KAMI_1,
    payload: image,
  };
};

export const setVisiKamiImage2 = image => {
  return {
    type: SET_IMAGE_VISI_KAMI_2,
    payload: image,
  };
};

export const setPublisherImage1 = image => {
  return {
    type: SET_IMAGE_PUBLISHER_1,
    payload: image,
  };
};

export const setPublisherImage2 = image => {
  return {
    type: SET_IMAGE_PUBLISHER_2,
    payload: image,
  };
};

export const setPublisherImage3 = image => {
  return {
    type: SET_IMAGE_PUBLISHER_3,
    payload: image,
  };
};

export const setPublisherImage4 = image => {
  return {
    type: SET_IMAGE_PUBLISHER_4,
    payload: image,
  };
};

export const setPublisherImage5 = image => {
  return {
    type: SET_IMAGE_PUBLISHER_5,
    payload: image,
  };
};

export const setPublisherImage6 = image => {
  return {
    type: SET_IMAGE_PUBLISHER_6,
    payload: image,
  };
};

export const getTentangKamiText = text => {
  return {
    type: GET_TENTANG_KAMI_TEXT,
    payload: text,
  };
};

export const getVisiKamiText = text => {
  return {
    type: GET_VISI_KAMI_TEXT,
    payload: text,
  };
};

export const getDspName = name => {
  return {
    type: GET_DSP_NAME,
    payload: name,
  };
};

export const getDspLogo = logo => {
  return {
    type: GET_DSP_LOGO,
    payload: logo,
  };
};
