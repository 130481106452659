import {
  Button,
  Card,
  CardContent,
  Checkbox,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import Swal from "sweetalert2";
import { hardBaseUrl } from "../../../../services/urlConstant";
import gradients from "../../../../theme/gradients";

const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isNumericString
    />
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  card: {
    width: theme.breakpoints.values.md,
    maxWidth: "100%",
    overflow: "visible",
    display: "flex",
    position: "relative",
    "& > *": {
      flexGrow: 1,
      flexBasis: "50%",
      width: "50%",
    },
  },
  content: {
    padding: theme.spacing(8, 4, 3, 4),
  },
  media: {
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    padding: theme.spacing(3),
    color: theme.palette.common.white,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  avatar_large: {
    width: theme.spacing(16),
    height: theme.spacing(16),
  },
  icon: {
    backgroundImage: gradients.green,
    color: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    position: "absolute",
    top: -32,
    left: theme.spacing(3),
    height: 64,
    width: 64,
    fontSize: 32,
  },
  loginForm: {
    marginTop: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  person: {
    marginTop: theme.spacing(2),
    display: "flex",
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  title: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "28px",
  },
  tambahLagu: {
    backgroundColor: "black",
    color: "white",
    width: "32px",
    height: "29px",
    borderRadius: "6px",
    cursor: "pointer",

    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
  textTambahLagu: {
    marginLeft: "10px",
    marginTop: "5px",
    fontSize: "14px",
    color: "#687083",
  },
  btnDialog: {
    backgroundColor: "black",
    color: "white",
    padding: "10px 28px",

    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
  btnSubmit: {
    backgroundColor: "black",
    color: "white",
    padding: "10px 28px",

    height: "40px",
    borderRadius: "6px",

    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
  headText: {
    fontSize: "12px",
    color: "#687083",
    fontWeight: "bold",
  },
  bodyTextBlack: {
    fontSize: "14px",
    fontWeight: "regular",
    color: "#111827",
  },
  bodyTextGrey: {
    fontSize: "14px",
    fontWeight: "regular",
    color: "#687083",
  },
  tableRow: {
    height: 20,
  },
  conTextBlack: {
    fontSize: "14px",
    fontWeight: "bold",
  },
  dropdown: {
    color: "black",
  },
  label: {
    color: "#364052",
    fontSize: "14px",
    fontWeight: 500,
  },
  subTitle: {
    fontSize: "18px",
    fontWeight: 700,
    color: " #111827",
  },
  breadcrumbs: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#111827",
  },
  breadCrumbsActive: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#687083",
  },
  rootSwitch: {
    width: "40px",
    height: "20px",
    padding: "0px",
    borderRadius: "10px",
    marginRight: "8px",
    position: "relative",
  },
  switchBase: {
    padding: "10px",
    color: "white",
    "&.Mui-checked": {},
    "&.Mui-checked + $track": {
      border: "none",
      color: "white",
      backgroundColor: "#606060",
      opacity: 1,
    },
  },
  checked: {},
  thumb: {
    color: "white",
    position: "absolute",
    width: "16px",
    height: "16px",
  },
  track: {
    backgroundColor: "#E4E7EB",
    borderRadius: "25px",
    border: "0px solid #606060",
    height: "auto",
    opacity: 1,
  },

  pageSize: {
    height: "30px",
    borderRadius: "6px",
    border: "1px solid #D1D5DC",
    paddingLeft: "5px",
    paddingRight: "5px",
    marginTop: "5px",
  },
  paginationText: {
    fontSize: "14px",
    fontWeight: "normal",
    fontStyle: "normal",
    color: "#687083",
  },
  pagination: {
    "& .Mui-selected": {
      backgroundColor: "black",
      color: "white",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  btnDetail: {
    backgroundColor: "#111827",
    color: "white",
    width: "30px",
    height: "30px",
    padding: "6px",
    borderRadius: "6px",
    marginRight: "10px",
  },
}));

function MainContract({ id }) {
  const classes = useStyles();
  const [timePeriod, setTimePeriod] = useState("1");
  const [contractNumber, setContractNumber] = useState("");
  const [startDate, setStartDate] = useState(moment().toString());
  const [endDate, setEndDate] = useState(moment().toString());
  const [dateRange, setDateRange] = useState(0);
  const [keterangan, setKeterangan] = useState("");
  const [wordsSong] = useState("");
  const [agentValueList, setAgentValueList] = useState([]);
  const [checked] = useState(false);

  const [advanceValueCheck, setAdvanceValueCheck] = useState(true);

  const [agentSwitch, setAgentSwitch] = useState(true);

  const [publisherShareValue, setPublisherShareValue] = useState(0);

  const [dataComposer, setDataComposer] = useState([]);
  const [selectedComposer, setSelectedComposer] = useState(null);
  const [startPeriod, setStartPeriod] = useState(moment().toString());
  const [endPeriod, setEndPeriod] = useState(moment().toString());
  const [advanceValueAmount, setAdvanceValueAmount] = useState(0);
  const [agentValue, setAgentValue] = useState(0);
  const [agentFee, setAgentFee] = useState(0);
  const feeCalculationList = [
    { name: "Sales Amount", value: "sales_amount" },
    { name: "Net Revenue Amount", value: "net_revenue_amount" },
  ];
  const [agentFeeType, setAgentFeeType] = useState("sales_amount");
  const [perpuityContract, setPerpuityContract] = useState(false);
  const hiddenAgent = false;
  const [approvalStatus, setApprovalStatus] = useState("");
  const handleChangePerpuityContract = event => {
    setPerpuityContract(event.target.checked);
  };

  const getComposerList = async () => {
    const token = localStorage.getItem("token");
    const urlClientList = `${hardBaseUrl}/publisher/transaction-composer`;
    await axios
      .get(urlClientList, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(res => {
        setDataComposer(res.data);
      })
      .catch(() => { });
  };

  useEffect(() => {
    getComposerList();
  }, []);

  const handleChangePublisherShare = event => {
    setPublisherShareValue(event.target.value);
  };

  const handleChangeAgentSwitch = event => {
    setAgentSwitch(event.target.checked);
  };

  const handleChangeAdvanceValueCheck = event => {
    setAdvanceValueCheck(event.target.checked);
  };

  const handleTimePeriodYear = () => {
    setTimePeriod("2");
  };

  const handleTimePeriodUnlimited = () => {
    setTimePeriod("1");
  };
  const handleChangeContractNumber = e => {
    setContractNumber(e.target.value);
  };

  const handleDateRangeChange = event => {
    setEndDate(
      new Date(startDate.getTime() + event.target.value * 24 * 60 * 60 * 1000)
    );

    setDateRange(event.target.value);
  };

  const handleDateRangeChangeYear = event => {
    setEndDate(
      new Date(
        startDate.getTime() + event.target.value * 365 * 24 * 60 * 60 * 1000
      )
    );
    setDateRange(event.target.value);
  };

  const handleDateChangeStartDate = date => {
    setStartDate(date);
  };

  const handleDateChangeEndDate = date => {
    if (date < startDate) {
      setStartDate(date);
    } else {
      setEndDate(date);
    }
  };

  const handleChangeText = e => {
    setKeterangan(e.target.value);
  };

  const getDetailMainContract = () => {
    const token = localStorage.getItem("token");
    const url = hardBaseUrl + `/publisher/contract/composer-main/${id}`;
    const options = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    axios
      .get(url, options)
      .then(res => {
        setContractNumber(res.data.data.contract.contract_number);
        setKeterangan(res.data.data.contract.description);
        const newSelectedComposer = {
          composer_id: res.data.data.contract.composer.composer_id,
          composer_name: res.data.data.contract.composer.sure_name,
          phone_number: res.data.data.contract.composer.phone_number,
        };
        setTimeout(() => {
          setSelectedComposer(newSelectedComposer);
        }, 1000);

        setAdvanceValueAmount(
          res.data.data.contract.contract_composer_balance.balance
        );
        const newStartDateAdvanceValue = new Date(
          res.data.data.contract.contract_composer_balance.start_period
        );
        const newEndDateAdvanceValue = new Date(
          res.data.data.contract.contract_composer_balance.end_period
        );
        setStartPeriod(newStartDateAdvanceValue);
        setEndPeriod(newEndDateAdvanceValue);
        setPublisherShareValue(
          res.data.data.contract.publisher_share_percentage
        );
        setAgentValue(res.data.data.contract.contract_composer_agent.agent_id);
        setAgentFeeType(
          res.data.data.contract.contract_composer_agent.fee_type
        );
        setAgentFee(
          res.data.data.contract.contract_composer_agent.agent_fee_formatted
        );
        setDateRange(res.data.data.contract.date_period);
        const newStartDate = new Date(res.data.data.contract.start_date);
        const newEndDate = new Date(res.data.data.contract.end_date);
        setStartDate(newStartDate);
        setEndDate(newEndDate);
        setPerpuityContract(res.data.data.contract.is_endless_period);

        setAgentSwitch(
          !res.data.data.contract.contract_composer_agent.is_agent_deleted
        );

        setAdvanceValueCheck(
          res.data.data.contract.contract_composer_balance.is_have_advance
        );
        setApprovalStatus(res.data.data.contract.approval_status);
        if (res.data.data.contract.type_period === "year") {
          setTimePeriod("2");
        } else {
          setTimePeriod("1");
        }
      })
      .catch(() => { });
  };

  useEffect(() => {
    getDetailMainContract();
  }, [checked, wordsSong]);

  const handleChangeAdvanceValueList = e => {
    setAdvanceValueAmount(e.target.value);
  };

  const handleChangeStartPeriod = date => {
    setStartPeriod(date);
  };

  const handleChangeEndPeriod = date => {
    setEndPeriod(date);
  };

  const handleChangeAgentType = e => {
    setAgentFeeType(e.target.value);
  };
  const handleChangeAgentValue = e => {
    e.preventDefault();
    setAgentValue(e.target.value);
  };

  const handleChangeAgentFee = e => {
    setAgentFee(e.target.value);
  };

  const getAgentList = () => {
    const token = localStorage.getItem("token");
    const url = `${hardBaseUrl}/publisher/agent-on-transaction`;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .get(url, config)
      .then(res => {
        setAgentValueList(res.data.data);
      })
      .catch(() => { });
  };
  useEffect(() => {
    getAgentList();
  }, []);

  const handleChangeAutoComplete = () => { };

  const getDateFormatForFilter = date => {
    let dateFormat = new Date(date);
    let day = dateFormat
      .getDate()
      .toString()
      .padStart(2, "0");
    let month = (dateFormat.getMonth() + 1).toString().padStart(2, "0");
    let year = dateFormat.getFullYear();
    let dateString = `${year}-${month}-${day}`;
    return dateString;
  };
  const handleButtonSimpan = e => {
    e.preventDefault();
    const token = localStorage.getItem("token");

    const newAgentData = () => {
      if (hiddenAgent === true) {
        return {};
      } else {
        return {
          agent_id: agentValue,
          agent_fee: agentFee === "" || agentFee === 0 ? 0 : Number(agentFee),
          fee_type: agentFeeType,
        };
      }
    };
    const payload = {
      composer_id: selectedComposer?.composer_id,
      contract_number: contractNumber,
      date_periode: dateRange === "" || dateRange === 0 ? 0 : Number(dateRange),
      start_date: getDateFormatForFilter(startDate),
      end_date: getDateFormatForFilter(endDate),
      description: keterangan,
      publisher_share_percentage:
        publisherShareValue === "" ? 0 : Number(publisherShareValue),
      is_endless_period: perpuityContract,
      type_period: timePeriod === "1" ? "unlimited" : "year",
      contract_composer_balance: {
        balance:
          typeof advanceValueAmount === "number"
            ? advanceValueAmount
            : advanceValueAmount === ""
              ? 0
              : Number(advanceValueAmount.replace(/,/g, "")),
        start_period: getDateFormatForFilter(startPeriod),
        end_period: getDateFormatForFilter(endPeriod),
      },

      contract_composer_agent: newAgentData(),
    };

    if (publisherShareValue > 100) {
      Swal.fire({
        title: "Error",
        text: "Publisher share percentage cannot be more than 100%",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
    if (publisherShareValue <= 100) {
      const url = `${hardBaseUrl}/publisher/contract/composer-main/${id}`;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      axios
        .put(url, payload, config)
        .then(() => {
          Swal.fire({
            title: "Success",
            text: "Data has been edited",
            icon: "success",
            confirmButtonText: "OK",
          }).then(result => {
            if (result.isConfirmed) {
              window.location.href = "/admin/kontrak-composer";
            }
          });
        })
        .catch(err => {
          Swal.fire({
            title: "Error",
            text: err.response.data.errors.message,
            icon: "error",
            confirmButtonText: "OK",
          });
        });
    }
  };

  return (
    <>
      <Grid container>
        <div>
          <ThemeProvider theme={theme}>
            <Typography
              component="h1"
              variant="h3"
              className={classes.subTitle}
            >
              Main Contract Information
            </Typography>
          </ThemeProvider>
          <ThemeProvider theme={theme}>
            <p
              style={{
                marginTop: "5px",
                color: "#8f8f8f",
                fontSize: "14px",
              }}
            >
              Form to fill out information data related to the contract to be
              made
            </p>
          </ThemeProvider>
        </div>
      </Grid>
      <div
        style={{
          marginBottom: "20px",
        }}
      >
        <Button
          onClick={handleTimePeriodUnlimited}
          className={classes.btnDialog}
          style={{
            backgroundColor: timePeriod === "1" ? "black" : "#FFFFFF",
            marginRight: "10px",
            borderColor: timePeriod === "1" ? "#FFFFFF" : "black",
            border: "1px solid #9AA2B1",
          }}
        >
          <ThemeProvider theme={theme}>
            <Typography
              style={{
                textTransform: "none",
                color: timePeriod === "1" ? "#FFFFFF" : "black",
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "20px",
                textDecoration: "none",
              }}
            >
              Perpetual
            </Typography>
          </ThemeProvider>
        </Button>
        <Button
          onClick={handleTimePeriodYear}
          className={classes.btnDialog}
          variant="contained"
          style={{
            backgroundColor: timePeriod === "2" ? "black" : "#FFFFFF",
            borderColor: timePeriod === "2" ? "#FFFFFF" : "black",
            border: "1px solid #9AA2B1",
          }}
        >
          <ThemeProvider theme={theme}>
            <Typography
              style={{
                textTransform: "none",
                color: timePeriod === "2" ? "#FFFFFF" : "black",
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "20px",
                textDecoration: "none",
              }}
            >
              Year
            </Typography>
          </ThemeProvider>
        </Button>
      </div>

      <Grid container>
        <Grid item xs={8} md={8} sm={8} lg={8} xl={8}>
          <Card
            style={{
              border: "1px solid #9AA2B1",
              borderRadius: "6px",
              boxShadow: "none",
            }}
          >
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={6} md={6} sm={6} lg={6} xl={6}>
                  <div
                    style={{
                      marginTop: "5px",
                      marginBottom: "5px",
                    }}
                  >
                    <div
                      style={{
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      <ThemeProvider theme={theme}>
                        <Typography
                          variant="subtitle2"
                          className={classes.label}
                        >
                          Contract Number
                        </Typography>
                      </ThemeProvider>
                    </div>
                    <div
                      style={{
                        marginTop: "-10px",
                        marginBottom: "10px",
                      }}
                    >
                      <TextField
                        variant="outlined"
                        onChange={handleChangeContractNumber}
                        name="numberformat"
                        disabled={
                          approvalStatus === "waiting_for_approval"
                            ? false
                            : true
                        }
                        value={contractNumber}
                        id="formatted-numberformat-input"
                        margin="dense"
                        fullWidth={true}
                      />
                    </div>
                  </div>
                </Grid>
                <Grid item xs={6} md={6} sm={6} lg={6} xl={6}>
                  <div
                    style={{
                      marginTop: "5px",
                      marginBottom: "5px",
                    }}
                  >
                    <div
                      style={{
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      <ThemeProvider theme={theme}>
                        <Typography
                          variant="subtitle2"
                          className={classes.label}
                        >
                          Time Period
                        </Typography>
                      </ThemeProvider>
                    </div>
                    <div
                      style={{
                        marginTop: "-10px",
                        marginBottom: "10px",
                      }}
                    >
                      <TextField
                        defaultValue={0}
                        variant="outlined"
                        onChange={
                          timePeriod === "1"
                            ? handleDateRangeChange
                            : handleDateRangeChangeYear
                        }
                        name="numberformat"
                        id="formatted-numberformat-input"
                        margin="dense"
                        disabled={
                          approvalStatus === "waiting_for_approval"
                            ? false
                            : true
                        }
                        value={dateRange}
                        fullWidth={true}
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                          endAdornment: (
                            <InputAdornment position="end">
                              {timePeriod === "1" ? "Day" : "Year"}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  </div>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6} md={6} sm={6} lg={6} xl={6}>
                  <div
                    style={{
                      marginTop: "5px",
                      marginBottom: "5px",
                    }}
                  >
                    <div
                      style={{
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      <ThemeProvider theme={theme}>
                        <Typography
                          variant="subtitle2"
                          className={classes.label}
                        >
                          Start Date
                        </Typography>
                      </ThemeProvider>
                    </div>
                    <div
                      style={{
                        marginTop: "-10px",
                        marginBottom: "10px",
                      }}
                    >
                      <KeyboardDatePicker
                        id="outlined-basic"
                        variant="outlined"
                        onChange={handleDateChangeStartDate}
                        value={startDate}
                        disabled={
                          approvalStatus === "waiting_for_approval"
                            ? false
                            : true
                        }
                        name="startDate"
                        format="DD-MM-YYYY"
                        inputVariant="outlined"
                        fullWidth={true}
                        margin="dense"
                      />
                    </div>
                  </div>
                </Grid>

                <Grid item xs={6} md={6} sm={6} lg={6} xl={6}>
                  <div
                    style={{
                      marginTop: "5px",
                      marginBottom: "5px",
                    }}
                  >
                    <div
                      style={{
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      <ThemeProvider theme={theme}>
                        <Typography
                          variant="subtitle2"
                          className={classes.label}
                        >
                          End Date
                        </Typography>
                      </ThemeProvider>
                    </div>
                    <div
                      style={{
                        marginTop: "-10px",
                        marginBottom: "10px",
                      }}
                    >
                      <KeyboardDatePicker
                        id="outlined-basic"
                        value={perpuityContract ? null : endDate}
                        onChange={handleDateChangeEndDate}
                        format="DD-MM-YYYY"
                        name="endDate"
                        inputVariant="outlined"
                        fullWidth={true}
                        margin="dense"
                        placeholder="-"
                        disabled={
                          approvalStatus === "waiting_for_approval"
                            ? false
                            : true
                        }
                      />
                      <div style={{ display: "flex" }}>
                        <Switch
                          checked={perpuityContract}
                          onChange={handleChangePerpuityContract}
                          name="checkedB"
                          color="primary"
                          disabled={
                            approvalStatus === "waiting_for_approval"
                              ? false
                              : true
                          }
                          classes={{
                            root: classes.rootSwitch,
                            switchBase: classes.switchBase,
                            thumb: classes.thumb,
                            track: classes.track,
                            checked: classes.checked,
                          }}
                        />
                        <p>This contract will perpetuity</p>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>

              <Grid item xs={12} md={12} sm={12} lg={12} xl={12}>
                <div
                  style={{
                    marginTop: "5px",
                    marginBottom: "5px",
                  }}
                >
                  <div
                    style={{
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    <ThemeProvider theme={theme}>
                      <Typography variant="subtitle2" className={classes.label}>
                        Description
                      </Typography>
                    </ThemeProvider>
                  </div>
                  <div
                    style={{
                      marginTop: "-10px",
                      marginBottom: "10px",
                    }}
                  >
                    <TextField
                      multiline={true}
                      minRows={10}
                      id="outlined-basic"
                      placeholder="Description"
                      variant="outlined"
                      fullWidth={true}
                      margin="dense"
                      name="keterangan"
                      disabled={
                        approvalStatus === "waiting_for_approval" ? false : true
                      }
                      value={keterangan}
                      onChange={e => handleChangeText(e)}
                    />
                  </div>
                </div>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4} md={4} sm={4} lg={4} xl={4}></Grid>
      </Grid>

      { }

      <Grid container style={{ marginTop: "20px" }}>
        <div>
          <ThemeProvider theme={theme}>
            <Typography
              component="h1"
              variant="h3"
              className={classes.subTitle}
            >
              Composer
            </Typography>
          </ThemeProvider>
          <ThemeProvider theme={theme}>
            <p
              style={{
                marginTop: "5px",
                color: "#8f8f8f",
                fontSize: "14px",
              }}
            >
              This feature can add composer in one contract
            </p>
          </ThemeProvider>
        </div>
      </Grid>

      <Grid container>
        <Grid item xs={8} md={8} sm={8} lg={8} xl={8}>
          <div
            style={{
              marginTop: "7px",
              marginBottom: "10px",
            }}
          >
            <FormControl
              variant="outlined"
              className={classes.formControl}
              fullWidth={true}
            >
              <Autocomplete
                onChange={(event, newValue) => {
                  setSelectedComposer(newValue);
                  handleChangeAutoComplete(event, newValue);
                }}
                options={dataComposer?.data}
                value={selectedComposer}
                getOptionLabel={option => option?.composer_name}
                className={classes.autoComplete}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Search Composer/Author"
                  />
                )}
              />
            </FormControl>
          </div>
        </Grid>
        <Grid item xs={4} md={4} sm={4} lg={4} xl={4}></Grid>
      </Grid>

      { }

      <Grid
        container
        style={{
          marginTop: "20px",
        }}
      >
        <Grid item xs={4} md={4} sm={4} lg={8} xl={8}>
          <ThemeProvider theme={theme}>
            <Typography variant="h3" className={classes.subTitle}>
              Advance Value
            </Typography>
          </ThemeProvider>
          <ThemeProvider theme={theme}>
            <p
              style={{
                marginTop: "5px",
                color: "#8f8f8f",
                fontSize: "14px",
              }}
            >
              Feature to set Advance Payment
            </p>
          </ThemeProvider>

          <ThemeProvider theme={theme}>
            <Grid component="label" container alignItems="center" spacing={1}>
              <Grid item>
                <Checkbox
                  checked={advanceValueCheck}
                  onChange={handleChangeAdvanceValueCheck}
                  style={{
                    color: "#111827",
                  }}
                />
              </Grid>
              { }
              <Grid item>
                <p style={{ marginTop: "15px", marginLeft: "-10px" }}>
                  Set Advance Value For Composer/Author
                </p>
              </Grid>
            </Grid>
          </ThemeProvider>
          {advanceValueCheck ? (
            <Card
              style={{
                border: "1px solid #9AA2B1",
                borderRadius: "6px",
                boxShadow: "none",
                marginTop: "10px",
              }}
            >
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <ThemeProvider theme={theme}>
                      <TableCell align="left">
                        <Typography
                          variant="subtitle2"
                          className={classes.headText}
                        >
                          Advance Value
                        </Typography>
                      </TableCell>

                      <TableCell align="left">
                        <Typography
                          variant="subtitle2"
                          className={classes.headText}
                        >
                          Start Period
                        </Typography>
                      </TableCell>

                      <TableCell align="left">
                        <Typography
                          variant="subtitle2"
                          className={classes.headText}
                        >
                          End Period
                        </Typography>
                      </TableCell>
                    </ThemeProvider>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow hover>
                    <TableCell>
                      <div
                        style={{
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        <Grid style={{ display: "flex" }}>
                          <Grid
                            style={{
                              width: "48px",
                              height: "38px",
                              marginRight: "-2px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "6px 0px 0px 6px",
                              backgroundColor: "#F9FAFB",
                              border: " 1px solid #D1D5DC",
                              color: "#9AA2B",
                              borderRight: "none",
                            }}
                          >
                            Rp
                          </Grid>
                          <NumberFormat
                            customInput={TextField}
                            variant="outlined"
                            thousandSeparator={true}
                            onChange={e => handleChangeAdvanceValueList(e)}
                            value={advanceValueAmount}
                            autoComplete="off"
                            name="advanceValue"
                            className={classes.Input}
                            style={{
                              paddingLeft: "0px",
                              height: "44px",
                              borderRadius: "0px 6px 6px 0px",
                            }}
                            size="small"
                            fullWidth
                          />
                        </Grid>
                      </div>
                    </TableCell>

                    <TableCell>
                      <KeyboardDatePicker
                        id="outlined-basic"
                        value={startPeriod}
                        onChange={e => handleChangeStartPeriod(e)}
                        format="DD-MM-YYYY"
                        name="startPeriod"
                        inputVariant="outlined"
                        fullWidth={true}
                        margin="dense"
                        placeholder="Tanggal Selesai"
                      />
                    </TableCell>

                    <TableCell>
                      <KeyboardDatePicker
                        id="outlined-basic"
                        value={endPeriod}
                        onChange={e => handleChangeEndPeriod(e)}
                        format="DD-MM-YYYY"
                        name="endPeriod"
                        inputVariant="outlined"
                        fullWidth={true}
                        margin="dense"
                        placeholder="Tanggal Selesai"
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Card>
          ) : (
            <></>
          )}
        </Grid>

        <Grid item xs={4} md={4} sm={4} lg={4} xl={4}></Grid>
      </Grid>

      <Grid
        container
        style={{
          marginTop: "20px",
        }}
      >
        <Grid item xs={4} md={4} sm={4} lg={8} xl={8}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <ThemeProvider theme={theme}>
                <Typography variant="h3" className={classes.subTitle}>
                  Publisher Share Percentage
                </Typography>
              </ThemeProvider>
              <ThemeProvider theme={theme}>
                <p
                  style={{
                    marginTop: "5px",
                    color: "#8f8f8f",
                    fontSize: "14px",
                  }}
                >
                  Feature to set publisher share percentage
                </p>
              </ThemeProvider>
            </div>

            <div>
              <TextField
                value={publisherShareValue}
                variant="outlined"
                onChange={e => handleChangePublisherShare(e)}
                name="numberformat"
                id="formatted-numberformat-input"
                margin="dense"
                fullWidth={true}
                InputProps={{
                  inputComponent: NumberFormatCustom,
                  endAdornment: (
                    <InputAdornment position="end">{"%"}</InputAdornment>
                  ),
                }}
              />
            </div>
          </div>
        </Grid>

        <Grid item xs={4} md={4} sm={4} lg={4} xl={4}></Grid>
      </Grid>

      <Grid
        container
        style={{
          marginTop: "20px",
        }}
      >
        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
          <div>
            <Typography
              component="h1"
              variant="h3"
              className={classes.subTitle}
            >
              Agent
            </Typography>
            <ThemeProvider theme={theme}>
              <p
                style={{
                  marginTop: "5px",
                  color: "#8f8f8f",
                  fontSize: "14px",
                }}
              >
                Feature to set Agent & fee Agent
              </p>
            </ThemeProvider>
            <div style={{ display: "flex" }}>
              <Switch
                checked={agentSwitch}
                onChange={handleChangeAgentSwitch}
                name="checkedB"
                color="primary"
                classes={{
                  root: classes.rootSwitch,
                  switchBase: classes.switchBase,
                  thumb: classes.thumb,
                  track: classes.track,
                  checked: classes.checked,
                }}
              />
              <p>Is Agent engaged?</p>
            </div>

            {agentSwitch ? (
              <Card
                style={{
                  border: "1px solid #9AA2B1",
                  borderRadius: "6px",
                  boxShadow: "none",
                  marginTop: "10px",
                }}
              >
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <ThemeProvider theme={theme}>
                        <TableCell align="left">
                          <Typography
                            variant="subtitle2"
                            className={classes.headText}
                          >
                            Agent
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography
                            variant="subtitle2"
                            className={classes.headText}
                          >
                            Agent Fee
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography
                            variant="subtitle2"
                            className={classes.headText}
                          >
                            Fee Calculation Basis
                          </Typography>
                        </TableCell>
                        { }
                      </ThemeProvider>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {hiddenAgent ? (
                      <TableRow>
                        <TableCell colSpan={4}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <p>Agent is not available</p>
                          </div>
                        </TableCell>
                      </TableRow>
                    ) : (
                      <TableRow hover>
                        <TableCell>
                          <FormControl variant="outlined" fullWidth={true}>
                            <Select
                              className={classes.mypadding}
                              id="demo-simple-select-outlined"
                              onChange={e => handleChangeAgentValue(e)}
                              autoWidth={true}
                              disabled={
                                approvalStatus === "approved" ? true : false
                              }
                              value={agentValue}
                              style={{
                                paddingTop: "0px",
                                paddingRight: "0px",
                                paddingBottom: "0px",
                                paddingLeft: "0px",
                                width: "120px",
                                height: "40px",
                              }}
                            >
                              <MenuItem value={0}>Select Agent</MenuItem>
                              {agentValueList?.map((item, idx) => (
                                <MenuItem value={item.agent_id} key={idx}>
                                  {item.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </TableCell>
                        <TableCell>
                          <TextField
                            value={agentFee}
                            variant="outlined"
                            onChange={e => handleChangeAgentFee(e)}
                            name="numberformat"
                            id="formatted-numberformat-input"
                            margin="dense"
                            fullWidth={true}
                            disabled={
                              approvalStatus === "approved" ? true : false
                            }
                            InputProps={{
                              inputComponent: NumberFormatCustom,
                              endAdornment: (
                                <InputAdornment position="end">
                                  {"%"}
                                </InputAdornment>
                              ),
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <FormControl variant="outlined" fullWidth={true}>
                            <Select
                              className={classes.mypadding}
                              id="demo-simple-select-outlined"
                              onChange={e => handleChangeAgentType(e)}
                              autoWidth={true}
                              value={agentFeeType}
                              disabled={
                                approvalStatus === "approved" ? true : false
                              }
                              style={{
                                paddingTop: "0px",
                                paddingRight: "0px",
                                paddingBottom: "0px",
                                paddingLeft: "0px",
                                width: "120px",
                                height: "40px",
                              }}
                            >
                              {feeCalculationList.map((item, idx) => (
                                <MenuItem value={item.value} key={idx}>
                                  {item.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </Card>
            ) : null}
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}></Grid>
      </Grid>
      <Divider className={classes.divider} />

      <Grid container>
        <Button
          variant="contained"
          onClick={e => handleButtonSimpan(e)}
          className={classes.btnSubmit}
          style={{
            backgroundColor: "black",
          }}
        >
          <ThemeProvider theme={theme}>
            <Typography
              style={{
                textTransform: "none",
                color: "#FFFFFF",
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "20px",
              }}
            >
              Edit Contract
            </Typography>
          </ThemeProvider>
        </Button>
      </Grid>
    </>
  );
}

export default MainContract;
