import {
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  Chip,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  OutlinedInput,
  Paper,
  Select,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import moment from "moment";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import TableContainer from "@material-ui/core/TableContainer";
import { BackupOutlined } from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
import CheckIcon from "@material-ui/icons/Check";
import MuiAlert from "@material-ui/lab/Alert";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import NumberFormat from "react-number-format";
import Swal from "sweetalert2";
import { Page } from "components";
import { hardBaseUrl } from "../../../../services/urlConstant";
import gradients from "../../../../theme/gradients";

const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isNumericString
    />
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  card: {
    width: theme.breakpoints.values.md,
    maxWidth: "100%",
    overflow: "visible",
    display: "flex",
    position: "relative",
    "& > *": {
      flexGrow: 1,
      flexBasis: "50%",
      width: "50%",
    },
  },
  content: {
    padding: theme.spacing(8, 4, 3, 4),
  },
  media: {
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    padding: theme.spacing(3),
    color: theme.palette.common.white,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  avatar_large: {
    width: theme.spacing(16),
    height: theme.spacing(16),
  },
  icon: {
    backgroundImage: gradients.green,
    color: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    position: "absolute",
    top: -32,
    left: theme.spacing(3),
    height: 64,
    width: 64,
    fontSize: 32,
  },
  loginForm: {
    marginTop: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  person: {
    marginTop: theme.spacing(2),
    display: "flex",
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  title: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "28px",
  },

  tambahLagu: {
    backgroundColor: "black",

    color: "white",
    width: "32px",
    height: "29px",
    borderRadius: "6px",
    cursor: "pointer",

    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
  textTambahLagu: {
    marginLeft: "10px",
    marginTop: "5px",
    fontSize: "14px",
    color: "#687083",
  },
  btnDialog: {
    backgroundColor: "black",

    color: "white",
    padding: "10px 28px",

    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
  btnSubmit: {
    backgroundColor: "black",

    color: "white",
    padding: "10px 28px",

    height: "40px",
    borderRadius: "6px",

    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
  headText: {
    fontSize: "12px",
    color: "#687083",
    fontWeight: "bold",
  },
  bodyTextBlack: {
    fontSize: "14px",
    fontWeight: "regular",
    color: "#111827",
  },
  bodyTextGrey: {
    fontSize: "14px",
    fontWeight: "regular",
    color: "#687083",
  },
  tableRow: {
    height: 20,
  },
  conTextBlack: {
    fontSize: "14px",
    fontWeight: "bold",
  },
  dropdown: {
    color: "black",
  },
  label: {
    color: "#364052",
    fontSize: "14px",
    fontWeight: 500,
  },
  subTitle: {
    fontSize: "18px",
    fontWeight: 700,
    color: " #111827",
  },
  breadcrumbs: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#111827",
  },
  breadCrumbsActive: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#687083",
  },
}));
const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "50px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#303030",
  borderStyle: "solid",
  backgroundColor: "white",
  color: "black",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

function TambahKontrak() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [songList, setSongList] = useState([]);

  const checked = false;
  const [penciptaList, setPenciptaList] = useState([]);

  const [songId, setSongId] = useState(null);
  const [startDate, setStartDate] = useState(moment().toString());
  const [endDate, setEndDate] = useState(moment().toString());
  const [dateRange, setDateRange] = useState(0);
  const [gambar, setGambar] = useState([]);
  const [keterangan, setKeterangan] = useState("");
  const [openSnack, setOpenSnack] = useState(false);

  const [typeSnackbar, setTypeSnackbar] = useState("success");
  const [message, setMessage] = useState("This is a message!");
  const [wordsSong, setWordsSong] = useState("");
  const [getColor, setGetColor] = useState("");
  const [timePeriod, setTimePeriod] = useState("1");
  const handleDrop = useCallback(acceptedFiles => {
    setGambar(acceptedFiles);
  }, []);

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: ".pdf",
    onDrop: handleDrop,
  });

  let files = acceptedFiles.map(file => (
    <li key={file.name}>
      {file.name} - {file.size} bytes
    </li>
  ));

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const openModalLagu = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleReset = () => {
    setPenciptaList([]);
    const newData = songList.map(item => {
      item.checked = false;
      return item;
    });
    setSongList(newData);
  };

  const tableRowClick = id => {
    songList.map(item => {
      if (item.song_id === id) {
        item.checked = !item.checked;
        setPenciptaList(item.song_composer);
        setSongId(item.song_id);
      } else {
        item.checked = false;
      }
    });
  };

  const getResponse = async () => {
    try {
      const token = localStorage.getItem("token");
      const url =
        hardBaseUrl + "/publisher/song?page=1&size=10&search=" + wordsSong;

      const options = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      const res = await axios.get(url, options);

      const newData = res.data.data.map(item => ({
        ...item,
        checked: false,
      }));
      setSongList(newData);
    } catch (error) {
      if (error.response.status === 404) {
      }
    }
  };

  useEffect(() => {
    getResponse();
    const getMainDsp = async () => {
      try {
        const token = localStorage.getItem("token");
        const url = `${hardBaseUrl}/me`;
        const res = await axios.get(url, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        setGetColor(res.data.data.publisher.theme_color);
      } catch (e) { }
    };
    getMainDsp();
  }, [checked, startDate, endDate, wordsSong]);

  const listPencipta = data => {
    let pencipta = data;

    if (pencipta === null || undefined || "") {
      return <div>-</div>;
    } else if (pencipta.length <= 3) {
      return pencipta.map((pencipta, index) => (
        <div key={index}>
          <ThemeProvider theme={theme}>
            <Chip
              key={index}
              label={pencipta.name}
              className={classes.tableChip}
              size="small"
            />
          </ThemeProvider>
        </div>
      ));
    } else if (pencipta.length > 3) {
      let pencipta1 = pencipta.slice(0, 3);
      let pencipta2 = pencipta.slice(3, pencipta.length);
      return (
        <div>
          <ThemeProvider theme={theme}>
            {pencipta1.map((pencipta, index) => (
              <Chip
                key={index}
                label={pencipta.name}
                className={classes.tableChip}
                size="small"
              />
            ))}
            <Chip
              key={pencipta.publisher_id}
              label={"+" + pencipta2.length}
              className={classes.tableChip}
              size="small"
            />
          </ThemeProvider>
        </div>
      );
    }
  };

  const handleDateChangeStartDate = date => {
    setStartDate(date);
  };

  const handleDateChangeEndDate = date => {
    if (date < startDate) {
      setStartDate(date);
      setMessage("End date must be greater than start date");
      setTypeSnackbar("error");
      setOpenSnack(true);
    } else {
      setEndDate(date);
    }
  };

  const handleDateRangeChange = event => {
    setEndDate(
      new Date(startDate.getTime() + event.target.value * 24 * 60 * 60 * 1000)
    );

    setDateRange(event.target.value);
  };

  const handleDateRangeChangeYear = event => {
    setEndDate(
      new Date(
        startDate.getTime() + event.target.value * 365 * 24 * 60 * 60 * 1000
      )
    );
    setDateRange(event.target.value);
  };

  const handleButtonSimpan = async e => {
    e.preventDefault();

    try {
      const token = localStorage.getItem("token");

      if (songId === null) {
        setTypeSnackbar("error");
        setMessage("lagu Tidak Boleh Kosong.");
        setOpenSnack(true);
      }
      if (startDate === "" || null || undefined) {
        setTypeSnackbar("error");
        setMessage("Tanggal Mulai Tidak Boleh Kosong.");
        setOpenSnack(true);
      }
      if (endDate === "" || null || undefined) {
        setTypeSnackbar("error");
        setMessage("Tanggal Selesai Tidak Boleh Kosong.");
        setOpenSnack(true);
      }

      if (songId !== null && startDate !== null && endDate !== null) {
        const formData = new FormData();
        formData.append("song_ids", songId);
        formData.append("date_periode", Number(dateRange));
        formData.append("start_date", startDate.toISOString().split("T")[0]);
        formData.append("end_date", endDate.toISOString().split("T")[0]);
        formData.append("description", keterangan);
        formData.append("documents", gambar[0]);

        let baseOfUrl = hardBaseUrl;
        let urlUse = baseOfUrl + "/publisher/contract/original-publisher";

        let resultAdd = await axios.post(urlUse, formData, {
          headers: {
            Authorization: "Bearer " + token,
          },
        });

        if (
          resultAdd.data.message === "success" &&
          resultAdd.data.meta.http_status === 200
        ) {
          Swal.fire({
            title: "Success",
            text: "Data Berhasil Disimpan",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "OK",
          });

          setTimeout(() => { }, 3000);
        } else {
          setTypeSnackbar("error");
          setMessage("Tambah Kontrak Gagal.");
          setOpenSnack(true);
        }
      }
    } catch (err) {
      Swal.fire({
        title: "Error",
        text: "Error.",
        icon: "error",
      }).then(() => { });
    }
  };

  const handleChangeText = e => {
    setKeterangan(e.target.value);
  };
  const handleCloseSnackbar = () => {
    setOpenSnack(false);
  };

  const handleTimePeriodYear = () => {
    setTimePeriod("2");
  };

  const handleTimePeriodUnlimited = () => {
    setTimePeriod("1");
  };

  return (
    <Page className={classes.root} title="Tambah Kontrak">
      <Container maxWidth={false}>
        <Snackbar
          open={openSnack}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert onClose={handleCloseSnackbar} severity={typeSnackbar}>
            {message}
          </Alert>
        </Snackbar>
        <Grid
          alignItems="flex-end"
          container
          justifyContent="space-between"
          spacing={3}
        >
          <Grid item>
            <ThemeProvider theme={theme}>
              <Typography
                style={{
                  fontSize: "24px",
                  fontWeight: "700",
                  lineHeight: "32px",
                }}
              >
                Add Contract
              </Typography>
            </ThemeProvider>
          </Grid>
          <Grid item>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography className={classes.breadCrumbs}>Home</Typography>

              <Typography className={classes.breadCrumbs}>Contract</Typography>
              <Typography className={classes.breadCrumbsActive}>
                Add Contract
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
        <Divider className={classes.divider} />

        <Grid container>
          <div>
            <ThemeProvider theme={theme}>
              <Typography
                component="h1"
                variant="h3"
                className={classes.subTitle}
              >
                Song
              </Typography>
            </ThemeProvider>
            <ThemeProvider theme={theme}>
              <p
                style={{
                  marginTop: "5px",
                  color: "#8f8f8f",
                  fontSize: "14px",
                }}
              >
                This feature can add multiple songs in one contract
              </p>
            </ThemeProvider>
          </div>
        </Grid>

        <Grid container>
          <Grid item xs={8} md={8} sm={8} lg={8} xl={8}>
            <Grid container>
              <Grid item xs={12} md={12} sm={12} lg={12} xl={12}>
                <div
                  style={{
                    marginTop: "5px",
                    marginBottom: "5px",
                  }}
                >
                  <div
                    style={{
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    <Paper
                      style={{
                        paddingTop: "10px",

                        paddingBottom: "10px",

                        backgroundColor: "#F9FAFB",
                        cursor: "pointer",
                      }}
                      onClick={openModalLagu}
                    >
                      <Container>
                        <div style={{ display: "flex" }}>
                          <IconButton
                            onClick={openModalLagu}
                            className={classes.tambahLagu}
                            style={{
                              backgroundColor: getColor,
                            }}
                          >
                            <AddIcon />
                          </IconButton>
                          <ThemeProvider theme={theme}>
                            <Typography
                              variant="subtitle2"
                              className={classes.textTambahLagu}
                            >
                              Click to add song
                            </Typography>
                          </ThemeProvider>
                        </div>
                      </Container>
                    </Paper>
                    <Dialog
                      fullWidth={true}
                      maxWidth={"md"}
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="max-width-dialog-title"
                    >
                      <DialogTitle id="max-width-dialog-title">
                        Cari Lagu
                      </DialogTitle>
                      <Grid container>
                        <Grid item xs={12} md={12} sm={12} lg={12} xl={12}>
                          <Container>
                            <div style={{ display: "flex" }}>
                              <FormControl
                                variant="outlined"
                                style={{ minWidth: 120 }}
                              >
                                <Select
                                  native
                                  value="name"
                                  onChange={() => { }}
                                  inputProps={{
                                    name: "age",
                                    id: "outlined-age-native-simple",
                                  }}
                                >
                                  <option
                                    value="name"
                                    style={{ color: "#687083" }}
                                  >
                                    Judul Lagu
                                  </option>
                                </Select>
                              </FormControl>
                              <FormControl variant="outlined" fullWidth={true}>
                                <OutlinedInput
                                  id="outlined-adornment-password"
                                  placeholder="Pencarian"
                                  value={wordsSong}
                                  onChange={e => {
                                    setWordsSong(e.target.value);
                                  }}
                                />
                              </FormControl>
                            </div>

                            <Grid container>
                              <Grid item xs={8} md={8} sm={8} lg={8} xl={8}>
                                <Divider className={classes.divider} />
                              </Grid>
                            </Grid>

                            <TableContainer style={{ maxHeight: 200 }}>
                              <Table stickyHeader size="small">
                                <TableHead>
                                  <TableRow>
                                    <ThemeProvider theme={theme}>
                                      <TableCell>
                                        <Typography
                                          variant="subtitle2"
                                          className={classes.headText}
                                        >
                                          ISWC Code
                                        </Typography>
                                      </TableCell>
                                      <TableCell>
                                        <Typography
                                          variant="subtitle2"
                                          className={classes.headText}
                                        >
                                          Song Title
                                        </Typography>
                                      </TableCell>
                                      <TableCell>
                                        <Typography
                                          variant="subtitle2"
                                          className={classes.headText}
                                        >
                                          Publisher
                                        </Typography>
                                      </TableCell>
                                    </ThemeProvider>
                                    <TableCell></TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {songList.map(customer => (
                                    <TableRow
                                      hover
                                      key={customer.song_id}
                                      onClick={() => {
                                        tableRowClick(
                                          customer.song_id,
                                          customer.song_composer
                                        );
                                      }}
                                    >
                                      <ThemeProvider theme={theme}>
                                        <TableCell>
                                          <Typography
                                            variant="subtitle2"
                                            className={classes.bodyTextGrey}
                                          >
                                            {customer.isrc_code}
                                          </Typography>
                                        </TableCell>
                                        <TableCell>
                                          <Typography
                                            variant="subtitle2"
                                            className={classes.bodyTextBlack}
                                          >
                                            {customer.title_song}
                                          </Typography>
                                        </TableCell>

                                        <TableCell>
                                          <Typography
                                            variant="subtitle2"
                                            className={classes.bodyTextBlack}
                                          >
                                            {listPencipta(customer.publishers)}
                                          </Typography>
                                        </TableCell>
                                      </ThemeProvider>
                                      <TableCell>
                                        {customer.checked === true ? (
                                          <CheckIcon />
                                        ) : (
                                          ""
                                        )}
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Container>
                        </Grid>
                      </Grid>
                      <DialogContent></DialogContent>
                      <DialogActions>
                        <Button
                          onClick={handleReset}
                          className={classes.btnDialog}
                          style={{
                            backgroundColor: getColor,
                          }}
                        >
                          <ThemeProvider theme={theme}>
                            <Typography
                              style={{
                                textTransform: "none",
                                color: "#FFFFFF",
                                fontSize: "14px",
                                fontWeight: 400,
                                lineHeight: "20px",
                                textDecoration: "none",
                              }}
                            >
                              Cancel
                            </Typography>
                          </ThemeProvider>
                        </Button>
                        <Button
                          onClick={handleClose}
                          className={classes.btnDialog}
                          variant="contained"
                          style={{
                            backgroundColor: getColor,
                          }}
                        >
                          <ThemeProvider theme={theme}>
                            <Typography
                              style={{
                                textTransform: "none",
                                color: "#FFFFFF",
                                fontSize: "14px",
                                fontWeight: 400,
                                lineHeight: "20px",
                                textDecoration: "none",
                              }}
                            >
                              Choose
                            </Typography>
                          </ThemeProvider>
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container>
          <div>
            <ThemeProvider theme={theme}>
              <Typography
                component="h1"
                variant="h3"
                className={classes.subTitle}
              >
                Composer
              </Typography>
            </ThemeProvider>
            <ThemeProvider theme={theme}>
              <p
                style={{
                  marginTop: "5px",
                  color: "#8f8f8f",
                  fontSize: "14px",
                }}
              >
                Composer Information
              </p>
            </ThemeProvider>
          </div>
        </Grid>
        <Grid container>
          <Grid item xs={8} md={8} sm={8} lg={8} xl={8}>
            <Grid container>
              <Grid item xs={12} md={12} sm={12} lg={12} xl={12}>
                <Card
                  style={{
                    border: "1px solid #9AA2B1",
                    borderRadius: "6px",
                    boxShadow: "none",
                  }}
                >
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <ThemeProvider theme={theme}>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={classes.headText}
                            >
                              Composer Name
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={classes.headText}
                            >
                              ISWC Code
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={classes.headText}
                            >
                              Ownership Percentage
                            </Typography>
                          </TableCell>
                        </ThemeProvider>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {penciptaList !== null ? (
                        penciptaList?.length > 0 ? (
                          penciptaList.map(customer => (
                            <TableRow hover key={customer.composer_id}>
                              <TableCell>
                                <Typography
                                  variant="subtitle2"
                                  className={classes.bodyTextBlack}
                                >
                                  {customer.composer.sure_name}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  variant="subtitle2"
                                  className={classes.bodyTextGrey}
                                >
                                  {customer.composer.iswc_code}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  variant="subtitle2"
                                  className={classes.bodyTextBlack}
                                >
                                  {customer.percentage_ownership}%
                                </Typography>
                              </TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={3}>
                              <center>
                                <ThemeProvider theme={theme}>
                                  <Typography
                                    variant="h6"
                                    style={{
                                      fontWeight: 400,
                                      color: "#687083",
                                      fontSize: "14px",
                                    }}
                                  >
                                    no data
                                  </Typography>
                                </ThemeProvider>
                              </center>
                            </TableCell>
                          </TableRow>
                        )
                      ) : (
                        <TableRow>
                          <TableCell colSpan={3}>
                            <center>
                              <ThemeProvider theme={theme}>
                                <Typography
                                  variant="h6"
                                  style={{
                                    fontWeight: 400,
                                    color: "#687083",
                                    fontSize: "14px",
                                  }}
                                >
                                  no data
                                </Typography>
                              </ThemeProvider>
                            </center>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={8} md={8} sm={8} lg={8} xl={8}>
            <Divider className={classes.divider} />
          </Grid>
        </Grid>

        <Grid container>
          <div>
            <ThemeProvider theme={theme}>
              <Typography
                component="h1"
                variant="h3"
                className={classes.subTitle}
              >
                Information Contract
              </Typography>
            </ThemeProvider>
            <ThemeProvider theme={theme}>
              <p
                style={{
                  marginTop: "5px",
                  color: "#8f8f8f",
                  fontSize: "14px",
                }}
              >
                Form to fill out information data related to the contract to be
                made
              </p>
            </ThemeProvider>
          </div>
        </Grid>
        <div
          style={{
            marginBottom: "20px",
          }}
        >
          <Button
            onClick={handleTimePeriodUnlimited}
            className={classes.btnDialog}
            style={{
              backgroundColor: timePeriod === "1" ? getColor : "#FFFFFF",
              marginRight: "10px",
              borderColor: timePeriod === "1" ? "#FFFFFF" : "black",
              border: "1px solid #9AA2B1",
            }}
          >
            <ThemeProvider theme={theme}>
              <Typography
                style={{
                  textTransform: "none",
                  color: timePeriod === "1" ? "#FFFFFF" : getColor,
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "20px",
                  textDecoration: "none",
                }}
              >
                Unlimited
              </Typography>
            </ThemeProvider>
          </Button>
          <Button
            onClick={handleTimePeriodYear}
            className={classes.btnDialog}
            variant="contained"
            style={{
              backgroundColor: timePeriod === "2" ? getColor : "#FFFFFF",
              borderColor: timePeriod === "2" ? "#FFFFFF" : "black",
              border: "1px solid #9AA2B1",
            }}
          >
            <ThemeProvider theme={theme}>
              <Typography
                style={{
                  textTransform: "none",
                  color: timePeriod === "2" ? "#FFFFFF" : getColor,
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "20px",
                  textDecoration: "none",
                }}
              >
                Year
              </Typography>
            </ThemeProvider>
          </Button>
        </div>

        <Grid container>
          <Grid item xs={8} md={8} sm={8} lg={8} xl={8}>
            <Card
              style={{
                border: "1px solid #9AA2B1",
                borderRadius: "6px",
                boxShadow: "none",
              }}
            >
              <CardContent>
                <Grid container>
                  <Grid item xs={6} md={6} sm={6} lg={6} xl={6}>
                    <div
                      style={{
                        marginTop: "5px",
                        marginBottom: "5px",
                      }}
                    >
                      <div
                        style={{
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        <ThemeProvider theme={theme}>
                          <Typography
                            variant="subtitle2"
                            className={classes.label}
                          >
                            Time Period
                          </Typography>
                        </ThemeProvider>
                      </div>
                      <div
                        style={{
                          marginTop: "-10px",
                          marginBottom: "10px",
                        }}
                      >
                        <TextField
                          defaultValue={0}
                          variant="outlined"
                          onChange={
                            timePeriod === "1"
                              ? handleDateRangeChange
                              : handleDateRangeChangeYear
                          }
                          name="numberformat"
                          id="formatted-numberformat-input"
                          margin="dense"
                          fullWidth={true}
                          InputProps={{
                            inputComponent: NumberFormatCustom,
                            endAdornment: (
                              <InputAdornment position="end">
                                {timePeriod === "1" ? "Day" : "Year"}
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={6} md={6} sm={6} lg={6} xl={6}></Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6} md={6} sm={6} lg={6} xl={6}>
                    <div
                      style={{
                        marginTop: "5px",
                        marginBottom: "5px",
                      }}
                    >
                      <div
                        style={{
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        <ThemeProvider theme={theme}>
                          <Typography
                            variant="subtitle2"
                            className={classes.label}
                          >
                            Start Date
                          </Typography>
                        </ThemeProvider>
                      </div>
                      <div
                        style={{
                          marginTop: "-10px",
                          marginBottom: "10px",
                        }}
                      >
                        <KeyboardDatePicker
                          id="outlined-basic"
                          variant="outlined"
                          onChange={handleDateChangeStartDate}
                          value={startDate}
                          name="startDate"
                          format="YYYY-MM-DD"
                          minDate={new Date()}
                          inputVariant="outlined"
                          fullWidth={true}
                          margin="dense"
                        />
                      </div>
                    </div>
                  </Grid>

                  <Grid item xs={6} md={6} sm={6} lg={6} xl={6}>
                    <div
                      style={{
                        marginTop: "5px",
                        marginBottom: "5px",
                      }}
                    >
                      <div
                        style={{
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        <ThemeProvider theme={theme}>
                          <Typography
                            variant="subtitle2"
                            className={classes.label}
                          >
                            End Date
                          </Typography>
                        </ThemeProvider>
                      </div>
                      <div
                        style={{
                          marginTop: "-10px",
                          marginBottom: "10px",
                        }}
                      >
                        <KeyboardDatePicker
                          id="outlined-basic"
                          value={endDate}
                          onChange={handleDateChangeEndDate}
                          format="YYYY-MM-DD"
                          name="endDate"
                          inputVariant="outlined"
                          minDate={new Date()}
                          fullWidth={true}
                          margin="dense"
                          placeholder="Tanggal Selesai"
                        />
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={12} sm={12} lg={12} xl={12}>
                    <div
                      style={{
                        marginTop: "5px",
                        marginBottom: "5px",
                      }}
                    >
                      <div
                        style={{
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        <ThemeProvider theme={theme}>
                          <Typography
                            variant="subtitle2"
                            className={classes.label}
                          >
                            Description
                          </Typography>
                        </ThemeProvider>
                      </div>
                      <div
                        style={{
                          marginTop: "-10px",
                          marginBottom: "10px",
                        }}
                      >
                        <TextField
                          multiline={true}
                          minRows={10}
                          id="outlined-basic"
                          placeholder="Tulis keterangan disini..."
                          variant="outlined"
                          fullWidth={true}
                          margin="dense"
                          name="keterangan"
                          onChange={e => handleChangeText(e)}
                        />
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={4} md={4} sm={4} lg={4} xl={4}></Grid>
        </Grid>
        { }

        <Grid container>
          <Grid item xs={8} md={8} sm={8} lg={8} xl={8}>
            <Divider className={classes.divider} />
          </Grid>
        </Grid>
        { }
        <Grid container>
          <div>
            <ThemeProvider theme={theme}>
              <Typography
                component="h1"
                variant="h3"
                className={classes.subTitle}
              >
                Supporting Document
              </Typography>
            </ThemeProvider>
            <ThemeProvider theme={theme}>
              <p
                style={{
                  marginTop: "5px",
                  color: "#8f8f8f",
                  fontSize: "14px",
                }}
              >
                Upload supporting documents, such as scanned agreement documents
                or can be in the form of pdf files
              </p>
            </ThemeProvider>
          </div>
        </Grid>
        <Grid container>
          <Grid item xs={8} md={8} sm={8} lg={8} xl={8}>
            <Card
              style={{
                border: "1px solid #9AA2B1",
                borderRadius: "6px",
                boxShadow: "none",
              }}
            >
              <CardContent>
                <ThemeProvider theme={theme}>
                  <Typography variant="subtitle2" className={classes.label}>
                    Upload Document
                  </Typography>
                </ThemeProvider>
                <div style={{ marginTop: "20px" }}>
                  <div {...getRootProps({ style })}>
                    <input {...getInputProps()} />
                    {isDragActive ? (
                      <>
                        <BackupOutlined
                          style={{ color: "grey", fontSize: "40px" }}
                        />
                        <Typography variant="h6">
                          <p>Drop the files here ...</p>
                        </Typography>
                      </>
                    ) : (
                      <>
                        <BackupOutlined
                          style={{ color: "grey", fontSize: "40px" }}
                        />
                        <Typography variant="h6">
                          <p>
                            <b>Search File</b>, Drag document here
                          </p>
                        </Typography>
                      </>
                    )}
                  </div>
                  <div
                    style={{
                      marginTop: "20px",
                    }}
                  >
                    <aside>
                      <Typography component="h1" variant="h5">
                        Files :
                      </Typography>
                      <ul>
                        <Typography component="h1" variant="h5">
                          {files}
                        </Typography>
                      </ul>
                    </aside>
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        <Grid container>
          <Button
            variant="contained"
            onClick={e => handleButtonSimpan(e)}
            className={classes.btnSubmit}
            style={{
              backgroundColor: getColor,
            }}
          >
            <ThemeProvider theme={theme}>
              <Typography
                style={{
                  textTransform: "none",
                  color: "#FFFFFF",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "20px",
                }}
              >
                Add Contract
              </Typography>
            </ThemeProvider>
          </Button>
        </Grid>
      </Container>
    </Page>
  );
}

export default TambahKontrak;
