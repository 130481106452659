import {
  Breadcrumbs,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import ExpandMore from "@material-ui/icons/ExpandMore";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import { InnoImage } from "inno-ui";
import { useEffect, useRef, useState } from "react";
import { ChromePicker } from "react-color";
import NumberFormat from "react-number-format";
import { useHistory, useLocation } from "react-router";
import Swal from "sweetalert2";
import UnggahLogo from "../../../../../assets/img/unggahLogo.svg";
import { Page } from "components";
import { hardBaseUrl } from "../../../../../services/urlConstant";

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  colorPickerArea: {
    marginTop: "6px",
  },
  button: {
    marginBottom: "20px",
    marginTop: "20px",
    backgroundColor: "black",
    color: "white",
    width: "145px",
    height: "40px",
    textTransform: "none",
    "&:hover": {
      color: "white",
      backgroundColor: "black",
    },
  },
  buttonCancel: {
    marginBottom: "20px",
    marginTop: "20px",
    backgroundColor: "white",
    color: "black",
    width: "135px",
    height: "40px",
    textTransform: "none",
    "&:hover": {
      color: "black",
      backgroundColor: "white",
    },
  },
  imgPreview: {
    maxWidth: "150px",
    maxHeight: "150px",
  },
  fileUploadArea: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  title: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "28px",
  },
  subTitle: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#6e6e6e",
  },
  label: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontWeight: "500px",
    fontSize: "14px",
    lineHeight: "20px",
  },
  inputFieldsTlp: {
    height: "44px",
    paddingTop: "0px",
    paddingBottom: "0px",
    borderLeft: "1px solid #D1D5DC",
    paddingLeft: "14px",
  },
  myTextField: {
    height: "44px",
    paddingBottom: "0px",
    paddingTop: "0px",
  },
  inputFields: {
    height: "44px",
    paddingTop: "0px",
    paddingBottom: "0px",
  },
  inputFieldsUrl: {
    height: "44px",
    paddingTop: "0px",
    paddingBottom: "0px",
    borderRight: "1px solid #D1D5DC",
  },
  breadcrumbs: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#111827",
  },
  breadCrumbsActive: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#687083",
  },
  rootSwitch: {
    width: "40px",
    height: "20px",
    padding: "0px",
    borderRadius: "10px",

    marginRight: "8px",
    position: "relative",
  },
  switchBase: {
    padding: "10px",
    color: "white",
    "&.Mui-checked": {},
    "&.Mui-checked + $track": {
      border: "none",
      color: "white",

      opacity: 1,
    },
  },
  checked: {},
  thumb: {
    color: "white",
    position: "absolute",
    width: "16px",
    height: "16px",
  },
  track: {
    backgroundColor: "#E4E7EB",
    borderRadius: "25px",
    border: "0px solid #606060",
    height: "auto",
    opacity: 1,
  },
  selectPadding: {
    "& .MuiSelect-outlined": {
      paddingTop: "11px",
      paddingBottom: "11px",
      paddingLeft: "20px",
      paddingRight: "30px",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
  required: {
    color: "red",
  },
  buttonDisabled: {
    marginBottom: "20px",
    marginTop: "20px",
    backgroundColor: "gray",
    color: "white",
    width: "145px",
    height: "40px",
    textTransform: "none",
    "&:hover": {
      color: "white",
      backgroundColor: "gray",
    },
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const EditDsp = () => {
  const history = useHistory();

  const { state } = useLocation();

  const [color, setColor] = useState("#000");
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [file, setFile] = useState(null);
  const [file2, setFile2] = useState(null);
  const [objectUrl, setObjectUrl] = useState(null);
  const [dsp, setDsp] = useState("");
  const [nama, setNama] = useState("");

  const [telepon, setTelepon] = useState("");
  const [email, setEmail] = useState("");
  const inputFile = useRef(null);
  const [openSnack, setOpenSnack] = useState(false);
  const [typeSnackbar, setTypeSnackbar] = useState("success");
  const [message, setMessage] = useState("This is a message!");
  const [clientType, setClientType] = useState("");
  const [alamat, setAlamat] = useState("");
  const [currencyList, setCurrencyList] = useState([]);
  const [curr_id, setCurr_id] = useState("");
  const [trans_fee, setTrans_fee] = useState(0);
  const [openBackdrop, setOpenBackdrop] = useState(false);

  const [clientTypeNew, setClientTypeNew] = useState("");

  const [domicilytype, setDomicilytype] = useState("");

  const listDomicile = [
    { id: 1, name: "Domestic" },
    { id: 2, name: "Overseas" },
  ];
  const [submitting, setSubmitting] = useState(false);

  const [clientTypeList, setClientTypeList] = useState([]);

  const clientTypeListNew = [
    {
      id: 1,
      name: "DSP",
      value: "dsp",
    },
    {
      id: 2,
      name: "Non DSP",
      value: "non_dsp",
    },
  ];
  const classes = useStyles();

  const theme = createTheme({
    typography: {
      fontFamily: ["Helvetica Neue"].join(","),
    },
  });

  const colorPreview = makeStyles(() => ({
    changeColor: {
      backgroundColor: color,
      padding: "10px",

      width: "48px",
      height: "24px",
    },
  }));

  const colorPrev = colorPreview();

  const onChange = e => {
    const name = e.target.name;
    const value = e.target.value;

    switch (name) {
      case "dsp":
        setDsp(value);
        break;
      case "nama":
        setNama(value);
        break;
      case "color":
        setColor(value);
        break;
      case "url":
        break;
      case "telepon":
        setTelepon(value);
        break;
      case "email":
        setEmail(value);
        break;
      case "personInCharge":
        break;
      default:
        break;
    }
  };

  const onChangeFile = e => {
    const name = e.target.name;
    const data = e.target.files[0];
    const object = URL.createObjectURL(data);

    if (name === "photo") {
      setFile(data);
      setObjectUrl(object);
      setFile2(data);
    }
  };
  const getCurr = () => {
    const token = localStorage.getItem("token");
    const url = `${hardBaseUrl}/currency/datatable?per_page=99&page=1`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(url, { headers })
      .then(res => {
        setCurrencyList(res.data.data);
      })
      .catch(() => {});
  };
  const getDetail = () => {
    const token = localStorage.getItem("token");
    const url = `${hardBaseUrl}/dsp/${state.customer.dsp_id}`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(url, { headers })
      .then(res => {
        setDsp(res.data.data.name);

        setColor(res.data.data.color);
        setNama(res.data.data.person_name);
        setEmail(res.data.data.person_email);
        setTelepon(res.data.data.person_phone);
        setAlamat(res.data.data.address);
        setClientType(
          res.data.data.client_classification.client_classification_id
        );
        setClientTypeNew(res.data.data.type);
        setTrans_fee(res.data.data.transaction_fee);
        setCurr_id(res.data.data.dsp_currency_id);
        setDomicilytype(
          listDomicile?.[
            listDomicile?.findIndex(
              el => el.name.toLowerCase() === res?.data?.data?.domicile
            )
          ]?.id || ""
        );
        if (
          res.data.data.profile_image === null ||
          res.data.data.profile_image === ""
        ) {
          setFile(null);
        } else {
          setFile(res.data.data.profile_image);
          setObjectUrl(res.data.data.profile_image);
        }
      })
      .catch(() => {});
  };

  const getListClient = () => {
    const token = localStorage.getItem("token");
    const url = `${hardBaseUrl}/publisher/classification/index`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(url, { headers })
      .then(res => {
        setClientTypeList(res.data.data);
      })
      .catch(() => {});
  };
  useEffect(() => {
    getListClient();
    getDetail();
    getCurr();
  }, []);
  const handleCloseSnackbar = () => {
    setOpenSnack(false);
  };

  const onSubmit = e => {
    e.preventDefault();
    setOpenBackdrop(true);

    if (nama === "") {
      setTypeSnackbar("error");
      setMessage("Name cannot be empty.");
      setOpenSnack(true);
      setOpenBackdrop(false);
    }
    if (!domicilytype) {
      setTypeSnackbar("error");
      setMessage("Domicily cannot be empty.");
      setOpenSnack(true);
      setOpenBackdrop(false);
    }

    if (nama !== "" && domicilytype) {
      const urlAddress = `${hardBaseUrl}/dsp/${state.customer.dsp_id}`;
      let theToken = localStorage.getItem("token");
      const headers = {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${theToken}`,
        },
      };
      const formData = new FormData();
      formData.append("color", color);
      formData.append("name", dsp);
      formData.append("address", alamat);
      formData.append("person_name", nama);
      formData.append("person_email", email);
      formData.append("person_phone", telepon);
      if (file2 !== null) {
        formData.append("image_logo", file);
      }
      formData.append(
        "domicile",
        listDomicile?.[
          listDomicile?.findIndex(e => e.id === domicilytype)
        ]?.name?.toLowerCase()
      );

      formData.append("type", clientTypeNew);
      formData.append("dsp_currency_id", curr_id);
      formData.append("transaction_fee", trans_fee);
      formData.append("client_classification_id", clientType);
      setSubmitting(true);
      axios
        .put(urlAddress, formData, headers)
        .then(() => {
          setOpenBackdrop(false);
          Swal.fire({
            title: "Success",
            text: "Data edited successfully",
            icon: "success",
            confirmButtonText: "Ok",
          }).then(result => {
            if (result.isConfirmed) {
              history.push("/admin/parameter/dsp");
            }
          });
        })
        .catch(err => {
          setOpenBackdrop(false);
          setSubmitting(false);
          Swal.fire({
            title: "Errors",
            text: `${err.response.data.errors[0].message}`,
            icon: "error",
            confirmButtonText: "Ok",
          });
        });
    }
  };

  const handleChangePhotoButton = e => {
    e.preventDefault();
    inputFile.current.click();
  };

  const handleChangeUploadType = e => {
    setClientTypeNew(e.target.value);
  };

  const handleChangeClientType = e => {
    setClientType(e.target.value);
  };
  const handleChangeCurrency = e => {
    setCurr_id(e.target.value);
  };
  const handleChangeAddress = event => {
    const { value } = event.target;

    setAlamat(value);
  };
  const changeValueTotalClaim = e => {
    let newVal = e;
    let resultVal = e;
    if (newVal.includes(",")) {
      resultVal = Number(newVal.replaceAll(",", ""));
    } else {
      resultVal = Number(resultVal);
    }
    setTrans_fee(resultVal);
  };
  return (
    <div className={classes.root}>
      <Backdrop className={classes.backdrop} open={openBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={openSnack}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleCloseSnackbar} severity={typeSnackbar}>
          {message}
        </Alert>
      </Snackbar>
      <Page>
        <Container maxWidth={false}>
          <Grid
            alignItems="flex-end"
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <ThemeProvider theme={theme}>
                <Typography
                  style={{
                    fontSize: "24px",
                    fontWeight: "700",
                    lineHeight: "32px",
                  }}
                >
                  Edit Client
                </Typography>
              </ThemeProvider>
            </Grid>
            <Grid item>
              <Breadcrumbs aria-label="breadcrumb">
                <Typography className={classes.breadCrumbs}>Home</Typography>

                <Typography className={classes.breadCrumbs}>Master</Typography>

                <Typography className={classes.breadCrumbs}>Client</Typography>
                <Typography className={classes.breadCrumbsActive}>
                  Edit Client
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>
          <Divider className={classes.divider} />

          <form onSubmit={onSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <ThemeProvider theme={theme}>
                  <Typography variant="h5" className={classes.title}>
                    {" "}
                    DSP Information{" "}
                  </Typography>
                </ThemeProvider>
                <div
                  style={{
                    border: "1px solid #9AA2B1",
                    borderRadius: "8px",
                    padding: "16px",
                    marginTop: "16px",
                  }}
                >
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6} lg={6} xl={6}>
                      <div
                        style={{
                          marginBottom: "10px",
                        }}
                      >
                        <ThemeProvider theme={theme}>
                          <Typography className={classes.label}>
                            Client Type{" "}
                            <span className={classes.required}>*</span>
                          </Typography>
                        </ThemeProvider>
                      </div>
                      <div
                        style={{
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        <FormControl
                          variant="outlined"
                          className={classes.formControl}
                          fullWidth={true}
                        >
                          <InputLabel
                            id="demo-simple-select-outlined-label"
                            shrink={false}
                          >
                            {clientTypeNew !== "" ? null : "Client Type"}
                          </InputLabel>
                          <Select
                            id="outlined-basic"
                            variant="outlined"
                            fullWidth={true}
                            name="publisher"
                            value={clientTypeNew}
                            onChange={event => handleChangeUploadType(event)}
                          >
                            {clientTypeListNew?.length > 0 ? (
                              clientTypeListNew.map((item, index) => {
                                return (
                                  <MenuItem key={index} value={item.value}>
                                    {item.name}
                                  </MenuItem>
                                );
                              })
                            ) : (
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                            )}
                          </Select>
                        </FormControl>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6} xl={6}>
                      <div
                        style={{
                          marginBottom: "10px",
                        }}
                      >
                        <ThemeProvider theme={theme}>
                          <Typography className={classes.label}>
                            Client Classification
                          </Typography>
                        </ThemeProvider>
                      </div>
                      <div
                        style={{
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        <FormControl
                          variant="outlined"
                          className={classes.formControl}
                          fullWidth={true}
                        >
                          <InputLabel
                            id="demo-simple-select-outlined-label"
                            shrink={false}
                          >
                            {clientType !== "" ? null : "Client Type"}
                          </InputLabel>
                          <Select
                            id="outlined-basic"
                            name="publisher"
                            value={clientType}
                            onChange={event => handleChangeClientType(event)}
                          >
                            {clientTypeList?.length > 0 ? (
                              clientTypeList.map((item, index) => {
                                return (
                                  <MenuItem
                                    key={index}
                                    value={item.client_classification_id}
                                  >
                                    {item.classification_name}
                                  </MenuItem>
                                );
                              })
                            ) : (
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                            )}
                          </Select>
                        </FormControl>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6} xl={6}>
                      <div
                        style={{
                          marginBottom: "10px",
                        }}
                      >
                        <ThemeProvider theme={theme}>
                          <label className={classes.label} htmlFor={"dsp"}>
                            Client Name{" "}
                            <span className={classes.required}>*</span>
                          </label>
                        </ThemeProvider>
                      </div>
                      <div
                        style={{
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        <TextField
                          style={{
                            marginTop: "6px",
                          }}
                          className={classes.myTextField}
                          fullWidth
                          onChange={onChange}
                          value={dsp}
                          id={"dsp"}
                          name="dsp"
                          type="text"
                          variant="outlined"
                          InputProps={{
                            classes: { input: classes.inputFields },
                          }}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6} xl={6}>
                      <div
                        style={{
                          marginBottom: "10px",
                        }}
                      >
                        <ThemeProvider theme={theme}>
                          <Typography className={classes.label}>
                            Domicile <span className={classes.required}>*</span>
                          </Typography>
                        </ThemeProvider>
                      </div>
                      <div
                        style={{
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        <FormControl
                          variant="outlined"
                          className={classes.formControl}
                          fullWidth={true}
                        >
                          <InputLabel
                            id="demo-simple-select-outlined-label"
                            shrink={false}
                          >
                            {domicilytype !== "" ? null : "Domicile"}
                          </InputLabel>
                          <Select
                            id="outlined-basic"
                            variant="outlined"
                            fullWidth={true}
                            name="domicilyType"
                            value={domicilytype}
                            onChange={event =>
                              setDomicilytype(event.target.value)
                            }
                          >
                            {listDomicile.map((item, index) => {
                              return (
                                <MenuItem key={index} value={item.id}>
                                  {item.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </div>
                    </Grid>
                  </Grid>

                  <div
                    style={{
                      marginTop: "16px",
                    }}
                  >
                    <ThemeProvider theme={theme}>
                      <label
                        className={classes.label}
                        htmlFor={"photo"}
                        style={{
                          marginTop: "16px",
                        }}
                      >
                        Client Logo
                      </label>
                    </ThemeProvider>
                  </div>
                  <Grid
                    style={{
                      marginTop: "6px",
                    }}
                  >
                    <Grid item xs={8}>
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        {file === null ? (
                          <div
                            style={{
                              width: "160px",
                              height: "160px",
                              border: "1px solid #D1D5DC",
                              borderRadius: "6px",
                              backgroundColor: "white",
                            }}
                          >
                            <InnoImage
                              src={UnggahLogo}
                              alt="Preview"
                              className={classes.imgPreview}
                              styleImageContainer={{
                                width: "100%",
                                height: "100%",
                                boxShadow: "none",
                                padding: 0,
                              }}
                              styleImage={{
                                maxWidth: "150px",
                                maxHeight: "150px",
                              }}
                            />
                          </div>
                        ) : (
                          <InnoImage
                            src={objectUrl}
                            alt="Preview"
                            className={classes.imgPreview}
                            styleImage={{
                              maxWidth: "150px",
                              maxHeight: "150px",
                            }}
                            style={{
                              border: "1px solid #D1D5DC",
                              borderRadius: "6px",
                              boxShadow: "none",
                              padding: 0,
                            }}
                          />
                        )}
                        <input
                          onChange={onChangeFile}
                          ref={inputFile}
                          style={{ display: "none" }}
                          type="file"
                          name="photo"
                        />
                        <Button
                          onClick={handleChangePhotoButton}
                          variant="outlined"
                          style={{
                            textTransform: "none",
                            backgroundColor: "black",
                            color: "white",
                            width: "129px",
                            height: "40px",
                            marginLeft: "16px",
                          }}
                        >
                          <ThemeProvider theme={theme}>
                            <Typography
                              style={{
                                color: "white",
                                fontStyle: "normal",
                                fontWeight: 500,
                                fontSize: "14px",
                                lineHeight: "20px",
                              }}
                            >
                              Change Logo
                            </Typography>
                          </ThemeProvider>
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                  <div
                    style={{
                      marginTop: "16px",
                    }}
                  >
                    <ThemeProvider theme={theme}>
                      <label className={classes.label} htmlFor="theme">
                        Identity Color{" "}
                        <span className={classes.required}>*</span>
                      </label>
                    </ThemeProvider>
                  </div>
                  <div
                    style={{
                      textAlign: "center",

                      borderRadius: "6px",
                      marginTop: "6px",
                    }}
                  >
                    <Grid>
                      <Grid item xs={8}>
                        <div className={classes.colorPickerArea}>
                          <TextField
                            fullWidth={true}
                            name="color"
                            onChange={onChange}
                            value={color}
                            variant="outlined"
                            disabled
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <div className={colorPrev.changeColor}></div>
                                  <Divider
                                    className={classes.colorDivider}
                                    orientation="vertical"
                                  />
                                </InputAdornment>
                              ),
                              endAdornment: (
                                <InputAdornment position="end">
                                  <ExpandMore />
                                </InputAdornment>
                              ),
                            }}
                            onClick={() =>
                              setShowColorPicker(
                                showColorPicker => !showColorPicker
                              )
                            }
                          />
                          {showColorPicker && (
                            <ChromePicker
                              color={color}
                              onChange={updateColor =>
                                setColor(updateColor.hex)
                              }
                            />
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                  {}
                  {}
                </div>
              </Grid>
              <Grid item xs={6}></Grid>
              <Grid item xs={6}>
                <ThemeProvider theme={theme}>
                  <Typography variant="h5" className={classes.title}>
                    PIC
                  </Typography>
                </ThemeProvider>
                <div
                  style={{
                    border: "1px solid #9AA2B1",
                    borderRadius: "8px",
                    padding: "16px",
                    marginTop: "16px",
                  }}
                >
                  <Grid item xs={12}>
                    <ThemeProvider theme={theme}>
                      <label className={classes.label} htmlFor="nama">
                        PIC Name <span className={classes.required}>*</span>
                      </label>
                    </ThemeProvider>
                    <TextField
                      style={{
                        marginTop: "6px",
                      }}
                      fullWidth
                      onChange={onChange}
                      id="nama"
                      name="nama"
                      value={nama}
                      type="text"
                      variant="outlined"
                      className={classes.myTextField}
                      InputProps={{
                        classes: { input: classes.inputFields },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <div
                      style={{
                        marginTop: "16px",
                      }}
                    >
                      <ThemeProvider theme={theme}>
                        <label className={classes.label} htmlFor="email">
                          Email <span className={classes.required}>*</span>
                        </label>
                      </ThemeProvider>
                    </div>
                    <TextField
                      style={{
                        marginTop: "6px",
                      }}
                      fullWidth
                      onChange={onChange}
                      id="email"
                      name="email"
                      value={email}
                      type="email"
                      variant="outlined"
                      className={classes.myTextField}
                      InputProps={{
                        classes: { input: classes.inputFields },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <div
                      style={{
                        marginTop: "16px",
                      }}
                    >
                      <ThemeProvider theme={theme}>
                        <label className={classes.label} htmlFor="telepon">
                          Phone Number{" "}
                          <span className={classes.required}>*</span>
                        </label>
                      </ThemeProvider>
                    </div>
                    <TextField
                      style={{
                        marginTop: "6px",
                      }}
                      fullWidth
                      className={classes.myTextField}
                      onChange={onChange}
                      id="telepon"
                      name="telepon"
                      value={telepon}
                      type="tel"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <div
                      style={{
                        marginTop: "16px",
                      }}
                    >
                      <ThemeProvider theme={theme}>
                        <label className={classes.label} htmlFor="telepon">
                          Address <span className={classes.required}>*</span>
                        </label>
                      </ThemeProvider>
                    </div>
                    <TextField
                      id="outlined-basic"
                      name="alamat"
                      variant="outlined"
                      multiline
                      rows={4}
                      fullWidth={true}
                      margin="dense"
                      onChange={event => handleChangeAddress(event)}
                      placeholder="Address"
                      value={alamat}
                    />
                  </Grid>
                </div>
              </Grid>
              <Grid item xs={6}></Grid>
              <Grid item xs={6}>
                <ThemeProvider theme={theme}>
                  <Typography variant="h5" className={classes.title}>
                    {" "}
                    Transfer & Fee Currency{" "}
                  </Typography>
                </ThemeProvider>
                <ThemeProvider theme={theme}>
                  <p
                    style={{
                      marginTop: "5px",
                      color: "#8f8f8f",
                      fontSize: "14px",
                    }}
                  >
                    To Settings Transfer Fee & Currency of Client
                  </p>
                </ThemeProvider>
                <div
                  style={{
                    border: "1px solid #9AA2B1",
                    borderRadius: "8px",
                    padding: "16px",
                    marginTop: "16px",
                  }}
                >
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6} lg={6} xl={6}>
                      <div
                        style={{
                          marginBottom: "10px",
                        }}
                      >
                        <ThemeProvider theme={theme}>
                          <Typography className={classes.label}>
                            Currency
                          </Typography>
                        </ThemeProvider>
                      </div>
                      <div
                        style={{
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        <FormControl
                          variant="outlined"
                          className={classes.formControl}
                          fullWidth={true}
                        >
                          {}
                          <Select
                            displayEmpty={true}
                            id="outlined-basic"
                            variant="outlined"
                            fullWidth={true}
                            name="publisher"
                            className={classes.selectPadding}
                            value={curr_id}
                            onChange={event => handleChangeCurrency(event)}
                          >
                            {currencyList?.length > 0 ? (
                              currencyList.map((item, index) => {
                                return (
                                  <MenuItem key={index} value={item?.id}>
                                    {item?.currency_iso_code}
                                  </MenuItem>
                                );
                              })
                            ) : (
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                            )}
                          </Select>
                        </FormControl>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6} xl={6}>
                      <div
                        style={{
                          marginBottom: "10px",
                        }}
                      >
                        <ThemeProvider theme={theme}>
                          <Typography className={classes.label}>
                            Transfer Fee
                          </Typography>
                        </ThemeProvider>
                      </div>
                      <div
                        style={{
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        <NumberFormat
                          disabled={false}
                          customInput={TextField}
                          variant="outlined"
                          thousandSeparator={true}
                          onChange={e => changeValueTotalClaim(e.target.value)}
                          autoComplete="off"
                          className={classes.inputNum}
                          style={{
                            paddingLeft: "0px",
                            height: "44px",
                          }}
                          size="small"
                          fullWidth
                          value={trans_fee}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              <Grid item xs={6}></Grid>

              {}
              {}

              {}

              <Grid item xs={6}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <Button
                    onClick={() => {
                      history.push("/admin/parameter/dsp");
                    }}
                    style={{
                      marginTop: "20px",
                      marginBottom: "20px",
                      marginRight: "10px",
                      height: "40px",
                      width: "90px",
                      borderRadius: "6px",
                      border: "1px solid #D1D5DC",
                      color: "#111827",
                      backgroundColor: "white",
                      textTransform: "none",
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    type="submit"
                    className={
                      submitting ? classes.buttonDisabled : classes.button
                    }
                    disabled={submitting}
                  >
                    <ThemeProvider theme={theme}>
                      <Typography
                        style={{
                          color: "white",
                        }}
                      >
                        Save Changes
                      </Typography>
                    </ThemeProvider>
                  </Button>
                </div>
              </Grid>
            </Grid>

            <Dialog
              keepMounted
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle id="alert-dialog-slide-title">
                {"Delete Tax"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                  Are you sure you want to delete this data?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  style={{
                    height: "40px",
                    width: "90px",
                    borderRadius: "6px",
                    border: "1px solid #D1D5DC",
                    color: "#111827",
                    backgroundColor: "white",
                    textTransform: "none",
                  }}
                >
                  Cancel
                </Button>
                <Button
                  style={{
                    height: "40px",
                    width: "90px",
                    borderRadius: "6px",
                    border: "0px solid #D1D5DC",
                    color: "white",
                    backgroundColor: "#111827",
                    textTransform: "none",
                  }}
                >
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
          </form>
        </Container>
      </Page>
    </div>
  );
};

export default EditDsp;
