import { Typography } from "@material-ui/core";
import { formatDate, formatTime, infiniteDate } from "../../../utils";

const DateTimeDisplay = ({ date }) => {
  return infiniteDate(date) ? (
    <Typography variant="h6">-</Typography>
  ) : (
    <div>
      <Typography variant="h6">{formatDate(date)}</Typography>
      <Typography variant="body2">{formatTime(date)}</Typography>
    </div>
  );
};
export default DateTimeDisplay;
