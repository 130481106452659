const cookie = require("react-cookies");
const CryptoJS = require("crypto-js");

export const setSessionLogin = responseData => {
  const project = process.env.REACT_APP_NAME;
  const objAuth = {};
  objAuth[`${project}_isLoggedIn`] = true;
  objAuth[`${project}_user`] = responseData;
  objAuth[`${project}_env`] = process.env.REACT_APP_MODE;
  setCookie("auth", objAuth);
};
export const setRememberMeLogin = responseData => {
  const project = process.env.REACT_APP_NAME;
  const objAuthRememberMe = {};
  objAuthRememberMe[`${project}_email`] = responseData.email;
  objAuthRememberMe[`${project}_password`] = responseData.password;
  objAuthRememberMe[`${project}_env`] = process.env.REACT_APP_MODE;
  setCookie("authRemember", objAuthRememberMe);
};

export const setRememberMeAdminLogin = responseData => {
  const project = process.env.REACT_APP_NAME;
  const objAuthRememberMe = {};
  objAuthRememberMe[`${project}_email`] = responseData.email;
  objAuthRememberMe[`${project}_password`] = responseData.password;
  objAuthRememberMe[`${project}_env`] = process.env.REACT_APP_MODE;
  setCookie("authRememberAdmin", objAuthRememberMe);
};

export const setVersion = responseData => {
  const project = process.env.REACT_APP_NAME;
  const objAuthVersion = {};
  objAuthVersion[`${project}_fe`] = responseData?.fe;
  objAuthVersion[`${project}_be`] = responseData?.api;
  objAuthVersion[`${project}_env`] = process.env.REACT_APP_MODE;
  setCookie("authVersion", objAuthVersion);
};

// set Cookie or Local Storage ?
export const setCookie = (name, objCookies) => {
  const ciphertext = CryptoJS.AES.encrypt(
    JSON.stringify(objCookies),
    process.env.REACT_APP_SECRET_ENCRYPTER
  );
  localStorage.setItem(name, ciphertext.toString(), { path: "/" });
  return cookie;
};

// get Cookie or Local Storage ?
export const getCookie = (name, str) => {
  let decrypt = null;
  if (
    localStorage.getItem(name) !== undefined &&
    localStorage.getItem(name) !== null
  ) {
    const bytes = CryptoJS.AES.decrypt(
      localStorage.getItem(name),
      process.env.REACT_APP_SECRET_ENCRYPTER
    );

    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    decrypt = decryptedData[str];
  }
  return decrypt;
};

// Clear Cookie or Local Storage ?
export const clearCookie = () => {
  // localStorage.removeItem("subDomain", { path: "/" });
  // localStorage.removeItem("typeWeb", { path: "/" });
  // localStorage.removeItem("authVersion", { path: "/" });
  // localStorage.removeItem("themeColor", { path: "/" });
  // localStorage.removeItem("role_id", { path: "/" });
  // localStorage.removeItem("role", { path: "/" });
  // localStorage.removeItem("token", { path: "/" });
  // Clear localStorage
  localStorage.clear();

  // Clear sessionStorage
  sessionStorage.clear();

  // Clear cookies
  document.cookie.split(";").forEach(c => {
    document.cookie = c
      .replace(/^ +/, "")
      .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
  });

  // Clear cache
  if ("caches" in window) {
    caches.keys().then(names => {
      names.forEach(name => caches.delete(name));
    });
  }

  // Clear IndexedDB
  const request = indexedDB.deleteDatabase("your-database-name");
  request.onsuccess = function () {
    console.log("Database deleted successfully");
  };
  request.onerror = function () {
    console.log("Error deleting database");
  };

  console.log("All browser data cleared");
  window.location.href = "/";
};
