export default function Chip(params) {
  const { width, height, color, title, type } = params;
  const style = {
    border: "1px solid",
    width: `${width}px`,
    height: `${height}px`,
    borderRadius: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textTransform: "capitalize",
    color: color
      ? color
      : type === "iklan"
      ? "#EA9437"
      : type === "layanan"
      ? "#54B371"
      : "#4991F2",
    borderColor: color
      ? color
      : type === "iklan"
      ? "#EA9437"
      : type === "layanan"
      ? "#54B371"
      : "#4991F2",
  };
  return (
    <div className="chip" style={style}>
      {title ? title : type}
    </div>
  );
}
Chip.defaultProps = {
  title: "",
  width: 90,
  height: 28,
};
