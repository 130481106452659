import {
  SET_IMAGE_TENTANG_KAMI_1,
  SET_IMAGE_TENTANG_KAMI_2,
  SET_IMAGE_VISI_KAMI_1,
  SET_IMAGE_VISI_KAMI_2,
  SET_IMAGE_PUBLISHER_1,
  SET_IMAGE_PUBLISHER_2,
  SET_IMAGE_PUBLISHER_3,
  SET_IMAGE_PUBLISHER_4,
  SET_IMAGE_PUBLISHER_5,
  SET_IMAGE_PUBLISHER_6,
  GET_TENTANG_KAMI_TEXT,
  GET_VISI_KAMI_TEXT,
  GET_DSP_NAME,
  GET_DSP_LOGO,
} from "../../../constants/types";

const initialState = {
  dspName: "",
  dspLogo: "",
  tentangKamiText: "",
  visiKamiText: "",
  tentangKamiImage1: {
    raw: "",
    preview: "",
  },
  tentangKamiImage2: {
    raw: "",
    preview: "",
  },
  visiKamiImage1: {
    raw: "",
    preview: "",
  },
  visiKamiImage2: {
    raw: "",
    preview: "",
  },
  publisherImage1: {
    raw: "",
    preview: "",
  },
  publisherImage2: {
    raw: "",
    preview: "",
  },
  publisherImage3: {
    raw: "",
    preview: "",
  },
  publisherImage4: {
    raw: "",
    preview: "",
  },
  publisherImage5: {
    raw: "",
    preview: "",
  },
  publisherImage6: {
    raw: "",
    preview: "",
  },
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_IMAGE_TENTANG_KAMI_1:
      return {
        ...state,
        tentangKamiImage1: {
          raw: action.payload,
          preview: URL.createObjectURL(action.payload),
        },
      };
    case SET_IMAGE_TENTANG_KAMI_2:
      return {
        ...state,
        tentangKamiImage2: {
          raw: action.payload,
          preview: URL.createObjectURL(action.payload),
        },
      };
    case SET_IMAGE_VISI_KAMI_1:
      return {
        ...state,
        visiKamiImage1: {
          raw: action.payload,
          preview: URL.createObjectURL(action.payload),
        },
      };
    case SET_IMAGE_VISI_KAMI_2:
      return {
        ...state,
        visiKamiImage2: {
          raw: action.payload,
          preview: URL.createObjectURL(action.payload),
        },
      };
    case SET_IMAGE_PUBLISHER_1:
      return {
        ...state,
        publisherImage1: {
          raw: action.payload,
          preview: URL.createObjectURL(action.payload),
        },
      };
    case SET_IMAGE_PUBLISHER_2:
      return {
        ...state,
        publisherImage2: {
          raw: action.payload,
          preview: URL.createObjectURL(action.payload),
        },
      };
    case SET_IMAGE_PUBLISHER_3:
      return {
        ...state,
        publisherImage3: {
          raw: action.payload,
          preview: URL.createObjectURL(action.payload),
        },
      };
    case SET_IMAGE_PUBLISHER_4:
      return {
        ...state,
        publisherImage4: {
          raw: action.payload,
          preview: URL.createObjectURL(action.payload),
        },
      };
    case SET_IMAGE_PUBLISHER_5:
      return {
        ...state,
        publisherImage5: {
          raw: action.payload,
          preview: URL.createObjectURL(action.payload),
        },
      };
    case SET_IMAGE_PUBLISHER_6:
      return {
        ...state,
        publisherImage6: {
          raw: action.payload,
          preview: URL.createObjectURL(action.payload),
        },
      };
    case GET_TENTANG_KAMI_TEXT:
      return {
        ...state,
        tentangKamiText: action.payload,
      };
    case GET_VISI_KAMI_TEXT:
      return {
        ...state,
        visiKamiText: action.payload,
      };
    case GET_DSP_NAME:
      return {
        ...state,
        dspName: action.payload,
      };
    case GET_DSP_LOGO:
      return {
        ...state,
        dspLogo: action.payload,
      };
    default:
    // empty
  }
  return state;
}
