import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { Button, Col, Row, Tooltip } from "antd";
import { useState } from "react";
export default function FAQ() {
  const [active, setActive] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      {isMobile ? null : (
        <div className="ending">
          <h1
            style={{
              textAlign: "center",
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: "36px",
              lineHeight: "44px",
            }}
          >
            Everything You Should Know.
          </h1>
          
          <div className="content-footer">
            <div className="item">
              <Row
                className="row"
                style={{ backgroundColor: active === 1 && "#F9FAFB" }}
                onClick={() => (active === 1 ? setActive(0) : setActive(1))}
              >
                <Col span={20}>
                  <h3>What is music publishing?</h3>
                  {active === 1 && (
                    <p style={{ color: "#687083" }}>
                      Taking care of the business side, enabling composers and
                      songwriters to concentrate on their creative work.The role
                      of the publisher music includes: Finding new songwriters
                      and composers who talented, and encourage and support them
                      when develop their skills, provide them with facilities
                      they need to produce music or offer written advice and
                      guidance for specific markets.
                      <br />
                      Get commission for new works, Register works of
                      songwriters and composers to all institutions and
                      appropriate collection agencies, for example PRS for Music
                      in UK Produces demo recordings and, in the case of
                      classical music contemporary, performance material (scores
                      and sections, etc.)
                      <br />
                      Produce and license print music productions with produce
                      promotional materials, including demos, press-kits
                      electronics, sampler CDs, study scores, brochures etc.
                      Promote composers and songwriters and their music to
                      artists, broadcasters, record companies, and others who
                      use music on a commercial basis, both national and
                      international international. Permission to use music, both
                      online directly in individual and special use cases (e.g.
                      synchronization agreement) or through community networks.
                      <br />
                      Responding to new licensing opportunities flowing from
                      technological development. Usage monitoring and tracking
                      the music they represent and ensure that payments
                      appropriate for all licensed uses. Making royalty payments
                      to songwriters and composers regarding their use of music
                      Take action suitable for anyone who uses music without
                      required license. Music publishing and copyright Business
                      publishing music depends on the existence of a strong
                      copyright.
                      <br />
                      Kontrol hak cipta memungkinkan penerbit untuk memulihkan
                      investasi mereka dalam penulisanlagu dan komposer dan
                      untuk memastikan bahwa penulis benar dikreditkan dan
                      dihargai untuk karya kreatif mereka.
                      <br /> Copyright control allows publishers to recover
                      their investment in songwriting and composing and for
                      ensure that authors are properly credited and rewarded for
                      their creative work.
                      <br /> Under this contract, the songwriter and composer
                      grant or license copyright in their music to music
                      publishers in exchange for a commitment to promote,
                      exploit and protect the music. Publisher agrees to pay
                      songwriter/composer royalties obtained from their
                      composition after deducting costs administration.
                    </p>
                  )}
                </Col>
                <Col span={4}>
                  <h3 style={{ textAlign: "right" }}>
                    <Tooltip title="">
                      <Button
                        shape="circle"
                        icon={
                          active === 1 ? (
                            <MinusOutlined style={{ color: "#687083" }} />
                          ) : (
                            <PlusOutlined style={{ color: "#687083" }} />
                          )
                        }
                      />
                    </Tooltip>
                  </h3>
                </Col>
              </Row>
              <hr style={{ width: "100%" }} />
              <Row
                className="row"
                style={{ backgroundColor: active === 2 && "#F9FAFB" }}
                onClick={() => (active === 2 ? setActive(0) : setActive(2))}
              >
                <Col span={20}>
                  <h3>
                    What is the difference between a music publisher and a
                    record company?
                  </h3>
                  {active === 2 && (
                    <p style={{ color: "#687083" }}>
                      Music publishers invest in songwriters and composers and
                      control the composition of the music, while the record
                      company invest in artists and control master recordings.
                      Not all songwriters and composers are recording artists
                      and many recording artists do not write their own songs.
                      Money collected separately for songs and master
                      recordings. For example, Ed Sheeran co-wrote his own song
                      but also writes for other artists such as One Direction.
                      By because of that, it has a music publishing agreement as
                      well recording contract. Some of the authors who co-wrote
                      with Ed Sheeran in the songs he records is not a recording
                      artist. Therefore, their only source of income is of music
                      publishing royalties.
                    </p>
                  )}
                </Col>
                <Col span={4}>
                  <h3 style={{ textAlign: "right" }}>
                    <Tooltip title="">
                      <Button
                        shape="circle"
                        icon={
                          active === 2 ? (
                            <MinusOutlined style={{ color: "#687083" }} />
                          ) : (
                            <PlusOutlined style={{ color: "#687083" }} />
                          )
                        }
                      />
                    </Tooltip>
                  </h3>
                </Col>
              </Row>
              <hr style={{ width: "100%" }} />
              <Row
                className="row"
                style={{ backgroundColor: active === 3 && "#F9FAFB" }}
                onClick={() => (active === 3 ? setActive(0) : setActive(3))}
              >
                <Col span={20}>
                  <h3>What rights do music publishers invest in?</h3>
                  {active === 3 && (
                    <p style={{ color: "#687083" }}>
                      There are several different rights in a song. They
                      sometimes not found in the law, but managed differently
                      for commercial reasons. Therefore Therefore, the issuance
                      contract will refer to: Mechanical rights – rights to make
                      reproductions or copies – often managed collectively by
                      MCPS. (See Q13) Performance rights – rights to appear in
                      public or communicate with the public (eg through digital
                      and/or broadcast music services). This often co-managed
                      with other publishers through PRS. (See Q14) Graphic
                      rights – rights to reproduce musical notation (sheet
                      music) graphically. It is frequently sublicensed through a
                      print publisher (see Q7) Lease/lease rights: the right to
                      lend orchestral parts for unavailable music to buy because
                      of the length or complexity of the arrangement.
                    </p>
                  )}
                </Col>
                <Col span={4}>
                  <h3 style={{ textAlign: "right" }}>
                    <Tooltip title="">
                      <Button
                        shape="circle"
                        icon={
                          active === 3 ? (
                            <MinusOutlined style={{ color: "#687083" }} />
                          ) : (
                            <PlusOutlined style={{ color: "#687083" }} />
                          )
                        }
                      />
                    </Tooltip>
                  </h3>
                </Col>
              </Row>
              <hr style={{ width: "100%" }} />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
