import Swal from "sweetalert2";

const ModalError = (error, title) =>
  Swal.fire({
    title: title || "Error",
    text: error,
    icon: "error",
    confirmButtonText: "OK",
  });

export default ModalError;
