import {
  Button,
  Container,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import { InnoTable } from "inno-ui";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import Swal from "sweetalert2";
import PencilVector from "../../../../assets/img/pencilVector.svg";
import RefreshVector from "../../../../assets/img/refresh-cw.svg";
import { ButtonWithModalPerItem } from "../../../../components/atoms/Button";
import { ButtonGroupTop } from "../../../../components/atoms/Button";
import HeaderPage from "../../../../layouts/Header/HeaderPage";
import { Page, SkeletonComponent } from "components";
import { hardBaseUrl } from "../../../../services/urlConstant";
import { emptyText } from "../../../../utils/";

const fontInter = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  results: {
    marginTop: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  inner: {
    minWidth: 700,
    border: "1px solid #D1D5DC",
    borderRadius: "6px",
    boxShadow: "none",
  },
  button: {
    marginBottom: "20px",
    backgroundColor: "black",
    color: "white",
    width: "auto",
    height: "44px",
    "&:hover": {
      color: "white",
      backgroundColor: "black",
    },
  },
  textAdd: {
    fontSize: "14px",
    textTransform: "none",
  },
  colorBox: {
    width: "30px",
    height: "30px",
  },
  tableArea: {
    marginTop: "20px",
  },
  pagination: {
    "& .Mui-selected": {
      backgroundColor: "black",
      color: "white",
    },
  },
  pageSize: {
    height: "30px",
    borderRadius: "6px",
    border: "1px solid #D1D5DC",
    paddingLeft: "5px",
    paddingRight: "5px",
    marginTop: "5px",
  },
  paginationText: {
    fontSize: "14px",
    fontWeight: "normal",
    fontStyle: "normal",
  },
  inputFields: {
    "& .MuiOutlinedInput-input": {
      padding: "0px",
      height: "44px",
      width: "268px",
    },
  },
  ib: {
    padding: "4px",
    color: "white",
    backgroundColor: "black",
    borderRadius: "6px",
    "&:focus": {
      color: "white",
      backgroundColor: "black",
    },
    "&:active": {
      boxShadow: "none",
      color: "white",
      backgroundColor: "black",
    },
    "&:hover": {
      color: "white",
      backgroundColor: "black",
      boxShadow: "none",
    },
    "&:hover, &.MuiIconButton": {
      background: "#374151 !important",
    },
  },
  ibRefresh: {
    padding: "4px",
    color: "white",
    marginLeft: "8px",
    backgroundColor: "black",
    borderRadius: "6px",
    "&:focus": {
      color: "white",
      backgroundColor: "black",
    },
    "&:active": {
      boxShadow: "none",
      color: "white",
      backgroundColor: "black",
    },
    "&:hover": {
      color: "white",
      backgroundColor: "black",
      boxShadow: "none",
    },
    "&:hover, &.MuiIconButton": {
      background: "#374151 !important",
    },
  },
}));

function Dsp({ userLogin }) {
  const classes = useStyles();
  const history = useHistory();
  let theToken = localStorage.getItem("token");
  const [response, setResponse] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalData, setTotalData] = useState(1);
  const [page, setPage] = useState(1);
  const [searchKey, setSearchKey] = useState("");
  const [loadingTable, setLoadingTable] = useState(false);
  const pagesCount = Math.ceil(totalData / rowsPerPage);

  const handleChangeRowsPerPage = event => {
    event.preventDefault();
    setRowsPerPage(event.target.value);

    setPage(1);
  };

  const handleChangePage = (event, data) => {
    setPage(data);
  };

  const getResponse = () => {
    setLoadingTable(true);
    const url = `${hardBaseUrl}/dsp?&search=${searchKey}&page=${page}&size=${rowsPerPage}`;
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${theToken}`,
        },
      })
      .then(res => {
        setResponse(res.data.data);
        setTotalData(res.data.meta.total);
        setPage(res.data.meta.page);
        setLoadingTable(false);
      })
      .catch(() => {
        setLoadingTable(false);
      });
  };

  const handleChangeSearch = event => {
    setPage(1);
    setSearchKey(event.target.value);
  };

  const handleDelete = id => {
    let theToken = localStorage.getItem("token");
    const url = `${hardBaseUrl}/dsp/${id}`;
    axios
      .delete(url, {
        headers: {
          Authorization: `Bearer ${theToken}`,
        },
      })
      .then(res => {
        if (res.data.message === "success") {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Data deleted successfully",
          }).then(result => {
            if (result.isConfirmed === true) {
              getResponse();
            }
          });
        }
      })
      .catch(err => {
        new Error(err);
      });
  };
  const handleRecovery = id => {
    let theToken = localStorage.getItem("token");
    const url = `${hardBaseUrl}/dsp-recovery/${id}`;
    const formData = new FormData();
    axios
      .put(url, formData, {
        headers: {
          Authorization: `Bearer ${theToken}`,
        },
      })
      .then(res => {
        if (res.data.message === "success") {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Data recovery successfully",
          }).then(result => {
            if (result.isConfirmed === true) {
              getResponse();
            }
          });
        }
      })
      .catch(err => {
        new Error(err);
      });
  };

  const handleOnEdit = item => {
    history.push({
      pathname: `/admin/parameter/dsp-admin/${item.dsp_id}`,
      state: { row: item },
    });
  };

  useEffect(() => {
    getResponse();
  }, [rowsPerPage, page, searchKey]);

  const CustomAction = ({ item }) => {
    return (
      <>
        <IconButton
          classes={{
            root: classes.ib,
          }}
          onClick={() => {
            handleOnEdit(item);
          }}
        >
          <img alt="the_pencil_logo" src={PencilVector} />
        </IconButton>
        {item?.is_active_flag ? (
          <ButtonWithModalPerItem
            userLogin={userLogin}
            item={item}
            title={"Delete DSP"}
            className={classes.ib}
            dialogTitle={"Delete DSP"}
            subTitle={"Are you sure you want to delete this data"}
            handleDelete={() => {
              handleDelete(item?.dsp_id);
            }}
          />
        ) : (
          <IconButton
            classes={{
              root: classes.ibRefresh,
            }}
            onClick={() => {
              handleRecovery(item?.dsp_id);
            }}
          >
            <img alt="the_pencil_logo" src={RefreshVector} />
          </IconButton>
        )}
      </>
    );
  };

  const renderColumnColor = item => {
    return (
      <div
        style={{
          backgroundColor: item,
        }}
        className={classes.colorBox}
      />
    );
  };

  const columnTable = [
    {
      name: "all",
      title: "Action",
      renderText: item => <CustomAction item={item} />,
    },
    {
      name: "all",
      title: "DSP",
      renderText: item => emptyText(item?.name),
    },
    {
      name: "all",
      title: "Identity Color",
      renderText: item => renderColumnColor(item?.color),
    },
    {
      name: "all",
      title: "Person In Charge",
      renderText: item => emptyText(item?.person_name),
    },
    {
      name: "all",
      title: "Email",
      renderText: item => emptyText(item?.person_email),
    },
    {
      name: "all",
      title: "Phone Number",
      renderText: item => emptyText(item?.person_phone),
    },
  ];

  return (
    <Page className={classes.root} title="Dsp">
      {loadingTable ? (
        <SkeletonComponent variant="wave" />
      ) : (
        <Container maxWidth={false}>
          <HeaderPage title="Parameter" breadcrumbs={["Parameter", "DSP"]} />
          <ButtonGroupTop />
          <Divider className={classes.divider} />
          <Grid container justifyContent="space-between">
            <Grid item>
              <FormControl>
                <TextField
                  id="fliterText"
                  name="filterText"
                  className={classes.inputFields}
                  type="text"
                  variant="outlined"
                  placeholder="Search"
                  onChange={event => handleChangeSearch(event)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img
                          alt="Logo"
                          src={
                            require("assets/image-public/images/search.svg")
                              .default
                          }
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                className={classes.button}
                startIcon={<AddIcon />}
                href="/admin/parameter/dsp-admin/add"
              >
                <ThemeProvider theme={fontInter}>
                  <Typography className={classes.textAdd}>Add DSP</Typography>
                </ThemeProvider>
              </Button>
            </Grid>
          </Grid>
          <InnoTable
            columns={columnTable}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            idColumnName={"performer_id"}
            isLoading={loadingTable}
            isUsingCheckbox={false}
            items={response}
            loadingColor={""}
            page={page}
            rowsPerPage={rowsPerPage}
            totalPage={pagesCount}
          />
        </Container>
      )}
    </Page>
  );
}

export default Dsp;
