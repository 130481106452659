import { Button, ButtonGroup, Container, Divider } from "@material-ui/core";
import { Grid } from "@mui/material";
import axios from "axios";
import {
  ModalError,
  ModalSuccess,
  Page,
  PrimaryButton,
  SecondaryButton,
  SkeletonComponent,
} from "components";
import { HeaderTitle } from "layouts";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { globalStyles } from "styles";
import { getErrors } from "utils";
import { hardBaseUrl } from "../../../services/urlConstant";
import ContractDetailForm from "./Components/ContractDetailForm";
import ModalDeactivateContract from "./Components/ModalDeactivateContract";
import SupportingDocuments from "./Components/SupportingDocument";
import { debounce } from "lodash";

const ContractDetail = () => {
  const classes = globalStyles();
  const history = useHistory();
  const { id } = useParams();
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const [selectedMenu, setSelectedMenu] = useState(1);
  const [modalVisible, setModalVisible] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);
  const [pageDetail, setPageDetail] = useState({
    contract: {},
    songs: [],
  });

  const [dataTable, setDataTable] = useState([]);
  const [queryParams, setQueryParams] = useState({
    page: 1,
    limit: 10,
    sort: "-created_at",
    search: "",
  });
  const handleChangeQueryParams = (value, key) => {
    setQueryParams(currentState => ({
      ...currentState,
      [key]: value,
    }));
  };

  const getDetail = async () => {
    try {
      const res = await axios.get(
        `${hardBaseUrl}/publisher/contract/original-publisher/${id}`,
        { headers }
      );
      const { data } = res?.data;
      const modifiedData = {
        ...data,
        songs: data?.songs?.map(song => ({
          ...song,
          composers: song?.composers?.map(composer => ({
            ...composer,
            iswc_code: song?.iswc_code,
          })),
        })),
      };
      setPageDetail(modifiedData);
    } catch (error) {
      ModalError(getErrors(error?.response));
    }
  };
  const getDataTable = async () => {
    try {
      setLoadingPage(true);
      const res = await axios.get(
        `${hardBaseUrl}/publisher/contract-document/original-publisher/${id}`,
        { headers, params: queryParams }
      );
      setDataTable(res?.data?.data || []);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  const debounceDataTable = useCallback(
    debounce(() => {
      getDataTable();
    }, 500),
    [queryParams]
  );
  const handleDeactivateContract = async date => {
    try {
      const res = await axios.delete(
        `${hardBaseUrl}/publisher/contract/status/original-publisher/${id}`,
        {
          is_active_flag: false,
          inactive_at: moment(date).format("YYYY-MM-DD"),
        },
        { headers }
      );
      ModalSuccess(res.data.message).then(() => getDetail());
    } catch (error) {
      ModalError(getErrors(error?.response));
    }
  };
  const fetchData = async () => {
    const promises = [];
    promises.push(getDetail());
    promises.push(getDataTable());
    try {
      await Promise.all(promises);
    } catch (error) {
      ModalError(error, "Error fetching data");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    const isSearching = Boolean(queryParams?.search);
    if (isSearching) {
      debounceDataTable();
      return () => {
        debounceDataTable.cancel();
      };
    } else {
      getDataTable();
    }
  }, [queryParams, debounceDataTable]);

  return (
    <Page className={classes.root} title="Contract Original Publisher Detail">
      {loadingPage ? (
        <SkeletonComponent variant="wave" />
      ) : (
        <Container maxWidth={false}>
          <HeaderTitle
            title="Contract Detail"
            breadcrumbData={breadcrumbData}
            backButton
          />
          <Grid container justifyContent="space-between" my="16px">
            <Grid item>
              <ButtonGroup>
                {menuContract?.map(({ title, id }) => {
                  const selected = id === selectedMenu;
                  return (
                    <Button
                      className={
                        selected
                          ? classes.groupButtonSelected
                          : classes?.groupButton
                      }
                      key={id}
                      onClick={() => {
                        setSelectedMenu(id);
                      }}
                    >
                      {title}
                    </Button>
                  );
                })}
              </ButtonGroup>
            </Grid>
            <Grid item>
              <Grid container columnSpacing={1}>
                {pageDetail?.contract?.is_active_flag && (
                  <Grid item>
                    <PrimaryButton
                      label="Deactivate"
                      onClick={() => setModalVisible(true)}
                    />
                  </Grid>
                )}
                <Grid item>
                  <SecondaryButton
                    label="Edit Contract"
                    onClick={() =>
                      history.push(
                        `/admin/kontrak-original-publisher/edit/${id}`
                      )
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
          {selectedMenu === 1 ? (
            <ContractDetailForm pageDetail={pageDetail} />
          ) : (
            <SupportingDocuments
              getDataTable={getDataTable}
              dataTable={dataTable}
              queryParams={queryParams}
              handleChangeQueryParams={handleChangeQueryParams}
            />
          )}
        </Container>
      )}
      <ModalDeactivateContract
        open={modalVisible}
        onClose={() => setModalVisible(false)}
        onSubmit={handleDeactivateContract}
      />
    </Page>
  );
};

const menuContract = [
  {
    id: 1,
    title: "Detail",
  },
  {
    id: 2,
    title: "Supporting Document",
  },
];
const breadcrumbData = [
  {
    label: "Home",
    link: "/admin/dashboard",
  },
  {
    label: "Contract Original Publisher",
    link: "/admin/kontrak-original-publisher",
  },
  {
    label: "Contract Detail",
    active: true,
  },
];

export default ContractDetail;
