import React, { useState, useEffect, useMemo, useCallback } from "react";
import { makeStyles } from "@material-ui/styles";
import NumberFormat from "react-number-format";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Chip,
  Paper,
  Container,
  Divider,
  Grid,
  FormControl,
  OutlinedInput,
  Select,
  Button,
  Typography,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  Card,
  CardContent,
  Checkbox,
  MenuItem,
  Switch,
  CardActions,
} from "@material-ui/core";
import Swal from "sweetalert2";
import TableContainer from "@material-ui/core/TableContainer";
import AddIcon from "@material-ui/icons/Add";
import gradients from "../../../../theme/gradients";
import { hardBaseUrl } from "../../../../services/urlConstant";
import axios from "axios";
import CheckIcon from "@material-ui/icons/Check";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { useDropzone } from "react-dropzone";
import { BackupOutlined } from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import InsertDriveFileOutlinedIcon from "@material-ui/icons/InsertDriveFileOutlined";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment";
import { Backdrop } from "@material-ui/core";
const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});
function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isNumericString
    />
  );
}
const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  card: {
    width: theme.breakpoints.values.md,
    maxWidth: "100%",
    overflow: "visible",
    display: "flex",
    position: "relative",
    "& > *": {
      flexGrow: 1,
      flexBasis: "50%",
      width: "50%",
    },
  },
  content: {
    padding: theme.spacing(8, 4, 3, 4),
  },
  media: {
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    padding: theme.spacing(3),
    color: theme.palette.common.white,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  avatar_large: {
    width: theme.spacing(16),
    height: theme.spacing(16),
  },
  icon: {
    backgroundImage: gradients.green,
    color: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    position: "absolute",
    top: -32,
    left: theme.spacing(3),
    height: 64,
    width: 64,
    fontSize: 32,
  },
  loginForm: {
    marginTop: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  person: {
    marginTop: theme.spacing(2),
    display: "flex",
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  title: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "28px",
  },
  tambahLagu: {
    backgroundColor: "black",
    color: "white",
    width: "32px",
    height: "29px",
    borderRadius: "6px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
  textTambahLagu: {
    marginLeft: "10px",
    marginTop: "5px",
    fontSize: "14px",
    color: "#687083",
  },
  btnDialog: {
    backgroundColor: "black",
    color: "white",
    padding: "10px 28px",
    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
  btnSubmit: {
    backgroundColor: "black",
    color: "white",
    padding: "10px 28px",
    height: "40px",
    borderRadius: "6px",
    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
  headText: {
    fontSize: "12px",
    color: "#687083",
    fontWeight: "bold",
  },
  bodyTextBlack: {
    fontSize: "14px",
    fontWeight: "regular",
    color: "#111827",
  },
  bodyTextGrey: {
    fontSize: "14px",
    fontWeight: "regular",
    color: "#687083",
  },
  tableRow: {
    height: 20,
  },
  conTextBlack: {
    fontSize: "14px",
    fontWeight: "bold",
  },
  dropdown: {
    color: "black",
  },
  label: {
    color: "#364052",
    fontSize: "14px",
    fontWeight: 500,
  },
  subTitle: {
    fontSize: "18px",
    fontWeight: 700,
    color: " #111827",
  },
  breadcrumbs: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#111827",
  },
  breadCrumbsActive: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#687083",
  },
  rootSwitch: {
    width: "40px",
    height: "20px",
    padding: "0px",
    borderRadius: "10px",
    marginRight: "8px",
    position: "relative",
  },
  switchBase: {
    padding: "10px",
    color: "white",
    "&.Mui-checked": {},
    "&.Mui-checked + $track": {
      border: "none",
      color: "white",
      backgroundColor: "black",
      opacity: 1,
    },
  },
  checked: {},
  thumb: {
    color: "white",
    position: "absolute",
    width: "16px",
    height: "16px",
  },
  track: {
    backgroundColor: "#E4E7EB",
    borderRadius: "25px",
    border: "0px solid #606060",
    height: "auto",
    opacity: 1,
  },
  pageSize: {
    height: "30px",
    borderRadius: "6px",
    border: "1px solid #D1D5DC",
    paddingLeft: "5px",
    paddingRight: "5px",
    marginTop: "5px",
  },
  paginationText: {
    fontSize: "14px",
    fontWeight: "normal",
    fontStyle: "normal",
    color: "#687083",
  },
  pagination: {
    "& .Mui-selected": {
      backgroundColor: "black",
      color: "white",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));
const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "50px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#303030",
  borderStyle: "solid",
  backgroundColor: "white",
  color: "black",
  outline: "none",
  transition: "border .24s ease-in-out",
};
const activeStyle = {
  borderColor: "#2196f3",
};
const acceptStyle = {
  borderColor: "#00e676",
};
const rejectStyle = {
  borderColor: "#ff1744",
};
function TambahKontrak() {
  const classes = useStyles();
  const [timePeriod, setTimePeriod] = useState("1");
  const [contractNumber, setContractNumber] = useState("");
  const [startDate, setStartDate] = useState(moment().toString());
  const [endDate, setEndDate] = useState(moment().toString());
  const [dateRange, setDateRange] = useState(0);
  const [sharePercentage] = useState("");
  const [keterangan, setKeterangan] = useState("");
  const [open, setOpen] = useState(false);
  const [wordsSong, setWordsSong] = useState("");
  const [isLoadSong, setIsLoadSong] = useState(true);
  const [songList, setSongList] = useState([]);
  const [advanceValueList, setAdvanceValueList] = useState([]);
  const [agentList, setAgentList] = useState([]);
  const [agentValueList, setAgentValueList] = useState([]);
  const [getColor, setGetColor] = useState("");
  const [penciptaList, setPenciptaList] = useState([]);
  const [checked] = useState(false);
  const [advanceValueCheck, setAdvanceValueCheck] = useState(false);
  const [pageAdvanceValue, setPageAdvanceValue] = useState(1);
  const [rowsPerPageAdvanceValue, setRowsPerPageAdvanceValue] = useState(5);
  const [sharePercentageList, setSharePercentageList] = useState([]);
  const [pageSharePercentage, setPageSharePercentage] = useState(1);
  const [rowsPerPageSharePercentage, setRowsPerPageSharePercentage] = useState(
    5
  );
  const [pageSharePercentageValue] = useState(1);
  const [sharePercentageValue, setSharePercentageValue] = useState(0);
  const [agentSwitch, setAgentSwitch] = useState(false);
  const [publisherShareList, setPublisherShareList] = useState([]);
  const [publisherFilterList, setPublisherFilterList] = useState([]);
  const [publisherFilterValue, setPublisherFilterValue] = useState(0);
  const [pagePublisherShare, setPagePublisherShare] = useState(1);
  const [rowsPerPagePublisherShare, setRowsPerPagePublisherShare] = useState(5);
  const [pagePublisherShareValue] = useState(1);
  const [gambar, setGambar] = useState([]);
  const [advanceFilterValue, setAdvanceFilterValue] = useState(0);
  const [advanceFilterValueList, setAdvanceFilterValueList] = useState([]);
  const [
    ownershipPercentageFilterList,
    setOwnershipPercentageFilterList,
  ] = useState([]);
  const [agentFilterValue, setAgentFilterValue] = useState(0);
  const [agentFilterValueList, setAgentFilterValueList] = useState([]);
  const [pageAgent, setPageAgent] = useState(1);
  const [rowsPerPageAgent, setRowsPerPageAgent] = useState(5);
  const [loadingContract, setLoadingContract] = useState(false);
  const [perpuityContract, setPerpuityContract] = useState(false);
  const handleChangePageAgent = (event, newPage) => {
    setPageAgent(newPage);
  };
  const handleChangeAgentFilter = event => {
    setAgentFilterValue(event.target.value);
  };
  const handleChangePublisherFilter = event => {
    setPublisherFilterValue(event.target.value);
  };
  const handleChangeAdvanceFilter = event => {
    setAdvanceFilterValue(event.target.value);
  };
  const handleDrop = useCallback(acceptedFiles => {
    if (acceptedFiles[0].size > 2000000) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "File size cannot be more than 2mb",
      });
    } else {
      setGambar(acceptedFiles);
    }
  }, []);
  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: ".pdf",
    onDrop: handleDrop,
  });
  const truncate = (str, n) => {
    return str?.length > n ? str.substr(0, n - 1) + "..." : str;
  };
  const handleRemoveFile = idx => {
    setGambar(gambar.filter((_, index) => index !== idx));
    acceptedFiles.splice(idx, 1);
  };
  const files =
    acceptedFiles[0]?.size > 2000000
      ? null
      : acceptedFiles.map((file, i) => {
        return (
          <Card
            key={file.name}
            style={{
              backgroundColor: "white",
              boxShadow: "none",
              borderRadius: "6px",
              border: "1px solid #E5E5E5",
            }}
          >
            <CardContent>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <InsertDriveFileOutlinedIcon
                    style={{
                      color: "#9AA2B1",
                      width: "13.33px",
                      height: "15px",
                      marginTop: "3px",
                    }}
                  />
                  <Typography
                    style={{
                      fontSize: "14px",
                      fontWeight: 400,
                      marginLeft: "5px",
                    }}
                  >
                    {truncate(file.name, 20)}
                  </Typography>
                </div>
                <div>
                  <CloseOutlinedIcon
                    onClick={() => handleRemoveFile(i)}
                    style={{
                      color: "#364052",
                      width: "12px",
                      height: "12px",
                      marginTop: "3px",
                      cursor: "pointer",
                    }}
                  />
                </div>
              </div>
            </CardContent>
          </Card>
        );
      });
  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );
  const publisherShareSlice = publisherShareList.slice(
    (pagePublisherShare - 1) * rowsPerPagePublisherShare,
    pagePublisherShare * rowsPerPagePublisherShare
  );
  const handleChangeAgentSwitch = event => {
    setAgentSwitch(event.target.checked);
  };
  const handleChangePerpuityContract = event => {
    setPerpuityContract(event.target.checked);
  };
  const advanceValueSlice = advanceValueList.slice(
    (pageAdvanceValue - 1) * rowsPerPageAdvanceValue,
    pageAdvanceValue * rowsPerPageAdvanceValue
  );
  const agentSlice = agentList.slice(
    (pageAgent - 1) * rowsPerPageAgent,
    pageAgent * rowsPerPageAgent
  );
  const pagesCountSharePercentage = song_composer => {
    return Math.ceil(song_composer.length / rowsPerPageSharePercentage);
  };
  const pagesCountAdvance = song_list => {
    return Math.ceil(song_list.length / rowsPerPageAdvanceValue);
  };
  const pagesCountAgent = song_list => {
    return Math.ceil(song_list.length / rowsPerPageAgent);
  };
  const pagesCountPublisher = song_list => {
    return Math.ceil(song_list.length / rowsPerPageAgent);
  };
  const sharePercentageSlice = sharePercentageList.slice(
    (pageSharePercentageValue - 1) * rowsPerPageSharePercentage,
    pageSharePercentageValue * rowsPerPageSharePercentage
  );
  useEffect(() => { }, [
    rowsPerPageSharePercentage,
    pageSharePercentageValue,
    sharePercentageList,
    pageSharePercentage,
    penciptaList,
    rowsPerPagePublisherShare,
    pagePublisherShareValue,
    publisherShareList,
    publisherShareSlice,
  ]);
  const handleChangeComposerSharePercentage = event => {
    setSharePercentageValue(event.target.value);
  };
  const handleChangeRowsPerPageAdvanceValue = event => {
    event.preventDefault();
    setRowsPerPageAdvanceValue(event.target.value);
    setPageAdvanceValue(1);
  };
  const handleChangeRowsPerPagePublisherShare = event => {
    event.preventDefault();
    setRowsPerPagePublisherShare(event.target.value);
    setPagePublisherShare(1);
  };
  const handleChangePagePublisherShare = (event, newPage) => {
    event.preventDefault();
    setPagePublisherShare(newPage);
  };
  const pageSizePaginationPublisherShare = () => {
    return (
      <div className={classes.pageSize}>
        <select
          style={{
            backgroundColor: "transparent",
            border: "none",
            outline: "none",
            marginTop: "4px",
          }}
          value={rowsPerPagePublisherShare}
          onChange={handleChangeRowsPerPagePublisherShare}
        >
          {[5, 20, 50].map(pageSize => {
            return (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            );
          })}
        </select>
      </div>
    );
  };
  const handleChangeRowsPerPageAgent = event => {
    event.preventDefault();
    setRowsPerPageAgent(event.target.value);
    setPageAgent(1);
  };
  const pageSizeAgent = () => {
    return (
      <div className={classes.pageSize}>
        <select
          style={{
            backgroundColor: "transparent",
            border: "none",
            outline: "none",
            marginTop: "4px",
          }}
          value={rowsPerPageAgent}
          onChange={handleChangeRowsPerPageAgent}
        >
          {[5, 20, 50].map(pageSize => {
            return (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            );
          })}
        </select>
      </div>
    );
  };
  const pageSizePaginationAdvanceValue = () => {
    return (
      <div className={classes.pageSize}>
        <select
          style={{
            backgroundColor: "transparent",
            border: "none",
            outline: "none",
            marginTop: "4px",
          }}
          value={rowsPerPageAdvanceValue}
          onChange={handleChangeRowsPerPageAdvanceValue}
        >
          {[5, 20, 50].map(pageSize => {
            return (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            );
          })}
        </select>
      </div>
    );
  };
  const handleChangePageSharePercentage = (event, newPage) => {
    event.preventDefault();
    setPageSharePercentage(newPage);
  };
  const handleChangeRowsPerPageSharePercentage = event => {
    event.preventDefault();
    setRowsPerPageSharePercentage(event.target.value);
    setPageSharePercentage(1);
  };
  const pageSizePaginationSharePercentage = () => {
    return (
      <div className={classes.pageSize}>
        <select
          style={{
            backgroundColor: "transparent",
            border: "none",
            outline: "none",
            marginTop: "4px",
          }}
          value={rowsPerPageSharePercentage}
          onChange={handleChangeRowsPerPageSharePercentage}
        >
          {[5, 20, 50].map(pageSize => {
            return (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            );
          })}
        </select>
      </div>
    );
  };
  const handleChangePageAdvanceValue = (event, newPage) => {
    event.preventDefault();
    setPageAdvanceValue(newPage);
  };
  const handleChangeAdvanceValueCheck = event => {
    setAdvanceValueCheck(event.target.checked);
  };
  const handleTimePeriodYear = () => {
    setTimePeriod("2");
  };
  const handleTimePeriodUnlimited = () => {
    setTimePeriod("1");
  };
  const handleChangeContractNumber = e => {
    setContractNumber(e.target.value);
  };
  const handleDateRangeChange = event => {
    setEndDate(
      new Date(startDate.getTime() + event.target.value * 24 * 60 * 60 * 1000)
    );
    setDateRange(event.target.value);
  };
  const handleDateRangeChangeYear = event => {
    setEndDate(
      new Date(
        startDate.getTime() + event.target.value * 365 * 24 * 60 * 60 * 1000
      )
    );
    setDateRange(event.target.value);
  };
  const handleDateChangeStartDate = date => {
    setStartDate(date);
  };
  const handleDateChangeEndDate = date => {
    if (date < startDate) {
      setStartDate(date);
    } else {
      setEndDate(date);
    }
  };
  const handleChangeText = e => {
    setKeterangan(e.target.value);
  };
  const openModalLagu = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const changeSearchSong = e => {
    setIsLoadSong(true);
    setWordsSong(e);
  };
  const tableRowClick = id => {
    const newData = [...songList];
    const index = newData.findIndex(item => item.song_id === id);
    newData[index].checked = !newData[index].checked;
    setSongList(newData);
    if (newData[index].checked === true) {
      setPenciptaList(songList => [...songList, newData[index]]);
      setSharePercentageList(sharePercentageList => [
        ...sharePercentageList,
        {
          ...newData[index],
          song_composer: newData[index].song_composer.map(item => ({
            ...item,
            share_percentage: item.percentage_ownership,
          })),
        },
      ]);
    } else {
      setPenciptaList(songList => songList.filter(item => item.song_id !== id));
      setSharePercentageList(sharePercentageList =>
        sharePercentageList.filter(item => item.song_id !== id)
      );
    }
  };
  useEffect(() => {
    const newDataAdvanceValueList = penciptaList.map(item => ({
      ...item,
      song_composer: item.song_composer.map(item => ({
        ...item,
        share_percentage: 0,
      })),
    }));
    const getSongComposer = newDataAdvanceValueList.map(item => {
      return item.song_composer;
    });
    const combineSongComposer = getSongComposer.reduce(
      (acc, val) => acc.concat(val),
      []
    );
    const getOnlySureName = combineSongComposer.map(item => {
      return {
        sure_name: item.composer.sure_name,
        song_id: item.song_id,
        composer_id: item.composer_id,
      };
    });
    const removeDuplicate = getOnlySureName.filter(
      (item, index, self) =>
        index === self.findIndex(t => t.sure_name === item.sure_name)
    );
    setAdvanceFilterValueList(removeDuplicate);
    setAgentFilterValueList(removeDuplicate);
    setPublisherFilterList(removeDuplicate);
    const newData = [...penciptaList];
    const newOwnershipPercentageList = newData.map(item => ({
      ...item,
      value: 0,
      start_period: new Date(),
      end_period: new Date(),
    }));
    setOwnershipPercentageFilterList(newOwnershipPercentageList);
    const getSongTitle = (arr, composer_id) => {
      const newArr = [];
      arr.forEach(item => {
        penciptaList.forEach(item2 => {
          if (item.song_id === item2.song_id) {
            item2.song_composer.forEach(item3 => {
              if (item3.composer_id === composer_id) {
                newArr.push({
                  song_title: item.song_title,
                  song_id: item2.song_id,
                  value: 0,
                  composer_id: item.composer_id,
                  start_period: new Date(),
                  end_period: new Date(),
                });
              }
            });
          }
        });
      });
      return newArr;
    };
    const newAdvanceValue = penciptaList.map(item => ({
      ...item,
      publisher_share_value: 0,
      song_composer: item.song_composer.map(item2 => ({
        ...item2,
        songs: getSongTitle(item2?.songs, item2.composer_id),
      })),
    }));
    const getOnlySongComposer = newAdvanceValue.map(item => {
      return item.song_composer;
    });
    const combineSongComposerAdvance = getOnlySongComposer.reduce(
      (acc, val) => acc.concat(val),
      []
    );
    const removeDuplicateAdvance = combineSongComposerAdvance.filter(
      (item, index, self) =>
        index === self.findIndex(t => t.composer_id === item.composer_id)
    );
    setAdvanceValueList(advanceList => {
      const indexAdvanceList = advanceList.map(item => item.composer_id);
      return removeDuplicateAdvance.map(item => {
        if (indexAdvanceList.includes(item.composer_id)) {
          return {
            ...item,
            songs: item.songs.map(item2 => {
              const indexAdvanceList2 = advanceList[
                indexAdvanceList.indexOf(item.composer_id)
              ].songs.map(item => item.song_id);
              if (indexAdvanceList2.includes(item2.song_id)) {
                return {
                  ...item2,
                  value:
                    advanceList[indexAdvanceList.indexOf(item.composer_id)]
                      .songs[indexAdvanceList2.indexOf(item2.song_id)].value,
                  start_period:
                    advanceList[indexAdvanceList.indexOf(item.composer_id)]
                      .songs[indexAdvanceList2.indexOf(item2.song_id)]
                      .start_period,
                  end_period:
                    advanceList[indexAdvanceList.indexOf(item.composer_id)]
                      .songs[indexAdvanceList2.indexOf(item2.song_id)]
                      .end_period,
                };
              } else {
                return item2;
              }
            }),
          };
        } else {
          return item;
        }
      });
    });
    const filterSongListBasedOnPenciptaChoosen = (arr, composer_id) => {
      const newArr = [];
      arr.forEach(item => {
        penciptaList.forEach(item2 => {
          if (item.song_id === item2.song_id) {
            item2.song_composer.forEach(item3 => {
              if (item3.composer_id === composer_id) {
                newArr.push({
                  song_title: item.song_title,
                  song_id: item2.song_id,
                  value: 0,
                  publisher_percentage: item3.publisher_share,
                  composer_id: item.composer_id,
                  type: "sales_amount",
                  agent_id: 0,
                  fee_calculation_list: [
                    { name: "Sales Amount", value: "sales_amount" },
                    { name: "Net Revenue Amount", value: "net_revenue_amount" },
                  ],
                });
              }
            });
          }
        });
      });
      return newArr;
    };
    const newAgentList = penciptaList.map(item => ({
      ...item,
      publisher_share_value: 0,
      song_composer: item.song_composer.map(item2 => ({
        ...item2,
        songs: filterSongListBasedOnPenciptaChoosen(
          item2.songs,
          item2.composer_id
        ),
      })),
    }));
    const getOnlySongComposerAgent = newAgentList.map(item => {
      return item.song_composer;
    });
    const combineSongComposerAgent = getOnlySongComposerAgent.reduce(
      (acc, val) => acc.concat(val),
      []
    );
    const removeDuplicateAgent = combineSongComposerAgent.filter(
      (item, index, self) =>
        index === self.findIndex(t => t.composer_id === item.composer_id)
    );
    setAgentList(advanceList => {
      const indexAdvanceList = advanceList.map(item => item.composer_id);
      return removeDuplicateAgent.map(item => {
        if (indexAdvanceList.includes(item.composer_id)) {
          return {
            ...item,
            songs: item.songs.map(item2 => {
              const indexAdvanceList2 = advanceList[
                indexAdvanceList.indexOf(item.composer_id)
              ].songs.map(item => item.song_id);
              if (indexAdvanceList2.includes(item2.song_id)) {
                return {
                  ...item2,
                  value:
                    advanceList[indexAdvanceList.indexOf(item.composer_id)]
                      .songs[indexAdvanceList2.indexOf(item2.song_id)].value,
                  publisher_percentage:
                    advanceList[indexAdvanceList.indexOf(item.composer_id)]
                      .songs[indexAdvanceList2.indexOf(item2.song_id)]
                      .publisher_percentage,
                  type:
                    advanceList[indexAdvanceList.indexOf(item.composer_id)]
                      .songs[indexAdvanceList2.indexOf(item2.song_id)].type,
                  agent_id:
                    advanceList[indexAdvanceList.indexOf(item.composer_id)]
                      .songs[indexAdvanceList2.indexOf(item2.song_id)].agent_id,
                };
              } else {
                return item2;
              }
            }),
          };
        } else {
          return item;
        }
      });
    });
    setPublisherShareList(advanceList => {
      const indexAdvanceList = advanceList.map(item => item.composer_id);
      return removeDuplicateAgent.map(item => {
        if (indexAdvanceList.includes(item.composer_id)) {
          return {
            ...item,
            songs: item.songs.map(item2 => {
              const indexAdvanceList2 = advanceList[
                indexAdvanceList.indexOf(item.composer_id)
              ].songs.map(item => item.song_id);
              if (indexAdvanceList2.includes(item2.song_id)) {
                return {
                  ...item2,
                  value:
                    advanceList[indexAdvanceList.indexOf(item.composer_id)]
                      .songs[indexAdvanceList2.indexOf(item2.song_id)].value,
                  publisher_percentage:
                    advanceList[indexAdvanceList.indexOf(item.composer_id)]
                      .songs[indexAdvanceList2.indexOf(item2.song_id)]
                      .publisher_percentage,
                  type:
                    advanceList[indexAdvanceList.indexOf(item.composer_id)]
                      .songs[indexAdvanceList2.indexOf(item2.song_id)].type,
                  agent_id:
                    advanceList[indexAdvanceList.indexOf(item.composer_id)]
                      .songs[indexAdvanceList2.indexOf(item2.song_id)].agent_id,
                };
              } else {
                return item2;
              }
            }),
          };
        } else {
          return item;
        }
      });
    });
  }, [penciptaList]);
  const listPencipta = data => {
    let pencipta = data;
    if (pencipta === null || undefined || "") {
      return <div>-</div>;
    } else if (pencipta.length <= 3) {
      return pencipta.map((pencipta, index) => (
        <div key={index}>
          <ThemeProvider theme={theme}>
            <Chip
              key={index}
              label={pencipta.composer.sure_name}
              className={classes.tableChip}
              size="small"
            />
          </ThemeProvider>
        </div>
      ));
    } else if (pencipta.length > 3) {
      let pencipta1 = pencipta.slice(0, 3);
      let pencipta2 = pencipta.slice(3, pencipta.length);
      return (
        <div>
          <ThemeProvider theme={theme}>
            {pencipta1.map((pencipta, index) => (
              <Chip
                key={index}
                label={pencipta.composer.sure_name}
                className={classes.tableChip}
                size="small"
              />
            ))}
            <Chip
              label={"+" + pencipta2.length}
              className={classes.tableChip}
              size="small"
            />
          </ThemeProvider>
        </div>
      );
    }
  };
  const handleReset = () => {
    setOpen(false);
  };
  const getResponse = async () => {
    try {
      const token = localStorage.getItem("token");
      const url =
        hardBaseUrl +
        "/publisher/contract-get-song?page=1&size=10&search=" +
        wordsSong;
      const options = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      const res = await axios.get(url, options);
      const newData = res.data.data.map(item => ({
        ...item,
        checked: false,
      }));
      const newDataSong = value => {
        return value.map(item => {
          let isExist = penciptaList.some(
            item2 => item2.song_id === item.song_id
          );
          if (isExist) {
            return {
              ...item,
              checked: true,
            };
          } else {
            return {
              ...item,
              checked: false,
            };
          }
        });
      };
      setSongList(newDataSong(newData));
      setIsLoadSong(false);
    } catch (error) {
      setIsLoadSong(false);
    }
  };
  useEffect(() => {
    getResponse();
    const getMainDsp = async () => {
      try {
        const token = localStorage.getItem("token");
        const url = `${hardBaseUrl}/me`;
        const res = await axios.get(url, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        setGetColor(res.data.data.publisher.theme_color);
      } catch (e) {
        new Error(e);
      }
    };
    getMainDsp();
  }, [checked, startDate, endDate, wordsSong]);
  const handleDeleteChip = (e, id, songId) => {
    e.stopPropagation();
    const newData = penciptaList.map(item => ({
      ...item,
      song_composer: item.song_composer.filter(
        item => item.composer.composer_id + item.song_id !== id + songId
      ),
    }));
    setPenciptaList(newData);
    setSharePercentageList(prev =>
      [...prev].map(item => ({
        ...item,
        song_composer: item.song_composer.filter(
          item => item.composer.composer_id + item.song_id !== id + songId
        ),
      }))
    );
  };
  const listPenciptaChip = (data, songId) => {
    return (
      <div>
        {data.length > 1
          ? data.map((pencipta, idx) => (
            <Chip
              key={pencipta.composer_id}
              label={pencipta.composer.sure_name}
              className={classes.tableChip}
              size="small"
              onDelete={e =>
                handleDeleteChip(e, pencipta.composer_id, songId, idx)
              }
            />
          ))
          : data.map(pencipta => (
            <Chip
              key={pencipta.composer_id}
              label={pencipta.composer.sure_name}
              className={classes.tableChip}
              size="small"
            />
          ))}
      </div>
    );
  };
  const handleChangeAdvanceValueList = (
    e,
    idComposer,
    indexAdvance,
    idSong
  ) => {
    const newData = [...advanceValueList];
    const indexComposer = advanceValueList[indexAdvance].songs.findIndex(
      item => item.composer_id === idComposer && item.song_id === idSong
    );
    newData[indexAdvance].songs[indexComposer].value = e.target.value;
    setAdvanceValueList(newData);
  };
  const handleChangeStartPeriod = (date, idComposer, indexAdvance, idSong) => {
    const newData = [...advanceValueList];
    const indexComposer = advanceValueList[indexAdvance].songs.findIndex(
      item => item.composer_id === idComposer && item.song_id === idSong
    );
    newData[indexAdvance].songs[indexComposer].start_period = date;
    setAdvanceValueList(newData);
  };
  const handleChangeEndPeriod = (date, idComposer, indexAdvance, idSong) => {
    const newData = [...advanceValueList];
    const indexComposer = advanceValueList[indexAdvance].songs.findIndex(
      item => item.composer_id === idComposer && item.song_id === idSong
    );
    newData[indexAdvance].songs[indexComposer].end_period = date;
    setAdvanceValueList(newData);
  };
  const handleChangeSharePercentageShare = (e, idComposer, idSong) => {
    const newData = [...sharePercentageList];
    const index = sharePercentageList.findIndex(
      item => item.song_id === idSong
    );
    const indexComposer = sharePercentageList[index].song_composer.findIndex(
      item => item.composer_id === idComposer
    );
    newData[index].song_composer[indexComposer].share_percentage =
      e.target.value;
    setSharePercentageList(newData);
  };
  const handleChangeAgentType = (e, idComposer, indexAgent, idSong) => {
    const newData = [...agentList];
    const indexComposer = agentList[indexAgent].songs.findIndex(
      item => item.composer_id === idComposer && item.song_id === idSong
    );
    newData[indexAgent].songs[indexComposer].type = e.target.value;
    setAgentList(newData);
  };
  const handleChangeAgentValueList = (e, idComposer, indexAgent, idSong) => {
    const newData = [...agentList];
    const indexComposer = agentList[indexAgent].songs.findIndex(
      item => item.composer_id === idComposer && item.song_id === idSong
    );
    newData[indexAgent].songs[indexComposer].agent_id = e.target.value;
    setAgentList(newData);
  };
  const handleChangeAgentFee = (e, idComposer, indexAgent, idSong) => {
    const newData = [...agentList];
    const indexComposer = agentList[indexAgent].songs.findIndex(
      item => item.composer_id === idComposer && item.song_id === idSong
    );
    newData[indexAgent].songs[indexComposer].value = e.target.value;
    setAgentList(newData);
  };
  const handleChangePublisherPercentage = (
    e,
    idComposer,
    indexPublisher,
    idSong
  ) => {
    const newData = [...publisherShareList];
    const indexComposer = publisherShareList[indexPublisher].songs.findIndex(
      item => item.composer_id === idComposer && item.song_id === idSong
    );
    newData[indexPublisher].songs[indexComposer].publisher_percentage =
      e.target.value;
    setPublisherShareList(newData);
  };
  const getAgentList = () => {
    const token = localStorage.getItem("token");
    const url = `${hardBaseUrl}/publisher/agent-on-transaction`;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .get(url, config)
      .then(res => {
        setAgentValueList(res.data.data);
      })
      .catch(() => { });
  };
  useEffect(() => {
    getAgentList();
  }, [agentList]);
  const getDateFormatForFilter = date => {
    let dateFormat = new Date(date);
    let day = dateFormat
      .getDate()
      .toString()
      .padStart(2, "0");
    let month = (dateFormat.getMonth() + 1).toString().padStart(2, "0");
    let year = dateFormat.getFullYear();
    let dateString = `${year}-${month}-${day}`;
    return dateString;
  };
  const handleButtonSimpan = e => {
    e.preventDefault();
    setLoadingContract(true);
    const token = localStorage.getItem("token");
    const getOnlySongsAdvance = advanceValueList.map(item => item.songs);
    const combineSongsAdvance = getOnlySongsAdvance.reduce(
      (acc, val) => acc.concat(val),
      []
    );
    const removeDuplicateAdvance = combineSongsAdvance.filter(
      (item, index) =>
        combineSongsAdvance.findIndex(
          item2 =>
            item2.composer_id === item.composer_id &&
            item2.song_id === item.song_id
        ) === index
    );
    const newDataAdvance = removeDuplicateAdvance.map(item => {
      return {
        composer_id: item.composer_id,
        song_id: item.song_id,
        balance: item.value === 0 ? 0 : Number(item.value.replace(/,/g, "")),
        start_period: getDateFormatForFilter(item.start_period),
        end_period: getDateFormatForFilter(item.end_period),
      };
    });
    const newDataOwnership = sharePercentageList.map(item => {
      return {
        song_id: item.song_id,
        composer_share_percentages: item.song_composer.map(item2 => {
          return {
            composer_id: item2.composer_id,
            ownership_percentage:
              item2.share_percentage === 0 || item2.share_percentage === ""
                ? 0
                : Number(item2.share_percentage),
          };
        }),
      };
    });
    const getOnlySongsAgent = agentList.map(item => item.songs);
    const combineSongsAgent = getOnlySongsAgent.reduce(
      (acc, val) => acc.concat(val),
      []
    );
    const removeDuplicateAgent = combineSongsAgent.filter(
      (item, index) =>
        combineSongsAgent.findIndex(
          item2 =>
            item2.composer_id === item.composer_id &&
            item2.song_id === item.song_id
        ) === index
    );
    const newDataAgent = removeDuplicateAgent.map(item => {
      return {
        song_id: item.song_id,
        composer_id: item.composer_id,
        agent_id: item.agent_id,
        agent_fee: item.value === 0 ? 0 : Number(item.value.replace(/,/g, "")),
        fee_type: item.type,
      };
    });
    const getOnlySongsPublisherShare = publisherShareList.map(
      item => item.songs
    );
    const combineSongsPublisherShare = getOnlySongsPublisherShare.reduce(
      (acc, val) => acc.concat(val),
      []
    );
    const removeDuplicatePublisherShare = combineSongsPublisherShare.filter(
      (item, index) =>
        combineSongsPublisherShare.findIndex(
          item2 =>
            item2.composer_id === item.composer_id &&
            item2.song_id === item.song_id
        ) === index
    );
    const newDataPublisher = removeDuplicatePublisherShare
      .map(item => {
        return {
          composer_id: item.composer_id,
          song_percentages: removeDuplicatePublisherShare
            .map(item2 => {
              return {
                song_id: item2.song_id,
                percentage:
                  typeof item2.publisher_percentage === "number"
                    ? item2.publisher_percentage
                    : item2.publisher_percentage === ""
                      ? 0
                      : Number(item2.publisher_percentage.replace(/,/g, "")),
                composer_id: item2.composer_id,
              };
            })
            .filter(item3 => item3.composer_id === item.composer_id),
        };
      })
      .filter(
        (item, index, self) =>
          index === self.findIndex(t => t.composer_id === item.composer_id)
      );
    const payload = {
      contract_number: contractNumber,
      date_periode: Number(dateRange),
      start_date: getDateFormatForFilter(startDate),
      end_date: getDateFormatForFilter(endDate),
      description: keterangan,
      publisher_share_percentage:
        sharePercentage === "" ? 0 : Number(sharePercentage),
      contract_composer_balances: newDataAdvance,
      is_endless_period: perpuityContract,
      ownership_percentages: newDataOwnership,
      publisher_share_percentages: newDataPublisher,
      contract_composer_agents: newDataAgent,
      type_period: timePeriod === "1" ? "unlimited" : "year",
    };
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const url = `${hardBaseUrl}/publisher/contract`;
    axios
      .post(url, payload, config)
      .then(res => {
        if (res.status === 200) {
          const urlDoc = `${hardBaseUrl}/contract-document`;
          const formData = new FormData();
          formData.append("contract_id", res.data.data.contract_id);
          formData.append("documents", gambar[0]);
          axios
            .post(urlDoc, formData, config)
            .then(() => {
              setLoadingContract(false);
              Swal.fire({
                title: "Success",
                text: "Data has been saved",
                icon: "success",
                confirmButtonText: "OK",
              }).then(result => {
                if (result.isConfirmed) {
                  window.location.href = "/admin/kontrak-composer";
                }
              });
            })
            .catch(err => {
              Swal.fire({
                title: "Error",
                text: err.response.data.errors.message,
                icon: "error",
                confirmButtonText: "OK",
              });
              setLoadingContract(false);
            })
            .finally(() => {
              setLoadingContract(false);
            });
        }
      })
      .catch(err => {
        Swal.fire({
          title: "Error",
          text: err.response.data.errors.message,
          icon: "error",
          confirmButtonText: "OK",
        });
        setLoadingContract(false);
      });
  };
  return (
    <>
      <Grid container>
        <Backdrop className={classes.backdrop} open={loadingContract}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <div>
          <ThemeProvider theme={theme}>
            <Typography
              component="h1"
              variant="h3"
              className={classes.subTitle}
            >
              Song Contract Information
            </Typography>
          </ThemeProvider>
          <ThemeProvider theme={theme}>
            <p
              style={{
                marginTop: "5px",
                color: "#8f8f8f",
                fontSize: "14px",
              }}
            >
              Form to fill out information data related to the contract to be
              made
            </p>
          </ThemeProvider>
        </div>
      </Grid>
      <div
        style={{
          marginBottom: "20px",
        }}
      >
        <Button
          onClick={handleTimePeriodUnlimited}
          className={classes.btnDialog}
          style={{
            backgroundColor: timePeriod === "1" ? "black" : "#FFFFFF",
            marginRight: "10px",
            borderColor: timePeriod === "1" ? "#FFFFFF" : "black",
            border: "1px solid #9AA2B1",
          }}
        >
          <ThemeProvider theme={theme}>
            <Typography
              style={{
                textTransform: "none",
                color: timePeriod === "1" ? "#FFFFFF" : "black",
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "20px",
                textDecoration: "none",
              }}
            >
              Perpetual
            </Typography>
          </ThemeProvider>
        </Button>
        <Button
          onClick={handleTimePeriodYear}
          className={classes.btnDialog}
          variant="contained"
          style={{
            backgroundColor: timePeriod === "2" ? "black" : "#FFFFFF",
            borderColor: timePeriod === "2" ? "#FFFFFF" : "black",
            border: "1px solid #9AA2B1",
          }}
        >
          <ThemeProvider theme={theme}>
            <Typography
              style={{
                textTransform: "none",
                color: timePeriod === "2" ? "#FFFFFF" : "black",
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "20px",
                textDecoration: "none",
              }}
            >
              Year
            </Typography>
          </ThemeProvider>
        </Button>
      </div>
      <Grid container>
        <Grid item xs={8} md={8} sm={8} lg={8} xl={8}>
          <Card
            style={{
              border: "1px solid #9AA2B1",
              borderRadius: "6px",
              boxShadow: "none",
            }}
          >
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={6} md={6} sm={6} lg={6} xl={6}>
                  <div
                    style={{
                      marginTop: "5px",
                      marginBottom: "5px",
                    }}
                  >
                    <div
                      style={{
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      <ThemeProvider theme={theme}>
                        <Typography
                          variant="subtitle2"
                          className={classes.label}
                        >
                          Contract Number *
                        </Typography>
                      </ThemeProvider>
                    </div>
                    <div
                      style={{
                        marginTop: "-10px",
                        marginBottom: "10px",
                      }}
                    >
                      <TextField
                        variant="outlined"
                        onChange={handleChangeContractNumber}
                        name="numberformat"
                        id="formatted-numberformat-input"
                        margin="dense"
                        required
                        fullWidth={true}
                      />
                    </div>
                  </div>
                </Grid>
                <Grid item xs={6} md={6} sm={6} lg={6} xl={6}>
                  <div
                    style={{
                      marginTop: "5px",
                      marginBottom: "5px",
                    }}
                  >
                    <div
                      style={{
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      <ThemeProvider theme={theme}>
                        <Typography
                          variant="subtitle2"
                          className={classes.label}
                        >
                          Time Period *
                        </Typography>
                      </ThemeProvider>
                    </div>
                    <div
                      style={{
                        marginTop: "-10px",
                        marginBottom: "10px",
                      }}
                    >
                      <TextField
                        defaultValue={0}
                        required
                        variant="outlined"
                        onChange={
                          timePeriod === "1"
                            ? handleDateRangeChange
                            : handleDateRangeChangeYear
                        }
                        name="numberformat"
                        id="formatted-numberformat-input"
                        margin="dense"
                        disabled={perpuityContract}
                        fullWidth={true}
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                          endAdornment: (
                            <InputAdornment position="end">
                              {timePeriod === "1" ? "Day" : "Year"}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  </div>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6} md={6} sm={6} lg={6} xl={6}>
                  <div
                    style={{
                      marginTop: "5px",
                      marginBottom: "5px",
                    }}
                  >
                    <div
                      style={{
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      <ThemeProvider theme={theme}>
                        <Typography
                          variant="subtitle2"
                          className={classes.label}
                        >
                          Start Date *
                        </Typography>
                      </ThemeProvider>
                    </div>
                    <div
                      style={{
                        marginTop: "-10px",
                        marginBottom: "10px",
                      }}
                    >
                      <KeyboardDatePicker
                        id="outlined-basic"
                        variant="outlined"
                        onChange={handleDateChangeStartDate}
                        value={startDate}
                        name="startDate"
                        required
                        format="DD MMMM YYYY"
                        inputVariant="outlined"
                        fullWidth={true}
                        margin="dense"
                      />
                    </div>
                  </div>
                </Grid>
                <Grid item xs={6} md={6} sm={6} lg={6} xl={6}>
                  <div
                    style={{
                      marginTop: "5px",
                      marginBottom: "5px",
                    }}
                  >
                    <div
                      style={{
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      <ThemeProvider theme={theme}>
                        <Typography
                          variant="subtitle2"
                          className={classes.label}
                        >
                          End Date *
                        </Typography>
                      </ThemeProvider>
                    </div>
                    <div
                      style={{
                        marginTop: "-10px",
                        marginBottom: "10px",
                      }}
                    >
                      <KeyboardDatePicker
                        id="outlined-basic"
                        value={perpuityContract ? null : endDate}
                        onChange={handleDateChangeEndDate}
                        format="DD MMMM YYYY"
                        disabled={perpuityContract}
                        required
                        name="endDate"
                        inputVariant="outlined"
                        fullWidth={true}
                        margin="dense"
                        placeholder="-"
                      />
                      <div style={{ display: "flex" }}>
                        <Switch
                          checked={perpuityContract}
                          onChange={handleChangePerpuityContract}
                          name="checkedB"
                          color="primary"
                          classes={{
                            root: classes.rootSwitch,
                            switchBase: classes.switchBase,
                            thumb: classes.thumb,
                            track: classes.track,
                            checked: classes.checked,
                          }}
                        />
                        <p>This contract will perpetuity</p>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12} sm={12} lg={12} xl={12}>
                <div
                  style={{
                    marginTop: "5px",
                    marginBottom: "5px",
                  }}
                >
                  <div
                    style={{
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    <ThemeProvider theme={theme}>
                      <Typography variant="subtitle2" className={classes.label}>
                        Description
                      </Typography>
                    </ThemeProvider>
                  </div>
                  <div
                    style={{
                      marginTop: "-10px",
                      marginBottom: "10px",
                    }}
                  >
                    <TextField
                      multiline={true}
                      minRows={10}
                      id="outlined-basic"
                      placeholder="Description"
                      variant="outlined"
                      fullWidth={true}
                      margin="dense"
                      name="keterangan"
                      onChange={e => handleChangeText(e)}
                    />
                  </div>
                </div>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4} md={4} sm={4} lg={4} xl={4}></Grid>
      </Grid>
      { }
      <Grid container style={{ marginTop: "20px" }}>
        <div>
          <ThemeProvider theme={theme}>
            <Typography
              component="h1"
              variant="h3"
              className={classes.subTitle}
            >
              Song
            </Typography>
          </ThemeProvider>
          <ThemeProvider theme={theme}>
            <p
              style={{
                marginTop: "5px",
                color: "#8f8f8f",
                fontSize: "14px",
              }}
            >
              This feature can add multiple songs with multiple composer/author
              in contract{" "}
            </p>
          </ThemeProvider>
        </div>
      </Grid>
      <Grid container>
        <Grid item xs={8} md={8} sm={8} lg={8} xl={8}>
          <Grid container>
            <Grid item xs={12} md={12} sm={12} lg={12} xl={12}>
              <div
                style={{
                  marginTop: "5px",
                  marginBottom: "5px",
                }}
              >
                <div
                  style={{
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                >
                  <Paper
                    style={{
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      backgroundColor: "#F9FAFB",
                      cursor: "pointer",
                    }}
                    onClick={openModalLagu}
                  >
                    {
                      <Container>
                        <div style={{ display: "flex" }}>
                          <IconButton
                            onClick={openModalLagu}
                            className={classes.tambahLagu}
                            style={{
                              backgroundColor: " #111827",
                            }}
                          >
                            <AddIcon />
                          </IconButton>
                          <ThemeProvider theme={theme}>
                            <Typography
                              variant="subtitle2"
                              className={classes.textTambahLagu}
                            >
                              Click to add song
                            </Typography>
                          </ThemeProvider>
                        </div>
                      </Container>
                    }
                  </Paper>
                  <Dialog
                    fullWidth={true}
                    maxWidth={"md"}
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="max-width-dialog-title"
                  >
                    <DialogTitle id="max-width-dialog-title">
                      Search Song
                    </DialogTitle>
                    <Grid container>
                      <Grid item xs={12} md={12} sm={12} lg={12} xl={12}>
                        <Container>
                          <div style={{ display: "flex" }}>
                            <FormControl
                              variant="outlined"
                              style={{ display: "none", minWidth: 120 }}
                            >
                              <Select
                                native
                                value="name"
                                onChange={() => { }}
                                disabled
                                inputProps={{
                                  name: "age",
                                  id: "outlined-age-native-simple",
                                }}
                              >
                                <option
                                  value="name"
                                  style={{ color: "#687083" }}
                                >
                                  Song Title
                                </option>
                              </Select>
                            </FormControl>
                            <FormControl variant="outlined" fullWidth={true}>
                              <OutlinedInput
                                id="outlined-adornment-password"
                                placeholder="Search"
                                value={wordsSong}
                                onChange={e => {
                                  changeSearchSong(e.target.value);
                                }}
                              />
                            </FormControl>
                          </div>
                          <Grid container>
                            <Grid item xs={8} md={8} sm={8} lg={8} xl={8}>
                              <Divider className={classes.divider} />
                            </Grid>
                          </Grid>
                          <TableContainer style={{ maxHeight: 200 }}>
                            <Table stickyHeader size="small">
                              <TableHead>
                                <TableRow>
                                  <ThemeProvider theme={theme}>
                                    <TableCell>
                                      <Typography
                                        variant="subtitle2"
                                        className={classes.headText}
                                      >
                                        ISWC Code
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography
                                        variant="subtitle2"
                                        className={classes.headText}
                                      >
                                        Song Title
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography
                                        variant="subtitle2"
                                        className={classes.headText}
                                      >
                                        Composer/Author Name
                                      </Typography>
                                    </TableCell>
                                  </ThemeProvider>
                                  <TableCell></TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {isLoadSong ? (
                                  <>
                                    <TableRow>
                                      <TableCell align="center" colSpan={3}>
                                        <CircularProgress />
                                      </TableCell>
                                    </TableRow>
                                  </>
                                ) : (
                                  <>
                                    {songList.map(customer => (
                                      <TableRow
                                        hover
                                        key={customer.song_id}
                                        onClick={() => {
                                          tableRowClick(
                                            customer.song_id,
                                            customer.song_composer
                                          );
                                        }}
                                      >
                                        <ThemeProvider theme={theme}>
                                          <TableCell>
                                            <Typography
                                              variant="subtitle2"
                                              className={classes.bodyTextGrey}
                                            >
                                              {customer.iswc_code}
                                            </Typography>
                                          </TableCell>
                                          <TableCell>
                                            <Typography
                                              variant="subtitle2"
                                              className={classes.bodyTextBlack}
                                            >
                                              {customer.title_song}
                                            </Typography>
                                          </TableCell>
                                          <TableCell>
                                            <Typography
                                              variant="subtitle2"
                                              className={classes.bodyTextBlack}
                                            >
                                              {listPencipta(
                                                customer.song_composer
                                              )}
                                            </Typography>
                                          </TableCell>
                                        </ThemeProvider>
                                        <TableCell>
                                          {customer.checked === true ? (
                                            <CheckIcon />
                                          ) : (
                                            ""
                                          )}
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                  </>
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Container>
                      </Grid>
                    </Grid>
                    <DialogContent></DialogContent>
                    <DialogActions>
                      <Button
                        onClick={handleReset}
                        className={classes.btnDialog}
                        style={{
                          backgroundColor: getColor || "black",
                        }}
                      >
                        <ThemeProvider theme={theme}>
                          <Typography
                            style={{
                              textTransform: "none",
                              color: "#FFFFFF",
                              fontSize: "14px",
                              fontWeight: 400,
                              lineHeight: "20px",
                              textDecoration: "none",
                            }}
                          >
                            Cancel
                          </Typography>
                        </ThemeProvider>
                      </Button>
                      <Button
                        onClick={handleClose}
                        className={classes.btnDialog}
                        variant="contained"
                        style={{
                          backgroundColor: getColor || "black",
                        }}
                      >
                        <ThemeProvider theme={theme}>
                          <Typography
                            style={{
                              textTransform: "none",
                              color: "#FFFFFF",
                              fontSize: "14px",
                              fontWeight: 400,
                              lineHeight: "20px",
                              textDecoration: "none",
                            }}
                          >
                            Choose
                          </Typography>
                        </ThemeProvider>
                      </Button>
                    </DialogActions>
                  </Dialog>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        style={{
          marginTop: "20px",
        }}
      ></Grid>
      <Grid container>
        <Grid item xs={8} md={8} sm={8} lg={8} xl={8}>
          <Grid container>
            <Grid item xs={12} md={12} sm={12} lg={12} xl={12}>
              <Card
                style={{
                  border: "1px solid #9AA2B1",
                  borderRadius: "6px",
                  boxShadow: "none",
                }}
              >
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <ThemeProvider theme={theme}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={classes.headText}
                          >
                            Song
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={classes.headText}
                          >
                            Composer/Author Name
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={classes.headText}
                          >
                            ISWC Code
                          </Typography>
                        </TableCell>
                      </ThemeProvider>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {penciptaList !== null ? (
                      penciptaList?.length > 0 ? (
                        penciptaList.map(customer => (
                          <TableRow hover key={customer.composer_id}>
                            <TableCell>
                              <Typography
                                variant="subtitle2"
                                className={classes.bodyTextBlack}
                              >
                                {customer.title_song}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography
                                variant="subtitle2"
                                className={classes.bodyTextBlack}
                              >
                                {listPenciptaChip(
                                  customer.song_composer,
                                  customer.song_id
                                )}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography
                                variant="subtitle2"
                                className={classes.bodyTextGrey}
                              >
                                {customer.iswc_code === ""
                                  ? "-"
                                  : customer.iswc_code}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={4}>
                            <center>
                              <ThemeProvider theme={theme}>
                                <Typography
                                  variant="h6"
                                  style={{
                                    fontWeight: 400,
                                    color: "#687083",
                                    fontSize: "14px",
                                  }}
                                >
                                  No Song Selected Yet
                                </Typography>
                              </ThemeProvider>
                            </center>
                          </TableCell>
                        </TableRow>
                      )
                    ) : (
                      <TableRow>
                        <TableCell colSpan={3}>
                          <center>
                            <ThemeProvider theme={theme}>
                              <Typography
                                variant="h6"
                                style={{
                                  fontWeight: 400,
                                  color: "#687083",
                                  fontSize: "14px",
                                }}
                              >
                                no data
                              </Typography>
                            </ThemeProvider>
                          </center>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      { }
      <Grid
        container
        style={{
          marginTop: "20px",
        }}
      >
        <Grid item xs={4} md={4} sm={4} lg={8} xl={8}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <ThemeProvider theme={theme}>
                <Typography variant="h3" className={classes.subTitle}>
                  Advance Value
                </Typography>
              </ThemeProvider>
              <ThemeProvider theme={theme}>
                <p
                  style={{
                    marginTop: "5px",
                    color: "#8f8f8f",
                    fontSize: "14px",
                  }}
                >
                  Feature to set Advance Payment
                </p>
              </ThemeProvider>
            </div>
            <div style={advanceValueCheck ? null : { display: "none" }}>
              <FormControl
                className={classes.formControl}
                fullWidth={true}
                id="dsp-filter"
                style={{ width: "300px" }}
              >
                <TextField
                  fullWidth={true}
                  size="small"
                  className={classes.select}
                  onChange={handleChangeAdvanceFilter}
                  value={advanceFilterValue}
                  select
                  variant="outlined"
                >
                  <MenuItem value={0}>Select Composer</MenuItem>
                  {advanceFilterValueList.map((option, index) => (
                    <MenuItem
                      key={index}
                      value={option.song_id + option.composer_id}
                    >
                      {option.sure_name}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </div>
          </div>
          <ThemeProvider theme={theme}>
            <Grid component="label" container alignItems="center" spacing={1}>
              <Grid item>
                <Checkbox
                  checked={advanceValueCheck}
                  onChange={handleChangeAdvanceValueCheck}
                  style={{
                    color: "#111827",
                  }}
                />
              </Grid>
              <Grid item>
                <p style={{ marginTop: "15px", marginLeft: "-10px" }}>
                  Set Advance Value For Composer/Author
                </p>
              </Grid>
            </Grid>
          </ThemeProvider>
          {advanceValueCheck ? (
            advanceValueList?.length > 0 && advanceFilterValue !== 0 ? (
              advanceValueList.map((item, index) => (
                <Card
                  key={index}
                  style={
                    item.song_id + item.composer_id === advanceFilterValue
                      ? {
                        border: "1px solid #9AA2B1",
                        borderRadius: "6px",
                        boxShadow: "none",
                        marginTop: "10px",
                      }
                      : { display: "none" }
                  }
                >
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <ThemeProvider theme={theme}>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={classes.headText}
                            >
                              Song
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography
                              variant="subtitle2"
                              className={classes.headText}
                            >
                              Advance Value
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography
                              variant="subtitle2"
                              className={classes.headText}
                            >
                              Start Period
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography
                              variant="subtitle2"
                              className={classes.headText}
                            >
                              End Period
                            </Typography>
                          </TableCell>
                        </ThemeProvider>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {item.songs?.length > 0 ? (
                        item.songs
                          .map(customer => (
                            <TableRow hover key={customer.song_composer_id}>
                              <TableCell>
                                <Typography
                                  variant="subtitle2"
                                  className={classes.bodyTextBlack}
                                >
                                  {customer.song_title}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <div
                                  style={{
                                    marginTop: "10px",
                                    marginBottom: "10px",
                                  }}
                                >
                                  <Grid style={{ display: "flex" }}>
                                    <Grid
                                      style={{
                                        width: "48px",
                                        height: "38px",
                                        marginRight: "-2px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        borderRadius: "6px 0px 0px 6px",
                                        backgroundColor: "#F9FAFB",
                                        border: " 1px solid #D1D5DC",
                                        color: "#9AA2B",
                                        borderRight: "none",
                                      }}
                                    >
                                      Rp
                                    </Grid>
                                    <NumberFormat
                                      customInput={TextField}
                                      variant="outlined"
                                      thousandSeparator={true}
                                      onChange={e =>
                                        handleChangeAdvanceValueList(
                                          e,
                                          item.composer_id,
                                          index,
                                          customer.song_id
                                        )
                                      }
                                      autoComplete="off"
                                      value={customer.value}
                                      className={classes.Input}
                                      style={{
                                        paddingLeft: "0px",
                                        height: "44px",
                                        borderRadius: "0px 6px 6px 0px",
                                      }}
                                      size="small"
                                      fullWidth
                                    />
                                  </Grid>
                                </div>
                              </TableCell>
                              <TableCell>
                                <KeyboardDatePicker
                                  id="outlined-basic"
                                  value={customer.start_period}
                                  onChange={e =>
                                    handleChangeStartPeriod(
                                      e,
                                      item.composer_id,
                                      index,
                                      customer.song_id
                                    )
                                  }
                                  format="DD MMMM YYYY"
                                  name="startPeriod"
                                  inputVariant="outlined"
                                  fullWidth={true}
                                  margin="dense"
                                  placeholder="Tanggal Selesai"
                                />
                              </TableCell>
                              <TableCell>
                                <KeyboardDatePicker
                                  id="outlined-basic"
                                  value={customer.end_period}
                                  onChange={e =>
                                    handleChangeEndPeriod(
                                      e,
                                      item.composer_id,
                                      index,
                                      customer.song_id
                                    )
                                  }
                                  format="DD MMMM YYYY"
                                  name="endPeriod"
                                  inputVariant="outlined"
                                  fullWidth={true}
                                  margin="dense"
                                  placeholder="Tanggal Selesai"
                                />
                              </TableCell>
                            </TableRow>
                          ))
                          .slice(
                            (pageAdvanceValue - 1) * rowsPerPageAdvanceValue,
                            pageAdvanceValue * rowsPerPageAdvanceValue
                          )
                      ) : (
                        <TableRow>
                          <TableCell colSpan={4}>
                            <center>
                              <ThemeProvider theme={theme}>
                                <Typography
                                  variant="h6"
                                  style={{
                                    fontWeight: 400,
                                    color: "#687083",
                                    fontSize: "14px",
                                  }}
                                >
                                  no data
                                </Typography>
                              </ThemeProvider>
                            </center>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                  {advanceValueSlice?.length > 0 ? (
                    <CardActions className={classes.actions}>
                      <Grid
                        alignContent="space-between"
                        container
                        justifyContent="space-between"
                        spacing={3}
                      >
                        <Grid item>
                          <div style={{ display: "flex" }}>
                            <div style={{ marginTop: "7px" }}>
                              <ThemeProvider theme={theme}>
                                <Typography className={classes.paginationText}>
                                  Item per page:
                                </Typography>
                              </ThemeProvider>
                            </div>{" "}
                            <div style={{ marginLeft: "10px" }}>
                              {pageSizePaginationAdvanceValue()}
                            </div>
                          </div>
                        </Grid>
                        <Grid item>
                          <Pagination
                            count={pagesCountAdvance(item.songs)}
                            shape="rounded"
                            className={classes.pagination}
                            page={pageAdvanceValue}
                            onChange={handleChangePageAdvanceValue}
                          />
                        </Grid>
                      </Grid>
                    </CardActions>
                  ) : null}
                </Card>
              ))
            ) : (
              <Card
                style={{
                  border: "1px solid #9AA2B1",
                  borderRadius: "6px",
                  boxShadow: "none",
                  marginTop: "10px",
                }}
              >
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <ThemeProvider theme={theme}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={classes.headText}
                          >
                            Song
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography
                            variant="subtitle2"
                            className={classes.headText}
                          >
                            Advance Value
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography
                            variant="subtitle2"
                            className={classes.headText}
                          >
                            Start Period
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography
                            variant="subtitle2"
                            className={classes.headText}
                          >
                            End Period
                          </Typography>
                        </TableCell>
                      </ThemeProvider>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={4}>
                        <center>
                          <ThemeProvider theme={theme}>
                            <Typography
                              variant="h6"
                              style={{
                                fontWeight: 400,
                                color: "#687083",
                                fontSize: "14px",
                              }}
                            >
                              No Composer Selected Yet
                            </Typography>
                          </ThemeProvider>
                        </center>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Card>
            )
          ) : null}
        </Grid>
        <Grid item xs={4} md={4} sm={4} lg={4} xl={4}></Grid>
      </Grid>
      <Grid
        container
        style={{
          marginTop: "20px",
        }}
      >
        <Grid item xs={4} md={4} sm={4} lg={8} xl={8}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <ThemeProvider theme={theme}>
                <Typography variant="h3" className={classes.subTitle}>
                  Ownership Share Percentage
                </Typography>
              </ThemeProvider>
              <ThemeProvider theme={theme}>
                <p
                  style={{
                    marginTop: "5px",
                    color: "#8f8f8f",
                    fontSize: "14px",
                  }}
                >
                  Feature to set ownership share percentage
                </p>
              </ThemeProvider>
            </div>
            <div>
              <FormControl
                className={classes.formControl}
                fullWidth={true}
                id="dsp-filter"
                style={{ width: "300px" }}
              >
                <TextField
                  fullWidth={true}
                  size="small"
                  className={classes.select}
                  onChange={handleChangeComposerSharePercentage}
                  value={sharePercentageValue}
                  select
                  variant="outlined"
                >
                  <MenuItem value={0}>Select Song</MenuItem>
                  {ownershipPercentageFilterList.map((option, index) => (
                    <MenuItem key={index} value={option.song_id}>
                      {option.title_song}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </div>
          </div>
          {sharePercentageList?.length > 0 && sharePercentageValue !== 0 ? (
            sharePercentageList.map((item, index) => (
              <Card
                key={index}
                style={
                  item.song_id !== sharePercentageValue
                    ? { display: "none" }
                    : {
                      border: "1px solid #9AA2B1",
                      borderRadius: "6px",
                      boxShadow: "none",
                      marginTop: "10px",
                    }
                }
              >
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <ThemeProvider theme={theme}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={classes.headText}
                          >
                            Composer/Author Name
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={classes.headText}
                          >
                            Share Percentage
                          </Typography>
                        </TableCell>
                      </ThemeProvider>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {item.song_composer?.length > 0 ? (
                      item.song_composer
                        .map(customer => {
                          return (
                            <TableRow
                              hover
                              key={
                                customer.song_id + customer.composer.composer_id
                              }
                            >
                              <TableCell>
                                <Typography
                                  variant="subtitle2"
                                  className={classes.bodyTextBlack}
                                >
                                  {customer.composer.sure_name}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <div
                                  style={{
                                    marginTop: "10px",
                                    marginBottom: "10px",
                                  }}
                                >
                                  <TextField
                                    value={customer.share_percentage}
                                    variant="outlined"
                                    onChange={e =>
                                      handleChangeSharePercentageShare(
                                        e,
                                        customer.composer_id,
                                        item.song_id
                                      )
                                    }
                                    name="numberformat"
                                    id="formatted-numberformat-input"
                                    margin="dense"
                                    fullWidth={true}
                                    InputProps={{
                                      inputComponent: NumberFormatCustom,
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          {"%"}
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </div>
                              </TableCell>
                            </TableRow>
                          );
                        })
                        .slice(
                          (pageSharePercentage - 1) *
                          rowsPerPageSharePercentage,
                          pageSharePercentage * rowsPerPageSharePercentage
                        )
                    ) : (
                      <TableRow>
                        <TableCell colSpan={4}>
                          <center>
                            <ThemeProvider theme={theme}>
                              <Typography
                                variant="h6"
                                style={{
                                  fontWeight: 400,
                                  color: "#687083",
                                  fontSize: "14px",
                                }}
                              >
                                no data
                              </Typography>
                            </ThemeProvider>
                          </center>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
                {sharePercentageSlice?.length > 0 ? (
                  <CardActions
                    className={classes.actions}
                    style={
                      item.song_id !== sharePercentageValue
                        ? { display: "none" }
                        : null
                    }
                  >
                    <Grid
                      alignContent="space-between"
                      container
                      justifyContent="space-between"
                      spacing={3}
                    >
                      <Grid item>
                        <div style={{ display: "flex" }}>
                          <div style={{ marginTop: "7px" }}>
                            <ThemeProvider theme={theme}>
                              <Typography className={classes.paginationText}>
                                Item per page:
                              </Typography>
                            </ThemeProvider>
                          </div>{" "}
                          <div style={{ marginLeft: "10px" }}>
                            {pageSizePaginationSharePercentage()}
                          </div>
                        </div>
                      </Grid>
                      <Grid item>
                        <Pagination
                          count={pagesCountSharePercentage(item.song_composer)}
                          shape="rounded"
                          className={classes.pagination}
                          page={pageSharePercentage}
                          onChange={handleChangePageSharePercentage}
                        />
                      </Grid>
                    </Grid>
                  </CardActions>
                ) : null}
              </Card>
            ))
          ) : (
            <Card
              style={{
                border: "1px solid #9AA2B1",
                borderRadius: "6px",
                boxShadow: "none",
                marginTop: "10px",
              }}
            >
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <ThemeProvider theme={theme}>
                      <TableCell>
                        <Typography
                          variant="subtitle2"
                          className={classes.headText}
                        >
                          Composer/Author Name
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="subtitle2"
                          className={classes.headText}
                        >
                          Share Percentage
                        </Typography>
                      </TableCell>
                    </ThemeProvider>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={4}>
                      <center>
                        <ThemeProvider theme={theme}>
                          <Typography
                            variant="h6"
                            style={{
                              fontWeight: 400,
                              color: "#687083",
                              fontSize: "14px",
                            }}
                          >
                            No Song Selected Yet
                          </Typography>
                        </ThemeProvider>
                      </center>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Card>
          )}
        </Grid>
        <Grid item xs={4} md={4} sm={4} lg={4} xl={4}></Grid>
      </Grid>
      <Grid
        container
        style={{
          marginTop: "20px",
        }}
      >
        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <Typography
                component="h1"
                variant="h3"
                className={classes.subTitle}
              >
                Publisher Share Percentage
              </Typography>
              <ThemeProvider theme={theme}>
                <p
                  style={{
                    marginTop: "5px",
                    color: "#8f8f8f",
                    fontSize: "14px",
                  }}
                >
                  Feature to set Publisher Share Percentage
                </p>
              </ThemeProvider>
            </div>
            <div>
              <FormControl
                className={classes.formControl}
                fullWidth={true}
                id="dsp-filter"
                style={{ width: "300px" }}
              >
                <TextField
                  fullWidth={true}
                  size="small"
                  className={classes.select}
                  onChange={handleChangePublisherFilter}
                  value={publisherFilterValue}
                  select
                  variant="outlined"
                >
                  <MenuItem value={0}>Select Composer</MenuItem>
                  {publisherFilterList.map((option, index) => (
                    <MenuItem
                      key={index}
                      value={option.song_id + option.composer_id}
                    >
                      {option.sure_name}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </div>
          </div>
          {publisherShareList?.length > 0 && publisherFilterValue !== 0 ? (
            publisherShareList?.map((item, index) => (
              <Card
                key={index}
                style={
                  item.song_id + item.composer_id === publisherFilterValue
                    ? {
                      border: "1px solid #9AA2B1",
                      borderRadius: "6px",
                      boxShadow: "none",
                      marginTop: "10px",
                    }
                    : { display: "none" }
                }
              >
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <ThemeProvider theme={theme}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={classes.headText}
                          >
                            Song
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography
                            variant="subtitle2"
                            className={classes.headText}
                          >
                            Share Percentage
                          </Typography>
                        </TableCell>
                      </ThemeProvider>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {item.songs?.length > 0 ? (
                      item.songs
                        ?.map(customer => (
                          <TableRow hover key={customer?.song_composer_id}>
                            <TableCell>
                              <Typography
                                variant="subtitle2"
                                className={classes.bodyTextBlack}
                              >
                                {customer?.song_title}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <TextField
                                value={customer?.publisher_percentage}
                                variant="outlined"
                                onChange={e =>
                                  handleChangePublisherPercentage(
                                    e,
                                    item?.composer_id,
                                    index,
                                    customer?.song_id
                                  )
                                }
                                name="numberformat"
                                id="formatted-numberformat-input"
                                margin="dense"
                                fullWidth={true}
                                InputProps={{
                                  inputComponent: NumberFormatCustom,
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      {"%"}
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </TableCell>
                          </TableRow>
                        ))
                        .slice(
                          (pagePublisherShare - 1) * rowsPerPagePublisherShare,
                          pagePublisherShare * rowsPerPagePublisherShare
                        )
                    ) : (
                      <TableRow>
                        <TableCell colSpan={4}>
                          <center>
                            <ThemeProvider theme={theme}>
                              <Typography
                                variant="h6"
                                style={{
                                  fontWeight: 400,
                                  color: "#687083",
                                  fontSize: "14px",
                                }}
                              >
                                no data
                              </Typography>
                            </ThemeProvider>
                          </center>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
                {publisherShareSlice.length > 0 ? (
                  <CardActions className={classes.actions}>
                    <Grid
                      alignContent="space-between"
                      container
                      justifyContent="space-between"
                      spacing={3}
                    >
                      <Grid item>
                        <div style={{ display: "flex" }}>
                          <div style={{ marginTop: "7px" }}>
                            <ThemeProvider theme={theme}>
                              <Typography className={classes.paginationText}>
                                Item per page:
                              </Typography>
                            </ThemeProvider>
                          </div>{" "}
                          <div style={{ marginLeft: "10px" }}>
                            {pageSizePaginationPublisherShare()}
                          </div>
                        </div>
                      </Grid>
                      <Grid item>
                        <Pagination
                          count={pagesCountPublisher(item.songs)}
                          shape="rounded"
                          className={classes.pagination}
                          page={pagePublisherShare}
                          onChange={handleChangePagePublisherShare}
                        />
                      </Grid>
                    </Grid>
                  </CardActions>
                ) : null}
              </Card>
            ))
          ) : (
            <Card
              style={{
                border: "1px solid #9AA2B1",
                borderRadius: "6px",
                boxShadow: "none",
                marginTop: "10px",
              }}
            >
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <ThemeProvider theme={theme}>
                      <TableCell>
                        <Typography
                          variant="subtitle2"
                          className={classes.headText}
                        >
                          Song
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography
                          variant="subtitle2"
                          className={classes.headText}
                        >
                          Share Percentage
                        </Typography>
                      </TableCell>
                    </ThemeProvider>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={4}>
                      <center>
                        <ThemeProvider theme={theme}>
                          <Typography
                            variant="h6"
                            style={{
                              fontWeight: 400,
                              color: "#687083",
                              fontSize: "14px",
                            }}
                          >
                            No Composer Selected Yet
                          </Typography>
                        </ThemeProvider>
                      </center>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Card>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}></Grid>
      </Grid>
      <Grid
        container
        style={{
          marginTop: "20px",
        }}
      >
        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <Typography
                component="h1"
                variant="h3"
                className={classes.subTitle}
              >
                Agent
              </Typography>
              <ThemeProvider theme={theme}>
                <p
                  style={{
                    marginTop: "5px",
                    color: "#8f8f8f",
                    fontSize: "14px",
                  }}
                >
                  Feature to set Agent & fee Agent
                </p>
              </ThemeProvider>
            </div>
            <div style={agentSwitch ? null : { display: "none" }}>
              <FormControl
                className={classes.formControl}
                fullWidth={true}
                id="dsp-filter"
                style={{ width: "300px" }}
              >
                <TextField
                  fullWidth={true}
                  size="small"
                  className={classes.select}
                  onChange={handleChangeAgentFilter}
                  value={agentFilterValue}
                  select
                  variant="outlined"
                >
                  <MenuItem value={0}>Select Composer</MenuItem>
                  {agentFilterValueList.map((option, index) => (
                    <MenuItem
                      key={index}
                      value={option.song_id + option.composer_id}
                    >
                      {option.sure_name}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <Switch
              checked={agentSwitch}
              onChange={handleChangeAgentSwitch}
              name="checkedB"
              color="primary"
              classes={{
                root: classes.rootSwitch,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
              }}
            />
            <p>Is Agent engaged?</p>
          </div>
          {agentSwitch ? (
            agentList?.length > 0 && agentFilterValue !== 0 ? (
              agentList?.map((item, index) => (
                <Card
                  key={index}
                  style={
                    item.song_id + item.composer_id === agentFilterValue
                      ? {
                        border: "1px solid #9AA2B1",
                        borderRadius: "6px",
                        boxShadow: "none",
                        marginTop: "10px",
                      }
                      : { display: "none" }
                  }
                >
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <ThemeProvider theme={theme}>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={classes.headText}
                            >
                              Song
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography
                              variant="subtitle2"
                              className={classes.headText}
                            >
                              Agent
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography
                              variant="subtitle2"
                              className={classes.headText}
                            >
                              Agent Fee
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography
                              variant="subtitle2"
                              className={classes.headText}
                            >
                              Fee Calculation Basis
                            </Typography>
                          </TableCell>
                        </ThemeProvider>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {item.songs?.length > 0 ? (
                        item.songs
                          ?.map(customer => (
                            <TableRow hover key={customer?.song_composer_id}>
                              <TableCell>
                                <Typography
                                  variant="subtitle2"
                                  className={classes.bodyTextBlack}
                                >
                                  {customer?.song_title}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <FormControl
                                  variant="outlined"
                                  fullWidth={true}
                                >
                                  <Select
                                    className={classes.mypadding}
                                    id="demo-simple-select-outlined"
                                    onChange={e =>
                                      handleChangeAgentValueList(
                                        e,
                                        item?.composer_id,
                                        index,
                                        customer?.song_id
                                      )
                                    }
                                    autoWidth={true}
                                    value={customer?.agent_id}
                                    style={{
                                      paddingTop: "0px",
                                      paddingRight: "0px",
                                      paddingBottom: "0px",
                                      paddingLeft: "0px",
                                      width: "120px",
                                      height: "40px",
                                    }}
                                  >
                                    <MenuItem value={0}>Select Agent</MenuItem>
                                    {agentValueList?.map((item, idx) => (
                                      <MenuItem
                                        value={item?.agent_id}
                                        key={idx}
                                      >
                                        {item?.name}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </TableCell>
                              <TableCell>
                                <TextField
                                  value={customer?.value}
                                  variant="outlined"
                                  onChange={e =>
                                    handleChangeAgentFee(
                                      e,
                                      item?.composer_id,
                                      index,
                                      customer?.song_id
                                    )
                                  }
                                  name="numberformat"
                                  id="formatted-numberformat-input"
                                  margin="dense"
                                  fullWidth={true}
                                  InputProps={{
                                    inputComponent: NumberFormatCustom,
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        {"%"}
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </TableCell>
                              <TableCell>
                                <FormControl
                                  variant="outlined"
                                  fullWidth={true}
                                >
                                  <Select
                                    className={classes.mypadding}
                                    id="demo-simple-select-outlined"
                                    onChange={e =>
                                      handleChangeAgentType(
                                        e,
                                        item?.composer_id,
                                        index,
                                        customer?.song_id
                                      )
                                    }
                                    autoWidth={true}
                                    value={customer.type}
                                    style={{
                                      paddingTop: "0px",
                                      paddingRight: "0px",
                                      paddingBottom: "0px",
                                      paddingLeft: "0px",
                                      width: "120px",
                                      height: "40px",
                                    }}
                                  >
                                    {customer?.fee_calculation_list?.map(
                                      (item, idx) => (
                                        <MenuItem value={item.value} key={idx}>
                                          {item?.name}
                                        </MenuItem>
                                      )
                                    )}
                                  </Select>
                                </FormControl>
                              </TableCell>
                            </TableRow>
                          ))
                          .slice(
                            (pageAgent - 1) * rowsPerPageAgent,
                            pageAgent * rowsPerPageAgent
                          )
                      ) : (
                        <TableRow>
                          <TableCell colSpan={4}>
                            <center>
                              <ThemeProvider theme={theme}>
                                <Typography
                                  variant="h6"
                                  style={{
                                    fontWeight: 400,
                                    color: "#687083",
                                    fontSize: "14px",
                                  }}
                                >
                                  no data
                                </Typography>
                              </ThemeProvider>
                            </center>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                  {agentSlice.length > 0 ? (
                    <CardActions className={classes.actions}>
                      <Grid
                        alignContent="space-between"
                        container
                        justifyContent="space-between"
                        spacing={3}
                      >
                        <Grid item>
                          <div style={{ display: "flex" }}>
                            <div style={{ marginTop: "7px" }}>
                              <ThemeProvider theme={theme}>
                                <Typography className={classes.paginationText}>
                                  Item per page:
                                </Typography>
                              </ThemeProvider>
                            </div>{" "}
                            <div style={{ marginLeft: "10px" }}>
                              {pageSizeAgent()}
                            </div>
                          </div>
                        </Grid>
                        <Grid item>
                          <Pagination
                            count={pagesCountAgent(item.songs)}
                            shape="rounded"
                            className={classes.pagination}
                            page={pageAgent}
                            onChange={handleChangePageAgent}
                          />
                        </Grid>
                      </Grid>
                    </CardActions>
                  ) : null}
                </Card>
              ))
            ) : (
              <Card
                style={{
                  border: "1px solid #9AA2B1",
                  borderRadius: "6px",
                  boxShadow: "none",
                  marginTop: "10px",
                }}
              >
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <ThemeProvider theme={theme}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={classes.headText}
                          >
                            Song
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography
                            variant="subtitle2"
                            className={classes.headText}
                          >
                            Agent
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography
                            variant="subtitle2"
                            className={classes.headText}
                          >
                            Agent Fee
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography
                            variant="subtitle2"
                            className={classes.headText}
                          >
                            Fee Calculation Basis
                          </Typography>
                        </TableCell>
                      </ThemeProvider>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={4}>
                        <center>
                          <ThemeProvider theme={theme}>
                            <Typography
                              variant="h6"
                              style={{
                                fontWeight: 400,
                                color: "#687083",
                                fontSize: "14px",
                              }}
                            >
                              No Composer Selected Yet
                            </Typography>
                          </ThemeProvider>
                        </center>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Card>
            )
          ) : null}
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}></Grid>
      </Grid>
      <Grid container>
        <Grid item xs={8} md={8} sm={8} lg={8} xl={8}>
          <Divider className={classes.divider} />
        </Grid>
      </Grid>
      { }
      <Grid container>
        <div>
          <ThemeProvider theme={theme}>
            <Typography
              component="h1"
              variant="h3"
              className={classes.subTitle}
            >
              Supporting Document
            </Typography>
          </ThemeProvider>
          <ThemeProvider theme={theme}>
            <p
              style={{
                marginTop: "5px",
                color: "#8f8f8f",
                fontSize: "14px",
              }}
            >
              Upload supporting documents, such as scanned agreement documents
              or can be in the form of pdf files
            </p>
          </ThemeProvider>
        </div>
      </Grid>
      <Grid container>
        <Grid item xs={8} md={8} sm={8} lg={8} xl={8}>
          <Card
            style={{
              border: "1px solid #9AA2B1",
              borderRadius: "6px",
              boxShadow: "none",
            }}
          >
            <CardContent>
              <ThemeProvider theme={theme}>
                <Typography variant="subtitle2" className={classes.label}>
                  Upload Document
                </Typography>
              </ThemeProvider>
              <div style={{ marginTop: "20px" }}>
                <div {...getRootProps({ style })}>
                  <input {...getInputProps()} />
                  {isDragActive ? (
                    <>
                      <BackupOutlined
                        style={{ color: "grey", fontSize: "40px" }}
                      />
                      <Typography variant="h6">
                        <p>Drop the files here ...</p>
                      </Typography>
                    </>
                  ) : (
                    <>
                      <BackupOutlined
                        style={{ color: "grey", fontSize: "40px" }}
                      />
                      <Typography variant="h6">
                        <p>
                          <b>Search File</b>, Drag document here
                        </p>
                      </Typography>
                    </>
                  )}
                </div>
                <div
                  style={{
                    marginTop: "20px",
                  }}
                >
                  <aside>
                    <Typography component="h1" variant="h5">
                      Files :
                    </Typography>
                    <ul>
                      <Typography component="h1" variant="h5">
                        {files}
                      </Typography>
                    </ul>
                  </aside>
                </div>
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
      <Grid container>
        <Button
          variant="contained"
          onClick={e => handleButtonSimpan(e)}
          className={classes.btnSubmit}
          style={{
            backgroundColor: "black",
          }}
        >
          <ThemeProvider theme={theme}>
            <Typography
              style={{
                textTransform: "none",
                color: "#FFFFFF",
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "20px",
              }}
            >
              Add Contract
            </Typography>
          </ThemeProvider>
        </Button>
      </Grid>
    </>
  );
}
export default TambahKontrak;
