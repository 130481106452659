import {
  Breadcrumbs,
  Button,
  Container,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  OutlinedInput,
  Typography,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import { useEffect, useState } from "react";
import { Page } from "components";
import { hardBaseUrl } from "../../../services/urlConstant";
import Results from "./Results";

const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  results: {
    marginTop: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  formControl: {},
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selectMenu: {
    backgroundColor: "red",
  },
  pageSize: {
    height: "30px",
    borderRadius: "6px",
    border: "1px solid #D1D5DC",
    paddingLeft: "5px",
    paddingRight: "5px",
    marginTop: "5px",
  },
  paginationText: {
    fontSize: "14px",
    fontWeight: "normal",
    fontStyle: "normal",
  },
  breadcrumbs: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#111827",
  },
  breadCrumbsActive: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#687083",
  },
  placeholder: {
    "&::placeholder": {
      color: "#9AA2B1",
    },
  },
}));

function Kontrak() {
  const classes = useStyles();

  const [getColor, setGetColor] = useState("");
  const values = {
    keySearch: "",
    password: "",
    weight: "",
    weightRange: "",
    showPassword: false,
  };

  const [response, setResponse] = useState([]);

  const [penciptaInString] = useState("");
  const [searchKey, setSearchKey] = useState("");

  const [pageSize, setPageSize] = useState(10);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const totalData = 1;
  const [page, setPage] = useState(1);

  const handleChangeSearch = event => {
    setPage(1);
    setSearchKey(event.target.value);
  };

  useEffect(() => {
    const getResponse = async () => {
      try {
        const token = localStorage.getItem("token");
        let baseOfUrl = hardBaseUrl;

        const url =
          baseOfUrl +
          `/publisher/contract?page=${page}&limit=${rowsPerPage}&nomor_kontrak=` +
          values.keySearch +
          "&composer=" +
          penciptaInString +
          "&search=" +
          searchKey;

        const options = {
          headers: {
            Authorization: "Bearer " + token,
          },
        };

        const res = await axios.get(url, options);

        setResponse(res.data.data);
        setPage(res.data.meta.page);
        setPageSize(res.data.meta.limit);
      } catch (error) {}
    };
    const getMainDsp = async () => {
      try {
        const token = localStorage.getItem("token");
        const url = `${hardBaseUrl}/me`;
        const res = await axios.get(url, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        setGetColor(res.data.data.publisher.theme_color);
      } catch (e) {}
    };
    getMainDsp();
    getResponse();
  }, [values, penciptaInString, searchKey, page, pageSize, rowsPerPage]);

  const pagesCount = Math.ceil(totalData / rowsPerPage);

  const handleChangeRowsPerPage = event => {
    event.preventDefault();
    setRowsPerPage(event.target.value);

    setPage(1);
  };

  const pageSizePagination = () => {
    return (
      <div className={classes.pageSize}>
        <select
          style={{
            backgroundColor: "transparent",
            border: "none",
            outline: "none",
            marginTop: "4px",
          }}
          value={rowsPerPage}
          onChange={handleChangeRowsPerPage}
        >
          {[10, 20, 50].map(pageSize => {
            return (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            );
          })}
        </select>
      </div>
    );
  };

  const handleChangePage = (event, data) => {
    setPage(data);
  };

  return (
    <Page className={classes.root} title="Kontrak">
      <Container maxWidth={false}>
        <Grid
          alignItems="flex-end"
          container
          justifyContent="space-between"
          spacing={3}
        >
          <Grid item>
            <ThemeProvider theme={theme}>
              <Typography
                style={{
                  fontSize: "24px",
                  fontWeight: "700",
                  lineHeight: "32px",
                }}
              >
                Contract Publisher
              </Typography>
            </ThemeProvider>
          </Grid>
          <Grid item>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography className={classes.breadCrumbs}>Home</Typography>

              <Typography className={classes.breadCrumbsActive}>
                Contract Publisher
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>

        <Divider className={classes.divider} />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
            }}
          >
            <div>
              <Button
                href="/admin/kontrak/tambah"
                style={{
                  backgroundColor: getColor,
                  color: "white",
                  textTransform: "none",
                  marginRight: "10px",
                  fontFamily: ["Inter"].join(","),
                }}
                variant="contained"
                size="large"
                startIcon={<AddIcon />}
              >
                Buat Kontrak
              </Button>
            </div>
            <div>
              <FormControl fullWidth variant="outlined">
                <OutlinedInput
                  placeholder="Pencarian"
                  id="outlined-adornment-amount"
                  style={{
                    width: "268px",
                    height: "44px",
                    border: "1px solid #D1D5DC",
                    borderRadius: "6px",
                  }}
                  value={searchKey}
                  onChange={event => handleChangeSearch(event)}
                  inputProps={{
                    classes: {
                      input: classes.placeholder,
                    },
                  }}
                  startAdornment={
                    <InputAdornment position="start">
                      <SearchIcon style={{ color: "#9AA2B1" }} />
                    </InputAdornment>
                  }
                  fullWidth={false}
                />
              </FormControl>
            </div>
          </div>
        </div>
        <Divider className={classes.divider} />
        {response && (
          <Results
            className={classes.results}
            items={response}
            pageSizePagination={pageSizePagination()}
            totalData={pagesCount}
            page={page}
            handleChangePage={handleChangePage}
          />
        )}
      </Container>
    </Page>
  );
}

export default Kontrak;
