import { Breadcrumbs, Grid, Link, Typography } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import PropTypes from "prop-types";

const theme = createTheme({
  typography: {
    fontFamily: ["Helvetica Neue"].join(","),
  },
});
const useStyles = makeStyles(() => ({
  root: {
    paddingTop: "24px",
  },
}));

function HeaderPageWithLink({ className, title, breadcrumbs, ...rest }) {
  const classes = useStyles();

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Grid
        alignItems="flex-end"
        container
        justifyContent="space-between"
        spacing={3}
      >
        <Grid item>
          <ThemeProvider theme={theme}>
            <Typography
              style={{
                fontSize: "24px",
                fontWeight: "700",
                lineHeight: "32px",
              }}
            >
              {title}
            </Typography>
          </ThemeProvider>
        </Grid>
        <Grid item>
          <Breadcrumbs aria-label="breadcrumb">
            {breadcrumbs.map(item => {
              return (
                <Link href={item.url} key={item.name}>
                  <Typography key={`${item.name}`}>{item.name}</Typography>
                </Link>
              );
            })}
          </Breadcrumbs>
        </Grid>
      </Grid>
    </div>
  );
}

HeaderPageWithLink.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
};

export default HeaderPageWithLink;
