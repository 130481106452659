import { Box, Grid } from "@mui/material";
import axios from "axios";
import {
  DateTimeDisplay,
  ImportDocumentModal,
  ModalError,
  ModalPreviewDocument,
  ModalSuccess,
  PrimaryButton,
  SearchTextInput,
  SelectInput,
  TableAction,
} from "components";
import { InnoTableV2 } from "inno-ui";
import moment from "moment-timezone";
import { useState } from "react";
import { useParams } from "react-router";
import { getErrors } from "utils";
import { hardBaseUrl } from "../../../../services/urlConstant";

const SupportingDocuments = ({
  getDataTable,
  dataTable,
  queryParams,
  handleChangeQueryParams,
}) => {
  const { id } = useParams();
  const token = localStorage.getItem("token");
  const [loadingButton, setLoadingButton] = useState(false);
  const [modalDocument, setModalDocument] = useState(false);
  const [modalPreview, setModalPreview] = useState(false);
  const [selectedFile, setSelectedFile] = useState({});

  const handleUploadDocument = async documentFiles => {
    const formData = new FormData();
    formData.append("documents", documentFiles[0]);
    formData.append("contract_id", id);
    try {
      setLoadingButton(true);
      await axios.post(
        `${hardBaseUrl}/contract-document/original-publisher`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      ModalSuccess("Document has been uploaded").then(() => getDataTable());
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingButton(false);
      setModalDocument(false);
    }
  };

  return (
    <Box>
      <Grid container justifyContent="space-between" mb="16px">
        <Grid item alignItems="center">
          <SearchTextInput
            value={queryParams?.search}
            onChange={e => handleChangeQueryParams(e?.target?.value, "search")}
            placeholder="Search"
          />
        </Grid>
        <Grid item>
          <Grid container columnSpacing={1} alignItems="center">
            <Grid item>
              <SelectInput
                options={optionSort}
                optionKey="key"
                optionLabel="value"
                value={queryParams?.sort}
                onChange={e =>
                  handleChangeQueryParams(e?.target?.value, "sort")
                }
                width={200}
              />
            </Grid>
            <Grid item>
              <PrimaryButton
                label="Import Document"
                onClick={() => setModalDocument(true)}
                size="large"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <InnoTableV2
        isLoading={false}
        columns={columnTable}
        items={dataTable}
        isHaveAction
        renderAction={item => (
          <TableAction
            handleView={() => {
              setModalPreview(true);
              setSelectedFile(item);
            }}
          />
        )}
      />
      <ModalPreviewDocument
        open={modalPreview}
        onClose={() => setModalPreview(false)}
        preload={selectedFile}
      />
      <ImportDocumentModal
        open={modalDocument}
        onClose={() => setModalDocument(false)}
        onSubmit={handleUploadDocument}
        fileType=".pdf"
        loadingButton={loadingButton}
        multiple
      />
    </Box>
  );
};

const optionSort = [
  {
    key: "-created_at",
    value: "Latest",
  },
  {
    key: "created_at",
    value: "Oldest",
  },
];
const columnTable = [
  {
    name: "filename",
    title: "Document Title",
    renderText: item => item || "-",
  },
  {
    name: "all",
    title: "Uploaded At",
    renderText: item => (
      <DateTimeDisplay
        date={moment(`${item?.created_date} ${item?.created_time}`)}
      />
    ),
  },
];
export default SupportingDocuments;
