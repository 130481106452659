import { Box, Grid } from "@material-ui/core";
import axios from "axios";
import { InnoTable } from "inno-ui";
import { useEffect, useState } from "react";
import { hardBaseUrl } from "../../../services/urlConstant";
import { SkeletonComponent } from "components";

const columnUsageMonitoring = [
  {
    name: "header_id",
    title: "ID Process",
  },
  {
    name: "date",
    title: "Date",
  },
  {
    name: "dsp_name",
    title: "DSP",
  },
  {
    name: "period",
    title: "Periode",
  },
  {
    name: "status",
    title: "Status",
  },
];

const url = `${hardBaseUrl}/dsrf/monitoring/datatable`;
const token = localStorage.getItem("token");
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${token}`,
};

function ComponentUsageProcessMonitoring() {
  const [loadingPageSkeleton, setLoadingPageSkeleton] = useState(false);

  const [stateUsageMonitor, setStateUsageMonitor] = useState({
    data: [],
    total: 0,
    page: 1,
    per_page: 10,
  });
  const { data, page, per_page, total } = stateUsageMonitor;
  const setState = (key, value) =>
    setStateUsageMonitor(state => ({
      ...state,
      [key]: value,
    }));
  const handleChangePage = (_, data) => setState("page", data);
  const handleChangeRowsPerPage = event => {
    event.persist();
    setStateUsageMonitor(state => ({
      ...state,
      per_page: Number(event.target.value),
      page: 1,
    }));
  };

  const getDsrf = async () => {
    setLoadingPageSkeleton(true);
    try {
      const response = await axios.get(url, {
        params: {
          page,
          per_page,
        },
        headers,
      });
      const { data, meta } = response?.data;
      setStateUsageMonitor(state => ({
        ...state,
        data: data || [],
        total: meta?.total || 0,
      }));
      setLoadingPageSkeleton(false);
    } catch (e) {
      setLoadingPageSkeleton(false);
      new Error(e);
    }
  };

  useEffect(() => {
    getDsrf();
  }, []);

  const pagesCount = Math.ceil(total / per_page);
  return (
    <Grid container>
      <Box width="100%">
        {loadingPageSkeleton ? (
          <SkeletonComponent variant="wave" />
        ) : (
          <InnoTable
            columns={columnUsageMonitoring}
            items={data || []}
            isLoading={false}
            page={page}
            rowsPerPage={per_page}
            totalPage={pagesCount}
            idColumnName="header_id"
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        )}
      </Box>
    </Grid>
  );
}

export default ComponentUsageProcessMonitoring;
