import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  styled,
  Typography,
} from "@mui/material";

const MenuProps = height => ({
  PaperProps: {
    style: {
      maxHeight: height || 250,
    },
  },
});

const SelectInput = ({
  label,
  placeholder,
  value,
  onChange,
  options = [],
  optionKey,
  optionLabel,
  width,
  height,
  renderValue,
  required,
  helperText,
  ...rest
}) => {
  const getNestedValue = (obj, path) => {
    return path?.split(".")?.reduce((acc, part) => acc && acc[part], obj);
  };
  return (
    <CustomFormControl customWidth={width}>
      <InputLabel id="select-label" size="small">
        <Box display="flex" gap="8px">
          {label}
          {required && (
            <Typography component="span" color="red">
              *
            </Typography>
          )}
        </Box>
      </InputLabel>
      <Select
        labelId="select-label"
        label={label}
        value={value}
        onChange={onChange}
        size="small"
        MenuProps={MenuProps(height)}
        {...rest}
      >
        {placeholder && (
          <MenuItem value="">
            <em>{placeholder}</em>
          </MenuItem>
        )}
        {options?.map((option, index) => (
          <MenuItem
            key={index}
            value={optionKey ? option?.[optionKey] : option}
          >
            {renderValue
              ? renderValue(option?.[optionKey])
              : optionKey
              ? getNestedValue(option, optionLabel)
              : option}
          </MenuItem>
        ))}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </CustomFormControl>
  );
};

const CustomFormControl = styled(FormControl)(({ customWidth }) => ({
  width: customWidth || 100,
}));

export default SelectInput;
