import { Grid, Modal } from "@material-ui/core";

export default function CustomModal({
  open,
  handleClose,
  children,
  width,
  noPadding,
}) {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Grid
        style={{
          backgroundColor: "white",
          width: width,
          padding: !noPadding && "20px",
          borderRadius: "8px",
        }}
      >
        {children}
      </Grid>
    </Modal>
  );
}
