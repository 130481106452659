import {
  Button,
  Container,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import HeaderPage from "../../../layouts/Header/HeaderPage";
import { Page } from "components";
import { hardBaseUrl } from "../../../services/urlConstant";
import { getCookie } from "../../../utils/constants";
import Results from "./Results";

const theme = createTheme({
  typography: {
    fontFamily: ["Helvetica Neue"].join(","),
  },
});

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  results: {
    marginTop: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  formControl: {},
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  pageSize: {
    height: "30px",
    borderRadius: "6px",
    border: "1px solid #D1D5DC",
    paddingLeft: "5px",
    paddingRight: "5px",
    marginTop: "5px",
  },
  paginationText: {
    fontSize: "14px",
    fontWeight: "normal",
    fontStyle: "normal",
  },
  inputFields: {
    "& .MuiOutlinedInput-input": {
      padding: "0px",
      height: "44px",
      width: "268px",
    },
  },
  selectPadding: {
    "& .MuiSelect-outlined": {
      paddingTop: "14px",
      paddingBottom: "14px",
      paddingLeft: "20px",
      paddingRight: "30px",
      width: "180px",
    },
  },
}));

function BeritaIklanAdmin() {
  const classes = useStyles();
  const breadcrumbs = ["Home", "News & Ads"];
  const [response, setResponse] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalData, setTotalData] = useState(1);
  const [page, setPage] = useState(1);
  const [searchKey, setSearchKey] = useState("");
  const [type, setType] = useState("");
  const [uploadTime, setUploadTime] = useState("-created_at");
  const userLogin = getCookie("auth", `${process.env.REACT_APP_NAME}_user`);
  const pagesCount = Math.ceil(totalData / rowsPerPage);

  const handleChangeSelectUploadTime = event => {
    setUploadTime(event.target.value);
  };

  const getResponse = () => {
    let theToken = localStorage.getItem("token");
    const url = `${hardBaseUrl}/article?page=${page}&size=${rowsPerPage}&search=${searchKey}&type=${type}&sort=${uploadTime}`;
    axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${theToken}`,
        },
      })
      .then(res => {
        setResponse(res.data.data);

        setTotalData(res.data.meta.total);
        setPage(res.data.meta.page);
      })
      .catch(() => {});
  };
  const handleChangeRowsPerPage = event => {
    event.preventDefault();
    setRowsPerPage(event.target.value);

    setPage(1);
  };

  const pageSizePagination = () => {
    return (
      <div className={classes.pageSize}>
        <select
          style={{
            backgroundColor: "transparent",
            border: "none",
            outline: "none",
            marginTop: "4px",
          }}
          value={rowsPerPage}
          onChange={handleChangeRowsPerPage}
        >
          {[10, 20, 50].map(pageSize => {
            return (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            );
          })}
        </select>
      </div>
    );
  };

  const handleChangePage = (event, data) => {
    setPage(data);
  };

  const handleChangeSelect = event => {
    setType(event.target.value);
    setPage(1);
  };

  const handleDelete = id => {
    let theToken = localStorage.getItem("token");
    const url = `${hardBaseUrl}/article`;

    axios
      .delete(url + "/" + id, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${theToken}`,
        },
      })
      .then(res => {
        if (res.data.message === "success") {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Data deleted successfully",
          }).then(result => {
            if (result.isConfirmed === true) {
              getResponse();
            }
          });
        }
      })
      .catch(() => {});
  };

  const handleChangeSearch = event => {
    setPage(1);
    setSearchKey(event.target.value);
  };

  useEffect(() => {
    getResponse();
    localStorage.removeItem("title");
    localStorage.removeItem("content");
    localStorage.removeItem("image_logo");
    localStorage.removeItem("raw_img");
    localStorage.removeItem("article_id");
    localStorage.removeItem("type");
    localStorage.removeItem("raw_content");
    localStorage.removeItem("recentDate");
  }, [rowsPerPage, page, searchKey, type, uploadTime]);

  return (
    <Page className={classes.root} title="Berita Iklan Publisher">
      <Container maxWidth={false}>
        <HeaderPage title="News & Ads" breadcrumbs={breadcrumbs} />
        <Divider className={classes.divider} />
        <Grid container>
          <Grid item xs={12} md={12} sm={12} lg={12} xl={12}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div>
                <Button
                  href="/admin/berita/tambah"
                  style={{
                    backgroundColor: "black",
                    color: "white",
                    paddingTop: "15px",
                    paddingBottom: "15px",
                    textTransform: "none",
                    height: "44px",
                    width: "auto",
                  }}
                  variant="contained"
                  startIcon={<AddIcon />}
                >
                  <ThemeProvider theme={theme}>
                    <Typography>Add News</Typography>
                  </ThemeProvider>
                </Button>

                <FormControl
                  variant="outlined"
                  style={{
                    marginLeft: "8px",
                  }}
                >
                  <OutlinedInput
                    id="outlined-adornment-amount"
                    className={classes.inputFields}
                    value={searchKey}
                    onChange={event => handleChangeSearch(event)}
                    placeholder="Search"
                    startAdornment={
                      <InputAdornment position="start">
                        <img
                          alt="Logo"
                          src={
                            require("assets/image-public/images/search.svg")
                              .default
                          }
                        />
                      </InputAdornment>
                    }
                    fullWidth={false}
                  />
                </FormControl>
              </div>

              <div>
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                    style={{
                      marginRight: "8px",
                    }}
                  >
                    <Select
                      displayEmpty={true}
                      className={classes.selectPadding}
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={type}
                      defaultValue={""}
                      onChange={handleChangeSelect}
                    >
                      <MenuItem value={""}>All Type</MenuItem>
                      <MenuItem value={"berita"}>News</MenuItem>
                      <MenuItem value={"edukasi"}>Education</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                    style={{
                      marginRight: "8px",
                    }}
                  >
                    <Select
                      displayEmpty={true}
                      labelId="demo-simple-select-outlined-label-a"
                      id="demo-simple-select-outlined-a"
                      className={classes.selectPadding}
                      value={uploadTime}
                      onChange={handleChangeSelectUploadTime}
                      defaultValue={""}
                    >
                      <MenuItem value="-created_at">Latest</MenuItem>
                      <MenuItem value={"created_at"}>Oldest</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>

        <Results
          userLogin={userLogin}
          className={classes.results}
          items={response}
          handleDelete={handleDelete}
          pageSizePagination={pageSizePagination()}
          totalData={pagesCount}
          page={page}
          handleChangePage={handleChangePage}
        />
      </Container>
    </Page>
  );
}

export default BeritaIklanAdmin;
