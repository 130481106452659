import {
  Button,
  Container,
  FormControl,
  IconButton,
  InputAdornment,
  TextField,
  Box,
  Typography,
} from "@material-ui/core";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import { InnoTable } from "inno-ui";
import { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router";
import Swal from "sweetalert2";
import PencilVector from "../../../../assets/img/pencilVector.svg";
import RefreshVector from "../../../../assets/img/refresh-cw.svg";
import { ButtonWithModalPerItem } from "../../../../components/atoms/Button";
import { ButtonGroupTop } from "../../../../components/atoms/Button";
import HeaderPage from "../../../../layouts/Header/HeaderPage";
import { Page, SkeletonComponent } from "components";
import { hardBaseUrl } from "../../../../services/urlConstant";
import { emptyText } from "../../../../utils";

const fontInter = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  results: {
    marginTop: theme.spacing(3),
  },
  content: {
    marginTop: "20px",
  },
  contentAdd: {
    marginTop: "20px",
    marginLeft: "20px",
    marginRight: "20px",
    fontFamily: ["Helvetica Neue"].join(","),
  },
  buttonAdd: {
    height: "44px",
    width: "auto",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "24px",
    backgroundColor: "black",
    color: "white",
    textTransform: "none",
  },
  textAdd: {
    color: "white",
    fontSize: "14px",
  },
  pageSize: {
    height: "30px",
    borderRadius: "6px",
    border: "1px solid #D1D5DC",
    paddingLeft: "5px",
    paddingRight: "5px",
    marginTop: "5px",
  },
  paginationText: {
    fontSize: "14px",
    fontWeight: "normal",
    fontStyle: "normal",
  },
  inputFields: {
    height: "44px",
    paddingTop: "0px",
    paddingBottom: "0px",
    "& .MuiOutlinedInput-root": {
      width: "312px",
      height: "44px",
      paddingTop: "0px",
      paddingBottom: "0px",
    },
  },
  ib: {
    padding: "4px",
    color: "white",
    backgroundColor: "black",
    borderRadius: "6px",
    "&:focus": {
      color: "white",
      backgroundColor: "black",
    },
    "&:active": {
      boxShadow: "none",
      color: "white",
      backgroundColor: "black",
    },
    "&:hover": {
      color: "white",
      backgroundColor: "black",
      boxShadow: "none",
    },
    "&:hover, &.MuiIconButton": {
      background: "#374151 !important",
    },
  },
  ibRefresh: {
    padding: "4px",
    color: "white",
    marginLeft: "8px",
    backgroundColor: "black",
    borderRadius: "6px",
    "&:focus": {
      color: "white",
      backgroundColor: "black",
    },
    "&:active": {
      boxShadow: "none",
      color: "white",
      backgroundColor: "black",
    },
    "&:hover": {
      color: "white",
      backgroundColor: "black",
      boxShadow: "none",
    },
    "&:hover, &.MuiIconButton": {
      background: "#374151 !important",
    },
  },
  flexCenter: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
}));

function MasterAssociation() {
  const classes = useStyles();
  const history = useHistory();
  const [response, setResponse] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalData, setTotalData] = useState(1);
  const [page, setPage] = useState(1);
  const [searchKey, setSearchKey] = useState("");
  const [loadingTable, setLoadingTable] = useState(false);

  const pagesCount = Math.ceil(totalData / rowsPerPage);
  const getResponse = () => {
    setLoadingTable(true);
    let theToken = localStorage.getItem("token");
    const url = `${hardBaseUrl}/association/index?search=${searchKey}&page=${page}&per_page=${rowsPerPage}`;
    const headers = {
      headers: {
        Authorization: `Bearer ${theToken}`,
      },
    };

    axios
      .get(url, headers)
      .then(res => {
        setResponse(res.data.data);
        setTotalData(res?.data?.meta?.total);
        setLoadingTable(false);
      })
      .catch(() => {
        setLoadingTable(false);
      });
  };
  const handleChangeRowsPerPage = event => {
    event.preventDefault();
    setRowsPerPage(event.target.value);

    setPage(1);
  };

  const handleChangePage = (event, data) => {
    setPage(data);
  };

  const handleChangeSearch = event => {
    setPage(1);
    setSearchKey(event.target.value);
  };

  const handleDelete = id => {
    const url = `${hardBaseUrl}/association/delete/${id}`;
    let theToken = localStorage.getItem("token");

    const headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${theToken}`,
      },
    };

    axios
      .delete(url, headers)
      .then(res => {
        if (res.data.message === "success") {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Data has been deleted",
          }).then(result => {
            if (result.isConfirmed === true) {
              getResponse();
            }
          });
        }
      })
      .catch(() => {});
  };

  const handleRecovery = id => {
    const url = `${hardBaseUrl}/association/recovery/${id}`;
    let theToken = localStorage.getItem("token");

    const headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${theToken}`,
      },
    };
    const formData = new FormData();

    axios
      .put(url, formData, headers)
      .then(res => {
        if (res.data.message === "success") {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Data has been recovered",
          }).then(result => {
            if (result.isConfirmed === true) {
              getResponse();
            }
          });
        }
      })
      .catch(() => {});
  };

  useEffect(() => {
    getResponse();
  }, [rowsPerPage, page, searchKey]);

  const handleEditSelect = item => {
    let payload = {
      customer: item,
    };
    history.push({
      pathname: `/admin/parameter/association/${item.association_id}`,
      state: payload,
    });
  };

  const customAction = item => {
    return (
      <div className={classes.flexCenter}>
        <IconButton
          classes={{
            root: classes.ib,
          }}
          onClick={() => {
            handleEditSelect(item);
          }}
        >
          <img alt="btn-edit" src={PencilVector} />
        </IconButton>
        {item.is_active_flag ? (
          <ButtonWithModalPerItem
            item={item}
            className={classes.ib}
            title={"Remove Publisher"}
            dialogTitle={"Remove Publisher"}
            subTitle={"Are you sure you want to delete this data"}
            handleDelete={() => {
              handleDelete(item?.association_id);
            }}
          />
        ) : (
          <IconButton
            classes={{
              root: classes.ibRefresh,
            }}
            onClick={() => {
              handleRecovery(item.association_id);
            }}
          >
            <img alt="btn-refresh" src={RefreshVector} />
          </IconButton>
        )}
      </div>
    );
  };

  const columnTable = [
    {
      name: "all",
      title: "Action",
      renderText: item => customAction(item),
    },
    {
      name: "all",
      title: "Association Name",
      renderText: item => emptyText(item?.name),
    },
    {
      name: "all",
      title: "Person In Charge",
      renderText: item => emptyText(item?.pic),
    },
    {
      name: "all",
      title: "Email",
      renderText: item => emptyText(item?.email),
    },
    {
      name: "all",
      title: "Phone",
      renderText: item => emptyText(item?.phone_number),
    },
  ];

  return (
    <Fragment>
      <Page className={classes.root} title="Association">
        {loadingTable ? (
          <SkeletonComponent variant="wave" />
        ) : (
          <Container maxWidth={false}>
            <HeaderPage
              title="Parameter"
              breadcrumbs={["Parameter", "Association"]}
            />
            <ButtonGroupTop />
            <Box className={classes.content}>
              <hr />
              <Box
                display="flex"
                marginTop="20px"
                justifyContent="space-between"
                alignItems="center"
              >
                <FormControl className={classes.margin} variant="outlined">
                  <TextField
                    id="input-with-icon-textfield"
                    variant="outlined"
                    className={classes.inputFields}
                    onChange={handleChangeSearch}
                    placeholder="Search"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img
                            alt="Logo"
                            src={
                              require("assets/image-public/images/search.svg")
                                .default
                            }
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
                <Button
                  href="/admin/parameter/association/add"
                  variant="contained"
                  className={classes.buttonAdd}
                  startIcon={<AddIcon />}
                >
                  <ThemeProvider theme={fontInter}>
                    <Typography className={classes.textAdd}>
                      Add Association
                    </Typography>
                  </ThemeProvider>
                </Button>
              </Box>
              <Box marginTop="30px">
                <InnoTable
                  columns={columnTable}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  idColumnName={"performer_id"}
                  isLoading={loadingTable}
                  isUsingCheckbox={false}
                  items={response}
                  loadingColor={""}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  totalPage={pagesCount}
                />
              </Box>
            </Box>
          </Container>
        )}
      </Page>
    </Fragment>
  );
}

export default MasterAssociation;
