import { Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Chart as ChartJS, registerables } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Bar as BarPerc } from "react-chartjs-2";

ChartJS.register(ChartDataLabels);
ChartJS.register(...registerables);

const options = {
  parsing: {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    delimiter: ",",
    complete: function() {},
  },
  plugins: {
    datalabels: {
      formatter: function(value, context) {
        let forReturn = "";
        let returnNum =
          context.chart.data.datasets[context.datasetIndex].dataListener[
            context.dataIndex
          ];
        if (typeof returnNum === "number") {
          if (returnNum === 0) {
            forReturn = "";
          }
        } else {
          forReturn = `${returnNum} %`;
        }

        return `${forReturn}`;
      },
      color: "#FE777B",
      labels: {
        title: {
          font: {},
        },
        value: {
          color: "#0a0a0a",
        },
      },
    },
    layout: {},
    legend: {
      display: false,
    },
    title: {
      display: false,
      text: "Chart.js Bar Chart - Stacked",
    },
    tooltip: {},
  },
  interaction: {},
  responsive: true,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
};
const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  results: {
    marginTop: theme.spacing(3),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  title: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "28px",
  },
  subTitle: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "20px",
  },
  label: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontWeight: "500px",
    fontSize: "14px",
    lineHeight: "20px",
    width: "140px",
    height: "20px",
  },
  fixedHeight: {
    height: 240,
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
    boxFlex: "1",
  },
  breadcrumbs: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#111827",
  },
  breadCrumbsActive: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#687083",
  },
  inputFilter: {
    width: "211px",
    height: "44px",
    border: "1px solid #D1D5DC",
    borderRadius: "6px",
    marginRight: "10px",
  },
  headText: {
    fontSize: "12px",
    color: "#687083",
    fontWeight: "bold",
  },
  bodyTextBlack: {
    fontSize: "14px",
    fontWeight: "regular",
    color: "#111827",
    fontStyle: "normal",
  },
  bodyTextGrey: {
    fontSize: "14px",
    fontWeight: "regular",
    color: "#687083",
    fontStyle: "normal",
  },
  tableRow: {
    height: 20,
  },
  conTextBlack: {
    fontSize: "14px",
    fontWeight: "bold",
  },
  dropdown: {
    color: "black",
  },
  subtitle: {
    fontSize: "18px",
    fontWeight: "bold",
    lineHeight: "28px",
  },
  btnRincian: {
    backgroundColor: "black",

    color: "white",
    padding: "10px 28px",
    borderRadius: "6px",
    width: "140px",
    height: "36px",

    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
  ib: {
    paddingRight: "7px",
    paddingLeft: "6px",
    paddingTop: "1px",
    paddingBottom: "2px",

    backgroundColor: "#FCF3F2",
    border: "1px solid #F1A69E",

    "&:focus": {
      color: "blue",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "pink",
      borderColor: "#005cbf",
    },
    "&:hover": {
      boxShadow: "none",
    },
  },
  myCheckBox: {
    "&.MuiCheckbox-root": {
      color: "black",

      "&.Mui-checked": {
        color: "black",
      },
    },
  },
  scrollingCard: {
    "&::-webkit-scrollbar": {
      width: 1,
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "lightgrey",
      outline: "1px solid slategrey",
    },
  },

  dialogPaper: {
    width: "420px",
    maxWidth: "420px",
  },
}));
function ChartStackedPerc(props) {
  const classes = useStyles();

  return (
    <>
      <Paper className={classes.fixedHeightPaper}>
        <BarPerc options={options} data={props.data} plugins={[]} />
      </Paper>
    </>
  );
}

export default ChartStackedPerc;
