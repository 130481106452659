import { Col, Pagination, Row } from "antd";
import Axios from "axios";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import Chip from "../../../../mpisLandingComponent/Chip/Chip";
import Layout from "../../../../mpisLandingComponent/Layout";
import Publisher from "../../../../mpisLandingComponent/Publisher";
import { hardBaseUrl } from "../../../../services/urlConstant";
import "./detailBerita.css";

export default function DetailBerita() {
  const history = useHistory();
  const [berita, setBerita] = useState({
    data: [],
    message: "",
    meta: { total: 0 },
  });
  const [terbaru, setTerbaru] = useState([]);
  const [, setIklan] = useState([]);
  const [layanan, setLayanan] = useState([]);
  const [pageBerita, setPageBerita] = useState(1);

  let data = useLocation().state;

  const getDataBerita = async () => {
    try {
      const response = await Axios.get(
        hardBaseUrl +
          `/article/public?page=${pageBerita}&size=4&not_id=${data.article_id}&search&type=&subdomain=`
      );
      setBerita(response.data);
    } catch (error) {
      new Error(error);
    }
  };

  const getSideBerita = async (res, i) => {
    try {
      const response = await Axios.get(
        hardBaseUrl +
          `/article/public?page=1&size=3&not_id=${data.article_id}&type=${res}`
      );
      i === 0
        ? setTerbaru(response.data.data)
        : i === 1
        ? setIklan(response.data.data)
        : setLayanan(response.data.data);
    } catch (error) {
      new Error(error);
    }
  };

  useEffect(() => {
    getDataBerita();
  }, [pageBerita]);

  useEffect(() => {
    ["berita", "iklan", "edukasi"].map((res, i) => {
      getSideBerita(res, i);
    });
  }, []);

  const handleDetailBerita = res => {
    history.push({
      pathname: `/artikel/detail/${res.article_id}`,
      state: res,
    });
  };

  const reduceTitle = res => {
    return res?.length > 50 ? res.substring(0, 50) + "..." : res;
  };

  return (
    <Layout>
      <div
        className="detail-berita"
        style={{
          paddingTop: "80px",
          backgroundColor: "white",
        }}
      >
        <div className="container">
          <Row>
            <Col span={15} style={{ paddingRight: "15px" }}>
              <h1 className="title-top">{data.title}</h1>
              <Publisher
                publisher={data.publisher_name}
                day={data.range_date}
              />
              <div style={{ marginTop: "30px" }}>
                <img src={data.banner_image} style={{ width: "100%" }} alt="" />
              </div>
              <p
                id="content"
                style={{
                  fontSize: "20px",
                  marginTop: "50px",
                  textAlign: "justify",
                }}
                dangerouslySetInnerHTML={{ __html: data.content }}
              />
            </Col>
            <Col
              span={9}
              style={{ paddingLeft: "20px" }}
              className="detail-berita-kanan"
            >
              <div style={{ marginBottom: "40px" }}>
                <h2>EDUCATION</h2>
                {layanan?.length > 0 &&
                  layanan.map((res, i) => {
                    return (
                      <div
                        key={i}
                        className="content"
                        onClick={() => handleDetailBerita(res)}
                      >
                        <div
                          style={{
                            flex: 1.8,
                            height: "100px",
                          }}
                        >
                          <img src={res.banner_image} alt=""></img>
                        </div>
                        <div
                          style={{
                            marginLeft: "15px",
                            flex: 5,
                          }}
                        >
                          <Chip
                            type={res.type === "edukasi" ? "Education" : ""}
                          />
                          <h2>{reduceTitle(res.title)}</h2>
                          <Publisher
                            fontSize="14px"
                            publisher={res.publisher_name}
                            day={res.range_date}
                          />
                        </div>
                      </div>
                    );
                  })}
              </div>
              <div style={{ marginBottom: "40px" }}>
                <h2>TOP NEWS</h2>
                {terbaru?.length > 0 &&
                  terbaru.map((res, i) => {
                    return (
                      <div
                        key={i}
                        className="content"
                        onClick={() => handleDetailBerita(res)}
                      >
                        <div
                          style={{
                            flex: 1.8,
                            height: "100px",
                          }}
                        >
                          <img src={res.banner_image} alt=""></img>
                        </div>
                        <div
                          style={{
                            marginLeft: "15px",
                            flex: 5,
                          }}
                        >
                          <Chip type={res.type === "berita" ? "News" : ""} />
                          <h2>{reduceTitle(res.title)}</h2>
                          <Publisher
                            fontSize="14px"
                            publisher={res.publisher_name}
                            day={res.range_date}
                          />
                        </div>
                      </div>
                    );
                  })}
              </div>
            </Col>
          </Row>
          <h2 className="berita-lainya">More News</h2>
          <Row gutter={16}>
            <>
              {berita.data.map((res, i) => {
                return (
                  <Col
                    span={6}
                    key={i}
                    style={{ paddingTop: "20px", cursor: "pointer" }}
                    onClick={() => handleDetailBerita(res)}
                  >
                    <div className="wrapper">
                      <img
                        src={res.banner_image}
                        alt=""
                        style={{
                          width: "100%",
                          borderRadius: "5px",
                          height: "205px",
                          objectFit: "cover",
                        }}
                      />
                      <div style={{ marginTop: "15px" }}>
                        <Chip
                          type={res.type === "berita" ? "News" : "Education"}
                        />
                        <h2>
                          {res?.title?.length > 50
                            ? res.title.substring(0, 40) + "..."
                            : res.title}
                        </h2>
                        <Publisher
                          publisher={res.publisher_name}
                          day={res.range_date}
                          fontSize="14px"
                        />
                      </div>
                    </div>
                  </Col>
                );
              })}
            </>
          </Row>
          <div style={{ textAlign: "center", marginTop: "30px" }}>
            <Pagination
              simple
              current={pageBerita}
              total={berita.meta.total}
              onChange={val => setPageBerita(val)}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
}
