import { LinearProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import { Suspense } from "react";
import { renderRoutes } from "react-router-config";
import Topbar from "./Topbar";

const useStyles = makeStyles(theme => ({
  container: {
    minHeight: "100vh",
    display: "flex",
    "@media all and (-ms-high-contrast:none)": {
      height: 0,
    },
  },
  content: {
    flexGrow: 1,
    maxWidth: "100%",
    overflowX: "hidden",
    paddingTop: 64,
    [theme.breakpoints.down("xs")]: {
      paddingTop: 56,
    },
  },
}));

function Auth({ route }) {
  const classes = useStyles();

  return (
    <>
      <Topbar />
      <div className={classes.container}>
        <div className={classes.content}>
          <Suspense fallback={<LinearProgress />}>
            {renderRoutes(route.routes)}
          </Suspense>
        </div>
      </div>
    </>
  );
}

Auth.propTypes = {
  route: PropTypes.object,
};

export default Auth;
