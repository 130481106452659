import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import PropTypes from "prop-types";
import undraw_empty_xct9 from "../../../assets/image-public/images/undraw_empty_xct9.svg";

const useStyles = makeStyles(theme => ({
  root: {
    textAlign: "center",
    padding: theme.spacing(3),
  },
  image: {
    height: 240,
    backgroundImage: `url(${undraw_empty_xct9})`,
    backgroundPositionX: "right",
    backgroundPositionY: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
}));

function Placeholder({ className, ...rest }) {
  const classes = useStyles();

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div className={classes.image} />
      <Typography variant="h4">There&apos;s nothing here...</Typography>
    </div>
  );
}

Placeholder.propTypes = {
  className: PropTypes.string,
};

export default Placeholder;
