import React, { Suspense } from "react";

import { LinearProgress } from "@material-ui/core";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { renderRoutes } from "react-router-config";

const useStyles = makeStyles(theme => ({
  container: {
    "@media all and (-ms-high-contrast:none)": {
      height: 0,
    },
    backgroundColor: "white",
    display: "flex",
    minHeight: "100vh",
  },
  content: {
    paddingTop: 64,
    flexGrow: 1,
    maxWidth: "100%",
    overflowX: "hidden",
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 256,
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: 56,
    },
  },
  content2: {
    paddingTop: 64,
    flexGrow: 1,
    maxWidth: "100%",
    overflowX: "hidden",
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 0,
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: 56,
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

function ExampleLayout({ route }) {
  const classes = useStyles();

  return (
    <div className={classes.content}>
      <Suspense fallback={<LinearProgress />}>
        {renderRoutes(route.routes, {
          userLogin: { hello: "hello", data: "aku data" },
        })}
      </Suspense>
    </div>
  );
}

ExampleLayout.propTypes = {
  route: PropTypes.shape({
    routes: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
};

export default ExampleLayout;
