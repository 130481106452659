import {
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import React, { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import { Page, PrimaryButton } from "components";
import { hardBaseUrl } from "../../../../services/urlConstant";

const interFont = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),

    paddingBottom: theme.spacing(3),
  },

  results: {
    marginTop: theme.spacing(3),
  },

  divider: {
    margin: theme.spacing(2, 0),

    borderTop: "1px solid #e1e1e1",
  },

  formControl: {},

  mypadding: {
    "& .MuiSelect-outlined": {
      paddingTop: "10.5px",

      paddingBottom: "10.5px",
    },
  },

  selectEmpty: {
    marginTop: theme.spacing(2),
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,

    color: "#fff",
  },

  pagination: {
    "& .Mui-selected": {
      backgroundColor: "black",

      color: "white",
    },
  },

  pageSize: {
    height: "30px",

    borderRadius: "6px",

    border: "1px solid #D1D5DC",

    paddingLeft: "5px",

    paddingRight: "5px",

    marginTop: "5px",
  },

  paginationText: {
    fontSize: "14px",

    fontWeight: "normal",

    fontStyle: "normal",

    color: "#687083",
  },

  breadcrumbs: {
    fontSize: "14px",

    fontWeight: 400,

    fontStyle: "regular",

    color: "#111827",
  },

  breadCrumbsActive: {
    fontSize: "14px",

    fontWeight: 400,

    fontStyle: "regular",

    color: "#687083",
  },

  placeholder: {
    "&::placeholder": {
      color: "#9AA2B1",
    },
  },

  title: {
    fontFamily: "Helvetica Neue",

    fontStyle: "normal",

    fontWeight: "bold",

    fontSize: "18px",

    lineHeight: "28px",
  },

  label: {
    color: "#364052",

    fontSize: "14px",

    fontWeight: 500,
  },

  subTitle: {
    fontSize: "18px",

    fontWeight: 700,

    color: " #111827",
  },
  foont24: {
    fontSize: "24px",
    fontWeight: "700",
    lineHeight: "32px",
  },
  dNone: {
    display: "none",
  },
  textStyle: {
    marginTop: "5px",
    color: "#8f8f8f",
    fontSize: "14px",
  },
  card: {
    border: "1px solid #9AA2B1",
    borderRadius: "6px",
    boxShadow: "none",
  },
  mt5mb0: {
    marginTop: "5px",
    marginBottom: "0px",
  },
  mt10mb5: {
    marginTop: "10px",

    marginBottom: "3px",
  },
  mtmin10mb5: {
    marginTop: "-10px",

    marginBottom: "-5px",
  },
  mt10mb10: {
    marginTop: "10px",

    marginBottom: "10px",
  },
  mtmin10mb10: {
    marginTop: "-10px",

    marginBottom: "10px",
  },
  mt5mb5: {
    marginTop: "5px",

    marginBottom: "5px",
  },
  mt5: {
    marginTop: "5px",
  },
  mb5: {
    marginBottom: "5px",
  },
  mtmin5mbmin8: {
    marginRight: "-5px",
    marginTop: "-8px",
  },
  p0: {
    padding: 0,
  },
  loanGrid: {
    width: "48px",
    height: "38px",
    marginRight: "-2px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "6px 0px 0px 6px",
    backgroundColor: "#F9FAFB",
    border: " 1px solid #D1D5DC",
    color: "#9AA2B",
    borderRight: "none",
  },
  pr8: {
    paddingRight: "8px",
  },
  flex: {
    display: "flex",
  },
  numFormat: {
    paddingLeft: "0px",
    height: "44px",
    borderRadius: "0px 6px 6px 0px",
  },
  font16: {
    fontWeight: 700,

    fontSize: "16px",

    lineHeight: "24px",
  },
}));

const EditLoan = () => {
  const classes = useStyles();
  const history = useHistory();
  const { state } = useLocation();
  const [composerName, setComposerName] = useState("");
  const [listComposer, setListComposer] = useState([]);
  const [financeFor, setFinanceFor] = useState(null);
  const [openModalReject, setOpenModalReject] = useState(null);
  const [openModal, setOpenModal] = useState(null);
  const [notes, setNotes] = useState("");
  const [tenor, setTenor] = useState(0);
  const [date, setDate] = useState("2022-08-01");
  const [money, setMoney] = useState("");

  const handleDateChangeDate = date => {
    setDate(date);
  };

  const getMe = async () => {
    try {
      let theToken = localStorage.getItem("token");
      let resultMe = await axios.get(hardBaseUrl + "/me", {
        headers: { Authorization: `Bearer ${theToken}` },
      });
      setFinanceFor(resultMe.data.data.role.roles_for);
    } catch (err) {}
  };

  const getResponse = () => {
    const url = `${hardBaseUrl}/publisher/transaction-loan/${state.customer.publisher_transaction_id}`;
    const theToken = localStorage.getItem("token");
    axios

      .get(url, {
        headers: {
          Authorization: `Bearer ${theToken}`,
        },
      })

      .then(res => {
        setComposerName(res.data.data.composer_id);
        let newFormatDate = formatDate(new Date(res.data.data.date));
        setDate(newFormatDate);
        setNotes(res.data.data.notes);
        setMoney(res.data.data.transaction_value_number);
        setTenor(res.data.data.tenor_number);
      })

      .catch(() => {});
  };

  const handleChangeTenor = event => {
    setTenor(event.target.value);
  };

  const handleChangeNotes = event => {
    setNotes(event.target.value);
  };

  const formatDate = date => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;

    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };
  const checkMoney = data => {
    const type = typeof data;
    if (type === "number") {
      return data
        .toString()
        .replace(/\./g, "")
        .replace(/,/g, "");
    } else if (type === "string") {
      return data.replace(/\./g, "").replace(/,/g, "");
    }
  };
  const onSubmit = event => {
    event.preventDefault();
    const token = localStorage.getItem("token");
    const url = `${hardBaseUrl}/publisher/transaction-loan`;
    const composer_id = Number(composerName);
    const moneyedited = checkMoney(money);
    const revenue = Number(moneyedited);

    const payload = {
      date: formatDate(date),
      composer_id: composer_id,
      id: state.customer.publisher_transaction_id,
      value: revenue,
      tenor: Number(tenor),
      note: notes,
    };

    axios
      .put(url, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      .then(res => {
        if (res.status === 200) {
          Swal.fire({
            title: "Success",

            text: "Loan has been updated",

            icon: "success",

            confirmButtonText: "Ok",
          }).then(result => {
            if (result.value) {
              history.push("/admin/loan");
            }
          });
        }
      })

      .catch(() => {});
  };

  const getComposerList = () => {
    const token = localStorage.getItem("token");
    const url = `${hardBaseUrl}/publisher/transaction-composer`;
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      .then(res => {
        setListComposer(res.data.data);
      })

      .catch(() => {});
  };

  const handleChangeSelectComposer = event => {
    setComposerName(event.target.value);
  };

  useEffect(() => {
    getMe();
    getComposerList();
    getResponse();
  }, []);

  const handleOpenModalReject = () => {
    setOpenModalReject(true);
  };
  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseReject = () => {
    setOpenModalReject(false);
  };
  const handleClose = () => {
    setOpenModal(false);
  };
  const updateStatus = statusToGo => {
    if (statusToGo === "approved") {
      const url = `${hardBaseUrl}/transaction/proof/${state.customer.publisher_transaction_id}`;
      const theToken = localStorage.getItem("token");

      const formData = new FormData();
      formData.append("status", "approved");

      axios
        .put(url, formData, {
          headers: {
            Authorization: `Bearer ${theToken}`,
          },
        })
        .then(res => {
          setOpenModal(false);
          if (
            res.data.message === "success" &&
            res.data.meta.http_status === 200
          ) {
            Swal.fire({
              title: "Success",

              text: "Updated",

              icon: "success",

              confirmButtonText: "Ok",
            }).then(result => {
              if (result.value) {
                history.push("/admin/loan");
              }
            });
          }
        })
        .catch(() => {});
    } else if (statusToGo === "rejected") {
      const url = `${hardBaseUrl}/transaction/proof/${state.customer.publisher_transaction_id}`;
      const theToken = localStorage.getItem("token");

      const formData = new FormData();
      formData.append("status", "rejected");

      axios
        .put(url, formData, {
          headers: {
            Authorization: `Bearer ${theToken}`,
          },
        })
        .then(res => {
          if (
            res.data.message === "success" &&
            res.data.meta.http_status === 200
          ) {
            setOpenModalReject(false);
            Swal.fire({
              title: "Success",
              text: "Rejected",
              icon: "success",
              confirmButtonText: "Ok",
            }).then(result => {
              if (result.value) {
                history.push("/admin/loan");
              }
            });
          }
        })
        .catch(() => {});
    }
  };
  return (
    <Page className={classes.root} title="Loan">
      <Container maxWidth={false}>
        <Grid
          alignItems="flex-end"
          container
          justifyContent="space-between"
          spacing={3}
        >
          <Grid item>
            <ThemeProvider theme={theme}>
              <Typography className={classes.foont24}>
                Loan Transaction
              </Typography>
            </ThemeProvider>
          </Grid>

          <Grid item className={classes.dNone}>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography className={classes.breadCrumbs}>Home</Typography>

              <Typography className={classes.breadCrumbs}>
                Transaction
              </Typography>

              <Typography className={classes.breadCrumbsActive}>
                Create Transaction
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>

        <Divider className={classes.divider} />

        <Grid container>
          <div className={classes.dNone}>
            <ThemeProvider theme={theme}>
              <Typography
                component="h1"
                variant="h3"
                className={classes.subTitle}
              >
                Create Transaction
              </Typography>
            </ThemeProvider>

            <ThemeProvider theme={theme}>
              <p className={classes.textStyle}>
                Create your loan transaction here.
              </p>
            </ThemeProvider>
          </div>
        </Grid>

        <Grid container>
          <Grid item xs={12} md={8} lg={8} xl={8}>
            <Card className={classes.card}>
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6} lg={6} xl={6}>
                    <div className={classes.mt5mb0}>
                      <div className={classes.mt10mb5}>
                        <ThemeProvider theme={theme}>
                          <Typography className={classes.label}>
                            Date
                          </Typography>
                        </ThemeProvider>
                      </div>

                      <div className={classes.mtmin10mb5}>
                        <KeyboardDatePicker
                          disabled={true}
                          id="outlined-basic"
                          value={date}
                          variant="outlined"
                          onChange={handleDateChangeDate}
                          name="date"
                          format="DD-MM-YYYY"
                          inputVariant="outlined"
                          fullWidth={true}
                          margin="dense"
                        />
                      </div>

                      <div className={classes.mt10mb10}>
                        <ThemeProvider theme={theme}>
                          <Typography className={classes.label}>
                            Tenor
                          </Typography>
                        </ThemeProvider>
                      </div>

                      <div className={classes.mtmin10mb10}>
                        <OutlinedInput
                          id="outlined-basic"
                          name="tenor"
                          variant="outlined"
                          fullWidth={true}
                          margin="dense"
                          type="number"
                          value={tenor}
                          onChange={event => handleChangeTenor(event)}
                          placeholder="Tenor"
                          endAdornment={<div>Month</div>}
                        />
                      </div>

                      <div className={classes.mb5}></div>
                    </div>
                  </Grid>

                  <Grid item xs={12} md={6} lg={6} xl={6}>
                    <div className={classes.mt5mb5}>
                      {}

                      <div className={classes.mt10mb10}>
                        <ThemeProvider theme={theme}>
                          <Typography className={classes.label}>
                            Composer/Author
                          </Typography>
                        </ThemeProvider>
                      </div>

                      <div className={classes.mtmin10mb10}>
                        <FormControl
                          disabled={true}
                          variant="outlined"
                          fullWidth={true}
                        >
                          <InputLabel
                            id="demo-simple-select-outlined-label"
                            shrink={false}
                            className={classes.mtmin5mbmin8}
                          >
                            {composerName !== "" ? null : "Choose Composer"}
                          </InputLabel>

                          <Select
                            className={(classes.mypadding, classes.p0)}
                            id="demo-simple-select-outlined"
                            value={composerName}
                            onChange={handleChangeSelectComposer}
                            autoWidth={true}
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>

                            {listComposer.map(item => (
                              <MenuItem
                                value={item.composer_id}
                                key={item.composer_id}
                              >
                                {item.composer_name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>

                      <Grid item md={6} className={classes.pr8}>
                        <label htmlFor="" className={classes.label}>
                          Loan Value
                        </label>
                        <Grid className={classes.flex}>
                          <Grid className={classes.loanGrid}>Rp</Grid>
                          <NumberFormat
                            value={money}
                            customInput={TextField}
                            variant="outlined"
                            thousandSeparator={true}
                            onChange={e => setMoney(e.target.value)}
                            autoComplete="off"
                            className={(classes.Input, classes.numFormat)}
                            size="small"
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                </Grid>

                <Grid>
                  <div>
                    <div className={classes.mt10mb10}>
                      <ThemeProvider theme={theme}>
                        <Typography className={classes.label}>Notes</Typography>
                      </ThemeProvider>
                    </div>

                    <div className={classes.mtmin10mb10}>
                      <TextField
                        disabled={true}
                        value={notes}
                        id="outlined-basic"
                        name="notes"
                        variant="outlined"
                        fullWidth={true}
                        multiline
                        rows={4}
                        margin="dense"
                        type="text"
                        onChange={event => handleChangeNotes(event)}
                        placeholder="Notes"
                      />
                    </div>
                  </div>
                </Grid>
                <Grid container justifyContent="flex-end">
                  <PrimaryButton
                    onClick={() => history.push("/admin/loan")}
                    label="Cancel"
                  />
                  <PrimaryButton onClick={e => onSubmit(e)} label="Update" />

                  {financeFor === "finance" ? (
                    <>
                      <PrimaryButton
                        onClick={handleOpenModalReject}
                        label="Reject"
                      />
                      <PrimaryButton
                        size="small"
                        onClick={handleOpenModal}
                        label="Approve"
                      />
                    </>
                  ) : (
                    <>{""}</>
                  )}
                </Grid>
              </CardContent>
              <React.Fragment>
                <Dialog
                  open={openModal}
                  onClose={handleClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    <ThemeProvider theme={interFont}>
                      <Typography className={classes.font16}>
                        {"Approve Loan Request"}
                      </Typography>
                    </ThemeProvider>
                  </DialogTitle>

                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      {"Are you sure to approve this loan request?"}
                    </DialogContentText>
                  </DialogContent>

                  <DialogActions>
                    <PrimaryButton
                      size="small"
                      onClick={() => {
                        setOpenModal(false);
                      }}
                      label="Cancel"
                    />
                    <PrimaryButton
                      size="small"
                      onClick={() => {
                        updateStatus("approved");
                      }}
                      label="Approve"
                    />
                  </DialogActions>
                </Dialog>
              </React.Fragment>

              <React.Fragment>
                <Dialog
                  open={openModalReject}
                  onClose={handleCloseReject}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    <ThemeProvider theme={interFont}>
                      <Typography className={classes.font16}>
                        {"Reject Loan Request"}
                      </Typography>
                    </ThemeProvider>
                  </DialogTitle>

                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      {"Are you sure to reject this loan request?"}
                    </DialogContentText>
                  </DialogContent>

                  <DialogActions>
                    <PrimaryButton
                      size="small"
                      onClick={() => {
                        setOpenModalReject(false);
                      }}
                      label="Cancel"
                    />
                    <PrimaryButton
                      size="small"
                      onClick={() => {
                        updateStatus("rejected");
                      }}
                      label="Reject"
                    />
                  </DialogActions>
                </Dialog>
              </React.Fragment>
            </Card>
          </Grid>

          <Grid item xs={12} md={4} lg={4} xl={4}></Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default EditLoan;
