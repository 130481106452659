import { Button, Divider, TextField, Typography } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { BackupOutlined } from "@material-ui/icons";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import { Autocomplete } from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { hardBaseUrl } from "../../../../../services/urlConstant";

const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  results: {
    marginTop: theme.spacing(3),
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  btnBack: {
    backgroundColor: "#25383c",
    color: "#fff",
    marginLeft: "10px",
    marginRight: "20px",
    textTransform: "none",
  },
  btnSubmit: {
    border: "1px solid lightgrey",
    textTransform: "none",
  },
  flexBetween: {
    display: "flex",
    justifyContent: "space-between",
  },
  fontSize16: {
    fontSize: "16px",
    fontWeight: 700,
  },
  closeOutline: {
    color: "#9AA2B1",
    cursor: "pointer",
    width: "12px",
    height: "12px",
    marginTop: "8px",
  },
  fontSize14: {
    fontSize: "14px",
    fontWeight: 500,
  },
  backupOutlined: {
    color: "grey",
    fontSize: "40px",
  },
  mt20: {
    marginTop: "20px",
  },
  mb10: {
    marginBottom: "10px",
  },
  listStyle: {
    listStyle: "none",
  },
  flexEnd: {
    display: "flex",
    justifyContent: "end",
  },
  mb20: {
    marginBottom: "20px",
  },
  autoComplete: {
    width: "195px",
    marginRight: "10px",
  },
  btnDownload: {
    backgroundColor: "#25383c",
    color: "#fff",
    marginBottom: "10px",
    marginRight: "20px",
    textTransform: "none",
  },
  mt10mb10: {
    marginTop: "10px",
    marginBottom: "10px",
  },
}));

const DialogImportDokumen = ({
  open,
  onClose,
  onSubmit,
  getRootProps,
  getInputProps,
  isDragActive,
  files,
  onSelectedPublisher,
  userLogin,
}) => {
  const classes = useStyles();
  const dialogRef = useRef();
  const [data, setData] = useState("");
  const [searchPublisher, setSearchPublisher] = useState("");
  const [selectedPublisher, setSelectedPublisher] = useState(null);
  const [dataPublishers, setDataPublishers] = useState([]);

  const downloadTemplate = () => {
    const url = `${hardBaseUrl}/publisher/song/download-template-catalogue`;
    const token = localStorage.getItem("token");
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(res => {
        setData(res.data.data);
      })
      .catch(err => new Error(err));
  };

  const getPublishers = () => {
    const url = `${hardBaseUrl}/publisher?page=1&size=10&search=${searchPublisher}`;
    const token = localStorage.getItem("token");
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(res => {
        if (res.data.data !== null) {
          setDataPublishers(res.data.data);
        } else {
          setDataPublishers([]);
        }
      })
      .catch(err => {
        new Error(err);
      });
  };

  useEffect(() => {
    downloadTemplate();
  }, []);

  useEffect(() => {
    getPublishers();
  }, [searchPublisher]);

  useEffect(() => {
    onSelectedPublisher(selectedPublisher);
  }, [selectedPublisher]);

  function onChangeSearchPublisher(e) {
    if (e.target.name === "searchPublisher") {
      setSearchPublisher(e.target.value);
    }
  }
  const showDropDown =
    userLogin.role.type === "association" || userLogin.role.type === "society";
  return (
    <div>
      <Dialog
        size="small"
        open={open}
        onClose={onClose}
        aria-labelledby="responsive-dialog-title"
        ref={dialogRef}
      >
        <DialogTitle id="responsive-dialog-title">
          <div className={classes.flexBetween}>
            <ThemeProvider theme={theme}>
              <Typography className={classes.fontSize16}>
                {"Import Document"}
              </Typography>
            </ThemeProvider>

            <CloseOutlinedIcon
              onClick={onClose}
              className={classes.closeOutline}
            />
          </div>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <div className={classes.mt10mb10}>
            <ThemeProvider theme={theme}>
              <Typography className={classes.fontSize14}>
                Upload Document
              </Typography>
            </ThemeProvider>
          </div>
          <div className="">
            <div {...getRootProps}>
              <input {...getInputProps()} />
              {isDragActive ? (
                <>
                  <BackupOutlined className={classes.backupOutlined} />
                  <p>Drop the files here ...</p>
                </>
              ) : (
                <>
                  <BackupOutlined className={classes.backupOutlined} />
                  <Typography component="p" variant="h6">
                    <b>Search File</b>, Drag Document Here
                  </Typography>
                </>
              )}
            </div>
            <div className={classes.mt20}>
              <aside>
                <ThemeProvider theme={theme}>
                  <a href={data} download>
                    <Button className={classes.btnDownload}>
                      Download Template
                    </Button>
                  </a>
                  {showDropDown ? (
                    <div>
                      <Autocomplete
                        clearOnEscape={true}
                        onChange={(event, value) => setSelectedPublisher(value)}
                        options={dataPublishers}
                        getOptionSelected={(option, value) =>
                          option.name === value.name
                        }
                        getOptionLabel={option => option.name}
                        className={classes.autoComplete}
                        renderInput={params => (
                          <TextField
                            {...params}
                            variant="outlined"
                            placeholder="Search Publisher"
                            size="small"
                            name="searchPublisher"
                            onChange={b => onChangeSearchPublisher(b)}
                          />
                        )}
                      />
                    </div>
                  ) : null}
                  {files.length > 0 && (
                    <Typography
                      className={
                        (classes.fontSize14, classes.mt20, classes.mb10)
                      }
                    >
                      Uploaded File
                    </Typography>
                  )}
                  <ul className={classes.listStyle}>{files}</ul>
                </ThemeProvider>
              </aside>
            </div>
          </div>
        </DialogContent>
        <Divider className={classes.divider} />
        <div className={(classes.flexEnd, classes.mb20)}>
          <Button
            className={classes.btnSubmit}
            variant="outlined"
            onClick={onClose}
          >
            Cancel
          </Button>

          <Button className={classes.btnBack} onClick={onSubmit}>
            Upload
          </Button>
        </div>
      </Dialog>
    </div>
  );
};

export default DialogImportDokumen;
