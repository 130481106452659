import { Close } from "@material-ui/icons";
import { AppBar, Dialog, Grid, IconButton, Typography } from "@mui/material";
import { globalStyles } from "styles";

const ModalPreviewDocument = ({ open, onClose, preload }) => {
  const classes = globalStyles();
  const { url, filename } = preload;
  return (
    <Dialog fullScreen open={open} onClose={onClose}>
      <AppBar>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          p="16px 16px 16px 24px"
        >
          <Grid item>
            <Typography color="white">{filename}</Typography>
          </Grid>
          <Grid item>
            <IconButton color="inherit" onClick={onClose}>
              <Close />
            </IconButton>
          </Grid>
        </Grid>
      </AppBar>
      <iframe src={url} className={classes?.filePreviewContainer} />
    </Dialog>
  );
};

export default ModalPreviewDocument;
