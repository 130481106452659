import { LockOutlined, MailOutlined } from "@ant-design/icons";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import { Button, Input } from "antd";
import Axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import CARIS from "../../../assets/img/newCarisLogo.webp";
import logoMpis from "../../../assets/img/newMpisLogo.webp";
import Layout from "../../../mpisLandingComponent/Layout";
import { hardBaseUrl } from "../../../services/urlConstant";
import { getErrors } from "../../../utils";
import "./Login.css";

export default function Login() {
  let history = useHistory();
  const message = useSelector(state => state.auth.message);
  const typeWeb = localStorage.getItem("typeWeb");
  const local_dsp_selected = localStorage.getItem("local_dsp_selected");
  const [forgotPassword, setForgotPassword] = useState(false);
  const [sentEmail, setSentEmail] = useState(false);
  const [remember, setRemember] = useState(false);
  const [dataLogin, setDataLogin] = useState({
    email: "",
    password: "",
  });
  const [loginDisabled, setLoginDisabled] = useState(false);

  useEffect(() => {
    if (local_dsp_selected) {
      localStorage.removeItem("local_dsp_selected");
    }
  }, []);

  useEffect(() => {
    if (message !== null) {
      window.location.reload();
    }
  }, [message]);

  const handleLogin = async e => {
    e.preventDefault();
    setLoginDisabled(true);
    let newDataLogin = {
      email: dataLogin.email,
      password: dataLogin.password,
      subdomain: "publisher",
      remember_me: remember,
    };

    try {
      let resultLogin = await Axios.post(
        hardBaseUrl + "/auth/login",
        newDataLogin,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (
        resultLogin.data.meta.http_status === 200 &&
        resultLogin.data.message === "success"
      ) {
        if (typeof resultLogin.data.data !== "object") {
          localStorage.setItem("url_login", history.location.pathname);
          history.push("/otp", { email: dataLogin.email });
        } else {
          localStorage.setItem("url_login", history.location.pathname);

          let resultMe = await Axios.get(hardBaseUrl + "/me", {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + resultLogin.data.data.access_token,
            },
          });
          if (
            resultMe.data.meta.http_status === 200 &&
            resultMe.data.message === "success"
          ) {
            if (resultMe.data.data.role.type === "publisher") {
              if (resultMe.data.data.publisher.theme_color === "") {
                localStorage.setItem("themeColor", "#9545eb");
              } else {
                localStorage.setItem(
                  "themeColor",
                  resultMe.data.data.publisher.theme_color
                );
              }
              history.push("/redirect/" + resultLogin.data.data.access_token);
            } else if (resultMe.data.data.role.type === "composer") {
              history.push("/redirect/" + resultLogin.data.data.access_token);
            } else {
              if (localStorage.getItem("typeWeb") === "caris") {
                localStorage.setItem("themeColor", "#111827");
              } else if (localStorage.getItem("typeWeb") === "mpis") {
                localStorage.setItem("themeColor", "#9545eb");
              }
              history.push("/redirect/" + resultLogin.data.data.access_token);
            }
          }
        }
      }
    } catch (error) {
      let errMessage = error.response
        ? getErrors(error.response)
        : "The connection server is having an error / check your internet connection";
      Swal.fire({
        icon: "error",
        title: "Failed!",
        text: errMessage || "Login Failed.",
        showCloseButton: false,
      }).then(() => {
        setLoginDisabled(false);
      });
    }
  };

  const handleForgot = async e => {
    e.preventDefault();
    await Axios.post(hardBaseUrl + "/auth/forgot", {
      email: dataLogin.email,
    });
    setTimeout(() => {
      setForgotPassword(false);
      setSentEmail(true);
    }, 500);
  };
  const getLogo = () => {
    if (typeWeb === "caris") {
      return (
        <img
          src={CARIS}
          alt=""
          width="206px"
          style={{
            marginBottom: "50px",
          }}
        />
      );
    } else {
      return (
        <img
          src={logoMpis}
          alt="logo"
          className="logo"
          width={"400px"}
          style={{
            marginBottom: "50px",
          }}
        />
      );
    }
  };
  return (
    <Layout>
      <div className="login">
        <div className="content">
          <div className="header">
            {getLogo()}

            <h1
              style={{
                fontSize: "30px",
                marginTop: "5px",
              }}
            >
              {forgotPassword
                ? "Forgot Password"
                : sentEmail
                  ? "Email has been sent"
                  : "Login to your account"}
            </h1>
            <h3 style={{ color: "#687083" }}>
              {forgotPassword
                ? "Enter the registered e-mail or mobile number. We will send a verification code to reset the password."
                : sentEmail
                  ? "You will receive a password recovery link at your email address."
                  : ""}
            </h3>
            {sentEmail && (
              <>
                <br />
                <h3 style={{ color: "#687083", fontSize: "14px" }}>
                  <span>{"Haven't received an email yet?"}</span>
                  <div
                    onClick={() => setSentEmail(false)}
                    style={{ color: "#9545EB", cursor: "pointer" }}
                  >
                    Resending
                  </div>
                </h3>
              </>
            )}
          </div>
          {!sentEmail && (
            <form
              className="input-form"
              onSubmit={forgotPassword ? handleForgot : handleLogin}
            >
              <Input
                onChange={e =>
                  setDataLogin({ ...dataLogin, email: e.target.value })
                }
                value={dataLogin.email}
                placeholder="Email / No. telepon"
                prefix={<MailOutlined style={{ marginRight: "10px" }} />}
                style={{
                  height: "50px",
                  borderRadius: "5px",
                  marginTop: "20px",
                }}
                disabled={loginDisabled}
              ></Input>
              {!forgotPassword && (
                <Input
                  onChange={e =>
                    setDataLogin({ ...dataLogin, password: e.target.value })
                  }
                  value={dataLogin.password}
                  placeholder="Password"
                  type="password"
                  prefix={<LockOutlined style={{ marginRight: "10px" }} />}
                  style={{
                    height: "50px",
                    borderRadius: "5px",
                    marginTop: "20px",
                    marginBottom: "20px",
                  }}
                  disabled={loginDisabled}
                ></Input>
              )}
              {!forgotPassword && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <Checkbox
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        name="checkedI"
                        onChange={e => setRemember(e.target.checked)}
                        checked={remember}
                      />
                    </div>
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => setRemember(!remember)}
                    >
                      Remember Me
                    </div>
                  </div>
                  <div
                    style={{
                      color: "#9545EB",
                      fontSize: "14px",
                      cursor: "pointer",
                    }}
                    onClick={() => setForgotPassword(true)}
                  >
                    Forgot password?
                  </div>
                </div>
              )}
              <Button
                type="primary"
                style={{
                  backgroundColor: "black",
                  border: "none",
                  width: "100%",
                  height: 44,
                  borderRadius: 5,
                  color: "white",
                  marginTop: "20px",
                  marginBottom: "20px",
                }}
                htmlType="submit"
                disabled={loginDisabled}
              >
                {forgotPassword ? "Continue" : "Login"}
              </Button>
              {forgotPassword && (
                <div
                  style={{
                    color: "#9545EB",
                    fontSize: "14px",
                    marginTop: "20px",
                    cursor: "pointer",
                  }}
                  onClick={() => setForgotPassword(false)}
                >
                  Back
                </div>
              )}
            </form>
          )}
        </div>
      </div>
    </Layout>
  );
}
