import { StatusChip } from "components";
import { Typography } from "@material-ui/core";

export const columnTableDsp = [
  {
    name: "line_number",
    title: "Line Number",
  },
  {
    name: "error_description",
    title: "Error Detail",
    width: 800,
    renderText: error_detail => (
      <Typography style={{ textOverflow: "ellipsis" }}>
        {error_detail}
      </Typography>
    ),
  },
];

export const columnTable = [
  {
    name: "line_number",
    title: "Line Number",
  },
  {
    name: "error_description",
    title: "Note",
    width: 800,
    renderText: error_detail => (
      <Typography style={{ textOverflow: "ellipsis" }}>
        {error_detail}
      </Typography>
    ),
  },
  {
    name: "status",
    title: "Status",
    renderText: status => (
      <StatusChip
        label={status}
        type={
          status === "Finish" || status === "Success"
            ? "success"
            : status === "Error" || status === "Failed"
            ? "danger"
            : status === "Started" ||
              status === "Initialize" ||
              status === "Running"
            ? "warning"
            : ""
        }
      />
    ),
  },
];

export const listUploadStatus = {
  I: "InitializeType",
  S: "Started",
  R: "Running",
  E: "Error",
  F: "Finish",
};
