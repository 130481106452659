import {
  ArrayChipWithRemove,
  CheckboxInput,
  SearchTextInput,
  SmallChip,
} from "components";
import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";
import {
  Box,
  CircularProgress,
  ClickAwayListener,
  Grow,
  InputAdornment,
  List,
  ListItem,
  Paper,
  Popover,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { getCookie } from "utils";

const MultipleSelectInputWithTags = ({
  label,
  placeholder,
  disabled,
  value = [],
  options = [],
  optionKey,
  optionLabel,
  checkBoxLabel,
  width,
  search,
  searchValue,
  onChangeSearch,
  onChange,
  textValue,
  loading,
  group,
}) => {
  const inputRef = useRef(null);
  const paperRef = useRef(null);

  const userLogin = getCookie("auth", `${process.env.REACT_APP_NAME}_user`);
  const mainPublisherId = userLogin?.publisher?.publisher_id;
  const [paperWidth, setPaperWidth] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleShowMenu = event => setAnchorEl(event.currentTarget);
  const handleClickAway = () => {
    setAnchorEl(null);
  };
  const handleClear = () => onChangeSearch("");
  useEffect(() => {
    if (paperRef.current) {
      setPaperWidth(paperRef.current.offsetWidth);
    }
  }, [anchorEl]);

  return (
    <Box>
      <CustomTextField
        customWidth={width}
        ref={inputRef}
        size="small"
        label={label}
        fullWidth
        focused={Boolean(anchorEl)}
        value={textValue}
        placeholder={placeholder}
        disabled={disabled}
        onClick={disabled ? null : handleShowMenu}
        InputProps={{
          readOnly: true,
          fullWidth: true,
          endAdornment: (
            <InputAdornment position="end">
              {value?.length > 0 && (
                <SmallChip
                  label={(
                    value?.length -
                    (value.some(item => item?.[optionKey] === "all") ? 1 : 0)
                  ).toString()}
                  type="failed"
                />
              )}
              {anchorEl ? <ArrowDropUp /> : <ArrowDropDown />}
            </InputAdornment>
          ),
        }}
      />
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClickAway}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        TransitionComponent={Grow}
      >
        <ClickAwayListener onClickAway={handleClickAway}>
          <SelectPaper customWidth={paperWidth} ref={paperRef}>
            {(value?.length > 0 || search) && (
              <FilterListContainer>
                {value?.length > 0 && (
                  <ArrayChipWithRemove
                    selectionsList={
                      value.some(item => item?.[optionKey] === "all")
                        ? [{ [optionKey]: "all", [optionLabel]: "All" }]
                        : value
                    }
                    value={optionLabel}
                    handleDelete={item => onChange(false, item)}
                  />
                )}
                {search && (
                  <Box mt={2}>
                    <SearchTextInput
                      placeholder="Search"
                      value={searchValue}
                      onChange={event => onChangeSearch(event?.target?.value)}
                      clear
                      onClear={handleClear}
                      width="100%"
                    />
                  </Box>
                )}
              </FilterListContainer>
            )}
            <CheckboxListContainer customWidth={inputRef?.current?.offsetWidth}>
              {loading ? (
                <Box p={2} textAlign="center">
                  <CircularProgress />
                </Box>
              ) : options?.length ? (
                group ? (
                  <Fragment>
                    {options.find(option => option?.[optionKey] === "all") && (
                      <CheckboxListIem>
                        <CheckboxInput
                          label="All"
                          checked={value?.some(
                            selected => selected?.[optionKey] === "all"
                          )}
                          onChange={e =>
                            onChange(e, {
                              [optionKey]: "all",
                              [optionLabel]: "All",
                            })
                          }
                        />
                      </CheckboxListIem>
                    )}

                    {options
                      .filter(
                        option =>
                          option?.[optionKey] === mainPublisherId &&
                          option?.[optionKey] !== "all" &&
                          option?.[optionLabel]
                      )
                      .map((option, index) => (
                        <Fragment key={index}>
                          {index === 0 && (
                            <CheckboxListIem>
                              <Typography fontWeight={700} mt={1}>
                                Publisher
                              </Typography>
                            </CheckboxListIem>
                          )}
                          <CheckboxListIem>
                            <CheckboxInput
                              label={checkBoxLabel || option?.[optionLabel]}
                              checked={value?.some(
                                selected =>
                                  selected?.[optionKey] === option?.[optionKey]
                              )}
                              onChange={e => onChange(e, option)}
                            />
                          </CheckboxListIem>
                        </Fragment>
                      ))}

                    {options
                      .filter(
                        option =>
                          option?.[optionKey] !== mainPublisherId &&
                          option?.[optionKey] !== "all" &&
                          option?.[optionLabel]
                      )
                      .map((option, index) => (
                        <Fragment key={index}>
                          {index === 0 && (
                            <CheckboxListIem>
                              <Typography fontWeight={700} mt={1}>
                                {"Publisher (Sub Reporting)"}
                              </Typography>
                            </CheckboxListIem>
                          )}
                          <CheckboxListIem>
                            <CheckboxInput
                              label={checkBoxLabel || option?.[optionLabel]}
                              checked={value?.some(
                                selected =>
                                  selected?.[optionKey] === option?.[optionKey]
                              )}
                              onChange={e => onChange(e, option)}
                            />
                          </CheckboxListIem>
                        </Fragment>
                      ))}
                  </Fragment>
                ) : (
                  options?.map((option, index) => (
                    <CheckboxListIem key={index}>
                      <CheckboxInput
                        label={checkBoxLabel || option?.[optionLabel]}
                        checked={value?.some(
                          selected =>
                            selected?.[optionKey] === option?.[optionKey]
                        )}
                        onChange={e => onChange(e, option)}
                      />
                    </CheckboxListIem>
                  ))
                )
              ) : (
                <Box p={2}>
                  <Typography align="center">
                    <em>No Result</em>
                  </Typography>
                </Box>
              )}
            </CheckboxListContainer>
          </SelectPaper>
        </ClickAwayListener>
      </Popover>
    </Box>
  );
};

const CustomTextField = styled(TextField)(({ customWidth }) => ({
  cursor: "pointer !important",
  width: customWidth,
  "& .MuiInputBase-root": {
    paddingRight: "8px",
    cursor: "pointer !important",
    "&.Mui-disabled": {
      cursor: "not-allowed !important",
      "&:hover": {
        cursor: "not-allowed !important",
      },
    },
  },
  "& .MuiInputBase-input": {
    cursor: "pointer !important",
    "&:hover": {
      cursor: "pointer !important",
    },
    "&.Mui-disabled": {
      cursor: "not-allowed !important",
      "&:hover": {
        cursor: "not-allowed !important",
      },
    },
  },
}));
const SelectPaper = styled(Paper)(({ customWidth }) => ({
  minWidth: customWidth,
}));
const FilterListContainer = styled(Box)(({ customWidth }) => ({
  maxWidth: customWidth,
  padding: 16,
  borderRadius: 8,
  borderBottom: "solid 1px #f1f1f1",
  boxShadow: "0 4px 8px #f1f1f1",
  position: "relative",
}));
const CheckboxListContainer = styled(List)(() => ({
  maxHeight: 250,
  overflowY: "auto",
  zIndex: 1,
  "&::-webkit-scrollbar": {
    width: "6px",
  },
  "&::-webkit-scrollbar-track": {
    background: "#f1f1f1",
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#888",
    borderRadius: "10px",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    background: "#555",
  },
}));
const CheckboxListIem = styled(ListItem)(() => ({
  padding: "0 20px !important",
}));

export default MultipleSelectInputWithTags;
