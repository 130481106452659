import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import {
  FormLabel,
  PrimaryButton,
  SecondaryButton,
  SelectInput,
  TextInput,
} from "components";
import { useEffect, useState } from "react";
import { globalStyles } from "styles";
import { getNestedValue } from "utils";

const ModalBankAccount = ({
  open,
  onClose,
  preload,
  optionBank,
  handleSubmit,
  loadingButton,
}) => {
  const classes = globalStyles();
  const [payload, setPayload] = useState({
    bank_id: 0,
    account_number: "",
    account_name: "",
  });
  const buttonDisabled =
    !payload?.bank_id || !payload?.account_name || !payload?.account_number;

  const handleChangePayload = (value, key) => {
    setPayload(currentState => ({
      ...currentState,
      ...(key !== "page" && { page: 1 }),
      [key]: value,
    }));
  };
  const getPreload = () => {
    setPayload({
      bank_id: preload?.bank?.bank_id,
      account_number: preload?.account_number,
      account_name: preload?.account_name,
    });
  };

  const onSubmit = () => {
    handleSubmit(payload);
  };

  useEffect(() => {
    getPreload();
  }, [preload]);

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogTitle>{`${preload ? "Edit" : "Add"} Bank Account`}</DialogTitle>
      <DialogContent>
        <Grid container direction="column" rowSpacing={1}>
          <Grid item>
            <FormLabel label="Bank" required />
            <SelectInput
              label={payload?.bank_id ? "" : "Bank"}
              options={optionBank}
              width="100%"
              value={payload?.bank_id || ""}
              onChange={event =>
                handleChangePayload(event?.target?.value, "bank_id")
              }
              optionKey="bank_id"
              optionLabel="name"
              renderValue={id => {
                let value = optionBank?.find(bank => bank?.bank_id === id);
                return value ? (
                  <Grid container columnSpacing={1}>
                    <Grid item>
                      <img
                        className={classes?.bankAccountImage}
                        src={getNestedValue(value, "profile_image")}
                        alt={"Bank"}
                      />
                    </Grid>
                    <Grid item>{getNestedValue(value, "name")}</Grid>
                  </Grid>
                ) : (
                  "Bank"
                );
              }}
              placeholder="None"
              disabled={loadingButton}
            />
          </Grid>
          <Grid item>
            <FormLabel label="Account Number" required />
            <TextInput
              placeholder="Account Number"
              value={payload?.account_number}
              onChange={e =>
                handleChangePayload(e?.target?.value, "account_number")
              }
              disabled={loadingButton}
            />
          </Grid>
          <Grid item>
            <FormLabel label="Name " required />
            <TextInput
              placeholder="Account Name"
              value={payload?.account_name}
              onChange={e =>
                handleChangePayload(e?.target?.value, "account_name")
              }
              disabled={loadingButton}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <SecondaryButton label="Cancel" onClick={onClose} />
        <PrimaryButton
          label={
            loadingButton
              ? preload
                ? "Updating"
                : "Submitting"
              : preload
              ? "Update"
              : "Add"
          }
          loading={loadingButton}
          disabled={buttonDisabled || loadingButton}
          onClick={onSubmit}
        />
      </DialogActions>
    </Dialog>
  );
};

export default ModalBankAccount;
