import { makeStyles } from "@material-ui/styles";

export const useStyling = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },

  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  singleFileIcon: {
    color: "#9AA2B1",
    height: "20px",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  results: {
    marginTop: theme.spacing(3),
  },
  btnEdit: {
    backgroundColor: "#111827",
    color: "white",
    width: "28px",
    height: "28px",
    padding: "6px",
    borderRadius: "6px",
    "&:hover, &.MuiIconButton": {
      background: "#374151 !important",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  backdrop1: {
    zIndex: 9999,
    color: "#fff",
  },
  inputFilter: {
    minWidth: 195,
    border: "1px solid #D1D5DC",
    borderRadius: "6px",
    "&.MuiInputBase-root": {
      height: 44,
    },
  },
  inputSearch: {
    width: "250px",
    height: "38px",
    border: "1px solid #D1D5DC",
    borderRadius: "6px",
  },
  buttonUploadExcel: {
    backgroundColor: "#34774C",
    color: "white",
    height: 44,
    padding: "10px 28px",
  },
  buttonAdd: {
    backgroundColor: " #111827",
    color: "white",
    padding: "10px 28px",
    height: 44,
  },
  pageSize: {
    height: "30px",
    borderRadius: "6px",
    border: "1px solid #D1D5DC",
    paddingLeft: "5px",
    paddingRight: "5px",
    marginTop: "5px",
  },
  paginationText: {
    fontSize: "14px",
    fontWeight: "normal",
    fontStyle: "normal",
  },
  breadcrumbs: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#111827",
  },
  breadCrumbsActive: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#687083",
  },
  placeholder: {
    "&::placeholder": {
      color: "#9AA2B1",
    },
  },
  select: {
    "& .MuiSelect-outlined": {
      paddingTop: "14px",
      paddingBottom: "14px",
      paddingLeft: "20px",
      paddingRight: "30px",
      width: "180px",
    },
  },
  buttonCancel: {
    height: "40px",
    width: "90px",
    borderRadius: "6px",
    border: "1px solid #D1D5DC",
    color: "#111827",
    backgroundColor: "white",
    textTransform: "none",
  },
  buttonSend: {
    height: "40px",
    width: "90px",
    borderRadius: "6px",
    border: "0px solid #D1D5DC",
    color: "white",
    backgroundColor: "#111827",
    textTransform: "none",
  },
  typography: {
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: "500",
    fontStyle: "normal",
    textTransform: "none",
  },
  tableChip: {
    marginRight: "5px",
    backgroundColor: "#F9FAFB",
    border: "1px solid #D1D5DC",
  },
  activeChip: {
    backgroundColor: "#8DE5AB",
    color: "#34774C",
    fontSize: "14px",
    fontWeight: "500",
  },
  activeChipIndicator: {
    color: "#34774C",
    fontSize: "12px",
  },
  pendingChip: {
    backgroundColor: "rgb(246, 201, 98)",
    color: "rgb(169 113 11)",
    fontSize: "14px",
    fontWeight: "500",
  },
  pendingChipIndicator: {
    color: "rgb(169 113 11)",
    fontSize: "12px",
  },
  nonActiveChip: {
    backgroundColor: "#D1D5DC",
    color: "#364052",
    fontSize: "14px",
    fontWeight: "500",
  },
  nonActiveChipIndicator: {
    color: "#364052",
    fontSize: "12px",
  },
}));

export const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "50px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#303030",
  borderStyle: "thin",
  backgroundColor: "lightgray",
  color: "black",
  outline: "none",
  transition: "border .24s ease-in-out",
  cursor: "pointer",
};

export const activeStyle = {
  borderColor: "#2196f3",
};

export const acceptStyle = {
  borderColor: "#00e676",
};

export const rejectStyle = {
  borderColor: "#ff1744",
};

export const mpisMasterTopMenu = [
  {
    id: 0,
    title: "Song",
    link: "/admin/parameter/lagu",
    selected: false,
  },
  {
    id: 1,
    title: "Composer/Author",
    link: "/admin/parameter/pencipta",
    selected: true,
  },
  {
    id: 2,
    title: "Performer",
    link: "/admin/parameter/performer",
    selected: false,
  },
];

export const carisMasterTopMenu = [
  {
    id: 0,
    title: "Composer/Author",
    link: "/admin/parameter/pencipta",
    selected: true,
  },
  {
    id: 1,
    title: "Song",
    link: "/admin/parameter/lagu",
    selected: false,
  },
  {
    id: 2,
    title: "Performer",
    link: "/admin/parameter/performer",
    selected: false,
  },

  {
    id: 3,
    title: "Client",
    link: "/admin/parameter/dsp",
    selected: false,
  },
  {
    id: 4,
    title: "User",
    link: "/admin/parameter/user",
    selected: false,
  },
  {
    id: 5,
    title: "Agent",
    link: "/admin/parameter/agent",
    selected: false,
  },
  {
    id: 6,
    title: "Group",
    link: "/admin/parameter/group",
    selected: false,
  },
  {
    id: 7,
    title: "Client Classification",
    link: "/admin/parameter/client-classification",
    selected: false,
  },
];

export const sortList = [
  {
    id: 1,
    title: "Composer/Author Name ASC",
    value: "sure_name",
  },
  {
    id: 2,
    title: "Composer/Author Name DSC",
    value: "sure_name-",
  },
  {
    id: 3,
    title: "ID Composer/Author ASC",
    value: "composer_id",
  },
  {
    id: 4,
    title: "ID Composer/Author DSC",
    value: "composer_id-",
  },
  {
    id: 5,
    title: "Nickname ASC",
    value: "alias_name",
  },
  {
    id: 6,
    title: "Nickname DSC",
    value: "alias_name-",
  },
  {
    id: 7,
    title: "Email ASC",
    value: "email",
  },
  {
    id: 8,
    title: "Email DSC",
    value: "email-",
  },
];
