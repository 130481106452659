const devLog = (items, path, show) => {
  if (process.env.NODE_ENV === "development") {
    if (show) {
      return items, path, show;
    }
  }
};

export default {
  devLog,
};
