import {
  Backdrop,
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import moment from "moment";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import "../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import updateIcon from "../../../../assets/img/pencilVector.svg";
import deleteIcon from "../../../../assets/img/trashVector.svg";
import { Page, PrimaryButton } from "components";
import { hardBaseUrl } from "../../../../services/urlConstant";
import { getCookie } from "../../../../utils/constants";
import { getErrors } from "../../../../utils";
import { StatusChip } from "../../../../components";

const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  btnSubmit: {
    backgroundColor: "black",
    marginRight: "10px",
    color: "white",
    width: "106px",
    height: "40px",
    borderRadius: "6px",
    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
  btnPreview: {
    backgroundColor: "black",
    marginRight: "10px",
    color: "white",
    width: "106px",
    height: "40px",
    borderRadius: "6px",
    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  btnDialog: {
    backgroundColor: "black",
    marginRight: "10px",
    marginBottom: "10px",
    color: "white",
    width: "150px",
    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
  headText: {
    fontSize: "12px",
    color: "#687083",
    fontWeight: "bold",
  },
  bodyTextBlack: {
    fontSize: "14px",
    fontWeight: "regular",
    color: "#111827",
    fontStyle: "normal",
  },
  label: {
    color: "#364052",
    fontSize: "14px",
    fontWeight: 500,
  },
  subTitle: {
    fontSize: "18px",
    fontWeight: 700,
    color: " #111827",
  },
  breadcrumbs: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#111827",
  },
  breadCrumbsActive: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#687083",
  },
  activeChipIndicator: {
    color: "#34774C",
    fontSize: "12px",
  },
  activeChip: {
    backgroundColor: "#8DE5AB",
    color: "#34774C",
    fontSize: "14px",
    fontWeight: "500",
  },
  nonActiveChipIndicator: {
    color: "red",
    fontSize: "12px",
  },
  nonActiveChip: {
    backgroundColor: "#f0928b",
    color: "#c94136",
    fontSize: "14px",
    fontWeight: "500",
  },
  tlpnField: {
    marginTop: "0px",
    "& .MuiOutlinedInput-root": {
      paddingLeft: 0,
    },
    "& MuiOutlinedInput-adornedStart": {
      paddingLeft: "0px",
    },
  },
  startAdornment: {
    background: "linear-gradient(-90deg, #F9FAFB 30%, #FFF 30%)",
  },
  "& MuiOutlinedInput-adornedStart": {
    paddingLeft: "0  !important",
  },
  btnEdit: {
    backgroundColor: "#111827",
    color: "white",
    width: "30px",
    height: "35px",
    padding: "6px",
    borderRadius: "6px",
    "&:hover": {
      backgroundColor: "#111827",
      color: "white",
    },
  },
  btnDelete: {
    backgroundColor: "#111827",
    color: "white",
    width: "35px",
    height: "35px",
    padding: "6px",
    borderRadius: "6px",
    marginLeft: "10px",
    cursor: "pointer",
  },
  required: {
    color: "red",
  },
}));

const interFont = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

function EditPencipta(props) {
  const ref = useRef(null);
  const { id } = useParams();
  const classes = useStyles();
  const history = useHistory();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState();
  const [alias, setAlias] = useState("");
  const [nik, setNik] = useState("");
  const [ipi_name_number, set_ipi_name_number] = useState("");
  const [ipi_base_number, set_ipi_base_number] = useState("");
  const [npwp, setNpwp] = useState("");
  const [getColor, setGetColor] = useState("");
  const [alamat, setAlamat] = useState("");
  const [songList, setSongList] = useState([]);
  const [startDate, setStartDate] = useState(moment().toString());
  const [endDate, setEndDate] = useState(moment().toString());
  const [identification_type, setIdentification_type] = useState("");
  const [sharePercentage, setSharePercentage] = useState(0);
  const [contractNumber, setContractNumber] = useState("");
  const [composerPublisherId, setComposerPublisherId] = useState("");
  const [openSendInvitation, setOpenSendInvitation] = useState(false);
  const [
    buttonSentInvitationDisable,
    setButtonSentInvitationDisable,
  ] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const [dataContract, setDataContract] = useState([]);
  const [openModalSelectPerformer, setOpenModalSelectPerformer] = useState(
    false
  );
  const [loading, setLoading] = useState(false);
  const [statusDialog, setStatusDialog] = useState("add");
  const [aliasName, setAliasName] = useState("");
  const [selectedAliasIndex, setSelectedAliasIndex] = useState(null);
  const [delLabel, setDelLabel] = useState("");
  const [tableAliasName, setTableAliasName] = useState([]);
  const [confirmDel, setConfirmDel] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const handleOpenSendInvitation = () => {
    setOpenSendInvitation(true);
  };

  const handleCloseSendInvitation = () => {
    setOpenSendInvitation(false);
  };

  const handleChangeStartDate = date => {
    setStartDate(date._d);
  };

  const handleChangeEndDate = date => {
    setEndDate(date._d);
  };

  const handleChangeSharePercentage = event => {
    setSharePercentage(event.target.value);
  };

  const baseUrl = hardBaseUrl;

  const getResponse = () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    const url = `${baseUrl}/publisher/composer/${id}`;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    axios
      .get(url, config)
      .then(res => {
        setName(res.data.data.sure_name);
        setEmail(res.data.data.email);
        setPhone(res.data.data.phone_number);
        setAlias(res.data.data.alias_name);
        setNik(res.data.data.nik);
        setNpwp(res.data.data.npwp);
        set_ipi_base_number(res?.data?.data?.ipi_base_number);
        set_ipi_name_number(res?.data?.data?.ipi_name_number);
        setIdentification_type(res.data.data.identification_type);
        setAlamat(res.data.data.address);
        setSharePercentage(res.data.data.composer_publisher.share_percentage);
        setContractNumber(res.data.data.composer_publisher.contract_number);
        setComposerPublisherId(
          res.data.data.composer_publisher.composer_publisher_id
        );
        setIsLoggedIn(res.data.data.is_logged_in);
        setDataContract(res.data.data.contracts);
        setTableAliasName(res.data.data.alias_names);

        let startingDate = res.data.data.composer_publisher.contract_start_period.split(
          "-"
        );
        let endingDate = res.data.data.composer_publisher.contract_end_period.split(
          "-"
        );
        let startingDateFormated =
          startingDate[1] + "/" + startingDate[2] + "/" + startingDate[0];
        let endingDateFormated =
          endingDate[1] + "/" + endingDate[2] + "/" + endingDate[0];
        let startingDateToDate = new Date(startingDateFormated);
        let endingDateToDate = new Date(endingDateFormated);
        setStartDate(startingDateToDate);
        setEndDate(endingDateToDate);

        const newData = res.data.data.song_composer.map(item => ({
          ...item,
          name: res.data.data.sure_name,
        }));
        setSongList(newData);
        localStorage.setItem(
          "composer_bank",
          JSON.stringify(res.data.data.composer_bank)
        );
      })
      .catch(err => new Error(err))
      .finally(() => setLoading(false));
  };
  const userLogin = getCookie("auth", `${process.env.REACT_APP_NAME}_user`);
  const getMainDsp = async () => {
    try {
      setGetColor(userLogin?.publisher?.theme_color);
    } catch (e) {
      new Error(e);
    }
  };

  useEffect(() => {
    getResponse();
    getMainDsp();
  }, []);
  const handleChange = event => {
    const { name, value } = event.target;
    if (name === "name") {
      setName(value);
    } else if (name === "email") {
      setEmail(value);
    } else if (name === "phone") {
      setPhone(value);
    } else if (name === "nik") {
      setNik(value);
    } else if (name === "alias") {
      setAlias(value);
    } else if (name === "npwp") {
      setNpwp(value);
    } else if (name === "alamat") {
      setAlamat(value);
    } else if (name === "contractNumber") {
      setContractNumber(value);
    } else if (name === "aliasName") {
      setAliasName(value);
    } else if (name === "identification_type") {
      setIdentification_type(value);
    }
  };
  const getDateFormatForFilter = date => {
    let dateFormat = new Date(date);
    let day = dateFormat
      .getDate()
      .toString()
      .padStart(2, "0");
    let month = (dateFormat.getMonth() + 1).toString().padStart(2, "0");
    let year = dateFormat.getFullYear();
    let dateString = `${year}-${month}-${day}`;
    return dateString;
  };
  const notifError = text =>
    Swal.fire({
      title: "Oops…",
      icon: "error",
      text: text,
      showCancelButton: false,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK",
    });
  const regexEmail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  const handleButtonSimpan = async () => {
    if (!name) {
      return notifError("Composer/Author name can't be empty");
    }
    if (email.length > 0) {
      if (!regexEmail.test(email)) {
        return notifError(
          "Please enter your email address in format: yourname@example.com"
        );
      }
    }
    if (nik && !identification_type) {
      return notifError("Identification Type can't be empty");
    }
    if (identification_type && !nik) {
      return notifError("Identification Number can't be empty");
    }
    if (!sharePercentage) {
      return notifError("Please enter the general share percentage");
    }

    const payload = {
      sure_name: name,
      alias_name: alias,
      email: email,
      phone_number: phone,
      alias_names: tableAliasName,
      song_aliases: tableAliasName,
      npwp: npwp,
      nik: nik,
      address: alamat,
      ipi_name_number,
      ipi_base_number,
      identification_type: identification_type,
      composer_publisher: {
        composer_publisher_id: Number(composerPublisherId),
        contract_number: contractNumber,
        contract_start_period: getDateFormatForFilter(startDate),
        contract_end_period: getDateFormatForFilter(endDate),
        share_percentage: Number(sharePercentage),
      },
    };

    let baseOfUrl = baseUrl;
    const token = localStorage.getItem("token");
    let urlUse = baseOfUrl + `/publisher/composer/${id}`;
    setSubmitting(true);
    await axios
      .put(urlUse, payload, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then(() => {
        Swal.fire({
          title: "Success",
          text: "Composer data was successfully updated",
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        }).then(result => {
          if (result.value) {
            props.history.push("/admin/parameter/pencipta");
          }
        });
      })
      .catch(err => {
        setSubmitting(false);
        let errMessage = getErrors(err.response);
        Swal.fire({
          title: "Error",
          text: errMessage,
          icon: "error",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
        });
      });
  };

  const sendInvitation = () => {
    setButtonSentInvitationDisable(true);
    const url = `${hardBaseUrl}/publisher/composer/invitation-single`;
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const payload = {
      composer_id: id,
    };
    axios
      .post(url, payload, config)
      .then(() => {
        setOpenSendInvitation(false);
        setTimeout(() => {
          setButtonSentInvitationDisable(false);
        }, 60000);
      })
      .catch(err => {
        setButtonSentInvitationDisable(false);
        setOpenSendInvitation(false);
        let errMessage = getErrors(err.response);
        Swal.fire({
          icon: "error",
          title: "Failed!",
          text: errMessage,
        });
      });
  };
  const onClickAddAlias = () => {
    setStatusDialog("add");
    setAliasName("");
    setOpenModalSelectPerformer(true);
  };
  const handleResetDialogPerformer = () => {
    setOpenModalSelectPerformer(false);
    setAliasName("");
  };
  const onClickDialogAlias = e => {
    e.preventDefault();
    if (aliasName !== "") {
      let newAlias = aliasName;
      let newArr = tableAliasName;
      newArr.push(newAlias);
      setTableAliasName(newArr);
      setOpenModalSelectPerformer(false);
      setAliasName("");
    } else {
      setOpenModalSelectPerformer(false);
      Swal.fire({
        title: "Error",
        text: "Please Fill Alias Name.",
        icon: "error",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
      }).then(result => {
        if (result.value) {
          setOpenModalSelectPerformer(true);
        }
      });
    }
  };
  const goDelete = () => {
    let currentAlias = tableAliasName;
    let afterRemove = _.remove(currentAlias, function(n) {
      return n !== delLabel;
    });

    setTableAliasName(afterRemove);
    setConfirmDel(false);
  };
  const onDel = e => {
    setDelLabel(e);

    setConfirmDel(true);
  };
  const onClickUpdateDialogAlias = e => {
    e.preventDefault();
    if (aliasName !== "") {
      let currentTable = tableAliasName;
      currentTable[selectedAliasIndex] = aliasName;
      setTableAliasName(currentTable);
      setOpenModalSelectPerformer(false);
    } else {
      setOpenModalSelectPerformer(false);
      Swal.fire({
        title: "Error",
        text: "Please Fill Alias Name.",
        icon: "error",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
      }).then(result => {
        if (result.value) {
          setOpenModalSelectPerformer(true);
        }
      });
    }
  };
  const onEdit = (e, index) => {
    setStatusDialog("edit");

    setAliasName(e);
    setSelectedAliasIndex(index);
    setOpenModalSelectPerformer(true);
  };
  return (
    <Page className={classes.root} title="Edit Composer">
      <Backdrop className={classes.backdrop} open={false}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container maxWidth={false}>
        <form ref={ref}>
          <Grid
            alignItems="flex-end"
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <ThemeProvider theme={theme}>
                <Typography
                  style={{
                    fontSize: "24px",
                    fontWeight: "700",
                    lineHeight: "32px",
                  }}
                >
                  Edit Composer/Author
                </Typography>
              </ThemeProvider>
            </Grid>
            <Grid item>
              <Breadcrumbs aria-label="breadcrumb">
                <Typography className={classes.breadCrumbs}>Home</Typography>
                <Typography className={classes.breadCrumbs}>
                  Parameter
                </Typography>
                <Typography className={classes.breadCrumbs}>
                  Composer
                </Typography>
                <Typography className={classes.breadCrumbsActive}>
                  Edit Composer/Author
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
          <Grid container>
            <Grid item xs={12} md={8} lg={8} xl={8}>
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      marginTop: "10px",
                    }}
                  >
                    <ThemeProvider theme={theme}>
                      <Typography
                        component="h1"
                        variant="h3"
                        className={classes.subTitle}
                      >
                        Account Information
                      </Typography>
                    </ThemeProvider>
                  </div>
                  {isLoggedIn === true ? (
                    <></>
                  ) : (
                    localStorage.typeWeb === "mpis" &&
                    localStorage.role !== "publisher" && (
                      <div>
                        <Button
                          onClick={handleOpenSendInvitation}
                          style={{
                            backgroundColor: " #111827",
                            color: "white",
                            padding: "10px 28px 10px 28px",
                            marginRight: "10px",
                          }}
                          variant="contained"
                          size="large"
                          disabled={buttonSentInvitationDisable}
                          startIcon={
                            buttonSentInvitationDisable ? "" : <AddIcon />
                          }
                        >
                          {buttonSentInvitationDisable ? (
                            <CircularProgress size={20} />
                          ) : (
                            <ThemeProvider theme={theme}>
                              <Typography
                                component="span"
                                style={{
                                  fontSize: "14px",
                                  lineHeight: "20px",
                                  fontWeight: "500",
                                  fontStyle: "normal",
                                  textTransform: "none",
                                }}
                              >
                                Send Invitation
                              </Typography>
                            </ThemeProvider>
                          )}
                        </Button>
                      </div>
                    )
                  )}
                </div>
                <ThemeProvider theme={theme}>
                  <p
                    style={{
                      marginTop: "5px",
                      color: "#8f8f8f",
                      fontSize: "14px",
                    }}
                  >
                    Fetaures to add composer/author’s account
                  </p>
                </ThemeProvider>
              </div>
            </Grid>
            <Grid item xs={12} md={3} lg={3} xl={3}></Grid>
          </Grid>

          <Dialog
            open={openSendInvitation}
            onClose={handleCloseSendInvitation}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              <ThemeProvider theme={interFont}>
                <Typography
                  style={{
                    fontWeight: 700,
                    fontSize: "16px",
                    lineHeight: "24px",
                  }}
                >
                  Send Invitation
                </Typography>
              </ThemeProvider>
            </DialogTitle>
            <DialogContent>Are you sure want to send invitation?</DialogContent>
            <DialogActions>
              <Button
                onClick={handleCloseSendInvitation}
                style={{
                  height: "40px",
                  width: "90px",
                  borderRadius: "6px",
                  border: "1px solid #D1D5DC",
                  color: "#111827",
                  backgroundColor: "white",
                  textTransform: "none",
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  sendInvitation();
                }}
                autoFocus
                style={{
                  height: "40px",
                  width: "90px",
                  borderRadius: "6px",
                  border: "0px solid #D1D5DC",
                  color: "white",
                  backgroundColor: "#111827",
                  textTransform: "none",
                }}
              >
                Send
              </Button>
            </DialogActions>
          </Dialog>
          <Grid container>
            <Grid item xs={12} md={8} lg={8} xl={8}>
              <Card
                style={{
                  border: "1px solid #9AA2B1",
                  borderRadius: "6px",
                  boxShadow: "none",
                }}
              >
                <CardContent>
                  <Grid container spacing={1}>
                    <Grid
                      justifyContent="space-between"
                      item
                      xs={12}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      <ThemeProvider theme={theme}>
                        <Typography className={classes.label}>
                          Composer/Author Name{" "}
                          <span className={classes.required}>*</span>
                        </Typography>
                      </ThemeProvider>
                      <TextField
                        id="outlined-basic"
                        name="name"
                        variant="outlined"
                        fullWidth={true}
                        disabled={loading}
                        value={name}
                        margin="dense"
                        onChange={event => handleChange(event)}
                        placeholder="Composer/Author Name"
                      />
                    </Grid>
                    <Grid
                      item
                      justifyContent="space-between"
                      xs={12}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      <ThemeProvider theme={theme}>
                        <Typography className={classes.label}>
                          Phone Number{" "}
                          <span className={classes.required}>*</span>
                        </Typography>
                      </ThemeProvider>
                      <TextField
                        id="outlined-basic"
                        name="phone"
                        type="number"
                        variant="outlined"
                        value={phone}
                        fullWidth={true}
                        disabled={loading}
                        margin="dense"
                        onChange={event => handleChange(event)}
                        placeholder="Phone Number"
                      />
                    </Grid>
                    <Grid
                      item
                      justifyContent="space-between"
                      xs={12}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      <ThemeProvider theme={theme}>
                        <Typography className={classes.label}>
                          Email <span className={classes.required}>*</span>
                        </Typography>
                      </ThemeProvider>
                      <TextField
                        id="outlined-basic"
                        name="email"
                        value={email}
                        variant="outlined"
                        disabled={loading}
                        fullWidth={true}
                        margin="dense"
                        type="email"
                        onChange={event => handleChange(event)}
                        placeholder="Email"
                      />
                    </Grid>
                    <Grid
                      item
                      justifyContent="space-between"
                      xs={12}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      <ThemeProvider theme={theme}>
                        <Typography className={classes.label}>
                          Identification Number{" "}
                          <span className={classes.required}>*</span>
                        </Typography>
                      </ThemeProvider>
                      <TextField
                        id="outlined-basic"
                        name="nik"
                        variant="outlined"
                        placeholder="Identification Number"
                        fullWidth={true}
                        value={nik}
                        disabled={loading}
                        margin="dense"
                        type={
                          identification_type === "KITAS" ? "text" : "number"
                        }
                        onChange={event => handleChange(event)}
                        onInput={e => {
                          let inputValue = e.target.value;
                          if (identification_type === "KITAS") {
                            inputValue = inputValue.replace(
                              /[^a-zA-Z0-9]/g,
                              ""
                            );
                            inputValue = inputValue.slice(0, 11);
                          } else {
                            inputValue = Math.max(0, parseInt(inputValue))
                              .toString()
                              .slice(0, 16);
                          }
                          e.target.value = inputValue;
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      justifyContent="space-between"
                      xs={12}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      <ThemeProvider theme={theme}>
                        <Typography className={classes.label}>
                          NPWP <span className={classes.required}>*</span>
                        </Typography>
                      </ThemeProvider>
                      <TextField
                        id="outlined-basic"
                        name="npwp"
                        variant="outlined"
                        margin="dense"
                        value={npwp}
                        type="number"
                        placeholder="NPWP"
                        disabled={loading}
                        fullWidth={true}
                        onChange={event => handleChange(event)}
                        onInput={e => {
                          e.target.value = Math.max(0, parseInt(e.target.value))
                            .toString()
                            .slice(0, 15);
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      justifyContent="space-between"
                      xs={12}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      <ThemeProvider theme={theme}>
                        <Typography className={classes.label}>
                          Identification Type{" "}
                          <span className={classes.required}>*</span>
                        </Typography>
                      </ThemeProvider>
                      <Select
                        style={{
                          width: "100%",
                          height: 37,
                          margin: "8px 0px 4px 0px",
                        }}
                        id="outlined-basic"
                        name="identification_type"
                        value={identification_type}
                        disabled={loading}
                        onChange={e => handleChange(e)}
                        displayEmpty
                        variant="outlined"
                        placeholder="bebas"
                        autoWidth
                        fullWidth
                      >
                        <MenuItem value="NIK">NIK</MenuItem>
                        <MenuItem value="KITAS">KITAS</MenuItem>
                      </Select>
                    </Grid>
                    <Grid
                      item
                      justifyContent="space-between"
                      xs={12}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      <ThemeProvider theme={theme}>
                        <Typography className={classes.label}>
                          IPI Name Number{" "}
                          <span className={classes.required}>*</span>
                        </Typography>
                      </ThemeProvider>
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        margin="dense"
                        disabled={loading}
                        type="number"
                        placeholder="IPI Name Number"
                        fullWidth
                        name="ipi_name_number"
                        onChange={event =>
                          set_ipi_name_number(event?.target?.value)
                        }
                        value={ipi_name_number}
                      />
                    </Grid>
                    <Grid
                      item
                      justifyContent="space-between"
                      xs={12}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      <ThemeProvider theme={theme}>
                        <Typography className={classes.label}>
                          IPI Base Number
                        </Typography>
                      </ThemeProvider>
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        margin="dense"
                        disabled={loading}
                        type="number"
                        placeholder="IPI Base Number"
                        name="ipi_base_number"
                        fullWidth
                        value={ipi_base_number}
                        onChange={event =>
                          set_ipi_base_number(event?.target?.value)
                        }
                      />
                    </Grid>
                    <Grid item justifyContent="space-between" xs={12}>
                      <ThemeProvider theme={theme}>
                        <Typography className={classes.label}>
                          Address
                        </Typography>
                      </ThemeProvider>
                      <TextField
                        id="outlined-basic"
                        name="alamat"
                        variant="outlined"
                        multiline
                        rows={4}
                        fullWidth={true}
                        disabled={loading}
                        margin="dense"
                        onChange={event => handleChange(event)}
                        placeholder="Address"
                        value={alamat}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={4} md={4} sm={4} lg={4} xl={4}></Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
              <Divider className={classes.divider} />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <div
                  style={{
                    paddingTop: "5px",
                    paddingBottom: "5px",
                  }}
                >
                  <ThemeProvider theme={theme}>
                    <Typography
                      component="h1"
                      variant="h3"
                      className={classes.subTitle}
                    >
                      Alias Name
                    </Typography>
                  </ThemeProvider>
                </div>

                <div>
                  <PrimaryButton
                    size="small"
                    onClick={() => onClickAddAlias()}
                    label="Add Alias Name"
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={8} md={8} sm={8} lg={8} xl={8}>
              <Card>
                <div>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <ThemeProvider theme={theme}>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={classes.headText}
                            >
                              Action
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={classes.headText}
                            >
                              Alias Name
                            </Typography>
                          </TableCell>
                        </ThemeProvider>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {tableAliasName?.length > 0 ? (
                        tableAliasName?.map((item, index) => {
                          return (
                            <TableRow key={`${item}-${index}`}>
                              <ThemeProvider theme={theme}>
                                <TableCell
                                  style={{
                                    width: "10%",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                    }}
                                  >
                                    <IconButton
                                      onClick={() => onEdit(item, index)}
                                      className={classes.btnEdit}
                                      style={{
                                        backgroundColor: getColor || "black",
                                      }}
                                    >
                                      <div>
                                        <img
                                          src={updateIcon}
                                          alt="update-icon"
                                          style={{
                                            filter: " brightness(0) invert(1)",
                                          }}
                                        />
                                      </div>
                                    </IconButton>
                                    <IconButton
                                      onClick={() => onDel(item)}
                                      className={classes.btnDelete}
                                      style={{
                                        backgroundColor: getColor || "black",
                                      }}
                                    >
                                      <div>
                                        <img
                                          src={deleteIcon}
                                          alt="delete-icon"
                                          style={{
                                            filter: " brightness(0) invert(1)",
                                          }}
                                        />
                                      </div>
                                    </IconButton>
                                  </div>
                                </TableCell>
                                <TableCell
                                  style={{
                                    width: "30%",
                                  }}
                                >
                                  <Typography
                                    variant="subtitle2"
                                    className={classes.bodyTextBlack}
                                  >
                                    {item}
                                  </Typography>
                                </TableCell>
                              </ThemeProvider>
                            </TableRow>
                          );
                        })
                      ) : (
                        <TableRow>
                          <TableCell colSpan={3}>
                            <div
                              style={{
                                marginTop: "10px",
                                marginBottom: "10px",
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <Typography
                                style={{
                                  color: "#687083",
                                  fontSize: "14px",
                                  fontWeight: "400",
                                }}
                              >
                                No Data
                              </Typography>
                            </div>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </div>
              </Card>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
              <Divider className={classes.divider} />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
              <div>
                <Typography
                  component="h1"
                  variant="h3"
                  className={classes.subTitle}
                >
                  Contract Information
                </Typography>
                <ThemeProvider theme={theme}>
                  <p
                    style={{
                      marginTop: "5px",
                      color: "#8f8f8f",
                      fontSize: "14px",
                    }}
                  >
                    Features to add composer/author&apos;s contract
                  </p>
                </ThemeProvider>
              </div>

              <Card
                style={{
                  border: "1px solid #9AA2B1",
                  borderRadius: "6px",
                  boxShadow: "none",
                  display: "none",
                }}
              >
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6} lg={6} xl={6}>
                      <div
                        style={{
                          marginTop: "10px",
                        }}
                      >
                        <ThemeProvider theme={theme}>
                          <Typography className={classes.label}>
                            Contract Number
                          </Typography>
                        </ThemeProvider>
                      </div>
                      <div>
                        <TextField
                          id="outlined-basic"
                          name="contractNumber"
                          variant="outlined"
                          placeholder="Contract Number"
                          fullWidth={true}
                          margin="dense"
                          value={contractNumber}
                          type="number"
                          onChange={event => handleChange(event)}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6} xl={6}></Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6} lg={6} xl={6}>
                      <ThemeProvider theme={theme}>
                        <label>Start Date</label>
                      </ThemeProvider>
                      <KeyboardDatePicker
                        id="outlined-basic"
                        variant="outlined"
                        onChange={handleChangeStartDate}
                        className={classes.selectPadding}
                        value={startDate}
                        name="startDate"
                        format="DD-MM-YYYY"
                        inputVariant="outlined"
                        fullWidth={true}
                        margin="dense"
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6} xl={6}>
                      <ThemeProvider theme={theme}>
                        <label>End Date</label>
                      </ThemeProvider>
                      <KeyboardDatePicker
                        id="outlined-basic"
                        variant="outlined"
                        onChange={handleChangeEndDate}
                        className={classes.selectPadding}
                        value={endDate}
                        name="endDate"
                        format="DD-MM-YYYY"
                        inputVariant="outlined"
                        fullWidth={true}
                        margin="dense"
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <Card
                style={{
                  border: "1px solid #9AA2B1",
                  borderRadius: "6px",
                  boxShadow: "none",
                }}
              >
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <ThemeProvider theme={theme}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={classes.headText}
                          >
                            Contract Number
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography
                            variant="subtitle2"
                            className={classes.headText}
                          >
                            Start Date
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography
                            variant="subtitle2"
                            className={classes.headText}
                          >
                            End Date
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography
                            variant="subtitle2"
                            className={classes.headText}
                          >
                            ID Publisher
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography
                            variant="subtitle2"
                            className={classes.headText}
                          >
                            Publisher
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography
                            variant="subtitle2"
                            className={classes.headText}
                          >
                            Status
                          </Typography>
                        </TableCell>
                      </ThemeProvider>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {songList !== null ? (
                      dataContract?.length > 0 ? (
                        dataContract.map(customer => (
                          <TableRow hover key={customer.contract_id}>
                            <TableCell>
                              <Typography
                                variant="subtitle2"
                                className={classes.bodyTextBlack}
                              >
                                {customer.contract_number}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography
                                variant="subtitle2"
                                align="left"
                                className={classes.bodyTextBlack}
                              >
                                {customer.start_date !== null
                                  ? customer.start_date.slice(0, -5)
                                  : ""}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography
                                variant="subtitle2"
                                align="left"
                                className={classes.bodyTextBlack}
                              >
                                {customer.end_date !== null
                                  ? customer.end_date.slice(0, -5)
                                  : ""}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography
                                variant="subtitle2"
                                align="right"
                                className={classes.bodyTextBlack}
                              >
                                {customer.publisher_id}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography
                                variant="subtitle2"
                                align="right"
                                className={classes.bodyTextBlack}
                              >
                                {customer.publisher_name}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <StatusChip
                                type={
                                  customer?.status === "Active" && "success"
                                }
                                label={
                                  customer.status === "Non Active"
                                    ? "Inactive"
                                    : customer?.status
                                }
                              />
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={6}>
                            <center>
                              <ThemeProvider theme={theme}>
                                <Typography
                                  variant="h6"
                                  style={{
                                    fontWeight: 400,
                                    color: "#687083",
                                    fontSize: "14px",
                                  }}
                                >
                                  no data
                                </Typography>
                              </ThemeProvider>
                            </center>
                          </TableCell>
                        </TableRow>
                      )
                    ) : (
                      <TableRow>
                        <TableCell colSpan={3}>
                          <center>
                            <ThemeProvider theme={theme}>
                              <Typography
                                variant="h6"
                                style={{
                                  fontWeight: 400,
                                  color: "#687083",
                                  fontSize: "14px",
                                }}
                              >
                                no data
                              </Typography>
                            </ThemeProvider>
                          </center>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}></Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
              <Divider className={classes.divider} />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
              <div>
                <Typography
                  component="h1"
                  variant="h3"
                  className={classes.subTitle}
                >
                  General Share Percentage
                </Typography>
                <ThemeProvider theme={theme}>
                  <p
                    style={{
                      marginTop: "5px",
                      color: "#8f8f8f",
                      fontSize: "14px",
                    }}
                  >
                    Set the percentage between Publisher and Composer
                  </p>
                </ThemeProvider>
              </div>
              <Card
                style={{
                  border: "1px solid #9AA2B1",
                  borderRadius: "6px",
                  boxShadow: "none",
                }}
              >
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6} lg={6} xl={6}>
                      <div
                        style={{
                          marginTop: "10px",
                        }}
                      >
                        <ThemeProvider theme={theme}>
                          <Typography className={classes.label}>
                            Composer Share Percentage{" "}
                            <span className={classes.required}>*</span>
                          </Typography>
                        </ThemeProvider>
                      </div>
                      <div>
                        <TextField
                          id="outlined-basic"
                          name="contractNumber"
                          variant="outlined"
                          placeholder="Share Percentage"
                          disabled={loading}
                          fullWidth={true}
                          margin="dense"
                          value={sharePercentage}
                          type="number"
                          onChange={event => handleChangeSharePercentage(event)}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {"%"}
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6} xl={6}></Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}></Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
              <Divider className={classes.divider} />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
              <div>
                <Typography
                  component="h1"
                  variant="h3"
                  className={classes.subTitle}
                >
                  Song Detail
                </Typography>
                <ThemeProvider theme={theme}>
                  <p
                    style={{
                      marginTop: "5px",
                      color: "#8f8f8f",
                      fontSize: "14px",
                    }}
                  >
                    See song detail&apos;s of composer/author
                  </p>
                </ThemeProvider>
              </div>
              <Card
                style={{
                  border: "1px solid #9AA2B1",
                  borderRadius: "6px",
                  boxShadow: "none",
                }}
              >
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <ThemeProvider theme={theme}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={classes.headText}
                          >
                            Song
                          </Typography>
                        </TableCell>

                        <TableCell align="right">
                          <Typography
                            variant="subtitle2"
                            className={classes.headText}
                          >
                            Composer/Author
                          </Typography>
                        </TableCell>
                      </ThemeProvider>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {songList !== null ? (
                      songList?.length > 0 ? (
                        songList.map((customer, index) => (
                          <TableRow
                            hover
                            key={`${index}`}
                            onClick={() =>
                              history.push({
                                pathname: `/admin/parameter/lagu/edit/${customer.song_id}`,
                                state: {
                                  customer: customer,
                                },
                              })
                            }
                            style={{ cursor: "pointer" }}
                          >
                            <TableCell>
                              <Typography
                                variant="subtitle2"
                                className={classes.bodyTextBlack}
                              >
                                {customer.song_title}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography
                                variant="subtitle2"
                                align="right"
                                className={classes.bodyTextBlack}
                              >
                                {customer.name}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={4}>
                            <center>
                              <ThemeProvider theme={theme}>
                                <Typography
                                  variant="h6"
                                  style={{
                                    fontWeight: 400,
                                    color: "#687083",
                                    fontSize: "14px",
                                  }}
                                >
                                  no data
                                </Typography>
                              </ThemeProvider>
                            </center>
                          </TableCell>
                        </TableRow>
                      )
                    ) : (
                      <TableRow>
                        <TableCell colSpan={3}>
                          <center>
                            <ThemeProvider theme={theme}>
                              <Typography
                                variant="h6"
                                style={{
                                  fontWeight: 400,
                                  color: "#687083",
                                  fontSize: "14px",
                                }}
                              >
                                no data
                              </Typography>
                            </ThemeProvider>
                          </center>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}></Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
              <Divider className={classes.divider} />
            </Grid>
          </Grid>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              md={8}
              lg={8}
              xl={8}
              style={{
                display: "flex",
                justifyContent: "end",
              }}
            >
              <div
                style={{
                  display: "flex",
                }}
              >
                <Button
                  className={classes.btnSubmit}
                  onClick={handleButtonSimpan}
                  disabled={submitting || loading}
                  style={{
                    backgroundColor: submitting || loading ? "grey" : getColor,
                  }}
                >
                  <ThemeProvider theme={theme}>
                    <Typography
                      component="span"
                      style={{
                        fontSize: "14px",
                        lineHeight: "20px",
                        fontWeight: "500",
                        fontStyle: "normal",
                        textTransform: "none",
                      }}
                    >
                      Save
                    </Typography>
                  </ThemeProvider>
                </Button>
                <Button
                  className={classes.btnPreview}
                  variant="outlined"
                  onClick={() => history.goBack()}
                  style={{
                    backgroundColor: getColor,
                  }}
                >
                  <ThemeProvider theme={theme}>
                    <Typography
                      component="span"
                      style={{
                        fontSize: "14px",
                        lineHeight: "20px",
                        fontWeight: "500",
                        fontStyle: "normal",
                        textTransform: "none",
                      }}
                    >
                      Back
                    </Typography>
                  </ThemeProvider>
                </Button>
              </div>
            </Grid>
          </Grid>
        </form>
        <Dialog
          open={openModalSelectPerformer}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            {statusDialog === "add" ? <>{"Add"}</> : <>{"Edit"}</>} Alias Name
          </DialogTitle>
          <DialogContent dividers>
            <div
              style={{
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              <ThemeProvider theme={theme}>
                <Typography className={classes.label}>Alias Name</Typography>
              </ThemeProvider>
            </div>
            <div
              style={{
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              {statusDialog === "add" ? (
                <>
                  <TextField
                    id="outlined-basic"
                    placeholder="Alias Name"
                    variant="outlined"
                    fullWidth={true}
                    name="aliasName"
                    onChange={event => handleChange(event)}
                    value={aliasName}
                  />
                </>
              ) : (
                <>
                  <TextField
                    id="outlined-basic"
                    placeholder="Alias Name"
                    variant="outlined"
                    fullWidth={true}
                    name="aliasName"
                    onChange={event => handleChange(event)}
                    value={aliasName}
                  />
                </>
              )}
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => handleResetDialogPerformer()}
              className={classes.btnDialog}
              style={{
                backgroundColor: getColor,
              }}
            >
              <ThemeProvider theme={theme}>
                <Typography
                  style={{
                    textTransform: "none",
                    color: "#FFFFFF",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "20px",
                  }}
                >
                  Cancel
                </Typography>
              </ThemeProvider>
            </Button>
            {statusDialog === "add" ? (
              <>
                <Button
                  onClick={e => onClickDialogAlias(e)}
                  className={classes.btnDialog}
                  style={{
                    backgroundColor: getColor,
                  }}
                >
                  <ThemeProvider theme={theme}>
                    <Typography
                      style={{
                        textTransform: "none",
                        color: "#FFFFFF",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "20px",
                      }}
                    >
                      Add Alias Name
                    </Typography>
                  </ThemeProvider>
                </Button>
              </>
            ) : (
              <>
                <Button
                  onClick={e => onClickUpdateDialogAlias(e)}
                  className={classes.btnDialog}
                  style={{
                    backgroundColor: getColor,
                  }}
                >
                  <ThemeProvider theme={theme}>
                    <Typography
                      style={{
                        textTransform: "none",
                        color: "#FFFFFF",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "20px",
                      }}
                    >
                      Update
                    </Typography>
                  </ThemeProvider>
                </Button>
              </>
            )}
          </DialogActions>
        </Dialog>
        <Dialog open={confirmDel} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Confirm Delete</DialogTitle>
          <DialogContent dividers>
            <div
              style={{
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              <ThemeProvider theme={theme}>
                <Typography className={classes.label}>
                  Are You Sure want to Delete {delLabel} ?
                </Typography>
              </ThemeProvider>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setConfirmDel(false)}
              className={classes.btnDialog}
              style={{
                backgroundColor: getColor,
              }}
            >
              <ThemeProvider theme={theme}>
                <Typography
                  style={{
                    textTransform: "none",
                    color: "#FFFFFF",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "20px",
                  }}
                >
                  Cancel
                </Typography>
              </ThemeProvider>
            </Button>
            <Button
              onClick={e => goDelete(e)}
              className={classes.btnDialog}
              style={{
                backgroundColor: getColor,
              }}
            >
              <ThemeProvider theme={theme}>
                <Typography
                  style={{
                    textTransform: "none",
                    color: "#FFFFFF",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "20px",
                  }}
                >
                  Yes
                </Typography>
              </ThemeProvider>
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Page>
  );
}

export default EditPencipta;
