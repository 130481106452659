export const month = [
  {
    id: 0,
    name: "None",
  },
  {
    id: 1,
    name: "January",
  },
  {
    id: 2,
    name: "February",
  },
  {
    id: 3,
    name: "March",
  },
  {
    id: 4,
    name: "April",
  },
  {
    id: 5,
    name: "May",
  },
  {
    id: 6,
    name: "June",
  },
  {
    id: 7,
    name: "July",
  },
  {
    id: 8,
    name: "August",
  },
  {
    id: 9,
    name: "September",
  },
  {
    id: 10,
    name: "October",
  },
  {
    id: 11,
    name: "November",
  },
  {
    id: 12,
    name: "Desember",
  },
];
export const monthData = [
  { id: 1, shortName: "Jan", fullName: "January" },
  { id: 2, shortName: "Feb", fullName: "February" },
  { id: 3, shortName: "Mar", fullName: "March" },
  { id: 4, shortName: "Apr", fullName: "April" },
  { id: 5, shortName: "May", fullName: "May" },
  { id: 6, shortName: "Jun", fullName: "June" },
  { id: 7, shortName: "Jul", fullName: "July" },
  { id: 8, shortName: "Aug", fullName: "August" },
  { id: 9, shortName: "Sep", fullName: "September" },
  { id: 10, shortName: "Oct", fullName: "October" },
  { id: 11, shortName: "Nov", fullName: "November" },
  { id: 12, shortName: "Dec", fullName: "December" },
];
