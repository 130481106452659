import { useMediaQuery, useTheme } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {
  ThemeProvider,
  createTheme,
  makeStyles,
} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { useState } from "react";
import bgEllipse from "../../../assets/background/background_contact_ellipse.webp";
import bgShade from "../../../assets/background/background_contact_shade.webp";
import iconEmail from "../../../assets/icons/icon_email.svg";
import iconMobilePhone from "../../../assets/icons/icon_mobile_phone.svg";
import iconPhone from "../../../assets/icons/icon_phone.svg";
import LandingFooter from "../../../components/templates/LandingPage/LandingFooter";
import LandingHero from "../../../components/templates/LandingPage/LandingHero";
import "../../../components/templates/LandingPage/LandingStyle.css";

const interFont = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
  },
  newInput: {
    "&.MuiTextField-root fieldset.MuiOutlinedInput-notchedOutline": {
      border: "0px solid black",
    },
    "&.MuiFormControl-root.MuiTextField-root .MuiOutlinedInput-root": {
      borderRadius: "0px",
      backgroundColor: "white",
    },
  },
  buttonSubmit: {
    color: "white",
    borderRadius: "20px",
    background: "linear-gradient(274.37deg, #FE1A9B -15.6%, #642C8C 101.68%)",
    textTransform: "none",
  },
}));

const Contact = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [dataTemp, setDataTemp] = useState({
    nama: "",

    email: "",
    nohp: "",
    message: "",
  });

  const handleChange = e => {
    setDataTemp({
      ...dataTemp,
      [e?.target?.name]: e?.target?.value,
    });
  };

  const contactList = [
    {
      icon: iconEmail,
      alt: "iconEmail",
      title: "info@asaba.co.id",
    },
    {
      icon: iconPhone,
      alt: "iconPhone",
      title: "(021) 57994700",
    },
    {
      icon: iconMobilePhone,
      alt: "iconMobilePhone",
      title: "0812-9396-9798",
    },
  ];

  const captions = {
    company_name: "PT. ASABA Digital Innotech",
    address: [
      "Ebenezer Building",
      "Jl. Setia Budi Selatan No.1, Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12920",
    ],
    contactList: contactList,
  };

  const heroBackground = () => {
    return (
      <>
        <img
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            objectFit: "cover",
            objectPosition: "left",
          }}
          alt="ellipse-thumbnail"
          src={bgShade}
        />
        <img
          style={{
            position: "absolute",
            width: "400px",
            height: "400px",
            top: "30%",
            left: "40%",
          }}
          alt="ellipse-thumbnail"
          src={bgEllipse}
        />
      </>
    );
  };

  const heroContent = () => {
    return (
      <ThemeProvider theme={interFont}>
        <Typography
          style={{
            fontWeight: 800,
            fontSize: isMobile ? "60px" : "100px",
            color: "white",
            maxWidth: "430px",
          }}
        >
          Fill The Form
        </Typography>
        <Typography
          style={{
            fontWeight: 500,
            color: "white",
            fontSize: isMobile ? "24px" : "50px",
            marginTop: "20px",
          }}
        >
          For Contact Us!
        </Typography>
      </ThemeProvider>
    );
  };

  return (
    <>
      <LandingHero heroBackground={heroBackground} heroContent={heroContent} />
      <div className="landing-content">
        <div className="form-contact">
          {Object.keys(dataTemp).map((key, index) => {
            const label =
              key === "nama"
                ? "Nama"
                : key === "jabatan"
                  ? "Jabatan"
                  : key === "namaPerusahaan"
                    ? "Nama Perusahaan"
                    : key === "industriPerusahaan"
                      ? "Industri Perusahaan"
                      : key === "bisnisUnit"
                        ? "Bisnis Unit"
                        : key === "service"
                          ? "Service / Solusi apa yang sudah digunakan oleh perusahaan Anda ?"
                          : key === "nohp"
                            ? "No. Handphone (Whatsapp)"
                            : key === "email"
                              ? "Email (example: nico@asaba.co.id)"
                              : key === "namaRef"
                                ? "Dengan mengisi form terlampir Anda bersedia memberikan data dan dapat kami hubungi untuk informasi lebih lanjut"
                                : key === "message"
                                  ? "Message"
                                  : "";
            const placeholder =
              key === "nama"
                ? "Enter Name"
                : key === "jabatan"
                  ? "Isi Jabatan"
                  : key === "namaPerusahaan"
                    ? "Isi Nama Perusahaan"
                    : key === "industriPerusahaan"
                      ? "Isi Industri Perusahaan"
                      : key === "bisnisUnit"
                        ? "Isi Bisnis Unit"
                        : key === "service"
                          ? "Isi Service / Solusi apa yang sudah digunakan oleh perusahaan Anda ?"
                          : key === "nohp"
                            ? "Enter No. Handphone (Whatsapp)"
                            : key === "email"
                              ? "Enter Email (example: nico@asaba.co.id)"
                              : key === "namaRef"
                                ? "Isi Nama"
                                : key === "message"
                                  ? "Enter your message here"
                                  : "";
            return (
              <div
                key={index}
                style={{
                  marginBottom:
                    index === Object?.keys?.(dataTemp)?.length - 1
                      ? ""
                      : "16px",
                }}
              >
                <div
                  style={{
                    color: "white",
                    marginBottom: "6px",
                  }}
                >
                  {label}
                </div>
                <div>
                  <TextField
                    className={classes.newInput}
                    id="outlined-size-small"
                    value={dataTemp[key]}
                    variant="outlined"
                    size="small"
                    fullWidth={true}
                    placeholder={placeholder}
                    onChange={e => handleChange(e)}
                    name={key}
                  />
                </div>
              </div>
            );
          })}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "40px",
          }}
        >
          <Button variant="contained" className={classes.buttonSubmit}>
            Submit
          </Button>
        </div>
      </div>

      <LandingFooter type="contact" captions={captions} />
    </>
  );
};

export default Contact;
