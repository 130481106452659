import {
  Card,
  CardActions,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import PerfectScrollbar from "react-perfect-scrollbar";
import TableEditBar from "../../../../components/molecules/Table/TableEditBar";
import { hardBaseUrl } from "../../../../services/urlConstant";

const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

function Results({
  className,
  pageSizePagination,
  page,
  totalData,
  handleChangePage,
  items,
  ...rest
}) {
  const [selectedCustomers] = useState([]);
  const [getColor, setGetColor] = useState("");

  useEffect(() => {
    const getMainDsp = async () => {
      try {
        const token = localStorage.getItem("token");
        const url = `${hardBaseUrl}/me`;
        const res = await axios.get(url, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        setGetColor(res.data.data.publisher.theme_color);
      } catch (error) {
        new Error(error);
      }
    };
    getMainDsp();
  }, []);

  const useStyles = makeStyles(theme => ({
    root: {},
    content: {
      padding: 0,
    },
    inner: {
      minWidth: 700,
    },
    nameCell: {
      display: "flex",
      alignItems: "center",
    },
    avatar: {
      height: 42,
      width: 42,
      marginRight: theme.spacing(1),
    },
    actions: {
      padding: theme.spacing(1),
      justifyContent: "flex-end",
    },
    pagination: {
      "& .Mui-selected": {
        backgroundColor: getColor,
        color: "white",
      },
    },
    pageSize: {
      height: "30px",
      borderRadius: "6px",
      border: "1px solid #D1D5DC",
      paddingLeft: "5px",
      paddingRight: "5px",
      marginTop: "5px",
    },
    paginationText: {
      fontSize: "14px",
      fontWeight: "normal",
      fontStyle: "normal",
      color: "#687083",
    },
    headText: {
      fontSize: "12px",
      color: "#687083",
      fontWeight: "bold",
    },
    bodyTextBlack: {
      fontSize: "14px",
      fontWeight: "regular",
      color: "#111827",
    },
    bodyTextGrey: {
      fontSize: "14px",
      fontWeight: "regular",
      color: "#687083",
    },
    tableRow: {
      height: 20,
    },
    conTextBlack: {
      fontSize: "14px",
      fontWeight: "bold",
    },
    dropdown: {
      color: "black",
    },
    activeChip: {
      backgroundColor: "#8DE5AB",
      color: "#34774C",
      fontSize: "14px",
      fontWeight: "500",
    },
    activeChipIndicator: {
      color: "#34774C",
      fontSize: "12px",
    },
    nonActiveChip: {
      backgroundColor: "#D1D5DC",
      color: "#364052",
      fontSize: "14px",
      fontWeight: "500",
    },
    nonActiveChipIndicator: {
      color: "#364052",
      fontSize: "12px",
    },
    tableChip: {
      marginRight: "5px",
      backgroundColor: "#F9FAFB",
      border: "1px solid #D1D5DC",
    },
    btnDetail: {
      backgroundColor: "#111827",
      color: "white",
      width: "30px",
      height: "30px",
      padding: "6px",
      borderRadius: "6px",
    },
    flex: {
      display: "flex",
    },
    mt7: {
      marginTop: "7px",
    },
    ml10: {
      marginLeft: "10px",
    },
  }));

  const classes = useStyles();
  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Card>
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <ThemeProvider theme={theme}>
                      <TableCell>
                        <Typography
                          variant="subtitle2"
                          className={classes.headText}
                        >
                          Due Date
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="subtitle2"
                          className={classes.headText}
                        >
                          Description
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="subtitle2"
                          className={classes.headText}
                        >
                          Value
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="subtitle2"
                          className={classes.headText}
                        >
                          Balance
                        </Typography>
                      </TableCell>
                    </ThemeProvider>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {items?.length > 0 ? (
                    items.map((customer, idx) => {
                      return (
                        <TableRow hover key={`${idx}`}>
                          <ThemeProvider theme={theme}>
                            <TableCell>
                              <Typography
                                variant="subtitle2"
                                className={classes.bodyTextBlack}
                              >
                                {customer?.date}
                              </Typography>
                            </TableCell>
                            <TableCell width={"17%"}>
                              <Typography
                                variant="subtitle2"
                                className={classes.bodyTextBlack}
                              >
                                {customer?.description}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography
                                variant="subtitle2"
                                className={classes.bodyTextBlack}
                                style={{
                                  color:
                                    customer?.mutation_type === "out"
                                      ? "red"
                                      : "green",
                                }}
                              >
                                <NumberFormat
                                  displayType="text"
                                  value={
                                    customer?.mutation_type === "out"
                                      ? customer?.value_out
                                      : customer.value_in
                                  }
                                  thousandSeparator={true}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                  prefix={`${
                                    customer?.mutation_type === "out"
                                      ? "- "
                                      : "+ "
                                  }Rp`}
                                />
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography
                                variant="subtitle2"
                                className={classes.bodyTextBlack}
                              >
                                <NumberFormat
                                  displayType="text"
                                  value={customer?.balance}
                                  thousandSeparator={true}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                  prefix="Rp "
                                />
                              </Typography>
                            </TableCell>
                          </ThemeProvider>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={6}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <Typography variant="subtitle1">
                            No data available
                          </Typography>
                        </div>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <Grid
            alignContent="space-between"
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <div className={classes.flex}>
                <div className={classes.mt7}>
                  <ThemeProvider theme={theme}>
                    <Typography className={classes.paginationText}>
                      Items per page:
                    </Typography>
                  </ThemeProvider>
                </div>{" "}
                <div className={classes.ml10}>{pageSizePagination}</div>
              </div>
            </Grid>

            <Grid item>
              <Pagination
                count={totalData}
                shape="rounded"
                className={classes.pagination}
                page={page}
                onChange={handleChangePage}
              />
            </Grid>
          </Grid>
        </CardActions>
      </Card>
      <TableEditBar selected={selectedCustomers} />
    </div>
  );
}

Results.propTypes = {
  className: PropTypes.string,
  customers: PropTypes.array,
};

Results.defaultProps = {
  customers: [],
};

export default Results;
