/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import { Editor } from "react-draft-wysiwyg";
import "../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import _ from "lodash";
import {
  Container,
  Divider,
  Grid,
  Typography,
  Button,
  Card,
  CardContent,
  Breadcrumbs,
} from "@material-ui/core";
import { Page } from "components";
import axios from "axios";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { hardBaseUrl } from "../../../services/urlConstant";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import Swal from "sweetalert2";
import imageUpload from "../../../assets/img/imageUpload.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  setTentangKamiImage1,
  setTentangKamiImage2,
  setVisiKamiImage1,
  setVisiKamiImage2,
  setPublisherImage1,
  setPublisherImage2,
  setPublisherImage3,
  setPublisherImage4,
  setPublisherImage5,
  setPublisherImage6,
  getTentangKamiText,
  getVisiKamiText,
  getDspName,
  getDspLogo,
} from "../../../constants/landingPage/manajemenWeb";
import CircularProgress from "@material-ui/core/CircularProgress";

const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  results: {
    marginTop: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  btnSubmit: {
    backgroundColor: "black",
    marginLeft: "10px",
    color: "white",
    padding: "10px 28px",
    width: "106px",
    borderRadius: "6px",
    height: "40px",

    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
  btnPreview: {
    backgroundColor: "black",
    color: "white",
    padding: "10px 28px",
    width: "106px",
    borderRadius: "6px",
    height: "40px",

    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
  error: {
    color: "red",
  },
  label: {
    color: "#364052",
    fontSize: "14px",
    fontWeight: 500,
  },
  subTitle: {
    fontSize: "18px",
    fontWeight: 700,
    color: " #111827",
  },
  breadcrumbs: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#111827",
  },
  breadCrumbsActive: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#687083",
  },
}));

const ManajemenWeb = props => {
  const classes = useStyles();
  const history = useHistory();

  const dispatch = useDispatch();

  const tentangKamiImage1 = useSelector(state => {
    return state.manajemenWeb.tentangKamiImage1;
  });

  const tentangKamiImage2 = useSelector(state => {
    return state.manajemenWeb.tentangKamiImage2;
  });

  const visiKamiImage1 = useSelector(state => {
    return state.manajemenWeb.visiKamiImage1;
  });

  const visiKamiImage2 = useSelector(state => {
    return state.manajemenWeb.visiKamiImage2;
  });

  const fotoPublisherImage1 = useSelector(state => {
    return state.manajemenWeb.publisherImage1;
  });

  const fotoPublisherImage2 = useSelector(state => {
    return state.manajemenWeb.publisherImage2;
  });

  const fotoPublisherImage3 = useSelector(state => {
    return state.manajemenWeb.publisherImage3;
  });

  const fotoPublisherImage4 = useSelector(state => {
    return state.manajemenWeb.publisherImage4;
  });

  const fotoPublisherImage5 = useSelector(state => {
    return state.manajemenWeb.publisherImage5;
  });

  const fotoPublisherImage6 = useSelector(state => {
    return state.manajemenWeb.publisherImage6;
  });

  const [textEditor, setTextEditor] = useState(EditorState.createEmpty());

  const [textEditor2, setTextEditor2] = useState(EditorState.createEmpty());
  const [getColor, setGetColor] = useState("");
  const [, setImagesArray] = useState("");

  // Lokal
  const [tentangKamiImage1Local, setTentangKamiImage1Local] = useState(null);
  const [tentangKamiImage2Local, setTentangKamiImage2Local] = useState(null);
  const [visiKamiImage1Local, setVisiKamiImage1Local] = useState(null);
  const [visiKamiImage2Local, setVisiKamiImage2Local] = useState(null);
  const [publisherImage1Local, setPublisherImage1Local] = useState(null);
  const [publisherImage2Local, setPublisherImage2Local] = useState(null);
  const [publisherImage3Local, setPublisherImage3Local] = useState(null);
  const [publisherImage4Local, setPublisherImage4Local] = useState(null);
  const [publisherImage5Local, setPublisherImage5Local] = useState(null);
  const [publisherImage6Local, setPublisherImage6Local] = useState(null);
  const [tentangKamiImage1LocalId, setTentangKamiImage1LocalId] = useState(
    null
  );
  const [tentangKamiImage2LocalId, setTentangKamiImage2LocalId] = useState(
    null
  );
  const [visiKamiImage1LocalId, setVisiKamiImage1LocalId] = useState(null);
  const [visiKamiImage2LocalId, setVisiKamiImage2LocalId] = useState(null);
  const [publisherImage1LocalId, setPublisherImage1LocalId] = useState(null);
  const [publisherImage2LocalId, setPublisherImage2LocalId] = useState(null);
  const [publisherImage3LocalId, setPublisherImage3LocalId] = useState(null);
  const [publisherImage4LocalId, setPublisherImage4LocalId] = useState(null);
  const [publisherImage5LocalId, setPublisherImage5LocalId] = useState(null);
  const [publisherImage6LocalId, setPublisherImage6LocalId] = useState(null);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [arrDelImage, setArrDelImage] = useState([]);

  useEffect(() => {
    const fetchContent = async () => {
      try {
        let baseOfUrl = hardBaseUrl;
        let theToken = localStorage.getItem("token");

        const url = baseOfUrl + "/admin/web-management";

        const options = {
          headers: {
            Authorization: "Bearer " + theToken,
          },
        };
        const response = await axios.get(url, options);

        const blocksFromHtmla = htmlToDraft(response.data.data.about_us);
        let { contentBlocks, entityMap } = blocksFromHtmla;
        const contentStatea = ContentState.createFromBlockArray(
          contentBlocks,
          entityMap
        );
        const editorStatea = EditorState.createWithContent(contentStatea);

        const blocksFromHtmlb = htmlToDraft(response.data.data.our_vision);
        let contentBlocksb = blocksFromHtmlb.contentBlocks;
        let entityMapb = blocksFromHtmlb.entityMap;
        const contentStateb = ContentState.createFromBlockArray(
          contentBlocksb,
          entityMapb
        );
        const editorStateb = EditorState.createWithContent(contentStateb);
        setTextEditor(editorStatea);
        setTextEditor2(editorStateb);

        let arrImg = response.data.data.images;
        let imagesOfAbout = await _.filter(arrImg, function(o) {
          return o.type === "about_us";
        });

        if (arrImg !== null) {
          setTentangKamiImage1Local(imagesOfAbout[0].image_name);
          setTentangKamiImage2Local(imagesOfAbout[1].image_name);
          setTentangKamiImage1LocalId(imagesOfAbout[0].web_management_image_id);
          setTentangKamiImage2LocalId(imagesOfAbout[1].web_management_image_id);
        }

        if (arrImg !== null) {
          let imagesOfVisi = await _.filter(arrImg, function(o) {
            return o.type === "our_vision";
          });
          setVisiKamiImage1Local(imagesOfVisi[0].image_name);
          setVisiKamiImage2Local(imagesOfVisi[1].image_name);
          setVisiKamiImage1LocalId(imagesOfVisi[0].web_management_image_id);
          setVisiKamiImage2LocalId(imagesOfVisi[1].web_management_image_id);
        }

        if (arrImg !== null) {
          let imagesOfPublisher = await _.filter(arrImg, function(o) {
            return o.type === "publisher";
          });
          setPublisherImage1Local(imagesOfPublisher[0].image_name);
          setPublisherImage2Local(imagesOfPublisher[1].image_name);
          setPublisherImage3Local(imagesOfPublisher[2].image_name);
          setPublisherImage4Local(imagesOfPublisher[3].image_name);
          setPublisherImage5Local(imagesOfPublisher[4].image_name);
          setPublisherImage6Local(imagesOfPublisher[5].image_name);

          setPublisherImage1LocalId(
            imagesOfPublisher[0].web_management_image_id
          );
          setPublisherImage2LocalId(
            imagesOfPublisher[1].web_management_image_id
          );
          setPublisherImage3LocalId(
            imagesOfPublisher[2].web_management_image_id
          );
          setPublisherImage4LocalId(
            imagesOfPublisher[3].web_management_image_id
          );
          setPublisherImage5LocalId(
            imagesOfPublisher[4].web_management_image_id
          );
          setPublisherImage6LocalId(
            imagesOfPublisher[5].web_management_image_id
          );
        }

        if (arrImg !== null) {
          setImagesArray(response.data.data.images);
        }
      } catch (error) {
        if (error.response.status === 404) {
          new Error(error.response.data);
        }
      }
    };
    fetchContent();
  }, []);

  const handleChangeImage = async e => {
    if (
      e.target.files.length &&
      e.target.name === "tentangKamiImage1" &&
      e.target.files[0].size <= 2000000
    ) {
      dispatch(setTentangKamiImage1(e.target.files[0]));
      if (tentangKamiImage1LocalId !== null) {
        let newDelImg = arrDelImage;
        newDelImg.push(tentangKamiImage1LocalId);
        setArrDelImage(newDelImg);
      }
    } else if (
      e.target.files.length &&
      e.target.name === "tentangKamiImage2" &&
      e.target.files[0].size <= 2000000
    ) {
      dispatch(setTentangKamiImage2(e.target.files[0]));
      if (tentangKamiImage2LocalId !== null) {
        let newDelImg = arrDelImage;
        newDelImg.push(tentangKamiImage2LocalId);
        setArrDelImage(newDelImg);
      }
    } else if (
      e.target.files.length &&
      e.target.name === "visiKamiImage1" &&
      e.target.files[0].size <= 2000000
    ) {
      dispatch(setVisiKamiImage1(e.target.files[0]));
      if (visiKamiImage1LocalId !== null) {
        let newDelImg = arrDelImage;
        newDelImg.push(visiKamiImage1LocalId);
        setArrDelImage(newDelImg);
      }
    } else if (
      e.target.files.length &&
      e.target.name === "visiKamiImage2" &&
      e.target.files[0].size <= 2000000
    ) {
      dispatch(setVisiKamiImage2(e.target.files[0]));
      if (visiKamiImage2LocalId !== null) {
        let newDelImg = arrDelImage;
        newDelImg.push(visiKamiImage2LocalId);
        setArrDelImage(newDelImg);
      }
    } else if (
      e.target.files.length &&
      e.target.name === "fotoPublisherImage1" &&
      e.target.files[0].size <= 2000000
    ) {
      dispatch(setPublisherImage1(e.target.files[0]));
      if (publisherImage1LocalId !== null) {
        let newDelImg = arrDelImage;
        newDelImg.push(publisherImage1LocalId);
        setArrDelImage(newDelImg);
      }
    } else if (
      e.target.files.length &&
      e.target.name === "fotoPublisherImage2" &&
      e.target.files[0].size <= 2000000
    ) {
      dispatch(setPublisherImage2(e.target.files[0]));
      if (publisherImage2LocalId !== null) {
        let newDelImg = arrDelImage;
        newDelImg.push(publisherImage2LocalId);
        setArrDelImage(newDelImg);
      }
    } else if (
      e.target.files.length &&
      e.target.name === "fotoPublisherImage3" &&
      e.target.files[0].size <= 2000000
    ) {
      dispatch(setPublisherImage3(e.target.files[0]));
      if (publisherImage3LocalId !== null) {
        let newDelImg = arrDelImage;
        newDelImg.push(publisherImage3LocalId);
        setArrDelImage(newDelImg);
      }
    } else if (
      e.target.files.length &&
      e.target.name === "fotoPublisherImage4" &&
      e.target.files[0].size <= 2000000
    ) {
      dispatch(setPublisherImage4(e.target.files[0]));
      if (publisherImage4LocalId !== null) {
        let newDelImg = arrDelImage;
        newDelImg.push(publisherImage4LocalId);
        setArrDelImage(newDelImg);
      }
    } else if (
      e.target.files.length &&
      e.target.name === "fotoPublisherImage5" &&
      e.target.files[0].size <= 2000000
    ) {
      dispatch(setPublisherImage5(e.target.files[0]));
      if (publisherImage5LocalId !== null) {
        let newDelImg = arrDelImage;
        newDelImg.push(publisherImage5LocalId);
        setArrDelImage(newDelImg);
      }
    } else if (
      e.target.files.length &&
      e.target.name === "fotoPublisherImage6" &&
      e.target.files[0].size <= 2000000
    ) {
      dispatch(setPublisherImage6(e.target.files[0]));
      if (publisherImage6LocalId !== null) {
        let newDelImg = arrDelImage;
        newDelImg.push(publisherImage6LocalId);
        setArrDelImage(newDelImg);
      }
    } else {
      Swal.fire({
        title: "Gagal",
        text: "Ukuran file maksimal 2MB",
        icon: "error",
        confirmButtonText: "Ok",
      });
    }
  };
  const handleSubmit = async () => {
    setLoadingSubmit(true);
    const token = localStorage.getItem("token");
    const rawContentState = convertToRaw(textEditor.getCurrentContent());
    const markup = draftToHtml(rawContentState);

    const rawContentState2 = convertToRaw(textEditor2.getCurrentContent());
    const markup2 = draftToHtml(rawContentState2);
    const data = new FormData();
    const deleteImage = arrDelImage;

    data.append("about_us", markup);
    data.append("our_vision", markup2);

    data.append("about_us_images", tentangKamiImage1.raw);
    data.append("about_us_images", tentangKamiImage2.raw);

    data.append("our_vision_images", visiKamiImage1.raw);
    data.append("our_vision_images", visiKamiImage2.raw);

    data.append("publisher_images", fotoPublisherImage1.raw);
    data.append("publisher_images", fotoPublisherImage2.raw);
    data.append("publisher_images", fotoPublisherImage3.raw);
    data.append("publisher_images", fotoPublisherImage4.raw);
    data.append("publisher_images", fotoPublisherImage5.raw);
    data.append("publisher_images", fotoPublisherImage6.raw);

    data.append("delete_img_id", JSON.stringify(deleteImage));

    const url = `${hardBaseUrl}/admin/web-management`;
    axios
      .post(url, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      })
      .then(res => {
        setLoadingSubmit(false);
        if (
          res.data.message === "success" &&
          res.data.meta.http_status === 200
        ) {
          Swal.fire({
            icon: "success",
            title: "Sukses",
            text: "Tambah Data berhasil.",
          }).then(result => {
            if (result.isConfirmed === true) {
              history.go(0);
            }
          });
        }
      })
      .catch(() => {
        setLoadingSubmit(false);
      });
  };

  const handlePreview = async () => {
    props.history.push("/admin/manajemen-web/preview");
  };

  const rawContentState = convertToRaw(textEditor.getCurrentContent());
  const markup = draftToHtml(rawContentState);
  dispatch(getTentangKamiText(markup));

  const rawContentState2 = convertToRaw(textEditor2.getCurrentContent());
  const markup2 = draftToHtml(rawContentState2);
  dispatch(getVisiKamiText(markup2));

  const getDspInfo = () => {
    const token = localStorage.getItem("token");

    const url = `${hardBaseUrl}/me`;

    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(res => {
        if (res.data.data.publisher !== null) {
          dispatch(getDspName(res.data.data.publisher.name));
          dispatch(getDspLogo(res.data.data.publisher.profile_image));
        }
      })
      .catch(err => {
        new Error(err);
      });
  };

  const getMainDsp = async () => {
    try {
      const token = localStorage.getItem("token");
      const url = `${hardBaseUrl}/me`;
      const res = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (res.data.data.publisher !== null) {
        setGetColor(res.data.data.publisher.theme_color);
      }
    } catch (err) {
      new Error(err);
    }
  };
  useEffect(() => {
    getDspInfo();
    getMainDsp();
  }, []);

  return (
    <Page className={classes.root} title="Manajemen Web">
      <Container maxWidth={false}>
        <Grid
          alignItems="flex-end"
          container
          justifyContent="space-between"
          spacing={3}
        >
          <Grid item>
            <ThemeProvider theme={theme}>
              <Typography
                style={{
                  fontSize: "24px",
                  fontWeight: "700",
                  lineHeight: "32px",
                }}
              >
                {"Manajemen Web"}
              </Typography>
            </ThemeProvider>
          </Grid>
          <Grid item>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography className={classes.breadCrumbs}>Home</Typography>

              <Typography className={classes.breadCrumbsActive}>
                {"Manajemen Web"}
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>

        <Divider className={classes.divider} />

        <Grid container>
          <div>
            {/* <img
            src={localStorage.getItem("tentangKamiImage1")}
            /> */}
            <ThemeProvider theme={theme}>
              <Typography
                component="h1"
                variant="h3"
                className={classes.subTitle}
              >
                Tentang Kami
              </Typography>
            </ThemeProvider>
            <ThemeProvider theme={theme}>
              <p
                style={{
                  marginTop: "5px",
                  color: "#8f8f8f",
                  fontSize: "14px",
                }}
              >
                filestur untuk mengatur halaman tentang kami di Publisher,
                dengan informasi foto dan isi konten.
              </p>
              {/* <div>{ReactHtmlParser(markup)}</div> */}
            </ThemeProvider>
          </div>
        </Grid>

        <Grid container>
          <Grid item xs={9} md={9} sm={9} lg={9} xl={9}>
            <Card
              style={{
                border: "1px solid #9AA2B1",
                borderRadius: "6px",
                boxShadow: "none",
              }}
            >
              <CardContent>
                <ThemeProvider theme={theme}>
                  <Typography>
                    <label htmlFor="logo" className={classes.label}>
                      Foto
                    </label>
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      marginTop: "5px",
                    }}
                  >
                    <div>
                      <label htmlFor="upload-button1">
                        {tentangKamiImage1.preview ? (
                          <img
                            src={tentangKamiImage1.preview}
                            alt="dummysrs"
                            style={{
                              width: "162px",
                              height: "161.75px",
                            }}
                          />
                        ) : (
                          <>
                            {tentangKamiImage1Local !== null ? (
                              <img
                                src={tentangKamiImage1Local}
                                alt="dummysas"
                                style={{
                                  width: "162px",
                                  height: "161.75px",
                                }}
                              />
                            ) : (
                              <img
                                src={imageUpload}
                                alt="dummya"
                                style={{
                                  width: "162px",
                                  height: "161.75px",
                                }}
                              />
                            )}
                          </>
                        )}
                      </label>
                      <input
                        type="file"
                        name="tentangKamiImage1"
                        id="upload-button1"
                        style={{ display: "none" }}
                        accept="image/png, image/gif, image/jpeg"
                        onChange={e => handleChangeImage(e)}
                      />
                    </div>
                    <div
                      style={{
                        marginLeft: "20px",
                      }}
                    >
                      <label htmlFor="upload-button2">
                        {tentangKamiImage2.preview ? (
                          <img
                            src={tentangKamiImage2.preview}
                            alt="dummy"
                            style={{
                              width: "162px",
                              height: "161.75px",
                            }}
                          />
                        ) : (
                          <>
                            {tentangKamiImage2Local !== null ? (
                              <img
                                src={tentangKamiImage2Local}
                                alt="dummysas"
                                style={{
                                  width: "162px",
                                  height: "161.75px",
                                }}
                              />
                            ) : (
                              <img
                                src={imageUpload}
                                alt="dummya"
                                style={{
                                  width: "162px",
                                  height: "161.75px",
                                }}
                              />
                            )}
                          </>
                        )}
                      </label>
                      <input
                        type="file"
                        id="upload-button2"
                        name="tentangKamiImage2"
                        style={{ display: "none" }}
                        onChange={e => handleChangeImage(e)}
                        accept="image/png, image/gif, image/jpeg"
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      marginTop: "10px",
                    }}
                  >
                    <Typography>
                      <label htmlFor="logo" className={classes.label}>
                        Isi
                      </label>
                    </Typography>

                    <div
                      style={{
                        marginTop: "5px",
                      }}
                    >
                      <Editor
                        editorState={textEditor}
                        wrapperClassName="wrapper-class"
                        editorClassName="editor-class input_description"
                        editorStyle={{
                          height: "30vH",
                          backgroundColor: "white",
                          paddingLeft: "10px",
                          paddingRight: "10px",
                        }}
                        toolbarClassName="toolbar-class"
                        wrapperStyle={{
                          border: "1px solid #ccc",
                          paddingLeft: 0,
                          paddingRight: 0,
                        }}
                        toolbar={{
                          options: [
                            "inline",
                            "blockType",
                            "fontSize",
                            "list",
                            "textAlign",
                            "colorPicker",
                            "link",
                            "remove",
                            "history",
                          ],
                        }}
                        onEditorStateChange={setTextEditor}
                      />
                    </div>
                  </div>
                </ThemeProvider>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={3} md={3} sm={3} lg={3} xl={3}></Grid>
        </Grid>

        <div
          style={{
            marginTop: "20px",
          }}
        >
          <Grid container>
            <div>
              <ThemeProvider theme={theme}>
                <Typography
                  component="h1"
                  variant="h3"
                  className={classes.subTitle}
                >
                  Visi Kami
                </Typography>
              </ThemeProvider>
              <ThemeProvider theme={theme}>
                <p
                  style={{
                    marginTop: "5px",
                    color: "#8f8f8f",
                    fontSize: "14px",
                  }}
                >
                  Fitur ini untuk mengatur informasi berupa visi dari Publisher
                  di halaman tentang kami.
                </p>
              </ThemeProvider>
            </div>
          </Grid>

          <Grid container>
            <Grid item xs={9} md={9} sm={9} lg={9} xl={9}>
              <Card
                style={{
                  border: "1px solid #9AA2B1",
                  borderRadius: "6px",
                  boxShadow: "none",
                }}
              >
                <CardContent>
                  <ThemeProvider theme={theme}>
                    <Typography>
                      <label htmlFor="logo" className={classes.label}>
                        Foto
                      </label>
                    </Typography>
                    <div
                      style={{
                        display: "flex",
                        marginTop: "5px",
                      }}
                    >
                      <div>
                        <label htmlFor="upload-button3">
                          {visiKamiImage1.preview ? (
                            <img
                              src={visiKamiImage1.preview}
                              alt="dummy"
                              style={{
                                width: "162px",
                                height: "161.75px",
                              }}
                            />
                          ) : (
                            <>
                              {visiKamiImage1Local !== null ? (
                                <img
                                  src={visiKamiImage1Local}
                                  alt="dummysas"
                                  style={{
                                    width: "162px",
                                    height: "161.75px",
                                  }}
                                />
                              ) : (
                                <img
                                  src={imageUpload}
                                  alt="dummya"
                                  style={{
                                    width: "162px",
                                    height: "161.75px",
                                  }}
                                />
                              )}
                            </>
                          )}
                        </label>
                        <input
                          type="file"
                          id="upload-button3"
                          name="visiKamiImage1"
                          style={{ display: "none" }}
                          onChange={e => handleChangeImage(e)}
                          accept="image/png, image/gif, image/jpeg"
                        />
                      </div>
                      <div
                        style={{
                          marginLeft: "20px",
                        }}
                      >
                        <label htmlFor="upload-button4">
                          {visiKamiImage2.preview ? (
                            <img
                              src={visiKamiImage2.preview}
                              alt="dummy"
                              style={{
                                width: "162px",
                                height: "161.75px",
                              }}
                            />
                          ) : (
                            <>
                              {visiKamiImage2Local !== null ? (
                                <img
                                  src={visiKamiImage2Local}
                                  alt="dummysas"
                                  style={{
                                    width: "162px",
                                    height: "161.75px",
                                  }}
                                />
                              ) : (
                                <img
                                  src={imageUpload}
                                  alt="dummya"
                                  style={{
                                    width: "162px",
                                    height: "161.75px",
                                  }}
                                />
                              )}
                            </>
                          )}
                        </label>
                        <input
                          type="file"
                          id="upload-button4"
                          name="visiKamiImage2"
                          style={{ display: "none" }}
                          onChange={e => handleChangeImage(e)}
                          accept="image/png, image/gif, image/jpeg"
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        marginTop: "10px",
                      }}
                    >
                      <Typography>
                        <label htmlFor="logo" className={classes.label}>
                          Isi
                        </label>
                      </Typography>

                      <div
                        style={{
                          marginTop: "5px",
                        }}
                      >
                        <Editor
                          editorState={textEditor2}
                          wrapperClassName="wrapper-class"
                          editorClassName="editor-class input_description"
                          editorStyle={{
                            height: "30vH",
                            backgroundColor: "white",
                            paddingLeft: "10px",
                            paddingRight: "10px",
                          }}
                          toolbarClassName="toolbar-class"
                          wrapperStyle={{
                            border: "1px solid #ccc",
                            paddingLeft: 0,
                            paddingRight: 0,
                          }}
                          toolbar={{
                            options: [
                              "inline",
                              "blockType",
                              "fontSize",
                              "list",
                              "textAlign",
                              "colorPicker",
                              "link",
                              "remove",
                              "history",
                            ],
                          }}
                          onEditorStateChange={setTextEditor2}
                        />
                      </div>
                    </div>
                  </ThemeProvider>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={3} md={3} sm={3} lg={3} xl={3}></Grid>
          </Grid>
        </div>

        <div
          style={{
            marginTop: "20px",
          }}
        >
          <Grid container>
            <div>
              <ThemeProvider theme={theme}>
                <Typography
                  component="h1"
                  variant="h3"
                  className={classes.subTitle}
                >
                  Foto Publisher
                </Typography>
              </ThemeProvider>
              <ThemeProvider theme={theme}>
                <p
                  style={{
                    marginTop: "5px",
                    color: "#8f8f8f",
                    fontSize: "14px",
                  }}
                >
                  Fitur ini untuk mengatur konten foto publisher di halaman
                  tentang kami.
                </p>
              </ThemeProvider>
            </div>
          </Grid>

          <Grid container>
            <Grid item xs={9} md={9} sm={9} lg={9} xl={9}>
              <Card
                style={{
                  border: "1px solid #9AA2B1",
                  borderRadius: "6px",
                  boxShadow: "none",
                }}
              >
                <CardContent>
                  <ThemeProvider theme={theme}>
                    <div
                      style={{
                        display: "flex",
                      }}
                    >
                      <div>
                        <label htmlFor="upload-button5">
                          {fotoPublisherImage1.preview ? (
                            <img
                              src={fotoPublisherImage1.preview}
                              alt="dummy"
                              style={{
                                width: "162px",
                                height: "161.75px",
                              }}
                            />
                          ) : (
                            <>
                              {publisherImage1Local !== null ? (
                                <img
                                  src={publisherImage1Local}
                                  alt="dummysas"
                                  style={{
                                    width: "162px",
                                    height: "161.75px",
                                  }}
                                />
                              ) : (
                                <img
                                  src={imageUpload}
                                  alt="dummya"
                                  style={{
                                    width: "162px",
                                    height: "161.75px",
                                  }}
                                />
                              )}
                            </>
                          )}
                        </label>
                        <input
                          type="file"
                          id="upload-button5"
                          name="fotoPublisherImage1"
                          style={{ display: "none" }}
                          onChange={e => handleChangeImage(e)}
                          accept="image/png, image/gif, image/jpeg"
                        />
                      </div>
                      <div
                        style={{
                          marginLeft: "20px",
                        }}
                      >
                        <label htmlFor="upload-button6">
                          {fotoPublisherImage2.preview ? (
                            <img
                              src={fotoPublisherImage2.preview}
                              alt="dummy"
                              style={{
                                width: "162px",
                                height: "161.75px",
                              }}
                            />
                          ) : (
                            <>
                              {publisherImage2Local !== null ? (
                                <img
                                  src={publisherImage2Local}
                                  alt="dummysas"
                                  style={{
                                    width: "162px",
                                    height: "161.75px",
                                  }}
                                />
                              ) : (
                                <img
                                  src={imageUpload}
                                  alt="dummya"
                                  style={{
                                    width: "162px",
                                    height: "161.75px",
                                  }}
                                />
                              )}
                            </>
                          )}
                        </label>
                        <input
                          type="file"
                          id="upload-button6"
                          name="fotoPublisherImage2"
                          style={{ display: "none" }}
                          onChange={e => handleChangeImage(e)}
                          accept="image/png, image/gif, image/jpeg"
                        />
                      </div>

                      <div
                        style={{
                          marginLeft: "20px",
                        }}
                      >
                        <label htmlFor="upload-button7">
                          {fotoPublisherImage3.preview ? (
                            <img
                              src={fotoPublisherImage3.preview}
                              alt="dummy"
                              style={{
                                width: "162px",
                                height: "161.75px",
                              }}
                            />
                          ) : (
                            <>
                              {publisherImage3Local !== null ? (
                                <img
                                  src={publisherImage3Local}
                                  alt="dummysas"
                                  style={{
                                    width: "162px",
                                    height: "161.75px",
                                  }}
                                />
                              ) : (
                                <img
                                  src={imageUpload}
                                  alt="dummya"
                                  style={{
                                    width: "162px",
                                    height: "161.75px",
                                  }}
                                />
                              )}
                            </>
                          )}
                        </label>
                        <input
                          type="file"
                          name="fotoPublisherImage3"
                          id="upload-button7"
                          style={{ display: "none" }}
                          onChange={e => handleChangeImage(e)}
                          accept="image/png, image/gif, image/jpeg"
                        />
                      </div>

                      <div
                        style={{
                          marginLeft: "20px",
                        }}
                      >
                        <label htmlFor="upload-button8">
                          {fotoPublisherImage4.preview ? (
                            <img
                              src={fotoPublisherImage4.preview}
                              alt="dummy"
                              style={{
                                width: "162px",
                                height: "161.75px",
                              }}
                            />
                          ) : (
                            <>
                              {publisherImage4Local !== null ? (
                                <img
                                  src={publisherImage4Local}
                                  alt="dummysas"
                                  style={{
                                    width: "162px",
                                    height: "161.75px",
                                  }}
                                />
                              ) : (
                                <img
                                  src={imageUpload}
                                  alt="dummya"
                                  style={{
                                    width: "162px",
                                    height: "161.75px",
                                  }}
                                />
                              )}
                            </>
                          )}
                        </label>
                        <input
                          type="file"
                          id="upload-button8"
                          name="fotoPublisherImage4"
                          style={{ display: "none" }}
                          onChange={e => handleChangeImage(e)}
                          accept="image/png, image/gif, image/jpeg"
                        />
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        marginTop: "20px",
                        marginBottom: "20px",
                      }}
                    >
                      <div>
                        <label htmlFor="upload-button9">
                          {fotoPublisherImage5.preview ? (
                            <img
                              src={fotoPublisherImage5.preview}
                              alt="dummy"
                              style={{
                                width: "162px",
                                height: "161.75px",
                              }}
                            />
                          ) : (
                            <>
                              {publisherImage5Local !== null ? (
                                <img
                                  src={publisherImage5Local}
                                  alt="dummysas"
                                  style={{
                                    width: "162px",
                                    height: "161.75px",
                                  }}
                                />
                              ) : (
                                <img
                                  src={imageUpload}
                                  alt="dummya"
                                  style={{
                                    width: "162px",
                                    height: "161.75px",
                                  }}
                                />
                              )}
                            </>
                          )}
                        </label>
                        <input
                          type="file"
                          id="upload-button9"
                          name="fotoPublisherImage5"
                          style={{ display: "none" }}
                          onChange={e => handleChangeImage(e)}
                          accept="image/png, image/gif, image/jpeg"
                        />
                      </div>
                      <div
                        style={{
                          marginLeft: "20px",
                        }}
                      >
                        <label htmlFor="upload-button10">
                          {fotoPublisherImage6.preview ? (
                            <img
                              src={fotoPublisherImage6.preview}
                              alt="dummy"
                              style={{
                                width: "162px",
                                height: "161.75px",
                              }}
                            />
                          ) : (
                            <>
                              {publisherImage6Local !== null ? (
                                <img
                                  src={publisherImage6Local}
                                  alt="dummysas"
                                  style={{
                                    width: "162px",
                                    height: "161.75px",
                                  }}
                                />
                              ) : (
                                <img
                                  src={imageUpload}
                                  alt="dummya"
                                  style={{
                                    width: "162px",
                                    height: "161.75px",
                                  }}
                                />
                              )}
                            </>
                          )}
                        </label>
                        <input
                          type="file"
                          id="upload-button10"
                          name="fotoPublisherImage6"
                          style={{ display: "none" }}
                          onChange={e => handleChangeImage(e)}
                          accept="image/png, image/gif, image/jpeg"
                        />
                      </div>
                    </div>
                  </ThemeProvider>
                </CardContent>
              </Card>
              <div
                style={{
                  marginTop: "10px",
                }}
              >
                <Button
                  className={classes.btnPreview}
                  onClick={handlePreview}
                  style={{
                    backgroundColor: getColor,
                  }}
                >
                  <ThemeProvider theme={theme}>
                    <Typography
                      style={{
                        textTransform: "none",
                        color: "#FFFFFF",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "20px",
                      }}
                    >
                      Preview
                    </Typography>
                  </ThemeProvider>
                </Button>
                <Button
                  className={classes.btnSubmit}
                  onClick={handleSubmit}
                  style={{
                    backgroundColor: getColor,
                  }}
                >
                  <ThemeProvider theme={theme}>
                    <Typography
                      style={{
                        textTransform: "none",
                        color: "#FFFFFF",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "20px",
                      }}
                    >
                      {loadingSubmit ? (
                        <CircularProgress color="light" size={20} />
                      ) : (
                        "Simpan"
                      )}
                    </Typography>
                  </ThemeProvider>
                </Button>
              </div>
            </Grid>
            <Grid item xs={3} md={3} sm={3} lg={3} xl={3}></Grid>
          </Grid>
        </div>
      </Container>
    </Page>
  );
};

export default ManajemenWeb;
