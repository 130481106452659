import {
  DateTimePicker as BasePicker,
  LocalizationProvider,
  renderTimeViewClock,
} from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment-timezone";

const DateTimePicker = ({
  label,
  value,
  onChange,
  defaultValue,
  minDate,
  maxDate,
  width,
  ...rest
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <BasePicker
        label={label}
        value={value}
        onChange={onChange}
        minDate={minDate || moment().subtract(10, "years")}
        maxDate={maxDate || moment()}
        defaultValue={defaultValue}
        ampm={false}
        format="DD MMM YYYY, HH:mm"
        viewRenderers={{
          hours: renderTimeViewClock,
          minutes: renderTimeViewClock,
        }}
        slotProps={{
          textField: {
            size: "small",
            style: { width: width },
            fullWidth: true,
          },
          field: { clearable: true },
        }}
        {...rest}
      />
    </LocalizationProvider>
  );
};

export default DateTimePicker;
