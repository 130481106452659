export const carisHosts = [
  "nadaku",
  "musica",
  "aquarius",
  "mymusic",
  "argaswarakencanamusik",
  "jagaddhita",
  "epublishing",
  "karyaanakhoki",
  "ciptasimphoniindah",
  "prodigi",
  "alfapustaka",
  "gmpublishing",
  "sci",
  "mahakaryabagus",
  "jk-records",
  "indosemar",
  "27musik",
  "limaduabelas",
  "rpm",
  "gpmi",
  "damarlangit",
  "tbw",
  "harmonidwiselaras",
  "harmoni",
  "jawara",
  "starcipta",
  "logiskreatifpublisherindo",
  "maharpustakanusantara",
];
