import {
  Backdrop,
  Breadcrumbs,
  CircularProgress,
  Container,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  OutlinedInput,
  Typography,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import SearchIcon from "@material-ui/icons/Search";
import { makeStyles } from "@material-ui/styles";
import { useState } from "react";
import { useHistory } from "react-router";
import { ButtonGroupTop } from "../../../components/atoms/Button";
import { Page } from "components";
import Results from "./Results";
const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },

  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  formControl: {},
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  results: {
    marginTop: theme.spacing(3),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  pagination: {
    "& .Mui-selected": {
      backgroundColor: "black",
      color: "white",
    },
  },
  pageSize: {
    height: "30px",
    borderRadius: "6px",
    border: "1px solid #D1D5DC",
    paddingLeft: "5px",
    paddingRight: "5px",
    marginTop: "5px",
  },
  paginationText: {
    fontSize: "14px",
    fontWeight: "normal",
    fontStyle: "normal",
  },
  breadcrumbs: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#111827",
  },
  breadCrumbsActive: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#687083",
  },
  placeholder: {
    "&::placeholder": {
      color: "#9AA2B1",
      fontFamily: ["Inter"].join(","),
    },
  },
}));

function SongClaim() {
  const classes = useStyles();
  const history = useHistory();
  const [searchKey, setSearchKey] = useState("");

  const [loadingResult, setLoadingResult] = useState(false);

  const customers = [];

  const [currentPage, setCurrentPage] = useState(1);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalData] = useState(1);
  const [page, setPage] = useState(1);

  const pagesCount = Math.ceil(totalData / rowsPerPage);

  const handleChangeRowsPerPage = event => {
    event.preventDefault();
    setRowsPerPage(event.target.value);

    setPage(1);
  };

  const pageSizePagination = () => {
    return (
      <div className={classes.pageSize}>
        <select
          style={{
            backgroundColor: "transparent",
            border: "none",
            outline: "none",
            marginTop: "4px",
          }}
          value={rowsPerPage}
          onChange={handleChangeRowsPerPage}
        >
          {[10, 20, 50].map(pageSize => {
            return (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            );
          })}
        </select>
      </div>
    );
  };


  const handleChangeSearch = event => {
    setLoadingResult(true);
    setSearchKey(event.target.value);
  };

  const onChangePage = value => {
    setCurrentPage(value);
    setPage(value);
  };


  const movePage = () => {
    history.push("/admin/publisher/song-history");
  };
  return (
    <Page className={classes.root} title="Song Claim">
      <Backdrop className={classes.backdrop} open={false}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container maxWidth={false}>
        <Grid
          alignItems="flex-end"
          container
          justifyContent="space-between"
          spacing={3}
        >
          <Grid item>
            <ThemeProvider theme={theme}>
              <Typography
                style={{
                  fontSize: "24px",
                  fontWeight: "700",
                  lineHeight: "32px",
                }}
              >
                Song Claim
              </Typography>
            </ThemeProvider>
          </Grid>
          <Grid item>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography className={classes.breadCrumbs}>Home</Typography>

              <Typography className={classes.breadCrumbs}>
                Song Claim
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>

        <Divider className={classes.divider} />
        <div>
          <ButtonGroupTop />
        </div>
        <div
          style={{
            display: "flex",
            marginTop: "16px",
          }}
        >
          <div>
            <FormControl
              fullWidth
              className={classes.margin}
              variant="outlined"
            >
              <OutlinedInput
                placeholder="Search"
                id="outlined-adornment-amount"
                style={{
                  width: "268px",
                  height: "44px",
                  border: "1px solid #D1D5DC",
                  borderRadius: "6px",
                }}
                value={searchKey}
                onChange={event => handleChangeSearch(event)}
                inputProps={{
                  classes: {
                    input: classes.placeholder,
                  },
                }}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon style={{ color: "#9AA2B1" }} />
                  </InputAdornment>
                }
                fullWidth={false}
              />
            </FormControl>
          </div>
        </div>
        <div
          style={{
            marginTop: "24px",
            display: "flex",
          }}
        >
          <div>See Log History Song Claim</div>
          <div>
            <ArrowForwardIcon
              onClick={() => movePage()}
              style={{ color: "#9545EB" }}
            />
          </div>
        </div>
        { }
        {customers && (
          <Results
            className={classes.results}
            items={customers}
            isLoading={loadingResult}
            handleChangePage={onChangePage}
            handleChangeTotalPage={() => console.log("Need To fix")}
            allDataNumber={totalData}
            currentPageNumber={currentPage}
            pageSizePagination={pageSizePagination()}
            totalData={pagesCount}
            page={page}
          />
        )}
      </Container>
    </Page>
  );
}

export default SongClaim;
