import {
  BackupOutlined,
  CheckCircle,
  CheckCircleOutline,
  HighlightOff,
} from "@material-ui/icons";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import axios from "axios";
import {
  ArrayChip,
  AttachmentFilesCard,
  CheckboxInput,
  CustomTable,
  FormLabel,
  ModalError,
  PrimaryButton,
  SearchTextInput,
  SecondaryButton,
  SectionLabel,
  SelectInput,
  StatusChip,
} from "components";
import { InnoTableV2 } from "inno-ui";
import { getTotalPage } from "lib";
import { debounce } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { globalStyles } from "styles";
import { formatFileSize, getErrors } from "utils";
import { hardBaseUrl } from "../../../../services/urlConstant";

export const ModalBulkAdd = ({ open, onClose, onSubmit, loadingButton }) => {
  const classes = globalStyles();
  const token = localStorage.getItem("token");
  const [files, setFiles] = useState([]);
  const buttonDisabled = !files?.length;

  const onDrop = useCallback(acceptedFiles => {
    if (acceptedFiles.length === 1) {
      setFiles(acceptedFiles);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    accept: [".xls", ".xlsx"],
  });

  const handleDownloadTemplate = async () => {
    const url = `${hardBaseUrl}/publisher/transaction-song/template`;
    try {
      const response = await axios.get(url, {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", "template.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      onClose();
      ModalError("Error downloading the template");
    }
  };
  const handleSubmit = () => {
    onSubmit(files);
  };

  const dropzoneStyle = useMemo(() => {
    return isDragActive ? classes?.dragActive : "";
  }, [isDragActive, classes.activeStyle]);

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle>
        <Typography fontSize={16} fontWeight={700}>
          Upload Song
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <div
          {...getRootProps({
            className: `${classes.dragFilesContainer} ${dropzoneStyle}`,
          })}
        >
          <input {...getInputProps()} />
          <BackupOutlined className={classes?.uploadIcon} />
          <Typography>
            {isDragActive
              ? "Drop the files here..."
              : "Drag files or click to upload"}
          </Typography>
        </div>
        <SectionLabel subTitle="File format must be .xlsx" m="8px 0 16px" />
        <PrimaryButton
          label="Download Template"
          onClick={handleDownloadTemplate}
          disabled={loadingButton}
        />
        {files?.length > 0 && (
          <Box my="16px">
            <FormLabel label="Document Attached :" />
            <Grid container direction="column" rowSpacing={1}>
              {files?.map(({ name, file_path, size }, index) => (
                <Grid item key={index}>
                  <AttachmentFilesCard
                    fileName={name}
                    fileSize={formatFileSize(size)}
                    filePath={file_path}
                    tooltipRemove="Remove File"
                    handleRemove={() => setFiles([])}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <SecondaryButton
          onClick={onClose}
          label="Cancel"
          disabled={loadingButton}
        />
        <PrimaryButton
          onClick={handleSubmit}
          label={loadingButton ? "Uploading" : "Upload"}
          disabled={buttonDisabled || loadingButton}
          loading={loadingButton}
        />
      </DialogActions>
    </Dialog>
  );
};

export const ModalAddSong = ({ open, onClose, onSubmit, preload }) => {
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const [loadingPage, setLoadingPage] = useState(false);
  const [selectedSongs, setSelectedSongs] = useState([]);
  const [dataTable, setDataTable] = useState([]);
  const [queryParams, setQueryParams] = useState({
    page: 1,
    size: 10,
    search: "",
  });
  const [tableTotalPage, setTableTotalPage] = useState(1);

  const handleChangeQueryParams = (value, key) => {
    setQueryParams(currentState => ({
      ...currentState,
      ...(key !== "page" && { page: 1 }),
      [key]: value,
    }));
  };
  const handleChangeTypeTableData = (song_id, value) => {
    setDataTable(currentState =>
      currentState.map(item =>
        item.song_id === song_id ? { ...item, type: value } : item
      )
    );
    setSelectedSongs(prev =>
      prev.map(song =>
        song.song_id === song_id ? { ...song, type: value } : song
      )
    );
  };
  const handleChangeCheckboxTable = (event, item) => {
    const { checked } = event.target;
    setSelectedSongs(prev => {
      const newSelectedSong = checked
        ? [...prev, item]
        : prev.filter(selected => selected.song_id !== item.song_id);
      return newSelectedSong;
    });
  };

  const getDataTable = async () => {
    try {
      setLoadingPage(true);
      const res = await axios.get(`${hardBaseUrl}/publisher/transaction-song`, {
        headers,
        params: queryParams,
      });
      const { data, meta } = res?.data;
      const modifiedTable = data?.map(item => ({
        ...item,
        type: "Sync Royalty",
      }));
      setDataTable(modifiedTable || []);
      const pageCount = getTotalPage(meta?.total, queryParams?.size);
      setTableTotalPage(pageCount || 1);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  const debounceDataTable = useCallback(
    debounce(() => {
      getDataTable();
    }, 500),
    [queryParams]
  );
  const handleSubmit = () => {
    onSubmit(selectedSongs);
  };

  useEffect(() => {
    const isSearching = Boolean(queryParams?.search);
    if (isSearching) {
      debounceDataTable();
      return () => {
        debounceDataTable.cancel();
      };
    } else {
      getDataTable();
    }
  }, [queryParams, debounceDataTable]);
  useEffect(() => {
    setSelectedSongs(preload);
  }, [preload]);

  return (
    <Dialog
      fullWidth={true}
      maxWidth="lg"
      open={open}
      onClose={onClose}
      disableEscapeKeyDown={true}
    >
      <DialogContent>
        <SearchTextInput
          fullWidth
          placeholder="Search Song"
          value={queryParams?.search}
          onChange={e => {
            handleChangeQueryParams(e?.target?.value, "search");
          }}
        />
        <Box mt="24px">
          <CustomTable
            columnTable={columnTableAddSong({
              selectedSongs,
              handleChangeCheckboxTable,
              handleChangeTypeTableData,
            })}
            data={dataTable}
            items={dataTable}
            page={queryParams?.page}
            pageSize={queryParams?.size}
            pageCount={tableTotalPage}
            handleChangePage={(_, page) =>
              handleChangeQueryParams(page, "page")
            }
            handleChangePageSize={e =>
              handleChangeQueryParams(e?.target?.value, "size")
            }
            isLoading={loadingPage}
            maxHeight={400}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <SecondaryButton label="Cancel" onClick={onClose} />
        <PrimaryButton
          label="Add"
          onClick={handleSubmit}
          disabled={selectedSongs?.length === 0}
        />
      </DialogActions>
    </Dialog>
  );
};

export const ModalSongResults = ({ open, onClose, preload }) => {
  const dialogRef = useRef();
  const [tableData, setTableData] = useState([]);

  const processPreload = () => {
    if (preload) {
      const invalidSongs = preload?.invalid_songs
        ? preload.invalid_songs?.map(item => ({
            ...item,
            status: "invalid",
          }))
        : [];
      const validSongs = preload?.valid_songs
        ? preload.valid_songs?.map(item => ({
            ...item,
            status: "success",
            message: "Success",
            line_number: "-",
          }))
        : [];
      const modifiedTable = [...invalidSongs, ...validSongs];
      setTableData(modifiedTable);
    }
  };

  useEffect(() => {
    processPreload();
  }, [preload]);

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      open={open}
      onClose={onClose}
      ref={dialogRef}
    >
      <DialogTitle>
        <Typography fontSize={16} fontWeight={700}>
          Results
        </Typography>
      </DialogTitle>
      <DialogContent>
        <InnoTableV2
          isLoading={false}
          columns={columnTableSongResults}
          items={tableData || []}
        />
      </DialogContent>
      <DialogActions>
        <PrimaryButton label="Close" onClick={onClose} />
      </DialogActions>
    </Dialog>
  );
};

export const ModalUploadResults = ({ open, onClose, preload }) => {
  const classes = globalStyles();
  const [tableData, setTableData] = useState([]);

  const processPreload = () => {
    if (preload) {
      const invalidSongs = preload?.invalid_songs
        ? preload?.invalid_songs.map(item => ({
            ...item,
            status: "invalid",
          }))
        : [];
      setTableData(invalidSongs);
    }
  };

  useEffect(() => {
    processPreload();
  }, [preload]);

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
      <DialogContent>
        <Box textAlign="center">
          <CheckCircle className={classes?.modalUploadResultIcon} />
          <Typography fontSize={29} fontWeight={500}>
            Success
          </Typography>
          <Typography fontSize={21} fontWeight={400} mt="16px">
            Document has been uploaded
          </Typography>
        </Box>
        {tableData?.length > 0 && (
          <Box my="16px">
            <InnoTableV2
              isLoading={false}
              columns={columnTableSongResults}
              items={tableData || []}
            />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <PrimaryButton label="Close" onClick={onClose} />
      </DialogActions>
    </Dialog>
  );
};

const columnTableSongResults = [
  {
    name: "song_title",
    title: "Song",
  },
  {
    name: "status",
    title: "Status",
    renderText: item => {
      const success = item === "success";
      return (
        <Box color={success ? "green" : "red"}>
          {success ? <CheckCircleOutline /> : <HighlightOff />}
        </Box>
      );
    },
  },
  {
    name: "all",
    title: "Message",
    renderText: item => (
      <StatusChip
        type={item?.status === "success" ? "success" : "danger"}
        label={item?.message}
      />
    ),
  },
  {
    name: "line_number",
    title: "Line Number",
  },
];
const optionRoyaltyType = [
  { key: "Sync Royalty", value: "Sync Royalty" },
  { key: "Mechanical Royalty", value: "Mechanical Royalty" },
  { key: "Performing", value: "Performing" },
  { key: "Mech-Sync Royalty", value: "Mech-Sync Royalty" },
];
const columnTableAddSong = ({
  selectedSongs,
  handleChangeCheckboxTable,
  handleChangeTypeTableData,
}) => [
  {
    name: "all",
    title: "Action",
    renderText: item => (
      <CheckboxInput
        checked={selectedSongs?.some(
          selected => selected?.song_id === item?.song_id
        )}
        onChange={e => handleChangeCheckboxTable(e, item)}
      />
    ),
  },
  {
    name: "song_title",
    title: "Song",
  },
  {
    name: "all",
    title: "Composer/ Author",
    renderText: item => <ArrayChip list={item?.composer_names} />,
  },
  {
    name: "all",
    title: "Alias Name",
    renderText: item => (
      <ArrayChip
        list={item?.composers?.map(composer => composer?.alias_name)}
      />
    ),
  },
  {
    name: "all",
    title: "Type",
    renderText: item => {
      const selectedSong = selectedSongs.find(
        song => song.song_id === item.song_id
      );
      const typeValue = selectedSong ? selectedSong.type : item.type;
      return (
        <SelectInput
          options={optionRoyaltyType}
          optionKey="key"
          optionLabel="value"
          value={typeValue}
          onChange={e =>
            handleChangeTypeTableData(item?.song_id, e?.target?.value)
          }
        />
      );
    },
    width: 230,
  },
];
