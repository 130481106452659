import "antd";
import "react-redux";
import "../assets/css/layoutNoHeader.css";
export default function LayoutNoHeader(params) {
  const { children } = params;
  return (
    <div className="fill-window" id="fill-window">
      <div className="children" id="children" style={{ marginTop: "0px" }}>
        {children}
      </div>
    </div>
  );
}
