import { Box, Grid, styled, Typography } from "@mui/material";
import { FormLabel, SectionLabel, StatusChip } from "components";
import { InnoTableV2 } from "inno-ui";
import moment from "moment-timezone";

const ContractDetailForm = ({ pageDetail }) => {
  const { contract, songs } = pageDetail;
  const selectedSong = songs?.[0];

  return (
    <Grid container direction="column" rowSpacing={2}>
      <Grid item>
        <SectionLabel
          title="Contract Information"
          subTitle="Contract information that has been made."
        />
        <FormCard>
          <Grid container rowSpacing={2}>
            {contractInformation({ contract })?.map((item, index) => (
              <Grid
                item
                xs={12}
                md={item?.label === "Description" ? 12 : 6}
                key={index}
              >
                <FormLabel label={item?.label} />
                {item?.type === "chip" ? (
                  <StatusChip
                    type={item?.value && "success"}
                    label={item?.value ? "Active" : "Inactive"}
                  />
                ) : (
                  <Typography>{item?.value || "-"}</Typography>
                )}
              </Grid>
            ))}
          </Grid>
        </FormCard>
      </Grid>
      <Grid item>
        <SectionLabel
          title="Song Information"
          subTitle="Detail song information."
        />
        <FormCard>
          <Grid container rowSpacing={1} columnSpacing={1}>
            {songInformation({ selectedSong })?.map((item, index) => (
              <Grid item xs={6} key={index}>
                <FormLabel label={item?.label} />
                <Typography>{item?.value}</Typography>
              </Grid>
            ))}
          </Grid>
        </FormCard>
      </Grid>
      <Grid item>
        <SectionLabel
          title="Composer/Author"
          subTitle="Detail composer/author information."
          mb="16px"
        />
        <InnoTableV2
          isLoading={false}
          columns={columnTable}
          items={selectedSong?.composers || []}
        />
      </Grid>
    </Grid>
  );
};

const getPreloadDate = date =>
  date ? moment(date, "DD-MM-YYYY").format("DD MMM YYYY") : "-";
const FormCard = styled(Box)({
  border: "1px solid #ebebeb",
  borderRadius: "5px",
  padding: "24px",
  marginTop: "16px",
});
const contractInformation = ({ contract }) => [
  {
    label: "Contract Number",
    value: contract?.contract_number,
  },
  {
    label: "Contract Status",
    value: contract?.is_active_flag,
    type: "chip",
  },
  {
    label: "Start Date",
    value: getPreloadDate(contract?.start_date),
  },
  {
    label: "End Date",
    value: getPreloadDate(contract?.end_date),
  },
  {
    label: "Publisher",
    value: contract?.publisher,
  },
  {
    label: "Publisher ID",
    value: contract?.id_publisher,
  },
  {
    label: "Description",
    value: contract?.description,
  },
];
const songInformation = ({ selectedSong }) => [
  {
    label: "Title",
    value: selectedSong?.title || "-",
  },
  {
    label: "ISWC Code",
    value: selectedSong?.iswc_code || "-",
  },
  {
    label: "Ownership",
    value: `${selectedSong?.ownership || 0} %`,
  },
  {
    label: "Performer",
    value: selectedSong?.performer || "-",
  },
];
const columnTable = [
  {
    name: "composer_name",
    title: "Composer/Author Name",
    renderText: item => item || "-",
  },
  {
    name: "iswc_code",
    title: "ISWC Code",
    renderText: item => item || "-",
  },
  {
    name: "ownership_percentage",
    title: "Ownership Percentage",
    renderText: item => `${item || 0} %`,
  },
];

export default ContractDetailForm;
