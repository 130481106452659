import {
  Backdrop,
  Breadcrumbs,
  Button,
  Card,
  CircularProgress,
  Container,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import MaskedInput from "react-text-mask";
import Swal from "sweetalert2";
import "../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import PencilVector from "../../../../assets/img/pencilVector.svg";
import TrashVector from "../../../../assets/img/trashVector.svg";
import { Page } from "components";
import { hardBaseUrl } from "../../../../services/urlConstant";
const fontInter = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});
const theme = createTheme({
  typography: {
    fontFamily: ["Helvetica Neue"].join(","),
  },
});
const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  results: {
    marginTop: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  btnSubmit: {
    backgroundColor: "#1a3040",
    marginRight: "10px",
    color: "white",
    width: "150px",
    "&:hover": {
      backgroundColor: "grey",
      color: "black",
    },
  },
  btnTambahBank: {
    backgroundColor: "white",
    color: "black",
    border: "1px solid black",
    width: "239px",
    height: "40px",
    "&:hover": {
      border: "1px solid grey",
      backgroundColor: "grey",
      color: "white",
    },
  },
  btnPreview: {
    width: "150px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  ib: {
    padding: "6px",
    color: "white",
    backgroundColor: "black",
    borderRadius: "6px",
    "&:focus": {
      color: "white",
      backgroundColor: "black",
    },
    "&:active": {
      boxShadow: "none",
      color: "white",
      backgroundColor: "black",
    },
    "&:hover": {
      color: "white",
      backgroundColor: "black",
      boxShadow: "none",
    },
  },
}));
function TextMaskCustom(props) {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        /\d/,
        "-",
        /[1-9]/,
        /[1-9]/,
        /[1-9]/,
        ".",
        /[1-9]/,
        /[1-9]/,
        /[1-9]/,
        ".",
        /[1-9]/,
        /[1-9]/,
        /[1-9]/,
        "-",
        /[1-9]/,
      ]}
      placeholderChar={"\u2000"}
      showMask
    />
  );
}
function EditPencipta(props) {
  const ref = useRef(null);
  const { state } = useLocation();
  const classes = useStyles();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [iscw, setIscw] = useState(state.customer.iswc_code);
  const [phone, setPhone] = useState();
  const [alias, setAlias] = useState("");
  const [nik, setNik] = useState("");
  const [npwp, setNpwp] = useState("");
  const [loadingPage, setLoadingPage] = useState(false);
  const [identificationType, setIdentificationType] = useState("");
  const [bankName, setBankName] = useState(null);
  const [bankOptions, setBankOptions] = useState([]);
  const [rekening, setRekening] = useState("");
  const [atasNama, setAtasNama] = useState("");
  const [bankId, setBankId] = useState();
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [bankItemSelected, setBankItemSelected] = useState(null);
  const [tempBank, setTempBank] = useState([]);
  const baseUrl = hardBaseUrl;
  const token = localStorage.getItem("token");
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseEdit = () => {
    setOpenEdit(false);
  };
  const getResponse = () => {
    setLoadingPage(true);
    let token = localStorage.getItem("token");
    const url = `${baseUrl}/publisher/composer/${state.customer.composer_id}`;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .get(url, config)
      .then(res => {
        if (res.data.data.bank_response) {
          const bank = res.data.data.bank_response.map(item => ({
            account_name: item.account_name,
            account_number: item.account_number,
            bank_id: item.bank.bank_id,
            profile_image: item.bank.profile_image,
          }));
          setTempBank(bank);
        }
        setName(res.data.data.sure_name);
        setEmail(res.data.data.email);
        setIscw(res.data.data.iswc_code);
        setIdentificationType(res.data.data.identification_type);
        setPhone(res.data.data.phone_number);
        setAlias(res.data.data.alias_name);
        setNik(res.data.data.nik);
        setNpwp(res.data.data.npwp);
        localStorage.setItem(
          "composer_bank",
          JSON.stringify(res.data.data.composer_bank)
        );
        setLoadingPage(false);
      })
      .catch(e => {
        new Error(e);
        setLoadingPage(false);
      });
  };
  const getBank = () => {
    const url = `${baseUrl}/publisher/composer/bank`;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .get(url, config)
      .then(res => {
        setBankOptions(res.data.data);
      })
      .catch(e => {
        new Error(e);
      });
  };
  useEffect(() => {
    getBank();
    getResponse();
  }, []);
  const handleChange = event => {
    const { name, value } = event.target;
    if (name === "name") {
      setName(value);
    } else if (name === "email") {
      setEmail(value);
    } else if (name === "phone") {
      setPhone(value);
    } else if (name === "nik") {
      setNik(value);
    } else if (name === "alias") {
      setAlias(value);
    } else if (name === "npwp") {
      setNpwp(value);
    } else if (name === "atasnama") {
      setAtasNama(value);
    } else if (name === "rekening") {
      setRekening(value);
    } else {
      setIdentificationType(value);
    }
  };
  const handleButtonSimpan = async () => {
    const form = ref.current;
    setLoadingPage(true);
    const listBank = tempBank.map(
      ({ account_name, account_number, bank_id }) => ({
        account_name,
        account_number,
        bank_id,
      })
    );
    let payload = {
      sure_name: name,
      email: email,
      phone_number: phone,
      alias_name: alias,
      npwp: npwp,
      nik: nik,
      identification_type: identificationType,
      iswc_code: form["iscw"].value,
      bank: listBank,
    };
    try {
      let baseOfUrl = baseUrl;
      let urlUse =
        baseOfUrl + `/publisher/composer/${state.customer.composer_id}`;
      let resultAdd = await axios.put(urlUse, payload, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      if (
        resultAdd.data.message === "success" &&
        resultAdd.data.meta.http_status === 200
      ) {
        setLoadingPage(false);
        Swal.fire({
          title: "Success",
          text: "Data has been updated",
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        }).then(result => {
          if (result.value) {
            props.history.push("/admin/parameter/pencipta-admin");
          }
        });
      }
    } catch (err) {
      setLoadingPage(false);
      Swal.fire({
        title: "Error",
        text: `${err.response.data.errors[0].message}`,
        icon: "error",
        confirmButtonText: "Ok",
      });
    }
  };
  const handleChangeSelectBank = e => {
    setBankName(e.target.value);
  };
  const createBank = e => {
    e.preventDefault();
    const img = bankOptions.find(item => item.bank_id === bankName);
    const data = {
      account_name: atasNama,
      account_number: rekening,
      bank_id: bankName,
      profile_image: img.profile_image,
    };
    tempBank.push(data);
    Swal.fire("You added a new bank", "", "success");
    setBankName(null);
    handleClose();
  };
  const handleDeleteBank = item => {
    setBankItemSelected(item);
    setOpenModalDelete(true);
  };
  const handleEditModeBank = item => {
    setOpenEdit(true);
    setBankId(item.bank_id);
    setBankName(item.bank_id);
    setRekening(item.account_number);
    setAtasNama(item.account_name);
  };
  const handleEditBank = e => {
    e.preventDefault();
    const img = bankOptions.find(item => item.bank_id === bankName);
    const data = tempBank.map(item => {
      if (item.bank_id === bankId) {
        item.bank_id = bankName;
        item.account_number = rekening;
        item.account_name = atasNama;
        item.profile_image = img.profile_image;
      }
      return item;
    });
    setTempBank(data);
    Swal.fire("You change bank", "", "success");
    handleCloseEdit();
  };
  const handleCloseDelete = () => {};
  const onClickDelete = () => {
    setOpenModalDelete(false);
    Swal.fire({
      title: "Bank deleted successfully",
      text: "",
      icon: "success",
      showCancelButton: false,
      confirmButtonColor: "#3085d6",
      confirmButtonText: "OK",
    });
    const data = tempBank.filter(
      item => item.bank_id !== bankItemSelected.bank_id
    );
    setTempBank(data);
  };
  return (
    <Page className={classes.root} title="Edit Composer">
      <Backdrop className={classes.backdrop} open={loadingPage}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container maxWidth={false}>
        <form ref={ref}>
          <Grid
            alignItems="flex-end"
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <ThemeProvider theme={fontInter}>
                <Typography
                  style={{
                    fontSize: "24px",
                    fontWeight: "700",
                    lineHeight: "32px",
                  }}
                >
                  Edit Composer
                </Typography>
              </ThemeProvider>
            </Grid>
            <Grid item>
              <Breadcrumbs aria-label="breadcrumb">
                <a href="/admin/parameter/publisher">Master</a>
                <a href="/ 34">Composer</a>
                <Typography>Edit Composer</Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
          <Grid container>
            <Grid item xs={12} md={8} sm={12} lg={8} xl={8}>
              <ThemeProvider theme={fontInter}>
                <Typography
                  style={{
                    fontSize: "18px",
                    fontWeight: "700",
                    lineHeight: "28px",
                    marginTop: "24px",
                  }}
                >
                  Composer Profil
                </Typography>
              </ThemeProvider>
              <ThemeProvider theme={theme}>
                <Typography
                  style={{
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "20px",
                    color: "#687083",
                    marginTop: "8px",
                  }}
                >
                  Change the profile details of the composer
                </Typography>
              </ThemeProvider>
              <Grid
                style={{
                  border: "1px solid #9AA2B1",
                  borderRadius: "8px",
                  padding: "16px",
                  marginTop: "16px",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6} sm={6} lg={6} xl={6}>
                    <ThemeProvider theme={fontInter}>
                      <Typography
                        style={{
                          fontWeight: 500,
                          fontSize: "14px",
                          lineHeight: "20px",
                          color: "#364052",
                        }}
                      >
                        Composer Name
                      </Typography>
                    </ThemeProvider>
                    <TextField
                      id="outlined-basic"
                      name="name"
                      variant="outlined"
                      fullWidth={true}
                      value={name}
                      onChange={event => handleChange(event)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} sm={6} lg={6} xl={6}>
                    <ThemeProvider theme={fontInter}>
                      <Typography
                        style={{
                          fontWeight: 500,
                          fontSize: "14px",
                          lineHeight: "20px",
                          color: "#364052",
                        }}
                      >
                        Alias Name
                      </Typography>
                    </ThemeProvider>
                    <TextField
                      id="outlined-basic"
                      name="alias"
                      variant="outlined"
                      value={alias}
                      fullWidth={true}
                      onChange={event => handleChange(event)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} sm={6} lg={6} xl={6}>
                    <ThemeProvider theme={fontInter}>
                      <Typography
                        style={{
                          fontWeight: 500,
                          fontSize: "14px",
                          lineHeight: "20px",
                          color: "#364052",
                        }}
                      >
                        Email
                      </Typography>
                    </ThemeProvider>
                    <TextField
                      id="outlined-basic"
                      name="email"
                      value={email}
                      variant="outlined"
                      fullWidth={true}
                      type="email"
                      onChange={event => handleChange(event)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} sm={6} lg={6} xl={6}>
                    <ThemeProvider theme={fontInter}>
                      <Typography
                        style={{
                          fontWeight: 500,
                          fontSize: "14px",
                          lineHeight: "20px",
                          color: "#364052",
                        }}
                      >
                        Phone Number
                      </Typography>
                    </ThemeProvider>
                    <OutlinedInput
                      id="outlined-adornment-amount"
                      fullWidth={true}
                      name="phone"
                      type="number"
                      value={phone}
                      onChange={event => handleChange(event)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} sm={6} lg={6} xl={6}>
                    <ThemeProvider theme={fontInter}>
                      <Typography
                        style={{
                          fontWeight: 500,
                          fontSize: "14px",
                          lineHeight: "20px",
                          color: "#364052",
                        }}
                      >
                        NPWP
                      </Typography>
                    </ThemeProvider>
                    <TextField
                      id="outlined-basic"
                      name="npwp"
                      variant="outlined"
                      value={npwp}
                      type="number"
                      fullWidth={true}
                      onChange={event => handleChange(event)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} sm={6} lg={6} xl={6}>
                    <ThemeProvider theme={fontInter}>
                      <Typography
                        style={{
                          fontWeight: 500,
                          fontSize: "14px",
                          lineHeight: "20px",
                          color: "#364052",
                        }}
                      >
                        Identification Number
                      </Typography>
                    </ThemeProvider>
                    <TextField
                      id="outlined-basic"
                      name="nik"
                      type="number"
                      value={nik}
                      variant="outlined"
                      fullWidth={true}
                      onChange={event => handleChange(event)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} sm={6} lg={6} xl={6}>
                    <ThemeProvider theme={fontInter}>
                      <Typography
                        style={{
                          fontWeight: 500,
                          fontSize: "14px",
                          lineHeight: "20px",
                          color: "#364052",
                        }}
                      >
                        ISCW Code
                      </Typography>
                    </ThemeProvider>
                    <TextField
                      fullWidth={true}
                      id="iscw"
                      variant="outlined"
                      className={classes.textField}
                      name="iscw"
                      InputProps={{
                        inputComponent: TextMaskCustom,
                        name: "iscw",
                        defaultValue: iscw,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} sm={6} lg={6} xl={6}>
                    <ThemeProvider theme={fontInter}>
                      <Typography
                        style={{
                          fontWeight: 500,
                          fontSize: "14px",
                          lineHeight: "20px",
                          color: "#364052",
                        }}
                      >
                        Identification Type
                      </Typography>
                    </ThemeProvider>
                    <Select
                      id="outlined-basic"
                      variant="outlined"
                      name="identificationType"
                      fullWidth
                      onChange={event => handleChange(event)}
                      value={identificationType}
                    >
                      <MenuItem value="NIK">NIK</MenuItem>
                      <MenuItem value="KITAS">KITAS</MenuItem>
                    </Select>
                  </Grid>
                  <Grid item xs={12} md={6} sm={6} lg={6} xl={6}>
                    <ThemeProvider theme={fontInter}>
                      <Typography
                        style={{
                          fontWeight: 500,
                          fontSize: "14px",
                          lineHeight: "20px",
                          color: "#364052",
                        }}
                      >
                        IPI Name Number
                      </Typography>
                    </ThemeProvider>
                    <TextField
                      id="outlined-basic"
                      // name="nik"
                      type="number"
                      // value={nik}
                      variant="outlined"
                      fullWidth={true}
                      // onChange={event => handleChange(event)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} sm={6} lg={6} xl={6}>
                    <ThemeProvider theme={fontInter}>
                      <Typography
                        style={{
                          fontWeight: 500,
                          fontSize: "14px",
                          lineHeight: "20px",
                          color: "#364052",
                        }}
                      >
                        IPI Base Number
                      </Typography>
                    </ThemeProvider>
                    <TextField
                      id="outlined-basic"
                      // name="nik"
                      type="number"
                      // value={nik}
                      variant="outlined"
                      fullWidth={true}
                      // onChange={event => handleChange(event)}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={8} md={8} sm={8} lg={8} xl={8}>
              <Divider className={classes.divider} />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={8} md={8} sm={8} lg={8} xl={8}>
              <ThemeProvider theme={fontInter}>
                <Typography
                  style={{
                    fontWeight: 700,
                    marginTop: 24,
                    fontSize: "18px",
                    lineHeight: "28px",
                    color: "#111827",
                  }}
                >
                  Bank Account
                </Typography>
              </ThemeProvider>
              <ThemeProvider theme={theme}>
                <Typography
                  style={{
                    fontWeight: 400,
                    fontSize: "14px",
                    lineHeight: "20px",
                    marginTop: 6,
                    color: "#687083",
                  }}
                >
                  Add Bank Account for payment
                </Typography>
              </ThemeProvider>
              <Button
                className={classes.btnTambahBank}
                onClick={handleOpen}
                style={{
                  color: "white",
                  backgroundColor: "black",
                  textTransform: "none",
                  marginTop: 6,
                  "&:hover": {
                    color: "white",
                    backgroundColor: "black",
                  },
                }}
              >
                <ThemeProvider theme={fontInter}>
                  <Typography
                    style={{
                      color: "white",
                      fontWeight: 400,
                      fontSize: "14px",
                      lineHeight: "20px",
                    }}
                  >
                    + Add Bank Account
                  </Typography>
                </ThemeProvider>
              </Button>
            </Grid>
            <Grid item xs={12} md={8} sm={8} lg={8} xl={8}>
              <Card
                style={{
                  marginTop: "16px",
                }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Action</TableCell>
                      <TableCell>Bank</TableCell>
                      <TableCell>Account Number</TableCell>
                      <TableCell>On behalf of</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tempBank?.length > 0 ? (
                      tempBank.map((bank, index) => (
                        <TableRow hover key={index}>
                          <TableCell
                            style={{
                              width: "20%",
                            }}
                          >
                            <IconButton
                              classes={{
                                root: classes.ib,
                              }}
                              onClick={() => {
                                handleEditModeBank(bank);
                              }}
                            >
                              <img alt="pncl_vector" src={PencilVector} />
                            </IconButton>
                            <IconButton
                              style={{
                                marginLeft: "5px",
                              }}
                              classes={{
                                root: classes.ib,
                              }}
                              onClick={() => {
                                handleDeleteBank(bank);
                              }}
                            >
                              <img alt="trsh_vctr" src={TrashVector} />
                            </IconButton>
                            <Dialog
                              open={openModalDelete}
                              onClose={handleCloseDelete}
                              aria-labelledby="alert-dialog-title"
                              aria-describedby="alert-dialog-description"
                            >
                              <DialogTitle id="alert-dialog-title">
                                <ThemeProvider theme={fontInter}>
                                  <Typography
                                    style={{
                                      fontWeight: 700,
                                      fontSize: "16px",
                                      lineHeight: "24px",
                                    }}
                                  >
                                    Delete Composer
                                  </Typography>
                                </ThemeProvider>
                              </DialogTitle>
                              <DialogContent>
                                {bankItemSelected !== null ? (
                                  <DialogContentText id="alert-dialog-description">
                                    {
                                      "Are you sure you want to delete this data?"
                                    }
                                  </DialogContentText>
                                ) : (
                                  <DialogContentText id="alert-dialog-description">
                                    Are you sure you want to delete this data?
                                  </DialogContentText>
                                )}
                              </DialogContent>
                              <DialogActions>
                                <Button
                                  onClick={() => {
                                    setOpenModalDelete(false);
                                  }}
                                  style={{
                                    height: "40px",
                                    width: "90px",
                                    borderRadius: "6px",
                                    border: "1px solid #D1D5DC",
                                    color: "#111827",
                                    backgroundColor: "white",
                                    textTransform: "none",
                                  }}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  onClick={() => {
                                    onClickDelete();
                                  }}
                                  autoFocus
                                  style={{
                                    height: "40px",
                                    width: "90px",
                                    borderRadius: "6px",
                                    border: "0px solid #D1D5DC",
                                    color: "white",
                                    backgroundColor: "#111827",
                                    textTransform: "none",
                                  }}
                                >
                                  Delete
                                </Button>
                              </DialogActions>
                            </Dialog>
                          </TableCell>
                          <TableCell component="th" scope="row" width={150}>
                            <div>
                              <img
                                alt="bank_logo"
                                src={bank.profile_image}
                                style={{
                                  width: "40%",
                                  height: "40p%",
                                  marginRight: "10px",
                                }}
                              />
                            </div>
                          </TableCell>
                          <TableCell>{bank.account_number}</TableCell>
                          <TableCell>{bank.account_name}</TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={4}>
                          <div
                            style={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Typography>Data not found</Typography>
                          </div>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </Card>
              <Divider className={classes.divider} />
              <div
                style={{
                  marginTop: "10px",
                }}
              >
                <Button
                  className={classes.btnSubmit}
                  onClick={handleButtonSimpan}
                  style={{
                    color: "white",
                    backgroundColor: "black",
                    textTransform: "none",
                    "&:hover": {
                      color: "white",
                      backgroundColor: "black",
                    },
                  }}
                >
                  <ThemeProvider theme={fontInter}>
                    <Typography
                      style={{
                        color: "white",
                        fontSize: "14px",
                      }}
                    >
                      Save
                    </Typography>
                  </ThemeProvider>
                </Button>
                <Dialog
                  fullWidth={true}
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="responsive-dialog-title"
                >
                  <form onSubmit={e => createBank(e)}>
                    <DialogTitle id="responsive-dialog-title">
                      {"Tambah Akun Bank"}
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText>Bank Name</DialogContentText>
                      <div>
                        <FormControl
                          variant="outlined"
                          className={classes.formControl}
                          fullWidth={true}
                        >
                          <InputLabel
                            id="demo-simple-select-outlined-label"
                            shrink={false}
                          >
                            {bankName !== null ? null : "Bank"}
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            onChange={handleChangeSelectBank}
                            autoWidth={true}
                            fullWidth={true}
                          >
                            {bankOptions.map((bank, index) => (
                              <MenuItem key={index} value={bank.bank_id}>
                                {bank.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </DialogContent>
                    <DialogContent>
                      <DialogContentText>Account Number</DialogContentText>
                      <div>
                        <FormControl
                          fullWidth
                          className={classes.margin}
                          variant="outlined"
                        >
                          <OutlinedInput
                            id="outlined-adornment-amount"
                            onChange={handleChange}
                            name="rekening"
                            type="number"
                            fullWidth={false}
                          />
                        </FormControl>
                      </div>
                    </DialogContent>
                    <DialogContent>
                      <DialogContentText>On behalf of</DialogContentText>
                      <div>
                        <FormControl
                          fullWidth
                          className={classes.margin}
                          variant="outlined"
                        >
                          <OutlinedInput
                            id="outlined-adornment-amount"
                            onChange={handleChange}
                            name="atasnama"
                            fullWidth={true}
                          />
                        </FormControl>
                      </div>
                    </DialogContent>
                    <DialogActions>
                      <Button autoFocus onClick={handleClose} color="primary">
                        Cancel
                      </Button>
                      <Button type="submit" color="primary" autoFocus>
                        Save
                      </Button>
                    </DialogActions>
                  </form>
                </Dialog>
                <Dialog
                  fullWidth={true}
                  open={openEdit}
                  onClose={handleCloseEdit}
                  aria-labelledby="responsive-dialog-title"
                >
                  <form onSubmit={e => handleEditBank(e)}>
                    <DialogTitle id="responsive-dialog-title">
                      {"Edit Akun Bank"}
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText>Bank</DialogContentText>
                      <div>
                        <FormControl
                          variant="outlined"
                          className={classes.formControl}
                          fullWidth={true}
                        >
                          <InputLabel
                            id="demo-simple-select-outlined-label"
                            shrink={false}
                          >
                            {bankName !== null ? null : "Bank"}
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={bankName}
                            onChange={handleChangeSelectBank}
                            autoWidth={true}
                            fullWidth={true}
                          >
                            {bankOptions.map((bank, index) => (
                              <MenuItem key={index} value={bank.bank_id}>
                                {bank.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </DialogContent>
                    <DialogContent>
                      <DialogContentText>Account Number</DialogContentText>
                      <div>
                        <FormControl
                          fullWidth
                          className={classes.margin}
                          variant="outlined"
                        >
                          <OutlinedInput
                            id="outlined-adornment-amount"
                            onChange={handleChange}
                            name="rekening"
                            type="number"
                            fullWidth={false}
                            value={rekening}
                          />
                        </FormControl>
                      </div>
                    </DialogContent>
                    <DialogContent>
                      <DialogContentText>On behalf of</DialogContentText>
                      <div>
                        <FormControl
                          fullWidth
                          className={classes.margin}
                          variant="outlined"
                        >
                          <OutlinedInput
                            id="outlined-adornment-amount"
                            onChange={handleChange}
                            name="atasnama"
                            fullWidth={true}
                            value={atasNama}
                          />
                        </FormControl>
                      </div>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={handleCloseEdit}
                        style={{
                          width: "90px",
                          height: "40px",
                          backgroundColor: "#FFFFFF",
                          border: "1px solid #D1D5DC",
                          borderRadius: "6px",
                        }}
                      >
                        <ThemeProvider theme={fontInter}>
                          <Typography
                            style={{
                              color: "black",
                              textTransform: "none",
                              fontWeight: 500,
                              fontSize: "14px",
                              lineHeight: "20px",
                            }}
                          >
                            Cancel
                          </Typography>
                        </ThemeProvider>
                      </Button>
                      <Button
                        style={{
                          width: "90px",
                          height: "40px",
                          backgroundColor: "black",
                          border: "1px solid #D1D5DC",
                          borderRadius: "6px",
                        }}
                        type="submit"
                      >
                        <ThemeProvider theme={fontInter}>
                          <Typography
                            style={{
                              color: "white",
                              textTransform: "none",
                              fontWeight: 500,
                              fontSize: "14px",
                              lineHeight: "20px",
                            }}
                          >
                            Save
                          </Typography>
                        </ThemeProvider>
                      </Button>
                    </DialogActions>
                  </form>
                </Dialog>
              </div>
            </Grid>
          </Grid>
        </form>
      </Container>
    </Page>
  );
}
export default EditPencipta;
