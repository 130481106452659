import { Button, Divider, Typography } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { BackupOutlined } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import { useRef } from "react";

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  results: {
    marginTop: theme.spacing(3),
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  btnBack: {
    backgroundColor: "#25383c",
    color: "#fff",
    marginLeft: "20px",
    marginRight: "20px",
  },
  btnSubmit: {
    border: "1px solid lightgrey",
  },
  backupOutlined: {
    color: "grey",
    fontSize: "40px",
  },
  mt20: {
    marginTop: "20px",
  },
  action: {
    display: "flex",
    justifyContent: "end",
    marginBottom: "20px",
  },
}));

const DialogImportPencipta = ({
  open,
  onClose,
  onSubmit,
  getRootProps,
  getInputProps,
  isDragActive,
  files,
}) => {
  const classes = useStyles();
  const dialogRef = useRef();

  return (
    <div>
      <Dialog
        fullWidth={true}
        open={open}
        onClose={onClose}
        aria-labelledby="responsive-dialog-title"
        ref={dialogRef}
      >
        <DialogTitle id="responsive-dialog-title">{"Import"}</DialogTitle>
        <Divider className={classes.divider} />
        <DialogContent>
          <div className="">
            <div {...getRootProps}>
              <input {...getInputProps()} />

              {isDragActive ? (
                <>
                  <BackupOutlined className={classes.backupOutlined} />
                  <p>Drop the files here ...</p>
                </>
              ) : (
                <>
                  <BackupOutlined className={classes.backupOutlined} />
                  <Typography component="p" variant="h6">
                    <p>
                      <b>Search File</b>, Put the picture here
                    </p>
                  </Typography>
                </>
              )}
            </div>
            <div className={classes.mt20}>
              <aside>
                <Typography component="h1" variant="h5">
                  Files :
                </Typography>
                <ul>
                  <Typography component="h1" variant="h5">
                    {files}
                  </Typography>
                </ul>
              </aside>
            </div>
          </div>
        </DialogContent>
        <Divider className={classes.divider} />
        <div className={classes.action}>
          <Button className={classes.btnSubmit} onClick={onSubmit}>
            Save
          </Button>
          <Button
            className={classes.btnBack}
            variant="outlined"
            onClick={onClose}
          >
            Back
          </Button>
        </div>
      </Dialog>
    </div>
  );
};

export default DialogImportPencipta;
