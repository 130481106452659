import { Container, Divider } from "@material-ui/core";
import { Box, Grid, styled, Typography } from "@mui/material";
import axios from "axios";
import {
  ColorPickerInput,
  FormLabel,
  ModalError,
  ModalSuccess,
  Page,
  PrimaryButton,
  SectionLabel,
  SkeletonComponent,
  ButtonGroupTop,
  TextInput,
} from "components";
import { HeaderTitle } from "layouts";
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import { globalStyles } from "styles";
import { getErrors } from "utils";
import { hardBaseUrl } from "../../../../services/urlConstant";

function Personalisasi() {
  const classes = globalStyles();
  const history = useHistory();
  const uploadInputRef = useRef(null);
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const [loadingPage, setLoadingPage] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [disableButtonApply, setDisableButtonApply] = useState(true);
  const [dataError, setDataError] = useState({});
  const [initialPayload, setInitialPayload] = useState(null);
  const [payload, setPayload] = useState({
    name: "",
    email: "",
    phone: "",
    subdomain: "",
    address: "",
    theme_color: "#000000",
    profile_image: "",
  });
  const {
    name,
    email,
    phone,
    subdomain,
    address,
    theme_color,
    profile_image,
    publisher_id,
  } = payload;
  const [publisherLogo, setPublisherLogo] = useState({
    image_url: null,
    image: null,
  });
  const handleChangePayload = (value, key) => {
    setPayload(currentState => ({
      ...currentState,
      [key]: value,
    }));
  };
  const handleChangeImage = e => {
    const selectedFiles = e.target.files[0];
    if (selectedFiles) {
      setPublisherLogo({
        image_url: URL.createObjectURL(selectedFiles),
        image: selectedFiles,
      });
    } else {
      setPublisherLogo({
        image_url: null,
        image: null,
      });
    }
  };
  const validatePayload = () => {
    const errors = {};
    const errorEmpty = field => `${field} can't be empty.`;
    const fieldLabels = {
      name: "Name",
      email: "Email",
      phone: "Phone Number",
      subdomain: "Subdomain",
      address: "Address",
    };
    Object.entries(fieldLabels).forEach(([key, label]) => {
      if (!payload[key]) {
        errors[key] = errorEmpty(label);
      }
    });
    setDataError(errors);
  };
  const hasChanges = () => {
    if (!initialPayload) return false;
    const isPayloadChanged =
      JSON.stringify(payload) !== JSON.stringify(initialPayload);
    const isLogoChanged = publisherLogo.image !== null;
    return isPayloadChanged || isLogoChanged;
  };

  const getDetail = async () => {
    try {
      setLoadingPage(true);
      const res = await axios.get(`${hardBaseUrl}/publisher/profile`, {
        headers,
      });
      const { data } = res?.data;
      const preload = {
        address: data?.address,
        email: data?.email,
        name: data?.name,
        phone: data?.phone,
        publisher_id: data?.publisher_id,
        subdomain: data?.subdomain,
        theme_color: data?.theme_color,
        profile_image: data?.profile_image,
      };
      setPayload(preload);
      setInitialPayload(preload);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  const handleSubmit = async () => {
    const modifiedPayload = { ...payload };
    delete modifiedPayload.profile_image;
    delete modifiedPayload.publisher_id;

    const formData = new FormData();
    Object.keys(modifiedPayload).forEach(key =>
      formData.append(key, modifiedPayload[key])
    );
    try {
      setSubmitting(true);
      await axios.put(`${hardBaseUrl}/publisher/profile`, formData, {
        headers,
      });
      if (publisherLogo?.image_url) handleChangeLogo();
      else
        ModalSuccess("Succesfully update Publisher Profile").then(() =>
          getDetail()
        );
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setSubmitting(false);
    }
  };
  const handleChangeLogo = async () => {
    const dataForm = new FormData();
    dataForm.append("image_logo", publisherLogo.image);
    dataForm.append("theme_color", theme_color);
    try {
      await axios.put(`${hardBaseUrl}/publisher/profile-image`, dataForm, {
        headers,
      });
      setPublisherLogo({ image: null, image_url: null });
      localStorage.setItem("themeColor", theme_color);
      ModalSuccess("Succesfully update Publisher Logo").then(() =>
        history.go(0)
      );
    } catch (error) {
      ModalError(getErrors(error?.response));
    }
  };

  useEffect(() => {
    getDetail();
  }, []);
  useEffect(() => {
    validatePayload();
    setDisableButtonApply(!hasChanges() || Object.keys(dataError).length > 0);
  }, [payload, initialPayload, dataError, publisherLogo]);

  return (
    <Page className={classes?.root} title="Personalization">
      {loadingPage ? (
        <SkeletonComponent variant="wave" />
      ) : (
        <Container maxWidth={false}>
          <HeaderTitle title="Configuration" breadcrumbData={breadcrumbData} />
          <ButtonGroupTop />
          <Box marginBottom="20px">
            <Divider />
          </Box>
          <Grid container columnSpacing={2} rowSpacing={1} mt="16px">
            <Grid item xs={12} md={6}>
              <SectionLabel
                title="Publisher Profile"
                subTitle="Customize publisher profile and URL to share"
              />
              <FormCard>
                <Grid container columnSpacing={2} rowSpacing={1}>
                  <Grid item xs={12} md={6}>
                    <FormLabel label="Publisher Name" required />
                    <TextInput
                      placeholder="Publisher Name"
                      value={name}
                      onChange={e =>
                        handleChangePayload(e?.target?.value, "name")
                      }
                      disabled={submitting}
                      error={!!dataError?.name}
                      helperText={dataError?.name}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormLabel label="Publisher ID" required />
                    <TextInput value={publisher_id} disabled />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormLabel label="Email" required />
                    <TextInput
                      placeholder="Email"
                      value={email}
                      onChange={e =>
                        handleChangePayload(e?.target?.value, "email")
                      }
                      disabled={submitting}
                      error={!!dataError?.email}
                      helperText={dataError?.email}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormLabel label="Phone Number" required />
                    <TextInput
                      placeholder="Phone Number"
                      value={phone}
                      onChange={e =>
                        handleChangePayload(e?.target?.value, "phone")
                      }
                      disabled={submitting}
                      error={!!dataError?.phone}
                      helperText={dataError?.phone}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormLabel label="Sub-Domain" required />
                    <TextInput
                      placeholder="Sub-Domain"
                      value={subdomain}
                      onChange={e =>
                        handleChangePayload(e?.target?.value, "subdomain")
                      }
                      disabled={submitting}
                      error={!!dataError?.subdomain}
                      helperText={dataError?.subdomain}
                      endAdornment=".caris.id"
                      inputStyle={{ textAlign: "right" }}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormLabel label="Address" required />
                    <TextInput
                      placeholder="Address"
                      value={address}
                      onChange={e =>
                        handleChangePayload(e?.target?.value, "address")
                      }
                      multiline
                      rows={4}
                      disabled={submitting}
                      error={!!dataError?.address}
                      helperText={dataError?.address}
                    />
                  </Grid>
                </Grid>
              </FormCard>
            </Grid>
            <Grid item xs={12} md={6}>
              <SectionLabel
                title="Appearance"
                subTitle="Choose your company logo and customize the color theme"
              />
              <FormCard>
                <FormLabel label="Logo" />
                <Box
                  position="relative"
                  borderRadius="10px"
                  bgcolor="#F9FAFB"
                  height="150px"
                >
                  <img
                    alt="logo"
                    src={publisherLogo.image_url || profile_image}
                    className={classes?.publisherLogo}
                  />
                  <UploadContainer
                    onClick={() =>
                      uploadInputRef.current && uploadInputRef.current.click()
                    }
                  >
                    <Box display="none">
                      <input
                        ref={uploadInputRef}
                        type="file"
                        accept="image/*"
                        onChange={handleChangeImage}
                      />
                    </Box>
                    {publisherLogo.image_url && (
                      <Typography fontSize={24} color="white">
                        Change Logo
                      </Typography>
                    )}
                  </UploadContainer>
                </Box>
                <Box mt="16px">
                  <FormLabel label="Theme Color" />
                  <ColorPickerInput
                    value={theme_color}
                    onChange={color =>
                      handleChangePayload(color, "theme_color")
                    }
                    disabled={submitting}
                  />
                </Box>
              </FormCard>
            </Grid>
          </Grid>
          <Divider className={classes?.divider} />
          <Grid container justifyContent="right">
            <PrimaryButton
              label={`${submitting ? "Saving" : "Apply"} Changes`}
              disabled={submitting || disableButtonApply}
              loading={submitting}
              onClick={handleSubmit}
            />
          </Grid>
        </Container>
      )}
    </Page>
  );
}
const FormCard = styled(Box)({
  border: "1px solid #ebebeb",
  borderRadius: "5px",
  padding: "24px",
  marginTop: "16px",
});
const UploadContainer = styled(Box)({
  position: "absolute",
  width: "100%",
  height: "100%",
  backgroundColor: "#808080",
  top: "0px",
  borderRadius: "5px",
  opacity: "0.5",
  padding: "16px",
  textAlign: "center",
  cursor: "pointer",
});
const breadcrumbData = [
  {
    label: "Configuration",
    link: "/admin/konfigurasi/personalisasi",
  },
  {
    label: "Personalization",
    active: true,
  },
];
export default Personalisasi;
