import React, { useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import SearchIcon from "@material-ui/icons/Search";
import DialogSearch from "./DialogSearch";
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  logo: {
    flexGrow: 1,
  },
  toolbar: {
    minHeight: 128,
    alignItems: "flex-start",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    alignSelf: "flex-end",
  },
  offset: theme.mixins.toolbar,
}));
const ButtonAppBar = ({ logo }) => {
  const classes = useStyles();
  const [openMenu, setOpenMenu] = useState(false);
  const [openDialogSearch, setOpenDialogSearch] = useState(false);
  const handleCloseDialogSearch = () => {
    setOpenDialogSearch(false);
  };
  const handleMenu = () => {
    setOpenMenu(!openMenu);
  };
  return (
    <div className={classes.root}>
      <AppBar position="static" style={{ backgroundColor: "white" }}>
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={handleMenu}
          >
            <MenuIcon style={{ color: "black" }} />
          </IconButton>
          <div className={classes.logo}>{logo}</div>
          <SearchIcon
            style={{ color: "black", marginRight: "20px" }}
            onClick={setOpenDialogSearch}
          />
          <Link to={"/login"} state={{ noFooter: true }}>
            <Button
              color="inherit"
              style={{
                backgroundColor: "black",
                textDecoration: "none",
                color: "white",
              }}
            >
              Login
            </Button>
          </Link>
        </Toolbar>
      </AppBar>
      {openMenu ? (
        <div
          style={{
            backgroundColor: "white",
            position: "absolute",
            zIndex: 100,
            width: "100%",
          }}
        >
          <div
            style={{
              paddingLeft: "10px",
              height: "30px",
              borderBottom: "1px solid #e0e0e0",
            }}
          >
            <Link
              to="/home"
              className="cl-dark2"
              onClick={() => setOpenMenu(false)}
            >
              Home
            </Link>
          </div>
          <div
            style={{
              paddingLeft: "10px",
              height: "30px",
              borderBottom: "1px solid #e0e0e0",
            }}
          >
            <Link
              to="/berita"
              className="cl-dark2"
              onClick={() => setOpenMenu(false)}
            >
              News
            </Link>
          </div>
          <div
            style={{
              paddingLeft: "10px",
              height: "30px",
              borderBottom: "1px solid #e0e0e0",
            }}
          >
            <Link
              to="/edukasi"
              className="cl-dark2"
              onClick={() => setOpenMenu(false)}
            >
              Education
            </Link>
          </div>
          <div
            style={{
              paddingLeft: "10px",
              height: "30px",
              borderBottom: "1px solid #e0e0e0",
            }}
          >
            <Link
              to="/tentang-kami"
              className="cl-dark2"
              onClick={() => setOpenMenu(false)}
            >
              About Us
            </Link>
          </div>
        </div>
      ) : null}
      <DialogSearch
        open={openDialogSearch}
        handleClose={handleCloseDialogSearch}
      />
    </div>
  );
};
export default ButtonAppBar;
