import { Close } from "@material-ui/icons";
import {
  Box,
  Card,
  ClickAwayListener,
  Divider,
  Grid,
  Grow,
  IconButton,
  InputAdornment,
  Paper,
  Popover,
  styled,
  TextField,
} from "@mui/material";
import {
  DateRangeIcon,
  LocalizationProvider,
  MonthCalendar,
} from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { FormLabel, PrimaryButton, SecondaryButton } from "components";
import moment from "moment-timezone";
import { useRef, useState } from "react";
import { formatPeriodMonth } from "utils";

const MonthRangePicker = ({
  label,
  startDate,
  endDate,
  handleChangeStartDate,
  handleChangeEndDate,
  disabled,
  clear,
  width,
  placeholder,
}) => {
  const inputRef = useRef(null);
  const emptyDate = !startDate || !endDate;
  const [anchorEl, setAnchorEl] = useState(null);
  const [period, setPeriod] = useState({
    start: startDate,
    end: endDate,
  });

  const togglePopper = event => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    if (anchorEl) {
      handleReset();
    }
  };
  const handleClickAway = () => {
    setAnchorEl(null);
    if (anchorEl) {
      handleReset();
    }
  };
  const handleClear = () => {
    handleChangeStartDate(null);
    handleChangeEndDate(null);
  };
  const handleReset = () => {
    handleChangePeriod(startDate, "start");
    handleChangePeriod(endDate, "end");
  };
  const handleChangePeriod = (value, key) =>
    setPeriod(state => ({
      ...state,
      [key]: value,
    }));
  const onChangeStartDate = date => {
    const startPeriod = moment(date).startOf("month");
    const endPeriod = moment(period?.end);
    if (startPeriod.isAfter(endPeriod)) {
      handleChangePeriod(startPeriod, "end");
    }
    handleChangePeriod(startPeriod, "start");
  };
  const onChangeEndDate = date => {
    const endPeriod = moment(date).startOf("month");
    const startPeriod = moment(period?.start);
    if (endPeriod.isBefore(startPeriod)) {
      handleChangePeriod(endPeriod, "start");
    }
    handleChangePeriod(endPeriod, "end");
  };
  const handleSubmit = () => {
    handleChangeStartDate(period?.start);
    handleChangeEndDate(period?.end);
    setAnchorEl(null);
  };

  return (
    <Box>
      <CustomTextField
        ref={inputRef}
        customWidth={width}
        size="small"
        label={label}
        placeholder={placeholder}
        value={formatPeriodMonth(startDate, endDate)}
        onClick={togglePopper}
        InputProps={{
          readOnly: true,
          shrink: !emptyDate,
          fullWidth: true,
          endAdornment: (
            <InputAdornment position="end">
              {clear && (startDate || endDate) && (
                <IconButton onClick={handleClear}>
                  <Close />
                </IconButton>
              )}
              <IconButton onClick={togglePopper}>
                <DateRangeIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        disabled={disabled}
      />
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClickAway}
        anchorOrigin={{
          vertical: "bottom",
        }}
        transformOrigin={{
          vertical: "top",
        }}
        TransitionComponent={Grow}
      >
        <ClickAwayListener onClickAway={handleClickAway}>
          <Paper
            elevation={3}
            sx={{
              padding: 2,
            }}
          >
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <Grid
                container
                spacing={2}
                direction="row"
                wrap="nowrap"
                alignItems="flex-start"
              >
                <Grid item>
                  <FormLabel label="Start Month" />
                  <Card mt={1}>
                    <MonthCalendar
                      value={period?.start}
                      onChange={onChangeStartDate}
                    />
                  </Card>
                </Grid>
                <Grid item>
                  <FormLabel label="End Month" />
                  <Card mt={1}>
                    <MonthCalendar
                      value={period?.end}
                      onChange={onChangeEndDate}
                    />
                  </Card>
                </Grid>
              </Grid>
            </LocalizationProvider>
            <Divider sx={{ my: 2 }} />
            <Grid container justifyContent="end" spacing={1}>
              <Grid item>
                <SecondaryButton
                  size="small"
                  onClick={togglePopper}
                  label="Cancel"
                />
              </Grid>
              <Grid item>
                <PrimaryButton
                  size="small"
                  onClick={handleSubmit}
                  label="Apply"
                />
              </Grid>
            </Grid>
          </Paper>
        </ClickAwayListener>
      </Popover>
    </Box>
  );
};

const CustomTextField = styled(TextField)(({ customWidth }) => ({
  width: customWidth || 300,
}));
export default MonthRangePicker;
