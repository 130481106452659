import { ArrowRightOutlined } from "@ant-design/icons";
import { useMediaQuery, useTheme } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import { makeStyles } from "@material-ui/core/styles";
import { Col, Row } from "antd";
import Axios from "axios";
import { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import Berita from "../../../mpisLandingComponent/Berita/Berita";
import Chip from "../../../mpisLandingComponent/Chip/Chip";
import DialogSearch from "../../../mpisLandingComponent/DialogSearch";
import FAQ from "../../../mpisLandingComponent/FAQ/FAQ";
import Layout from "../../../mpisLandingComponent/Layout";
import Publisher from "../../../mpisLandingComponent/Publisher";
import SearchInput from "../../../mpisLandingComponent/SearchInput";
import { hardBaseUrl, hardSubDomain } from "../../../services/urlConstant";
import "./home.css";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3.2,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 190,
  },
}));

const Home = () => {
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [edukasi, setEdukasi] = useState([]);
  const [totalData, setTotalData] = useState({
    ComposerTotal: "0",
    PublisherTotal: "0",
    SongTotal: "",
  });
  const classes = useStyles();

  const [openDialogSearch, setOpenDialogSearch] = useState(false);

  const handleCloseDialogSearch = () => {
    setOpenDialogSearch(false);
  };

  useEffect(() => {
    const getEdukasi = async () => {
      try {
        let resultEdukasi = await Axios.get(
          hardBaseUrl + "/article/public?size=10&page=1&type=edukasi&subdomain="
        );

        if (resultEdukasi.data.data !== null) {
          setEdukasi(resultEdukasi.data.data);
        }
      } catch (error) {
        new Error(error);
      }
    };

    const totalData = async () => {
      try {
        let resultEdukasi = await Axios.get(hardBaseUrl + "/landing/all/total");
        setTotalData(resultEdukasi.data.data);
      } catch (error) {
        new Error(error);
      }
    };

    totalData();
    getEdukasi();
  }, []);

  const getColorClass = () => {
    let postFix = "#9545EB";
    if (hardSubDomain !== "mpis") {
      postFix = "#EB0606";
    }
    return postFix;
  };

  const getBanner = () => {
    return (
      <div className={isMobile ? "banner nadaku-mobile" : "banner nadaku"}>
        <div className="content">
          {isMobile ? (
            <p
              style={{
                color: "white",
                fontSize: "20px",
                fontWeight: "bold",
                marginLeft: "-100px",
              }}
            >
              You bring the passion, <br />
              we bring the music.
            </p>
          ) : (
            <h1>
              You bring the passion, <br />
              we bring the music.
            </h1>
          )}

          {isMobile ? (
            <>
              <Button
                style={{
                  backgroundColor: "black",
                  textDecoration: "none",
                  color: "white",
                  marginLeft: "-100px",
                }}
                onClick={() => setOpenDialogSearch(true)}
              >
                Search
              </Button>
              <DialogSearch
                open={openDialogSearch}
                handleClose={handleCloseDialogSearch}
              />
            </>
          ) : (
            <SearchInput />
          )}
        </div>
      </div>
    );
  };

  const handleDetailBeritaMobile = res => {
    history.push({
      pathname: `/artikel/detail/${res.article_id}`,
      state: res,
    });
  };

  const Container = ({
    img,
    type,
    title,
    publisher_name,
    range_date,
    onClick,
  }) => {
    return (
      <div style={{ width: "514px", margin: "16px" }} onClick={onClick}>
        <Card
          style={{ height: "500px", width: "300px", border: "1px solid black" }}
        >
          <CardMedia className={classes.media} image={img} title={img} />
          <CardContent>
            <Chip title={"News"} type={type} />
            <h2
              style={{
                marginTop: "6px",
                fontWeight: 500,
                fontSize: "20px",
                lineHeight: "30px",
              }}
            >
              {title}
            </h2>
            <div style={{ marginTop: "-5px" }}>
              <Publisher
                fontSize="14px"
                publisher={publisher_name}
                day={range_date}
              />
            </div>
          </CardContent>
        </Card>
      </div>
    );
  };

  return (
    <Layout>
      <div className="dashboard">
        {getBanner()}

        <div
          style={{
            marginTop: isMobile ? null : "60px",
          }}
        >
          <Berita dataNews={true} />
        </div>
        {isMobile ? (
          <>
            <div className="banner-song-mobile">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "0px",
                }}
              >
                <div
                  className="wall"
                  style={{
                    backgroundColor: getColorClass(),
                  }}
                ></div>
                <div className="number">{totalData.SongTotal}</div>
              </div>
              <p style={{ marginLeft: "30px" }}>
                Music registered in our platform
              </p>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "0px",
                }}
              >
                <div
                  className="wall"
                  style={{
                    backgroundColor: getColorClass(),
                  }}
                ></div>
                <div className="number">{totalData.ComposerTotal}</div>
              </div>
              <p style={{ marginLeft: "30px" }}>
                Songwriters already join with us
              </p>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "0px",
                }}
              >
                <div
                  className="wall"
                  style={{
                    backgroundColor: getColorClass(),
                  }}
                ></div>
                <div className="number">{totalData.PublisherTotal}</div>
              </div>
              <p style={{ marginLeft: "30px" }}>
                Music Publishing collaborating together
              </p>
            </div>
          </>
        ) : (
          <>
            <div className="banner-song">
              <Row style={{ height: "100%" }} className="container">
                <Col
                  span={8}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                  }}
                >
                  <div className="wrapper">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "0px",
                      }}
                    >
                      <div
                        className="wall"
                        style={{
                          backgroundColor: getColorClass(),
                        }}
                      ></div>
                      <div className="number">{totalData.SongTotal}</div>
                    </div>
                    <p style={{ marginLeft: "30px" }}>
                      Music registered in our platform
                    </p>
                  </div>
                </Col>
                <Col
                  span={8}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                  }}
                >
                  <div className="wrapper">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "0px",
                      }}
                    >
                      <div
                        className="wall"
                        style={{
                          backgroundColor: getColorClass(),
                        }}
                      ></div>
                      <div className="number">{totalData.ComposerTotal}</div>
                    </div>
                    <p style={{ marginLeft: "30px" }}>
                      Songwriters already join with us
                    </p>
                  </div>
                </Col>
                <Col
                  span={8}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                  }}
                >
                  <div className="wrapper">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "0px",
                      }}
                    >
                      <div
                        className="wall"
                        style={{
                          backgroundColor: getColorClass(),
                        }}
                      ></div>
                      <div className="number">{totalData.PublisherTotal}</div>
                    </div>
                    <p style={{ marginLeft: "30px" }}>
                      Music Publishing collaborating together
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </>
        )}

        {isMobile ? (
          <>
            <div style={{ backgroundColor: "white", paddingTop: "20px" }}>
              <p
                className="title"
                style={{
                  color: getColorClass(),
                  fontStyle: "normal",
                  fontWeight: 500,
                  fontSize: "16px",
                  lineHeight: "24px",
                  paddingLeft: "10px",
                }}
              >
                EDUCATION
              </p>
              <Row
                style={{
                  margin: 0,
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "30px",
                }}
              >
                <Col span={16}>
                  <h1 style={{ paddingLeft: "10px" }}>
                    Increase your knowledge about music!
                  </h1>
                </Col>
                <Col span={8} style={{ textAlign: "right" }}>
                  <p>
                    <Link
                      to={"/edukasi"}
                      style={{ color: getColorClass(), paddingRight: "10px" }}
                    >
                      See more <ArrowRightOutlined />
                    </Link>
                  </p>
                </Col>
              </Row>
              <div
                style={{
                  width: "100%",
                  height: "100%",

                  marginBottom: "50px",
                }}
              >
                {edukasi.length > 0 ? (
                  <div
                    style={{
                      width: "100%",
                      overflowX: "auto",
                      display: "flex",
                      height: "600px",
                    }}
                  >
                    <>
                      {edukasi.map((res, i) => {
                        return (
                          <Container
                            onClick={() => handleDetailBeritaMobile(res)}
                            key={i}
                            img={res.banner_image}
                            type={res.type}
                            title={res.title}
                            publisher_name={res.publisher_name}
                            range_date={res.range_date}
                          />
                        );
                      })}
                    </>
                  </div>
                ) : (
                  <>{""}</>
                )}
              </div>
              <hr style={{ border: "1px solid #E4E7EB" }} />
            </div>
          </>
        ) : (
          <>
            <div className={"edukasi container"}>
              <p
                className="title"
                style={{
                  color: getColorClass(),
                  fontStyle: "normal",
                  fontWeight: 500,
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
              >
                EDUCATION
              </p>
              <Row
                style={{
                  margin: 0,
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "30px",
                }}
              >
                <Col span={16}>
                  <h1>Increase your knowledge about music!</h1>
                </Col>
                <Col span={8} style={{ textAlign: "right" }}>
                  <h2>
                    <Link to={"/edukasi"} style={{ color: getColorClass() }}>
                      See more <ArrowRightOutlined />
                    </Link>
                  </h2>
                </Col>
              </Row>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  whiteSpace: "nowrap",
                  marginBottom: "50px",
                }}
              >
                <Carousel responsive={responsive}>
                  {edukasi.length > 0 ? (
                    <>
                      {edukasi.map((res, i) => {
                        return (
                          <div
                            className="images-wrapper-new"
                            key={i}
                            style={{
                              height: "270px",
                              width: "100%",
                            }}
                          >
                            <img
                              src={res.banner_image}
                              alt=""
                              style={{
                                height: "100%",
                                borderRadius: "10px",
                                width: "100%",
                                objectFit: "cover",
                              }}
                              onDragStart={e => e.preventDefault()}
                            />
                            <div className="content">
                              <Chip color="white" title="Education" />
                              <h2
                                style={{
                                  margin: 0,
                                  padding: 0,
                                  height: "30px",
                                }}
                              >
                                {res.title.substring(0, 20) + "..."}
                              </h2>
                              <Publisher
                                color="white"
                                day={res.range_date}
                                fontSize="12px"
                              />
                            </div>
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    <>{""}</>
                  )}
                </Carousel>
              </div>
              <hr style={{ border: "1px solid #E4E7EB" }} />
            </div>
          </>
        )}

        <FAQ />
      </div>
    </Layout>
  );
};

export default Home;
