import { BackupOutlined, CloseOutlined } from "@material-ui/icons";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import {
  CurrencyNumberInput,
  FormLabel,
  PrimaryButton,
  PrimaryIconButton,
  SecondaryButton,
} from "components";
import { useCallback, useMemo, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { formatFileSize, stringTruncate } from "utils";
import DocumentVector from "../../../assets/image-public/images/vector-doc-icon.png";
import TrashVector from "../../../assets/img/trashVector.svg";
import { globalStyles } from "styles";

const ImportDocumentModal = ({
  open,
  onClose,
  onSubmit,
  exchangeRate,
  loadingButton,
  fileType,
  multiple,
}) => {
  const classes = globalStyles();
  const dialogRef = useRef();
  const [payload, setPayload] = useState(0);
  const [documentFiles, setDocumentFiles] = useState([]);

  const onDrop = useCallback(acceptedFiles => {
    setDocumentFiles(prev => [...prev, ...acceptedFiles]);
  }, []);
  const handleRemoveFile = idx => {
    setDocumentFiles(prevFiles => prevFiles.filter((_, i) => i !== idx));
  };

  const handleSubmit = () => {
    onSubmit(documentFiles, payload);
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: fileType,
    multiple: multiple,
  });

  const dropzoneStyle = useMemo(() => {
    return isDragActive ? classes?.dragActive : "";
  }, [isDragActive, classes.activeStyle]);

  return (
    <Dialog
      ref={dialogRef}
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography fontWeight={700}>Import Document</Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={onClose}>
              <CloseOutlined />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <FormLabel label="Upload Document" />
        <div
          {...getRootProps({
            className: `${classes.dragFilesContainer} ${dropzoneStyle}`,
          })}
        >
          <input {...getInputProps()} />
          <Box color="grey">
            <BackupOutlined className={classes?.uploadIcon} />
          </Box>
          <Typography>
            {isDragActive
              ? "Drop the files here..."
              : "Drag files or click to upload"}
          </Typography>
        </div>
        <Box mt="16px">
          <FormLabel label="Uploaded File" />
          {documentFiles.length > 0 &&
            documentFiles?.map((item, index) => (
              <Grid
                key={index}
                container
                justifyContent="space-between"
                alignItems="center"
                my={2}
              >
                <Grid item>
                  <Grid item container columnSpacing={2} alignItems="center">
                    <Grid item>
                      <img alt="Logo" src={DocumentVector} />
                    </Grid>
                    <Grid item>
                      <Typography variant="body1">
                        {stringTruncate(item?.name, 20)}
                      </Typography>
                      <Typography variant="body2">
                        {formatFileSize(item?.size)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <PrimaryIconButton
                    onClick={() => handleRemoveFile(index)}
                    icon={<img alt="delete" src={TrashVector} />}
                  />
                </Grid>
              </Grid>
            ))}
        </Box>
        {exchangeRate && (
          <Box mt="16px">
            <FormLabel label="Exchange Rate" />
            <CurrencyNumberInput
              startAdornment="Rp"
              value={payload}
              onChange={value => setPayload(value)}
              decimalScale={2}
            />
          </Box>
        )}
      </DialogContent>
      <Divider />
      <DialogActions>
        <SecondaryButton label="Cancel" onClick={onClose} />
        <PrimaryButton
          label="Confirm"
          disabled={loadingButton}
          loading={loadingButton}
          onClick={handleSubmit}
        />
      </DialogActions>
    </Dialog>
  );
};

export default ImportDocumentModal;
