import { useMediaQuery, useTheme } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { ThemeProvider } from "@material-ui/core/styles";
import teamsDiki from "../../../assets/images/our_teams_diki.svg";
import teamsFarah from "../../../assets/images/our_teams_farah.svg";
import teamsJimmy from "../../../assets/images/our_teams_jimmy.svg";
import teamsTiara from "../../../assets/images/our_teams_tiara.svg";
import teamsWirianto from "../../../assets/images/our_teams_wirianto.svg";
import "../../../components/templates/LandingPage/LandingStyle.css";
import { interFont } from "./About";

export const OurTeams = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const photoLayout = (margin, position) => {
    return {
      margin: !margin ? "22px 0 0" : "0 0 22px",
      width: isMobile ? "170px" : "234px",
      height: isMobile ? "321px" : "443px",
      objectFit: "cover",
      objectPosition:
        position === "center" ? "52%" : position === "left" ? "10%" : "left",
    };
  };
  return (
    <div
      className="landing-content"
      style={{
        flexDirection: isMobile ? "column" : "row",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: isMobile && "space-between",
          marginRight: !isMobile && "22px",
        }}
      >
        <div
          style={{
            marginRight: !isMobile && "22px",
          }}
        >
          <img alt="CTO" src={teamsWirianto} style={photoLayout("top")} />
          <NameCard
            name="Diki Arifin"
            position="Product Owner"
            background="linear-gradient(47.52deg, #652D8D 9.72%, #E80088 124.6%)"
            align="end"
          />
        </div>
        <div
          style={{
            marginRight: !isMobile && "22px",
          }}
        >
          <NameCard
            name="Wirianto Widjaja"
            position="CTO"
            background="linear-gradient(224.43deg, #652D8D 8.19%, #E80088 153.87%)"
          />
          <img alt="Product Owner" src={teamsDiki} style={photoLayout()} />
        </div>
      </div>
      <div>
        {isMobile ? (
          <div>
            <div
              style={{
                margin: "22px 0 ",
              }}
            >
              <img
                alt="Consultant"
                src={teamsJimmy}
                style={{
                  width: "100%",
                }}
              />
              <NameCard
                name="Jimmy Ariansyah"
                position="Consultant"
                type="top"
                background="linear-gradient(313.1deg, #652D8D 9.57%, #E80088 118.58%)"
                layout="mobile"
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: isMobile && "space-between",
                margin: "22px 0",
              }}
            >
              <div
                style={{
                  marginRight: !isMobile && "22px",
                }}
              >
                <img
                  alt="Analyst"
                  src={teamsFarah}
                  style={photoLayout("top", "center")}
                />
                <NameCard
                  name="Tiara Salsabila"
                  position="Analyst"
                  background="linear-gradient(127.16deg, #652D8D 9.35%, #E80088 89.44%)"
                />
              </div>
              <div
                style={{
                  marginRight: !isMobile && "22px",
                  width: isMobile ? "170px" : "234px",
                }}
              >
                <NameCard
                  name="Farah Kalsum"
                  position="Analyst"
                  background="linear-gradient(127.16deg, #652D8D 9.35%, #E80088 89.44%)"
                />
                <img
                  alt="Analyst"
                  src={teamsTiara}
                  style={photoLayout("", "left")}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "60px 0",
                background: "#652D8D",
              }}
            >
              <ThemeProvider theme={interFont}>
                <Typography
                  style={{
                    fontWeight: 700,
                    fontSize: "30px",
                    color: "white",
                  }}
                >
                  Meet Our <span style={{ color: "#E80088" }}>Team</span>
                </Typography>
              </ThemeProvider>
            </div>
          </div>
        ) : (
          <div>
            <div
              style={{
                display: "flex",
              }}
            >
              <NameCard
                name="Jimmy Ariansyah"
                position="Consultant"
                type="top"
                background="linear-gradient(313.1deg, #652D8D 9.57%, #E80088 118.58%)"
              />
              <img
                alt="Consultant"
                src={teamsJimmy}
                style={{
                  marginLeft: "22px",
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                margin: "22px 0",
              }}
            >
              <NameCard
                name="Farah Kalsum"
                position="Analyst"
                background="linear-gradient(127.16deg, #652D8D 9.35%, #E80088 89.44%)"
              />
              <img
                alt="Analyst"
                src={teamsTiara}
                style={{
                  marginLeft: "33px",
                  marginRight: "22px",
                }}
              />
              <NameCard
                name="Tiara Salsabila"
                position="Analyst"
                background="linear-gradient(127.16deg, #652D8D 9.35%, #E80088 89.44%)"
              />
            </div>
            <div
              style={{
                display: "flex",
              }}
            >
              <img alt="Analyst" src={teamsFarah} />
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: "35px",
                  width: "392px",
                  height: "184px",
                  background: "#652D8D",
                }}
              >
                <ThemeProvider theme={interFont}>
                  <Typography
                    style={{
                      fontWeight: 700,
                      fontSize: "30px",
                      color: "white",
                    }}
                  >
                    Meet Our <span style={{ color: "#E80088" }}>Team</span>
                  </Typography>
                </ThemeProvider>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const NameCard = ({ name, position, background, type, align, layout }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <div
      style={{
        width: layout ? "100%" : isMobile ? "170px" : "234px",
        marginTop: layout && "22px",
        height: layout ? "180px" : "237px",
        color: "white",
        padding: "25px",
        background: background,
        display: "flex",
        flexDirection: "column",
        justifyContent: type ? "start" : "flex-end",
        textAlign: align ? "end" : "start",
      }}
    >
      <ThemeProvider theme={interFont}>
        <Typography
          style={{
            fontWeight: 700,
            fontSize: "18px",
          }}
        >
          {name}
        </Typography>
        <Typography
          style={{
            fontWeight: 400,
            fontSize: "16px",
          }}
        >
          {position}
        </Typography>
      </ThemeProvider>
    </div>
  );
};
