import { Container, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Box, Grid, styled } from "@mui/material";
import axios from "axios";
import {
  FormLabel,
  ModalError,
  ModalSuccess,
  NumberInput,
  Page,
  PrimaryButton,
  SectionLabel,
  SelectInput,
  SkeletonComponent,
  TextInput,
} from "components";
import { HeaderTitle } from "layouts";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { getErrors } from "utils";
import { hardBaseUrl } from "../../../../services/urlConstant";

const FormTax = () => {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const [loadingPage, setLoadingPage] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [payload, setPayload] = useState({
    code: "",
    description: "",
    method: "addition",
    object: "sales",
    rate: 0,
    tax_bases: 0,
  });

  const handleChangePayload = (value, key) => {
    setPayload(currentState => ({
      ...currentState,
      [key]: value,
    }));
  };

  const getDetail = async () => {
    try {
      setLoadingPage(true);
      const res = await axios.get(`${hardBaseUrl}/tax/detail/${id}`, {
        headers,
      });

      setPayload(res?.data?.data);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  const handleSubmit = async () => {
    const url = `${hardBaseUrl}/tax/${id ? `update/${id}` : "create"}`;
    const method = id ? axios.put : axios.post;

    try {
      setLoadingButton(true);
      await method(url, payload, { headers });
      ModalSuccess(
        `Successfully ${id ? "Saved Changes" : "Added Tax"}`
      ).then(() => history.goBack());
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingButton(false);
    }
  };

  useEffect(() => {
    if (id) {
      getDetail();
    }
  }, []);

  return (
    <Page className={classes.root} title={`${id ? "Edit" : "Add"} Tax`}>
      {loadingPage ? (
        <SkeletonComponent variant="wave" />
      ) : (
        <Container maxWidth={false}>
          <HeaderTitle
            title={`${id ? "Edit" : "Add"} Tax`}
            breadcrumbData={breadcrumbData(id)}
            backButton
          />
          <Divider className={classes.divider} />
          <SectionLabel
            title="Tax Information"
            subTitle={`Fill this tax information to ${
              id ? "edit" : "add"
            } tax for Client`}
          />
          <Box width="50%">
            <FormCard>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormLabel label="Code" required />
                  <TextInput
                    value={payload?.code}
                    onChange={e =>
                      handleChangePayload(e?.target?.value, "code")
                    }
                    placeholder="Tax Code"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormLabel label="Rate" required />
                  <NumberInput
                    value={payload?.rate}
                    onChange={e =>
                      handleChangePayload(Number(e?.target?.value), "rate")
                    }
                    endAdornment="%"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormLabel label="Tax Bases" required />
                  <NumberInput
                    value={payload?.tax_bases}
                    onChange={e =>
                      handleChangePayload(Number(e?.target?.value), "tax_bases")
                    }
                    endAdornment="%"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormLabel label="Method" required />
                  <SelectInput
                    value={payload?.method}
                    options={optionTaxMethod}
                    optionKey="value"
                    optionLabel="name"
                    onChange={e =>
                      handleChangePayload(e?.target?.value, "method")
                    }
                    width="100%"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormLabel label="Object" required />
                  <SelectInput
                    value={payload?.object}
                    options={optionTaxObject}
                    optionKey="value"
                    optionLabel="name"
                    onChange={e =>
                      handleChangePayload(e?.target?.value, "object")
                    }
                    width="100%"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormLabel label="Description" />
                  <TextInput
                    value={payload?.description}
                    onChange={e =>
                      handleChangePayload(e?.target?.value, "description")
                    }
                    placeholder="Tax Description"
                    multiline
                    rows={4}
                  />
                </Grid>
              </Grid>
            </FormCard>
            <Grid container justifyContent="right">
              <PrimaryButton
                label={
                  loadingButton
                    ? id
                      ? "Saving Changes"
                      : "Adding Tax"
                    : id
                    ? "Save Changes"
                    : "Add Tax"
                }
                width={180}
                disabled={loadingButton}
                loading={loadingButton}
                onClick={handleSubmit}
              />
            </Grid>
          </Box>
        </Container>
      )}
    </Page>
  );
};

const useStyles = makeStyles({
  root: {
    paddingTop: "16px",
    paddingBottom: "16px",
  },
  divider: {
    margin: "16px 0",
    borderTop: "1px solid #e1e1e1",
  },
});
const FormCard = styled(Box)({
  border: "1px solid #9AA2B1",
  padding: "16px",
  borderRadius: "8px",
  margin: "16px 0",
});
const breadcrumbData = id => [
  {
    label: "Home",
    link: "/admin/dashboard",
  },
  {
    label: "Configuration",
    link: "/admin/konfigurasi/pajak",
  },
  {
    label: "Tax",
    link: "/admin/konfigurasi/pajak",
  },
  {
    label: `${id ? "Edit" : "Add"} Tax`,
    active: true,
  },
];
const optionTaxMethod = [
  {
    name: "Addition",
    value: "addition",
  },
  {
    name: "Deduction",
    value: "deduction",
  },
];
const optionTaxObject = [
  {
    name: "Sales",
    value: "sales",
  },
  {
    name: "Payment",
    value: "payment",
  },
  {
    name: "Both",
    value: "both",
  },
];
export default FormTax;
