import {
  Breadcrumbs,
  Button,
  ButtonGroup,
  Container,
  Divider,
  Grid,
  Typography,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import moment from "moment";
import { Page } from "../../../../components/molecules";
import { hardBaseUrl } from "../../../../services/urlConstant";
import Detail from "../Detail";
import DialogNonAktifkanKontrak from "../DialogNonAktifkanKontrak";
import DokumenPendukung from "../DokumenPendukung";

const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },

  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  formControl: {},
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  results: {
    marginTop: theme.spacing(3),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  pageSize: {
    height: "30px",
    borderRadius: "6px",
    border: "1px solid #D1D5DC",
    paddingLeft: "5px",
    paddingRight: "5px",
    marginTop: "5px",
  },
  paginationText: {
    fontSize: "14px",
    fontWeight: "normal",
    fontStyle: "normal",
  },
  breadcrumbs: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#111827",
  },
  breadCrumbsActive: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#687083",
  },
  placeholder: {
    "&::placeholder": {
      color: "#9AA2B1",
    },
  },
  buttonSelect: {
    backgroundColor: "#E4E7EB",

    color: "black",
    "&:hover": {
      backgroundColor: "#E4E7EB",
      color: "black",
    },
  },
  conTextBlack: {
    fontSize: "14px",
    fontWeight: "bold",
  },
  label: {
    color: "#364052",
    fontSize: "14px",
    fontWeight: 500,
  },
  subTitle: {
    fontSize: "18px",
    fontWeight: 700,
    color: " #111827",
  },
}));

const DetailKontrak = () => {
  const { state } = useLocation();

  const classes = useStyles();

  const [valueTab, setValueTab] = useState(1);
  const [dataComposer, setDataComposer] = useState([]);

  const [idPerformer, setIdPerformer] = useState(null);
  const [performerName, setPerformerName] = useState("");
  const [getColor, setGetColor] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [currentDate, setCurrentDate] = useState(moment().toString());
  const [radioDateValue, setRadioDateValue] = useState(null);
  const [contractNumber, setContractNumber] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [status, setStatus] = useState("");
  const [publisherName, setPublisherName] = useState("");
  const [idPublisher, setIdPublisher] = useState("");
  const [description, setDescription] = useState("");
  const [songTitle, setSongTitle] = useState("");
  const [ownershipPercentage, setOwnershipPercentage] = useState("");

  const handleOpenNonAktifDialog = () => setOpenDialog(true);
  const handleCloseNonAKtifDialog = () => setOpenDialog(false);

  const handleChangeDateNonAktif = event => {
    if (event.target.value === "3day") {
      setRadioDateValue("3day");
      setCurrentDate(new Date().getTime() + 3 * 24 * 60 * 60 * 1000);
    } else if (event.target.value === "1week") {
      setRadioDateValue("1week");
      setCurrentDate(new Date().getTime() + 7 * 24 * 60 * 60 * 1000);
    } else if (event.target.value === "2week") {
      setRadioDateValue("2week");
      setCurrentDate(new Date().getTime() + 14 * 24 * 60 * 60 * 1000);
    }
  };

  const handleChangeDate = date => {
    setCurrentDate(date);
  };

  const onSubmitNonAktif = () => {
    const date = new Date(currentDate);
    const year = date.getFullYear();

    const month = ("0" + (date.getMonth() + 1)).slice(-2);

    const day = ("0" + date.getDate()).slice(-2);
    const dateString = `${year}-${month}-${day}`;

    const payload = {
      is_active_flag: false,
      inactive_at: dateString,
    };

    const url = `${hardBaseUrl}/publisher/contract/status/original-publisher/${state.customer.contract_id}`;
    const token = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .put(url, payload, { headers })
      .then(() => {
        Swal.fire({
          title: "Success",
          text: "Status Kontrak Berhasil Diubah",
          icon: "success",
        });
        handleCloseNonAKtifDialog();
      })
      .catch(() => {
        Swal.fire({
          title: "Error",
          text: "Terjadi Kesalahan",
          icon: "error",
        });
      });
  };

  const handleClickTab1 = () => {
    setValueTab(1);
  };

  const handleClickTab2 = () => {
    setValueTab(2);
  };

  const getSongResponse = () => {
    const url = `${hardBaseUrl}/publisher/song`;
    const token = localStorage.getItem("token");

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .get(url, config)
      .then(res => {
        const dataComposer = res.data.data.filter(
          composer => composer.song_id === state.customer.song.song_id
        );

        setIdPerformer(dataComposer[0].performer_id);

        setDataComposer(dataComposer[0].song_composer);
      })
      .catch(() => { });
  };

  const getPerformerList = () => {
    const url = `${hardBaseUrl}/publisher/performer`;
    const token = localStorage.getItem("token");

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .get(url, config)
      .then(res => {
        const dataPerformer = res.data.data.filter(
          performer => performer.performer_id === idPerformer
        );

        setPerformerName(dataPerformer[0].first_name);
      })
      .catch(() => { });
  };

  const getMainDsp = async () => {
    try {
      const token = localStorage.getItem("token");
      const url = `${hardBaseUrl}/me`;
      const res = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setGetColor(res.data.data.publisher.theme_color);
    } catch (e) {
      new Error(e);
    }
  };

  useEffect(() => {
    getMainDsp();
  }, []);

  useEffect(() => {
    getSongResponse();
    getPerformerList();
  }, [dataComposer, idPerformer]);

  const getResponseDetail = () => {
    const url = `${hardBaseUrl}/publisher/contract/original-publisher/${state.customer.contract_id}`;
    const token = localStorage.getItem("token");

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .get(url, config)
      .then(res => {
        setContractNumber(res.data.data.contract.contract_number);
        setStatus(res.data.data.contract.is_active_flag);
        setStartDate(res.data.data.contract.start_date);
        setEndDate(res.data.data.contract.end_date);
        setPublisherName(res.data.data.contract.publisher);
        setIdPublisher(res.data.data.contract.id_publisher);
        setSongTitle(res.data.data.songs[0].title);
        setPerformerName(res.data.data.songs[0].performer);

        setDescription(res.data.data.contract.description);
        setOwnershipPercentage(res.data.data.songs[0].ownership);
        setDataComposer(res.data.data.songs[0].composers);
      })
      .catch(() => { });
  };
  useEffect(() => {
    getResponseDetail();
  }, []);

  return (
    <Page className={classes.root} title="Pencipta">
      <Container maxWidth={false}>
        <Grid
          alignItems="flex-end"
          container
          justifyContent="space-between"
          spacing={3}
        >
          <Grid item>
            <ThemeProvider theme={theme}>
              <Typography
                style={{
                  fontSize: "24px",
                  fontWeight: "700",
                  lineHeight: "32px",
                }}
              >
                Contract Detail
              </Typography>
            </ThemeProvider>
          </Grid>
          <Grid item>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography className={classes.breadCrumbs}>Home</Typography>

              <Typography className={classes.breadCrumbs}>Contract</Typography>

              <Typography className={classes.breadCrumbsActive}>
                Contract Detail
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "20px",
          }}
        >
          <div>
            <ButtonGroup aria-label="outlined primary button group">
              <Button
                className={valueTab === 1 ? classes.buttonSelect : ""}
                style={{
                  textTransform: "none",
                  border: "1px solid #D1D5DC",
                  padding: "10px 16px",
                }}
                onClick={handleClickTab1}
              >
                <ThemeProvider theme={theme}>
                  <Typography
                    style={{
                      lineHeight: "20px",
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                  >
                    Detail
                  </Typography>
                </ThemeProvider>
              </Button>
              <Button
                className={valueTab === 2 ? classes.buttonSelect : ""}
                style={{
                  textTransform: "none",
                  border: "1px solid #D1D5DC",
                  padding: "10px 16px",
                }}
                onClick={handleClickTab2}
              >
                <ThemeProvider theme={theme}>
                  <Typography
                    style={{
                      lineHeight: "20px",
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                  >
                    Supporting Document
                  </Typography>
                </ThemeProvider>
              </Button>
            </ButtonGroup>
          </div>
          {valueTab === 1 ? (
            <div></div>
          ) : (
            <div>
              <Button
                onClick={handleOpenNonAktifDialog}
                style={{
                  textTransform: "none",
                  backgroundColor: getColor,
                  color: "white",
                  padding: "10px 28px",
                  borderRadius: "6px",
                  width: "160px",
                  height: "40px",
                }}
              >
                <ThemeProvider theme={theme}>
                  <Typography
                    style={{
                      fontSize: "14px",
                      fontWeight: "500",

                      color: "white",
                    }}
                  >
                    Non-Active
                  </Typography>
                </ThemeProvider>
              </Button>
              <Button
                style={{
                  textTransform: "none",
                  backgroundColor: getColor,
                  color: "white",
                  padding: "10px 28px",
                  borderRadius: "6px",
                  width: "160px",
                  height: "40px",
                  marginLeft: "10px",
                  whiteSpace: "nowrap",
                }}
              >
                {" "}
                <ThemeProvider theme={theme}>
                  <Typography
                    style={{
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                  >
                    Update Contract
                  </Typography>
                </ThemeProvider>
              </Button>
            </div>
          )}
        </div>
        <Divider className={classes.divider} />

        {valueTab === 1 ? (
          <Detail
            noKontrak={contractNumber}
            tglMulai={startDate}
            publisher={publisherName}
            status={status}
            tglSelesai={endDate}
            idPublisher={idPublisher}
            keterangan={description}
            judul={songTitle}
            kepemilikan={ownershipPercentage}
            performer={performerName}
            dataPencipta={dataComposer}
          />
        ) : (
          <DokumenPendukung idKontrak={state.customer.contract_id} />
        )}
      </Container>
      <DialogNonAktifkanKontrak
        open={openDialog}
        onClose={handleCloseNonAKtifDialog}
        dateValue={currentDate}
        handleChange={handleChangeDateNonAktif}
        radioValue={radioDateValue}
        onSubmit={onSubmitNonAktif}
        handleChangeDate={handleChangeDate}
      />
    </Page>
  );
};

export default DetailKontrak;
