import {
  Breadcrumbs,
  Button,
  Checkbox,
  Container,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import { useState } from "react";
import { ButtonGroupTop } from "../../../../components/atoms/Button";
import { Page } from "components";
const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  results: {
    marginTop: theme.spacing(3),
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  button: {
    padding: "10px 28px 10px 28px",
    backgroundColor: "black",
    color: "white",
    width: "213px",
    height: "40px",
    borderRadius: "6px",
    textTransform: "none",
    whiteSpace: "nowrap",
    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
  subtitle: {
    fontSize: "18px",
    fontWeight: "bold",
    lineHeight: "28px",
  },
  breadcrumbs: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#111827",
  },
  breadCrumbsActive: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#687083",
  },
  pageSize: {
    height: "30px",
    borderRadius: "6px",
    border: "1px solid #D1D5DC",
    paddingLeft: "5px",
    paddingRight: "5px",
    marginTop: "5px",
  },
  select: {
    "& .MuiSelect-outlined": {
      paddingTop: "14px",
      paddingBottom: "14px",
      paddingLeft: "20px",
      paddingRight: "30px",
      width: "180px",
    },
  },
}));

const ComposerCredential = () => {
  const classes = useStyles();

  const [credentialType, setCredentialType] = useState(1);

  const [itemContract, setItemContract] = useState([
    {
      id: 0,
      name: "Advance",
      checked: false,
    },
    {
      id: 1,
      name: "Publisher Share",
      checked: false,
    },
  ]);
  const [itemSongUsage, setItemSongUsage] = useState([
    {
      id: 0,
      name: "Advance",
      checked: false,
    },
  ]);
  const [selectedItemContract, setSelectedItemContract] = useState([]);


  const handleChangeCredentialType = event => {
    setCredentialType(event.target.value);
  };

  const handleChangeCheckBoxContract = event => {
    let item = [...itemContract];
    item[event.target.id].checked = event.target.checked;
    setItemContract(item);

    if (event.target.checked === true) {
      let item = [...selectedItemContract];
      item.push(event.target.id);
      setSelectedItemContract(item);
    } else {
      let item = [...selectedItemContract];
      item.splice(item.indexOf(event.target.id), 1);
      setSelectedItemContract(item);
    }
  };

  const handleChangeCheckBoxSongUsage = event => {
    let item = [...itemSongUsage];
    item[event.target.id].checked = event.target.checked;
    setItemSongUsage(item);
  };
  return (
    <Page className={classes.root} title="Cost Type">
      <Container maxWidth={false}>
        <Grid
          alignItems="flex-end"
          container
          justifyContent="space-between"
          spacing={3}
        >
          <Grid item>
            <ThemeProvider theme={theme}>
              <Typography
                style={{
                  fontSize: "24px",
                  fontWeight: "700",
                  lineHeight: "32px",
                }}
              >
                Configuration
              </Typography>
            </ThemeProvider>
          </Grid>
          <Grid item>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography className={classes.breadCrumbs}>Home</Typography>

              <Typography className={classes.breadCrumbs}>
                Configuration
              </Typography>

              <Typography className={classes.breadCrumbsActive}>
                Composer Credential
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
        <ButtonGroupTop />
        <Divider className={classes.divider} />
        <Grid container>
          <Grid item xs={4} md={4} sm={4} lg={8} xl={8}>
            <ThemeProvider theme={theme}>
              <Typography variant="h3" className={classes.subtitle}>
                Composers Credential
              </Typography>
            </ThemeProvider>
            <ThemeProvider theme={theme}>
              <p
                style={{
                  marginTop: "5px",
                  color: "#8f8f8f",
                  fontSize: "14px",
                }}
              >
                Feature to manages menu lists and information accessible to
                Composer.
              </p>
            </ThemeProvider>
            <ThemeProvider theme={theme}>
              <label>Period</label>
            </ThemeProvider>
            <FormControl
              className={classes.formControl}
              fullWidth={true}
              id="dsp-filter"
            >
              <TextField
                size="small"
                className={classes.select}
                style={{
                  width: "195px",

                  height: "44px",
                  border: "1px solid #D1D5DC",
                  borderRadius: "6px",
                }}
                onChange={handleChangeCredentialType}
                value={credentialType}
                select
                variant="outlined"
                InputProps={{}}
              >
                <MenuItem value={1}>Contract</MenuItem>

                <MenuItem value={2}>Song Usage</MenuItem>
              </TextField>
            </FormControl>
          </Grid>
          <Grid item xs={4} md={4} sm={4} lg={4} xl={4}></Grid>
        </Grid>
        <Grid container>
          <Grid item xs={5} md={5} sm={5} lg={5} xl={5}>
            {credentialType === 1 ? (
              <div>
                <FormControl
                  style={{
                    border: "1px solid #D1D5DC",
                    marginTop: "20px",
                    borderRadius: "6px",
                    padding: "10px",
                  }}
                >
                  {itemContract.map((item, index) => {
                    return (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div style={{ width: "400px" }}>
                          <ThemeProvider theme={theme}>
                            <label style={{ marginTop: "10px" }}>
                              {item.name}
                            </label>
                          </ThemeProvider>
                        </div>

                        <Checkbox
                          className={classes.myCheckBox}
                          id={`${item.id}`}
                          key={index}
                          checked={item.checked}
                          name={item.name}
                          value={item.id}
                          onChange={e => handleChangeCheckBoxContract(e)}
                        />
                      </div>
                    );
                  })}
                </FormControl>
              </div>
            ) : (
              <div>
                <FormControl
                  style={{
                    border: "1px solid #D1D5DC",
                    marginTop: "20px",
                    borderRadius: "6px",
                    padding: "10px",
                  }}
                >
                  {itemSongUsage.map((item, index) => {
                    return (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div style={{ width: "400px" }}>
                          <ThemeProvider theme={theme}>
                            <label style={{ marginTop: "10px" }}>
                              {item.name}
                            </label>
                          </ThemeProvider>
                        </div>

                        <Checkbox
                          className={classes.myCheckBox}
                          id={`${item.id}`}
                          key={index}
                          checked={item.checked}
                          name={item.name}
                          value={item.id}
                          onChange={e => handleChangeCheckBoxSongUsage(e)}
                        />
                      </div>
                    );
                  })}
                </FormControl>
              </div>
            )}
            <div
              style={{
                marginTop: "20px",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="outlined"
                style={{
                  textTransform: "none",
                  backgroundColor: "black",
                  color: "white",
                  width: "129px",
                  height: "40px",
                }}
              >
                <ThemeProvider theme={theme}>
                  <Typography
                    style={{
                      color: "white",
                      fontStyle: "normal",
                      fontWeight: 500,
                      fontSize: "14px",
                      lineHeight: "20px",
                    }}
                  >
                    Save
                  </Typography>
                </ThemeProvider>
              </Button>
            </div>
          </Grid>
          <Grid item xs={7} md={7} sm={7} lg={7} xl={7}></Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default ComposerCredential;
