import React from "react";
import { makeStyles } from "@material-ui/styles";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import {
  Container,
  Divider,
  Grid,
  Button,
  Breadcrumbs,
  Typography,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { Page } from "components";

import { useLocation } from "react-router-dom";

const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  results: {
    marginTop: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  formControl: {},
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  pagination: {
    "& .Mui-selected": {
      backgroundColor: "black",
      color: "white",
    },
  },
  pageSize: {
    height: "30px",
    borderRadius: "6px",
    border: "1px solid #D1D5DC",
    paddingLeft: "5px",
    paddingRight: "5px",
    marginTop: "5px",
  },
  paginationText: {
    fontSize: "14px",
    fontWeight: "normal",
    fontStyle: "normal",
    color: "#687083",
  },
  breadcrumbs: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#111827",
  },
  breadCrumbsActive: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#687083",
  },
  placeholder: {
    "&::placeholder": {
      color: "#9AA2B1",
    },
  },
  subTitle: {
    fontSize: "18px",
    fontWeight: 700,
    color: " #111827",
  },
  headText: {
    fontSize: "12px",
    color: "#687083",
    fontWeight: "bold",
  },
}));

const DetailAdvanced = () => {
  const classes = useStyles();
  const { state } = useLocation();
  return (
    <Page className={classes.root} title="Detail Advance">
      <Container maxWidth={false}>
        <Grid
          alignItems="flex-end"
          container
          justifyContent="space-between"
          spacing={3}
        >
          <Grid item>
            <ThemeProvider theme={theme}>
              <Typography
                style={{
                  fontSize: "24px",
                  fontWeight: "700",
                  lineHeight: "32px",
                }}
              >
                Advance Transaction
              </Typography>
            </ThemeProvider>
          </Grid>
          <Grid item>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography className={classes.breadCrumbs}>Home</Typography>

              <Typography className={classes.breadCrumbs}>
                Transaction
              </Typography>

              <Typography className={classes.breadCrumbsActive}>
                Detail Transaction
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>

        <Divider className={classes.divider} />

        <Grid container>
          <div>
            <ThemeProvider theme={theme}>
              <Typography
                component="h1"
                variant="h3"
                className={classes.subTitle}
              >
                Detail Credit Note
              </Typography>
            </ThemeProvider>
            <ThemeProvider theme={theme}>
              <p
                style={{
                  marginTop: "5px",
                  color: "#8f8f8f",
                  fontSize: "14px",
                }}
              >
                This is detail credit note information, in advance transaction.
              </p>
            </ThemeProvider>
          </div>
        </Grid>

        <Grid container>
          <Grid item xs={12} md={8} lg={8} xl={8}>
            <Card
              style={{
                border: "1px solid #9AA2B1",
                borderRadius: "6px",
                boxShadow: "none",
              }}
            >
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6} lg={6} xl={6}>
                    {typeof state.customer.img === "undefined" ? (
                      <>{""}</>
                    ) : (
                      <>
                        <img
                          src={state.customer.img}
                          alt="img"
                          style={{
                            width: "155.52px",
                            height: "80px",
                          }}
                        />
                      </>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6} lg={6} xl={6}>
                    <div
                      style={{
                        textAlign: "right",
                      }}
                    >
                      <div>
                        <ThemeProvider theme={theme}>
                          <Typography
                            style={{
                              fontSize: "14px",
                              fontWeight: "600",
                              lineHeight: "20px",
                            }}
                          >
                            {"CARIS MUSIC ENTERTAINMENT"}
                          </Typography>
                          <Typography
                            style={{
                              fontSize: "14px",
                              fontWeight: "400",
                              lineHeight: "17px",
                              color: "#6F6F84",
                              wordWrap: "break-word",
                            }}
                          >
                            {state.customer.composer_address === "" ? (
                              <>
                                <span>-</span>
                              </>
                            ) : (
                              <>{state.customer.composer_address}</>
                            )}
                          </Typography>
                        </ThemeProvider>
                      </div>
                    </div>
                  </Grid>
                </Grid>

                <Divider className={classes.divider} />

                <Grid container spacing={3}>
                  <Grid item xs={12} md={6} lg={6} xl={6}>
                    <ThemeProvider theme={theme}>
                      <Typography
                        style={{
                          fontSize: "14px",
                          fontWeight: "400",
                          lineHeight: "16.94px",
                        }}
                      >
                        Advance DSP :
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "14px",
                          fontWeight: "600",
                          lineHeight: "16.94px",
                        }}
                      >
                        {state.customer.dsp_name}
                      </Typography>
                    </ThemeProvider>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6} xl={6}>
                    <ThemeProvider theme={theme}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          style={{
                            fontSize: "14px",
                            fontWeight: "400",
                            lineHeight: "16.94px",
                          }}
                        >
                          Transaction Number :
                        </Typography>

                        <Typography
                          style={{
                            fontSize: "14px",
                            fontWeight: "400",
                            lineHeight: "16.94px",
                          }}
                        >
                          {state.customer.transaction_number}
                        </Typography>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: "5px",
                        }}
                      >
                        <Typography
                          style={{
                            fontSize: "14px",
                            fontWeight: "400",
                            lineHeight: "16.94px",
                          }}
                        >
                          Type :
                        </Typography>

                        <Typography
                          style={{
                            fontSize: "14px",
                            fontWeight: "400",
                            lineHeight: "16.94px",
                            textTransform: "capitalize",
                          }}
                        >
                          {state.customer.type}
                        </Typography>
                      </div>
                    </ThemeProvider>
                  </Grid>
                </Grid>
                <Divider className={classes.divider} />

                <Grid container>
                  <div>
                    <ThemeProvider theme={theme}>
                      <Typography
                        style={{
                          fontSize: "14px",
                          fontWeight: "600",
                        }}
                      >
                        Song Detail :
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        {state.customer.notes}
                      </Typography>
                    </ThemeProvider>
                  </div>
                </Grid>
                <div
                  style={{
                    marginTop: "20px",
                  }}
                >
                  <ThemeProvider theme={theme}>
                    <Table
                      size="small"
                      style={{
                        border: "1px solid #9AA2B1",
                        borderRadius: "18px",
                      }}
                    >
                      <TableHead
                        style={{
                          backgroundColor: "#F9FAFB",
                        }}
                      >
                        <TableRow>
                          <TableCell
                            variant="subtitle2"
                            className={classes.headText}
                          >
                            Song
                          </TableCell>
                          <TableCell
                            variant="subtitle2"
                            className={classes.headText}
                          >
                            Composer
                          </TableCell>
                          <TableCell
                            variant="subtitle2"
                            align="right"
                            className={classes.headText}
                          >
                            Traffic
                          </TableCell>
                          <TableCell
                            variant="subtitle2"
                            align="right"
                            className={classes.headText}
                          >
                            Revenue
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {state.customer.loan_array.length > 0 ? (
                          state.customer.loan_array.map(customer => (
                            <TableRow hover key={customer.id}>
                              <ThemeProvider theme={theme}>
                                <TableCell>
                                  <Typography
                                    variant="subtitle2"
                                    className={classes.bodyTextBlack}
                                  >
                                    {customer.subject}
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography
                                    variant="subtitle2"
                                    className={classes.bodyTextBlack}
                                  >
                                    {customer.tenor}
                                  </Typography>
                                </TableCell>

                                <TableCell align="right">
                                  <Typography
                                    variant="subtitle2"
                                    className={classes.bodyTextBlack}
                                  >
                                    {customer.value}
                                  </Typography>
                                </TableCell>
                              </ThemeProvider>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={6}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <Typography variant="subtitle1">
                                  No data available
                                </Typography>
                              </div>
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </ThemeProvider>
                </div>

                <div
                  style={{
                    marginTop: "20px",
                  }}
                >
                  <ThemeProvider theme={theme}>
                    <Typography
                      style={{
                        fontSize: "14px",
                        fontWeight: "400",
                        textAlign: "right",
                      }}
                    >
                      Recoupment Total :
                    </Typography>
                    <Typography
                      style={{
                        fontSize: "24px",
                        fontWeight: "600",
                        textAlign: "right",
                      }}
                    >
                      {state.customer.revenue}
                    </Typography>
                  </ThemeProvider>
                </div>
                <div
                  style={{
                    marginTop: "32px",
                    marginBottom: "32px",
                    float: "right",
                  }}
                >
                  <Button
                    style={{
                      marginLeft: "10px",
                      backgroundColor: "#111827",
                      borderRadius: "6px",
                      paddingLeft: "30px",
                      paddingRight: "30px",
                      height: "40px",
                      color: "#FFFFFF",
                      textTransform: "none",
                    }}
                  >
                    Confirm Payment
                  </Button>
                </div>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4} lg={4} xl={4}></Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default DetailAdvanced;
