import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import { LockOutlined } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import {
  PasswordInput,
  PrimaryButton,
} from "components";
import Axios from "axios";
import Swal from "sweetalert2";
import { hardBaseUrl } from "../../../services/urlConstant";
import "../Login/Login.css";

export const useStyles = makeStyles({
  loginFieldIcon: {
    width: "16px",
  },
  changePassword: {
    backgroundColor: "black",
    border: "none",
    width: "100%",
    height: 44,
    borderRadius: 5,
    color: "white",
  },
  subtitle1: {
    color: "#687083",
    marginTop: "1rem"
  },
  input: {
    "& .MuiOutlinedInput-root": {
      height: 44,
    },
  }
});

export default function NewPassword() {
  let history = useHistory();
  const classes = useStyles();
  const [body, setBody] = useState({
    token: useParams().token,
    password: "",
    confirm_password: "",
  });
  const { confirm_password, password, token } = body;

  const handleForgot = async e => {
    e.preventDefault();
    try {
      if (confirm_password !== password || !confirm_password || !password) {
        Swal.fire({
          icon: "error",
          text: "Mohon masukan password dan confirm password dengan benar",
          showConfirmButton: false,
        });
      } else {
        const response = await Axios.post(
          hardBaseUrl + "/auth/reset-password/forgot",
          {
            token: token,
            new_password: password,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.data.message === "success") {
          Swal.fire({
            icon: "success",
            text: "Berhasil merubah password anda!",
            showConfirmButton: true,
          });
          setTimeout(() => {
            history.push("/");
          }, 2000);
        }
      }
    } catch (error) {
      new Error(error);
    }
  };

  return (
    <Box height="100vh" display="flex" justifyContent="center" alignItems="center">
      <Box width="30%">
        <Grid container spacing={3}>
          <Grid item>
            <Box display="flex" justifyContent="center" flexDirection="column" textAlign="center">
              <Typography variant="h4">
                Buat Password Baru
              </Typography>
              <Typography component="subtitle1" className={classes.subtitle1}>
                Password baru Anda harus berbeda dengan password Anda sebelumnya.
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <form className="input-form" onSubmit={handleForgot}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <PasswordInput
                    placeholder="Password"
                    className={classes.input}
                    onChange={e =>
                      setBody((state) => ({
                        ...state,
                        password: e.target.value,
                      }))
                    }
                    value={password}
                    startAdornment={
                      <LockOutlined className={classes?.loginFieldIcon} />
                    }
                    width="100%"
                  />
                </Grid>
                <Grid item xs={12}>
                  <PasswordInput
                    placeholder="Confirm Password"
                    className={classes.input}
                    onChange={e =>
                      setBody((state) => ({
                        ...state,
                        confirm_password: e?.target?.value,
                      }))
                    }
                    value={confirm_password}
                    startAdornment={
                      <LockOutlined className={classes?.loginFieldIcon} />
                    }
                    width="100%"
                  />
                </Grid>
                <Grid item xs={12}>
                  <PrimaryButton
                    type="primary"
                    width="100%"
                    height={44}
                    className={classes.changePassword}
                    htmlType="submit"
                  >
                    Ubah Password
                  </PrimaryButton>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
