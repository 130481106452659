import {
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  OutlinedInput,
  TextField,
  Typography,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { makeStyles } from "@material-ui/styles";
import moment from "moment";
import axios from "axios";
import { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { useHistory } from "react-router";
import Swal from "sweetalert2";
import { Page } from "components";
import { hardBaseUrl } from "../../../../services/urlConstant";

const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),

    paddingBottom: theme.spacing(3),
  },

  results: {
    marginTop: theme.spacing(3),
  },

  divider: {
    margin: theme.spacing(2, 0),

    borderTop: "1px solid #e1e1e1",
  },

  formControl: {},

  mypadding: {
    "& .MuiSelect-outlined": {
      paddingTop: "10.5px",

      paddingBottom: "10.5px",
    },
  },

  selectEmpty: {
    marginTop: theme.spacing(2),
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,

    color: "#fff",
  },

  pagination: {
    "& .Mui-selected": {
      backgroundColor: "black",

      color: "white",
    },
  },

  pageSize: {
    height: "30px",

    borderRadius: "6px",

    border: "1px solid #D1D5DC",

    paddingLeft: "5px",

    paddingRight: "5px",

    marginTop: "5px",
  },

  paginationText: {
    fontSize: "14px",

    fontWeight: "normal",

    fontStyle: "normal",

    color: "#687083",
  },

  breadcrumbs: {
    fontSize: "14px",

    fontWeight: 400,

    fontStyle: "regular",

    color: "#111827",
  },

  breadCrumbsActive: {
    fontSize: "14px",

    fontWeight: 400,

    fontStyle: "regular",

    color: "#687083",
  },

  placeholder: {
    "&::placeholder": {
      color: "#9AA2B1",
    },
  },

  title: {
    fontFamily: "Helvetica Neue",

    fontStyle: "normal",

    fontWeight: "bold",

    fontSize: "18px",

    lineHeight: "28px",
  },

  label: {
    color: "#364052",

    fontSize: "14px",

    fontWeight: 500,
  },

  subTitle: {
    fontSize: "18px",

    fontWeight: 700,

    color: " #111827",
  },
}));

const AddLoan = () => {
  const classes = useStyles();

  const history = useHistory();

  const [loanValue, setLoanValue] = useState(0);

  const [notes, setNotes] = useState("");

  const [tenor, setTenor] = useState(0);

  const [date, setDate] = useState(moment().toString());
  const [dataPublisher, setDataPublisher] = useState({});

  const handleDateChangeDate = date => {
    setDate(date);
  };

  const handleChangeTenor = event => {
    setTenor(event.target.value);
  };

  const getTransactionNumber = () => {
    const token = localStorage.getItem("token");

    const url = `${hardBaseUrl}/publisher/transaction-number/loan`;

    axios

      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      .then(() => { })

      .catch(() => { });
  };

  const handleChangeLoanValue = event => {
    setLoanValue(event.target.value);
  };

  const handleChangeNotes = event => {
    setNotes(event.target.value);
  };

  const formatDate = date => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;

    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const onSubmit = event => {
    event.preventDefault();

    const token = localStorage.getItem("token");

    const url = `${hardBaseUrl}/composer/transaction/loan`;

    let newFormatMoney = loanValue.replace(/,/g, "");
    const revenue = Number(newFormatMoney);

    const payload = {
      date: formatDate(date),
      value: Number(revenue),
      tenor: Number(tenor),
      note: notes,
    };

    axios

      .post(url, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      .then(res => {
        if (res.status === 200) {
          Swal.fire({
            title: "Success",

            text: "Loan has been added",

            icon: "success",

            confirmButtonText: "Ok",
          }).then(result => {
            if (result.value) {
              history.push("/pencipta/loan");
            }
          });
        }
      })

      .catch(() => { });
  };

  const getComposerList = () => {
    const token = localStorage.getItem("token");

    const url = `${hardBaseUrl}/publisher/transaction-composer`;

    axios

      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      .then(() => { })

      .catch(() => { });
  };

  useEffect(() => {
    getTransactionNumber();

    getComposerList();
  }, []);

  const getCurrentPublisher = () => {
    const token = localStorage.getItem("token");
    const url = `${hardBaseUrl}/current/publisher`;
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(res => {
        setDataPublisher(res.data.data);
      })
      .catch(() => { });
  };

  useEffect(() => {
    getCurrentPublisher();
  }, []);

  return (
    <Page className={classes.root} title="Loan">
      <Container maxWidth={false}>
        <Grid
          alignItems="flex-end"
          container
          justifyContent="space-between"
          spacing={3}
        >
          <Grid item>
            <ThemeProvider theme={theme}>
              <Typography
                style={{
                  fontSize: "24px",

                  fontWeight: "700",

                  lineHeight: "32px",
                }}
              >
                Loan Transaction
              </Typography>
            </ThemeProvider>
          </Grid>

          <Grid item>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography className={classes.breadCrumbs}>Home</Typography>

              <Typography className={classes.breadCrumbs}>
                Transaction
              </Typography>

              <Typography className={classes.breadCrumbsActive}>
                Create Transaction
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>

        <Divider className={classes.divider} />

        <Grid container>
          <div>
            <ThemeProvider theme={theme}>
              <Typography
                component="h1"
                variant="h3"
                className={classes.subTitle}
              >
                Create Transaction
              </Typography>
            </ThemeProvider>

            <ThemeProvider theme={theme}>
              <p
                style={{
                  marginTop: "5px",

                  color: "#8f8f8f",

                  fontSize: "14px",
                }}
              >
                Create your loan transaction here.
              </p>
            </ThemeProvider>
          </div>
        </Grid>

        <Grid container>
          <Grid item xs={12} md={8} lg={8} xl={8}>
            <Card
              style={{
                border: "1px solid #9AA2B1",

                borderRadius: "6px",

                boxShadow: "none",
              }}
            >
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6} lg={6} xl={6}>
                    <div
                      style={{
                        marginTop: "5px",

                        marginBottom: "0px",
                      }}
                    >
                      <div
                        style={{
                          marginTop: "10px",

                          marginBottom: "3px",
                        }}
                      >
                        <ThemeProvider theme={theme}>
                          <Typography className={classes.label}>
                            Date
                          </Typography>
                        </ThemeProvider>
                      </div>

                      <div
                        style={{
                          marginTop: "-10px",

                          marginBottom: "-5px",
                        }}
                      >
                        { }

                        <KeyboardDatePicker
                          id="outlined-basic"
                          variant="outlined"
                          onChange={handleDateChangeDate}
                          name="date"
                          format="DD-MM-YYYY"
                          minDate={new Date()}
                          inputVariant="outlined"
                          fullWidth={true}
                          margin="dense"
                        />
                      </div>

                      <div
                        style={{
                          marginTop: "10px",

                          marginBottom: "10px",
                        }}
                      >
                        <ThemeProvider theme={theme}>
                          <Typography className={classes.label}>
                            Tenor
                          </Typography>
                        </ThemeProvider>
                      </div>

                      <div
                        style={{
                          marginTop: "-10px",

                          marginBottom: "10px",
                        }}
                      >
                        <OutlinedInput
                          id="outlined-basic"
                          name="tenor"
                          variant="outlined"
                          fullWidth={true}
                          margin="dense"
                          type="number"
                          value={tenor}
                          onChange={event => handleChangeTenor(event)}
                          placeholder="Tenor"
                          endAdornment={<div>Month</div>}
                        />
                      </div>

                      { }

                      <div
                        style={{
                          marginBottom: "5px",
                        }}
                      >
                        { }
                      </div>
                    </div>
                  </Grid>

                  <Grid item xs={12} md={6} lg={6} xl={6}>
                    <div
                      style={{
                        marginTop: "5px",

                        marginBottom: "5px",
                      }}
                    >
                      { }

                      <div
                        style={{
                          marginTop: "10px",

                          marginBottom: "10px",
                        }}
                      >
                        <ThemeProvider theme={theme}>
                          <Typography className={classes.label}>
                            Publisher
                          </Typography>
                        </ThemeProvider>
                      </div>

                      <div
                        style={{
                          marginTop: "-10px",

                          marginBottom: "10.5px",
                        }}
                      >
                        <FormControl fullWidth={true}>
                          <OutlinedInput
                            id="outlined-adornment-amount"
                            fullWidth={true}
                            name="loanValue"
                            placeholder="Publisher"
                            className={classes.tlpnField}
                            margin="dense"
                            readOnly
                            value={dataPublisher?.name}
                          />
                        </FormControl>
                      </div>

                      <div
                        style={{
                          marginTop: "10px",
                          display: "none",
                          marginBottom: "10px",
                        }}
                      >
                        <ThemeProvider theme={theme}>
                          <Typography className={classes.label}>
                            Loan Value
                          </Typography>
                        </ThemeProvider>
                      </div>

                      <div
                        style={{
                          marginTop: "-10px",
                          display: "none",
                          marginBottom: "10px",
                        }}
                      >
                        <OutlinedInput
                          id="outlined-adornment-amount"
                          fullWidth={true}
                          name="loanValue"
                          type="number"
                          placeholder="Loan Value"
                          className={classes.tlpnField}
                          style={{ paddingLeft: "0px" }}
                          onChange={event => handleChangeLoanValue(event)}
                          margin="dense"
                          inputProps={{
                            style: { paddingLeft: "0px" },
                          }}
                          startAdornment={
                            <div
                              style={{
                                display: "flex",

                                flexDirection: "column",

                                justifyContent: "center",

                                alignContent: "center",

                                backgroundColor: "#D1D5DC",

                                height: 35,

                                width: 45,

                                marginRight: "6px",
                              }}
                            >
                              <InputAdornment
                                position="start"
                                style={{
                                  color: "#9AA2B1",

                                  marginLeft: "6px",
                                }}
                              >
                                Rp
                              </InputAdornment>
                            </div>
                          }
                        />
                      </div>

                      <Grid item md={12} style={{ paddingRight: "8px" }}>
                        <div>
                          <ThemeProvider theme={theme}>
                            <Typography className={classes.label}>
                              Loan Value
                            </Typography>
                          </ThemeProvider>
                        </div>
                        <Grid style={{ display: "flex" }}>
                          <Grid
                            style={{
                              width: "48px",
                              height: "38px",
                              marginRight: "-2px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "6px 0px 0px 6px",
                              backgroundColor: "#F9FAFB",
                              border: " 1px solid #D1D5DC",
                              color: "#9AA2B",
                              borderRight: "none",
                            }}
                          >
                            Rp
                          </Grid>
                          <NumberFormat
                            customInput={TextField}
                            variant="outlined"
                            thousandSeparator={true}
                            onChange={e => setLoanValue(e.target.value)}
                            autoComplete="off"
                            className={classes.Input}
                            style={{
                              paddingLeft: "0px",
                              height: "44px",
                              borderRadius: "0px 6px 6px 0px",
                              width: "100%",
                            }}
                            size="small"
                          />
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                </Grid>

                <Grid>
                  <div>
                    <div
                      style={{
                        marginTop: "10px",

                        marginBottom: "10px",
                      }}
                    >
                      <ThemeProvider theme={theme}>
                        <Typography className={classes.label}>Notes</Typography>
                      </ThemeProvider>
                    </div>

                    <div
                      style={{
                        marginTop: "-10px",

                        marginBottom: "10px",
                      }}
                    >
                      <TextField
                        id="outlined-basic"
                        name="notes"
                        variant="outlined"
                        fullWidth={true}
                        multiline
                        rows={4}
                        margin="dense"
                        type="text"
                        onChange={event => handleChangeNotes(event)}
                        placeholder="Notes"
                      />
                    </div>
                  </div>
                </Grid>

                <Grid container justifyContent="flex-end">
                  <Button
                    style={{
                      borderRadius: "6px",
                      textTransform: "none",
                      width: "84px",

                      height: "40px",

                      border: "1px solid #D1D5DC",
                    }}
                  >
                    Cancel
                  </Button>

                  <Button
                    style={{
                      marginLeft: "10px",
                      textTransform: "none",
                      backgroundColor: "#111827",

                      borderRadius: "6px",

                      width: "84px",

                      height: "40px",

                      color: "#FFFFFF",
                    }}
                    onClick={e => onSubmit(e)}
                  >
                    Add
                  </Button>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={4} lg={4} xl={4}></Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default AddLoan;
