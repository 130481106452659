import { useMediaQuery, useTheme } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const interFont = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

export const CustomSlide = props => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <div
      style={{
        height: isMobile ? "" : "510px",
        width: isMobile ? "" : "1200px",
      }}
    >
      <div
        style={{
          position: isMobile ? "" : "absolute",
          marginBottom: isMobile ? "20px" : "",
          top: 0,
          left: 0,
          maxWidth: isMobile ? "365px" : "600px",
          height: isMobile ? "" : "300px",
          backgroundColor: "#FE1A9B",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <img
          key={props?.index}
          alt={props?.name}
          src={props?.image}
          style={{
            height: isMobile ? "250px" : "300px",
          }}
        />
      </div>
      <div
        style={{
          position: isMobile ? "" : "absolute",
          top: isMobile ? "" : "76px",
          right: 0,
          width: isMobile ? "365px" : "740px",
          backgroundColor: "#642C8C",
          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
          padding: isMobile ? "20px" : "56px",
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          color: "white",
        }}
      >
        <div
          style={{
            marginRight: !isMobile && "64px",
          }}
        >
          <ThemeProvider theme={interFont}>
            <Typography
              style={{
                fontWeight: 700,
                fontSize: isMobile ? "18px" : "24px",
                lineHeight: "32px",
              }}
            >
              {props?.name}
            </Typography>
            <Typography
              style={{
                fontWeight: 400,
                fontSize: isMobile ? "12px" : "16px",
                lineHeight: isMobile ? "16px" : "24px",
                marginBottom: isMobile ? "16px" : "",
              }}
            >
              {props?.position}
            </Typography>
          </ThemeProvider>
        </div>
        <div>
          <ThemeProvider theme={interFont}>
            <Typography
              style={{
                fontWeight: 400,
                fontSize: isMobile ? "12px" : "16px",
                lineHeight: isMobile ? "16px" : "24px",
                maxHeight: "240px",
                overflow: "hidden",
              }}
            >
              {props?.content}
            </Typography>
          </ThemeProvider>
        </div>
      </div>
    </div>
  );
};
