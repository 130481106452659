import { Container, Divider } from "@material-ui/core";
import { Box } from "@mui/material";
import {
  ArrayChip,
  ArrayTagChip,
  CurrencyDisplay,
  CustomTable,
  Page,
  PrimaryButton,
  SecondaryButton,
  SongUsageTrendChart,
  SongUsageTrendSummarytCard,
} from "components";
import { HeaderTitle } from "layouts";
import { useRef, useState } from "react";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { globalStyles } from "styles";
import { getCookie } from "utils";

const PrintReportReview = () => {
  const classes = globalStyles();
  const history = useHistory();
  const { state } = useLocation();
  const inputRef = useRef(null);
  const userLogin = getCookie("auth", `${process.env.REACT_APP_NAME}_user`);
  const roleComposer = userLogin?.role?.type === "composer";
  const roleSociety = userLogin?.role?.type === "society";
  const [loadingButton, setLoadingButton] = useState(false);
  const { dataTable, chartData, chartReport, selectedDSP } = state;

  const handlePrintPDF = useReactToPrint({
    content: () => inputRef.current,
    onBeforeGetContent: () => setLoadingButton(true),
    onAfterPrint: () => setLoadingButton(false),
  });

  return (
    <Page className={classes.root} title="Review of Song Usage">
      <Container maxWidth={false} id="content-to-print" ref={inputRef}>
        <HeaderTitle title="Review of Song Usage" />
        <Divider className={classes.divider} />
        <Box border="1px solid #ebebeb" borderRadius="5px" p="50px 20px">
          <Box mb={2}>
            {selectedDSP?.length > 0 && (
              <ArrayTagChip
                selectionsList={
                  selectedDSP.some(item => item.dsp_id === "all")
                    ? [{ dsp_id: "all", name: "All" }]
                    : selectedDSP
                }
                value="name"
              />
            )}
          </Box>
          <SongUsageTrendChart chartData={chartData} preview />
        </Box>
        <SongUsageTrendSummarytCard chartReport={chartReport} />
        <CustomTable
          columnTable={columnTable({ roleComposer, roleSociety })}
          data={dataTable || []}
          maxHeight="100%"
          tableSize="medium"
        />
      </Container>
      <Box justifyContent="flex-end" display="flex" gap={1} m={3}>
        <SecondaryButton label="Back" onClick={() => history.goBack()} />
        <PrimaryButton
          label={loadingButton ? "Please Wait ..." : "Print Report"}
          loading={loadingButton}
          disabled={loadingButton}
          onClick={handlePrintPDF}
        />
      </Box>
    </Page>
  );
};
const columnTable = ({ roleComposer, roleSociety }) => [
  {
    name: roleComposer ? "title" : "SongTitle",
    title: "Song Title",
  },
  ...(roleComposer || roleSociety
    ? [
        {
          name: roleSociety ? "ISWC" : "iswc_code",
          title: "ISWC Code",
        },
      ]
    : []),
  {
    name: roleComposer ? "composer_names" : "Composer",
    title: `Composer${roleComposer ? "" : "/Author"}`,
    renderText: item => <ArrayChip list={item} />,
  },
  {
    name: roleComposer ? "listener" : "Traffic",
    title: "Traffic",
    headerAlign: "right",
    renderText: item => <CurrencyDisplay value={item} />,
  },
  {
    name: roleComposer ? "base_currency_revenue" : "OriginalCurrencyRevenue",
    title: "Revenue",
    headerAlign: "right",
    renderText: item => (
      <CurrencyDisplay value={item} prefix="Rp " decimalScale={2} />
    ),
  },
  ...(roleComposer || roleSociety
    ? []
    : [
        {
          name: "BaseCurrencyComposerRevenue",
          title: "Composer Revenue",
          headerAlign: "right",
          renderText: item => (
            <CurrencyDisplay value={item} prefix="Rp " decimalScale={2} />
          ),
        },
        {
          name: "BaseCurrencyPublisherRevenue",
          title: "Publisher Revenue",
          headerAlign: "right",
          renderText: item => (
            <CurrencyDisplay value={item} prefix="Rp " decimalScale={2} />
          ),
        },
      ]),
  ...(roleComposer
    ? []
    : [
        {
          name: "ProductType",
          title: "Product Type",
        },
      ]),
];

export default PrintReportReview;
