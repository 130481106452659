import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment-timezone";
import { getDatePickerYear, setDatePickerYear } from "utils";

const YearPicker = ({
  value,
  onChange,
  label,
  width,
  minDate,
  maxDate,
  ...rest
}) => {
  const dateValue = value ? setDatePickerYear(value) : null;
  const handleChangeYear = year => {
    const formattedYear = year ? getDatePickerYear(year) : null;
    onChange(formattedYear);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker
        label={label}
        views={["year"]}
        value={dateValue}
        onChange={handleChangeYear}
        slotProps={{
          textField: {
            size: "small",
            style: { width: width },
            fullWidth: true,
          },
          field: { clearable: true },
          popper: {
            sx: {
              "& .MuiDateCalendar-root": {
                height: "auto",
              },
            },
          },
        }}
        minDate={minDate || moment().subtract(10, "years")}
        maxDate={maxDate || moment()}
        {...rest}
      />
    </LocalizationProvider>
  );
};

export default YearPicker;
