import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Container } from "@material-ui/core";
import { Page } from "components";

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  results: {
    marginTop: theme.spacing(3),
  },
}));

function DetailBerita() {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Detail Berita">
      <Container maxWidth={false}>
        <h1>Detail Berita</h1>
      </Container>
    </Page>
  );
}

export default DetailBerita;
