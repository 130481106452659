import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  Typography,
  colors,
} from "@material-ui/core";
import {
  ThemeProvider,
  createTheme,
  makeStyles,
} from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import newlogompis from "../../../assets/images/newlogompis.png";
import newLogoMpisPurple from "../../../assets/images/NewLogoMpisPurple.png";
import avatarAdi from "../../../assets/images/testimony_adi_grey.png";

export const interFont = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  divider: {
    backgroundColor: colors.grey[300],
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  ib: {
    padding: "4px",
    color: "black",
    backgroundColor: "white",
    borderRadius: "40px",
    height: "38px",
    width: "38px",
    fontSize: "16px",
  },
}));
const DetailPartner = () => {
  const classes = useStyles();
  return (
    <>
      <div id="section-1">
        <div
          id="img-background"
          style={{
            position: "relative",
            width: "100%",
            height: "100vh",
            maxHeight: "250px",
            borderTop: "40px solid #E80088",
          }}
        >
          <div
            id="top-section-1"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              zIndex: 3,
              width: "100%",
              position: "absolute",
              top: "0",
              left: "0",
              paddingTop: "21px",
            }}
          >
            <Container maxWidth="xl">
              <div style={{ marginTop: "80px" }}>
                <Grid container spacing={3}>
                  <Grid item xs={1}></Grid>
                  <Grid item xs={2}>
                    <a href="/home">
                      <img
                        alt={"new-mpis"}
                        src={newLogoMpisPurple}
                        style={{
                          zIndex: 2,
                        }}
                      />
                    </a>
                  </Grid>
                  <Grid item xs={1}></Grid>
                  <Grid
                    item
                    xs={4}
                    style={{
                      marginTop: "30px",
                    }}
                  >
                    <Grid container>
                      <Grid item xs={3}>
                        <Typography
                          style={{
                            color: "#642C8C",
                          }}
                          fontSize={"14px"}
                          fontFamily="Inter"
                          fontWeight={400}
                        >
                          About Us
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <a
                          style={{
                            color: "#642C8C",
                            fontSize: "14px",
                            fontWeight: 400,
                            fontFamily: "Inter",
                          }}
                          href="/partner"
                        >
                          Our Partners
                        </a>
                      </Grid>
                      <Grid item xs={3}>
                        <Link
                          style={{
                            color: "#642C8C",
                            fontSize: "14px",
                            fontWeight: 400,
                            fontFamily: "Inter",
                          }}
                          to={"/blog"}
                        >
                          Blog
                        </Link>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography
                          style={{
                            color: "#642C8C",
                          }}
                          fontSize={"14px"}
                          fontFamily="Inter"
                          fontWeight={400}
                        >
                          <a
                            style={{
                              color: "#642C8C",
                              fontSize: "14px",
                              fontWeight: 400,
                              fontFamily: "Inter",
                            }}
                            href="/contact"
                          >
                            Contact Us
                          </a>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    style={{
                      marginTop: "24px",
                    }}
                  >
                    <Grid container spacing={1}>
                      <Grid item xs={2}></Grid>
                      <Grid item xs={2}>
                        <Button
                          variant="outlined"
                          style={{
                            color: "#642C8C",
                            borderColor: "#642C8C",
                            borderRadius: "6px",
                            textTransform: "none",
                          }}
                        >
                          Login
                        </Button>
                      </Grid>
                      <Grid item xs={3}>
                        <Button
                          variant="outlined"
                          style={{
                            color: "#642C8C",
                            borderColor: "#642C8C",
                            borderRadius: "6px",
                            textTransform: "none",
                          }}
                        >
                          Watch Demo
                        </Button>
                      </Grid>
                      <Grid item xs={1}>
                        <IconButton
                          classes={{
                            root: classes.ib,
                          }}
                        >
                          ID
                        </IconButton>
                      </Grid>
                      <Grid item xs={1}>
                        <IconButton
                          classes={{
                            root: classes.ib,
                          }}
                        >
                          EN
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </Container>
          </div>
        </div>
      </div>

      { }
      <div
        id="section-2"
        style={{
          marginBottom: "32px",
        }}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          gridGap={"64px"}
          padding={"64px 120px"}
        >
          <Box display={"flex"} justifyContent={"center"}>
            <Typography
              style={{
                fontSize: "32px",
                fontWeight: 700,
                lineHeight: "44.8px",
                color: "#642C8C",
              }}
            >
              Detail Testimoni
            </Typography>
          </Box>
          <Box display={"flex"} gridGap={"40px"}>
            <Box
              style={{
                backgroundColor: "#652D8D",
                maxHeight: "250px",
                marginLeft: "20px",
              }}
            >
              <img src={avatarAdi} />
            </Box>

            <Box
              style={{ padding: "10px 0px" }}
              display={"flex"}
              flexDirection={"column"}
              gridGap={"24px"}
            >
              <Box>
                <Typography
                  style={{
                    color: "#E80088",
                    fontSize: "24px",
                    fontWeight: 700,
                    lineHeight: "33.6px",
                  }}
                >
                  Adi
                </Typography>
                <Typography
                  style={{
                    color: "#E80088",
                    fontSize: "24px",
                    fontWeight: 700,
                    lineHeight: "33.6px",
                  }}
                >
                  (Finance)
                </Typography>
              </Box>
              <Typography
                style={{
                  fontSize: "16px",
                  fontWeight: 400,
                  lineHeight: "20px",
                  color: "#642C8C",
                  textOverflow: "ellipsis",
                }}
              >
                {`I'm often asked to give a royalty and song usage report in a
                short time. To do this, I had to process the report data I
                received from the client. Often the reports given are very
                difficult to understand and I need more time to process them
                into reports that are acceptable to my superiors and colleagues.
                Using MPIS I was able to create reports very easily, I just
                checked the Dashboard and downloaded the data I needed. MPIS
                greatly saved my time, I could do other tasks without having to
                worry about royalty and songs usage reports anymore.`}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Grid
          container
          spacing={3}
          style={{
            marginTop: "32px",
            backgroundColor: "#F9FAFB",
          }}
        ></Grid>
      </div>

      { }
      <div
        id="section-3"
        style={{
          backgroundColor: "#652D8D",
        }}
      >
        { }
        <Grid container spacing={3}>
          <Grid item xs={2}></Grid>
          <Grid item xs={8}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  marginTop: "88px",
                  marginBottom: "40px",
                }}
              >
                <img
                  alt={"quote-thumbnail"}
                  src={require("assets/image-public/images/quote-symbol.png").default}
                  style={{
                    zIndex: 2,
                  }}
                />
              </div>
              <div>
                <ThemeProvider theme={interFont}>
                  <Typography
                    style={{
                      fontWeight: 500,
                      fontSize: "24px",
                      lineHeight: "32px",
                      color: "white",
                    }}
                  >
                    Solutions for the music industry to transparent royalty
                    system
                  </Typography>
                </ThemeProvider>
              </div>

              <div
                style={{
                  marginTop: "40px",
                }}
              >
                <img
                  alt={"quote-thumbnail"}
                  src={require("assets/image-public/images/quote-symbol.png").default}
                  style={{
                    zIndex: 2,
                  }}
                />
              </div>
            </div>
          </Grid>
          <Grid item xs={2}></Grid>
        </Grid>

        { }
        <Grid container spacing={3}>
          <Grid item xs={1}></Grid>
          <Grid item xs={11}>
            <div
              style={{
                display: "flex",
              }}
            >
              <div>
                <img
                  alt={"new-mpis"}
                  src={newlogompis}
                  style={{
                    zIndex: 2,
                  }}
                />
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={1}></Grid>
          <Grid
            item
            xs={3}
            style={{
              flexDirection: "column",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div>
              <ThemeProvider theme={interFont}>
                <Typography
                  style={{
                    fontWeight: 400,
                    fontSize: "14px",
                    lineHeight: "20px",
                    color: "white",
                  }}
                >
                  &copy; 2022 Music Publishing Information System
                </Typography>
              </ThemeProvider>
            </div>
          </Grid>
          <Grid
            item
            xs={5}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Divider className={classes.divider} />
          </Grid>
          <Grid
            item
            xs={2}
            style={{
              display: "flex",
              justifyContent: "end",
            }}
          >
            <div
              style={{
                display: "flex",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <img
                  alt={"new-mpis"}
                  src={require("assets/image-public/images/Frame-530.png").default}
                  style={{
                    zIndex: 2,
                  }}
                />
              </div>
              <div
                style={{
                  marginLeft: "16px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <img
                  alt={"new-mpis"}
                  src={require("assets/image-public/images/Frame-531.png").default}
                  style={{
                    zIndex: 2,
                  }}
                />
              </div>
              <div
                style={{
                  marginLeft: "16px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <div>
                  <ThemeProvider theme={interFont}>
                    <Typography
                      style={{
                        fontWeight: 700,
                        fontSize: "18px",
                        lineHeight: "28px",
                        color: "white",
                      }}
                    >
                      Easy
                    </Typography>
                  </ThemeProvider>
                </div>
                <div>
                  <ThemeProvider theme={interFont}>
                    <Typography
                      style={{
                        fontWeight: 700,
                        fontSize: "18px",
                        lineHeight: "28px",
                        color: "white",
                      }}
                    >
                      Flexible
                    </Typography>
                  </ThemeProvider>
                </div>
                <div>
                  <ThemeProvider theme={interFont}>
                    <Typography
                      style={{
                        fontWeight: 700,
                        fontSize: "18px",
                        lineHeight: "28px",
                        color: "white",
                      }}
                    >
                      Streamline
                    </Typography>
                  </ThemeProvider>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={1}></Grid>
        </Grid>
      </div>
    </>
  );
};

export default DetailPartner;
