import { SET_MESSAGE, CLEAR_MESSAGE } from "../../../constants/types";

const initialState = {
  message: null,
  error: false,
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_MESSAGE:
      return {
        ...state,
        message: payload.message,
        error: payload.error,
      };
    case CLEAR_MESSAGE:
      return {
        ...state,
        message: null,
        error: false,
      };
    default:
      return state;
  }
}
