import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useHistory, useLocation } from "react-router";
import { makeStyles } from "@material-ui/styles";
import {
  Container,
  Divider,
  CircularProgress,
  Tooltip,
  Chip,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import Swal from "sweetalert2";
import axios from "axios";
import {
  AutoCompleteWithSearch,
  ButtonGroupTop,
  ModalError,
  ModalSuccess,
  Page,
  PrimaryButton,
  SearchTextInput,
  SkeletonComponent,
} from "components";
import { hardBaseUrl } from "../../../../services/urlConstant";
import ExcelLogo from "../../../../assets/img/excel-logo.png";
import DownloadLogo from "../../../../assets/img/download.png";
import { useDropzone } from "react-dropzone";
import DialogImportDokumen from "./DialogImportDokumen";
import { getErrors } from "utils";
import { InnoTable } from "inno-ui";
import {
  Box,
  Grid,
  IconButton,
  styled,
  Typography,
} from "@mui/material";
import { HeaderTitle } from "layouts";
import { debounce } from "lodash";
import { getTotalPage } from "lib";
import { CloseOutlined, InsertDriveFileOutlined } from "@material-ui/icons";

function Lagu({ userLogin }) {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const urlParams = new URLSearchParams(location.search);
  const paramsSearch = urlParams.get("search");
  const paramsPage = urlParams.get("page");
  const paramsSize = urlParams.get("size");
  const paramsComposer = urlParams.get("composer");

  const [loadingPage, setLoadingPage] = useState(false);
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [queryParams, setQueryParams] = useState({
    page: Number(paramsPage) || 1,
    size: Number(paramsSize) || 10,
    search: paramsSearch || "",
    original_publisher_id: "",
    sort: 0,
    composer_id: paramsComposer || "",
  });
  const [tableTotalPage, setTableTotalPage] = useState(1);
  const [searchComposer, setSearchComposer] = useState("");
  const [optionComposer, setOptionComposer] = useState([]);

  const handleChangePageParams = (value, key) => {
    urlParams.set(key, value);
    history.push({ search: urlParams.toString() });
  };
  const handleChangeQueryParams = (value, key) => {
    setQueryParams(currentState => ({
      ...currentState,
      ...(key !== "page" && { page: 1 }),
      [key]: value,
    }));
  };
  const handleChangeSearch = event => {
    const value = event?.target?.value;
    handleChangeQueryParams(value, "search");
    handleChangePageParams(value, "search");
  };
  const handleChangePage = page => {
    handleChangeQueryParams(page, "page");
    handleChangePageParams(page, "page");
  };
  const handleChangePageSize = size => {
    handleChangeQueryParams(size, "size");
    handleChangePageParams(size, "size");
  };
  const handleChangeComposer = option => {
    const composer_id = option?.composer_id || "";
    handleChangeQueryParams(composer_id, "composer_id");
    handleChangePageParams(composer_id, "composer");
  };

  const [loadingButton, setLoadingButton] = useState(false);
  const [openModalExcel, setOpenModalExcel] = useState(false);
  const [dokumen, setDokumen] = useState([]);
  const [selectedPublisherId, setSelectedPublisherId] = useState(null);

  const onDrop = useCallback(acceptedFiles => {
    setDokumen(acceptedFiles);
  }, []);
  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    accept: ".xlsx, .xls",
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const handleRemoveFile = idx => {
    setDokumen(dokumen.filter((_, i) => i !== idx));
    acceptedFiles.splice(idx, 1);
  };

  const truncate = (str, n) => {
    let newString = str?.length > n ? str.substr(0, n - 1) + "..." : str;
    return newString;
  };

  const files = acceptedFiles.map((file, i) => {
    return (
      <Box
        key={i}
        border="1px solid #D1D5DC"
        borderRadius="6px"
        p="8px 8px 8px 16px"
        mt="6px"
      >
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Box display="flex">
              <InsertDriveFileOutlined className={classes?.singleFileIcon} />
              <Typography fontSize="14px" ml="4px">
                {truncate(file.name, 20)}
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <IconButton onClick={() => handleRemoveFile(i)}>
              <CloseOutlined />
            </IconButton>
          </Grid>
        </Grid>
      </Box>
    );
  });

  const handleOpenModalExcel = () => {
    setOpenModalExcel(true);
  };
  const handleCloseModalExcel = () => {
    setOpenModalExcel(false);
  };

  const getDataTable = async () => {
    try {
      setLoadingPage(true);
      const res = await axios.get(`${hardBaseUrl}/songs`, {
        headers,
        params: queryParams,
      });

      const { data, meta } = res?.data;
      setDataTable(data);
      const pageCount = getTotalPage(meta?.total, queryParams?.size || 1);
      setTableTotalPage(pageCount);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  const debounceDataTable = useCallback(
    debounce(() => {
      getDataTable();
    }, 500),
    [queryParams]
  );
  const getOptionComposer = async () => {
    try {
      setLoadingFilter(true);
      const res = await axios.get(`${hardBaseUrl}/composers`, {
        headers,
        params: {
          page: 1,
          size: 50,
          ...(searchComposer && { search: searchComposer }),
        },
      });
      const modifiedData = res?.data?.data?.map(item => ({
        ...item,
        id: item?.composer_id,
        label: item?.sure_name,
      }));
      setOptionComposer(modifiedData);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingFilter(false);
    }
  };
  const debounceOptionComposer = useCallback(
    debounce(() => {
      getOptionComposer();
    }, 500),
    [searchComposer]
  );
  const handleDownloadDocument = async () => {
    try {
      setLoadingButton(true);
      const res = await axios.get(
        `${hardBaseUrl}/publisher/song/export/${queryParams?.composer_id || 0}`,
        { headers }
      );
      ModalSuccess(res.data?.message);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingButton(false);
    }
  };

  useEffect(() => {
    debounceDataTable();
    if (queryParams?.page !== paramsPage) {
      handleChangePageParams(queryParams?.page, "page");
    }
    return () => {
      debounceDataTable.cancel();
    };
  }, [queryParams, debounceDataTable]);
  useEffect(() => {
    debounceOptionComposer();
    return () => {
      debounceOptionComposer.cancel();
    };
  }, [searchComposer, debounceOptionComposer]);

  const handleImportDocument = () => {
    const token = localStorage.getItem("token");
    const url = `${hardBaseUrl}/publisher/song/import`;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const data = new FormData();

    data.append("files", dokumen[0]);
    data.append("publisher_id", selectedPublisherId);

    axios
      .post(url, data, config)
      .then(res => {
        if (res.data.message === "success") {
          setOpenModalExcel(false);
          Swal.fire({
            icon: "success",
            title: "Data is being processed",
            text:
              "You can check the progress of the upload process in the Upload Monitoring Menu",
            confirmButtonText: "Close",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
          }).then(result => {
            if (result.isConfirmed === true) {
              window.location.reload();
            }
          });
        }
      })
      .catch(err => {
        setOpenModalExcel(false);
        const dataError = err?.response?.data?.errors[0]?.message;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          html: dataError,
        }).then(res => {
          if (res.isConfirmed === true) {
            setOpenModalExcel(true);
          }
        });
      });
  };
  const handleSelectedPublisher = e => {
    if (e !== null) {
      setSelectedPublisherId(e.publisher_id);
    } else {
      setSelectedPublisherId(null);
    }
  };

  const renderComposer = (item, key1 = "", key2 = "") => {
    let newVal = item[key1][key2];
    return newVal;
  };

  const renderAliasSong = item => {
    let newItem = item;
    let itemLength = newItem?.length;
    let moreLength = itemLength - 3;
    let inside = newItem?.map((item, idx) => (
      <Box key={idx}>
        {idx === 3 ? (
          <React.Fragment>
            <Chip
              label={`+${moreLength}`}
              className={classes.tableChip}
              size="small"
            />
          </React.Fragment>
        ) : (
          <>
            {idx < 3 ? (
              <React.Fragment>
                <Chip label={item} className={classes.tableChip} size="small" />
              </React.Fragment>
            ) : null}
          </>
        )}
      </Box>
    ));

    return <React.Fragment>{inside}</React.Fragment>;
  };

  return (
    <Page className={classes.root} title="Song">
      {loadingPage ? (
        <SkeletonComponent variant="wave" />
      ) : (
        <Container maxWidth={false}>
          <HeaderTitle title="Parameter" breadcrumbData={breadcrumbData} />
          <ButtonGroupTop />
          <CustomDivider />
          <Grid container justifyContent="space-between" mb="16px">
            <Grid item>
              <Grid container columnSpacing={1}>
                <Grid item>
                  <SearchTextInput
                    placeholder="Search"
                    value={queryParams?.search}
                    onChange={handleChangeSearch}
                  />
                </Grid>
                <Grid item>
                  <AutoCompleteWithSearch
                    label="Composer/Author"
                    options={optionComposer}
                    optionLabel="sure_name"
                    loading={loadingFilter}
                    inputValue={searchComposer}
                    onInputChange={setSearchComposer}
                    value={
                      optionComposer?.find(
                        option =>
                          option?.composer_id === queryParams?.composer_id
                      ) || null
                    }
                    onChange={handleChangeComposer}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container columnSpacing={1}>
                <Tooltip title="Download Document">
                  <Grid item>
                    <PrimaryButton
                      onClick={handleDownloadDocument}
                      disabled={loadingButton}
                      label={
                        loadingButton ? (
                          <CircularProgress size="24px" color="inherit" />
                        ) : (
                          <img src={DownloadLogo} alt="logo" />
                        )
                      }
                      height="42px"
                    />
                  </Grid>
                </Tooltip>
                <Tooltip title="Import Document">
                  <Grid item>
                    <PrimaryButton
                      onClick={handleOpenModalExcel}
                      label={<img src={ExcelLogo} alt="logo" />}
                      height="42px"
                      color="#34774C"
                    />
                  </Grid>
                </Tooltip>
                <Grid item>
                  <PrimaryButton
                    label="Add Songs"
                    onClick={() => history.push("/admin/parameter/lagu/tambah")}
                    startIcon={<AddIcon />}
                    size="large"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <InnoTable
            isLoading={false}
            items={dataTable || []}
            page={queryParams?.page}
            rowsPerPage={queryParams?.size}
            totalPage={tableTotalPage}
            columns={[
              {
                name: "title_song",
                title: "Song Title",
                convertFunction: null,
              },
              {
                name: "song_aliases",
                title: "Alias Song Title",
                renderText: item => renderAliasSong(item),
              },
              {
                name: "publisher_name",
                title: "Publisher",
                convertFunction: null,
              },
              {
                name: "original_publisher_name",
                title: "Original Publisher",
                convertFunction: null,
              },
              { name: "iswc_code", title: "ISWC", convertFunction: null },
              {
                name: "song_composer",
                title: "Composer/Author",
                componentType: "chip",
                renderText: item =>
                  renderComposer(item, "composer", "sure_name"),
              },
              {
                name: "song_composer",
                title: "Composer Alias Name",
                componentType: "chip",
                selectedKeyInside: "alias_names",
              },
            ]}
            handleChangePage={(_, page) => handleChangePage(page)}
            handleChangeRowsPerPage={e =>
              handleChangePageSize(e?.target?.value)
            }
            isHaveAction={true}
            handleEdit={item => history.push(`/admin/parameter/lagu/edit/${item.song_id}`)}
          />
        </Container>
      )}
      <DialogImportDokumen
        open={openModalExcel}
        onClose={handleCloseModalExcel}
        getInputProps={getInputProps}
        getRootProps={getRootProps({ style })}
        isDragActive={isDragActive}
        files={files}
        onSubmit={handleImportDocument}
        onSelectedPublisher={handleSelectedPublisher}
        userLogin={userLogin}
      />
    </Page>
  );
}

const CustomDivider = styled(Divider)(() => ({
  marginBottom: 16,
  borderTop: "1px solid #e1e1e1",
}));

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  tableChip: {
    marginRight: "5px",
    backgroundColor: "#F9FAFB",
    border: "1px solid #D1D5DC",
  },
  singleFileIcon: {
    color: "#9AA2B1",
    height: "20px",
  },
}));

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "50px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#303030",
  borderStyle: "thin",
  backgroundColor: "lightgray",
  color: "black",
  outline: "none",
  transition: "border .24s ease-in-out",
  cursor: "pointer",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const breadcrumbData = [
  {
    label: "Home",
    link: "/admin/dashboard",
  },
  {
    label: "Parameter",
    link: "/admin/parameter/lagu",
  },
  {
    label: "Song",
    active: true,
  },
];
export default Lagu;
