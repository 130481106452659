import {
  Backdrop,
  Breadcrumbs,
  Button,
  Checkbox,
  CircularProgress,
  ClickAwayListener,
  Container,
  ThemeProvider,
  Divider,
  FormControl,
  FormControlLabel,
  InputAdornment,
  OutlinedInput,
  Paper,
  Box,
  TextField,
  Typography,
  createTheme,
} from "@material-ui/core";
import { ArrowDropDown, Clear } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import { InnoTable } from "inno-ui";
import moment from "moment";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Page, SkeletonComponent } from "components";
import { hardBaseUrl } from "../../services/urlConstant";

const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const getDateFormat = date => moment(date).format("DD MMM YYYY");
const getTimeFormat = date => moment(date).format("HH:mm:ss");

function InitCataloguePage() {
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: "Bearer " + token,
  };

  const [pageState, setPageState] = useState({
    loadingPage: false,
    menuVisiblePublisher: false,
    listPublisher: [],
    selectedPublisher: [],
    paramsPublisher: {
      page: 1,
      size: 100,
    },
    loadingTable: false,
    paramsTable: {
      page: 1,
      limit: 10,
    },
    dataTable: [],
    pagesCountTable: 1,
  });

  const {
    paramsTable,
    paramsPublisher,
    selectedPublisher,
    dataTable,
    pagesCountTable,
  } = pageState;
  const { page, limit } = paramsTable;
  const setValuePageState = (value, key) => {
    setPageState(currentState => ({
      ...currentState,
      [key]: value,
    }));
  };

  const setParamsPublisher = (value, key) => {
    setPageState(currentState => ({
      ...currentState,
      paramsPublisher: {
        ...currentState?.paramsPublisher,
        [key]: value,
      },
    }));
  };

  const setParamsTable = (value, key) => {
    setPageState(currentState => ({
      ...currentState,
      paramsTable: {
        ...currentState?.paramsTable,
        [key]: value,
      },
    }));
  };

  const clickMenu = () => {
    setValuePageState(!pageState?.menuVisiblePublisher, "menuVisiblePublisher");
  };

  const handleClickAway = () => {
    setValuePageState(false, "menuVisiblePublisher");
  };

  const handleChangePage = (_, data) => {
    setParamsTable(data, "page");
  };

  const handleChangeRowsPerPage = e => {
    setParamsTable(e?.target?.value, "limit");
    setParamsTable(1, "page");
  };

  const formatSelectedPublishers = publishers => {
    const maxCharCount = 36;
    if (publishers?.length === 0) {
      return "Publisher";
    } else {
      const publisherString = publishers
        .map(selectedRole => selectedRole.name)
        .join(", ");
      if (publisherString.length <= maxCharCount) {
        return publisherString;
      } else {
        return publisherString.substring(0, maxCharCount) + "...";
      }
    }
  };

  const handleChangeCheckBox = value => {
    const updatedPublisher = [...selectedPublisher];
    const key = value?.publisher_id;
    const isThereAKey = updatedPublisher?.some(
      item => item?.publisher_id === key
    );
    if (isThereAKey) {
      const newListPublisher = updatedPublisher?.filter(
        item => item?.publisher_id !== key
      );
      setPageState(current => ({
        ...current,
        selectedPublisher: newListPublisher,
      }));
    } else {
      const newListPublisher = [...updatedPublisher, value];
      setPageState(current => ({
        ...current,
        selectedPublisher: newListPublisher,
      }));
    }
  };

  const getPublisherList = async () => {
    const url = hardBaseUrl + "/publisher";
    const options = {
      headers,
      params: paramsPublisher,
    };
    const res = await axios.get(url, options);
    setValuePageState(res?.data?.data || [], "listPublisher");
  };

  const getTableList = async publisher_ids => {
    setValuePageState(true, "loadingTable");
    const url = hardBaseUrl + "/song-catalog/list";
    const options = {
      headers,
      params: {
        ...paramsTable,
        publisher_ids,
      },
    };
    const res = await axios.get(url, options);
    const dataTable = res?.data?.data;
    setValuePageState(dataTable, "dataTable");
    setValuePageState(false, "loadingTable");
  };

  useEffect(() => {
    getTableList();
  }, []);

  useEffect(() => {
    if (paramsPublisher) {
      getPublisherList();
    }
    if (paramsTable) {
      if (selectedPublisher?.length > 0) {
        const publisherIDs = [
          ...selectedPublisher.map(({ publisher_id }) => publisher_id),
        ].toString();
        getTableList(publisherIDs);
      }
    }
  }, [paramsPublisher, paramsTable, selectedPublisher]);

  const handleSubmit = () => {
    const payload = {
      publisher_ids: pageState?.selectedPublisher?.map(id => id.publisher_id),
    };
    const url = hardBaseUrl + "/song-catalog/init-song";
    setValuePageState(true, "loadingPage");
    axios
      .post(url, payload, { headers })
      .then(res => {
        if (res?.status === 200) {
          Swal.fire({
            title: "Init Catalogue Successfully Processed",
            icon: "success",
            confirmButtonText: "Close",
            confirmButtonColor: "#111827",
          });
          setValuePageState(false, "loadingPage");
        }
      })
      .catch(err => {
        Swal.fire({
          title: "Error",
          text: err?.response?.data?.errors?.message,
          icon: "error",
          confirmButtonText: "OK",
        });
        setValuePageState(false, "loadingPage");
      });
  };

  const useStyles = makeStyles(theme => ({
    root: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    breadCrumbs: {
      fontSize: "14px",
      fontWeight: 400,
      fontStyle: "regular",
      color: "#111827",
    },
    filterPublisher: {
      width: "300px",
      marginRight: "20px",
      height: "44px",
      border: "1px solid #D1D5DC",
      borderRadius: "6px",
    },
    breadCrumbsActive: {
      fontSize: "14px",
      fontWeight: 400,
      fontStyle: "regular",
      color: "#687083",
    },
    title: {
      fontSize: "24px",
      fontWeight: "700",
      lineHeight: "32px",
    },
    buttonStart: {
      height: "44px",
      width: "auto",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: "24px",
      backgroundColor: "black",
      color: "white",
      textTransform: "none",
    },
    textStart: {
      color: "white",
      fontSize: "14px",
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    divider: {
      margin: theme.spacing(2, 0),
      borderTop: "1px solid #e1e1e1",
    },
    content: {
      marginTop: "20px",
    },
    inputFields: {
      height: "44px",
      paddingTop: "0px",
      paddingBottom: "0px",
      "& .MuiOutlinedInput-root": {
        width: "250px",
        height: "44px",
        paddingTop: "0px",
        paddingBottom: "0px",
      },
    },
    subtitleText: {
      fontSize: "10px",
      fontWeight: 400,
      lineHeight: "18px",
    },
    contentFlex: {
      display: "flex",
      justifyContent: "space-between",
    },
    contentSpaceBetween: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-end",
    },
    publisherMenu: {
      boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
      display: "flex",
      flexDirection: "column",
      background: "white",
      position: "absolute",
      zIndex: "1000",
    },
    publisherContainer: {
      width: "300px",
      minHeight: "100px",
      maxHeight: "300px",
      marginTop: "10px",
      borderTop: "1px solid #E4E7EB",
      overflow: "scroll",
    },
    flexCenter: {
      display: "flex",
      justifyContent: "center",
      margin: "10px 10px 0",
    },
    pointer: {
      cursor: "pointer",
    },
  }));
  const classes = useStyles();
  const RenderCustomDate = ({ item }) => {
    return (
      <>
        {getDateFormat(item?.date)}
        <Typography className={classes.subtitleText}>
          {getTimeFormat(item?.date)}
        </Typography>
      </>
    );
  };

  const RenderCustomStatus = ({ item }) => {
    const status = item?.status;
    const success = status === "Success" || status === "success";
    return success ? <>{status}</> : <u>{status}</u>;
  };

  const columnTable = [
    {
      name: "id",
      title: "Init ID",
    },
    {
      name: "all",
      title: "Date",
      renderText: item => <RenderCustomDate item={item} />,
    },
    {
      name: "publishers",
      title: "Publisher",
      renderText: publishers =>
        publishers
          ? (publishers || []).map((publisher, key) => (
              <Typography key={key}>{publisher}</Typography>
            ))
          : "-",
    },
    {
      name: "record_count",
      title: "Total Song",
    },
    {
      name: "all",
      title: "Status",
      renderText: item => <RenderCustomStatus item={item} />,
    },
  ];
  return (
    <Page className={classes.root} title="Init Catalogue">
      {pageState?.loadingPage ? (
        <SkeletonComponent variant="wave" />
      ) : (
        <Container maxWidth={false}>
          <Box className={classes.contentSpaceBetween}>
            <ThemeProvider theme={theme}>
              <Typography className={classes.title}>Init Cataloguee</Typography>
            </ThemeProvider>
            <Breadcrumbs aria-label="breadcrumb">
              <a href="/admin/dashboard">
                <Typography className={classes.breadCrumbs}>Home</Typography>
              </a>
              <Typography className={classes.breadCrumbsActive}>
                Init Catalogue
              </Typography>
            </Breadcrumbs>
          </Box>
          <Divider className={classes.divider} />
          <Box className={classes.content}>
            <Box className={classes.contentFlex}>
              <ClickAwayListener onClickAway={handleClickAway}>
                <Box>
                  <OutlinedInput
                    id="outlined-adornment-amount"
                    size="small"
                    onClick={clickMenu}
                    className={classes.filterPublisher}
                    startAdornment={
                      <InputAdornment position="start">
                        {formatSelectedPublishers(selectedPublisher)}
                      </InputAdornment>
                    }
                    endAdornment={
                      <InputAdornment position="end">
                        <ArrowDropDown
                          onClick={clickMenu}
                          className={classes.pointer}
                        />
                      </InputAdornment>
                    }
                    fullWidth={false}
                    readOnly={true}
                  />
                  {pageState?.menuVisiblePublisher && (
                    <Paper className={classes.publisherMenu}>
                      <FormControl className={classes.flexCenter}>
                        <TextField
                          autoFocus={true}
                          className={classes.inputFields}
                          variant="outlined"
                          placeholder="Search"
                          value={paramsPublisher?.search}
                          onChange={e =>
                            setParamsPublisher(e?.target?.value, "search")
                          }
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <img
                                  alt="Logo"
                                  src={
                                    require("assets/image-public/images/search.svg")
                                      .default
                                  }
                                />
                              </InputAdornment>
                            ),
                            endAdornment: pageState?.paramsPublisher?.search !==
                              "" && (
                              <InputAdornment position="end">
                                <Clear
                                  onClick={() =>
                                    setParamsPublisher("", "search")
                                  }
                                  className={classes.pointer}
                                />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </FormControl>
                      <Container className={classes.publisherContainer}>
                        {pageState?.listPublisher?.length > 0 ? (
                          <FormControl>
                            {pageState?.listPublisher?.map((item, index) => {
                              const isChecked = selectedPublisher?.some(
                                key => key?.publisher_id === item?.publisher_id
                              );
                              return (
                                <FormControlLabel
                                  key={index}
                                  control={
                                    <Checkbox
                                      checked={isChecked}
                                      name={item?.name}
                                      value={item?.publisher_id}
                                      onChange={() =>
                                        handleChangeCheckBox(item)
                                      }
                                    />
                                  }
                                  label={item?.name || "-"}
                                />
                              );
                            })}
                          </FormControl>
                        ) : (
                          <center>No Data</center>
                        )}
                      </Container>
                    </Paper>
                  )}
                </Box>
              </ClickAwayListener>
              <Button
                variant="contained"
                className={classes.buttonStart}
                onClick={handleSubmit}
              >
                <Typography className={classes.textStart}>Start</Typography>
              </Button>
            </Box>
            <Box className={classes.content}>
              <InnoTable
                columns={columnTable}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                isLoading={pageState?.loadingTable}
                isUsingCheckbox={false}
                items={dataTable}
                loadingColor={""}
                page={page}
                rowsPerPage={limit}
                totalPage={pagesCountTable}
              />
            </Box>
          </Box>
        </Container>
      )}
    </Page>
  );
}

export default InitCataloguePage;
