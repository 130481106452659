import {
  CircularProgress,
  Container,
  Divider,
  Grid,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import { InnoTableV2 } from "inno-ui";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { hardBaseUrl } from "../../../../services/urlConstant";
import { breadcrumbData } from "../Components/SongClaimMenu";
import {
  StatusChip,
  Page,
  ButtonGroupTop,
  ModalError,
  DateTimeDisplay,
  ModalSuccess,
  SkeletonComponent,
} from "components";
import { RefreshOutlined, VisibilityOutlined } from "@material-ui/icons";
import { getTotalPage } from "lib";
import { HeaderTitle } from "layouts";
import { getErrors } from "utils";

function UnclaimMonitoring() {
  const classes = useStyles();
  const history = useHistory();
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const [loadingPage, setLoadingPage] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [queryParams, setQueryParams] = useState({
    page: 1,
    size: 10,
    type: "UM",
  });
  const [tableData, setTableData] = useState([]);
  const [tablePageCount, setTablePageCount] = useState(1);

  const handleChangeQueryParams = (value, key) => {
    setQueryParams(currentState => ({
      ...currentState,
      [key]: value,
    }));
  };
  const handleResetPage = () => handleChangeQueryParams(1, "page");
  const handleViewDetail = id => {
    history.push({
      pathname: `/admin/publisher/unclaim-monitoring/${id}`,
    });
  };

  const getDataTable = async () => {
    setLoadingPage(true);
    const url = `${hardBaseUrl}/process/datatable`;
    try {
      const res = await axios.get(url, { headers, params: queryParams });
      const resData = res?.data?.data;
      const resTotalSize = res?.data?.meta?.total;
      setTableData(resData);
      setTablePageCount(getTotalPage(resTotalSize, queryParams?.size));
    } catch (error) {
      ModalError(getErrors(error));
    } finally {
      setLoadingPage(false);
    }
  };
  const handleRetryUnclaimProcess = async id => {
    setButtonLoading(true);
    await axios
      .post(`${hardBaseUrl}/unclaim/retry/${id}`, {}, { headers })
      .then(() => {
        ModalSuccess("Successfully Retry Unclaim Process").then(() =>
          getDataTable()
        );
      })
      .catch(error => ModalError(getErrors(error)))
      .finally(() => setButtonLoading(false));
  };

  useEffect(() => {
    getDataTable();
  }, [queryParams]);

  const columnTable = [
    {
      name: "all",
      title: "Action",
      renderText: item => (
        <Grid container spacing={1}>
          <Grid item>
            <Tooltip title="View Detail">
              <IconButton
                className={classes?.iconButton}
                disabled={buttonLoading}
                onClick={() => handleViewDetail(item?.id)}
              >
                <VisibilityOutlined />
              </IconButton>
            </Tooltip>
          </Grid>
          {item.status === "Error" && (
            <Grid item>
              <Tooltip title="Retry Process">
                <IconButton
                  className={classes?.iconButton}
                  disabled={buttonLoading}
                  onClick={() => handleRetryUnclaimProcess(item?.id)}
                >
                  {buttonLoading ? (
                    <CircularProgress
                      size={16}
                      className={classes?.circularButton}
                    />
                  ) : (
                    <RefreshOutlined />
                  )}
                </IconButton>
              </Tooltip>
            </Grid>
          )}
        </Grid>
      ),
    },
    {
      name: "id",
      title: "Unclaim ID",
    },
    {
      name: "start_date_time",
      title: "Start Date",
      renderText: item => <DateTimeDisplay date={item} />,
    },
    {
      name: "end_date_time",
      title: "End Date",
      renderText: item => <DateTimeDisplay date={item} />,
    },
    {
      name: "status",
      title: "Status",
      renderText: status => (
        <StatusChip
          type={
            status === "Finish"
              ? "success"
              : status === "Error"
                ? "danger"
                : status === "Running"
                  ? "warning"
                  : ""
          }
          label={status === "Error" ? "Request Time Out" : status}
        />
      ),
    },
  ];
  return (
    <Page className={classes?.root} title="Unclaim Monitoring">
      {loadingPage ? (
        <SkeletonComponent variant="wave" />
      ) : (
        <Container maxWidth={false}>
          <HeaderTitle title="Unclaim Monitoring" breadcrumbData={breadcrumbData} />
          <Divider className={classes?.divider} />
          <ButtonGroupTop />
          <InnoTableV2
            columns={columnTable}
            handleChangePage={(_, value) =>
              handleChangeQueryParams(value, "page")
            }
            handleChangeRowsPerPage={event => {
              handleChangeQueryParams(event?.target?.value, "size");
              handleResetPage();
            }}
            isLoading={loadingPage}
            items={tableData || []}
            page={queryParams?.page}
            rowsPerPage={queryParams?.size}
            totalPage={tablePageCount}
          />
        </Container>
      )}
    </Page>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  pageTitle: {
    fontSize: 24,
    fontWeight: 700,
    lineHeight: "32px",
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  buttonBlack: {
    backgroundColor: "#111827",
    color: "white",
    textTransform: "none",
    whiteSpace: "nowrap",
    height: 38,
    "&:hover": {
      backgroundColor: "#111827",
      color: "white",
    },
  },
  iconButton: {
    width: 28,
    height: 28,
    borderRadius: 6,
    padding: 0,
    backgroundColor: "#111827",
    color: "white",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#111827",
      color: "white",
    },
    "&.MuiIconButton-root.Mui-disabled": {
      backgroundColor: "#9ca3af",
      color: "white",
    },
  },
  circularButton: {
    color: "white",
  },
}));
export default UnclaimMonitoring;
