import { Container, Divider } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { Grid } from "@mui/material";
import axios from "axios";
import {
  ButtonGroupTop,
  CurrencyDisplay,
  ModalError,
  Page,
  PrimaryButton,
  SearchTextInput,
  SectionLabel,
  SelectInput,
  SkeletonComponent,
  TableAction,
} from "components";
import { InnoTableV2 } from "inno-ui";
import { HeaderTitle } from "layouts";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { globalStyles } from "styles";
import { formatDate, getErrors, textCapitalization } from "utils";
import { hardBaseUrl } from "../../../services/urlConstant";

const LoanPage = () => {
  const classes = globalStyles();
  const history = useHistory();
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const [loadingPage, setLoadingPage] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [queryParams, setQueryParams] = useState({
    page: 1,
    per_page: 10,
    search: "",
    filter_status: "",
    order_by: "publisher_transaction_id",
    order: "desc",
  });
  const [tableTotalPage, setTableTotalPage] = useState(1);

  const handleChangeQueryParams = (value, key) => {
    setQueryParams(currentState => ({
      ...currentState,
      ...(key !== "page" && { page: 1 }),
      [key]: value,
    }));
  };

  const getDataTable = async () => {
    try {
      setLoadingPage(true);
      const res = await axios.get(`${hardBaseUrl}/composer/transaction/loan`, {
        headers,
        params: queryParams,
      });
      const { loan_data, query } = res?.data?.data;
      setDataTable(loan_data || []);
      setTableTotalPage(query?.total_page || 1);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  const debounceDataTable = useCallback(
    debounce(() => {
      getDataTable();
    }, 500),
    [queryParams]
  );
  useEffect(() => {
    const isSearching = Boolean(queryParams?.search);
    if (isSearching) {
      debounceDataTable();
      return () => {
        debounceDataTable.cancel();
      };
    } else {
      getDataTable();
    }
  }, [queryParams, debounceDataTable]);

  return (
    <Page className={classes.root} title="Loan">
      {loadingPage ? (
        <SkeletonComponent variant="wave" />
      ) : (
        <Container maxWidth={false}>
          <HeaderTitle
            title="Transaction"
            breadcrumbData={breadcrumbData}
          />
          <ButtonGroupTop />
          <Divider className={classes.flatDivider} />
          <SectionLabel title="Loan Transaction" my="16px" />
          <Grid container justifyContent="space-between" my="16px">
            <Grid item>
              <SearchTextInput
                placeholder="Search"
                value={queryParams?.search}
                onChange={e =>
                  handleChangeQueryParams(e?.target?.value, "search")
                }
              />
            </Grid>
            <Grid item>
              <Grid container columnSpacing={1} alignItems="center">
                <Grid item>
                  <SelectInput
                    label="Status"
                    options={optionStatus}
                    optionKey="value"
                    optionLabel="name"
                    value={queryParams?.role_id || ""}
                    onChange={e =>
                      handleChangeQueryParams(e?.target?.value, "publisher")
                    }
                    placeholder="All"
                    width={200}
                  />
                </Grid>
                <Grid item>
                  <PrimaryButton
                    label="Create Transaction"
                    onClick={() => history.push("/pencipta/loan/add")}
                    startIcon={<Add />}
                    size="large"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <InnoTableV2
            isLoading={false}
            columns={columnTable}
            items={dataTable}
            page={queryParams?.page}
            rowsPerPage={queryParams?.per_page}
            totalPage={tableTotalPage}
            handleChangePage={(_, page) =>
              handleChangeQueryParams(page, "page")
            }
            handleChangeRowsPerPage={e =>
              handleChangeQueryParams(e?.target?.value, "per_page")
            }
            isHaveAction
            renderAction={item => (
              <TableAction
                handleView={() =>
                  history.push({
                    pathname: `/pencipta/loan/${item?.publisher_transaction_id}`,
                    state: {
                      customer: item,
                    },
                  })
                }
                tooltipView="View"
              />
            )}
          />
        </Container>
      )}
    </Page>
  );
};
const breadcrumbData = [
  {
    label: "Transaction",
    link: "/pencipta/loan",
  },
  {
    label: "Loan",
    active: true,
  },
];
const optionStatus = [
  {
    name: "Waiting for Approval",
    value: "waiting_for_approval",
  },
  {
    name: "Approved",
    value: "approved",
  },
  {
    name: "Reject",
    value: "reject",
  },
  {
    name: "Active",
    value: "active",
  },
  {
    name: "Paid",
    value: "paid",
  },
];
const labelStatus = {
  paid: "Paid",
  unpaid: "Unpaid",
  waiting_for_approval: "Waiting for Approval",
  active: "Active",
  approved: "Approved",
  rejected: "Rejected",
};
const columnTable = [
  {
    name: "date",
    title: "Date",
    renderText: item => item && formatDate(item),
  },
  {
    name: "publisher",
    title: "Publisher",
    renderText: item => item || "-",
  },
  {
    name: "tenor",
    title: "Tenor",
    renderText: item => item || "-",
  },
  {
    name: "loan_value",
    title: "Loan Value",
    headerAlign: "right",
    renderText: item => <CurrencyDisplay value={item} />,
  },
  {
    name: "status",
    title: "Status",
    renderText: item => labelStatus?.[item] || textCapitalization(item) || "-",
  },
];

export default LoanPage;
