import { ArrowRightOutlined } from "@ant-design/icons";
import { Typography } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import CloseIcon from "@material-ui/icons/Close";
import ImageOutlinedIcon from "@material-ui/icons/ImageOutlined";
import { makeStyles } from "@material-ui/styles";
import { Button, Col, Row } from "antd";
import ReactHtmlParser from "react-html-parser";
import { useSelector } from "react-redux";
import bannerFooter from "../../../../assets/img/banner-footer.png";
import elipse from "../../../../assets/img/Ellipse-83.png";
import intersect from "../../../../assets/img/Intersect.svg";
import Polygon from "../../../../assets/img/Polygon-1.svg";
import Layout from "./Layout";
import "./previewmanajemenweb.css";

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(3),
  },
  header: {
    fontSize: "40px",
    fontWeight: "bold",
    color: "#000",
  },
  writerName: {
    fontSize: "20px",
    color: "gray",
    marginTop: "10px",
    marginBottom: "10px",
  },
  date: {
    fontSize: "20px",
    color: "gray",
  },
  img: {
    objectFit: "contain",
    width: "100%",
  },
  mainText: {
    fontSize: "20px",
    color: "#687083",
    marginTop: "10px",
    marginBottom: "10px",
    textAlign: "justify",
    lineHeight: "30px",
  },
  appBar: {
    backgroundColor: "#f5f5f5",
    color: "#000",
    boxShadow: "none",
    borderBottom: "1px solid #e0e0e0",
  },
  textHeader: {
    fontSize: "25px",

    marginLeft: "10px",
  },
  iconHeader: {
    fontSize: "29px",
    marginTop: "5px",
  },
  backButton: {
    cursor: "pointer",
    "&:hover": {
      color: "gray",
    },
  },
  displayFlex: {
    display: "flex",
    width: "100%",
  },
  previewPhoto: {
    width: "120px",
    height: "120px",
  },
  card: {
    border: "none",
    boxShadow: "none",
    display: "flex",
    marginBottom: "10px",
  },
  cardDetail: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
  },
  menuTitle: {
    fontSize: "20px",
    color: "#687083",
    fontWeight: 700,
    marginBottom: "20px",
  },
}));

const PreviewManajemenWeb = props => {
  const classes = useStyles();
  const theme = createTheme({
    typography: {
      fontFamily: ["Inter"].join(","),
    },
  });

  const lang = useSelector(state => state.langReducer.language);

  const handleBack = e => {
    e.preventDefault();
    localStorage.removeItem("dspName");
    localStorage.removeItem("dspLogo");
    localStorage.removeItem("publisherImage1");
    localStorage.removeItem("publisherImage2");
    localStorage.removeItem("publisherImage3");
    localStorage.removeItem("publisherImage4");
    localStorage.removeItem("publisherImage5");
    localStorage.removeItem("publisherImage6");
    localStorage.removeItem("tentangKamiImage1");
    localStorage.removeItem("tentangKamiImage2");
    localStorage.removeItem("visiKamiImage1");
    localStorage.removeItem("visiKamiImage2");
    props.history.push("/admin/manajemen-web");
  };

  const tentangKamiImage1 = useSelector(state => {
    return state.manajemenWeb.tentangKamiImage1;
  });

  const tentangKamiImage2 = useSelector(state => {
    return state.manajemenWeb.tentangKamiImage2;
  });

  const visiKamiImage1 = useSelector(state => {
    return state.manajemenWeb.visiKamiImage1;
  });

  const visiKamiImage2 = useSelector(state => {
    return state.manajemenWeb.visiKamiImage2;
  });

  const fotoPublisherImage1 = useSelector(state => {
    return state.manajemenWeb.publisherImage1;
  });

  const fotoPublisherImage2 = useSelector(state => {
    return state.manajemenWeb.publisherImage2;
  });

  const fotoPublisherImage3 = useSelector(state => {
    return state.manajemenWeb.publisherImage3;
  });

  const fotoPublisherImage4 = useSelector(state => {
    return state.manajemenWeb.publisherImage4;
  });

  const fotoPublisherImage5 = useSelector(state => {
    return state.manajemenWeb.publisherImage5;
  });

  const fotoPublisherImage6 = useSelector(state => {
    return state.manajemenWeb.publisherImage6;
  });

  const tentangKamiText = useSelector(state => {
    return state.manajemenWeb.tentangKamiText;
  });

  const visiKamiText = useSelector(state => {
    return state.manajemenWeb.visiKamiText;
  });
  const truncate = (str, n) => {
    return str?.length > n ? str.substr(0, n - 1) + "..." : str;
  };

  return (
    <>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <ThemeProvider theme={theme}>
            <Typography
              className={classes.backButton}
              variant="h6"
              onClick={e => handleBack(e)}
            >
              <div className={classes.displayFlex}>
                <CloseIcon className={classes.iconHeader} />
                <div className={classes.textHeader}>Preview</div>
              </div>
            </Typography>
          </ThemeProvider>
        </Toolbar>
      </AppBar>
      <Layout>
        <div className="tentang-kami">
          <div className="banner">
            <div
              className="container"
              style={{
                display: "inline-block",
              }}
            >
              <div>
                <h1 style={{ fontSize: "48px", fontWeight: 700 }}>
                  {lang === "IND"
                    ? `Tentang ${
                        localStorage.getItem("dspName") === null ||
                        localStorage.getItem("dspName") === "" ||
                        localStorage.getItem("dspName") === undefined
                          ? "Kami"
                          : localStorage.getItem("dspName")
                      }`
                    : "About Simfoni"}
                </h1>
                <p
                  style={{ width: "600px", fontSize: "18px", color: "#687083" }}
                >
                  {truncate(ReactHtmlParser(tentangKamiText), 200)}
                </p>
                <Button
                  className="button"
                  style={{ backgroundColor: "#111827" }}
                >
                  Baca Selengkapnya <ArrowRightOutlined />{" "}
                </Button>
              </div>
            </div>
          </div>
          <div className="gallery">
            <Row style={{ height: "550px" }} gutter={16}>
              <Col span={7}>
                {fotoPublisherImage1.preview === "" ? (
                  localStorage.getItem("publisherImage1") === null || "" ? (
                    <div
                      style={{
                        height: "550px",
                        backgroundColor: "#E4E7EB",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <ImageOutlinedIcon
                        style={{
                          fontSize: "100px",
                          color: "darkgray",
                        }}
                      />
                    </div>
                  ) : (
                    <img
                      alt=""
                      src={localStorage.getItem("publisherImage1")}
                      style={{
                        height: "550px",
                        width: "100%",
                        objectFit: "cover",
                        maxHeight: "641px",
                      }}
                    />
                  )
                ) : (
                  <img
                    alt=""
                    src={fotoPublisherImage1.preview}
                    style={{
                      height: "550px",
                      width: "100%",
                      objectFit: "cover",
                      maxHeight: "641px",
                    }}
                  />
                )}
              </Col>
              <Col span={10}>
                <div
                  style={{
                    flexDirection: "column",
                    height: "100%",
                    display: "flex",

                    gap: "24px",
                  }}
                >
                  {fotoPublisherImage2.preview === "" ? (
                    localStorage.getItem("publisherImage2") === null || "" ? (
                      <div
                        style={{
                          flex: 4,
                          backgroundColor: "#E4E7EB",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <ImageOutlinedIcon
                          style={{
                            fontSize: "100px",
                            color: "darkgray",
                          }}
                        />
                      </div>
                    ) : (
                      <img
                        alt=""
                        src={localStorage.getItem("publisherImage2")}
                        style={{
                          width: "100%",
                          height: "100%",
                          maxHeight: "330px",
                          objectFit: "cover",
                        }}
                      />
                    )
                  ) : (
                    <img
                      alt=""
                      src={fotoPublisherImage2.preview}
                      style={{
                        width: "100%",
                        height: "100%",
                        maxHeight: "330px",
                        objectFit: "cover",
                      }}
                    />
                  )}

                  <div
                    style={{
                      flex: 2.5,
                      display: "flex",
                      flexDirection: "row",
                      gap: "24px",
                    }}
                  >
                    {fotoPublisherImage3.preview === "" ? (
                      localStorage.getItem("publisherImage3") === null || "" ? (
                        <div
                          style={{
                            flex: 1,
                            backgroundColor: "#E4E7EB",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <ImageOutlinedIcon
                            style={{
                              fontSize: "100px",
                              color: "darkgray",
                            }}
                          />
                        </div>
                      ) : (
                        <img
                          alt=""
                          src={localStorage.getItem("publisherImage3")}
                          style={{
                            width: "100%",
                            height: "100%",
                            maxHeight: "184px",
                            maxWidth: "292px",
                            objectFit: "cover",
                          }}
                        />
                      )
                    ) : (
                      <img
                        alt=""
                        src={fotoPublisherImage3.preview}
                        style={{
                          width: "100%",
                          height: "100%",
                          maxHeight: "184px",
                          maxWidth: "292px",
                          objectFit: "cover",
                        }}
                      />
                    )}
                    {fotoPublisherImage4.preview === "" ? (
                      localStorage.getItem("publisherImage4") === null || "" ? (
                        <div
                          style={{
                            flex: 1,
                            backgroundColor: "#E4E7EB",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <ImageOutlinedIcon
                            style={{
                              fontSize: "100px",
                              color: "darkgray",
                            }}
                          />
                        </div>
                      ) : (
                        <img
                          alt=""
                          src={localStorage.getItem("publisherImage4")}
                          style={{
                            width: "100%",
                            height: "100%",
                            maxHeight: "184px",
                            maxWidth: "292px",
                            objectFit: "cover",
                          }}
                        />
                      )
                    ) : (
                      <img
                        alt=""
                        src={fotoPublisherImage4.preview}
                        style={{
                          width: "100%",
                          height: "100%",
                          maxHeight: "184px",
                          maxWidth: "292px",
                          objectFit: "cover",
                        }}
                      />
                    )}
                  </div>
                </div>
              </Col>
              <Col span={7}>
                <div
                  style={{
                    flexDirection: "column",
                    height: "100%",
                    display: "flex",
                    gap: "24px",
                  }}
                >
                  {fotoPublisherImage5.preview === "" ? (
                    localStorage.getItem("publisherImage5") === null || "" ? (
                      <div
                        style={{
                          height: "184px",
                          backgroundColor: "#E4E7EB",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <ImageOutlinedIcon
                          style={{
                            fontSize: "100px",
                            color: "darkgray",
                          }}
                        />
                      </div>
                    ) : (
                      <img
                        alt=""
                        src={localStorage.getItem("publisherImage5")}
                        style={{
                          width: "100%",
                          height: "184px",
                          maxHeight: "330px",
                          objectFit: "cover",
                        }}
                      />
                    )
                  ) : (
                    <img
                      alt=""
                      src={fotoPublisherImage5.preview}
                      style={{
                        width: "100%",
                        height: "184px",
                        maxHeight: "330px",
                        objectFit: "cover",
                      }}
                    />
                  )}

                  {fotoPublisherImage6.preview === "" ? (
                    localStorage.getItem("publisherImage6") === null || "" ? (
                      <div
                        style={{
                          height: "340px",
                          backgroundColor: "#E4E7EB",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          border: "5px solid #FFFFFF",
                        }}
                      >
                        <ImageOutlinedIcon
                          style={{
                            fontSize: "100px",
                            color: "darkgray",
                          }}
                        />
                      </div>
                    ) : (
                      <img
                        alt=""
                        src={localStorage.getItem("publisherImage6")}
                        style={{
                          width: "100%",
                          height: "340px",
                          maxHeight: "340px",
                          objectFit: "cover",
                        }}
                      />
                    )
                  ) : (
                    <img
                      alt=""
                      src={fotoPublisherImage6.preview}
                      style={{
                        width: "100%",
                        height: "340px",
                        maxHeight: "340px",
                        objectFit: "cover",
                      }}
                    />
                  )}
                </div>
              </Col>
            </Row>
          </div>
          <div className="simfoni container">
            <div style={{ flex: 1 }}>
              <>
                {tentangKamiImage1.preview === "" ? (
                  localStorage.getItem("tentangKamiImage1") === null || "" ? (
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          backgroundColor: "#E4E7EB",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "303px",
                          height: "177px",
                          zIndex: "20",
                          border: "5px solid #FFFFFF",
                        }}
                      >
                        <ImageOutlinedIcon
                          style={{
                            fontSize: "100px",
                            color: "darkgray",
                          }}
                        />
                      </div>
                      <img
                        alt=""
                        src={intersect}
                        style={{
                          width: "77.75px",
                          height: "78.67px",
                          marginTop: "40px",
                          marginLeft: "-20px",
                        }}
                      />
                      <img
                        alt=""
                        src={elipse}
                        style={{
                          width: "43px",
                          height: "43px",
                          marginTop: "40px",
                          marginLeft: "50px",
                        }}
                      />
                    </div>
                  ) : (
                    <div style={{ display: "flex" }}>
                      <img
                        alt=""
                        src={localStorage.getItem("tentangKamiImage1")}
                        style={{
                          width: "273px",
                          height: "177px",
                          objectFit: "cover",
                          border: "5px solid #FFFFFF",
                          padding: "0",
                          boxShadow:
                            "0px 16px 24px rgba(65, 78, 98, 0.12), 0px 0px 1px rgba(65, 78, 98, 0.05)",
                          boxSizing: "border-box",
                          zIndex: "20",
                          position: "relative",
                        }}
                      />
                      <img
                        alt=""
                        src={intersect}
                        style={{
                          width: "77.75px",
                          height: "78.67px",
                          marginTop: "40px",
                        }}
                      />
                      <img
                        alt=""
                        src={elipse}
                        style={{
                          width: "43px",
                          height: "43px",
                          marginTop: "40px",
                          marginLeft: "50px",
                        }}
                      />
                    </div>
                  )
                ) : (
                  <div style={{ display: "flex" }}>
                    <img
                      alt=""
                      src={tentangKamiImage1.preview}
                      style={{
                        width: "273px",
                        height: "177px",
                        objectFit: "cover",
                        border: "5px solid #FFFFFF",
                        padding: "0",
                        boxShadow:
                          "0px 16px 24px rgba(65, 78, 98, 0.12), 0px 0px 1px rgba(65, 78, 98, 0.05)",
                        boxSizing: "border-box",
                        zIndex: "20",
                        position: "relative",
                      }}
                    />
                    <img
                      alt=""
                      src={intersect}
                      style={{
                        width: "77.75px",
                        height: "78.67px",
                        marginTop: "40px",
                      }}
                    />
                    <img
                      alt=""
                      src={elipse}
                      style={{
                        width: "43px",
                        height: "43px",
                        marginTop: "40px",
                        marginLeft: "50px",
                      }}
                    />
                  </div>
                )}

                {tentangKamiImage2.preview === "" ? (
                  localStorage.getItem("tentangKamiImage2") === null || "" ? (
                    <div
                      style={{
                        marginTop: "50px",
                        display: "flex",
                        justifyContent: "end",
                        objectFit: "cover",
                      }}
                    >
                      <img
                        alt=""
                        src={elipse}
                        style={{
                          width: "43px",
                          height: "43px",
                          marginTop: "40px",
                          marginRight: "200px",
                        }}
                      />
                      <img
                        alt=""
                        src={Polygon}
                        style={{
                          width: "89.93px",
                          height: "89.93px",
                          position: "absolute",
                          left: "300px",
                          zIndex: "0",
                        }}
                      />
                      <div
                        style={{
                          backgroundColor: "#E4E7EB",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "303px",
                          height: "177px",
                          zIndex: "20",
                          border: "5px solid #FFFFFF",
                        }}
                      >
                        <ImageOutlinedIcon
                          style={{
                            fontSize: "100px",
                            color: "darkgray",
                          }}
                        />
                      </div>
                    </div>
                  ) : (
                    <div
                      style={{
                        marginTop: "50px",
                        display: "flex",
                        justifyContent: "end",
                        objectFit: "cover",
                      }}
                    >
                      <img
                        alt=""
                        src={elipse}
                        style={{
                          width: "43px",
                          height: "43px",
                          marginTop: "40px",
                          marginRight: "200px",
                        }}
                      />
                      <img
                        alt=""
                        src={Polygon}
                        style={{
                          width: "89.93px",
                          height: "89.93px",
                          position: "absolute",
                          left: "300px",
                          zIndex: "0",
                        }}
                      />
                      <img
                        alt=""
                        src={localStorage.getItem("tentangKamiImage2")}
                        style={{
                          width: "273px",
                          height: "177px",
                          marginRight: "40px",
                          objectFit: "cover",
                          border: "5px solid #FFFFFF",
                          boxShadow:
                            "0px 16px 24px rgba(65, 78, 98, 0.12), 0px 0px 1px rgba(65, 78, 98, 0.05)",
                          boxSizing: "border-box",
                          zIndex: "20",
                        }}
                      />
                    </div>
                  )
                ) : (
                  <div
                    style={{
                      marginTop: "50px",
                      display: "flex",
                      justifyContent: "end",
                      objectFit: "cover",
                    }}
                  >
                    <img
                      alt=""
                      src={elipse}
                      style={{
                        width: "43px",
                        height: "43px",
                        marginTop: "40px",
                        marginRight: "200px",
                      }}
                    />
                    <img
                      alt=""
                      src={Polygon}
                      style={{
                        width: "89.93px",
                        height: "89.93px",
                        position: "absolute",
                        left: "300px",
                        zIndex: "0",
                      }}
                    />
                    <img
                      alt=""
                      src={tentangKamiImage2.preview}
                      style={{
                        width: "273px",
                        height: "177px",
                        marginRight: "40px",
                        objectFit: "cover",
                        border: "5px solid #FFFFFF",
                        boxShadow:
                          "0px 16px 24px rgba(65, 78, 98, 0.12), 0px 0px 1px rgba(65, 78, 98, 0.05)",
                        boxSizing: "border-box",
                        zIndex: "20",
                      }}
                    />
                  </div>
                )}
              </>
            </div>
            <div
              style={{
                flex: 1,
                alignItems: "center",
                display: "flex",
              }}
            >
              <div className="content">
                <h1>
                  {lang === "IND"
                    ? `Tentang ${
                        localStorage.getItem("dspName") === null ||
                        localStorage.getItem("dspName") === "" ||
                        localStorage.getItem("dspName") === undefined
                          ? "Kami"
                          : localStorage.getItem("dspName")
                      }`
                    : "About Simfoni"}
                </h1>
                <p>{ReactHtmlParser(tentangKamiText)}</p>
              </div>
            </div>
          </div>
          <div className="simfoni container" style={{ padding: 0 }}>
            <div
              style={{
                flex: 1,
                alignItems: "center",
                display: "flex",
              }}
            >
              <div className="content">
                <h1>
                  {lang === "IND"
                    ? `Visi ${
                        localStorage.getItem("dspName") === null ||
                        localStorage.getItem("dspName") === "" ||
                        localStorage.getItem("dspName") === undefined
                          ? "Kami"
                          : localStorage.getItem("dspName")
                      }`
                    : "Our Vision"}
                </h1>
                <p>{ReactHtmlParser(visiKamiText)}</p>
              </div>
            </div>
            <div style={{ flex: 1 }}>
              <>
                {visiKamiImage1.preview === "" ? (
                  localStorage.getItem("visiKamiImage1") === null || "" ? (
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          backgroundColor: "#E4E7EB",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "303px",
                          height: "177px",
                          zIndex: "20",
                          border: "5px solid #FFFFFF",
                        }}
                      >
                        <ImageOutlinedIcon
                          style={{
                            fontSize: "100px",
                            color: "darkgray",
                          }}
                        />
                      </div>
                      <img
                        alt=""
                        src={intersect}
                        style={{
                          width: "77.75px",
                          height: "78.67px",
                          marginTop: "40px",
                          position: "relative",
                          zIndex: "0",
                          marginLeft: "-20px",
                        }}
                      />
                      <img
                        alt=""
                        src={elipse}
                        style={{
                          width: "43px",
                          height: "43px",
                          marginTop: "40px",
                          marginLeft: "50px",
                        }}
                      />
                    </div>
                  ) : (
                    <div style={{ display: "flex" }}>
                      <img
                        alt=""
                        src={localStorage.getItem("visiKamiImage1")}
                        style={{
                          width: "273px",
                          height: "177px",
                          objectFit: "cover",
                          border: "5px solid #FFFFFF",
                          boxShadow:
                            "0px 16px 24px rgba(65, 78, 98, 0.12), 0px 0px 1px rgba(65, 78, 98, 0.05)",
                          boxSizing: "border-box",
                          zIndex: "20",
                        }}
                      />
                      <img
                        alt=""
                        src={intersect}
                        style={{
                          width: "77.75px",
                          height: "78.67px",
                          marginTop: "40px",
                          position: "relative",
                          zIndex: "0",
                        }}
                      />
                      <img
                        alt=""
                        src={elipse}
                        style={{
                          width: "43px",
                          height: "43px",
                          marginTop: "40px",
                          marginLeft: "50px",
                        }}
                      />
                    </div>
                  )
                ) : (
                  <div style={{ display: "flex" }}>
                    <img
                      alt=""
                      src={visiKamiImage1.preview}
                      style={{
                        width: "273px",
                        height: "177px",
                        objectFit: "cover",
                        border: "5px solid #FFFFFF",
                        boxShadow:
                          "0px 16px 24px rgba(65, 78, 98, 0.12), 0px 0px 1px rgba(65, 78, 98, 0.05)",
                        boxSizing: "border-box",
                        zIndex: "20",
                      }}
                    />
                    <img
                      alt=""
                      src={intersect}
                      style={{
                        width: "77.75px",
                        height: "78.67px",
                        marginTop: "40px",
                        position: "relative",
                        zIndex: "0",
                      }}
                    />
                    <img
                      alt=""
                      src={elipse}
                      style={{
                        width: "43px",
                        height: "43px",
                        marginTop: "40px",
                        marginLeft: "50px",
                      }}
                    />
                  </div>
                )}

                {visiKamiImage2.preview === "" ? (
                  localStorage.getItem("visiKamiImage2") === null || "" ? (
                    <div
                      style={{
                        marginTop: "50px",
                        display: "flex",
                        justifyContent: "end",
                      }}
                    >
                      <img
                        alt=""
                        src={elipse}
                        style={{
                          width: "43px",
                          height: "43px",
                          marginTop: "40px",
                          marginRight: "200px",
                        }}
                      />
                      <img
                        alt=""
                        src={Polygon}
                        style={{
                          width: "89.93px",
                          height: "89.93px",
                          position: "absolute",
                          left: "870px",
                          zIndex: "0",
                        }}
                      />
                      <div
                        style={{
                          backgroundColor: "#E4E7EB",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "303px",
                          height: "177px",
                          zIndex: "20",
                          border: "5px solid #FFFFFF",
                        }}
                      >
                        <ImageOutlinedIcon
                          style={{
                            fontSize: "100px",
                            color: "darkgray",
                          }}
                        />
                      </div>
                    </div>
                  ) : (
                    <div
                      style={{
                        marginTop: "50px",
                        display: "flex",
                        justifyContent: "end",
                      }}
                    >
                      <img
                        alt=""
                        src={elipse}
                        style={{
                          width: "43px",
                          height: "43px",
                          marginTop: "40px",
                          marginRight: "200px",
                        }}
                      />
                      <img
                        alt=""
                        src={Polygon}
                        style={{
                          width: "89.93px",
                          height: "89.93px",
                          position: "absolute",
                          left: "870px",
                          zIndex: "0",
                        }}
                      />
                      <img
                        alt=""
                        src={localStorage.getItem("visiKamiImage2")}
                        style={{
                          width: "273px",
                          height: "177px",
                          marginRight: "40px",
                          objectFit: "cover",
                          border: "5px solid #FFFFFF",
                          boxShadow:
                            "0px 16px 24px rgba(65, 78, 98, 0.12), 0px 0px 1px rgba(65, 78, 98, 0.05)",
                          boxSizing: "border-box",
                          zIndex: "20",
                        }}
                      />
                    </div>
                  )
                ) : (
                  <div
                    style={{
                      marginTop: "50px",
                      display: "flex",
                      justifyContent: "end",
                    }}
                  >
                    <img
                      alt=""
                      src={elipse}
                      style={{
                        width: "43px",
                        height: "43px",
                        marginTop: "40px",
                        marginRight: "200px",
                      }}
                    />
                    <img
                      alt=""
                      src={Polygon}
                      style={{
                        width: "89.93px",
                        height: "89.93px",
                        position: "absolute",
                        left: "870px",
                        zIndex: "0",
                      }}
                    />
                    <img
                      alt=""
                      src={visiKamiImage2.preview}
                      style={{
                        width: "273px",
                        height: "177px",
                        marginRight: "40px",
                        objectFit: "cover",
                        border: "5px solid #FFFFFF",
                        boxShadow:
                          "0px 16px 24px rgba(65, 78, 98, 0.12), 0px 0px 1px rgba(65, 78, 98, 0.05)",
                        boxSizing: "border-box",
                        zIndex: "20",
                      }}
                    />
                  </div>
                )}
              </>
            </div>
          </div>
          <div className="banner-footer">
            <img
              alt=""
              src={bannerFooter}
              style={{
                width: "100%",
                position: "absolute",
                height: "100%",
                objectFit: "cover",
              }}
            />
            <div style={{ zIndex: 2 }}>
              <div>
                <h1 style={{ color: "white", fontSize: "48px" }}>
                  Ayo bergabung bersama simfoni!
                </h1>
                <p style={{ width: "955px", fontSize: "18px" }}>
                  Dictum tincidunt dictum ultrices massa rhoncus magna eget
                  purus volutpat. Pellentesque enim libero, massa euismod. Sed
                  enim at urna, elit imperdiet. Quisque arcu pretium amet,
                  varius. Nunc, libero adipiscing id nulla volutpat libero nec
                </p>
              </div>
              <Button
                style={{
                  width: "273px",
                  marginTop: "48px",
                  height: "56px",
                  borderRadius: "10px",
                  fontWeight: "bold",
                  color: "#9545EB",
                }}
              >
                GABUNG SEKARANG <ArrowRightOutlined />
              </Button>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default PreviewManajemenWeb;
