import { InputAdornment, styled, TextField } from "@mui/material";

const TextInput = ({
  placeholder,
  onChange,
  value,
  disabled,
  multiline,
  startAdornment,
  endAdornment,
  inputMode,
  rows,
  label,
  required,
  width,
  error,
  helperText,
  inputStyle,
  ...rest
}) => {
  return (
    <CustomTextField
      customWidth={width}
      size="small"
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      disabled={disabled}
      multiline={multiline}
      rows={rows}
      InputProps={{
        autoComplete: "off",
        startAdornment: startAdornment && (
          <InputAdornment position="start">{startAdornment}</InputAdornment>
        ),
        endAdornment: endAdornment && (
          <InputAdornment position="end">{endAdornment}</InputAdornment>
        ),
        inputMode,
      }}
      InputLabelProps={{
        shrink: value ? true : undefined,
      }}
      label={label}
      required={required}
      fullWidth
      error={error}
      helperText={helperText}
      inputStyle={inputStyle}
      {...rest}
    />
  );
};
const CustomTextField = styled(TextField)(({ customWidth, inputStyle }) => ({
  width: customWidth,
  "& .MuiOutlinedInput-input": {
    ...inputStyle,
  },
}));

export default TextInput;
