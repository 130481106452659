import {
  Chip,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useHistory } from "react-router";
import PencilVector from "../../../assets/img/pencilVector.svg";
import { ButtonWithModalPerItem } from "../../../components/atoms/Button";

const fontInter = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const theme = createTheme({
  typography: {
    fontFamily: ["Helvetica Neue"].join(","),
  },
});

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 700,
    border: "1px solid #D1D5DC",
    borderRadius: "6px",
    boxShadow: "none",
  },
  nameCell: {
    display: "flex",
    alignItems: "center",
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1),
  },
  actions: {
    padding: theme.spacing(1),
    justifyContent: "flex-end",
  },
  pagination: {
    "& .Mui-selected": {
      backgroundColor: "black",
      color: "white",
    },
  },
  pageSize: {
    height: "30px",
    borderRadius: "6px",
    border: "1px solid #D1D5DC",
    paddingLeft: "5px",
    paddingRight: "5px",
    marginTop: "5px",
  },
  paginationText: {
    fontSize: "14px",
    fontWeight: "normal",
    fontStyle: "normal",
  },
  textField: {
    width: "150px",
  },
  mypadding: {
    "& .MuiSelect-outlined": {
      paddingTop: "14px",
      paddingBottom: "14px",
      paddingRight: "37px",
      paddingLeft: "20px",
    },
  },
  ib: {
    padding: "4px",
    color: "white",
    backgroundColor: "black",
    borderRadius: "6px",
    "&:focus": {
      color: "white",
      backgroundColor: "black",
    },
    "&:active": {
      boxShadow: "none",
      color: "white",
      backgroundColor: "black",
    },
    "&:hover": {
      color: "white",
      backgroundColor: "black",

      boxShadow: "none",
    },
  },
}));

function Results({
  userLogin,
  className,
  handleChangePage,
  pageSizePagination,
  handleDelete,
  items,
  page,
  totalData,
  ...rest
}) {
  const classes = useStyles();
  const history = useHistory();

  const onDelete = item => {
    handleDelete(item.article_id);
  };

  const handleClickEdit = item => {
    history.push({
      pathname: "/admin/berita/" + item.article_id,
      state: { customer: item },
    });
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <TableContainer className={classes.inner}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Action</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Publisher</TableCell>
              <TableCell>Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items?.length > 0 ? (
              items.map((customer, index) => (
                <TableRow hover key={`${customer.article_id}-${index}`}>
                  <TableCell
                    style={{
                      width: "10%",
                    }}
                    align="left"
                  >
                    <IconButton
                      classes={{
                        root: classes.ib,
                      }}
                      onClick={() => {
                        handleClickEdit(customer);
                      }}
                    >
                      <img src={PencilVector} />
                    </IconButton>
                    <ButtonWithModalPerItem
                      userLogin={userLogin}
                      item={customer}
                      dialogTitle={"Delete News"}
                      subTitle={"Are you sure you want to delete this data"}
                      handleDelete={onDelete}
                    />
                  </TableCell>
                  <TableCell>{customer.title}</TableCell>
                  <TableCell>
                    {customer.type === "berita" ? (
                      <Chip
                        style={{
                          backgroundColor: "#F0F8FE",
                          color: "#295ECC",
                          border: "1px solid #93C9FA",
                          textTransform: "capitalize",
                        }}
                        label={customer.type}
                      />
                    ) : (
                      <>{""}</>
                    )}
                    {customer.type === "iklan" ? (
                      <Chip
                        style={{
                          backgroundColor: "#FEF8E7",
                          color: "#A84D20",
                          border: "1px solid #F6C962",
                          textTransform: "capitalize",
                        }}
                        label={customer.type}
                      />
                    ) : (
                      <>{""}</>
                    )}
                    {customer.type === "layanan" ? (
                      <Chip
                        style={{
                          backgroundColor: "#EFFCF4",
                          color: "#34774C",
                          border: "1px solid #8DE5AB",
                          textTransform: "capitalize",
                        }}
                        label={customer.type}
                      />
                    ) : (
                      <>{""}</>
                    )}
                    {customer.type === "edukasi" ? (
                      <Chip
                        style={{
                          backgroundColor: "#F4ECFD",
                          color: "#7737BC",
                          border: "1px solid #CAA2F5",
                          textTransform: "capitalize",
                        }}
                        label={customer.type}
                      />
                    ) : (
                      <>{""}</>
                    )}
                  </TableCell>
                  <TableCell>
                    {customer.publisher !== null ? (
                      <>{customer.publisher.name}</>
                    ) : (
                      <>{"-"}</>
                    )}
                  </TableCell>
                  <TableCell>
                    <div>
                      <ThemeProvider theme={fontInter}>
                        <Typography
                          style={{
                            fontStyle: "normal",
                            fontWeight: 400,
                            fontSize: "14px",
                            lineHeight: "20px",
                            color: "#111827",
                          }}
                        >
                          {customer.date}
                        </Typography>
                      </ThemeProvider>
                    </div>
                    <div>
                      <ThemeProvider theme={fontInter}>
                        <Typography
                          style={{
                            fontStyle: "normal",
                            fontWeight: 400,
                            fontSize: "12px",
                            lineHeight: "18px",
                            color: "#687083",
                          }}
                        >
                          {customer.time}
                        </Typography>
                      </ThemeProvider>
                    </div>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6}>
                  <div
                    style={{
                      textAlign: "center",
                    }}
                  >
                    Data not found
                  </div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Grid
        alignContent="space-between"
        container
        justifyContent="space-between"
        style={{
          marginTop: "16px",
        }}
      >
        <Grid item>
          <div style={{ display: "flex" }}>
            <div style={{ marginTop: "7px" }}>
              <ThemeProvider theme={theme}>
                <Typography className={classes.paginationText}>
                  Items Per Page
                </Typography>
              </ThemeProvider>
            </div>
            <div style={{ marginLeft: "24px" }}>{pageSizePagination}</div>
          </div>
        </Grid>

        <Grid item>
          <Pagination
            count={totalData || 1}
            shape="rounded"
            className={classes.pagination}
            page={page}
            onChange={handleChangePage}
          />
        </Grid>
      </Grid>
    </div>
  );
}

Results.propTypes = {
  className: PropTypes.string,
  customers: PropTypes.array,
};

Results.defaultProps = {
  customers: [],
};

export default Results;
