import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  OutlinedInput,
  Paper,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CancelIcon from "@material-ui/icons/Cancel";
import CloseIcon from "@material-ui/icons/Close";
import Pagination from "@material-ui/lab/Pagination";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useRef, useState } from "react";
import BcaLogo from "../../../../assets/img/bca-logo.png";
import BniLogo from "../../../../assets/img/bni_logo.png";
import MandiriLogo from "../../../../assets/img/mandiri_logo.png";
import PencilVector from "../../../../assets/img/pencilVector.svg";
import { ButtonWithModalPerItem } from "../../../../components/atoms/Button";
import TableEditBar from "../../../../components/molecules/Table/TableEditBar";

const theme = createTheme({
  typography: {
    fontFamily: ["Helvetica Neue"].join(","),
  },
});

const fontInter = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 700,
    border: "1px solid #D1D5DC",
    borderRadius: "6px",
    boxShadow: "none",
  },
  nameCell: {
    display: "flex",
    alignItems: "center",
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1),
  },
  actions: {
    padding: theme.spacing(1),
    justifyContent: "flex-end",
  },
  pageSize: {
    height: "30px",
    borderRadius: "6px",
    border: "1px solid #D1D5DC",
    paddingLeft: "5px",
    paddingRight: "5px",
    marginTop: "5px",
  },
  paginationText: {
    fontSize: "14px",
    fontWeight: "normal",
    fontStyle: "normal",
  },
  pagination: {
    "& .Mui-selected": {
      backgroundColor: "black",
      color: "white",
    },
  },
  mypadding: {
    "& .MuiSelect-outlined": {
      paddingTop: "14px",
      paddingBottom: "14px",
      paddingRight: "37px",
      paddingLeft: "20px",
    },
  },
  ib: {
    padding: "4px",
    color: "white",
    backgroundColor: "black",
    borderRadius: "6px",
    "&:focus": {
      color: "white",
      backgroundColor: "black",
    },
    "&:active": {
      boxShadow: "none",
      color: "white",
      backgroundColor: "black",
    },
    "&:hover": {
      color: "white",
      backgroundColor: "black",
      boxShadow: "none",
    },
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const img = {
  display: "block",
  width: "auto",
  height: "100%",
  marginLeft: "auto",
  marginRight: "auto",
  borderRadius: "6px",
};

function Results({
  className,
  items,
  isLoading,
  handleChangePage,
  handleDelRec,
  handleEditRec,
  pageSizePagination,
  page,
  totalData,
  ...rest
}) {
  const classes = useStyles();
  const inputFile = useRef(null);
  const selectedCustomers = [];
  const [openConf, setOpenConf] = useState(false);
  const [recSelectedId] = useState(null);
  const [notUse] = useState(true);
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [newNamaBank, setNewNamaBank] = useState("");
  const [newKodeBank, setNewKodeBank] = useState("");
  const [newLogoBank, setNewLogoBank] = useState("");
  const [newDataLogoBank, setNewDataLogoBank] = useState(null);
  const [idBankSelected, setIdBankSelected] = useState(null);
  const [openImage, setOpenImage] = useState(false);
  const [imageToOpen, setImageToOpen] = useState(null);

  const handleDelConf = () => {
    setOpenConf(false);
    handleDelRec(recSelectedId);
  };

  const handleCloseConf = () => {
    setOpenConf(false);
  };

  const getBankPerId = id => {
    if (id === 0) {
      return (
        <>
          <img src={BcaLogo} style={{ width: "30%", marginRight: "10px" }} />
        </>
      );
    } else if (id === 1) {
      return (
        <>
          <img src={BniLogo} style={{ width: "30%", marginRight: "10px" }} />
        </>
      );
    } else if (id === 2) {
      return (
        <>
          <img
            src={MandiriLogo}
            style={{ width: "30%", marginRight: "10px" }}
          />
        </>
      );
    }
  };

  const onDelete = item => {
    handleDelRec(item.bank_id);
  };

  const handleEdit = item => {
    setNewNamaBank(item.name);
    setNewKodeBank(item.code);
    setNewLogoBank(item.profile_image);
    setIdBankSelected(item.bank_id);

    setOpenModalEdit(true);
  };

  const handleCloseEdit = () => {
    setOpenModalEdit(false);
  };

  const handleChangePhotoButton = e => {
    e.preventDefault();
    inputFile.current.click();
  };

  const onChangeFile = e => {
    const data = e.target.files[0];
    const object = URL.createObjectURL(data);

    setNewDataLogoBank(data);
    setNewLogoBank(object);
  };

  const simpanNewBank = () => {
    let itemObj = {
      bank_id: idBankSelected,
      code: newKodeBank,
      name: newNamaBank,
      profile_image: newDataLogoBank,
    };
    setOpenModalEdit(false);
    handleEditRec(itemObj);
  };

  const handleClickImage = item => {
    setImageToOpen(item.profile_image);

    setOpenImage(true);
  };

  const handleCloseImage = () => {
    setOpenImage(false);
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <TableContainer className={classes.inner}>
        <Dialog
          open={openConf}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleCloseConf}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            {"Hapus Data"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Apakah Yakin Akan Menghapus Data ini ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDelConf} color="primary">
              Ya
            </Button>
            <Button onClick={handleCloseConf} color="primary">
              Tidak
            </Button>
          </DialogActions>
        </Dialog>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Aksi</TableCell>
              <TableCell>Nama Bank</TableCell>
              <TableCell>Kode Bank</TableCell>
              <TableCell>Logo</TableCell>
            </TableRow>
          </TableHead>
          {isLoading ? (
            <TableBody>
              <TableRow>
                <TableCell>. . . Loading . . .</TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {items?.length > 0 ? (
                items.map(customer => (
                  <TableRow
                    hover
                    key={`${customer.bank_id}`}
                    selected={
                      selectedCustomers.indexOf(customer.bank_id) !== -1
                    }
                  >
                    <TableCell
                      style={{
                        width: "10%",
                      }}
                    >
                      <IconButton
                        classes={{
                          root: classes.ib,
                        }}
                        onClick={() => {
                          handleEdit(customer);
                        }}
                      >
                        <img src={PencilVector} />
                      </IconButton>

                      <ButtonWithModalPerItem
                        item={customer}
                        title={"Hapus Bank"}
                        dialogTitle={"Hapus Bank"}
                        subTitle={"Apakah Anda yakin akan menghapus data ini"}
                        handleDelete={onDelete}
                      />
                    </TableCell>
                    <TableCell
                      style={{
                        width: "30%",
                      }}
                    >
                      {customer.name}
                    </TableCell>
                    <TableCell
                      style={{
                        width: "30%",
                      }}
                    >
                      {customer.code}
                    </TableCell>

                    {notUse ? (
                      <>
                        <TableCell component="td" scope="row">
                          <img
                            onClick={() => {
                              handleClickImage(customer);
                            }}
                            src={customer.profile_image}
                            style={{
                              width: "15%",
                            }}
                          />
                        </TableCell>
                      </>
                    ) : (
                      <>
                        <TableCell component="td" scope="row" width={150}>
                          {getBankPerId(customer.bank_id)}
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div
                      style={{
                        textAlign: "center",
                      }}
                    >
                      Data tidak ditemukan
                    </div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          )}
        </Table>
        <Dialog
          open={openModalEdit}
          onClose={handleCloseEdit}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            id="alert-dialog-title"
            style={{
              padding: "0px 24px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <ThemeProvider theme={fontInter}>
                <Typography
                  style={{
                    fontWeight: 700,
                    fontSize: "16px",
                    lineHeight: "24px",
                    paddingBottom: "16px",
                    paddingTop: "16px",
                  }}
                >
                  Edit Bank
                </Typography>
              </ThemeProvider>
              <IconButton
                aria-label="close"
                onClick={() => setOpenModalEdit(false)}
              >
                <CloseIcon />
              </IconButton>
            </div>
          </DialogTitle>
          <Divider />
          <DialogContent>
            <div
              style={{
                marginTop: "16px",
              }}
            >
              <ThemeProvider theme={fontInter}>
                <Typography
                  style={{
                    fontWeight: 400,
                    fontSize: "14px",
                    lineHeight: "20px",
                  }}
                >
                  Nama Bank
                </Typography>
              </ThemeProvider>
            </div>
            <div
              style={{
                marginTop: "6px",
              }}
            >
              <OutlinedInput
                fullWidth={true}
                value={newNamaBank}
                onChange={e => setNewNamaBank(e.target.value)}
              />
            </div>
            <div
              style={{
                marginTop: "16px",
              }}
            >
              <ThemeProvider theme={fontInter}>
                <Typography
                  style={{
                    fontWeight: 400,
                    fontSize: "14px",
                    lineHeight: "20px",
                  }}
                >
                  Kode Bank
                </Typography>
              </ThemeProvider>
            </div>
            <div
              style={{
                marginTop: "6px",
              }}
            >
              <OutlinedInput
                fullWidth={true}
                value={newKodeBank}
                onChange={e => setNewKodeBank(e.target.value)}
              />
            </div>
            <div
              style={{
                marginTop: "16px",
              }}
            >
              <ThemeProvider theme={fontInter}>
                <Typography
                  style={{
                    fontWeight: 400,
                    fontSize: "14px",
                    lineHeight: "20px",
                  }}
                >
                  Logo Bank
                </Typography>
              </ThemeProvider>
            </div>
            <div
              style={{
                marginTop: "6px",
                border: "1px solid #D1D5DC",
                width: "402px",
                height: "56px",
                borderRadius: "6px",
                paddingTop: "10px",
                paddingBottom: "10px",
                display: "flex",
              }}
            >
              <input
                onChange={onChangeFile}
                ref={inputFile}
                accept="image/*"
                id="contained-button-file"
                multiple
                type="file"
                style={{
                  display: "none",
                }}
              />
              <Button
                variant="contained"
                style={{
                  marginLeft: "14px",
                  border: "1px solid #D1D5DC",
                  backgroundColor: "white",
                }}
                onClick={handleChangePhotoButton}
              >
                <ThemeProvider theme={fontInter}>
                  <Typography
                    style={{
                      fontWeight: 400,
                      fontSize: "14px",
                      lineHeight: "20px",
                      textTransform: "none",
                    }}
                  >
                    Unggah
                  </Typography>
                </ThemeProvider>
              </Button>
              {newLogoBank !== "" ? (
                <img src={newLogoBank} style={img} />
              ) : (
                <ThemeProvider theme={fontInter}>
                  <Typography
                    style={{
                      fontWeight: 400,
                      fontSize: "14px",
                      lineHeight: "20px",
                      textTransform: "none",
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto",
                      marginTop: "auto",
                      marginBottom: "auto",
                    }}
                  >
                    Belum ada
                  </Typography>
                </ThemeProvider>
              )}
            </div>
          </DialogContent>
          <Divider />
          <DialogActions
            style={{
              paddingLeft: "24px",
              paddingRight: "24px",
            }}
          >
            <Button
              onClick={() => {
                setOpenModalEdit(false);
              }}
              style={{
                height: "40px",
                width: "90px",
                borderRadius: "6px",
                border: "1px solid #D1D5DC",
                color: "#111827",
                backgroundColor: "white",
                textTransform: "none",
              }}
            >
              <ThemeProvider theme={fontInter}>
                <Typography
                  style={{
                    fontWeight: 400,
                    fontSize: "16px",
                    lineHeight: "24px",
                  }}
                >
                  Batal
                </Typography>
              </ThemeProvider>
            </Button>
            <Button
              onClick={() => {
                simpanNewBank();
              }}
              autoFocus
              style={{
                height: "40px",
                width: "90px",
                borderRadius: "6px",
                border: "0px solid #D1D5DC",
                color: "white",
                backgroundColor: "#111827",
                textTransform: "none",
              }}
            >
              <ThemeProvider theme={fontInter}>
                <Typography
                  style={{
                    fontWeight: 400,
                    fontSize: "16px",
                    lineHeight: "24px",
                  }}
                >
                  Simpan
                </Typography>
              </ThemeProvider>
            </Button>
          </DialogActions>
        </Dialog>
      </TableContainer>

      <Grid
        alignContent="space-between"
        container
        justifyContent="space-between"
        style={{ marginTop: "16px" }}
      >
        <Grid item>
          <div style={{ display: "flex" }}>
            <div style={{ marginTop: "7px" }}>
              <ThemeProvider theme={theme}>
                <Typography className={classes.paginationText}>
                  Item per halaman
                </Typography>
              </ThemeProvider>
            </div>{" "}
            <div style={{ marginLeft: "24px" }}>{pageSizePagination}</div>
          </div>
        </Grid>

        <Grid item>
          <Pagination
            count={totalData}
            shape="rounded"
            className={classes.pagination}
            page={page}
            onChange={(event, page) => handleChangePage(page)}
          />
        </Grid>
      </Grid>

      <Dialog
        onClose={handleCloseImage}
        aria-labelledby="simple-dialog-title"
        open={openImage}
      >
        <Paper elevation={0} />
        <CancelIcon
          onClick={() => {
            setOpenImage(false);
          }}
          style={{
            color: "black",
            position: "absolute",
            right: 0,
          }}
        />
        <img src={imageToOpen} alt="" />
        <Paper />
      </Dialog>
      <TableEditBar selected={selectedCustomers} />
    </div>
  );
}

Results.propTypes = {
  className: PropTypes.string,
  customers: PropTypes.array,
};

Results.defaultProps = {
  customers: [],
};

export default Results;
