import {
  Divider,
  Grid,
  ThemeProvider,
  Typography,
  colors,
  createTheme,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import newlogompis from "../../../assets/images/newlogompis.png";
import quoteSymbol from "../../../assets/images/quote-symbol.png";
import "./LandingStyle.css";
import footerSocialMediaLogo1 from "../../../assets/image-public/images/Frame-530.png";
import footerSocialMediaLogo2 from "../../../assets/image-public/images/Frame-531.png";

const interFont = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const useStyles = makeStyles(theme => ({
  divider: {
    backgroundColor: colors.grey[300],
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

const footerSocialMedia = [footerSocialMediaLogo1, footerSocialMediaLogo2];
const catchPhrase = ["Easy", "Flexible", "Streamline"];

function LandingFooter({ captions, type }) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div className="landing-footer">
      <Grid container direction={isMobile ? "column-reverse" : "row"}>
        <Grid item xs={12} md={3}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              height: isMobile ? "" : "431px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: isMobile ? "row" : "",
                justifyContent: isMobile ? "space-between" : "",
                alignItems: isMobile && "flex-end",
                margin: isMobile ? "16px 0 24px 0" : "",
              }}
            >
              <img
                alt={"new-mpis"}
                src={newlogompis}
                style={{
                  zIndex: 2,
                  marginBottom: !isMobile && "26px",
                  height: isMobile && "80px",
                }}
              />
              {isMobile && (
                <div>
                  {footerSocialMedia?.map((item, index) => (
                    <img
                      key={index}
                      alt={"new-mpis"}
                      src={item}
                      style={{
                        zIndex: 2,
                        marginRight: "16px",
                        height: 36,
                      }}
                    />
                  ))}
                  <div
                    style={{
                      marginLeft: isMobile ? "" : "10px",
                      marginTop: isMobile ? "10px" : "",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    {catchPhrase?.map((item, index) => (
                      <div key={index}>
                        <ThemeProvider theme={interFont}>
                          <Typography
                            style={{
                              fontWeight: 700,
                              fontSize: "18px",
                              lineHeight: "28px",
                              color: "white",
                            }}
                          >
                            {item}
                          </Typography>
                        </ThemeProvider>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
            <ThemeProvider theme={interFont}>
              <Typography
                style={{
                  fontWeight: 400,
                  fontSize: "14px",
                  lineHeight: "20px",
                  color: "white",
                  textAlign: isMobile ? "center" : "",
                }}
              >
                &copy; 2022 Music Publishing Information System
              </Typography>
            </ThemeProvider>
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: isMobile ? "" : "431px",
            }}
          >
            {type === "contact" ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                }}
              >
                <ThemeProvider theme={interFont}>
                  <Typography
                    style={{
                      fontWeight: 500,
                      fontSize: isMobile ? "18px" : "24px",
                      color: "white",
                    }}
                  >
                    {captions?.company_name}
                  </Typography>
                </ThemeProvider>
                <div
                  style={{
                    margin: isMobile ? "16px 0" : "32px 0",
                  }}
                >
                  <ThemeProvider theme={interFont}>
                    {captions?.address?.map((item, index) => (
                      <Typography
                        key={index}
                        style={{
                          fontWeight: 500,
                          fontSize: isMobile ? "14px" : "18px",
                          lineHeight: "32px",
                          color: "white",
                        }}
                      >
                        {item}
                      </Typography>
                    ))}
                  </ThemeProvider>
                </div>
                <div>
                  <ThemeProvider theme={interFont}>
                    {captions?.contactList?.map((item, index) => (
                      <div key={index}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: index > 0 && "10px",
                          }}
                        >
                          <div
                            style={{
                              width: isMobile ? "28px" : "40px",
                              height: isMobile ? "28px" : "40px",
                              textAlign: "center",
                              padding: isMobile ? "3px" : "8px",
                              borderRadius: "100px",
                              gap: "8px",
                              background:
                                "linear-gradient(129.36deg, #652D8D -9.07%, #E80088 137.98%)",
                            }}
                          >
                            <img
                              alt={`a-${index}`}
                              src={item?.icon}
                              style={{
                                zIndex: 2,
                                scale: isMobile && "70%",
                              }}
                            />
                          </div>
                          <Typography
                            style={{
                              fontWeight: 500,
                              fontSize: isMobile ? "12px" : "16px",
                              lineHeight: "32px",
                              color: "white",
                              marginLeft: "10px",
                            }}
                          >
                            {item?.title}
                          </Typography>
                        </div>
                      </div>
                    ))}
                  </ThemeProvider>
                </div>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div>
                  <img
                    alt={"quote-thumbnail"}
                    src={quoteSymbol}
                    className="quote-thumbnail"
                  />
                </div>
                <div>
                  <ThemeProvider theme={interFont}>
                    <Typography
                      style={{
                        fontWeight: 500,
                        margin: isMobile ? "16px 0 " : "40px 0",
                        width: isMobile
                          ? " 360px"
                          : type === "home"
                          ? "649px"
                          : "865px",
                        fontSize: isMobile ? "16px" : "28px",
                        lineHeight: isMobile ? "20px" : "44px",
                        textAlign: "center",
                        letterSpacing: "0em",
                        color: "white",
                        fontfamily: "Lato",
                      }}
                    >
                      {captions}
                    </Typography>
                  </ThemeProvider>
                </div>
                <div>
                  <img
                    alt={"quote-thumbnail"}
                    src={quoteSymbol}
                    className="quote-thumbnail"
                  />
                </div>
              </div>
            )}
            {!isMobile && <Divider className={classes.divider} />}
          </div>
        </Grid>
        {!isMobile && (
          <Grid item xs={12} md={3}>
            <div
              style={{
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "flex-end",
                height: isMobile ? "" : "431px",
              }}
            >
              {footerSocialMedia?.map((item, index) => (
                <img
                  key={index}
                  alt={"new-mpis"}
                  src={item}
                  style={{
                    zIndex: 2,
                    marginRight: "16px",
                  }}
                />
              ))}
              <div
                style={{
                  marginLeft: "10px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                {catchPhrase?.map((item, index) => (
                  <div key={index}>
                    <ThemeProvider theme={interFont}>
                      <Typography
                        style={{
                          fontWeight: 700,
                          fontSize: "18px",
                          lineHeight: "28px",
                          color: "white",
                        }}
                      >
                        {item}
                      </Typography>
                    </ThemeProvider>
                  </div>
                ))}
              </div>
            </div>
          </Grid>
        )}
      </Grid>
    </div>
  );
}

export default LandingFooter;
