import {
  Breadcrumbs,
  Button,
  Container,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import { useEffect, useState } from "react";
import { Page } from "components";
import { hardBaseUrl } from "../../../services/urlConstant";
import Results from "./Results";

const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  results: {
    marginTop: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  formControl: {
    width: "340px",
    border: "0px solid grey",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  pagination: {
    "& .Mui-selected": {
      backgroundColor: "black",
      color: "white",
    },
  },
  pageSize: {
    height: "30px",
    borderRadius: "6px",
    border: "1px solid #D1D5DC",
    paddingLeft: "5px",
    paddingRight: "5px",
    marginTop: "5px",
  },
  paginationText: {
    fontSize: "14px",
    fontWeight: "normal",
    fontStyle: "normal",
    color: "#687083",
  },
  breadcrumbs: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#111827",
  },
  breadCrumbsActive: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#687083",
  },
  placeholder: {
    "&::placeholder": {
      color: "#9AA2B1",
    },
  },
}));

const AdvancedPage = () => {
  const classes = useStyles();
  const composerOptions = "";
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const totalData = 1;
  const [page, setPage] = useState(1);
  const currentPageApi = 1;
  const totalSizeApi = 10;
  const searchKey = "";
  const dspSearch = "";

  const [response, setResponse] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        let theToken = localStorage.getItem("token");
        let baseOfUrl = hardBaseUrl;
        const url =
          baseOfUrl +
          "/publisher/transaction/advanced?page=" +
          currentPageApi +
          "&size=" +
          totalSizeApi +
          "&search=" +
          searchKey +
          "&dsp_id=" +
          dspSearch;
        const options = {
          headers: {
            Authorization: "Bearer " + theToken,
          },
        };
        const response = await axios.get(url, options);
        let newItem = [];
        response.data.data.forEach(item => {
          item["loan_array"] = [];
          newItem.push(item);
        });
        setResponse(newItem);
      } catch (error) {
        new Error(error);
      }
    };
    fetchUsers();
  }, []);

  const pagesCount = Math.ceil(totalData / rowsPerPage);

  const handleChangeRowsPerPage = event => {
    event.preventDefault();
    setRowsPerPage(event.target.value);

    setPage(1);
  };

  const pageSizePagination = () => {
    return (
      <div className={classes.pageSize}>
        <select
          style={{
            backgroundColor: "transparent",
            border: "none",
            outline: "none",
            marginTop: "4px",
          }}
          value={rowsPerPage}
          onChange={handleChangeRowsPerPage}
        >
          {[10, 20, 50].map(pageSize => {
            return (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            );
          })}
        </select>
      </div>
    );
  };

  const handleChangePage = (event, data) => {
    setPage(data);
  };
  return (
    <Page className={classes.root} title="Advance Transaction">
      <Container maxWidth={false}>
        <Grid
          alignItems="flex-end"
          container
          justifyContent="space-between"
          spacing={3}
        >
          <Grid item>
            <ThemeProvider theme={theme}>
              <Typography
                style={{
                  fontSize: "24px",
                  fontWeight: "700",
                  lineHeight: "32px",
                }}
              >
                Advance Transaction
              </Typography>
            </ThemeProvider>
          </Grid>
          <Grid item>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography className={classes.breadCrumbs}>Home</Typography>

              <Typography className={classes.breadCrumbs}>
                Transaction
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>

        <Divider className={classes.divider} />

        <div
          style={{
            marginTop: "24px",
          }}
        >
          <span
            style={{
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "14px",
              lineHeight: "20px",
            }}
          >
            Client Name
          </span>
        </div>

        <div
          style={{
            marginTop: "6px",
          }}
        >
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel
              id="demo-simple-select-outlined-label"
              shrink={false}
              style={{
                marginTop: "-5px",
              }}
            >
              {composerOptions !== "" ? null : "All Client"}
            </InputLabel>
            <Select
              style={{
                width: "340px",
                height: "44px",
                border: "0px solid #D1D5DC",
                borderRadius: "6px",
              }}
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={composerOptions}
              autoWidth={true}
            >
              <MenuItem value={""}>All</MenuItem>
              <MenuItem value={"layanan"}>Spotify</MenuItem>
              <MenuItem value={"berita"}>XL Axiata</MenuItem>
              <MenuItem value={"iklan"}>Soundcloud</MenuItem>
              <MenuItem value={"edukasi"}>Telkomsel</MenuItem>
            </Select>
          </FormControl>
        </div>

        <div
          style={{
            marginTop: "16px",
            border: "1px solid #D1D5DC",
            borderRadius: "10px",
            padding: "16px",
            width: "340px",
            backgroundColor: "#111827",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <div>
              <img
                alt="Logo"
                src={
                  require("assets/image-public/images/icon_images/ri_money-dollar-circle-fill.svg")
                    .default
                }
              />
            </div>
            <div
              style={{
                marginLeft: "18px",
              }}
            >
              <div>
                <span
                  style={{
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "14px",
                    lineHeight: "20px",
                    color: "#9AA2B1",
                  }}
                >
                  Advanced DSP
                </span>
              </div>
              <div
                style={{
                  marginTop: "8px",
                }}
              >
                <span
                  style={{
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: "700",
                    fontSize: "24px",
                    lineHeight: "32px",
                    color: "white",
                  }}
                >
                  Rp 10.000.000,-
                </span>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              <span
                style={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "16px",
                  lineHeight: "24px",
                  color: "#687083",
                }}
              >
                All Client
              </span>
            </div>
            <div>
              <span
                style={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "20px",
                  color: "#687083",
                }}
              >
                July 2022 - July 2024
              </span>
            </div>
          </div>
        </div>

        <div
          style={{
            marginTop: "24px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
            }}
          >
            <>
              <Button
                href="/admin/advance/add"
                style={{
                  backgroundColor: "#111827",
                  color: "white",
                  padding: "10px 28px 10px 28px",
                  marginRight: "10px",
                  width: "300px",
                }}
                variant="contained"
                size="large"
                startIcon={<AddIcon />}
              >
                <ThemeProvider theme={theme}>
                  <Typography
                    component="span"
                    style={{
                      fontSize: "14px",
                      lineHeight: "20px",
                      fontWeight: "500",
                      fontStyle: "normal",
                      textTransform: "none",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Create Transaction
                  </Typography>
                </ThemeProvider>
              </Button>
            </>
            <FormControl
              fullWidth
              className={classes.margin}
              variant="outlined"
            >
              <OutlinedInput
                placeholder="Search"
                id="outlined-adornment-amount"
                style={{
                  height: "44px",
                  border: "1px solid #D1D5DC",
                  borderRadius: "6px",
                }}
                inputProps={{
                  classes: {
                    input: classes.placeholder,
                  },
                }}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon style={{ color: "#9AA2B1" }} />
                  </InputAdornment>
                }
                fullWidth={false}
              />
            </FormControl>
          </div>
        </div>

        {response && (
          <Results
            className={classes.results}
            items={response}
            pageSizePagination={pageSizePagination()}
            totalData={pagesCount}
            page={page}
            handleChangePage={handleChangePage}
          />
        )}
      </Container>
    </Page>
  );
};

export default AdvancedPage;
