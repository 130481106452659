import {
  Breadcrumbs,
  Box,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  OutlinedInput,
  Snackbar,
  Switch,
  Typography,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import { InnoTable } from "inno-ui";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { ButtonGroupTop } from "../../../../components/atoms/Button";
import { Page, SkeletonComponent, PrimaryButton } from "components";
import { hardBaseUrl, hardTypeWeb } from "../../../../services/urlConstant";
import { getCookie } from "../../../../utils/constants";

const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function OriginalPublisher() {
  const [loadingPageSkeleton, setLoadingPageSkeleton] = useState(false);
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [response, setResponse] = useState([]);
  const [subpublisher, setSubpublisher] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [openSnack, setOpenSnack] = useState(false);
  const [typeSnackbar, setTypeSnackbar] = useState("success");
  const [message, setMessage] = useState("This is a message!");
  const [id, setId] = useState();
  const [loadingPage, setLoadingPage] = useState(false);
  const userLogin = getCookie("auth", `${process.env.REACT_APP_NAME}_user`);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseEdit = () => {
    setOpenEdit(false);
    setSubpublisher("");
    setEmail("");
    setPhone("");
  };
  const [getColor, setGetColor] = useState("");
  const handleChange = event => {
    const name = event.target.name;
    const value = event.target.value;
    if (name === "subpublisher") {
      setSubpublisher(value);
    } else if (name === "phone") {
      setPhone(value);
    } else if (name === "email") {
      setEmail(value);
    }
  };
  const getResponse = () => {
    setLoadingPageSkeleton(true);

    setLoadingPage(true);
    const theToken = localStorage.getItem("token");
    axios
      .get(hardBaseUrl + "/publisher/originalpublisher", {
        headers: {
          Authorization: "Bearer " + theToken,
        },
      })
      .then(res => {
        setResponse(res.data.data);
        setLoadingPage(false);
        setLoadingPageSkeleton(false);
      })
      .catch(() => {
        setLoadingPage(false);
        setLoadingPageSkeleton(false);
      });
  };
  const handleSubmit = e => {
    e.preventDefault();
    e.persist();

    // Regular expression to validate email format strictly
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (subpublisher === "") {
      setTypeSnackbar("error");
      setMessage("Sub-Publisher Name Cannot Be Empty.");
      setOpenSnack(true);
    } else if (phone === "") {
      setTypeSnackbar("error");
      setMessage("Phone Number Cannot Be Empty.");
      setOpenSnack(true);
    } else if (email === "") {
      setTypeSnackbar("error");
      setMessage("Email cannot be empty.");
      setOpenSnack(true);
    } else if (!emailRegex.test(email)) {
      setTypeSnackbar("error");
      setMessage("Email format is incorrect.");
      setOpenSnack(true);
    } else {
      // Semua input telah divalidasi
      const theToken = localStorage.getItem("token");
      const url = `${hardBaseUrl}/publisher/originalpublisher`;
      const data = {
        name: subpublisher,
        phone: phone,
        email: email,
      };

      axios
        .post(url, data, {
          headers: {
            Authorization: "Bearer " + theToken,
          },
        })
        .then(() => {
          handleClose();
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Data has been added",
            showConfirmButton: false,
            timer: 1500,
          });
          getResponse();
          setSubpublisher("");
          setPhone("");
          setEmail("");
          setOpen(false);
        })
        .catch(err => {
          handleClose();
          Swal.fire({
            position: "center",
            icon: "error",
            title: err.response.data.errors[0].message,
            showConfirmButton: false,
            timer: 1500,
          });
        });
    }
  };

  const handleDelete = async item => {
    const theToken = localStorage.getItem("token");
    const url = `${hardBaseUrl}/publisher/originalpublisher/${item.original_publisher_id}`;
    axios
      .delete(url, {
        headers: {
          Authorization: "Bearer " + theToken,
        },
      })
      .then(res => {
        if (res.data.message === "success") {
          Swal.fire({
            icon: "success",
            title: "Berhasil",
            text: "Data berhasil dihapus",
          }).then(result => {
            if (result.isConfirmed === true) {
              getResponse();
            }
          });
        }
      })
      .catch(e => {
        new Error(e);
      });
  };
  const handleEditMode = item => {
    const token = localStorage.getItem("token");
    setId(item.original_publisher_id);
    setOpenEdit(true);
    const url = `${hardBaseUrl}/publisher/originalpublisher`;
    axios
      .get(url, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then(res => {
        const { name, phone, email } = res?.data?.data?.find(
          ({ original_publisher_id }) =>
            original_publisher_id === item.original_publisher_id
        );
        setSubpublisher(name || "");
        setPhone(phone || "");
        setEmail(email || "");
      })
      .catch(e => {
        new Error(e);
      });
  };
  const handleEdit = e => {
    e.preventDefault();

    // Regular expression to validate email format strictly
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (subpublisher === "") {
      setTypeSnackbar("error");
      setMessage("Sub-Publisher Name Cannot Be Empty.");
      setOpenSnack(true);
    } else if (phone === "") {
      setTypeSnackbar("error");
      setMessage("Phone Number Cannot Be Empty.");
      setOpenSnack(true);
    } else if (email === "") {
      setTypeSnackbar("error");
      setMessage("Email cannot be empty.");
      setOpenSnack(true);
    } else if (!emailRegex.test(email)) {
      setTypeSnackbar("error");
      setMessage("Email format is incorrect.");
      setOpenSnack(true);
    } else {
      // Semua input telah divalidasi
      const theToken = localStorage.getItem("token");
      const url = `${hardBaseUrl}/publisher/originalpublisher/${id}`;
      const data = {
        name: subpublisher,
        phone: phone,
        email: email,
      };

      axios
        .put(url, data, {
          headers: {
            Authorization: "Bearer " + theToken,
          },
        })
        .then(() => {
          handleCloseEdit();
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Data has been changed",
            showConfirmButton: false,
            timer: 1500,
          });
          getResponse();
          setSubpublisher("");
          setPhone("");
          setEmail("");
          setOpenEdit(false);
        })
        .catch(err => {
          handleCloseEdit();
          Swal.fire({
            position: "center",
            icon: "error",
            title: err.response.data.errors[0].message,
            showConfirmButton: false,
            timer: 1500,
          });
        });
    }
  };

  const handleSwitch = async (event, id) => {
    const theToken = localStorage.getItem("token");
    const checked = event;
    const data = {
      is_active: checked,
    };
    if (checked) {
      const url = `${hardBaseUrl}/publisher/originalpublisher/status/${id}`;
      axios
        .put(url, data, {
          headers: {
            Authorization: "Bearer " + theToken,
          },
        })
        .then(() => {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Data berhasil diubah",
            showConfirmButton: false,
            timer: 1500,
          });
          getResponse();
        })
        .catch(() => {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "Data gagal diubah",
            showConfirmButton: false,
            timer: 1500,
          });
        });
    } else {
      const url = `${hardBaseUrl}/publisher/originalpublisher/status/${id}`;
      axios
        .put(url, data, {
          headers: {
            Authorization: "Bearer " + theToken,
          },
        })
        .then(() => {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Data berhasil diubah",
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch(() => {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "Data gagal diubah",
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .finally(() => {
          getResponse();
        });
    }
  };
  const getMainDsp = async () => {
    try {
      const token = localStorage.getItem("token");
      const url = `${hardBaseUrl}/me`;
      const res = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setGetColor(res.data.data.publisher.theme_color);
    } catch (e) {
      new Error(e);
    }
  };
  useEffect(() => {
    getResponse();
    getMainDsp();
  }, []);
  const handleCloseSnackbar = () => {
    setOpenSnack(false);
  };
  const onKeyDownNumber = e => {
    const regex = new RegExp(
      /(^\d*\.?\d*$)|(Backspace|Tab|Delete|ArrowLeft|ArrowRight)/
    );
    if (!regex.test(e.key)) {
      e.preventDefault();
    }
  };

  const useStyles = makeStyles(theme => ({
    root: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    container: {
      display: "grid",
    },
    divider: {
      margin: theme.spacing(2, 0),
      borderTop: "1px solid #e1e1e1",
    },
    results: {
      marginTop: theme.spacing(3),
    },
    paper: {
      position: "absolute",
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    buttonAddPublisher: {
      padding: "10px 28px 10px 28px",
      backgroundColor: getColor,
      color: "white",
      width: "237px",
      height: "40px",
      borderRadius: "6px",
      textTransform: "none",
      whiteSpace: "nowrap",
      justifySelf: "end",
      "&:hover": {
        backgroundColor: "black",
        color: "white",
      },
    },
    button: {
      padding: "10px 28px 10px 28px",
      backgroundColor: "black",
      color: "white",
      width: "237px",
      height: "40px",
      borderRadius: "6px",
      textTransform: "none",
      whiteSpace: "nowrap",
      "&:hover": {
        backgroundColor: "black",
        color: "white",
      },
    },
    subtitle: {
      fontSize: "18px",
      fontWeight: "bold",
      lineHeight: "28px",
    },
    breadcrumbs: {
      fontSize: "14px",
      fontWeight: 400,
      fontStyle: "regular",
      color: "#111827",
    },
    breadCrumbsActive: {
      fontSize: "14px",
      fontWeight: 400,
      fontStyle: "regular",
      color: "#687083",
    },
    rootSwitch: {
      width: "40px",
      height: "20px",
      padding: "0px",
      borderRadius: "10px",
      marginRight: "8px",
      position: "relative",
    },
    switchBase: {
      padding: "10px",
      color: "white",
      "&.Mui-checked": {},
      "&.Mui-checked + $track": {
        border: "none",
        color: "white",
        backgroundColor: "#111827",
        opacity: 1,
      },
    },
    checked: {},
    thumb: {
      color: "white",
      position: "absolute",
      width: "16px",
      height: "16px",
    },
    track: {
      backgroundColor: "#E4E7EB",
      borderRadius: "25px",
      border: "0px solid #606060",
      height: "auto",
      opacity: 1,
    },
    font24: {
      fontSize: "24px",
      fontWeight: "700",
      lineHeight: "32px",
    },
    cardParent: {
      border: "1px solid #D1D5DC",
      borderRadius: "6px",
      boxShadow: "none",
    },
    textStyle: {
      marginTop: "5px",
      color: "#8f8f8f",
      fontSize: "14px",
    },
    colorWhite: {
      color: "white",
    },
    typgraphBtn: {
      backgroundColor: getColor,
      color: "white",
      textTransform: "none",
    },
    btnSave: {
      backgroundColor: getColor,
      color: "white",
      textTransform: "none",
      marginRight: "16px",
    },
  }));

  const classes = useStyles();

  const RenderStatus = ({ item }) => {
    return (
      <React.Fragment>
        <div
          key={`${item.original_publisher_id}`}
          style={{
            textTransform: "capitalize",
          }}
        >
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  color={"default"}
                  focusVisibleClassName={classes.focusVisible}
                  disableRipple
                  classes={{
                    root: classes.rootSwitch,
                    switchBase: classes.switchBase,
                    thumb: classes.thumb,
                    track: classes.track,
                    checked: classes.checked,
                  }}
                  checked={item.is_active_flag}
                  onChange={e => handleSwitch(e, item.original_publisher_id)}
                  id={`${item.original_publisher_id}`}
                />
              }
            />
          </FormGroup>
        </div>
      </React.Fragment>
    );
  };
  const onChangeSwitch = (val, item) => {
    handleSwitch(val, item.original_publisher_id);
  };
  return (
    <Page className={classes.root} title="Origin Publisher">
      <Snackbar
        open={openSnack}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleCloseSnackbar} severity={typeSnackbar}>
          {message}
        </Alert>
      </Snackbar>
      {loadingPageSkeleton ? (
        <SkeletonComponent variant="wave" />
      ) : (
        <Container maxWidth={false} className={classes.container}>
          <Grid
            alignItems="flex-end"
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <ThemeProvider theme={theme}>
                <Typography className={classes.font24}>
                  Configuration
                </Typography>
              </ThemeProvider>
            </Grid>
            <Grid item>
              <Breadcrumbs aria-label="breadcrumb">
                <Typography className={classes.breadCrumbs}>Home</Typography>
                <Typography className={classes.breadCrumbs}>
                  Configuration
                </Typography>
                <Typography className={classes.breadCrumbsActive}>
                  {userLogin?.role?.type === "publisher"
                    ? "Original Publisher"
                    : "Sub-Publisher"}
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>
          <ButtonGroupTop />
          <Divider className={classes.divider} />
          <ThemeProvider theme={theme}>
            <Typography variant="h3" className={classes.subtitle}>
              List{" "}
              {userLogin?.role?.type === "publisher"
                ? "Original Publisher"
                : "Sub-Publisher"}
            </Typography>
          </ThemeProvider>
          <ThemeProvider theme={theme}>
            <p className={classes.textStyle}>
              To add data for{" "}
              {hardTypeWeb && hardTypeWeb === "caris"
                ? "original-publishers"
                : "original-publishers"}{" "}
              who have collaborated
            </p>
          </ThemeProvider>
          <Box margin="10px 0px" justifyContent="flex-end" display="flex">
            <PrimaryButton
              label={
                userLogin?.role?.type === "publisher"
                  ? "Add Original Publisher"
                  : "Add Sub-Publisher"
              }
              onClick={handleOpen}
            />
          </Box>
          <InnoTable
            columns={[
              {
                name: "is_active_flag",
                title: "Status",
                componentType: "switch",
                renderText: item => <RenderStatus item={item} />,
                handleChangeSwitch: (item, val) =>
                  onChangeSwitch(item.target.checked, val),
              },
              {
                name: "name",
                title: "Original-Publisher Name",
                componentType: "text",
              },
              {
                name: "phone",
                title: "Phone Number",
                componentType: "text",
              },
              {
                name: "email",
                title: "Email",
                componentType: "text",
              },
            ]}
            idColumnName={"original_publisher_id"}
            deleteName={"name"}
            isLoading={loadingPage}
            isHaveAction={true}
            isUsingCheckbox={false}
            isUsingDeleteConfirmation={true}
            items={response}
            loadingColor={""}
            handleDelete={handleDelete}
            handleEdit={handleEditMode}
          />
          <Dialog
            fullWidth={true}
            maxWidth="xs"
            open={open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
          >
            <form onSubmit={handleSubmit}>
              <DialogTitle id="responsive-dialog-title">
                {userLogin?.role?.type === "publisher"
                  ? "Add Original-Publisher"
                  : "Add Sub-Publisher"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  {userLogin?.role?.type === "publisher"
                    ? "Original-Publisher"
                    : "Sub-Publisher"}{" "}
                  Name *
                </DialogContentText>
                <FormControl
                  fullWidth
                  className={classes.margin}
                  variant="outlined"
                  required
                >
                  <OutlinedInput
                    id="outlined-adornment-amount"
                    name="subpublisher"
                    fullWidth={false}
                    onChange={handleChange}
                  />
                </FormControl>
              </DialogContent>
              <DialogContent>
                <DialogContentText>Phone Number *</DialogContentText>
                <FormControl
                  fullWidth
                  className={classes.margin}
                  variant="outlined"
                  required
                >
                  <OutlinedInput
                    id="outlined-adornment-amount"
                    name="phone"
                    onChange={handleChange}
                    type="number"
                    fullWidth={false}
                  />
                </FormControl>
              </DialogContent>
              <DialogContent>
                <DialogContentText>Email *</DialogContentText>
                <FormControl
                  fullWidth
                  className={classes.margin}
                  variant="outlined"
                  required
                >
                  <OutlinedInput
                    id="outlined-adornment-amount"
                    name="email"
                    onChange={handleChange}
                    fullWidth={true}
                  />
                </FormControl>
              </DialogContent>
              <DialogActions>
                <PrimaryButton label="Cancel" onClick={handleClose} />

                <PrimaryButton label="Save" onClick={handleSubmit} />
              </DialogActions>
            </form>
          </Dialog>
          <Dialog
            fullWidth
            maxWidth="xs"
            open={openEdit}
            onClose={handleCloseEdit}
            aria-labelledby="responsive-dialog-title"
          >
            <form onSubmit={handleEdit}>
              <DialogTitle id="responsive-dialog-title">
                {userLogin?.role?.type === "publisher"
                  ? "Edit Original-Publisher"
                  : "Edit Sub-Publisher"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  {userLogin?.role?.type === "publisher"
                    ? "Original-Publisher"
                    : "Sub-Publisher"}{" "}
                  Name *
                </DialogContentText>
                <FormControl
                  fullWidth
                  className={classes.margin}
                  variant="outlined"
                  required
                >
                  <OutlinedInput
                    id="outlined-adornment-amount"
                    name="subpublisher"
                    fullWidth={false}
                    onChange={handleChange}
                    value={subpublisher}
                  />
                </FormControl>
              </DialogContent>
              <DialogContent>
                <DialogContentText>Phone Number *</DialogContentText>
                <FormControl
                  fullWidth
                  className={classes.margin}
                  variant="outlined"
                  required
                >
                  <OutlinedInput
                    id="outlined-adornment-amount"
                    name="phone"
                    onChange={handleChange}
                    type="number"
                    fullWidth={false}
                    value={phone}
                  />
                </FormControl>
              </DialogContent>
              <DialogContent>
                <DialogContentText>Email *</DialogContentText>
                <FormControl
                  fullWidth
                  className={classes.margin}
                  variant="outlined"
                  required
                >
                  <OutlinedInput
                    id="outlined-adornment-amount"
                    name="email"
                    onChange={handleChange}
                    fullWidth={true}
                    value={email}
                  />
                </FormControl>
              </DialogContent>
              <DialogActions>
                <PrimaryButton label="Cancel" onClick={handleCloseEdit} />

                <PrimaryButton label="Update" onClick={handleEdit} />
              </DialogActions>
            </form>
          </Dialog>
        </Container>
      )}
    </Page>
  );
}
export default OriginalPublisher;
