import {
  Card,
  CardContent,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";

const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },

  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  formControl: {},
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  results: {
    marginTop: theme.spacing(3),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  pageSize: {
    height: "30px",
    borderRadius: "6px",
    border: "1px solid #D1D5DC",
    paddingLeft: "5px",
    paddingRight: "5px",
    marginTop: "5px",
  },
  paginationText: {
    fontSize: "14px",
    fontWeight: "normal",
    fontStyle: "normal",
  },
  breadcrumbs: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#111827",
  },
  breadCrumbsActive: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#687083",
  },
  placeholder: {
    "&::placeholder": {
      color: "#9AA2B1",
    },
  },
  conTextBlack: {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    marginBottom: "10px",
  },
  label: {
    color: "#364052",
    fontSize: "14px",
    fontWeight: 500,
    marginBottom: "5px",
  },
  subTitle: {
    fontSize: "18px",
    fontWeight: 700,
    color: " #111827",
  },
}));

const Detail = ({
  noKontrak,
  tglMulai,
  publisher,
  status,
  tglSelesai,
  idPublisher,
  keterangan,
  judul,
  kodeIsrc,
  kepemilikan,
  performer,
  dataPencipta,
}) => {
  const classes = useStyles();

  return (
    <div>
      <div>
        <Grid container>
          <div>
            <ThemeProvider theme={theme}>
              <Typography
                component="h1"
                variant="h3"
                className={classes.subTitle}
              >
                Contract Information
              </Typography>
            </ThemeProvider>
            <ThemeProvider theme={theme}>
              <p
                style={{
                  marginTop: "5px",
                  color: "#8f8f8f",
                  fontSize: "14px",
                }}
              >
                Contract information that has been made.
              </p>
            </ThemeProvider>
          </div>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={8} lg={8} xl={8}>
            <Card
              style={{
                border: "1px solid #9AA2B1",
                borderRadius: "6px",
                boxShadow: "none",
              }}
            >
              <CardContent>
                <ThemeProvider theme={theme}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6} lg={6} xl={6}>
                      <Typography className={classes.label}>
                        Contract Number
                      </Typography>
                      <Typography className={classes.conTextBlack}>
                        {noKontrak || "-"}
                      </Typography>
                      <Typography className={classes.label}>
                        Start Date
                      </Typography>
                      <Typography className={classes.conTextBlack}>
                        {tglMulai || "-"}
                      </Typography>
                      <Typography className={classes.label}>
                        Publisher
                      </Typography>
                      <Typography className={classes.conTextBlack}>
                        {publisher || "-"}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6} xl={6}>
                      <Typography className={classes.label}>Status</Typography>
                      <Typography className={classes.conTextBlack}>
                        {status || "-"}
                      </Typography>
                      <Typography className={classes.label}>
                        End Date
                      </Typography>
                      <Typography className={classes.conTextBlack}>
                        {tglSelesai || "-"}
                      </Typography>
                      <Typography className={classes.label}>
                        Publisher ID
                      </Typography>
                      <Typography className={classes.conTextBlack}>
                        {idPublisher || "-"}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} xl={12}>
                      <Typography className={classes.label}>
                        Description
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "16px",
                          fontWeight: 400,
                          color: "#687083",
                        }}
                      >
                        {keterangan || "-"}
                      </Typography>
                    </Grid>
                  </Grid>
                </ThemeProvider>
              </CardContent>
            </Card>
            <Divider className={classes.divider} />
          </Grid>
          <Grid item xs={12} md={4} lg={4} xl={4}></Grid>
        </Grid>
      </div>

      <div
        style={{
          marginTop: "20px",
        }}
      >
        <Grid container>
          <div>
            <ThemeProvider theme={theme}>
              <Typography
                component="h1"
                variant="h3"
                className={classes.subTitle}
              >
                Song Information
              </Typography>
            </ThemeProvider>
            <ThemeProvider theme={theme}>
              <p
                style={{
                  marginTop: "5px",
                  color: "#8f8f8f",
                  fontSize: "14px",
                }}
              >
                Detail song information.
              </p>
            </ThemeProvider>
          </div>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={8} lg={8} xl={8}>
            <Card
              style={{
                border: "1px solid #9AA2B1",
                borderRadius: "6px",
                boxShadow: "none",
              }}
            >
              <CardContent>
                <ThemeProvider theme={theme}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6} lg={6} xl={6}>
                      <Typography className={classes.label}>Title</Typography>
                      <Typography className={classes.conTextBlack}>
                        {judul || "-"}
                      </Typography>
                      <Typography className={classes.label}>
                        Ownership
                      </Typography>
                      <Typography className={classes.conTextBlack}>
                        {kepemilikan || "-"}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6} xl={6}>
                      <Typography className={classes.label}>
                        ISWC Code
                      </Typography>
                      <Typography className={classes.conTextBlack}>
                        {kodeIsrc || "-"}
                      </Typography>
                      <Typography className={classes.label}>
                        Performer
                      </Typography>
                      <Typography className={classes.conTextBlack}>
                        {performer || "-"}
                      </Typography>
                    </Grid>
                  </Grid>
                </ThemeProvider>
              </CardContent>
            </Card>
            <Divider className={classes.divider} />
          </Grid>
          <Grid item xs={12} md={4} lg={4} xl={4}></Grid>
        </Grid>
      </div>

      <div
        style={{
          marginTop: "20px",
        }}
      >
        <Grid container>
          <div>
            <ThemeProvider theme={theme}>
              <Typography
                component="h1"
                variant="h3"
                className={classes.subTitle}
              >
                Composer
              </Typography>
            </ThemeProvider>
            <ThemeProvider theme={theme}>
              <p
                style={{
                  marginTop: "5px",
                  color: "#8f8f8f",
                  fontSize: "14px",
                }}
              >
                Detail composer information.
              </p>
            </ThemeProvider>
          </div>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={8} lg={8} xl={8}>
            <Card
              style={{
                border: "1px solid #9AA2B1",
                borderRadius: "6px",
                boxShadow: "none",
              }}
            >
              <Table size="small">
                <TableHead>
                  <ThemeProvider theme={theme}>
                    <TableRow>
                      <TableCell>
                        <Typography
                          variant="subtitle2"
                          className={classes.headText}
                          style={{
                            whiteSpace: "nowrap",
                          }}
                        >
                          Composer Name
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="subtitle2"
                          className={classes.headText}
                          style={{
                            whiteSpace: "nowrap",
                          }}
                        >
                          ISWC Code
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="subtitle2"
                          className={classes.headText}
                          style={{
                            whiteSpace: "nowrap",
                          }}
                        >
                          Ownership Percentage
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </ThemeProvider>
                </TableHead>
                {dataPencipta === null || dataPencipta.length === 0 ? (
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={7}>
                        <div
                          style={{
                            marginTop: "10px",
                            marginBottom: "10px",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <Typography>Belum ada data</Typography>
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <TableBody>
                    {dataPencipta.map(row => (
                      <TableRow key={row.composer_id}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={classes.bodyText}
                            style={{
                              whiteSpace: "nowrap",
                            }}
                          >
                            {row.composer_name}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={classes.bodyText}
                            style={{
                              whiteSpace: "nowrap",
                            }}
                          >
                            {row.iswc_code || "-"}-
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={classes.bodyText}
                            style={{
                              whiteSpace: "nowrap",
                            }}
                          >
                            {row.ownership_percentage}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                )}
              </Table>
            </Card>
          </Grid>
          <Grid item xs={12} md={4} lg={4} xl={4}></Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Detail;
