export default function Publisher(params) {
  const { publisher, day, color, fontSize, dot } = params;
  return (
    <h3
      className="publisher"
      style={{
        color: color,
        fontSize: fontSize,
        marginTop: 0,
      }}
    >
      {publisher}{" "}
      <div
        style={{
          display: "inline-block",
          width: dot,
          height: dot,
          borderRadius: "50%",
          backgroundColor: color || "#9AA2B1",
          marginBottom: "2px",
          marginLeft: "8px",
          marginRight: "8px",
        }}
      ></div>{" "}
      {day}
    </h3>
  );
}
Publisher.defaultProps = {
  publisher: "PT NADAKU MUSIC",
  day: "0 Hari yang lalu",
  color: "#9AA2B1",
  fontSize: "",
  dot: 5,
};
