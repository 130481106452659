import {
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import { Page } from "components";

const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  results: {
    marginTop: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  dividerNew: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #9e9e9b",
  },
  formControl: {},
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  pagination: {
    "& .Mui-selected": {
      backgroundColor: "black",
      color: "white",
    },
  },
  pageSize: {
    height: "30px",
    borderRadius: "6px",
    border: "1px solid #D1D5DC",
    paddingLeft: "5px",
    paddingRight: "5px",
    marginTop: "5px",
  },
  paginationText: {
    fontSize: "14px",
    fontWeight: "normal",
    fontStyle: "normal",
    color: "#687083",
  },
  breadcrumbs: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#111827",
  },
  breadCrumbsActive: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#687083",
  },
  placeholder: {
    "&::placeholder": {
      color: "#9AA2B1",
    },
  },
  subTitle: {
    fontSize: "18px",
    fontWeight: 700,
    color: " #111827",
  },
  headText: {
    fontSize: "12px",
    color: "#687083",
    fontWeight: "bold",
  },
  tableChip: {
    marginTop: "5px",
  },
}));

const DetailDalamNegri = () => {
  const classes = useStyles();

  const songData = [];

  return (
    <Page className={classes.root} title="Claim Detail">
      <Container maxWidth={false}>
        <Grid
          alignItems="flex-end"
          container
          justifyContent="space-between"
          spacing={3}
        >
          <Grid item>
            <ThemeProvider theme={theme}>
              <Typography
                style={{
                  fontSize: "24px",
                  fontWeight: "700",
                  lineHeight: "32px",
                }}
              >
                Invoice
              </Typography>
            </ThemeProvider>
          </Grid>
          <Grid item>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography className={classes.breadCrumbs}>Home</Typography>

              <Typography className={classes.breadCrumbs}>
                Transaction
              </Typography>

              <Typography className={classes.breadCrumbsActive}>
                Detail Transaction
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>

        <Divider className={classes.divider} />

        <Grid container>
          <Grid item xs={12} md={8} lg={8} xl={8}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={6} xl={6}>
                <div>
                  <ThemeProvider theme={theme}>
                    <Typography
                      component="h1"
                      variant="h3"
                      className={classes.subTitle}
                    >
                      Print Transaction Notes
                    </Typography>
                  </ThemeProvider>
                  <ThemeProvider theme={theme}>
                    <p
                      style={{
                        marginTop: "10px",
                        color: "#8f8f8f",
                        fontSize: "14px",
                      }}
                    >
                      This is the preview of the transaction detail
                      <br />
                      before it was printed in pdf form
                    </p>
                  </ThemeProvider>
                </div>
              </Grid>
              <Grid item xs={12} md={6} lg={6} xl={6}>
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "flex-end",
                  }}
                >
                  {}
                  <Button
                    style={{
                      backgroundColor: "#111827",
                      color: "white",
                      padding: "10px 28px 10px 28px",
                      borderRadius: "6px",
                    }}
                    variant="contained"
                    size="large"
                    onClick={() => {}}
                  >
                    <ThemeProvider theme={theme}>
                      <Typography
                        component="span"
                        style={{
                          fontSize: "14px",
                          lineHeight: "20px",
                          fontWeight: "500",
                          fontStyle: "normal",
                          textTransform: "none",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Download As PDF
                      </Typography>
                    </ThemeProvider>
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4} lg={4} xl={4}></Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={8} lg={8} xl={8}>
            <Card
              style={{
                border: "0px solid #9AA2B1",
                borderRadius: "0px",
                boxShadow: "rgba(0, 0, 0, 0.35) 0px 2px 15px",
              }}
            >
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6} lg={6} xl={6}>
                    <img
                      src={""}
                      alt="img"
                      style={{
                        width: "155.52px",
                        height: "50px",
                        objectFit: "contain",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={6} xl={6}>
                    <div
                      style={{
                        textAlign: "right",
                      }}
                    >
                      <div>
                        <ThemeProvider theme={theme}>
                          <Typography
                            style={{
                              fontSize: "14px",
                              fontWeight: "600",
                              lineHeight: "20px",
                            }}
                          >
                            {"companyName"}
                          </Typography>
                          <Typography
                            style={{
                              fontSize: "14px",
                              fontWeight: "400",
                              lineHeight: "17px",
                              color: "#6F6F84",
                              wordWrap: "break-word",
                            }}
                          >
                            {"address"}
                          </Typography>
                        </ThemeProvider>
                      </div>
                    </div>
                  </Grid>
                </Grid>

                <Divider className={classes.divider} />

                <Grid container spacing={3}>
                  <Grid item xs={12} md={6} lg={6} xl={6}>
                    <ThemeProvider theme={theme}>
                      <Typography
                        style={{
                          fontSize: "14px",
                          fontWeight: "400",
                          lineHeight: "16.94px",
                        }}
                      >
                        Claim to :
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "14px",
                          fontWeight: "600",
                          lineHeight: "16.94px",
                        }}
                      >
                        {"dspName"}
                      </Typography>
                    </ThemeProvider>
                    <div
                      style={{
                        marginTop: "14px",
                      }}
                    >
                      <ThemeProvider theme={theme}>
                        <Typography
                          style={{
                            fontSize: "14px",
                            fontWeight: "400",
                            lineHeight: "16.94px",
                          }}
                        >
                          Subject :
                        </Typography>
                        <Typography
                          style={{
                            fontSize: "14px",
                            fontWeight: "600",
                            lineHeight: "16.94px",
                            textTransform: "capitalize",
                          }}
                        >
                          {"subject"}
                        </Typography>
                      </ThemeProvider>
                    </div>
                    <div
                      style={{
                        marginTop: "14px",
                      }}
                    >
                      <ThemeProvider theme={theme}>
                        <Typography
                          style={{
                            fontSize: "14px",
                            fontWeight: "400",
                            lineHeight: "16.94px",
                          }}
                        >
                          Account Number :
                        </Typography>
                        <Typography
                          style={{
                            fontSize: "14px",
                            fontWeight: "600",
                            lineHeight: "16.94px",
                            textTransform: "capitalize",
                          }}
                        >
                          {"Account Number"}
                        </Typography>
                      </ThemeProvider>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6} xl={6}>
                    <ThemeProvider theme={theme}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          style={{
                            fontSize: "14px",
                            fontWeight: "400",
                            lineHeight: "16.94px",
                          }}
                        >
                          Transaction Number :
                        </Typography>

                        <Typography
                          style={{
                            fontSize: "14px",
                            fontWeight: "400",
                            lineHeight: "16.94px",
                          }}
                        >
                          {"transactionNumber"}
                        </Typography>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: "5px",
                        }}
                      >
                        <Typography
                          style={{
                            fontSize: "14px",
                            fontWeight: "400",
                            lineHeight: "16.94px",
                          }}
                        >
                          Date :
                        </Typography>

                        <Typography
                          style={{
                            fontSize: "14px",
                            fontWeight: "400",
                            lineHeight: "16.94px",
                          }}
                        >
                          {"invoiceDate"}
                        </Typography>
                      </div>
                    </ThemeProvider>

                    <div
                      style={{
                        marginTop: "14px",
                      }}
                    >
                      <ThemeProvider theme={theme}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            style={{
                              fontSize: "14px",
                              fontWeight: "400",
                              lineHeight: "16.94px",
                              display: "none",
                            }}
                          >
                            Type :
                          </Typography>

                          <Typography
                            style={{
                              fontSize: "14px",
                              fontWeight: "400",
                              lineHeight: "16.94px",
                              display: "none",
                            }}
                          >
                            {"type"}
                          </Typography>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            style={{
                              fontSize: "14px",
                              fontWeight: "400",
                              lineHeight: "16.94px",
                            }}
                          >
                            Period :
                          </Typography>

                          <Typography
                            style={{
                              fontSize: "14px",
                              fontWeight: "400",
                              lineHeight: "16.94px",
                            }}
                          >
                            {"period"}
                          </Typography>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginTop: "5px",
                          }}
                        >
                          <Typography
                            style={{
                              fontSize: "14px",
                              fontWeight: "400",
                              lineHeight: "16.94px",
                            }}
                          >
                            Revenue :
                          </Typography>

                          <Typography
                            style={{
                              fontSize: "14px",
                              fontWeight: "400",
                              lineHeight: "16.94px",
                            }}
                          >
                            Rp {"revenue"},-
                          </Typography>
                        </div>
                      </ThemeProvider>
                    </div>
                  </Grid>
                </Grid>

                <Divider className={classes.divider} />

                <Card
                  style={{
                    border: "1px solid #9AA2B1",
                    borderRadius: "6px",
                    boxShadow: "none",
                    marginTop: "14px",
                    marginBottom: "14px",
                  }}
                >
                  <Table id="table-usage" size="small">
                    <TableHead>
                      <TableRow>
                        <ThemeProvider theme={theme}>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={classes.headText}
                            >
                              Song
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={classes.headText}
                            >
                              Composer/Author
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={classes.headText}
                            >
                              Type
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={classes.headText}
                            >
                              Revenue
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={classes.headText}
                            >
                              Notes
                            </Typography>
                          </TableCell>
                        </ThemeProvider>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {songData !== null ? (
                        songData?.length > 0 ? (
                          songData.map(customer => (
                            <TableRow hover key={customer.song_id}>
                              <TableCell>
                                <Typography
                                  variant="subtitle2"
                                  className={classes.bodyTextBlack}
                                >
                                  {customer.song_title}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  variant="subtitle2"
                                  className={classes.bodyTextGrey}
                                >
                                  {}
                                  {"customer.song_title"}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  variant="subtitle2"
                                  className={classes.bodyTextBlack}
                                >
                                  {customer.type}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  variant="subtitle2"
                                  className={classes.bodyTextBlack}
                                >
                                  Rp{" "}
                                  {customer.revenue
                                    .toString()
                                    .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.")}
                                  ,-
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  variant="subtitle2"
                                  className={classes.bodyTextBlack}
                                >
                                  {customer.notes}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={5}>
                              <center>
                                <ThemeProvider theme={theme}>
                                  <Typography
                                    variant="h6"
                                    style={{
                                      fontWeight: 400,
                                      color: "#687083",
                                      fontSize: "14px",
                                    }}
                                  >
                                    There&apos;s no data available.
                                  </Typography>
                                </ThemeProvider>
                              </center>
                            </TableCell>
                          </TableRow>
                        )
                      ) : (
                        <TableRow>
                          <TableCell colSpan={5}>
                            <center>
                              <ThemeProvider theme={theme}>
                                <Typography
                                  variant="h6"
                                  style={{
                                    fontWeight: 400,
                                    color: "#687083",
                                    fontSize: "14px",
                                  }}
                                >
                                  There&apos;s no data available.
                                </Typography>
                              </ThemeProvider>
                            </center>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </Card>

                <Card
                  style={{
                    border: "0px solid #9AA2B1",
                    borderRadius: "6px",
                    boxShadow: "none",
                    marginTop: "14px",
                    marginBottom: "14px",
                    background: "#f7f7f5",
                  }}
                >
                  <div
                    style={{
                      padding: "30px",
                    }}
                  >
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6} lg={6} xl={6}>
                        Revenue
                      </Grid>
                      <Grid item xs={12} md={6} lg={6} xl={6}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "end",
                          }}
                        >
                          <ThemeProvider theme={theme}>
                            <Typography
                              style={{
                                fontSize: "14px",
                                fontWeight: "600",
                              }}
                            >
                              {"Rp 2.000.000,-"}
                            </Typography>
                          </ThemeProvider>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={4} lg={4} xl={4}>
                        Value At Tax
                      </Grid>
                      <Grid item xs={12} md={4} lg={4} xl={4}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          10%
                        </div>
                      </Grid>
                      <Grid item xs={12} md={4} lg={4} xl={4}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "end",
                          }}
                        >
                          <ThemeProvider theme={theme}>
                            <Typography
                              style={{
                                fontSize: "14px",
                                fontWeight: "600",
                              }}
                            >
                              {"Rp 200.000,-"}
                            </Typography>
                          </ThemeProvider>
                        </div>
                      </Grid>
                    </Grid>
                    <Divider className={classes.dividerNew} />
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6} lg={6} xl={6}>
                        Revenue Total
                      </Grid>
                      <Grid item xs={12} md={6} lg={6} xl={6}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "end",
                          }}
                        >
                          <ThemeProvider theme={theme}>
                            <Typography
                              style={{
                                fontSize: "18px",
                                fontWeight: "600",
                              }}
                            >
                              {"Rp 2.200.000,-"}
                            </Typography>
                          </ThemeProvider>
                        </div>
                      </Grid>

                      <Grid item xs={12} md={6} lg={6} xl={6}>
                        Terbilang
                      </Grid>
                      <Grid item xs={12} md={6} lg={6} xl={6}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "end",
                          }}
                        >
                          <ThemeProvider theme={theme}>
                            <Typography
                              style={{
                                fontSize: "14px",
                                fontWeight: "600",
                              }}
                            >
                              {"Dua Juta Dua Ratus Dua Puluh Ribu Rupiah"}
                            </Typography>
                          </ThemeProvider>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </Card>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div>Sign From :</div>
                  <div>Sign To :</div>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "80px",
                  }}
                >
                  <div>
                    <ThemeProvider theme={theme}>
                      <Typography
                        style={{
                          fontSize: "14px",
                          fontWeight: "600",
                        }}
                      >
                        {"Caris Music Entertainment"}
                      </Typography>
                    </ThemeProvider>
                  </div>
                  <div>
                    <ThemeProvider theme={theme}>
                      <Typography
                        style={{
                          fontSize: "14px",
                          fontWeight: "600",
                        }}
                      >
                        {"Spotify"}
                      </Typography>
                    </ThemeProvider>
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default DetailDalamNegri;
