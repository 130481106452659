import { Box, Grid, Typography } from "@mui/material";
import axios from "axios";
import {
  CurrencyDisplay,
  FormLabel,
  ModalError,
  ModalSuccess,
  ModalWarning,
  PrimaryButton,
  SecondaryButton,
  SectionLabel,
  StatusChip,
  TableCellMultipleRow,
} from "components";
import { InnoTableV2 } from "inno-ui";
import { useParams } from "react-router";
import { formatDate, getErrors } from "utils";
import { hardBaseUrl } from "../../../../services/urlConstant";
import ContractStatusChip from "./ContractStatusChip";

const ContractDetailForm = ({
  preload,
  pageDetail,
  userRole,
  dataTableAdvance,
  dataTableAgents,
  getDetail,
  setLoadingPage,
}) => {
  const { id } = useParams();
  const { category } = preload;
  const isSongContract = category === "song";
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const { contract, songs } = pageDetail;

  const approvalStatus = contract?.approval_status;
  const financeApproval =
    userRole === "finance" &&
    approvalStatus !== "rejected" &&
    approvalStatus !== "approved" &&
    approvalStatus !== "";

  const handleApproveContract = status => {
    const approved = status === "approved";
    ModalWarning(
      `Are you sure you want to ${
        approved ? "approve" : "reject"
      } this contract?`,
      `${approved ? "Approve" : "Reject"} Contract`
    ).then(res => res?.isConfirmed && approveContract(status));
  };

  const approveContract = async status => {
    try {
      setLoadingPage(true);
      await axios.put(
        `${hardBaseUrl}/publisher/contract/approval-status/${id}`,
        { approval_status: status },
        { headers }
      );
      ModalSuccess(`Your contract has been ${status}`).then(() => getDetail());
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };

  return (
    <Box>
      <Box border="1px solid #ebebeb" borderRadius="5px" p="24px" my="16px">
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <FormLabel label="Approval Status" />
            {contract?.approval_status_update !== "" ? (
              <ContractStatusChip status={contract?.approval_status_update} />
            ) : (
              <ContractStatusChip status={approvalStatus} />
            )}
          </Grid>
          {financeApproval && (
            <Grid item>
              <Grid container columnSpacing={1}>
                <Grid item>
                  <SecondaryButton
                    label="Reject"
                    onClick={() => handleApproveContract("rejected")}
                  />
                </Grid>
                <Grid item>
                  <PrimaryButton
                    label="Approve"
                    onClick={() => handleApproveContract("approved")}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Box>
      <SectionLabel
        title={`${isSongContract ? "Song" : "Main"} Contract Information`}
        subTitle="Contract information that has been made."
      />
      <Box border="1px solid #ebebeb" borderRadius="5px" p="24px" my="16px">
        <Grid container rowSpacing={2}>
          {contractInformation({ contract })?.map((item, index) => (
            <Grid
              item
              xs={12}
              md={item?.label === "Description" ? 12 : 6}
              key={index}
            >
              <FormLabel label={item?.label} />
              {item?.type === "chip" ? (
                <StatusChip
                  type={item?.value && "success"}
                  label={item?.value ? "Active" : "Inactive"}
                />
              ) : (
                <Typography>{item?.value || "-"}</Typography>
              )}
            </Grid>
          ))}
        </Grid>
      </Box>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        my={!isSongContract && "16px"}
      >
        <Grid item>
          <SectionLabel
            title={isSongContract ? "Song Information" : "Composer"}
            subTitle={
              isSongContract
                ? "Detail song information."
                : "Composer who are part of this contract."
            }
          />
        </Grid>
        {!isSongContract && (
          <Grid item>
            <SectionLabel title={contract?.composer?.sure_name} />
          </Grid>
        )}
      </Grid>
      <Box my="16px">
        {isSongContract && (
          <InnoTableV2
            isLoading={false}
            items={songs}
            columns={columnTableSongContract}
          />
        )}
      </Box>
      <SectionLabel
        title="Advance Value"
        subTitle="Feature to set Advance Payment."
      />
      <Box my="16px">
        <InnoTableV2
          isLoading={false}
          items={dataTableAdvance}
          columns={columnTableAdvance({ isSongContract })}
        />
      </Box>
      {!isSongContract && (
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          my="16px"
        >
          <Grid item>
            <SectionLabel
              title="Publisher Share Percentage"
              subTitle="Feature to set publisher share percentage."
            />
          </Grid>
          <Grid item>
            <SectionLabel title={`${contract?.publisher_share_percentage} %`} />
          </Grid>
        </Grid>
      )}
      <SectionLabel
        title="Agent"
        subTitle="Feature to set Agent & fee Agent."
      />
      <Box my="16px">
        <InnoTableV2
          isLoading={false}
          items={dataTableAgents}
          columns={columnTableAgent({ isSongContract })}
        />
      </Box>
    </Box>
  );
};

const optionsFeeCalucationBasis = [
  { name: "Sales Amount", value: "sales_amount" },
  { name: "Net Revenue Amount", value: "net_revenue_amount" },
];
const contractInformation = ({ contract }) => [
  {
    label: "Contract Number",
    value: contract?.contract_number,
  },
  {
    label: "Contract Status",
    value: contract?.is_active_flag,
    type: "chip",
  },
  {
    label: "Start Date",
    value: formatDate(contract?.start_date),
  },
  {
    label: "End Date",
    value: formatDate(contract?.end_date),
  },
  {
    label: "Publisher",
    value: contract?.publisher,
  },
  {
    label: "Publisher ID",
    value: contract?.id_publisher,
  },
  {
    label: "Description",
    value: contract?.description,
  },
];
const columnTableSongContract = [
  {
    name: "title",
    title: "Song",
    renderText: item => item || "-",
  },
  {
    name: "composers",
    title: "Composer/Author Name",
    renderText: item => (
      <TableCellMultipleRow
        list={item}
        itemKey="composer_name"
        tooltipKey="composer_name"
      />
    ),
  },
  {
    name: "composers",
    title: <Box textAlign="right">Publisher Share Percentages</Box>,
    renderText: item => (
      <TableCellMultipleRow
        list={item}
        itemKey="publisher_share"
        tooltipKey="composer_name"
        type="number"
        suffix=" %"
      />
    ),
  },
  {
    name: "composers",
    title: <Box textAlign="right">Ownership Share Percentages</Box>,
    renderText: item => (
      <TableCellMultipleRow
        list={item}
        itemKey="ownership_percentage"
        tooltipKey="composer_name"
        type="number"
        suffix=" %"
      />
    ),
  },
  {
    name: "iswc_code",
    title: "ISWC Code",
    renderText: item => item || "-",
  },
];
const columnTableAdvance = ({ isSongContract }) => [
  ...(isSongContract
    ? [
        {
          name: "title",
          title: "Song",
        },
        {
          name: "composers",
          title: "Composer",
          renderText: item => (
            <TableCellMultipleRow
              list={item}
              itemKey="composer_name"
              tooltipKey="composer_name"
            />
          ),
        },
      ]
    : []),
  {
    name: "composers",
    title: <Box textAlign="right">Advance Value</Box>,
    renderText: item => (
      <TableCellMultipleRow
        list={item}
        itemKey="balance"
        tooltipKey="composer_name"
        type="number"
        prefix="Rp "
        suffix=",-"
      />
    ),
  },
  {
    name: "composers",
    title: "Start Period",
    renderText: item => (
      <TableCellMultipleRow
        list={item}
        itemKey="start_period"
        tooltipKey="composer_name"
        type="date"
      />
    ),
  },
  {
    name: "composers",
    title: "End Period",
    renderText: item => (
      <TableCellMultipleRow
        list={item}
        itemKey="end_period"
        tooltipKey="composer_name"
        type="date"
      />
    ),
  },
];
const columnTableAgent = ({ isSongContract }) => [
  ...(isSongContract
    ? [
        {
          name: "title",
          title: "Song",
          renderText: item => item || "-",
        },
        {
          name: "composers",
          title: "Composer",
          renderText: item => (
            <TableCellMultipleRow
              list={item}
              itemKey="composer_name"
              tooltipKey="composer_name"
            />
          ),
        },
      ]
    : []),
  {
    name: "agent_name",
    title: "Agent",
    renderText: item => item || "-",
  },
  {
    name: "agent_fee_formatted",
    title: "Agent Fee",
    renderText: item => <CurrencyDisplay value={Number(item)} suffix=" %" />,
  },
  {
    name: "fee_type",
    title: "Fee Calculation Basis",
    renderText: item =>
      item
        ? optionsFeeCalucationBasis?.find(fee => fee?.value === item)?.name
        : "-",
  },
];
export default ContractDetailForm;
