import { Button, Box, Checkbox, Grid, makeStyles } from "@material-ui/core";
import { Description } from "@material-ui/icons";
import axios from "axios";
import { InnoTable } from "inno-ui";
import { useEffect, useState } from "react";
import { hardBaseUrl } from "../../../services/urlConstant";
import {
  ModalError,
  MonthYearRangePicker,
  SelectInput,
  SkeletonComponent,
} from "components";
import moment from "moment-timezone";
import { getErrors } from "utils";

const token = localStorage.getItem("token");
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${token}`,
};

const useStyles = makeStyles(() => ({
  checkbox: {
    color: "black"
  },
  grid: {
    display: "flex",
    flexDirection: "row",
    alignItems: "end"
  }
}));
const formatMonthFilter = date => moment(date).format("YYYY-MM");

const ComponentUsageProcess = () => {
  const classes = useStyles();
  const [stateUsageProcess, setStateUsageProcess] = useState({
    listDSP: [],
    filter: {
      dsp: "",
      period: null,
      start_date: moment(),
      end_date: moment(),
    },
    loading: false,
    selectedDSP: [],
    usageProcess: [],
    page: 1,
    per_page: 10,
    total: 0,
  });
  const {
    filter,
    listDSP,
    loading,
    selectedDSP,
    page,
    per_page,
    usageProcess,
    total,
  } = stateUsageProcess;
  const { dsp, period, start_date, end_date } = filter;
  const setValue = (key, value) =>
    setStateUsageProcess(state => ({
      ...state,
      [key]: value,
    }));

  const setSelected = value => {
    const isHaveItem = selectedDSP.some(
      ({ file_path }) => file_path === value.file_path
    );
    if (isHaveItem) {
      setStateUsageProcess(state => ({
        ...state,
        selectedDSP: [...selectedDSP].filter(
          ({ file_path }) => file_path !== value.file_path
        ),
      }));
    } else {
      setStateUsageProcess(state => ({
        ...state,
        selectedDSP: [...state.selectedDSP, value],
      }));
    }
  };

  const setFilter = (key, value) =>
    setStateUsageProcess(state => ({
      ...state,
      filter: {
        ...state.filter,
        [key]: value,
      },
    }));

  const handleChangeStartDate = date => {
    if (end_date && moment(date).isAfter(end_date)) {
      setFilter("end_date", date);
    }
    setFilter("start_date", date);
  };

  const handleChangeEndDate = date => {
    if (start_date && moment(date).isBefore(start_date)) {
      setFilter("start_date", date);
    }
    setFilter("end_date", date);
  };

  const getDsps = async () => {
    setValue("loading", true);
    try {
      const getListDSP = await axios.get(
        `${hardBaseUrl}/publisher/dashboard/option`,
        {
          headers,
          params: {
            dsp_flag: "ACTIVE",
          },
        }
      );
      setValue("listDSP", getListDSP.data.data.dsp || []);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setValue("loading", false);
    }
  };
  const getListUsageDSP = async () => {
    if (dsp) {
      setValue("loading", true);
      const url = `${hardBaseUrl}/dsrf/datatable`;
      try {
        const response = await axios.get(url, {
          params: {
            dsp_name: dsp,
            period,
            start_date: formatMonthFilter(start_date),
            end_date: formatMonthFilter(end_date),
            page,
            per_page,
          },
          headers,
        });
        setStateUsageProcess(state => ({
          ...state,
          total: response.meta.found,
          usageProcess: response.data,
        }));
      } catch (error) {
        ModalError("Data yang anda cari tidak ditemukan.");
      } finally {
        setValue("loading", false);
      }
    }
  };

  const handleChangePage = (_, data) => setValue("page", data);
  const handleChangeRowsPerPage = event => {
    event.persist();
    setStateUsageProcess(state => ({
      ...state,
      per_page: Number(event.target.value),
      page: 1,
    }));
  };
  const tableColumn = [
    {
      name: "all",
      renderText: item => {
        const isChecked = selectedDSP.some(
          ({ file_path }) => file_path === item.file_path
        );
        return (
          <Checkbox
            className={classes.checkbox}
            onChange={() => setSelected(item)}
            checked={isChecked}
          />
        );
      },
    },
    {
      name: "file_name",
      title: "File Name",
      renderText: item => {
        return (
          <Grid
            className={classes.grid}
            direction="column"
            container
          >
            <Description />
            {item || "-"}
          </Grid>
        );
      },
    },
  ];

  useEffect(() => {
    getDsps();
  }, []);
  useEffect(() => {
    getListUsageDSP();
  }, [dsp, period, page, per_page]);
  useEffect(() => {
    const periodFilter = `${formatMonthFilter(start_date)} ${formatMonthFilter(
      end_date
    )}`;
    setFilter("period", periodFilter);
  }, [start_date, end_date]);

  const pagesCount = Math.ceil(total / per_page);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container justifyContent="flex-end" spacing={2}>
          <Grid item>
            <SelectInput
              label="DSP"
              value={dsp}
              onChange={({ target }) =>
                setFilter("dsp", target.value || "")
              }
              options={listDSP}
              optionKey="dsp_id"
              optionLabel="name"
              placeholder="None"
              width={250}
            />
          </Grid>
          <Grid item>
            <MonthYearRangePicker
              label="Period"
              startDate={start_date}
              handleChangeStartDate={handleChangeStartDate}
              endDate={end_date}
              handleChangeEndDate={handleChangeEndDate}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {loading ? (
          <SkeletonComponent variant="wave" />
        ) : (
          <InnoTable
            columns={tableColumn}
            isLoading={loading}
            items={usageProcess}
            page={page}
            rowsPerPage={per_page}
            totalPage={pagesCount}
            idColumnName="file_path"
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        )}
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" justifyContent="flex-end">
          {selectedDSP?.length > 0 && (
            <Button variant="outlined">Process</Button>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default ComponentUsageProcess;
