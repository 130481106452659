import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  TOKEN_VALID,
  TOKEN_INVALID,
} from "../../../constants/types";

const initialState = {
  isLoggedIn: false,
  loggedIn: false,
  user: {
    name: "aloha",
    first_name: "Shen",
    last_name: "Zhi",
    email: "demo@devias.io",
    avatar: require("assets/image-public/images/avatars/avatar_11.png").default,
    bio: "Brain Director",
    role: null,
  },
  message: null,
  error: false,
  loading: true,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case REGISTER_SUCCESS:
      return {
        ...state,
        isLoggedIn: false,
      };
    case REGISTER_FAIL:
      return {
        ...state,
        isLoggedIn: false,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        user: payload.user,
        message: "Auth Sukses",
        error: false,
        loading: false,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
        message: "Auth Error",
        error: true,
        loading: false,
      };
    case LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        loggedIn: false,
        // user: null,
        message: "Logged Out",
        user: {
          name: "aloha",
          first_name: "Shen",
          last_name: "Zhi",
          email: "demo@devias.io",
          avatar: require("assets/image-public/images/avatars/avatar_11.png").default,
          bio: "Brain Director",
          role: null,
        },
        error: false,
        loading: false,
      };
    case TOKEN_VALID:
      return {
        ...state,
        isLoggedIn: true,
        loggedIn: true,
        user: payload.user,
        message: "Token Valid",
        error: false,
        loading: false,
      };

    case TOKEN_INVALID:
      return {
        ...state,
        isLoggedIn: false,
        loggedIn: false,
        user: null,
        message: "Token INVALID",
        error: true,
        loading: false,
      };
    default:
      return state;
  }
}
