import { IconButton, useMediaQuery, useTheme } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import { useRef, useState } from "react";
import "react-multi-carousel/lib/styles.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import bgShade from "../../../assets/background/background_blog_shade.webp";
import bgImage from "../../../assets/background/background_partner_illustrative.webp";
import navLeft from "../../../assets/icons/icon_nav_left_landing.svg";
import navRight from "../../../assets/icons/icon_nav_right_landing.svg";
import avatarAdi from "../../../assets/images/testimony_adi.png";
import avatarAndhini from "../../../assets/images/testimony_andhini.png";
import avatarFarah from "../../../assets/images/testimony_farah.png";
import avatarKhandi from "../../../assets/images/testimony_khandi.png";
import avatarMarsha from "../../../assets/images/testimony_marsha.png";
import avatarYayan from "../../../assets/images/testimony_yayan.png";
import LandingFooter from "../../../components/templates/LandingPage/LandingFooter";
import LandingHero from "../../../components/templates/LandingPage/LandingHero";
import "../../../components/templates/LandingPage/LandingStyle.css";
import "./Partner.css";
import { PartnerSlide } from "./PartnerSlide";
import PublisherCard from "./PublisherCards";
import { publisherLists } from "./PublisherList";

const interFont = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

function Partner() {
  const sliderRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const settingsRs = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: isMobile ? 1 : 3,
    slidesToScroll: isMobile ? 1 : 3,
    arrows: false,
    beforeChange: (prev, next) => setCurrentSlide(next),
    appendDots: dots => (
      <div
        style={{
          borderRadius: "10px",
          padding: "10px",
          marginTop: "315px",
          marginLeft: "305px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <IconButton
          style={{
            width: "40px",
            height: "40px",
            zIndex: 2,
          }}
          onClick={prevSlide}
        >
          <img
            alt="nav-right"
            src={navLeft}
            style={{
              width: "40px",
              height: "40px",
            }}
          />
        </IconButton>
        <ul
          style={{
            margin: isMobile ? "0 10px" : "0 20px",
            listStyle: "none",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {dots?.map((_, index) => {
            const slidesToShow = isMobile ? 1 : 3;
            const targetSet = Math.floor(
              index + (isMobile ? 0 : 1) / slidesToShow
            );
            const targetSlide = targetSet * slidesToShow;
            return (
              <li
                key={index + 1}
                style={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                  background:
                    targetSlide === currentSlide
                      ? "linear-gradient(213.69deg, #E00388 -37.5%, #6E288B 112%)"
                      : "white",
                  color: targetSlide === currentSlide ? "white" : "#6E288B",
                  border:
                    targetSlide === currentSlide ? "none" : "solid 1px #6E288B",
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: isMobile ? "0 2.5px" : "0 5px",
                }}
                onClick={() => {
                  sliderRef.current.slickGoTo(targetSlide);
                }}
              >
                {index + 1}
              </li>
            );
          })}
        </ul>
        <IconButton
          style={{
            width: "40px",
            height: "40px",
            zIndex: 2,
          }}
          onClick={nextSlide}
        >
          <img
            alt="nav-right"
            style={{
              width: "40px",
              height: "40px",
            }}
            src={navRight}
          />
        </IconButton>
      </div>
    ),
  };

  const heroBackground = () => {
    return (
      <>
        <img
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            height: "100%",
            width: "100%",
            objectFit: "cover",
          }}
          alt="background-image"
          src={bgImage}
        />
        <img
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            height: "100%",
            width: "100%",
            objectFit: "cover",
          }}
          alt="background-shade"
          src={bgShade}
        />
      </>
    );
  };

  const heroContent = () => {
    return (
      <ThemeProvider theme={interFont}>
        <Typography
          style={{
            fontWeight: 800,
            fontSize: isMobile ? "60px" : "100px",
            color: "white",
            lineHeight: isMobile ? "60px" : "90px",
            maxWidth: "385px",
          }}
        >
          Our Partners
        </Typography>
        <Typography
          style={{
            fontWeight: 600,
            color: "white",
            fontSize: isMobile ? "18px" : "28px",
            margin: isMobile ? "12px 0" : "24px 0",
            lineHeight: "32px",
          }}
        >
          Together, we&apos;re building a better future for music
        </Typography>
        <Typography
          style={{
            fontWeight: 400,
            color: "white",
            fontSize: isMobile ? "16px" : "24px",
            maxWidth: "685px",
          }}
        >
          We are proud to partner with the best music publishing companies in
          the world. Together, we are building a better future for music by
          providing songwriters and artists with the resources and support they
          need to succeed.
        </Typography>
      </ThemeProvider>
    );
  };
  const testimonyList = [
    {
      name: "Adi",
      position: "Finance",
      content:
        "I'm often asked to give a royalty and song usage report in a short time. To do this, I had to process the report data I received from the client. Often the reports given are very difficult to understand and I need more time to process them into reports that are acceptable to my superiors and colleagues. Using MPIS I was able to create reports very easily, I just checked the Dashboard and downloaded the data I needed. MPIS greatly saved my time, I could do other tasks without having to worry about royalty and songs usage reports anymore.",
      image: avatarAdi,
    },
    {
      name: "Khandi",
      position: "Finance",
      content:
        "Before using MPIS, my working hours were spent counting the royalties of the songs I had. Not to mention each platform has a different formula and method of calculation, it made me have to devote more time and concentration to working on royalty reports. Because I have to count it one by one, by line of data, and by type of service. With MPIS, I just need to explain the formula and how to calculate each platform, and then MPIS does the calculation. The results of the calculations I can get in the form of dashboards and reports that I can check and download. With MPIS, the work I used to do for months could be done in less time.",
      image: avatarKhandi,
    },
    {
      name: "Andhini",
      position: "Finance",
      content:
        "Nowadays there are a lot of streaming platforms that listeners can use to listen to their favorite songs. It is of course very profitable for Publisher to be able to reach more listeners on each different streaming service. With the plethora of platforms and services of course raises the question, on which service and platform can I maximize the promotion of the songs? So from that I need a service that can hold all the royalty information from each platform, so I can more accurately determine on which platform I'm going to maximize the promotion on my songs. MPIS present offers that, with it I can compare the royalti reports and the usage of my songs from every platform. I just accessed the dashboard, selected the DSP and period I wanted, and voilà, I got the comparison report I needed.",
      image: avatarAndhini,
    },
    {
      name: "Farah",
      position: "Marketing",
      content:
        "With the advancement of recording technology, nowadays everyone can easily produce music to their liking. This, of course, makes more and more artists and new songs emerge, and leads to increasing competition to win the hearts of listeners. I, as a Marketing Team, of course need an analysis and accurate data information to determine which songs I'm going to promote. The amount of streaming platforms also affects that, I have to find out on which platforms my songs are listened to a lot. MPIS is present and offers the data, information and reports I need, my analysis and marketing strategy are more accurate with the help of MPIS.",
      image: avatarFarah,
    },
    {
      name: "Marsha",
      position: "Admin",
      content:
        "I work at one of the largest and oldest publishers in the country. They have thousands of catalogs of songs from several generations. As the times evolved, it was necessary to digitize the songs catalog data that Publisher had. It's my own challenge to solve that. With MPIS I can manage my songs catalog data efficiently and organized. This digitization process helped my work in verifying the royalties and usage of songs owned by Publisher.",
      image: avatarMarsha,
    },
    {
      name: "Yayan",
      position: "Admin",
      content:
        "I often meeting with prospective clients who will use my songs. A presentation usually requires a simple and interesting data display to attract the client. Previously I always took a few days to make this presentation material, because I had to translate the report of song usage from the streaming platform into a chart or graph that was easy to read by the public. Fortunately MPIS has a dashboard display in a graphical form that is very easy to modify and understand, so I just accessed MPIS to get the presentation frame I needed.",
      image: avatarYayan,
    },
  ];

  const nextSlide = () => {
    sliderRef.current.slickNext();
  };

  const prevSlide = () => {
    sliderRef.current.slickPrev();
  };

  return (
    <>
      <LandingHero heroBackground={heroBackground} heroContent={heroContent} />
      <div className="landing-content">
        <Grid container spacing={3}>
          {publisherLists?.map(item => (
            <Grid
              key={item.id}
              item
              xs={6}
              md={2}
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <PublisherCard publisher={item} />
            </Grid>
          ))}
        </Grid>
      </div>

      <div
        style={{
          backgroundColor: "#F9FAFB",
          padding: "80px 203px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            width: isMobile ? "365px" : "1200px",
            marginBottom: "102px",
          }}
        >
          <Slider ref={sliderRef} {...settingsRs}>
            {testimonyList?.map((item, index) => (
              <PartnerSlide
                key={index}
                index={index}
                name={item?.name}
                image={item?.image}
                position={item?.position}
                content={item?.content}
              />
            ))}
          </Slider>
        </div>
      </div>
      <LandingFooter captions="Solutions for the music industry to transparent royalty system" />
    </>
  );
}

export default Partner;
