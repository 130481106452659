import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import MuiAlert from "@material-ui/lab/Alert";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import _, { debounce, uniqWith } from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import "../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import updateIcon from "../../../../assets/img/pencilVector.svg";
import deleteIcon from "../../../../assets/img/trashVector.svg";
import { ModalError, Page } from "components";
import { hardBaseUrl } from "../../../../services/urlConstant";
import { getCookie } from "../../../../utils/constants";
import ChipComponentAdd from "./ChipComponentAdd";
import { getErrors } from "utils";

const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  thePercent: {
    fontSize: "30px",
  },
  results: {
    marginTop: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  btnSubmit: {
    backgroundColor: "black",
    color: "white",
    padding: "10px 28px",
    width: "106px",
    height: "40px",
    borderRadius: "6px",

    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
  btnPengaturanPencipta: {
    backgroundColor: "black",
    color: "white",
    padding: "10px 28px",
    borderRadius: "6px",
    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
  btnPreview: {
    backgroundColor: "black",
    marginLeft: "10px",
    color: "white",
    padding: "10px 28px",
    width: "106px",
    height: "40px",
    borderRadius: "6px",

    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
  btnDialog: {
    backgroundColor: "black",
    marginLeft: "10px",
    color: "white",
    padding: "10px 19px",
    width: "150px",

    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  headText: {
    fontSize: "12px",
    color: "#687083",
    fontWeight: "bold",
  },
  bodyTextBlack: {
    fontSize: "14px",
    fontWeight: "regular",
    color: "#111827",
    fontStyle: "normal",
  },
  dropdown: {
    color: "black",
  },
  conTextBlack: {
    fontSize: "14px",
    fontWeight: "bold",
  },
  label: {
    color: "#364052",
    fontSize: "14px",
    fontWeight: 500,
  },
  subTitle: {
    fontSize: "18px",
    fontWeight: 700,
    color: " #111827",
  },
  breadcrumbs: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#111827",
  },
  breadCrumbsActive: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#687083",
  },
  btnEdit: {
    backgroundColor: "#111827",
    color: "white",
    width: "30px",
    height: "35px",
    padding: "6px",
    borderRadius: "6px",
    "&:hover": {
      backgroundColor: "#111827",
      color: "white",
    },
  },
  btnDelete: {
    backgroundColor: "#111827",
    color: "white",
    width: "35px",
    height: "35px",
    padding: "6px",
    borderRadius: "6px",
    marginLeft: "10px",
    cursor: "pointer",
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function TambahLagu(props) {
  const ref = useRef(null);
  const classes = useStyles();
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const [judul, setJudul] = useState("");
  const [publisher, setPublisher] = useState("");
  const [publisherList, setPublisherList] = useState([]);
  const [performerList, setPerformerList] = useState([]);
  const [penciptaData, setPenciptaData] = useState([]);
  const [penciptaList, setPenciptaList] = useState([]);
  const [openSnack, setOpenSnack] = useState(false);
  const [typeSnackbar, setTypeSnackbar] = useState("success");
  const [message, setMessage] = useState("This is a message!");
  const [openModalSelect, setOpenModalSelect] = useState(false);
  const [percentage, setPercentage] = useState(0);
  const [getColor, setGetColor] = useState("");
  const [relatedIsrc, setRelatedIsrc] = useState("");
  const publisherShare = 0;
  const [statusDialog, setStatusDialog] = useState("add");
  const [indexSel, setIndexSel] = useState("");
  const [failedNotif, setFailedNotif] = useState(false);
  const [position, setPosition] = useState(0);
  const [listboxNode, setListboxNode] = useState("");
  const [isLoadingList, setIsLoadingList] = useState({
    pencipta: false,
    performer: false,
  });

  const [performerData, setPerformerData] = useState([]);
  const [performerDataTable, setPerformerDataTable] = useState([]);
  const [openModalSelectPerformer, setOpenModalSelectPerformer] = useState(
    false
  );
  const [
    openModalSelectPerformerAlias,
    setOpenModalSelectPerformerAlias,
  ] = useState(false);
  const [statusDialogAlias, setStatusDialogAlias] = useState("add");
  const [aliasName, setAliasName] = useState("");
  const [selectedAliasIndex, setSelectedAliasIndex] = useState(null);
  const [delLabel, setDelLabel] = useState("");
  const [workcode, setWorkcode] = useState("");
  const [tableAliasName, setTableAliasName] = useState([]);
  const [confirmDel, setConfirmDel] = useState(false);

  const [paramsPencipta, setParamsPencipta] = useState({
    page: 1,
    limit: 10,
    search: "",
  });
  const [paramsPerformer, setParamsPerformer] = useState({
    page: 1,
    limit: 10,
    search: "",
  });
  const [metaList, setMetaList] = useState({
    penciptaMeta: {},
    performerMeta: {},
  });

  const getPenciptaList = async () => {
    setIsLoadingList({ ...isLoadingList, pencipta: true });
    try {
      const url = hardBaseUrl + "/publisher/composer";

      const res = await axios.get(url, {
        headers,
        params: {
          page: paramsPencipta.search ? 1 : paramsPencipta.page,
          size: paramsPencipta.limit,
          search: paramsPencipta.search,
        },
      });
      if (res?.data?.meta?.http_status === 200) {
        setMetaList(prev => ({ ...prev, penciptaMeta: res.data.meta }));
        setPenciptaList(prev =>
          uniqWith([...prev, ...res.data.data], (arr, otherArr) => {
            return arr.composer_id === otherArr.composer_id;
          })
        );
      }
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setIsLoadingList({ ...isLoadingList, pencipta: false });
    }
  };
  const debouncePenciptaList = useCallback(
    debounce(() => {
      getPenciptaList();
    }, 500),
    [paramsPencipta]
  );
  const getPerformerList = useCallback(async () => {
    setIsLoadingList({ ...isLoadingList, performer: true });

    try {
      const url = hardBaseUrl + "/publisher/performer";
      const res = await axios.get(url, {
        headers,
        params: {
          page: paramsPerformer.search ? 1 : paramsPerformer.page,
          size: paramsPerformer.limit,
          search: paramsPerformer.search,
        },
      });
      if (res?.data?.meta?.http_status === 200) {
        setMetaList(prev => ({ ...prev, performerMeta: res.data.meta }));
        setPerformerList(prev =>
          uniqWith([...prev, ...res.data.data], (arr, otherArr) => {
            return arr.performer_id === otherArr.performer_id;
          })
        );
      }
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setIsLoadingList({ ...isLoadingList, performer: false });
    }
  }, [paramsPerformer]);
  const debouncePerformerList = useCallback(
    debounce(() => {
      getPerformerList();
    }, 500),
    [paramsPerformer]
  );
  useEffect(() => {
    const getPublisherList = async () => {
      try {
        let baseOfUrl = hardBaseUrl;
        const token = localStorage.getItem("token");
        const url = baseOfUrl + "/publisher/song/subpublisher";

        const res = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setPublisherList(res.data.data);
      } catch (error) {}
    };

    const getMainDsp = async () => {
      try {
        const token = localStorage.getItem("token");
        const url = `${hardBaseUrl}/me`;
        const res = await axios.get(url, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        setGetColor(res.data.data.publisher.theme_color);
      } catch (e) {}
    };

    getMainDsp();

    getPublisherList();
  }, []);
  const handleChangeSearchDropdownPencipta = e => {
    setParamsPencipta({ ...paramsPencipta, search: e?.target?.value });
  };

  useEffect(() => {
    debouncePenciptaList();
    return () => {
      debouncePenciptaList.cancel();
    };
  }, [paramsPencipta, debouncePenciptaList]);
  useEffect(() => {
    debouncePerformerList();
    return () => {
      debouncePerformerList.cancel();
    };
  }, [paramsPerformer, debouncePerformerList]);
  useEffect(() => {
    if (listboxNode !== "" && position) {
      listboxNode.scrollTop = position;
    }
  }, [position, listboxNode]);

  useEffect(() => {
    const sum = penciptaData.reduce(
      (a, b) => Number(a) + Number(b.percentage_ownership),
      0
    );
    setPercentage(sum);
  }, [penciptaData]);

  const handleChange = event => {
    const { name, value } = event.target;
    if (name === "judul") {
      setJudul(value);
    } else if (name === "publisher") {
      setPublisher(value);
    } else if (name === "performer") {
    } else if (name === "relatedIsrc") {
      setRelatedIsrc(value);
    } else if (name === "aliasName") {
      setAliasName(value);
    } else if (name === "workcode") {
      setWorkcode(value);
    }
  };

  const fetchMoreDataPencipta = () => {
    setParamsPencipta(prev => ({
      page: prev.page + 1,
      limit: prev.limit,
      search: prev.search,
    }));
  };
  const reformatSongRec = () => {
    let newItemOuter = [];
    if (performerDataTable?.length > 0) {
      let newVal = _.map(performerDataTable, item => {
        let thePerformers = item.performers;

        let insideMap = _.map(thePerformers, performer => {
          let performerItem = {
            performer_id: performer.performer_id,
            performer_name: `${performer.first_name} ${performer.last_name}`,
          };
          return performerItem;
        });
        let checkIsrc = item.isrc;
        if (checkIsrc === "") {
          checkIsrc = "-";
        }
        let newItem = {
          song_recording_id: 0,
          isrc_code: checkIsrc,
          performers: insideMap,
        };
        return newItem;
      });

      newItemOuter = newVal;
    } else {
      newItemOuter = [];
    }
    return newItemOuter;
  };
  const notifError = text =>
    Swal.fire({
      title: "Oops…",
      icon: "error",
      text: text,
      showCancelButton: false,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK",
    }).then(result => {
      if (result.isConfirmed) {
      }
    });

  const checkPencipta = async () => {
    let res = true;
    penciptaData.forEach((itm, idx) => {
      if (!+itm.percentage_ownership || !itm.percentage_ownership) {
        res = false;
        return notifError("Ownership Percentage can't be empty");
      }
      if (!+itm.choosenType?.length) {
        res = false;
        return notifError("Role can't be empty");
      }
      if (!+itm.percentage_publisher || !itm.percentage_publisher) {
        res = false;
        return notifError("Publisher percentage can't be empty");
      }
    });
    return res;
  };
  const handleButtonSimpan = async e => {
    e.preventDefault();
    const form = ref.current;

    const token = localStorage.getItem("token");

    if (judul === "") {
      return notifError("Song title can't be empty");
    }
    if (publisher === "") {
      return notifError("Publisher can't be empty");
    }
    if (!workcode) {
      return notifError("Work code can't be empty");
    }

    if (penciptaData?.length === 0) {
      return notifError("Composer/Author can't be empty");
    }
    if (
      judul !== "" &&
      publisher !== "" &&
      workcode &&
      penciptaData?.length !== 0
    ) {
      let resCheckPencipta = await checkPencipta();

      if (resCheckPencipta === true) {
        let songComposerData = [];
        penciptaData.map(item => {
          songComposerData.push({
            composer_id: item.composer_id,
            composer_percentage: parseFloat(item.percentage_ownership),

            composer_roles: item.choosenType,
            share_percentage: parseFloat(item.percentage_publisher),
          });
        });

        let songPerformerData = [];

        performerData.map(item => {
          songPerformerData.push(item.performer_id);
        });
        let formatSongRec = await reformatSongRec();

        let payload = {
          song_id: 0,
          song_title: judul,
          iswc_code: form["isrc"].value,
          isrc_code: relatedIsrc,
          alias_names: tableAliasName,
          song_aliases: tableAliasName,
          original_publisher_id: publisher,
          list_performer_id: songPerformerData,
          publisher_share_percentage: parseFloat(publisherShare),

          song_composers: songComposerData,
          song_recordings: formatSongRec,
          work_code: workcode,
        };

        let baseOfUrl = hardBaseUrl;
        let urlUse = baseOfUrl + "/publisher/song/create-or-update";
        try {
          let resultAdd = await axios.post(urlUse, payload, {
            headers: {
              Authorization: "Bearer " + token,
            },
          });

          if (
            resultAdd.data.message === "success" &&
            resultAdd.data.meta.http_status === 200
          ) {
            setTypeSnackbar("success");
            Swal.fire({
              title: "Success",
              text: "Song data was successfully added",
              icon: "success",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "OK",
            }).then(result => {
              if (result.isConfirmed === true) {
                props.history.push("/admin/parameter/lagu-admin");
              }
            });
          } else {
            setTypeSnackbar("error");
            setMessage("Tambah Lagu Gagal.");
            setOpenSnack(true);
          }
        } catch (err) {
          Swal.fire({
            title: "Oops…",
            text: `${err.response.data.errors[0].message}`,
            icon: "error",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "OK",
          }).then(() => {});
        }
      }
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnack(false);
  };

  const onChangeDialogPerformer = (e, data) => {
    const newData = data.map(item => ({
      ...item,
    }));
    setPerformerData(newData);
  };
  const onClickDialogPerformer = e => {
    e.preventDefault();

    if (performerDataTable?.length > 0) {
      let resFind = _.find(performerDataTable, function(o) {
        return o.isrc === relatedIsrc;
      });
      if (typeof resFind === "undefined") {
        let arrTable = {
          isrc: relatedIsrc,
          performers: performerData,
        };
        setPerformerDataTable([...performerDataTable, arrTable]);
        setOpenModalSelectPerformer(false);
      } else {
        setFailedNotif(true);
      }
    } else {
      let arrTable = {
        isrc: relatedIsrc,
        performers: performerData,
      };
      setPerformerDataTable([...performerDataTable, arrTable]);
      setOpenModalSelectPerformer(false);
    }
  };
  const onClickUpdateDialogPerformer = e => {
    e.preventDefault();

    let copyVal = performerDataTable;

    let arrTable = {
      isrc: relatedIsrc,
      performers: performerData,
    };
    copyVal[indexSel] = arrTable;
    setPerformerDataTable(copyVal);
    setOpenModalSelectPerformer(false);
  };
  const onDel = e => {
    let newPerf = performerDataTable;
    let result = _.remove(newPerf, function(n) {
      return n.isrc !== e;
    });

    setPerformerDataTable(result);
  };

  const onEdit = (e, index) => {
    setStatusDialog("edit");

    let getVal = performerDataTable[index];

    setIndexSel(index);
    setRelatedIsrc(getVal.isrc);
    setPerformerData(getVal.performers);
    setOpenModalSelectPerformer(true);
  };
  const onCloseModalSelectPerformer = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpenModalSelectPerformer(false);
    }
  };
  const onClickAddPerformer = () => {
    setStatusDialog("add");
    setPerformerData([]);
    setRelatedIsrc("");
    setOpenModalSelectPerformer(true);
  };
  const chocho = number => {
    let arrTemp = penciptaData;
    if (typeof arrTemp[number] !== "undefined") {
      return arrTemp[number].choosenType;
    } else {
      return [];
    }
  };
  const onChangeDialog = (e, data) => {
    let newData = data.map((item, index) => ({
      ...item,
      percentage_ownership: item.percentage_ownership || 0,
      percentage_publisher: item.percentage_publisher || 0,

      choosenType: chocho(index),

      type: [
        {
          code: "C",
          name: "Composer",
        },
        {
          code: "A",
          name: "Author",
        },
        {
          code: "AR",
          name: "Arranger",
        },
      ],
    }));

    setPenciptaData(newData);
  };
  const onClickDialog = e => {
    e.preventDefault();
    setOpenModalSelect(false);
  };

  const handleResetDialog = () => {
    setPenciptaData([]);
    setOpenModalSelect(false);
  };
  const handleResetDialogPerformer = () => {
    setPerformerData([]);
    setRelatedIsrc("");

    setOpenModalSelectPerformer(false);
  };

  const handleChangePersentageValueTable = (e, idValue) => {
    const { id, value, name } = e.target;

    setPenciptaData(prevState =>
      prevState.map(item => ({
        ...item,
        percentage_ownership:
          item.composer_id === idValue ? value : item.percentage_ownership,
      }))
    );
  };
  const handleChangePublisherPercentage = (e, idValue) => {
    const { id, value, name } = e.target;

    setPenciptaData(prevState =>
      prevState.map(item => ({
        ...item,
        percentage_publisher:
          item.composer_id === idValue ? value : item.percentage_publisher,
      }))
    );
  };

  const handleChangeTableType = (e, id) => {
    const newData = [...penciptaData];
    const index = newData.findIndex(item => item.composer_id === id);

    newData[index].choosenType = e.target.value;

    setPenciptaData(newData);
  };

  const fetchMoreDataPerformer = () => {
    setParamsPerformer(prev => ({
      page: prev.page + 1,
      limit: prev.limit,
      search: prev.search,
    }));
  };

  const handleScrollListBox = (event, type) => {
    setListboxNode(event.currentTarget);

    const x = listboxNode.scrollTop + listboxNode.clientHeight;

    if (listboxNode.scrollHeight - x <= 1) {
      setPosition(x);

      if (type === "performer" && !isLoadingList.performer) {
        const modulusPagePerformer =
          metaList.performerMeta?.total % metaList?.performerMeta?.limit;
        const remainingCountPermorfer =
          modulusPagePerformer >= 1
            ? Math.floor(
                metaList?.performerMeta?.total / metaList?.performerMeta?.limit
              ) + 1
            : metaList?.performerMeta?.total / metaList?.performerMeta?.limit;
        if (paramsPerformer.page < remainingCountPermorfer) {
          setPosition(x);

          fetchMoreDataPerformer();
        }
      }
      if (type === "pencipta" && !isLoadingList.pencipta) {
        const modulusPagePencipta =
          metaList.penciptaMeta?.total % metaList?.penciptaMeta?.limit;
        const remainingCountPencipta =
          modulusPagePencipta >= 1
            ? Math.floor(
                metaList?.penciptaMeta?.total / metaList?.penciptaMeta?.limit
              ) + 1
            : metaList?.penciptaMeta?.total / metaList?.penciptaMeta?.limit;
        if (paramsPencipta.page < remainingCountPencipta) {
          setPosition(x);

          fetchMoreDataPencipta();
        }
      }
    }
  };

  const onClickAddAlias = () => {
    setStatusDialogAlias("add");

    setAliasName("");
    setOpenModalSelectPerformerAlias(true);
  };
  const handleResetDialogPerformerAlias = () => {
    setOpenModalSelectPerformerAlias(false);
    setAliasName("");
  };
  const onClickDialogAlias = e => {
    e.preventDefault();

    if (aliasName !== "") {
      let newAlias = aliasName;
      let newArr = tableAliasName;
      newArr.push(newAlias);
      setTableAliasName(newArr);
      setOpenModalSelectPerformerAlias(false);
      setAliasName("");
    } else {
      setOpenModalSelectPerformerAlias(false);
      Swal.fire({
        title: "Error",
        text: "Please Fill Alias Name.",
        icon: "error",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
      }).then(result => {
        if (result.value) {
          setOpenModalSelectPerformerAlias(true);
        }
      });
    }
  };
  const goDelete = e => {
    let currentAlias = tableAliasName;

    let afterRemove = _.remove(currentAlias, function(n) {
      return n !== delLabel;
    });

    setTableAliasName(afterRemove);
    setConfirmDel(false);
  };
  const onDelAlias = e => {
    setDelLabel(e);
    setConfirmDel(true);
  };
  const onClickUpdateDialogAlias = e => {
    e.preventDefault();

    let currentTable = tableAliasName;

    if (aliasName !== "") {
      currentTable[selectedAliasIndex] = aliasName;
      setTableAliasName(currentTable);
      setOpenModalSelectPerformerAlias(false);
    } else {
      setOpenModalSelectPerformerAlias(false);
      Swal.fire({
        title: "Error",
        text: "Please Fill Alias Name.",
        icon: "error",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
      }).then(result => {
        if (result.value) {
          setOpenModalSelectPerformerAlias(true);
        }
      });
    }
  };
  const onEditAlias = (e, index) => {
    setStatusDialogAlias("edit");

    setAliasName(e);
    setSelectedAliasIndex(index);
    setOpenModalSelectPerformerAlias(true);
  };
  const userLogin = getCookie("auth", `${process.env.REACT_APP_NAME}_user`);
  function handleButtonBack() {
    if (userLogin?.role?.type === "publisher") {
      props.history.push("/admin/parameter/lagu");
    } else {
      props.history.push("/admin/parameter/lagu-admin");
    }
  }
  return (
    <Page className={classes.root} title="Add Song">
      <Container maxWidth={false}>
        <form ref={ref}>
          <Snackbar
            open={openSnack}
            autoHideDuration={6000}
            onClose={handleCloseSnackbar}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert onClose={handleCloseSnackbar} severity={typeSnackbar}>
              {message}
            </Alert>
          </Snackbar>
          <Grid
            alignItems="flex-end"
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <ThemeProvider theme={theme}>
                <Typography
                  style={{
                    fontSize: "24px",
                    fontWeight: "700",
                    lineHeight: "32px",
                  }}
                >
                  Add Song
                </Typography>
              </ThemeProvider>
            </Grid>
            <Grid item>
              <Breadcrumbs aria-label="breadcrumb">
                <Typography className={classes.breadCrumbs}>Home</Typography>

                <Typography className={classes.breadCrumbs}>
                  Parameter
                </Typography>

                <Typography className={classes.breadCrumbs}>Song</Typography>

                <Typography className={classes.breadCrumbsActive}>
                  Add Song
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>
          <Divider className={classes.divider} />

          <Grid container>
            <div>
              <ThemeProvider theme={theme}>
                <Typography
                  component="h1"
                  variant="h3"
                  className={classes.subTitle}
                >
                  Song Information
                </Typography>
              </ThemeProvider>

              <ThemeProvider theme={theme}>
                <p
                  style={{
                    marginTop: "5px",
                    color: "#8f8f8f",
                    fontSize: "14px",
                  }}
                >
                  This feature is used to add a new song.
                </p>
              </ThemeProvider>
            </div>
          </Grid>

          <Grid container>
            <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
              <Card
                style={{
                  boxShadow: "none",
                  borderRadius: "6px",
                  border: "1px solid #9AA2B1",
                }}
              >
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <div
                        style={{
                          marginTop: "5px",
                          marginBottom: "5px",
                        }}
                      >
                        <div
                          style={{
                            marginTop: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          <ThemeProvider theme={theme}>
                            <Typography className={classes.label}>
                              Song Title
                            </Typography>
                          </ThemeProvider>
                        </div>
                        <div
                          style={{
                            marginTop: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          <TextField
                            id="outlined-basic"
                            placeholder="Song Title"
                            variant="outlined"
                            fullWidth={true}
                            name="judul"
                            onChange={event => handleChange(event)}
                          />
                        </div>
                        <div
                          style={{
                            marginTop: "32px",
                            marginBottom: "10px",
                          }}
                        >
                          <ThemeProvider theme={theme}>
                            <Typography className={classes.label}>
                              Publisher
                            </Typography>
                          </ThemeProvider>
                        </div>
                        <div
                          style={{
                            marginTop: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          <FormControl
                            variant="outlined"
                            className={classes.formControl}
                            fullWidth={true}
                          >
                            <InputLabel
                              id="demo-simple-select-outlined-label"
                              shrink={false}
                            >
                              {publisher !== undefined
                                ? null
                                : "Choose Publisher"}
                            </InputLabel>
                            <Select
                              id="outlined-basic"
                              variant="outlined"
                              fullWidth={true}
                              name="publisher"
                              value={publisher}
                              onChange={event => handleChange(event)}
                            >
                              {publisherList?.length > 0 ? (
                                publisherList.map((item, index) => {
                                  return (
                                    <MenuItem
                                      key={index}
                                      value={item.original_publisher_id}
                                    >
                                      {item.name}
                                    </MenuItem>
                                  );
                                })
                              ) : (
                                <MenuItem value="">
                                  <em>None</em>
                                </MenuItem>
                              )}
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <div
                        style={{
                          marginTop: "5px",
                          marginBottom: "0px",
                        }}
                      >
                        <div
                          style={{
                            marginTop: "14px",
                          }}
                        >
                          <ThemeProvider theme={theme}>
                            <Typography className={classes.label}>
                              ISWC Code{" "}
                            </Typography>
                          </ThemeProvider>
                        </div>
                        <div
                          style={{
                            marginTop: "6px",
                          }}
                        >
                          <TextField
                            id="isrc"
                            placeholder="ISWC"
                            variant="outlined"
                            fullWidth={true}
                            name="isrc"
                            onChange={event => handleChange(event)}
                          />
                          <ThemeProvider theme={theme}>
                            <Typography
                              style={{
                                fontSize: "12px",
                                fontWeight: "400",
                                color: "#687083",
                                marginTop: "5px",
                              }}
                            >
                              Cth: US/Z03/21/9102
                            </Typography>
                          </ThemeProvider>
                        </div>

                        <div
                          style={{
                            marginTop: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          <ThemeProvider theme={theme}>
                            <Typography className={classes.label}>
                              Work Code
                            </Typography>
                          </ThemeProvider>
                        </div>
                        <div
                          style={{
                            marginTop: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          <TextField
                            id="outlined-basic"
                            placeholder="Work Code"
                            variant="outlined"
                            fullWidth={true}
                            name="workcode"
                            onChange={event => handleChange(event)}
                          />
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={4} md={4} sm={4} lg={4} xl={4}></Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
              <Divider className={classes.divider} />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <div
                  style={{
                    paddingTop: "5px",
                    paddingBottom: "5px",
                  }}
                >
                  <ThemeProvider theme={theme}>
                    <Typography
                      component="h1"
                      variant="h3"
                      className={classes.subTitle}
                    >
                      Song Alias
                    </Typography>
                  </ThemeProvider>
                </div>

                <div>
                  <Button
                    className={classes.btnPengaturanPencipta}
                    style={{
                      backgroundColor: getColor,
                    }}
                    onClick={() => onClickAddAlias()}
                  >
                    <ThemeProvider theme={theme}>
                      <Typography
                        style={{
                          textTransform: "none",
                          color: "#FFFFFF",
                          fontSize: "14px",
                          fontWeight: 400,
                          lineHeight: "20px",
                        }}
                      >
                        Add Song Alias
                      </Typography>
                    </ThemeProvider>
                  </Button>
                </div>
              </div>
            </Grid>
            <Grid item xs={8} md={8} sm={8} lg={8} xl={8}>
              <Card>
                <div>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <ThemeProvider theme={theme}>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={classes.headText}
                            >
                              Action
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={classes.headText}
                            >
                              Song Alias
                            </Typography>
                          </TableCell>
                        </ThemeProvider>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {tableAliasName?.length > 0 ? (
                        tableAliasName.map((item, index) => {
                          return (
                            <TableRow key={`${index}`}>
                              <ThemeProvider theme={theme}>
                                <TableCell
                                  style={{
                                    width: "10%",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                    }}
                                  >
                                    <IconButton
                                      onClick={() => onEditAlias(item, index)}
                                      className={classes.btnEdit}
                                      style={{
                                        backgroundColor: getColor || "black",
                                      }}
                                    >
                                      <div>
                                        <img
                                          src={updateIcon}
                                          alt="update-icon"
                                          style={{
                                            filter: " brightness(0) invert(1)",
                                          }}
                                        />
                                      </div>
                                    </IconButton>
                                    <IconButton
                                      onClick={() => onDelAlias(item)}
                                      className={classes.btnDelete}
                                      style={{
                                        backgroundColor: getColor || "black",
                                      }}
                                    >
                                      <div>
                                        <img
                                          src={deleteIcon}
                                          alt="delete-icon"
                                          style={{
                                            filter: " brightness(0) invert(1)",
                                          }}
                                        />
                                      </div>
                                    </IconButton>
                                  </div>
                                </TableCell>
                                <TableCell
                                  style={{
                                    width: "30%",
                                  }}
                                >
                                  <Typography
                                    variant="subtitle2"
                                    className={classes.bodyTextBlack}
                                  >
                                    {item}
                                  </Typography>
                                </TableCell>
                              </ThemeProvider>
                            </TableRow>
                          );
                        })
                      ) : (
                        <TableRow>
                          <TableCell colSpan={3}>
                            <div
                              style={{
                                marginTop: "10px",
                                marginBottom: "10px",
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <Typography
                                style={{
                                  color: "#687083",
                                  fontSize: "14px",
                                  fontWeight: "400",
                                }}
                              >
                                No Data
                              </Typography>
                            </div>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </div>
              </Card>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
              <Divider className={classes.divider} />
            </Grid>
          </Grid>

          <Grid
            container
            style={{
              marginTop: "20px",
            }}
          >
            <Grid item xs={8} md={8} sm={8} lg={8} xl={8}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <div
                  style={{
                    paddingTop: "5px",
                    paddingBottom: "5px",
                  }}
                >
                  <ThemeProvider theme={theme}>
                    <Typography
                      component="h1"
                      variant="h3"
                      className={classes.subTitle}
                    >
                      Performer
                    </Typography>
                  </ThemeProvider>
                  <ThemeProvider theme={theme}>
                    <span
                      style={{
                        color: "#8f8f8f",
                        fontSize: "14px",
                      }}
                    >
                      {"Add Performer list for song's information"}
                    </span>
                  </ThemeProvider>
                </div>

                <div>
                  <Button
                    className={classes.btnPengaturanPencipta}
                    style={{
                      backgroundColor: getColor,
                    }}
                    onClick={() => onClickAddPerformer()}
                  >
                    <ThemeProvider theme={theme}>
                      <Typography
                        style={{
                          textTransform: "none",
                          color: "#FFFFFF",
                          fontSize: "14px",
                          fontWeight: 400,
                          lineHeight: "20px",
                        }}
                      >
                        Add Performer
                      </Typography>
                    </ThemeProvider>
                  </Button>
                </div>
              </div>
            </Grid>
            <Grid item xs={8} md={8} sm={8} lg={8} xl={8}>
              <Card>
                <div>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <ThemeProvider theme={theme}>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={classes.headText}
                            >
                              Action
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={classes.headText}
                            >
                              ISRC Code
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={classes.headText}
                            >
                              Performer
                            </Typography>
                          </TableCell>
                        </ThemeProvider>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {performerDataTable?.length > 0 ? (
                        performerDataTable.map((item, index) => {
                          return (
                            <TableRow key={`${index}`}>
                              <ThemeProvider theme={theme}>
                                <TableCell
                                  style={{
                                    width: "10%",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                    }}
                                  >
                                    <IconButton
                                      onClick={() => onEdit(item.isrc, index)}
                                      className={classes.btnEdit}
                                      style={{
                                        backgroundColor: getColor || "black",
                                      }}
                                    >
                                      <div>
                                        <img
                                          src={updateIcon}
                                          alt="update-icon"
                                          style={{
                                            filter: " brightness(0) invert(1)",
                                          }}
                                        />
                                      </div>
                                    </IconButton>
                                    <IconButton
                                      onClick={() => onDel(item.isrc)}
                                      className={classes.btnDelete}
                                      style={{
                                        backgroundColor: getColor || "black",
                                      }}
                                    >
                                      <div>
                                        <img
                                          src={deleteIcon}
                                          alt="delete-icon"
                                          style={{
                                            filter: " brightness(0) invert(1)",
                                          }}
                                        />
                                      </div>
                                    </IconButton>
                                  </div>
                                </TableCell>
                                <TableCell
                                  style={{
                                    width: "30%",
                                  }}
                                >
                                  <Typography
                                    variant="subtitle2"
                                    className={classes.bodyTextBlack}
                                  >
                                    {item.isrc}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  style={{
                                    width: "60%",
                                  }}
                                >
                                  <Typography
                                    variant="subtitle2"
                                    className={classes.bodyTextBlack}
                                  >
                                    {item.performers?.length > 0 ? (
                                      <>
                                        <ChipComponentAdd
                                          items={item.performers}
                                        />
                                      </>
                                    ) : (
                                      <>{""}</>
                                    )}
                                  </Typography>
                                </TableCell>
                              </ThemeProvider>
                            </TableRow>
                          );
                        })
                      ) : (
                        <TableRow>
                          <TableCell colSpan={3}>
                            <div
                              style={{
                                marginTop: "10px",
                                marginBottom: "10px",
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <Typography
                                style={{
                                  color: "#687083",
                                  fontSize: "14px",
                                  fontWeight: "400",
                                }}
                              >
                                No Data
                              </Typography>
                            </div>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </div>
              </Card>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
              <Divider className={classes.divider} />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} md={8} sm={12} lg={8} xl={8}>
              <div>
                <ThemeProvider theme={theme}>
                  <Typography
                    component="h1"
                    variant="h3"
                    className={classes.subTitle}
                  >
                    Composer/Author
                  </Typography>
                </ThemeProvider>
                <ThemeProvider theme={theme}>
                  <p
                    style={{
                      marginTop: "5px",
                      color: "#8f8f8f",
                      fontSize: "14px",
                    }}
                  >
                    This is the composer/author of the song
                  </p>
                </ThemeProvider>
              </div>
            </Grid>
            <Grid item xs={12} md={4} sm={12} lg={4} xl={4}></Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={8} sm={12} lg={8} xl={8}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "15px",
                }}
              >
                <div>
                  <div
                    style={{
                      marginTop: "5px",
                      marginBottom: "5px",
                    }}
                  >
                    <Button
                      className={classes.btnPengaturanPencipta}
                      style={{
                        backgroundColor: getColor,
                      }}
                      onClick={() => setOpenModalSelect(true)}
                    >
                      <ThemeProvider theme={theme}>
                        <Typography
                          style={{
                            textTransform: "none",
                            color: "#FFFFFF",
                            fontSize: "14px",
                            fontWeight: 400,
                            lineHeight: "20px",
                          }}
                        >
                          Composer/Author Configuration
                        </Typography>
                      </ThemeProvider>
                    </Button>
                  </div>
                </div>

                <div>
                  <div
                    style={{
                      marginTop: "5px",
                      marginBottom: "5px",
                    }}
                  >
                    <ThemeProvider theme={theme}>
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <div
                          style={{
                            fontSize: "14px",
                            fontWeight: "400",
                            color: "#687083",
                            marginTop: "10px",
                          }}
                        >
                          <Typography>Percentage</Typography>
                        </div>
                        <div
                          style={{
                            fontSize: "24px",
                            fontWeight: "500",
                            marginLeft: "10px",
                          }}
                        >
                          <Typography>{percentage}%</Typography>
                        </div>
                      </div>
                    </ThemeProvider>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={12} sm={12} lg={12} xl={12}>
              <Card>
                <div>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <ThemeProvider theme={theme}>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={classes.headText}
                            >
                              Composer/Author Name
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={classes.headText}
                            >
                              Alias Name
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={classes.headText}
                            >
                              Ownership Percentage
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={classes.headText}
                              style={{ width: "200px" }}
                            >
                              Role
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={classes.headText}
                            >
                              Publisher Percentage
                            </Typography>
                          </TableCell>
                        </ThemeProvider>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {penciptaData?.length > 0 ? (
                        penciptaData.map((item, index) => {
                          return (
                            <TableRow key={`${index}`}>
                              <ThemeProvider theme={theme}>
                                <TableCell>
                                  <Typography
                                    variant="subtitle2"
                                    className={classes.bodyTextBlack}
                                  >
                                    {item.sure_name}
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography
                                    variant="subtitle2"
                                    className={classes.bodyTextBlack}
                                  >
                                    {item.alias_name}
                                  </Typography>
                                </TableCell>
                              </ThemeProvider>
                              <TableCell>
                                <FormControl
                                  variant="outlined"
                                  className={classes.formControl}
                                  fullWidth={false}
                                >
                                  <TextField
                                    className={classes.textField}
                                    variant="outlined"
                                    id={`${item.composer_id}`}
                                    name="composer"
                                    onChange={e =>
                                      handleChangePersentageValueTable(
                                        e,
                                        item.composer_id
                                      )
                                    }
                                    value={item.percentage_ownership}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="start">
                                          <div>%</div>
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </FormControl>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  variant="subtitle2"
                                  className={classes.bodyTextBlack}
                                >
                                  <FormControl
                                    variant="outlined"
                                    fullWidth={true}
                                  >
                                    <Select
                                      className={classes.mypadding}
                                      id="demo-simple-select-outlined-role"
                                      name="role"
                                      onChange={e =>
                                        handleChangeTableType(
                                          e,
                                          item.composer_id
                                        )
                                      }
                                      autoWidth={true}
                                      multiple
                                      renderValue={selected =>
                                        selected.join(", ")
                                      }
                                      value={item.choosenType}
                                      style={{
                                        paddingTop: "0px",
                                        paddingRight: "0px",
                                        paddingBottom: "0px",
                                        paddingLeft: "0px",
                                        width: "400px",
                                      }}
                                    >
                                      {item.type.map((itemOne, idx) => (
                                        <MenuItem
                                          value={itemOne.code}
                                          key={idx}
                                        >
                                          <Checkbox
                                            checked={
                                              item.choosenType.indexOf(
                                                itemOne.code
                                              ) > -1
                                            }
                                            color="primary"
                                          />
                                          <ListItemText
                                            primary={itemOne.name}
                                          />
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <FormControl
                                  variant="outlined"
                                  className={classes.formControl}
                                  fullWidth={false}
                                >
                                  <TextField
                                    className={classes.textField}
                                    variant="outlined"
                                    id={`${item.composer_id}`}
                                    name="composer"
                                    onChange={e =>
                                      handleChangePublisherPercentage(
                                        e,
                                        item.composer_id
                                      )
                                    }
                                    value={item.percentage_publisher}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="start">
                                          <div>%</div>
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </FormControl>
                              </TableCell>
                            </TableRow>
                          );
                        })
                      ) : (
                        <TableRow>
                          <TableCell colSpan={5}>
                            <div
                              style={{
                                marginTop: "10px",
                                marginBottom: "10px",
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <Typography
                                style={{
                                  color: "#687083",
                                  fontSize: "14px",
                                  fontWeight: "400",
                                }}
                              >
                                No Data
                              </Typography>
                            </div>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </div>
              </Card>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Divider className={classes.divider} />
            </Grid>
          </Grid>
          <Grid container>
            <div
              style={{
                display: "flex",
              }}
            >
              <Button
                className={classes.btnSubmit}
                onClick={e => handleButtonSimpan(e)}
                style={{
                  backgroundColor: getColor,
                }}
              >
                <ThemeProvider theme={theme}>
                  <Typography
                    style={{
                      textTransform: "none",
                      color: "#FFFFFF",
                      fontSize: "14px",
                      fontWeight: 400,
                      lineHeight: "20px",
                    }}
                  >
                    Save
                  </Typography>
                </ThemeProvider>
              </Button>
              <Button
                className={classes.btnPreview}
                variant="outlined"
                onClick={e => handleButtonBack(e)}
                style={{
                  backgroundColor: getColor,
                }}
              >
                <ThemeProvider theme={theme}>
                  <Typography
                    style={{
                      textTransform: "none",
                      color: "#FFFFFF",
                      fontSize: "14px",
                      fontWeight: 400,
                      lineHeight: "20px",
                    }}
                  >
                    Back
                  </Typography>
                </ThemeProvider>
              </Button>
            </div>
          </Grid>
        </form>
      </Container>
      <Dialog
        open={openModalSelectPerformer}
        onClose={(event, reason) => onCloseModalSelectPerformer(event, reason)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {statusDialog === "add" ? <>{"Add"}</> : <>{"Edit"}</>} Performer
        </DialogTitle>
        <DialogContent dividers>
          <div
            style={{
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            <ThemeProvider theme={theme}>
              <Typography className={classes.label}>ISRC Code</Typography>
            </ThemeProvider>
          </div>
          <div
            style={{
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            {statusDialog === "add" ? (
              <>
                <TextField
                  id="outlined-basic"
                  placeholder="ISRC"
                  variant="outlined"
                  fullWidth={true}
                  name="relatedIsrc"
                  onChange={event => handleChange(event)}
                  value={relatedIsrc}
                />
                {failedNotif ? (
                  <span
                    style={{
                      color: "red",
                    }}
                  >
                    {"ISRC Code is Exist."}
                  </span>
                ) : (
                  <span>{""}</span>
                )}
              </>
            ) : (
              <>
                <TextField
                  id="outlined-basic"
                  placeholder="ISRC"
                  variant="outlined"
                  fullWidth={true}
                  name="relatedIsrc"
                  onChange={event => handleChange(event)}
                  value={relatedIsrc}
                />
              </>
            )}
          </div>
          <div
            style={{
              marginTop: "33px",
              marginBottom: "10px",
            }}
          >
            <ThemeProvider theme={theme}>
              <Typography className={classes.label}>Performer Name</Typography>
            </ThemeProvider>
          </div>
          <div
            style={{
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            <Autocomplete
              multiple
              id="checkboxes-tags-demo"
              options={performerList}
              disableCloseOnSelect
              getOptionLabel={option => option.first_name}
              onChange={(e, data) => onChangeDialogPerformer(e, data)}
              value={performerData}
              getOptionSelected={(option, value) =>
                option.first_name === value.first_name
              }
              renderOption={(option, { selected }) => {
                return (
                  <React.Fragment>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                      value={option.id}
                    />
                    {option.first_name}
                  </React.Fragment>
                );
              }}
              style={{ width: 500 }}
              renderInput={params => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Performer Name"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {isLoadingList?.performer ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
              autoHighlight
              inputValue={paramsPerformer.search || ""}
              onInputChange={(e, value, reason) => {
                if (reason !== "reset") {
                  setParamsPerformer({
                    ...paramsPerformer,
                    search: e.target.value,
                  });
                }
              }}
              ListboxProps={{
                onScroll: e => handleScrollListBox(e, "performer"),
              }}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleResetDialogPerformer()}
            className={classes.btnDialog}
            style={{
              backgroundColor: getColor,
            }}
          >
            <ThemeProvider theme={theme}>
              <Typography
                style={{
                  textTransform: "none",
                  color: "#FFFFFF",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "20px",
                }}
              >
                Cancel
              </Typography>
            </ThemeProvider>
          </Button>
          {statusDialog === "add" ? (
            <>
              <Button
                onClick={e => onClickDialogPerformer(e)}
                className={classes.btnDialog}
                style={{
                  backgroundColor: getColor,
                }}
              >
                <ThemeProvider theme={theme}>
                  <Typography
                    style={{
                      textTransform: "none",
                      color: "#FFFFFF",
                      fontSize: "14px",
                      fontWeight: 400,
                      lineHeight: "20px",
                    }}
                  >
                    Add
                  </Typography>
                </ThemeProvider>
              </Button>
            </>
          ) : (
            <>
              <Button
                onClick={e => onClickUpdateDialogPerformer(e)}
                className={classes.btnDialog}
                style={{
                  backgroundColor: getColor,
                }}
              >
                <ThemeProvider theme={theme}>
                  <Typography
                    style={{
                      textTransform: "none",
                      color: "#FFFFFF",
                      fontSize: "14px",
                      fontWeight: 400,
                      lineHeight: "20px",
                    }}
                  >
                    Update
                  </Typography>
                </ThemeProvider>
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
      <Dialog
        open={openModalSelect}
        onClose={() => setOpenModalSelect(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Composer/Author</DialogTitle>
        <DialogContent dividers>
          <Autocomplete
            multiple
            id="checkboxes-tags-demo-1"
            options={penciptaList}
            disableCloseOnSelect
            getOptionLabel={option => option.sure_name}
            onChange={(e, data) => onChangeDialog(e, data)}
            value={penciptaData}
            getOptionSelected={(option, value) =>
              option.sure_name === value.sure_name
            }
            autoHighlight
            inputValue={paramsPencipta.search || ""}
            onInputChange={(e, _, reason) => {
              if (reason !== "reset") {
                handleChangeSearchDropdownPencipta(e);
              }
            }}
            renderOption={(option, { selected }) => {
              return (
                <Box id={option.id} component="li">
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                    value={option.id}
                  />
                  {option.sure_name}
                </Box>
              );
            }}
            onClose={() => {
              if (!penciptaData?.length) {
                setPosition(0);
                setListboxNode("");
              }
            }}
            style={{ width: 500 }}
            renderInput={params => (
              <TextField
                {...params}
                variant="outlined"
                label="Composer/Author"
                placeholder="Composer/Author"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {isLoadingList?.pencipta ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
            ListboxProps={{
              onScroll: e => handleScrollListBox(e, "pencipta"),
            }}
          ></Autocomplete>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleResetDialog()}
            className={classes.btnDialog}
            style={{
              backgroundColor: getColor,
            }}
          >
            <ThemeProvider theme={theme}>
              <Typography
                style={{
                  textTransform: "none",
                  color: "#FFFFFF",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "20px",
                }}
              >
                Reset
              </Typography>
            </ThemeProvider>
          </Button>
          <Button
            onClick={e => onClickDialog(e)}
            className={classes.btnDialog}
            style={{
              backgroundColor: getColor,
            }}
          >
            <ThemeProvider theme={theme}>
              <Typography
                style={{
                  textTransform: "none",
                  color: "#FFFFFF",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "20px",
                }}
              >
                Add
              </Typography>
            </ThemeProvider>
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openModalSelectPerformerAlias}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {statusDialogAlias === "add" ? <>{"Add"}</> : <>{"Edit"}</>} Song
          Alias
        </DialogTitle>
        <DialogContent dividers>
          <div
            style={{
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            <ThemeProvider theme={theme}>
              <Typography className={classes.label}>Song Alias</Typography>
            </ThemeProvider>
          </div>
          <div
            style={{
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            {statusDialogAlias === "add" ? (
              <>
                <TextField
                  id="outlined-basic"
                  placeholder="Song Alias"
                  variant="outlined"
                  fullWidth={true}
                  name="aliasName"
                  onChange={event => handleChange(event)}
                  value={aliasName}
                />
              </>
            ) : (
              <>
                <TextField
                  id="outlined-basic"
                  placeholder="Song Alias"
                  variant="outlined"
                  fullWidth={true}
                  name="aliasName"
                  onChange={event => handleChange(event)}
                  value={aliasName}
                />
              </>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleResetDialogPerformerAlias()}
            className={classes.btnDialog}
            style={{
              backgroundColor: getColor,
            }}
          >
            <ThemeProvider theme={theme}>
              <Typography
                style={{
                  textTransform: "none",
                  color: "#FFFFFF",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "20px",
                }}
              >
                Cancel
              </Typography>
            </ThemeProvider>
          </Button>
          {statusDialogAlias === "add" ? (
            <>
              <Button
                onClick={e => onClickDialogAlias(e)}
                className={classes.btnDialog}
                style={{
                  backgroundColor: getColor,
                }}
              >
                <ThemeProvider theme={theme}>
                  <Typography
                    style={{
                      textTransform: "none",
                      color: "#FFFFFF",
                      fontSize: "14px",
                      fontWeight: 400,
                      lineHeight: "20px",
                    }}
                  >
                    Add Song Alias
                  </Typography>
                </ThemeProvider>
              </Button>
            </>
          ) : (
            <>
              <Button
                onClick={e => onClickUpdateDialogAlias(e)}
                className={classes.btnDialog}
                style={{
                  backgroundColor: getColor,
                }}
              >
                <ThemeProvider theme={theme}>
                  <Typography
                    style={{
                      textTransform: "none",
                      color: "#FFFFFF",
                      fontSize: "14px",
                      fontWeight: 400,
                      lineHeight: "20px",
                    }}
                  >
                    Update
                  </Typography>
                </ThemeProvider>
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
      <Dialog open={confirmDel} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Confirm Delete</DialogTitle>
        <DialogContent dividers>
          <div
            style={{
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            <ThemeProvider theme={theme}>
              <Typography className={classes.label}>
                Are You Sure want to Delete {delLabel} ?
              </Typography>
            </ThemeProvider>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setConfirmDel(false)}
            className={classes.btnDialog}
            style={{
              backgroundColor: getColor,
            }}
          >
            <ThemeProvider theme={theme}>
              <Typography
                style={{
                  textTransform: "none",
                  color: "#FFFFFF",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "20px",
                }}
              >
                Cancel
              </Typography>
            </ThemeProvider>
          </Button>
          <Button
            onClick={e => goDelete(e)}
            className={classes.btnDialog}
            style={{
              backgroundColor: getColor,
            }}
          >
            <ThemeProvider theme={theme}>
              <Typography
                style={{
                  textTransform: "none",
                  color: "#FFFFFF",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "20px",
                }}
              >
                Yes
              </Typography>
            </ThemeProvider>
          </Button>
        </DialogActions>
      </Dialog>
    </Page>
  );
}

export default TambahLagu;
