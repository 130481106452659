import { Visibility, VisibilityOff } from "@material-ui/icons";
import { styled } from "@mui/material";
import { TextInput } from "components";
import { useState } from "react";

const PasswordInput = ({
  placeholder,
  onChange,
  value,
  disabled,
  label,
  className,
  ...rest
}) => {
  const [passwordToggle, setPasswordToggle] = useState(false);

  return (
    <TextInput
      label={label}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      disabled={disabled}
      type={passwordToggle ? "text" : "password"}
      className={className}
      endAdornment={
        passwordToggle ? (
          <IconVisible onClick={() => setPasswordToggle(!passwordToggle)} />
        ) : (
          <IconHidden onClick={() => setPasswordToggle(!passwordToggle)} />
        )
      }
      {...rest}
    />
  );
};
const IconVisible = styled(Visibility)({
  width: "16px",
  cursor: "pointer",
});
const IconHidden = styled(VisibilityOff)({
  width: "16px",
  cursor: "pointer",
});

export default PasswordInput;
