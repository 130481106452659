import { Container, IconButton } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  styled,
  Typography,
} from "@mui/material";
import axios from "axios";
import { InnoTable } from "inno-ui";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useHistory, useLocation } from "react-router";
import ExcelLogo from "../../../../assets/img/excel-logo.png";
import PencilVector from "../../../../assets/img/pencilVector.svg";
import {
  ButtonGroupTop,
  PrimaryButton,
  Page,
  SearchTextInput,
  SkeletonComponent,
  StatusChip,
  SecondaryButton,
  ModalError,
  PrimaryIconButton,
  ArrayChip,
  ModalSuccess,
} from "components";
import { hardBaseUrl, hardTypeWeb } from "../../../../services/urlConstant";
import { formatDate, getErrors, wordCapitalize } from "utils";
import DialogImportDokumen from "./DialogImportDokumen";
import {
  acceptStyle,
  activeStyle,
  baseStyle,
  rejectStyle,
  useStyling,
} from "./Hooks";
import { HeaderTitle } from "layouts";
import { debounce } from "lodash";
import { getTotalPage } from "lib";
import { CloseOutlined, InsertDriveFileOutlined } from "@material-ui/icons";
const useStyles = useStyling;

function Pencipta() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const urlParams = new URLSearchParams(location.search);
  const paramsSearch = urlParams.get("search");
  const paramsPage = urlParams.get("page");
  const paramsSize = urlParams.get("size");
  const isCaris = hardTypeWeb === "caris";

  const [loadingPage, setLoadingPage] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [queryParams, setQueryParams] = useState({
    page: Number(paramsPage) || 1,
    size: Number(paramsSize) || 10,
    search: paramsSearch || "",
  });
  const [tableTotalPage, setTableTotalPage] = useState(1);
  const [selectedComposers, setSelectedComposers] = useState([]);
  const [dokumen, setDokumen] = useState([]);
  const [openModalExcel, setOpenModalExcel] = useState(false);
  const [openSendInvitation, setOpenSendInvitation] = useState(false);
  const allComposerSelected = dataTable?.every(option =>
    selectedComposers?.some(
      selected => selected?.composer_id === option?.composer_id
    )
  );

  const handleChangePageParams = (value, key) => {
    urlParams.set(key, value);
    history.push({ search: urlParams.toString() });
  };
  const handleChangeQueryParams = (value, key) => {
    setQueryParams(currentState => ({
      ...currentState,
      ...(key !== "page" && { page: 1 }),
      [key]: value,
    }));
  };
  const handleChangeSearch = event => {
    const value = event?.target?.value;
    handleChangeQueryParams(value, "search");
    handleChangePageParams(value, "search");
  };
  const handleChangePage = page => {
    handleChangeQueryParams(page, "page");
    handleChangePageParams(page, "page");
  };
  const handleChangePageSize = size => {
    handleChangeQueryParams(size, "size");
    handleChangePageParams(size, "size");
  };
  const handleSelectComposer = (event, option) => {
    const { checked } = event?.target || false;
    setSelectedComposers(prev =>
      checked
        ? [...prev, option]
        : prev.filter(item => item.composer_id !== option.composer_id)
    );
  };
  const handleSelectAllComposer = event => {
    const { checked } = event?.target || false;
    setSelectedComposers(checked ? dataTable : []);
  };
  const handleCloseSendInvitation = () => setOpenSendInvitation(false);

  const onDrop = useCallback(acceptedFiles => {
    setDokumen(acceptedFiles);
  }, []);
  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    accept: ".xlsx, .xls",
  });
  const handleRemoveFile = idx => {
    setDokumen(dokumen.filter((_, i) => i !== idx));
    acceptedFiles.splice(idx, 1);
  };
  const truncate = (str, n) => {
    return str?.length > n ? str.substr(0, n - 1) + "..." : str;
  };
  const files = acceptedFiles.map((file, i) => {
    return (
      <li key={file.name}>
        <Box
          key={file.name}
          border="1px solid #D1D5DC"
          borderRadius="6px"
          p="8px 8px 8px 16px"
          mt="6px"
        >
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Box display="flex">
                <InsertDriveFileOutlined className={classes?.singleFileIcon} />
                <Typography fontSize="14px" ml="4px">
                  {truncate(file.name, 20)}
                </Typography>
              </Box>
            </Grid>
            <Grid item>
              <IconButton onClick={() => handleRemoveFile(i)}>
                <CloseOutlined />
              </IconButton>
            </Grid>
          </Grid>
        </Box>
      </li>
    );
  });
  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );
  const handleOpenModalExcel = () => {
    setOpenModalExcel(true);
  };
  const handleCloseModalExcel = () => {
    setOpenModalExcel(false);
  };

  const getDataTable = async () => {
    try {
      setLoadingPage(true);
      const res = await axios.get(`${hardBaseUrl}/publisher/composer`, {
        headers,
        params: queryParams,
      });

      const { data, meta } = res?.data;
      setDataTable(data);
      const pageCount = getTotalPage(meta?.total, queryParams?.size || 1);
      setTableTotalPage(pageCount);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  const debounceDataTable = useCallback(
    debounce(() => {
      getDataTable();
    }, 500),
    [queryParams]
  );

  useEffect(() => {
    debounceDataTable();
    return () => {
      debounceDataTable.cancel();
    };
  }, [queryParams, debounceDataTable]);

  const handleSendInvitation = async () => {
    setLoadingButton(true);

    const composer_ids = allComposerSelected
      ? []
      : selectedComposers.map(item => item?.composer_id);

    try {
      setLoadingButton(true);
      const res = await axios.post(
        `${hardBaseUrl}/publisher/composer/invitation`,
        { composer_ids },
        { headers }
      );
      ModalSuccess(res.data.data);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingButton(false);
      setOpenSendInvitation(false);
    }
  };

  const handleImportDocument = async () => {
    const data = new FormData();
    data.append("file", dokumen[0]);
    try {
      setLoadingPage(true);
      await axios.post(`${hardBaseUrl}/publisher/composer/import`, data, {
        headers,
      });
      ModalSuccess(
        "You can check the progress of the upload process in the Upload Monitoring Menu",
        "Data is being processed"
      ).then(res => {
        if (res.isConfirmed === true) {
          getDataTable();
        }
      });
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
      setOpenModalExcel(false);
    }
  };

  const columnTable = [
    {
      name: "all",
      title: (
        <Box>
          {isCaris && (
            <CustomCheckbox
              checked={allComposerSelected}
              onChange={handleSelectAllComposer}
            />
          )}
          Action
        </Box>
      ),
      renderText: item => (
        <Box>
          {isCaris && (
            <CustomCheckbox
              checked={selectedComposers?.some(
                selected => selected?.composer_id === item?.composer_id
              )}
              onChange={e => handleSelectComposer(e, item)}
            />
          )}
          <PrimaryIconButton
            icon={<img alt="edit" src={PencilVector} />}
            onClick={() =>
              history.push(`/admin/parameter/pencipta/edit/${item.composer_id}`)
            }
          />
        </Box>
      ),
    },
    {
      name: "composer_id",
      title: "ID Composer/Author",
    },
    {
      name: "sure_name",
      title: "Composer/Author Name",
    },
    {
      name: "alias_names",
      title: "Alias Name",
      renderText: item => <ArrayChip list={item} />,
    },
    {
      name: "phone_number",
      title: "Phone",
    },
    {
      name: "email",
      title: "Email",
    },
    ...(isCaris
      ? [
        {
          name: "last_invitation",
          title: "Last Sent",
          renderText: item => formatDate(item),
        },
      ]
      : []),
    ...(isCaris
      ? [
        {
          name: "status",
          title: "Status",
          renderText: status => (
            <StatusChip
              type={
                status === "active"
                  ? "success"
                  : status === "pending"
                    ? "warning"
                    : ""
              }
              label={wordCapitalize(status)}
            />
          ),
        },
      ]
      : []),
  ];

  return (
    <Page className={classes.root} title="Composer">
      {loadingPage ? (
        <SkeletonComponent variant="wave" />
      ) : (
        <Container maxWidth={false}>
          <HeaderTitle title="Parameter" breadcrumbData={breadcrumbData} />
          <ButtonGroupTop />
          <CustomDivider />
          <Grid container justifyContent="space-between" my="16px">
            <Grid item>
              <SearchTextInput
                placeholder="Search"
                value={queryParams?.search}
                onChange={event => handleChangeSearch(event)}
              />
            </Grid>
            <Grid item>
              <Grid container columnSpacing={1}>
                <Grid item>
                  <PrimaryButton
                    label="Upload Excel"
                    onClick={handleOpenModalExcel}
                    startIcon={<img src={ExcelLogo} alt="logo" />}
                    size="large"
                    color="#34774C"
                  />
                </Grid>
                {isCaris && (
                  <Grid item>
                    <PrimaryButton
                      label="Send Invitation"
                      onClick={() => setOpenSendInvitation(true)}
                      disabled={
                        loadingButton || selectedComposers?.length === 0
                      }
                      loading={loadingButton}
                      startIcon={<AddIcon />}
                      size="large"
                    />
                  </Grid>
                )}
                <Grid item>
                  <PrimaryButton
                    label="Add Composer/Author"
                    onClick={() =>
                      history.push("/admin/parameter/pencipta/tambah")
                    }
                    startIcon={<AddIcon />}
                    size="large"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <InnoTable
            isLoading={loadingPage}
            columns={columnTable}
            items={dataTable}
            page={queryParams?.page}
            rowsPerPage={queryParams?.size}
            totalPage={tableTotalPage}
            handleChangePage={(_, page) => handleChangePage(page)}
            handleChangeRowsPerPage={e =>
              handleChangePageSize(e?.target?.value)
            }
          />
        </Container>
      )}
      <Dialog open={openSendInvitation} onClose={handleCloseSendInvitation}>
        <DialogTitle id="alert-dialog-title">
          <Typography>Send Invitation</Typography>
        </DialogTitle>
        <DialogContent>
          {`Are you sure want to send invitation to ${allComposerSelected ? "all user" : "selected user"
            }?`}
        </DialogContent>
        <DialogActions>
          <SecondaryButton onClick={handleCloseSendInvitation} label="Cancel" />
          <PrimaryButton label="Send" onClick={() => handleSendInvitation()} />
        </DialogActions>
      </Dialog>
      <DialogImportDokumen
        open={openModalExcel}
        onClose={handleCloseModalExcel}
        getInputProps={getInputProps}
        getRootProps={getRootProps({ style })}
        isDragActive={isDragActive}
        files={files}
        onSubmit={handleImportDocument}
        loading={loadingPage}
      />
    </Page>
  );
}

const CustomDivider = styled(Divider)(() => ({
  borderTop: "1px solid #e1e1e1",
}));

const CustomCheckbox = styled(Checkbox)(({ customColor }) => ({
  "&.MuiCheckbox-root": {
    color: customColor || "#111827",
    "&.Mui-checked": {
      color: customColor || "#111827",
    },
  },
}));

const breadcrumbData = [
  {
    label: "Home",
    link: "/admin/dashboard",
  },
  {
    label: "Parameter",
    link: "/admin/parameter/pencipta",
  },
  {
    label: "Composer",
    active: true,
  },
];

export default Pencipta;
