import {
  Backdrop,
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Typography,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import NumberFormat from "react-number-format";
import { useHistory } from "react-router";
import { Page } from "components";

const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  results: {
    marginTop: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  formControl: {},
  mypadding: {
    "& .MuiSelect-outlined": {
      paddingTop: "10.5px",
      paddingBottom: "10.5px",
    },
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  pagination: {
    "& .Mui-selected": {
      backgroundColor: "black",
      color: "white",
    },
  },
  pageSize: {
    height: "30px",
    borderRadius: "6px",
    border: "1px solid #D1D5DC",
    paddingLeft: "5px",
    paddingRight: "5px",
    marginTop: "5px",
  },
  paginationText: {
    fontSize: "14px",
    fontWeight: "normal",
    fontStyle: "normal",
    color: "#687083",
  },
  breadcrumbs: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#111827",
  },
  breadCrumbsActive: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#687083",
  },
  placeholder: {
    "&::placeholder": {
      color: "#9AA2B1",
    },
  },
  title: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "28px",
  },
  label: {
    color: "#364052",
    fontSize: "14px",
    fontWeight: 500,
  },
  subTitle: {
    fontSize: "18px",
    fontWeight: 700,
    color: " #111827",
  },
  myinput: {
    "& .MuiOutlinedInput-input": {
      paddingTop: "11px",
      paddingLeft: "12px",
      paddingRight: "12px",
      paddingBottom: "11px",
    },
  },
  backdrop1: {
    zIndex: 100,
    color: "#fff",
  },
  mydateinput: {
    "& .MuiOutlinedInput-root": {
      paddingTop: "0px",
      paddingBottom: "0px",
      height: "44px",
    },
  },
  inputNum: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "0px 6px 6px 0px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderLeft: "1px solid #d1d5dc",
      borderRight: "1px solid #d1d5dc",
      borderTop: "1px solid #d1d5dc",
      borderBottom: "1px solid #d1d5dc",
    },
    "& .MuiOutlinedInput-input": {
      paddingTop: "10.5px",
      paddingBottom: "10.5px",
    },
    "& input.Mui-disabled": {
      color: "black",
    },
  },
  activeChip: {
    backgroundColor: "#8DE5AB",
    color: "#34774C",
    fontSize: "14px",
    fontWeight: "500",
  },
  nonActiveChip: {
    backgroundColor: "#D1D5DC",
    color: "#364052",
    fontSize: "14px",
    fontWeight: "500",
  },
  activeChipIndicator: {
    color: "#34774C",
    fontSize: "12px",
  },
  nonActiveChipIndicator: {
    color: "#364052",
    fontSize: "12px",
  },
}));

const DetailPlatformShare = () => {
  const classes = useStyles();
  const history = useHistory();

  const client = [];

  const submiting = false;

  const status = true;

  const handleAdd = async () => {
    history.push({
      pathname: "/admin/platform-share",
    });
  };

  const getDateFormat = date => {
    let newDate = new Date(date);

    let dateOnly = newDate.getDate();
    if (newDate.getDate() < 10) {
      dateOnly = `0${newDate.getDate()}`;
    }

    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    let monthIs = monthNames[newDate.getMonth()];

    let formatDate = dateOnly + " " + monthIs + " " + newDate.getFullYear();
    return formatDate;
  };
  const getDateFormatFull = date => {
    let newDate = new Date(date);

    let dateOnly = newDate.getDate();
    if (newDate.getDate() < 10) {
      dateOnly = `0${newDate.getDate()}`;
    }

    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    let monthIs = monthNames[newDate.getMonth()];

    let formatDate =
      dateOnly +
      " " +
      monthIs +
      " " +
      newDate.getFullYear() +
      " " +
      newDate.getHours() +
      ":" +
      newDate.getMinutes() +
      ":" +
      newDate.getSeconds();
    return formatDate;
  };
  return (
    <Page className={classes.root} title="View Currency Data">
      <Backdrop className={classes.backdrop1} open={submiting}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container maxWidth={false}>
        <Grid
          alignItems="flex-end"
          container
          justifyContent="space-between"
          spacing={3}
        >
          <Grid item>
            <ThemeProvider theme={theme}>
              <Typography
                style={{
                  fontSize: "24px",
                  fontWeight: "700",
                  lineHeight: "32px",
                }}
              >
                Platform Share
              </Typography>
            </ThemeProvider>
          </Grid>
          <Grid item>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography className={classes.breadCrumbs}>Home</Typography>
              <Typography className={classes.breadCrumbs}>
                Platform Share
              </Typography>
              <Typography className={classes.breadCrumbs}>
                View Detail
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>

        <Divider className={classes.divider} />

        <Grid container>
          <div>
            <ThemeProvider theme={theme}>
              <span
                style={{
                  fontSize: "16px",
                }}
              >
                <b>Detail of Platform Share</b>
              </span>
            </ThemeProvider>
          </div>
        </Grid>

        <Grid container>
          <div>
            <ThemeProvider theme={theme}>
              <p
                style={{
                  color: "#8f8f8f",
                  fontSize: "14px",
                }}
              >
                Features to view detail of Platform Share Billing
              </p>
            </ThemeProvider>
          </div>
        </Grid>

        <Grid container>
          <Grid item xs={12} md={8} lg={8} xl={8}>
            <Card
              id="card-of-success"
              style={{
                border: "1px solid #D1D5DC",
                borderRadius: "6px",
                boxShadow: "none",
              }}
            >
              <CardContent>
                {status === true ? (
                  <>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6} lg={6} xl={6}>
                        <div>
                          <ThemeProvider theme={theme}>
                            <Typography className={classes.label}>
                              Create Date
                            </Typography>
                          </ThemeProvider>
                        </div>
                        <div>
                          <ThemeProvider theme={theme}>
                            <Typography className={classes.label}>
                              222
                            </Typography>
                          </ThemeProvider>
                        </div>
                      </Grid>

                      <Grid item xs={12} md={6} lg={6} xl={6}>
                        <div>
                          <ThemeProvider theme={theme}>
                            <Typography className={classes.label}>
                              Period
                            </Typography>
                          </ThemeProvider>
                        </div>
                        <div>
                          <ThemeProvider theme={theme}>
                            <Typography className={classes.label}>
                              {getDateFormat(client.start_date)} -{" "}
                              {getDateFormat(client.end_date)}
                            </Typography>
                          </ThemeProvider>
                        </div>
                      </Grid>

                      <Grid item xs={12} md={6} lg={6} xl={6}>
                        <div>
                          <ThemeProvider theme={theme}>
                            <Typography className={classes.label}>
                              Paid Value
                            </Typography>
                          </ThemeProvider>
                        </div>
                        <div>
                          <ThemeProvider theme={theme}>
                            <Typography className={classes.label}>
                              <NumberFormat
                                value={client.value}
                                displayType={"text"}
                                thousandSeparator="."
                                decimalSeparator=","
                                thousandsGroupStyle="thousand"
                                prefix={"Rp "}
                                suffix=",-"
                              />
                            </Typography>
                          </ThemeProvider>
                        </div>
                      </Grid>

                      <Grid item xs={12} md={6} lg={6} xl={6}>
                        <div>
                          <ThemeProvider theme={theme}>
                            <Typography className={classes.label}>
                              Filename
                            </Typography>
                          </ThemeProvider>
                        </div>
                        <div>
                          <ThemeProvider theme={theme}>
                            <Typography className={classes.label}>
                              {getDateFormatFull(client.created_at)}
                            </Typography>
                          </ThemeProvider>
                        </div>
                      </Grid>
                    </Grid>
                  </>
                ) : null}

                <Grid container justifyContent="space-between"></Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4} lg={4} xl={4}></Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={8} lg={8} xl={8}>
            <Grid container justifyContent="flex-end">
              <div
                style={{
                  marginTop: "32px",
                }}
              >
                <Button
                  onClick={handleAdd}
                  style={{
                    marginLeft: "10px",
                    backgroundColor: "#111827",
                    borderRadius: "6px",
                    width: "84px",
                    height: "40px",
                    color: "#FFFFFF",
                    textTransform: "none",
                  }}
                >
                  <ThemeProvider theme={theme}>
                    <Typography
                      component="span"
                      style={{
                        fontSize: "14px",
                        lineHeight: "20px",
                        fontWeight: "500",
                        fontStyle: "normal",
                        textTransform: "none",
                      }}
                    >
                      Back
                    </Typography>
                  </ThemeProvider>
                </Button>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default DetailPlatformShare;
