import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import { formatDate } from "utils";

const DateRangeModal = ({
  label,
  modalVisible,
  handleCloseModal,
  startDate,
  endDate,
  handleDateChangeStartDate,
  handleDateChangeEndDate,
}) => {
  const classes = useStyles();
  return (
    <Dialog open={modalVisible} onClose={handleCloseModal} maxWidth="xl">
      {label && (
        <DialogTitle>
          <Typography variant="h5">{label}</Typography>
        </DialogTitle>
      )}
      <DialogContent>
        <Grid container>
          <Grid item>
            <DatePicker
              disableToolbar
              autoOk
              orientation="portrait"
              variant="static"
              openTo="year"
              onChange={handleDateChangeStartDate}
              value={startDate}
            />
          </Grid>
          <Grid item>
            <DatePicker
              value={endDate}
              onChange={handleDateChangeEndDate}
              disableToolbar
              autoOk
              orientation="portrait"
              variant="static"
              openTo="year"
              minDate={startDate}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes?.dialogActions}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography>
              {`${formatDate(startDate)} - ${formatDate(endDate)}`}
            </Typography>
          </Grid>
          <Grid item>
            <Grid item>
              <Button
                className={classes?.buttonBlack}
                onClick={handleCloseModal}
              >
                Close
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles(() => ({
  formContainer: props => ({
    height: props?.height || 42,
    width: props?.width,
    "&::placeholder": {
      color: "#9AA2B1",
    },
  }),
  selectionsLayoutPaper: {
    maxHeight: 500,
  },
  dialogActions: {
    padding: "8px 24px",
  },
  buttonBlack: {
    padding: "6px 16px",
    backgroundColor: "#111827",
    color: "white",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "24px",
    textTransform: "none",
    whiteSpace: "nowrap",
    "&:hover": {
      backgroundColor: "#111827",
      color: "white",
    },
  },
}));

export default DateRangeModal;
