import {
  Button,
  Card,
  Chip,
  Container,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecordRounded";
import { makeStyles } from "@material-ui/styles";
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { useRef } from "react";
import { Line } from "react-chartjs-2";
import { useLocation } from "react-router-dom";
import { Page } from "components";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});
const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  card: {
    borderLeft: "1px solid gray",
    boxShadow: "none",
    borderRadius: "0px",
  },
  insideCard: {
    padding: "20px",
  },
  cardArea: {
    marginTop: "20px",
    marginBottom: "20px",
    justifyContent: "space-between",
  },
  filterArea: {
    marginTop: "20px",
    marginBottom: "20px",
    display: "flex",
  },
  chipArea: {
    marginTop: "20px",
    marginBottom: "20px",
  },
  spotifyChip: {
    border: "1px solid #1db954",
    backgroundColor: "#ccffaa",
    color: "#1db954",
  },
  spotifyChipDot: {
    color: "#1db954",
  },
  tableChip: {
    marginRight: "10px",
    display: "inline-block",
    padding: "0 12px",
    borderRadius: "32px",
    fontSize: "13px",
    backgroundColor: "#D1D5DC",
  },
  pageSize: {
    height: "30px",
    borderRadius: "6px",
    border: "1px solid #D1D5DC",
    paddingLeft: "5px",
    paddingRight: "5px",
    marginTop: "5px",
  },
  paginationText: {
    fontSize: "14px",
    fontWeight: "normal",
    fontStyle: "normal",
    color: "#687083",
  },
  textField: {
    width: "290px",
    height: "44px",
    backgroundColor: "white",
    borderRadius: "6px",
    border: "1px solid #D1D5DC",
  },
  btnCariLaporan: {
    backgroundColor: "black",
    color: "white",
    width: "156px",
    height: "40px",
    marginTop: "25px",
    marginLeft: "10px",
    marginRight: "10px",

    textTransform: "none",
    borderRadius: "6px",
    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
  headText: {
    fontSize: "12px",
    color: "#687083",
    fontWeight: "bold",
  },
  bodyTextBlack: {
    fontSize: "14px",
    fontWeight: "regular",
    color: "#111827",
    fontStyle: "normal",
  },
  bodyTextGrey: {
    fontSize: "14px",
    fontWeight: "regular",
    color: "#687083",
    fontStyle: "normal",
  },
  tableRow: {
    height: 20,
  },
  conTextBlack: {
    fontSize: "14px",
    fontWeight: "bold",
  },
  dropdown: {
    color: "black",
  },
  subtitle: {
    fontSize: "18px",
    fontWeight: "bold",
    lineHeight: "28px",
  },
  btnRincian: {
    backgroundColor: "black",
    color: "white",
    padding: "10px 28px",
    borderRadius: "6px",
    width: "140px",
    height: "36px",

    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
  reportText: {
    fontSize: "14px",
    fontWeight: 500,
    color: "#687083",
    marginBottom: "15px",
    width: "300px",
  },
  reportValue: {
    fontSize: "24px",
    fontWeight: 500,
    color: "#111827",
  },
  breadcrumbs: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#111827",
  },
  breadCrumbsActive: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#687083",
  },
  label: {
    color: "#364052",
    fontSize: "14px",
    fontWeight: 500,
    fontStyle: "normal",
    lineHeight: "20px",
    marginBottom: "5px",
  },
  formControl: {
    height: "41px",
    backgroundColor: "#F7F7F7",
  },
  filterFormColor: {
    backgroundColor: "#F7F7F7",
  },
  placeholder: {
    "&::placeholder": {
      color: "#9AA2B1",
    },
  },
  table: {
    border: "1px solid #D1D5DC",
    borderRadius: "6px",
  },
}));

const PrintReportReview = () => {
  const { state } = useLocation();
  const classes = useStyles();
  const inputRef = useRef(null);

  const formatter = new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 0,
  });

  const formatter2 = new Intl.NumberFormat("id-ID", {
    minimumFractionDigits: 0,
  });

  const listPencipta = data => {
    let pencipta = data;

    if (pencipta === null) {
      return <div>-</div>;
    } else if (pencipta?.length < 3) {
      return pencipta.map(pencipta => (
        <div key={pencipta} className={classes.tableChip}>
          {`${pencipta}`}
        </div>
      ));
    } else if (pencipta?.length > 3) {
      let pencipta1 = pencipta.slice(0, 3);
      let pencipta2 = pencipta.slice(3, pencipta?.length);
      return (
        <div>
          {pencipta1.map(pencipta => (
            <div key={pencipta} className={classes.tableChip}>
              {`${pencipta}`}
            </div>
          ))}
          <div key={pencipta} className={classes.tableChip}>
            {`+ ${pencipta2?.length}`}
          </div>
        </div>
      );
    }
  };

  const handlePrintPDF = () => {
    html2canvas(inputRef.current).then(canvas => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "px", "a4");
      pdf.height = "600";
      pdf.width = "800";
      pdf.addImage(imgData, "PNG", 0, 0, 450, 500);
      pdf.save("download.pdf");
    });
  };

  const options = {
    parsing: {
      header: true,
      dynamicTyping: true,
      skipEmptyLines: true,
      delimiter: ",",
    },
    responsive: true,
    interaction: {
      mode: "index",
      intersect: false,
    },
    hover: {
      mode: "nearest",
      intersect: true,
    },
    plugins: {
      layout: {},
      legend: {
        display: false,
      },
      title: {
        display: false,
        text: "Chart.js Line Chart",
      },
      subtitle: {
        display: false,
        text: "Custom Chart Subtitle",
      },
      tooltip: {
        enabled: false,
      },
    },
  };
  return (
    <div className="review-lagu">
      <Page className={classes.root} title="Song Usage Review">
        <div ref={inputRef}>
          <Container maxWidth={false}>
            <Grid
              alignItems="flex-end"
              container
              justifyContent="space-between"
              spacing={3}
            >
              <Grid item>
                <ThemeProvider theme={theme}>
                  <Typography
                    style={{
                      fontSize: "24px",
                      fontWeight: "700",
                      lineHeight: "32px",
                    }}
                  >
                    Review of Song Usage
                  </Typography>
                </ThemeProvider>
              </Grid>
              <Grid item></Grid>
            </Grid>

            <Divider className={classes.divider} />

            <Grid container>
              <Grid item xs={12} md={12} sm={12} lg={12} xl={12}>
                <div
                  style={{
                    border: "1px solid #ebebeb",
                    borderRadius: "5px",
                    paddingBottom: "50px",
                    paddingTop: "50px",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      marginBottom: "20px",
                    }}
                  >
                    {state.nameOfDsp === "" ? (
                      <div></div>
                    ) : (
                      <Chip
                        size="small"
                        label={state.nameOfDsp}
                        avatar={
                          <FiberManualRecordIcon
                            style={{ width: "10px", color: state.dspColor }}
                          />
                        }
                        style={{
                          marginRight: "10px",
                          color: state.dspColor,
                          backgroundColor: `${state.dspColor}10`,
                        }}
                      />
                    )}
                  </div>
                  <Line options={options} data={state.chartData} plugins={[]} />
                </div>
              </Grid>
            </Grid>

            <div className={classes.cardArea}>
              <Grid container spacing={2}>
                <Grid item xs={3} md={3}>
                  <Card className={classes.card}>
                    <div className={classes.insideCard}>
                      <Typography
                        component="p"
                        variant="caption"
                        className={classes.reportText}
                      >{`${state.getMonth} listener traffic`}</Typography>
                      <Typography
                        component="h2"
                        variant="h3"
                        className={classes.reportValue}
                      >
                        {state.trafficListenerPerMonth}
                      </Typography>
                    </div>
                  </Card>
                </Grid>
                <Grid item xs={3} md={3}>
                  <Card className={classes.card}>
                    <div className={classes.insideCard}>
                      <Typography
                        component="p"
                        variant="caption"
                        className={classes.reportText}
                      >{`${state.getMonth} Revenue`}</Typography>
                      <Typography
                        component="h2"
                        variant="h3"
                        className={classes.reportValue}
                      >
                        {`${state.royaltyPerMonth},-`}
                      </Typography>
                    </div>
                  </Card>
                </Grid>
                <Grid item xs={3} md={3}>
                  <Card className={classes.card}>
                    <div className={classes.insideCard}>
                      <Typography
                        component="p"
                        variant="caption"
                        className={classes.reportText}
                      >
                        Total Listener Traffic
                      </Typography>
                      <Typography
                        component="h2"
                        variant="h3"
                        className={classes.reportValue}
                      >
                        {state.trafficListenerAll}
                      </Typography>
                    </div>
                  </Card>
                </Grid>
                <Grid item xs={3} md={3}>
                  <Card className={classes.card}>
                    <div className={classes.insideCard}>
                      <Typography
                        component="p"
                        variant="caption"
                        className={classes.reportText}
                      >
                        Total Revenue
                      </Typography>
                      <Typography
                        component="h2"
                        variant="h3"
                        className={classes.reportValue}
                      >
                        {`${state.royaltyAll},-`}
                      </Typography>
                    </div>
                  </Card>
                </Grid>
              </Grid>
            </div>

            <Grid item xs={12} md={12} sm={12} lg={12} xl={12}>
              <Table size="small" className={classes.table}>
                <TableHead>
                  <TableRow>
                    <ThemeProvider theme={theme}>
                      <TableCell>
                        <Typography
                          variant="subtitle2"
                          className={classes.headText}
                        >
                          Song Title
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="subtitle2"
                          className={classes.headText}
                        >
                          Composer/Author
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography
                          variant="subtitle2"
                          className={classes.headText}
                          align="right"
                        >
                          Traffic
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="subtitle2"
                          className={classes.headText}
                          align="right"
                        >
                          Revenue
                        </Typography>
                      </TableCell>
                    </ThemeProvider>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {state.tableData?.length > 0 ? (
                    state.tableData?.map(lagu => (
                      <TableRow hover key={lagu.song_id}>
                        <ThemeProvider theme={theme}>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={classes.bodyTextBlack}
                            >
                              {lagu.title}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={classes.bodyTextBlack}
                            >
                              {listPencipta(lagu.composer_names)}
                            </Typography>
                          </TableCell>
                          <TableCell align="right">
                            <Typography
                              variant="subtitle2"
                              className={classes.bodyTextBlack}
                            >
                              {formatter2.format(lagu.listener)}
                            </Typography>
                          </TableCell>
                          <TableCell align="right">
                            <Typography
                              variant="subtitle2"
                              className={classes.bodyTextBlack}
                            >
                              {`${formatter.format(lagu.royalty)},-`}
                            </Typography>
                          </TableCell>
                        </ThemeProvider>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={5} style={{ height: "50px" }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <ThemeProvider theme={theme}>
                            <Typography
                              component="span"
                              style={{
                                fontSize: "14px",
                                lineHeight: "20px",
                                fontWeight: "400",
                                fontStyle: "normal",
                                color: "#687083",
                              }}
                            >
                              Tidak ada data
                            </Typography>
                          </ThemeProvider>
                        </div>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Grid>
          </Container>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "end",
            marginTop: "20px",
            marginRight: "20px",
          }}
        >
          <Button
            variant="contained"
            style={{
              textTransform: "none",
              backgroundColor: "#111827",
              color: "white",
              boxShadow: "none",
              marginBottom: "20px",
              marginLeft: "20px",
              width: "136px",
              height: "36px",
              borderRadius: "6px",
            }}
            onClick={handlePrintPDF}
          >
            <ThemeProvider theme={theme}>
              <Typography
                style={{
                  color: "white",
                  whiteSpace: "nowrap",
                  fontSize: "14px",
                  fontWeight: "500",
                }}
              >
                Print Report
              </Typography>
            </ThemeProvider>
          </Button>
        </div>
      </Page>
    </div>
  );
};

export default PrintReportReview;
