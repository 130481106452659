import React, { useState, useEffect } from "react";
import { Page } from "components";
import {
  createTheme,
  ThemeProvider,
  makeStyles,
} from "@material-ui/core/styles";
import axios from "axios";
import Swal from "sweetalert2";
import { hardBaseUrl } from "../../../../services/urlConstant";
import { Autocomplete } from "@material-ui/lab";
import {
  Container,
  TextField,
  InputAdornment,
  Button,
  Typography,
  FormControl,
  CircularProgress,
  MenuItem,
  Checkbox,
  Select,
  Grid,
  Backdrop,
  Breadcrumbs,
  Divider,
} from "@material-ui/core";
import { useHistory, useParams } from "react-router";

const fontInter = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
    h5: {
      fontWeight: 700,
    },
  },
});

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  button: {
    textTransform: "none",
    backgroundColor: "black",
    color: "white",
    width: "115px",
    height: "40px",
    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
  content: {
    marginTop: "20px",
  },
  contentAdd: {
    marginTop: "20px",
    marginLeft: "20px",
    marginRight: "20px",
    fontFamily: ["Inter"].join(","),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  title: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "28px",
  },
  subTitle: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#6e6e6e",
  },
  inputFields: {
    height: "44px",
    paddingTop: "0px",
    paddingBottom: "0px",
    "& .MuiOutlinedInput-root": {
      minWidth: 250,
      height: "44px",
      paddingTop: "0px",
      paddingBottom: "0px",
    },
  },
  boxAddButton: {
    display: "flex",
    margin: "20px 0px",
  },
  containerModal: {
    padding: "1rem",
  },
  autoComplete: {
    "& .MuiOutlinedInput-root": {
      padding: "0px",
      height: "44px",
    },
    "& .MuiOutlinedInput-root input.MuiInputBase-input.MuiOutlinedInput-input": {
      paddingLeft: "14px",
    },
  },
}));

const theme = createTheme({
  typography: {
    fontFamily: ["Helvetica Neue"].join(","),
  },
});

const listField = [
  {
    key: "territory_code",
    title: "TIS-A",
  },
  {
    key: "territory_ext_code",
    title: "TIS-A-Ext",
  },
];

const MockListTerritory = [
  {
    title: "Country",
    value: "Country",
    id: "Country",
  },
  {
    title: "Economical Country-G",
    value: "Economical",
    id: "Economical",
  },
  {
    title: "Geographical Country",
    value: "Geographical",
    id: "Geographical",
  },
  {
    title: "Political Country-Gro",
    value: "Political",
    id: "Political",
  },
];

const AddOrEditTerritory = () => {
  const { type, id } = useParams();
  const history = useHistory();
  const isEditing = type === "edit" ? true : false;
  const classes = useStyles();
  const token = localStorage.getItem("token");
  const [listCountry, setListCountry] = useState([]);
  const [state, setState] = useState({
    value: {
      territory_name: "",
      territory_type_flag: "",
      territory_code: "",
      territory_ext_code: "",
      country: [],
    },
    page: 1,
    search: "",
    size: 10,
    listTerritory: [],
    openModal: false,
    totalData: 0,
    loading: false,
    loadingDialog: false,
    openModalDelete: false,
    itemToDelete: null,
  });
  const { value, loading } = state;

  const clearValue = () =>
    setState(currentState => ({
      ...currentState,
      value: {
        territory_name: "",
        territory_type_flag: "",
        territory_code: "",
        territory_ext_code: "",
        country: [],
      },
    }));

  const changeState = (key, value) =>
    setState(currentState => ({
      ...currentState,
      [key]: value,
    }));

  const onChange = (key, value) =>
    setState(currentState => ({
      ...currentState,
      value: {
        ...currentState.value,
        [key]: value,
      },
    }));

  const handleSubmit = () => {
    const params = {
      ...value,
      territory_name: value?.territory_name,
      country_ids: value?.country,
      territory_type_flag: value?.territory_type_flag,
      description: "",
      is_the_world_flag: false,
      is_usa_flag: false,
      parent_territory_id: 0,
    };
    changeState("loading", true);
    if (isEditing) {
      const url = `${hardBaseUrl}/admin/territory/${id}`;
      axios
        .put(url, params, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          Swal.fire({
            title: "Success",
            text: "This territory successfully edited",
            icon: "success",
          });
          history.push("/admin/parameter/territory");
        })
        .catch(({ response }) => {
          Swal.fire({
            title: "Error",
            text: response?.data?.errors[0]?.message || "Something wrong",
            icon: "error",
            confirmButtonText: "Ok",
          });
        })
        .finally(() => {
          changeState("loading", false);
          clearValue();
        });
    } else {
      const url = `${hardBaseUrl}/admin/territory`;
      axios
        .post(url, params, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          Swal.fire({
            title: "Success",
            text: "This territory successfully added",
            icon: "success",
          });
          history.push("/admin/parameter/territory");
        })
        .catch(({ response }) => {
          Swal.fire({
            title: "Error",
            text: response?.data?.errors[0]?.message || "Something wrong",
            icon: "error",
            confirmButtonText: "Ok",
          });
        })
        .finally(() => {
          changeState("loading", false);
          clearValue();
        });
    }
  };

  const getListCountry = async () => {
    const url = `${hardBaseUrl}/country/table`;
    const params = {
      page: 1,
      per_page: -1,
    };
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params,
    });
    setListCountry(response?.data?.data || []);
  };

  const getDetailEdit = async id => {
    changeState("loading", true);
    const url = `${hardBaseUrl}/admin/territory/${id}`;
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = response?.data?.data;
      setState(currentState => ({
        ...currentState,
        loadingDialog: false,
        value: {
          ...data,
          country: (data?.territory_countries || [])?.map(
            ({ country_id }) => country_id
          ),
          territory_name: data?.territory_name || "",
          territory_type_flag: data?.territory_type_flag || "",
          territory_code: data?.territory_code || "",
          territory_ext_code: data?.territory_ext_code || "",
        },
      }));
      changeState("loading", false);
    } catch (err) {
      changeState("loading", false);
    }
  };
  console.log(value, "cek");
  useEffect(() => {
    getListCountry();
    if (isEditing) getDetailEdit(id);
  }, []);

  const renderValue = selected =>
    listCountry
      .filter(item => selected.includes(item.id))
      .map(({ country_name }) => country_name)
      .join(", ");
  return (
    <Page className={classes.root} title="Add Publisher">
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container maxWidth={false}>
        <div>
          <Grid
            alignItems="flex-end"
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <ThemeProvider theme={theme}>
                <Typography
                  style={{
                    fontSize: "24px",
                    fontWeight: "700",
                    lineHeight: "32px",
                  }}
                >
                  {isEditing ? "Edit" : "Add"} Territory
                </Typography>
              </ThemeProvider>
            </Grid>
            <Grid item>
              <Breadcrumbs aria-label="breadcrumb">
                <a href="/admin/parameter/territory">Parameter</a>
                <a href="/admin/parameter/territory">Territory</a>
                <Typography>Add Territory</Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>

          <Divider className={classes.divider} />
          <ThemeProvider theme={theme}>
            <Typography variant="h5" className={classes.title}>
              Territory Information
            </Typography>
            <Typography
              variant="caption"
              className={classes.subTitle}
              style={{
                marginTop: "8px",
              }}
            >
              Create data of the territory Information
            </Typography>
          </ThemeProvider>
          <div
            style={{
              border: "1px solid #9AA2B1",
              padding: "16px",
              borderRadius: "8px",
              marginTop: "16px",
              width: "60%",
            }}
          >
            <Grid container direction="column" spacing={2}>
              <Grid item xs={12}>
                <ThemeProvider theme={fontInter}>
                  <Typography
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    Territory Name *
                  </Typography>
                </ThemeProvider>
                <TextField
                  style={{
                    marginTop: "6px",
                  }}
                  fullWidth
                  onChange={e => onChange("territory_name", e?.target?.value)}
                  id="territoryName"
                  name="territoryName"
                  type="text"
                  value={value?.territory_name}
                  placeholder="Input Territory Name"
                  variant="outlined"
                  InputProps={{
                    classes: { input: classes.inputFields },
                  }}
                />
              </Grid>
              {listField.map(({ key, title }) => (
                <Grid item key={key}>
                  <Typography>{title} *</Typography>
                  <TextField
                    disabled={loading}
                    value={value[key]}
                    fullWidth
                    variant="outlined"
                    placeholder={title}
                    onChange={e => onChange(key, e?.target?.value)}
                    InputProps={{
                      endAdornment: isEditing && loading && (
                        <InputAdornment position="start">
                          <CircularProgress size={30} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              ))}
              <Grid item>
                <ThemeProvider theme={fontInter}>
                  <Typography
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    Territory Type *
                  </Typography>
                </ThemeProvider>
                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  fullWidth={true}
                >
                  <Autocomplete
                    onChange={(_, newValue) =>
                      onChange("territory_type_flag", newValue?.value)
                    }
                    value={
                      MockListTerritory.find(
                        option => option.value === value?.territory_type_flag
                      ) || null
                    }
                    options={MockListTerritory}
                    getOptionLabel={option => option?.title}
                    getOptionSelected={(option, value) =>
                      option.value === value?.territory_type_flag
                    }
                    className={classes.autoComplete}
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Select Territory Type"
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <Typography>Country *</Typography>
                <Select
                  disabled={loading}
                  displayEmpty
                  className={classes.selectPadding}
                  placeholder="Country"
                  value={value?.country}
                  renderValue={renderValue}
                  variant="outlined"
                  fullWidth
                  defaultValue={""}
                  onChange={e => onChange("country", e?.target?.value)}
                  multiple
                >
                  {(listCountry || []).map(({ id, country_name }) => {
                    const isSelected = value?.country.some(item => item === id);
                    return (
                      <MenuItem key={id} value={id}>
                        <Checkbox checked={isSelected} />
                        {country_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>
              <div
                style={{
                  display: "flex",
                  gap: "8px",
                  justifyContent: "flex-end",
                  marginTop: "16px",
                }}
              >
                <Button
                  onClick={() => history.push("/admin/parameter/territory")}
                  // color="primary"
                  style={{
                    height: "40px",
                    width: "115px",
                    borderRadius: "6px",
                    border: "1px solid #D1D5DC",
                    color: "#111827",
                    backgroundColor: "white",
                    textTransform: "none",
                  }}
                >
                  Cancel
                </Button>
                <Button
                  disabled={loading}
                  variant="contained"
                  onClick={() => handleSubmit()}
                  className={classes.button}
                >
                  <ThemeProvider theme={fontInter}>
                    {loading ? (
                      <CircularProgress size={20} />
                    ) : (
                      <Typography
                        style={{
                          color: "white",
                          fontWeight: 500,
                          fontSize: "14px",
                          lineHeight: "20px",
                        }}
                      >
                        Save
                      </Typography>
                    )}
                  </ThemeProvider>
                </Button>
              </div>
            </Grid>
          </div>
        </div>
      </Container>
    </Page>
  );
};

export default AddOrEditTerritory;
