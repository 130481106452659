import { Button, Divider, Typography } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { BackupOutlined } from "@material-ui/icons";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import { useRef } from "react";
import { hardBaseUrl } from "../../../../../services/urlConstant";
import { PrimaryButton } from "components";

const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  results: {
    marginTop: theme.spacing(3),
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  btnBack: {
    backgroundColor: "#25383c",
    color: "#fff",
    marginLeft: "10px",
    marginRight: "20px",
    textTransform: "none",
  },
  btnSubmit: {
    border: "1px solid lightgrey",
    textTransform: "none",
  },
  flexBetween: {
    display: "flex",
    justifyContent: "space-between",
  },
  fontSize16: {
    fontSize: "16px",
    fontWeight: 700,
  },
  closeOutline: {
    color: "#9AA2B1",
    cursor: "pointer",
    width: "12px",
    height: "12px",
    marginTop: "8px",
  },
  fontSize14: {
    fontSize: "14px",
    fontWeight: 500,
  },
  backupOutlined: {
    color: "grey",
    fontSize: "40px",
  },
  mt20: {
    marginTop: "20px",
  },
  mb10: {
    marginBottom: "10px",
  },
  listStyle: {
    listStyle: "none",
  },
  flexEnd: {
    display: "flex",
    justifyContent: "end",
  },
  mb20: {
    marginBottom: "20px",
  },
}));

const DialogImportDokumen = ({
  open,
  onClose,
  onSubmit,
  getRootProps,
  getInputProps,
  isDragActive,
  files,
}) => {
  const classes = useStyles();
  const dialogRef = useRef();

  const downloadTemplate = () => {
    const url = `${hardBaseUrl}/publisher/composer/export`;
    const token = localStorage.getItem("token");
    axios
      .get(url, {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(res => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "template.xlsx");
        document.body.appendChild(link);
        link.click();
      });
  };

  return (
    <div>
      <Dialog
        size="small"
        open={open}
        onClose={onClose}
        aria-labelledby="responsive-dialog-title"
        ref={dialogRef}
      >
        <DialogTitle id="responsive-dialog-title">
          <div className={classes.flexBetween}>
            <ThemeProvider theme={theme}>
              <Typography className={classes.fontSize16}>
                {"Import Document"}
              </Typography>
            </ThemeProvider>

            <CloseOutlinedIcon
              onClick={onClose}
              className={classes.closeOutline}
            />
          </div>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <ThemeProvider theme={theme}>
            <Typography className={classes.fontSize14}>
              Upload Document
            </Typography>
          </ThemeProvider>
          <div className="">
            <div {...getRootProps}>
              <input {...getInputProps()} />
              {}

              {isDragActive ? (
                <>
                  <BackupOutlined className={classes.backupOutlined} />
                  <p>Drop the files here ...</p>
                </>
              ) : (
                <>
                  <BackupOutlined className={classes.backupOutlined} />
                  <Typography component="p" variant="h6">
                    <b>Search File</b>, Drag Document Here
                  </Typography>
                </>
              )}
            </div>
            <div
              style={{
                marginTop: "20px",
              }}
            >
              <aside>
                <ThemeProvider theme={theme}>
                  <PrimaryButton
                    onClick={downloadTemplate}
                    label="Download Template"
                  />
                  <Typography className={(classes.fontSize14, classes.mb10)}>
                    Uploaded File
                  </Typography>
                  <ul className={classes.listStyle}>{files}</ul>
                </ThemeProvider>
              </aside>
            </div>
          </div>
        </DialogContent>
        <Divider className={classes.divider} />
        <div className={(classes.flexEnd, classes.mb20)}>
          <Button
            className={classes.btnSubmit}
            variant="outlined"
            onClick={onClose}
          >
            Cancel
          </Button>

          <Button className={classes.btnBack} onClick={onSubmit}>
            Upload
          </Button>
        </div>
      </Dialog>
    </div>
  );
};

export default DialogImportDokumen;
