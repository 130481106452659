import {
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  FormControl,
  Grid,
  Input,
  MenuItem,
  OutlinedInput,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@material-ui/core";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import { useEffect, useState } from "react";
import moment from "moment";
import Swal from "sweetalert2";
import { Page } from "components";
import { hardBaseUrl } from "../../../services/urlConstant";
import gradients from "../../../theme/gradients";

const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  card: {
    width: theme.breakpoints.values.md,
    maxWidth: "100%",
    overflow: "visible",
    display: "flex",
    position: "relative",
    "& > *": {
      flexGrow: 1,
      flexBasis: "50%",
      width: "50%",
    },
  },
  content: {
    padding: theme.spacing(8, 4, 3, 4),
  },
  media: {
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    padding: theme.spacing(3),
    color: theme.palette.common.white,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  avatar_large: {
    width: theme.spacing(16),
    height: theme.spacing(16),
  },
  icon: {
    backgroundImage: gradients.green,
    color: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    position: "absolute",
    top: -32,
    left: theme.spacing(3),
    height: 64,
    width: 64,
    fontSize: 32,
  },
  loginForm: {
    marginTop: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  person: {
    marginTop: theme.spacing(2),
    display: "flex",
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  title: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "28px",
  },

  tambahLagu: {
    backgroundColor: "black",

    color: "white",
    width: "32px",
    height: "29px",
    borderRadius: "6px",
    cursor: "pointer",

    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
  textTambahLagu: {
    marginLeft: "10px",
    marginTop: "5px",
    fontSize: "14px",
    color: "#687083",
  },
  btnDialog: {
    backgroundColor: "black",

    color: "white",
    padding: "10px 28px",

    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
  btnSubmit: {
    backgroundColor: "black",

    color: "white",
    padding: "10px 28px",

    height: "40px",
    borderRadius: "6px",

    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
  headText: {
    fontSize: "12px",
    color: "#687083",
    fontWeight: "bold",
  },
  bodyTextBlack: {
    fontSize: "14px",
    fontWeight: "regular",
    color: "#111827",
  },
  bodyTextGrey: {
    fontSize: "14px",
    fontWeight: "regular",
    color: "#687083",
  },
  tableRow: {
    height: 20,
  },
  conTextBlack: {
    fontSize: "14px",
    fontWeight: "bold",
  },
  dropdown: {
    color: "black",
  },
  label: {
    color: "#364052",
    fontSize: "14px",
    fontWeight: 500,
  },
  subTitle: {
    fontSize: "18px",
    fontWeight: 700,
    color: " #111827",
  },
  breadcrumbs: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#111827",
  },
  breadCrumbsActive: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#687083",
  },
}));

function AjukanPembayaran(props) {
  const classes = useStyles();

  const checked = false;

  const songId = null;
  const startDate = moment().toString();
  const endDate = moment().toString();
  const dateRange = 0;

  const [keterangan, setKeterangan] = useState("");
  const [openSnack, setOpenSnack] = useState(false);

  const [typeSnackbar, setTypeSnackbar] = useState("success");
  const [message, setMessage] = useState("This is a message!");
  const wordsSong = "";
  const [getColor, setGetColor] = useState("");

  useEffect(() => {
    const getMainDsp = async () => {
      try {
        const token = localStorage.getItem("token");
        const url = `${hardBaseUrl}/me`;
        const res = await axios.get(url, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        setGetColor(res.data.data.publisher.theme_color);
      } catch (e) {
        new Error(e);
      }
    };
    getMainDsp();
  }, [checked, startDate, endDate, wordsSong]);

  const handleButtonSimpan = async e => {
    e.preventDefault();

    try {
      const token = localStorage.getItem("token");

      if (songId === null) {
        setTypeSnackbar("error");
        setMessage("lagu Tidak Boleh Kosong.");
        setOpenSnack(true);
      }
      if (startDate === "" || null || undefined) {
        setTypeSnackbar("error");
        setMessage("Tanggal Mulai Tidak Boleh Kosong.");
        setOpenSnack(true);
      }
      if (endDate === "" || null || undefined) {
        setTypeSnackbar("error");
        setMessage("Tanggal Selesai Tidak Boleh Kosong.");
        setOpenSnack(true);
      }
      if (keterangan === "") {
        setTypeSnackbar("error");
        setMessage("Keterangan Tidak Boleh Kosong.");
        setOpenSnack(true);
      }

      if (
        songId !== null &&
        startDate !== null &&
        endDate !== null &&
        keterangan !== ""
      ) {
        let payload = {
          song_id: songId,
          date_period: Number(dateRange),
          start_date: startDate.toISOString().split("T")[0],
          end_date: endDate.toISOString().split("T")[0],
          is_active_flag: true,
          description: keterangan,
        };

        let baseOfUrl = hardBaseUrl;
        let urlUse = baseOfUrl + "/publisher/contract";
        let resultAdd = await axios.post(urlUse, payload, {
          headers: {
            Authorization: "Bearer " + token,
          },
        });
        if (
          resultAdd.data.message === "success" &&
          resultAdd.data.meta.http_status === 200
        ) {
          Swal.fire({
            title: "Success",
            text: "Data Berhasil Disimpan",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "OK",
          });

          setTimeout(() => {
            props.history.push("/admin/permintaan-pembayaran");
          }, 3000);
        } else {
          setTypeSnackbar("error");
          setMessage("Tambah Kontrak Gagal.");
          setOpenSnack(true);
        }
      }
    } catch (err) {
      Swal.fire({
        title: "Error",
        text: "Error.",
        icon: "error",
      }).then(() => { });
    }
  };

  const handleChangeText = e => {
    setKeterangan(e.target.value);
  };
  const handleCloseSnackbar = () => {
    setOpenSnack(false);
  };

  return (
    <Page className={classes.root} title="Ajukan Pembayaran">
      <Container maxWidth={false}>
        <Snackbar
          open={openSnack}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert onClose={handleCloseSnackbar} severity={typeSnackbar}>
            {message}
          </Alert>
        </Snackbar>
        <Grid
          alignItems="flex-end"
          container
          justifyContent="space-between"
          spacing={3}
        >
          <Grid item>
            <ThemeProvider theme={theme}>
              <Typography
                style={{
                  fontSize: "24px",
                  fontWeight: "700",
                  lineHeight: "32px",
                }}
              >
                Ajukan Pembayaran
              </Typography>
            </ThemeProvider>
          </Grid>
          <Grid item>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography className={classes.breadCrumbs}>Home</Typography>

              <Typography className={classes.breadCrumbs}>
                Permintaan Pembayaran
              </Typography>
              <Typography className={classes.breadCrumbsActive}>
                Ajukan Pembayaran
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
        <Divider className={classes.divider} />

        <Grid container>
          <div>
            <ThemeProvider theme={theme}>
              <Typography
                component="h1"
                variant="h3"
                className={classes.subTitle}
              >
                Formulir Pengajuan Pembayaran
              </Typography>
            </ThemeProvider>
            <ThemeProvider theme={theme}>
              <p
                style={{
                  marginTop: "5px",
                  color: "#8f8f8f",
                  fontSize: "14px",
                }}
              >
                Sesuaikan konten untuk mengingatkan pencipta dengan waktu
                kontrak akan berakhir.
              </p>
            </ThemeProvider>
          </div>
        </Grid>
        <Grid container>
          <Grid item xs={8} md={8} sm={8} lg={8} xl={8}>
            <Card
              style={{
                border: "1px solid #9AA2B1",
                borderRadius: "6px",
                boxShadow: "none",
              }}
            >
              <CardContent>
                <Grid container>
                  <Grid item xs={6} md={6} sm={6} lg={6} xl={6}>
                    <div
                      style={{
                        marginTop: "5px",
                        marginBottom: "5px",
                      }}
                    >
                      <div
                        style={{
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        <ThemeProvider theme={theme}>
                          <Typography
                            variant="subtitle2"
                            className={classes.label}
                          >
                            Publisher
                          </Typography>
                        </ThemeProvider>
                      </div>
                      <div
                        style={{
                          marginTop: "-10px",
                          marginBottom: "10px",
                          marginRight: "10px",
                        }}
                      >
                        <FormControl fullWidth size="small">
                          <Select
                            variant="outlined"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                          >
                            <MenuItem value={10}>Ten</MenuItem>
                            <MenuItem value={20}>Twenty</MenuItem>
                            <MenuItem value={30}>Thirty</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={6} md={6} sm={6} lg={6} xl={6}>
                    <div
                      style={{
                        marginTop: "5px",
                        marginBottom: "5px",
                      }}
                    >
                      <div
                        style={{
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        <ThemeProvider theme={theme}>
                          <Typography
                            variant="subtitle2"
                            className={classes.label}
                          >
                            Total Pembayaran Pencipta
                          </Typography>
                        </ThemeProvider>
                      </div>
                      <div
                        style={{
                          marginTop: "-10px",
                          marginBottom: "10px",
                          marginRight: "10px",
                        }}
                      >
                        <ThemeProvider theme={theme}>
                          <Typography
                            variant="subtitle2"
                            className={classes.label}
                            style={{
                              fontSize: "18px",
                            }}
                          >
                            Rp. 0
                          </Typography>
                        </ThemeProvider>
                      </div>
                    </div>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6} md={6} sm={6} lg={6} xl={6}>
                    <div
                      style={{
                        marginTop: "5px",
                        marginBottom: "5px",
                      }}
                    >
                      <div
                        style={{
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        <ThemeProvider theme={theme}>
                          <Typography
                            variant="subtitle2"
                            className={classes.label}
                          >
                            Pengajuan Pembayaran
                          </Typography>
                        </ThemeProvider>
                      </div>
                      <div
                        style={{
                          marginTop: "5px",
                          marginBottom: "10px",
                          display: "flex",
                        }}
                      >
                        <Input
                          disabled
                          size="small"
                          value="Rp."
                          inputProps={{
                            min: 0,
                            style: { textAlign: "center" },
                          }}
                          style={{
                            width: "40px",
                            margin: "0px",
                            padding: "0px",
                            border: "1px solid #D1D5DC",
                            backgroundColor: "#D1D5DC",
                            borderRadius: "5px 0px 0px 5px",
                          }}
                        />
                        <FormControl variant="standard" size="small" fullWidth>
                          <OutlinedInput
                            variant="outlined"
                            id="input-with-icon-adornment"
                            style={{
                              borderRadius: "0px 5px 5px 0px",
                            }}
                          />
                        </FormControl>
                      </div>
                    </div>
                  </Grid>

                  <Grid item xs={6} md={6} sm={6} lg={6} xl={6}>
                    <div
                      style={{
                        marginTop: "5px",
                        marginBottom: "5px",
                      }}
                    >
                      <div
                        style={{
                          marginTop: "10px",
                          marginBottom: "20px",
                        }}
                      >
                        <ThemeProvider theme={theme}>
                          <Typography
                            variant="subtitle2"
                            className={classes.label}
                          >
                            Transfer ke bank Anda
                          </Typography>
                        </ThemeProvider>
                      </div>
                      <div
                        style={{
                          marginTop: "-10px",
                          marginBottom: "10px",
                        }}
                      >
                        <FormControl fullWidth size="small">
                          <Select
                            variant="outlined"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                          >
                            <MenuItem value={10}>Ten</MenuItem>
                            <MenuItem value={20}>Twenty</MenuItem>
                            <MenuItem value={30}>Thirty</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={12} sm={12} lg={12} xl={12}>
                    <div
                      style={{
                        marginTop: "5px",
                        marginBottom: "5px",
                      }}
                    >
                      <div
                        style={{
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        <ThemeProvider theme={theme}>
                          <Typography
                            variant="subtitle2"
                            className={classes.label}
                          >
                            Catatan
                          </Typography>
                        </ThemeProvider>
                      </div>
                      <div
                        style={{
                          marginTop: "-10px",
                          marginBottom: "10px",
                        }}
                      >
                        <TextField
                          multiline={true}
                          minRows={10}
                          id="outlined-basic"
                          placeholder="Tulis catatan disini..."
                          variant="outlined"
                          fullWidth={true}
                          margin="dense"
                          name="keterangan"
                          onChange={e => handleChangeText(e)}
                        />
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={4} md={4} sm={4} lg={4} xl={4}></Grid>
        </Grid>
        <Grid container>
          <Button
            variant="contained"
            onClick={e => handleButtonSimpan(e)}
            className={classes.btnSubmit}
            style={{
              backgroundColor: getColor,
              marginTop: "20px",
            }}
          >
            <ThemeProvider theme={theme}>
              <Typography
                style={{
                  textTransform: "none",
                  color: "#FFFFFF",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "20px",
                }}
              >
                Simpan
              </Typography>
            </ThemeProvider>
          </Button>
        </Grid>
      </Container>
    </Page>
  );
}

export default AjukanPembayaran;
