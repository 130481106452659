import {
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  Chip,
  Container,
  Divider,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import PencilVectorGrey from "../../../../assets/img/pencilVectorGrey.svg";
import TrashVectorGrey from "../../../../assets/img/trashVectorGrey.svg";
import CustomModal from "../../../../components/molecules/Modal/CustomModal";
import { Page } from "components";
import { hardBaseUrl } from "../../../../services/urlConstant";
import { formatFileSize } from "../../../../utils";

const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  results: {
    marginTop: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  formControl: {},
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  pagination: {
    "& .Mui-selected": {
      backgroundColor: "black",
      color: "white",
    },
  },
  pageSize: {
    height: "30px",
    borderRadius: "6px",
    border: "1px solid #D1D5DC",
    paddingLeft: "5px",
    paddingRight: "5px",
    marginTop: "5px",
  },
  paginationText: {
    fontSize: "14px",
    fontWeight: "normal",
    fontStyle: "normal",
    color: "#687083",
  },
  breadcrumbs: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#111827",
  },
  breadCrumbsActive: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#687083",
  },
  placeholder: {
    "&::placeholder": {
      color: "#9AA2B1",
    },
  },
  subTitle: {
    fontSize: "18px",
    fontWeight: 700,
    color: " #111827",
  },
  headText: {
    fontSize: "12px",
    color: "#687083",
    fontWeight: "bold",
  },
  buttonBlack: {
    backgroundColor: " #111827",
    color: "white",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "20px",
    borderRadius: "6px",
    textTransform: "none",
    marginLeft: "16px",
  },
  buttonDisabled: {
    backgroundColor: "gray",
    color: "white",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "20px",
    borderRadius: "6px",
    textTransform: "none",
    marginLeft: "16px",
  },
  buttonWhite: {
    backgroundColor: "white",
    color: "black",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "20px",
    borderRadius: "6px",
    border: "1px solid",
    textTransform: "none",
  },

  myinput: {
    height: "38px",
    borderTopLeftRadius: "0px",
    borderBottomLeftRadius: "0px",
  },
}));

const DetailLoanPencipta = () => {
  const classes = useStyles();

  const [state, setState] = useState({});
  const { id } = useParams();
  const [modalPayment, setModalPayment] = useState(false);
  const [files, setFiles] = useState([]);

  const [balance, setBalance] = useState(null);

  const [initTable, setInitTable] = useState([]);
  const [money, setMoney] = useState("");
  const [theLoanDetails, setTheLoanDetails] = useState([]);
  const [currentLengthItems, setCurrentLengthItems] = useState(0);
  const [enableAddButton, setEnableAddButton] = useState(false);

  const oneTable = {
    ins_number: 1,
    due_date: "aaaa",
    installment_value: 0,
    status_installment: "Unpaid",
    show_action: true,
  };

  const createNowDate = () => {
    const yyy = moment().format("D MMMM YYYY");

    return yyy;
  };

  const getDataDetail = () => {
    let theToken = localStorage.getItem("token");
    const url = `${hardBaseUrl}/composer/transaction/loan/${id}`;
    axios
      .get(hardBaseUrl + "/me", {
        headers: {
          Authorization: `Bearer ${theToken}`,
        },
      })
      .then(() => {
        axios
          .get(url, {
            headers: {
              Authorization: `Bearer ${theToken}`,
            },
          })
          .then(response => {
            setCurrentLengthItems(response.data.data.loan_details.length);
            setTheLoanDetails(response.data.data.loan_details);
            setState(response.data.data);

            if (response?.data?.data?.loan_details?.length > 0) {
              setEnableAddButton(true);
              let items = response.data.data.loan_details;
              let newArr = [];
              items.forEach(item => {
                let newObj = {
                  ins_number: item.installment_number,
                  due_date: item.date,

                  installment_value: item.repayment_amount,
                  status_installment: item.payment_status,
                  show_action: false,
                };
                newArr.push(newObj);
              });

              setInitTable(newArr);
            } else {
              setEnableAddButton(false);
              let newTab = {
                ...oneTable,
                due_date: createNowDate(),
                ins_number: response.data.data.loan_details.length + 1,
              };

              let currentDetail = response.data.data.loan_details;
              currentDetail.push(newTab);
              setInitTable(currentDetail);
            }
          })
          .catch(() => { });
      })
      .catch(() => { });
  };
  const getBalance = async () => {
    try {
      let theToken = localStorage.getItem("token");
      let baseOfUrl = hardBaseUrl;
      const url = baseOfUrl + "/composer/transaction/payment/get-balance";
      const options = {
        headers: {
          Authorization: "Bearer " + theToken,
        },
      };
      const response = await axios.get(url, options);
      setBalance(response.data.data.balance_string);
    } catch (error) {
      new Error(error);
    }
  };
  useEffect(() => {
    getDataDetail();
    getBalance();
  }, []);

  const handleFiles = e => {
    const data = [];
    Array.from(e.target.files).forEach(file => {
      data.push(file);
    });
    setFiles(data);
  };

  const uploadIns = () => {
    setModalPayment(false);
    let theToken = localStorage.getItem("token");

    let numMoney = money.replaceAll(",", "");

    const formData = new FormData();
    formData.append("id", id);
    files.map(val => {
      formData.append("documents", val);
    });
    formData.append("repayment_amount", Number(numMoney));
    const url = `${hardBaseUrl}/composer/transaction/loan/repayment`;
    axios
      .post(url, formData, {
        headers: {
          Authorization: `Bearer ${theToken}`,
        },
      })
      .then(response => {
        Swal.fire({
          icon: response?.data?.message === "success" ? "success" : "error",
          title: response?.data?.message === "success" ? "Success" : "Error",
          text: response?.data?.message,
        }).then(() => (window.location.href = "/pencipta/loan/" + id));
      })
      .catch(err => {
        Swal.fire({
          icon: err?.data?.message === "success" ? "success" : "error",
          title: err?.data?.message === "success" ? "Success" : "Error",
          text: err?.response?.data?.errors.map(val => `${val?.message}` + " "),
        });
      });
  };

  const addNewRow = () => {
    setEnableAddButton(false);
    let items = theLoanDetails;
    let newArr = [];
    items.forEach(item => {
      let newValueOfMoney = item.repayment_amount;
      let newObj = {
        ins_number: item.installment_number,
        due_date: item.date,

        installment_value: newValueOfMoney,
        status_installment: item.payment_status,
        show_action: false,
      };
      newArr.push(newObj);
    });
    let newTab = {
      ...oneTable,
      due_date: createNowDate(),
      ins_number: currentLengthItems + 1,
    };
    newArr.push(newTab);
    setInitTable(newArr);
  };

  return (
    <Page className={classes.root} title="Loan">
      <Container maxWidth={false}>
        <Grid
          alignItems="flex-end"
          container
          justifyContent="space-between"
          spacing={3}
        >
          <Grid item>
            <ThemeProvider theme={theme}>
              <Typography
                style={{
                  fontSize: "24px",
                  fontWeight: "700",
                  lineHeight: "32px",
                }}
              >
                Loan Transaction
              </Typography>
            </ThemeProvider>
          </Grid>
          <Grid item>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography className={classes.breadCrumbs}>Home</Typography>

              <Typography className={classes.breadCrumbs}>
                Transaction
              </Typography>

              <Typography className={classes.breadCrumbsActive}>
                Detail Transaction
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>

        <Divider className={classes.divider} />

        <Grid container>
          <Grid item xs={12} md={8} lg={8} xl={8}>
            <Grid container>
              <div>
                <ThemeProvider theme={theme}>
                  <Typography
                    component="h1"
                    variant="h3"
                    className={classes.subTitle}
                  >
                    Loan Detail
                  </Typography>
                </ThemeProvider>
                <ThemeProvider theme={theme}>
                  <p
                    style={{
                      marginTop: "5px",
                      color: "#8f8f8f",
                      fontSize: "14px",
                    }}
                  >
                    This is the detail of the loan transaction
                  </p>
                </ThemeProvider>
              </div>
              <Grid
                style={{
                  textAlign: "right",
                  display: "flex",
                  justifyContent: "flex-end",
                  flex: 1,
                }}
                item
              >
                <CustomChip status={state?.status} />
              </Grid>
            </Grid>
            <Card
              style={{
                border: "1px solid #9AA2B1",
                borderRadius: "6px",
                boxShadow: "none",
              }}
            >
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item md={8}>
                    <h1
                      style={{
                        fontWeight: "700",
                        fontSize: "24px",
                        lineHeight: "32px",
                      }}
                    >
                      {state?.composer_name}
                    </h1>
                  </Grid>
                  <Grid item md={4}>
                    <div
                      style={{
                        textAlign: "right",
                      }}
                    >
                      <p>
                        {state?.composer_address ||
                          state?.publisher_address ||
                          ""}
                      </p>
                    </div>
                  </Grid>
                </Grid>

                <Divider className={classes.divider} />

                <Grid container spacing={3}>
                  <Grid item xs={12} md={6} lg={6} xl={6}>
                    <ThemeProvider theme={theme}>
                      <Typography
                        style={{
                          fontSize: "14px",
                          fontWeight: "400",
                          lineHeight: "16.94px",
                        }}
                      >
                        Request Loan To:
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "14px",
                          fontWeight: "600",
                          lineHeight: "16.94px",
                        }}
                      >
                        {state?.publisher_name}
                      </Typography>
                    </ThemeProvider>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6} xl={6}>
                    <ThemeProvider theme={theme}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          style={{
                            fontSize: "14px",
                            fontWeight: "400",
                            lineHeight: "16.94px",
                          }}
                        >
                          Date :
                        </Typography>

                        <Typography
                          style={{
                            fontSize: "14px",
                            fontWeight: "400",
                            lineHeight: "16.94px",
                          }}
                        >
                          {state?.date}
                        </Typography>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: "5px",
                        }}
                      >
                        <Typography
                          style={{
                            fontSize: "14px",
                            fontWeight: "400",
                            lineHeight: "16.94px",
                          }}
                        >
                          Composer :
                        </Typography>

                        <Typography
                          style={{
                            fontSize: "14px",
                            fontWeight: "400",
                            lineHeight: "16.94px",
                          }}
                        >
                          {state?.composer_name}
                        </Typography>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: "5px",
                        }}
                      >
                        <Typography
                          style={{
                            fontSize: "14px",
                            fontWeight: "400",
                            lineHeight: "16.94px",
                          }}
                        >
                          Tenor :
                        </Typography>

                        <Typography
                          style={{
                            fontSize: "14px",
                            fontWeight: "400",
                            lineHeight: "16.94px",
                          }}
                        >
                          {state?.tenor}
                        </Typography>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: "5px",
                        }}
                      >
                        <Typography
                          style={{
                            fontSize: "14px",
                            fontWeight: "400",
                            lineHeight: "16.94px",
                          }}
                        >
                          Value :
                        </Typography>

                        <Typography
                          style={{
                            fontSize: "14px",
                            fontWeight: "400",
                            lineHeight: "16.94px",
                          }}
                        >
                          {state?.transaction_value}
                        </Typography>
                      </div>
                    </ThemeProvider>
                  </Grid>
                </Grid>
                <Divider className={classes.divider} />

                <Grid container>
                  <div>
                    <ThemeProvider theme={theme}>
                      <Typography
                        style={{
                          fontSize: "14px",
                          fontWeight: "600",
                        }}
                      >
                        Notes :
                      </Typography>
                    </ThemeProvider>
                  </div>
                  <div>{" " + state?.notes}</div>
                </Grid>
                <div
                  style={{
                    marginTop: "20px",
                  }}
                ></div>

                <div
                  style={{
                    marginTop: "20px",
                  }}
                >
                  <ThemeProvider theme={theme}>
                    <Typography
                      style={{
                        fontSize: "14px",
                        fontWeight: "400",
                        textAlign: "right",
                      }}
                    >
                      Value :
                    </Typography>
                    <Typography
                      style={{
                        fontSize: "24px",
                        fontWeight: "600",
                        textAlign: "right",
                      }}
                    >
                      {state?.transaction_value}
                    </Typography>
                  </ThemeProvider>
                </div>
              </CardContent>
            </Card>

            {state.status === "active" ? (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      marginTop: "16px",
                      border: "1px solid #D1D5DC",
                      borderRadius: "10px",
                      padding: "16px",
                      width: "340px",
                      backgroundColor: "#111827",
                      position: "relative",
                    }}
                  >
                    <img
                      style={{
                        position: "absolute",
                        right: 40,
                      }}
                      src={
                        require("assets/image-public/images/rectangle_opacity.png")
                          .default
                      }
                      alt=""
                    />
                    <img
                      style={{
                        position: "absolute",
                        right: -20,
                        height: "64px",
                      }}
                      src={
                        require("assets/image-public/images/rectangle_opacity.png")
                          .default
                      }
                      alt=""
                    />
                    <img
                      style={{
                        position: "absolute",
                        right: -2,
                        height: "64px",
                        top: -15,
                      }}
                      src={
                        require("assets/image-public/images/rectangle_opacity.png")
                          .default
                      }
                      alt=""
                    />
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div>
                        <img
                          alt="Logo"
                          src={
                            require("assets/image-public/images/icon_images/ri_money-dollar-circle-fill.svg")
                              .default
                          }
                        />
                      </div>
                      <div
                        style={{
                          marginLeft: "18px",
                        }}
                      >
                        <div>
                          <span
                            style={{
                              fontFamily: "Inter",
                              fontStyle: "normal",
                              fontWeight: "500",
                              fontSize: "14px",
                              lineHeight: "20px",
                              color: "#9AA2B1",
                            }}
                          >
                            Loan Total
                          </span>
                        </div>
                        <div
                          style={{
                            marginTop: "8px",
                          }}
                        >
                          <span
                            style={{
                              fontFamily: "Inter",
                              fontStyle: "normal",
                              fontWeight: "700",
                              fontSize: "24px",
                              lineHeight: "32px",
                              color: "white",
                            }}
                          >
                            {state?.transaction_value}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "none",

                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <span
                          style={{
                            fontFamily: "Inter",
                            fontStyle: "normal",
                            fontWeight: "500",
                            fontSize: "16px",
                            lineHeight: "24px",
                            color: "#687083",
                          }}
                        >
                          b
                        </span>
                      </div>
                      <div>
                        <span
                          style={{
                            fontFamily: "Inter",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "14px",
                            lineHeight: "20px",
                            color: "#687083",
                          }}
                        >
                          a
                        </span>
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      marginTop: "16px",
                      border: "1px solid #D1D5DC",
                      borderRadius: "10px",
                      padding: "16px",
                      width: "340px",
                      backgroundColor: "#111827",
                      position: "relative",
                    }}
                  >
                    <img
                      style={{
                        position: "absolute",
                        right: 40,
                      }}
                      src={
                        require("assets/image-public/images/rectangle_opacity.png")
                          .default
                      }
                      alt=""
                    />
                    <img
                      style={{
                        position: "absolute",
                        right: -20,
                        height: "64px",
                      }}
                      src={
                        require("assets/image-public/images/rectangle_opacity.png")
                          .default
                      }
                      alt=""
                    />
                    <img
                      style={{
                        position: "absolute",
                        right: -2,
                        height: "64px",
                        top: -15,
                      }}
                      src={
                        require("assets/image-public/images/rectangle_opacity.png")
                          .default
                      }
                      alt=""
                    />
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div>
                        <img
                          alt="Logo"
                          src={
                            require("assets/image-public/images/icon_images/ri_money-dollar-circle-fill.svg")
                              .default
                          }
                        />
                      </div>
                      <div
                        style={{
                          marginLeft: "18px",
                        }}
                      >
                        <div>
                          <span
                            style={{
                              fontFamily: "Inter",
                              fontStyle: "normal",
                              fontWeight: "500",
                              fontSize: "14px",
                              lineHeight: "20px",
                              color: "#9AA2B1",
                            }}
                          >
                            Balance
                          </span>
                        </div>
                        <div
                          style={{
                            marginTop: "8px",
                          }}
                        >
                          <span
                            style={{
                              fontFamily: "Inter",
                              fontStyle: "normal",
                              fontWeight: "700",
                              fontSize: "24px",
                              lineHeight: "32px",
                              color: "white",
                            }}
                          >
                            {balance}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "none",

                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <span
                          style={{
                            fontFamily: "Inter",
                            fontStyle: "normal",
                            fontWeight: "500",
                            fontSize: "16px",
                            lineHeight: "24px",
                            color: "#687083",
                          }}
                        >
                          b
                        </span>
                      </div>
                      <div>
                        <span
                          style={{
                            fontFamily: "Inter",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "14px",
                            lineHeight: "20px",
                            color: "#687083",
                          }}
                        >
                          a
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>{""}</>
            )}

            {state?.status === "active" && (
              <Grid style={{ marginTop: "24px" }}>
                <Table size="small" style={{ border: "1px solid gray" }}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Installment Number</TableCell>
                      <TableCell>Due Date</TableCell>
                      <TableCell>Installment Value</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell align="center">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {initTable.map(item => {
                      return (
                        <TableRow key={item.ins_number}>
                          <ThemeProvider theme={theme}>
                            <TableCell>{item.ins_number}</TableCell>
                            <TableCell>{item.due_date}</TableCell>
                            <TableCell>
                              {item.status_installment === "paid" ? (
                                <span>{item.installment_value}</span>
                              ) : (
                                <>
                                  <div>
                                    <Grid style={{ display: "flex" }}>
                                      <Grid
                                        style={{
                                          width: "48px",
                                          height: "38px",
                                          marginRight: "-2px",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          borderRadius: "6px 0px 0px 6px",
                                          backgroundColor: "#F9FAFB",
                                          border: " 1px solid #D1D5DC",
                                          color: "#9AA2B",
                                          borderRight: "none",
                                        }}
                                      >
                                        Rp
                                      </Grid>
                                      <NumberFormat
                                        disabled={!item.show_action}
                                        customInput={TextField}
                                        variant="outlined"
                                        thousandSeparator={true}
                                        onChange={e => setMoney(e.target.value)}
                                        autoComplete="off"
                                        InputProps={{
                                          classes: {
                                            root: classes.myinput,
                                          },
                                        }}
                                      />
                                    </Grid>
                                  </div>
                                </>
                              )}
                            </TableCell>
                            <TableCell>
                              <span
                                style={{
                                  textTransform: "capitalize",
                                }}
                              >
                                {item.status_installment}
                              </span>
                            </TableCell>
                            <TableCell>
                              {item.show_action ? (
                                <Button
                                  onClick={() => setModalPayment(true)}
                                  key={item.ins_number}
                                  className={classes.buttonBlack}
                                >
                                  Pay
                                </Button>
                              ) : (
                                <Button
                                  disabled={true}
                                  key={item.ins_number}
                                  className={classes.buttonDisabled}
                                >
                                  Pay
                                </Button>
                              )}
                            </TableCell>
                          </ThemeProvider>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
                {enableAddButton ? (
                  <>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        marginTop: "16px",
                      }}
                    >
                      <Button
                        onClick={() => addNewRow()}
                        style={{
                          backgroundColor: "black",
                          color: "white",
                          padding: "10px 28px 10px 28px",

                          width: "300px",
                        }}
                        variant="contained"
                        size="large"
                      >
                        <ThemeProvider theme={theme}>
                          <Typography
                            component="span"
                            style={{
                              fontSize: "14px",
                              lineHeight: "20px",
                              fontWeight: "500",
                              fontStyle: "normal",
                              textTransform: "none",
                              whiteSpace: "nowrap",
                            }}
                          >
                            Add Payment
                          </Typography>
                        </ThemeProvider>
                      </Button>
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        marginTop: "16px",
                      }}
                    >
                      <Button
                        disabled={!enableAddButton}
                        onClick={() => { }}
                        style={{
                          backgroundColor: "#dbd5d5",
                          color: "white",
                          padding: "10px 28px 10px 28px",

                          width: "300px",
                        }}
                        variant="contained"
                        size="large"
                      >
                        <ThemeProvider theme={theme}>
                          <Typography
                            component="span"
                            style={{
                              fontSize: "14px",
                              lineHeight: "20px",
                              fontWeight: "500",
                              fontStyle: "normal",
                              textTransform: "none",
                              whiteSpace: "nowrap",
                            }}
                          >
                            Add Payment
                          </Typography>
                        </ThemeProvider>
                      </Button>
                    </div>
                  </>
                )}
              </Grid>
            )}
          </Grid>
        </Grid>
      </Container>
      {modalPayment && (
        <CustomModal
          open={modalPayment}
          handleClose={() => {
            setModalPayment(false);
          }}
          width="460px"
          noPadding
        >
          <Grid
            style={{
              display: "flex",
              justifyContent: "space-between",
              borderBottom: "1px solid #E4E7EB",
              padding: "20px",
            }}
          >
            <h1
              style={{
                fontWeight: 700,
                fontSize: "20px",
                lineHeight: "30px",
              }}
            >
              Upload Payment Proof
            </h1>
            <IconButton
              style={{ padding: 0 }}
              onClick={() => setModalPayment(false)}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid
            style={{
              padding: "24px",
            }}
          >
            <Grid
              style={{
                border: "1px dashed #9AA2B1",
                width: "100%",
                paddingTop: "16px",
                paddingBottom: "16px",
                textAlign: "center",
                backgroundColor: "#F9FAFB",
                borderRadius: "8px",
                position: "relative",
              }}
            >
              <input
                type="file"
                multiple
                onChange={handleFiles}
                style={{
                  opacity: 0.0,
                  position: "absolute",
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  width: "100%",
                  height: "100%",
                }}
              />
              <img
                alt="Logo"
                src={
                  require("assets/image-public/images/vector-upload-trans.png")
                    .default
                }
              />
              <h3
                style={{
                  fontWeight: 400,
                  fontSize: "14px",
                  lineHeight: "20px",
                  marginTop: "8px",
                }}
              >
                Upload Proof Of Payment, <strong>Search File</strong>{" "}
              </h3>
            </Grid>
          </Grid>
          <Grid
            style={{
              padding: "24px",
            }}
          >
            <h3>Document Attached</h3>
            {files.map((res, i) => {
              return (
                <Grid
                  key={i}
                  style={{
                    marginTop: "12px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <img
                    alt="Logo"
                    src={
                      require("assets/image-public/images/vector-doc-icon.png")
                        .default
                    }
                    style={{
                      width: "24px",
                      height: "24px",
                      objectFit: "contain",
                    }}
                  />
                  <Grid
                    style={{
                      fontWeight: 400,
                      fontSize: "14px",
                      width: "100%",
                      paddingLeft: "16px",
                    }}
                  >
                    <p
                      style={{
                        lineHeight: "20px",
                        margin: 0,
                        marginBottom: "4px",
                      }}
                    >
                      {res?.name}
                    </p>
                    <span
                      style={{
                        color: "#687083",
                        lineHeight: "8px",
                      }}
                    >
                      {formatFileSize(res?.size)}
                    </span>
                  </Grid>
                  <Grid
                    container
                    style={{
                      justifyContent: "flex-end",
                      alignItems: "center",
                      gap: "12px",
                    }}
                  >
                    { }
                    <img alt="Logo" src={PencilVectorGrey} />
                    <IconButton
                      onClick={() =>
                        setFiles([...files].filter(val => val !== res))
                      }
                    >
                      <img alt="LogoTrash" src={TrashVectorGrey} />
                    </IconButton>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
          <Grid
            style={{
              padding: "24px",
              borderTop: "1px solid #E4E7EB",
            }}
            container
            justifyContent="flex-end"
          >
            <Button
              onClick={() => {
                setModalPayment(false);
              }}
              className={classes.buttonWhite}
            >
              Cancel
            </Button>
            <Button onClick={uploadIns} className={classes.buttonBlack}>
              Upload
            </Button>
          </Grid>
        </CustomModal>
      )}
    </Page>
  );
};

export default DetailLoanPencipta;

const CustomChip = ({ status }) => {
  let label =
    status === "waiting_for_approval"
      ? "Waiting For Approval"
      : status === "approved"
        ? "Approved"
        : status === "rejected"
          ? "Rejected"
          : status === "paid"
            ? "Paid"
            : status === "active"
              ? "Active"
              : "";
  return (
    <Chip
      label={label}
      style={{
        border: `1px solid ${status === "waiting_for_approval"
            ? "#F6C962"
            : status === "approved" || status === "paid" || status === "active"
              ? "#8DE5AB"
              : status === "rejected"
                ? "#F1A69E"
                : ""
          }`,
        height: "28px",
        color:
          status === "waiting_for_approval"
            ? "#A84D20"
            : status === "approved" || status === "paid" || status === "active"
              ? "#34774C"
              : status === "rejected"
                ? "#A63124"
                : "",
        backgroundColor:
          status === "waiting_for_approval"
            ? "#FEF8E7"
            : status === "approved" || status === "paid" || status === "active"
              ? "#EFFCF4"
              : status === "rejected"
                ? "#FCF3F2"
                : "",
      }}
    />
  );
};
