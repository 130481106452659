import React from "react";
import BigLogoMpis from "../../../assets/img/newMpisLogo.webp";
import BigLogoCaris from "../../../assets/img/newCarisLogo.webp";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";


const useStyles = makeStyles(() => ({
  caris: {
    width: "1000px",
  },
}));

const SplashScreen = () => {
  const classes = useStyles();
  const typeWeb = localStorage.getItem("typeWeb") || "mpis";
  const isMpis = typeWeb === "mpis";
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100vh"
    >
      <img
        src={isMpis ? BigLogoMpis : BigLogoCaris}
        alt="logo"
        className={!isMpis ? classes?.caris : ""}
      />
      <CircularProgress />
    </Box>
  );
};

export default SplashScreen;
