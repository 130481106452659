import { Box, Button, Typography } from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import { ChevronLeftOutlined, ChevronRightOutlined } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import LandingFooter from "../../../components/templates/LandingPage/LandingFooter";
import LandingHero from "../../../components/templates/LandingPage/LandingHero";
import bgShade from "../../../assets/background/background_blog_detail_shade.webp";
import { blogList } from "./DummyBlog";
import { useHistory, useParams } from "react-router";

export const interFont = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const heroBackground = img => {
  return (
    <>
      <img
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          height: "100%",
          width: "100%",
          objectFit: "cover",
        }}
        alt="background-image"
        src={img}
      />
      <img
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          height: "100%",
          width: "100%",
          objectFit: "cover",
        }}
        alt="background-shade"
        src={bgShade}
      />
    </>
  );
};

const DetailBlog = () => {
  const history = useHistory();
  const { id } = useParams();

  const [contentBlog, setContentBlog] = useState();
  const [prevDisable, setPrevDisable] = useState(false);
  const [nextDisable, setNextDisable] = useState(false);

  useEffect(() => {
    const detailContent = blogList.find(item => item?.id === parseInt(id));
    setContentBlog(detailContent);

    setNextDisable(blogList[blogList.length - 1]?.id === parseInt(id));
    setPrevDisable(blogList[0]?.id === parseInt(id));
  }, [id]);

  const handlePrev = () => {
    const currentIndex = blogList.findIndex(item => item?.id === parseInt(id));
    const prevIndex = currentIndex - 1;

    if (prevIndex >= 0) {
      const prevContent = blogList[prevIndex];
      setContentBlog(prevContent);
      setPrevDisable(prevIndex === 0);
      setNextDisable(false);
      history.push(`/blog/${prevContent.id}`);
    }
  };

  const handleNext = () => {
    const currentIndex = blogList.findIndex(item => item?.id === parseInt(id));
    const nextIndex = currentIndex + 1;

    if (nextIndex < blogList.length) {
      const nextContent = blogList[nextIndex];
      setContentBlog(nextContent);
      setNextDisable(nextIndex === blogList.length - 1);
      setPrevDisable(false);

      history.push(`/blog/${nextContent.id}`);
    }
  };

  return (
    <>
      <LandingHero
        heroBackground={() => heroBackground(contentBlog?.image)}
        heroContent={() => <></>}
        type="blog-detail"
      />

      <div
        id="section-2"
        style={{
          backgroundColor: "#F9FAFB",
          marginBottom: "32px",
        }}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          gridGap={"24px"}
          padding={"64px 120px"}
        >
          <Box>
            <Typography
              style={{
                fontSize: "24px",
                fontWeight: 700,
                lineHeight: "33.6px",
                color: "#E80088",
                textAlign: "justify",
              }}
            >
              {contentBlog?.title}
            </Typography>
          </Box>
          <Box>
            <Typography
              style={{
                fontSize: "16px",
                fontWeight: 400,
                lineHeight: "22.4px",
                color: "#652D8D",
              }}
            >
              {contentBlog?.content}
            </Typography>
          </Box>
          <Box display={"flex"} justifyContent={"end"} gridGap={"16px"}>
            <Box display={"flex"} flexDirection={"row"} gridGap={"10px"}>
              <Button
                variant="text"
                className="header-button-action"
                disabled={prevDisable}
                onClick={handlePrev}
              >
                <ChevronLeftOutlined />
                <Typography>Previous Article</Typography>
              </Button>
            </Box>
            <Box display={"flex"} flexDirection={"row"} gridGap={"10px"}>
              <Button
                variant="text"
                className="header-button-action"
                disabled={nextDisable}
                onClick={handleNext}
              >
                <Typography>Next Article</Typography>
                <ChevronRightOutlined />
              </Button>
            </Box>
          </Box>
        </Box>
      </div>

      <LandingFooter captions="Solutions for the music industry to transparent royalty system" />
    </>
  );
};

export default DetailBlog;
