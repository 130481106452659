import {
  Breadcrumbs,
  Card,
  CardContent,
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { makeStyles } from "@material-ui/styles";
import { useLocation } from "react-router-dom";
import { Page } from "components";

const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const DetailPublisher = props => {
  const { state } = useLocation();

  const tableData = [state.lagu];
  const isrc = "-";
  const getColor = "#000";
  const penciptaList = [state.lagu.composer_names];

  const formatter2 = new Intl.NumberFormat("id-ID", {
    minimumFractionDigits: 0,
  });

  const useStyles = makeStyles(theme => ({
    root: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    divider: {
      margin: theme.spacing(2, 0),
      borderTop: "1px solid #e1e1e1",
    },
    card: {
      borderLeft: "1px solid gray",
      boxShadow: "none",
      borderRadius: "0px",
    },
    insideCard: {
      padding: "20px",

      wordWrap: "break-word",
    },
    cardArea: {
      marginTop: "20px",
      marginBottom: "20px",
      borderRadius: "0px",
    },
    publisherMargin: {
      marginTop: "40px",
    },
    infoArea: {
      marginTop: "20px",
      marginBottom: "20px",
    },
    label: {
      fontSize: "16px",
      fontWeight: 500,
      color: "#364052",
    },
    labelValue: {
      fontSize: "16px",
      fontWeight: 400,
      color: "#111827",
    },
    headText: {
      fontSize: "12px",
      color: "#687083",
      fontWeight: "bold",
    },
    bodyTextBlack: {
      fontSize: "14px",
      fontWeight: "regular",
      color: "#111827",
      fontStyle: "normal",
    },
    bodyTextGrey: {
      fontSize: "14px",
      fontWeight: "regular",
      color: "#687083",
      fontStyle: "normal",
    },
    tableRow: {
      height: 20,
    },
    conTextBlack: {
      fontSize: "14px",
      fontWeight: "bold",
    },
    dropdown: {
      color: "black",
    },
    subtitle: {
      fontSize: "18px",
      fontWeight: "bold",
      lineHeight: "28px",
    },
    reportText: {
      fontSize: "14px",
      fontWeight: 500,
      color: "#687083",
      marginBottom: "15px",

      width: "300px",
    },
    reportValue: {
      fontSize: "24px",
      fontWeight: 500,
      color: "#111827",
    },
    breadcrumbs: {
      fontSize: "14px",
      fontWeight: 400,
      fontStyle: "regular",
      color: "#111827",
    },
    breadCrumbsActive: {
      fontSize: "14px",
      fontWeight: 400,
      fontStyle: "regular",
      color: "#687083",
    },
    table: {
      marginTop: "20px",
      border: "1px solid #D1D5DC",
      borderRadius: "6px",
    },
    thead: {
      "& th:first-child": {
        borderRadius: "6px",
      },
      "& th:last-child": {
        borderRadius: "6px",
      },
    },
    pagination: {
      "& .Mui-selected": {
        backgroundColor: getColor,
        color: "white",
      },
    },
    pageSize: {
      height: "30px",
      borderRadius: "6px",
      border: "1px solid #D1D5DC",
      paddingLeft: "5px",
      paddingRight: "5px",
      marginTop: "5px",
    },
    paginationText: {
      fontSize: "14px",
      fontWeight: "normal",
      fontStyle: "normal",
      color: "#687083",
    },
    hoverText: {
      color: "#111827",
      "&:hover": {
        color: "#9545EB",
      },
    },
  }));

  const classes = useStyles();
  const handleBack = () => {
    props.history.goBack();
  };
  return (
    <Page className={classes.root} title="Review Penggunaan Lagu Pencipta">
      <Container maxWidth={false}>
        <Grid
          alignItems="flex-end"
          container
          justifyContent="space-between"
          spacing={3}
        >
          <Grid item>
            <ThemeProvider theme={theme}>
              <Typography variant="h5">
                Song Title:{" "}
                <span
                  style={{
                    fontWeight: 700,
                  }}
                >
                  {state.lagu.song.song_title}
                </span>
              </Typography>
              <Typography
                style={{
                  fontSize: "18px",
                  fontWeight: "400",
                  lineHeight: "28px",
                }}
              >
                Year of Song Usage:{" "}
                <span>
                  {}
                  {state.year}
                </span>
              </Typography>
            </ThemeProvider>
          </Grid>
          <Grid item>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="textPrimary" className={classes.breadCrumbs}>
                Home
              </Typography>
              <Typography color="textPrimary">
                Analysis of Song Usage
              </Typography>
              <Typography color="textPrimary">Song Details</Typography>
              <Typography
                color="textPrimary"
                className={classes.breadCrumbsActive}
              >
                Publisher Details
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>

        <div className={classes.infoArea}>
          <Grid container lg={12} xl={12}>
            <Card
              style={{
                width: "100%",
                border: "1px solid #D1D5DC",
                borderRadius: "6px",
                height: "120px",
                boxShadow: "none",
              }}
            >
              <CardContent>
                <ThemeProvider theme={theme}>
                  <Grid container spacing={1} lg={12} xl={12}>
                    <Grid item lg={2} xl={2}>
                      <Typography variant="subtitle2" className={classes.label}>
                        ISRC Code
                      </Typography>
                    </Grid>
                    <Grid item lg={10} xl={10}>
                      <Typography
                        variant="subtitle2"
                        className={classes.labelValue}
                      >
                        {isrc}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container spacing={1} lg={12} xl={12}>
                    <Grid item lg={2} xl={2}>
                      <Typography variant="subtitle2" className={classes.label}>
                        Composer
                      </Typography>
                    </Grid>
                    <Grid item lg={10} xl={10}>
                      <Typography
                        variant="subtitle2"
                        className={classes.labelValue}
                      >
                        {penciptaList.join(", ")}
                      </Typography>
                    </Grid>
                  </Grid>
                </ThemeProvider>
              </CardContent>
            </Card>
          </Grid>
        </div>

        <div>
          <div
            className={classes.hoverText}
            style={{
              display: "flex",
              cursor: "pointer",
            }}
            onClick={handleBack}
          >
            <ArrowBackIosIcon
              style={{
                fontSize: "14px",
                marginTop: "4px",
              }}
            />
            <ThemeProvider theme={theme}>
              <Typography
                style={{
                  fontSize: "14px",
                }}
              >
                Back
              </Typography>
            </ThemeProvider>
          </div>

          <div>
            <ThemeProvider theme={theme}>
              <Typography
                style={{
                  fontSize: "18px",
                  fontWeight: "700",
                  color: "#111827",
                }}
              >
                {state.lagu.publisher}
              </Typography>
            </ThemeProvider>
          </div>
        </div>

        <Grid item xs={12} md={12} sm={12} lg={12} xl={12}>
          <Table size="small" className={classes.table}>
            <TableHead>
              <TableRow>
                <ThemeProvider theme={theme}>
                  <TableCell>
                    <Typography
                      variant="subtitle2"
                      className={classes.headText}
                    >
                      DSP
                    </Typography>
                  </TableCell>

                  <TableCell>
                    <Typography
                      variant="subtitle2"
                      className={classes.headText}
                    >
                      Listener Traffic
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="subtitle2"
                      className={classes.headText}
                      align="right"
                    >
                      Revenue
                    </Typography>
                  </TableCell>
                </ThemeProvider>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData[0].song_usages[0].detail.length > 0 ? (
                tableData[0].song_usages[0].detail.map((lagu, index) => (
                  <TableRow hover key={index}>
                    <ThemeProvider theme={theme}>
                      <TableCell>
                        <Typography
                          variant="subtitle2"
                          className={classes.bodyTextBlack}
                        >
                          {lagu.dsp_name}
                        </Typography>
                      </TableCell>

                      <TableCell>
                        <Typography
                          variant="subtitle2"
                          className={classes.bodyTextBlack}
                        >
                          {formatter2.format(lagu.listener)}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography
                          variant="subtitle2"
                          className={classes.bodyTextBlack}
                        >
                          {lagu.royalty}
                        </Typography>
                      </TableCell>
                    </ThemeProvider>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={5} style={{ height: "50px" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <ThemeProvider theme={theme}>
                        <Typography
                          component="span"
                          style={{
                            fontSize: "14px",
                            lineHeight: "20px",
                            fontWeight: "400",
                            fontStyle: "normal",
                            color: "#687083",
                          }}
                        >
                          Data not found
                        </Typography>
                      </ThemeProvider>
                    </div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          {}
        </Grid>
      </Container>
    </Page>
  );
};

export default DetailPublisher;
