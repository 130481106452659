import {
  Backdrop,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import Pagination from "@material-ui/lab/Pagination";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link as RouterLink } from "react-router-dom";
import TableEditBar from "../../../components/molecules/Table/TableEditBar";
import { hardBaseUrl } from "../../../services/urlConstant";

const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

function Results({
  className,
  pageSizePagination,
  page,
  totalData,
  handleChangePage,
  items,
  isLoading,
  ...rest
}) {
  const selectedCustomers = [];

  const [getColor, setGetColor] = useState("");

  useEffect(() => {
    const getMainDsp = async () => {
      try {
        const token = localStorage.getItem("token");
        const url = `${hardBaseUrl}/me`;
        const res = await axios.get(url, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        setGetColor(res.data.data.publisher.theme_color);
      } catch (e) {}
    };
    getMainDsp();
  }, []);

  const useStyles = makeStyles(theme => ({
    root: {},
    content: {
      padding: 0,
    },
    inner: {
      minWidth: 700,
    },
    nameCell: {
      display: "flex",
      alignItems: "center",
    },
    avatar: {
      height: 42,
      width: 42,
      marginRight: theme.spacing(1),
    },
    actions: {
      padding: theme.spacing(1),
      justifyContent: "flex-end",
    },
    pagination: {
      "& .Mui-selected": {
        backgroundColor: getColor,
        color: "white",
      },
    },
    pageSize: {
      height: "30px",
      borderRadius: "6px",
      border: "1px solid #D1D5DC",
      paddingLeft: "5px",
      paddingRight: "5px",
      marginTop: "5px",
    },
    paginationText: {
      fontSize: "14px",
      fontWeight: "normal",
      fontStyle: "normal",
      color: "#687083",
    },
    headText: {
      fontSize: "12px",
      color: "#687083",
      fontWeight: "bold",
    },
    bodyTextBlack: {
      fontSize: "14px",
      fontWeight: "regular",
      color: "#111827",
    },
    bodyTextGrey: {
      fontSize: "14px",
      fontWeight: "regular",
      color: "#687083",
    },
    tableRow: {
      height: 20,
    },
    conTextBlack: {
      fontSize: "14px",
      fontWeight: "bold",
    },
    dropdown: {
      color: "black",
    },
    activeChip: {
      backgroundColor: "#8DE5AB",
      color: "#34774C",
      fontSize: "14px",
      fontWeight: "500",
    },
    activeChipIndicator: {
      color: "#34774C",
      fontSize: "12px",
    },
    nonActiveChip: {
      backgroundColor: "#D1D5DC",
      color: "#364052",
      fontSize: "14px",
      fontWeight: "500",
    },
    nonActiveChipIndicator: {
      color: "#364052",
      fontSize: "12px",
    },
    tableChip: {
      marginRight: "5px",
      backgroundColor: "#F9FAFB",
      border: "1px solid #D1D5DC",
    },
    btnDetail: {
      backgroundColor: "#111827",
      color: "white",
      width: "30px",
      height: "30px",
      padding: "6px",
      borderRadius: "6px",
    },
  }));

  const classes = useStyles();
  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Card
        style={{
          position: "relative",

          zIndex: 0,
        }}
      >
        <Backdrop
          style={{ color: "#fff", zIndex: 100, position: "absolute" }}
          open={isLoading}
        >
          <CircularProgress />
        </Backdrop>
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <ThemeProvider theme={theme}>
                      <TableCell>
                        <Typography
                          variant="subtitle2"
                          className={classes.headText}
                        >
                          Action
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="subtitle2"
                          className={classes.headText}
                        >
                          Create Date
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="subtitle2"
                          className={classes.headText}
                        >
                          Period
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="subtitle2"
                          className={classes.headText}
                        >
                          Paid Value
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="subtitle2"
                          className={classes.headText}
                        >
                          Status
                        </Typography>
                      </TableCell>
                    </ThemeProvider>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {items.length > 0 ? (
                    items.map((customer, index) => {
                      return (
                        <TableRow
                          hover
                          key={`${index}-${customer.loan_id}`}
                          selected={
                            selectedCustomers.indexOf(customer.loan_id) !== -1
                          }
                        >
                          <ThemeProvider theme={theme}>
                            <TableCell>
                              <Tooltip title={"Update"} placement="bottom">
                                <IconButton
                                  className={classes.btnDetail}
                                  style={{
                                    backgroundColor: getColor,
                                  }}
                                >
                                  <RouterLink
                                    to={{
                                      pathname: `/admin/payment/${customer.publisher_transaction_id}`,
                                      state: {
                                        customer: customer,
                                      },
                                    }}
                                  >
                                    <VisibilityOutlinedIcon
                                      style={{
                                        filter: " brightness(0) invert(1)",
                                        marginTop: "10px",
                                      }}
                                    />
                                  </RouterLink>
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                            <TableCell>
                              <Typography
                                variant="subtitle2"
                                className={classes.bodyTextBlack}
                              >
                                {customer?.composer_name}
                              </Typography>
                            </TableCell>
                            <TableCell width={"17%"}>
                              <Typography
                                variant="subtitle2"
                                className={classes.bodyTextBlack}
                              >
                                {customer?.invoice_date}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography
                                variant="subtitle2"
                                className={classes.bodyTextBlack}
                              >
                                {customer.revenue}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography
                                variant="subtitle2"
                                className={classes.bodyTextBlack}
                              >
                                {customer?.status === "waiting_for_approval"
                                  ? "Waiting for Approval"
                                  : customer?.status === "approved"
                                  ? "Approved"
                                  : customer?.status === "rejected"
                                  ? "Rejected"
                                  : customer?.status === "paid"
                                  ? "Paid"
                                  : customer?.status}
                              </Typography>
                            </TableCell>
                          </ThemeProvider>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={6}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <Typography variant="subtitle1">
                            No data available
                          </Typography>
                        </div>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <Grid
            alignContent="space-between"
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <div style={{ display: "flex" }}>
                <div style={{ marginTop: "7px" }}>
                  <ThemeProvider theme={theme}>
                    <Typography className={classes.paginationText}>
                      Items per page:
                    </Typography>
                  </ThemeProvider>
                </div>{" "}
                <div style={{ marginLeft: "10px" }}>{pageSizePagination}</div>
              </div>
            </Grid>

            <Grid item>
              <Pagination
                count={totalData}
                shape="rounded"
                className={classes.pagination}
                page={page}
                onChange={handleChangePage}
              />
            </Grid>
          </Grid>
        </CardActions>
      </Card>
      <TableEditBar selected={selectedCustomers} />
    </div>
  );
}

Results.propTypes = {
  className: PropTypes.string,
  customers: PropTypes.array,
};

Results.defaultProps = {
  customers: [],
};

export default Results;
