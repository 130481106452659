import {
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  Menu,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { useHistory } from "react-router";
import Swal from "sweetalert2";
import datePicIcon from "../../../../assets/img/date-picker.png";
import PencilVectorGrey from "../../../../assets/img/pencilVectorGrey.svg";
import TrashVectorGrey from "../../../../assets/img/trashVectorGrey.svg";
import CurrencyInput from "../../../../components/atoms/Input/CurrencyInput";
import { Page } from "components";

import { KeyboardDatePicker } from "@material-ui/pickers";
import { hardBaseUrl } from "../../../../services/urlConstant";

const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  results: {
    marginTop: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  formControl: {
    width: "100%",
  },
  mypadding: {
    "& .MuiSelect-outlined": {
      paddingTop: "10.5px",
      paddingBottom: "10.5px",
    },
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  pagination: {
    "& .Mui-selected": {
      backgroundColor: "black",
      color: "white",
    },
  },
  pageSize: {
    height: "30px",
    borderRadius: "6px",
    border: "1px solid #D1D5DC",
    paddingLeft: "5px",
    paddingRight: "5px",
    marginTop: "5px",
  },
  paginationText: {
    fontSize: "14px",
    fontWeight: "normal",
    fontStyle: "normal",
    color: "#687083",
  },
  breadcrumbs: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#111827",
  },
  breadCrumbsActive: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#687083",
  },
  placeholder: {
    "&::placeholder": {
      color: "#9AA2B1",
    },
  },
  title: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "28px",
  },
  label: {
    color: "#364052",
    fontSize: "14px",
    fontWeight: 500,
  },
  subTitle: {
    fontSize: "18px",
    fontWeight: 700,
    color: " #111827",
  },
  myinput: {
    "& .MuiOutlinedInput-input": {
      paddingTop: "11px",
      paddingLeft: "12px",
      paddingRight: "12px",
      paddingBottom: "11px",
    },
  },
}));

const AddAdvanced = () => {
  const classes = useStyles();
  const history = useHistory();
  const [items] = useState([]);
  const [penciptaList, setPenciptaList] = useState([]);
  const [typeAdv, setTypeAdv] = useState("none");
  const [numberTrans, setNumberTrans] = useState("");
  const [client, setClient] = useState("");
  const [revenue, setRevenue] = useState(0);
  const [month1, setMonth1] = useState("");
  const [month2, setMonth2] = useState("");
  const [year1, setYear1] = useState("");
  const [year2, setYear2] = useState("");
  const [yearList, setYearList] = useState([]);
  const [openInvoiceDate, setOpenInvoiceDate] = useState(false);
  const [theDate, setTheDate] = useState(moment().toString());
  const monthList = [
    {
      value: "01",
      name: "January",
    },
    {
      value: "02",
      name: "February",
    },
    {
      value: "03",
      name: "March",
    },
    {
      value: "04",
      name: "April",
    },
    {
      value: "05",
      name: "May",
    },
    {
      value: "06",
      name: "June",
    },
    {
      value: "07",
      name: "July",
    },
    {
      value: "08",
      name: "August",
    },
    {
      value: "09",
      name: "September",
    },
    {
      value: "10",
      name: "October",
    },
    {
      value: "11",
      name: "November",
    },
    {
      value: "12",
      name: "December",
    },
  ];
  useEffect(() => {
    const getPenciptaList = async () => {
      try {
        const url = hardBaseUrl + "/publisher/composer";
        const token = localStorage.getItem("token");
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        const response = await axios.get(url, config);
        setPenciptaList(response.data.data);
      } catch (error) {
        new Error(error);
      }
    };
    getPenciptaList();
  }, []);

  useEffect(() => {
    const thisYear = moment().year();
    for (let i = thisYear; i >= 2000; i--) {
      setYearList(yearList => [...yearList, i]);
    }
  }, [month1, month2, year1, year2]);

  const changeType = async item => {
    try {
      let theToken = localStorage.getItem("token");
      let baseOfUrl = hardBaseUrl;
      const url =
        baseOfUrl +
        "/publisher/transaction-number/payment?type=" +
        item.target.value;
      const options = {
        headers: {
          Authorization: "Bearer " + theToken,
        },
      };
      const response = await axios.get(url, options);
      setTypeAdv(item.target.value);
      setNumberTrans(response.data.data);
    } catch (error) {
      new Error(error);
    }
  };
  const changeClient = async item => {
    setClient(item.target.value);
  };
  const changeRevenue = async item => {
    setRevenue(item.target.value);
  };
  const clickSave = async () => {
    const revenueData = revenue.replaceAll("Rp. ", "");
    const newRevenue = revenueData.replaceAll(".", "");
    const token = localStorage.getItem("token");
    const url = `${hardBaseUrl}/publisher/transaction`;
    let payload = {
      transaction_name: "advanced",
      transaction_number: numberTrans,
      period: `${month1}-${year1} - ${month2}-${year2}`,
      type: typeAdv,
      date: theDate,
      revenue: Number(newRevenue),
      song_id: [],
    };
    try {
      axios
        .post(url, payload, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          Swal.fire({
            title: "Success",
            text: "Advanced has been added",
            icon: "success",
            confirmButtonText: "Ok",
          }).then(result => {
            if (result.isConfirmed) {
              history.push("/admin/advance");
            } else if (result.isDenied) {
              Swal.fire("Changes are not saved", "", "info");
            }
          });
          Swal.fire({
            title: "Do you want to save the changes?",
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: "Save",
            denyButtonText: "Don't save",
          }).then(result => {
            if (result.isConfirmed) {
              Swal.fire("Saved!", "", "success");
            } else if (result.isDenied) {
              Swal.fire("Changes are not saved", "", "info");
            }
          });
        })
        .catch(err => {
          new Error(err);
        });
    } catch (error) {
      new Error(error);
    }
  };
  const clickOpenInvoiceDate = () => {
    setOpenInvoiceDate(true);
  };
  const handleCloseMenuInvoiceDate = () => {
    setOpenInvoiceDate(false);
  };
  const handleChangeStartDate = event => {
    event.persist();
    const { value, name } = event.target;

    if (name === "month1") {
      setMonth1(value);
    } else if (name === "year1") {
      setYear1(value);
    }
  };
  const handleChangeEndDate = event => {
    event.persist();
    const { value, name } = event.target;

    if (name === "month2") {
      if (year1 === year2) {
        if (month1 > month2) {
          setMonth2(month1);
          setMonth1(value);
        } else {
          setMonth2(value);
        }
      } else {
        setMonth2(value);
      }
    } else if (name === "year2") {
      if (year1 > year2) {
        setYear2(year1);
        setYear1(value);
      } else {
        setYear2(value);
      }
    }
  };
  const handleDateChange = async item => {
    let date = item._d;
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    let aaa = [year, month, day].join("-");
    setTheDate(aaa);
  };
  return (
    <Page className={classes.root} title="Advance Transaction Add">
      <Container maxWidth={false}>
        <Grid
          alignItems="flex-end"
          container
          justifyContent="space-between"
          spacing={3}
        >
          <Grid item>
            <ThemeProvider theme={theme}>
              <Typography
                style={{
                  fontSize: "24px",
                  fontWeight: "700",
                  lineHeight: "32px",
                }}
              >
                Advance Transaction
              </Typography>
            </ThemeProvider>
          </Grid>
          <Grid item>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography className={classes.breadCrumbs}>Home</Typography>

              <Typography className={classes.breadCrumbs}>
                Transaction
              </Typography>

              <Typography className={classes.breadCrumbsActive}>
                Create Transaction
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>

        <Divider className={classes.divider} />

        <Grid container>
          <div>
            <ThemeProvider theme={theme}>
              <p
                style={{
                  marginTop: "5px",
                  color: "#8f8f8f",
                  fontSize: "14px",
                }}
              >
                The pain itself is love, the main storage system. The mass is
                just a means to achieve this, no torture.
              </p>
            </ThemeProvider>
          </div>
        </Grid>
        <Grid container>
          <div>
            <span
              style={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "14px",
                lineHeight: "20px",
                color: "#364052",
              }}
            >
              Client Name
            </span>
          </div>
        </Grid>
        <Grid container>
          <div
            style={{
              marginTop: "6px",
              marginBottom: "16px",
            }}
          >
            <FormControl variant="outlined" className={classes.formControl}>
              {client === "" ? (
                <>
                  <InputLabel
                    id="demo-simple-select-outlined-label"
                    shrink={false}
                    style={{
                      marginTop: "-5px",
                    }}
                  >
                    {"All Client"}
                  </InputLabel>
                </>
              ) : (
                <>{""}</>
              )}
              <Select
                onChange={changeClient}
                style={{
                  width: "340px",
                  height: "44px",
                  border: "0px solid #D1D5DC",
                  borderRadius: "6px",
                }}
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={client}
                autoWidth={true}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {penciptaList.map(item => (
                  <MenuItem value={item.composer_id} key={item.composer_id}>
                    {item.alias_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </Grid>

        <Grid container>
          <Grid item xs={12} md={8} lg={8} xl={8}>
            <Card
              style={{
                border: "1px solid #D1D5DC",
                borderRadius: "6px",
                boxShadow: "none",
              }}
            >
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6} lg={6} xl={6}>
                    <div
                      style={{
                        marginTop: "5px",
                        marginBottom: "5px",
                      }}
                    >
                      <div
                        style={{
                          marginTop: "10px",
                          marginBottom: "3px",
                        }}
                      >
                        <ThemeProvider theme={theme}>
                          <Typography
                            variant="subtitle2"
                            className={classes.label}
                          >
                            Date
                          </Typography>
                        </ThemeProvider>
                      </div>
                      <div
                        style={{
                          marginTop: "-10px",
                          marginBottom: "10px",
                        }}
                      >
                        <KeyboardDatePicker
                          id="outlined-basic"
                          variant="outlined"
                          onChange={date => handleDateChange(date)}
                          value={theDate}
                          name="startDate"
                          format="YYYY-MM-DD"
                          minDate={new Date()}
                          inputVariant="outlined"
                          fullWidth={true}
                          margin="dense"
                        />
                      </div>

                      <div
                        style={{
                          marginTop: "10px",
                          marginBottom: "3px",
                        }}
                      >
                        <ThemeProvider theme={theme}>
                          <Typography
                            variant="subtitle2"
                            className={classes.label}
                          >
                            Period
                          </Typography>
                        </ThemeProvider>
                      </div>
                      <div
                        style={{
                          marginTop: "-2px",
                          marginBottom: "10px",
                        }}
                      >
                        <FormControl
                          fullWidth
                          className={classes.margin}
                          variant="outlined"
                        >
                          <React.Fragment>
                            <OutlinedInput
                              variant="outlined"
                              aria-controls="invoice-period"
                              aria-haspopup="true"
                              size="small"
                              value={`${month1}-${year1} - ${month2}-${year2}`}
                              onClick={clickOpenInvoiceDate}
                              disabled
                              className={classes.mypadding}
                              style={{ height: "39px" }}
                              endAdornment={
                                <InputAdornment position="end">
                                  <div>
                                    <img
                                      src={datePicIcon}
                                      alt="filterperiode"
                                      style={{
                                        width: "20px",
                                        height: "20px",
                                      }}
                                      onClick={clickOpenInvoiceDate}
                                    />
                                  </div>
                                </InputAdornment>
                              }
                            />

                            <Menu
                              id="invoice-period"
                              anchorEl={openInvoiceDate}
                              keepMounted={false}
                              style={{ cursor: "pointer" }}
                              open={Boolean(openInvoiceDate)}
                              onClose={handleCloseMenuInvoiceDate}
                              PaperProps={{
                                style: {
                                  transform:
                                    "translateX(700px) translateY(250px)",
                                },
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <Container style={{ width: "300px" }}>
                                  <div
                                    style={{
                                      marginTop: "10px",
                                      marginBottom: "10px",
                                    }}
                                  >
                                    <ThemeProvider theme={theme}>
                                      <Typography className={classes.label}>
                                        Start Period
                                      </Typography>
                                    </ThemeProvider>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        marginTop: "10px",
                                      }}
                                    >
                                      <div>
                                        <ThemeProvider theme={theme}>
                                          <Typography className={classes.label}>
                                            Month
                                          </Typography>
                                        </ThemeProvider>
                                        <FormControl
                                          variant="outlined"
                                          fullWidth={true}
                                        >
                                          <InputLabel
                                            id="demo-simple-select-outlined-label"
                                            shrink={false}
                                            style={{
                                              marginRight: "-5px",
                                              marginTop: "-8px",
                                            }}
                                          >
                                            {month1 !== "" ? null : "Month"}
                                          </InputLabel>
                                          <Select
                                            className={classes.mypadding}
                                            id="demo-simple-select-outlined"
                                            name="month1"
                                            value={month1}
                                            onChange={e =>
                                              handleChangeStartDate(e)
                                            }
                                            autoWidth={true}
                                            style={{
                                              paddingTop: "0px",
                                              paddingRight: "0px",
                                              paddingBottom: "0px",
                                              paddingLeft: "0px",
                                              width: "120px",
                                            }}
                                          >
                                            <MenuItem value="">
                                              <em>None</em>
                                            </MenuItem>
                                            {monthList.map((item, idx) => (
                                              <MenuItem
                                                value={item.value}
                                                key={idx}
                                              >
                                                {item.name}
                                              </MenuItem>
                                            ))}
                                          </Select>
                                        </FormControl>
                                      </div>
                                      <div>
                                        <ThemeProvider theme={theme}>
                                          <Typography className={classes.label}>
                                            Year
                                          </Typography>
                                        </ThemeProvider>
                                        <FormControl
                                          variant="outlined"
                                          fullWidth={true}
                                        >
                                          <InputLabel
                                            id="demo-simple-select-outlined-label"
                                            shrink={false}
                                            style={{
                                              marginRight: "-5px",
                                              marginTop: "-8px",
                                            }}
                                          >
                                            {year1 !== "" ? null : "Year"}
                                          </InputLabel>
                                          <Select
                                            className={classes.mypadding}
                                            id="demo-simple-select-outlined"
                                            name="year1"
                                            value={year1}
                                            onChange={e =>
                                              handleChangeStartDate(e)
                                            }
                                            autoWidth={true}
                                            style={{
                                              paddingTop: "0px",
                                              paddingRight: "0px",
                                              paddingBottom: "0px",
                                              paddingLeft: "0px",
                                              width: "120px",
                                            }}
                                          >
                                            <MenuItem value="">
                                              <em>None</em>
                                            </MenuItem>
                                            {yearList.map((item, idx) => (
                                              <MenuItem value={item} key={idx}>
                                                {item}
                                              </MenuItem>
                                            ))}
                                          </Select>
                                        </FormControl>
                                      </div>
                                    </div>
                                  </div>

                                  <div>
                                    <ThemeProvider theme={theme}>
                                      <Typography className={classes.label}>
                                        End Period
                                      </Typography>
                                    </ThemeProvider>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        marginTop: "10px",
                                        marginBottom: "10px",
                                      }}
                                    >
                                      <div>
                                        <ThemeProvider theme={theme}>
                                          <Typography className={classes.label}>
                                            Month
                                          </Typography>
                                        </ThemeProvider>
                                        <FormControl
                                          variant="outlined"
                                          fullWidth={true}
                                        >
                                          <InputLabel
                                            id="demo-simple-select-outlined-label"
                                            shrink={false}
                                            style={{
                                              marginRight: "-5px",
                                              marginTop: "-8px",
                                            }}
                                          >
                                            {month2 !== "" ? null : "Month"}
                                          </InputLabel>
                                          <Select
                                            className={classes.mypadding}
                                            id="demo-simple-select-outlined"
                                            name="month2"
                                            value={month2}
                                            onChange={e =>
                                              handleChangeEndDate(e)
                                            }
                                            autoWidth={true}
                                            style={{
                                              paddingTop: "0px",
                                              paddingRight: "0px",
                                              paddingBottom: "0px",
                                              paddingLeft: "0px",
                                              width: "120px",
                                            }}
                                          >
                                            <MenuItem value="">
                                              <em>None</em>
                                            </MenuItem>
                                            {monthList.map((item, idx) => (
                                              <MenuItem
                                                value={item.value}
                                                key={idx}
                                              >
                                                {item.name}
                                              </MenuItem>
                                            ))}
                                          </Select>
                                        </FormControl>
                                      </div>
                                      <div>
                                        <ThemeProvider theme={theme}>
                                          <Typography className={classes.label}>
                                            Year
                                          </Typography>
                                        </ThemeProvider>
                                        <FormControl
                                          variant="outlined"
                                          fullWidth={true}
                                        >
                                          <InputLabel
                                            id="demo-simple-select-outlined-label"
                                            shrink={false}
                                            style={{
                                              marginRight: "-5px",
                                              marginTop: "-8px",
                                            }}
                                          >
                                            {year2 !== "" ? null : "Year"}
                                          </InputLabel>
                                          <Select
                                            className={classes.mypadding}
                                            id="demo-simple-select-outlined"
                                            name="year2"
                                            value={year2}
                                            onChange={e =>
                                              handleChangeEndDate(e)
                                            }
                                            autoWidth={true}
                                            style={{
                                              paddingTop: "0px",
                                              paddingRight: "0px",
                                              paddingBottom: "0px",
                                              paddingLeft: "0px",
                                              width: "120px",
                                            }}
                                          >
                                            <MenuItem value="">
                                              <em>None</em>
                                            </MenuItem>
                                            {yearList.map((item, idx) => (
                                              <MenuItem value={item} key={idx}>
                                                {item}
                                              </MenuItem>
                                            ))}
                                          </Select>
                                        </FormControl>
                                      </div>
                                    </div>
                                  </div>
                                </Container>
                              </div>
                            </Menu>
                          </React.Fragment>
                        </FormControl>
                      </div>

                      <div
                        style={{
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        <ThemeProvider theme={theme}>
                          <Typography className={classes.label}>
                            Revenue Value
                          </Typography>
                        </ThemeProvider>
                      </div>
                      <div
                        style={{
                          marginTop: "-10px",
                          marginBottom: "10px",
                        }}
                      >
                        <CurrencyInput
                          placeholder="Rp 5.000.000"
                          type="text"
                          onChange={changeRevenue}
                        />
                      </div>
                    </div>
                  </Grid>

                  <Grid item xs={12} md={6} lg={6} xl={6}>
                    <div
                      style={{
                        marginTop: "5px",
                        marginBottom: "5px",
                      }}
                    >
                      <div
                        style={{
                          marginTop: "10px",
                          marginBottom: "2px",
                        }}
                      >
                        <ThemeProvider theme={theme}>
                          <Typography className={classes.label}>
                            Type
                          </Typography>
                        </ThemeProvider>
                      </div>
                      <div>
                        <FormControl
                          variant="outlined"
                          className={classes.formControl}
                        >
                          <Select
                            style={{
                              height: "40px",
                              border: "0px solid #D1D5DC",
                              borderRadius: "6px",
                            }}
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={typeAdv}
                            autoWidth={true}
                            onChange={changeType}
                          >
                            <MenuItem value={"none"}>None</MenuItem>
                            <MenuItem value={"recoupment"}>Recoupment</MenuItem>
                            <MenuItem value={"flat"}>Flat</MenuItem>
                          </Select>
                        </FormControl>
                      </div>

                      <div
                        style={{
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        <ThemeProvider theme={theme}>
                          <Typography className={classes.label}>
                            Transaction Number
                          </Typography>
                        </ThemeProvider>
                      </div>
                      <div
                        style={{
                          marginTop: "-6px",
                          marginBottom: "10px",
                        }}
                      >
                        <OutlinedInput
                          id="outlined-adornment-amount"
                          fullWidth={true}
                          name="phone"
                          type="text"
                          placeholder="ADV/2020/33/S"
                          className={classes.tlpnField}
                          margin="dense"
                          value={numberTrans}
                        />
                      </div>
                    </div>
                  </Grid>
                </Grid>
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <span>Song Detail</span>
                  </Grid>
                  <Grid item>
                    <Button
                      style={{
                        backgroundColor: "black",
                        borderRadius: "6px",
                        color: "#FFFFFF",
                        textTransform: "none",
                      }}
                    >
                      Add Song
                    </Button>
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid item xs={12} md={12} lg={12} xl={12}>
                    <div
                      style={{
                        marginTop: "16px",
                      }}
                    >
                      <TableContainer
                        component={Paper}
                        className={classes.inner}
                      >
                        <Table size="small">
                          <TableHead
                            style={{
                              background: "#F9FAFB",
                            }}
                          >
                            <TableRow>
                              <ThemeProvider theme={theme}>
                                <TableCell
                                  style={{
                                    borderTopLeftRadius: "8px",
                                  }}
                                >
                                  <Typography
                                    variant="subtitle2"
                                    className={classes.headText}
                                  >
                                    Song
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography
                                    variant="subtitle2"
                                    className={classes.headText}
                                  >
                                    Composer
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography
                                    variant="subtitle2"
                                    className={classes.headText}
                                  >
                                    Action
                                  </Typography>
                                </TableCell>
                              </ThemeProvider>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {items?.length > 0 ? (
                              items.map(customer => (
                                <TableRow hover key={customer.loan_id}>
                                  <ThemeProvider theme={theme}>
                                    <TableCell>
                                      <Typography
                                        variant="subtitle2"
                                        className={classes.bodyTextBlack}
                                      >
                                        {customer.composer_name}
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography
                                        variant="subtitle2"
                                        className={classes.bodyTextBlack}
                                      >
                                        {customer.composer_name}
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography
                                        variant="subtitle2"
                                        className={classes.bodyTextBlack}
                                      >
                                        {customer.loan_date}
                                      </Typography>
                                    </TableCell>
                                  </ThemeProvider>
                                </TableRow>
                              ))
                            ) : (
                              <TableRow>
                                <TableCell colSpan={6}>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Typography variant="subtitle1">
                                      No data available
                                    </Typography>
                                  </div>
                                </TableCell>
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid item xs={12} md={12} lg={12} xl={12}>
                    <div
                      style={{
                        background: "#F9FAFB",
                        border: "1px dashed #D1D5DC",
                        borderRadius: "8px",
                        padding: "16px",
                        marginTop: "16px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          alt="Logo"
                          src={
                            require("assets/image-public/images/vector-upload-trans.png")
                              .default
                          }
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "Inter",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "14px",
                            lineHeight: "20px",
                            color: "#111827",
                          }}
                        >
                          Upload proof of transfer,{" "}
                        </span>
                        <span
                          style={{
                            fontFamily: "Inter",
                            fontStyle: "normal",
                            fontWeight: "600",
                            fontSize: "14px",
                            lineHeight: "20px",
                            color: "#364052",
                          }}
                        >
                          &nbsp;Search File
                        </span>
                      </div>
                    </div>
                  </Grid>
                </Grid>

                <Grid container>
                  <div
                    style={{
                      marginTop: "16px",
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: "700",
                        fontSize: "16px",
                        lineHeight: "24px",
                        color: "#111827",
                      }}
                    >
                      Documents Attached
                    </span>
                  </div>
                </Grid>

                <Grid container>
                  <Grid item xs={12} md={12} lg={12} xl={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "12px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            alt="Logo"
                            src={
                              require("assets/image-public/images/vector-doc-icon.png")
                                .default
                            }
                          />
                        </div>
                        <div
                          style={{
                            marginLeft: "15px",
                          }}
                        >
                          <div>
                            <span
                              style={{
                                fontFamily: "Inter",
                                fontStyle: "normal",
                                fontWeight: "400",
                                fontSize: "14px",
                                lineHeight: "20px",
                                color: "#111827",
                              }}
                            >
                              Payment Proof1.jpg
                            </span>
                          </div>
                          <div>
                            <span
                              style={{
                                fontFamily: "Inter",
                                fontStyle: "normal",
                                fontWeight: "400",
                                fontSize: "12px",
                                lineHeight: "18px",
                                color: "#687083",
                              }}
                            >
                              15KB
                            </span>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <span>See</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            marginLeft: "14px",
                          }}
                        >
                          <img alt="Logo" src={PencilVectorGrey} />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            marginLeft: "14px",
                          }}
                        >
                          <img alt="LogoTrash" src={TrashVectorGrey} />
                        </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={12} lg={12} xl={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "12px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            alt="Logo"
                            src={
                              require("assets/image-public/images/vector-doc-icon.png")
                                .default
                            }
                          />
                        </div>
                        <div
                          style={{
                            marginLeft: "15px",
                          }}
                        >
                          <div>
                            <span
                              style={{
                                fontFamily: "Inter",
                                fontStyle: "normal",
                                fontWeight: "400",
                                fontSize: "14px",
                                lineHeight: "20px",
                                color: "#111827",
                              }}
                            >
                              Payment Proof1.jpg
                            </span>
                          </div>
                          <div>
                            <span
                              style={{
                                fontFamily: "Inter",
                                fontStyle: "normal",
                                fontWeight: "400",
                                fontSize: "12px",
                                lineHeight: "18px",
                                color: "#687083",
                              }}
                            >
                              15KB
                            </span>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <span>See</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            marginLeft: "14px",
                          }}
                        >
                          <img alt="Logo" src={PencilVectorGrey} />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            marginLeft: "14px",
                          }}
                        >
                          <img alt="LogoTrash" src={TrashVectorGrey} />
                        </div>
                      </div>
                    </div>
                  </Grid>
                </Grid>

                <Grid container justifyContent="flex-end">
                  <div
                    style={{
                      marginTop: "32px",
                    }}
                  >
                    <Button
                      onClick={() => {
                        history.push("/admin/advance");
                      }}
                      style={{
                        borderRadius: "6px",
                        width: "84px",
                        height: "40px",
                        border: "1px solid #D1D5DC",
                        textTransform: "none",
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                  <div
                    style={{
                      marginTop: "32px",
                    }}
                  >
                    <Button
                      style={{
                        marginLeft: "10px",
                        backgroundColor: "#111827",
                        borderRadius: "6px",
                        width: "84px",
                        height: "40px",
                        color: "#FFFFFF",
                        textTransform: "none",
                      }}
                      onClick={clickSave}
                    >
                      Add
                    </Button>
                  </div>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4} lg={4} xl={4}></Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default AddAdvanced;
