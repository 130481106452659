import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import axios from "axios";
import {
  AutoCompleteWithSearch,
  ModalError,
  PrimaryButton,
  SecondaryButton,
} from "components";
import { API_ENDPOINTS } from "constants";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { getErrors } from "utils";

const ModalComposerConfiguration = ({
  open,
  onClose,
  preload,
  handleSubmit,
}) => {
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const [queryParams, setQueryParams] = useState({
    page: 1,
    size: 10,
    search: "",
  });
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [loadingPage, setLoadingPage] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const handleChangeQueryParams = (value, key) => {
    setQueryParams(currentState => ({
      ...currentState,
      ...(key !== "page" && { page: 1 }),
      [key]: value,
    }));
  };

  const getDataTable = async () => {
    try {
      setLoadingPage(true);
      const res = await axios.get(API_ENDPOINTS.GET_PUBLISHER_COMPOSERS, {
        headers,
        params: queryParams,
      });
      const { data, meta } = res?.data;
      const newOptions = data?.map(item => ({
        ...item,
        id: item?.composer_id,
        is_editable: true,
      }));
      setOptions(prev =>
        queryParams.page === 1 ? newOptions : [...prev, ...newOptions]
      );
      setHasMore(meta?.total > newOptions.length);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  const debounceDataTable = useCallback(
    debounce(() => {
      getDataTable();
    }, 500),
    [queryParams]
  );
  const getPreload = () => {
    const modifiedData = preload?.map(item => ({
      ...item,
      id: item?.composer_id,
    }));
    setSelectedOptions(modifiedData);
  };

  const onSubmit = () => {
    const modifiedData = selectedOptions?.map(item => ({
      ...item,
      composer_percentage: item?.composer_percentage || 0,
      composer_roles: item?.composer_roles || [],
      share_percentage: item?.share_percentage || 0,
      is_editable: item?.is_editable,
    }));
    handleSubmit(modifiedData);
    onClose();
  };

  useEffect(() => {
    if (queryParams.search) {
      debounceDataTable();
      return () => {
        debounceDataTable.cancel();
      };
    } else {
      getDataTable();
    }
  }, [queryParams, debounceDataTable]);
  useEffect(() => {
    getPreload();
  }, [preload]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Composer/Author</DialogTitle>
      <DialogContent dividers>
        <AutoCompleteWithSearch
          multiple
          loading={loadingPage}
          disableCloseOnSelect
          value={selectedOptions}
          options={options}
          onChange={item => setSelectedOptions(item)}
          inputValue={queryParams?.search}
          onInputChange={value => handleChangeQueryParams(value, "search")}
          optionLabel="sure_name"
          placeholder="Search composers/authors"
          setQueryParams={setQueryParams}
          hasMore={hasMore}
          disabledKey="is_editable"
          autoHighlight
          width="100%"
        />
      </DialogContent>
      <DialogActions>
        <SecondaryButton label="Cancel" onClick={onClose} />
        <PrimaryButton
          label="Add"
          onClick={onSubmit}
          disabled={!selectedOptions.length}
        />
      </DialogActions>
    </Dialog>
  );
};

export default ModalComposerConfiguration;
