import { Container, Divider } from "@material-ui/core";
import { Box, Grid, Typography } from "@mui/material";
import axios from "axios";
import {
  CurrencyDisplay,
  FormLabel,
  ImportDocumentModal,
  ModalError,
  ModalSuccess,
  ModalWarning,
  Page,
  PrimaryButton,
  SecondaryButton,
  SectionLabel,
  SkeletonComponent,
  StatusChip,
  TableCellMultipleRow,
} from "components";
import html2canvas from "html2canvas";
import { InnoTableV2 } from "inno-ui";
import { jsPDF } from "jspdf";
import { HeaderTitle } from "layouts";
import {
  calculateSingleTax,
  calculateTotalRevenue,
  calculateTotalTax,
} from "lib";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { globalStyles } from "styles";
import {
  formatDate,
  getErrors,
  textCapitalization,
  wordCapitalize,
} from "utils";
import { hardBaseUrl } from "../../../services/urlConstant";

const DetailNotification = props => {
  const classes = globalStyles();
  const { id } = useParams();
  const inputRef = useRef(null);
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const [loadingPage, setLoadingPage] = useState(false);
  const [pageDetail, setPageDetail] = useState({});

  console.log(pageDetail, "cek");

  const getDetail = async () => {
    setLoadingPage(true);
    try {
      const res = await axios.get(`${hardBaseUrl}/notification/${id}`, {
        headers,
      });
      const { data } = res?.data;
      setPageDetail(data);
    } catch (error) {
      ModalError(getErrors(error?.response));
    }
    setLoadingPage(false);
  };

  useEffect(() => {
    getDetail();
  }, []);
  return (
    <Page className={classes.root} title="Notification Detail">
      {loadingPage ? (
        <SkeletonComponent variant="wave" />
      ) : (
        <Container maxWidth={false}>
          <HeaderTitle
            title="Notification"
            breadcrumbData={breadcrumbData}
            backButton
          />
          <Divider className={classes?.divider} />
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            my="16px"
          >
            <Grid item>
              <SectionLabel
                title="Notification Detail"
                subTitle="View details of the notification."
              />
            </Grid>
          </Grid>
          <Box
            border="1px solid #9AA2B1"
            borderRadius="6px"
            p="24px"
            ref={inputRef}
          >
            <>
              <Grid container spacing={8}>
                <Grid item xs={12} md={6}>
                  <Grid container direction="column">
                    <Grid
                      item
                      display={"flex"}
                      justifyContent={"space-between"}
                    >
                      Title :
                      <FormLabel label={pageDetail?.title} />
                    </Grid>
                    <Grid
                      item
                      display={"flex"}
                      justifyContent={"space-between"}
                    >
                      Type :
                      <FormLabel label={textCapitalization(pageDetail?.type)} />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid item display={"flex"} justifyContent={"space-between"}>
                    Date :
                    <FormLabel label={formatDate(pageDetail?.updated_at)} />
                  </Grid>
                  <Grid item display={"flex"} justifyContent={"space-between"}>
                    Contract Number :
                    <FormLabel
                      label={pageDetail?.data_contract?.contract_number || "-"}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Divider />

              <Grid container spacing={8}>
                <Grid item xs={12} md={6}>
                  <Grid container direction="column" rowSpacing={1}>
                    <Grid
                      item
                      display={"flex"}
                      justifyContent={"space-between"}
                    >
                      Approval Contract Status :
                      <FormLabel
                        label={
                          pageDetail?.data_contract?.approval_status || "-"
                        }
                      />
                    </Grid>
                    <Grid
                      item
                      display={"flex"}
                      justifyContent={"space-between"}
                    >
                      Double Claim Composer :
                      <FormLabel
                        label={pageDetail?.data_double_claimed?.composer || "-"}
                      />
                    </Grid>
                    <Grid
                      item
                      display={"flex"}
                      justifyContent={"space-between"}
                    >
                      Isrc Code :
                      <FormLabel
                        label={
                          pageDetail?.data_double_claimed?.isrc_code || "-"
                        }
                      />
                    </Grid>
                    <Grid
                      item
                      display={"flex"}
                      justifyContent={"space-between"}
                    >
                      Iswc Code :
                      <FormLabel
                        label={
                          pageDetail?.data_double_claimed?.iswc_code || "-"
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid item display={"flex"} justifyContent={"space-between"}>
                    Publisher :
                    <FormLabel
                      label={pageDetail?.data_double_claimed?.publisher}
                    />
                  </Grid>
                  <Grid item display={"flex"} justifyContent={"space-between"}>
                    Song :
                    <FormLabel
                      label={pageDetail?.data_double_claimed?.song || "-"}
                    />
                  </Grid>
                  <Grid item display={"flex"} justifyContent={"space-between"}>
                    Publisher Transaction Status :
                    <FormLabel
                      label={
                        pageDetail?.data_publisher_transaction?.status || "-"
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            </>
          </Box>
        </Container>
      )}
    </Page>
  );
};

const breadcrumbData = [
  {
    label: "Home",
    link: "/admin/dashboard",
  },
  {
    label: "Notification",
    link: "/admin/notifikasi",
  },
];

export default DetailNotification;
