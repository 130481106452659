import { Container, Divider } from "@material-ui/core";
import { Box, Grid } from "@mui/material";
import axios from "axios";
import {
  CurrencyDisplay,
  FormLabel,
  ModalError,
  Page,
  SectionLabel,
  SkeletonComponent,
} from "components";
import { HeaderTitle } from "layouts";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { globalStyles } from "styles";
import { formatDateTime, getErrors } from "utils";
import { hardBaseUrl, hardTypeWeb } from "../../../../services/urlConstant";

const ViewExRate = () => {
  const classes = globalStyles();
  const { id, currency } = useParams();
  const isMpis = hardTypeWeb === "mpis";
  const pageTitle = isMpis ? "Parameter" : "Configuration";
  const pageUrl = isMpis ? "parameter" : "konfigurasi";
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const [loadingPage, setLoadingPage] = useState(false);
  const [pageDetail, setPageDetail] = useState({});

  const getDetail = async () => {
    try {
      setLoadingPage(true);
      const res = await axios.get(`${hardBaseUrl}/exchange-rate/detail/${id}`, {
        headers,
      });
      setPageDetail(res?.data?.data);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };

  useEffect(() => {
    getDetail();
  }, []);

  return (
    <Page className={classes.root} title="View Currency Data">
      {loadingPage ? (
        <SkeletonComponent variant="wave" />
      ) : (
        <Container maxWidth={false}>
          <HeaderTitle
            title="View Currency Data"
            breadcrumbData={breadcrumbData({ pageTitle, pageUrl })}
            backButton
          />
          <Divider className={classes.divider} />
          <SectionLabel
            title="View Currency Data"
            subTitle="Features for view detail of currency data"
          />
          <Box
            border="1px solid #ebebeb"
            borderRadius="5px"
            p="24px"
            mt="16px"
            maxWidth="70%"
          >
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <FormLabel label="Currency" />
                {currency}
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container direction="column" rowSpacing={1}>
                  <Grid item>
                    <FormLabel label="Start Date" />
                    {formatDateTime(pageDetail?.start_date)}
                  </Grid>
                  <Grid item>
                    <FormLabel label="End Date" />
                    {formatDateTime(pageDetail?.end_date)}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormLabel label="Currency" />
                <CurrencyDisplay
                  value={pageDetail?.value}
                  decimalScale={2}
                  prefix="Rp "
                  suffix=",-"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormLabel label="Created At" />
                {formatDateTime(pageDetail?.created_at)}
              </Grid>
            </Grid>
          </Box>
        </Container>
      )}
    </Page>
  );
};

const breadcrumbData = ({ pageTitle, pageUrl }) => [
  {
    label: pageTitle,
    link: `admin/${pageUrl}/exchange-rate`,
  },
  {
    label: "Exchange Rate",
    link: `admin/${pageUrl}/exchange-rate`,
  },
  {
    label: "View Currency Data",
    active: true,
  },
];
export default ViewExRate;
