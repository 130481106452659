import {
  Avatar,
  Button,
  Container,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import Axios from "axios";
import { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import HeaderPage from "../../layouts/Header/HeaderPage";
import { Page, InputPassword, SkeletonComponent } from "components";
import { hardBaseUrl } from "../../services/urlConstant";
import { getCookie } from "../../utils/constants";
import gradients from "../../theme/gradients";

const fontInter = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const theme = createTheme({
  typography: {
    fontFamily: ["Helvetica Neue"].join(","),
  },
});

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  card: {
    width: theme.breakpoints.values.md,
    maxWidth: "100%",
    overflow: "visible",
    display: "flex",
    position: "relative",
    "& > *": {
      flexGrow: 1,
      flexBasis: "50%",
      width: "50%",
    },
  },
  content: {
    padding: theme.spacing(8, 4, 3, 4),
  },
  contentTable: {
    padding: 0,
    border: "0px solid #e0e0e0",
    borderRadius: "24px",
  },
  media: {
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    padding: theme.spacing(3),
    color: theme.palette.common.white,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  avatar_large: {
    width: "182px",
    height: "182px",
  },
  icon: {
    backgroundImage: gradients.green,
    color: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    position: "absolute",
    top: -32,
    left: theme.spacing(3),
    height: 64,
    width: 64,
    fontSize: 32,
  },
  loginForm: {
    marginTop: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(0, 0),
    borderTop: "1px solid #E4E7EB",
  },
  person: {
    marginTop: theme.spacing(2),
    display: "flex",
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  inputFields: {
    height: "44px",
    paddingTop: "0px",
    paddingBottom: "0px",
  },
  myTextField: {
    height: "44px",
    paddingBottom: "0px",
    paddingTop: "0px",
  },
}));

function Profile() {
  const classes = useStyles();
  const theToken = localStorage.getItem("token");
  const uploadInputRef = useRef(null);
  const roleId = localStorage?.getItem("role_id");
  const [loadingPage, setLoadingPage] = useState(false);
  const [dataProfile, setDataProfile] = useState({
    full_name: "",
    email: "",
    phone: "",
  });
  const [versionApp, setVersionApp] = useState({
    FE: "",
    BE: "",
  });

  const [password, setPassword] = useState({
    new_password: "",
    confirm_new_password: "",
  });

  const [dataImage, setDataImage] = useState(null);

  const feVersion = getCookie(
    "authVersion",
    `${process.env.REACT_APP_NAME}_fe`
  );

  const beVersion = getCookie(
    "authVersion",
    `${process.env.REACT_APP_NAME}_be`
  );

  const handleChangeImage = async e => {
    try {
      const selectedFile = e.target.files[0];

      // Validasi ukuran file (maksimal 5 MB)
      if (selectedFile && selectedFile.size > 5 * 1024 * 1024) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Ukuran file terlalu besar. Maksimal 5 MB.",
        });
        return;
      }

      const formData = new FormData();
      formData.append("profile_image", e.target.files[0]);
      const response = await Axios.put(
        hardBaseUrl + "/update-image",
        formData,
        {
          headers: {
            Authorization: "Bearer " + theToken,
          },
        }
      );
      if (response.data.message === "success") {
        setDataImage(response.data.data.display_picture_url);
        Swal.fire({
          icon: "success",
          title: "Successfully updated profile photo",
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
          window.location.reload();
        });
      }
    } catch (error) {}
  };

  const handleEditProfile = async () => {
    try {
      const missingFields = [];

      if (!dataProfile.full_name) {
        missingFields.push("Full Name");
      }

      if (!dataProfile.phone) {
        missingFields.push("Phone Number");
      }

      if (!dataProfile.email) {
        missingFields.push("Email Address");
      }

      if (missingFields.length > 0) {
        const missingFieldsString = missingFields.join(", ");
        Swal.fire({
          icon: "error",
          title: "Missing Information",
          text: `Please complete the following fields: ${missingFieldsString}`,
        });
        return;
      }

      const response = await Axios.put(
        hardBaseUrl + "/update-profile",
        dataProfile,
        { headers: { Authorization: "Bearer " + theToken } }
      );

      if (response.data.message === "success") {
        setDataProfile(response.data.data); // Update dataProfile with response data
        Swal.fire({
          icon: "success",
          title: "Successfully updated profile data",
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: response.data.message || "An error occurred",
          text: "",
        });
      }
    } catch (error) {
      console.error("Error updating profile:", error);
      Swal.fire({
        icon: "error",
        title: "An error occurred",
        text: "Please try again later.",
      });
    }
  };
  const handleChangePassword = async () => {
    // Password complexity validation
    const hasUpperCase = /[A-Z]/.test(password.new_password);
    const hasLowerCase = /[a-z]/.test(password.new_password);
    const hasNumber = /\d/.test(password.new_password);
    const hasSymbol = /[^\w\s]/.test(password.new_password);

    if (!hasUpperCase || !hasLowerCase || !hasNumber || !hasSymbol) {
      Swal.fire({
        icon: "error",
        title: "Weak Password",
        text:
          "Password must contain at least one uppercase letter, lowercase letter, number, and symbol.",
      });
      return; // Early exit if password is weak
    }

    // Existing password confirmation check
    if (password.new_password !== password.confirm_new_password) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please enter the password or confirm the password correctly!",
      });
      return; // Early exit if passwords don't match
    }

    // Send change password request (unchanged)
    try {
      const response = await Axios.put(
        hardBaseUrl + "/change-password",
        { new_password: password.new_password },
        { headers: { Authorization: "Bearer " + theToken } }
      );

      const { data } = response;
      Swal.fire({
        icon: data.message || "error",
        title: data.message,
        text:
          data.message === "success" ? "Successfully changed password!" : "",
      });
      setPassword({ new_password: "", confirm_new_password: "" });
    } catch (error) {
      console.error("Error changing password:", error);
      Swal.fire({
        icon: "error",
        title: "An error occurred",
        text: "Please try again later.",
      });
    }
  };

  const fetchVersion = () => {
    const url = `${hardBaseUrl}/version`;
    const headers = {
      "Content-Type": "application/json",
    };
    Axios.get(url, { headers }).then(res => {
      if (res.data?.meta?.http_status === 200) {
        setVersionApp({
          FE: res.data.data.fe,
          BE: res.data.data.api,
        });
      }
    });
  };

  useEffect(() => {
    setLoadingPage(true);
    const url = `${hardBaseUrl}/me/${roleId}`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${theToken}`,
    };
    Axios.get(url, { headers }).then(res => {
      if (res.data?.meta?.http_status === 200) {
        setDataProfile({
          full_name: res.data.data.name,
          email: res.data.data.email,
          phone: res.data.data.phone,
        });
        setDataImage(res.data.data.display_picture_url);
      }
    });
    fetchVersion();
    setLoadingPage(false);
  }, []);

  return (
    <Page className={classes.root} title="Profil">
      {loadingPage ? (
        <SkeletonComponent variant="rect" />
      ) : (
        <Container maxWidth={false}>
          <HeaderPage
            title="Profil"
            breadcrumbs={[]}
            style={{
              marginBottom: "16px",
            }}
          />
          <Divider className={classes.divider} />
          <Grid container>
            <Grid item xs={8} md={8} sm={8} lg={8} xl={8}>
              <div
                style={{
                  marginTop: "24px",
                }}
              >
                <Typography
                  style={{
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: 700,
                    fontSize: "18px",
                    lineHeight: "28px",
                    color: "#111827",
                  }}
                >
                  Account Information
                </Typography>
              </div>
              <div
                style={{
                  marginTop: "8px",
                }}
              >
                <Typography
                  style={{
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: "14px",
                    lineHeight: "20px",
                    color: "#687083",
                  }}
                >
                  Features to make changes to profile data
                </Typography>
              </div>
              <div
                style={{
                  border: "1px solid #D1D5DC",
                  boxSizing: "border-box",
                  borderRadius: "6px",
                  padding: "16px",
                  marginTop: "16px",
                }}
              >
                <Grid container>
                  <Grid item xs={12} md={12} sm={12} lg={12} xl={12}>
                    <div>
                      <div>
                        <ThemeProvider theme={theme}>
                          <Typography
                            style={{
                              fontFamily: "Inter",
                              fontStyle: "normal",
                              fontWeight: 500,
                              fontSize: "14px",
                              lineHeight: "20px",
                              color: "#364052",
                            }}
                          >
                            Full name *
                          </Typography>
                        </ThemeProvider>
                      </div>
                      <div
                        style={{
                          marginTop: "6px",
                        }}
                      >
                        <TextField
                          value={dataProfile.full_name}
                          id="outlined-basic"
                          onChange={e =>
                            setDataProfile({
                              ...dataProfile,
                              full_name: e.target.value,
                            })
                          }
                          variant="outlined"
                          fullWidth={true}
                          InputProps={{
                            classes: { input: classes.inputFields },
                          }}
                        />
                      </div>
                    </div>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={6} md={6} sm={6} lg={6} xl={6}>
                    <div
                      style={{
                        marginTop: "16px",
                        marginRight: "16px",
                      }}
                    >
                      <div>
                        <ThemeProvider theme={theme}>
                          <Typography
                            style={{
                              fontFamily: "Inter",
                              fontStyle: "normal",
                              fontWeight: 500,
                              fontSize: "14px",
                              lineHeight: "20px",
                              color: "#364052",
                            }}
                          >
                            Email *
                          </Typography>
                        </ThemeProvider>
                      </div>
                      <div
                        style={{
                          marginTop: "6px",
                        }}
                      >
                        <TextField
                          value={dataProfile.email}
                          id="outlined-basic"
                          onChange={e =>
                            setDataProfile({
                              ...dataProfile,
                              email: e.target.value,
                            })
                          }
                          variant="outlined"
                          fullWidth={true}
                          InputProps={{
                            classes: { input: classes.inputFields },
                          }}
                        />
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={6} md={6} sm={6} lg={6} xl={6}>
                    <div
                      style={{
                        marginTop: "16px",
                      }}
                    >
                      <div
                        style={{
                          marginTop: "6px",
                        }}
                      >
                        <ThemeProvider theme={theme}>
                          <Typography
                            style={{
                              fontFamily: "Inter",
                              fontStyle: "normal",
                              fontWeight: 500,
                              fontSize: "14px",
                              lineHeight: "20px",
                              color: "#364052",
                            }}
                          >
                            Phone number *
                          </Typography>
                        </ThemeProvider>
                      </div>
                      <div
                        style={{
                          marginTop: "6px",
                        }}
                      >
                        <TextField
                          id="outlined-basic"
                          value={dataProfile.phone}
                          onChange={e =>
                            setDataProfile({
                              ...dataProfile,
                              phone: e.target.value,
                            })
                          }
                          variant="outlined"
                          fullWidth={true}
                          InputProps={{
                            classes: { input: classes.inputFields },
                          }}
                        />
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid item xs={8} md={8} sm={8} lg={8} xl={8}>
              <Grid container>
                <Grid item xs={5} md={5} sm={5} lg={5} xl={5}>
                  <div
                    style={{
                      marginTop: "16px",
                    }}
                  >
                    <Button
                      variant="contained"
                      onClick={handleEditProfile}
                      style={{
                        border: "1px solid #D1D5DC",
                        backgroundColor: "black",
                        boxShadow: "none",
                        width: "180px",
                        height: "40px",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "6px",
                      }}
                    >
                      <ThemeProvider theme={fontInter}>
                        <Typography
                          component="span"
                          style={{
                            fontSize: "14px",
                            lineHeight: "20px",
                            fontWeight: "500",
                            fontStyle: "normal",
                            textTransform: "none",
                            color: "white",
                          }}
                        >
                          Save Changes
                        </Typography>
                      </ThemeProvider>
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Divider
            className={classes.divider}
            style={{
              marginTop: "24px",
            }}
          />
          <Grid container>
            <Grid item xs={8} md={8} sm={8} lg={8} xl={8}>
              <div
                style={{
                  marginTop: "24px",
                }}
              >
                <Typography
                  style={{
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: 700,
                    fontSize: "18px",
                    lineHeight: "28px",
                    color: "#111827",
                  }}
                >
                  Profile picture
                </Typography>
              </div>
              <div
                style={{
                  marginTop: "8px",
                }}
              >
                <Typography
                  style={{
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: "14px",
                    lineHeight: "20px",
                    color: "#687083",
                  }}
                >
                  Features to make profile photo changes
                </Typography>
              </div>
              <div
                style={{
                  border: "1px solid #D1D5DC",
                  boxSizing: "border-box",
                  borderRadius: "6px",
                  padding: "16px",
                  marginTop: "16px",
                }}
              >
                <Grid item xs={8} md={8} sm={8} lg={8} xl={8}>
                  <Grid container>
                    <Grid item xs={4} md={4} sm={4} lg={4} xl={4}>
                      {dataImage !== null ? (
                        <>
                          <img
                            src={dataImage}
                            alt="profileImage"
                            className={classes.avatar_large}
                          />
                        </>
                      ) : (
                        <>
                          <Avatar
                            className={classes.avatar_large}
                            src={""}
                          ></Avatar>
                        </>
                      )}
                    </Grid>
                    <Grid item xs={5} md={5} sm={5} lg={5} xl={5}>
                      <div
                        style={{
                          marginTop: "5px",
                          marginBottom: "5px",
                        }}
                      >
                        <input
                          ref={uploadInputRef}
                          type="file"
                          accept="image/*"
                          style={{ display: "none" }}
                          onChange={handleChangeImage}
                        />
                        <Button
                          variant="contained"
                          onClick={() =>
                            uploadInputRef.current &&
                            uploadInputRef.current.click()
                          }
                          style={{
                            border: "1px solid #D1D5DC",
                            backgroundColor: "black",
                            boxShadow: "none",

                            width: "180px",
                            height: "40px",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "6px",
                          }}
                        >
                          <ThemeProvider theme={theme}>
                            <Typography
                              component="span"
                              style={{
                                fontSize: "14px",
                                lineHeight: "20px",
                                fontWeight: "500",
                                fontStyle: "normal",
                                textTransform: "none",
                                color: "white",
                              }}
                            >
                              Change Profile Photo
                            </Typography>
                          </ThemeProvider>
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
          <Divider
            className={classes.divider}
            style={{
              marginTop: "24px",
            }}
          />
          <Grid container>
            <Grid item xs={8} md={8} sm={8} lg={8} xl={8}>
              <div
                style={{
                  marginTop: "24px",
                }}
              >
                <Typography
                  style={{
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: 700,
                    fontSize: "18px",
                    lineHeight: "28px",
                    color: "#111827",
                  }}
                >
                  Change Password
                </Typography>
              </div>
              <div
                style={{
                  marginTop: "8px",
                }}
              >
                <Typography
                  style={{
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: "14px",
                    lineHeight: "20px",
                    color: "#687083",
                  }}
                >
                  Features to make a new password change
                </Typography>
              </div>
              <div
                style={{
                  border: "1px solid #D1D5DC",
                  boxSizing: "border-box",
                  borderRadius: "6px",
                  padding: "16px",
                  marginTop: "16px",
                }}
              >
                <Grid item xs={12} md={12} sm={12} lg={12} xl={12}>
                  <Grid container>
                    <Grid item xs={6} md={6} sm={6} lg={6} xl={6}>
                      <div
                        style={{
                          marginRight: "16px",
                        }}
                      >
                        <div>
                          <ThemeProvider theme={theme}>
                            <Typography
                              style={{
                                fontFamily: "Inter",
                                fontStyle: "normal",
                                fontWeight: 500,
                                fontSize: "14px",
                                lineHeight: "20px",
                                color: "#364052",
                              }}
                            >
                              New password
                            </Typography>
                          </ThemeProvider>
                        </div>
                        <div
                          style={{
                            marginTop: "6px",
                          }}
                        >
                          <InputPassword
                            onChange={e =>
                              setPassword({
                                ...password,
                                new_password: e.target.value,
                              })
                            }
                            id="outlined-basic"
                            type="password"
                            value={password.new_password}
                            variant="outlined"
                            fullWidth={true}
                            InputProps={{
                              classes: { input: classes.inputFields },
                            }}
                          />
                        </div>
                        <div
                          style={{
                            marginTop: "6px",
                          }}
                        >
                          <Typography
                            style={{
                              fontFamily: "Inter",
                              fontStyle: "normal",
                              fontWeight: 400,
                              fontSize: "12px",
                              lineHeight: "18px",
                              color: "#687083",
                            }}
                          >
                            Minimum 8 Characters
                          </Typography>
                        </div>
                      </div>
                    </Grid>

                    <Grid item xs={6} md={6} sm={6} lg={6} xl={6}>
                      <div>
                        <div>
                          <ThemeProvider theme={theme}>
                            <Typography
                              style={{
                                fontFamily: "Inter",
                                fontStyle: "normal",
                                fontWeight: 500,
                                fontSize: "14px",
                                lineHeight: "20px",
                                color: "#364052",
                              }}
                            >
                              Confirm New Password
                            </Typography>
                          </ThemeProvider>
                        </div>
                        <div
                          style={{
                            marginTop: "6px",
                          }}
                        >
                          <InputPassword
                            onChange={e =>
                              setPassword({
                                ...password,
                                confirm_new_password: e.target.value,
                              })
                            }
                            id="outlined-basic"
                            type="password"
                            value={password.confirm_new_password}
                            variant="outlined"
                            fullWidth={true}
                            InputProps={{
                              classes: { input: classes.inputFields },
                            }}
                          />
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
              <div
                style={{
                  marginTop: "16px",
                }}
              >
                <Button
                  variant="contained"
                  style={{
                    border: "1px solid #D1D5DC",
                    backgroundColor: "black",
                    boxShadow: "none",
                    width: "180px",
                    height: "40px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "6px",
                  }}
                >
                  <ThemeProvider theme={fontInter}>
                    <Typography
                      component="span"
                      onClick={handleChangePassword}
                      style={{
                        fontSize: "14px",
                        lineHeight: "20px",
                        fontWeight: "500",
                        fontStyle: "normal",
                        textTransform: "none",
                        color: "white",
                      }}
                    >
                      Save Changes
                    </Typography>
                  </ThemeProvider>
                </Button>
              </div>
            </Grid>
          </Grid>
          <Divider
            className={classes.divider}
            style={{
              marginTop: "24px",
            }}
          />
          <Grid container>
            <Grid item xs={8} md={8} sm={8} lg={8} xl={8}>
              <div
                style={{
                  marginTop: "24px",
                }}
              >
                <Typography
                  style={{
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: 700,
                    fontSize: "18px",
                    lineHeight: "28px",
                    color: "#111827",
                  }}
                >
                  CARIS Version
                </Typography>
              </div>
              <div
                style={{
                  border: "1px solid #D1D5DC",
                  boxSizing: "border-box",
                  borderRadius: "6px",
                  padding: "0px",
                  marginTop: "16px",
                }}
              >
                <Grid item xs={12} md={12} sm={12} lg={12} xl={12}>
                  <Grid container>
                    <Table size="medium">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            align="left"
                            width="30%"
                            style={{
                              borderTopLeftRadius: "6px",
                            }}
                          >
                            Version
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{
                              borderTopRightRadius: "6px",
                            }}
                          ></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell align="left">
                            <b>Front End Version</b>
                          </TableCell>
                          <TableCell align="left">{versionApp.FE}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            align="left"
                            style={{
                              borderBottomLeftRadius: "6px",
                            }}
                          >
                            <b>Back End Version</b>
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{
                              borderBottomRightRadius: "6px",
                            }}
                          >
                            {versionApp.BE}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </Container>
      )}
    </Page>
  );
}

export default Profile;
