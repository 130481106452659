import { UserOutlined } from "@ant-design/icons";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Input } from "antd";
import Axios from "axios";
import React, { useState } from "react";
import { useHistory } from "react-router";
import searchImage from "../assets/img/search.png";
import { hardBaseUrl, hardSubDomain } from "../services/urlConstant";
export default function ScrollDialog({ handleClose, open }) {
  const history = useHistory();
  const scroll = "paper";
  const [input, setInput] = useState("");
  const [dataSearch, setDataSearch] = useState({
    artists: [],
    composers: [],
    songs: [],
  });
  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);
  const handleSearch = async val => {
    setInput(val);
    try {
      const response = await Axios.get(
        hardBaseUrl +
        `/search/song/suggestion?search=${val}&subdomain=${hardSubDomain !== "mpis" ? hardSubDomain : ""
        }`
      );
      setDataSearch(response.data.data);
    } catch (error) {
      new Error(error);
    }
  };
  const handleRedirect = (a, b) => {
    history.push({
      pathname: "/detail-lagu",
      search: `?search=${b}?field=${a}`,
      state: {
        search: b,
        field: a,
      },
    });
    setInput("");
    window.location.reload();
  };
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth
      >
        <DialogTitle id="scroll-dialog-title">
          <Input
            className="input-search"
            style={{ height: "100%", borderRadius: "6px", color: "black" }}
            color="red"
            onChange={e => handleSearch(e.target.value)}
            value={input}
            prefix={
              <Button type="text">
                <img src={searchImage} alt="" />
              </Button>
            }
            size="large"
            placeholder="Search for a song, singer or composer"
          />
        </DialogTitle>
        {input !== "" ? (
          <DialogContent dividers={scroll === "paper"}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
            >
              <div
                style={{
                  height: input !== "" ? "400px" : "0px",
                }}
              >
                {input !== "" && (
                  <>
                    <div style={{ color: "white" }}>
                      <h4 style={{ color: "black", marginTop: "20px" }}>
                        Song
                      </h4>
                      {dataSearch.songs.map((res, index) => {
                        return (
                          <div
                            className="result-search"
                            style={{ color: "black" }}
                            onClick={() => handleRedirect("songs", res)}
                            key={`${index}`}
                          >
                            <i
                              className="fa fa-music"
                              style={{ marginRight: "10px" }}
                            />{" "}
                            {res}
                          </div>
                        );
                      })}
                      <h4 style={{ color: "black", marginTop: "20px" }}>
                        Composer
                      </h4>
                      {dataSearch.composers.map((res, index) => {
                        return (
                          <div
                            className="result-search"
                            style={{ color: "black" }}
                            onClick={() => handleRedirect("composers", res)}
                            key={`${index}`}
                          >
                            <UserOutlined
                              style={{ marginRight: "10px", color: "black" }}
                            />{" "}
                            {res}
                          </div>
                        );
                      })}
                      <h4 style={{ color: "black", marginTop: "20px" }}>
                        Artist
                      </h4>
                      {dataSearch.artists.map((res, index) => {
                        return (
                          <div
                            className="result-search"
                            style={{ color: "black" }}
                            onClick={() => handleRedirect("artist", res)}
                            key={`${index}`}
                          >
                            <UserOutlined
                              style={{ marginRight: "10px", color: "black" }}
                            />{" "}
                            {res}
                          </div>
                        );
                      })}
                    </div>
                  </>
                )}
              </div>
            </DialogContentText>
          </DialogContent>
        ) : null}
        <DialogActions>
          <Button
            onClick={handleClose}
            style={{ color: "white", backgroundColor: "black" }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
