import { IconButton, useMediaQuery, useTheme } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import { useRef } from "react";
import "react-multi-carousel/lib/styles.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import bgEllipse from "../../../assets/background/background_home_ellipse.webp";
import bgShade from "../../../assets/background/background_home_illustrative.webp";
import navLeft from "../../../assets/icons/icon_nav_left_landing.svg";
import navRight from "../../../assets/icons/icon_nav_right_landing.svg";
import group1 from "../../../assets/images/illustrative_landing_1.png";
import group2 from "../../../assets/images/illustrative_landing_2.png";
import group3 from "../../../assets/images/illustrative_landing_3.png";
import group4 from "../../../assets/images/illustrative_landing_4.png";
import avatarAdi from "../../../assets/images/testimony_adi.png";
import avatarAndhini from "../../../assets/images/testimony_andhini.png";
import avatarFarah from "../../../assets/images/testimony_farah.png";
import avatarKhandi from "../../../assets/images/testimony_khandi.png";
import avatarMarsha from "../../../assets/images/testimony_marsha.png";
import avatarYayan from "../../../assets/images/testimony_yayan.png";
import LandingFooter from "../../../components/templates/LandingPage/LandingFooter";
import LandingHero from "../../../components/templates/LandingPage/LandingHero";
import { CustomSlide } from "./CustomSlide";
import "./Home.css";

export const interFont = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

function Home() {
  const sliderRef = useRef(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const settingsRs = {
    dots: isMobile ? false : true,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };
  const testimonyList = [
    {
      name: "Adi",
      position: "Finance",
      content:
        "I'm often asked to give a royalty and song usage report in a short time. To do this, I had to process the report data I received from the client. Often the reports given are very difficult to understand and I need more time to process them into reports that are acceptable to my superiors and colleagues. Using MPIS I was able to create reports very easily, I just checked the Dashboard and downloaded the data I needed. MPIS greatly saved my time, I could do other tasks without having to worry about royalty and songs usage reports anymore.",
      image: avatarAdi,
    },
    {
      name: "Khandi",
      position: "Finance",
      content:
        "Before using MPIS, my working hours were spent counting the royalties of the songs I had. Not to mention each platform has a different formula and method of calculation, it made me have to devote more time and concentration to working on royalty reports. Because I have to count it one by one, by line of data, and by type of service. With MPIS, I just need to explain the formula and how to calculate each platform, and then MPIS does the calculation. The results of the calculations I can get in the form of dashboards and reports that I can check and download. With MPIS, the work I used to do for months could be done in less time.",
      image: avatarKhandi,
    },
    {
      name: "Andhini",
      position: "Finance",
      content:
        "Nowadays there are a lot of streaming platforms that listeners can use to listen to their favorite songs. It is of course very profitable for Publisher to be able to reach more listeners on each different streaming service. With the plethora of platforms and services of course raises the question, on which service and platform can I maximize the promotion of the songs? So from that I need a service that can hold all the royalty information from each platform, so I can more accurately determine on which platform I'm going to maximize the promotion on my songs. MPIS present offers that, with it I can compare the royalti reports and the usage of my songs from every platform. I just accessed the dashboard, selected the DSP and period I wanted, and voilà, I got the comparison report I needed.",
      image: avatarAndhini,
    },
    {
      name: "Farah",
      position: "Marketing",
      content:
        "With the advancement of recording technology, nowadays everyone can easily produce music to their liking. This, of course, makes more and more artists and new songs emerge, and leads to increasing competition to win the hearts of listeners. I, as a Marketing Team, of course need an analysis and accurate data information to determine which songs I'm going to promote. The amount of streaming platforms also affects that, I have to find out on which platforms my songs are listened to a lot. MPIS is present and offers the data, information and reports I need, my analysis and marketing strategy are more accurate with the help of MPIS.",
      image: avatarFarah,
    },
    {
      name: "Marsha",
      position: "Admin",
      content:
        "I work at one of the largest and oldest publishers in the country. They have thousands of catalogs of songs from several generations. As the times evolved, it was necessary to digitize the songs catalog data that Publisher had. It's my own challenge to solve that. With MPIS I can manage my songs catalog data efficiently and organized. This digitization process helped my work in verifying the royalties and usage of songs owned by Publisher.",
      image: avatarMarsha,
    },
    {
      name: "Yayan",
      position: "Admin",
      content:
        "I often meeting with prospective clients who will use my songs. A presentation usually requires a simple and interesting data display to attract the client. Previously I always took a few days to make this presentation material, because I had to translate the report of song usage from the streaming platform into a chart or graph that was easy to read by the public. Fortunately MPIS has a dashboard display in a graphical form that is very easy to modify and understand, so I just accessed MPIS to get the presentation frame I needed.",
      image: avatarYayan,
    },
  ];
  const nextImage = [group1, group2, group3, group4];
  const nextSlide = () => {
    sliderRef.current.slickNext();
  };

  const prevSlide = () => {
    sliderRef.current.slickPrev();
  };

  const heroBackground = () => {
    return (
      <>
        <img
          style={{
            position: "absolute",
            width: "700px",
            height: "700px",
            top: "34%",
            left: "44%",
          }}
          alt="background-ellipse"
          src={bgEllipse}
        />
        <img
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            height: "100%",
            width: "100%",
            objectFit: "cover",
            objectPosition: isMobile && "left",
          }}
          alt="background-image"
          src={bgShade}
        />
      </>
    );
  };

  const heroContent = () => {
    return (
      <ThemeProvider theme={interFont}>
        <Typography
          style={{
            fontWeight: 800,
            fontSize: isMobile ? "60px" : "100px",
            color: "white",
            lineHeight: isMobile ? "60px" : "90px",
            maxWidth: "540px",
          }}
        >
          Easy Flexible Streamline
        </Typography>
        <Typography
          style={{
            fontWeight: 500,
            color: "white",
            fontSize: isMobile ? "18px" : "24px",
            marginTop: "20px",
            maxWidth: "500px",
          }}
        >
          Easify and Enchance Music Royalty Management Globally
        </Typography>
      </ThemeProvider>
    );
  };

  return (
    <>
      <LandingHero heroBackground={heroBackground} heroContent={heroContent} />
      <div className="landing-content">
        <div
          style={{
            maxWidth: isMobile ? "365px" : "1200px",
            position: "relative",

            flexDirection: isMobile ? "column-reverse" : "column",
            justifyContent: "center",
            margin: isMobile ? "auto" : "",
          }}
        >
          <div
            style={{
              position: isMobile ? "" : "absolute",
              right: 0,
              top: "22px",
              height: "32px",
              zIndex: 2,
              display: "flex",
              flexDirection: "row",
              justifyContent: isMobile ? "center" : "",
              marginBottom: isMobile && "16px",
            }}
          >
            <IconButton onClick={prevSlide} style={{ padding: 0 }}>
              <img alt="nav-left" src={navLeft} />
            </IconButton>
            <IconButton
              onClick={nextSlide}
              style={{ padding: 0, marginLeft: "16px" }}
            >
              <img alt="nav-right" src={navRight} />
            </IconButton>
          </div>
          <Slider ref={sliderRef} {...settingsRs}>
            {testimonyList?.map((item, index) => (
              <CustomSlide
                key={index}
                index={index}
                name={item?.name}
                image={item?.image}
                position={item?.position}
                content={item?.content}
              />
            ))}
          </Slider>
        </div>
      </div>

      <div className="landing-content">
        <Grid container justifyContent="center" spacing={2}>
          {nextImage?.map((item, index) => (
            <Grid key={index} item xs={12} md={3}>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img alt="group-img" src={item} />
              </div>
            </Grid>
          ))}
        </Grid>
        <div
          style={{
            marginTop: "40px",
            textAlign: "center",
          }}
        >
          <ThemeProvider theme={interFont}>
            <Typography
              style={{
                fontWeight: 500,
                fontSize: "24px",
                lineHeight: "32px",
                color: "#652D8D",
              }}
            >
              Stay Tuned
            </Typography>
            <Typography
              style={{
                fontWeight: 500,
                fontSize: "24px",
                lineHeight: "32px",
                color: "#FE1A9B",
              }}
            >
              What&apos;s next coming up!
            </Typography>
          </ThemeProvider>
        </div>
      </div>

      <LandingFooter
        type="home"
        captions="Streamlining business processes for managing
entire cycle of royalty management platform"
      />
    </>
  );
}

export default Home;
