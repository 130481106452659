import {
  Button,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  createTheme,
} from "@material-ui/core";
import { ThemeProvider, makeStyles } from "@material-ui/styles";
import axios from "axios";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { hardBaseUrl } from "../../services/urlConstant";

const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});
const useStyles = makeStyles(() => ({
  title: {
    marginTop: "5px",
    color: "#8f8f8f",
    fontSize: "14px",
  },
  subtitle: {
    fontSize: "18px",
    fontWeight: "bold",
    lineHeight: "28px",
  },
  boxContent: {
    border: "1px solid #D1D5DC",
    padding: "16px",
    borderRadius: "8px",
  },
  suffix: {
    color: "#111827",
  },
  buttonSave: {
    height: "44px",
    marginTop: "20px",
    width: "fit-content",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "24px",
    backgroundColor: "black",
    color: "white",
    textTransform: "none",
  },
  textButtonSave: {
    color: "white",
    fontSize: "14px",
  },
  capitalize: {
    textTransform: "capitalize",
  },
}));

function DashboardLandingPage() {
  const classes = useStyles();
  const [data, setData] = useState({
    listContent: [],
    loading: false,
  });

  const { listContent } = data;

  const changeValue = (key, value) =>
    setData(currentState => ({
      ...currentState,
      [key]: value,
    }));

  const token = localStorage.getItem("token");
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const getData = () => {
    const url = `${hardBaseUrl}/landing-page/module/dashboard`;
    axios
      .get(url, config)
      .then(res => changeValue("listContent", res?.data?.data || []))
      .catch(err => new Error(err));
  };

  const submitValue = data => {
    const value = {
      module: "dashboard",
      data: data,
    };
    const url = `${hardBaseUrl}/landing-page/update`;
    axios
      .post(url, value, config)
      .then(() =>
        Swal.fire({
          icon: "success",
          title: "Success",
        }).then(() => {
          getData();
        })
      )
      .catch(err => new Error(err));
  };

  const handleChange = (key, e) => {
    const newData = [...listContent];
    const filteredData = [...newData]?.filter(({ id }) => id !== key);
    const getDataByKey = [...listContent]?.find(({ id }) => id === key);
    const newValue = {
      ...getDataByKey,
      url: e?.target?.value,
    };
    changeValue("listContent", [...filteredData, newValue] || []);
  };

  useEffect(() => {
    getData();
  }, []);

  const sizeOfBoxContent = listContent?.length > 1 ? 7 : 6;
  const spaceBetweenContent = listContent?.length > 1 ? 2 : 0;
  const sortData = (listContent || [])?.sort((a, b) => a?.id - b?.id);

  return (
    <Grid container direction="column">
      <Grid item xs={4} md={4} sm={4} lg={8} xl={8}>
        <ThemeProvider theme={theme}>
          <Typography variant="h3" className={classes.subtitle}>
            Dashboard
          </Typography>
        </ThemeProvider>
        <ThemeProvider theme={theme}>
          <p className={classes.title}>
            This page is for manage & settings the dashboard content
          </p>
        </ThemeProvider>
      </Grid>

      <Grid
        className={classes.boxContent}
        item
        xs={sizeOfBoxContent}
        md={sizeOfBoxContent}
        sm={sizeOfBoxContent}
        lg={sizeOfBoxContent}
        xl={sizeOfBoxContent}
      >
        <Grid container spacing={spaceBetweenContent}>
          {sortData?.map(({ id, type, url }, index, currentData) => {
            const isLastIndex = index + 1 === currentData?.length;
            const sizeGrid = isLastIndex && index % 2 === 0 ? 12 : 6;
            const capitalizeType =
              (type || "").charAt(0)?.toUpperCase() + type?.slice(1);
            return (
              <Grid
                item
                key={id || index}
                xs={sizeGrid}
                md={sizeGrid}
                sm={sizeGrid}
                lg={sizeGrid}
                xl={sizeGrid}
              >
                <Typography>{`Link ${capitalizeType} Video`}</Typography>
                <TextField
                  variant="outlined"
                  onChange={value => handleChange(id, value)}
                  name="name"
                  size="medium"
                  id="formatted-numberformat-input"
                  value={url}
                  margin="dense"
                  fullWidth={true}
                  placeholder={`Link ${capitalizeType} Video`}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" className={classes.suffix}>
                        .com
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            );
          })}
        </Grid>
      </Grid>

      <Button
        variant="contained"
        className={classes.buttonSave}
        onClick={() => submitValue(listContent)}
      >
        <Typography className={classes.textButtonSave}>Save</Typography>
      </Button>
    </Grid>
  );
}

export default DashboardLandingPage;
