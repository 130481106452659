import { Button, Divider, Drawer } from "@material-ui/core";
import React from "react";
import "./LandingStyle.css";
import newlogompis from "../../../assets/images/newlogompis.png";

function LandingNavBar({
  openMobile,
  onMobileClose,
  headerMenu,
  headerButton,
}) {
  const content = (
    <div className="landing-nav-bar">
      <a href="/home" className="landing-nav-logo">
        <img
          alt={"new-mpis"}
          className="header-logo-mpis"
          src={newlogompis}
          style={{
            height: "80px",
          }}
        />
      </a>
      <div className="landing-header-nav-menu-container">
        {headerMenu?.map((item, index) => (
          <div className="landing-header-nav-menu" key={index}>
            <a className="nav-menu" href={item?.link}>
              {item?.title}
            </a>
          </div>
        ))}
      </div>
      <Divider className="nav-divider" />
      {headerButton?.map((item, index) => (
        <div key={index}>
          <Button
            variant="text"
            className="landing-header-nav-action"
            onClick={item?.action}
          >
            {item?.title}
          </Button>
        </div>
      ))}
    </div>
  );
  return (
    <Drawer
      anchor="right"
      onClose={onMobileClose}
      open={openMobile}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
}

export default LandingNavBar;
