import { BackupOutlined } from "@material-ui/icons";
import { Box, Grid, Typography } from "@mui/material";
import { AttachmentFilesCard, FormLabel, ModalError } from "components";
import { useCallback, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { globalStyles } from "styles";
import { formatFileSize } from "utils";

function SupportingDocumentForm({
  supportingDocumentFiles,
  setSupportingDocumentFiles,
}) {
  const classes = globalStyles();
  const onDrop = useCallback(acceptedFiles => {
    if (acceptedFiles[0].size > 2000000) {
      ModalError("File size cannot be more than 2mb");
    } else {
      setSupportingDocumentFiles(acceptedFiles);
    }
  }, []);
  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
  } = useDropzone({
    onDrop,
    accept: ".pdf",
  });

  const handleRemoveFile = idx => {
    setSupportingDocumentFiles(
      supportingDocumentFiles?.filter((_, index) => index !== idx)
    );
    acceptedFiles.splice(idx, 1);
  };
  const dropzoneStyle = useMemo(() => {
    return isDragActive ? classes?.dragActive : "";
  }, [isDragActive, classes?.activeStyle]);

  return (
    <Box border="1px solid #ebebeb" borderRadius="5px" p="24px">
      <FormLabel label="Upload Document" />
      <div
        {...getRootProps({
          className: `${classes?.dragFilesContainer} ${dropzoneStyle}`,
        })}
      >
        <input {...getInputProps()} />
        <Box color="grey">
          <BackupOutlined className={classes?.uploadIcon} />
        </Box>
        <Typography>
          {isDragActive
            ? "Drop the files here..."
            : "Drag files or click to upload"}
        </Typography>
      </div>
      {acceptedFiles?.length > 0 && (
        <Box mt="16px">
          <FormLabel label="Files :" />
          <Grid container direction="column" rowSpacing={1}>
            {acceptedFiles?.map(({ name, file_path, size }, index) => (
              <Grid item key={index}>
                <AttachmentFilesCard
                  fileName={name}
                  fileSize={formatFileSize(size)}
                  filePath={file_path}
                  tooltipRemove="Remove File"
                  handleRemove={handleRemoveFile}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
    </Box>
  );
}

export default SupportingDocumentForm;
