import { Box, Grid } from "@mui/material";
import axios from "axios";
import {
  ImportDocumentModal,
  ModalError,
  ModalPreviewDocument,
  ModalSuccess,
  PrimaryButton,
  SearchTextInput,
  SelectInput,
  TableAction,
} from "components";
import { InnoTableV2 } from "inno-ui";
import { useState } from "react";
import { getErrors } from "utils";
import { hardBaseUrl } from "../../../../services/urlConstant";

const SupportingDocuments = ({
  idKontrak,
  queryParams,
  handleChangeQueryParams,
  getDataTable,
  dataTable,
}) => {
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const [loadingButton, setLoadingButton] = useState(false);
  const [modalDocument, setModalDocument] = useState(false);
  const [modalPreview, setModalPreview] = useState(false);
  const [selectedFile, setSelectedFile] = useState({});

  const handleUploadDocument = async documentFiles => {
    const formData = new FormData();
    formData.append("documents", documentFiles[0]);
    if (idKontrak.contractType === "song") {
      formData.append("contract_id", idKontrak.idKontrak);
    }
    try {
      setLoadingButton(true);
      await axios.post(
        `${hardBaseUrl}/contract-document${
          idKontrak.contractType === "main"
            ? `/contract/composer-main?contract_id=${idKontrak.idKontrak}`
            : ""
        }`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      ModalSuccess("Document has been uploaded").then(() => getDataTable());
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingButton(false);
      setModalDocument(false);
    }
  };
  const deleteDocument = async id => {
    try {
      const payload = {
        ids: [id],
      };
      await axios.delete(
        `${hardBaseUrl}/contract-document${
          idKontrak.contractType === "song" ? "" : "/contract/composer-main"
        }`,
        { data: payload, headers }
      );
      ModalSuccess("Document has been deleted").then(() => getDataTable());
    } catch (error) {
      ModalError(getErrors(error?.response));
    }
  };
  return (
    <Box>
      <Grid container justifyContent="space-between" mb="16px">
        <Grid item alignItems="center">
          <SearchTextInput
            value={queryParams?.search}
            onChange={e => handleChangeQueryParams(e?.target?.value, "search")}
            placeholder="Search"
          />
        </Grid>
        <Grid item>
          <Grid container columnSpacing={1} alignItems="center">
            <Grid item>
              <SelectInput
                options={optionSort}
                optionKey="key"
                optionLabel="value"
                value={queryParams?.type}
                onChange={e =>
                  handleChangeQueryParams(e?.target?.value, "type")
                }
                width={200}
              />
            </Grid>
            <Grid item>
              <PrimaryButton
                label="Import Document"
                onClick={() => setModalDocument(true)}
                size="large"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <InnoTableV2
        isLoading={false}
        columns={columnTable}
        items={dataTable}
        isHaveAction
        renderAction={item => (
          <TableAction
            handleView={() => {
              setModalPreview(true);
              setSelectedFile(item);
            }}
            tooltipView="View"
            deleteConfirmation
            deleteConfirmationTitle="Are you sure you want to delete this document?"
            deleteConfirmationKey="this document"
            handleDelete={() => deleteDocument(item?.contract_document_id)}
          />
        )}
      />
      <ModalPreviewDocument
        open={modalPreview}
        onClose={() => setModalPreview(false)}
        preload={selectedFile}
      />
      {modalDocument && (
        <ImportDocumentModal
          open={modalDocument}
          onClose={() => setModalDocument(false)}
          onSubmit={handleUploadDocument}
          fileType=".pdf"
          loadingButton={loadingButton}
          multiple
        />
      )}
    </Box>
  );
};

const optionSort = [
  {
    key: "-created_at",
    value: "Latest",
  },
  {
    key: "created_at",
    value: "Oldest",
  },
];
const columnTable = [
  {
    name: "filename",
    title: "Document Title",
    renderText: item => item || "-",
  },
  {
    name: "first_name",
    title: "Uploaded At",
  },
];
export default SupportingDocuments;
