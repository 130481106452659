import {
  Divider,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import {
  ThemeProvider,
  createTheme,
  makeStyles,
} from "@material-ui/core/styles";

import { useRef, useState } from "react";
import "react-multi-carousel/lib/styles.css";
import Slider from "react-slick";
import bgImage from "../../../assets/background/background_about_illustrative.webp";
import bgShade from "../../../assets/background/background_about_vector.webp";
import navLeft from "../../../assets/icons/icon_nav_left_landing.svg";
import navRight from "../../../assets/icons/icon_nav_right_landing.svg";
import sliderIllustrative1 from "../../../assets/images/illustrative_about_1.png";
import sliderIllustrative2 from "../../../assets/images/illustrative_about_2.png";
import sliderIllustrative3 from "../../../assets/images/illustrative_about_3.png";
import LandingFooter from "../../../components/templates/LandingPage/LandingFooter";
import LandingHero from "../../../components/templates/LandingPage/LandingHero";
import "../../../components/templates/LandingPage/LandingStyle.css";
import "./About.css";
import { CarouselCard } from "./CarouselCard";
import { OurTeams } from "./OurTeams";

export const interFont = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const useStyles = makeStyles(() => ({
  divider: {
    width: "500px",
    height: "1.5px",
    background: "#642C8C",
    margin: "0 40px",
  },
  buttonStarted: {
    color: "white",
    borderRadius: "20px",
    background: "linear-gradient(274.37deg, #FE1A9B -15.6%, #642C8C 101.68%)",
    textTransform: "none",
    border: "solid 1px white",
    marginTop: "28px",
  },
}));

function About() {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const sliderRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);

  const settingsRs = {
    centerMode: true,
    centerPadding: 0,

    arrows: false,
    infinite: true,
    slidesToShow: isMobile ? 1 : 3,
    slidesToScroll: 1,
    speed: 500,
    dots: false,
    beforeChange: (current, next) => {
      setCurrentSlide(next);
    },
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const nextSlide = () => {
    sliderRef.current.slickNext();
  };
  const prevSlide = () => {
    sliderRef.current.slickPrev();
  };

  const heroBackground = () => {
    return (
      <>
        <img
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
          alt="ellipse-thumbnail"
          src={bgShade}
        />
        <img
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
          alt="ellipse-thumbnail"
          src={bgImage}
        />
      </>
    );
  };

  const heroContent = () => {
    return (
      <>
        <ThemeProvider theme={interFont}>
          <Typography
            style={{
              fontWeight: 800,
              fontSize: isMobile ? "60px" : "100px",
              color: "white",
              lineHeight: isMobile ? "60px" : "90px",
              maxWidth: "400px",
            }}
          >
            What Is MPIS ?
          </Typography>
          <Typography
            style={{
              fontWeight: 400,
              color: "white",
              fontSize: isMobile ? "16px" : "24px",
              marginTop: isMobile ? "12px" : "20px",
              maxWidth: "797px",
            }}
          >
            Music publisher information system (MPIS) is an easy platform for
            music publisher to monitor cleareance and royalty calculations from
            digital streaming platform (DSP). MPIS provides flexible services
            for the music industry market in reporting usage of music
            distribution.
          </Typography>
        </ThemeProvider>
        <Button variant="contained" className={classes.buttonStarted}>
          Get Started
        </Button>
      </>
    );
  };

  const carouselList = [
    {
      image: sliderIllustrative1,
      title: "Start Your",
      titleHightlight: "Journey",
      content:
        "Embark on a journey toward simplified reporting by initiating contact with our representatives. Our team will guide you through the onboarding process, ensuring a seamless introduction to our platform.",
    },
    {
      image: sliderIllustrative2,
      title: "Optimize Your",
      titleHightlight: "Catalog",
      content:
        "Enhance your music catalog with precision and professionalism. Synchronize your catalog using the industry-standard format. This approach guarantees consistency and positions your content for maximum accessibility in music usage reports.",
    },
    {
      image: sliderIllustrative3,
      title: "Unify Your",
      titleHightlight: "Reporting",
      content:
        "Experience the power of consolidated reporting. Your data is securely stored and effortlessly merged on our platform. You will gain access to a single-point dashboard, empowering you to seamlessly monitor, analyze, and strategize based on comprehensive insights.",
    },
    {
      image: sliderIllustrative1,
      title: "Start Your",
      titleHightlight: "Journey",
      content:
        "Embark on a journey toward simplified reporting by initiating contact with our representatives. Our team will guide you through the onboarding process, ensuring a seamless introduction to our platform.",
    },
    {
      image: sliderIllustrative2,
      title: "Optimize Your",
      titleHightlight: "Catalog",
      content:
        "Enhance your music catalog with precision and professionalism. Synchronize your catalog using the industry-standard format. This approach guarantees consistency and positions your content for maximum accessibility in music usage reports.",
    },
    {
      image: sliderIllustrative3,
      title: "Unify Your",
      titleHightlight: "Reporting",
      content:
        "Experience the power of consolidated reporting. Your data is securely stored and effortlessly merged on our platform. You will gain access to a single-point dashboard, empowering you to seamlessly monitor, analyze, and strategize based on comprehensive insights.",
    },
  ];

  return (
    <>
      <LandingHero heroBackground={heroBackground} heroContent={heroContent} />
      <div className="landing-content">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              maxWidth: isMobile ? "365px" : "1200px",
              position: "relative",
            }}
          >
            <Slider ref={sliderRef} {...settingsRs}>
              {carouselList?.map((item, index) => {
                const selected = index === currentSlide;
                return (
                  <CarouselCard
                    key={index}
                    item={item}
                    selected={selected}
                    index={index}
                  />
                );
              })}
            </Slider>
            <div
              style={{
                display: "flex",
                justifyContent: isMobile && "center",
                alignItems: "center",
                position: isMobile ? "unset" : "absolute",
                top: !isMobile && "320px",
                left: !isMobile && "50%",
                transform: !isMobile && "translate(-50%)",
                gap: isMobile && "16px",
              }}
            >
              <IconButton
                style={{
                  height: "32px",
                  width: "32px",
                  zIndex: 2,
                }}
                onClick={prevSlide}
              >
                <img alt="nav-left" src={navLeft} />
              </IconButton>
              {!isMobile && <Divider className={classes.divider} />}
              <IconButton
                style={{
                  height: "32px",
                  width: "32px",
                  zIndex: 2,
                }}
                onClick={nextSlide}
              >
                <img alt="nav-right" src={navRight} />
              </IconButton>
            </div>
          </div>
        </div>
      </div>
      <OurTeams />
      <LandingFooter captions="Easy to access and customizable music royalty dashboard" />
    </>
  );
}
export default About;
