import axios from "axios";
import { ModalError, ModalSuccess } from "components";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useHistory, useLocation } from "react-router-dom";
import { getErrors } from "utils";
import { hardBaseUrl } from "../../../services/urlConstant";
import ContractForm from "./Components/ContractForm";

function EditContract() {
  const history = useHistory();
  const { state } = useLocation();
  const { id } = useParams();
  const contractType =
    state?.customer?.category === "song" ? "Song Contract" : "Main Contract";
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: "Bearer " + token,
  };
  const isSongContract = contractType === "Song Contract";
  const urlContract = `${hardBaseUrl}/publisher/contract`;
  const mainContractFormEndpoint = !isSongContract ? "/composer-main" : "";
  const [loadingContract, setLoadingContract] = useState(false);
  const [contractDetailPreload, setContractDetailPreload] = useState({});

  const getDetailContract = async () => {
    try {
      setLoadingContract(true);
      const res = await axios.get(
        `${urlContract}${mainContractFormEndpoint}/${Number(id)}`,
        { headers }
      );
      setContractDetailPreload(res?.data?.data);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingContract(false);
    }
  };
  const handlSubmitData = async payload => {
    try {
      setLoadingContract(true);
      await axios.put(
        `${urlContract}${mainContractFormEndpoint}/${Number(id)}`,
        payload,
        { headers }
      );
      ModalSuccess().then(() => history.goBack());
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingContract(false);
    }
  };

  useEffect(() => {
    getDetailContract();
  }, []);

  return (
    <ContractForm
      title="Edit Contract"
      handlSubmitData={handlSubmitData}
      loadingContract={loadingContract}
      stateContractType={contractType}
      preload={contractDetailPreload}
      contractId={id}
    />
  );
}

export default EditContract;
