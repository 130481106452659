import { Divider } from "@material-ui/core";
import { Box, Container, Grid, Typography } from "@mui/material";
import axios from "axios";
import {
  ArrayChip,
  CurrencyNumberInput,
  CustomTable,
  FormLabel,
  ModalError,
  ModalSuccess,
  MultipleSelectInput,
  Page,
  PrimaryButton,
  SectionLabel,
  SelectInput,
  SkeletonComponent,
  TextInput,
} from "components";
import { API_ENDPOINTS } from "constants";
import { HeaderTitle } from "layouts";
import { getTotalPage } from "lib";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { hardBaseUrl } from "services/urlConstant";
import { globalStyles } from "styles";
import {
  arrayToCommaSeparatedString,
  getErrors,
  paginateDataTable,
} from "utils";
import {
  ModalAddSongAlias,
  ModalComposerConfiguration,
  ModalPerformer,
} from "./Components";

const FormParameterSong = () => {
  const classes = globalStyles();
  const history = useHistory();
  const { id } = useParams();
  const pageTitle = id ? "Edit Song" : "Add Song";
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const [loadingPage, setLoadingPage] = useState(false);
  const [optionOriginalPublisher, setOptionOriginalPublisher] = useState([]);
  const [optionRole, setOptionRole] = useState([]);
  const [modalSongAlias, setModalSongAlias] = useState(false);
  const [modalPerformer, setModalPerformer] = useState(false);
  const [modalComposer, setModalComposer] = useState(false);
  const [dataTableAlias, setDataTableAlias] = useState([]);
  const [selectedAlias, setSelectedAlias] = useState(null);
  const [selectedPerformer, setSelectedPerformer] = useState(null);
  const [composerConfiguration, setComposerConfiguration] = useState(true);
  const [payload, setPayload] = useState({
    song_id: 0,
    song_title: "",
    iswc_code: "",
    original_publisher_id: 0,
    work_code: "",
    song_aliases: [],
    song_composers: [],
    song_recordings: [],
    publisher_share_percentage: 0,
  });
  const [queryParams, setQueryParams] = useState({
    page: 1,
    size: 10,
  });
  let tableTotalPage =
    getTotalPage(payload?.song_recordings?.length, queryParams?.size) || 1;

  const handleChangeQueryParams = (value, key) => {
    setQueryParams(currentState => ({
      ...currentState,
      ...(key !== "page" && { page: 1 }),
      [key]: value,
    }));
  };
  const handleChangePayload = (value, key) => {
    setPayload(prev => ({ ...prev, [key]: value }));
  };
  const handleAddAlias = value => {
    setDataTableAlias(prev => {
      if (selectedAlias) {
        return prev.map(alias =>
          alias.id === selectedAlias?.id ? { ...alias, name: value } : alias
        );
      } else {
        const newId = prev.length > 0 ? prev[prev.length - 1].id + 1 : 1;
        return [...prev, { id: newId, name: value }];
      }
    });
  };
  const handleAddPerformer = value => {
    const { performers, isrc_code } = value;
    setPayload(prev => {
      const newRecording = {
        id: selectedPerformer?.id || prev?.song_recordings?.length + 1,
        isrc_code,
        performers,
      };

      const updatedRecordings = selectedPerformer
        ? prev?.song_recordings?.map(recording =>
            recording.id === selectedPerformer.id ? newRecording : recording
          )
        : [...prev?.song_recordings, newRecording];

      return { ...prev, song_recordings: updatedRecordings };
    });
  };
  const handleAddComposer = value => {
    setPayload(prev => ({
      ...prev,
      song_composers: value,
    }));
  };
  const handleChangeComposerTable = (value, key, item, option) => {
    setPayload(prev => ({
      ...prev,
      song_composers: prev.song_composers.map(composer => {
        if (composer.composer_id !== item?.composer_id) return composer;
        let updatedComposer = { ...composer };
        if (key === "composer_roles") {
          updatedComposer.composer_roles = value
            ? [...new Set([...composer.composer_roles, option])]
            : composer.composer_roles.filter(role => role !== option);
        } else {
          updatedComposer[key] = value;
        }

        return updatedComposer;
      }),
    }));
  };

  const getOptionOriginalPublisher = async () => {
    try {
      const res = await axios.get(
        API_ENDPOINTS.GET_PUBLISHER_SONG_ORIGINAL_PUBLISHER,
        { headers }
      );
      setOptionOriginalPublisher(res?.data?.data);
    } catch (error) {
      ModalError(getErrors(error?.response));
    }
  };
  const getOptionRole = async () => {
    try {
      const res = await axios.get(API_ENDPOINTS.GET_WRITER_ROLE, { headers });
      const modifiedData = res?.data?.data?.map(item => ({
        id: item?.writer_role_code,
        label: item?.writer_role_name,
      }));
      setOptionRole(modifiedData);
    } catch (error) {
      ModalError(getErrors(error?.response));
    }
  };
  const getDetail = async () => {
    try {
      const res = await axios.get(`${hardBaseUrl}/songs/${id}`, { headers });
      const { data } = res?.data;
      const modifiedData = {
        ...data,
        song_composers: data?.song_composers?.map(composer => ({
          ...composer,
          composer_roles: composer?.composer_roles?.map(role => ({ id: role })),
        })),
        song_recordings: (data?.song_recordings || [])?.map(song => ({
          ...song,
          performers: song?.performers?.map(performer => ({
            ...performer,
            first_name: performer?.performer_name,
          })),
        })),
      };
      setPayload(modifiedData);
      setComposerConfiguration(data?.is_song_composer_editable);
      setDataTableAlias(
        data?.song_aliases?.map((alias, index) => ({
          id: index + 1,
          name: alias,
        }))
      );
    } catch (error) {
      ModalError(getErrors(error?.response));
    }
  };
  const fetcData = async () => {
    setLoadingPage(true);
    const promises = [getOptionOriginalPublisher(), getOptionRole()];
    if (id) promises.push(getDetail());
    try {
      await Promise.all(promises);
    } catch (error) {
      ModalError(error, "Error fetching data");
    } finally {
      setLoadingPage(false);
    }
  };
  const handleSubmit = async () => {
    const method = id ? axios.put : axios.post;
    const { song_composers, song_recordings, ...restPayload } = payload;
    const modifiedPayload = {
      ...restPayload,
      song_aliases: dataTableAlias?.map(alias => alias?.name),
      song_composers: song_composers?.map(song => ({
        composer_id: song?.composer_id,
        composer_percentage: song?.composer_percentage,
        composer_roles: song?.composer_roles?.map(role => role?.id),
        share_percentage: song?.share_percentage,
      })),
      song_recordings: song_recordings?.map(record => ({
        isrc_code: record?.isrc_code,
        performers: record?.performers?.map(performer => ({
          performer_id: performer?.performer_id,
          performer_name: performer?.performer_name || performer?.first_name,
        })),
        song_recording_id: record?.song_recording_id,
      })),
    };
    try {
      setLoadingPage(true);
      await method(
        `${hardBaseUrl}/songs${id ? `/${id}` : ""}`,
        modifiedPayload,
        { headers }
      );
      ModalSuccess(`Successfully ${id ? "save" : "add"} song`).then(() =>
        history.goBack()
      );
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };

  useEffect(() => {
    fetcData();
  }, []);
  useEffect(() => {
    const sum = payload?.song_composers.reduce(
      (a, b) => Number(a) + Number(b.composer_percentage),
      0
    );
    handleChangePayload(sum, "publisher_share_percentage");
  }, [payload?.song_composers]);

  useEffect(() => {
    if (queryParams.page > tableTotalPage) {
      setQueryParams(currentState => ({
        ...currentState,
        page: currentState?.page - 1,
      }));
    }
  }, [payload?.song_recordings, tableTotalPage]);

  return (
    <Page className={classes.root} title={`${id ? "Edit" : "Add"} Song`}>
      {loadingPage ? (
        <SkeletonComponent variant="wave" />
      ) : (
        <Container maxWidth={false}>
          <HeaderTitle
            title={pageTitle}
            breadcrumbData={breadcrumbData({ pageTitle })}
            backButton
          />
          <Divider className={classes?.divider} />
          <SectionLabel
            title="Song Information"
            subTitle="This feature is used to add a new song."
            my="16px"
          />
          <Box border="1px solid #ebebeb" borderRadius="5px" p="24px" my="16px">
            <Grid container columnSpacing={2} rowSpacing={1}>
              <Grid item xs={12} md={6}>
                <FormLabel label="Song Title" required />
                <TextInput
                  placeholder="Song Title"
                  value={payload?.song_title}
                  onChange={e =>
                    handleChangePayload(e?.target?.value, "song_title")
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormLabel label="ISWC Code" />
                <TextInput
                  placeholder="ISWC Code"
                  value={payload?.iswc_code}
                  onChange={e =>
                    handleChangePayload(e?.target?.value, "iswc_code")
                  }
                  helperText="eg. US/Z03/21/9102"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormLabel label="Original Publisher" />
                <SelectInput
                  label={
                    payload?.original_publisher_id ? null : "Original Publisher"
                  }
                  options={optionOriginalPublisher}
                  optionKey="original_publisher_id"
                  optionLabel="name"
                  value={payload?.original_publisher_id || ""}
                  placeholder="None"
                  onChange={e =>
                    handleChangePayload(
                      Number(e?.target?.value),
                      "original_publisher_id"
                    )
                  }
                  width="100%"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormLabel label="Work Code" required />
                <TextInput
                  placeholder="Work Code"
                  value={payload?.work_code}
                  onChange={e =>
                    handleChangePayload(e?.target?.value, "work_code")
                  }
                />
              </Grid>
            </Grid>
          </Box>
          <Divider className={classes?.divider} />
          <Grid container justifyContent="space-between" my="16px">
            <Grid item>
              <SectionLabel title="Song Alias" />
            </Grid>
            <Grid item>
              <PrimaryButton
                label="Add Song Alias"
                onClick={() => setModalSongAlias(true)}
                size="large"
              />
            </Grid>
          </Grid>
          <CustomTable
            columnTable={columnTableAlias}
            data={dataTableAlias}
            isHaveAction
            handleEdit={item => {
              setModalSongAlias(true);
              setSelectedAlias(item);
            }}
            handleDelete={item => {
              setDataTableAlias(prev =>
                prev.filter(alias => alias.id !== item?.id)
              );
            }}
          />
          <Divider className={classes?.divider} />
          <Grid container justifyContent="space-between" my="16px">
            <Grid item>
              <SectionLabel
                title="Performer"
                subTitle="Add Performer list for song's information"
              />
            </Grid>
            <Grid item>
              <PrimaryButton
                label="Add Performer"
                onClick={() => setModalPerformer(true)}
                size="large"
              />
            </Grid>
          </Grid>
          <CustomTable
            columnTable={columnTablePerformer}
            data={paginateDataTable({
              data: payload?.song_recordings,
              page: queryParams?.page,
              size: queryParams?.size,
            })}
            page={queryParams?.page}
            pageSize={queryParams?.size}
            pageCount={tableTotalPage}
            handleChangePage={(_, page) =>
              handleChangeQueryParams(page, "page")
            }
            handleChangePageSize={e =>
              handleChangeQueryParams(e?.target?.value, "size")
            }
            isHaveAction
            handleEdit={item => {
              setModalPerformer(true);
              setSelectedPerformer(item);
            }}
            handleDelete={item => {
              setPayload(prev => ({
                ...prev,
                song_recordings: prev.song_recordings.filter(
                  recording => recording.isrc_code !== item?.isrc_code
                ),
              }));
            }}
          />
          <Divider className={classes?.divider} />
          <Grid container justifyContent="space-between" my="16px">
            <Grid item>
              <SectionLabel
                title="Composer/ Author"
                subTitle="This is the composer/author of the song"
              />
            </Grid>
            <Grid item>
              <Grid container columnSpacing={1} alignItems="center">
                <Grid item>
                  <Typography textAlign="right" fontSize={24}>
                    Percentage :{" "}
                    <Typography
                      component="span"
                      fontSize={24}
                      color={payload?.publisher_share_percentage > 100 && "red"}
                    >
                      {`${payload?.publisher_share_percentage} %`}
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item>
                  <PrimaryButton
                    label="Composer/Author Configuration"
                    onClick={() => setModalComposer(true)}
                    disabled={!composerConfiguration}
                    size="large"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <CustomTable
            columnTable={columnTableComposer({
              optionRole,
              handleChangeComposerTable,
            })}
            data={payload?.song_composers}
          />
          <Divider className={classes?.divider} />
          <Grid container justifyContent="right" my="16px">
            <PrimaryButton label={id ? "Save" : "Add"} onClick={handleSubmit} />
          </Grid>
        </Container>
      )}
      <ModalAddSongAlias
        open={modalSongAlias}
        onClose={() => {
          setModalSongAlias(false);
          setSelectedAlias(null);
        }}
        preload={selectedAlias?.name}
        handleSubmit={handleAddAlias}
      />
      {modalPerformer && (
        <ModalPerformer
          open={modalPerformer}
          onClose={() => {
            setModalPerformer(false);
            setSelectedPerformer(null);
          }}
          preload={selectedPerformer}
          handleSubmit={handleAddPerformer}
          selectedOptions={payload?.song_recordings}
        />
      )}
      <ModalComposerConfiguration
        open={modalComposer}
        onClose={() => setModalComposer(false)}
        preload={payload?.song_composers}
        handleSubmit={handleAddComposer}
      />
    </Page>
  );
};
const breadcrumbData = ({ pageTitle }) => [
  {
    label: "Parameter",
    link: "/admin/parameter/lagu",
  },
  {
    label: "Song",
    link: "/admin/parameter/lagu",
  },
  {
    label: pageTitle,
    active: true,
  },
];
const columnTableAlias = [
  {
    name: "name",
    title: "Song Alias",
    renderText: item => item || "-",
  },
];
const columnTablePerformer = [
  {
    name: "isrc_code",
    title: "ISRC Code",
    renderText: item => item || "-",
  },
  {
    name: "performers",
    title: "Performer",
    renderText: item => (
      <ArrayChip list={item.map(performer => performer?.first_name)} />
    ),
  },
];
const columnTableComposer = ({ optionRole, handleChangeComposerTable }) => [
  {
    name: "sure_name",
    title: "Composer/Author Name",
    renderText: item => item || "-",
  },
  {
    name: "alias_name",
    title: "Alias Name",
    renderText: item => item || "-",
  },
  {
    name: "all",
    title: "Owenership Percentage",
    renderText: item => (
      <CurrencyNumberInput
        value={item?.composer_percentage}
        onChange={value =>
          handleChangeComposerTable(value || 0, "composer_percentage", item)
        }
        endAdornment="%"
        disabled={!item?.is_editable || false}
      />
    ),
  },
  {
    name: "all",
    title: "Role",
    renderText: item => (
      <MultipleSelectInput
        placeholder="Composer Role"
        value={item?.composer_roles || []}
        textValue={arrayToCommaSeparatedString(item?.composer_roles, "id")}
        options={optionRole}
        optionKey="id"
        optionLabel="label"
        onChange={(event, option) =>
          handleChangeComposerTable(event, "composer_roles", item, option)
        }
        disabled={!item?.is_editable || false}
        width="100%"
      />
    ),
  },
  {
    name: "all",
    title: "Publisher Percentage",
    renderText: item => (
      <CurrencyNumberInput
        value={item?.share_percentage}
        onChange={value =>
          handleChangeComposerTable(value || 0, "share_percentage", item)
        }
        endAdornment="%"
        disabled={!item?.is_editable || false}
      />
    ),
  },
];
export default FormParameterSong;
