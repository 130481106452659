import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { getDatePickerMonth, setDatePickerMonth } from "utils";

const MonthPicker = ({ value, onChange, label, width }) => {
  const dateValue = value ? setDatePickerMonth(value) : null;
  const handleChangeMonth = month => {
    const formattedMonth = month ? getDatePickerMonth(month) : null;
    onChange(formattedMonth);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker
        label={label || "Month"}
        views={["month"]}
        value={dateValue}
        onChange={handleChangeMonth}
        slotProps={{
          textField: { size: "small", style: { width: width || 220 } },
          field: { clearable: true },
        }}
      />
    </LocalizationProvider>
  );
};

export default MonthPicker;
