import { CurrencyDisplay } from "components";
import { Box, Grid, styled, Typography } from "@mui/material";

const ReportSummaryCard = ({ title, value, isCurrency, hidden, maxWidth }) =>
  !hidden && (
    <SummaryCard maxWidth={maxWidth}>
      <SummaryTitle>{title}</SummaryTitle>
      <SummaryValue>
        <CurrencyDisplay
          value={value}
          decimalScale={isCurrency && value && 2}
          prefix={isCurrency && "Rp"}
        />
      </SummaryValue>
    </SummaryCard>
  );

const SongUsageTrendSummarytCard = ({ chartReport }) => {
  const reportSummaryList = [
    {
      title: `${chartReport?.month || "-"} Traffic`,
      value: chartReport?.traffic || 0,
      hidden: !chartReport?.month,
    },
    {
      title: `${chartReport?.month || "-"} Revenue`,
      value: chartReport?.revenue || 0,
      isCurrency: true,
      hidden: !chartReport?.month,
    },
    {
      title: "Total Traffic",
      value: chartReport?.totalTraffic || 0,
    },
    {
      title: "Total Revenue",
      value: chartReport?.totalRevenue || 0,
      isCurrency: true,
    },
  ];
  return (
    <Grid container my={2}>
      {reportSummaryList?.map((item, key) => (
        <Grid key={key} item xs={3}>
          <ReportSummaryCard
            title={item?.title}
            value={item?.value}
            isCurrency={item?.isCurrency}
            hidden={item?.hidden}
            maxWidth={item?.maxWidth}
          />
        </Grid>
      ))}
    </Grid>
  );
};

const SummaryCard = styled(Box)(({ maxWidth }) => ({
  borderLeft: "1px solid gray",
  padding: "20px",
  maxWidth: maxWidth,
}));
const SummaryTitle = styled(Typography)(() => ({
  fontSize: "14px",
  fontWeight: 500,
  color: "#687083",
  textAlign: "right",
}));
const SummaryValue = styled(Typography)(() => ({
  fontSize: "24px",
  fontWeight: 500,
  color: "#111827",
}));
export default SongUsageTrendSummarytCard;
