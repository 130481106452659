import {
  Breadcrumbs,
  Button,
  Container,
  Divider,
  Grid,
  Typography,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { ButtonGroupTop } from "../../../../components/atoms/Button";
import { Page } from "components";
import { hardBaseUrl } from "../../../../services/urlConstant";
import Results from "./Results";

const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  results: {
    marginTop: theme.spacing(3),
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  button: {
    padding: "10px 28px 10px 28px",
    backgroundColor: "black",
    color: "white",
    width: "213px",
    height: "40px",
    borderRadius: "6px",
    textTransform: "none",
    whiteSpace: "nowrap",
    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
  subtitle: {
    fontSize: "18px",
    fontWeight: "bold",
    lineHeight: "28px",
  },
  breadcrumbs: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#111827",
  },
  breadCrumbsActive: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#687083",
  },
  pagination: {
    "& .Mui-selected": {
      backgroundColor: "black",
      color: "white",
    },
  },
  pageSize: {
    height: "30px",
    borderRadius: "6px",
    border: "1px solid #D1D5DC",
    paddingLeft: "5px",
    paddingRight: "5px",
    marginTop: "5px",
  },
  paginationText: {
    fontSize: "14px",
    fontWeight: "normal",
    fontStyle: "normal",
  },
}));

function Pembayaran() {
  const classes = useStyles();
  const [response, setResponse] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalData, setTotalData] = useState(1);
  const [page, setPage] = useState(1);
  const search = "";


  const [getColor, setGetColor] = useState("");

  const pagesCount = Math.ceil(totalData / rowsPerPage);

  const handleChangePage = (event, data) => {
    setPage(data);
  };


  const getResponse = () => {
    const theToken = localStorage.getItem("token");
    const url = `${hardBaseUrl}/currency/datatable?page=${page}&per_page=${rowsPerPage}search=${search}`;

    axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",

          Authorization: `Bearer ${theToken}`,
        },
      })
      .then(res => {
        console.log("res", res);
        setResponse(res.data.data);
        setPage(res.data.meta.page);
        setTotalData(res.data.meta.total);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const handleChangeRowsPerPage = event => {
    event.preventDefault();
    setRowsPerPage(event.target.value);

    setPage(1);
  };

  const pageSizePagination = () => {
    return (
      <select
        className={classes.pageSize}
        value={rowsPerPage}
        onChange={handleChangeRowsPerPage}
      >
        {[5, 10, 15].map((page, i) => {
          return (
            <option key={i} value={page}>
              {page}
            </option>
          );
        })}
      </select>
    );
  };

  const handleDelete = id => {
    const url = `${hardBaseUrl}/currency/delete/${id}`;
    let theToken = localStorage.getItem("token");
    axios
      .delete(url, {
        headers: {
          Authorization: `Bearer ${theToken}`,
        },
      })
      .then(res => {
        console.log(res);
        if (res.data.message === "success") {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Data has been deleted",
          }).then(result => {
            if (result.isConfirmed === true) {
              getResponse();
            }
          });
        }
      })
      .catch(err => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: err.response.data.errors[0].message,
        });
      });
  };

  const getMainDsp = async () => {
    try {
      const token = localStorage.getItem("token");
      const url = `${hardBaseUrl}/me`;
      const res = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("res main dsp review", res.data.data);
      setGetColor(res.data.data.publisher.theme_color);
    } catch (e) {
      console.log("error main dsp", e);
    }
  };

  useEffect(() => {
    getMainDsp();
    getResponse();
  }, [page, rowsPerPage, search]);

  return (
    <Page className={classes.root} title="Currency">
      <Container maxWidth={false}>
        <Grid
          alignItems="flex-end"
          container
          justifyContent="space-between"
          spacing={3}
        >
          <Grid item>
            <ThemeProvider theme={theme}>
              <Typography
                style={{
                  fontSize: "24px",
                  fontWeight: "700",
                  lineHeight: "32px",
                }}
              >
                Master
              </Typography>
            </ThemeProvider>
          </Grid>
          <Grid item>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography className={classes.breadCrumbs}>Home</Typography>

              <Typography className={classes.breadCrumbs}>Master</Typography>

              <Typography className={classes.breadCrumbsActive}>
                Currency
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
        <ButtonGroupTop />
        <Divider className={classes.divider} />
        <Grid container>
          <Grid item xs={4} md={4} sm={4} lg={8} xl={8}>
            <ThemeProvider theme={theme}>
              <Typography variant="h3" className={classes.subtitle}>
                Currency
              </Typography>
            </ThemeProvider>
            <ThemeProvider theme={theme}>
              <p
                style={{
                  marginTop: "5px",
                  color: "#8f8f8f",
                  fontSize: "14px",
                }}
              >
                Feature to settings Currency
              </p>
            </ThemeProvider>
            <div
              style={{
                marginTop: "5px",
                marginBottom: "5px",
              }}
            >
              <div
                style={{
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                <Button
                  variant="outlined"
                  className={classes.button}
                  startIcon={<AddIcon />}
                  href="/admin/master/currency/tambah"
                  style={{
                    backgroundColor: getColor,
                  }}
                >
                  <ThemeProvider theme={theme}>
                    <Typography
                      component="span"
                      style={{
                        color: "white",
                      }}
                    >
                      Add Currency
                    </Typography>
                  </ThemeProvider>
                </Button>
              </div>
            </div>

            <div
              style={{
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              {response && (
                <Results
                  className={classes.results}
                  items={response}
                  handleDelete={handleDelete}
                  pageSizePagination={pageSizePagination()}
                  page={page}
                  totalData={pagesCount}
                  handleChangePage={handleChangePage}
                />
              )}
            </div>
          </Grid>
        </Grid>
      </Container>
      <div></div>
    </Page>
  );
}

export default Pembayaran;
