import { Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Box, Container, Grid, Typography } from "@mui/material";
import axios from "axios";
import {
  ModalError,
  ModalSuccess,
  Page,
  SectionLabel,
  SkeletonComponent,
  SwitchInput,
} from "components";
import { HeaderTitle } from "layouts";
import { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router";
import { getErrors } from "utils";
import { hardBaseUrl } from "../../../../services/urlConstant";

function DetailSubReporting() {
  const classes = useStyles();
  const { id } = useParams();
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const [loadingPage, setLoadingPage] = useState(false);
  const [formDetail, setFormDetail] = useState({
    sub_reporting_publisher_name: "",
    dsp_name: "",
    status: "waiting",
    is_active: false,
  });
  const {
    sub_reporting_publisher_name,
    dsp_name,
    status,
    is_active,
  } = formDetail;
  const isWaiting = status === "waiting";

  const getDetail = async () => {
    try {
      setLoadingPage(true);
      const res = await axios.get(
        `${hardBaseUrl}/publisher/sub-reporting/${id}`,
        { headers }
      );
      const { data } = res?.data;
      setFormDetail(data);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  const handleSwitchStatus = async event => {
    const { checked } = event?.target;
    try {
      await axios.put(
        `${hardBaseUrl}/publisher/sub-reporting/change-status/${id}`,
        {
          is_active: checked,
        },
        { headers }
      );
      ModalSuccess(
        `Succesfully ${checked ? "Activate" : "Deactivate"} Sub Reporting`
      ).then(res => res?.isConfirmed && getDetail());
    } catch (error) {
      ModalError(getErrors(error?.response));
    }
  };

  useEffect(() => {
    getDetail();
  }, []);

  return (
    <Page className={classes.root} title="Sub Reporting Detail">
      {loadingPage ? (
        <SkeletonComponent variant="wave" />
      ) : (
        <Container maxWidth={false}>
          <HeaderTitle
            title="Sub Reporting"
            breadcrumbData={breadcrumbData}
            backButton
          />
          <Divider className={classes.divider} />
          <SectionLabel
            title="Sub Reporting Detail"
            subTitle="Detail information about sub reporting"
          />
          <Grid container mt="16px">
            <Grid item xs={8}>
              <Box borderRadius="6px" border="1px solid #9AA2B1" padding="24px">
                <Grid container columnSpacing={2} mb="16px">
                  <Grid item minWidth="200px">
                    <CardLabel
                      title="Publisher"
                      subTitle={sub_reporting_publisher_name}
                    />
                  </Grid>
                  <Grid item>
                    <CardLabel title="DSP" subTitle={dsp_name} />
                  </Grid>
                </Grid>
                <SwitchInput
                  disabled={isWaiting}
                  checked={isWaiting ? false : is_active}
                  onChange={handleSwitchStatus}
                  label={`${is_active ? "Active" : "Inactive"} Sub Reporting`}
                />
              </Box>
            </Grid>
          </Grid>
        </Container>
      )}
    </Page>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
}));
const CardLabel = ({ title, subTitle }) => (
  <Fragment>
    <Typography fontWeight={500} fontSize="18px">
      {title}
    </Typography>
    <Typography mt="6px">{subTitle}</Typography>
  </Fragment>
);
const breadcrumbData = [
  {
    label: "Home",
    link: "/admin/dashboard",
  },
  {
    label: "Configuration",
    link: "/admin/konfigurasi/sub-reporting",
  },
  {
    label: "Sub Reporting",
    link: "/admin/konfigurasi/sub-reporting",
  },
  {
    label: "Sub Reporting Detail",
    active: true,
  },
];
export default DetailSubReporting;
