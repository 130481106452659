import { Container, Divider } from "@material-ui/core";
import { Grid } from "@mui/material";
import axios from "axios";
import {
  ModalBankAccount,
  ModalError,
  ModalSuccess,
  Page,
  PrimaryButton,
  SectionLabel,
  SkeletonComponent,
  TableAction,
} from "components";
import { InnoTableV2 } from "inno-ui";
import { HeaderTitle } from "layouts";
import { getTotalPage } from "lib";
import { useEffect, useRef, useState } from "react";
import { globalStyles } from "styles";
import { getErrors } from "utils";
import { ButtonGroupTop } from "../../../../components/atoms/Button";
import { hardBaseUrl } from "../../../../services/urlConstant";
import { useSelector } from "react-redux";

function Pembayaran() {
  const classes = globalStyles();
  const publisherId = useSelector(state => state.publisherId);
  const token = localStorage.getItem("token");
  const isFirstLoad = useRef(true);
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const [loadingPage, setLoadingPage] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [queryParams, setQueryParams] = useState({
    page: 1,
    size: 10,
  });
  const [tableTotalPage, setTableTotalPage] = useState(1);

  const handleChangeQueryParams = (value, key) => {
    setQueryParams(currentState => ({
      ...currentState,
      ...(key !== "page" && { page: 1 }),
      [key]: value,
    }));
  };

  const [loadingButton, setLoadingButton] = useState(false);
  const [modalFormVisible, setModalFormVisible] = useState(false);
  const [optionBank, setOptionBank] = useState([]);
  const [preload, setPreload] = useState(null);

  const handleCloseModal = () => {
    setModalFormVisible(false);
    setPreload(null);
  };

  const getDataTable = async () => {
    try {
      setLoadingPage(true);
      const res = await axios.get(`${hardBaseUrl}/publisher/bank`, {
        headers,
        params: queryParams,
      });
      const { data, meta } = res?.data;
      setDataTable(data || []);
      const pageCount = getTotalPage(meta?.total, queryParams?.size);
      setTableTotalPage(pageCount || 1);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  const getOptionBank = async () => {
    try {
      const res = await axios.get(`${hardBaseUrl}/publisher/composer/bank`, {
        headers,
      });
      setOptionBank(res?.data?.data);
    } catch (error) {
      ModalError(getErrors(error?.response));
    }
  };
  const fetchData = async () => {
    setLoadingPage(true);
    const promises = [];
    promises.push(getOptionBank());
    promises.push(getDataTable());

    try {
      await Promise.all(promises);
    } catch (error) {
      ModalError(error, "Error fetching data");
    } finally {
      setLoadingPage(false);
      isFirstLoad.current = false;
    }
  };
  const handleSubmitBank = async payload => {
    const method = preload ? axios.put : axios.post;
    try {
      setLoadingButton(true);
      await method(
        `${hardBaseUrl}/publisher/bank${preload ? `/${preload?.publisher_bank_id}` : ""
        }`,
        {
          ...payload,
          publisher_id: publisherId.publisherId,
        },
        { headers }
      );
      handleCloseModal();
      ModalSuccess(`Sucessfully ${preload ? "update" : "add"} bank`).then(() =>
        getDataTable()
      );
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingButton(false);
    }
  };
  const handleDelete = async item => {
    try {
      await axios.delete(
        `${hardBaseUrl}/publisher/bank/${item?.publisher_bank_id}`,
        { headers }
      );
      ModalSuccess("Succesfully delete bank account").then(() =>
        getDataTable()
      );
    } catch (error) {
      ModalError(getErrors(error?.response));
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    if (!isFirstLoad.current) {
      getDataTable();
    }
  }, [queryParams]);

  return (
    <Page className={classes.root} title="Payment">
      {loadingPage ? (
        <SkeletonComponent variant="wave" />
      ) : (
        <Container maxWidth={false}>
          <HeaderTitle title="Configuration" breadcrumbData={breadcrumbData} />
          <ButtonGroupTop />
          <Divider className={classes.flatDivider} />
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            my="16px"
          >
            <Grid item>
              <SectionLabel
                title="Bank Account"
                subTitle="Add bank account data to use for payment options in
                  composer/author"
              />
            </Grid>
            <Grid item>
              <PrimaryButton
                label="Add Bank Account"
                onClick={() => setModalFormVisible(true)}
                size="large"
              />
            </Grid>
          </Grid>
          <InnoTableV2
            isLoading={false}
            columns={columnTable}
            items={dataTable}
            page={queryParams?.page}
            rowsPerPage={queryParams?.size}
            totalPage={tableTotalPage}
            handleChangePage={(_, page) =>
              handleChangeQueryParams(page, "page")
            }
            handleChangeRowsPerPage={e =>
              handleChangeQueryParams(e?.target?.value, "size")
            }
            isHaveAction
            renderAction={item => (
              <TableAction
                handleEdit={() => {
                  setModalFormVisible(true);
                  setPreload(item);
                }}
                handleDelete={() => handleDelete(item)}
                deleteConfirmation
                deleteConfirmationKey={`account number ${item?.account_number}`}
                deleteConfirmationTitle="Delete Payment"
              />
            )}
          />
        </Container>
      )}
      <ModalBankAccount
        open={modalFormVisible}
        onClose={handleCloseModal}
        preload={preload}
        optionBank={optionBank}
        handleSubmit={handleSubmitBank}
        loadingButton={loadingButton}
      />
    </Page>
  );
}

const breadcrumbData = [
  {
    label: "Configuration",
    link: "/admin/konfigurasi/pembayaran",
  },
  {
    label: "Payment",
    active: true,
  },
];
const columnTable = [
  {
    name: "bank",
    title: "Bank",
    renderText: item => (
      <img alt="bank" src={item?.profile_image} width="30%" />
    ),
  },
  {
    name: "account_number",
    title: "Account Number",
  },
  {
    name: "account_name",
    title: "Name",
  },
];
export default Pembayaran;
