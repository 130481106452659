import { Container, Divider, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Grid } from "@mui/material";
import { ButtonGroupTop } from "components/atoms/Button";
import axios from "axios";
import {
  AutoCompleteComponent,
  CurrencyDisplay,
  ModalError,
  Page,
  PrimaryButton,
  SearchTextInput,
  SkeletonComponent,
  StatusChip,
} from "components";
import { InnoTable } from "inno-ui";
import { HeaderTitle } from "layouts";
import { getTotalPage } from "lib";
import { debounce } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { getErrors } from "utils";
import { hardBaseUrl } from "../../../services/urlConstant";

function UploadData() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const urlParams = new URLSearchParams(location.search);
  const paramsSearch = urlParams.get("search");
  const paramsPage = urlParams.get("page");
  const paramsSize = urlParams.get("size");
  const paramsDSP = urlParams.get("dsp");

  const [loadingPage, setLoadingPage] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [queryParams, setQueryParams] = useState({
    page: Number(paramsPage) || 1,
    size: Number(paramsSize) || 10,
    search: paramsSearch || "",
    dsp_id: Number(paramsDSP) || "",
    type: "UD",
  });
  const [tableTotalPage, setTableTotalPage] = useState(1);
  const [optionDsp, setOptionDsp] = useState([]);
  const [selectedDSP, setSelectedDSP] = useState({});

  const handleChangePageParams = (value, key) => {
    urlParams.set(key, value);
    history.push({ search: urlParams.toString() });
  };
  const handleChangeQueryParams = (value, key) => {
    setQueryParams(currentState => ({
      ...currentState,
      ...(key !== "page" && { page: 1 }),
      [key]: value,
    }));
  };
  const handleChangeSearch = event => {
    const value = event?.target?.value;
    handleChangeQueryParams(value, "search");
    handleChangePageParams(value, "search");
  };
  const handleChangePage = page => {
    handleChangeQueryParams(page, "page");
    handleChangePageParams(page, "page");
  };
  const handleChangePageSize = size => {
    handleChangeQueryParams(size, "size");
    handleChangePageParams(size, "size");
  };
  const handleChangeDsp = id => {
    handleChangeQueryParams(id, "dsp_id");
    handleChangePageParams(id, "dsp");
    setSelectedDSP(optionDsp?.find(item => item?.id === id));
  };
  const handleUploadDsp = () => {
    const { dsp_id } = queryParams;
    if (dsp_id) {
      history.push({
        pathname: `/admin/add-upload/${dsp_id}`,
        state: { selectedDsp: selectedDSP },
      });
    } else {
      ModalError("Please select DSP");
    }
  };

  const getOptionDSP = async () => {
    try {
      setLoadingPage(true);
      const res = await axios.get(
        `${hardBaseUrl}/dsp/all?process_method=upload`,
        { headers }
      );
      const { data } = res?.data;
      const modifiedData = [
        {
          id: "",
          label: "None",
        },
        ...data?.map(item => ({
          ...item,
          id: item?.dsp_id,
          label: item?.dsp_name,
        })),
      ];
      setOptionDsp(modifiedData);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  const getDataTable = async () => {
    try {
      setLoadingPage(true);
      const res = await axios.get(`${hardBaseUrl}/process/datatable`, {
        headers,
        params: queryParams,
      });

      const { data, meta } = res?.data;
      setDataTable(data);
      const pageCount = getTotalPage(meta?.total, queryParams?.size || 1);
      setTableTotalPage(pageCount);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  const debounceDataTable = useCallback(
    debounce(() => {
      getDataTable();
    }, 500),
    [queryParams]
  );

  useEffect(() => {
    getOptionDSP();
  }, []);
  useEffect(() => {
    if (queryParams?.dsp_id !== "") {
      debounceDataTable();
    }
    if (queryParams?.page !== paramsPage) {
      handleChangePageParams(queryParams?.page, "page");
    }
    return () => {
      debounceDataTable.cancel();
    };
  }, [queryParams, debounceDataTable]);

  return (
    <Page className={classes.root} title="Upload Data">

      <Container maxWidth={false}>
        <HeaderTitle title="Upload Data" breadcrumbData={breadcrumbData} />
        <ButtonGroupTop />
        <Box marginBottom="20px">
          <Divider />
        </Box>
        <Grid
          container
          display="flex"
          justifyContent="space-between"
          my="16px"
        >
          <Grid item>
            <SearchTextInput
              placeholder="Search"
              value={queryParams?.search}
              onChange={event => handleChangeSearch(event)}
            />
          </Grid>
          <Grid item>
            <Grid container columnSpacing={1} alignItems="center">
              <Grid item>
                <AutoCompleteComponent
                  options={optionDsp}
                  label="Select DSP"
                  value={
                    optionDsp.find(
                      option => option.id === queryParams?.dsp_id
                    ) || null
                  }
                  onChange={value => handleChangeDsp(value)}
                  size="small"
                  width={250}
                />
              </Grid>
              <Grid item>
                <PrimaryButton
                  label="Upload DSP Data"
                  onClick={handleUploadDsp}
                  size="large"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {loadingPage ? (
          <SkeletonComponent variant="wave" />
        ) : (
          <InnoTable
            isLoading={false}
            columns={columnTable}
            items={dataTable}
            page={queryParams?.page}
            rowsPerPage={queryParams?.size}
            totalPage={tableTotalPage}
            handleChangePage={(_, page) => handleChangePage(page)}
            handleChangeRowsPerPage={e =>
              handleChangePageSize(e?.target?.value)
            }
            isHaveAction={true}
            handleView={item =>
              history.push({
                pathname: `/admin/detail-upload/${item?.id}`,
                state: { customer: item },
              })
            }
          />
        )}
      </Container>
    </Page>
  );
}

const getPeriodMonth = item => {
  const startDate = item?.start_date;
  const endDate = item?.end_date;
  const formattedDate = date => moment(date).format("DD MMM YYYY");
  return `${formattedDate(startDate)} - ${formattedDate(endDate)}`;
};
const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
}));
const breadcrumbData = [
  {
    label: "Home",
    link: "/admin/dashboard",
  },
  {
    label: "Upload Data",
    active: true,
  },
];
const statusTypeMap = {
  Finish: "success",
  Error: "danger",
  Started: "warning",
  Initialize: "warning",
  Running: "warning",
};
const columnTable = [
  {
    name: "id",
    title: "Upload ID",
  },
  {
    name: "header",
    title: "Period",
    renderText: item => getPeriodMonth(item),
  },
  {
    name: "header",
    title: "Total Revenue",
    headerAlign: "right",
    renderText: header => (
      <CurrencyDisplay
        value={header?.revenue || 0}
        decimalScale={2}
        prefix="Rp"
      />
    ),
  },
  {
    name: "record_count",
    title: "Number of Record",
    headerAlign: "right",
    renderText: item => <CurrencyDisplay value={item} />,
  },
  {
    name: "status",
    title: "Upload Status",
    renderText: status => (
      <StatusChip label={status} type={statusTypeMap[status] || ""} />
    ),
  },
];
export default UploadData;
