import {
  Breadcrumbs,
  Button,
  Chip,
  Container,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  TextField,
  Typography,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import SearchIcon from "@material-ui/icons/Search";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import { InnoTable } from "inno-ui";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import Swal from "sweetalert2";
import { Page } from "components";
import { hardBaseUrl } from "../../../services/urlConstant";
import { formatDate } from "../../../utils";

const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  results: {
    marginTop: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  formControl: {},
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selectMenu: {
    backgroundColor: "red",
  },
  pageSize: {
    height: "30px",
    borderRadius: "6px",
    border: "1px solid #D1D5DC",
    paddingLeft: "5px",
    paddingRight: "5px",
    marginTop: "5px",
  },
  paginationText: {
    fontSize: "14px",
    fontWeight: "normal",
    fontStyle: "normal",
  },
  breadcrumbs: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#111827",
  },
  breadCrumbsActive: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#687083",
  },
  placeholder: {
    "&::placeholder": {
      color: "#9AA2B1",
    },
  },
  buttonSelect: {
    backgroundColor: "#E4E7EB",

    color: "black",
    "&:hover": {
      backgroundColor: "#E4E7EB",
      color: "black",
    },
  },
  select: {
    height: "44px",
    paddingTop: "0px",
    paddingBottom: "0px",

    "& .MuiOutlinedInput-root": {
      height: "44px",
      paddingTop: "0px",
      paddingBottom: "0px",
    },
  },
  tableChip: {
    marginRight: "5px",
    backgroundColor: "#F9FAFB",
    border: "1px solid #D1D5DC",
  },
  activeChip: {
    backgroundColor: "#8DE5AB",
    color: "#34774C",
    fontSize: "14px",
    fontWeight: "500",
  },
  activeChipIndicator: {
    color: "#34774C",
    fontSize: "12px",
  },
  nonActiveChip: {
    backgroundColor: "#D1D5DC",
    color: "#364052",
    fontSize: "14px",
    fontWeight: "500",
  },
  nonActiveChipIndicator: {
    color: "#364052",
    fontSize: "12px",
  },
}));

function KontrakOriginalPublisher() {
  const classes = useStyles();
  const history = useHistory();
  const [values] = useState({
    keySearch: "",
    password: "",
    weight: "",
    weightRange: "",
    showPassword: false,
  });
  const [response, setResponse] = useState([]);
  const [penciptaInString] = useState("");
  const [searchKey, setSearchKey] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rowsPerPageMain] = useState(10);
  const [totalData, setTotalData] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);

  const categoryList = [
    {
      label: "Main",
      value: "main",
    },
    {
      label: "Song",
      value: "song",
    },
  ];
  const statusList = [
    {
      label: "Active",
      value: "active",
    },
    {
      label: "Non-active",
      value: "non-active",
    },
  ];
  const approvalStatusList = [
    {
      label: "Approved",
      value: "approved",
    },
    {
      label: "Rejected",
      value: "rejected",
    },
    {
      label: "Waiting for approval",
      value: "waiting_for_approval",
    },
    {
      label: "Waiting for editing approval",
      value: "waiting_for_editing_approval",
    },
  ];
  const [categoryValue, setCategoryValue] = useState(0);
  const [statusValue, setStatusValue] = useState(0);

  const [approvalStatusValue, setApprovalStatusValue] = useState(0);

  const handleChangeCategory = event => {
    setCategoryValue(event.target.value);
    setPage(1);
  };
  const handleChangeStatus = event => {
    setStatusValue(event.target.value);
    setPage(1);
  };
  const handleChangeApprovalStatus = event => {
    setApprovalStatusValue(event.target.value);
    setPage(1);
  };

  const handleChangeSearch = event => {
    setPage(1);
    setSearchKey(event.target.value);
  };

  useEffect(() => {
    const getResponse = async () => {
      setIsLoading(true);
      try {
        const token = localStorage.getItem("token");
        let baseOfUrl = hardBaseUrl;
        const getFilterValue = value => {
          if (value === 0) {
            return "";
          } else {
            return value;
          }
        };

        const url =
          baseOfUrl +
          `/publisher/contract?v=2&page=${page}&offset=0&limit=${rowsPerPage}` +
          "&composer=" +
          penciptaInString +
          "&search=" +
          searchKey +
          "&category=" +
          getFilterValue(categoryValue) +
          "&status=" +
          getFilterValue(statusValue) +
          "&approval=" +
          getFilterValue(approvalStatusValue);

        const options = {
          headers: {
            Authorization: "Bearer " + token,
          },
        };

        const res = await axios.get(url, options);

        setResponse(res.data.data.contracts);
        setPage(res.data.meta.page);
        setTotalData(res.data.meta.total);
        setPageSize(res.data.meta.limit);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };

    getResponse();
  }, [
    values,
    penciptaInString,
    searchKey,
    page,
    pageSize,
    rowsPerPage,
    rowsPerPageMain,
    categoryValue,
    statusValue,
    approvalStatusValue,
  ]);

  const pagesCount = Math.ceil(totalData / rowsPerPage);

  const handleChangeRowsPerPage = event => {
    event.preventDefault();
    setRowsPerPage(event.target.value);

    setPage(1);
  };

  const handleChangePage = (event, data) => {
    setPage(data);
  };

  const handleDelete = item => {
    const token = localStorage.getItem("token");
    let url = "";
    if (item.category === "song") {
      url = `${hardBaseUrl}/publisher/contract/${item.contract_id}`;
    } else if (item.category === "main") {
      url = `${hardBaseUrl}/publisher/contract/composer-main/${item.contract_id}`;
    }

    axios
      .delete(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        Swal.fire({
          title: "Success",
          text: "Delete Contract Success",
          icon: "success",
          confirmButtonText: "OK",
        }).then(result => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        });
      })

      .catch(err => {
        Swal.fire({
          title: "Error",
          text: err.response.data.errors[0].message,
          icon: "error",
          confirmButtonText: "OK",
        }).then(result => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        });
      });
  };

  const renderCategory = item => {
    return (
      <React.Fragment>
        <div
          style={{
            textTransform: "capitalize",
          }}
        >
          {item}
        </div>
      </React.Fragment>
    );
  };
  const renderContractStatus = item => {
    if (item === true) {
      return (
        <React.Fragment>
          <Chip
            icon={
              <FiberManualRecordIcon
                fontSize="small"
                className={classes.activeChipIndicator}
              />
            }
            label="Aktif"
            className={classes.activeChip}
          />
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <Chip
            icon={
              <FiberManualRecordIcon
                fontSize="small"
                className={classes.nonActiveChipIndicator}
              />
            }
            label="Non Aktif"
            className={classes.nonActiveChip}
          />
        </React.Fragment>
      );
    }
  };

  const renderApprovalStatus = item => {
    if (item === "approved") {
      return <p>Approved</p>;
    } else if (item === "rejected") {
      return <p>Rejected</p>;
    } else if (item === "waiting_for_approval") {
      return <p>Waiting</p>;
    } else if (item === "waiting_for_editing_approval") {
      return <p>Waiting for editing approval</p>;
    } else {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <p>-</p>
        </div>
      );
    }
  };

  return (
    <Page className={classes.root} title="Kontrak">
      <Container maxWidth={false}>
        <Grid
          alignItems="flex-end"
          container
          justifyContent="space-between"
          spacing={3}
        >
          <Grid item>
            <ThemeProvider theme={theme}>
              <Typography
                style={{
                  fontSize: "24px",
                  fontWeight: "700",
                  lineHeight: "32px",
                }}
              >
                Contract Composer/Author
              </Typography>
            </ThemeProvider>
          </Grid>
          <Grid item>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography className={classes.breadCrumbs}>Home</Typography>

              <Typography className={classes.breadCrumbsActive}>
                Contract Composer/Author
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>

        <Divider className={classes.divider} />

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex" }}>
            <Button
              href="/admin/kontrak-composer/tambah"
              style={{
                backgroundColor: "black",
                color: "white",
                textTransform: "none",
                marginRight: "10px",
                fontFamily: ["Inter"].join(","),
                width: "320px",
              }}
              variant="contained"
              size="large"
              startIcon={<AddIcon />}
            >
              Create Contract
            </Button>
            <FormControl fullWidth variant="outlined">
              <OutlinedInput
                placeholder="Search"
                id="outlined-adornment-amount"
                style={{
                  width: "268px",
                  height: "44px",
                  border: "1px solid #D1D5DC",
                  borderRadius: "6px",
                }}
                value={searchKey}
                onChange={event => handleChangeSearch(event)}
                inputProps={{
                  classes: {
                    input: classes.placeholder,
                  },
                }}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon style={{ color: "#9AA2B1" }} />
                  </InputAdornment>
                }
                fullWidth={false}
              />
            </FormControl>
          </div>
          <div style={{ display: "flex" }}>
            <FormControl
              className={classes.formControl}
              fullWidth={true}
              id="dsp-filter"
            >
              <TextField
                size="small"
                className={classes.select}
                style={{
                  width: "195px",
                  marginLeft: "20px",
                  height: "44px",
                  border: "1px solid #D1D5DC",
                  borderRadius: "6px",
                }}
                onChange={handleChangeCategory}
                value={categoryValue}
                select
                variant="outlined"
                InputProps={{}}
              >
                <MenuItem value={0}>
                  <p
                    style={{
                      fontSize: "14px",
                      color: "#687083",
                      marginTop: "10px",
                      fontWeight: "400",
                    }}
                  >
                    Category
                  </p>
                </MenuItem>
                {categoryList.map((option, i) => (
                  <MenuItem key={i} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
            <FormControl
              className={classes.formControl}
              fullWidth={true}
              id="dsp-filter"
            >
              <TextField
                size="small"
                className={classes.select}
                style={{
                  width: "195px",
                  marginLeft: "20px",
                  height: "44px",
                  border: "1px solid #D1D5DC",
                  borderRadius: "6px",
                }}
                onChange={handleChangeStatus}
                value={statusValue}
                select
                variant="outlined"
                InputProps={{}}
              >
                <MenuItem value={0}>
                  <p
                    style={{
                      fontSize: "14px",
                      color: "#687083",
                      marginTop: "10px",
                      fontWeight: "400",
                    }}
                  >
                    Status
                  </p>
                </MenuItem>
                {statusList.map((option, i) => (
                  <MenuItem key={i} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>

            <FormControl
              className={classes.formControl}
              fullWidth={true}
              id="dsp-filter"
            >
              <TextField
                size="small"
                className={classes.select}
                style={{
                  width: "195px",
                  marginLeft: "20px",
                  height: "44px",
                  border: "1px solid #D1D5DC",
                  borderRadius: "6px",
                }}
                onChange={handleChangeApprovalStatus}
                value={approvalStatusValue}
                select
                variant="outlined"
                InputProps={{}}
              >
                <MenuItem value={0}>
                  <p
                    style={{
                      fontSize: "14px",
                      color: "#687083",
                      marginTop: "10px",
                      fontWeight: "400",
                    }}
                  >
                    Approval Status
                  </p>
                </MenuItem>
                {approvalStatusList.map((option, i) => (
                  <MenuItem key={i} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </div>
        </div>
        <Divider className={classes.divider} />
        {response && (
          <InnoTable
            columns={[
              {
                name: "contract_number",
                title: "Contract Number",
                componentType: "text",
              },
              {
                name: "category",
                title: "Category",
                componentType: "text",
                renderText: item => renderCategory(item, "category"),
              },
              {
                name: "songs",
                title: "Song Title",
                componentType: "chip",
                selectedKeyInside: "song_title",
              },
              {
                name: "composers",
                title: "Composer / Author",
                componentType: "text",
                selectedKeyInside: "sure_name",
              },
              {
                name: "start_date",
                title: "Start Date",
                componentType: "text",
                renderText: item => formatDate(item),
              },
              {
                name: "end_date",
                title: "End Date",
                componentType: "text",
                renderText: item => formatDate(item),
              },
              {
                name: "is_active_flag",
                title: "Contract Status",
                componentType: "text",
                renderText: item => renderContractStatus(item),
              },
              {
                name: "updated_at",
                title: "Last Update",
                componentType: "text",
              },
              {
                name: "approval_status",
                title: "Approval Status",
                componentType: "text",
                renderText: item => renderApprovalStatus(item),
              },
            ]}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleDelete={handleDelete}
            handleEdit={item =>
              history.push({
                pathname: `/admin/kontrak-composer/edit/${item.contract_id}`,
                state: {
                  customer: item,
                  id: item.contract_id,
                  is_editable: item.is_editable,
                },
              })
            }
            handleView={item =>
              history.push({
                pathname: `/admin/kontrak-composer/${item.contract_id}`,
                state: {
                  customer: item,
                  id: item.contract_id,
                },
              })
            }
            idColumnName={"contract_id"}
            isLoading={isLoading}
            isHaveAction={true}
            isUsingCheckbox={false}
            isUsingDeleteConfirmation={true}
            items={response}
            loadingColor={""}
            page={page}
            rowsPerPage={rowsPerPage}
            totalPage={pagesCount}
          />
        )}
      </Container>
    </Page>
  );
}

export default KontrakOriginalPublisher;
