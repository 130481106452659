import { Container, Divider } from "@material-ui/core";
import { AddBox } from "@material-ui/icons";
import IndeterminateCheckBoxIcon from "@material-ui/icons/IndeterminateCheckBox";
import { TreeItem, TreeView } from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";
import { Box, Grid, styled } from "@mui/material";
import axios from "axios";
import {
  CheckboxInput,
  FormLabel,
  ModalError,
  ModalSuccess,
  Page,
  PrimaryButton,
  SectionLabel,
  SelectInput,
  SkeletonComponent,
  TextInput,
} from "components";
import { HeaderTitle } from "layouts";
import { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useHistory, useParams } from "react-router";
import { getErrors, wordCapitalize } from "utils";
import { hardBaseUrl } from "../../../../services/urlConstant";
import Swal from "sweetalert2";

const DragIcons = () => (
  <svg
    width="6"
    height="18"
    viewBox="0 0 10 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="2" cy="2" r="2" fill="#D1D5DC" />
    <circle cx="8" cy="2" r="2" fill="#D1D5DC" />
    <circle cx="2" cy="8" r="2" fill="#D1D5DC" />
    <circle cx="8" cy="8" r="2" fill="#D1D5DC" />
    <circle cx="2" cy="14" r="2" fill="#D1D5DC" />
    <circle cx="8" cy="14" r="2" fill="#D1D5DC" />
    <circle cx="2" cy="20" r="2" fill="#D1D5DC" />
    <circle cx="8" cy="20" r="2" fill="#D1D5DC" />
  </svg>
);

function FormRole() {
  const classes = useStyles();
  const history = useHistory();
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: "Bearer " + token,
  };
  const { role_id } = useParams();
  const isEditing = Boolean(role_id);
  const [loadingPage, setLoadingPage] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [active, setActive] = useState([]);
  const [payload, setPayload] = useState({
    role_name: "",
    role_type: "",
    application: "caris",
    application_menus: [],
  });
  const { role_type } = payload;
  const selectedAppAndMenu = payload?.application_menus.find(({ application }) => application === (payload?.application));
  const selectedMenu = !isEditing ? selectedAppAndMenu?.menus?.map((item, index) => {
    const newIndex = `${index}`;
    return {
      ...item,
      children: item?.children?.map((child, childIndex) => ({
        ...child,
        index: newIndex + childIndex + 1
      })),
      index: newIndex
    };
  }) : selectedAppAndMenu?.menus;

  const handleChangePayload = (value, key) => setPayload(prev => ({ ...prev, [key]: value }));

  const handleChildCheckboxChange = (id) => {
    const newActive = [...(active || [])];
    const isAlreadySelected = newActive?.some(item => item === id);
    if (isAlreadySelected) {
      const filterId = newActive.filter(item => item !== id);
      setActive(filterId);
    } else {
      setActive((active) => [...active, id]);
    }

  };

  const handleParentMenuTextChange = (permissionId, value) => {
    setPayload(({ application, application_menus, ...prev }) => {
      const checkSelectedMenu = application_menus.find(item => item?.application === application);
      const menus = [...checkSelectedMenu?.menus];
      const selectedPermission = menus?.map(({ menu_text, ...permission }) => ({
        ...permission,
        menu_text: (permissionId === permission?.index || permissionId === permission?.id) ? value : menu_text
      }));
      const filterByApplication = application_menus.filter((item) => item?.application !== application);
      return {
        ...prev,
        application,
        application_menus: [
          ...filterByApplication,
          {
            application: checkSelectedMenu?.application,
            menus: selectedPermission
          }
        ],
      };
    });
  };

  const handleChildMenuTextChange = (permissionId, value) => {
    setPayload(({ application, application_menus, ...prev }) => {
      const checkSelectedMenu = application_menus.find(item => item?.application === application);
      const menus = [...checkSelectedMenu?.menus];
      const selectedPermission = menus?.map(({ children, ...rest }) => ({
        ...rest,
        children: children?.map(({ menu_text, ...child }) => ({
          ...child,
          menu_text: (permissionId === child?.index || permissionId === child?.id) ? value : menu_text
        }))
      }));
      const filterByApplication = application_menus.filter((item) => item?.application !== application);
      return {
        ...prev,
        application,
        application_menus: [
          ...filterByApplication,
          {
            application: checkSelectedMenu?.application,
            menus: selectedPermission
          }
        ],
      };
    });
  };

  const handleParentDragEnd = result => {
    if (!result.destination) return;
    const { source, destination, draggableId } = result;
    const isParent = draggableId.startsWith("draggable-parent");
    const newPermissions = payload?.application_menus?.find(({ application }) => application === payload?.application)?.menus || [];
    if (isParent) {
      const movedPermission = newPermissions.splice(source.index, 1)[0];
      newPermissions.splice(destination.index, 0, movedPermission);
      newPermissions.forEach((menu, index) => {
        menu.order = index + 1;
      });
      setPayload(prev => ({
        ...prev,
        application_menus: prev.application_menus.map((menu) => ({
          ...menu,
          menus: newPermissions
        })
        ),
      }));
    } else {
      const parentIndex = parseInt(source.droppableId.split("-")[2]);
      const updatedParentPermission = { ...newPermissions[parentIndex] };
      updatedParentPermission.children = updatedParentPermission.children || [];
      const movedChild = updatedParentPermission.children.splice(
        source.index,
        1
      )[0];
      updatedParentPermission.children.splice(destination.index, 0, movedChild);
      updatedParentPermission.children.forEach((child, index) => {
        if (child) {
          child.order = index + 1;
        }
      });
      newPermissions[parentIndex] = updatedParentPermission;
      setPayload(prev => ({
        ...prev,
        application_menus: prev.application_menus.map((menu) => ({
          ...menu,
          menus: newPermissions
        })
        ),
      }));
    }
  };

  const validatePayload = () => {
    const errors = [];
    const errorEmpty = error => `${error} can't be empty.`;
    const hasActiveChildren = active?.every(item => item);

    if (payload.role_name === "") {
      errors.push(errorEmpty("Role Name"));
    } else if (!hasActiveChildren) {
      errors.push(errorEmpty("Access"));
    }
    return errors;
  };

  const getAccessList = async () => {
    try {
      setLoadingPage(true);
      const res = await axios.get(`${hardBaseUrl}/role-menu`, {
        headers,
        params: {
          ...(isEditing && {
            role_id: Number(role_id),
          }),
          role_type: payload?.role_type
        },
      });
      const { role_name, application_menus, role_type } = res?.data?.data;
      const application = application_menus[0]?.application;
      setPayload((state) => ({
        ...state,
        role_name: payload?.role_name || role_name,
        ...(isEditing && {
          role_type: payload?.role_type || role_type,
        }),
        application,
        application_menus,
      }));
      if (isEditing) {
        const checkChildrenIsActive = application_menus[0]?.menus?.flatMap(({ children }) =>
          children?.filter(({ is_active }) => is_active).map(({ id }) => id)
        );
        setActive(checkChildrenIsActive || []);
      } else {
        setActive([]);
      }

    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };

  const handleSubmit = async () => {
    const { role_name, application_menus } = payload;

    const mpisMenus =
      application_menus.find(item => item.application === "mpis")?.menus || [];
    const carisMenus =
      application_menus.find(item => item.application === "caris")?.menus || [];

    const isMpisActive = mpisMenus.some(menu => menu.is_active);
    const isCarisActive = carisMenus.some(menu => menu.is_active);

    const checkActiveChildren = selectedMenu?.map(({ children, ...props }) => {
      const updatedChildren = children?.map((childProps) => ({
        ...childProps,
        is_active: active?.some(id => id === childProps?.index || id === childProps?.id)
      }));
      const isParentActive = updatedChildren?.some(child => child.is_active);

      return {
        ...props,
        is_active: isParentActive,
        children: updatedChildren
      };
    });

    const filterMenus = [...payload?.application_menus].filter(({ application }) => application !== payload?.application);
    const modifiedPayload = {
      ...(role_id && { role_id: Number(role_id) }),
      role_name,
      role_type,
      application_menus: [
        ...filterMenus,
        {
          application: payload?.application,
          menus: checkActiveChildren
        }
      ]
    };

    const method = role_id ? axios.put : axios.post;
    const errors = validatePayload();

    if (errors?.length) {
      ModalError(errors[0]);
      return;
    }

    Swal.fire({
      title: "Confirmation",
      text: `Are you sure you want to ${role_id ? "save" : "update"} ${isMpisActive && isCarisActive ? "MPIS & CARIS" : ""
        } this data?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Save",
      cancelButtonText: "Cancel",
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          setLoadingButton(true);
          await method(`${hardBaseUrl}/role-menu`, modifiedPayload, {
            headers,
          });
          ModalSuccess(
            `Successfully ${role_id ? "Save" : "Create"} Role`
          ).then(() => history.goBack());
        } catch (error) {
          ModalError(getErrors(error?.response));
        } finally {
          setLoadingButton(false);
        }
      }
    });
  };


  useEffect(() => {
    getAccessList();
  }, []);

  useEffect(() => {
    getAccessList();
  }, [payload?.role_type]);

  useEffect(() => {
    if (isEditing) {
      const checkChildrenIsActive = selectedAppAndMenu?.menus?.flatMap(({ children }) =>
        children?.filter(({ is_active }) => is_active).map(({ id }) => id)
      );
      setActive(checkChildrenIsActive || []);
    }
  }, [isEditing, selectedAppAndMenu]);

  const title = `${!isEditing ? "Add" : "Edit"} Role User`;
  return (
    <Page
      className={classes?.root}
      title={title}
    >
      <Container maxWidth={false}>
        <HeaderTitle
          title={`${isEditing ? "Edit" : "Add"} Role User`}
          breadcrumbData={breadcrumbData({ type: isEditing ? "Edit" : "Add" })}
          backButton
        />
        <Divider className={classes?.divider} />
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <SectionLabel title="User" />
            <FormCard>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <FormLabel label="Role Username" required />
                  <TextInput
                    value={payload?.role_name}
                    disabled={loadingPage}
                    onChange={event =>
                      handleChangePayload(event?.target?.value, "role_name")
                    }
                    placeholder="Username"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormLabel label="Role Type" required />
                  <SelectInput
                    options={optionType}
                    optionKey="value"
                    optionLabel="label"
                    disabled={isEditing || loadingPage}
                    value={payload?.role_type}
                    onChange={e =>
                      handleChangePayload(e?.target?.value, "role_type")
                    }
                    placeholder="Choose Role Type"
                    width="100%"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormLabel label="App Access" required />
                  <SelectInput
                    options={optionApplication}
                    optionKey="value"
                    optionLabel="label"
                    value={payload?.application}
                    onChange={e =>
                      handleChangePayload(e?.target?.value, "application")
                    }
                    placeholder="Choose Access Type"
                    disabled={role_type !== "publisher" || loadingPage}
                    width="100%"
                  />
                </Grid>
              </Grid>
            </FormCard>
            <PrimaryButton
              label={
                loadingButton
                  ? role_id
                    ? "Saving"
                    : "Adding"
                  : role_id
                    ? "Save"
                    : "Add"
              }
              onClick={handleSubmit}
              disabled={loadingButton}
              loading={loadingButton}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <SectionLabel title="Access Rights" />
            {loadingPage ? (
              <SkeletonComponent variant="wave" />
            ) : (
              <FormCard>
                <DragDropContext onDragEnd={handleParentDragEnd}>
                  <Droppable
                    droppableId="droppable-parent"
                    direction="vertical"
                  >
                    {provided => (
                      <Box ref={provided.innerRef} {...provided.droppableProps}>
                        {(selectedMenu || [])?.map(
                          (permission, parentIndex) => (
                            <Draggable
                              key={`draggable-parent-${parentIndex}`}
                              draggableId={`draggable-parent-${parentIndex}`}
                              index={parentIndex}
                              type="PARENT"
                            >
                              {provided => (
                                <Grid
                                  container
                                  spacing={1}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <Grid item>
                                    <Box my="6px">
                                      <DragIcons />
                                    </Box>
                                  </Grid>
                                  <Grid item>
                                    <TreeView
                                      defaultCollapseIcon={<IndeterminateCheckBoxIcon />}
                                      defaultExpandIcon={<AddBox />}
                                    >
                                      <TreeItem
                                        nodeId={`tree-parent-${parentIndex}`}
                                        label={
                                          <TextInput
                                            variant="standard"
                                            value={permission?.menu_text}
                                            onChange={e =>
                                              handleParentMenuTextChange((permission?.index || permission?.id), e.target.value)
                                            }
                                            width={288}
                                          />
                                        }
                                      >
                                        {permission.children?.length > 1 ? (
                                          <Droppable
                                            droppableId={`droppable-child-${parentIndex}`}
                                            type="CHILD"
                                            direction="vertical"
                                          >
                                            {provided => (
                                              <Box
                                                ref={provided.innerRef}
                                                {...provided.droppableProps}
                                              >
                                                {(permission?.children || []).map((child, childIndex) =>
                                                  <Draggable
                                                    key={childIndex}
                                                    draggableId={`draggable-child-${parentIndex}-${childIndex}`}
                                                    index={childIndex}
                                                    type="CHILD"
                                                  >
                                                    {provided => (
                                                      <Grid
                                                        container
                                                        columnSpacing={1}
                                                        alignItems="center"
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                      >
                                                        <Grid item pt="6px">
                                                          <DragIcons />
                                                        </Grid>
                                                        <Grid item>
                                                          <CheckboxInput
                                                            checked={active?.some(item => item === child?.index || item === child?.id)}
                                                            onChange={() =>
                                                              handleChildCheckboxChange(child?.index || child?.id)
                                                            }
                                                            label={
                                                              <TextInput
                                                                variant="standard"
                                                                value={child.menu_text}
                                                                onChange={e =>
                                                                  handleChildMenuTextChange(
                                                                    (child?.index || child?.id),
                                                                    e.target.value
                                                                  )
                                                                }
                                                                width={250}
                                                              />
                                                            }
                                                            size="small"
                                                          />
                                                        </Grid>
                                                      </Grid>
                                                    )}
                                                  </Draggable>
                                                )}
                                                {provided.placeholder}
                                              </Box>
                                            )}
                                          </Droppable>
                                        ) : (
                                          <CheckboxInput
                                            checked={active?.some(item => item === permission?.children[0]?.index || item === permission?.children[0]?.id)}
                                            onChange={() => handleChildCheckboxChange(permission?.children[0]?.index || permission?.children[0]?.id)}
                                            label={
                                              <TextInput
                                                variant="standard"
                                                value={permission.children[0].menu_text}
                                                onChange={e =>
                                                  handleChildMenuTextChange(
                                                    (permission?.index || permission?.id),
                                                    e.target.value
                                                  )
                                                }
                                                width={250}
                                              />
                                            }
                                            size="small"
                                          />
                                        )}
                                      </TreeItem>
                                    </TreeView>
                                  </Grid>
                                </Grid>
                              )}
                            </Draggable>
                          )
                        )}
                        {provided.placeholder}
                      </Box>
                    )}
                  </Droppable>
                </DragDropContext>
              </FormCard>
            )}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    fontFamily: ["Helvetica Neue"].join(","),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
}));
const FormCard = styled(Box)({
  border: "1px solid #9AA2B1",
  borderRadius: "8px",
  padding: "16px",
  margin: "16px 0",
});
const breadcrumbData = ({ type }) => [
  {
    label: "Configuration",
    link: "/admin/konfigurasi/role-user",
  },
  {
    label: "Role User",
    link: "/admin/konfigurasi/role-user",
  },
  {
    label: `${wordCapitalize(type)} User Role`,
    active: true,
  },
];
const optionApplication = [
  {
    value: "mpis",
    label: "MPIS",
  },
  {
    value: "caris",
    label: "CARIS",
  },
];
const optionType = [
  {
    value: "admin",
    label: "Admin",
  },
  {
    value: "publisher",
    label: "Publisher",
  },
  {
    value: "composer",
    label: "Composer",
  },
  {
    value: "association",
    label: "Association",
  },
  {
    value: "society",
    label: "Society",
  },
];
export default FormRole;
