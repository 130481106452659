import React from "react";
import manWeb from "../../../assets/img/manwebsvg.svg";
import {
  GetApp,
  SystemUpdateAltOutlined,
  CheckCircleOutline,
} from "@material-ui/icons/";
import icon_dashboard_unselected from "../../../assets/image-public/images/icon_images/icon_dashboard_unselected.svg";
import icon_dashboard from "../../../assets/image-public/images/icon_images/icon_dashboard.svg";
import icon_review_unselected from "../../../assets/image-public/images/icon_images/icon_review_unselected.svg";
import icon_master_unselected from "../../../assets/image-public/images/icon_images/icon_master_unselected.svg";
import icon_kontrak_unselected from "../../../assets/image-public/images/icon_images/icon_kontrak_unselected.svg";
import icon_berita_unselected from "../../../assets/image-public/images/icon_images/icon_berita_unselected.svg";
import reviewSelected from "../../../assets/image-public/images/icon_images/reviewSelected.svg";
import icon_konfigurasi_unselected from "../../../assets/image-public/images/icon_images/icon_konfigurasi_unselected.svg";
import kontrakSelected from "../../../assets/image-public/images/icon_images/kontrakSelected.svg";
import manajemenWebSelected from "../../../assets/image-public/images/icon_images/manajemenWebSelected.svg";
import Setting from "../../../assets/image-public/images/admin-master-logo-svg/Setting.svg";
import ProgramingData from "../../../assets/image-public/images/admin-master-logo-svg/ProgramingData.svg";
import BookOpen from "../../../assets/image-public/images/admin-master-logo-svg/BookOpen.png";
import song_claim_unselected from "../../../assets/image-public/images/song_claim_unselected.svg";
import song_claim_selected from "../../../assets/image-public/images/song_claim_selected.svg";
import uploadMonitoringUnselected from "../../../assets/image-public/images/icon_images/upload-monitoring-unselected.svg";
import uploadMonitoringSelected from "../../../assets/image-public/images/icon_images/upload-monitoring-selected.svg";
import unselected_reports from "../../../assets/image-public/images/icon_images/unselected_reports.svg";
import selected_reports from "../../../assets/image-public/images/icon_images/selected_reports.svg";
import platform_share_unselected from "../../../assets/image-public/images/icon_images/platform_share_unselected.png";
import platform_share_selected from "../../../assets/image-public/images/icon_images/platform_share_selected.png";

let navItemUsed = [];
const typeWeb = localStorage.getItem("typeWeb");

if (typeWeb === "mpis") {
  navItemUsed = [
    {
      subheader: "",
      items: [
        {
          title: "Dashboard",
          href: "/admin/dashboard",
          show: true,
          icon: () => (
            <img
              style={{
                width: "20px",
                marginRight: "10px",
              }}
              alt={"dashboard"}
              src={icon_dashboard_unselected}
            />
          ),
          selectedicon: () => (
            <img
              style={{
                width: "20px",
                marginRight: "10px",
              }}
              alt={"dashboard"}
              src={icon_dashboard}
            />
          ),
        },
        {
          title: "Song Usage",
          href: "/admin/review-lagu",
          show: true,
          icon: () => (
            <img
              style={{
                width: "20px",
                marginRight: "10px",
              }}
              alt="review"
              src={icon_review_unselected}
            />
          ),
          selectedicon: () => (
            <img
              style={{
                width: "20px",
                marginRight: "10px",
              }}
              alt="review"
              src={reviewSelected}
            />
          ),
        },
        {
          title: "Configuration",
          href: "/admin/konfigurasi/aktifitas",
          show: true,
          icon: () => (
            <img
              style={{
                width: "20px",
                marginRight: "10px",
              }}
              alt="konfigurasi"
              src={icon_konfigurasi_unselected}
            />
          ),
          selectedicon: () => (
            <img
              style={{
                width: "20px",
                marginRight: "10px",
              }}
              alt="konfigurasi"
              src={Setting}
            />
          ),
        },
        {
          title: "Transaction",
          href: null,
          show: true,
          key: "Transaction",
          icon: () => (
            <img
              style={{
                width: "20px",
                marginRight: "10px",
              }}
              alt="icon-berita-iklan"
              src={icon_berita_unselected}
            />
          ),
          selectedicon: () => (
            <img
              style={{
                width: "20px",
                marginRight: "10px",
              }}
              alt="selected-icon-berita-iklan"
              src={BookOpen}
            />
          ),
          items: [
            {
              title: "Advance",
              href: "/admin/advance",
              key: "advance",
              show: true,
            },
          ],
        },
        {
          title: "Song Claim",
          href: "/admin/publisher/double-claim",
          show: true,
          key: "Song Claim",
          icon: () => (
            <img
              style={{
                width: "20px",
                marginRight: "10px",
              }}
              alt="Conf"
              src={song_claim_unselected}
            />
          ),
          selectedicon: () => (
            <img
              style={{
                width: "20px",
                marginRight: "10px",
              }}
              alt="Select file"
              src={song_claim_selected}
            />
          ),
        },
        {
          title: "Parameter",
          href: "/admin/parameter/lagu",
          show: true,
          icon: () => (
            <img
              style={{
                width: "20px",
                marginRight: "10px",
              }}
              alt="master"
              src={icon_master_unselected}
            />
          ),
          selectedicon: () => (
            <img
              style={{
                width: "20px",
                marginRight: "10px",
              }}
              alt="master"
              src={ProgramingData}
            />
          ),
        },
        {
          title: "Upload Monitoring",
          href: "/admin/upload-monitoring",
          show: true,
          icon: () => (
            <img
              style={{
                width: "20px",
                marginRight: "10px",
              }}
              alt="monitoring"
              src={uploadMonitoringUnselected}
            />
          ),
          selectedicon: () => (
            <img
              style={{
                width: "20px",
                marginRight: "10px",
              }}
              alt="monitoring"
              src={uploadMonitoringSelected}
            />
          ),
        },
        {
          title: "Download Monitoring",
          href: "/admin/download-monitoring",
          show: true,
          icon: () => (
            <GetApp
              style={{
                width: "20px",
                marginRight: "10px",
                color: "grey",
              }}
            />
          ),
          selectedicon: () => (
            <GetApp
              style={{
                width: "20px",
                marginRight: "10px",
                color: "white",
              }}
            />
          ),
        },
        {
          title: "Download CCID",
          href: "/admin/download-ccid",
          show: true,
          icon: () => (
            <SystemUpdateAltOutlined
              style={{
                width: "20px",
                marginRight: "10px",
                color: "grey",
              }}
            />
          ),
          selectedicon: () => (
            <SystemUpdateAltOutlined
              style={{
                width: "20px",
                marginRight: "10px",
                color: "white",
              }}
            />
          ),
        },
        {
          title: "Submit to DSP",
          href: "/admin/submitDSP",
          show: true,
          icon: () => (
            <CheckCircleOutline
              style={{
                width: "20px",
                marginRight: "10px",
                color: "grey",
              }}
            />
          ),
          selectedicon: () => (
            <CheckCircleOutline
              style={{
                width: "20px",
                marginRight: "10px",
                color: "white",
              }}
            />
          ),
        },
      ],
    },
  ];
} else {
  navItemUsed = [
    {
      subheader: "",
      items: [
        {
          title: "Dashboard",
          href: "/admin/dashboard",
          show: true,
          icon: () => (
            <img
              style={{
                width: "20px",
                marginRight: "10px",
              }}
              alt={"dashboard"}
              src={icon_dashboard_unselected}
            />
          ),
          selectedicon: () => (
            <img
              style={{
                width: "20px",
                marginRight: "10px",
              }}
              alt={"dashboard"}
              src={icon_dashboard}
            />
          ),
        },
        {
          title: "Song Usage",
          href: "/admin/review-lagu",
          show: true,
          icon: () => (
            <img
              style={{
                width: "20px",
                marginRight: "10px",
              }}
              alt="review"
              src={icon_review_unselected}
            />
          ),
          selectedicon: () => (
            <img
              style={{
                width: "20px",
                marginRight: "10px",
              }}
              alt="review"
              src={reviewSelected}
            />
          ),
        },
        {
          title: "Report",
          href: "/admin/reports",
          show: true,
          icon: () => (
            <img
              style={{
                width: "20px",
                marginRight: "10px",
              }}
              alt="review"
              src={unselected_reports}
            />
          ),
          selectedicon: () => (
            <img
              style={{
                width: "20px",
                marginRight: "10px",
              }}
              alt="review"
              src={selected_reports}
            />
          ),
        },
        {
          title: "Configuration",
          href: "/admin/konfigurasi/personalisasi",
          show: true,
          icon: () => (
            <img
              style={{
                width: "20px",
                marginRight: "10px",
              }}
              alt="konfigurasi"
              src={icon_konfigurasi_unselected}
            />
          ),
          selectedicon: () => (
            <img
              style={{
                width: "20px",
                marginRight: "10px",
              }}
              alt="konfigurasi"
              src={Setting}
            />
          ),
        },
        {
          title: "Contract",
          href: "/admin/kontrak",
          show: true,
          icon: () => (
            <img
              style={{
                width: "20px",
                marginRight: "10px",
              }}
              alt="kontrak"
              src={icon_kontrak_unselected}
            />
          ),
          selectedicon: () => (
            <img
              style={{
                width: "20px",
                marginRight: "10px",
              }}
              alt="kontrak"
              src={kontrakSelected}
            />
          ),
          items: [
            {
              title: "Composer",
              href: "/admin/kontrak-composer",
              key: "advanced",
              show: true,
            },
            {
              title: "Original Publisher",
              href: "/admin/kontrak-original-publisher",
              key: "Finalisasi Berita Iklan",
              show: true,
            },
          ],
        },
        {
          title: "Upload Monitoring",
          href: "/admin/upload-monitoring",
          show: true,
          icon: () => (
            <img
              style={{
                width: "20px",
                marginRight: "10px",
              }}
              alt="monitoring"
              src={uploadMonitoringUnselected}
            />
          ),
          selectedicon: () => (
            <img
              style={{
                width: "20px",
                marginRight: "10px",
              }}
              alt="monitoring"
              src={uploadMonitoringSelected}
            />
          ),
        },
        {
          title: "Transaction",
          href: null,
          show: true,
          key: "Transaction",
          icon: () => (
            <img
              style={{
                width: "20px",
                marginRight: "10px",
              }}
              alt="icon-berita-iklan"
              src={icon_berita_unselected}
            />
          ),
          selectedicon: () => (
            <img
              style={{
                width: "20px",
                marginRight: "10px",
              }}
              alt="selected-icon-berita-iklan"
              src={BookOpen}
            />
          ),
          items: [
            {
              title: "Upload Data",
              href: "/admin/upload",
              key: "upload-data",
              show: false,
            },
            {
              title: "Advance",
              href: "/admin/advance",
              key: "advance",
              show: true,
            },
            {
              title: "Cost Allocation",
              href: "/admin/cost-allocation",
              key: "cost-allocation",
              show: true,
            },
            {
              title: "Invoice",
              href: "/admin/invoice",
              key: "Invoice claim",
              show: true,
            },
            {
              title: "Payment",
              href: "/admin/payment",
              key: "Finalisasi Berita Iklan",
              show: true,
            },
            {
              title: "Loan",
              href: "/admin/loan",
              key: "Finalisasi Berita Iklan",
              show: true,
            },
          ],
        },
        {
          title: "Platform Share",
          href: "/admin/platform-share",
          show: true,
          key: "Platform Share",
          icon: () => (
            <img
              style={{
                width: "20px",
                marginRight: "10px",
              }}
              alt="PlatformShar"
              src={platform_share_unselected}
            />
          ),
          selectedicon: () => (
            <img
              style={{
                width: "20px",
                marginRight: "10px",
              }}
              alt="Select file"
              src={platform_share_selected}
            />
          ),
        },
        {
          title: "Song Claim",
          href: "/admin/publisher/double-claim",
          show: true,
          key: "Song Claim",
          icon: () => (
            <img
              style={{
                width: "20px",
                marginRight: "10px",
              }}
              alt="Conf"
              src={song_claim_unselected}
            />
          ),
          selectedicon: () => (
            <img
              style={{
                width: "20px",
                marginRight: "10px",
              }}
              alt="Select file"
              src={song_claim_selected}
            />
          ),
        },
        {
          title: "Parameter",
          href: "/admin/parameter/pencipta",
          show: true,
          icon: () => (
            <img
              style={{
                width: "20px",
                marginRight: "10px",
              }}
              alt="master"
              src={icon_master_unselected}
            />
          ),
          selectedicon: () => (
            <img
              style={{
                width: "20px",
                marginRight: "10px",
              }}
              alt="master"
              src={ProgramingData}
            />
          ),
        },
        {
          title: "News & Ads",
          href: "/admin/berita-publisher",
          show: true,
          icon: () => (
            <img
              style={{
                width: "20px",
                marginRight: "10px",
              }}
              alt="berita"
              src={icon_berita_unselected}
            />
          ),
          selectedicon: () => (
            <img
              style={{
                width: "20px",
                marginRight: "10px",
              }}
              alt="berita"
              src={BookOpen}
            />
          ),
        },
        {
          title: "Web Management",
          href: "/admin/manajemen-web",
          show: true,
          icon: () => (
            <img
              style={{
                width: "17px",
                marginRight: "10px",
                marginLeft: "3px",
              }}
              alt="icon-man-web"
              src={manWeb}
            />
          ),
          selectedicon: () => (
            <img
              style={{
                width: "17px",
                marginRight: "10px",
                marginLeft: "3px",
              }}
              src={manajemenWebSelected}
            />
          ),
        },
        {
          title: "Download Monitoring",
          href: "/admin/download-monitoring",
          show: true,
          icon: () => (
            <GetApp
              style={{
                width: "20px",
                marginRight: "10px",
                color: "grey",
              }}
            />
          ),
          selectedicon: () => (
            <GetApp
              style={{
                width: "20px",
                marginRight: "10px",
                color: "white",
              }}
            />
          ),
        },
        {
          title: "Download CCID",
          href: "/admin/download-ccid",
          show: true,
          icon: () => (
            <SystemUpdateAltOutlined
              style={{
                width: "20px",
                marginRight: "10px",
                color: "grey",
              }}
            />
          ),
          selectedicon: () => (
            <SystemUpdateAltOutlined
              style={{
                width: "20px",
                marginRight: "10px",
                color: "white",
              }}
            />
          ),
        },
        {
          title: "Submit to DSP",
          href: "/admin/submitDSP",
          show: true,
          icon: () => (
            <CheckCircleOutline
              style={{
                width: "20px",
                marginRight: "10px",
                color: "grey",
              }}
            />
          ),
          selectedicon: () => (
            <CheckCircleOutline
              style={{
                width: "20px",
                marginRight: "10px",
                color: "white",
              }}
            />
          ),
        },
      ],
    },
  ];
}

const items = navItemUsed;

export default items;
