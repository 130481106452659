import {
  Button,
  Card,
  CardContent,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { makeStyles } from "@material-ui/styles";
import React, { useRef } from "react";
import ErrorChip from "../../../../components/atoms/Chip/ErrorChip";
import SuccessChip from "../../../../components/atoms/Chip/SuccessChip";
const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});
const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  results: {
    marginTop: theme.spacing(3),
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  content: {
    padding: 0,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  btnBack: {
    backgroundColor: "#7066e0",
    color: "#fff",
    marginLeft: "10px",
    marginRight: "20px",
    textTransform: "none",
    "&.MuiButton-root:hover": {
      backgroundColor: "#7066e0",
    },
  },
  btnSubmit: {
    border: "1px solid lightgrey",
    textTransform: "none",
  },
}));
const DialogAfterSubmit = ({ open, onClose, object }) => {
  const classes = useStyles();
  const dialogRef = useRef();

  const [details, setDetails] = useState([]);

  React.useEffect(() => {
    if (object !== null) {
      let invalids = object.invalid_songs.map(item => {
        return {
          song_title: item.song_title,
          status: "invalid",
          message: item.message,
          line_number: `${item.line_number}`,
        };
      });

      let mergeResult = [...invalids];

      setDetails(mergeResult);
    }
  }, [object]);

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={open}
        onClose={onClose}
        aria-labelledby="dialog-upload-song"
        disableEscapeKeyDown={true}
        ref={dialogRef}
      >
        <DialogTitle id="responsive-dialog-title">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <ThemeProvider theme={theme}>
              <Typography
                style={{
                  fontSize: "16px",
                  fontWeight: 700,
                }}
              >
                {""}
              </Typography>
            </ThemeProvider>
            <CloseOutlinedIcon
              onClick={onClose}
              style={{
                color: "#9AA2B1",
                cursor: "pointer",
                width: "12px",
                height: "12px",
                marginTop: "8px",
              }}
            />
          </div>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <div className="">
            <div
              style={{
                textAlign: "center",
              }}
            >
              <CheckCircleIcon
                style={{
                  color: green[500],
                  fontSize: 100,
                }}
              />
            </div>
            <div
              style={{
                textAlign: "center",
              }}
            >
              <h1>Success</h1>
            </div>
            <div
              style={{
                textAlign: "center",
              }}
            >
              <h2>Document has been uploaded</h2>
            </div>
          </div>
          {details?.length > 0 ? (
            <>
              <Card>
                <CardContent className={classes.content}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={classes.headText}
                          >
                            Song Title
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={classes.headText}
                          >
                            Status
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={classes.headText}
                          >
                            Message
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={classes.headText}
                          >
                            Line Number
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {details.map((customer, idx) => (
                        <TableRow hover key={`${idx}`}>
                          <TableCell align="left">
                            <Typography
                              variant="subtitle2"
                              className={classes.bodyTextBlack}
                            >
                              {customer.song_title}
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            {customer.status === "invalid" ? (
                              <>
                                <Typography
                                  variant="subtitle2"
                                  className={classes.bodyTextBlack}
                                >
                                  <HighlightOffIcon
                                    style={{
                                      color: "red",
                                    }}
                                  />
                                </Typography>
                              </>
                            ) : null}
                            {customer.status === "success" ? (
                              <>
                                <Typography
                                  variant="subtitle2"
                                  className={classes.bodyTextBlack}
                                >
                                  <CheckCircleOutlineIcon
                                    style={{
                                      color: "green",
                                    }}
                                  />
                                </Typography>
                              </>
                            ) : null}
                          </TableCell>
                          <TableCell align="left">
                            <Typography
                              variant="subtitle2"
                              className={classes.bodyTextBlack}
                            >
                              {customer.status === "success" ? (
                                <>
                                  <SuccessChip
                                    chipLabel={customer.message}
                                    key={`${idx}`}
                                  />
                                </>
                              ) : (
                                <>
                                  <ErrorChip
                                    chipLabel={customer.message}
                                    key={`${idx}`}
                                  />
                                </>
                              )}
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography
                              variant="subtitle2"
                              className={classes.bodyTextBlack}
                            >
                              {customer.line_number}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </CardContent>
              </Card>
            </>
          ) : null}
        </DialogContent>
        <Divider className={classes.divider} />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "20px",
          }}
        >
          <Button className={classes.btnBack} onClick={onClose}>
            OK
          </Button>
        </div>
      </Dialog>
    </div>
  );
};
export default DialogAfterSubmit;
