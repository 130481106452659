import { lazy } from "react";
import { Redirect } from "react-router-dom";
import AuthLayout from "../layouts/Auth";
import DashboardAdminLayout from "../layouts/DashboardAdmin";
import ErrorLayout from "../layouts/Error";
import BeritaIklanAdmin from "../pages/Admin/BeritaIklanAdmin";
import EditBeritaAdmin from "../pages/Admin/BeritaIklanAdmin/DaftarBeritaIklan/EditBerita";
import PreviewEditBeritaAdmin from "../pages/Admin/BeritaIklanAdmin/DaftarBeritaIklan/PreviewEditBerita";
import PreviewTambahBeritaAdmin from "../pages/Admin/BeritaIklanAdmin/DaftarBeritaIklan/PreviewTambahBerita";
import TambahBeritaAdmin from "../pages/Admin/BeritaIklanAdmin/DaftarBeritaIklan/TambahBerita";
import FinalisasiBeritaIklanAdmin from "../pages/Admin/BeritaIklanAdmin/FinalisasiBeritaIklan";
import PreviewBeritaIklanAdmin from "../pages/Admin/BeritaIklanAdmin/FinalisasiBeritaIklan/PreviewBeritaIklan";
import PreviewFinalisasiBerita from "../pages/Admin/BeritaIklanAdmin/FinalisasiBeritaIklan/PreviewFinalisasiBerita";
import KonfigurasiAdminAktifitas from "../pages/Admin/KonfigurasiAdmin/Aktifitas";
import KonfigurasiAdminPajak from "../pages/Admin/KonfigurasiAdmin/Pajak";
import KonfigurasiAdminRoleUser from "../pages/Admin/KonfigurasiAdmin/RoleUser";
import FormRole from "../pages/Admin/KonfigurasiAdmin/RoleUser/FormRole";
import KonfigurasiAdminSistem from "../pages/Admin/KonfigurasiAdmin/Sistem";
import KonfigurasiAdminTemplateNotifikasi from "../pages/Admin/KonfigurasiAdmin/TemplateNotifikasi";
import ManajemenWebAdmin from "../pages/Admin/ManajemenWeb";
import PreviewManajemenWebAdmin from "../pages/Admin/ManajemenWeb/PreviewManajemenWeb";
import MasterAssociation from "../pages/Admin/MasterAdmin/Asosiasi";
import MasterAdminBank from "../pages/Admin/MasterAdmin/Bank";
import MasterAdminBankTambah from "../pages/Admin/MasterAdmin/Bank/TambahBank";
import MasterAdminBankUbah from "../pages/Admin/MasterAdmin/Bank/UbahBank";
import MasterAdminCurrency from "../pages/Admin/MasterAdmin/Currency";
import MasterAdminCurrencyTambah from "../pages/Admin/MasterAdmin/Currency/CreateCurrency";
import MasterAdminCurrencyEdit from "../pages/Admin/MasterAdmin/Currency/EditCurrency";
import MasterAdminDsp from "../pages/Admin/MasterAdmin/Dsp";
import EditDsp from "../pages/Admin/MasterAdmin/Dsp/EditDsp";
import TambahDsp from "../pages/Admin/MasterAdmin/Dsp/TambahDsp";
import MasterAdminLagu from "../pages/Admin/MasterAdmin/Lagu";
import MasterAdminLaguEdit from "../pages/Admin/MasterAdmin/Lagu/EditLagu";
import MasterAdminLaguTambah from "../pages/Admin/MasterAdmin/Lagu/TambahLagu";
import MasterAdminPencipta from "../pages/Admin/MasterAdmin/Pencipta";
import MasterAdminPenciptaEdit from "../pages/Admin/MasterAdmin/Pencipta/EditPencipta";
import MasterAdminPublisher from "../pages/Admin/MasterAdmin/Publisher";
import AddPublisher from "../pages/Admin/MasterAdmin/Publisher/AddPublisher";
import EditPublisher from "../pages/Admin/MasterAdmin/Publisher/EditPublisher";
import MasterAdminUser from "../pages/Admin/MasterAdmin/User";
import MasterAdminEditUser from "../pages/Admin/MasterAdmin/User/EditUser";
import MasterAdminTambahUser from "../pages/Admin/MasterAdmin/User/TambahUser";
import MasterAdminUserPencipta from "../pages/Admin/MasterAdmin/UserPencipta";
import MasterAdminUserPublisher from "../pages/Admin/MasterAdmin/UserPublisher";
import ChangePassword from "../pages/changePassword";
import AdvancedPageFinance from "../pages/Finance/AdvancedPage";
import AddAdvancedFinance from "../pages/Finance/AdvancedPage/AddAdvanced";
import DetailAdvancedFinance from "../pages/Finance/AdvancedPage/DetailAdvanced";
import PrintPdfAdvancedPage from "../pages/Finance/AdvancedPage/DetailAdvanced/PrintPdf";
import PaymentPage from "../pages/Finance/PaymentPage";
import AddPayment from "../pages/Finance/PaymentPage/AddPayment";
import DetailPayment from "../pages/Finance/PaymentPage/DetailPayment";
import PrintPdfPaymentPage from "../pages/Finance/PaymentPage/DetailPayment/PrintPdf";
import PaymentMutation from "../pages/Finance/PaymentPage/PaymentMutation";
import InvitationPassword from "../pages/InvitationPassword/InvitationPassword";
import ViewBerita from "../pages/LandingPage/Berita";
import BeritaDetailView from "../pages/LandingPage/Berita/DetailBerita";
import DetailLagu from "../pages/LandingPage/DetailLagu/DetailLagu";
import EdukasiView from "../pages/LandingPage/Edukasi/Edukasi";
import Home from "../pages/LandingPage/Home/Home";
import LoginLanding from "../pages/LandingPage/Login/Login";
import LoginAdminLanding from "../pages/LandingPage/LoginAdmin/LoginAdmin";
import ManageComposer from "../pages/LandingPage/ManageComposer/ManageComposer";
import ManageRole from "../pages/LandingPage/ManageRole/ManageRole";
import NewPassword from "../pages/LandingPage/NewPassword/NewPassword";
import Otp from "../pages/LandingPage/Otp/Otp";
import PrivacyPolicy from "../pages/LandingPage/PrivacyPolicy";
import TentangKamiView from "../pages/LandingPage/TentangKami/TentangKami";
import NotificationPage from "../pages/NotificationPage";
import DashboardPencipta from "../pages/Pencipta/DashboardPencipta";
import DetailLaguPencipta from "../pages/Pencipta/DashboardPencipta/DetailLagu";
import DetailPublisher from "../pages/Pencipta/DashboardPencipta/DetailPublisher";
import KontrakListDetailPencipta from "../pages/Pencipta/Kontrak/ContractDetail";
import KontrakListPencipta from "../pages/Pencipta/Kontrak";
import LoanPagePencipta from "../pages/Pencipta/LoanPage";
import AddLoanPencipta from "../pages/Pencipta/LoanPage/AddLoan";
import DetailLoanPencipta from "../pages/Pencipta/LoanPage/DetailLoan";
import PaymentPencipta from "../pages/Pencipta/PaymentPencipta";
import PaymentPenciptaMutation from "../pages/Pencipta/PaymentPencipta/PaymentMutation";
import PenciptaAddPayment from "../pages/Pencipta/PaymentPencipta/PenciptaAddPayment";
import PenciptaDetailPayment from "../pages/Pencipta/PaymentPencipta/PenciptaDetailPayment";
import PrintPdfPencipta from "../pages/Pencipta/PaymentPencipta/PenciptaDetailPayment/PrintPdf";
import PenciptaReviewLagu from "../pages/Pencipta/PenciptaReviewLagu";
import PenciptaDetailReviewLagu from "../pages/Pencipta/PenciptaReviewLagu/PenciptaDetailReviewLagu";
import PermintaanPembayaran from "../pages/Pencipta/PermintaanPembayaran";
import AjukanPembayaran from "../pages/Pencipta/PermintaanPembayaran/AjukanPembayaran";
import ProfilePencipta from "../pages/Pencipta/ProfilePencipta";
import AdvancedPage from "../pages/Publisher/AdvancedPage";
import AddAdvanced from "../pages/Publisher/AdvancedPage/AddAdvanced";
import DetailAdvanced from "../pages/Publisher/AdvancedPage/DetailAdvanced";
import BeritaIklanPublisher from "../pages/Publisher/BeritaIklanPublisher";
import DetailBerita from "../pages/Publisher/BeritaIklanPublisher/DetailBerita";
import EditBerita from "../pages/Publisher/BeritaIklanPublisher/EditBerita";
import PreviewBeritaEdit from "../pages/Publisher/BeritaIklanPublisher/PreviewBeritaEdit";
import PreviewBeritaTambah from "../pages/Publisher/BeritaIklanPublisher/PreviewBeritaTambah";
import TambahBerita from "../pages/Publisher/BeritaIklanPublisher/TambahBerita";
import ClaimAndUsage from "../pages/Publisher/ClaimAndUsage";
import AddClaimAndUsage from "../pages/Publisher/ClaimAndUsage/AddClaimAndUsage";
import DetailClaimAndUsage from "../pages/Publisher/ClaimAndUsage/DetailClaimAndUsage";
import DetailDalamNegri from "../pages/Publisher/ClaimAndUsage/DetailDalamNegri";
import DetailLuarNegri from "../pages/Publisher/ClaimAndUsage/DetailLuarNegri";
import EditClaimAndUsage from "../pages/Publisher/ClaimAndUsage/EditClaimAndUsage";
import ClaimPage from "../pages/Publisher/ClaimPage";
import AddClaim from "../pages/Publisher/ClaimPage/AddClaim";
import DetailClaim from "../pages/Publisher/ClaimPage/DetailClaim";
import CostAllocation from "../pages/Publisher/CostAllocation";
import CreateTresshold from "../pages/Publisher/CostAllocation/CreateTresshold";
import DefaultTresshold from "../pages/Publisher/CostAllocation/DefaultTresshold";
import DetailCostAllocation from "../pages/Publisher/CostAllocation/DetailCostAllocation";
import DashboardPublisher from "../pages/Publisher/Dashboard";
import PrintReportPage from "../pages/Publisher/Dashboard/PrintReportPage";
import TrendComposer from "../pages/Publisher/Dashboard/TrendComposer";
import DownloadCCID from "../pages/Publisher/DownloadCCID";
import DownloadMonitoring from "../pages/Publisher/DownloadMonitoring";
import ExchangesRate from "../pages/Publisher/ExchangesRate";
import AddExRate from "../pages/Publisher/ExchangesRate/AddExRate/AddExRate";
import ViewExRate from "../pages/Publisher/ExchangesRate/ViewExRate/ViewExRate";
import AktifitasPublisher from "../pages/Publisher/KonfigurasiPublisher/Aktifitas";
import ComposerCredential from "../pages/Publisher/KonfigurasiPublisher/ComposerCredential";
import ContractReminder from "../pages/Publisher/KonfigurasiPublisher/ContractReminder";
import CostType from "../pages/Publisher/KonfigurasiPublisher/CostType";
import EditCostType from "../pages/Publisher/KonfigurasiPublisher/CostType/EditCostType";
import TambahCostType from "../pages/Publisher/KonfigurasiPublisher/CostType/TambahCostType";
import InvoiceConf from "../pages/Publisher/KonfigurasiPublisher/Invoice";
import OriginalPublisherPublisher from "../pages/Publisher/KonfigurasiPublisher/OriginalPublisher";
import PembayaranPublisher from "../pages/Publisher/KonfigurasiPublisher/Pembayaran";
import PersonalisasiPublisher from "../pages/Publisher/KonfigurasiPublisher/Personalisasi";
import KontrakPublisher from "../pages/Publisher/Kontrak";
import DetailKontrakPublisher from "../pages/Publisher/Kontrak/DetailKontrak";
import KontrakPublisherTambah from "../pages/Publisher/Kontrak/TambahKontrak";
import KontrakComposer from "../pages/Publisher/KontrakComposer";
import DetailKontrakComposer from "../pages/Publisher/KontrakComposer/DetailKontrak";
import KontrakComposerEdit from "../pages/Publisher/KontrakComposer/EditKontrak";
import MainDetailKontrakComposer from "../pages/Publisher/KontrakComposer/MainContract/DetailKontrak";
import MainKontrakComposerEdit from "../pages/Publisher/KontrakComposer/MainContract/EditKontrak";
import MainKontrakComposerTambah from "../pages/Publisher/KontrakComposer/MainContract/TambahKontrak";
import KontrakComposerTambah from "../pages/Publisher/KontrakComposer/TambahKontrak";
import KontrakOriginalPublisher from "../pages/Publisher/KontrakOriginalPublisher";
import DetailKontrakOriginalPublisher from "../pages/Publisher/KontrakOriginalPublisher/ContractDetail";
import KontrakOriginalPublisherEdit from "../pages/Publisher/KontrakOriginalPublisher/EditContract";
import KontrakOriginalPublisherTambah from "../pages/Publisher/KontrakOriginalPublisher/AddContract";
import {
  default as LoanPage,
  default as LoanPageFinance,
} from "../pages/Publisher/LoanPage";
import {
  default as AddLoan,
  default as AddLoanFinance,
} from "../pages/Publisher/LoanPage/AddLoan";
import {
  default as DetailLoan,
  default as DetailLoanFinance,
} from "../pages/Publisher/LoanPage/DetailLoan";
import EditLoanFinance from "../pages/Publisher/LoanPage/EditLoan";
import ManajemenWeb from "../pages/Publisher/ManajemenWeb";
import PreviewManajemenWeb from "../pages/Publisher/ManajemenWeb/PreviewManajemenWeb";
import MasterPublisherAgent from "../pages/Publisher/Master/Agent";
import MasterPublisherAgentEdit from "../pages/Publisher/Master/Agent/EditAgent";
import MasterPublisherAgentTambah from "../pages/Publisher/Master/Agent/TambahAgent";
import ClientClassification from "../pages/Publisher/Master/ClientClassification";
import AddClientClassification from "../pages/Publisher/Master/ClientClassification/AddClientClassification";
import MasterCurrency from "../pages/Publisher/Master/Currency";
import MasterCurrencyTambah from "../pages/Publisher/Master/Currency/CreateCurrency";
import MasterCurrencyEdit from "../pages/Publisher/Master/Currency/EditCurrency";
import PublisherMasterAdminDsp from "../pages/Publisher/Master/Dsp";
import MasterClientAdd from "../pages/Publisher/Master/Dsp/AddClient";
import masterGroup from "../pages/Publisher/Master/Group";
import AddMasterGroup from "../pages/Publisher/Master/Group/AddMasterGroup";
import DetailMasterGroup from "../pages/Publisher/Master/Group/DetailMasterGroup";
import MasterPublisherLagu from "../pages/Publisher/Master/Lagu";
import MasterPublisherLaguEdit from "../pages/Publisher/Master/Lagu/FormParameterSong";
import MasterPublisherLaguTambah from "../pages/Publisher/Master/Lagu/FormParameterSong";
import MasterPublisherPencipta from "../pages/Publisher/Master/Pencipta";
import MasterPublisherPenciptaEdit from "../pages/Publisher/Master/Pencipta/EditPencipta";
import MasterPublisherPenciptaTambah from "../pages/Publisher/Master/Pencipta/TambahPencipta";
import MasterPublisherPerformer from "../pages/Publisher/Master/Performer";
import MasterPublisherPerformerEdit from "../pages/Publisher/Master/Performer/EditPerformer";
import MasterPublisherPerformerTambah from "../pages/Publisher/Master/Performer/TambahPerformer";
import MasterPublisherDsp from "../pages/Publisher/Master/PublisherDsp";
import DetailDSP from "../pages/Publisher/Master/PublisherDsp/detail";
import EditClient from "../pages/Publisher/Master/PublisherDsp/EditClient";
import MasterPublisherTax from "../pages/Publisher/Master/Tax";
import CreateTax from "../pages/Publisher/Master/Tax/CreateTax";
import EditTax from "../pages/Publisher/Master/Tax/EditTax";
import MasterPublisherUser from "../pages/Publisher/Master/User";
import MasterPublisherUserEdit from "../pages/Publisher/Master/User/EditUser";
import MasterPublisherUserTambah from "../pages/Publisher/Master/User/TambahUser";
import PlatformShare from "../pages/Publisher/PlatformShare";
import AddPlatformShare from "../pages/Publisher/PlatformShare/AddPlatformShare";
import DetailPlatformShare from "../pages/Publisher/PlatformShare/DetailPlatformShare";
import ProfilePublisher from "../pages/Publisher/Profile";
import ReportView from "../pages/Publisher/Report";
import DetailReportView from "../pages/Publisher/Report/DetailReport";
import ReviewLagu from "../pages/Publisher/ReviewLagu";
import DetailReviewLagu from "../pages/Publisher/ReviewLagu/DetailReviewLagu";
import PrintReportDetailReview from "../pages/Publisher/ReviewLagu/DetailReviewLagu/PrintReportDetailReview";
import PrintReportReview from "../pages/Publisher/ReviewLagu/PrintReportReview";
import SongClaim from "../pages/Publisher/SongClaim";
import DoubleClaim from "../pages/Publisher/SongClaim/DoubleClaim";
import HistoryClaim from "../pages/Publisher/SongClaim/HistoryClaim";
import SongUnclaim from "../pages/Publisher/SongClaim/SongUnclaim";
import UploadData from "../pages/Publisher/UploadData";
import AddUpload from "../pages/Publisher/UploadData/AddUpload/AddUpload";
import DetailUpload from "../pages/Publisher/UploadData/DetailUpload/DetailUpload";
import UploadMonitoring from "../pages/Publisher/UploadMonitoring";
import DetailMonitor from "../pages/Publisher/UploadMonitoring/DetailMonitor";
import RedirectPage from "../pages/RedirectPage";
import adminRoutes from "./adminRoutes";
import publisherRoutes from "./publisherRoutes";
let routesUsed = [];
let roleUsed = "all";
switch (roleUsed) {
  case "admin":
    routesUsed = adminRoutes;
    break;
  case "publisher":
    routesUsed = publisherRoutes;
    break;
  default:
    routesUsed = [
      {
        path: "/home",
        exact: true,
        component: Home,
      },
      {
        path: "/redirect/:token",
        exact: true,
        component: RedirectPage,
      },
      {
        path: "/manage-composer",
        exact: true,
        isAuth: false,
        component: ManageComposer,
      },
      {
        path: "/manage-role",
        exact: true,
        isAuth: false,
        component: ManageRole,
      },
      {
        path: "/otp",
        exact: true,
        isAuth: false,
        component: Otp,
      },
      {
        path: "/login",
        exact: true,
        isAuth: false,
        component: LoginLanding,
      },
      {
        path: "/admin-login",
        exact: true,
        isAuth: false,
        component: LoginAdminLanding,
      },
      {
        path: "/new-password/:token",
        exact: true,
        component: NewPassword,
      },
      {
        path: "/invitation/password/:token",
        exact: true,
        isAuth: false,
        component: InvitationPassword,
      },
      {
        path: "/berita",
        exact: true,
        isAuth: false,
        component: ViewBerita,
      },
      {
        path: "/privacy-policy",
        exact: true,
        isAuth: false,
        component: PrivacyPolicy,
      },
      {
        path: "/artikel/detail/:id",
        exact: true,
        isAuth: false,
        component: BeritaDetailView,
      },
      {
        path: "/detail-lagu",
        exact: true,
        isAuth: false,
        component: DetailLagu,
      },
      {
        path: "/edukasi",
        exact: true,
        isAuth: false,
        component: EdukasiView,
      },
      {
        path: "/tentang-kami",
        exact: true,
        isAuth: false,
        component: TentangKamiView,
      },
      {
        path: "/auth",
        component: AuthLayout,
        routes: [
          {
            path: "/auth/login",
            exact: true,
            component: lazy(() => import("../views/Login")),
          },
          {
            path: "/auth/register",
            exact: true,
            component: lazy(() => import("../views/Register")),
          },
          {
            component: () => <Redirect to="/errors/error-404" />,
          },
        ],
      },
      {
        path: "/errors",
        component: ErrorLayout,
        routes: [
          {
            path: "/errors/error-401",
            exact: true,
            component: lazy(() => import("../views/Error401")),
          },
          {
            path: "/errors/error-404",
            exact: true,
            component: lazy(() => import("../views/Error404")),
          },
          {
            path: "/errors/error-500",
            exact: true,
            component: lazy(() => import("../views/Error500")),
          },
          {
            component: () => <Redirect to="/errors/error-404" />,
          },
        ],
      },
      {
        path: "/finance",
        component: DashboardAdminLayout,
        routes: [
          {
            path: "/finance/advance",
            exact: true,
            component: AdvancedPageFinance,
          },
          {
            path: "/finance/advance/add",
            exact: true,
            component: AddAdvancedFinance,
          },
          {
            path: "/finance/advanced/print-as-pdf/:id",
            exact: true,
            component: PrintPdfAdvancedPage,
          },
          {
            path: "/finance/advance/:id",
            exact: true,
            component: DetailAdvancedFinance,
          },
          {
            path: "/finance/payment",
            exact: true,
            component: PaymentPage,
          },
          {
            path: "/finance/payment/add",
            exact: true,
            component: AddPayment,
          },
          {
            path: "/finance/payment/:id",
            exact: true,
            component: DetailPayment,
          },
          {
            path: "/finance/loan",
            exact: true,
            component: LoanPageFinance,
          },
          {
            path: "/finance/loan/add",
            exact: true,
            component: AddLoanFinance,
          },
          {
            path: "/finance/loan/:id",
            exact: true,
            component: DetailLoanFinance,
          },
        ],
      },
      {
        path: "/admin",
        component: DashboardAdminLayout,
        routes: [
          {
            path: "/admin/dashboard",
            exact: true,
            role: ["publisher"],
            component: DashboardPublisher,
          },
          {
            path: "/admin/composer-trend",
            exact: true,
            component: TrendComposer,
            role: ["publisher", "admin"],
          },
          {
            path: "/admin/dashboard/print-report",
            exact: true,
            component: PrintReportPage,
            role: ["publisher", "admin"],
          },
          {
            path: "/admin/profile",
            exact: true,
            component: ProfilePublisher,
            role: ["publisher", "admin"],
          },
          {
            path: "/admin/upload-monitoring",
            exact: true,
            component: UploadMonitoring,
            role: ["publisher", "admin"],
          },
          {
            path: "/admin/detail-upload-monitoring/:id",
            exact: true,
            component: DetailMonitor,
            role: ["publisher", "admin"],
          },
          {
            path: "/admin/upload",
            exact: true,
            component: UploadData,
            role: ["publisher", "admin"],
          },
          {
            path: "/admin/add-upload/:id",
            exact: true,
            component: AddUpload,
            role: ["publisher", "admin"],
          },
          {
            path: "/admin/detail-upload/:id",
            exact: true,
            component: DetailUpload,
            role: ["publisher", "admin"],
          },
          {
            path: "/admin/download-monitoring",
            exact: true,
            component: DownloadMonitoring,
            role: ["publisher", "admin"],
          },
          {
            path: "/admin/download-ccid",
            exact: true,
            component: DownloadCCID,
            role: ["publisher"],
          },
          {
            path: "/admin/konfigurasi/exchange-rate",
            exact: true,
            component: ExchangesRate,
            role: ["publisher", "admin"],
          },
          {
            path: "/admin/konfigurasi/exchange-rate/add",
            exact: true,
            component: AddExRate,
            role: ["publisher", "admin"],
          },
          {
            path: "/admin/konfigurasi/exchange-rate/view/:id",
            exact: true,
            component: ViewExRate,
            role: ["publisher", "admin"],
          },
          {
            path: "/admin/berita-publisher",
            exact: true,
            component: BeritaIklanPublisher,
            role: ["publisher", "admin"],
          },
          {
            path: "/admin/notifikasi",
            exact: true,
            component: NotificationPage,
          },
          {
            path: "/admin/berita-publisher/edit/preview",
            exact: true,
            component: PreviewBeritaEdit,
          },
          {
            path: "/admin/berita-publisher/edit/:id",
            exact: true,
            component: EditBerita,
          },
          {
            path: "/admin/invoice",
            exact: true,
            component: ClaimAndUsage,
            role: ["publisher", "admin"],
          },
          {
            path: "/admin/invoice/add",
            exact: true,
            component: AddClaimAndUsage,
            role: ["publisher", "admin"],
          },
          {
            path: "/admin/invoice/:id",
            exact: true,
            component: DetailClaimAndUsage,
            role: ["publisher", "admin"],
          },
          {
            path: "/admin/invoice/:id",
            exact: true,
            component: DetailDalamNegri,
            role: ["publisher", "admin"],
          },
          {
            path: "/admin/invoice/:id",
            exact: true,
            component: DetailLuarNegri,
            role: ["publisher", "admin"],
          },
          {
            path: "/admin/invoice/edit/:id",
            exact: true,
            component: EditClaimAndUsage,
            role: ["publisher", "admin"],
          },
          {
            path: "/admin/transaction/claim",
            exact: true,
            component: ClaimAndUsage,
            role: ["publisher", "admin"],
          },
          {
            path: "/admin/transaction/claim/add",
            exact: true,
            component: AddClaimAndUsage,
            role: ["publisher", "admin"],
          },
          {
            path: "/admin/transaction/claim/:id",
            exact: true,
            component: DetailClaimAndUsage,
            role: ["publisher", "admin"],
          },
          {
            path: "/admin/berita-publisher/detail/:id",
            exact: true,
            component: DetailBerita,
          },
          {
            path: "/admin/berita-publisher/tambah",
            exact: true,
            component: TambahBerita,
          },
          {
            path: "/admin/berita-publisher/tambah/preview",
            exact: true,
            component: PreviewBeritaTambah,
          },
          {
            path: "/admin/publisher/double-claim",
            exact: true,
            component: DoubleClaim,
          },
          {
            path: "/admin/publisher/song-claim",
            exact: true,
            component: SongClaim,
          },
          {
            path: "/admin/publisher/song-unclaim",
            exact: true,
            component: SongUnclaim,
          },
          {
            path: "/admin/publisher/song-history",
            exact: true,
            component: HistoryClaim,
          },
          {
            path: "/admin/reports",
            exact: true,
            component: ReportView,
          },
          {
            path: "/admin/reports/:id",
            exact: true,
            component: DetailReportView,
          },
          {
            path: "/admin/master/tax/tambah",
            exact: true,
            component: CreateTax,
          },
          {
            path: "/admin/master/tax/edit/:id",
            exact: true,
            component: EditTax,
          },
          {
            path: "/admin/parameter/pencipta",
            exact: true,
            component: MasterPublisherPencipta,
          },
          {
            path: "/admin/parameter/pencipta/tambah",
            exact: true,
            component: MasterPublisherPenciptaTambah,
          },
          {
            path: "/admin/parameter/pencipta/edit/:id",
            exact: true,
            component: MasterPublisherPenciptaEdit,
          },
          {
            path: "/admin/parameter/client-classification",
            exact: true,
            component: ClientClassification,
          },
          {
            path: "/admin/parameter/client-classification/add",
            exact: true,
            component: AddClientClassification,
          },
          {
            path: "/admin/parameter/group",
            exact: true,
            component: masterGroup,
          },
          {
            path: "/admin/parameter/group/add",
            exact: true,
            component: AddMasterGroup,
          },
          {
            path: "/admin/parameter/group/detail/:id",
            exact: true,
            component: DetailMasterGroup,
          },
          {
            path: "/admin/parameter/lagu",
            exact: true,
            component: MasterPublisherLagu,
          },
          {
            path: "/admin/parameter/lagu/tambah",
            exact: true,
            component: MasterPublisherLaguTambah,
          },
          {
            path: "/admin/parameter/lagu/edit/:id",
            exact: true,
            component: MasterPublisherLaguEdit,
          },
          {
            path: "/admin/parameter/agent",
            exact: true,
            component: MasterPublisherAgent,
          },
          {
            path: "/admin/parameter/agent/tambah",
            exact: true,
            component: MasterPublisherAgentTambah,
          },
          {
            path: "/admin/parameter/agent/edit/:id",
            exact: true,
            component: MasterPublisherAgentEdit,
          },
          {
            path: "/admin/master/currency",
            exact: true,
            component: MasterCurrency,
          },
          {
            path: "/admin/master/currency/tambah",
            exact: true,
            component: MasterCurrencyTambah,
          },
          {
            path: "/admin/master/currency/edit/:id",
            exact: true,
            component: MasterCurrencyEdit,
          },
          {
            path: "/admin/parameter/dsp",
            exact: true,
            component: MasterPublisherDsp,
          },
          {
            path: "/admin/parameter/client/add",
            exact: true,
            component: MasterClientAdd,
          },
          {
            path: "/admin/parameter/client/edit/:id",
            exact: true,
            component: EditClient,
          },
          {
            path: "/admin/parameter/dsp/detail/:id",
            exact: true,
            component: DetailDSP,
          },
          {
            path: "/admin/parameter/performer",
            exact: true,
            component: MasterPublisherPerformer,
          },
          {
            path: "/admin/parameter/performer/tambah",
            exact: true,
            component: MasterPublisherPerformerTambah,
          },
          {
            path: "/admin/parameter/performer/edit/:id",
            exact: true,
            component: MasterPublisherPerformerEdit,
          },
          {
            path: "/admin/parameter/user",
            exact: true,
            component: MasterPublisherUser,
          },
          {
            path: "/admin/parameter/user/tambah",
            exact: true,
            component: MasterPublisherUserTambah,
          },
          {
            path: "/admin/parameter/user/edit/:id",
            exact: true,
            component: MasterPublisherUserEdit,
          },
          {
            path: "/admin/konfigurasi/personalisasi",
            exact: true,
            component: PersonalisasiPublisher,
          },
          {
            path: "/admin/konfigurasi/tax",
            exact: true,
            component: MasterPublisherTax,
          },
          {
            path: "/admin/konfigurasi/cost-type",
            exact: true,
            component: CostType,
          },
          {
            path: "/admin/konfigurasi/add-cost-type",
            exact: true,
            component: TambahCostType,
          },
          {
            path: "/admin/konfigurasi/edit-cost-type/:id",
            exact: true,
            component: EditCostType,
          },
          {
            path: "/admin/konfigurasi/composer-credential",
            exact: true,
            component: ComposerCredential,
          },
          {
            path: "/admin/konfigurasi/pembayaran",
            exact: true,
            component: PembayaranPublisher,
          },
          {
            path: "/admin/konfigurasi/original-publisher",
            exact: true,
            component: OriginalPublisherPublisher,
          },
          {
            path: "/admin/konfigurasi/aktifitas",
            exact: true,
            component: AktifitasPublisher,
          },
          {
            path: "/admin/konfigurasi/invoice",
            exact: true,
            component: InvoiceConf,
          },
          {
            path: "/admin/konfigurasi/contract-reminder",
            exact: true,
            component: ContractReminder,
          },
          {
            path: "/admin/kontrak-publisher",
            exact: true,
            component: KontrakPublisher,
          },
          {
            path: "/admin/kontrak-publisher/detail/:id",
            exact: true,
            component: DetailKontrakPublisher,
          },
          {
            path: "/admin/kontrak-original-publisher",
            exact: true,
            component: KontrakOriginalPublisher,
          },
          {
            path: "/admin/kontrak-original-publisher/tambah",
            exact: true,
            component: KontrakOriginalPublisherTambah,
          },
          {
            path: "/admin/kontrak-original-publisher/edit/:id",
            exact: true,
            component: KontrakOriginalPublisherEdit,
          },
          {
            path: "/admin/kontrak-original-publisher/:id",
            exact: true,
            component: DetailKontrakOriginalPublisher,
          },
          {
            path: "/admin/kontrak-composer",
            exact: true,
            component: KontrakComposer,
          },
          {
            path: "/admin/kontrak-composer/tambah",
            exact: true,
            component: KontrakComposerTambah,
          },
          {
            path: "/admin/kontrak-composer/edit/:id",
            exact: true,
            component: KontrakComposerEdit,
          },
          {
            path: "/admin/kontrak-composer/:id",
            exact: true,
            component: DetailKontrakComposer,
          },
          {
            path: "/admin/kontrak-composer-main/tambah",
            exact: true,
            component: MainKontrakComposerTambah,
          },
          {
            path: "/admin/kontrak-composer-main/edit/:id",
            exact: true,
            component: MainKontrakComposerEdit,
          },
          {
            path: "/admin/kontrak-composer-main/:id",
            exact: true,
            component: MainDetailKontrakComposer,
          },
          {
            path: "/admin/kontrak-publisher/tambah",
            exact: true,
            component: KontrakPublisherTambah,
          },
          {
            path: "/admin/loan",
            exact: true,
            component: LoanPage,
          },
          {
            path: "/admin/loan/add",
            exact: true,
            component: AddLoan,
          },
          {
            path: "/admin/loan/edit/:id",
            exact: true,
            component: EditLoanFinance,
          },
          {
            path: "/admin/loan/:id",
            exact: true,
            component: DetailLoan,
          },
          {
            path: "/admin/transaction/loan",
            exact: true,
            component: LoanPage,
          },
          {
            path: "/admin/transaction/loan/add",
            exact: true,
            component: AddLoan,
          },
          {
            path: "/admin/transaction/loan/:id",
            exact: true,
            component: DetailLoan,
          },
          {
            path: "/admin/claim",
            exact: true,
            component: ClaimPage,
          },
          {
            path: "/admin/claim/add",
            exact: true,
            component: AddClaim,
          },
          {
            path: "/admin/claim/:id",
            exact: true,
            component: DetailClaim,
          },
          {
            path: "/admin/default-tresshold",
            exact: true,
            component: DefaultTresshold,
          },
          {
            path: "/admin/cost-allocation",
            exact: true,
            component: CostAllocation,
          },
          {
            path: "/admin/create-tresshold",
            exact: true,
            component: CreateTresshold,
          },
          {
            path: "/admin/detail-cost-allocation/:id",
            exact: true,
            component: DetailCostAllocation,
          },
          {
            path: "/admin/advance",
            exact: true,
            component: AdvancedPageFinance,
          },
          {
            path: "/admin/advance/add",
            exact: true,
            component: AddAdvancedFinance,
          },
          {
            path: "/admin/advance/:id",
            exact: true,
            component: DetailAdvancedFinance,
          },
          {
            path: "/admin/platform-share",
            exact: true,
            component: PlatformShare,
          },
          {
            path: "/admin/platform-share/add",
            exact: true,
            component: AddPlatformShare,
          },
          {
            path: "/admin/platform-share/:id",
            exact: true,
            component: DetailPlatformShare,
          },
          {
            path: "/admin/payment",
            exact: true,
            component: PaymentPage,
          },
          {
            path: "/admin/payment-mutation",
            exact: true,
            component: PaymentMutation,
          },
          {
            path: "/admin/payment/add",
            exact: true,
            component: AddPayment,
          },
          {
            path: "/admin/payment/print-as-pdf/:id",
            exact: true,
            component: PrintPdfPaymentPage,
          },
          {
            path: "/admin/payment/:id",
            exact: true,
            component: DetailPayment,
          },
          {
            path: "/admin/transaction/advance",
            exact: true,
            component: AdvancedPage,
          },
          {
            path: "/admin/transaction/advance/add",
            exact: true,
            component: AddAdvanced,
          },
          {
            path: "/admin/transaction/advance/:id",
            exact: true,
            component: DetailAdvanced,
          },
          {
            path: "/admin/review-lagu",
            exact: true,
            component: ReviewLagu,
          },
          {
            path: "/admin/review-lagu/print-report",
            exact: true,
            component: PrintReportReview,
            role: ["publisher", "admin"],
          },
          {
            path: "/admin/review-lagu/detail/print-report",
            exact: true,
            component: PrintReportDetailReview,
            role: ["publisher", "admin"],
          },
          {
            path: "/admin/review-lagu/:id",
            exact: true,
            component: DetailReviewLagu,
          },
          {
            path: "/admin/change-password",
            exact: true,
            component: ChangePassword,
          },
          {
            path: "/admin/konfigurasi/sistem",
            exact: true,
            component: props => {
              return <KonfigurasiAdminSistem {...props} mustLoggedIn={true} />;
            },
          },
          {
            path: "/admin/konfigurasi/template-notifikasi",
            exact: true,
            component: KonfigurasiAdminTemplateNotifikasi,
          },
          {
            path: "/admin/konfigurasi/pajak",
            exact: true,
            component: KonfigurasiAdminPajak,
          },
          {
            path: "/admin/konfigurasi/role-user",
            exact: true,
            component: KonfigurasiAdminRoleUser,
          },
          {
            path: "/admin/konfigurasi/role-user/tambah/:type",
            exact: true,
            component: FormRole,
          },
          {
            path: "/admin/konfigurasi/role-user/edit/:type/:role_id",
            exact: true,
            component: FormRole,
          },
          {
            path: "/admin/konfigurasi/aktifitas-admin",
            exact: true,
            component: KonfigurasiAdminAktifitas,
          },
          {
            path: "/admin/master/publisher",
            exact: true,
            component: MasterAdminPublisher,
          },
          {
            path: "/admin/master/publisher/add",
            exact: true,
            component: AddPublisher,
          },
          {
            path: "/admin/master/publisher/:id",
            exact: true,
            component: EditPublisher,
          },
          {
            path: "/admin/master/association",
            exact: true,
            component: MasterAssociation,
          },
          {
            path: "/admin/master/admin-currency",
            exact: true,
            component: MasterAdminCurrency,
          },
          {
            path: "/admin/master/admin-currency/tambah",
            exact: true,
            component: MasterAdminCurrencyTambah,
          },
          {
            path: "/admin/master/admin-currency/edit/:id",
            exact: true,
            component: MasterAdminCurrencyEdit,
          },
          {
            path: "/admin/master/lagu-admin",
            exact: true,
            component: MasterAdminLagu,
          },
          {
            path: "/admin/master/lagu-admin/tambah",
            exact: true,
            component: MasterAdminLaguTambah,
          },
          {
            path: "/admin/master/lagu-admin/edit/:id",
            exact: true,
            component: MasterAdminLaguEdit,
          },
          {
            path: "/admin/master/user-admin",
            exact: true,
            component: MasterAdminUser,
          },
          {
            path: "/admin/master/user-admin/tambah",
            exact: true,
            component: MasterAdminTambahUser,
          },
          {
            path: "/admin/master/user-admin/edit/:id",
            exact: true,
            component: MasterAdminEditUser,
          },
          {
            path: "/admin/master/bank-admin",
            exact: true,
            component: MasterAdminBank,
          },
          {
            path: "/admin/master/bank-admin/tambah",
            exact: true,
            component: MasterAdminBankTambah,
          },
          {
            path: "/admin/master/bank-admin/ubah/:id",
            exact: true,
            component: MasterAdminBankUbah,
          },
          {
            path: "/admin/master/pencipta-admin/:id",
            exact: true,
            component: MasterAdminPenciptaEdit,
          },
          {
            path: "/admin/master/pencipta-admin",
            exact: true,
            component: MasterAdminPencipta,
          },
          {
            path: "/admin/master/dsp-publisher",
            exact: true,
            component: PublisherMasterAdminDsp,
          },
          {
            path: "/admin/master/dsp-admin",
            exact: true,
            component: MasterAdminDsp,
          },
          {
            path: "/admin/master/dsp-admin/add",
            exact: true,
            component: TambahDsp,
          },
          {
            path: "/admin/master/dsp-admin/:id",
            exact: true,
            component: EditDsp,
          },
          {
            path: "/admin/master/user-publisher",
            exact: true,
            component: MasterAdminUserPublisher,
          },
          {
            path: "/admin/master/user-pencipta",
            exact: true,
            component: MasterAdminUserPencipta,
          },
          {
            path: "/admin/master/exchange-rate",
            exact: true,
            component: ExchangesRate,
            role: ["publisher", "admin"],
          },
          {
            path: "/admin/master/exchange-rate/add",
            exact: true,
            component: AddExRate,
            role: ["publisher", "admin"],
          },
          {
            path: "/admin/master/exchange-rate/view/:id",
            exact: true,
            component: ViewExRate,
            role: ["publisher", "admin"],
          },
          {
            path: "/admin/berita",
            exact: true,
            component: BeritaIklanAdmin,
          },
          {
            path: "/admin/berita/tambah",
            exact: true,
            component: TambahBeritaAdmin,
          },
          {
            path: "/admin/berita/tambah/preview",
            exact: true,
            component: PreviewTambahBeritaAdmin,
          },
          {
            path: "/admin/berita/edit/preview",
            exact: true,
            component: PreviewEditBeritaAdmin,
          },
          {
            path: "/admin/berita/:id",
            exact: true,
            component: EditBeritaAdmin,
          },
          {
            path: "/admin/finalisasi-berita",
            exact: true,
            component: FinalisasiBeritaIklanAdmin,
          },
          {
            path: "/admin/finalisasi-berita/view/preview",
            exact: true,
            component: PreviewFinalisasiBerita,
          },
          {
            path: "/admin/finalisasi-berita/view/:id",
            exact: true,
            component: PreviewBeritaIklanAdmin,
          },
          {
            path: "/admin/manajemen-web",
            exact: true,
            component: ManajemenWeb,
          },
          {
            path: "/admin/manajemen-web/preview",
            exact: true,
            component: PreviewManajemenWeb,
          },
          {
            path: "/admin/manajemen-web-admin",
            exact: true,
            component: ManajemenWebAdmin,
          },
          {
            path: "/admin/manajemen-web/preview-admin",
            exact: true,
            component: PreviewManajemenWebAdmin,
          },
        ],
      },
      {
        path: "/pencipta",
        component: DashboardAdminLayout,
        routes: [
          {
            path: "/pencipta/dashboard-pencipta",
            exact: true,
            component: DashboardPencipta,
          },
          {
            path: "/pencipta/dashboard-pencipta/detail-lagu/:id",
            exact: true,
            role: ["composer"],
            component: DetailLaguPencipta,
          },
          {
            path:
              "/pencipta/dashboard-pencipta/detail-lagu/detail-publisher/:id",
            exact: true,
            component: DetailPublisher,
          },
          {
            path: "/pencipta/kontrak",
            exact: true,
            role: ["pencipta"],
            component: KontrakListPencipta,
          },
          {
            path: "/pencipta/kontrak/detail-kontrak/:id/:songId",
            exact: true,
            component: KontrakListDetailPencipta,
          },
          {
            path: "/pencipta/permintaan-pembayaran",
            exact: true,
            component: PermintaanPembayaran,
          },
          {
            path: "/pencipta/permintaan-pembayaran/ajukan-pembayaran",
            exact: true,
            component: AjukanPembayaran,
          },
          {
            path: "/pencipta/profile-pencipta",
            exact: true,
            component: ProfilePencipta,
            role: ["publisher", "admin", "composer"],
          },
          {
            path: "/pencipta/payment",
            exact: true,
            role: ["pencipta"],
            component: PaymentPencipta,
          },
          {
            path: "/pencipta/payment/add",
            exact: true,
            component: PenciptaAddPayment,
          },
          {
            path: "/pencipta/payment/print-as-pdf/:id",
            exact: true,
            component: PrintPdfPencipta,
          },
          {
            path: "/pencipta/payment-mutation",
            exact: true,
            role: ["pencipta"],
            component: PaymentPenciptaMutation,
          },
          {
            path: "/pencipta/payment/:id",
            exact: true,
            component: PenciptaDetailPayment,
          },
          {
            path: "/pencipta/loan",
            exact: true,
            component: LoanPagePencipta,
          },
          {
            path: "/pencipta/loan/add",
            exact: true,
            component: AddLoanPencipta,
          },
          {
            path: "/pencipta/loan/:id",
            exact: true,
            component: DetailLoanPencipta,
          },
          {
            path: "/pencipta/review-lagu",
            exact: true,
            component: PenciptaReviewLagu,
          },
          {
            path: "/pencipta/review-lagu/print-report",
            exact: true,
            component: PrintReportReview,
            role: ["publisher", "admin"],
          },
          {
            path: "/pencipta/review-lagu/:id",
            exact: true,
            component: PenciptaDetailReviewLagu,
          },
        ],
      },
    ];
}
const itemRoutes = routesUsed;
export default itemRoutes;
