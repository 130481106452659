import Chip from "@material-ui/core/Chip";
import React from "react";

function ErrorChip({ chipLabel }) {
  return (
    <React.Fragment>
      <Chip
        label={chipLabel}
        style={{
          backgroundColor: "pink",
          border: "1px solid darkred",
          color: "darkred",
        }}
      />
    </React.Fragment>
  );
}

export default ErrorChip;
