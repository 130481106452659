import { Box, Grid } from "@mui/material";
import {
  DateTimeDisplay,
  ModalPreviewDocument,
  SearchTextInput,
  SelectInput,
  TableAction,
} from "components";
import { InnoTableV2 } from "inno-ui";
import moment from "moment-timezone";
import { useState } from "react";

const SupportingDocument = ({
  dataTable,
  queryParams,
  handleChangeQueryParams,
}) => {
  const [modalPreview, setModalPreview] = useState(false);
  const [selectedFile, setSelectedFile] = useState({});

  return (
    <Box>
      <Grid container justifyContent="space-between" my="16px">
        <Grid item>
          <SearchTextInput
            placeholder="Search"
            value={queryParams?.search}
            onChange={e => handleChangeQueryParams(e?.target?.value, "search")}
          />
        </Grid>
        <Grid item>
          <SelectInput
            options={optionSort}
            optionKey="key"
            optionLabel="value"
            value={queryParams?.sort}
            onChange={e => handleChangeQueryParams(e?.target?.value, "sort")}
            width={200}
          />
        </Grid>
      </Grid>
      <InnoTableV2
        isLoading={false}
        columns={columnTable}
        items={dataTable}
        isHaveAction
        renderAction={item => (
          <TableAction
            handleView={() => {
              setModalPreview(true);
              setSelectedFile(item);
            }}
          />
        )}
      />
      <ModalPreviewDocument
        open={modalPreview}
        onClose={() => setModalPreview(false)}
        preload={selectedFile}
      />
    </Box>
  );
};

const optionSort = [
  {
    key: "-created_at",
    value: "Latest",
  },
  {
    key: "created_at",
    value: "Oldest",
  },
];
const columnTable = [
  {
    name: "filename",
    title: "Document Title",
    renderText: item => item || "-",
  },
  {
    name: "all",
    title: "Uploaded At",
    renderText: item => (
      <DateTimeDisplay
        date={moment(`${item?.created_date} ${item?.created_time}`)}
      />
    ),
  },
];

export default SupportingDocument;
