import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Modal,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import {
  AddCircle,
  ArrowLeft,
  ArrowRight,
  CheckCircle,
} from "@material-ui/icons";
import Pagination from "@material-ui/lab/Pagination";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import PencilVector from "../../../assets/img/pencilVector.svg";
import TrashVector from "../../../assets/img/trashVector.svg";
import { hardBaseUrl } from "../../../services/urlConstant";
import AddBlogPosting from "./AddOrEditBlogPosting";

const columns = ["", "Action", "Title", "Body", "Status"];

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: "20px",
    fontWeight: "500",
    lineHeight: "32px",
  },
  pagination: {
    marginTop: 10,
    "& .Mui-selected": {
      backgroundColor: "black",
      color: "white",
    },
  },
  subTitle: {
    fontSize: "14px",
    lineHeight: "20px",
    color: "#687083",
    fontWeight: "400",
  },
  cardPadding: {
    padding: "16px 24px",
  },
  headerCard: {
    borderBottom: "solid 1px",
  },
  secondary: {
    background: "black",
    color: "white",
    marginLeft: 10,
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  card: {
    borderRadius: 16,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid #D1D5DC",
  },
  paper: {
    position: "absolute",
    width: 500,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  button: {
    height: "44px",
    marginTop: "20px",
    width: "fit-content",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "24px",
    backgroundColor: "black",
    color: "white",
    textTransform: "none",
  },
  boxArrow: {
    opacity: 0.5,
    background: "#6c6b6b45",
    alignItems: "end",
    display: "flex",
    alignSelf: "flex-end",
    borderRadius: 5,
    padding: ".5rem",
  },
  selectPadding: {
    "& .MuiSelect-outlined": {
      paddingTop: "14px",
      paddingBottom: "14px",
      paddingLeft: "20px",
      paddingRight: "30px",
    },
    marginLeft: ".5rem",
  },
  ib: {
    padding: "4px",
    color: "white",
    backgroundColor: "black",
    borderRadius: "6px",
    "&:focus": {
      color: "white",
      backgroundColor: "black",
    },
    "&:active": {
      boxShadow: "none",
      color: "white",
      backgroundColor: "black",
    },
    "&:hover, &.MuiIconButton": {
      background: "#374151 !important"
    },
  },
  contentImage: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: 100,
    background: "#E3E6EF",
  },
  inputFields: {
    height: "44px",
    marginLeft: "0px !important",
    paddingTop: "0px",
    paddingBottom: "0px",
    "& .MuiOutlinedInput-root": {
      width: 250,
      height: "44px",
      paddingTop: "0px",
      paddingBottom: "0px",
    },
  },
}));

const DeleteConfirmation = ({ isOpen, handleClose, onSubmit }) => {
  const classes = useStyles();
  return (
    <Modal open={isOpen} onClose={handleClose} className={classes.modal}>
      <Grid container direction="column" className={classes.paper} spacing={1}>
        <Grid item>
          <Typography className={classes.title}>Delete Blog Posting</Typography>
        </Grid>
        <Grid item>
          <Typography className={classes.subTitle}>
            Are you sure want to delete this data ?
          </Typography>
        </Grid>
        <Grid item style={{ alignSelf: "end" }}>
          <Button onClick={handleClose} variant="outlined">
            Cancel
          </Button>
          <Button
            onClick={onSubmit}
            variant="outlined"
            className={classes.secondary}
          >
            Delete
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
};

function BlogPosting() {
  const classes = useStyles();
  const token = localStorage.getItem("token");
  const [state, setState] = useState({
    isOpen: false,
    listContentTable: [],
    listContentHeader: [],
    openDelete: null,
    search: "",
    page: 1,
    size: 10,
    total: 0,
    editOrder: false,
    value: {
      title: "",
      body: "",
      image: "",
      status: "",
      id: null,
    },
  });
  const {
    isOpen,
    listContentTable,
    listContentHeader,
    value,
    search,
    openDelete,
    page,
    size,
    total,
    editOrder,
  } = state;

  const changeValue = (key, value) =>
    setState(currentState => ({
      ...currentState,
      [key]: value,
    }));

  const changeValueField = (key, value) =>
    setState(currentState => ({
      ...currentState,
      value: {
        ...currentState?.value,
        [key]: value,
      },
    }));
  const setIsOpen = value =>
    setState(currentState => ({
      ...currentState,
      isOpen: value,
    }));

  const closeModal = () => {
    setState(currentState => ({
      ...currentState,
      value: {
        title: "",
        body: "",
        image: "",
        status: "",
      },
    }));
    setIsOpen(false);
  };

  const openEdit = (title, body, image, id, status) => {
    const valueForEdit = {
      title,
      body,
      image,
      status,
      id,
    };
    setState(currentState => ({
      ...currentState,
      value: valueForEdit,
    }));
    setIsOpen(true);
  };

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const editOrderList = (index, type) => {
    const data = [...listContentHeader];
    const newData = data.map(obj => ({ ...obj }));
    if (type === "forward" && index < newData.length - 1) {
      const tempOrderNumber = newData[index].order_number;
      newData[index].order_number = newData[index + 1].order_number;
      newData[index + 1].order_number = tempOrderNumber;
    } else {
      const tempOrderNumber = newData[index].order_number;
      newData[index].order_number = newData[index - 1].order_number;
      newData[index - 1].order_number = tempOrderNumber;
    }
    changeValue(
      "listContentHeader",
      newData.sort((a, b) => a.order_number - b.order_number)
    );
  };

  const editBlog = (title, body, image, id, status) => {
    const url = `${hardBaseUrl}/blog-posting/update/${id}`;
    const formData = new FormData();
    formData.append("title", title);
    formData.append("body", body);
    formData.append("image", image);
    formData.append("status", status);
    axios.put(url, formData, config).then(() => {
      closeModal();
      Swal.fire({
        icon: "success",
        title: "Sukses",
        text: "Sukses Edit Blog",
      }).then(() => {
        getListTable(page, size, search);
      });
    });
  };

  const addBlog = (title, body, image) => {
    const url = `${hardBaseUrl}/blog-posting/create`;
    const formData = new FormData();
    formData.append("title", title);
    formData.append("body", body);
    formData.append("image", image);
    formData.append("status", "draft");
    axios.post(url, formData, config).then(() => {
      closeModal();
      Swal.fire({
        icon: "success",
        title: "Sukses",
        text: "Sukses Tambah Blog",
      }).then(() => {
        getListTable(page, size, search);
      });
    });
  };

  const getListHeader = async () => {
    const url = `${hardBaseUrl}/blog-posting/list`;
    const params = {
      sort: "order_number asc",
      page: 1,
      size: 3,
    };
    const newConfig = {
      ...config,
      params,
    };
    try {
      const responseListOrderHeader = await axios.get(url, newConfig);
      changeValue(
        "listContentHeader",
        responseListOrderHeader?.data?.data || []
      );
    } catch (err) {
      new Error(err);
    }
  };

  const getListTable = async (page, size, search) => {
    const url = `${hardBaseUrl}/blog-posting/list`;
    const params = {
      page,
      size,
      search,
    };
    const newConfig = {
      params,
      ...config,
    };
    try {
      const responseListOrderTable = await axios.get(url, newConfig);
      changeValue("listContentTable", responseListOrderTable?.data?.data || []);
      changeValue("total", responseListOrderTable?.data?.meta?.total || 0);
    } catch (err) {
      new Error(err);
    }
  };

  const deleteBlog = id => {
    const url = `${hardBaseUrl}/blog-posting/delete/${id}`;
    axios
      .delete(url, config)
      .then(() => {
        Swal.fire({
          icon: "success",
          title: "Sukses",
          text: "Sukses Hapus Blog",
        }).then(() => {
          getListTable(page, size, search);
          getListHeader();
        });
      })
      .catch(err => {
        Swal.fire({
          icon: "error",
          title: "Failed",
          text: err || "Gagal Hapus Blog",
        });
      });
  };

  const saveOrderList = () => {
    if (!editOrder) {
      changeValue("editOrder", !editOrder);
    } else {
      const url = `${hardBaseUrl}/blog-posting/order`;
      const data = listContentHeader.map(({ id, order_number }) => ({
        id,
        order_number,
      }));
      axios.post(url, data, config).then(() => {
        getListHeader();
        changeValue("editOrder", false);
      });
    }
  };

  const sortOrderByAlphabet = () => {
    const newData = [...listContentHeader].sort((a, b) =>
      a?.title.localeCompare(b.title)
    );
    changeValue("listContentHeader", newData);
  };

  useEffect(() => {
    getListHeader();
  }, []);

  useEffect(() => {
    getListTable(page, size, search);
  }, [page, size, search]);

  const isEditing = Boolean(value?.id);
  const pagesCount = Math.ceil(total / size);
  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      spacing={3}
    >
      <DeleteConfirmation
        isOpen={openDelete}
        handleClose={() => changeValue("openDelete", null)}
        onSubmit={() => {
          deleteBlog(openDelete);
          changeValue("openDelete", null);
        }}
      />
      <AddBlogPosting
        open={isOpen}
        onClose={closeModal}
        value={value}
        handleChange={changeValueField}
        onSubmit={isEditing ? editBlog : addBlog}
        isEditing={isEditing}
      />
      <Grid item>
        <Typography className={classes.title}>Blog Posting</Typography>
        <Typography className={classes.subTitle}>
          This page is for manage blog posting section in landing page
        </Typography>
      </Grid>
      <Grid item>
        <Card className={classes.card}>
          <CardHeader
            component={() => (
              <>
                <Grid
                  className={classes.cardPadding}
                  container
                  justifyContent="space-between"
                >
                  <Typography className={classes.title}>
                    Picture List
                  </Typography>
                  <Grid item>
                    <Button onClick={sortOrderByAlphabet} variant="outlined">
                      A - Z
                    </Button>
                    <Button
                      onClick={saveOrderList}
                      className={classes.secondary}
                    >
                      {!editOrder ? "Edit Order" : "Save Changes"}
                    </Button>
                  </Grid>
                </Grid>
                <Divider className={classes.divider} />
              </>
            )}
          />
          <CardContent className={classes.cardPadding}>
            <Grid container justifyContent="space-between" spacing={2}>
              {listContentHeader.map(({ image, title }, index) => (
                <Grid xs={4} item key={index}>
                  <Card className={classes.contentImage}>
                    {editOrder && index !== 0 && (
                      <IconButton
                        className={classes.boxArrow}
                        onClick={() => editOrderList(index, "backward")}
                      >
                        <ArrowLeft />
                      </IconButton>
                    )}
                    <img
                      src={image}
                      alt={image}
                      style={{ margin: "0px 0.5rem" }}
                    />
                    {editOrder && index !== listContentHeader?.length - 1 && (
                      <IconButton
                        className={classes.boxArrow}
                        onClick={() => editOrderList(index, "forward")}
                      >
                        <ArrowRight />
                      </IconButton>
                    )}
                  </Card>
                  <Typography>{title}</Typography>
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item>
        <Grid container spacing={3}>
          <Grid
            container
            item
            justifyContent="space-between"
            alignItems="center"
            direction="row"
          >
            <TextField
              id="input-with-icon-textfield"
              variant="outlined"
              style={{
                marginLeft: "8px",
              }}
              className={classes.inputFields}
              onChange={e => changeValue("search", e?.target?.value)}
              value={search}
              placeholder="Search"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img alt="Logo" src={require("assets/image-public/images/search.svg").default} />
                  </InputAdornment>
                ),
              }}
            />
            <Button
              onClick={() => setIsOpen(true)}
              className={classes.secondary}
            >
              Create Blog Posting
            </Button>
          </Grid>
          <Grid container>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {columns.map((item, i) => (
                      <TableCell key={i}>{item}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {listContentTable.map(
                    ({ title, body, status, image, id }, key) => (
                      <TableRow key={key}>
                        <TableCell width={15}>
                          {status === "published" ? (
                            <CheckCircle style={{ color: green[500] }} />
                          ) : (
                            <AddCircle style={{ color: "#244B99" }} />
                          )}
                        </TableCell>
                        <TableCell width={100}>
                          <IconButton
                            onClick={() =>
                              openEdit(title, body, image, id, status)
                            }
                            classes={{ root: classes.ib }}
                          >
                            <img src={PencilVector} />
                          </IconButton>
                          <IconButton
                            onClick={() => changeValue("openDelete", id)}
                            classes={{ root: classes.ib }}
                            style={{ marginLeft: "8px" }}
                          >
                            <img src={TrashVector} />
                          </IconButton>
                        </TableCell>
                        <TableCell width={150}>{title}</TableCell>
                        <TableCell style={{ wordBreak: "break-all" }}>
                          {body}
                        </TableCell>
                        <TableCell style={{ textTransform: "capitalize" }}>
                          {status}
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
              padding=".5rem 0px"
            >
              <Box display="flex" alignItems="center">
                <Typography>Item By Page</Typography>
                <Select
                  displayEmpty
                  className={classes.selectPadding}
                  variant="outlined"
                  value={size}
                  onChange={e => changeValue("size", e?.target?.value)}
                >
                  {[5, 10, 20, 50].map(item => {
                    return (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Box>
              <Box>
                <Pagination
                  count={pagesCount || 1}
                  shape="rounded"
                  className={classes.pagination}
                  page={page}
                  onChange={(_, value) => changeValue("page", value)}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default BlogPosting;
