import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
  },
  results: {
    marginTop: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  mypadding: {
    "& .MuiSelect-outlined": {
      paddingTop: "10.5px",
      paddingBottom: "10.5px",
    },
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  table: {
    marginTop: "1rem",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  pagination: {
    "& .Mui-selected": {
      backgroundColor: "black",
      color: "white",
    },
  },
  pageSize: {
    height: "30px",
    borderRadius: "6px",
    border: "1px solid #D1D5DC",
    paddingLeft: "5px",
    paddingRight: "5px",
    marginTop: "5px",
  },
  paginationText: {
    fontSize: "14px",
    fontWeight: "normal",
    fontStyle: "normal",
    color: "#687083",
  },
  blackButton: {
    backgroundColor: "#111827",
    color: "#FFFFFF",
  },
  breadcrumbs: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#111827",
  },
  breadCrumbsActive: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#687083",
  },
  placeholder: {
    "&::placeholder": {
      color: "#9AA2B1",
    },
  },
  title: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "28px",
  },
  label: {
    fontSize: "14px",
    fontWeight: 500,
  },
  subTitle: {
    fontSize: "18px",
    fontWeight: 700,
    color: " #111827",
  },
  myinput: {
    "& .MuiOutlinedInput-input": {
      paddingTop: "11px",
      paddingLeft: "12px",
      paddingRight: "12px",
      paddingBottom: "11px",
    },
  },
  bodyTextWhite: {
    fontSize: "14px",
    fontWeight: "regular",
    fontStyle: "normal",
    color: "white",
    background: "black",
    transition: "all 0.2s ease-in",
    textTransform: "capitalize",
    width: "fit-content",
    "&:hover": {
      background: "white",
      color: "black",
    },
  },
  backdrop1: {
    zIndex: 100,
    color: "#fff",
  },
  mydateinput: {
    "& .MuiOutlinedInput-root": {
      paddingTop: "0px",
      paddingBottom: "0px",
      height: "44px",
    },
  },
  inputNum: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "0px 6px 6px 0px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderLeft: "1px solid #d1d5dc",
      borderRight: "1px solid #d1d5dc",
      borderTop: "1px solid #d1d5dc",
      borderBottom: "1px solid #d1d5dc",
    },
    "& .MuiOutlinedInput-input": {
      paddingTop: "10.5px",
      paddingBottom: "10.5px",
    },
    "& input.Mui-disabled": {
      color: "black",
    },
  },
  activeChip: {
    backgroundColor: "#8DE5AB",
    color: "#34774C",
    fontSize: "14px",
    fontWeight: "500",
  },
  activeChipIndicator: {
    color: "#34774C",
    fontSize: "12px",
  },
  progresChip: {
    backgroundColor: "#f5e340",
    color: "#364052",
    fontSize: "14px",
    fontWeight: "500",
  },
  progresChipIndicator: {
    color: "#c9be5b",
    fontSize: "12px",
  },
  nonActiveChip: {
    backgroundColor: "#fa7878",
    color: "#d40202",
    fontSize: "14px",
    fontWeight: "500",
  },
  nonActiveChipIndicator: {
    color: "#d40202",
    fontSize: "12px",
  },
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 700,
  },
  cardCatalogue: {
    border: "1px solid #D1D5DC",
    borderRadius: "6px",
    boxShadow: "none",
  },
  buttonBack: {
    marginLeft: "10px",
    backgroundColor: "#111827",
    borderRadius: "6px",
    width: "84px",
    height: "40px",
    color: "#FFFFFF",
    textTransform: "none",
  },
  labelBack: {
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: "500",
    fontStyle: "normal",
    textTransform: "none",
  },
  cardDsp: {
    border: "1px solid #D1D5DC",
    borderRadius: "6px",
    boxShadow: "none",
  },
  typographyTitle: {
    fontSize: "24px",
    fontWeight: "700",
    lineHeight: "32px",
  },
  bottomFilter: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "16px",
  },
  outlinedInput: {
    width: "250px",
    height: "44px",
    border: "1px solid #D1D5DC",
    borderRadius: "6px",
  },
  invitation: {
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
  },
  btnCancel: {
    height: "40px",
    width: "90px",
    borderRadius: "6px",
    border: "1px solid #D1D5DC",
    color: "#111827",
    backgroundColor: "white",
    textTransform: "none",
  },
  btnSend: {
    height: "40px",
    width: "90px",
    borderRadius: "6px",
    border: "0px solid #D1D5DC",
    color: "white",
    backgroundColor: "#111827",
    textTransform: "none",
  },
  searchIcon: { color: "#9AA2B1" },
  titleSpan: {
    fontSize: "16px",
  },
  subTitleSpan: {
    color: "#8f8f8f",
    fontSize: "14px",
  },
  btnDownloadError: {
    padding: "10px 15px",
    backgroundColor: "#111827",
    borderRadius: "6px",
    color: "#FFFFFF",
    textTransform: "none",
  },
  labelBtnDownloadError: {
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: "500",
    textTransform: "none",
  },
  mt: {
    marginTop: "32px",
  },
}));
