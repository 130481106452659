import Chip from "@material-ui/core/Chip";
import { makeStyles } from "@material-ui/styles";
import React from "react";

const useStyles = makeStyles(() => ({
  root: {},
  divider: {},
  results: {},
  backdrop: {},
  title: {},
  moreButton: {
    margin: "2px",
    color: "#4991F2",
    border: "1px solid #D1D5DC",
    backgroundColor: "#F9FAFB",
    "&:hover": {
      backgroundColor: "lightgrey",
    },
  },
}));

function ChipComponent({ items }) {
  const classes = useStyles();

  const [totalItems, setTotalItems] = useState(0);
  const [showMore, setShowMore] = useState(false);

  React.useEffect(() => {
    setTotalItems(items.length - 3);
  }, []);

  const handleShowMore = () => {
    let currentShow = showMore;
    setShowMore(!currentShow);
  };

  return (
    <React.Fragment>
      {showMore === true ? (
        <React.Fragment>
          {items.map(item => {
            return (
              <Chip
                label={item.performer_name}
                key={item.performer_id}
                style={{
                  margin: "2px",
                  backgroundColor: "#F9FAFB",
                  border: "1px solid #D1D5DC",
                }}
              />
            );
          })}
        </React.Fragment>
      ) : (
        <React.Fragment>
          {items.map((item, idx) => {
            return (
              <React.Fragment key={idx}>
                {idx <= 2 ? (
                  <React.Fragment>
                    <Chip
                      label={item.performer_name}
                      key={item.performer_id}
                      style={{
                        margin: "2px",
                        backgroundColor: "#F9FAFB",
                        border: "1px solid #D1D5DC",
                      }}
                    />
                  </React.Fragment>
                ) : (
                  <React.Fragment>{""}</React.Fragment>
                )}
              </React.Fragment>
            );
          })}
          {totalItems > 0 ? (
            <Chip
              label={`+${totalItems}`}
              onClick={() => {
                handleShowMore();
              }}
              className={classes.moreButton}
            />
          ) : (
            <>{""}</>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

export default ChipComponent;
