import { FormLabel, SectionLabel, StatusChip } from "components";
import { Box, Grid, styled, Typography } from "@mui/material";
import { formatDate } from "utils";
import { InnoTableV2 } from "inno-ui";
import moment from "moment-timezone";

const ContractDetailForm = ({ preload }) => {
  const { contract } = preload;
  return (
    <Box>
      <SectionLabel
        title="Contract Information"
        subTitle="Details of your contract information."
      />
      <FormCard>
        <Grid container rowSpacing={2}>
          <Grid item xs={12} md={6}>
            <FormLabel label="Contract Number" />
            <Typography>{contract?.contract_number || "-"}</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormLabel label="Status" />
            <StatusChip
              type={contract?.is_active_flag && "success"}
              label={contract?.is_active_flag ? "Active" : "Inactive"}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormLabel label="Start Date" />
            <Typography>
              {contract?.start_date
                ? formatDate(getPreloadDate(contract?.start_date))
                : "-"}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormLabel label="End Date" />
            <Typography>
              {contract?.end_date
                ? formatDate(getPreloadDate(contract?.end_date))
                : "-"}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormLabel label="Publisher" />
            <Typography>{contract?.publisher || "-"}</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormLabel label="Publisher ID" />
            <Typography>{contract?.id_publisher || "-"}</Typography>
          </Grid>
        </Grid>
      </FormCard>
      <SectionLabel
        title="Song Information"
        subTitle="Details of your song information."
      />
      <FormCard>
        <Grid container rowSpacing={2}>
          <Grid item xs={12} md={6}>
            <FormLabel label="Title" />
            <Typography>{preload?.song_title || "-"}</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormLabel label="ISWC Code" />
            <Typography>{preload?.iswc_code || "-"}</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormLabel label="Ownership" />
            <Typography>{`${preload?.total_ownership || 0} %`}</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormLabel label="Performer" />
            <Typography>{preload?.performer || "-"}</Typography>
          </Grid>
        </Grid>
      </FormCard>
      <SectionLabel title="Composer" subTitle="Details of your Composer." />
      <Box my="16px">
        <InnoTableV2
          isLoading={false}
          columns={columnTable}
          items={[preload]}
        />
      </Box>
    </Box>
  );
};

const getPreloadDate = date => moment(date, "DD-MM-YYYY hh:mm");
const FormCard = styled(Box)({
  border: "1px solid #ebebeb",
  borderRadius: "5px",
  padding: "24px",
  margin: "16px 0",
});
const columnTable = [
  { name: "composer_name", title: "Composer Name" },
  { name: "iswc_code", title: "ISWC Code" },
  {
    name: "composer_ownership",
    title: "Ownership Percentage",
    renderText: item => `${item || 0} %`,
  },
];

export default ContractDetailForm;
