import {
  Button,
  ClickAwayListener,
  IconButton,
  OutlinedInput,
  ThemeProvider,
  Typography,
  createTheme,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useState } from "react";
import { useLocation } from "react-router";
import LandingHeader from "./LandingHeader";
import "./LandingStyle.css";
import { feBaseUrl } from "../../../services/urlConstant";

const interFont = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});
const useStyles = makeStyles(() => ({
  label: {
    margin: "12px 0 0 0",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "26px",
  },
}));

function LandingHero(props) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const blogDetail = props?.type === "blog-detail";
  const location = useLocation();
  const [showDropdown, setShowDropdown] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
    if (showDropdown) {
      setIsCopied(false);
    }
  };
  const handleCopyClick = () => {
    const dummyInput = document.createElement("input");
    document.body.appendChild(dummyInput);
    dummyInput.value = `${feBaseUrl}${location?.pathname}`;
    dummyInput.select();
    document.execCommand("copy");
    document.body.removeChild(dummyInput);

    setIsCopied(true);
  };
  const handleShare = platform => {
    switch (platform) {
      case "facebook":
        window.open(
          `https://www.facebook.com/sharer/sharer.php?u=${feBaseUrl}${location?.pathname}`
        );
        break;
      case "twitter":
        window.open(
          `https://twitter.com/intent/tweet?url=${feBaseUrl}${location?.pathname}`
        );
        break;
      case "whatsapp":
        window.open(
          `https://api.whatsapp.com/send?text=Check%20out%20this%20link:%20${feBaseUrl}${location?.pathname}`
        );
        break;
      case "linkedin":
        window.open(
          `https://www.linkedin.com/shareArticle?url=${feBaseUrl}${location?.pathname}`
        );
        break;
      default:
        // Handle other platforms as needed
        break;
    }
  };
  const socialMediaList = [
    {
      id: "linkedin",
      name: "Linked-In",
      icon: "bx bxl-linkedin",
      color: "#1177b5",
    },
    {
      id: "twitter",
      name: "Twitter",
      icon: "bx bxl-twitter",
      color: "#1da1f1",
    },
    {
      id: "facebook",
      name: "Facebook",
      icon: "bx bxl-facebook",
      color: "#4267b2",
    },
    {
      id: "whatsapp",
      name: "WhatsApp",
      icon: "bx bxl-whatsapp",
      color: "#2bd46a",
    },
  ];

  return (
    <div className="landing-hero" data-testid="data-test-page-landing-page">
      <div className={blogDetail ? "landing-hero-blog-detail" : ""}>
        <props.heroBackground />
        <LandingHeader />
        <div className="hero-content-container">
          <props.heroContent />
        </div>
        {!blogDetail && (
          <IconButton className="button-share" onClick={toggleDropdown}>
            <img alt="share-thumbnail" src={require("assets/image-public/images/Button-share.png")} />
          </IconButton>
        )}
        {showDropdown && (
          <ClickAwayListener onClickAway={toggleDropdown}>
            <div className="share-dropdown">
              <div className="share-dropdown-quotation" />
              <ThemeProvider theme={interFont}>
                <Typography
                  style={{
                    fontWeight: 400,
                    fontSize: isMobile ? "14px" : "16px",
                    marginBottom: "12px",
                  }}
                >
                  Share this unique offer :
                </Typography>
              </ThemeProvider>
              <div>
                {socialMediaList?.map((item, index) => (
                  <IconButton
                    key={item?.id}
                    style={{
                      marginRight: isMobile
                        ? "6px"
                        : index === socialMediaList?.length - 1
                          ? 0
                          : "10px",
                      padding: "6px",
                      background: item?.color,
                    }}
                    onClick={() => handleShare(item?.id)}
                  >
                    <i
                      className={item?.icon}
                      style={{
                        color: "white",
                        fontSize: isMobile ? "28px" : "38px",
                      }}
                    />
                  </IconButton>
                ))}
              </div>
              <label className={classes.label}>Share this link</label>
              <div className="url-copy-containter">
                <OutlinedInput
                  type="text"
                  value={`${feBaseUrl}${location?.pathname}`}
                  readOnly
                  style={{
                    flex: 1,
                    marginRight: "8px",
                  }}
                />
                <Button className="button-copy" onClick={handleCopyClick}>
                  Copy URL
                </Button>
              </div>
              {isCopied && (
                <ThemeProvider theme={interFont}>
                  <Typography
                    style={{
                      fontWeight: 400,
                      fontSize: isMobile ? "8px" : "12px",
                      marginTop: "12px",
                    }}
                  >
                    Link copied to clipboard
                  </Typography>
                </ThemeProvider>
              )}
            </div>
          </ClickAwayListener>
        )}
      </div>
    </div>
  );
}

export default LandingHero;
