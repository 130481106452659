import {
  Breadcrumbs,
  Container,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  Snackbar,
  TextField,
  Typography,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { Page } from "components";
import { hardBaseUrl } from "../../../../services/urlConstant";
import gradients from "../../../../theme/gradients";
import MainContract from "./MainContract";
import SongContract from "./SongContract";

const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  card: {
    width: theme.breakpoints.values.md,
    maxWidth: "100%",
    overflow: "visible",
    display: "flex",
    position: "relative",
    "& > *": {
      flexGrow: 1,
      flexBasis: "50%",
      width: "50%",
    },
  },
  content: {
    padding: theme.spacing(8, 4, 3, 4),
  },
  media: {
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    padding: theme.spacing(3),
    color: theme.palette.common.white,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  avatar_large: {
    width: theme.spacing(16),
    height: theme.spacing(16),
  },
  icon: {
    backgroundImage: gradients.green,
    color: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    position: "absolute",
    top: -32,
    left: theme.spacing(3),
    height: 64,
    width: 64,
    fontSize: 32,
  },
  loginForm: {
    marginTop: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  person: {
    marginTop: theme.spacing(2),
    display: "flex",
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  title: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "28px",
  },
  tambahLagu: {
    backgroundColor: "black",
    color: "white",
    width: "32px",
    height: "29px",
    borderRadius: "6px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
  textTambahLagu: {
    marginLeft: "10px",
    marginTop: "5px",
    fontSize: "14px",
    color: "#687083",
  },
  btnDialog: {
    backgroundColor: "black",
    color: "white",
    padding: "10px 28px",
    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
  btnSubmit: {
    backgroundColor: "black",
    color: "white",
    padding: "10px 28px",
    height: "40px",
    borderRadius: "6px",
    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
  headText: {
    fontSize: "12px",
    color: "#687083",
    fontWeight: "bold",
  },
  bodyTextBlack: {
    fontSize: "14px",
    fontWeight: "regular",
    color: "#111827",
  },
  bodyTextGrey: {
    fontSize: "14px",
    fontWeight: "regular",
    color: "#687083",
  },
  tableRow: {
    height: 20,
  },
  conTextBlack: {
    fontSize: "14px",
    fontWeight: "bold",
  },
  dropdown: {
    color: "black",
  },
  label: {
    color: "#364052",
    fontSize: "14px",
    fontWeight: 500,
  },
  subTitle: {
    fontSize: "18px",
    fontWeight: 700,
    color: " #111827",
  },
  breadcrumbs: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#111827",
  },
  breadCrumbsActive: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#687083",
  },
  rootSwitch: {
    width: "40px",
    height: "20px",
    padding: "0px",
    borderRadius: "10px",
    marginRight: "8px",
    position: "relative",
  },
  switchBase: {
    padding: "10px",
    color: "white",
    "&.Mui-checked": {},
    "&.Mui-checked + $track": {
      border: "none",
      color: "white",
      backgroundColor: "black",
      opacity: 1,
    },
  },
  checked: {},
  thumb: {
    color: "white",
    position: "absolute",
    width: "16px",
    height: "16px",
  },
  track: {
    backgroundColor: "#E4E7EB",
    borderRadius: "25px",
    border: "0px solid #606060",
    height: "auto",
    opacity: 1,
  },
}));
function TambahKontrak() {
  const classes = useStyles();
  const checked = false;
  const startDate = moment().toString();
  const endDate = moment().toString();
  const [openSnack, setOpenSnack] = useState(false);
  const typeSnackbar = "success";
  const message = "This is a message!";
  const wordsSong = "";
  const [publisherId, setPublisherId] = useState();
  const contractType = ["Song Contract", "Main Contract"];
  const [contractTypeValue, setContractTypeValue] = useState("Song Contract");
  const handleChangeContractType = event => {
    setContractTypeValue(event.target.value);
  };
  const getMe = () => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const url = `${hardBaseUrl}/me`;
    axios
      .get(url, config)
      .then(res => {
        setPublisherId(res.data.data.publisher.publisher_id);
      })
      .catch(() => { });
  };
  useEffect(() => {
    getMe();
  }, []);
  useEffect(() => { }, [publisherId]);

  useEffect(() => { }, [checked, startDate, endDate, wordsSong]);
  const handleCloseSnackbar = () => {
    setOpenSnack(false);
  };
  return (
    <Page className={classes.root} title="Tambah Kontrak">
      <Container maxWidth={false}>
        <Snackbar
          open={openSnack}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert onClose={handleCloseSnackbar} severity={typeSnackbar}>
            {message}
          </Alert>
        </Snackbar>
        <Grid
          alignItems="flex-end"
          container
          justifyContent="space-between"
          spacing={3}
        >
          <Grid item>
            <ThemeProvider theme={theme}>
              <Typography
                style={{
                  fontSize: "24px",
                  fontWeight: "700",
                  lineHeight: "32px",
                }}
              >
                Add Contract
              </Typography>
            </ThemeProvider>
          </Grid>
          <Grid item>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography className={classes.breadCrumbs}>Home</Typography>
              <Typography className={classes.breadCrumbs}>Contract</Typography>
              <Typography className={classes.breadCrumbsActive}>
                Add Contract
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        <Grid container>
          <div>
            <ThemeProvider theme={theme}>
              <Typography
                component="h1"
                variant="h3"
                className={classes.subTitle}
              >
                Contract Type
              </Typography>
            </ThemeProvider>
            <ThemeProvider theme={theme}>
              <p
                style={{
                  marginTop: "5px",
                  color: "#8f8f8f",
                  fontSize: "14px",
                }}
              >
                Feature to select type of contract between main or song contract
              </p>
            </ThemeProvider>
          </div>
        </Grid>
        <Grid container>
          <Grid item xs={8} md={8} sm={8} lg={8} xl={8}>
            <FormControl
              className={classes.formControl}
              fullWidth={true}
              id="dsp-filter"
            >
              <TextField
                fullWidth={true}
                size="small"
                className={classes.select}
                onChange={handleChangeContractType}
                value={contractTypeValue}
                select
                variant="outlined"
              >
                {contractType.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Grid>
          <Grid item xs={4} md={4} sm={4} lg={4} xl={4}></Grid>
        </Grid>
        <Grid container>
          <Grid item xs={8} md={8} sm={8} lg={8} xl={8}>
            <Divider className={classes.divider} />
          </Grid>
        </Grid>
        {contractTypeValue === "Song Contract" ? (
          <SongContract />
        ) : (
          <MainContract />
        )}
      </Container>
    </Page>
  );
}
export default TambahKontrak;
