import { Facebook, Instagram, Twitter, YouTube } from "@material-ui/icons";
import { Col, Row } from "antd";
import { Link } from "react-router-dom";
import logoLight from "../../../../assets/img/logo-light.png";
import nadakuLogoFooter from "../../../../assets/img/nadakuFig.png";
import supported from "../../../../assets/img/supported-img.png";
import "./layout.css";

export default function Layout(params) {
  const hardSubDomain = localStorage.getItem("hardSubDomain");
  const { children } = params;

  return (
    <div className="fill-window" id="fill-window">
      <div className="children" id="children">
        {children}
      </div>
      <div className="footer">
        <div className="container content">
          <Row>
            <Col span={18}>
              {hardSubDomain !== null ? (
                <>
                  {hardSubDomain === "mpis" ? (
                    <>
                      <div>
                        <img src={logoLight} alt="" />
                      </div>
                    </>
                  ) : (
                    <></>
                  )}

                  {hardSubDomain === "nadaku" ? (
                    <>
                      <div>
                        <img
                          src={nadakuLogoFooter}
                          alt=""
                          style={{
                            height: "32px",
                          }}
                        />
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <>
                  {hardSubDomain !== null ? (
                    <div>
                      <img
                        src={nadakuLogoFooter}
                        alt=""
                        style={{
                          height: "32px",
                        }}
                      />
                    </div>
                  ) : (
                    <div>
                      <img src={logoLight} alt="" />
                    </div>
                  )}
                </>
              )}

              <div style={{ display: "flex", marginTop: "20px" }}>
                {hardSubDomain !== null ? (
                  <>
                    {hardSubDomain === "mpis" ? (
                      <>
                        <Link to="" style={{ color: "white" }}>
                          Penerbit
                        </Link>
                      </>
                    ) : (
                      <></>
                    )}
                    {hardSubDomain === "nadaku" ? <></> : <></>}
                  </>
                ) : (
                  <>
                    {hardSubDomain !== null ? (
                      <></>
                    ) : (
                      <>
                        <Link to="" style={{ color: "white" }}>
                          Penerbit
                        </Link>
                      </>
                    )}
                  </>
                )}

                <Link to="" style={{ color: "white" }}>
                  Berita
                </Link>
                <Link to="" style={{ color: "white", marginLeft: "20px" }}>
                  Edukasi
                </Link>
                <Link to="" style={{ color: "white", marginLeft: "20px" }}>
                  Tentang Kami
                </Link>
              </div>
            </Col>
            <Col span={6} style={{ textAlign: "right" }}>
              <p>Supported by:</p>
              <img src={supported} alt="" />
            </Col>
            <hr
              style={{
                borderColor: "white",
                width: "100%",
                marginTop: "30px",
                marginBottom: "10px",
              }}
            />
          </Row>
          <Row>
            <Col span={18}>Copyright © 2021 MPIS, Inc.</Col>
            <Col span={6}>
              <div
                style={{ display: "flex", justifyContent: "flex-end" }}
                className="sosmed"
              >
                <Link to="">
                  <Twitter style={{ color: "white" }} />
                </Link>
                <Link to="">
                  <YouTube style={{ color: "white", marginLeft: "10px" }} />
                </Link>
                <Link to="">
                  <Facebook style={{ color: "white", marginLeft: "10px" }} />
                </Link>
                <Link to="">
                  <Instagram style={{ color: "white", marginLeft: "10px" }} />
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}
