import React, { useState, useEffect } from "react";
import Layout from "../../../mpisLandingComponent/Layout";
import "./Edukasi.css";
import { Row, Col } from "antd";
import Chip from "../../../mpisLandingComponent/Chip/Chip";
import Publisher from "../../../mpisLandingComponent/Publisher";
import { ArrowRightOutlined } from "@ant-design/icons";
import Axios from "axios";
import { hardBaseUrl } from "../../../services/urlConstant";
import { useHistory } from "react-router";

export default function Edukasi() {
  const history = useHistory();
  const [edukasi, setEdukasi] = useState([]);

  const getEdukasi = () => {
    try {
      Axios.get(hardBaseUrl + "/article/public?type=edukasi").then(res => {
        if (res.data.message === "success") {
          let data = res.data.data;
          setEdukasi(data);
        }
      });
    } catch (error) {
      new Error(error);
    }
  };

  useEffect(() => {
    getEdukasi();
  }, []);

  const handleDetailBerita = res => {
    history.push({
      pathname: `/artikel/detail/${res.article_id}`,
      state: res,
    });
  };

  return (
    <Layout>
      <div className="edukasi">
        <div className="container">
          <h1>Insight for You</h1>
          <div style={{ height: "576px" }}>
            <Row style={{ height: "100%" }} gutter={16}>
              {edukasi?.length > 0 && (
                <Col
                  onClick={() => handleDetailBerita(edukasi[0])}
                  span={16}
                  style={{ height: "100%" }}
                  className="banner-wrapper"
                >
                  <img
                    src={edukasi[0].banner_image}
                    alt=""
                    className="image"
                    style={{ height: "100%", width: "100%" }}
                  />
                  <div className="content">
                    <Chip color="white" title="Education" />
                    <h1 style={{ color: "white", margin: 0 }}>
                      {edukasi[0].title}
                    </h1>
                    <Publisher
                      publisher={edukasi[0].publisher_name}
                      day={edukasi[0].range_date}
                      color="white"
                      fontSize="14px"
                    />
                  </div>
                </Col>
              )}

              {edukasi?.length > 0 && (
                <Col
                  span={8}
                  style={{
                    height: "100%",
                  }}
                >
                  <div
                    style={{
                      height: "50%",
                      paddingBottom: "9px",
                      position: "relative",
                    }}
                    className="banner-wrapper"
                    onClick={() => handleDetailBerita(edukasi[1])}
                  >
                    <img
                      src={edukasi[1].banner_image}
                      alt=""
                      className="image"
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "cover",
                      }}
                    />
                    <div className="content">
                      <Chip color="white" title="Education" />
                      <h2 style={{ color: "white", margin: 0 }}>
                        {edukasi[1].title}
                      </h2>
                      <Publisher
                        publisher={edukasi[1].publisher_name}
                        day={edukasi[1].range_date}
                        color="white"
                        fontSize="14px"
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      height: "50%",
                      paddingTop: "9px",
                      position: "relative",
                    }}
                    className="banner-wrapper"
                    onClick={() => handleDetailBerita(edukasi[2])}
                  >
                    <img
                      src={edukasi[2].banner_image}
                      alt=""
                      className="image"
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "cover",
                      }}
                    />
                    <div className="content">
                      <Chip color="white" title="Education" />
                      <h2 style={{ color: "white", margin: 0 }}>
                        {edukasi[2].title}
                      </h2>
                      <Publisher
                        publisher={edukasi[2].publisher_name}
                        day={edukasi[2].range_date}
                        color="white"
                        fontSize="14px"
                      />
                    </div>
                  </div>
                </Col>
              )}
            </Row>
          </div>

          <hr className="gap" />
          <h1>Latest Post</h1>
          <div style={{ marginTop: "25px" }} className="row-content">
            <Row style={{ marginTop: "100px" }}>
              {edukasi?.map((res, i) => {
                return (
                  <Col
                    key={i}
                    span={12}
                    style={{
                      height: "180px",
                      display: "flex",
                      flexDirection: "row",
                      marginBottom: "54px",
                    }}
                    gap={16}
                  >
                    <div style={{ flex: 2.5 }}>
                      <img
                        src={res.banner_image}
                        alt=""
                        style={{
                          height: "100%",
                          borderRadius: "10px",
                          width: "100%",
                          objectFit: "cover",
                        }}
                      />
                    </div>
                    <div
                      style={{
                        flex: 3,
                        paddingLeft: "24px",
                        marginRight: "20px",
                      }}
                    >
                      <div>
                        <Chip
                          title="Education"
                          type={res.type}
                          color="#9545EB"
                        />
                        <h2 style={{ marginTop: "5px", marginBottom: "0px" }}>
                          {res?.title?.length > 40
                            ? res.title.substring(0, 40) + "..."
                            : res.title}
                        </h2>
                        <Publisher
                          publisher={res.publisher_name}
                          day={res.range_date}
                        />
                        <p
                          style={{
                            fontSize: "16px",
                            color: "#687083",
                            margin: "0px",
                          }}
                          dangerouslySetInnerHTML={{
                            __html:
                              res?.content?.length > 70
                                ? res.content.substring(0, 20) + "..."
                                : res.content,
                          }}
                        />
                        <div
                          onClick={() => handleDetailBerita(res)}
                          style={{
                            color: "#9545EB",
                            cursor: "pointer",
                          }}
                        >
                          Read More <ArrowRightOutlined />{" "}
                        </div>
                      </div>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </div>
        </div>
      </div>
    </Layout>
  );
}
