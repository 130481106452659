import { Chip, makeStyles } from "@material-ui/core";
import { FiberManualRecord } from "@material-ui/icons";

const useStyles = makeStyles(() => ({
  chip: props => ({
    borderStyle: "solid",
    borderWidth: "0.5px",
    borderColor:
      props.type === "success"
        ? "green"
        : props.type === "danger"
        ? "red"
        : props.type === "warning"
        ? "orange"
        : "gray",
    color:
      props.type === "success"
        ? "green"
        : props.type === "danger"
        ? "red"
        : props.type === "warning"
        ? "orange"
        : "gray",
    backgroundColor:
      props.type === "success"
        ? "#f0fdf4"
        : props.type === "danger"
        ? "#fef2f2"
        : props.type === "warning"
        ? "#fff7ed"
        : "#f9fafb",
  }),
  chipIndicator: props => ({
    height: 8,
    width: 8,
    color:
      props.type === "success"
        ? "green"
        : props.type === "danger"
        ? "red"
        : props.type === "warning"
        ? "orange"
        : "gray",
  }),
}));

const StatusChip = ({ label, type }) => {
  const classes = useStyles({ type });

  return label ? (
    <Chip
      icon={<FiberManualRecord className={classes.chipIndicator} />}
      label={label}
      size="small"
      className={classes.chip}
    />
  ) : (
    "-"
  );
};

export default StatusChip;
