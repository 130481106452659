import {
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { makeStyles } from "@material-ui/styles";
import { Page } from "components";

const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  results: {
    marginTop: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  formControl: {},
  mypadding: {
    "& .MuiSelect-outlined": {
      paddingTop: "10.5px",
      paddingBottom: "10.5px",
    },
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  pagination: {
    "& .Mui-selected": {
      backgroundColor: "black",
      color: "white",
    },
  },
  pageSize: {
    height: "30px",
    borderRadius: "6px",
    border: "1px solid #D1D5DC",
    paddingLeft: "5px",
    paddingRight: "5px",
    marginTop: "5px",
  },
  paginationText: {
    fontSize: "14px",
    fontWeight: "normal",
    fontStyle: "normal",
    color: "#687083",
  },
  breadcrumbs: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#111827",
  },
  breadCrumbsActive: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#687083",
  },
  placeholder: {
    "&::placeholder": {
      color: "#9AA2B1",
    },
  },
  title: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "28px",
  },
  label: {
    color: "#364052",
    fontSize: "14px",
    fontWeight: 500,
  },
  subTitle: {
    fontSize: "18px",
    fontWeight: 700,
    color: " #111827",
  },
  myinput: {
    "& .MuiOutlinedInput-input": {
      paddingTop: "11px",
      paddingLeft: "12px",
      paddingRight: "12px",
      paddingBottom: "11px",
    },
  },
  mydateinput: {
    "& .MuiOutlinedInput-input": {
      paddingTop: "11px",
      paddingBottom: "11px",
    },
  },
}));

const AddClaim = () => {
  const classes = useStyles();

  const composerName = "";
  const listComposer = [];

  const handleChangeSelect = () => {};

  return (
    <Page className={classes.root} title="Claim">
      <Container maxWidth={false}>
        <Grid
          alignItems="flex-end"
          container
          justifyContent="space-between"
          spacing={3}
        >
          <Grid item>
            <ThemeProvider theme={theme}>
              <Typography
                style={{
                  fontSize: "24px",
                  fontWeight: "700",
                  lineHeight: "32px",
                }}
              >
                Claim
              </Typography>
            </ThemeProvider>
          </Grid>
          <Grid item>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography className={classes.breadCrumbs}>Home</Typography>

              <Typography className={classes.breadCrumbs}>
                Transaction
              </Typography>

              <Typography className={classes.breadCrumbsActive}>
                Create Transaction
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>

        <Divider className={classes.divider} />

        <Grid container>
          <div>
            <ThemeProvider theme={theme}>
              <p
                style={{
                  marginTop: "5px",
                  color: "#8f8f8f",
                  fontSize: "14px",
                }}
              >
                Feature for creating invoices for song usage claims
              </p>
            </ThemeProvider>
          </div>
        </Grid>

        <Grid container>
          <div
            style={{
              marginTop: "6px",
              marginBottom: "16px",
            }}
          >
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel
                id="demo-simple-select-outlined-label"
                shrink={false}
                style={{
                  marginTop: "-5px",
                }}
              >
                {"Invoice Type"}
              </InputLabel>
              <Select
                style={{
                  width: "340px",
                  height: "44px",
                  border: "0px solid #D1D5DC",
                  borderRadius: "6px",
                }}
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={""}
                onChange={handleChangeSelect}
                autoWidth={true}
              >
                <MenuItem value={""}>Invoice Type</MenuItem>
                <MenuItem value={"claim"}>Claim</MenuItem>
                <MenuItem value={"usage"}>Usage</MenuItem>
              </Select>
            </FormControl>
          </div>
        </Grid>

        <Grid container>
          <Grid item xs={12} md={8} lg={8} xl={8}>
            <Card
              style={{
                border: "1px solid #9AA2B1",
                borderRadius: "6px",
                boxShadow: "none",
              }}
            >
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6} lg={6} xl={6}>
                    <div
                      style={{
                        marginTop: "5px",
                        marginBottom: "5px",
                      }}
                    >
                      <div
                        style={{
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        <ThemeProvider theme={theme}>
                          <Typography className={classes.label}>
                            Client Name
                          </Typography>
                        </ThemeProvider>
                      </div>

                      <div
                        style={{
                          marginTop: "-10px",
                          marginBottom: "10.5px",
                        }}
                      >
                        <FormControl variant="outlined" fullWidth={true}>
                          <InputLabel
                            id="demo-simple-select-outlined-label"
                            shrink={false}
                            style={{
                              marginRight: "-5px",
                              marginTop: "-8px",
                            }}
                          >
                            {composerName !== "" ? null : "Client Name"}
                          </InputLabel>
                          <Select
                            className={classes.mypadding}
                            id="demo-simple-select-outlined"
                            value={composerName}
                            autoWidth={true}
                            style={{
                              paddingTop: "0px",
                              paddingRight: "0px",
                              paddingBottom: "0px",
                              paddingLeft: "0px",
                            }}
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            {listComposer.map(item => (
                              <MenuItem
                                value={item.composer_id}
                                key={item.composer_id}
                              >
                                {item.composer_name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>

                      <div
                        style={{
                          marginTop: "10px",
                          marginBottom: "3px",
                        }}
                      >
                        <ThemeProvider theme={theme}>
                          <Typography
                            variant="subtitle2"
                            className={classes.label}
                          >
                            Transaction Number
                          </Typography>
                        </ThemeProvider>
                      </div>
                      <div>
                        <TextField
                          id="outlined-basic"
                          name="email"
                          variant="outlined"
                          fullWidth={true}
                          margin="small"
                          type="text"
                          placeholder="-"
                          className={classes.myinput}
                        />
                      </div>

                      <div
                        style={{
                          marginTop: "10px",
                          marginBottom: "3px",
                        }}
                      >
                        <ThemeProvider theme={theme}>
                          <Typography
                            variant="subtitle2"
                            className={classes.label}
                          >
                            Invoice Date
                          </Typography>
                        </ThemeProvider>
                      </div>
                      <div>
                        <KeyboardDatePicker
                          id="outlined-basic"
                          variant="outlined"
                          name="startDate"
                          format="YYYY-MM-DD"
                          minDate={new Date()}
                          inputVariant="outlined"
                          fullWidth={true}
                          className={classes.mydateinput}
                        />
                      </div>
                    </div>
                  </Grid>

                  <Grid item xs={12} md={6} lg={6} xl={6}>
                    <div
                      style={{
                        marginTop: "5px",
                        marginBottom: "5px",
                      }}
                    >
                      <div
                        style={{
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        <ThemeProvider theme={theme}>
                          <Typography className={classes.label}>
                            Type
                          </Typography>
                        </ThemeProvider>
                      </div>

                      <div
                        style={{
                          marginTop: "-10px",
                          marginBottom: "10.5px",
                        }}
                      >
                        <FormControl variant="outlined" fullWidth={true}>
                          <InputLabel
                            id="demo-simple-select-outlined-label"
                            shrink={false}
                            style={{
                              marginRight: "-5px",
                              marginTop: "-8px",
                            }}
                          >
                            {composerName !== "" ? null : "Type"}
                          </InputLabel>
                          <Select
                            className={classes.mypadding}
                            id="demo-simple-select-outlined"
                            value={composerName}
                            autoWidth={true}
                            style={{
                              paddingTop: "0px",
                              paddingRight: "0px",
                              paddingBottom: "0px",
                              paddingLeft: "0px",
                            }}
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            {listComposer.map(item => (
                              <MenuItem
                                value={item.composer_id}
                                key={item.composer_id}
                              >
                                {item.composer_name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>

                      <div
                        style={{
                          marginTop: "10px",
                          marginBottom: "3px",
                        }}
                      >
                        <ThemeProvider theme={theme}>
                          <Typography
                            variant="subtitle2"
                            className={classes.label}
                          >
                            Period
                          </Typography>
                        </ThemeProvider>
                      </div>
                      <div>
                        <KeyboardDatePicker
                          id="outlined-basic"
                          variant="outlined"
                          name="startDate"
                          format="YYYY-MM-DD"
                          minDate={new Date()}
                          inputVariant="outlined"
                          fullWidth={true}
                          className={classes.mydateinput}
                        />
                      </div>

                      <div
                        style={{
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        <ThemeProvider theme={theme}>
                          <Typography className={classes.label}>
                            Revenue
                          </Typography>
                        </ThemeProvider>
                      </div>

                      <div
                        style={{
                          marginTop: "-6px",
                        }}
                      >
                        <OutlinedInput
                          id="outlined-adornment-amount"
                          fullWidth={true}
                          name="phone"
                          type="number"
                          placeholder="Value"
                          className={classes.tlpnField}
                          style={{ paddingLeft: "0px" }}
                          margin="dense"
                          inputProps={{
                            style: { paddingLeft: "0px" },
                          }}
                          startAdornment={
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignContent: "center",
                                backgroundColor: "#D1D5DC",
                                height: 35,
                                width: 45,
                                marginRight: "6px",
                              }}
                            >
                              <InputAdornment
                                position="start"
                                style={{
                                  color: "#9AA2B1",
                                  marginLeft: "6px",
                                }}
                              >
                                Rp
                              </InputAdornment>
                            </div>
                          }
                        />
                      </div>
                    </div>
                  </Grid>
                </Grid>
                <Grid>
                  <div>
                    <div
                      style={{
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      <ThemeProvider theme={theme}>
                        <Typography className={classes.label}>Notes</Typography>
                      </ThemeProvider>
                    </div>
                    <div
                      style={{
                        marginTop: "-10px",
                        marginBottom: "10px",
                      }}
                    >
                      <TextField
                        id="outlined-basic"
                        name="email"
                        variant="outlined"
                        fullWidth={true}
                        multiline
                        rows={4}
                        margin="dense"
                        type="text"
                        placeholder="Notes"
                      />
                    </div>
                  </div>
                </Grid>

                <Grid container justifyContent="flex-end">
                  <Button
                    style={{
                      borderRadius: "6px",
                      width: "84px",
                      height: "40px",
                      border: "1px solid #D1D5DC",
                      textTransform: "none",
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    style={{
                      marginLeft: "10px",
                      backgroundColor: "#111827",
                      borderRadius: "6px",
                      width: "84px",
                      height: "40px",
                      color: "#FFFFFF",
                      textTransform: "none",
                    }}
                  >
                    Add
                  </Button>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4} lg={4} xl={4}></Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default AddClaim;
