import Divider from "@material-ui/core/Divider";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/styles";
import BannerPrivacy from "../../../assets/img/banner-privacy.webp";
import Layout from "../../../mpisLandingComponent/Layout";

const interFont = createTheme({
  typography: {
    fontFamily: ["Open Sans"].join(","),
  },
});

const useStyles = makeStyles(theme => ({
  root: {},
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
}));

const PrivacyPolicy = () => {
  const classes = useStyles();
  const scrollToId1 = () => {
    const element = document.getElementById("1");

    element.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "center",
    });
  };

  const scrollToId2 = () => {
    const element = document.getElementById("2");

    element.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "center",
    });
  };
  const scrollToId3 = () => {
    const element = document.getElementById("3");

    element.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "center",
    });
  };

  const scrollToId4 = () => {
    const element = document.getElementById("4");

    element.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "center",
    });
  };

  const scrollToId5 = () => {
    const element = document.getElementById("5");

    element.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "center",
    });
  };

  const scrollToId6 = () => {
    const element = document.getElementById("6");

    element.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "center",
    });
  };

  const scrollToId7 = () => {
    const element = document.getElementById("7");

    element.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "center",
    });
  };

  const scrollToId8 = () => {
    const element = document.getElementById("8");

    element.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "center",
    });
  };

  const scrollToId9 = () => {
    const element = document.getElementById("9");

    element.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "center",
    });
  };
  const scrollToId10 = () => {
    const element = document.getElementById("10");

    element.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "center",
    });
  };

  const scrollToId11 = () => {
    const element = document.getElementById("11");

    element.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "center",
    });
  };

  const scrollToId12 = () => {
    const element = document.getElementById("12");

    element.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "center",
    });
  };

  const scrollToId13 = () => {
    const element = document.getElementById("13");

    element.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "center",
    });
  };
  return (
    <Layout>
      <div>
        <div className="banner-privacy.webp">
          <img src={BannerPrivacy} alt="banner-privacy.webp" />
        </div>
        <div
          style={{
            width: "100%",
            height: "100%",
            marginTop: "50px",
            paddingLeft: "20%",
            paddingRight: "20%",

            fontSize: "20px",
            lineHeight: "30px",
          }}
        >
          <ThemeProvider theme={interFont}>
            <div>
              <p
                style={{
                  fontWeight: "bold",
                }}
              >
                Last updated August 01, 2022
              </p>
            </div>
            <p>
              This privacy notice for PT Asaba Computer Centre (doing business
              as Asaba) (&apos;
              <span
                style={{
                  fontWeight: "bold",
                }}
              >
                Asaba
              </span>
              &apos;, &apos;
              <span
                style={{
                  fontWeight: "bold",
                }}
              >
                we
              </span>
              &apos;, &apos;
              <span
                style={{
                  fontWeight: "bold",
                }}
              >
                us
              </span>
              &apos;, or &apos;
              <span
                style={{
                  fontWeight: "bold",
                }}
              >
                our
              </span>
              &apos;), describes how and why we might collect, store, use,
              and/or share (&apos;process&apos;) your information when you use
              our services (&apos;Services&apos;), such as when you:
            </p>
            <div
              style={{
                paddingLeft: "100px",
              }}
            >
              <ul>
                <li>
                  Visit our website at https://caris.id, or any website of ours
                  that links to this privacy notice
                </li>
                <li>
                  Engage with us in other related ways, including any sales,
                  marketing, or events
                </li>
              </ul>
            </div>
            <p
              style={{
                fontWeight: "bold",
              }}
            >
              Questions or concerns?
            </p>
            <p>
              Reading this privacy notice will help you understand your privacy
              rights and choices. If you do not agree with our policies and
              practices, please do not use our Services. If you still have any
              questions or concerns, please contact us at info@finpoint.id.
            </p>
            <p
              style={{
                fontWeight: "bold",
              }}
            >
              SUMMARY OF KEY POINTS
            </p>
            <p
              style={{
                fontWeight: "bold",
              }}
            >
              This summary provides key points from our privacy notice, but you
              can find out more details about any of these topics by clicking
              the link following each key point or by using our table of
              contents below to find the section you are looking for.
            </p>

            <p
              style={{
                fontWeight: "bold",
              }}
            >
              What personal information do we process?
            </p>

            <p>
              When you visit, use, or navigate our Services, we may process
              personal information depending on how you interact with Finpoint
              and the Services, the choices you make, and the products and
              features you use.
            </p>

            <p
              style={{
                fontWeight: "bold",
              }}
            >
              Do we process any sensitive personal information?
            </p>
            <p>We do not process sensitive personal information.</p>

            <p
              style={{
                fontWeight: "bold",
              }}
            >
              Do we receive any information from third parties?
            </p>

            <p>We do not receive any information from third parties.</p>

            <p
              style={{
                fontWeight: "bold",
              }}
            >
              How do we process your information?
            </p>

            <p>
              We have organisational and technical processes and procedures in
              place to protect your personal information. However, no electronic
              transmission over the internet or information storage technology
              can be guaranteed to be 100% secure, so we cannot promise or
              guarantee that hackers, cybercriminals, or other unauthorised
              third parties will not be able to defeat our security and
              improperly collect, access, steal, or modify your information.
            </p>

            <p
              style={{
                fontWeight: "bold",
              }}
            >
              What are your rights?
            </p>

            <p>
              Depending on where you are located geographically, the applicable
              privacy law may mean you have certain rights regarding your
              personal information.
            </p>

            <p
              style={{
                fontWeight: "bold",
              }}
            >
              How do you exercise your rights?
            </p>

            <p>
              The easiest way to exercise your rights is by filling out our data
              subject request form by contacting us. We will consider and act
              upon any request in accordance with applicable data protection
              laws.
            </p>

            <p>
              Want to learn more about what Finpoint does with any information
              we collect?
            </p>

            <p
              style={{
                fontWeight: "bold",
              }}
            >
              TABLE OF CONTENTS
            </p>
            <div
              style={{
                paddingLeft: "25px",
              }}
            >
              <ol>
                <li>
                  <p
                    style={{
                      color: "red",
                      cursor: "pointer",
                    }}
                    onClick={scrollToId1}
                  >
                    What Information Do We Collect?
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      color: "red",
                      cursor: "pointer",
                    }}
                    onClick={scrollToId2}
                  >
                    How Do We Process Your Information?
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      color: "red",
                      cursor: "pointer",
                    }}
                    onClick={scrollToId3}
                  >
                    When And With Whom Do We Share Your Personal Information?
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      color: "red",
                      cursor: "pointer",
                    }}
                    onClick={scrollToId4}
                  >
                    Do We Use Cookies And Other Tracking Technologies?
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      color: "red",
                      cursor: "pointer",
                    }}
                    onClick={scrollToId5}
                  >
                    How Long Do We Keep Your Information?
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      color: "red",
                      cursor: "pointer",
                    }}
                    onClick={scrollToId6}
                  >
                    How Do We Keep Your Information Safe?
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      color: "red",
                      cursor: "pointer",
                    }}
                    onClick={scrollToId7}
                  >
                    Do We Collect Information From Minors?
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      color: "red",
                      cursor: "pointer",
                    }}
                    onClick={scrollToId8}
                  >
                    What Are Your Privacy Rights?
                  </p>
                </li>

                <li>
                  <p
                    style={{
                      color: "red",
                      cursor: "pointer",
                    }}
                    onClick={scrollToId9}
                  >
                    Controls For Do-Not-Track Features
                  </p>
                </li>

                <li>
                  <p
                    style={{
                      color: "red",
                      cursor: "pointer",
                    }}
                    onClick={scrollToId10}
                  >
                    Do California Residents Have Specific Privacy Rights?
                  </p>
                </li>

                <li>
                  <p
                    style={{
                      color: "red",
                      cursor: "pointer",
                    }}
                    onClick={scrollToId11}
                  >
                    Do We Make Updates To This Notice?
                  </p>
                </li>

                <li>
                  <p
                    style={{
                      color: "red",
                      cursor: "pointer",
                    }}
                    onClick={scrollToId12}
                  >
                    How Can You Contact Us About This Notice?
                  </p>
                </li>

                <li>
                  <p
                    style={{
                      color: "red",
                      cursor: "pointer",
                    }}
                    onClick={scrollToId13}
                  >
                    How Can You Review, Update, Or Delete The Data We Collect
                    From You?
                  </p>
                </li>
              </ol>
            </div>

            <p
              style={{
                fontWeight: "bold",
              }}
              id="1"
            >
              1. What Information Do We Collect?
            </p>
            <div
              style={{
                paddingLeft: "25px",
              }}
            >
              <p>We collect personal information that you provide to us.</p>
              <p
                style={{
                  fontWeight: "bold",
                  color: "red",
                }}
              >
                Read More{" "}
                <span>
                  <ExpandMoreIcon
                    style={{
                      marginBottom: "-5px",
                    }}
                  />
                </span>
              </p>
            </div>

            <Divider className={classes.divider} />

            <p
              style={{
                fontWeight: "bold",
              }}
              id="2"
            >
              2. How Do We Process Your Information?
            </p>
            <div
              style={{
                paddingLeft: "25px",
              }}
            >
              <p>
                We process your information to provide, improve, and administer
                our Services, communicate with you, for security and fraud
                prevention, and to comply with law. We may also process your
                information for other purposes with your consent.
              </p>
              <p
                style={{
                  fontWeight: "bold",
                  color: "red",
                }}
              >
                Read More{" "}
                <span>
                  <ExpandMoreIcon
                    style={{
                      marginBottom: "-5px",
                    }}
                  />
                </span>
              </p>
            </div>

            <Divider className={classes.divider} />

            <p
              style={{
                fontWeight: "bold",
              }}
              id="3"
            >
              3. When And With Whom Do We Share Your Personal Information?
            </p>
            <div
              style={{
                paddingLeft: "25px",
              }}
            >
              <p>
                We may share information in specific situations described in
                this section and/or with the following third parties.
              </p>
              <p
                style={{
                  fontWeight: "bold",
                  color: "red",
                }}
              >
                Read More{" "}
                <span>
                  <ExpandMoreIcon
                    style={{
                      marginBottom: "-5px",
                    }}
                  />
                </span>
              </p>
            </div>

            <Divider className={classes.divider} />

            <p
              style={{
                fontWeight: "bold",
              }}
              id="4"
            >
              4. Do We Use Cookies And Other Tracking Technologies?
            </p>
            <div
              style={{
                paddingLeft: "25px",
              }}
            >
              <p>
                We may use cookies and other tracking technologies to collect
                and store your information.
              </p>
              <p
                style={{
                  fontWeight: "bold",
                  color: "red",
                }}
              >
                Read More{" "}
                <span>
                  <ExpandMoreIcon
                    style={{
                      marginBottom: "-5px",
                    }}
                  />
                </span>
              </p>
            </div>

            <Divider className={classes.divider} />

            <p
              style={{
                fontWeight: "bold",
              }}
              id="5"
            >
              5. How Long Do We Keep Your Information?
            </p>
            <div
              style={{
                paddingLeft: "25px",
              }}
            >
              <p>
                We keep your information for as long as necessary to fulfil the
                purposes outlined in this privacy notice unless otherwise
                required by law.
              </p>
              <p
                style={{
                  fontWeight: "bold",
                  color: "red",
                }}
              >
                Read More{" "}
                <span>
                  <ExpandMoreIcon
                    style={{
                      marginBottom: "-5px",
                    }}
                  />
                </span>
              </p>
            </div>

            <Divider className={classes.divider} />

            <p
              style={{
                fontWeight: "bold",
              }}
              id="6"
            >
              6. How Do We Keep Your Information Safe?
            </p>
            <div
              style={{
                paddingLeft: "25px",
              }}
            >
              <p>
                We aim to protect your personal information through a system of
                organisational and technical security measures.
              </p>
              <p
                style={{
                  fontWeight: "bold",
                  color: "red",
                }}
              >
                Read More{" "}
                <span>
                  <ExpandMoreIcon
                    style={{
                      marginBottom: "-5px",
                    }}
                  />
                </span>
              </p>
            </div>

            <Divider className={classes.divider} />

            <p
              style={{
                fontWeight: "bold",
              }}
              id="7"
            >
              7. Do We Collect Information From Minors?
            </p>
            <div
              style={{
                paddingLeft: "25px",
              }}
            >
              <p>
                We do not knowingly collect data from or market to children
                under 18 years of age.
              </p>
              <p
                style={{
                  fontWeight: "bold",
                  color: "red",
                }}
              >
                Read More{" "}
                <span>
                  <ExpandMoreIcon
                    style={{
                      marginBottom: "-5px",
                    }}
                  />
                </span>
              </p>
            </div>

            <Divider className={classes.divider} />

            <p
              style={{
                fontWeight: "bold",
              }}
              id="8"
            >
              8. What Are Your Privacy Rights?
            </p>
            <div
              style={{
                paddingLeft: "25px",
              }}
            >
              <p>
                You may review, change, or terminate your account at any time.
              </p>
              <p
                style={{
                  fontWeight: "bold",
                  color: "red",
                }}
              >
                Read More{" "}
                <span>
                  <ExpandMoreIcon
                    style={{
                      marginBottom: "-5px",
                    }}
                  />
                </span>
              </p>
            </div>

            <Divider className={classes.divider} />

            <p
              style={{
                fontWeight: "bold",
              }}
              id="9"
            >
              9. Controls For Do-Not-Track Features
            </p>
            <div
              style={{
                paddingLeft: "25px",
              }}
            >
              <p>
                Most web browsers and some mobile operating systems and mobile
                applications include a Do-Not-Track (&apos;DNT&apos;) feature or
                setting you can activate to signal your privacy preference not
                to have data about your online browsing activities monitored and
                collected. At this stage no uniform technology standard for
                recognising and implementing DNT signals has been finalised. As
                such, we do not currently respond to DNT browser signals or any
                other mechanism that automatically communicates your choice not
                to be tracked online. If a standard for online tracking is
                adopted that we must follow in the future, we will inform you
                about that practice in a revised version of this privacy notice.
              </p>
            </div>

            <Divider className={classes.divider} />

            <p
              style={{
                fontWeight: "bold",
              }}
              id="10"
            >
              10. Do California Residents Have Specific Privacy Rights?
            </p>
            <div
              style={{
                paddingLeft: "25px",
              }}
            >
              <p>
                Yes, if you are a resident of California, you are granted
                specific rights regarding access to your personal information.{" "}
              </p>
              <p
                style={{
                  fontWeight: "bold",
                  color: "red",
                }}
              >
                Read More{" "}
                <span>
                  <ExpandMoreIcon
                    style={{
                      marginBottom: "-5px",
                    }}
                  />
                </span>
              </p>
            </div>

            <Divider className={classes.divider} />

            <p
              style={{
                fontWeight: "bold",
              }}
              id="11"
            >
              11. Do We Make Updates To This Notice?
            </p>
            <div
              style={{
                paddingLeft: "25px",
              }}
            >
              <p>
                Yes, we will update this notice as necessary to stay compliant
                with relevant laws.
              </p>
              <p
                style={{
                  fontWeight: "bold",
                  color: "red",
                }}
              >
                Read More{" "}
                <span>
                  <ExpandMoreIcon
                    style={{
                      marginBottom: "-5px",
                    }}
                  />
                </span>
              </p>
            </div>

            <Divider className={classes.divider} />

            <p
              style={{
                fontWeight: "bold",
              }}
              id="12"
            >
              12. How Can You Contact Us About This Notice?
            </p>
            <div
              style={{
                paddingLeft: "25px",
              }}
            >
              <p>
                If you have questions or comments about this notice, you may
                email us at info@finpoint.id or by post to:
              </p>
              <div
                style={{
                  fontWeight: 400,
                }}
              >
                <p>PT Finpoint Solusi Indonesia Ebenezer Building, </p>
                <p
                  style={{
                    marginTop: "-20px",
                  }}
                >
                  Jl. Setia Budi Selatan No.1, RT.5/RW.5, Kuningan, Karet,
                </p>
                <p
                  style={{
                    marginTop: "-20px",
                  }}
                >
                  Kecamatan Setiabudi, Kota Jakarta Selatan
                </p>
                <p
                  style={{
                    marginTop: "-20px",
                  }}
                >
                  Daerah Khusus Ibukota Jakarta 12920{" "}
                </p>
                <p
                  style={{
                    marginTop: "-20px",
                  }}
                >
                  Indonesia
                </p>
              </div>
            </div>

            <Divider className={classes.divider} />

            <p
              style={{
                fontWeight: "bold",
              }}
              id="13"
            >
              13. How Can You Review, Update, Or Delete The Data We Collect From
              You?
            </p>
            <div
              style={{
                paddingLeft: "25px",
              }}
            >
              <p>
                Based on the applicable laws of your country, you may have the
                right to request access to the personal information we collect
                from you, change that information, or delete it. To request to
                review, update, or delete your personal information, please
                contact us.{" "}
              </p>
            </div>

            <Divider className={classes.divider} />
          </ThemeProvider>
        </div>
      </div>
    </Layout>
  );
};

export default PrivacyPolicy;
