import { Container, Divider } from "@material-ui/core";
import { Box, Grid, Typography } from "@mui/material";
import axios from "axios";
import {
  CurrencyDisplay,
  FormLabel,
  ImportDocumentModal,
  ModalError,
  ModalSuccess,
  ModalWarning,
  Page,
  PrimaryButton,
  SecondaryButton,
  SectionLabel,
  SkeletonComponent,
  StatusChip,
  TableCellMultipleRow,
} from "components";
import html2canvas from "html2canvas";
import { InnoTableV2 } from "inno-ui";
import { jsPDF } from "jspdf";
import { HeaderTitle } from "layouts";
import {
  calculateSingleTax,
  calculateTotalRevenue,
  calculateTotalTax,
} from "lib";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { globalStyles } from "styles";
import {
  formatDate,
  getErrors,
  textCapitalization,
  wordCapitalize,
} from "utils";
import { hardBaseUrl } from "../../../../services/urlConstant";

const DetailClaimAndUsage = props => {
  const classes = globalStyles();
  const { id } = useParams();
  const inputRef = useRef(null);
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const [loadingPage, setLoadingPage] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [modalDocument, setModalDocument] = useState(false);
  const [pageDetail, setPageDetail] = useState({});
  const [selectedDsp, setSelectedDsp] = useState({});
  const [selectedBank, setSelectedBank] = useState({});
  const currencyPrefix = `${selectedDsp?.dsp_currency?.currency_symbol_code ||
    "Rp"} `;
  const usingExchangeRate =
    selectedDsp?.dsp_currency?.currency_symbol_code &&
    selectedDsp?.dsp_currency?.currency_symbol_code !== "Rp";
  let totalRevenue = calculateTotalRevenue(pageDetail?.songs);
  let totalTax = calculateTotalTax(pageDetail?.taxes, totalRevenue);
  let totalFee =
    pageDetail?.type === "usage" ? totalRevenue : pageDetail?.revenue;
  let totalRevenueAfterTax = totalFee + totalTax;
  const dataTable =
    pageDetail?.type === "usage"
      ? pageDetail?.songs
      : [
          {
            notes: pageDetail?.note,
            revenue: pageDetail?.revenue,
          },
        ];
  const handlePrintPDF = () => {
    html2canvas(inputRef.current, { useCORS: true }).then(canvas => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save(
        `${pageDetail?.dsp_name}_${pageDetail?.type}_${formatDate(
          pageDetail?.invoice_date
        )}.pdf`
      );
    });
  };
  const handleConfirm = (approval, status) =>
    ModalWarning(
      `Are you sure you want to ${approval} this claim transaction?`,
      `${wordCapitalize(approval)} Claim Transaction`
    ).then(res => res?.isConfirmed && handleChangeStatus(status));
  const handleCloseModalDocument = () => {
    setModalDocument(false);
  };

  const getDetail = async () => {
    try {
      const res = await axios.get(
        `${hardBaseUrl}/publisher/transaction/detail/${id}`,
        { headers }
      );
      const { data } = res?.data;
      setPageDetail(data);
    } catch (error) {
      ModalError(getErrors(error?.response));
    }
  };
  const getListDSP = async dsp_id => {
    try {
      const res = await axios.get(`${hardBaseUrl}/master/all-client`, {
        headers,
      });
      setSelectedDsp(res?.data?.data?.find(item => item?.dsp_id === dsp_id));
    } catch (error) {
      ModalError(getErrors(error?.response));
    }
  };
  const getListBankAccount = async account_number => {
    try {
      const res = await axios.get(`${hardBaseUrl}/publisher/bank`, {
        headers,
      });
      setSelectedBank(
        res?.data?.data?.find(item => item?.account_number === account_number)
      );
    } catch (error) {
      ModalError(getErrors(error?.response));
    }
  };
  const fetchData = async () => {
    setLoadingPage(true);
    const promises = [];
    promises.push(getListDSP(pageDetail?.dsp_id));
    if (pageDetail?.account_number) {
      promises.push(getListBankAccount(pageDetail?.account_number));
    }
    try {
      await Promise.all(promises);
    } catch (error) {
      ModalError(error, "Error fetching data");
    } finally {
      setLoadingPage(false);
    }
  };
  const handleChangeStatus = async status => {
    try {
      setLoadingButton(true);
      await axios.put(
        `${hardBaseUrl}/transaction/proof/${id}`,
        { status: status },
        { headers }
      );
      ModalSuccess("Status has been changed").then(() => getDetail());
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingButton(false);
    }
  };
  const handleUploadDocument = async (documentFiles, payload) => {
    const formData = new FormData();
    documentFiles.forEach(file => {
      formData.append("file_name", file);
    });
    formData.append("status", "paid");
    if (usingExchangeRate && payload) {
      formData.append("exchange_rate", payload);
    }
    try {
      setLoadingButton(true);
      await axios.put(`${hardBaseUrl}/transaction/proof/${id}`, formData, {
        headers,
      });
      ModalSuccess("Status has been changed").then(() => getDetail());
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingButton(false);
      handleCloseModalDocument();
    }
  };

  useEffect(() => {
    getDetail();
  }, []);
  useEffect(() => {
    fetchData();
  }, [pageDetail]);

  return (
    <Page className={classes.root} title="Invoice Detail">
      {loadingPage ? (
        <SkeletonComponent variant="wave" />
      ) : (
        <Container maxWidth={false}>
          <HeaderTitle
            title="Invoice"
            breadcrumbData={breadcrumbData}
            backButton
          />
          <Divider className={classes?.divider} />
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            my="16px"
          >
            <Grid item>
              <SectionLabel
                title="Transaction Detail"
                subTitle="View details of the transaction."
              />
            </Grid>
            <Grid item>
              <StatusChip
                label={statusLabel({
                  status: pageDetail?.status,
                  status_update: pageDetail?.status_update,
                })}
                type={statusType({
                  status: pageDetail?.status,
                  status_update: pageDetail?.status_update,
                })}
              />
            </Grid>
          </Grid>
          <Box
            border="1px solid #9AA2B1"
            borderRadius="6px"
            p="24px"
            ref={inputRef}
          >
            <>
              <Grid container>
                <Grid item xs={12} md={6}>
                  <img
                    src={pageDetail?.publisher?.profile_image}
                    alt="img"
                    height="50px"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography textAlign="right" fontSize={14} fontWeight={600}>
                    {pageDetail?.publisher?.name}
                  </Typography>
                  <Typography textAlign="right" fontSize={14} color="#6F6F84">
                    {pageDetail?.publisher?.address}
                  </Typography>
                </Grid>
              </Grid>
              <Divider className={classes.divider} />
              <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                  <Grid container direction="column">
                    <Grid item>
                      Claim to :
                      <FormLabel label={pageDetail?.dsp_name} />
                    </Grid>
                    <Grid item>
                      Subject :
                      <FormLabel label={textCapitalization(pageDetail?.type)} />
                    </Grid>
                    <Grid item>
                      Invoice Number :
                      <FormLabel
                        label={
                          pageDetail?.invoice_number ||
                          pageDetail.transaction_number ||
                          "-"
                        }
                      />
                    </Grid>
                    {pageDetail?.job_number && (
                      <Grid item>
                        Job Number :
                        <FormLabel label={pageDetail?.job_number || "-"} />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid container direction="column" rowSpacing={1}>
                    <Grid item display="flex" justifyContent="space-between">
                      Date :
                      <FormLabel label={formatDate(pageDetail?.invoice_date)} />
                    </Grid>
                    <Grid item display="flex" justifyContent="space-between">
                      Usage Period :
                      <FormLabel
                        label={
                          pageDetail?.period
                            ? periodTime(pageDetail?.period)
                            : "-"
                        }
                      />
                    </Grid>
                    <Grid item display="flex" justifyContent="space-between">
                      PO Number :
                      <FormLabel label={pageDetail?.po_number || "-"} />
                    </Grid>
                    {pageDetail?.account_number && (
                      <Grid item display="flex" justifyContent="space-between">
                        Account Number :
                        <FormLabel
                          label={`${pageDetail?.account_number} ${selectedBank?.bank?.name}`}
                        />
                      </Grid>
                    )}
                    <Grid item display="flex" justifyContent="space-between">
                      Fee :
                      <FormLabel
                        label={
                          <CurrencyDisplay
                            value={pageDetail?.revenue}
                            prefix={currencyPrefix}
                            decimalScale={2}
                            suffix=",-"
                          />
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Divider className={classes.divider} />
              <InnoTableV2
                isLoading={false}
                columns={columnTable({
                  type: pageDetail?.type,
                  currencyPrefix,
                })}
                items={dataTable}
              />
              <Box
                border="0px solid #9AA2B1"
                borderRadius="6px"
                backgroundColor="#fafafa"
                p="14px"
                my="24px"
              >
                <Grid container direction="column" rowSpacing={1}>
                  <Grid item>
                    <Grid container justifyContent="space-between">
                      <Grid item>Fee</Grid>
                      <Grid item>
                        <Typography>
                          <CurrencyDisplay
                            value={totalRevenue}
                            prefix={currencyPrefix}
                            decimalScale={2}
                            suffix=",-"
                          />
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  {pageDetail?.taxes?.length > 0 &&
                    pageDetail?.taxes?.map((item, index) => (
                      <Grid item key={index}>
                        <Grid
                          container
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Grid item>{item?.code + ` (${item?.rate}%)`}</Grid>
                          <Grid item>
                            <Typography>
                              <CurrencyDisplay
                                value={calculateSingleTax(item, totalRevenue)}
                                prefix={currencyPrefix}
                                decimalScale={2}
                                suffix=",-"
                              />
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}
                </Grid>
                <Divider className={classes.divider} />
                <Grid container justifyContent="space-between">
                  <Grid item>Total Fee</Grid>
                  <Grid item>
                    <Typography
                      textAlign="right"
                      fontSize="22px"
                      fontWeight={600}
                    >
                      <CurrencyDisplay
                        value={
                          pageDetail?.taxes?.length > 0
                            ? totalRevenueAfterTax
                            : totalFee
                        }
                        decimalScale={2}
                        prefix={currencyPrefix}
                        suffix=",-"
                      />
                    </Typography>
                  </Grid>
                </Grid>
                <Typography textAlign="right" fontSize="18px" fontWeight="bold">
                  {selectedDsp?.domicile === "overseas"
                    ? textCapitalization(
                        pageDetail?.transaction_payment_detail
                          ?.total_revenue_in_words?.en
                      )
                    : textCapitalization(
                        pageDetail?.transaction_payment_detail
                          ?.total_revenue_in_words?.id
                      )}
                </Typography>
              </Box>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Typography fontSize="12px" fontWeight={500}>
                    Sign From :
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography fontSize="12px" fontWeight={500}>
                    Sign To :
                  </Typography>
                </Grid>
              </Grid>
              <Grid container justifyContent="space-between" mt="64px">
                <Grid item>
                  <FormLabel label={pageDetail?.publisher?.name} />
                </Grid>
                <Grid item>
                  <FormLabel label={pageDetail?.dsp_name} />
                </Grid>
              </Grid>
            </>
          </Box>
          {(props.rolesFor === "finance" &&
            pageDetail?.status === "waiting_for_approval") ||
            (pageDetail?.status === "unpaid" &&
              pageDetail?.status_update !== "rejected" && (
                <Grid
                  container
                  justifyContent="right"
                  columnSpacing={1}
                  mt="24px"
                >
                  <Grid item>
                    <SecondaryButton
                      label="Reject"
                      onClick={() => handleConfirm("reject", "rejected")}
                      disabled={loadingButton}
                      loading={loadingButton}
                    />
                  </Grid>
                  <Grid item>
                    <PrimaryButton
                      label={
                        pageDetail?.status === "waiting_for_approval"
                          ? "Approve"
                          : pageDetail?.status === "unpaid" &&
                            pageDetail?.status_update !== "rejected"
                          ? "Confirm"
                          : ""
                      }
                      onClick={
                        pageDetail?.status === "waiting_for_approval"
                          ? () => handleConfirm("approve", "unpaid")
                          : pageDetail?.status === "unpaid" &&
                            pageDetail?.status_update !== "rejected"
                          ? () => setModalDocument(true)
                          : null
                      }
                      disabled={loadingButton}
                      loading={loadingButton}
                    />
                  </Grid>
                </Grid>
              ))}
          <Grid container justifyContent="right" mt="24px">
            <PrimaryButton label="Download As PDF" onClick={handlePrintPDF} />
          </Grid>
          {selectedDsp && modalDocument && (
            <ImportDocumentModal
              open={modalDocument}
              onClose={handleCloseModalDocument}
              onSubmit={handleUploadDocument}
              fileType="image/*"
              loadingButton={loadingButton}
              multiple
              exchangeRate={usingExchangeRate}
            />
          )}
        </Container>
      )}
    </Page>
  );
};

const statusType = ({ status, status_update }) =>
  status_update === "rejected" || status === "rejected" || status === "unpaid"
    ? "danger"
    : status === "approved" || status === "paid"
    ? "success"
    : status === "waiting_for_approval"
    ? "warning"
    : "";
const statusLabel = ({ status, status_update }) =>
  status_update === "rejected" || status === "rejected" || status === "unpaid"
    ? "Rejected"
    : status === "waiting_for_approval"
    ? "Waiting for Approval"
    : wordCapitalize(status) || " - ";
const periodTime = time => {
  const [startDate, endDate] = time?.split(" - ");
  const formattedStartDate = moment(startDate.trim(), "D MMMM YYYY").format(
    "DD MMM YYYY"
  );
  const formattedEndDate = moment(endDate.trim(), "D MMMM YYYY").format(
    "DD MMM YYYY"
  );
  return `${formattedStartDate} - ${formattedEndDate}`;
};
const breadcrumbData = [
  {
    label: "Home",
    link: "/admin/dashboard",
  },
  {
    label: "Transaction",
    link: "/admin/invoice",
  },
  {
    label: "Invoice",
    link: "/admin/invoice",
  },
  {
    label: "Transaction Detail",
    active: true,
  },
];
const columnTable = ({ type, currencyPrefix }) => [
  ...(type === "usage"
    ? [
        {
          name: "song_title",
          title: "Song",
          renderText: item => item || "-",
        },
        {
          name: "composers",
          title: "Composer/Author",
          renderText: item => (
            <TableCellMultipleRow
              list={item}
              itemKey="sure_name"
              tooltipKey="sure_name"
            />
          ),
        },
        {
          name: "composers",
          title: "Publisher Percentage",
          renderText: item => (
            <TableCellMultipleRow
              list={item}
              itemKey="publisher_share_percentage"
              tooltipKey="sure_name"
              type="number"
              suffix=" %"
            />
          ),
        },
        {
          name: "type",
          title: "Type",
          renderText: item => item || "-",
        },
      ]
    : []),
  {
    name: "revenue",
    title: "Fee",
    renderText: item => (
      <CurrencyDisplay value={item} decimalScale={2} prefix={currencyPrefix} />
    ),
  },
  ...(type === "usage"
    ? [
        {
          name: "qty",
          title: "Qty",
          renderText: item => item || "-",
        },
        {
          name: "all",
          title: "Total Fee",
          renderText: item => (
            <CurrencyDisplay
              value={item?.revenue * item?.qty}
              decimalScale={2}
              prefix={currencyPrefix}
            />
          ),
        },
      ]
    : []),
  {
    name: "notes",
    title: "Notes",
    renderText: item => item || "-",
    width: 400,
  },
];
export default DetailClaimAndUsage;
