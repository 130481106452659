import { Col, Row } from "antd";
import Axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import EmailSvg from "../../../assets/img/email.svg";
import frame1 from "../../../assets/img/Frame1.png";
import frame2 from "../../../assets/img/Frame2.png";
import frame3 from "../../../assets/img/Frame3.png";
import frame4 from "../../../assets/img/Frame4.png";
import frame5 from "../../../assets/img/Frame5.png";
import frame6 from "../../../assets/img/Frame6.png";
import PhoneSvg from "../../../assets/img/phone.svg";
import UserSvg from "../../../assets/img/user.svg";
import utilities from "../../../assets/img/utilities.png";
import Layout from "../../../mpisLandingComponent/Layout";
import { hardBaseUrl, hardSubDomain } from "../../../services/urlConstant";
import "./TentangKami.css";

export default function TentangKami() {
  const lang = useSelector(state => state.langReducer.language);
  const [dataContent, setDataContent] = useState({
    about_us: "",
    images: [],
    publisher: {},
    our_vision: "",
  });
  const [publisherName, setPublisherName] = useState("");
  const [publisherEmail, setPublisherEmail] = useState("");
  const [publisherPhone, setPublisherPhone] = useState("");

  const getData = async () => {
    try {
      const response = await Axios.get(
        hardBaseUrl +
        `/landing/publisher/web-content?subdomain${hardSubDomain !== "mpis" &&
        hardSubDomain}`
      );
      if (response.data.message === "success") {
        setDataContent({
          about_us: response.data.data.about_us,
          images: response.data.data.images || [],
          publisher: response.data.data.publisher,
          our_vision: response.data.data.our_vision,
        });
        setPublisherName(response.data.data.publisher?.name);
        setPublisherEmail(response.data.data.publisher?.email);
        setPublisherPhone(response.data.data.publisher?.phone);
      }
    } catch (error) {
      new Error(error);
    }
  };

  useEffect(() => {
    getData();
  }, [getData]);

  const getTitle = () => {
    if (localStorage.getItem("subDomain") === null) {
      return "MPIS";
    } else if (localStorage.getItem("subDomain") === "mpis") {
      return "MPIS";
    } else if (localStorage.getItem("subDomain") === "nadaku") {
      return "Nadaku";
    } else if (localStorage.getItem("subDomain") === "musica") {
      return "Musica";
    } else {
      return (
        localStorage.getItem("subDomain").slice(1)
      );
    }
  };

  return (
    <Layout>
      <div className="tentang-kami">
        <div className="banner">
          <div
            className="container"
            style={{
              display: "inline-block",
            }}
          >
            <div>
              <h1 style={{ fontSize: "48px", fontWeight: 700 }}>
                {localStorage.getItem("subDomain") === null
                  ? lang === "IND"
                    ? "About MPIS"
                    : "About MPIS"
                  : lang === "IND"
                    ? "About " + getTitle()
                    : "About " + getTitle()}
              </h1>
              <p
                style={{ width: "600px", fontSize: "18px", color: "#687083" }}
                dangerouslySetInnerHTML={{
                  __html: dataContent.about_us,
                }}
              />
            </div>
          </div>
        </div>
        <div className="gallery">
          <Row style={{ height: "100%" }}>
            <Col span={7} style={{ height: "100%", paddingRight: "18px" }}>
              <img
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#E4E7EB",
                  objectFit: "cover",
                }}
                src={
                  dataContent.images?.length > 0 &&
                    dataContent.images.filter(v => v.type === "publisher")[0] !==
                    undefined
                    ? dataContent.images.filter(v => v.type === "publisher")[0]
                      .image_name
                    : frame1
                }
                alt=""
              />
            </Col>
            <Col span={10}>
              <div style={{ height: "100%", width: "100%" }}>
                <div style={{ height: "360px", paddingBottom: "8px" }}>
                  <img
                    style={{
                      width: "100%",
                      height: "100%",
                      backgroundColor: "#E4E7EB",
                      objectFit: "cover",
                    }}
                    src={
                      dataContent.images?.length > 0 &&
                        dataContent.images.filter(
                          v => v.type === "publisher"
                        )[1] !== undefined
                        ? dataContent.images.filter(
                          v => v.type === "publisher"
                        )[1].image_name
                        : frame2
                    }
                    alt=""
                  />
                </div>
                <div
                  style={{
                    height: "190px",
                    paddingTop: "8px",
                    display: "flex",
                  }}
                >
                  <img
                    style={{
                      width: "100%",
                      height: "100%",
                      backgroundColor: "#E4E7EB",
                      objectFit: "cover",
                      marginRight: "8px",
                    }}
                    src={
                      dataContent.images?.length > 0 &&
                        dataContent.images.filter(
                          v => v.type === "publisher"
                        )[2] !== undefined
                        ? dataContent.images.filter(
                          v => v.type === "publisher"
                        )[2].image_name
                        : frame3
                    }
                    alt=""
                  />
                  <img
                    style={{
                      width: "100%",
                      height: "100%",
                      backgroundColor: "#E4E7EB",
                      objectFit: "cover",
                      marginLeft: "8px",
                    }}
                    src={
                      dataContent.images?.length > 0 &&
                        dataContent.images.filter(
                          v => v.type === "publisher"
                        )[3] !== undefined
                        ? dataContent.images.filter(
                          v => v.type === "publisher"
                        )[3].image_name
                        : frame4
                    }
                    alt=""
                  />
                </div>
              </div>
            </Col>
            <Col span={7} style={{ paddingLeft: "18px" }}>
              <img
                style={{
                  width: "100%",
                  height: "190px",
                  objectFit: "cover",
                  paddingBottom: "8px",
                }}
                src={
                  dataContent.images?.length > 0 &&
                    dataContent.images.filter(v => v.type === "publisher")[4] !==
                    undefined
                    ? dataContent.images.filter(v => v.type === "publisher")[4]
                      .image_name
                    : frame5
                }
                alt=""
              />
              <img
                style={{
                  width: "100%",
                  height: "360px",
                  objectFit: "cover",
                  paddingTop: "8px",
                }}
                src={
                  dataContent.images?.length > 0 &&
                    dataContent.images.filter(v => v.type === "publisher")[5] !==
                    undefined
                    ? dataContent.images.filter(v => v.type === "publisher")[5]
                      .image_name
                    : frame6
                }
                alt=""
              />
            </Col>
          </Row>
        </div>
        <div
          className="simfoni container about-section"
          style={{
            display: "none",
          }}
        >
          <div className="image-wrapper">
            <img
              src={utilities}
              alt=""
              style={{
                height: "100%",
                width: "100%",
                objectFit: "contain",
              }}
            />
            <img
              src={
                dataContent.images.filter(v => v.type === "about_us")?.length >
                  0 &&
                  dataContent.images.filter(v => v.type === "about_us")[0] !==
                  undefined
                  ? dataContent.images.filter(v => v.type === "about_us")[0]
                    .image_name
                  : ""
              }
              alt=""
              className="image-utilities1"
            />
            <img
              src={
                dataContent.images.filter(v => v.type === "about_us")?.length >
                  0 &&
                  dataContent.images.filter(v => v.type === "about_us")[1] !==
                  undefined
                  ? dataContent.images.filter(v => v.type === "about_us")[1]
                    .image_name
                  : ""
              }
              alt=""
              className="image-utilities2"
            />
          </div>
          <div
            style={{
              flex: 1,
              alignItems: "center",
              display: "flex",
            }}
          >
            <div className="content">
              <h1>
                {localStorage.getItem("subDomain") === null
                  ? lang === "IND"
                    ? "About MPIS"
                    : "About MPIS"
                  : lang === "IND"
                    ? `About ${getTitle()}`
                    : `About ${getTitle()}`}
              </h1>
              <p
                dangerouslySetInnerHTML={{
                  __html: dataContent.about_us,
                }}
              ></p>
            </div>
          </div>
        </div>
        <div
          className="simfoni container ourvision-section"
          style={{
            padding: 0,
          }}
        >
          <div
            style={{
              flex: 1,
              alignItems: "center",
              display: "flex",
            }}
          >
            <div className="content">
              <h1>
                {localStorage.getItem("subDomain") === null
                  ? lang === "IND"
                    ? "Our Vision"
                    : "Our Vision"
                  : lang === "IND"
                    ? `${getTitle()} Vision`
                    : "Our Vision"}
              </h1>
              <p
                dangerouslySetInnerHTML={{
                  __html: dataContent.our_vision,
                }}
              ></p>
              <h1>Contact Us</h1>
              <div
                style={{
                  display: "flex",
                  marginTop: "-30px",
                }}
              >
                <img src={UserSvg} alt="user" />
                <p
                  style={{
                    marginLeft: "30px",
                    marginTop: "25px",
                    color: "#000000",
                    fontSize: "20px",
                    fontWeight: "500",
                  }}
                >
                  {publisherName || "-"}
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  marginTop: "-30px",
                }}
              >
                <img src={EmailSvg} alt="email" />
                <p
                  style={{
                    marginLeft: "28px",
                    marginTop: "25px",
                    color: "#000000",
                    fontSize: "20px",
                    fontWeight: "500",
                  }}
                >
                  {publisherEmail || "-"}
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  marginTop: "-30px",
                }}
              >
                <img src={PhoneSvg} alt="phone" />
                <p
                  style={{
                    marginLeft: "30px",
                    marginTop: "25px",
                    color: "#000000",
                    fontSize: "20px",
                    fontWeight: "500",
                  }}
                >
                  {publisherPhone || "-"}
                </p>
              </div>
            </div>
          </div>

          <div>
            <div
              className="image-wrapper"
              style={{
                display: "none",
              }}
            >
              <img
                src={utilities}
                alt=""
                style={{
                  height: "100%",
                  width: "100%",
                  objectFit: "contain",
                }}
              />
              <img
                src={
                  dataContent.images.filter(v => v.type === "our_vision")
                    ?.length > 0 &&
                    dataContent.images.filter(v => v.type === "our_vision")[0] !==
                    undefined
                    ? dataContent.images.filter(v => v.type === "our_vision")[0]
                      .image_name
                    : ""
                }
                alt=""
                className="image-utilities3"
              />
              <img
                src={
                  dataContent.images.filter(v => v.type === "our_vision")
                    ?.length > 0 &&
                    dataContent.images.filter(v => v.type === "our_vision")[1] !==
                    undefined
                    ? dataContent.images.filter(v => v.type === "our_vision")[1]
                      .image_name
                    : ""
                }
                alt=""
                className="image-utilities4"
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
