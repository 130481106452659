import {
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import "@material-ui/pickers";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { useHistory } from "react-router";
import moment from "moment";
import Swal from "sweetalert2";
import { Page } from "components";
import { hardBaseUrl } from "../../../../services/urlConstant";
const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});
const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  inner: {
    minWidth: 700,
    border: "1px solid #D1D5DC",
    borderRadius: "6px",
    boxShadow: "none",
  },
  results: {
    marginTop: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  formControl: {},
  mypadding: {
    "& .MuiSelect-outlined": {
      paddingTop: "10.5px",
      paddingBottom: "10.5px",
    },
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  pagination: {
    "& .Mui-selected": {
      backgroundColor: "black",
      color: "white",
    },
  },
  pageSize: {
    height: "30px",
    borderRadius: "6px",
    border: "1px solid #D1D5DC",
    paddingLeft: "5px",
    paddingRight: "5px",
    marginTop: "5px",
  },
  paginationText: {
    fontSize: "14px",
    fontWeight: "normal",
    fontStyle: "normal",
    color: "#687083",
  },
  breadcrumbs: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#111827",
  },
  breadCrumbsActive: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#687083",
  },
  placeholder: {
    "&::placeholder": {
      color: "#9AA2B1",
    },
  },
  title: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "28px",
  },
  label: {
    color: "#364052",
    fontSize: "14px",
    fontWeight: 500,
  },
  subTitle: {
    fontSize: "18px",
    fontWeight: 700,
    color: " #111827",
  },
  mydateinput: {
    "& .MuiOutlinedInput-input": {
      paddingTop: "11px",
      paddingBottom: "11px",
    },
  },
  myinput: {
    "& .MuiOutlinedInput-input": {
      paddingTop: "11px",
      paddingLeft: "12px",
      paddingRight: "12px",
      paddingBottom: "11px",
    },
  },
}));

const PenciptaAddPayment = () => {
  const classes = useStyles();
  const history = useHistory();
  const [type, setType] = useState("type");
  const [notes, setNotes] = useState("");
  const [balanceNumber, setBalanceNumber] = useState(0);
  const [publisherName, setPublisherName] = useState("");
  const [paymentValue, setPaymentValue] = useState(0);
  const [remainingBalance, setRemainingBalance] = useState(0);
  const [errorPaymentValue, setErrorPaymentValue] = useState("");
  const [paymentDate, setPaymentDate] = useState(moment().toString());
  const onChangeNotes = async e => {
    setNotes(e.target.value);
  };

  useEffect(() => {
    const getPublisherName = async () => {
      try {
        let theToken = localStorage.getItem("token");
        let url = `${hardBaseUrl}/me`;
        let res = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${theToken}`,
          },
        });
        setPublisherName(res.data.data.publisher.name);
      } catch (error) {
        new Error(error);
      }
    };
    const getBalance = async () => {
      try {
        let theToken = localStorage.getItem("token");
        let baseOfUrl = hardBaseUrl;
        const url = baseOfUrl + "/composer/transaction/payment/get-balance";
        const options = {
          headers: {
            Authorization: "Bearer " + theToken,
          },
        };
        const response = await axios.get(url, options);

        setBalanceNumber(response.data.data.balance_number);
        setRemainingBalance(response.data.data.balance_number);
      } catch (error) {
        new Error(error);
      }
    };
    getPublisherName();
    getBalance();
  }, []);

  const formatDate = date => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;

    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const handleButtonSimpan = async () => {
    const payload = {
      date: formatDate(paymentDate),
      value: Number(paymentValue),
      note: notes,
    };
    try {
      let theToken = localStorage.getItem("token");
      let baseOfUrl = hardBaseUrl;

      const url = baseOfUrl + "/composer/transaction/payment";
      const options = {
        headers: {
          Authorization: "Bearer " + theToken,
        },
      };
      const response = await axios.post(url, payload, options);
      if (
        response.data.message === "success" &&
        response.data.meta.http_status === 200
      ) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Add Data Success.",
        }).then(result => {
          if (result.isConfirmed === true) {
            history.push("/pencipta/payment");
          }
        });
      }
    } catch (error) {
      new Error(error);
    }
  };

  const handleChangeSelect = async event => {
    setType(event.target.value);
  };
  const onPickDate = date => {
    setPaymentDate(date);
  };

  const changeNumberValue = value => {
    setPaymentValue(value);
    if (value > remainingBalance) {
      setErrorPaymentValue("Payment value is greater than remaining balance");
      setPaymentValue(0);
      setRemainingBalance(balanceNumber);
    } else {
      setErrorPaymentValue("");
    }

    if (value === "" || 0 || null || undefined) {
      setErrorPaymentValue("");
      setRemainingBalance(balanceNumber);
    }
  };
  useEffect(() => {
    if (paymentValue === "" || 0 || null || undefined) {
      setRemainingBalance(balanceNumber);
    } else {
      setRemainingBalance(balanceNumber - paymentValue);
    }
  }, [remainingBalance, paymentValue]);
  const changeNumber = async () => {};

  const TextFieldDisabled = withStyles({
    root: {
      "& .MuiInputBase-root.Mui-disabled": {
        color: "#263238",
      },
      "& .MuiFormLabel-root.Mui-disabled": {
        color: "#263238",
      },
    },
  })(TextField);
  return (
    <Page className={classes.root} title="Payment">
      <Container maxWidth={false}>
        <Grid
          alignItems="flex-end"
          container
          justifyContent="space-between"
          spacing={3}
        >
          <Grid item>
            <ThemeProvider theme={theme}>
              <Typography
                style={{
                  fontSize: "24px",
                  fontWeight: "700",
                  lineHeight: "32px",
                }}
              >
                Payment Transaction
              </Typography>
            </ThemeProvider>
          </Grid>
          <Grid item>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography className={classes.breadCrumbs}>Home</Typography>

              <Typography className={classes.breadCrumbs}>
                Transaction
              </Typography>

              <Typography className={classes.breadCrumbsActive}>
                Create Transaction
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>

        <Divider className={classes.divider} />

        <Grid container>
          <div>
            <ThemeProvider theme={theme}>
              <Typography
                component="h1"
                variant="h3"
                className={classes.subTitle}
              >
                Create Transaction
              </Typography>
            </ThemeProvider>
            <ThemeProvider theme={theme}>
              <p
                style={{
                  marginTop: "5px",
                  color: "#8f8f8f",
                  fontSize: "14px",
                }}
              >
                Feature for making payment transactions to composer
              </p>
            </ThemeProvider>
          </div>
        </Grid>

        <div
          style={{
            marginTop: "16px",
            marginBottom: "16px",
            border: "1px solid #D1D5DC",
            borderRadius: "10px",
            padding: "16px",
            width: "340px",
            backgroundColor: "#111827",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <div>
              <img
                alt="Logo"
                src={
                  require("assets/image-public/images/icon_images/ri_money-dollar-circle-fill.svg")
                    .default
                }
              />
            </div>
            <div
              style={{
                marginLeft: "18px",
              }}
            >
              <div>
                <span
                  style={{
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "14px",
                    lineHeight: "20px",
                    color: "#9AA2B1",
                  }}
                >
                  Balance
                </span>
              </div>
              <div
                style={{
                  marginTop: "8px",
                }}
              >
                <span
                  style={{
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: "700",
                    fontSize: "24px",
                    lineHeight: "32px",
                    color: "white",
                  }}
                >
                  <NumberFormat
                    displayType="text"
                    value={balanceNumber}
                    thousandSeparator={true}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    prefix="Rp "
                  />
                </span>
              </div>
            </div>
          </div>
        </div>

        <Grid container>
          <div
            style={{
              display: "none",
            }}
          >
            <span
              style={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "14px",
                lineHeight: "20px",
                color: "#364052",
              }}
            >
              Type
            </span>
          </div>
        </Grid>

        <Grid container>
          <div
            style={{
              display: "none",
              marginTop: "6px",
              marginBottom: "16px",
            }}
          >
            <FormControl variant="outlined" className={classes.formControl}>
              <Select
                style={{
                  width: "340px",
                  height: "44px",
                  border: "0px solid #D1D5DC",
                  borderRadius: "6px",
                }}
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={type}
                onChange={handleChangeSelect}
                autoWidth={true}
              >
                <MenuItem value={"type"}>Type</MenuItem>
                <MenuItem value={"claim"}>Claim</MenuItem>
                <MenuItem value={"usage"}>Usage</MenuItem>
              </Select>
            </FormControl>
          </div>
        </Grid>

        <Grid container>
          <Grid item xs={12} md={8} lg={8} xl={8}>
            <Card
              style={{
                border: "1px solid #9AA2B1",
                borderRadius: "6px",
                boxShadow: "none",
              }}
            >
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6} lg={6} xl={6}>
                    <div
                      style={{
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      <ThemeProvider theme={theme}>
                        <Typography className={classes.label}>Date</Typography>
                      </ThemeProvider>
                    </div>
                    <div>
                      <KeyboardDatePicker
                        id="outlined-basic"
                        variant="outlined"
                        name="date"
                        format="DD-MM-YYYY"
                        minDate={new Date()}
                        inputVariant="outlined"
                        fullWidth={true}
                        className={classes.mydateinput}
                        onChange={onPickDate}
                        value={paymentDate}
                      />
                    </div>

                    <div
                      style={{
                        marginTop: "10px",
                        marginBottom: "5px",
                      }}
                    >
                      <ThemeProvider theme={theme}>
                        <Typography className={classes.label}>Value</Typography>
                      </ThemeProvider>
                    </div>
                    <div
                      style={{
                        marginBottom: "10px",
                      }}
                    >
                      {}
                      <NumberFormat
                        id={"remainingBallance"}
                        name={"remainingBallance"}
                        customInput={TextField}
                        variant="outlined"
                        allowNegative={false}
                        thousandSeparator={true}
                        prefix="Rp "
                        onValueChange={values => {
                          const { floatValue } = values;
                          if (floatValue) changeNumberValue(floatValue);
                        }}
                        autoComplete="off"
                        style={{
                          paddingLeft: "0px",
                          height: "44px",
                        }}
                        value={paymentValue}
                        size="small"
                        fullWidth
                      />
                      {}
                      <small style={{ color: "red" }}>
                        {errorPaymentValue}
                      </small>
                    </div>
                  </Grid>

                  <Grid item xs={12} md={6} lg={6} xl={6}>
                    <div
                      style={{
                        marginTop: "5px",
                        marginBottom: "5px",
                      }}
                    >
                      <div
                        style={{
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        <ThemeProvider theme={theme}>
                          <Typography className={classes.label}>
                            Publisher
                          </Typography>
                        </ThemeProvider>
                      </div>
                      <div>
                        <TextField
                          disabled={true}
                          id="outlined-basic"
                          name="email"
                          variant="outlined"
                          fullWidth={true}
                          margin="small"
                          type="text"
                          placeholder="Publisher"
                          className={classes.myinput}
                          onChange={e => changeNumber(e)}
                          value={publisherName}
                        />
                      </div>

                      <div
                        style={{
                          marginTop: "10px",
                          marginBottom: "3px",
                        }}
                      >
                        <ThemeProvider theme={theme}>
                          <Typography
                            variant="subtitle2"
                            className={classes.label}
                          >
                            Remaining Balance
                          </Typography>
                        </ThemeProvider>
                      </div>
                      <div
                        style={{
                          marginBottom: "10px",
                        }}
                      >
                        {}

                        <NumberFormat
                          id={"remainingBallance"}
                          name={"remainingBallance"}
                          customInput={TextFieldDisabled}
                          variant="outlined"
                          allowNegative="false"
                          thousandSeparator={true}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          prefix="Rp "
                          autoComplete="off"
                          InputProps={{
                            style: {
                              "& .MuiInputBase-root.Mui-disabled": {
                                WebkitTextFillColor: "#000000",
                              },
                              "& .MuiInputBase-input.Mui-disabled": {
                                WebkitTextFillColor: "#000000",
                              },
                            },
                          }}
                          style={{
                            paddingLeft: "0px",
                            height: "44px",
                          }}
                          value={remainingBalance}
                          size="small"
                          fullWidth
                          disabled
                          defaultValue={remainingBalance}
                        />
                      </div>
                    </div>
                  </Grid>
                </Grid>
                <Grid>
                  <div>
                    <div
                      style={{
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      <ThemeProvider theme={theme}>
                        <Typography className={classes.label}>Notes</Typography>
                      </ThemeProvider>
                    </div>
                    <div
                      style={{
                        marginTop: "-10px",
                        marginBottom: "10px",
                      }}
                    >
                      <TextField
                        id="outlined-basic"
                        name="email"
                        variant="outlined"
                        fullWidth={true}
                        multiline
                        rows={4}
                        margin="dense"
                        type="text"
                        onChange={event => onChangeNotes(event)}
                        placeholder="Notes"
                      />
                    </div>
                  </div>
                </Grid>

                <Grid container justifyContent="flex-end">
                  <div
                    style={{
                      marginTop: "32px",
                    }}
                  >
                    <Button
                      style={{
                        borderRadius: "6px",
                        width: "84px",
                        height: "40px",
                        border: "1px solid #D1D5DC",
                        textTransform: "none",
                      }}
                      href="/pencipta/payment"
                    >
                      Cancel
                    </Button>
                    <Button
                      style={{
                        marginLeft: "10px",
                        backgroundColor: "#111827",
                        borderRadius: "6px",
                        width: "84px",
                        height: "40px",
                        color: "#FFFFFF",
                        textTransform: "none",
                      }}
                      onClick={handleButtonSimpan}
                    >
                      Add
                    </Button>
                  </div>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4} lg={4} xl={4}></Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default PenciptaAddPayment;
