import {
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  Grid,
  Typography,
} from "@material-ui/core";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { useRef } from "react";
import { useLocation } from "react-router-dom";
import { Page } from "components";

const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  results: {
    marginTop: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  formControl: {},
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  pagination: {
    "& .Mui-selected": {
      backgroundColor: "black",
      color: "white",
    },
  },
  pageSize: {
    height: "30px",
    borderRadius: "6px",
    border: "1px solid #D1D5DC",
    paddingLeft: "5px",
    paddingRight: "5px",
    marginTop: "5px",
  },
  paginationText: {
    fontSize: "14px",
    fontWeight: "normal",
    fontStyle: "normal",
    color: "#687083",
  },
  breadcrumbs: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#111827",
  },
  breadCrumbsActive: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#687083",
  },
  placeholder: {
    "&::placeholder": {
      color: "#9AA2B1",
    },
  },
  subTitle: {
    fontSize: "18px",
    fontWeight: 700,
    color: " #111827",
  },
  headText: {
    fontSize: "12px",
    color: "#687083",
    fontWeight: "bold",
  },
}));

const PrintPdf = () => {
  const classes = useStyles();
  const { state } = useLocation();
  const inputRef = useRef(null);

  const handlePrintPDF = () => {
    html2canvas(inputRef.current, { useCORS: true }).then(canvas => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF({
        orientation: "landscape",
      });
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save("download.pdf");
    });
  };

  return (
    <Page className={classes.root} title="Claim Detail">
      <Container maxWidth={false}>
        <Grid
          alignItems="flex-end"
          container
          justifyContent="space-between"
          spacing={3}
        >
          <Grid item>
            <ThemeProvider theme={theme}>
              <Typography
                style={{
                  fontSize: "24px",
                  fontWeight: "700",
                  lineHeight: "32px",
                }}
              >
                Payment Transaction
              </Typography>
            </ThemeProvider>
          </Grid>
          <Grid item>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography className={classes.breadCrumbs}>Home</Typography>

              <Typography className={classes.breadCrumbs}>
                Transaction
              </Typography>

              <Typography className={classes.breadCrumbsActive}>
                Detail Transaction
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>

        <Divider className={classes.divider} />

        <Grid container>
          <Grid item xs={12} md={8} lg={8} xl={8}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={6} xl={6}>
                <div>
                  <ThemeProvider theme={theme}>
                    <Typography
                      component="h1"
                      variant="h3"
                      className={classes.subTitle}
                    >
                      Print Transaction Notes
                    </Typography>
                  </ThemeProvider>
                  <ThemeProvider theme={theme}>
                    <p
                      style={{
                        marginTop: "5px",
                        color: "#8f8f8f",
                        fontSize: "14px",
                      }}
                    >
                      This is the preview of the transaction detail before it
                      was printed in pdf form
                    </p>
                  </ThemeProvider>
                </div>
              </Grid>
              <Grid item xs={12} md={6} lg={6} xl={6}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    style={{
                      backgroundColor: "#111827",
                      color: "white",
                      padding: "10px 28px 10px 28px",
                      borderRadius: "6px",
                    }}
                    variant="contained"
                    size="large"
                    onClick={handlePrintPDF}
                  >
                    <ThemeProvider theme={theme}>
                      <Typography
                        component="span"
                        style={{
                          fontSize: "14px",
                          lineHeight: "20px",
                          fontWeight: "500",
                          fontStyle: "normal",
                          textTransform: "none",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Download As PDF
                      </Typography>
                    </ThemeProvider>
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4} lg={4} xl={4}></Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12} md={8} lg={8} xl={8}>
            <Card
              style={{
                border: "1px solid #9AA2B1",
                borderRadius: "6px",
                boxShadow: "none",
              }}
              ref={inputRef}
            >
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6} lg={6} xl={6}>
                    {state.img === "" ? (
                      <div></div>
                    ) : (
                      <img
                        src={state.img}
                        alt="img"
                        style={{
                          width: "155.52px",
                          height: "80px",
                        }}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} md={6} lg={6} xl={6}>
                    <div
                      style={{
                        textAlign: "right",
                      }}
                    >
                      <div>
                        <ThemeProvider theme={theme}>
                          <Typography
                            style={{
                              fontSize: "14px",
                              fontWeight: "600",
                              lineHeight: "20px",
                            }}
                          >
                            {state.composerName}
                          </Typography>
                          <Typography
                            style={{
                              fontSize: "14px",
                              fontWeight: "400",
                              lineHeight: "17px",
                              color: "#6F6F84",
                              wordWrap: "break-word",
                            }}
                          >
                            {state.address}
                          </Typography>
                        </ThemeProvider>
                      </div>
                    </div>
                  </Grid>
                </Grid>

                <Divider className={classes.divider} />

                <Grid container spacing={3}>
                  <Grid item xs={12} md={6} lg={6} xl={6}>
                    <ThemeProvider theme={theme}>
                      <Typography
                        style={{
                          fontSize: "14px",
                          fontWeight: "400",
                          lineHeight: "16.94px",
                        }}
                      >
                        Request Payment to:
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "14px",
                          fontWeight: "600",
                          lineHeight: "16.94px",
                        }}
                      >
                        {state.publisher}
                      </Typography>
                    </ThemeProvider>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6} xl={6}>
                    <ThemeProvider theme={theme}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          style={{
                            fontSize: "14px",
                            fontWeight: "400",
                            lineHeight: "16.94px",
                          }}
                        >
                          Date:
                        </Typography>

                        <Typography
                          style={{
                            fontSize: "14px",
                            fontWeight: "400",
                            lineHeight: "16.94px",
                          }}
                        >
                          {state.date}
                        </Typography>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: "5px",
                        }}
                      >
                        <Typography
                          style={{
                            fontSize: "14px",
                            fontWeight: "400",
                            lineHeight: "16.94px",
                          }}
                        >
                          Composer:
                        </Typography>

                        <Typography
                          style={{
                            fontSize: "14px",
                            fontWeight: "400",
                            lineHeight: "16.94px",
                          }}
                        >
                          {state.composerName}
                        </Typography>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: "5px",
                        }}
                      >
                        <Typography
                          style={{
                            fontSize: "14px",
                            fontWeight: "400",
                            lineHeight: "16.94px",
                          }}
                        >
                          Value:
                        </Typography>

                        <Typography
                          style={{
                            fontSize: "14px",
                            fontWeight: "400",
                            lineHeight: "16.94px",
                          }}
                        >
                          {state.value}
                        </Typography>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: "5px",
                        }}
                      >
                        <Typography
                          style={{
                            fontSize: "14px",
                            fontWeight: "400",
                            lineHeight: "16.94px",
                          }}
                        >
                          Remaining Balance:
                        </Typography>

                        <Typography
                          style={{
                            fontSize: "14px",
                            fontWeight: "400",
                            lineHeight: "16.94px",
                          }}
                        >
                          {state.remainingBalance || "-"}
                        </Typography>
                      </div>
                    </ThemeProvider>
                  </Grid>
                </Grid>
                <Divider className={classes.divider} />

                <Grid container>
                  <div>
                    <ThemeProvider theme={theme}>
                      <Typography
                        style={{
                          fontSize: "14px",
                          fontWeight: "600",
                        }}
                      >
                        Notes:
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        {state.notes}
                      </Typography>
                    </ThemeProvider>
                  </div>
                </Grid>

                <div
                  style={{
                    marginTop: "20px",
                  }}
                >
                  <ThemeProvider theme={theme}>
                    <Typography
                      style={{
                        fontSize: "14px",
                        fontWeight: "400",
                        textAlign: "right",
                      }}
                    >
                      Value:
                    </Typography>
                    <Typography
                      style={{
                        fontSize: "24px",
                        fontWeight: "600",
                        textAlign: "right",
                      }}
                    >
                      {state.value}
                    </Typography>
                  </ThemeProvider>
                </div>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4} lg={4} xl={4}></Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default PrintPdf;
