import { Container, Divider } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { Box, Grid, Typography } from "@mui/material";
import axios from "axios";
import {
  AutoCompleteComponent,
  CheckboxInput,
  CurrencyDisplay,
  CurrencyNumberInput,
  DatePicker,
  DateRangePicker,
  FormLabel,
  ModalError,
  MonthYearRangePicker,
  Page,
  PrimaryButton,
  SectionLabel,
  SelectInput,
  SkeletonComponent,
  SwitchInput,
  TableAction,
  TableCellMultipleRow,
  TextInput,
} from "components";
import { InnoTableV2 } from "inno-ui";
import { HeaderTitle } from "layouts";
import {
  calculateSingleTax,
  calculateTotalRevenue,
  calculateTotalTax,
} from "lib";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import { globalStyles } from "styles";
import { getErrors, getNestedValue } from "utils";
import { hardBaseUrl } from "../../../../services/urlConstant";
import {
  ModalAddSong,
  ModalBulkAdd,
  ModalSongResults,
  ModalUploadResults,
} from "./ModalInvoice";

function FormInvoice({
  pageTitle,
  pageSubTitle,
  loadingPage,
  preload,
  onSubmitPayload,
  editMode,
}) {
  const classes = globalStyles();
  const history = useHistory();
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const listInvoiceType = [
    { key: "claim", name: "Claim" },
    { key: "usage", name: "Usage" },
  ];

  const [loadingButton, setLoadingButton] = useState(false);
  const [payload, setPayload] = useState({
    transaction_name: "claim",
    type: "usage",
    claim_type: "claim",
    invoice_number: "",
    transaction_number: "",
    po_number: "",
    invoice_date: moment(),
    dsp_id: 0,
    end_date: moment(),
    start_date: moment(),
    exchange_rate: 0,
    job_number: "",
    account_number: 0,
    is_all_song: false,
    songs: [],
    revenue: 0,
    note: "",
    taxes: [],
  });
  const [optionClient, setOptionClient] = useState([]);
  const [optionBankAccount, setOptionBankAccount] = useState([]);
  const [showTaxes, setShowTaxes] = useState(false);
  const [showModalBulkAdd, setShowModalBulkAdd] = useState(false);
  const [showModalAddSong, setShowModalAddSong] = useState(false);
  const [showModalSongResults, setShowModalSongResult] = useState(false);
  const [showModalUploadResults, setShowModalUploadResults] = useState(false);
  const [uploadedSong, setUploadedSong] = useState({});

  const isUsageInvoice = payload?.type === "usage";
  const payloadDspId = payload?.dsp_id;
  let selectedDSP = optionClient?.find(dsp => dsp.dsp_id === payloadDspId);
  const selectedTaxes = payload?.taxes;
  const checkSelectedTaxes = selectedTaxes?.length > 0;
  const dataTableClaim = {
    note: payload?.note,
    revenue: payload?.revenue,
  };
  const isOverseas = selectedDSP && selectedDSP?.domicile === "overseas";
  const isForeignCurrency =
    selectedDSP &&
    selectedDSP?.dsp_currency !== null &&
    selectedDSP?.dsp_currency?.currency_iso_code !== "IDR";
  const selectedCurrencyCode = `${selectedDSP?.dsp_currency
    ?.currency_symbol_code || "Rp "} `;
  let totalFee = isUsageInvoice
    ? calculateTotalRevenue(payload?.songs)
    : payload?.revenue;
  let totalTax = calculateTotalTax(selectedTaxes, totalFee);
  let totalRevenueAfterTax = totalFee + (showTaxes && totalTax);
  const initialPayload = useRef(payload);
  const hasPayloadChanges =
    JSON.stringify(initialPayload?.current) !== JSON.stringify(payload);
  const hasChanges = !editMode ? hasPayloadChanges : false;
  const songDetailButtonAction = buttonAddList({
    uploadedSong,
    setShowModalSongResult,
    showModalSongResults,
    setShowModalBulkAdd,
    showModalBulkAdd,
    setShowModalAddSong,
    showModalAddSong,
  });

  const handleCloseModalBulkAdd = (_, reason) => {
    if (reason && (reason === "backdropClick" || reason === "escapeKeyDown")) {
      return;
    }
    setShowModalBulkAdd(false);
  };
  const handleCloseModalAddSong = () => setShowModalAddSong(false);
  const handleChangePayload = (value, key) => {
    setPayload(currentState => ({
      ...currentState,
      [key]: value,
    }));
  };
  const handleAddSong = item => {
    const modifiedTable = item?.map(item => {
      const revenueValue =
        typeof item?.revenue === "string" && item?.revenue_number !== undefined
          ? Number(item?.revenue_number)
          : Number(item?.revenue);

      return {
        ...item,
        revenue: revenueValue,
        qty: 1,
      };
    });

    handleChangePayload(modifiedTable, "songs");
    handleCloseModalAddSong();
  };
  const handleChangeCheckboxTaxes = (event, item) => {
    const { checked } = event.target;
    const newSelectedTaxes = checked
      ? [...selectedTaxes, item]
      : selectedTaxes.filter(selected => selected.id !== item.id);
    handleChangePayload(newSelectedTaxes, "taxes");
  };
  const setPreload = () => {
    if (preload && preload.period) {
      const [startPeriod, endPeriod] = preload?.period?.split(" - ");
      let start_date, end_date;

      start_date = formatPreloadDate(startPeriod);
      end_date = formatPreloadDate(endPeriod);

      const modifiedPreload = {
        ...preload,
        start_date,
        end_date,
        invoice_number: preload?.transaction_number,
        invoice_date: formatPreloadDate(preload?.invoice_date),
      };
      setPayload(modifiedPreload);
    }
  };
  const validatePayload = () => {
    const errors = [];
    const errorEmpty = err => `${err} can't be empty.`;

    if (payload?.dsp_id === 0) {
      errors.push(errorEmpty("Client Name"));
    } else if (payload?.songs?.length === 0 && isUsageInvoice) {
      errors.push(errorEmpty("Songs"));
    } else {
      Object.entries(payload).forEach(([key, value]) => {
        switch (key) {
          case "revenue":
            if (!value && !isUsageInvoice) errors.push(errorEmpty("Fee"));
            break;
          case "invoice_date":
            if (!value || value === "1970-01-01") {
              errors.push("Invoice date");
            } else if (
              value &&
              new Date(value).toString() === "Invalid Date" &&
              value !== "1970-01-01"
            ) {
              errors.push("Invoice Date - Invalid format");
            }
            break;
          case "invoice_number":
            if (!value) errors.push(errorEmpty("Invoice number"));
            break;
          case "account_number":
            if (!value && isOverseas) {
              errors.push(errorEmpty("Account number"));
            }
            break;
          case "job_number":
            if (!value && isOverseas) {
              errors.push(errorEmpty("Job number"));
            }
            break;
          case "start_date":
            if (!value) {
              if (!errors.includes("Period")) errors.push(errorEmpty("Period"));
            }
            break;
          case "end_date":
            if (!value) {
              if (!errors.includes("Period")) errors.push(errorEmpty("Period"));
            }
            break;
          case "taxes":
            if (!value.length && showTaxes) errors.push(errorEmpty("Taxes"));
            break;
          case "songs":
            if (value.length && isUsageInvoice) {
              value.forEach((data, index) => {
                if (data.revenue === "0" || !data.revenue) {
                  errors.push(errorEmpty(`Song fee number ${index + 1}`));
                } else if (!data.qty) {
                  errors.push(errorEmpty(`Song qty number ${index + 1}`));
                }
              });
            }
            break;
          default:
            break;
        }
      });
    }

    return errors;
  };
  const handleChangePublisherShare = ({ value, item, song }) => {
    const modifiedTable = payload?.songs?.map(payloadSong =>
      payloadSong?.song_id === song?.song_id
        ? {
            ...payloadSong,
            composers: payloadSong?.composers?.map(composer =>
              composer?.composer_id === item?.composer_id
                ? {
                    ...composer,
                    publisher_share_percentage: Number(value),
                  }
                : composer
            ),
          }
        : payloadSong
    );
    handleChangePayload(modifiedTable, "songs");
  };

  const getClientList = async () => {
    const url = `${hardBaseUrl}/master/all-client`;
    try {
      const res = await axios.get(url, { headers });
      const { data } = res?.data;
      const modifiedData = [
        {
          id: 0,
          label: "None",
        },
        ...data?.map(item => ({
          ...item,
          id: item?.dsp_id,
          label: item?.dsp_name,
        })),
      ];
      setOptionClient(modifiedData);
    } catch (error) {
      ModalError(error);
    }
  };
  const getExchangeRate = async () => {
    const currentDate = moment().format("DD MMM YYYY");
    const dspCurrencyId = selectedDSP?.dsp_currency?.id;
    const countryCurrencyId = selectedDSP?.country?.currency?.id;
    const currencyId = dspCurrencyId || countryCurrencyId;
    const url = `${hardBaseUrl}/exchange-rate/code?currency_id=${currencyId}&upload_date=${currentDate}`;
    if (currencyId) {
      try {
        const res = await axios.get(url, { headers });
        const resValue = res?.data?.data?.value;
        handleChangePayload(Number(resValue), "exchange_rate");
      } catch (error) {
        ModalError(error);
      }
    }
  };
  const getBaseCurrency = async () => {
    const { dsp_id, start_date, end_date } = payload;
    try {
      const res = await axios.get(
        `${hardBaseUrl}/publisher/transaction-claim/total-dsp-revenue`,
        {
          headers,
          params: {
            dsp_id,
            start_date: start_date.format("YYYY-MM"),
            end_date: end_date.format("YYYY-MM"),
          },
        }
      );
      const { data } = res?.data;
      handleChangePayload(Number(data?.original_currency_revenue), "revenue");
    } catch (error) {
      ModalError(error);
    }
  };
  const getListBankAccount = async () => {
    const url = `${hardBaseUrl}/publisher/bank`;
    try {
      const res = await axios.get(url, { headers });
      const resData = res?.data?.data;
      if (res?.data?.meta?.http_status === 200) {
        setOptionBankAccount(resData);
      }
    } catch (error) {
      ModalError(error);
    }
  };
  const postUploadDocuments = async item => {
    const formData = new FormData();
    formData.append("file", item[0]);
    try {
      setLoadingButton(true);
      const res = await axios.post(
        `${hardBaseUrl}/publisher/transaction-song/upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { data } = res?.data;
      const validSongs = data?.valid_songs;
      const processSongs = validSongs.map(item => {
        const uniqueComposers = [...new Set(item.composers)];
        const composerObjects = uniqueComposers.map(composer => ({
          sure_name: composer,
        }));

        return {
          ...item,
          composers: composerObjects,
          qty: 1,
        };
      });

      const mergeResult = [...payload?.songs, ...processSongs];
      const modifiedTable = Array.from(
        new Set(mergeResult.map(song => song?.song_id))
      ).map(song_id => mergeResult.find(song => song?.song_id === song_id));
      handleChangePayload(modifiedTable, "songs");
      setUploadedSong(data);
      handleCloseModalBulkAdd();
      setShowModalUploadResults(true);
    } catch (error) {
      ModalError(getErrors(error?.response));
      handleCloseModalBulkAdd();
    } finally {
      setLoadingButton(false);
    }
  };
  const handleSubmitPayload = () => {
    const errors = validatePayload();
    const {
      invoice_number,
      invoice_date,
      start_date,
      end_date,
      account_number,
      job_number,
    } = payload;

    const modifiedPayload = {
      ...payload,
      taxes: showTaxes ? selectedTaxes?.map(tax => tax?.id) : [],
      account_number: isOverseas ? account_number : "",
      job_number: isOverseas ? job_number : "",
      transaction_number: invoice_number,
      invoice_date: formatPayloadDate(invoice_date),
      revenue: totalFee,
      start_date: isUsageInvoice
        ? formatPayloadDate(start_date)
        : formatPayloadPeriodClaim(start_date),
      end_date: isUsageInvoice
        ? formatPayloadDate(end_date)
        : formatPayloadPeriodClaim(end_date),
    };

    if (errors?.length) {
      ModalError(errors[0]);
      return;
    }
    onSubmitPayload(modifiedPayload);
  };

  useEffect(() => {
    getClientList();
    getListBankAccount();
  }, []);
  useEffect(() => {
    setPreload();
  }, [preload]);
  useEffect(() => {
    getExchangeRate();
  }, [payloadDspId]);
  useEffect(() => {
    if (payloadDspId) {
      getBaseCurrency();
    }
  }, [payloadDspId, payload?.start_date, payload?.end_date]);
  useEffect(() => {
    const handleBeforeUnload = event => {
      if (hasChanges) {
        event.preventDefault();
        event.returnValue = "";
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [hasChanges]);
  useEffect(() => {
    const unblock = history.block(() => {
      if (hasChanges) {
        return "You have unsaved changes. Are you sure you want to leave this page?";
      }
    });

    return () => {
      unblock();
    };
  }, [history, hasChanges]);

  return (
    <Page className={classes?.root} title={pageTitle}>
      {loadingPage ? (
        <SkeletonComponent variant="wave" />
      ) : (
        <Container maxWidth={false}>
          <HeaderTitle
            title="Invoice"
            breadcrumbData={breadcrumbData(pageTitle)}
            backButton
          />
          <Divider className={classes?.divider} />
          <SectionLabel subTitle={pageSubTitle} />
          <Grid container my="16px">
            <Grid item xs={12} md={4}>
              <FormLabel label="Invoice Type" />
              <SelectInput
                value={payload?.type}
                onChange={event => {
                  if (!isUsageInvoice) {
                    handleChangePayload([], "songs");
                  }
                  handleChangePayload(event?.target?.value, "type");
                }}
                options={listInvoiceType}
                optionKey="key"
                optionLabel="name"
                width={150}
              />
            </Grid>
          </Grid>
          <Box border="1px solid #ebebeb" borderRadius="6px" p="24px" mt="16px">
            <Grid container columnSpacing={2} rowSpacing={1}>
              <Grid item xs={12} md={6}>
                <FormLabel label="Invoice Number" required />
                <TextInput
                  placeholder="Invoice Number"
                  value={payload?.invoice_number}
                  onChange={event =>
                    handleChangePayload(event?.target?.value, "invoice_number")
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormLabel label="PO Number" />
                <TextInput
                  placeholder="PO Number"
                  value={payload?.po_number}
                  onChange={event =>
                    handleChangePayload(event?.target?.value, "po_number")
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormLabel label="Invoice Date" required />
                <DatePicker
                  value={payload?.invoice_date}
                  onChange={date => handleChangePayload(date, "invoice_date")}
                  minDate={preload ? null : moment()}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormLabel label="Client Name" required />
                <AutoCompleteComponent
                  options={optionClient}
                  value={
                    optionClient.find(option => option.id === payloadDspId) ||
                    null
                  }
                  onChange={value => handleChangePayload(value, "dsp_id")}
                  size="small"
                  width="100%"
                />
              </Grid>
              {!editMode && (
                <Grid item xs={12}>
                  <Grid container justifyContent="right">
                    <PrimaryButton
                      label="Add Client"
                      endIcon={<AddIcon />}
                      onClick={() =>
                        history.push("/admin/parameter/client/add", {
                          fromClaim: true,
                        })
                      }
                    />
                  </Grid>
                </Grid>
              )}
              <Grid item xs={12} md={6}>
                <FormLabel label="Usage Period" required />
                {isUsageInvoice ? (
                  <DateRangePicker
                    startDate={payload?.start_date}
                    endDate={payload?.end_date}
                    handleChangeStartDate={value =>
                      handleChangePayload(value, "start_date")
                    }
                    handleChangeEndDate={value =>
                      handleChangePayload(value, "end_date")
                    }
                    width="100%"
                    placeholder="Usage Period"
                    endMaxDate={moment().add(10, "years")}
                  />
                ) : (
                  <MonthYearRangePicker
                    startDate={payload?.start_date}
                    endDate={payload?.end_date}
                    handleChangeStartDate={value =>
                      handleChangePayload(value, "start_date")
                    }
                    handleChangeEndDate={value =>
                      handleChangePayload(value, "end_date")
                    }
                    width="100%"
                    placeholder="Usage Period"
                  />
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                {isOverseas && isForeignCurrency && (
                  <div>
                    <FormLabel label="Exchange Rate" required />
                    <CurrencyNumberInput
                      placeholder="Exchange Rate"
                      value={payload?.exchange_rate}
                      startAdornment="Rp"
                      onChange={value =>
                        handleChangePayload(value, "exchange_rate")
                      }
                    />
                  </div>
                )}
              </Grid>
              {isOverseas && (
                <Grid item xs={12} md={6}>
                  <FormLabel label="Job No" required />
                  <TextInput
                    placeholder="Job No"
                    value={payload?.job_number}
                    onChange={event =>
                      handleChangePayload(event?.target?.value, "job_number")
                    }
                  />
                </Grid>
              )}
              {isOverseas && (
                <Grid item xs={12} md={6}>
                  <FormLabel label="Account Number" required />
                  <SelectInput
                    options={optionBankAccount}
                    width="100%"
                    value={payload?.account_number || ""}
                    onChange={event =>
                      handleChangePayload(
                        event?.target?.value,
                        "account_number"
                      )
                    }
                    optionKey="account_number"
                    optionLabel="bank.name"
                    renderValue={id => {
                      let value = optionBankAccount?.find(
                        bank => bank?.account_number === id
                      );
                      return (
                        value && (
                          <Grid
                            container
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Grid item>
                              <Grid container columnSpacing={1}>
                                <Grid item>
                                  <img
                                    className={classes?.bankAccountImage}
                                    src={getNestedValue(
                                      value,
                                      "bank.profile_image"
                                    )}
                                    alt={"Bank"}
                                  />
                                </Grid>
                                <Grid item>
                                  {getNestedValue(value, "bank.name")}
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item>
                              {getNestedValue(value, "account_number")}
                            </Grid>
                          </Grid>
                        )
                      );
                    }}
                    placeholder="None"
                    label={payload?.account_number ? "" : "Account Number"}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <SectionLabel title="Song Detail" />
                  </Grid>
                  {isUsageInvoice && (
                    <Grid item>
                      <Grid
                        container
                        justifyContent="flex-end"
                        columnSpacing={1}
                      >
                        {songDetailButtonAction?.map((item, index) => (
                          <Grid item key={index}>
                            <PrimaryButton
                              label={item?.label}
                              onClick={item?.onClick}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12} my={1}>
                <InnoTableV2
                  columns={columnTableSongDetail({
                    classes,
                    isUsageInvoice,
                    handleChangePublisherShare,
                    handleChangePayload,
                    payload,
                    selectedCurrencyCode,
                  })}
                  items={
                    isUsageInvoice ? payload?.songs : [dataTableClaim] || []
                  }
                  isLoading={false}
                />
              </Grid>
              <Grid item xx={12} mb={1}>
                <SwitchInput
                  checked={showTaxes}
                  onChange={() => setShowTaxes(!showTaxes)}
                  label="Use taxes in this transaction"
                />
              </Grid>
              <Grid item xs={12}>
                {showTaxes ? (
                  <Box>
                    <InnoTableV2
                      columns={columnTableTaxes}
                      items={selectedDSP?.taxes}
                      isLoading={false}
                      isHaveAction
                      renderAction={item => (
                        <Box textAlign="center" width="100%">
                          <CheckboxInput
                            checked={selectedTaxes?.some(
                              selected => selected?.id === item?.id
                            )}
                            onChange={e => handleChangeCheckboxTaxes(e, item)}
                          />
                        </Box>
                      )}
                    />
                    <Box
                      bgcolor="#fafafa"
                      borderRadius="6px"
                      p="14px"
                      mt="24px"
                    >
                      <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid item>Fee</Grid>
                        <Grid item>
                          <Typography fontSize={18}>
                            <CurrencyDisplay
                              value={totalFee}
                              prefix={selectedCurrencyCode}
                              decimalScale={2}
                              suffix=",-"
                            />
                          </Typography>
                        </Grid>
                      </Grid>
                      <Box mt="16px">
                        {checkSelectedTaxes &&
                          selectedTaxes?.map((item, index) => (
                            <Grid
                              container
                              key={index}
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Grid item>{item?.code}</Grid>
                              <Grid item>
                                <Typography fontSize={18}>
                                  <CurrencyDisplay
                                    value={calculateSingleTax(item, totalFee)}
                                    prefix={selectedCurrencyCode}
                                    decimalScale={2}
                                    suffix=",-"
                                  />
                                </Typography>
                              </Grid>
                            </Grid>
                          ))}
                      </Box>
                      <Divider className={classes?.divider} />
                      {isOverseas && isForeignCurrency && (
                        <Grid
                          container
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Grid item>Exchange Rate</Grid>
                          <Grid item>
                            <Typography fontSize={18}>
                              <CurrencyDisplay
                                value={payload?.exchange_rate}
                                prefix="Rp "
                                decimalScale={2}
                                suffix=",-"
                              />
                            </Typography>
                          </Grid>
                        </Grid>
                      )}
                      <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid item>
                          <Typography fontSize="18px">Total Fee</Typography>
                        </Grid>
                        <Grid item>
                          <Typography fontWeight="bold" fontSize="24px">
                            <CurrencyDisplay
                              value={totalRevenueAfterTax}
                              prefix={selectedCurrencyCode}
                              decimalScale={2}
                              suffix=",-"
                            />
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                ) : (
                  <Grid container justifyContent="flex-end">
                    <Grid item>
                      <Typography fontSize="14px" textAlign="right">
                        Total Fee :
                      </Typography>
                      <Typography fontWeight="bold" fontSize="24px">
                        <CurrencyDisplay
                          value={totalFee}
                          prefix={selectedCurrencyCode}
                          decimalScale={2}
                          suffix=",-"
                        />
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </Grid>
              <Grid item xs={12}>
                <Grid container justifyContent="right">
                  <PrimaryButton
                    label={editMode ? "Save" : "Add"}
                    onClick={handleSubmitPayload}
                    disabled={loadingPage}
                    loading={loadingPage}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <ModalBulkAdd
            open={showModalBulkAdd}
            onClose={handleCloseModalBulkAdd}
            onSubmit={postUploadDocuments}
            loadingButton={loadingButton}
          />
          <ModalAddSong
            open={showModalAddSong}
            onClose={handleCloseModalAddSong}
            onSubmit={handleAddSong}
            preload={payload?.songs}
          />
          <ModalSongResults
            open={showModalSongResults}
            onClose={() => setShowModalSongResult(false)}
            preload={uploadedSong}
          />
          <ModalUploadResults
            open={showModalUploadResults}
            onClose={() => setShowModalUploadResults(false)}
            preload={uploadedSong}
          />
        </Container>
      )}
    </Page>
  );
}
const formatPreloadDate = date => moment(date, "DD MMMM YYYY");
const formatPayloadPeriodClaim = date => date.format("YYYY-MM");
const formatPayloadDate = date => date.format("YYYY-MM-DD");
const isEmptyObject = obj => {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};
const breadcrumbData = pageTitle => [
  {
    label: "Home",
    link: "/admin/dashboard",
  },
  {
    label: "Transaction",
    link: "/admin/invoice",
  },
  {
    label: pageTitle,
    active: true,
  },
];
const columnTableTaxes = [
  {
    name: "code",
    title: "Code",
    renderText: item => item || "-",
  },
  {
    name: "description",
    title: "Description",
    renderText: item => item || "-",
  },
  {
    name: "rate",
    title: "Rate",
    renderText: item => `${item} %`,
  },
];
const columnTableSongDetail = ({
  classes,
  isUsageInvoice,
  handleChangePublisherShare,
  handleChangePayload,
  payload,
  selectedCurrencyCode,
}) => [
  ...(isUsageInvoice
    ? [
        {
          name: "song_title",
          title: "Song",
        },
        {
          name: "composers",
          title: "Composer/Author",
          renderText: item => (
            <TableCellMultipleRow
              type="chip"
              list={item}
              itemKey="sure_name"
              alignCenter
            />
          ),
        },
        {
          name: "all",
          title: "Publisher Percentage",
          renderText: song => (
            <TableCellMultipleRow
              type="numberInput"
              list={song?.composers}
              itemKey="publisher_share_percentage"
              tooltipKey="sure_name"
              onChange={({ value, item }) =>
                handleChangePublisherShare({ value, item, song })
              }
              endAdornment="%"
            />
          ),
        },
        {
          name: "type",
          title: "Type",
        },
        {
          name: "all",
          title: "Notes",
          renderText: item => (
            <Box minWidth={100}>
              <TextInput
                value={item?.notes}
                onChange={event => {
                  const modifiedTable = payload?.songs?.map(song =>
                    song?.song_id === item?.song_id
                      ? { ...song, notes: event?.target?.value }
                      : song
                  );
                  handleChangePayload(modifiedTable, "songs");
                }}
                multiline
                rows={2}
                placeholder="Notes"
              />
            </Box>
          ),
        },
        {
          name: "all",
          title: (
            <>
              Fee <span className={classes?.required}>*</span>
            </>
          ),
          renderText: item => (
            <CurrencyNumberInput
              value={item?.revenue}
              onChange={val => {
                const modifiedTable = payload?.songs?.map(song =>
                  song.song_id === item?.song_id
                    ? { ...song, revenue: Number(val) }
                    : song
                );
                handleChangePayload(modifiedTable, "songs");
              }}
              startAdornment={selectedCurrencyCode}
            />
          ),
        },
        {
          name: "all",
          title: "Qty",
          renderText: item => (
            <CurrencyNumberInput
              value={item?.qty}
              onChange={val => {
                const modifiedTable = payload?.songs?.map(song =>
                  song.song_id === item?.song_id
                    ? { ...song, qty: Number(val) }
                    : song
                );
                handleChangePayload(modifiedTable, "songs");
              }}
            />
          ),
        },
        {
          name: "all",
          title: "Total Fee",
          renderText: item => (
            <CurrencyDisplay
              value={Number(item?.revenue) * Number(item?.qty)}
              prefix={selectedCurrencyCode}
              decimalScale={0}
            />
          ),
        },
        {
          name: "all",
          title: "Action",
          renderText: item => (
            <TableAction
              handleDelete={() => {
                const modifiedTable = payload?.songs?.filter(
                  selected => selected.song_id !== item?.song_id
                );
                handleChangePayload(modifiedTable, "songs");
              }}
              width={30}
            />
          ),
        },
      ]
    : [
        {
          name: "all",
          title: (
            <>
              Fee <span className={classes?.required}>*</span>
            </>
          ),
          renderText: item => (
            <CurrencyNumberInput
              disabled
              value={item?.revenue}
              onChange={val => handleChangePayload(Number(val), "revenue")}
              startAdornment={selectedCurrencyCode}
            />
          ),
        },
        {
          name: "all",
          title: "Notes",
          renderText: item => (
            <TextInput
              value={item?.notes}
              onChange={event =>
                handleChangePayload(event?.target?.value, "note")
              }
              multiline
              rows={2}
              placeholder="Notes"
            />
          ),
        },
      ]),
];
const buttonAddList = ({
  uploadedSong,
  setShowModalSongResult,
  showModalSongResults,
  setShowModalBulkAdd,
  showModalBulkAdd,
  setShowModalAddSong,
  showModalAddSong,
}) => [
  ...(isEmptyObject(uploadedSong)
    ? []
    : [
        {
          label: "Results",
          onClick: () => setShowModalSongResult(!showModalSongResults),
        },
      ]),
  {
    label: "Bulk Add",
    onClick: () => setShowModalBulkAdd(!showModalBulkAdd),
  },
  {
    label: "Single Add",
    onClick: () => setShowModalAddSong(!showModalAddSong),
  },
];

export default FormInvoice;
