import { Chip, Grid, List, ListItem, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import moment from "moment";
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import NotifSign from "assets/img/notif-sign.svg";
import gradients from "theme/gradients";

const useStyles = makeStyles(theme => ({
  root: {},
  listItem: {
    "&:hover": {
      backgroundColor: theme.palette.background.default,
    },
    cursor: "pointer",
  },
  avatarBlue: {
    backgroundImage: gradients.blue,
  },
  avatarGreen: {
    backgroundImage: gradients.green,
  },
  avatarOrange: {
    backgroundImage: gradients.orange,
  },
  avatarIndigo: {
    backgroundImage: gradients.indigo,
  },
  arrowForwardIcon: {
    color: theme.palette.icon,
  },
}));

function NotificationList({ notifications, onClick, className, ...rest }) {
  const classes = useStyles();

  return (
    <List {...rest} className={clsx(classes.root, className)} disablePadding>
      {notifications.map(notification => (
        <ListItem
          className={classes.listItem}
          component={RouterLink}
          key={notification?.notification_id}
          to={`/admin/notifikasi/${notification?.notification_id}`}
          style={{
            cursor:
              notification?.type === "request_composer_payment" && "pointer",
          }}
          onClick={notification?.type === "request_composer_payment" && onClick}
        >
          <Grid container>
            <Grid item xs={2} md={2} sm={2} lg={2} xl={2}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img
                  src={NotifSign}
                  alt="notif-sign"
                  style={{ width: "13px", marginTop: "5px" }}
                />
              </div>
            </Grid>
            <Grid item xs={10} md={10} sm={10} lg={10} xl={10}>
              <Grid container>
                <Grid item xs={9} md={9} sm={9} lg={9} xl={9}>
                  {notification?.is_new && notification?.is_read === false && (
                    <div>
                      <Chip
                        label="NEW"
                        size="small"
                        style={{
                          backgroundColor: "#FCF3F2",
                          color: "#A63124",
                          fontSize: "12px",
                          fontWeight: "500",
                          borderRadius: "11px",
                          border: "1px solid #A63124",
                        }}
                      />
                    </div>
                  )}
                  <Typography component="span" variant="subtitle1">
                    {notification?.title}
                  </Typography>
                </Grid>
                <Grid item xs={3} md={3} sm={3} lg={3} xl={3}>
                  <Typography component="span" variant="body2">
                    {moment(notification?.created_at).fromNow()}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} md={12} sm={12} lg={12} xl={12}>
                  {notification?.type === "song_double_claimed" ? (
                    <>
                      <Typography component="span" variant="body2">
                        <Grid container>
                          <Grid item xs={12} md={12} sm={12} lg={12} xl={12}>
                            <div>
                              One of the songs experienced a double claim with
                              the following details :
                            </div>
                          </Grid>
                          <Grid item xs={3} md={3} sm={3} lg={3} xl={3}>
                            <div>Song Name</div>
                          </Grid>
                          <Grid item xs={1} md={1} sm={1} lg={1} xl={1}>
                            <div>:</div>
                          </Grid>
                          <Grid item xs={8} md={8} sm={8} lg={8} xl={8}>
                            <div>{notification?.data_double_claimed.song}</div>
                          </Grid>
                          <Grid item xs={3} md={3} sm={3} lg={3} xl={3}>
                            <div>Composer</div>
                          </Grid>
                          <Grid item xs={1} md={1} sm={1} lg={1} xl={1}>
                            <div>:</div>
                          </Grid>
                          <Grid item xs={8} md={8} sm={8} lg={8} xl={8}>
                            <div>
                              {notification?.data_double_claimed.composer}
                            </div>
                          </Grid>
                          <Grid item xs={3} md={3} sm={3} lg={3} xl={3}>
                            <div>ISRC</div>
                          </Grid>
                          <Grid item xs={1} md={1} sm={1} lg={1} xl={1}>
                            <div>:</div>
                          </Grid>
                          <Grid item xs={8} md={8} sm={8} lg={8} xl={8}>
                            <div>
                              {notification?.data_double_claimed.isrc_code}
                            </div>
                          </Grid>
                          <Grid item xs={3} md={3} sm={3} lg={3} xl={3}>
                            <div>ISWC</div>
                          </Grid>
                          <Grid item xs={1} md={1} sm={1} lg={1} xl={1}>
                            <div>:</div>
                          </Grid>
                          <Grid item xs={8} md={8} sm={8} lg={8} xl={8}>
                            <div>
                              {notification?.data_double_claimed.iswc_code}
                            </div>
                          </Grid>
                          <Grid item xs={3} md={3} sm={3} lg={3} xl={3}>
                            <div>Publisher</div>
                          </Grid>
                          <Grid item xs={1} md={1} sm={1} lg={1} xl={1}>
                            <div>:</div>
                          </Grid>
                          <Grid item xs={8} md={8} sm={8} lg={8} xl={8}>
                            <div>
                              {notification?.data_double_claimed.publisher}
                            </div>
                          </Grid>
                        </Grid>
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Typography component="span" variant="body2">
                        {notification?.content}
                      </Typography>
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ListItem>
      ))}
    </List>
  );
}

NotificationList.propTypes = {
  className: PropTypes.string,
  notifications: PropTypes.array.isRequired,
};

export default NotificationList;
