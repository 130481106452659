import Chip from "@material-ui/core/Chip";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
  chip: props => ({
    borderColor: props.type === "success" ? "green" : "red",
    color: props.type === "success" ? "green" : "red",
    backgroundColor: props.type === "success" ? "#e0f7ea" : "#fef2f2",
  }),
}));

const SmallChip = ({ label, type }) => {
  const classes = useStyles({ type });

  return (
    <Chip
      label={label}
      size="small"
      className={classes.chip}
      variant="outlined"
    />
  );
};

SmallChip.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["success", "failed"]).isRequired,
};

export default SmallChip;
