import { PrimaryButton } from "components";
import {
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { makeStyles } from "@material-ui/styles";
import { useState } from "react";

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  results: {
    marginTop: theme.spacing(3),
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  gap4: {
    display: "flex",
    gap: "4px",
    marginTop: "10px",
  },
}));

const DialogTambahUserPencipta = ({
  open,
  onClose,
  onSubmit,
  handleChangeSelectRole,
  handleChange,
  role,
  roleItems,
  composer,
  penciptaItems,
  handleChangeSelectPencipta,
  name,
  email,
  phone,
}) => {
  const classes = useStyles();

  const [passwordToggle, setPasswordToggle] = useState(false);
  const [konfirmasiPasswordToggle, setKonfirmasiPasswordToggle] = useState(
    false
  );

  const handleVisiblePassword = () => {
    setPasswordToggle(passwordToggle => !passwordToggle);
  };
  const handleVisibleKonfirmasiPassword = () => {
    setKonfirmasiPasswordToggle(!konfirmasiPasswordToggle);
  };

  return (
    <div>
      <Dialog
        fullWidth={true}
        open={open}
        onClose={onClose}
        aria-labelledby="responsive-dialog-title"
      >
        <form>
          <DialogTitle id="responsive-dialog-title">
            Tambah User Composer
          </DialogTitle>

          <DialogContent>
            <DialogContentText>User Name</DialogContentText>
            <div>
              <FormControl
                fullWidth
                className={classes.margin}
                variant="outlined"
              >
                <OutlinedInput
                  id="outlined-adornment-amount-phone"
                  onChange={handleChange}
                  name="name"
                  type="text"
                  fullWidth={true}
                  value={name}
                />
              </FormControl>
            </div>
          </DialogContent>

          <DialogContent>
            <DialogContentText>Composer </DialogContentText>
            <div>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                fullWidth={true}
              >
                <InputLabel
                  id="demo-simple-select-outlined-label"
                  shrink={false}
                >
                  {composer !== null ? null : "Pencipta"}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  onChange={handleChangeSelectPencipta}
                  autoWidth={true}
                  fullWidth={true}
                  defaultValue=""
                  value={composer}
                >
                  <MenuItem value="">Composer</MenuItem>
                  {penciptaItems.map(item => {
                    return (
                      <MenuItem key={item.composer_id} value={item.composer_id}>
                        {item.sure_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </DialogContent>
          <DialogContent>
            <DialogContentText>Role</DialogContentText>
            <div>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                fullWidth={true}
              >
                <InputLabel
                  id="demo-simple-select-outlined-label"
                  shrink={false}
                >
                  {role !== null ? null : "Role"}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  onChange={handleChangeSelectRole}
                  autoWidth={true}
                  fullWidth={true}
                  defaultValue=""
                  value={role}
                >
                  <MenuItem value="">Role</MenuItem>
                  {roleItems.map(item => {
                    return (
                      <MenuItem key={item.role_id} value={item.role_id}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </DialogContent>

          { }
          <DialogContent>
            <DialogContentText>Password</DialogContentText>
            <div>
              <FormControl
                fullWidth
                className={classes.margin}
                variant="outlined"
              >
                <TextField
                  id="outlined-adornment-amount-pass"
                  onChange={handleChange}
                  name="password"
                  variant="outlined"
                  type={passwordToggle ? "text" : "password"}
                  fullWidth={false}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        onClick={handleVisiblePassword}
                      >
                        {passwordToggle ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOff />
                        )}
                      </InputAdornment>
                    ),
                  }}
                  autoComplete="new-password"
                />
              </FormControl>
            </div>
          </DialogContent>
          <DialogContent>
            <DialogContentText>Confirm Password</DialogContentText>
            <div>
              <FormControl
                fullWidth
                className={classes.margin}
                variant="outlined"
              >
                <TextField
                  id="outlined-adornment-amount-passconf-new"
                  onChange={handleChange}
                  name="konfirmasiPassword"
                  variant="outlined"
                  type={konfirmasiPasswordToggle ? "text" : "password"}
                  fullWidth={false}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        onClick={handleVisibleKonfirmasiPassword}
                      >
                        {konfirmasiPasswordToggle ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOff />
                        )}
                      </InputAdornment>
                    ),
                  }}
                  autoComplete="new-confirmation-password"
                />
              </FormControl>
            </div>
          </DialogContent>
          <DialogContent>
            <DialogContentText>Phone Number</DialogContentText>
            <div>
              <FormControl
                fullWidth
                className={classes.margin}
                variant="outlined"
              >
                <OutlinedInput
                  id="outlined-adornment-amount-phone"
                  onChange={handleChange}
                  name="phone"
                  type="number"
                  fullWidth={true}
                  value={phone}
                />
              </FormControl>
            </div>
          </DialogContent>

          <DialogContent>
            <DialogContentText>Email</DialogContentText>
            <div>
              <FormControl
                fullWidth
                className={classes.margin}
                variant="outlined"
              >
                <OutlinedInput
                  id="outlined-adornment-amount-email"
                  onChange={handleChange}
                  name="email"
                  type="text"
                  fullWidth={true}
                  value={email}
                  autoComplete="new-email"
                />
              </FormControl>
            </div>
          </DialogContent>
          <DialogActions>
            <div className={classes.gap4}>
              <PrimaryButton size="small" onClick={onClose} label="Cancel" />
              <PrimaryButton size="small" onClick={onSubmit} label="Save" />
            </div>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default DialogTambahUserPencipta;
