import { Container, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import {
  ModalError,
  Page,
  SearchTextInput,
  SkeletonComponent,
} from "components";
import { InnoTable } from "inno-ui";
import { HeaderTitle } from "layouts";
import { getTotalPage } from "lib";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { getErrors } from "utils";
import { ButtonGroupTop } from "../../../components/atoms/Button";
import { hardBaseUrl } from "../../../services/urlConstant";
import { Box } from "@mui/material";

function SongUnclaim() {
  const classes = useStyles();
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const [loadingPage, setLoadingPage] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [queryParams, setQueryParams] = useState({
    page: 1,
    per_page: 10,
    search: "",
    sort: "song_title",
    type: "UNCLAIM",
  });
  const [tableTotalPage, setTableTotalPage] = useState(1);

  const handleChangeQueryParams = (value, key) => {
    setQueryParams(currentState => ({
      ...currentState,
      ...(key !== "page" && { page: 1 }),
      [key]: value,
    }));
  };

  const getDataTable = async () => {
    try {
      setLoadingPage(true);
      const res = await axios.get(`${hardBaseUrl}/song-claims`, {
        headers,
        params: queryParams,
      });

      const { data, meta } = res?.data;
      setDataTable(data);
      const pageCount = getTotalPage(meta?.total, queryParams?.size || 1);
      setTableTotalPage(pageCount);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  const debounceDataTable = useCallback(
    debounce(() => {
      getDataTable();
    }, 500),
    [queryParams]
  );

  useEffect(() => {
    debounceDataTable();
    return () => {
      debounceDataTable.cancel();
    };
  }, [queryParams, debounceDataTable]);

  return (
    <Page className={classes.root} title="Song Claim">
      {loadingPage ? (
        <SkeletonComponent variant="wave" />
      ) : (
        <Container maxWidth={false}>
          <HeaderTitle title="Song Claim" breadcrumbData={breadcrumbData} />
          <Divider className={classes.divider} />
          <ButtonGroupTop items={topMenuButton} />
          <Box mb="20px">
            <SearchTextInput
              placeholder="Search"
              value={queryParams?.search}
              onChange={e =>
                handleChangeQueryParams(e?.target?.value, "search")
              }
            />
          </Box>
          <InnoTable
            isLoading={false}
            columns={columnTable}
            items={dataTable}
            page={queryParams?.page}
            rowsPerPage={queryParams?.per_page}
            totalPage={tableTotalPage}
            handleChangePage={(_, page) =>
              handleChangeQueryParams(page, "page")
            }
            handleChangeRowsPerPage={e =>
              handleChangeQueryParams(e?.target?.value, "per_page")
            }
          />
        </Container>
      )}
    </Page>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
}));
const columnTable = [
  {
    name: "song_title",
    title: "Song Title",
    renderText: item => item || "-",
  },
  {
    name: "composer_names",
    title: "Composer",
    renderText: item => item || "-",
  },
  {
    name: "iswc_code",
    title: "ISWC",
    renderText: item => item || "-",
  },
];
const breadcrumbData = [
  {
    label: "Home",
    link: "/admin/dashboard",
  },
  {
    label: "Song Claim",
    active: true,
  },
];
const topMenuButton = [
  {
    id: 1,
    title: "Double Claim",
    link: "/admin/publisher/double-claim",
  },
  {
    id: 2,
    title: "Unclaim",
    link: "/admin/song-unclaim",
  },
];
export default SongUnclaim;
