import {
  DatePicker,
  FormLabel,
  PrimaryButton,
  RadioButtonInput,
  SecondaryButton,
  SectionLabel,
} from "components";
import { CloseOutlined } from "@material-ui/icons";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { useRef, useState } from "react";
import moment from "moment-timezone";

const ModalDeactivateContract = ({ open, onClose, onSubmit }) => {
  const dialogRef = useRef();
  const [payload, setPayload] = useState({
    range: "3days",
    inactive_at: moment().add(3, "days"),
  });

  const handleChangePayload = (value, key) => {
    setPayload(currentState => ({
      ...currentState,
      [key]: value,
    }));
  };
  const handleChangeDeactivateRange = event => {
    const { value } = event.target;
    const selectedRange = deactivateRange.find(range => range.key === value);
    handleChangePayload(value, "range");

    if (selectedRange) {
      const { amount, unit } = selectedRange.duration;
      const updatedDate = moment().add(amount, unit);
      handleChangePayload(updatedDate, "inactive_at");
    }
  };

  const handleSubmit = () => onSubmit(payload?.inactive_at);
  return (
    <Dialog
      ref={dialogRef}
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography fontWeight={700}>Deactivate Contract</Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={onClose}>
              <CloseOutlined />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <SectionLabel subTitle="Choose the date when the contract will be deactivated" mb="16px"/>
        <FormLabel label="Deactivate Date" />
        <DatePicker disabled value={payload?.inactive_at} />
        <RadioButtonInput
          options={deactivateRange}
          value={payload?.range}
          optionKey="key"
          optionLabel="label"
          onChange={handleChangeDeactivateRange}
        />
      </DialogContent>
      <DialogActions>
        <SecondaryButton label="Cancel" onClick={onClose} />
        <PrimaryButton
          label="Confirm"
          onClick={handleSubmit}
        />
      </DialogActions>
    </Dialog>
  );
};

const deactivateRange = [
  { key: "3days", label: "3 Days", duration: { amount: 3, unit: "days" } },
  { key: "1week", label: "1 Week", duration: { amount: 1, unit: "weeks" } },
  { key: "2weeks", label: "2 Weeks", duration: { amount: 2, unit: "weeks" } },
];

export default ModalDeactivateContract;
