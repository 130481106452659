import { CheckboxInput, SmallChip } from "components";
import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";
import {
  Box,
  CircularProgress,
  ClickAwayListener,
  Grow,
  InputAdornment,
  List,
  ListItem,
  Paper,
  Popover,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import { useRef, useState } from "react";

const MultipleSelectInput = ({
  label,
  placeholder,
  disabled,
  value = [],
  options = [],
  optionKey,
  optionLabel,
  checkBoxLabel,
  width,
  onChange,
  textValue,
  loading,
  ...rest
}) => {
  const inputRef = useRef(null);
  const paperRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleShowMenu = event => setAnchorEl(event.currentTarget);
  const handleClickAway = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <CustomTextField
        customWidth={width}
        ref={inputRef}
        size="small"
        label={label}
        focused={anchorEl}
        value={textValue || ""}
        placeholder={placeholder}
        disabled={disabled}
        onClick={disabled ? null : handleShowMenu}
        InputProps={{
          readOnly: true,
          fullWidth: true,
          endAdornment: (
            <InputAdornment position="end">
              {value?.length > 0 && (
                <SmallChip
                  label={(
                    value?.length -
                    (value?.some(item => item?.[optionKey] === "all") ? 1 : 0)
                  ).toString()}
                  type="failed"
                />
              )}
              {anchorEl ? <ArrowDropUp /> : <ArrowDropDown />}
            </InputAdornment>
          ),
        }}
        {...rest}
      />
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClickAway}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        TransitionComponent={Grow}
      >
        <ClickAwayListener onClickAway={handleClickAway}>
          <SelectPaper
            customWidth={inputRef?.current?.offsetWidth}
            ref={paperRef}
          >
            <CheckboxListContainer customWidth={inputRef?.current?.offsetWidth}>
              {loading ? (
                <Box p={2} textAlign="center">
                  <CircularProgress />
                </Box>
              ) : options?.length ? (
                options?.map((option, index) => (
                  <CheckboxListIem key={index}>
                    <CheckboxInput
                      label={
                        checkBoxLabel
                          ? checkBoxLabel(option)
                          : option?.[optionLabel]
                      }
                      checked={value?.some(
                        selected =>
                          selected?.[optionKey] === option?.[optionKey]
                      )}
                      onChange={e => onChange(e, option)}
                    />
                  </CheckboxListIem>
                ))
              ) : (
                <Box p={2}>
                  <Typography align="center">
                    <em>No Result</em>
                  </Typography>
                </Box>
              )}
            </CheckboxListContainer>
          </SelectPaper>
        </ClickAwayListener>
      </Popover>
    </Box>
  );
};

const CustomTextField = styled(TextField)(({ customWidth }) => ({
  cursor: "pointer !important",
  color: "red",
  width: customWidth,
  "& .MuiInputBase-root": {
    paddingRight: "8px",
    cursor: "pointer !important",
    "&.Mui-disabled": {
      cursor: "not-allowed !important",
      "&:hover": {
        cursor: "not-allowed !important",
      },
    },
  },
  "& .MuiInputBase-input": {
    cursor: "pointer !important",
    "&:hover": {
      cursor: "pointer !important",
    },
    "&.Mui-disabled": {
      cursor: "not-allowed !important",
      "&:hover": {
        cursor: "not-allowed !important",
      },
    },
  },
}));
const SelectPaper = styled(Paper)(({ customWidth }) => ({
  minWidth: customWidth,
}));
const CheckboxListContainer = styled(List)(() => ({
  maxHeight: 250,
  overflowY: "auto",
  zIndex: 1,
  "&::-webkit-scrollbar": {
    width: "6px",
  },
  "&::-webkit-scrollbar-track": {
    background: "#f1f1f1",
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#888",
    borderRadius: "10px",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    background: "#555",
  },
}));
const CheckboxListIem = styled(ListItem)(() => ({
  padding: "0 20px !important",
}));

export default MultipleSelectInput;
