import { LockOutlined, MailOutlined } from "@material-ui/icons";
import { Box, Button, Grid, styled, Typography } from "@mui/material";
import * as Sentry from "@sentry/react";
import Axios from "axios";
import {
  CheckboxInput,
  ModalError,
  PasswordInput,
  PrimaryButton,
  SecondaryButton,
  TextInput,
} from "components";
import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { globalStyles } from "styles";
import { Timer } from "utils";
import backgroundLogin from "../../../assets/background/background_login.webp";
import carisLogo from "../../../assets/img/newCarisLogo.webp";
import mpisLogo from "../../../assets/img/newMpisLogo.webp";
import { hardBaseUrl } from "../../../services/urlConstant";
import { getErrors } from "../../../utils";
import { setRememberMeLogin, setSessionLogin } from "../../../utils/constants";
import "./LoginCaris.css";

const containEmail = string => {
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailPattern.test(string);
};

const getSubdomain = () => {
  const host = window.location.hostname;
  const subdomain = host.split(".")[0];
  // for non local
  if (!host.includes("localhost")) {
    if (host.includes("caris.id")) {
      return subdomain;
    } else {
      return "mpis";
    }
  } else {
    //for local
    if (host.includes(".localhost")) {
      return subdomain;
    } else {
      return "mpis";
    }
  }
};

export default function LoginCaris() {
  const classes = globalStyles();
  let history = useHistory();
  const local_dsp_selected = localStorage.getItem("local_dsp_selected");
  const token = localStorage.getItem("token");
  const isRememberMe = localStorage.getItem("authRemember");
  const message = useSelector(state => state.auth.message);
  const isMpis = getSubdomain() === "mpis";
  const [forgotPassword, setForgotPassword] = useState(false);
  const [timer, setTimer] = useState(120);
  const [sentEmail, setSentEmail] = useState(false);
  const [remember, setRemember] = useState(false);
  const [dataLogin, setDataLogin] = useState({
    email: "",
    password: "",
  });
  const [loginDisabled, setLoginDisabled] = useState(false);

  const onTimeEnd = () => setTimer(0);
  const handleLogin = async () => {

    setLoginDisabled(true);
    let newDataLogin = {
      email: dataLogin.email,
      password: dataLogin.password,
      remember_me: remember,
      subdomain: getSubdomain(),
    };
    try {
      let resultLogin = await Axios.post(
        hardBaseUrl + "/auth/login",
        newDataLogin
      );

      if (remember) {
        setRememberMeLogin({
          email: dataLogin.email,
          password: dataLogin.password,
        });
        localStorage?.setItem(
          "token",
          resultLogin?.data?.data?.access_token || ""
        );
      }

      if (
        resultLogin.data.meta.http_status === 200 &&
        resultLogin.data.message === "success"
      ) {
        if (typeof resultLogin.data.data !== "object") {
          Sentry.captureMessage("Error catch undefined : undefined");
          localStorage.setItem("url_login", history.location.pathname);
          history.push("/otp", { email: dataLogin.email });
        } else {
          localStorage.setItem("url_login", history.location.pathname);
          localStorage.setItem("reminder", "reminder");

          let resultMe = await Axios.get(hardBaseUrl + "/me", {
            headers: {
              Authorization: "Bearer " + resultLogin.data.data.access_token,
            },
          });

          if (
            resultMe.data.meta.http_status === 200 &&
            resultMe.data.message === "success"
          ) {
            const payload = resultMe.data.data;
            setSessionLogin(payload);
            if (resultMe.data.data.role.type === "publisher") {
              if (resultMe.data.data.publisher.theme_color === "") {
                localStorage.setItem("themeColor", "#9545eb");
              } else {
                localStorage.setItem(
                  "themeColor",
                  resultMe.data.data.publisher.theme_color
                );
              }
              history.push("/redirect/" + resultLogin.data.data.access_token);
            } else if (resultMe.data.data.role.type === "association") {
              localStorage.setItem("url_login", history.location.pathname);
              history.push("/redirect/" + resultLogin.data.data.access_token);
            } else {
              if (localStorage.getItem("typeWeb") === "caris") {
                localStorage.setItem("themeColor", "#111827");
              } else if (localStorage.getItem("typeWeb") === "mpis") {
                localStorage.setItem("themeColor", "#9545eb");
              }
              history.push("/redirect/" + resultLogin.data.data.access_token);
            }
          } else {
            Sentry.captureMessage(
              "Error response /me resultMe.data.message is not success"
            );
          }
        }
      } else {
        Sentry.captureMessage(
          "Error response /auth/login ../../../pages/LandingPage/LoginCaris/LoginCaris.js"
        );
      }
    } catch (error) {
      ModalError(getErrors(error?.response)).then(() =>
        setLoginDisabled(false)
      );
    }
  };
  const handleResendEmail = async () => {
    try {
      await Axios.post(hardBaseUrl + "/auth/forgot", {
        email: dataLogin.email,
      });
      setTimer(120);
    } catch (error) {
      ModalError(getErrors(error?.response));
    }
  };

  const handleForgot = async () => {
    try {
      setLoginDisabled(true);
      await Axios.post(`${hardBaseUrl}/auth/forgot`, {
        email: dataLogin.email,
      });
      setTimeout(() => {
        setForgotPassword(false);
        setSentEmail(true);
      }, 500);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoginDisabled(false);
    }
  };

  useEffect(() => {
    if (local_dsp_selected) {
      localStorage.removeItem("local_dsp_selected");
    }
    localStorage.removeItem("auth");
    localStorage.removeItem("role");
    localStorage.removeItem("role_id");
    if (isRememberMe && token) {
      history?.push(`/redirect/${token}`);
    }
  }, []);
  useEffect(() => {
    if (message !== null) {
      window.location.reload();
    }
  }, [message]);
  const isTimerEnd = timer === 0;

  return (
    <Grid container>
      <Grid item xs={12} md={5} data-testid="data-test-page-login-mpis">
        <FormContainer>
          <Box maxWidth="600px" mx="auto">
            <Box
              mb="24px"
              textAlign={forgotPassword || sentEmail ? "center" : "left"}
            >
              <img
                src={isMpis ? mpisLogo : carisLogo}
                alt="logo"
                className="logo"
                width={isMpis ? "140px" : "200px"}
              />
            </Box>
            <Box>
              <TextInter
                fontSize="36px"
                fontWeight={700}
                textAlign={forgotPassword || sentEmail ? "center" : "left"}
              >
                {sentEmail
                  ? "Email Successfully Sent"
                  : forgotPassword
                    ? "Forgot the password"
                    : "Welcome Back !"}
              </TextInter>
              <TextInter
                color="#687083"
                textAlign={forgotPassword || sentEmail ? "center" : "left"}
                mb="24px"
              >
                {sentEmail
                  ? "You will receive a password recovery link at your email address."
                  : forgotPassword
                    ? "Enter the registered e-mail or mobile number. We will send you a verification code to reset your password."
                    : "Let's start manage and setting your music, composer and label easily with us "}
              </TextInter>

              {sentEmail ? (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  gap={1}
                >
                  <TextInter color="#687083">
                    Haven&apos;t received an email yet ?
                  </TextInter>
                  {isTimerEnd ? (
                    <TextButton onClick={handleResendEmail} variant="text">
                      Resend Email
                    </TextButton>
                  ) : (
                    <Box
                      display="flex"
                      alignItems="center"
                      gap={0.5}
                      justifyContent="center"
                    >
                      <TextInter
                        color={isTimerEnd ? "black" : "#687083"}
                        fontSize="15px"
                      >
                        Resend Email
                      </TextInter>
                      <Timer
                        time={timer}
                        onTimeEnd={onTimeEnd}
                        renderText={value => (
                          <TextInter fontSize="15px" color="#687083">
                            {`(${value}s)`}
                          </TextInter>
                        )}
                      />
                    </Box>
                  )}
                </Box>
              ) : (
                <Fragment>
                  <Box my="10px">
                    {!forgotPassword && (
                      <TextInter fontSize="14px" fontWeight={600} mb="8px">
                        Email
                      </TextInter>
                    )}
                    <TextInput
                      placeholder="Email"
                      onChange={e =>
                        setDataLogin({ ...dataLogin, email: e.target.value })
                      }
                      value={dataLogin.email}
                      startAdornment={
                        <MailOutlined className={classes?.loginFieldIcon} />
                      }
                      disabled={loginDisabled}
                      width="100%"
                      onKeyDown={e => {
                        if (e?.key === "Enter") {
                          if (forgotPassword) {
                            handleForgot();
                          } else {
                            handleLogin();
                          }
                        }
                      }}
                    />
                  </Box>
                  {!forgotPassword && (
                    <Box my="10px">
                      <Typography
                        fontFamily="inter"
                        fontSize="14px"
                        fontWeight={600}
                        mb="8px"
                      >
                        Password
                      </Typography>
                      <PasswordInput
                        placeholder="Password"
                        onChange={e =>
                          setDataLogin({
                            ...dataLogin,
                            password: e.target.value,
                          })
                        }
                        value={dataLogin.password}
                        startAdornment={
                          <LockOutlined className={classes?.loginFieldIcon} />
                        }
                        disabled={loginDisabled}
                        width="100%"
                        onKeyDown={e => {
                          if (e?.key === "Enter") {
                            handleLogin();
                          }
                        }}
                      />
                    </Box>
                  )}
                  <Box my="10px">
                    {!forgotPassword && (
                      <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid item>
                          <CheckboxInput
                            disabled={loginDisabled}
                            label="Remember Me"
                            checked={remember}
                            onChange={e => setRemember(e.target.checked)}
                          />
                        </Grid>
                        <Grid item>
                          <TextButton
                            disabled={loginDisabled}
                            onClick={() => setForgotPassword(true)}
                            variant="text"
                          >
                            Forgot password?
                          </TextButton>
                        </Grid>
                      </Grid>
                    )}
                  </Box>
                  <Box my="10px">
                    <PrimaryButton
                      label={
                        loginDisabled
                          ? forgotPassword
                            ? "Sending"
                            : "Logging In"
                          : forgotPassword
                            ? "Continue"
                            : "Login"
                      }
                      disabled={
                        forgotPassword
                          ? loginDisabled || !containEmail(dataLogin.email)
                          : loginDisabled
                      }
                      loading={loginDisabled}
                      onClick={forgotPassword ? handleForgot : handleLogin}
                      width="100%"
                    />
                  </Box>
                  <Box mt="10px">
                    {forgotPassword && (
                      <SecondaryButton
                        disabled={loginDisabled}
                        onClick={() => setForgotPassword(false)}
                        variant="text"
                        customColor="black"
                        width="100%"
                        label="Back"
                      />
                    )}
                  </Box>
                </Fragment>
              )}
            </Box>
          </Box>
        </FormContainer>
      </Grid>
      <Grid item xs={0} md={7}>
        <ImageContainer />
      </Grid>
    </Grid>
  );
}

const TextInter = styled(Typography)({
  fontFamily: "inter",
});
const FormContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  height: "100vh",
  padding: "0px 3em",
  backgroundColor: "#f5f5f5",
});
const ImageContainer = styled(Box)({
  width: "100%",
  height: "100%",
  backgroundImage: `url(${backgroundLogin})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
});
const TextButton = styled(Button)(({ customColor, width }) => ({
  color: customColor || "#9545EB",
  border: "black",
  textTransform: "none",
  width,
}));
