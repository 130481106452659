import {
  Button,
  Card,
  CardContent,
  Chip,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecordRounded";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import { hardBaseUrl } from "../../../../services/urlConstant";

const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },

  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  formControl: {},
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  results: {
    marginTop: theme.spacing(3),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  pageSize: {
    height: "30px",
    borderRadius: "6px",
    border: "1px solid #D1D5DC",
    paddingLeft: "5px",
    paddingRight: "5px",
    marginTop: "5px",
  },
  paginationText: {
    fontSize: "14px",
    fontWeight: "normal",
    fontStyle: "normal",
  },
  breadcrumbs: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#111827",
  },
  breadCrumbsActive: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#687083",
  },
  placeholder: {
    "&::placeholder": {
      color: "#9AA2B1",
    },
  },
  conTextBlack: {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    marginBottom: "10px",
  },
  label: {
    color: "#364052",
    fontSize: "14px",
    fontWeight: 500,
    marginBottom: "5px",
  },
  subTitle: {
    fontSize: "18px",
    fontWeight: 700,
    color: " #111827",
  },
  btnSubmit: {
    backgroundColor: "black",

    color: "white",
    padding: "10px 28px",

    height: "40px",
    borderRadius: "6px",

    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
}));

const DetailMainContract = ({
  noKontrak,
  tglMulai,
  publisher,
  status,
  tglSelesai,
  idPublisher,
  keterangan,
  approvalStatus,
  approvalStatusUpdate,
  agentList,
  advanceValue,
  composerName,
  publisherSharePercentage,
  perpuityContract,
}) => {
  const { state } = useLocation();
  const classes = useStyles();

  const [roleFor, setRoleFor] = useState("");

  const getChipStatus = status => {
    if (status === "approved") {
      return (
        <Chip
          avatar={
            <FiberManualRecordIcon
              style={{ width: "10px", color: "#34774C" }}
            />
          }
          style={{
            margin: "4px",
            color: "#34774C",
            backgroundColor: "#8DE5AB",
          }}
          label={"Approved"}
        />
      );
    } else if (status === "rejected") {
      return (
        <Chip
          avatar={
            <FiberManualRecordIcon
              style={{ width: "10px", color: "#E53E3E" }}
            />
          }
          style={{
            margin: "4px",
            color: "#E53E3E",
            backgroundColor: "#FED7D7",
          }}
          label={"Rejected"}
        />
      );
    } else if (status === "waiting_for_approval") {
      return (
        <Chip
          avatar={
            <FiberManualRecordIcon
              style={{ width: "10px", color: "#F6AD55" }}
            />
          }
          style={{ color: "#F6AD55", backgroundColor: "#FEEBC8" }}
          label={"Waiting for Approval"}
        />
      );
    } else if (status === "waiting_for_editing_approval") {
      return (
        <Chip
          avatar={
            <FiberManualRecordIcon
              style={{ width: "10px", color: "#F6AD55" }}
            />
          }
          style={{ color: "#F6AD55", backgroundColor: "#FEEBC8" }}
          label={" Waiting for Editing Approval"}
        />
      );
    }
  };

  const handleApprove = () => {
    const token = localStorage.getItem("token");
    const url = `${hardBaseUrl}/publisher/contract/composer-main/approval/${state.customer.contract_id}`;

    const data = {
      approval_status: "approved",
    };
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, approve it!",
    }).then(result => {
      if (result.isConfirmed) {
        axios
          .put(url, data, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then(() => {
            Swal.fire({
              icon: "success",
              title: "Approved!",
              text: "Your contract has been approved.",
              showConfirmButton: true,
            }).then(result => {
              if (result.isConfirmed) {
                window.location.reload();
              }
            });
          })
          .catch(err => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: err.response.data.errors[0].message,
              confirmButtonColor: "#3085d6",
            });
          });
      }
    });
  };
  const handleReject = () => {
    const token = localStorage.getItem("token");
    const url = `${hardBaseUrl}/publisher/contract/composer-main/approval/${state.customer.contract_id}`;
    const data = {
      approval_status: "rejected",
    };
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, reject",
    }).then(result => {
      if (result.isConfirmed) {
        axios
          .put(url, data, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then(() => {
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "Contract has been rejected",
              confirmButtonColor: "#3085d6",
            }).then(result => {
              if (result.isConfirmed) {
                window.location.reload();
              }
            });
          })
          .catch(err => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: err.response.data.errors[0].message,
              confirmButtonColor: "#3085d6",
            });
          });
      }
    });
  };

  const getFeeTypeAgent = feeType => {
    if (feeType === "sales_amount") {
      return "Sales Amount";
    } else if (feeType === "net_revenue_amount") {
      return "Net Revenue Amount";
    }
  };

  const getMe = () => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const url = `${hardBaseUrl}/me`;
    axios
      .get(url, config)
      .then(res => {
        setRoleFor(res.data.data.role.roles_for);
      })
      .catch(() => {});
  };

  useEffect(() => {
    getMe();
  }, []);

  return (
    <div>
      <div>
        {roleFor === "finance" ? (
          <div>
            <Grid container>
              <div>
                <ThemeProvider theme={theme}>
                  <Typography
                    component="h1"
                    variant="h3"
                    className={classes.subTitle}
                  >
                    {approvalStatusUpdate === "waiting_for_approval" ||
                    approvalStatusUpdate === "approved" ? (
                      <>Edit</>
                    ) : (
                      <>Create</>
                    )}{" "}
                    Submission
                  </Typography>
                </ThemeProvider>
                <ThemeProvider theme={theme}>
                  <p
                    style={{
                      marginTop: "5px",
                      color: "#8f8f8f",
                      fontSize: "14px",
                    }}
                  >
                    Features for contract editing validation purposes
                  </p>
                </ThemeProvider>
              </div>
            </Grid>
            <Grid container>
              <Grid item xs={12} md={8} lg={8} xl={8}>
                <Card
                  style={{
                    border: "1px solid #9AA2B1",
                    borderRadius: "6px",
                    boxShadow: "none",
                    marginBottom: "20px",
                  }}
                >
                  <CardContent>
                    {}
                    <Grid container>
                      <Grid item xs={6} md={6} lg={6} xl={6}>
                        <Typography className={classes.label}>
                          Status
                        </Typography>
                        {approvalStatusUpdate !== "" ? (
                          <>{getChipStatus(approvalStatusUpdate)}</>
                        ) : (
                          <>{getChipStatus(approvalStatus) || "-"}</>
                        )}
                      </Grid>
                      <Grid item xs={6} md={6} lg={6} xl={6}>
                        {}
                        <>
                          {approvalStatus === "rejected" ||
                          approvalStatus === "approved" ||
                          approvalStatus === "" ? (
                            <>{""}</>
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <Button
                                variant="contained"
                                className={classes.btnSubmit}
                                style={{
                                  backgroundColor: "white",
                                  color: "black",
                                  border: "1px solid #9AA2B1",
                                }}
                                onClick={handleReject}
                              >
                                <ThemeProvider theme={theme}>
                                  <Typography
                                    style={{
                                      textTransform: "none",
                                      color: "black",
                                      fontSize: "14px",
                                      fontWeight: 400,
                                      lineHeight: "20px",
                                    }}
                                  >
                                    Reject
                                  </Typography>
                                </ThemeProvider>
                              </Button>

                              <Button
                                variant="contained"
                                className={classes.btnSubmit}
                                style={{
                                  backgroundColor: "black",
                                  marginLeft: "10px",
                                }}
                                onClick={handleApprove}
                              >
                                <ThemeProvider theme={theme}>
                                  <Typography
                                    style={{
                                      textTransform: "none",
                                      color: "#FFFFFF",
                                      fontSize: "14px",
                                      fontWeight: 400,
                                      lineHeight: "20px",
                                    }}
                                  >
                                    Approve
                                  </Typography>
                                </ThemeProvider>
                              </Button>
                            </div>
                          )}
                        </>
                        {}
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={4} lg={4} xl={4}></Grid>
            </Grid>
          </div>
        ) : (
          <div>
            <Grid container>
              <div>
                <ThemeProvider theme={theme}>
                  <Typography
                    component="h1"
                    variant="h3"
                    className={classes.subTitle}
                  >
                    {approvalStatusUpdate === "waiting_for_approval" ||
                    approvalStatusUpdate === "approved" ? (
                      <>Edit</>
                    ) : (
                      <>Create</>
                    )}{" "}
                    Submission
                  </Typography>
                </ThemeProvider>
                <ThemeProvider theme={theme}>
                  <p
                    style={{
                      marginTop: "5px",
                      color: "#8f8f8f",
                      fontSize: "14px",
                    }}
                  >
                    Features for contract editing validation purposes
                  </p>
                </ThemeProvider>
              </div>
            </Grid>
            <Grid container>
              <Grid item xs={12} md={8} lg={8} xl={8}>
                <Card
                  style={{
                    border: "1px solid #9AA2B1",
                    borderRadius: "6px",
                    boxShadow: "none",
                    marginBottom: "20px",
                  }}
                >
                  <CardContent>
                    <Typography className={classes.label}>Status</Typography>
                    {getChipStatus(approvalStatus) || "-"}
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={4} lg={4} xl={4}></Grid>
            </Grid>
          </div>
        )}

        <Grid container>
          <div>
            <ThemeProvider theme={theme}>
              <Typography
                component="h1"
                variant="h3"
                className={classes.subTitle}
              >
                Main Contract Information
              </Typography>
            </ThemeProvider>
            <ThemeProvider theme={theme}>
              <p
                style={{
                  marginTop: "5px",
                  color: "#8f8f8f",
                  fontSize: "14px",
                }}
              >
                Contract information that has been made.
              </p>
            </ThemeProvider>
          </div>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={8} lg={8} xl={8}>
            <Card
              style={{
                border: "1px solid #9AA2B1",
                borderRadius: "6px",
                boxShadow: "none",
              }}
            >
              <CardContent>
                <ThemeProvider theme={theme}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6} lg={6} xl={6}>
                      <Typography className={classes.label}>
                        Contract Number
                      </Typography>
                      <Typography className={classes.conTextBlack}>
                        {noKontrak || "-"}
                      </Typography>
                      <Typography className={classes.label}>
                        Start Date
                      </Typography>
                      <Typography className={classes.conTextBlack}>
                        {tglMulai || "-"}
                      </Typography>
                      <Typography className={classes.label}>
                        Publisher
                      </Typography>
                      <Typography className={classes.conTextBlack}>
                        {publisher || "-"}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6} xl={6}>
                      <Typography className={classes.label}>Status</Typography>
                      <Typography className={classes.conTextBlack}>
                        {status ? (
                          <Chip
                            avatar={
                              <FiberManualRecordIcon
                                style={{ width: "10px", color: "#34774C" }}
                              />
                            }
                            style={{
                              margin: "4px",
                              color: "#34774C",
                              backgroundColor: "#8DE5AB",
                            }}
                            label={"Active"}
                          />
                        ) : (
                          <Chip
                            style={{
                              margin: "4px",
                              color: "#F87171",
                              backgroundColor: "#FEE2E2",
                            }}
                            label={"Inactive"}
                          />
                        )}
                      </Typography>
                      <Typography className={classes.label}>
                        End Date
                      </Typography>
                      <Typography className={classes.conTextBlack}>
                        {perpuityContract ? "-" : tglSelesai || "-"}
                      </Typography>
                      <Typography className={classes.label}>
                        Publisher ID
                      </Typography>
                      <Typography className={classes.conTextBlack}>
                        {idPublisher || "-"}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} xl={12}>
                      <Typography className={classes.label}>
                        Description
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "16px",
                          fontWeight: 400,
                          color: "#687083",
                        }}
                      >
                        {keterangan || "-"}
                      </Typography>
                    </Grid>
                  </Grid>
                </ThemeProvider>
              </CardContent>
            </Card>
            <Divider className={classes.divider} />
          </Grid>
          <Grid item xs={12} md={4} lg={4} xl={4}></Grid>
        </Grid>
      </div>

      <div>
        <Grid container></Grid>
        <Grid container>
          <Grid item xs={12} md={8} lg={8} xl={8}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <ThemeProvider theme={theme}>
                  <Typography
                    component="h1"
                    variant="h3"
                    className={classes.subTitle}
                  >
                    Composer
                  </Typography>
                </ThemeProvider>
                <ThemeProvider theme={theme}>
                  <p
                    style={{
                      marginTop: "5px",
                      color: "#8f8f8f",
                      fontSize: "14px",
                    }}
                  >
                    Composer who are part of this contract.
                  </p>
                </ThemeProvider>
              </div>
              <div>
                <ThemeProvider theme={theme}>
                  <Typography
                    component="h1"
                    variant="h3"
                    className={classes.subTitle}
                  >
                    {composerName}
                  </Typography>
                </ThemeProvider>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={4} lg={4} xl={4}></Grid>
        </Grid>
      </div>

      <div>
        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
          <div>
            <Typography
              component="h1"
              variant="h3"
              className={classes.subTitle}
            >
              Advance Value
            </Typography>
            <ThemeProvider theme={theme}>
              <p
                style={{
                  marginTop: "5px",
                  color: "#8f8f8f",
                  fontSize: "14px",
                }}
              >
                Feature to set Advance Payment
              </p>
            </ThemeProvider>

            <Card
              style={{
                border: "1px solid #9AA2B1",
                borderRadius: "6px",
                boxShadow: "none",
                marginTop: "10px",
              }}
            >
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <ThemeProvider theme={theme}>
                      <TableCell align="left">
                        <Typography
                          variant="subtitle2"
                          className={classes.headText}
                        >
                          Advance Value
                        </Typography>
                      </TableCell>

                      <TableCell align="left">
                        <Typography
                          variant="subtitle2"
                          className={classes.headText}
                        >
                          Start Period
                        </Typography>
                      </TableCell>

                      <TableCell align="left">
                        <Typography
                          variant="subtitle2"
                          className={classes.headText}
                        >
                          End Period
                        </Typography>
                      </TableCell>
                    </ThemeProvider>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {advanceValue?.length > 0 ? (
                    advanceValue?.map(customer => (
                      <TableRow hover key={customer.song_composer_id}>
                        <TableCell>{customer.advance_value}</TableCell>

                        <TableCell>{customer.start_period}</TableCell>

                        <TableCell>{customer.end_period}</TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={4}>
                        <center>
                          <ThemeProvider theme={theme}>
                            <Typography
                              variant="h6"
                              style={{
                                fontWeight: 400,
                                color: "#687083",
                                fontSize: "14px",
                              }}
                            >
                              no data
                            </Typography>
                          </ThemeProvider>
                        </center>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Card>
          </div>
        </Grid>
      </div>

      <Grid container style={{ marginTop: "20px" }}>
        <Grid item xs={12} md={8} lg={8} xl={8}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <ThemeProvider theme={theme}>
                <Typography
                  component="h1"
                  variant="h3"
                  className={classes.subTitle}
                >
                  Publisher Share Percentage
                </Typography>
              </ThemeProvider>
              <ThemeProvider theme={theme}>
                <p
                  style={{
                    marginTop: "5px",
                    color: "#8f8f8f",
                    fontSize: "14px",
                  }}
                >
                  Feature to set publisher share percentage
                </p>
              </ThemeProvider>
            </div>
            <div>
              <ThemeProvider theme={theme}>
                <Typography
                  component="h1"
                  variant="h3"
                  className={classes.subTitle}
                >
                  {publisherSharePercentage}%
                </Typography>
              </ThemeProvider>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={4} lg={4} xl={4}></Grid>
      </Grid>

      <Grid
        container
        style={{
          marginTop: "20px",
        }}
      >
        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
          <div>
            <Typography
              component="h1"
              variant="h3"
              className={classes.subTitle}
            >
              Agent
            </Typography>
            <ThemeProvider theme={theme}>
              <p
                style={{
                  marginTop: "5px",
                  color: "#8f8f8f",
                  fontSize: "14px",
                }}
              >
                Feature to set Agent & fee Agent
              </p>
            </ThemeProvider>

            <Card
              style={{
                border: "1px solid #9AA2B1",
                borderRadius: "6px",
                boxShadow: "none",
                marginTop: "10px",
              }}
            >
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <ThemeProvider theme={theme}>
                      <TableCell>
                        <Typography
                          variant="subtitle2"
                          className={classes.headText}
                        >
                          Composer/Author Name
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography
                          variant="subtitle2"
                          className={classes.headText}
                        >
                          Agent
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography
                          variant="subtitle2"
                          className={classes.headText}
                        >
                          Agent Fee
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography
                          variant="subtitle2"
                          className={classes.headText}
                        >
                          Fee Calculation Basis
                        </Typography>
                      </TableCell>
                    </ThemeProvider>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {agentList?.length > 0 ? (
                    agentList?.map(customer => (
                      <TableRow hover key={customer.agent_id}>
                        <TableCell>{customer.composer_name}</TableCell>
                        <TableCell>{customer.agent_name}</TableCell>
                        <TableCell>{customer.agent_fee_formatted}</TableCell>
                        <TableCell>
                          {getFeeTypeAgent(customer.fee_type)}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={4}>
                        <center>
                          <ThemeProvider theme={theme}>
                            <Typography
                              variant="h6"
                              style={{
                                fontWeight: 400,
                                color: "#687083",
                                fontSize: "14px",
                              }}
                            >
                              no data
                            </Typography>
                          </ThemeProvider>
                        </center>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Card>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}></Grid>
      </Grid>
    </div>
  );
};

export default DetailMainContract;
