import {
  Backdrop,
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  Checkbox,
  Chip,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Menu,
  MenuItem,
  OutlinedInput,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import _ from "lodash";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDropzone } from "react-dropzone";
import { useHistory, useLocation } from "react-router";

import axios from "axios";
import { Page } from "components";
import moment from "moment";

import AddIcon from "@material-ui/icons/Add";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import SearchIcon from "@material-ui/icons/Search";
import { KeyboardDatePicker } from "@material-ui/pickers";
import NumberFormat from "react-number-format";
import TrashVector from "../../../../assets/img/trashVector.svg";
import Debugging from "../../../../utils/devDebug";
import Swal from "sweetalert2";
import datePicIcon from "../../../../assets/img/date-picker.png";
import { hardBaseUrl } from "../../../../services/urlConstant";
import DialogResult from "./DialogResult";
import DialogUploadSong from "./DialogUploadSong";

const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  results: {
    marginTop: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  mypadding: {
    "& .MuiSelect-outlined": {
      paddingTop: "10.5px",
      paddingBottom: "10.5px",
    },
  },
  myInput: {
    "& .MuiOutlinedInput-input": {
      paddingTop: "12px",
      paddingBottom: "12px",
    },
  },
  textArea: {
    border: "1px solid #red",
    ".makeStyles-myInput-58": {
      border: "1px solid #red",
    },
  },
  percentField: {
    "& .MuiOutlinedInput-input": {
      paddingTop: "10px",
      paddingBottom: "10px",
      width: "250px",
    },
  },
  inputNum: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "0px 6px 6px 0px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderLeft: "1px solid #d1d5dc",
      borderRight: "1px solid #d1d5dc",
      borderTop: "1px solid #d1d5dc",
      borderBottom: "1px solid #d1d5dc",
    },
    "& .MuiOutlinedInput-input": {
      paddingTop: "10.5px",
      paddingBottom: "10.5px",
    },
    "& input.Mui-disabled": {
      color: "black",
    },
  },
  inputNumPercent: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "6px 0px 0px 6px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderLeft: "1px solid #d1d5dc",
      borderRight: "1px solid #d1d5dc",
      borderTop: "1px solid #d1d5dc",
      borderBottom: "1px solid #d1d5dc",
    },
    "& .MuiOutlinedInput-input": {
      paddingTop: "10.5px",
      paddingBottom: "10.5px",
    },
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  pagination: {
    "& .Mui-selected": {
      backgroundColor: "black",
      color: "white",
    },
  },
  paginationText: {
    fontSize: "14px",
    fontWeight: "normal",
    fontStyle: "normal",
    color: "#687083",
  },
  breadcrumbs: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#111827",
  },
  breadCrumbsActive: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#687083",
  },
  placeholder: {
    "&::placeholder": {
      color: "#9AA2B1",
    },
  },
  title: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "28px",
  },
  label: {
    color: "#364052",
    fontSize: "14px",
    fontWeight: 500,
  },
  subTitle: {
    fontSize: "18px",
    fontWeight: 700,
    color: " #111827",
  },
  ib: {
    padding: "4px",
    color: "white",
    backgroundColor: "black",
    borderRadius: "6px",
    "&:focus": {
      color: "white",
      backgroundColor: "black",
    },
    "&:active": {
      boxShadow: "none",
      color: "white",
      backgroundColor: "black",
    },
    "&:hover": {
      color: "white",
      backgroundColor: "black",
      boxShadow: "none",
    },
  },
  pageSize: {
    height: "30px",
    borderRadius: "6px",
    border: "1px solid #D1D5DC",
    paddingLeft: "5px",
    paddingRight: "5px",
    marginTop: "5px",
    backgroundColor: "white",
  },
  paginationButton: {
    width: "40px",
    height: "40px",
    border: "1px solid #D1D5DC",
    textTransform: "none",
    alignItems: "center",
    backgroundColor: "white",
  },
  tableChip: {
    margin: "2px",
    backgroundColor: "#F9FAFB",
    border: "1px solid #D1D5DC",
  },
  rootSwitch: {
    width: "40px",
    height: "20px",
    padding: "0px",
    borderRadius: "10px",
    marginRight: "8px",
    position: "relative",
    backgroundColor: "green",
    opacity: 1,
  },
  "&.MuiSwitch-colorSecondary.Mui-checked": {
    backgroundColor: "green",
  },
  switchBase: {
    padding: "10px",
    color: "white",
    "&.Mui-checked + $track": {
      border: "none",
      color: "white",
      backgroundColor: "black",
      opacity: 1,
    },
    // ini ketika di checked
    "&.MuiSwitch-colorSecondary.Mui-checked + $track": {
      border: "none",
      color: "white",
      backgroundColor: "black",
      opacity: 1,
    },
  },
  checked: {
    backgroundColor: "black",
    border: "none",
    color: "white",
    opacity: 1,
  },
  thumb: {
    color: "white",
    position: "absolute",
    width: "16px",
    height: "16px",
  },
  // ini untuk unchecked
  track: {
    backgroundColor: "#9c9c9c",
    opacity: 1,
  },
  colorSecondary: {
    "&.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "black",
      opacity: 1,
    },
    "&.Mui-checked": {
      color: "white",
      backgroundColor: "black",
    },
  },
  colorPrimary: {
    "&.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "cyan",
      opacity: 1,
    },
    "&.Mui-checked": {
      color: "white",
      backgroundColor: "yellow",
    },
  },
  myAct: {
    "& .MuiDialogActions-root": {
      justifyContent: "center",
    },
  },
  required: {
    color: "red",
  },
}));
const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "50px",
  borderWidth: 2,
  borderRadius: 8,
  borderColor: "#303030",
  borderStyle: "thin",
  backgroundColor: "lightgray",
  color: "black",
  outline: "none",
  transition: "border .24s ease-in-out",
  cursor: "pointer",
};
const activeStyle = {
  borderColor: "#2196f3",
};
const acceptStyle = {
  borderColor: "#00e676",
};
const rejectStyle = {
  borderColor: "#ff1744",
};

const AddClaimAndUsage = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [hasChanges, setHasChanges] = useState(false);
  const [invoiceType, setInvoiceType] = useState("usage");
  const prefInvoiceType = useRef("usage");
  const [clientType, setClientType] = useState("");
  const [listInvoiceType] = useState(["claim", "usage", "rbt"]);
  const [clientName, setClientName] = useState("");
  const [listClientName, setListClientName] = useState([]);
  const [openInvoiceDate, setOpenInvoiceDate] = useState(false);
  const [month1, setMonth1] = useState("");
  const [month2, setMonth2] = useState("");
  const [year1, setYear1] = useState("");
  const [year2, setYear2] = useState("");
  const [periodStartDate, setPeriodStartDate] = useState(null);
  const [periodEndDate, setPeriodEndDate] = useState(null);
  const [yearList, setYearList] = useState([]);
  const [songList, setSongList] = useState([]);
  const [songdId] = useState([]);
  const [invoiceDate, setInvoiceDate] = useState(null);
  const [songDialog, setSongDialog] = useState(false);
  const [checkedVal, setcheckedVal] = useState(false);
  const [totalRev, setTotalrev] = useState("");
  const [totalAfterTax, setTotalAfterTax] = useState("");
  const [arrOfSelectedSong, setArrOfSelectedSong] = useState([]);
  const [valueTotalClaim, setValueTotalClaim] = useState(0);
  const [globalNotes, setGlobalNotes] = useState("");
  const [selectedTax, setSelectedTax] = useState([]);
  const [selectedBankAccount, setSelectedBankAccount] = useState(null);
  const [loadingDelete, setLoadingDelete] = React.useState(false);
  const monthList = [
    {
      value: "01",
      name: "January",
    },
    {
      value: "02",
      name: "February",
    },
    {
      value: "03",
      name: "March",
    },
    {
      value: "04",
      name: "April",
    },
    {
      value: "05",
      name: "May",
    },
    {
      value: "06",
      name: "June",
    },
    {
      value: "07",
      name: "July",
    },
    {
      value: "08",
      name: "August",
    },
    {
      value: "09",
      name: "September",
    },
    {
      value: "10",
      name: "October",
    },
    {
      value: "11",
      name: "November",
    },
    {
      value: "12",
      name: "December",
    },
  ];
  const [addSongData, setAddSongData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [searchKey, setSearchKey] = useState("");
  const [afterSetPeriodFirst] = useState(true);
  const [afterGetClientList, setAfterGetClientList] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [files, setFiles] = useState([]);
  const [dokumen, setDokumen] = useState([]);

  const pageCount = Math.ceil(totalData / rowsPerPage);
  const [clientSelected, setClientSelected] = useState(null);
  const [listBankAccount, setListAccountBank] = useState(null);
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [poNumber, setPoNumber] = useState("");
  const [jobNumber, setJobNumber] = useState("");
  const [exchangeRate, setExchangeRate] = useState(0);
  const [resultUploadSong, setResultUploadSong] = useState(null);
  const [openResult, setOpenResult] = useState(false);
  const [submiting, setSubmiting] = useState(false);
  const typeClaim = invoiceType === "claim" || invoiceType === "rbt";
  const isForeignCurrency =
    clientName &&
    clientSelected?.dsp_currency !== null &&
    clientSelected?.dsp_currency?.currency_iso_code !== "IDR";

  const handleDrop = items => {
    if (items?.length > 1) {
      Debugging.devLog("more than 1", "handleDrop :553", true);
    } else {
      if (files?.length > 0) {
        const data = [];
        items.forEach(item => {
          data.push(item);
        });
        setFiles(data);
      } else {
        const data = [];
        items.forEach(item => {
          data.push(item);
        });
        setFiles(data);
      }
    }
  };
  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop: handleDrop,
    accept: [".xls", ".xlsx"],
  });
  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );
  const handleImportDokumen = e => {
    e.preventDefault();
    setOpenDialog(false);
    setLoadingDelete(true);
    const formData = new FormData();
    const url = `${hardBaseUrl}/publisher/transaction-song/upload`;
    const token = localStorage.getItem("token");
    formData.append("file", files[0]);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .post(url, formData, config)
      .then(res => {
        let currSongs = songList;

        let newSongs = res.data.data.valid_songs;
        let processSong = newSongs.map(item => {
          let a = {
            ...item,
            composer_names: item.composers,
            addNotes: item.notes,
            choosenType: item.type,
            type: [
              "Sync Royalty",
              "Mechanical Royalty",
              "Performing",
              "Mech-Sync Royalty",
            ],
            addRevenue: item.revenue,
            qty: 1,
            addRevenueNumber: Number(item.revenue) * 1,
          };
          return a;
        });

        let idsSong = newSongs.map(item => {
          return item.song_id;
        });

        setArrOfSelectedSong(idsSong);

        setResultUploadSong(res.data.data);
        const mergeResult = [...currSongs, ...processSong];
        setSongList(mergeResult);

        setOpenDialog(false);
        setLoadingDelete(false);
        Swal.fire({
          title: "Success",
          text: "Document has been uploaded",
          icon: "success",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
        });
      })
      .catch(() => {
        setLoadingDelete(false);
        Swal.fire({
          title: "Error",
          text: "Document failed to upload",
          icon: "error",
          confirmButtonText: "Ok",
        });
      });
  };
  const onRemoveFile = idx => {
    setDokumen(dokumen.filter((_, i) => i !== idx));
    setFiles(files.filter((_, i) => i !== idx));
    acceptedFiles.splice(idx, 1);
  };
  const getPageNumber = () => {
    const firstPage = (page - 1) * rowsPerPage + 1;
    const lastPage = page * rowsPerPage;
    return `${firstPage} - ${lastPage} of ${totalData} items`;
  };

  const changeValueTotalClaim = e => {
    setValueTotalClaim(e);
    let theVal = e;
    let changeFormat = theVal.replaceAll(",", "");
    let newRes = Number(changeFormat)
      .toString()
      .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.");
    setValueTotalClaim(newRes.replaceAll(".", ","));
    setTotalrev(Number(e.replaceAll(",", "")));
    if (selectedTax?.length) {
      setTotalAfterTax(newRes);

      const tempTaxes = selectedTax.map(el => ({
        ...el,
        afterCountTax:
          (el.rate / 100) * (el.tax_bases / 100) * +newRes.replaceAll(".", ""),
      }));
      const sumAllTaxes =
        tempTaxes.reduce(
          (acc, crrt) =>
            crrt?.method === "addition"
              ? acc + crrt.afterCountTax
              : acc - crrt.afterCountTax,
          0
        ) + +newRes.replaceAll(".", "");
      setSelectedTax(tempTaxes);

      const sumAfterTax = sumAllTaxes;
      setTotalAfterTax(sumAfterTax);
    } else {
      setTotalAfterTax(Number(e.replaceAll(",", "")));
    }
  };
  const getRevenueClaim = () => {
    const token = localStorage.getItem("token");
    const startDate = `${year1}-${month1}`;
    const endDate = `${year2}-${month2}`;
    const urlInvoiceRevenue = `${hardBaseUrl}/publisher/transaction-claim/total-dsp-revenue?dsp_id=${clientName}&start_date=${startDate === "-" ? "" : startDate
      }&end_date=${endDate === "-" ? "" : endDate}`;
    axios
      .get(urlInvoiceRevenue, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(res => {
        setValueTotalClaim(res.data.data.original_currency_revenue);
        let newRes = res.data.data.revenue;
        setTotalrev(res.data.data.original_currency_revenue);
        setTotalAfterTax(res.data.data.original_currency_revenue);
        setSelectedTax([]);
      })
      .catch(() => { });
  };
  useEffect(() => {
    if (
      (prefInvoiceType.current === "usage" && typeClaim) ||
      (prefInvoiceType.current === "claim" && invoiceType === "usage")
    ) {
      setSelectedTax([]);
      setTotalAfterTax("");
      setTotalrev("");
      setValueTotalClaim(0);
      if (
        prefInvoiceType.current === "usage" &&
        typeClaim &&
        songList?.length
      ) {
        setSongList([]);
      }
    }
  }, [invoiceType, prefInvoiceType.current]);
  useEffect(() => {
    if (
      typeClaim &&
      month1 !== "" &&
      month2 !== "" &&
      year1 !== "" &&
      year2 !== "" &&
      clientName !== ""
    ) {
      getRevenueClaim();
    }
  }, [invoiceType, clientName, month1, month2, year1, year2]);
  useEffect(() => {
    if (
      checkedVal === true &&
      typeClaim &&
      month1 !== "" &&
      month2 !== "" &&
      year1 !== "" &&
      year2 !== "" &&
      clientName !== ""
    ) {
      if (typeof valueTotalClaim === "string") {
        let tempTax = [...selectedTax].map(el => {
          return {
            ...el,
            afterCountTax:
              (Number(el.rate) / 100) *
              (Number(el.tax_bases) / 100) *
              +valueTotalClaim.replaceAll(",", ""),
          };
        });
        const sumAllTaxes =
          tempTax.reduce(
            (acc, crrt) =>
              crrt?.method === "addition"
                ? acc + crrt?.afterCountTax || 0
                : acc - crrt?.afterCountTax || 0,
            0
          ) + +valueTotalClaim.replaceAll(",", "");
        setSelectedTax(tempTax);
        setTotalAfterTax(sumAllTaxes);
      } else {
        let tempTax = [...selectedTax].map(el => {
          return {
            ...el,
            afterCountTax:
              (Number(el.rate) / 100) *
              (Number(el.tax_bases) / 100) *
              +valueTotalClaim,
          };
        });
        const sumAllTaxes =
          tempTax.reduce(
            (acc, crrt) =>
              crrt?.method === "addition"
                ? acc + crrt.afterCountTax
                : acc - crrt.afterCountTax,
            0
          ) + +valueTotalClaim;
        setSelectedTax(tempTax);
        setTotalAfterTax(isNaN(sumAllTaxes) ? valueTotalClaim : sumAllTaxes);
      }
    }
  }, [checkedVal]);

  const handleSearchKey = event => {
    setSearchKey(event.target.value);
  };

  const clickOpenInvoiceDate = () => {
    setOpenInvoiceDate(true);
  };
  const handleCloseMenuInvoiceDate = () => {
    setOpenInvoiceDate(false);
  };
  const getSongListFromBtn = () => {
    const token = localStorage.getItem("token");
    const startDate = `${year1}-${month1}`;
    const endDate = `${year2}-${month2}`;
    const urlSongList = `${hardBaseUrl}/publisher/transaction-song?page=${page}&size=${rowsPerPage}&dsp_id=${clientName}&start_date=${startDate === "-" ? "" : startDate
      }&end_date=${endDate === "-" ? "" : endDate}&search=${searchKey}`;
    axios
      .get(urlSongList, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(res => {
        setTotalData(res.data.meta.total);
        setPage(res.data.meta.page);
        const newData = res.data.data.map(item => ({
          ...item,
          checked: false,
          type: [
            "Sync Royalty",
            "Mechanical Royalty",
            "Performing",
            "Mech-Sync Royalty",
          ],
          choosenType: "Sync Royalty",
          addRevenue: 0,
          addNotes: "",
          qty: 1,
        }));
        let theSongs = arrOfSelectedSong;

        newData.forEach((item, index) => {
          theSongs.forEach(itemSong => {
            if (item.song_id === itemSong) {
              newData[index].checked = true;
            }
          });
        });
        setAddSongData(newData);
        setSongDialog(true);
      })
      .catch(() => { });
  };
  const clickOpenSongDialog = () => {
    getSongListFromBtn();
  };
  const clickOpenResult = () => {
    setOpenResult(true);
  };
  const clickOpenUploadSongDialog = () => {
    setOpenDialog(true);
  };
  const clickAddClient = () => {
    history.push("/admin/parameter/client/add", { fromClaim: true });
  };
  const handleCloseSongDialog = () => {
    setSongDialog(false);
  };

  const handleInvoiceDateChange = event => {
    setHasChanges(true);
    setInvoiceDate(event);
  };
  const handleChangeUsageStart = event => {
    setHasChanges(true);
    setPeriodStartDate(event);
  };
  const handleChangeUsageEnd = event => {
    setHasChanges(true);
    setPeriodEndDate(event);
  };

  useEffect(() => {
    if (afterSetPeriodFirst) {
      const changeMonthToNum = item => {
        // input should be 01 or 02 and etc
        if (item === "10" || item === "11" || item === "12") {
          let newItem = Number(item);
          return newItem;
        } else {
          let newItem = Number(item.replaceAll("0", ""));
          return newItem;
        }
      };
      const changeMonthToStr = item => {
        // input should be 01 or 02 and etc
        if (item === 10 || item === 11 || item === 12) {
          let newItem = `${item}`;
          return newItem;
        } else {
          let newItem = `0${item}`;
          return newItem;
        }
      };
      const checkMonth = (item1, item2) => {
        let removeZeroMonth1 = changeMonthToNum(item1);
        let removeZeroMonth2 = changeMonthToNum(item2);
        if (removeZeroMonth1 > removeZeroMonth2) {
          let backMonth1 = changeMonthToStr(removeZeroMonth1);
          let backMonth2 = changeMonthToStr(removeZeroMonth2);
          setMonth1(backMonth2);
          setMonth2(backMonth1);
        }
      };
      if (year1 !== "" && year2 !== "") {
        if (year1 > year2) {
          setYear1(year2);
          setYear2(year1);
          checkMonth(month1, month2);
        }
      }
    }
  }, [month1, year1]);
  useEffect(() => {
    getListBankAccount();
    if (afterSetPeriodFirst) {
      const changeMonthToNum = item => {
        // input should be 01 or 02 and etc
        if (item === "10" || item === "11" || item === "12") {
          let newItem = Number(item);
          return newItem;
        } else {
          let newItem = Number(item?.replaceAll("0", ""));
          return newItem;
        }
      };
      const changeMonthToStr = item => {
        // input should be 01 or 02 and etc
        if (item === 10 || item === 11 || item === 12) {
          let newItem = `${item}`;
          return newItem;
        } else {
          let newItem = `0${item}`;
          return newItem;
        }
      };
      const checkMonth = (item1, item2) => {
        let removeZeroMonth1 = changeMonthToNum(item1);
        let removeZeroMonth2 = changeMonthToNum(item2);
        if (removeZeroMonth1 > removeZeroMonth2) {
          let backMonth1 = changeMonthToStr(removeZeroMonth1);
          let backMonth2 = changeMonthToStr(removeZeroMonth2);
          setMonth1(backMonth2);
          setMonth2(backMonth1);
        }
      };
      if (year1 !== "" && year2 !== "") {
        if (year2 < year1) {
          setYear2(year1);
          setYear1(year2);
          checkMonth(month1, month2);
        } else {
          if (year1 === year2) {
            checkMonth(month1, month2);
          }
        }
      }
    }
  }, [month2, year2]);
  const handleChangeMonthStart = e => {
    setMonth1(e.target.value);
  };
  const handleChangeYearStart = e => {
    setYear1(e.target.value);
  };
  const handleChangeMonthEnd = e => {
    setMonth2(e.target.value);
  };
  const handleChangeYearEnd = e => {
    setYear2(e.target.value);
  };

  const handleChangeCheckboxTaxes = value => {
    const tempTax = [...selectedTax];
    if (selectedTax.find(el => el.id === value.id)) {
      switch (value?.method) {
        case "addition":
          setTotalAfterTax(Number(totalAfterTax) - +value.afterCountTax);

          break;
        case "deduction":
          setTotalAfterTax(Number(totalAfterTax) + +value.afterCountTax);

          break;
        default: {
          //
        }
      }
      tempTax.splice(
        tempTax.findIndex(data => data.id === value.id),
        1
      );
      setSelectedTax(tempTax);
    } else {
      const afterCountTax =
        (value.rate / 100) * (value.tax_bases / 100) * Number(totalRev);

      if (totalRev) {
        switch (value?.method) {
          case "addition":
            setTotalAfterTax(Number(totalAfterTax) + afterCountTax);
            break;
          case "deduction":
            setTotalAfterTax(Number(totalAfterTax) - afterCountTax);
            break;
          default: {
            //
          }
        }
        value.afterCountTax = afterCountTax;
      }
      tempTax.push(value);
      setSelectedTax(tempTax);
    }
  };
  const getListBankAccount = async () => {
    const token = localStorage.getItem("token");
    const url = `${hardBaseUrl}/publisher/bank`;
    try {
      const listBank = await axios.get(url, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (listBank.data.meta.http_status === 200) {
        setListAccountBank(listBank.data.data);
      }
    } catch (err) {
      //
    }
  };
  const getInvoiceRevenue = () => {
    const token = localStorage.getItem("token");
    const startDate = `${year1}-${month1}`;
    const endDate = `${year2}-${month2}`;
    const arrSongIdToString = songdId?.toString();
    const urlInvoiceRevenue = `${hardBaseUrl}/publisher/song-usage/total-revenue?dsp_id=${clientName}&start_date=${startDate === "-" ? "" : startDate
      }&end_date=${endDate === "-" ? "" : endDate}&song_id=${arrSongIdToString}`;
    axios.get(urlInvoiceRevenue, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  const getClientList = async () => {
    const token = localStorage.getItem("token");
    const urlClientList = `${hardBaseUrl}/master/all-client`;
    await axios
      .get(urlClientList, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(res => {
        let newData = res.data.data;
        let afterNewData = newData.map(itm => {
          let aaa = {
            ...itm,
            client_type: "foreign",
          };
          return aaa;
        });

        setListClientName(afterNewData);

        setAfterGetClientList(true);
      })
      .catch(() => { });
  };

  const handleChangeInvoiceType = e => {
    setHasChanges(true);
    setInvoiceType(e.target.value);
    let date = moment().toString();
    let month = date.getMonth() + 1;
    if (month < 10) {
      month = "0" + month;
    }
  };

  const handleChangeClientType = e => {
    setHasChanges(true);
    setClientType(e.target.value);
  };

  const getExchangeRate = async (id, date) => {
    const token = localStorage.getItem("token");
    const urlClientList = `${hardBaseUrl}/exchange-rate/code?currency_id=${id}&upload_date=${date}`;
    await axios
      .get(urlClientList, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(res => {
        setExchangeRate(res?.data?.data?.value);
      })
      .catch(() => { });
  };

  const handleChangeSelectClientName = e => {
    setHasChanges(true);
    const selectedClient = listClientName.find(
      el => el.dsp_id === e.target.value
    );
    setClientSelected(selectedClient);
    if (selectedClient?.dsp_currency?.id) {
      getExchangeRate(
        selectedClient?.dsp_currency?.id,
        moment().format("DD MMM YYYY")
      );
    } else if (selectedClient?.country?.currency?.id) {
      getExchangeRate(
        selectedClient?.country?.currency?.id,
        moment().format("DD MMM YYYY")
      );
    }

    setClientName(e.target.value);
    let resType = _.find(listClientName, function (o) {
      return o.dsp_id < Number(e.target.value);
    });
    if (typeof resType !== "undefined") {
      setClientType("domestic");
    }
  };

  const getSongList = () => {
    const token = localStorage.getItem("token");
    const startDate = `${year1}-${month1}`;
    const endDate = `${year2}-${month2}`;
    const urlSongList = `${hardBaseUrl}/publisher/transaction-song?page=${page}&size=${rowsPerPage}&dsp_id=${clientName}&start_date=${startDate === "-" ? "" : startDate
      }&end_date=${endDate === "-" ? "" : endDate}&search=${searchKey}`;
    axios
      .get(urlSongList, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(res => {
        setTotalData(res.data.meta.total);
        setPage(res.data.meta.page);
        const newData = res.data.data.map(item => ({
          ...item,
          checked: false,
          type: [
            "Sync Royalty",
            "Mechanical Royalty",
            "Performing",
            "Mech-Sync Royalty",
          ],
          choosenType: "Sync Royalty",
          addRevenue: 0,
          addNotes: "",
          qty: 1,
        }));
        let theSongs = arrOfSelectedSong;

        newData.forEach((item, index) => {
          theSongs.forEach(itemSong => {
            if (item.song_id === itemSong) {
              newData[index].checked = true;
            }
          });
        });
        setAddSongData(newData);
      })
      .catch(() => { });
  };

  const handleChangeTableType = (e, id) => {
    const newData = [...addSongData];
    const index = newData.findIndex(item => item.song_id === id);
    newData[index].choosenType = e.target.value;
    // change type on songlist
    const indexSong = songList.findIndex(item => item.song_id === id);
    const newSongListType = [...songList];
    if (indexSong >= 0 && songList?.length) {
      newSongListType[indexSong].choosenType = e.target.value;
      setSongList(newSongListType);
    }
    setAddSongData(newData);
  };
  const handleNextPage = e => {
    e.preventDefault();
    setPage(page + 1);
  };
  const handlePrevPage = e => {
    e.preventDefault();
    setPage(page - 1);
  };

  useEffect(() => {
    const thisYear = moment().year() + 5;
    let arrYears = [];
    for (let i = thisYear; i >= 2000; i--) {
      arrYears.push(i);
    }
    setYearList(arrYears);

    getClientList();
    getInvoiceRevenue();
    getSongList();

    // add checked: false to each item in addSongDataTemp
  }, [invoiceType, clientName, rowsPerPage, page, searchKey]);

  useEffect(() => {
    if (typeof location.state !== "undefined") {
      if (location.state.fromClient === true) {
        getClientList();
        setClientName(location.state.data.newClientId);
        const selectedClient = listClientName.find(
          el => el.dsp_id === location.state.data.newClientId
        );
        setClientSelected(selectedClient);
      }
    }
  }, [afterGetClientList]);

  const handleChangeTableCheckbox = (e, id) => {
    const newData = [...addSongData];
    const index = newData.findIndex(item => item.song_id === id);
    newData[index].checked = !newData[index].checked;
    if (newData[index].checked === true) {
      //push teh data into songLIst array
      setSongList(songList => [...songList, newData[index]]);
    } else {
      //remove the data from songList array
      setSongList(songList => songList.filter(item => item.song_id !== id));
    }
    setAddSongData(newData);

    let getArr = arrOfSelectedSong;
    setArrOfSelectedSong(getArr);

    if (newData[index].checked === true) {
      getArr.push(id);
    } else {
      const index = getArr.indexOf(id);
      if (index > -1) {
        // only splice array when item is found
        getArr.splice(index, 1); // 2nd parameter means remove one item only
      }
    }
    setArrOfSelectedSong(getArr);
  };
  const handleDeleteSongList = (e, id) => {
    e.preventDefault();

    let arrSelSong = [...arrOfSelectedSong];
    let theSongList = [...songList];
    const index = arrSelSong.indexOf(id);
    if (index > -1) {
      // only splice array when item is found
      arrSelSong.splice(index, 1); // 2nd parameter means remove one item only
      theSongList.splice(index, 1);
    }
    setArrOfSelectedSong(arrSelSong);
    setSongList(theSongList);
    if (!theSongList?.length) {
      setTotalAfterTax("0");
      setcheckedVal(prev => !prev);
      setSelectedTax([]);
    }
  };
  const handleDeleteSongListClaim = (e, id) => {
    let arrSelSong = [...arrOfSelectedSong];
    let theSongList = [...songList];

    const index = arrSelSong.indexOf(id);
    if (index > -1) {
      arrSelSong.splice(index, 1); // 2nd parameter means remove one item only
      theSongList.splice(index, 1);
    }
    setArrOfSelectedSong(arrSelSong);
    setSongList(theSongList);
  };
  const handleChangeRowsPerPage = event => {
    event.preventDefault();
    setRowsPerPage(event.target.value);

    setPage(1);
  };

  const pageSizePagination = () => {
    return (
      <div className={classes.pageSize}>
        <select
          style={{
            backgroundColor: "transparent",
            border: "none",
            outline: "none",
            marginTop: "4px",
          }}
          value={rowsPerPage}
          onChange={handleChangeRowsPerPage}
        >
          {[10, 20, 50].map(pageSize => {
            return (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            );
          })}
        </select>
      </div>
    );
  };

  const myGet = item => {
    let arrChip = [];
    if (item !== null && item?.length > 0) {
      let myArray = item;
      myArray.forEach(newItem => {
        arrChip.push(newItem);
      });
    }
    return (
      <>
        <div>
          <ThemeProvider theme={theme}>
            {arrChip.map((newitem, idx) => {
              return (
                <React.Fragment key={idx}>
                  <Chip
                    key={idx}
                    label={newitem}
                    className={classes.tableChip}
                    size="small"
                  />
                </React.Fragment>
              );
            })}
          </ThemeProvider>
        </div>
      </>
    );
  };
  const myGetAliasName = item => {
    let arrChip = [];
    if (item !== null && item?.length > 0) {
      let myArray = item;
      myArray.forEach(newItem => {
        arrChip.push(newItem.alias_name);
      });
      return (
        <>
          <div>
            <ThemeProvider theme={theme}>
              {arrChip.map((newitem, idx) => {
                return (
                  <React.Fragment key={idx}>
                    <Chip
                      key={idx}
                      label={newitem === "" ? "-" : newitem}
                      className={classes.tableChip}
                      size="small"
                    />
                  </React.Fragment>
                );
              })}
            </ThemeProvider>
          </div>
        </>
      );
    } else {
      return "-";
    }
  };
  const formatDate = date => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month?.length < 2) month = "0" + month;
    if (day?.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };
  const onSubmit = e => {
    e.preventDefault();
    setSubmiting(true);
    const token = localStorage.getItem("token");
    const url = `${hardBaseUrl}/publisher/transaction`;

    const returnOnlyNumber = str => {
      return typeof str === "string"
        ? Number(str?.replaceAll(",", ""))
        : Number(str?.toString()?.replaceAll(",", ""));
    };

    const newData = songList?.map(item => {
      return {
        song_id: item.song_id,
        type: item.choosenType,
        notes: item.addNotes,
        qty: Number(item.qty),
        composers: item?.composers,
        revenue: returnOnlyNumber(item.addRevenue),
      };
    });

    const payload = {
      dsp_id: clientName,
      end_date: typeClaim
        ? `${year2}-${month2}`
        : periodEndDate
          ? moment(periodEndDate).format()
          : moment(invoiceDate).format(),
      invoice_date: formatDate(invoiceDate),
      is_all_song: false,
      note: globalNotes,
      songs: newData,
      start_date: typeClaim
        ? `${year1}-${month1}`
        : periodStartDate
          ? moment(periodStartDate).format()
          : moment(invoiceDate).format(),
      transaction_name: "claim",
      transaction_number: invoiceNumber,
      type: invoiceType,
      revenue: totalRev,
      invoice_number: invoiceNumber,
      po_number: poNumber,
      job_number: jobNumber,
      exchange_rate: returnOnlyNumber(exchangeRate),
      account_number: selectedBankAccount?.account_number,
      taxes: selectedTax.map(el => el.id),
    };

    if (payload.dsp_id === "") {
      Swal.fire({
        title: "Error",
        text: "Client Name can't be empty.",
        icon: "error",
        confirmButtonText: "OK",
      });
      setSubmiting(false);
    } else if (payload.songs?.length === 0 && invoiceType === "usage") {
      Swal.fire({
        title: "Error",
        text: "Songs can't be empty.",
        icon: "error",
        confirmButtonText: "OK",
      });
      setSubmiting(false);
    } else {
      if (typeClaim) {
        const errors = [];
        Object.entries(payload).forEach(el => {
          switch (el[0]) {
            case "revenue":
              if (!el[1]) {
                errors.push("Fee");
              }
              break;
            case "invoice_date":
              if (!el[1] || el[1] === "1970-01-01") {
                errors.push("Invoice date");
              } else if (
                el[1] &&
                new Date(el[1]).toString() === "Invalid Date" &&
                el[1] !== "1970-01-01"
              ) {
                errors.push(["Invoice date", "Invalid format"]);
              }
              break;
            case "invoice_number":
              if (!el[1]) {
                errors.push("Invoice number");
              }
              break;
            case "account_number":
              if (
                !el[1] &&
                listClientName.find(el => el.dsp_id === payload.dsp_id)
                  ?.domicile === "overseas"
              ) {
                errors.push("Account number");
              }
              break;
            case "job_number":
              if (
                !el[1] &&
                listClientName.find(el => el.dsp_id === payload.dsp_id)
                  ?.domicile === "overseas"
              ) {
                errors.push("Job number");
              }
              break;
            case "start_date":
              if (el[1] === "-" && payload.end_date === "-") {
                if (!errors.includes("Period")) errors.push("Period");
              }
              break;
            case "end_date":
              if (el[1] === "-" && payload.start_date === "-") {
                if (!errors.includes("Period")) errors.push("Period");
              }
              break;
            case "taxes":
              if (!el[1].length && checkedVal) {
                errors.push("Taxes");
              }
              break;
            default:
              break;
          }
        });
        if (errors?.length) {
          Swal.fire({
            title: "Error",
            text: `${typeof errors[0] === "object"
              ? errors?.[0]?.[0] + " " + errors?.[0]?.[1]
              : errors[0] + " can't be empty."
              }`,
            icon: "error",
            confirmButtonText: "OK",
          });
          setSubmiting(false);
        } else {
          if (checkedVal === true) {
            payload.tax_name = selectedTax.code || "Create Claim Tax";
            payload.tax_value = Number(selectedTax.rate);
          } else {
            payload.revenue = Number(totalRev);
          }
          payload.songs = [];

          axios
            .post(url, payload, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })
            .then(res => {
              if (res.status === 200) {
                setHasChanges(false);
                Swal.fire({
                  title: "Success",
                  text: "Invoice has been created",
                  icon: "success",
                  confirmButtonText: "OK",
                }).then(result => {
                  if (result.value) {
                    window.location.href = "/admin/invoice";
                  }
                });
                setSongList([]);
                setAddSongData([]);
                setSelectedTax([]);
                setSelectedBankAccount(null);
                setJobNumber("");
                setInvoiceNumber("");
                setPoNumber("");
                setInvoiceType(invoiceType);
                setClientName("");
                setMonth1("");
                setMonth2("");
                setYear1("");
                setYear2("");
                setInvoiceDate(new Date());
              }
            })
            .catch(err => {
              setSubmiting(false);
              if (err.response?.data?.errors?.length) {
                Swal.fire({
                  title: "Error",
                  text: err.response?.data?.errors?.[0].message,
                  icon: "error",
                  confirmButtonText: "OK",
                });
              }
            });
        }
      } else {
        const errors = [];
        Object.entries(payload).forEach(el => {
          switch (el[0]) {
            case "revenue":
              if (!el[1]) {
                errors.push("Fee");
              }
              break;
            case "songs":
              if (!el[1].length) {
                errors.push("Song");
              } else {
                el[1].forEach((data, index) => {
                  if (data.revenue === "0" || !data.revenue) {
                    errors.push(`Song fee number ${index + 1}`);
                  } else if (!data.qty) {
                    errors.push(`Song qty number ${index + 1}`);
                  }
                });
              }
              break;
            case "invoice_number":
              if (!el[1]) {
                errors.push("Invoice number");
              }
              break;
            case "account_number":
              if (
                !el[1] &&
                listClientName.find(el => el.dsp_id === payload.dsp_id)
                  ?.domicile === "overseas"
              ) {
                errors.push("Account number");
              }
              break;
            case "job_number":
              if (
                !el[1] &&
                listClientName.find(el => el.dsp_id === payload.dsp_id)
                  ?.domicile === "overseas"
              ) {
                errors.push("Job number");
              }
              break;
            case "start_date":
              if (el[1] === "-" && payload.end_date === "-") {
                if (!errors.includes("Period")) errors.push("Period");
              }
              break;
            case "end_date":
              if (el[1] === "-" && payload.start_date === "-") {
                if (!errors.includes("Period")) errors.push("Period");
              }
              break;
            case "taxes":
              if (!el[1].length && checkedVal) {
                errors.push("Taxes");
              }
              break;
            case "invoice_date":
              if (!el[1] || el[1] === "1970-01-01") {
                errors.push("Invoice date");
              } else if (
                el[1] &&
                new Date(el[1]).toString() === "Invalid Date" &&
                el[1] !== "1970-01-01"
              ) {
                errors.push(["Invoice date", "Invalid format"]);
              }
              break;
            default:
              break;
          }
        });
        if (errors.length) {
          Swal.fire({
            title: "Error",
            text: `${typeof errors[0] === "object"
              ? errors?.[0]?.[0] + " " + errors?.[0]?.[1]
              : errors[0] + " can't be empty."
              }`,
            icon: "error",
            confirmButtonText: "OK",
          });
          setSubmiting(false);
        } else {
          if (checkedVal === true) {
            payload.tax_name = "Create Usage Tax";
          } else {
            payload.revenue = Number(totalRev);
          }
          axios
            .post(url, payload, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })
            .then(res => {
              if (res.status === 200) {
                Swal.fire({
                  title: "Success",
                  text: "Invoice has been created",
                  icon: "success",
                  confirmButtonText: "OK",
                }).then(result => {
                  if (result.value) {
                    window.location.href = "/admin/invoice";
                  }
                });
                setSongList([]);
                setAddSongData([]);
                setInvoiceType(invoiceType);
                setClientName("");
                setMonth1("");
                setMonth2("");
                setYear1("");
                setYear2("");
                setInvoiceDate(new Date());
                setSelectedTax([]);
                setSelectedBankAccount(null);
                setJobNumber("");
                setInvoiceNumber("");
                setPoNumber("");
              }
            })
            .catch(err => {
              if (err.response?.data?.errors?.length) {
                Swal.fire({
                  title: "Error",
                  text: err.response?.data?.errors?.[0].message,
                  icon: "error",
                  confirmButtonText: "OK",
                }).then(result => {
                  if (result.value) {
                    //
                  }
                });
                setSubmiting(false);
              }
            });
        }
      }
    }
  };

  const handleChecked = () => {
    setcheckedVal(prev => {
      return !prev;
    });
  };

  const onChangeSongUsage = useCallback(() => {
    if (invoiceType === "usage") {
      let songArr = songList;
      let arrNum = [];
      songArr.forEach((item, i) => {
        if (typeof item.addRevenue === "string") {
          if (item.addRevenue !== "") {
            let valval = item.addRevenue;
            if (valval.includes(",")) {
              let convNum =
                Number(valval.replaceAll(",", "")) * Number(item.qty);
              arrNum.push(convNum);
              songArr[i].addRevenueNumber = convNum;
            } else {
              let convNum = Number(valval) * Number(item.qty);
              arrNum.push(convNum);
              songArr[i].addRevenueNumber = convNum;
            }
          } else {
            arrNum.push(0);
            songArr[i].addRevenueNumber = 0;
          }
        } else {
          let valval = item.addRevenue * Number(item.qty);
          arrNum.push(valval);
          songArr[i].addRevenueNumber = valval;
        }

        // number
      });
      let resSum = arrNum.reduce((a, b) => a + b, 0);
      let tempTax = [...selectedTax].map(el => {
        return {
          ...el,
          afterCountTax:
            (Number(el.rate) / 100) * (Number(el.tax_bases) / 100) * resSum,
        };
      });
      setSelectedTax(tempTax);
      let newRes = resSum;
      setTotalrev(newRes);

      let resSumAllTaxes =
        Number(
          tempTax.reduce(
            (acc, crrnt) =>
              crrnt?.method === "addition"
                ? acc + Number(crrnt.afterCountTax)
                : acc - Number(crrnt.afterCountTax),
            0
          )
        ) + resSum;
      setTotalAfterTax(resSumAllTaxes);
    }
  }, [songList, invoiceType]);
  const onChangeQtySong = (e, index) => {
    const { value } = e?.target;
    let tempVal = [...songList];
    const arrNum = [];
    tempVal[index].qty = value;
    tempVal.forEach((item, i) => {
      if (typeof item.addRevenue === "string") {
        if (item.addRevenue !== "") {
          let valval = item.addRevenue;
          if (valval.includes(",")) {
            let convNum = Number(valval.replaceAll(",", "")) * Number(item.qty);
            arrNum.push(convNum);
            tempVal[i].addRevenueNumber = convNum;
          } else {
            let convNum = Number(valval) * Number(item.qty);
            arrNum.push(convNum);
            tempVal[i].addRevenueNumber = convNum;
          }
        } else {
          arrNum.push(0);
          tempVal[i].addRevenueNumber = 0;
        }
        setSongList(tempVal);
      } else {
        let valval = item.addRevenue * Number(item.qty);
        arrNum.push(valval);
        tempVal[i].addRevenueNumber = valval;
      }
    });
    const sumAllRevenue = arrNum.reduce((a, b) => a + b, 0);
    setTotalrev(sumAllRevenue);
    let tempTax = [...selectedTax].map(el => {
      return {
        ...el,
        afterCountTax:
          (Number(el.rate) / 100) *
          (Number(el.tax_bases) / 100) *
          arrNum.reduce((a, b) => a + b, 0),
      };
    });
    setSelectedTax(tempTax);
    setSongList(tempVal);
    const sumAllTaxes =
      Number(
        tempTax.reduce(
          (acc, crrnt) =>
            crrnt?.method === "addition"
              ? acc + Number(crrnt.afterCountTax)
              : acc - Number(crrnt.afterCountTax),
          0
        )
      ) + sumAllRevenue;
    setTotalAfterTax(sumAllTaxes);
  };

  const onChangePercentageSong = (e, index, idx) => {
    const { value } = e?.target;
    setSongList(prevSongList => {
      const updatedSongList = [...prevSongList];
      updatedSongList[index].composers[idx].publisher_share_percentage = Number(
        value
      );
      return updatedSongList;
    });
  };
  useEffect(() => {
    onChangeSongUsage();
  }, [onChangeSongUsage]);
  const onChangeMoney = (e, index) => {
    let newVal = e.target.value;
    let tempVal = [...songList];
    tempVal[index].addRevenue = newVal;
    setSongList(tempVal);
    let arrNum = [];
    tempVal.forEach((item, i) => {
      if (typeof item.addRevenue === "string") {
        if (item.addRevenue !== "") {
          let valval = item.addRevenue;
          if (valval.includes(",")) {
            let convNum = Number(valval.replaceAll(",", "")) * Number(item.qty);
            arrNum.push(convNum);
            tempVal[i].addRevenueNumber = convNum;
          } else {
            let convNum = Number(valval) * Number(item.qty);
            arrNum.push(convNum);
            tempVal[i].addRevenueNumber = convNum;
          }
        } else {
          arrNum.push(0);
          tempVal[i].addRevenueNumber = 0;
        }
        setSongList(tempVal);
      } else {
        let valval = item.addRevenue * Number(item.qty);
        arrNum.push(valval);
        tempVal[i].addRevenueNumber = valval;
      }
    });
    setSongList(tempVal);
    let resSum = arrNum.reduce((a, b) => a + b, 0);
    let tempTax = [...selectedTax].map(el => {
      return {
        ...el,
        afterCountTax:
          (Number(el.rate) / 100) * (Number(el.tax_bases) / 100) * resSum,
      };
    });
    setSelectedTax(tempTax);
    let newRes = resSum;
    setTotalrev(newRes);

    let resSumAllTaxes =
      Number(
        tempTax.reduce(
          (acc, crrnt) =>
            crrnt?.method === "addition"
              ? acc + Number(crrnt.afterCountTax)
              : acc - Number(crrnt.afterCountTax),
          0
        )
      ) + resSum;
    setTotalAfterTax(resSumAllTaxes);
  };
  const changeNotes = (e, index) => {
    let newVal = e;
    let tempVal = songList;
    tempVal[index].addNotes = newVal;
    setSongList(tempVal);
  };
  const onAddSong = () => {
    setSongDialog(false);
  };

  const handleCloseImportDialog = () => setOpenDialog(false);
  const handleCloseImportResult = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpenResult(false);
    }
  };

  const valuePeriod = () => {
    const emptyStart = month1 === "" || year1 === "";
    const emptyEnd = month2 === "" || year2 === "";
    const formatDate = date => moment(date).format("DD/MM/YYYY");

    if (typeClaim) {
      return `${emptyStart ? "" : month1 + "/" + year1} - ${emptyEnd ? "" : month2 + "/" + year2
        }`;
    } else {
      return `${periodStartDate ? formatDate(periodStartDate) : ""} - ${periodEndDate ? formatDate(periodEndDate) : ""
        }`;
    }
  };

  useEffect(() => {
    const handleBeforeUnload = event => {
      if (hasChanges) {
        const message =
          "You have unsaved changes. Are you sure you want to leave?";
        event.returnValue = message;
        return message;
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [hasChanges]);

  return (
    <Page className={classes.root} title="Create Invoice">
      <Backdrop className={classes.backdrop} open={loadingDelete}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container maxWidth={false}>
        <Grid
          alignItems="flex-end"
          container
          justifyContent="space-between"
          spacing={3}
        >
          <Grid item>
            <ThemeProvider theme={theme}>
              <Typography
                style={{
                  fontSize: "24px",
                  fontWeight: "700",
                  lineHeight: "32px",
                }}
              >
                Invoice{" "}
              </Typography>
            </ThemeProvider>
          </Grid>
          <Grid item>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography className={classes.breadCrumbs}>Home</Typography>

              <Typography className={classes.breadCrumbs}>
                Transaction
              </Typography>

              <Typography className={classes.breadCrumbsActive}>
                Create Transaction
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>

        <Divider className={classes.divider} />

        <Grid container>
          <div>
            <ThemeProvider theme={theme}>
              <p
                style={{
                  marginTop: "5px",
                  color: "#8f8f8f",
                  fontSize: "14px",
                }}
              >
                Create your transaction here.
              </p>
            </ThemeProvider>
          </div>
        </Grid>

        <Grid container>
          <Grid item xs={12} md={4} lg={4} xl={4}>
            <div
              style={{
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              <ThemeProvider theme={theme}>
                <Typography className={classes.label}>Invoice Type</Typography>
              </ThemeProvider>
            </div>

            <div
              style={{
                marginTop: "-10px",
                marginBottom: "10.5px",
              }}
            >
              <FormControl variant="outlined" fullWidth={true}>
                <Select
                  className={classes.mypadding}
                  id="demo-simple-select-outlined"
                  value={invoiceType}
                  onChange={handleChangeInvoiceType}
                  autoWidth={true}
                  style={{
                    paddingTop: "0px",
                    paddingRight: "0px",
                    paddingBottom: "0px",
                    paddingLeft: "0px",
                  }}
                >
                  {listInvoiceType?.map((item, idx) => (
                    <MenuItem value={item} key={idx}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </Grid>
          <Grid item xs={12} md={2} lg={2} xl={2}></Grid>
          <Grid item xs={12} md={4} lg={4} xl={4}>
            <div
              style={{
                marginTop: "10px",
                marginBottom: "10px",
                marginLeft: "13px",
                display: "none",
              }}
            >
              <ThemeProvider theme={theme}>
                <Typography className={classes.label}>Client Type</Typography>
              </ThemeProvider>
            </div>

            <div
              style={{
                marginTop: "-10px",
                marginBottom: "10.5px",
                marginLeft: "13px",
                display: "none",
              }}
            >
              <FormControl variant="outlined" fullWidth={true}>
                <Select
                  className={classes.mypadding}
                  id="demo-simple-select-outlined"
                  value={clientType}
                  onChange={handleChangeClientType}
                  autoWidth={true}
                  displayEmpty={true}
                  style={{
                    paddingTop: "0px",
                    paddingRight: "0px",
                    paddingBottom: "0px",
                    paddingLeft: "0px",
                  }}
                >
                  <MenuItem value="">
                    <>{"Client Type"}</>
                  </MenuItem>
                  <MenuItem value="domestic">
                    <>{"Domestic"}</>
                  </MenuItem>
                  <MenuItem value="foreign">
                    <>{"Foreign"}</>
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12} md={12} lg={12} xl={12}>
            <Card
              style={{
                border: "1px solid #9AA2B1",
                borderRadius: "6px",
                boxShadow: "none",
              }}
            >
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6} lg={6} xl={6}>
                    <div
                      style={{
                        marginTop: "5px",
                        marginBottom: "5px",
                      }}
                    >
                      <ThemeProvider theme={theme}>
                        <Typography
                          variant="subtitle2"
                          className={classes.label}
                        >
                          Invoice Number{" "}
                          <span className={classes.required}>*</span>
                        </Typography>
                      </ThemeProvider>
                      <div
                        style={{
                          marginTop: "-10px",
                          marginBottom: "10px",
                        }}
                      >
                        <TextField
                          id="outlined-basic"
                          name="name"
                          variant="outlined"
                          fullWidth={true}
                          margin="dense"
                          value={invoiceNumber}
                          onChange={event => {
                            setHasChanges(true);
                            setInvoiceNumber(event.target.value);
                          }}
                          placeholder="Invoice Number"
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        marginTop: "5px",
                        marginBottom: "5px",
                      }}
                    >
                      <ThemeProvider theme={theme}>
                        <Typography
                          variant="subtitle2"
                          className={classes.label}
                        >
                          Invoice Date{" "}
                          <span className={classes.required}>*</span>
                        </Typography>
                      </ThemeProvider>
                      <div
                        style={{
                          marginTop: "-10px",
                          marginBottom: "10px",
                        }}
                      >
                        <KeyboardDatePicker
                          id="outlined-basic"
                          variant="outlined"
                          onChange={handleInvoiceDateChange}
                          value={invoiceDate}
                          placeholder="Invoice Date"
                          name="startDate"
                          format="DD-MM-YYYY"
                          minDate={new Date()}
                          inputVariant="outlined"
                          fullWidth={true}
                          margin="dense"
                          keyboardIcon={
                            <img
                              src={datePicIcon}
                              alt="filter"
                              style={{
                                width: "20px",
                                height: "20px",
                                marginRight: "-10px",
                              }}
                            />
                          }
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        marginTop: "34px",
                        display: "flex",
                        justifyContent: "end",
                      }}
                    >
                      <div
                        style={{
                          height: "38px",
                          paddingLeft: "10px",
                          paddingRight: "10px",
                        }}
                      />
                    </div>
                    <div
                      style={{
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      <ThemeProvider theme={theme}>
                        <Typography className={classes.label}>
                          Usage Period{" "}
                          <span className={classes.required}>*</span>
                        </Typography>
                      </ThemeProvider>
                    </div>
                    <div
                      style={{
                        marginTop: "-10px",
                        marginBottom: "10.5px",
                      }}
                    >
                      <FormControl
                        fullWidth
                        className={classes.margin}
                        variant="outlined"
                      >
                        <React.Fragment>
                          <OutlinedInput
                            variant="outlined"
                            aria-controls="invoice-period"
                            aria-haspopup="true"
                            size="small"
                            value={valuePeriod()}
                            onClick={clickOpenInvoiceDate}
                            readOnly
                            className={classes.mypadding}
                            style={{ height: "39px" }}
                            endAdornment={
                              <InputAdornment position="end">
                                <div>
                                  <img
                                    src={datePicIcon}
                                    alt="filterperiode"
                                    style={{
                                      width: "20px",
                                      height: "20px",
                                    }}
                                    onClick={clickOpenInvoiceDate}
                                  />
                                </div>
                              </InputAdornment>
                            }
                          />
                          <Menu
                            id="invoice-period"
                            // anchorEl={openInvoiceDate}
                            keepMounted={false}
                            style={{ cursor: "pointer" }}
                            open={Boolean(openInvoiceDate)}
                            onClose={handleCloseMenuInvoiceDate}
                            PaperProps={{
                              style: {
                                transform:
                                  "translateX(700px) translateY(150px)",
                              },
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <Container style={{ width: "300px" }}>
                                <div
                                  style={{
                                    marginTop: "10px",
                                    marginBottom: "10px",
                                  }}
                                >
                                  <ThemeProvider theme={theme}>
                                    <Typography className={classes.label}>
                                      Start Period
                                    </Typography>
                                  </ThemeProvider>
                                  {typeClaim ? (
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        marginTop: "10px",
                                      }}
                                    >
                                      <div>
                                        <ThemeProvider theme={theme}>
                                          <Typography className={classes.label}>
                                            Month
                                          </Typography>
                                        </ThemeProvider>
                                        <FormControl
                                          variant="outlined"
                                          fullWidth={true}
                                        >
                                          <InputLabel
                                            id="demo-simple-select-outlined-label"
                                            shrink={false}
                                            style={{
                                              marginRight: "-5px",
                                              marginTop: "-8px",
                                            }}
                                          >
                                            {month1 !== "" ? null : "Month"}
                                          </InputLabel>
                                          <Select
                                            className={classes.mypadding}
                                            id="demo-simple-select-outlined-month1"
                                            name="month1"
                                            value={month1}
                                            onChange={e => {
                                              setHasChanges(true);
                                              handleChangeMonthStart(e);
                                            }}
                                            autoWidth={true}
                                            style={{
                                              paddingTop: "0px",
                                              paddingRight: "0px",
                                              paddingBottom: "0px",
                                              paddingLeft: "0px",
                                              width: "120px",
                                            }}
                                          >
                                            <MenuItem value="">
                                              <em>None</em>
                                            </MenuItem>
                                            {monthList.map((item, idx) => (
                                              <MenuItem
                                                value={item.value}
                                                key={idx}
                                              >
                                                {item.name}
                                              </MenuItem>
                                            ))}
                                          </Select>
                                        </FormControl>
                                      </div>
                                      <div>
                                        <ThemeProvider theme={theme}>
                                          <Typography className={classes.label}>
                                            Year
                                          </Typography>
                                        </ThemeProvider>
                                        <FormControl
                                          variant="outlined"
                                          fullWidth={true}
                                        >
                                          <InputLabel
                                            id="demo-simple-select-outlined-label"
                                            shrink={false}
                                            style={{
                                              marginRight: "-5px",
                                              marginTop: "-8px",
                                            }}
                                          >
                                            {year1 !== "" ? null : "Year"}
                                          </InputLabel>
                                          <Select
                                            className={classes.mypadding}
                                            id="demo-simple-select-outlined-year1"
                                            name="year1"
                                            value={year1}
                                            onChange={e => {
                                              setHasChanges(true);
                                              handleChangeYearStart(e);
                                            }}
                                            autoWidth={true}
                                            style={{
                                              paddingTop: "0px",
                                              paddingRight: "0px",
                                              paddingBottom: "0px",
                                              paddingLeft: "0px",
                                              width: "120px",
                                            }}
                                          >
                                            <MenuItem value="">
                                              <em>None</em>
                                            </MenuItem>
                                            {yearList.map((item, idx) => (
                                              <MenuItem
                                                value={Number(item)}
                                                key={idx}
                                              >
                                                {item}
                                              </MenuItem>
                                            ))}
                                          </Select>
                                        </FormControl>
                                      </div>
                                    </div>
                                  ) : (
                                    <KeyboardDatePicker
                                      id="outlined-basic"
                                      variant="outlined"
                                      onChange={handleChangeUsageStart}
                                      value={periodStartDate}
                                      placeholder="Start Period"
                                      name="startDate"
                                      format="DD-MM-YYYY"
                                      inputVariant="outlined"
                                      fullWidth={true}
                                      margin="dense"
                                      keyboardIcon={
                                        <img
                                          src={datePicIcon}
                                          alt="filter"
                                          style={{
                                            width: "20px",
                                            height: "20px",
                                            marginRight: "-10px",
                                          }}
                                        />
                                      }
                                    />
                                  )}
                                </div>

                                <div>
                                  <ThemeProvider theme={theme}>
                                    <Typography className={classes.label}>
                                      End Period
                                    </Typography>
                                  </ThemeProvider>
                                  {typeClaim ? (
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        marginTop: "10px",
                                        marginBottom: "10px",
                                      }}
                                    >
                                      <div>
                                        <ThemeProvider theme={theme}>
                                          <Typography className={classes.label}>
                                            Month
                                          </Typography>
                                        </ThemeProvider>
                                        <FormControl
                                          variant="outlined"
                                          fullWidth={true}
                                        >
                                          <InputLabel
                                            id="demo-simple-select-outlined-label"
                                            shrink={false}
                                            style={{
                                              marginRight: "-5px",
                                              marginTop: "-8px",
                                            }}
                                          >
                                            {month2 !== "" ? null : "Month"}
                                          </InputLabel>
                                          <Select
                                            className={classes.mypadding}
                                            id="demo-simple-select-outlined-month2"
                                            name="month2"
                                            value={month2}
                                            onChange={e => {
                                              setHasChanges(true);
                                              handleChangeMonthEnd(e);
                                            }}
                                            autoWidth={true}
                                            style={{
                                              paddingTop: "0px",
                                              paddingRight: "0px",
                                              paddingBottom: "0px",
                                              paddingLeft: "0px",
                                              width: "120px",
                                            }}
                                          >
                                            <MenuItem value="">
                                              <em>None</em>
                                            </MenuItem>
                                            {monthList.map((item, idx) => (
                                              <MenuItem
                                                value={item.value}
                                                key={idx}
                                              >
                                                {item.name}
                                              </MenuItem>
                                            ))}
                                          </Select>
                                        </FormControl>
                                      </div>
                                      <div>
                                        <ThemeProvider theme={theme}>
                                          <Typography className={classes.label}>
                                            Year
                                          </Typography>
                                        </ThemeProvider>
                                        <FormControl
                                          variant="outlined"
                                          fullWidth={true}
                                        >
                                          <InputLabel
                                            id="demo-simple-select-outlined-label"
                                            shrink={false}
                                            style={{
                                              marginRight: "-5px",
                                              marginTop: "-8px",
                                            }}
                                          >
                                            {year2 !== "" ? null : "Year"}
                                          </InputLabel>
                                          <Select
                                            className={classes.mypadding}
                                            id="demo-simple-select-outlined-year2"
                                            name="year2"
                                            value={year2}
                                            onChange={e => {
                                              setHasChanges(true);
                                              handleChangeYearEnd(e);
                                            }}
                                            autoWidth={true}
                                            style={{
                                              paddingTop: "0px",
                                              paddingRight: "0px",
                                              paddingBottom: "0px",
                                              paddingLeft: "0px",
                                              width: "120px",
                                            }}
                                          >
                                            <MenuItem value="">
                                              <em>None</em>
                                            </MenuItem>
                                            {yearList.map((item, idx) => (
                                              <MenuItem value={item} key={idx}>
                                                {item}
                                              </MenuItem>
                                            ))}
                                          </Select>
                                        </FormControl>
                                      </div>
                                    </div>
                                  ) : (
                                    <KeyboardDatePicker
                                      id="outlined-basic"
                                      variant="outlined"
                                      onChange={handleChangeUsageEnd}
                                      value={periodEndDate}
                                      placeholder="End Period"
                                      name="endDate"
                                      format="DD-MM-YYYY"
                                      // minDate={new Date()}
                                      inputVariant="outlined"
                                      fullWidth={true}
                                      margin="dense"
                                      keyboardIcon={
                                        <img
                                          src={datePicIcon}
                                          alt="filter"
                                          style={{
                                            width: "20px",
                                            height: "20px",
                                            marginRight: "-10px",
                                          }}
                                        />
                                      }
                                    />
                                  )}
                                </div>
                              </Container>
                            </div>
                          </Menu>
                        </React.Fragment>
                      </FormControl>
                    </div>
                    {clientName &&
                      listClientName?.find(el => el.dsp_id === clientName)
                        ?.domicile === "overseas" && (
                        <div>
                          <div
                            style={{
                              marginTop: "10px",
                              marginBottom: "10px",
                            }}
                          >
                            <ThemeProvider theme={theme}>
                              <Typography className={classes.label}>
                                Job No{" "}
                                <span className={classes.required}>*</span>
                              </Typography>
                            </ThemeProvider>
                          </div>

                          <div
                            style={{
                              marginTop: "-10px",
                              marginBottom: "10.5px",
                            }}
                          >
                            <FormControl fullWidth variant="outlined">
                              <OutlinedInput
                                id="outlined-adornment-amount"
                                style={{
                                  height: "38px",
                                  borderRadius: "6px",
                                }}
                                value={jobNumber}
                                onChange={event => {
                                  setHasChanges(true);
                                  setJobNumber(event.target.value);
                                }}
                              />
                            </FormControl>
                          </div>
                        </div>
                      )}
                  </Grid>

                  <Grid item xs={12} md={6} lg={6} xl={6}>
                    <div
                      style={{
                        marginTop: "5px",
                        marginBottom: "5px",
                      }}
                    >
                      <ThemeProvider theme={theme}>
                        <Typography
                          variant="subtitle2"
                          className={classes.label}
                        >
                          PO Number
                        </Typography>
                      </ThemeProvider>
                      <div
                        style={{
                          marginTop: "-10px",
                          marginBottom: "10px",
                        }}
                      >
                        <TextField
                          id="outlined-basic"
                          name="name"
                          variant="outlined"
                          fullWidth={true}
                          margin="dense"
                          value={poNumber}
                          onChange={event => {
                            setHasChanges(true);
                            setPoNumber(event.target.value);
                          }}
                          placeholder="PO Number"
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        marginTop: "4px",
                        marginBottom: "0px",
                      }}
                    >
                      <div
                        style={{
                          marginTop: "0px",
                          marginBottom: "0px",
                        }}
                      >
                        <div
                          style={{
                            marginTop: "0px",
                            marginBottom: "0px",
                          }}
                        >
                          <ThemeProvider theme={theme}>
                            <Typography className={classes.label}>
                              Client Name{" "}
                              <span className={classes.required}>*</span>
                            </Typography>
                          </ThemeProvider>
                        </div>

                        <div
                          style={{
                            marginTop: "0px",
                            marginBottom: "0px",
                          }}
                        >
                          <FormControl variant="outlined" fullWidth={true}>
                            <InputLabel
                              id="demo-simple-select-outlined-label"
                              shrink={false}
                              style={{
                                marginRight: "-5px",
                                marginTop: "-8px",
                              }}
                            >
                              {clientName !== "" ? null : "Client Name"}
                            </InputLabel>
                            <Select
                              className={classes.mypadding}
                              id="demo-simple-select-outlined"
                              value={clientName}
                              onChange={handleChangeSelectClientName}
                              autoWidth={true}
                              style={{
                                paddingTop: "0px",
                                paddingRight: "0px",
                                paddingBottom: "0px",
                                paddingLeft: "0px",
                              }}
                            >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {listClientName.map((item, idx) => (
                                <MenuItem value={item.dsp_id} key={idx}>
                                  {item.dsp_name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                      <div
                        style={{
                          marginTop: "34px",
                          display: "flex",
                          justifyContent: "end",
                        }}
                      >
                        <Button
                          id="add-song-outer"
                          style={{
                            backgroundColor: "#111827",
                            borderRadius: "6px",
                            height: "38px",
                            color: "#FFFFFF",
                            textTransform: "none",
                            paddingLeft: "10px",
                            paddingRight: "10px",
                          }}
                          onClick={clickAddClient}
                          endIcon={<AddIcon />}
                        >
                          Add Client
                        </Button>
                      </div>

                      {clientName &&
                        listClientName?.find(el => el?.dsp_id === clientName)
                          ?.domicile === "overseas" && (
                          <div
                            style={{
                              marginTop: "14px",
                            }}
                          >
                            {isForeignCurrency && (
                              <div>
                                <ThemeProvider theme={theme}>
                                  <Typography className={classes.label}>
                                    Exchange Rate{" "}
                                    <span className={classes.required}>*</span>
                                  </Typography>
                                </ThemeProvider>

                                <FormControl
                                  variant="outlined"
                                  fullWidth={true}
                                >
                                  <Grid style={{ display: "flex" }}>
                                    <Grid
                                      style={{
                                        width: "48px",
                                        height: "37px",
                                        marginRight: "-2px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        borderRadius: "6px 0px 0px 6px",
                                        backgroundColor: "#F9FAFB",
                                        border: " 1px solid #D1D5DC",
                                        color: "#9AA2B",
                                        borderRight: "none",
                                      }}
                                    >
                                      Rp
                                    </Grid>
                                    <NumberFormat
                                      disabled={exchangeRate === 0}
                                      customInput={TextField}
                                      variant="outlined"
                                      thousandSeparator={true}
                                      onChange={e => {
                                        setHasChanges(true);
                                        setExchangeRate(e.target.value);
                                      }}
                                      autoComplete="off"
                                      className={classes.inputNum}
                                      style={{
                                        paddingLeft: "0px",
                                        height: "44px",
                                      }}
                                      size="small"
                                      fullWidth
                                      value={exchangeRate}
                                    />
                                  </Grid>
                                </FormControl>
                              </div>
                            )}
                            <div
                              style={{
                                marginTop: "0px",
                                marginBottom: "0px",
                                // display: 'none',
                              }}
                            >
                              <div
                                style={{
                                  marginTop: "10px",
                                  marginBottom: "0px",
                                }}
                              >
                                <ThemeProvider theme={theme}>
                                  <Typography className={classes.label}>
                                    Account Number{" "}
                                    <span className={classes.required}>*</span>
                                  </Typography>
                                </ThemeProvider>
                              </div>

                              <div
                                style={{
                                  marginTop: "0px",
                                  marginBottom: "0px",
                                }}
                              >
                                <FormControl
                                  variant="outlined"
                                  // className={classes.formControl}
                                  fullWidth={true}
                                  style={
                                    {
                                      // paddingTop: "0px",
                                    }
                                  }
                                >
                                  <InputLabel
                                    id="demo-simple-select-outlined-label"
                                    shrink={false}
                                    style={{
                                      marginRight: "-5px",
                                      marginTop: "-8px",
                                    }}
                                  >
                                    {selectedBankAccount ? null : "Account No"}
                                  </InputLabel>
                                  <Select
                                    className={classes.mypadding}
                                    id="demo-simple-select-outlined"
                                    value={selectedBankAccount}
                                    onChange={e => {
                                      setHasChanges(true);
                                      setSelectedBankAccount(e.target.value);
                                    }}
                                    autoWidth={true}
                                    renderValue={value => (
                                      <Box
                                        display={"flex"}
                                        alignItems={"center"}
                                        justifyContent="space-between"
                                        sx={{
                                          "& > img": { mr: 2, flexShrink: 0 },
                                          minWidth: "480px",
                                        }}
                                      >
                                        <Box
                                          style={{
                                            display: "flex",
                                            gap: "0 10px",
                                            alignItems: "center",
                                          }}
                                        >
                                          <img
                                            width="48px"
                                            src={value?.bank?.profile_image}
                                            alt={value?.bank?.name || "Bank"}
                                            loading={"lazy"}
                                          ></img>
                                          <Typography>
                                            {value?.bank?.name}
                                          </Typography>
                                        </Box>
                                        <Box>
                                          <Typography>
                                            {value?.account_number}
                                          </Typography>
                                        </Box>
                                      </Box>
                                    )}
                                    style={{
                                      paddingTop: "0px",
                                      paddingRight: "0px",
                                      paddingBottom: "0px",
                                      paddingLeft: "0px",
                                    }}
                                  >
                                    <MenuItem value="">
                                      <em>None</em>
                                    </MenuItem>
                                    {listBankAccount?.map((item, idx) => (
                                      <MenuItem value={item} key={idx}>
                                        <Box
                                          display={"flex"}
                                          alignItems={"center"}
                                          justifyContent="space-between"
                                          sx={{
                                            "& > img": {
                                              mr: 2,
                                              flexShrink: 0,
                                            },
                                            minWidth: "480px",
                                          }}
                                        >
                                          <Box
                                            style={{
                                              display: "flex",
                                              gap: "0 10px",
                                            }}
                                          >
                                            <img
                                              width="48px"
                                              src={item?.bank?.profile_image}
                                              loading={"lazy"}
                                              alt={item?.bank?.name || "Bank"}
                                            ></img>
                                            <Typography>
                                              {item?.bank?.name}
                                            </Typography>
                                          </Box>
                                          <Box>
                                            <Typography>
                                              {item?.account_number}
                                            </Typography>
                                          </Box>
                                        </Box>
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </div>
                            </div>
                          </div>
                        )}
                    </div>
                  </Grid>
                </Grid>
                {typeClaim ? (
                  <div>
                    <Typography
                      style={{
                        fontSize: "16px",
                        fontWeight: "700",
                        lineHeight: "24px",
                        margin: "20px 0",
                      }}
                    >
                      Song Detail
                    </Typography>
                  </div>
                ) : (
                  <>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: "16px",
                          fontWeight: "700",
                          lineHeight: "24px",
                        }}
                      >
                        Song Detail
                      </Typography>
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        {resultUploadSong !== null ? (
                          <>
                            <Button
                              id="result-song-upload"
                              style={{
                                display: "block",
                                marginLeft: "10px",
                                backgroundColor: "#111827",
                                borderRadius: "6px",
                                height: "40px",
                                color: "#FFFFFF",
                                marginBottom: "10px",
                                textTransform: "none",
                              }}
                              onClick={clickOpenResult}
                            >
                              Result
                            </Button>
                          </>
                        ) : null}

                        <Button
                          id="add-song-outer-upload"
                          style={{
                            display: "block",
                            marginLeft: "10px",
                            backgroundColor: "#111827",
                            borderRadius: "6px",
                            height: "40px",
                            color: "#FFFFFF",
                            marginBottom: "10px",
                            textTransform: "none",
                          }}
                          onClick={clickOpenUploadSongDialog}
                        >
                          Bulk Add
                        </Button>

                        <Button
                          id="add-song-outer"
                          style={{
                            marginLeft: "10px",
                            backgroundColor: "#111827",
                            borderRadius: "6px",
                            height: "40px",
                            color: "#FFFFFF",
                            marginBottom: "10px",
                            textTransform: "none",
                          }}
                          onClick={clickOpenSongDialog}
                        >
                          Single Add
                        </Button>
                      </div>
                    </div>
                  </>
                )}
                <DialogUploadSong
                  open={openDialog}
                  onClose={handleCloseImportDialog}
                  getInputProps={getInputProps}
                  getRootProps={getRootProps({ style })}
                  isDragActive={isDragActive}
                  files={files}
                  onSubmit={handleImportDokumen}
                  handleRemoveFile={onRemoveFile}
                />
                <DialogResult
                  open={openResult}
                  object={resultUploadSong}
                  tableErr={[]}
                  onClose={handleCloseImportResult}
                />
                <div>
                  <Dialog
                    open={songDialog}
                    onClose={handleCloseSongDialog}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    fullWidth
                    maxWidth="lg"
                  >
                    <DialogTitle id="simple-modal-title">
                      <div>
                        <OutlinedInput
                          id="outlined-adornment-amount"
                          fullWidth={true}
                          name="phone"
                          placeholder="Search"
                          className={classes.tlpnField}
                          style={{
                            width: "200px",
                          }}
                          type="text"
                          onChange={event => handleSearchKey(event)}
                          value={searchKey}
                          margin="dense"
                          startAdornment={
                            <SearchIcon style={{ color: "#9AA2B1" }} />
                          }
                        />
                      </div>
                    </DialogTitle>
                    <DialogContent>
                      <Table size="small" id="pop-up-song">
                        <TableHead>
                          <TableRow>
                            <ThemeProvider theme={theme}>
                              <TableCell></TableCell>
                              <TableCell>
                                <Typography
                                  variant="subtitle2"
                                  className={classes.headText}
                                >
                                  Song
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  variant="subtitle2"
                                  className={classes.headText}
                                >
                                  Composer/Author
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  variant="subtitle2"
                                  className={classes.headText}
                                >
                                  Alias Name
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  variant="subtitle2"
                                  className={classes.headText}
                                >
                                  Type
                                </Typography>
                              </TableCell>
                            </ThemeProvider>
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          {addSongData !== null ? (
                            addSongData?.length > 0 ? (
                              addSongData?.map(customer => (
                                <TableRow hover key={customer.song_id}>
                                  <TableCell>
                                    <Checkbox
                                      id={`checkBox-${customer.song_id}`}
                                      onChange={event => {
                                        setHasChanges(true);
                                        handleChangeTableCheckbox(
                                          event,
                                          customer.song_id
                                        );
                                      }}
                                      color="primary"
                                      inputProps={{
                                        "aria-label": "secondary checkbox",
                                      }}
                                      checked={customer.checked}
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <Typography
                                      variant="subtitle2"
                                      className={classes.bodyTextGrey}
                                    >
                                      {customer.song_title}
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography
                                      variant="subtitle2"
                                      className={classes.bodyTextBlack}
                                    >
                                      {myGet(customer.composer_names)}
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography
                                      variant="subtitle2"
                                      className={classes.bodyTextBlack}
                                    >
                                      {myGetAliasName(customer.composers)}
                                    </Typography>
                                  </TableCell>

                                  <TableCell>
                                    <Typography
                                      variant="subtitle2"
                                      className={classes.bodyTextBlack}
                                    >
                                      <FormControl
                                        variant="outlined"
                                        fullWidth={true}
                                      >
                                        <Select
                                          className={classes.mypadding}
                                          id="demo-simple-select-outlined"
                                          name="year2"
                                          onChange={e =>
                                            handleChangeTableType(
                                              e,
                                              customer.song_id
                                            )
                                          }
                                          autoWidth={true}
                                          value={customer.choosenType}
                                          style={{
                                            paddingTop: "0px",
                                            paddingRight: "0px",
                                            paddingBottom: "0px",
                                            paddingLeft: "0px",
                                            width: "120px",
                                          }}
                                        >
                                          {customer.type.map((item, idx) => (
                                            <MenuItem value={item} key={idx}>
                                              {item}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </FormControl>
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                              ))
                            ) : (
                              <TableRow>
                                <TableCell colSpan={typeClaim ? 3 : 4}>
                                  <center>
                                    <ThemeProvider theme={theme}>
                                      <Typography
                                        variant="h6"
                                        style={{
                                          fontWeight: 400,
                                          color: "#687083",
                                          fontSize: "14px",
                                        }}
                                      >
                                        There&apos;s no data available.
                                      </Typography>
                                    </ThemeProvider>
                                  </center>
                                </TableCell>
                              </TableRow>
                            )
                          ) : (
                            <TableRow>
                              <TableCell colSpan={typeClaim ? 3 : 4}>
                                <center>
                                  <ThemeProvider theme={theme}>
                                    <Typography
                                      variant="h6"
                                      style={{
                                        fontWeight: 400,
                                        color: "#687083",
                                        fontSize: "14px",
                                      }}
                                    >
                                      There&apos;s no data available.
                                    </Typography>
                                  </ThemeProvider>
                                </center>
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </DialogContent>
                    <DialogActions
                      style={{
                        display: "block",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          paddingLeft: "10px",
                          paddingRight: "10px",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <div style={{ marginTop: "7px" }}>
                            <ThemeProvider theme={theme}>
                              <Typography className={classes.paginationText}>
                                Show:
                              </Typography>
                            </ThemeProvider>
                          </div>{" "}
                          <div style={{ marginLeft: "5px" }}>
                            {pageSizePagination()}
                          </div>
                          <div style={{ marginTop: "7px", marginLeft: "5px" }}>
                            <ThemeProvider theme={theme}>
                              <Typography className={classes.paginationText}>
                                Items
                              </Typography>
                            </ThemeProvider>
                          </div>{" "}
                        </div>
                        <div
                          style={{
                            display: "flex",
                          }}
                        >
                          <div
                            style={{
                              marginRight: "10px",
                              marginTop: "7px",
                            }}
                          >
                            <p>{getPageNumber()}</p>
                          </div>
                          <div>
                            <button
                              className={classes.paginationButton}
                              style={{ cursor: "pointer" }}
                              onClick={e => handlePrevPage(e)}
                              disabled={page === 1 ? true : false}
                            >
                              <ArrowBackIosIcon style={{ fontSize: "10px" }} />
                            </button>
                            <button
                              className={classes.paginationButton}
                              style={{ cursor: "pointer" }}
                              onClick={e => handleNextPage(e)}
                              disabled={page === pageCount ? true : false}
                            >
                              <ArrowForwardIosIcon
                                style={{ fontSize: "10px" }}
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    </DialogActions>
                    <DialogActions>
                      <div
                        style={{
                          display: "block",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            paddingRight: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                            }}
                          ></div>

                          <div style={{ display: "flex" }}>
                            <Button
                              style={{
                                borderRadius: "6px",
                                width: "84px",
                                height: "40px",
                                border: "1px solid #D1D5DC",
                                textTransform: "none",
                              }}
                              onClick={handleCloseSongDialog}
                            >
                              Cancel
                            </Button>
                            <Button
                              id="add-song-popup"
                              style={{
                                marginLeft: "10px",
                                backgroundColor: "#111827",
                                borderRadius: "6px",
                                height: "40px",
                                color: "#FFFFFF",
                                textTransform: "none",
                              }}
                              onClick={() => onAddSong()}
                            >
                              Single Add
                            </Button>
                          </div>
                        </div>
                      </div>
                    </DialogActions>
                  </Dialog>
                </div>
                {typeClaim ? (
                  <>
                    <Card
                      id="container-claim"
                      style={{
                        border: "1px solid #9AA2B1",
                        borderRadius: "6px",
                        boxShadow: "none",
                        marginBottom: "10px",
                      }}
                    >
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <ThemeProvider theme={theme}>
                              <TableCell>
                                <Typography
                                  variant="subtitle2"
                                  className={classes.headText}
                                >
                                  Fee{" "}
                                  <span className={classes.required}>*</span>
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  variant="subtitle2"
                                  className={classes.headText}
                                >
                                  Notes
                                </Typography>
                              </TableCell>
                            </ThemeProvider>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell>
                              <div
                                style={{
                                  marginTop: "0px",
                                  marginBottom: "0px",
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <Grid
                                  style={{
                                    display: "flex",
                                    marginTop: "6px",
                                  }}
                                >
                                  <Grid
                                    style={{
                                      width: "48px",
                                      height: "38px",
                                      marginRight: "-2px",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      borderRadius: "6px 0px 0px 6px",
                                      backgroundColor: "#F9FAFB",
                                      border: " 1px solid #D1D5DC",
                                      color: "#9AA2B",
                                      borderRight: "none",
                                    }}
                                  >
                                    {clientSelected?.dsp_currency
                                      ?.currency_symbol_code
                                      ? clientSelected?.dsp_currency
                                        ?.currency_symbol_code
                                      : "Rp"}
                                  </Grid>
                                  <NumberFormat
                                    customInput={TextField}
                                    variant="outlined"
                                    thousandSeparator={true}
                                    onChange={e => {
                                      setHasChanges(true);
                                      changeValueTotalClaim(e.target.value);
                                    }}
                                    autoComplete="off"
                                    className={classes.inputNum}
                                    style={{
                                      paddingLeft: "0px",
                                      height: "44px",
                                    }}
                                    size="small"
                                    fullWidth
                                    value={valueTotalClaim}
                                  />
                                </Grid>
                              </div>
                            </TableCell>
                            <TableCell>
                              <TextareaAutosize
                                onChange={e => {
                                  setHasChanges(true);
                                  setGlobalNotes(e.target.value);
                                }}
                                className={classes.textArea}
                                style={{
                                  borderRadius: "6px",
                                  borderColor: "#D1D5DC",
                                  marginTop: "5px",
                                }}
                                minRows={1.7}
                                variant="outlined"
                                value={globalNotes}
                              />
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Card>
                  </>
                ) : (
                  <>
                    <Card
                      style={{
                        border: "1px solid #9AA2B1",
                        borderRadius: "6px",
                        boxShadow: "none",
                        marginBottom: "10px",
                      }}
                    >
                      <Table id="table-out" size="large">
                        <TableHead>
                          <TableRow>
                            <ThemeProvider theme={theme}>
                              <TableCell>
                                <Typography
                                  variant="subtitle2"
                                  className={classes.headText}
                                >
                                  Song
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  variant="subtitle2"
                                  className={classes.headText}
                                >
                                  Composer/Author
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  variant="subtitle2"
                                  className={classes.headText}
                                >
                                  Publisher Percentage
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  variant="subtitle2"
                                  className={classes.headText}
                                >
                                  Type
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  variant="subtitle2"
                                  className={classes.headText}
                                >
                                  Notes
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  variant="subtitle2"
                                  className={classes.headText}
                                >
                                  Fee{" "}
                                  <span className={classes.required}>*</span>
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  variant="subtitle2"
                                  className={classes.headText}
                                >
                                  Qty
                                </Typography>
                              </TableCell>

                              <TableCell>
                                <Typography
                                  variant="subtitle2"
                                  className={classes.headText}
                                >
                                  Total Fee
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  variant="subtitle2"
                                  className={classes.headText}
                                >
                                  Action
                                </Typography>
                              </TableCell>
                            </ThemeProvider>
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          {songList !== null ? (
                            songList?.length > 0 ? (
                              songList?.map((customer, index) => (
                                <TableRow hover key={`${customer.song_id}`}>
                                  <TableCell>
                                    <Typography
                                      variant="subtitle2"
                                      className={classes.bodyTextBlack}
                                    >
                                      {customer.song_title}
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      width: "15%",
                                    }}
                                  >
                                    {customer?.composers?.length &&
                                      customer?.composers?.map(composer => {
                                        return (
                                          <Tooltip
                                            key={composer?.composer_id}
                                            title={composer?.sure_name}
                                            placement="bottom"
                                          >
                                            <Typography
                                              variant="subtitle2"
                                              className={classes.bodyTextGrey}
                                              style={{
                                                height: "37.63px",
                                                display: "-webkit-box",
                                                overflow: "hidden",
                                                WebkitBoxOrient: "vertical",
                                                WebkitLineClamp: 2,
                                                WebkitAlignItems: "top",
                                                verticalAlign: "top",
                                                alignItems: "top",
                                                textAlign: "top",
                                              }}
                                            >
                                              {composer?.sure_name}
                                            </Typography>
                                          </Tooltip>
                                        );
                                      })}
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      width: "10%",
                                    }}
                                  >
                                    {customer?.composers.length &&
                                      customer?.composers.map(
                                        (composer, idx) => {
                                          return (
                                            <NumberFormat
                                              key={composer?.composer_id}
                                              id={`song_${composer?.composer_id}`}
                                              name={`song_${composer?.composer_id}`}
                                              customInput={TextField}
                                              variant="outlined"
                                              allowNegative="false"
                                              onChange={e => {
                                                setHasChanges(true);
                                                onChangePercentageSong(
                                                  e,
                                                  index,
                                                  idx
                                                );
                                              }}
                                              autoComplete="off"
                                              style={{
                                                paddingLeft: "0px",
                                                height: "44px",
                                              }}
                                              size="small"
                                              fullWidth
                                              defaultValue={
                                                composer?.publisher_share_percentage
                                              }
                                              InputProps={{
                                                endAdornment: (
                                                  <InputAdornment position="end">
                                                    {"%"}
                                                  </InputAdornment>
                                                ),
                                              }}
                                            />
                                          );
                                        }
                                      )}
                                  </TableCell>
                                  <TableCell>
                                    <Typography
                                      variant="subtitle2"
                                      className={classes.bodyTextBlack}
                                    >
                                      {customer.choosenType}
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography
                                      variant="subtitle2"
                                      className={classes.bodyTextBlack}
                                    >
                                      <TextareaAutosize
                                        onChange={e => {
                                          setHasChanges(true);
                                          changeNotes(e.target.value, index);
                                        }}
                                        className={classes.textArea}
                                        id={`notes_${customer.song_id}`}
                                        name={`notes_${customer.song_id}`}
                                        style={{
                                          borderRadius: "6px",
                                          borderColor: "#D1D5DC",
                                          marginTop: "5px",
                                          width: "100px",
                                        }}
                                        minRows={1.7}
                                        variant="outlined"
                                        defaultValue={customer.addNotes}
                                      />
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      width: "20%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        marginTop: "0px",
                                        marginBottom: "0px",
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <Grid
                                        style={{
                                          display: "flex",
                                          marginTop: "6px",
                                        }}
                                      >
                                        <Grid
                                          style={{
                                            width: "48px",
                                            height: "38px",
                                            marginRight: "-2px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            borderRadius: "6px 0px 0px 6px",
                                            backgroundColor: "#F9FAFB",
                                            border: " 1px solid #D1D5DC",
                                            color: "#9AA2B",
                                            borderRight: "none",
                                          }}
                                        >
                                          {clientSelected?.dsp_currency
                                            ?.currency_symbol_code
                                            ? clientSelected?.dsp_currency
                                              ?.currency_symbol_code
                                            : "Rp"}
                                        </Grid>
                                        <NumberFormat
                                          id={`song_${customer.song_id}`}
                                          name={`song_${customer.song_id}`}
                                          customInput={TextField}
                                          variant="outlined"
                                          thousandSeparator={true}
                                          onChange={e => {
                                            setHasChanges(true);
                                            onChangeMoney(e, index);
                                          }}
                                          autoComplete="off"
                                          className={classes.inputNum}
                                          style={{
                                            paddingLeft: "0px",
                                            height: "44px",
                                          }}
                                          size="small"
                                          fullWidth
                                          placeholder="0"
                                        />
                                      </Grid>
                                    </div>
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      width: "20%",
                                    }}
                                  >
                                    <NumberFormat
                                      id={`song_${customer.song_id}`}
                                      name={`song_${customer.song_id}`}
                                      customInput={TextField}
                                      variant="outlined"
                                      allowNegative="false"
                                      onChange={e => {
                                        setHasChanges(true);
                                        onChangeQtySong(e, index);
                                      }}
                                      autoComplete="off"
                                      style={{
                                        paddingLeft: "0px",
                                        height: "44px",
                                      }}
                                      size="small"
                                      fullWidth
                                      defaultValue={customer.qty}
                                    />
                                  </TableCell>

                                  {typeClaim ? (
                                    <>
                                      <TableCell>
                                        <IconButton
                                          style={{
                                            marginLeft: "8px",
                                            backgroundColor: "#000",
                                          }}
                                          classes={{
                                            root: classes.ib,
                                          }}
                                          onClick={e =>
                                            handleDeleteSongListClaim(
                                              e,
                                              customer.song_id
                                            )
                                          }
                                        >
                                          <img
                                            alt="theDataImage"
                                            src={TrashVector}
                                          />
                                        </IconButton>
                                      </TableCell>
                                    </>
                                  ) : (
                                    <>
                                      <TableCell>
                                        <Typography
                                          variant="subtitle2"
                                          className={classes.bodyTextBlack}
                                        >
                                          <NumberFormat
                                            displayType="text"
                                            value={customer.addRevenueNumber}
                                            thousandSeparator={true}
                                            decimalScale={2}
                                            prefix={
                                              clientSelected?.dsp_currency
                                                ?.currency_symbol_code
                                                ? clientSelected?.dsp_currency
                                                  ?.currency_symbol_code + " "
                                                : "Rp "
                                            }
                                          />
                                        </Typography>
                                      </TableCell>
                                      <TableCell>
                                        <IconButton
                                          style={{
                                            marginLeft: "8px",
                                            backgroundColor: "#000",
                                          }}
                                          classes={{
                                            root: classes.ib,
                                          }}
                                          onClick={e =>
                                            handleDeleteSongList(
                                              e,
                                              customer.song_id
                                            )
                                          }
                                        >
                                          <img
                                            alt="theDataImage"
                                            src={TrashVector}
                                          />
                                        </IconButton>
                                      </TableCell>
                                    </>
                                  )}
                                </TableRow>
                              ))
                            ) : (
                              <TableRow>
                                <TableCell colSpan={typeClaim ? 6 : 6}>
                                  <center>
                                    <ThemeProvider theme={theme}>
                                      <Typography
                                        variant="h6"
                                        style={{
                                          fontWeight: 400,
                                          color: "#687083",
                                          fontSize: "14px",
                                        }}
                                      >
                                        There&apos;s no data available.
                                      </Typography>
                                    </ThemeProvider>
                                  </center>
                                </TableCell>
                              </TableRow>
                            )
                          ) : (
                            <TableRow>
                              <TableCell colSpan={typeClaim ? 6 : 6}>
                                <center>
                                  <ThemeProvider theme={theme}>
                                    <Typography
                                      variant="h6"
                                      style={{
                                        fontWeight: 400,
                                        color: "#687083",
                                        fontSize: "14px",
                                      }}
                                    >
                                      There&apos;s no data available.
                                    </Typography>
                                  </ThemeProvider>
                                </center>
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </Card>
                  </>
                )}

                <div>
                  <FormGroup
                    style={{
                      marginLeft: "10px",
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Switch
                          disableRipple
                          classes={{
                            root: classes.rootSwitch,
                            switchBase: classes.switchBase,
                            thumb: classes.thumb,
                            track: classes.track,
                            checked: classes.checked,
                            colorSecondary: classes.colorSecondary,
                            colorPrimary: classes.colorPrimary,
                          }}
                          checked={checkedVal}
                          onChange={handleChecked}
                        />
                      }
                      label="Use taxes in this transaction"
                    />
                  </FormGroup>
                </div>

                <div>
                  {checkedVal === true ? (
                    <>
                      <div
                        style={{
                          marginTop: "24px",
                          display: "none",
                        }}
                      >
                        <FormControl variant="outlined">
                          <OutlinedInput
                            id="outlined-adornment-weight"
                            endAdornment={
                              <InputAdornment position="end">%</InputAdornment>
                            }
                            className={classes.percentField}
                            style={{}}
                          />
                        </FormControl>
                      </div>

                      <div
                        style={{
                          marginTop: "24px",
                          marginBottom: "0px",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Table
                          id="table-out"
                          size="small"
                          style={{ borderRadius: "6px" }}
                        >
                          <TableHead>
                            <TableRow>
                              <ThemeProvider theme={theme}>
                                <TableCell>
                                  <Typography
                                    variant="subtitle2"
                                    className={classes.headText}
                                  >
                                    Code
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography
                                    variant="subtitle2"
                                    className={classes.headText}
                                  >
                                    Description
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography
                                    variant="subtitle2"
                                    className={classes.headText}
                                  >
                                    Rate
                                  </Typography>
                                </TableCell>
                              </ThemeProvider>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {clientSelected?.taxes?.length ? (
                              clientSelected?.taxes?.map((el, index) => {
                                return (
                                  <TableRow hover key={`${el.code}`}>
                                    <TableCell
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        handleChangeCheckboxTaxes(
                                          selectedTax?.find(
                                            data => data?.id === el?.id
                                          )
                                            ? selectedTax?.find(
                                              data => data?.id === el?.id
                                            )
                                            : el,
                                          index
                                        );
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Checkbox
                                          id={`checkBox-${el.id}`}
                                          onChange={() => {
                                            setHasChanges(true);
                                            handleChangeCheckboxTaxes(
                                              selectedTax?.find(
                                                data => data?.id === el?.id
                                              )
                                                ? selectedTax?.find(
                                                  data => data?.id === el?.id
                                                )
                                                : el,
                                              index
                                            );
                                          }}
                                          color="primary"
                                          inputProps={{
                                            "aria-label": "secondary checkbox",
                                          }}
                                          checked={
                                            selectedTax?.findIndex(
                                              data => data.id === el.id
                                            ) >= 0
                                          }
                                        />
                                        <Typography
                                          variant="subtitle2"
                                          className={classes.bodyTextBlack}
                                        >
                                          {el?.code}
                                        </Typography>
                                      </div>
                                    </TableCell>
                                    <TableCell>
                                      <Typography
                                        variant="subtitle2"
                                        className={classes.bodyTextBlack}
                                      >
                                        {el?.description}
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography
                                        variant="subtitle2"
                                        className={classes.bodyTextBlack}
                                      >
                                        {el?.rate + "%"}
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                );
                              })
                            ) : (
                              <TableRow>
                                <TableCell colSpan={typeClaim ? 3 : 4}>
                                  <center>
                                    <ThemeProvider theme={theme}>
                                      <Typography
                                        variant="h6"
                                        style={{
                                          fontWeight: 400,
                                          color: "#687083",
                                          fontSize: "14px",
                                        }}
                                      >
                                        There&apos;s no data available.
                                      </Typography>
                                    </ThemeProvider>
                                  </center>
                                </TableCell>
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      </div>

                      <Card
                        style={{
                          border: "0px solid #9AA2B1",
                          borderRadius: "6px",
                          boxShadow: "none",
                          marginBottom: "10px",
                          backgroundColor: "#fafafa",
                          padding: "14px",
                          marginTop: "28px",
                        }}
                      >
                        <Grid container spacing={3}>
                          <Grid item xs={12} md={4} lg={4} xl={4}>
                            Fee
                          </Grid>
                          <Grid item xs={12} md={4} lg={4} xl={4}>
                            {""}
                          </Grid>
                          <Grid item xs={12} md={4} lg={4} xl={4}>
                            <div
                              style={{
                                textAlign: "right",
                                fontSize: "18px",
                              }}
                            >
                              <NumberFormat
                                displayType="text"
                                value={totalRev}
                                thousandSeparator={true}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                prefix={
                                  clientSelected?.dsp_currency
                                    ?.currency_symbol_code
                                    ? clientSelected?.dsp_currency
                                      ?.currency_symbol_code + " "
                                    : "Rp "
                                }
                              />
                            </div>
                          </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                          {selectedTax.map(el => (
                            <Fragment key={el.id}>
                              <Grid
                                item
                                xs={12}
                                md={4}
                                lg={4}
                                xl={4}
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  flexDirection: "column",
                                }}
                              >
                                <div>{el.code}</div>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                md={4}
                                lg={4}
                                xl={4}
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  flexDirection: "column",
                                  textAlign: "center",
                                }}
                              ></Grid>
                              <Grid
                                item
                                xs={12}
                                md={4}
                                lg={4}
                                xl={4}
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  flexDirection: "column",
                                }}
                              >
                                <div
                                  style={{
                                    textAlign: "right",
                                    fontSize: "18px",
                                  }}
                                >
                                  <NumberFormat
                                    displayType="text"
                                    value={el?.afterCountTax}
                                    thousandSeparator={true}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    prefix={
                                      clientSelected?.dsp_currency
                                        ?.currency_symbol_code
                                        ? clientSelected?.dsp_currency
                                          ?.currency_symbol_code + " "
                                        : "Rp "
                                    }
                                  />
                                </div>
                              </Grid>
                            </Fragment>
                          ))}
                        </Grid>

                        <Divider className={classes.divider} />
                        {isForeignCurrency && (
                          <Grid container spacing={3}>
                            <Grid
                              item
                              xs={12}
                              md={4}
                              lg={4}
                              xl={4}
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                              }}
                            >
                              <div>Exchange Rate</div>
                            </Grid>
                            <Grid item xs={12} md={4} lg={4} xl={4}></Grid>
                            <Grid item xs={12} md={4} lg={4} xl={4}>
                              <div
                                style={{
                                  textAlign: "right",
                                  fontSize: "18px",
                                }}
                              >
                                <NumberFormat
                                  displayType="text"
                                  value={exchangeRate}
                                  thousandSeparator={true}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                  prefix="Rp "
                                />
                              </div>
                            </Grid>
                          </Grid>
                        )}
                        <Grid container spacing={3}>
                          <Grid item xs={12} md={4} lg={4} xl={4}>
                            <div
                              style={{
                                fontSize: "18px",
                              }}
                            >
                              Total Fee{" "}
                              {location.search === "?yogie=true" ? (
                                <>{totalAfterTax}</>
                              ) : null}
                            </div>
                          </Grid>
                          <Grid item xs={12} md={4} lg={4} xl={4}></Grid>
                          <Grid item xs={12} md={4} lg={4} xl={4}>
                            <div
                              style={{
                                textAlign: "right",
                                fontSize: "22px",
                              }}
                            >
                              <NumberFormat
                                displayType="text"
                                value={totalAfterTax}
                                thousandSeparator={true}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                prefix={
                                  clientSelected?.dsp_currency
                                    ?.currency_symbol_code
                                    ? clientSelected?.dsp_currency
                                      ?.currency_symbol_code + " "
                                    : "Rp "
                                }
                                style={{ fontWeight: "bold" }}
                              />
                            </div>
                          </Grid>
                        </Grid>
                      </Card>
                    </>
                  ) : (
                    <>{""}</>
                  )}
                  <Grid container justifyContent="flex-end">
                    {checkedVal !== true ? (
                      <>
                        <div
                          style={{
                            display: "block",
                            marginTop: "24px",
                            marginBottom: "14px",
                          }}
                        >
                          <div
                            style={{
                              fontSize: "14px",
                              textAlign: "right",
                            }}
                          >
                            Total Fee :
                          </div>
                          <div
                            style={{
                              fontSize: "24px",
                            }}
                          >
                            <b>
                              <NumberFormat
                                displayType="text"
                                value={totalRev}
                                thousandSeparator={true}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                style={{ fontWeight: "bold" }}
                                prefix={
                                  clientSelected?.dsp_currency
                                    ?.currency_symbol_code
                                    ? clientSelected?.dsp_currency
                                      ?.currency_symbol_code + " "
                                    : "Rp "
                                }
                              />
                            </b>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>{""}</>
                    )}
                  </Grid>
                </div>
                <Grid container justifyContent="flex-end">
                  <Button
                    onClick={() => {
                      history.push("/admin/invoice");
                    }}
                    style={{
                      borderRadius: "6px",
                      width: "84px",
                      height: "40px",
                      border: "1px solid #D1D5DC",
                      textTransform: "none",
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    style={{
                      marginLeft: "10px",
                      backgroundColor: submiting ? "gray" : "#111827",
                      borderRadius: "6px",
                      width: "84px",
                      height: "40px",
                      color: "#FFFFFF",
                      textTransform: "none",
                    }}
                    disabled={submiting}
                    onClick={e => onSubmit(e)}
                  >
                    Add
                  </Button>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default AddClaimAndUsage;
