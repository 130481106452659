import { Button } from "@material-ui/core";
import Axios from "axios";
import { useState } from "react";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import InputPassword from "../components/atoms/Input/InputPassword";
export default function ChangePassword() {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const auth = useSelector(state => state.auth);
  const handleChangePassword = e => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Password anda tidak sesuai",
      });
    } else {
      Swal.fire({
        title: "Apakah anda yakin?",
        text: "Anda akan mengganti password!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Confirm",
      }).then(result => {
        if (result.isConfirmed) {
          Axios.post(
            "https://304d-182-0-241-254.ngrok.io/auth/reset-password",
            {
              token: auth.user.token,
              old_password: oldPassword,
              new_password: newPassword,
            },
            {
              headers: {
                Authorization: "Bearer " + auth.user.token,
              },
            }
          ).then(() => {
            Swal.fire("Success!", "Success change password.", "success");
          });
        }
      });
    }
  };
  return (
    <form
      style={{
        marginTop: "50px",
        marginLeft: "20%",
        marginRight: "20%",
        textAlign: "center",
      }}
      onSubmit={handleChangePassword}
    >
      <InputPassword
        onChange={e => setOldPassword(e.target.value)}
        type="password"
        fullWidth
        placeholder="old password"
        variant="outlined"
      />
      <InputPassword
        onChange={e => setNewPassword(e.target.value)}
        type="password"
        fullWidth
        placeholder="new password"
        variant="outlined"
        style={{ marginTop: "10px" }}
      />
      <InputPassword
        onChange={e => setConfirmPassword(e.target.value)}
        type="password"
        fullWidth
        placeholder="confirm new password"
        variant="outlined"
        style={{ marginTop: "10px" }}
      />
      <Button
        type="submit"
        variant="contained"
        style={{ backgroundColor: "black", color: "white", marginTop: "20px" }}
      >
        Change Password
      </Button>
    </form>
  );
}
