import { Container, Divider } from "@material-ui/core";
import { Box, Grid } from "@mui/material";
import axios from "axios";
import {
  CurrencyNumberInput,
  DateTimePicker,
  FormLabel,
  ModalError,
  ModalSuccess,
  Page,
  PrimaryButton,
  SectionLabel,
  TextInput,
} from "components";
import { HeaderTitle } from "layouts";
import moment from "moment";
import { useState } from "react";
import { useHistory, useParams } from "react-router";
import { globalStyles } from "styles";
import { getCookie, getErrors } from "utils";
import { hardBaseUrl, hardTypeWeb } from "../../../../services/urlConstant";

const AddExRate = () => {
  const classes = globalStyles();
  const { id, currency } = useParams();
  const history = useHistory();
  const userLogin = getCookie("auth", `${process.env.REACT_APP_NAME}_user`);
  const isMpis = hardTypeWeb === "mpis";
  const pageTitle = isMpis ? "Parameter" : "Configuration";
  const pageUrl = isMpis ? "parameter" : "konfigurasi";
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const eightYearsAgo = moment().subtract(8, "years");
  const oneHourLater = moment().add(1, "hour");

  const [loadingButton, setLoadingButton] = useState(false);
  const [payload, setPayload] = useState({
    currency_id: id,
    start_date: null,
    end_date: null,
    value: 0,
    ...(!isMpis && {
      publisher_id: userLogin?.publisher?.publisher_id,
    }),
  });

  const handleChangePayload = (value, key) =>
    setPayload(prev => ({
      ...prev,
      [key]: value,
    }));
  const handleStartDateChange = newStartDate => {
    let newEndDate = payload.end_date;
    if (newEndDate && moment(newEndDate).isBefore(moment(newStartDate))) {
      newEndDate = moment(newStartDate).add(1, "minute");
    }
    if (moment(newEndDate).isBefore(oneHourLater)) {
      newEndDate = oneHourLater;
    }

    setPayload(prevPayload => ({
      ...prevPayload,
      start_date: newStartDate,
      end_date: newEndDate,
    }));
  };
  const handleEndDateChange = newEndDate => {
    if (moment(newEndDate).isBefore(oneHourLater)) {
      newEndDate = oneHourLater;
    }
    if (
      payload.start_date &&
      moment(newEndDate).isSameOrBefore(moment(payload.start_date))
    ) {
      newEndDate = moment(payload.start_date).add(1, "minute");
    }

    setPayload(prevPayload => ({
      ...prevPayload,
      end_date: newEndDate,
    }));
  };
  const validatePayload = () => {
    const errors = [];
    const errorEmpty = error => `${error} can't be empty.`;

    if (!payload.value) {
      errors.push(errorEmpty("Value"));
    } else if (!payload?.start_date) {
      errors.push(errorEmpty("Start Date"));
    } else if (!payload?.end_date) {
      errors.push(errorEmpty("End Date"));
    }
    return errors;
  };

  const handleSubmit = async () => {
    const errors = validatePayload();
    if (errors?.length) {
      ModalError(errors[0]);
      return;
    }
    try {
      setLoadingButton(true);
      await axios.post(`${hardBaseUrl}/exchange-rate/create`, payload, {
        headers,
      });
      ModalSuccess("Successfully Add Exchange Rate").then(() =>
        history.goBack()
      );
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingButton(false);
    }
  };

  return (
    <Page className={classes.root} title="Create Exchanges rate">
      <Container maxWidth={false}>
        <HeaderTitle
          title="Create Exchange Rate"
          breadcrumbData={breadcrumbData({ pageTitle, pageUrl })}
          backButton
        />
        <Divider className={classes.divider} />
        <SectionLabel subTitle="Feature for create exchanges rate" />
        <Box maxWidth="70%">
          <Box border="1px solid #ebebeb" borderRadius="5px" p="24px" my="16px">
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <FormLabel label="Start Date" required />
                <DateTimePicker
                  value={payload?.start_date}
                  onChange={handleStartDateChange}
                  minDate={eightYearsAgo}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormLabel label="End Date" required />
                <DateTimePicker
                  value={payload?.end_date}
                  onChange={handleEndDateChange}
                  minDate={eightYearsAgo}
                  maxDate={moment().add(10, "year")}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormLabel label="Currency" required />
                <TextInput value={currency} disabled />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormLabel label="Value" required />
                <CurrencyNumberInput
                  value={payload?.value}
                  onChange={value => handleChangePayload(value, "value")}
                  decimalScale={2}
                />
              </Grid>
            </Grid>
          </Box>
          <Grid container justifyContent="right">
            <PrimaryButton
              label="Add"
              onClick={handleSubmit}
              loading={loadingButton}
              disabled={loadingButton}
            />
          </Grid>
        </Box>
      </Container>
    </Page>
  );
};

const breadcrumbData = ({ pageTitle, pageUrl }) => [
  {
    label: pageTitle,
    link: `admin/${pageUrl}/exchange-rate`,
  },
  {
    label: "Exchange Rate",
    link: `admin/${pageUrl}/exchange-rate`,
  },
  {
    label: "Create Exchange Rate",
    active: true,
  },
];

export default AddExRate;
