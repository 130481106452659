import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  Radio,
  TextField,
  Typography,
  Box,
  CircularProgress
} from "@material-ui/core";
import {
  ThemeProvider,
  createTheme,
  withStyles,
} from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import { setValueBeritaDanIklan, deleteAllValuesBeritaDanIklan } from "redux/actions/news&ads";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import axios from "axios";
import { ContentState, EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { InnoImage } from "inno-ui";
import moment from "moment";
import { Editor } from "react-draft-wysiwyg";
import { useDropzone } from "react-dropzone";
import Swal from "sweetalert2";
import "../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import UnggahLogo from "../../../../assets/img/searchFile.svg";
import { Page } from "components";
import { hardBaseUrl } from "../../../../services/urlConstant";
const theToken = localStorage.getItem("token");
const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});
const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  results: {
    marginTop: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  styleImage: {
    height: "100%",
    width: "auto",
  },
  btnSubmit: {
    backgroundColor: "black",
    marginRight: "10px",
    color: "white",
    padding: "10px 28px",
    width: "150px",
    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
  btnPreview: {
    backgroundColor: "black",
    color: "white",
    padding: "10px 28px",
    width: "150px",
    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
  addNews: {
    fontSize: "24px",
    fontWeight: "700",
    lineHeight: "32px",
  },
  marginTop20: {
    marginTop: "20px"
  },
  textP: {
    marginTop: "5px",
    color: "#8f8f8f",
    fontSize: "14px",
  },
  wrapperImage: {
    position: "relative",
    height: "200px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#F9FAFB",
    cursor: "pointer"
  },
  textImage: {
    fontSize: "12px",
    color: "#687083",
  },
  boxImage: {
    padding: 10,
    overflow: "hidden",
    display: "flex",
    alignItems: "center"
  },
  error: {
    color: "red",
  },
  label: {
    color: "#364052",
    fontSize: "14px",
    fontWeight: 500,
  },
  labelRequired: {
    color: "red"
  },
  subTitle: {
    fontSize: "18px",
    fontWeight: 700,
    color: " #111827",
  },
  textSubmit: {
    textTransform: "none",
    color: "#FFFFFF",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "20px",
  },
  breadcrumbs: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#111827",
  },
  cardContent: {
    border: "1px solid #9AA2B1",
    borderRadius: "6px",
    boxShadow: "none",
  },
  breadCrumbsActive: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#687083",
  },
}));
const BlackRadio = withStyles({
  root: {
    color: "#303030",
    "&$checked": {
      color: "#000000",
    },
  },
  checked: {},
})(props => <Radio color="default" {...props} />);
const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "grey",
  borderStyle: "dashed",
  backgroundColor: "white",
  color: "black",
  outline: "none",
  transition: "border .24s ease-in-out",
};
const activeStyle = {
  borderColor: "#2196f3",
};
const acceptStyle = {
  borderColor: "#00e676",
};
const rejectStyle = {
  borderColor: "#ff1744",
};
const img = {
  display: "block",
  width: "auto",
  height: "100%",
  marginLeft: "auto",
  marginRight: "auto",
  borderRadius: "6px",
};
function TambahBerita() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const newsAds = useSelector(data => data?.newsAds);
  const [loading, setLoading] = useState(false);
  const [selectedValue, setSelectedValue] = useState(newsAds?.type || "berita");
  const [textEditor, setTextEditor] = useState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(newsAds.content || "").contentBlocks)));
  const [judul, setJudul] = useState(newsAds?.title || "");
  const [gambar, setGambar] = useState(newsAds?.gambar || []);
  const [publisherName, setPublisherName] = useState(newsAds?.publisherName || "");
  const [adminName, setAdminName] = useState(newsAds?.adminName || "");
  const [error, setError] = useState({
    judul: "",
    publisherName: "",
    gambar: "",
    textEditor: "",
  });
  const [imagesPrev, setImagesPrev] = useState(newsAds?.imagesPrev || "");
  const [files, setFiles] = useState([]);

  // Handle file drop
  const handleDrop = useCallback(async acceptedFiles => {
    setGambar(acceptedFiles);
    let tesRes = await agetBase64(acceptedFiles[0]);
    // let resAfter = tesRes.replace(/^data:image\/(png|jpg|jpeg);base64,/, "");
    setImagesPrev(tesRes);
    setFiles(
      acceptedFiles.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
  }, []);

  // Dropzone configuration
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ accept: "image/*", onDrop: handleDrop });

  // Convert file to base64
  const agetBase64 = file => {
    return new Promise(resolve => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  useEffect(() => {
    files.forEach(file => URL.revokeObjectURL(file.preview));
  }, [files]);

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  // Handle text change
  const handleChangeText = e => {
    setJudul(e.target.value);
  };

  // Handle selection change
  const handleChange = event => {
    setSelectedValue(event.target.value);
  };

  // Handle form submission
  const handleSubmit = e => {
    e.preventDefault();
    if (judul === "") {
      setError({
        ...error,
        judul: "Judul tidak boleh kosong",
      });
    } else if (!textEditor.getCurrentContent().hasText()) {
      setError({
        ...error,
        textEditor: "Isi tidak boleh kosong",
      });
    } else if (gambar.length === 0 && !newsAds.image_logo) {
      setError({
        ...error,
        gambar: "Gambar tidak boleh kosong",
      });
    } else {
      setLoading(true);
      const rawContentState = convertToRaw(textEditor.getCurrentContent());
      const markup = draftToHtml(rawContentState);
      const formData = new FormData();
      formData.append("title", judul);
      formData.append("type", selectedValue);
      formData.append("content", markup);
      if (gambar.length > 0) {
        formData.append("image_logo", gambar[0]);
      } else if (gambar?.length === 0) {
        const image_logo = newsAds?.image_logo;
        const image_logo_file = new File([image_logo], "image_logo.jpg", { type: "image/jpeg" });
        formData.append("image_logo", image_logo_file);
      }
      const url = `${hardBaseUrl}/article`;
      const config = {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${theToken}`,
        },
      };
      axios
        .post(url, formData, config)
        .then(() => {
          Swal.fire({
            title: "Berhasil",
            text: "Berita & Iklan berhasil ditambahkan",
            icon: "success",
            confirmButtonText: "Oke",
          });
          dispatch(deleteAllValuesBeritaDanIklan());
          history.push("/admin/berita-publisher");
          setJudul("");
          setTextEditor(EditorState.createEmpty());
          setGambar([]);
          setSelectedValue("berita");
        })
        .catch(err => {
          new Error(err);
        })
        .finally(() => setLoading(false));
    }
  };

  // Handle preview
  const handlePreview = async () => {
    const recentDate = moment();
    const date = recentDate.date();
    const month = recentDate.format("MMMM");
    const year = recentDate.year();
    const dateString = `${date} ${month} ${year}`;
    const rawContentState = convertToRaw(textEditor.getCurrentContent());
    const markup = draftToHtml(rawContentState);

    const payload = {
      title: judul,
      type: selectedValue,
      content: markup,
      rawContent: convertToRaw(textEditor.getCurrentContent()),
      recentDate: dateString,
      publisherName: publisherName,
      adminName: adminName,
      image_logo: imagesPrev,
      imagesPrev,
      gambar,
      files
    };
    dispatch(setValueBeritaDanIklan(payload));
    history.push("/admin/berita-publisher/tambah/preview");
  };


  // Fetch publisher name
  const getPublisherName = () => {
    const url = `${hardBaseUrl}/me`;
    const config = {
      headers: {
        Authorization: `Bearer ${theToken}`,
      },
    };
    axios
      .get(url, config)
      .then(res => {
        setAdminName(res.data.data.role.name);
        setPublisherName(res.data.data.publisher.name);
      })
      .catch(err => {
        new Error(err);
      });
  };

  useEffect(() => {
    getPublisherName();
  }, []);
  return (
    <Page className={classes.root} title="Add News">
      <Container maxWidth={false}>
        <Grid
          alignItems="flex-end"
          container
          justifyContent="space-between"
          spacing={3}
        >
          <Grid item>
            <ThemeProvider theme={theme}>
              <Typography className={classes.addNews}>
                Add News
              </Typography>
            </ThemeProvider>
          </Grid>
          <Grid item>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography className={classes.breadCrumbs}>Home</Typography>
              <Typography className={classes.breadCrumbs}>{"News"}</Typography>
              <Typography className={classes.breadCrumbsActive}>
                Add News
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        <form onSubmit={e => handleSubmit(e)}>
          <Grid container>
            <Box>
              <ThemeProvider theme={theme}>
                <Typography
                  component="h1"
                  variant="h3"
                  className={classes.subTitle}
                >
                  Cover
                </Typography>
              </ThemeProvider>
              <ThemeProvider theme={theme}>
                <p className={classes.textP}>
                  Add cover/banner for news
                </p>
              </ThemeProvider>
            </Box>
          </Grid>
          <Grid container>
            <Grid item xs={8} md={8} sm={8} lg={8} xl={8}>
              <Box border="1px solid #9AA2B1" borderRadius="8px" padding="16px" marginTop="16px">
                <Box marginBottom="6px" display="flex" gridGap={1}>
                  <Typography className={classes.label}>
                    Banner
                  </Typography>
                  <Typography className={classes.labelRequired}>
                    *
                  </Typography>
                </Box>
                <Box
                  {...getRootProps({ style })}
                  className={classes.wrapperImage}
                >
                  <Box className={classes.boxImage}>
                    {/* using this condition because InnoImage not re-render when image is changing */}
                    {!imagesPrev ? (
                      <InnoImage
                        src={UnggahLogo}
                        styleImage={{
                          height: "100%",
                          width: "auto",
                        }}
                        styleImageContainer={{
                          boxShadow: "none",
                          padding: 0,
                          ...img,
                          backgroundColor: "transparent",
                        }}
                        fill={true}
                      />
                    ) : (
                      <img
                        src={imagesPrev}
                        className={classes.styleImage}
                      />
                    )}
                  </Box>
                  <input {...getInputProps()} />
                </Box>
                <aside>
                  <Typography
                    component="span"
                    className={classes.textImage}
                  >
                    Cover format can be images and videos, less than 1MB, 1000 x
                    500
                  </Typography>
                </aside>
                <Box marginTop="6px"></Box>
              </Box>
            </Grid>
            <Grid item xs={4} md={4} sm={4} lg={4} xl={4}></Grid>
          </Grid>
          <Grid container className={classes.marginTop20}>
            <Box>
              <ThemeProvider theme={theme}>
                <Typography
                  component="h1"
                  variant="h3"
                  className={classes.subTitle}
                >
                  Content
                </Typography>
              </ThemeProvider>
              <ThemeProvider theme={theme}>
                <p className={classes.textP}>
                  Type content of the news here
                </p>
              </ThemeProvider>
            </Box>
          </Grid>
          <Grid container>
            <Grid item xs={8} md={8} sm={8} lg={8} xl={8}>
              <Card className={classes.cardContent}>
                <CardContent>
                  <Grid>
                    <Box margin="5px 0px">
                      <Box margin="10px 0px" display="flex" gridGap={1}>
                        <Typography className={classes.label}>
                          Title
                        </Typography>
                        <Typography className={classes.labelRequired}>
                          *
                        </Typography>
                      </Box>
                      <Box margin="10px 0px">
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          fullWidth={true}
                          margin="dense"
                          onChange={handleChangeText}
                          name="judul"
                          value={judul}
                        />
                      </Box>
                      {judul === "" && (
                        <Typography
                          component="h1"
                          variant="h5"
                          className={classes.error}
                        >
                          {error.judul}
                        </Typography>
                      )}
                    </Box>
                  </Grid>
                  <Grid container>
                    <Box margin="10px 0px">
                      <Box margin="10px 0px">
                        <ThemeProvider theme={theme}>
                          <Typography className={classes.label}>
                            Type
                          </Typography>
                        </ThemeProvider>
                      </Box>
                      <Box margin="10px 0px">
                        <FormControlLabel
                          value="end"
                          control={
                            <BlackRadio
                              checked={selectedValue === "berita"}
                              onChange={handleChange}
                              value="berita"
                              name="berita"
                              inputProps={{ "aria-label": "A" }}
                            />
                          }
                          label="News"
                        />
                        <FormControlLabel
                          value="end"
                          control={
                            <BlackRadio
                              checked={selectedValue === "edukasi"}
                              onChange={handleChange}
                              value="edukasi"
                              name="edukasi"
                              inputProps={{ "aria-label": "C" }}
                            />
                          }
                          label="Education"
                        />
                      </Box>
                    </Box>
                  </Grid>
                  <Box margin="10px 0px">
                    <Box margin="10px 0px">
                      <ThemeProvider theme={theme}>
                        <Typography>Contents</Typography>
                      </ThemeProvider>
                    </Box>
                    <Box margin="10px 0px">
                      <Editor
                        editorState={textEditor}
                        wrapperClassName="wrapper-class"
                        editorClassName="editor-class input_description"
                        editorStyle={{
                          height: "60vH",
                          backgroundColor: "white",
                          paddingLeft: "10px",
                          paddingRight: "10px",
                        }}
                        toolbarClassName="toolbar-class"
                        wrapperStyle={{
                          border: "1px solid #ccc",
                          paddingLeft: 0,
                          paddingRight: 0,
                        }}
                        toolbar={{
                          options: [
                            "inline",
                            "blockType",
                            "fontSize",
                            "list",
                            "textAlign",
                            "colorPicker",
                            "link",
                            "remove",
                            "history",
                          ],
                        }}
                        onEditorStateChange={setTextEditor}
                      />
                      {textEditor.getCurrentContent().hasText() === false && (
                        <Typography
                          component="h1"
                          variant="h5"
                          className={classes.error}
                        >
                          {error.textEditor}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </CardContent>
              </Card>
              <Divider className={classes.divider} />
              <Button
                className={classes.btnSubmit}
                type="submit"
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress className={classes.textSubmit} size={20} />
                ) : (
                  <ThemeProvider theme={theme}>
                    <Typography className={classes.textSubmit}>
                      Apply
                    </Typography>
                  </ThemeProvider>
                )}
              </Button>
              <Button
                className={classes.btnPreview}
                onClick={handlePreview}
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress className={classes.textSubmit} size={20} />
                ) : (
                  <ThemeProvider theme={theme}>
                    <Typography className={classes.textSubmit}>
                      Preview
                    </Typography>
                  </ThemeProvider>
                )}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </Page>
  );
}
export default TambahBerita;
