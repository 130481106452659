import { Box } from "@mui/material";
import NumberFormat from "react-number-format";

const CurrencyDisplay = ({
  value,
  decimalScale,
  prefix,
  suffix,
  textAlign,
  ...rest
}) => {
  return (
    <Box textAlign={textAlign || "right"}>
      <NumberFormat
        displayType="text"
        value={value || 0}
        thousandSeparator={true}
        decimalScale={decimalScale}
        fixedDecimalScale={true}
        prefix={prefix}
        suffix={suffix}
        {...rest}
      />
    </Box>
  );
};

export default CurrencyDisplay;
