import { DefaultDraftBlockRenderMap, EditorState, Modifier } from "draft-js";
import { Map } from "immutable";

export function setBlockData(editorState, data) {
  const newContentState = Modifier.setBlockData(
    editorState.getCurrentContent(),
    editorState.getSelection(),
    data
  );

  return EditorState.push(editorState, newContentState, "change-block-data");
}

const newBlockRenderMap = Map({});

export const blockRenderMap = DefaultDraftBlockRenderMap.merge(
  newBlockRenderMap
);
