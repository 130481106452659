import { Button, IconButton, useMediaQuery, useTheme } from "@material-ui/core";
import { Menu as MenuIcon } from "@material-ui/icons";
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router";
import LandingNavBar from "./LandingNavBar";
import "./LandingStyle.css";
import newlogompis from "../../../assets/images/newlogompis.png";

function LandingHeader() {
  const history = useHistory();
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [navBarMobileVisible, setNavBarMobileVisible] = useState(false);

  const headerMenu = [
    {
      id: 1,
      title: "About Us",
      link: "/about",
    },
    {
      id: 2,
      title: "Our Partners",
      link: "/partner",
    },
    {
      id: 3,
      title: "Blog",
      link: "/blog",
    },
    {
      id: 4,
      title: "Contact Us",
      link: "/contact",
    },
  ];

  const headerButton = [
    {
      id: 1,
      title: "Login",
      action: () => {
        redirectToLogin();
      },
    },
    {
      id: 2,
      title: "Watch Demo",
      action: null,
    },
  ];

  const langMenu = [
    {
      id: "id",
      title: "ID",
      action: null,
    },
    {
      id: "en",
      title: "EN",
      action: null,
    },
  ];

  const redirectToLogin = () => {
    history.push("/login");
  };
  const handleOpenNavBar = () => {
    setNavBarMobileVisible(true);
  };
  const handleCloseNavBar = () => {
    setNavBarMobileVisible(false);
  };
  return (
    <div className="landing-header">
      <a href="/">
        <img
          alt={"new-mpis"}
          className="header-logo-mpis"
          src={newlogompis}
          style={{
            height: isMobile && "80px",
          }}
        />
      </a>
      {isMobile ? (
        <IconButton className="button-nav" onClick={handleOpenNavBar}>
          <MenuIcon />
        </IconButton>
      ) : (
        <>
          <div className="landing-header-menu-container">
            <div className="landing-header-nav-menu-container">
              {headerMenu?.map((item, index) => (
                <div key={index}>
                  <a
                    className="nav-menu"
                    style={{
                      color: "white",
                      margin:
                        index === 0
                          ? "0 20px 0 0"
                          : index === headerMenu?.length - 1
                            ? "0 0 0 20px"
                            : "0 20px",
                      paddingBottom:
                        location?.pathname === item?.link ? "10px" : "",
                      borderBottom:
                        location?.pathname === item?.link
                          ? "1px solid white"
                          : "",
                    }}
                    href={item?.link || "#"}
                  >
                    {item?.title}
                  </a>
                </div>
              ))}
            </div>
            {headerButton?.map((item, index) => (
              <div
                key={index}
                style={{
                  paddingRight: index === 0 ? "16px" : 0,
                }}
              >
                <Button
                  variant="outlined"
                  className="header-button-action"
                  onClick={item?.action}
                  data-testid={`data-test-button-login-${index}`}
                >
                  {item?.title}
                </Button>
              </div>
            ))}
            {langMenu?.map((item, index) => (
              <div
                key={index}
                style={{
                  paddingLeft: "16px",
                }}
              >
                <IconButton className="button-language" onClick={item?.action}>
                  {item?.title}
                </IconButton>
              </div>
            ))}
          </div>
        </>
      )}
      <LandingNavBar
        onMobileClose={handleCloseNavBar}
        openMobile={navBarMobileVisible}
        headerMenu={headerMenu}
        headerButton={headerButton}
      />
    </div>
  );
}

export default LandingHeader;
