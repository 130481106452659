import { Breadcrumbs, Typography } from "@material-ui/core";
import { useHistory } from "react-router";

const InnoBreadcrumbs = ({ data }) => {
  const history = useHistory();

  const onChange = value => {
    history.push(value);
  };

  return (
    <Breadcrumbs aria-label="breadcrumb">
      {data?.map((value, key) => {
        return (
          <a key={key} onClick={() => onChange(value.link)}>
            <Typography
              style={{
                fontSize: "14px",
                fontWeight: 400,
                fontStyle: "regular",
                color: value.active ? "#687083" : "#111827",
              }}
            >
              {value.label}
            </Typography>
          </a>
        );
      })}
    </Breadcrumbs>
  );
};

export default InnoBreadcrumbs;
