import {
  FormLabel,
  PrimaryButton,
  SecondaryButton,
  TextInput,
} from "components";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useEffect, useState } from "react";

const ModalAddSongAlias = ({ open, onClose, handleSubmit, preload }) => {
  const [value, setValue] = useState("");

  const getPreload = () => {
    setValue(preload || "");
  };
  const onSubmit = () => {
    handleSubmit(value);
    setValue("");
    onClose();
  };

  useEffect(() => {
    getPreload();
  }, [preload]);
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>{preload ? "Edit" : "Add"} Song Alias</DialogTitle>
      <DialogContent dividers>
        <FormLabel label="Song Alias" />
        <TextInput
          placeholder="Song Alias"
          value={value}
          onChange={e => setValue(e?.target?.value)}
        />
      </DialogContent>
      <DialogActions>
        <SecondaryButton label="Close" onClick={onClose} />
        <PrimaryButton
          label={preload ? "Save" : "Add"}
          onClick={onSubmit}
          disabled={!value}
        />
      </DialogActions>
    </Dialog>
  );
};

export default ModalAddSongAlias;
