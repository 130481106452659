import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import { tokenInfo } from "../redux/actions/auth";
import { SplashScreen, Page } from "components";
import { getErrors } from "utils";
import { hardBaseUrl } from "../services/urlConstant";
import Axios from "axios";
import * as Sentry from "@sentry/react";
import Swal from "sweetalert2";

const useStyles = makeStyles(theme => ({
  root: {
    textAlign: "center",
    verticalAlign: "middle",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

function RedirectPage({ match }) {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);
  const token = match.params.token;

  const validateToken = async () => {
    try {
      const data = await Axios.get(`${hardBaseUrl}/me`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return data?.data;
    } catch (error) {
      const err = getErrors(error?.response);
      Swal.fire({
        icon: "error",
        text: err
      }).then((result) => {
        if (result?.isConfirmed) {
          history.push("/");
        }
      });
      new Error(error);
    }
  };

  const handleRoleRedirect = async (roleType, roleName) => {
    switch (roleType) {
      case "admin":
        roleName === "Admin APMINDO"
          ? history.push("/admin/master/lagu-admin")
          : history.push("/admin/dashboard");
        break;
      case "publisher":
      case "society":
        history.push("/admin/dashboard");
        break;
      case "association":
        history.push("/admin/dashboard-association");
        break;
      case "composer":
        try {
          const { data } = await Axios.get(`${hardBaseUrl}/composer-manager/composers`, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          });
          data.data.length <= 1
            ? history.push("/pencipta/dashboard-pencipta")
            : history.push("/manage-composer");
        } catch (error) {
          Sentry.captureMessage(`Error redirecting composer: ${getErrors(error.response)}`);
        }
        break;
      default:
        break;
    }
  };

  const initializeToken = async () => {
    try {
      const result = await validateToken();
      console.log({ result });
      dispatch(tokenInfo(token));
      localStorage.setItem("token", token);
    } catch (error) {
      new Error(error);
      Sentry.captureMessage(`Error initializing token: ${getErrors(error.response)}`);
    }
  };

  useEffect(() => {
    initializeToken();
  }, [dispatch, token]);

  useEffect(() => {
    if (auth?.user?.role) {
      const urlRedirect = auth?.user?.user_menu[0]?.children[0]?.page_url;
      const { type, name } = auth.user.role;
      setTimeout(() => {
        if (urlRedirect) {
          history.push(urlRedirect);
        } else {
          handleRoleRedirect(type, name);
        }
      }, 1500);
    }
  }, [auth?.user]);

  return (
    <Page className={classes.root} title="Redirect">
      <SplashScreen />
    </Page>
  );
}

export default RedirectPage;
