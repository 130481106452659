import {
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Chip,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import moment from "moment";
import TableContainer from "@material-ui/core/TableContainer";
import AddIcon from "@material-ui/icons/Add";
import CheckIcon from "@material-ui/icons/Check";
import Pagination from "@material-ui/lab/Pagination";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import Swal from "sweetalert2";
import { hardBaseUrl } from "../../../../services/urlConstant";
import gradients from "../../../../theme/gradients";

const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  card: {
    width: theme.breakpoints.values.md,
    maxWidth: "100%",
    overflow: "visible",
    display: "flex",
    position: "relative",
    "& > *": {
      flexGrow: 1,
      flexBasis: "50%",
      width: "50%",
    },
  },
  content: {
    padding: theme.spacing(8, 4, 3, 4),
  },
  media: {
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    padding: theme.spacing(3),
    color: theme.palette.common.white,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  avatar_large: {
    width: theme.spacing(16),
    height: theme.spacing(16),
  },
  icon: {
    backgroundImage: gradients.green,
    color: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    position: "absolute",
    top: -32,
    left: theme.spacing(3),
    height: 64,
    width: 64,
    fontSize: 32,
  },
  loginForm: {
    marginTop: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  person: {
    marginTop: theme.spacing(2),
    display: "flex",
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  title: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "28px",
  },
  tambahLagu: {
    backgroundColor: "black",

    color: "white",
    width: "32px",
    height: "29px",
    borderRadius: "6px",
    cursor: "pointer",

    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
  textTambahLagu: {
    marginLeft: "10px",
    marginTop: "5px",
    fontSize: "14px",
    color: "#687083",
  },
  btnDialog: {
    backgroundColor: "black",

    color: "white",
    padding: "10px 28px",

    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
  btnSubmit: {
    backgroundColor: "black",

    color: "white",
    padding: "10px 28px",

    height: "40px",
    borderRadius: "6px",

    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
  headText: {
    fontSize: "12px",
    color: "#687083",
    fontWeight: "bold",
  },
  bodyTextBlack: {
    fontSize: "14px",
    fontWeight: "regular",
    color: "#111827",
  },
  bodyTextGrey: {
    fontSize: "14px",
    fontWeight: "regular",
    color: "#687083",
  },
  tableRow: {
    height: 20,
  },
  conTextBlack: {
    fontSize: "14px",
    fontWeight: "bold",
  },
  dropdown: {
    color: "black",
  },
  label: {
    color: "#364052",
    fontSize: "14px",
    fontWeight: 500,
  },
  subTitle: {
    fontSize: "18px",
    fontWeight: 700,
    color: " #111827",
  },
  breadcrumbs: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#111827",
  },
  breadCrumbsActive: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#687083",
  },
  rootSwitch: {
    width: "40px",
    height: "20px",
    padding: "0px",
    borderRadius: "10px",

    marginRight: "8px",
    position: "relative",
  },
  switchBase: {
    padding: "10px",
    color: "white",
    "&.Mui-checked": {},
    "&.Mui-checked + $track": {
      border: "none",
      color: "white",
      backgroundColor: "#606060",
      opacity: 1,
    },
  },
  checked: {},
  thumb: {
    color: "white",
    position: "absolute",
    width: "16px",
    height: "16px",
  },
  track: {
    backgroundColor: "#E4E7EB",
    borderRadius: "25px",
    border: "0px solid #606060",
    height: "auto",
    opacity: 1,
  },

  pageSize: {
    height: "30px",
    borderRadius: "6px",
    border: "1px solid #D1D5DC",
    paddingLeft: "5px",
    paddingRight: "5px",
    marginTop: "5px",
  },
  paginationText: {
    fontSize: "14px",
    fontWeight: "normal",
    fontStyle: "normal",
    color: "#687083",
  },
  pagination: {
    "& .Mui-selected": {
      backgroundColor: "black",
      color: "white",
    },
  },
  btnDetail: {
    backgroundColor: "#111827",
    color: "white",
    width: "30px",
    height: "30px",
    padding: "6px",
    borderRadius: "6px",
    marginRight: "10px",
  },
}));

function EditKontrak({ id }) {
  const classes = useStyles();
  const [timePeriod, setTimePeriod] = useState("1");
  const [contractNumber, setContractNumber] = useState("");
  const [startDate, setStartDate] = useState(moment().toString());
  const [endDate, setEndDate] = useState(moment().toString());
  const [dateRange, setDateRange] = useState(0);
  const [sharePercentage, setSharePercentage] = useState("");
  const [keterangan, setKeterangan] = useState("");
  const [open, setOpen] = useState(false);
  const [wordsSong, setWordsSong] = useState("");
  const [isLoadSong, setIsLoadSong] = useState(true);
  const [songList, setSongList] = useState([]);
  const [searchSongList, setSearchSongList] = useState([]);
  const [advanceValueList, setAdvanceValueList] = useState([]);
  const [agentList, setAgentList] = useState([]);
  const [agentValueList, setAgentValueList] = useState([]);
  const [getColor, setGetColor] = useState("");
  const [penciptaList, setPenciptaList] = useState([]);
  const checked = false;
  const [composerList, setComposerList] = useState([]);
  const [advanceValueCheck, setAdvanceValueCheck] = useState(false);
  const [pageAdvanceValue, setPageAdvanceValue] = useState(1);
  const [rowsPerPageAdvanceValue, setRowsPerPageAdvanceValue] = useState(5);
  const [sharePercentageList, setSharePercentageList] = useState([]);
  const [pageSharePercentage, setPageSharePercentage] = useState(1);
  const [rowsPerPageSharePercentage, setRowsPerPageSharePercentage] = useState(
    5
  );
  const pageSharePercentageValue = 1;
  const [sharePercentageValue, setSharePercentageValue] = useState(0);
  const [agentSwitch, setAgentSwitch] = useState(false);
  const [publisherShareList, setPublisherShareList] = useState([]);
  const [publisherShareFilterValue, setPublisherShareFilterValue] = useState(0);
  const [pagePublisherShare, setPagePublisherShare] = useState(1);
  const [rowsPerPagePublisherShare, setRowsPerPagePublisherShare] = useState(5);
  const [pagePublisherShareValue] = useState(1);
  const [advanceFilterValue, setAdvanceFilterValue] = useState(0);
  const [agentFilterValue, setAgentFilterValue] = useState(0);
  const [pageAgent, setPageAgent] = useState(1);
  const [rowsPerPageAgent, setRowsPerPageAgent] = useState(5);
  const [perpuityContract, setPerpuityContract] = useState(false);
  const [contractStatus, setContractStatus] = useState("");
  function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={values => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        isNumericString
      />
    );
  }

  const handleChangePerpuityContract = event => {
    setPerpuityContract(event.target.checked);
  };

  const handleChangePageAgent = (event, newPage) => {
    setPageAgent(newPage);
  };

  const handleChangeAgentFilter = event => {
    setAgentFilterValue(event.target.value);
  };
  const handleChangePublisherShareFilter = event => {
    setPublisherShareFilterValue(event.target.value);
  };

  const handleChangeAdvanceFilter = event => {
    setAdvanceFilterValue(event.target.value);
  };

  const getDetailContract = () => {
    const url = `${hardBaseUrl}/publisher/contract/${id}`;
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .get(url, config)
      .then(res => {
        const newDataPenciptaList = res.data.data.songs.map(data => ({
          ...data,
          title: data.title,
        }));

        setPenciptaList(newDataPenciptaList);

        const newDataSharePercentageList = res.data.data.songs.map(data => ({
          ...data,
        }));
        setContractStatus(res.data.data.contract.approval_status);
        setComposerList(res.data.data.composers);
        setSharePercentageList(newDataSharePercentageList);
        setSongList(res.data.data.songs);
        setPublisherShareList(
          res.data.data.songs.map(el => {
            const mappingPublisherShare = el.composers?.map(data => ({
              song_title: el.title,
              song_id: el.song_id,
              ...data,
            }));

            return mappingPublisherShare;
          })
        );

        setAdvanceValueList(
          res.data.data.songs.map(el => {
            const mappingSongBalance = el.composers?.map(data => ({
              song_title: el.title,
              song_id: el.song_id,
              ...data,
            }));
            if (mappingSongBalance?.composers?.length) {
              setAdvanceValueCheck(true);
            }
            return mappingSongBalance;
          })
        );
        setAgentList(res.data.data.agents);
        setContractNumber(res.data.data.contract.contract_number);
        setStartDate(new Date(res.data.data.contract.start_date));
        setEndDate(new Date(res.data.data.contract.end_date));
        setKeterangan(res.data.data.contract.description);
        setSharePercentage(res.data.data.contract.composer_share_percentage);
        setPerpuityContract(res.data.data.contract.is_endless_period);
        if (res.data.data.contract.type_period === "year") {
          setTimePeriod("2");
        } else {
          setTimePeriod("1");
        }
        setDateRange(res.data.data.contract.date_period);
      })
      .catch(() => { });
  };
  useEffect(() => {
    getDetailContract();
  }, []);

  const publisherShareSlice = publisherShareList.slice(
    (pagePublisherShare - 1) * rowsPerPagePublisherShare,
    pagePublisherShare * rowsPerPagePublisherShare
  );

  const handleChangeAgentSwitch = event => {
    setAgentSwitch(event.target.checked);
  };

  const pagesCountSharePercentage = song_composer => {
    return Math.ceil(song_composer.length / rowsPerPageSharePercentage);
  };
  const pagesCountAdvance = song_list => {
    return Math.ceil(song_list.length / rowsPerPageAdvanceValue);
  };

  const pagesCountPublisherShare = song_list => {
    return Math.ceil(song_list.length / rowsPerPagePublisherShare);
  };

  const sharePercentageSlice = sharePercentageList.slice(
    (pageSharePercentageValue - 1) * rowsPerPageSharePercentage,
    pageSharePercentageValue * rowsPerPageSharePercentage
  );
  useEffect(() => { }, [
    rowsPerPageSharePercentage,
    pageSharePercentageValue,
    sharePercentageList,
    pageSharePercentage,
    penciptaList,
    rowsPerPagePublisherShare,
    pagePublisherShareValue,
    publisherShareList,
    publisherShareSlice,
  ]);
  const handleChangeComposerSharePercentage = event => {
    setSharePercentageValue(event.target.value);
  };

  const handleChangeRowsPerPageAdvanceValue = event => {
    event.preventDefault();
    setRowsPerPageAdvanceValue(event.target.value);

    setPageAdvanceValue(1);
  };

  const handleChangeRowsPerPagePublisherShare = event => {
    event.preventDefault();
    setRowsPerPagePublisherShare(event.target.value);

    setPagePublisherShare(1);
  };

  const handleChangePagePublisherShare = (event, newPage) => {
    event.preventDefault();

    setPagePublisherShare(newPage);
  };

  const pageSizePaginationPublisherShare = () => {
    return (
      <div className={classes.pageSize}>
        <select
          style={{
            backgroundColor: "transparent",
            border: "none",
            outline: "none",
            marginTop: "4px",
          }}
          value={rowsPerPagePublisherShare}
          onChange={handleChangeRowsPerPagePublisherShare}
        >
          {[5, 20, 50].map(pageSize => {
            return (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            );
          })}
        </select>
      </div>
    );
  };
  const handleChangeRowsPerPageAgent = event => {
    event.preventDefault();
    setRowsPerPageAgent(event.target.value);

    setPageAgent(1);
  };

  const pageSizeAgent = () => {
    return (
      <div className={classes.pageSize}>
        <select
          style={{
            backgroundColor: "transparent",
            border: "none",
            outline: "none",
            marginTop: "4px",
          }}
          value={rowsPerPageAgent}
          onChange={handleChangeRowsPerPageAgent}
        >
          {[5, 20, 50].map(pageSize => {
            return (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            );
          })}
        </select>
      </div>
    );
  };

  const pageSizePaginationAdvanceValue = () => {
    return (
      <div className={classes.pageSize}>
        <select
          style={{
            backgroundColor: "transparent",
            border: "none",
            outline: "none",
            marginTop: "4px",
          }}
          value={rowsPerPageAdvanceValue}
          onChange={handleChangeRowsPerPageAdvanceValue}
        >
          {[5, 20, 50].map(pageSize => {
            return (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            );
          })}
        </select>
      </div>
    );
  };
  const handleChangePageSharePercentage = (event, newPage) => {
    event.preventDefault();
    setPageSharePercentage(newPage);
  };

  const handleChangeRowsPerPageSharePercentage = event => {
    event.preventDefault();
    setRowsPerPageSharePercentage(event.target.value);

    setPageSharePercentage(1);
  };

  const pageSizePaginationSharePercentage = () => {
    return (
      <div className={classes.pageSize}>
        <select
          style={{
            backgroundColor: "transparent",
            border: "none",
            outline: "none",
            marginTop: "4px",
          }}
          value={rowsPerPageSharePercentage}
          onChange={handleChangeRowsPerPageSharePercentage}
        >
          {[5, 20, 50].map(pageSize => {
            return (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            );
          })}
        </select>
      </div>
    );
  };
  const handleChangePageAdvanceValue = (event, newPage) => {
    event.preventDefault();
    setPageAdvanceValue(newPage);
  };

  const handleChangeAdvanceValueCheck = event => {
    setAdvanceValueCheck(event.target.checked);
  };

  const handleTimePeriodYear = () => {
    setTimePeriod("2");
  };

  const handleTimePeriodUnlimited = () => {
    setTimePeriod("1");
  };
  const handleChangeContractNumber = e => {
    setContractNumber(e.target.value);
  };

  const handleDateRangeChange = event => {
    setEndDate(
      new Date(startDate.getTime() + event.target.value * 24 * 60 * 60 * 1000)
    );

    setDateRange(event.target.value);
  };

  const handleDateRangeChangeYear = event => {
    setEndDate(
      new Date(
        startDate.getTime() + event.target.value * 365 * 24 * 60 * 60 * 1000
      )
    );
    setDateRange(event.target.value);
  };

  const handleDateChangeStartDate = date => {
    setStartDate(date);
  };

  const handleDateChangeEndDate = date => {
    if (date < startDate) {
      setStartDate(date);
    } else {
      setEndDate(date);
    }
  };

  const handleChangeText = e => {
    setKeterangan(e.target.value);
  };

  const openModalLagu = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const changeSearchSong = e => {
    setIsLoadSong(true);
    setWordsSong(e);
  };

  const tableRowClick = (value, index, checked) => {
    const dataComposer = [];
    if (checked) {
      let updatedList = searchSongList.map(item => {
        if (item.song_id == value.song_id) {
          return { ...item, checked: false };
        }
        return item;
      });

      setSearchSongList(updatedList);
      setSongList(songList =>
        songList.filter(item => item.song_id !== value.song_id)
      );
      setPenciptaList(penciptaList =>
        penciptaList.filter(item => item.song_id !== value.song_id)
      );
      setSharePercentageList(sharePercentageList =>
        sharePercentageList.filter(item => item.song_id !== value.song_id)
      );
    } else {
      let updatedList = searchSongList.map(item => {
        if (item.song_id == value.song_id) {
          return { ...item, checked: true };
        }
        return item;
      });

      setSearchSongList(updatedList);
      value.song_composer.map(value => {
        dataComposer.push({
          composer_id: value.composer_id,
          composer_name: value.composer?.sure_name || "",
          ownership_percentage: value.percentage_ownership,
          publisher_share: value.publisher_share,
          song_balance: {
            ContractComposerID: value.songs[0].composer_id,
            balance: value.songs[0].advance_balance,
            balance_formatted: "",
            end_period: value.songs[0].end_period,
            start_period: value.songs[0].start_period,
            song_id: value.songs[0].song_id,
            song_title: value.songs[0].song_title,
          },
        });
      });
      setSongList([
        ...songList,
        {
          composers: dataComposer,
          iswc_code: value.iswc_code,
          song_id: value.song_id,
          title: value.title_song,
        },
      ]);
      setPenciptaList([
        ...songList,
        {
          composers: dataComposer,
          iswc_code: value.iswc_code,
          song_id: value.song_id,
          title: value.title_song,
        },
      ]);
      setSharePercentageList(sharePercentageList => [
        ...sharePercentageList,
        {
          ...songList,
          song_composer: dataComposer.map(item => ({
            ...item,
            share_percentage: 0,
          })),
        },
      ]);
    }
  };

  const listPencipta = data => {
    let pencipta = data;

    if (pencipta === null || undefined || "") {
      return <div>-</div>;
    } else if (pencipta?.length <= 3) {
      return pencipta.map((pencipta, index) => (
        <div key={index}>
          <ThemeProvider theme={theme}>
            <Chip
              key={index}
              label={pencipta.composer.sure_name}
              className={classes.tableChip}
              size="small"
            />
          </ThemeProvider>
        </div>
      ));
    } else if (pencipta?.length > 3) {
      let pencipta1 = pencipta?.slice(0, 3);
      let pencipta2 = pencipta?.slice(3, pencipta?.length);
      return (
        <div>
          <ThemeProvider theme={theme}>
            {pencipta1?.map((pencipta, index) => (
              <Chip
                key={index}
                label={pencipta.composer.sure_name}
                className={classes.tableChip}
                size="small"
              />
            ))}
            <Chip
              label={"+" + pencipta2.length}
              className={classes.tableChip}
              size="small"
            />
          </ThemeProvider>
        </div>
      );
    }
  };

  const handleReset = () => {
    setOpen(false);
  };

  const getResponse = async () => {
    try {
      const token = localStorage.getItem("token");

      const url =
        hardBaseUrl +
        `/publisher/contract-get-song?page=1&size=10&id=${id}&search=` +
        wordsSong;

      const options = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      const res = await axios.get(url, options);

      const data = [];
      res.data.data.map(item => {
        if (songList) {
          songList.map(itemCheck => {
            if (item.song_id === itemCheck.song_id) {
              data.push({
                ...item,
                checked: true,
              });
            } else {
              data.push({
                ...item,
                checked: false,
              });
            }
          });
        } else {
          data.push({
            ...item,
            checked: false,
          });
        }
        setSearchSongList(data);
      });

      setIsLoadSong(false);
    } catch (error) {
      setIsLoadSong(false);
      throw error;
    }
  };

  useEffect(() => {
    getResponse();
    const getMainDsp = async () => {
      try {
        const token = localStorage.getItem("token");
        const url = `${hardBaseUrl}/me`;
        const res = await axios.get(url, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        setGetColor(res.data.data.publisher.theme_color);
      } catch (e) {
        new Error(e);
      }
    };
    getMainDsp();
  }, [checked, startDate, endDate, wordsSong]);

  const handleDeleteChip = (e, id, songId, index) => {
    e.stopPropagation();
    const findComposersArray = penciptaList.find(
      ({ song_id }) => song_id === songId
    );
    const filterPenciptaList = findComposersArray?.composers?.filter(
      (_, idx) => idx !== index
    );
    const newFilteredObjWithComposers = {
      ...findComposersArray,
      composers: filterPenciptaList,
    };
    const filteredPenciptaList = penciptaList.filter(
      ({ song_id }) => song_id !== songId
    );
    const newPenciptaList = [
      ...filteredPenciptaList,
      newFilteredObjWithComposers,
    ];
    setPenciptaList(newPenciptaList);
    setSharePercentageList(prev =>
      [...prev].map(item => ({
        ...item,
        composers: item?.composers?.filter(
          item => item.composer_id + item.song_id !== id + songId
        ),
      }))
    );
  };

  const listPenciptaChip = (data, songId) => {
    return (
      <div>
        {data.length > 1
          ? data.map((pencipta, idx) => (
            <Chip
              key={pencipta.composer_id}
              label={pencipta.composer_name}
              className={classes.tableChip}
              size="small"
              onDelete={e =>
                handleDeleteChip(e, pencipta.composer_id, songId, idx)
              }
            />
          ))
          : data.map(pencipta => (
            <Chip
              key={pencipta.composer_id}
              label={pencipta.composer_name}
              className={classes.tableChip}
              size="small"
            />
          ))}
      </div>
    );
  };

  const handleChangeAdvanceValueList = (
    e,
    idComposer,
    indexAdvance,
    idSong
  ) => {
    const newData = [...advanceValueList];

    const indexComposer = advanceValueList[indexAdvance].songs.findIndex(
      item => item.composer_id === idComposer && item.song_id === idSong
    );

    newData[indexAdvance].songs[indexComposer].value = e.target.value;

    setAdvanceValueList(newData);
  };

  const handleChangeStartPeriod = (date, idComposer, indexAdvance, idSong) => {
    const newData = [...advanceValueList];
    const indexComposer = advanceValueList[indexAdvance].findIndex(
      item => item.composer_id === idComposer && item.song_id === idSong
    );

    newData[indexAdvance][indexComposer].song_balance.start_period = date;

    setAdvanceValueList(newData);
  };

  const handleChangeEndPeriod = (date, idComposer, indexAdvance, idSong) => {
    const newData = [...advanceValueList];

    const indexComposer = advanceValueList[indexAdvance].findIndex(
      item => item.composer_id === idComposer && item.song_id === idSong
    );

    newData[indexAdvance][indexComposer].song_balance.end_period = date;

    setAdvanceValueList(newData);
  };

  const handleChangeSharePercentageShare = (e, idComposer, idSong) => {
    const newData = [...sharePercentageList];
    const index = sharePercentageList.findIndex(
      item => item.song_id === idSong
    );

    const indexComposer = sharePercentageList[index].song_composer.findIndex(
      item => item.composer_id === idComposer
    );
    newData[index].song_composer[indexComposer].share_percentage =
      e.target.value;
    setSharePercentageList(newData);
  };

  const handleChangeAgentType = (e, idComposer, indexAgent, idSong) => {
    const newData = [...agentList];

    const indexComposer = agentList[indexAgent].songs.findIndex(
      item => item.composer_id === idComposer && item.song_id === idSong
    );

    newData[indexAgent].songs[indexComposer].type = e.target.value;

    setAgentList(newData);
  };

  const handleChangeAgentValueList = (e, idComposer, indexAgent, idSong) => {
    const newData = [...agentList];

    const indexComposer = agentList[indexAgent].songs.findIndex(
      item => item.composer_id === idComposer && item.song_id === idSong
    );

    newData[indexAgent].songs[indexComposer].agent_id = e.target.value;

    setAgentList(newData);
  };

  const handleChangeAgentFee = (e, idComposer, indexAgent, idSong) => {
    const newData = [...agentList];

    const indexComposer = agentList[indexAgent].songs.findIndex(
      item => item.composer_id === idComposer && item.song_id === idSong
    );

    newData[indexAgent].songs[indexComposer].value = e.target.value;

    setAgentList(newData);
  };

  const handleChangePublisherShare = (e, idComposer, indexPub, idSong) => {
    const newData = [...publisherShareList];

    const indexComposer = publisherShareList[indexPub].findIndex(
      item => item.composer_id === idComposer && item.song_id === idSong
    );

    newData[indexPub][indexComposer].publisher_share = e.target.value;

    setPublisherShareList(newData);
  };

  const getAgentList = () => {
    const token = localStorage.getItem("token");
    const url = `${hardBaseUrl}/publisher/agent-on-transaction`;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .get(url, config)
      .then(res => {
        setAgentValueList(res.data.data);
      })
      .catch(() => { });
  };
  useEffect(() => {
    getAgentList();
  }, [agentList]);
  const getDateFormatForFilter = date => {
    let dateFormat = new Date(date);
    let day = dateFormat
      .getDate()
      .toString()
      .padStart(2, "0");
    let month = (dateFormat.getMonth() + 1).toString().padStart(2, "0");
    let year = dateFormat.getFullYear();
    let dateString = `${year}-${month}-${day}`;
    return dateString;
  };

  const handleButtonSimpan = e => {
    e.preventDefault();
    const token = localStorage.getItem("token");

    const getOnlySongsAdvance = advanceValueList.map(item => item);

    const combineSongsAdvance = getOnlySongsAdvance?.reduce(
      (acc, val) => acc.concat(val),
      []
    );

    const removeDuplicateAdvance = combineSongsAdvance?.filter(
      (item, index) =>
        combineSongsAdvance?.findIndex(
          item2 =>
            item2.composer_id === item.composer_id &&
            item2.song_id === item.song_id
        ) === index
    );

    const getValue = value => {
      if (typeof value === "string") {
        if (value === "") {
          return 0;
        } else {
          return Number(value.replace(/,/g, ""));
        }
      } else if (typeof value === "number") {
        if (value === 0) {
          return 0;
        } else {
          return value;
        }
      }
    };

    const newDataAdvance = removeDuplicateAdvance?.map(item => {
      return {
        composer_id: item?.composer_id,
        song_id: item?.song_id,
        balance: getValue(item.value),
        start_period: getDateFormatForFilter(item?.start_period),
        end_period: getDateFormatForFilter(item?.end_period),
      };
    });
    const newDataOwnership = sharePercentageList?.map(
      ({ composers, song_id }) => {
        console.log({ composers, sharePercentageList });
        return {
          song_id,
          composer_share_percentages: (composers || []).map(
            ({ composer_id, ownership_percentage }) => {
              return {
                composer_id,
                ownership_percentage:
                  ownership_percentage === ""
                    ? 0
                    : Number(ownership_percentage),
              };
            }
          ),
        };
      }
    );

    const getOnlySongsAgent = agentList.map(item => item.songs);
    const combineSongsAgent = getOnlySongsAgent.reduce(
      (acc, val) => acc.concat(val),
      []
    );
    const removeDuplicateAgent = combineSongsAgent.filter(
      (item, index) =>
        combineSongsAgent.findIndex(
          item2 =>
            item2.composer_id === item.composer_id &&
            item2.song_id === item.song_id
        ) === index
    );
    const newDataAgent = removeDuplicateAgent.map(item => {
      return {
        song_id: item.song_id,
        composer_id: item.composer_id,
        agent_id: item.agent_id,
        agent_fee: getValue(item.value),
        fee_type: item.type,
      };
    });

    const removeDuplicatePublisherShare = publisherShareList
      .flat(1)
      .filter(
        (item, index) =>
          publisherShareList
            .flat(1)
            .findIndex(
              item2 =>
                item2.composer_id === item.composer_id &&
                item2.song_id === item.song_id
            ) === index
      );

    const newDataPublisher = removeDuplicatePublisherShare
      .map(item => {
        return {
          composer_id: item.composer_id,
          song_percentages: removeDuplicatePublisherShare
            .map(item2 => {
              return {
                song_id: item2.song_id,
                percentage:
                  item2.publisher_share === 0
                    ? 0
                    : Number(
                      typeof item2.publisher_share === "string"
                        ? item2.publisher_share.replace(/,/g, "")
                        : item2.publisher_share
                    ),
                composer_id: item2.composer_id,
              };
            })
            .filter(item3 => item3.composer_id === item.composer_id),
        };
      })

      .filter(
        (item, index, self) =>
          index === self.findIndex(t => t.composer_id === item.composer_id)
      );
    const payload = {
      contract_number: contractNumber,
      date_periode: Number(dateRange),
      start_date: getDateFormatForFilter(startDate),
      end_date: getDateFormatForFilter(endDate),
      description: keterangan,
      publisher_share_percentage: getValue(sharePercentage),
      contract_composer_balances: newDataAdvance,
      ownership_percentages: newDataOwnership,
      publisher_share_percentages: newDataPublisher,
      contract_composer_agents: newDataAgent,
      is_endless_period: perpuityContract,
      type_period: timePeriod === "1" ? "unlimited" : "year",
    };
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const url = `${hardBaseUrl}/publisher/contract/${id}`;
    axios
      .put(url, payload, config)
      .then(() => {
        Swal.fire({
          title: "Success",
          text: "Data has been edited",
          icon: "success",
          confirmButtonText: "OK",
        }).then(result => {
          if (result.isConfirmed) {
            window.location.href = "/admin/kontrak-composer";
          }
        });
      })
      .catch(err => {
        Swal.fire({
          title: "Error",
          text: err.response.data.errors.message,
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };

  return (
    <>
      <Grid container>
        <div>
          <ThemeProvider theme={theme}>
            <Typography
              component="h1"
              variant="h3"
              className={classes.subTitle}
            >
              Song Contract Information
            </Typography>
          </ThemeProvider>
          <ThemeProvider theme={theme}>
            <p
              style={{
                marginTop: "5px",
                color: "#8f8f8f",
                fontSize: "14px",
              }}
            >
              Form to fill out information data related to the contract to be
              made
            </p>
          </ThemeProvider>
        </div>
      </Grid>
      <div
        style={{
          marginBottom: "20px",
        }}
      >
        <Button
          onClick={handleTimePeriodUnlimited}
          className={classes.btnDialog}
          style={{
            backgroundColor: timePeriod === "1" ? "black" : "#FFFFFF",
            marginRight: "10px",
            borderColor: timePeriod === "1" ? "#FFFFFF" : "black",
            border: "1px solid #9AA2B1",
          }}
        >
          <ThemeProvider theme={theme}>
            <Typography
              style={{
                textTransform: "none",
                color: timePeriod === "1" ? "#FFFFFF" : "black",
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "20px",
                textDecoration: "none",
              }}
            >
              Perpetual
            </Typography>
          </ThemeProvider>
        </Button>
        <Button
          onClick={handleTimePeriodYear}
          className={classes.btnDialog}
          variant="contained"
          style={{
            backgroundColor: timePeriod === "2" ? "black" : "#FFFFFF",
            borderColor: timePeriod === "2" ? "#FFFFFF" : "black",
            border: "1px solid #9AA2B1",
          }}
        >
          <ThemeProvider theme={theme}>
            <Typography
              style={{
                textTransform: "none",
                color: timePeriod === "2" ? "#FFFFFF" : "black",
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "20px",
                textDecoration: "none",
              }}
            >
              Year
            </Typography>
          </ThemeProvider>
        </Button>
      </div>

      <Grid container>
        <Grid item xs={8} md={8} sm={8} lg={8} xl={8}>
          <Card
            style={{
              border: "1px solid #9AA2B1",
              borderRadius: "6px",
              boxShadow: "none",
            }}
          >
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={6} md={6} sm={6} lg={6} xl={6}>
                  <div
                    style={{
                      marginTop: "5px",
                      marginBottom: "5px",
                    }}
                  >
                    <div
                      style={{
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      <ThemeProvider theme={theme}>
                        <Typography
                          variant="subtitle2"
                          className={classes.label}
                        >
                          Contract Number
                        </Typography>
                      </ThemeProvider>
                    </div>
                    <div
                      style={{
                        marginTop: "-10px",
                        marginBottom: "10px",
                      }}
                    >
                      <TextField
                        variant="outlined"
                        value={contractNumber}
                        onChange={handleChangeContractNumber}
                        name="numberformat"
                        id="formatted-numberformat-input"
                        margin="dense"
                        disabled={
                          contractStatus === "waiting_for_approval" ||
                            contractStatus === "waiting_for_editing_approval"
                            ? false
                            : true
                        }
                        fullWidth={true}
                      />
                    </div>
                  </div>
                </Grid>
                <Grid item xs={6} md={6} sm={6} lg={6} xl={6}>
                  <div
                    style={{
                      marginTop: "5px",
                      marginBottom: "5px",
                    }}
                  >
                    <div
                      style={{
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      <ThemeProvider theme={theme}>
                        <Typography
                          variant="subtitle2"
                          className={classes.label}
                        >
                          Time Period
                        </Typography>
                      </ThemeProvider>
                    </div>
                    <div
                      style={{
                        marginTop: "-10px",
                        marginBottom: "10px",
                      }}
                    >
                      <TextField
                        variant="outlined"
                        onChange={
                          timePeriod === "1"
                            ? handleDateRangeChange
                            : handleDateRangeChangeYear
                        }
                        name="numberformat"
                        id="formatted-numberformat-input"
                        margin="dense"
                        value={dateRange}
                        disabled={
                          contractStatus === "waiting_for_approval" ||
                            contractStatus === "waiting_for_editing_approval"
                            ? false
                            : true
                        }
                        fullWidth={true}
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                          endAdornment: (
                            <InputAdornment position="end">
                              {timePeriod === "1" ? "Day" : "Year"}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  </div>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6} md={6} sm={6} lg={6} xl={6}>
                  <div
                    style={{
                      marginTop: "5px",
                      marginBottom: "5px",
                    }}
                  >
                    <div
                      style={{
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      <ThemeProvider theme={theme}>
                        <Typography
                          variant="subtitle2"
                          className={classes.label}
                        >
                          Start Date
                        </Typography>
                      </ThemeProvider>
                    </div>
                    <div
                      style={{
                        marginTop: "-10px",
                        marginBottom: "10px",
                      }}
                    >
                      <KeyboardDatePicker
                        id="outlined-basic"
                        variant="outlined"
                        onChange={handleDateChangeStartDate}
                        value={startDate}
                        name="startDate"
                        disabled={
                          contractStatus === "waiting_for_approval" ||
                            contractStatus === "waiting_for_editing_approval"
                            ? false
                            : true
                        }
                        format="DD MMMM YYYY"
                        inputVariant="outlined"
                        fullWidth={true}
                        margin="dense"
                      />
                    </div>
                  </div>
                </Grid>

                <Grid item xs={6} md={6} sm={6} lg={6} xl={6}>
                  <div
                    style={{
                      marginTop: "5px",
                      marginBottom: "5px",
                    }}
                  >
                    <div
                      style={{
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      <ThemeProvider theme={theme}>
                        <Typography
                          variant="subtitle2"
                          className={classes.label}
                        >
                          End Date
                        </Typography>
                      </ThemeProvider>
                    </div>
                    <div
                      style={{
                        marginTop: "-10px",
                        marginBottom: "10px",
                      }}
                    >
                      <KeyboardDatePicker
                        id="outlined-basic"
                        value={perpuityContract ? null : endDate}
                        onChange={handleDateChangeEndDate}
                        format="DD MMMM YYYY"
                        name="endDate"
                        disabled={
                          contractStatus === "approved" ||
                            contractStatus === "waiting_for_approval" ||
                            contractStatus === "waiting_for_editing_approval"
                            ? false
                            : true
                        }
                        inputVariant="outlined"
                        fullWidth={true}
                        placeholder="-"
                        margin="dense"
                      />
                      <div style={{ display: "flex" }}>
                        <Switch
                          checked={perpuityContract}
                          onChange={handleChangePerpuityContract}
                          name="checkedB"
                          color="primary"
                          disabled={
                            contractStatus === "approved" ||
                              contractStatus === "waiting_for_approval" ||
                              contractStatus === "waiting_for_editing_approval"
                              ? false
                              : true
                          }
                          classes={{
                            root: classes.rootSwitch,
                            switchBase: classes.switchBase,
                            thumb: classes.thumb,
                            track: classes.track,
                            checked: classes.checked,
                          }}
                        />
                        <p>This contract will perpetuity</p>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>

              <Grid item xs={12} md={12} sm={12} lg={12} xl={12}>
                <div
                  style={{
                    marginTop: "5px",
                    marginBottom: "5px",
                  }}
                >
                  <div
                    style={{
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    <ThemeProvider theme={theme}>
                      <Typography variant="subtitle2" className={classes.label}>
                        Description
                      </Typography>
                    </ThemeProvider>
                  </div>
                  <div
                    style={{
                      marginTop: "-10px",
                      marginBottom: "10px",
                    }}
                  >
                    <TextField
                      multiline={true}
                      minRows={10}
                      id="outlined-basic"
                      placeholder="Description"
                      variant="outlined"
                      fullWidth={true}
                      margin="dense"
                      name="keterangan"
                      onChange={e => handleChangeText(e)}
                      value={keterangan}
                    />
                  </div>
                </div>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4} md={4} sm={4} lg={4} xl={4}></Grid>
      </Grid>

      { }

      <Grid container style={{ marginTop: "20px" }}>
        <div>
          <ThemeProvider theme={theme}>
            <Typography
              component="h1"
              variant="h3"
              className={classes.subTitle}
            >
              Song
            </Typography>
          </ThemeProvider>
          <ThemeProvider theme={theme}>
            <p
              style={{
                marginTop: "5px",
                color: "#8f8f8f",
                fontSize: "14px",
              }}
            >
              This feature can add multiple songs with multiple composer/author
              in contract
            </p>
          </ThemeProvider>
        </div>
      </Grid>

      <Grid container>
        <Grid item xs={8} md={8} sm={8} lg={8} xl={8}>
          <Grid container>
            <Grid item xs={12} md={12} sm={12} lg={12} xl={12}>
              <div
                style={{
                  marginTop: "5px",
                  marginBottom: "5px",
                }}
              >
                <div
                  style={{
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                >
                  <Paper
                    style={{
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      backgroundColor: "#F9FAFB",
                      cursor: "pointer",
                    }}
                    onClick={openModalLagu}
                  >
                    <Container>
                      <div style={{ display: "flex" }}>
                        <IconButton
                          onClick={openModalLagu}
                          className={classes.tambahLagu}
                          style={{
                            backgroundColor: " #111827",
                          }}
                        >
                          <AddIcon />
                        </IconButton>
                        <ThemeProvider theme={theme}>
                          <Typography
                            variant="subtitle2"
                            className={classes.textTambahLagu}
                          >
                            Click to add song
                          </Typography>
                        </ThemeProvider>
                      </div>
                    </Container>
                  </Paper>
                  <Dialog
                    fullWidth={true}
                    maxWidth={"md"}
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="max-width-dialog-title"
                  >
                    <DialogTitle id="max-width-dialog-title">
                      Search Song
                    </DialogTitle>
                    <Grid container>
                      <Grid item xs={12} md={12} sm={12} lg={12} xl={12}>
                        <Container>
                          <div style={{ display: "flex" }}>
                            <FormControl
                              variant="outlined"
                              style={{ display: "none", minWidth: 120 }}
                            >
                              <Select
                                native
                                value="name"
                                onChange={() => { }}
                                disabled
                                inputProps={{
                                  name: "age",
                                  id: "outlined-age-native-simple",
                                }}
                              >
                                <option
                                  value="name"
                                  style={{ color: "#687083" }}
                                >
                                  Song Title
                                </option>
                              </Select>
                            </FormControl>
                            <FormControl variant="outlined" fullWidth={true}>
                              <OutlinedInput
                                id="outlined-adornment-password"
                                placeholder="Search"
                                value={wordsSong}
                                onChange={e => {
                                  changeSearchSong(e.target.value);
                                }}
                              />
                            </FormControl>
                          </div>

                          <Grid container>
                            <Grid item xs={8} md={8} sm={8} lg={8} xl={8}>
                              <Divider className={classes.divider} />
                            </Grid>
                          </Grid>

                          <TableContainer style={{ maxHeight: 200 }}>
                            <Table stickyHeader size="small">
                              <TableHead>
                                <TableRow>
                                  <ThemeProvider theme={theme}>
                                    <TableCell>
                                      <Typography
                                        variant="subtitle2"
                                        className={classes.headText}
                                      >
                                        ISWC Code
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography
                                        variant="subtitle2"
                                        className={classes.headText}
                                      >
                                        Song Title
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography
                                        variant="subtitle2"
                                        className={classes.headText}
                                      >
                                        Composer/Author Name
                                      </Typography>
                                    </TableCell>
                                  </ThemeProvider>
                                  <TableCell></TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {isLoadSong ? (
                                  <>
                                    <TableRow>
                                      <TableCell align="center" colSpan={3}>
                                        <CircularProgress />
                                      </TableCell>
                                    </TableRow>
                                  </>
                                ) : (
                                  <>
                                    {searchSongList.length > 0
                                      ? searchSongList?.map((value, index) => (
                                        <TableRow
                                          hover
                                          key={value.song_id}
                                          onClick={() => {
                                            tableRowClick(
                                              value,
                                              index,
                                              value.checked
                                            );
                                          }}
                                        >
                                          <ThemeProvider theme={theme}>
                                            <TableCell>
                                              <Typography
                                                variant="subtitle2"
                                                className={
                                                  classes.bodyTextGrey
                                                }
                                              >
                                                {value.iswc_code}
                                              </Typography>
                                            </TableCell>
                                            <TableCell>
                                              <Typography
                                                variant="subtitle2"
                                                className={
                                                  classes.bodyTextBlack
                                                }
                                              >
                                                {value.title_song}
                                              </Typography>
                                            </TableCell>

                                            <TableCell>
                                              <Typography
                                                variant="subtitle2"
                                                className={
                                                  classes.bodyTextBlack
                                                }
                                              >
                                                {listPencipta(
                                                  value.song_composer
                                                )}
                                              </Typography>
                                            </TableCell>
                                          </ThemeProvider>
                                          <TableCell>
                                            {value.checked === true ? (
                                              <CheckIcon />
                                            ) : (
                                              ""
                                            )}
                                          </TableCell>
                                        </TableRow>
                                      ))
                                      : ""}
                                  </>
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Container>
                      </Grid>
                    </Grid>
                    <DialogContent></DialogContent>
                    <DialogActions>
                      <Button
                        onClick={handleReset}
                        className={classes.btnDialog}
                        style={{
                          backgroundColor: getColor || "black",
                        }}
                      >
                        <ThemeProvider theme={theme}>
                          <Typography
                            style={{
                              textTransform: "none",
                              color: "#FFFFFF",
                              fontSize: "14px",
                              fontWeight: 400,
                              lineHeight: "20px",
                              textDecoration: "none",
                            }}
                          >
                            Cancel
                          </Typography>
                        </ThemeProvider>
                      </Button>
                      <Button
                        onClick={handleClose}
                        className={classes.btnDialog}
                        variant="contained"
                        style={{
                          backgroundColor: getColor || "black",
                        }}
                      >
                        <ThemeProvider theme={theme}>
                          <Typography
                            style={{
                              textTransform: "none",
                              color: "#FFFFFF",
                              fontSize: "14px",
                              fontWeight: 400,
                              lineHeight: "20px",
                              textDecoration: "none",
                            }}
                          >
                            Choose
                          </Typography>
                        </ThemeProvider>
                      </Button>
                    </DialogActions>
                  </Dialog>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        style={{
          marginTop: "20px",
        }}
      ></Grid>
      <Grid container>
        <Grid item xs={8} md={8} sm={8} lg={8} xl={8}>
          <Grid container>
            <Grid item xs={12} md={12} sm={12} lg={12} xl={12}>
              <Card
                style={{
                  border: "1px solid #9AA2B1",
                  borderRadius: "6px",
                  boxShadow: "none",
                }}
              >
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <ThemeProvider theme={theme}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={classes.headText}
                          >
                            Song
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={classes.headText}
                          >
                            Composer/Author Name
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={classes.headText}
                          >
                            ISWC Code
                          </Typography>
                        </TableCell>
                      </ThemeProvider>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {penciptaList !== null ? (
                      penciptaList.length > 0 ? (
                        penciptaList.map(customer => (
                          <TableRow hover key={customer.composer_id}>
                            <TableCell>
                              <Typography
                                variant="subtitle2"
                                className={classes.bodyTextBlack}
                              >
                                {customer.title}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography
                                variant="subtitle2"
                                className={classes.bodyTextBlack}
                              >
                                {listPenciptaChip(
                                  customer.composers,
                                  customer.song_id
                                )}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography
                                variant="subtitle2"
                                className={classes.bodyTextGrey}
                              >
                                {customer.iswc_code === ""
                                  ? "-"
                                  : customer.iswc_code}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={4}>
                            <center>
                              <ThemeProvider theme={theme}>
                                <Typography
                                  variant="h6"
                                  style={{
                                    fontWeight: 400,
                                    color: "#687083",
                                    fontSize: "14px",
                                  }}
                                >
                                  No Song Selected Yet
                                </Typography>
                              </ThemeProvider>
                            </center>
                          </TableCell>
                        </TableRow>
                      )
                    ) : (
                      <TableRow>
                        <TableCell colSpan={3}>
                          <center>
                            <ThemeProvider theme={theme}>
                              <Typography
                                variant="h6"
                                style={{
                                  fontWeight: 400,
                                  color: "#687083",
                                  fontSize: "14px",
                                }}
                              >
                                no data
                              </Typography>
                            </ThemeProvider>
                          </center>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      { }

      <Grid
        container
        style={{
          marginTop: "20px",
        }}
      >
        <Grid item xs={4} md={4} sm={4} lg={8} xl={8}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <ThemeProvider theme={theme}>
                <Typography variant="h3" className={classes.subTitle}>
                  Advance Value
                </Typography>
              </ThemeProvider>
              <ThemeProvider theme={theme}>
                <p
                  style={{
                    marginTop: "5px",
                    color: "#8f8f8f",
                    fontSize: "14px",
                  }}
                >
                  Feature to set Advance Payment
                </p>
              </ThemeProvider>
            </div>
            <div style={advanceValueCheck ? null : { display: "none" }}>
              <FormControl
                className={classes.formControl}
                fullWidth={true}
                id="dsp-filter"
                style={{ width: "300px" }}
              >
                <TextField
                  fullWidth={true}
                  size="small"
                  className={classes.select}
                  onChange={handleChangeAdvanceFilter}
                  value={advanceFilterValue}
                  select
                  variant="outlined"
                >
                  <MenuItem value={0}>Select Composer</MenuItem>
                  {composerList.map((option, index) => (
                    <MenuItem key={index} value={option.composer_id}>
                      {option.sure_name}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </div>
          </div>

          <ThemeProvider theme={theme}>
            <Grid component="label" container alignItems="center" spacing={1}>
              <Grid item>
                <Checkbox
                  checked={advanceValueCheck}
                  onChange={handleChangeAdvanceValueCheck}
                  style={{
                    color: "#111827",
                  }}
                />
              </Grid>
              <Grid item>
                <p style={{ marginTop: "15px", marginLeft: "-10px" }}>
                  Set Advance Value For Composer/Author
                </p>
              </Grid>
            </Grid>
          </ThemeProvider>
          {advanceValueCheck ? (
            advanceValueList.length > 0 && advanceFilterValue !== 0 ? (
              <Card
                style={{
                  border: "1px solid #9AA2B1",
                  borderRadius: "6px",
                  boxShadow: "none",
                  marginTop: "10px",
                }}
              >
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <ThemeProvider theme={theme}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={classes.headText}
                          >
                            Song
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography
                            variant="subtitle2"
                            className={classes.headText}
                          >
                            Advance Value
                          </Typography>
                        </TableCell>

                        <TableCell align="left">
                          <Typography
                            variant="subtitle2"
                            className={classes.headText}
                          >
                            Start Period
                          </Typography>
                        </TableCell>

                        <TableCell align="left">
                          <Typography
                            variant="subtitle2"
                            className={classes.headText}
                          >
                            End Period
                          </Typography>
                        </TableCell>
                      </ThemeProvider>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {advanceValueList?.length ? (
                      advanceValueList
                        ?.map((data, index) =>
                          data.map(customer => {
                            return (
                              <TableRow hover key={customer.composer_id}>
                                <TableCell>
                                  <Typography
                                    variant="subtitle2"
                                    className={classes.bodyTextBlack}
                                  >
                                    {customer?.song_title}
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <div
                                    style={{
                                      marginTop: "10px",
                                      marginBottom: "10px",
                                    }}
                                  >
                                    <Grid style={{ display: "flex" }}>
                                      <Grid
                                        style={{
                                          width: "48px",
                                          height: "38px",
                                          marginRight: "-2px",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          borderRadius: "6px 0px 0px 6px",
                                          backgroundColor: "#F9FAFB",
                                          border: " 1px solid #D1D5DC",
                                          color: "#9AA2B",
                                          borderRight: "none",
                                        }}
                                      >
                                        Rp
                                      </Grid>
                                      <NumberFormat
                                        customInput={TextField}
                                        variant="outlined"
                                        thousandSeparator={true}
                                        onChange={e =>
                                          handleChangeAdvanceValueList(
                                            e,
                                            customer.composer_id,
                                            index,
                                            customer.song_id
                                          )
                                        }
                                        autoComplete="off"
                                        value={customer.song_balance.balance}
                                        className={classes.Input}
                                        style={{
                                          paddingLeft: "0px",
                                          height: "44px",
                                          borderRadius: "0px 6px 6px 0px",
                                        }}
                                        size="small"
                                        fullWidth
                                      />
                                    </Grid>
                                  </div>
                                </TableCell>

                                <TableCell>
                                  <KeyboardDatePicker
                                    id="outlined-basic"
                                    value={
                                      customer?.song_balance?.start_period ||
                                      null
                                    }
                                    onChange={e =>
                                      handleChangeStartPeriod(
                                        e,
                                        customer.composer_id,
                                        index,
                                        customer.song_id
                                      )
                                    }
                                    format="DD MMMM YYYY"
                                    name="startPeriod"
                                    inputVariant="outlined"
                                    fullWidth={true}
                                    margin="dense"
                                    placeholder="Tanggal Selesai"
                                  />
                                </TableCell>

                                <TableCell>
                                  <KeyboardDatePicker
                                    id="outlined-basic"
                                    value={
                                      customer?.song_balance?.end_period || null
                                    }
                                    onChange={e =>
                                      handleChangeEndPeriod(
                                        e,
                                        customer.composer_id,
                                        index,
                                        customer.song_id
                                      )
                                    }
                                    format="DD MMMM YYYY"
                                    name="endPeriod"
                                    inputVariant="outlined"
                                    fullWidth={true}
                                    margin="dense"
                                    placeholder="Tanggal Selesai"
                                  />
                                </TableCell>
                              </TableRow>
                            );
                          })
                        )
                        .slice(
                          (pageAdvanceValue - 1) * rowsPerPageAdvanceValue,
                          pageAdvanceValue * rowsPerPageAdvanceValue
                        )
                    ) : (
                      <TableRow>
                        <TableCell colSpan={4}>
                          <center>
                            <ThemeProvider theme={theme}>
                              <Typography
                                variant="h6"
                                style={{
                                  fontWeight: 400,
                                  color: "#687083",
                                  fontSize: "14px",
                                }}
                              >
                                no data
                              </Typography>
                            </ThemeProvider>
                          </center>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>

                { }
                <CardActions className={classes.actions}>
                  <Grid
                    alignContent="space-between"
                    container
                    justifyContent="space-between"
                    spacing={3}
                  >
                    <Grid item>
                      <div style={{ display: "flex" }}>
                        <div style={{ marginTop: "7px" }}>
                          <ThemeProvider theme={theme}>
                            <Typography className={classes.paginationText}>
                              Item per page:
                            </Typography>
                          </ThemeProvider>
                        </div>{" "}
                        <div style={{ marginLeft: "10px" }}>
                          {pageSizePaginationAdvanceValue()}
                        </div>
                      </div>
                    </Grid>

                    <Grid item>
                      <Pagination
                        count={pagesCountAdvance(advanceValueList?.length)}
                        shape="rounded"
                        className={classes.pagination}
                        page={pageAdvanceValue}
                        onChange={handleChangePageAdvanceValue}
                      />
                    </Grid>
                  </Grid>
                </CardActions>
                { }
              </Card>
            ) : (
              <Card
                style={{
                  border: "1px solid #9AA2B1",
                  borderRadius: "6px",
                  boxShadow: "none",
                  marginTop: "10px",
                }}
              >
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <ThemeProvider theme={theme}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={classes.headText}
                          >
                            Song
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography
                            variant="subtitle2"
                            className={classes.headText}
                          >
                            Advance Value
                          </Typography>
                        </TableCell>

                        <TableCell align="left">
                          <Typography
                            variant="subtitle2"
                            className={classes.headText}
                          >
                            Start Period
                          </Typography>
                        </TableCell>

                        <TableCell align="left">
                          <Typography
                            variant="subtitle2"
                            className={classes.headText}
                          >
                            End Period
                          </Typography>
                        </TableCell>
                      </ThemeProvider>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={4}>
                        <center>
                          <ThemeProvider theme={theme}>
                            <Typography
                              variant="h6"
                              style={{
                                fontWeight: 400,
                                color: "#687083",
                                fontSize: "14px",
                              }}
                            >
                              No Composer Selected Yet
                            </Typography>
                          </ThemeProvider>
                        </center>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Card>
            )
          ) : null}
        </Grid>

        <Grid item xs={4} md={4} sm={4} lg={4} xl={4}></Grid>
      </Grid>

      <Grid
        container
        style={{
          marginTop: "20px",
        }}
      >
        <Grid item xs={4} md={4} sm={4} lg={8} xl={8}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <ThemeProvider theme={theme}>
                <Typography variant="h3" className={classes.subTitle}>
                  Ownership Share Percentage
                </Typography>
              </ThemeProvider>
              <ThemeProvider theme={theme}>
                <p
                  style={{
                    marginTop: "5px",
                    color: "#8f8f8f",
                    fontSize: "14px",
                  }}
                >
                  Feature to set ownership share percentage
                </p>
              </ThemeProvider>
            </div>

            <div>
              <FormControl
                className={classes.formControl}
                fullWidth={true}
                id="dsp-filter"
                style={{ width: "300px" }}
              >
                <TextField
                  fullWidth={true}
                  size="small"
                  className={classes.select}
                  onChange={handleChangeComposerSharePercentage}
                  value={sharePercentageValue}
                  select
                  variant="outlined"
                >
                  <MenuItem value={0}>Select Song</MenuItem>
                  {songList.map((option, index) => (
                    <MenuItem key={index} value={option.song_id}>
                      {option.title}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </div>
          </div>

          {sharePercentageList.length > 0 && sharePercentageValue !== 0 ? (
            sharePercentageList.length > 0 &&
            sharePercentageList.map((item, index) => (
              <Card
                key={index}
                style={
                  item.song_id !== sharePercentageValue
                    ? { display: "none" }
                    : {
                      border: "1px solid #9AA2B1",
                      borderRadius: "6px",
                      boxShadow: "none",
                      marginTop: "10px",
                    }
                }
              >
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <ThemeProvider theme={theme}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={classes.headText}
                          >
                            Composer/Author Name
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={classes.headText}
                          >
                            Share Percentage
                          </Typography>
                        </TableCell>
                      </ThemeProvider>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {item?.composers?.length > 0 ? (
                      item?.composers
                        .map(customer => {
                          return (
                            <TableRow
                              hover
                              key={customer.song_id + customer.composer_id}
                            >
                              <TableCell>
                                <Typography
                                  variant="subtitle2"
                                  className={classes.bodyTextBlack}
                                >
                                  {customer.composer_name}
                                </Typography>
                              </TableCell>

                              <TableCell>
                                <div
                                  style={{
                                    marginTop: "10px",
                                    marginBottom: "10px",
                                  }}
                                >
                                  <TextField
                                    value={customer.ownership_percentage}
                                    variant="outlined"
                                    onChange={e =>
                                      handleChangeSharePercentageShare(
                                        e,
                                        customer.composer_id,
                                        item.song_id
                                      )
                                    }
                                    name="numberformat"
                                    id="formatted-numberformat-input"
                                    margin="dense"
                                    disabled={
                                      contractStatus === "approved"
                                        ? true
                                        : false
                                    }
                                    fullWidth={true}
                                    InputProps={{
                                      inputComponent: NumberFormatCustom,
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          {"%"}
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </div>
                              </TableCell>
                            </TableRow>
                          );
                        })

                        .slice(
                          (pageSharePercentage - 1) *
                          rowsPerPageSharePercentage,
                          pageSharePercentage * rowsPerPageSharePercentage
                        )
                    ) : (
                      <TableRow>
                        <TableCell colSpan={4}>
                          <center>
                            <ThemeProvider theme={theme}>
                              <Typography
                                variant="h6"
                                style={{
                                  fontWeight: 400,
                                  color: "#687083",
                                  fontSize: "14px",
                                }}
                              >
                                no data
                              </Typography>
                            </ThemeProvider>
                          </center>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>

                {sharePercentageSlice.length > 0 ? (
                  <CardActions
                    className={classes.actions}
                    style={
                      item.song_id !== sharePercentageValue
                        ? { display: "none" }
                        : null
                    }
                  >
                    <Grid
                      alignContent="space-between"
                      container
                      justifyContent="space-between"
                      spacing={3}
                    >
                      <Grid item>
                        <div style={{ display: "flex" }}>
                          <div style={{ marginTop: "7px" }}>
                            <ThemeProvider theme={theme}>
                              <Typography className={classes.paginationText}>
                                Item per page:
                              </Typography>
                            </ThemeProvider>
                          </div>{" "}
                          <div style={{ marginLeft: "10px" }}>
                            {pageSizePaginationSharePercentage()}
                          </div>
                        </div>
                      </Grid>

                      <Grid item>
                        <Pagination
                          count={pagesCountSharePercentage(
                            sharePercentageList.length
                          )}
                          shape="rounded"
                          className={classes.pagination}
                          page={pageSharePercentage}
                          onChange={handleChangePageSharePercentage}
                        />
                      </Grid>
                    </Grid>
                  </CardActions>
                ) : null}
              </Card>
            ))
          ) : (
            <Card
              style={{
                border: "1px solid #9AA2B1",
                borderRadius: "6px",
                boxShadow: "none",
                marginTop: "10px",
              }}
            >
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <ThemeProvider theme={theme}>
                      <TableCell>
                        <Typography
                          variant="subtitle2"
                          className={classes.headText}
                        >
                          Composer/Author Name
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="subtitle2"
                          className={classes.headText}
                        >
                          Share Percentage
                        </Typography>
                      </TableCell>
                    </ThemeProvider>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={4}>
                      <center>
                        <ThemeProvider theme={theme}>
                          <Typography
                            variant="h6"
                            style={{
                              fontWeight: 400,
                              color: "#687083",
                              fontSize: "14px",
                            }}
                          >
                            No Song Selected Yet.
                          </Typography>
                        </ThemeProvider>
                      </center>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Card>
          )}
        </Grid>

        <Grid item xs={4} md={4} sm={4} lg={4} xl={4}></Grid>
      </Grid>

      <Grid
        container
        style={{
          marginTop: "20px",
        }}
      >
        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <Typography
                component="h1"
                variant="h3"
                className={classes.subTitle}
              >
                Publisher Share Percentage
              </Typography>
              <ThemeProvider theme={theme}>
                <p
                  style={{
                    marginTop: "5px",
                    color: "#8f8f8f",
                    fontSize: "14px",
                  }}
                >
                  Feature to set publisher share percentage
                </p>
              </ThemeProvider>
            </div>

            <div>
              <FormControl
                className={classes.formControl}
                fullWidth={true}
                id="dsp-filter"
                style={{ width: "300px" }}
              >
                <TextField
                  fullWidth={true}
                  size="small"
                  className={classes.select}
                  onChange={handleChangePublisherShareFilter}
                  value={publisherShareFilterValue}
                  select
                  variant="outlined"
                >
                  <MenuItem value={0}>Select Composer</MenuItem>
                  {composerList.map((option, index) => (
                    <MenuItem
                      key={index}
                      value={option.song_id + option.composer_id}
                    >
                      {option.sure_name}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </div>
          </div>

          {publisherShareList?.length > 0 && publisherShareFilterValue !== 0 ? (
            <Card
              style={{
                border: "1px solid #9AA2B1",
                borderRadius: "6px",
                boxShadow: "none",
                marginTop: "10px",
              }}
            >
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <ThemeProvider theme={theme}>
                      <TableCell>
                        <Typography
                          variant="subtitle2"
                          className={classes.headText}
                        >
                          Song
                        </Typography>
                      </TableCell>

                      <TableCell align="left">
                        <Typography
                          variant="subtitle2"
                          className={classes.headText}
                        >
                          Share Percentage
                        </Typography>
                      </TableCell>
                    </ThemeProvider>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {publisherShareList?.length > 0 ? (
                    publisherShareList
                      ?.map((data, index) =>
                        data.map(customer => {
                          return (
                            <TableRow hover key={customer?.song_composer_id}>
                              <TableCell>
                                <Typography
                                  variant="subtitle2"
                                  className={classes.bodyTextBlack}
                                >
                                  {customer?.song_title}
                                </Typography>
                              </TableCell>

                              <TableCell>
                                <TextField
                                  value={customer?.publisher_share}
                                  variant="outlined"
                                  onChange={e =>
                                    handleChangePublisherShare(
                                      e,
                                      customer?.composer_id,
                                      index,
                                      customer?.song_id
                                    )
                                  }
                                  name="numberformat"
                                  id="formatted-numberformat-input"
                                  margin="dense"
                                  fullWidth={true}
                                  InputProps={{
                                    inputComponent: NumberFormatCustom,
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        {"%"}
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })
                      )
                      .slice(
                        (pagePublisherShare - 1) * rowsPerPagePublisherShare,
                        pagePublisherShare * rowsPerPagePublisherShare
                      )
                  ) : (
                    <TableRow>
                      <TableCell colSpan={4}>
                        <center>
                          <ThemeProvider theme={theme}>
                            <Typography
                              variant="h6"
                              style={{
                                fontWeight: 400,
                                color: "#687083",
                                fontSize: "14px",
                              }}
                            >
                              no data
                            </Typography>
                          </ThemeProvider>
                        </center>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>

              {publisherShareSlice.length > 0 ? (
                <CardActions className={classes.actions}>
                  <Grid
                    alignContent="space-between"
                    container
                    justifyContent="space-between"
                    spacing={3}
                  >
                    <Grid item>
                      <div style={{ display: "flex" }}>
                        <div style={{ marginTop: "7px" }}>
                          <ThemeProvider theme={theme}>
                            <Typography className={classes.paginationText}>
                              Item per page:
                            </Typography>
                          </ThemeProvider>
                        </div>{" "}
                        <div style={{ marginLeft: "10px" }}>
                          {pageSizePaginationPublisherShare()}
                        </div>
                      </div>
                    </Grid>

                    <Grid item>
                      <Pagination
                        count={pagesCountPublisherShare(
                          publisherShareList.length
                        )}
                        shape="rounded"
                        className={classes.pagination}
                        page={pagePublisherShare}
                        onChange={handleChangePagePublisherShare}
                      />
                    </Grid>
                  </Grid>
                </CardActions>
              ) : null}
            </Card>
          ) : (
            <Card
              style={{
                border: "1px solid #9AA2B1",
                borderRadius: "6px",
                boxShadow: "none",
                marginTop: "10px",
              }}
            >
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <ThemeProvider theme={theme}>
                      <TableCell>
                        <Typography
                          variant="subtitle2"
                          className={classes.headText}
                        >
                          Song
                        </Typography>
                      </TableCell>

                      <TableCell align="left">
                        <Typography
                          variant="subtitle2"
                          className={classes.headText}
                        >
                          Share Percentage
                        </Typography>
                      </TableCell>
                    </ThemeProvider>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={4}>
                      <center>
                        <ThemeProvider theme={theme}>
                          <Typography
                            variant="h6"
                            style={{
                              fontWeight: 400,
                              color: "#687083",
                              fontSize: "14px",
                            }}
                          >
                            No Composer Selected Yet.
                          </Typography>
                        </ThemeProvider>
                      </center>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Card>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}></Grid>
      </Grid>

      <Grid
        container
        style={{
          marginTop: "20px",
        }}
      >
        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <Typography
                component="h1"
                variant="h3"
                className={classes.subTitle}
              >
                Agent
              </Typography>
              <ThemeProvider theme={theme}>
                <p
                  style={{
                    marginTop: "5px",
                    color: "#8f8f8f",
                    fontSize: "14px",
                  }}
                >
                  Feature to set agent & fee agent
                </p>
              </ThemeProvider>
            </div>

            <div style={agentSwitch ? null : { display: "none" }}>
              <FormControl
                className={classes.formControl}
                fullWidth={true}
                id="dsp-filter"
                style={{ width: "300px" }}
              >
                <TextField
                  fullWidth={true}
                  size="small"
                  className={classes.select}
                  onChange={handleChangeAgentFilter}
                  value={agentFilterValue}
                  select
                  variant="outlined"
                >
                  <MenuItem value={0}>Select Composer</MenuItem>
                  {composerList.map((option, index) => (
                    <MenuItem key={index} value={option.composer_id}>
                      {option.sure_name}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <Switch
              checked={agentSwitch}
              onChange={handleChangeAgentSwitch}
              name="checkedB"
              color="primary"
              classes={{
                root: classes.rootSwitch,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
              }}
            />
            <p>Is Agent engaged?</p>
          </div>

          {agentSwitch ? (
            agentList?.length > 0 && agentFilterValue !== 0 ? (
              agentList?.map((item, index) => (
                <Card
                  key={index}
                  style={
                    item.composer_id === agentFilterValue
                      ? {
                        border: "1px solid #9AA2B1",
                        borderRadius: "6px",
                        boxShadow: "none",
                        marginTop: "10px",
                      }
                      : { display: "none" }
                  }
                >
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <ThemeProvider theme={theme}>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={classes.headText}
                            >
                              Song
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography
                              variant="subtitle2"
                              className={classes.headText}
                            >
                              Agent
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography
                              variant="subtitle2"
                              className={classes.headText}
                            >
                              Agent Fee
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography
                              variant="subtitle2"
                              className={classes.headText}
                            >
                              Fee Calculation Basis
                            </Typography>
                          </TableCell>
                          { }
                        </ThemeProvider>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {item?.agents?.length > 0 ? (
                        item?.agents
                          ?.map(customer => (
                            <TableRow hover key={customer?.song_composer_id}>
                              <TableCell>
                                <Typography
                                  variant="subtitle2"
                                  className={classes.bodyTextBlack}
                                >
                                  {customer?.song_title}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <FormControl
                                  variant="outlined"
                                  fullWidth={true}
                                >
                                  <Select
                                    className={classes.mypadding}
                                    id="demo-simple-select-outlined"
                                    onChange={e =>
                                      handleChangeAgentValueList(
                                        e,
                                        item?.composer_id,
                                        index,
                                        customer?.song_id
                                      )
                                    }
                                    autoWidth={true}
                                    value={customer?.agent_id}
                                    disabled={
                                      contractStatus === "approved"
                                        ? true
                                        : false
                                    }
                                    style={{
                                      paddingTop: "0px",
                                      paddingRight: "0px",
                                      paddingBottom: "0px",
                                      paddingLeft: "0px",
                                      width: "120px",
                                      height: "40px",
                                    }}
                                  >
                                    <MenuItem value={0}>Select Agent</MenuItem>
                                    {agentValueList?.map((item, idx) => (
                                      <MenuItem
                                        value={item?.agent_id}
                                        key={idx}
                                      >
                                        {item?.name}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </TableCell>
                              <TableCell>
                                <TextField
                                  value={customer?.fee}
                                  variant="outlined"
                                  onChange={e =>
                                    handleChangeAgentFee(
                                      e,
                                      item?.composer_id,
                                      index,
                                      customer?.song_id
                                    )
                                  }
                                  name="numberformat"
                                  id="formatted-numberformat-input"
                                  margin="dense"
                                  fullWidth={true}
                                  disabled={
                                    contractStatus === "approved" ? true : false
                                  }
                                  InputProps={{
                                    inputComponent: NumberFormatCustom,
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        {"%"}
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </TableCell>
                              <TableCell>
                                <FormControl
                                  variant="outlined"
                                  fullWidth={true}
                                >
                                  <Select
                                    className={classes.mypadding}
                                    id="demo-simple-select-outlined"
                                    onChange={e =>
                                      handleChangeAgentType(
                                        e,
                                        item?.composer_id,
                                        index,
                                        customer?.song_id
                                      )
                                    }
                                    autoWidth={true}
                                    value={customer.type}
                                    disabled={
                                      contractStatus === "approved"
                                        ? true
                                        : false
                                    }
                                    style={{
                                      paddingTop: "0px",
                                      paddingRight: "0px",
                                      paddingBottom: "0px",
                                      paddingLeft: "0px",
                                      width: "120px",
                                      height: "40px",
                                    }}
                                  >
                                    {customer?.fee_calculation_list?.map(
                                      (item, idx) => (
                                        <MenuItem value={item.value} key={idx}>
                                          {item?.name}
                                        </MenuItem>
                                      )
                                    )}
                                  </Select>
                                </FormControl>
                              </TableCell>
                            </TableRow>
                          ))
                          .slice(
                            (pageAgent - 1) * rowsPerPageAgent,
                            pageAgent * rowsPerPageAgent
                          )
                      ) : (
                        <TableRow>
                          <TableCell colSpan={4}>
                            <center>
                              <ThemeProvider theme={theme}>
                                <Typography
                                  variant="h6"
                                  style={{
                                    fontWeight: 400,
                                    color: "#687083",
                                    fontSize: "14px",
                                  }}
                                >
                                  no data
                                </Typography>
                              </ThemeProvider>
                            </center>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>

                  { }
                  <CardActions className={classes.actions}>
                    <Grid
                      alignContent="space-between"
                      container
                      justifyContent="space-between"
                      spacing={3}
                    >
                      <Grid item>
                        <div style={{ display: "flex" }}>
                          <div style={{ marginTop: "7px" }}>
                            <ThemeProvider theme={theme}>
                              <Typography className={classes.paginationText}>
                                Item per page:
                              </Typography>
                            </ThemeProvider>
                          </div>{" "}
                          <div style={{ marginLeft: "10px" }}>
                            {pageSizeAgent()}
                          </div>
                        </div>
                      </Grid>

                      <Grid item>
                        <Pagination
                          shape="rounded"
                          className={classes.pagination}
                          page={pageAgent}
                          onChange={handleChangePageAgent}
                        />
                      </Grid>
                    </Grid>
                  </CardActions>
                  { }
                </Card>
              ))
            ) : (
              <Card
                style={{
                  border: "1px solid #9AA2B1",
                  borderRadius: "6px",
                  boxShadow: "none",
                  marginTop: "10px",
                }}
              >
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <ThemeProvider theme={theme}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={classes.headText}
                          >
                            Song
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography
                            variant="subtitle2"
                            className={classes.headText}
                          >
                            Agent
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography
                            variant="subtitle2"
                            className={classes.headText}
                          >
                            Agent Fee
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography
                            variant="subtitle2"
                            className={classes.headText}
                          >
                            Fee Calculation Basis
                          </Typography>
                        </TableCell>
                      </ThemeProvider>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={4}>
                        <center>
                          <ThemeProvider theme={theme}>
                            <Typography
                              variant="h6"
                              style={{
                                fontWeight: 400,
                                color: "#687083",
                                fontSize: "14px",
                              }}
                            >
                              No Composer Selected Yet
                            </Typography>
                          </ThemeProvider>
                        </center>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Card>
            )
          ) : null}
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}></Grid>
      </Grid>

      <Grid container>
        <Grid item xs={8} md={8} sm={8} lg={8} xl={8}>
          <Divider className={classes.divider} />
        </Grid>
      </Grid>
      <Grid container>
        <Button
          variant="contained"
          onClick={e => handleButtonSimpan(e)}
          className={classes.btnSubmit}
          style={{
            backgroundColor: "black",
          }}
        >
          <ThemeProvider theme={theme}>
            <Typography
              style={{
                textTransform: "none",
                color: "#FFFFFF",
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "20px",
              }}
            >
              Edit Contract
            </Typography>
          </ThemeProvider>
        </Button>
      </Grid>
    </>
  );
}

export default EditKontrak;
