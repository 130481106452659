import {
  Breadcrumbs,
  Card,
  CardContent,
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Page } from "components";
import { hardBaseUrl } from "../../../../services/urlConstant";

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  inner: {
    minWidth: 700,
  },

  body: {
    fontWeight: 400,
    fontSize: "16px",
    color: "#111827",
    lineHeight: "24px",
    marginBottom: "10px",
  },
  label: {
    color: "#364052",
    fontSize: "14px",
    fontWeight: 500,
    marginBottom: "0px",
  },
  subTitle: {
    fontSize: "18px",
    fontWeight: 700,
    color: " #111827",
  },
  breadcrumbs: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#111827",
  },
  breadCrumbsActive: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#687083",
  },
  font24: {
    fontSize: "24px",
    fontWeight: "700",
    lineHeight: "32px",
  },
  cardParent: {
    border: "1px solid #D1D5DC",
    borderRadius: "6px",
    boxShadow: "none",
  },
  textStyle: {
    marginTop: "5px",
    color: "#8f8f8f",
    fontSize: "14px",
  },
  colorWhite: {
    color: "white",
  },
}));

const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

export default function DetailSongClaim() {
  const classes = useStyles();
  const { state } = useLocation();
  const dataDSP = useLocation().state;

  const getDetail = () => {
    const token = localStorage.getItem("token");
    const url = `${hardBaseUrl}/master/client/${dataDSP.id}`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(url, { headers })
      .then(() => {})
      .catch(() => {});
  };

  useEffect(() => {
    getDetail();
  }, []);

  return (
    <Page className={classes.root} title="Song Claim">
      <Container maxWidth={false}>
        <Grid
          alignItems="flex-end"
          container
          justifyContent="space-between"
          spacing={3}
        >
          <Grid item>
            <ThemeProvider theme={theme}>
              <Typography className={classes.font24}>Song Claim</Typography>
            </ThemeProvider>
          </Grid>
          <Grid item>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography className={classes.breadCrumbs}>Home</Typography>

              <Typography className={classes.breadCrumbs}>
                Song Claim
              </Typography>

              <Typography className={classes.breadCrumbs}>Detail</Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>

        <Grid container spacing={2} className={classes.cardParent}>
          <Grid item xs={4} sm={6} md={6} lg={6} xl={6}>
            <div>
              <ThemeProvider theme={theme}>
                <Typography variant="h5" className={classes.subTitle}>
                  {"Song's Information"}
                </Typography>
              </ThemeProvider>
              <ThemeProvider theme={theme}>
                <p className={classes.textStyle}>{"To View Song's Detail"}</p>
              </ThemeProvider>
            </div>
            <div>
              <Card className={classes.cardParent}>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                      <ThemeProvider theme={theme}>
                        <Typography
                          variant="subtitle2"
                          className={classes.label}
                        >
                          ISWC Code
                        </Typography>
                        <Typography variant="body1" className={classes.body}>
                          {"US/Z03/21/9102"}
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          className={classes.label}
                        >
                          Composer / Author Name
                        </Typography>
                        <Typography variant="body1" className={classes.body}>
                          {"Tulus"}
                        </Typography>
                      </ThemeProvider>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                      <ThemeProvider theme={theme}>
                        <Typography
                          variant="subtitle2"
                          className={classes.label}
                        >
                          Song Title
                        </Typography>
                        <Typography variant="body1" className={classes.body}>
                          {"Aku"}
                        </Typography>
                      </ThemeProvider>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </div>
          </Grid>
          <Grid item xs={4} sm={6} md={6} lg={6} xl={6}></Grid>
          <Grid item xs={4} sm={6} md={6} lg={6} xl={6}>
            <div>
              <ThemeProvider theme={theme}>
                <Typography variant="h5" className={classes.subTitle}>
                  {state.status === "Unclaim" ? (
                    <>Unclaim Information</>
                  ) : (
                    <>Double Claim Information</>
                  )}
                </Typography>
              </ThemeProvider>
              <ThemeProvider theme={theme}>
                <p className={classes.textStyle}>
                  {"To View Song's Double Claim Information"}
                </p>
              </ThemeProvider>
            </div>

            {state.status === "Unclaim" ? (
              <>
                <div className={classes.inner}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>DSP</TableCell>
                        <TableCell>Period</TableCell>
                        <TableCell>Traffic</TableCell>
                        <TableCell>Revenue</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>Spotify</TableCell>
                        <TableCell>Februari 2022</TableCell>
                        <TableCell>1.803</TableCell>
                        <TableCell>Rp 259.163,-</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>
              </>
            ) : (
              <>
                <div className={classes.inner}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Publisher</TableCell>
                        <TableCell>Claim Percentage</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>CARIS MUSIC ENTERTAINMENT</TableCell>
                        <TableCell>100%</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>CARIS INDONESIA</TableCell>
                        <TableCell>100%</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>
              </>
            )}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
