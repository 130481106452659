import { Container, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Grid } from "@mui/material";
import axios from "axios";
import {
  ButtonGroupTop,
  CheckboxInput,
  ModalError,
  ModalSuccess,
  Page,
  PrimaryButton,
  SectionLabel,
  SelectInput,
  SkeletonComponent,
} from "components";
import { InnoTableV2 } from "inno-ui";
import { HeaderTitle } from "layouts";
import { useEffect, useState } from "react";
import { getErrors, textCapitalization } from "utils";
import { hardBaseUrl } from "../../../../services/urlConstant";

function Pembayaran() {
  const classes = useStyles();
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const [loadingPage, setLoadingPage] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [domicile, setDomicile] = useState("domestic");

  const handleCheckTax = (event, id) => {
    const { checked } = event?.target;
    setDataTable(currentState =>
      currentState.map(item =>
        item.id === id ? { ...item, is_active_flag: checked } : item
      )
    );
  };

  const getDataTable = async () => {
    try {
      setLoadingPage(true);
      const res = await axios.get(
        `${hardBaseUrl}/publisher/configuration/tax`,
        {
          headers,
          params: { domicile },
        }
      );
      setDataTable(res?.data?.data || []);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  const handleSaveChanges = async () => {
    const payload = {
      domicile,
      taxes: dataTable?.map(item => ({
        id: item?.id,
        is_active_flag: item?.is_active_flag,
      })),
    };
    try {
      setLoadingButton(true);
      await axios.post(`${hardBaseUrl}/publisher/configuration/tax`, payload, {
        headers,
      });
      ModalSuccess(
        "Client's tax setting successfully saved, check the detail information at the table"
      ).then(() => getDataTable());
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingButton(false);
    }
  };

  useEffect(() => {
    getDataTable();
  }, [domicile]);

  return (
    <Page className={classes.root} title="Tax">
      {loadingPage ? (
        <SkeletonComponent variant="wave" />
      ) : (
        <Container maxWidth={false}>
          <HeaderTitle title="Configuration" breadcrumbData={breadcrumbData} />
          <ButtonGroupTop />
          <Divider className={classes.divider} />
          <SectionLabel
            title="Tax"
            subTitle="Feature to settings client's tax"
          />
          <Grid container justifyContent="right" mb="16px">
            <Grid item>
              <SelectInput
                label="Domicile"
                value={domicile}
                options={optionDomicile}
                optionLabel="name"
                optionKey="value"
                onChange={e => setDomicile(e?.target?.value)}
                width={200}
              />
            </Grid>
          </Grid>
          <InnoTableV2
            columns={columnTable}
            items={dataTable}
            isLoading={false}
            isHaveAction
            renderAction={item => (
              <CheckboxInput
                disabled={loadingButton}
                checked={item.is_active_flag}
                onChange={e => handleCheckTax(e, item.id)}
              />
            )}
          />
          <Grid container justifyContent="right" mt="16px">
            <PrimaryButton
              label={`${loadingButton ? "Saving" : "Save"} Changes`}
              onClick={handleSaveChanges}
              disabled={loadingButton}
              loading={loadingButton}
            />
          </Grid>
        </Container>
      )}
    </Page>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
}));
const breadcrumbData = [
  {
    label: "Home",
    link: "/admin/dashboard",
  },
  {
    label: "Configuration",
    link: "/admin/konfigurasi/tax",
  },
  {
    label: "Tax",
    active: true,
  },
];
const optionDomicile = [
  {
    name: "Domestic",
    value: "domestic",
  },
  {
    name: "Overseas",
    value: "overseas",
  },
];
const columnTable = [
  {
    name: "code",
    title: "Code",
  },
  {
    name: "description",
    title: "Description",
  },
  {
    name: "rate",
    title: "Rate",
    renderText: item => `${item} %`,
  },
  {
    name: "tax_bases",
    title: "Base",
    renderText: item => `${item} %`,
  },
  {
    name: "method",
    title: "Method",
    renderText: item => textCapitalization(item),
  },
  {
    name: "object",
    title: "Object",
    renderText: item => textCapitalization(item),
  },
];

export default Pembayaran;
