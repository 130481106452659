import {
  Backdrop,
  Button,
  CircularProgress,
  Container,
  Divider,
  FormControl,
  Box,
  Grid,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  Snackbar,
  Typography,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import { makeStyles } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";

import MuiAlert from "@material-ui/lab/Alert";
import axios from "axios";
import { ButtonGroupTop } from "../../../../components/atoms/Button";
import HeaderPage from "../../../../layouts/Header/HeaderPage";
import { Page, SkeletonComponent } from "components";

import { InnoTable } from "inno-ui";
import DialogEditUser from "./DialogEditUser";
import DialogTambahUser from "./DialogTambahUser";

import { hardBaseUrl } from "../../../../services/urlConstant";

const fontInter = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  formControl: {
    width: "180px",
    height: 44,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  results: {
    marginTop: theme.spacing(3),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  buttonAdd: {
    backgroundColor: "black",
    color: "white",
    textTransform: "none",
    width: "auto",
    height: "44px",
  },
  textAdd: {
    color: "white",
    fontSize: "14px",
  },
  pageSize: {
    height: "30px",
    borderRadius: "6px",
    border: "1px solid #D1D5DC",
    paddingLeft: "5px",
    paddingRight: "5px",
    marginTop: "5px",
  },
  paginationText: {
    fontSize: "14px",
    fontWeight: "normal",
    fontStyle: "normal",
  },
  mypadding: {
    "& .MuiSelect-outlined": {
      padding: "14px 14px",
    },
  },
  searchPadding: {
    "& .MuiSelect-outlined": {
      padding: "14px 14px",
    },
  },
  inputFields: {
    "& .MuiOutlinedInput-input": {
      padding: "0px",
      height: "44px",
      width: "268px",
    },
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function User() {
  const classes = useStyles();
  const [searchType, setSearchType] = useState("");
  const [searchKey, setSearchKey] = useState("");

  const [loadingDelete, setLoadingDelete] = useState(false);
  const [totalData, setTotalData] = useState(0);

  const [currentPageApi, setCurrentPageApi] = useState(1);
  const [totalSizeApi, setTotalSizeApi] = useState(10);
  const [openSnack, setOpenSnack] = useState(false);
  const [typeSnackbar, setTypeSnackbar] = useState("success");
  const [message, setMessage] = useState("This is a message!");
  const [openEdit, setOpenEdit] = useState(false);
  const [id, setId] = useState();

  const [customers, setCustomers] = useState([]);

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [listRole, setListRole] = useState([]);

  const [openAdd, setOpenAdd] = useState(false);
  const [name, setName] = useState("");
  const [role, setRole] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [currentEmail, setCurrentEmail] = useState("");
  const [password, setPassword] = useState("");
  const [konfirmasiPassword, setKonfirmasiPassword] = useState("");
  const [reloadAfterAdd, setReloadAfterAdd] = useState(false);
  const [loadingTable, setLoadingTable] = useState(true);
  const [openMenu, setOpenMenu] = useState(null);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [roleSearch, setRoleSearch] = useState("");

  const pagesCount = Math.ceil(totalData / rowsPerPage);

  useEffect(() => {
    const fetchUsers = async () => {
      setLoadingTable(true);
      try {
        let theToken = localStorage.getItem("token");
        let baseOfUrl = hardBaseUrl;
        const url =
          baseOfUrl +
          "/user?type=admin&page=" +
          currentPageApi +
          "&size=" +
          totalSizeApi +
          "&search=" +
          searchKey +
          "&role_id=" +
          searchType;
        const options = {
          headers: {
            Authorization: "Bearer " + theToken,
          },
        };
        const response = await axios.get(url, options);
        setCustomers(response.data.data);
        setTotalData(response.data.meta.total);

        setReloadAfterAdd(false);

        setLoadingTable(false);
      } catch (error) {
        setReloadAfterAdd(false);
        setLoadingTable(false);
        if (error.response.status === 404) {
          new Error(error.response.data);
        }
      }
    };
    fetchUsers();
  }, [
    rowsPerPage,
    searchKey,
    currentPageApi,
    totalSizeApi,
    page,
    reloadAfterAdd,
    searchType,
  ]);

  const fetchRoles = async () => {
    try {
      let theToken = localStorage.getItem("token");
      let baseOfUrl = hardBaseUrl;
      const url = baseOfUrl + "/role?type=admin";
      const options = {
        headers: {
          Authorization: "Bearer " + theToken,
        },
      };

      const response = await axios.get(url, options);
      setListRole(response.data.data);
    } catch (error) {
      new Error(error);
    }
  };

  useEffect(() => {
    fetchRoles();
  }, []);

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const handleChange = event => {
    const name = event.target.name;
    const value = event.target.value;
    if (name === "name") {
      setName(value);
    } else if (name === "role") {
      setRole(value);
    } else if (name === "phone") {
      setPhone(value);
    } else if (name === "email") {
      setEmail(value);
    } else if (name === "password") {
      setPassword(value);
    } else if (name === "konfirmasiPassword") {
      setKonfirmasiPassword(value);
    }
  };

  const handleChangeSelectRole = (event, role, index) => {
    const updatedRoles = [...listRole];
    updatedRoles[index].checked = event.target.checked;
    setListRole(updatedRoles);
    if (event.target.checked) {
      if (
        !selectedRoles.find(
          selectedRole => selectedRole?.role_id === role.role_id
        )
      ) {
        setSelectedRoles(prevSelectedRoles => [...prevSelectedRoles, role]);
      }
    } else {
      setSelectedRoles(prevSelectedRoles =>
        prevSelectedRoles.filter(
          selectedRole => selectedRole.role_id !== role.role_id
        )
      );
    }
  };

  const handleChangeSelect = event => {
    setSearchType(event.target.value);
    setCurrentPageApi(1);
  };

  const handleChangeSearch = event => {
    setSearchKey(event.target.value);
    setCurrentPageApi(1);
  };

  const onChangePage = (event, value) => {
    setCurrentPageApi(value);
    setPage(value);
  };

  const onDelAct = async value => {
    setLoadingDelete(true);
    try {
      let baseOfUrl = hardBaseUrl;
      let urlUse = baseOfUrl + "/user/" + value.user_id;
      let theToken = localStorage.getItem("token");
      let optsDel = {
        headers: {
          Authorization: "Bearer " + theToken,
        },
      };
      const responseDel = await axios.delete(urlUse, optsDel);
      if (
        responseDel.data.message === "success" &&
        responseDel.data.meta.http_status === 200
      ) {
        setLoadingDelete(false);
        Swal.fire({
          icon: "success",
          title: "Berhasil",
          text: "Data deleted successfully",
        }).then(result => {
          if (result.isConfirmed === true) {
            setReloadAfterAdd(true);
          }
        });
      }
    } catch (error) {
      setMessage("Error.");
      setTypeSnackbar("error");
      setOpenSnack(true);
      setLoadingDelete(false);
    }
  };

  const onRecovery = async value => {
    setLoadingDelete(true);
    try {
      let baseOfUrl = hardBaseUrl;
      let urlUse = baseOfUrl + "/user-recovery/" + value.user_id;
      let theToken = localStorage.getItem("token");
      let optsDel = {
        headers: {
          Authorization: "Bearer " + theToken,
        },
      };
      const formData = new FormData();
      const responseDel = await axios.put(urlUse, formData, optsDel);
      if (
        responseDel.data.message === "success" &&
        responseDel.data.meta.http_status === 200
      ) {
        setLoadingDelete(false);
        Swal.fire({
          icon: "success",
          title: "Berhasil",
          text: "Data recovery successfully",
        }).then(result => {
          if (result.isConfirmed === true) {
            setReloadAfterAdd(true);
          }
        });
      }
    } catch (error) {
      setMessage("Error.");
      setTypeSnackbar("error");
      setOpenSnack(true);
    }
  };

  const handleOpenEdit = () => {
    setOpenEdit(true);
  };
  const handleEdit = async value => {
    setId(value.user_id);
    let baseOfUrl = hardBaseUrl;
    const url = baseOfUrl + "/user/" + value.user_id;
    let theToken = localStorage.getItem("token");
    const options = {
      headers: {
        Authorization: "Bearer " + theToken,
      },
    };
    const response = await axios.get(url, options);
    setName(response.data.data.name);
    setRole(response.data.data.role_id);
    setPhone(response.data.data.phone);
    setEmail(response.data.data.email);
    setCurrentEmail(response.data.data.email);

    const updatedSelectedRoles = response.data.data.role_ids.map(roleId => {
      const role = listRole?.find(role => role.role_id === roleId);
      if (role) {
        role.checked = true;
      }
      return role;
    });
    setSelectedRoles(
      updatedSelectedRoles[0] === undefined ? [] : updatedSelectedRoles
    );

    handleOpenEdit();
  };

  const handleCloseSnackbar = () => {
    setOpenSnack(false);
  };
  const handleChangeRowsPerPage = event => {
    event.preventDefault();

    setRowsPerPage(event.target.value);
    setTotalSizeApi(event.target.value);

    setPage(1);
  };

  const handleModalAdd = () => {
    setOpenAdd(!openAdd);
    setName("");
    setRole("");
    setPhone("");
    setEmail("");
    const updatedRoles = listRole?.map(roles => {
      return { ...roles, checked: false };
    });
    setListRole(updatedRoles);
    setSelectedRoles([]);
    handleClickAway();
  };
  const notifError = (text, confrimed = () => setOpenAdd(true)) =>
    Swal.fire({
      title: "Oops…",
      icon: "error",
      text: text,
      showCancelButton: false,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK",
    }).then(result => {
      if (result.isConfirmed) {
        confrimed();
      }
    });
  const regexEmail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  const onSubmitAdd = e => {
    e.preventDefault();

    setOpenAdd(false);

    if (name === "") {
      return notifError("Name can't be empty");
    }
    if (email === "") {
      return notifError("Email address can't be empty");
    }
    if (phone === "") {
      return notifError("Phone number can't be empty");
    }
    if (password === "") {
      return notifError("Password can't be empty");
    }
    if (konfirmasiPassword === "") {
      return notifError("Confirm password can't be empty");
    }
    if (selectedRoles?.length === 0) {
      return notifError("Role can't be empty");
    }

    if (!regexEmail.test(email)) {
      return notifError(
        "Please enter your email address in format: yourname@example.com"
      );
    }

    const selectedRolesId = selectedRoles?.map(obj => obj?.role_id);

    if (
      name !== "" &&
      email !== "" &&
      phone !== "" &&
      password !== "" &&
      selectedRoles?.length > 0
    ) {
      if (password !== konfirmasiPassword) {
        notifError("The confirmation password does not match");
      } else {
        const url = `${hardBaseUrl}/user`;
        const data = {
          name: name,
          email: email,
          phone: phone,

          password: password,
          role_ids: selectedRolesId,
          display_picture_url: null,
        };
        let theToken = localStorage.getItem("token");
        axios
          .post(url, data, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${theToken}`,
            },
          })
          .then(res => {
            if (
              res.data.message === "success" &&
              res.data.meta.http_status === 200
            ) {
              Swal.fire({
                icon: "success",
                title: "Success",
                text: "Data added successfully",
              }).then(result => {
                if (result.isConfirmed === true) {
                  setReloadAfterAdd(true);
                }
              });
            }
          })
          .catch(err => {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: `${err.response.data.errors[0].message}`,
            }).then(result => {
              if (result.isConfirmed === true) {
                setName(data.name);
                setEmail(data.email);
                setPhone(data.phone);
                setRole(data.role_id);
                setPassword("");
                setKonfirmasiPassword("");
                setOpenAdd(true);
              }
            });
          });
      }
    }
  };

  const handleUpdate = e => {
    e.preventDefault();
    setOpenEdit(false);

    if (name === "") {
      return notifError("Name can't be empty", () => setOpenEdit(true));
    }
    if (email === "") {
      return notifError("Email address can't be empty", () =>
        setOpenEdit(true)
      );
    }
    if (phone === "") {
      return notifError("Phone number can't be empty", () => setOpenEdit(true));
    }

    if (selectedRoles?.length === 0) {
      return notifError("Role can't be empty", () => setOpenEdit(true));
    }

    if (!regexEmail.test(email)) {
      return notifError(
        "Please enter your email address in format: yourname@example.com",
        () => setOpenEdit(true)
      );
    }
    // if (currentEmail === email) {
    //   return notifError("Please Insert different Email", () =>
    //     setOpenEdit(true)
    //   );
    // }

    const selectedRolesId = selectedRoles?.map(obj => obj?.role_id);
    if (
      name !== "" &&
      phone !== "" &&
      selectedRoles?.length > 0 &&
      email !== "" &&
      email !== currentEmail
    ) {
      const url = `${hardBaseUrl}/user/${id}`;
      let theToken = localStorage.getItem("token");
      const data = {
        name: name,
        email: email,
        phone: phone,

        role_ids: selectedRolesId,
        display_picture_url: null,
      };

      axios
        .put(url, data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${theToken}`,
          },
        })
        .then(() => {
          Swal.fire({
            icon: "success",
            title: "Berhasil",
            text: "Data changed successfully",
          });
          handleCloseEdit();
          setReloadAfterAdd(true);
          setSelectedRoles([]);
        })
        .catch(err => {
          setOpenEdit(false);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: `${err.response.data.errors[0].message}`,
          }).then(result => {
            if (result.isConfirmed === true) {
              setOpenEdit(true);
            }
          });
        });
    }
  };

  const renderRole = (item, key = "") => {
    return <React.Fragment>{item[key]}</React.Fragment>;
  };

  const clickMenu = () => {
    setOpenMenu(!openMenu);
  };
  const handleClickAway = () => {
    setOpenMenu(false);
  };
  const handleChangeSearchRole = event => {
    setRoleSearch(event?.target?.value);
  };
  const filteredRoles = listRole?.filter(role =>
    role?.name?.toLowerCase().includes(roleSearch?.toLowerCase())
  );
  const formatRoleNames = roles => {
    try {
      const maxCharCount = 40;
      if (!roles || roles.length === 0) {
        return "Choose Role";
      } else {
        const roleString = roles
          .map(selectedRole => selectedRole.name)
          .join(", ");
        if (roleString.length <= maxCharCount) {
          return roleString;
        } else {
          return roleString.substring(0, maxCharCount) + "...";
        }
      }
    } catch (err) {
      Swal.fire({
        title: "Error",
        text: "Option Role data not found",
        icon: "error",
        confirmButtonText: "Ok",
      });
    }
  };

  return (
    <Page className={classes.root} title="User">
      <Snackbar
        open={openSnack}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleCloseSnackbar} severity={typeSnackbar}>
          {message}
        </Alert>
      </Snackbar>

      <Backdrop className={classes.backdrop} open={loadingDelete}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {loadingTable ? (
        <SkeletonComponent variant="wave" />
      ) : (
        <Container maxWidth={false}>
          <HeaderPage title="Parameter" breadcrumbs={["Parameter", "User"]} />
          <ButtonGroupTop />
          <Divider className={classes.divider} />
          <Grid container>
            <Grid item xs={12} md={12} sm={12} lg={12} xl={12}>
              <Box display="flex" justifyContent="space-between">
                <FormControl className={classes.margin} variant="outlined">
                  <OutlinedInput
                    className={classes.inputFields}
                    id="outlined-adornment-amount"
                    value={searchKey}
                    onChange={event => handleChangeSearch(event)}
                    placeholder="Search"
                    startAdornment={
                      <InputAdornment position="start">
                        <img
                          alt="Logo"
                          src={
                            require("assets/image-public/images/search.svg")
                              .default
                          }
                        />
                      </InputAdornment>
                    }
                    fullWidth={false}
                  />
                </FormControl>
                <Box
                  display="grid"
                  gridTemplateColumns="1fr 1fr"
                  gridGap=".5rem"
                >
                  <FormControl variant="outlined">
                    <Select
                      displayEmpty={true}
                      className={classes.formControl}
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={searchType}
                      defaultValue={""}
                      onChange={handleChangeSelect}
                    >
                      <MenuItem value={""}>All Role</MenuItem>
                      {listRole.map(item => {
                        return (
                          <MenuItem key={item.role_id} value={item.role_id}>
                            {item.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <Button
                    onClick={handleModalAdd}
                    className={classes.buttonAdd}
                    variant="contained"
                    size="large"
                    startIcon={<AddIcon />}
                  >
                    <ThemeProvider theme={fontInter}>
                      <Typography className={classes.textAdd}>
                        Add User
                      </Typography>
                    </ThemeProvider>
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
          <InnoTable
            columns={[
              {
                name: "name",
                title: "User Name",
              },
              {
                name: "role",
                title: "Role",
                renderText: item => renderRole(item, "name"),
              },
              {
                name: "phone",
                title: "Phone Number",
              },
              {
                name: "email",
                title: "Email",
              },
            ]}
            handleChangePage={onChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleRefresh={{
              basedOnField: "is_active_flag",
              function: onRecovery,
            }}
            handleDelete={onDelAct}
            handleEdit={handleEdit}
            idColumnName={"user_id"}
            deleteName={"name"}
            isLoading={loadingTable}
            isHaveAction={true}
            isUsingCheckbox={false}
            isUsingDeleteConfirmation={true}
            items={customers}
            loadingColor={""}
            page={page}
            rowsPerPage={Number(rowsPerPage)}
            totalPage={pagesCount}
          />

          {openAdd && (
            <DialogTambahUser
              open={openAdd}
              onClose={handleModalAdd}
              role={role}
              handleChangeSelectRole={handleChangeSelectRole}
              handleChange={handleChange}
              onSubmit={onSubmitAdd}
              name={name}
              phone={phone}
              email={email}
              password={password}
              konfirmasiPassword={konfirmasiPassword}
              openMenu={openMenu}
              handleClickAway={handleClickAway}
              clickMenu={clickMenu}
              formatRoleNames={formatRoleNames}
              selectedRoles={selectedRoles}
              handleChangeSearchRole={handleChangeSearchRole}
              filteredRoles={filteredRoles}
            />
          )}
          {openEdit && (
            <DialogEditUser
              open={openEdit}
              onClose={handleCloseEdit}
              role={role}
              handleChangeSelectRole={handleChangeSelectRole}
              handleChange={handleChange}
              name={name}
              phone={phone}
              onSubmit={handleUpdate}
              email={email}
              openMenu={openMenu}
              handleClickAway={handleClickAway}
              clickMenu={clickMenu}
              formatRoleNames={formatRoleNames}
              selectedRoles={selectedRoles}
              handleChangeSearchRole={handleChangeSearchRole}
              filteredRoles={filteredRoles}
            />
          )}
        </Container>
      )}
    </Page>
  );
}

export default User;
