import { Container, Divider } from "@material-ui/core";
import { Box } from "@mui/material";
import axios from "axios";
import {
  ModalError,
  Page,
  SearchTextInput,
  SkeletonComponent,
  StatusChip,
  TableAction,
} from "components";
import { InnoTableV2 } from "inno-ui";
import { HeaderTitle } from "layouts";
import { getTotalPage } from "lib";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { globalStyles } from "styles";
import { formatDate, getCookie, getErrors } from "utils";
import { hardBaseUrl } from "../../../services/urlConstant";

const ContractList = () => {
  const classes = globalStyles();
  const history = useHistory();
  const userLogin = getCookie("auth", `${process.env.REACT_APP_NAME}_user`);
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const [loadingPage, setLoadingPage] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [queryParams, setQueryParams] = useState({
    page: 1,
    size: 10,
    search: "",
    publisher_id: userLogin?.publisher?.publisher_id,
  });
  const [tableTotalPage, setTableTotalPage] = useState(1);

  const handleChangeQueryParams = (value, key) => {
    setQueryParams(currentState => ({
      ...currentState,
      ...(key !== "page" && { page: 1 }),
      [key]: value,
    }));
  };

  const getDataTable = async () => {
    try {
      setLoadingPage(true);
      const res = await axios.get(`${hardBaseUrl}/composer/contract`, {
        headers,
        params: queryParams,
      });
      const { data, meta } = res?.data;
      setDataTable(data || []);
      const pageCount = getTotalPage(meta?.total, queryParams?.size);
      setTableTotalPage(pageCount || 1);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  const debounceDataTable = useCallback(
    debounce(() => {
      getDataTable();
    }, 500),
    [queryParams]
  );

  useEffect(() => {
    const isSearching = Boolean(queryParams?.search);
    if (isSearching) {
      debounceDataTable();
      return () => {
        debounceDataTable.cancel();
      };
    } else {
      getDataTable();
    }
  }, [queryParams, debounceDataTable]);

  return (
    <Page className={classes.root} title="Contract">
      {loadingPage ? (
        <SkeletonComponent variant="wave" />
      ) : (
        <Container maxWidth={false}>
          <HeaderTitle title="Contract" breadcrumbData={breadcrumbData} />
          <Divider className={classes?.divider} />
          <Box mb="16px">
            <SearchTextInput
              placeholder="Search"
              value={queryParams?.search}
              onChange={e =>
                handleChangeQueryParams(e?.target?.value, "search")
              }
            />
          </Box>
          <InnoTableV2
            isLoading={false}
            columns={columnTable}
            items={dataTable}
            page={queryParams?.page}
            rowsPerPage={queryParams?.size}
            totalPage={tableTotalPage}
            handleChangePage={(_, page) =>
              handleChangeQueryParams(page, "page")
            }
            handleChangeRowsPerPage={e =>
              handleChangeQueryParams(e?.target?.value, "size")
            }
            isHaveAction
            renderAction={item => (
              <TableAction
                handleView={() =>
                  history.push(
                    `/pencipta/kontrak/detail-kontrak/${item.contract_id}/${item.song_id}`
                  )
                }
              />
            )}
          />
        </Container>
      )}
    </Page>
  );
};

const breadcrumbData = [
  {
    label: "Home",
    link: "/pencipta/dashboard-pencipta",
  },
  {
    label: "Contract",
    active: true,
  },
];
const columnTable = [
  {
    name: "contract_number",
    title: "Contract number	",
    renderText: item => item || "-",
  },
  {
    name: "song_title",
    title: "Song Title",
    renderText: item => item || "-",
  },
  {
    name: "publisher_name",
    title: "Publisher",
    renderText: item => item || "-",
  },
  {
    name: "start_date",
    title: "Start Date",
    renderText: item => formatDate(item),
  },
  {
    name: "end_date",
    title: "End Date",
    renderText: item => formatDate(item),
  },
  {
    name: "status",
    title: "Status",
    renderText: item => (
      <StatusChip
        label={item === "Aktif" ? "Active" : "Inactive"}
        type={item === "Aktif" && "success"}
      />
    ),
  },
];

export default ContractList;
