import { Container } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/styles";
import { useEffect, useState } from "react";
const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(3),
  },
  header: {
    fontSize: "40px",
    fontWeight: "bold",
    color: "#000",
  },
  writerName: {
    fontSize: "20px",
    color: "#000",
    marginTop: "10px",
    marginBottom: "10px",
  },
  date: {
    fontSize: "20px",
    color: "gray",
  },
  img: {
    width: "80%",
    height: "100%",
    objectFit: "cover",
    marginTop: "20px",
  },
  mainText: {
    fontSize: "20px",
    color: "#000",
    marginBottom: "10px",
    display: "inline-block",
    width: "80%",
    wordWrap: "break-word",
    whiteSpace: "pre-wrap",
    marginTop: "20px",
  },
  appBar: {
    backgroundColor: "#f5f5f5",
    color: "#000",
    boxShadow: "none",
    borderBottom: "1px solid #e0e0e0",
  },
  textHeader: {
    fontSize: "25px",

    marginLeft: "10px",
  },
  iconHeader: {
    fontSize: "29px",
    marginTop: "5px",
  },
  backButton: {
    cursor: "pointer",
    "&:hover": {
      color: "gray",
    },
  },
  displayFlex: {
    display: "flex",
    width: "100%",
  },
}));

const PreviewBerita = props => {
  const [title, setTitle] = useState("");
  const [recentDate, setRecentDate] = useState("");
  const [publisherName, setPublisherName] = useState("");
  const [gambar, setGambar] = useState("");

  const classes = useStyles();
  const theme = createTheme({
    typography: {
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        "Segoe UI",
        "Roboto",
        "Helvetica Neue",
        "Arial",
        "sans-serif",
        "Apple Color Emoji",
        "Segoe UI Emoji",
        "Segoe UI Symbol",
      ].join(","),
    },
  });
  useEffect(() => {
    const title = localStorage.getItem("title");
    const recentDate = localStorage.getItem("recentDate");
    const publisherName = localStorage.getItem("publisherName");
    document.getElementById("contentAsHTML").innerHTML = localStorage.getItem(
      "content"
    );

    if (localStorage.getItem("image_logo") !== null) {
      setGambar(localStorage.getItem("image_logo"));
    } else {
      setGambar("");
    }

    setTitle(title);
    setRecentDate(recentDate);
    setPublisherName(publisherName);
  }, []);
  const handleBack = e => {
    e.preventDefault();
    props.history.goBack();
  };
  return (
    <div className={classes.root}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <ThemeProvider theme={theme}>
            <Typography
              className={classes.backButton}
              variant="h6"
              onClick={e => handleBack(e)}
            >
              <div className={classes.displayFlex}>
                <CloseIcon className={classes.iconHeader} />
                <div className={classes.textHeader}>Preview</div>
              </div>
            </Typography>
          </ThemeProvider>
        </Toolbar>
      </AppBar>
      <Container>
        <ThemeProvider theme={theme}>
          <Typography variant="h1" className={classes.header}>
            {title}
          </Typography>
          <div>
            <Typography variant="h6" className={classes.writerName}>
              {publisherName}
              <span className={classes.date}> - {recentDate}</span>
            </Typography>{" "}
          </div>
        </ThemeProvider>

        <img
          alt="theImgData"
          className={classes.img}
          src={gambar}
          id="imgData"
        ></img>
        <ThemeProvider theme={theme}>
          <Typography variant="body2" className={classes.mainText}>
            <div id="contentAsHTML"></div>
          </Typography>
        </ThemeProvider>
      </Container>
    </div>
  );
};

export default PreviewBerita;
