import {
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecordRounded";
import Pagination from "@material-ui/lab/Pagination";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import { hardBaseUrl } from "../../../../../services/urlConstant";

const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },

  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },

  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  results: {
    marginTop: theme.spacing(3),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  pageSize: {
    height: "30px",
    borderRadius: "6px",
    border: "1px solid #D1D5DC",
    paddingLeft: "5px",
    paddingRight: "5px",
    marginTop: "5px",
  },
  paginationText: {
    fontSize: "14px",
    fontWeight: "normal",
    fontStyle: "normal",
  },
  breadcrumbs: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#111827",
  },
  breadCrumbsActive: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#687083",
  },
  placeholder: {
    "&::placeholder": {
      color: "#9AA2B1",
    },
  },
  conTextBlack: {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    marginBottom: "10px",
  },
  label: {
    color: "#364052",
    fontSize: "14px",
    fontWeight: 500,
    marginBottom: "5px",
  },
  subTitle: {
    fontSize: "18px",
    fontWeight: 700,
    color: " #111827",
  },
  btnSubmit: {
    backgroundColor: "black",
    color: "white",
    padding: "10px 28px",

    height: "40px",
    borderRadius: "6px",

    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
}));

const Detail = ({
  noKontrak,
  tglMulai,
  publisher,
  status,
  tglSelesai,
  idPublisher,
  keterangan,
  penciptaListSlice,
  approvalStatus,
  approvalStatusUpdate,
  pageSizePagination,
  pagesCount,
  page,
  handleChangePage,
  publisherShareListSlice,
  pageSizePaginationPublisherShare,
  pagesCountPublisherShare,
  pagePublisherShare,
  handleChangePagePublisherShare,
  userLogin,
}) => {
  const { state } = useLocation();
  const classes = useStyles();
  let history = useHistory();

  const getChipStatus = status => {
    if (status === "approved") {
      return (
        <Chip
          avatar={
            <FiberManualRecordIcon
              style={{ width: "10px", color: "#34774C" }}
            />
          }
          style={{
            margin: "4px",
            color: "#34774C",
            backgroundColor: "#8DE5AB",
          }}
          label={"Approved"}
        />
      );
    } else if (status === "rejected") {
      return (
        <Chip
          avatar={
            <FiberManualRecordIcon
              style={{ width: "10px", color: "#E53E3E" }}
            />
          }
          style={{
            margin: "4px",
            color: "#E53E3E",
            backgroundColor: "#FED7D7",
          }}
          label={"Rejected"}
        />
      );
    } else if (status === "waiting_for_approval") {
      return (
        <Chip
          avatar={
            <FiberManualRecordIcon
              style={{ width: "10px", color: "#F6AD55" }}
            />
          }
          style={{ color: "#F6AD55", backgroundColor: "#FEEBC8" }}
          label={"Waiting for Approval"}
        />
      );
    }
  };

  const handleApprove = () => {
    const token = localStorage.getItem("token");
    const url = `${hardBaseUrl}/publisher/contract/composer-main/approval/${state.customer.contract_id}`;

    const data = {
      approval_status: "approved",
    };
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, approve it!",
    }).then(result => {
      if (result.isConfirmed) {
        axios
          .put(url, data, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then(() => {
            Swal.fire({
              icon: "success",
              title: "Approved!",
              text: "Your contract has been approved.",
              showConfirmButton: true,
            }).then(result => {
              if (result.isConfirmed) {
                window.location.reload();
              }
            });
          })
          .catch(err => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: err.response.data.errors[0].message,
              confirmButtonColor: "#3085d6",
            });
          });
      }
    });
  };
  const handleReject = () => {
    const token = localStorage.getItem("token");
    const url = `${hardBaseUrl}/publisher/contract/composer-main/approval/${state.customer.contract_id}`;
    const data = {
      approval_status: "rejected",
    };
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, reject",
    }).then(result => {
      if (result.isConfirmed) {
        axios
          .put(url, data, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then(() => {
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "Contract has been rejected",
              confirmButtonColor: "#3085d6",
            }).then(result => {
              if (result.isConfirmed) {
                window.location.reload();
              }
            });
          })
          .catch(err => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: err.response.data.errors[0].message,
              confirmButtonColor: "#3085d6",
            });
          });
      }
    });
  };
  const handleRejectUpdate = () => {
    const token = localStorage.getItem("token");
    const url = `${hardBaseUrl}/publisher/contract/composer-main/approval/${state.customer.contract_id}`;
    const data = {
      approval_status: "rejected",
    };
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, reject",
    }).then(result => {
      if (result.isConfirmed) {
        axios
          .put(url, data, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then(() => {
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "Contract has been rejected",
              confirmButtonColor: "#3085d6",
            }).then(result => {
              if (result.isConfirmed) {
                window.location.reload();
              }
            });
          })
          .catch(err => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: err.response.data.errors[0].message,
              confirmButtonColor: "#3085d6",
            });
          });
      }
    });
  };
  const handleApproveUpdate = () => {
    const token = localStorage.getItem("token");
    const url = `${hardBaseUrl}/publisher/contract/composer-main/approval/${state.customer.contract_id}`;

    const data = {
      approval_status: "approved",
    };
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, approve it!",
    }).then(result => {
      if (result.isConfirmed) {
        axios
          .put(url, data, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then(() => {
            Swal.fire({
              icon: "success",
              title: "Approved!",
              text: "Your contract has been approved.",
              showConfirmButton: true,
            }).then(result => {
              if (result.isConfirmed) {
                history.push("/admin/kontrak-composer");
              }
            });
          })
          .catch(err => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: err.response.data.errors[0].message,
              confirmButtonColor: "#3085d6",
            });
          });
      }
    });
  };
  const roleName = userLogin?.role?.name;

  return (
    <div>
      <div>
        {roleName === "Finance Manager" ? (
          <div>
            <Grid container>
              <div>
                <ThemeProvider theme={theme}>
                  <Typography
                    component="h1"
                    variant="h3"
                    className={classes.subTitle}
                  >
                    {approvalStatusUpdate === "waiting_for_approval" ||
                    approvalStatusUpdate === "approved" ? (
                      <>Edit</>
                    ) : (
                      <>Create</>
                    )}{" "}
                    Submission
                  </Typography>
                </ThemeProvider>
                <ThemeProvider theme={theme}>
                  <p
                    style={{
                      marginTop: "5px",
                      color: "#8f8f8f",
                      fontSize: "14px",
                    }}
                  >
                    Features for contract editing validation purposes
                  </p>
                </ThemeProvider>
              </div>
            </Grid>
            <Grid container>
              <Grid item xs={12} md={8} lg={8} xl={8}>
                <Card
                  style={{
                    border: "1px solid #9AA2B1",
                    borderRadius: "6px",
                    boxShadow: "none",
                    marginBottom: "20px",
                  }}
                >
                  <CardContent>
                    <Grid container>
                      <Grid item xs={6} md={6} lg={6} xl={6}>
                        <Typography className={classes.label}>
                          Status
                        </Typography>
                        {approvalStatusUpdate !== "" ? (
                          <>{getChipStatus(approvalStatusUpdate)}</>
                        ) : (
                          <>{getChipStatus(approvalStatus) || "-"}</>
                        )}
                      </Grid>
                      <Grid item xs={6} md={6} lg={6} xl={6}>
                        {approvalStatusUpdate !== "" ? (
                          <>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <Button
                                variant="contained"
                                className={classes.btnSubmit}
                                style={{
                                  backgroundColor: "white",
                                  color: "black",
                                  border: "1px solid #9AA2B1",
                                }}
                                onClick={handleRejectUpdate}
                              >
                                <ThemeProvider theme={theme}>
                                  <Typography
                                    style={{
                                      textTransform: "none",
                                      color: "black",
                                      fontSize: "14px",
                                      fontWeight: 400,
                                      lineHeight: "20px",
                                    }}
                                  >
                                    Reject
                                  </Typography>
                                </ThemeProvider>
                              </Button>

                              <Button
                                variant="contained"
                                className={classes.btnSubmit}
                                style={{
                                  backgroundColor: "black",
                                  marginLeft: "10px",
                                }}
                                onClick={handleApproveUpdate}
                              >
                                <ThemeProvider theme={theme}>
                                  <Typography
                                    style={{
                                      textTransform: "none",
                                      color: "#FFFFFF",
                                      fontSize: "14px",
                                      fontWeight: 400,
                                      lineHeight: "20px",
                                    }}
                                  >
                                    Approve
                                  </Typography>
                                </ThemeProvider>
                              </Button>
                            </div>
                          </>
                        ) : (
                          <>
                            {approvalStatus === "rejected" ||
                            approvalStatus === "approved" ||
                            approvalStatus === "" ? (
                              <>{""}</>
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <Button
                                  variant="contained"
                                  className={classes.btnSubmit}
                                  style={{
                                    backgroundColor: "white",
                                    color: "black",
                                    border: "1px solid #9AA2B1",
                                  }}
                                  onClick={handleReject}
                                >
                                  <ThemeProvider theme={theme}>
                                    <Typography
                                      style={{
                                        textTransform: "none",
                                        color: "black",
                                        fontSize: "14px",
                                        fontWeight: 400,
                                        lineHeight: "20px",
                                      }}
                                    >
                                      Reject
                                    </Typography>
                                  </ThemeProvider>
                                </Button>

                                <Button
                                  variant="contained"
                                  className={classes.btnSubmit}
                                  style={{
                                    backgroundColor: "black",
                                    marginLeft: "10px",
                                  }}
                                  onClick={handleApprove}
                                >
                                  <ThemeProvider theme={theme}>
                                    <Typography
                                      style={{
                                        textTransform: "none",
                                        color: "#FFFFFF",
                                        fontSize: "14px",
                                        fontWeight: 400,
                                        lineHeight: "20px",
                                      }}
                                    >
                                      Approve
                                    </Typography>
                                  </ThemeProvider>
                                </Button>
                              </div>
                            )}
                          </>
                        )}
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={4} lg={4} xl={4}></Grid>
            </Grid>
          </div>
        ) : (
          <div>
            <Grid container>
              <div>
                <ThemeProvider theme={theme}>
                  <Typography
                    component="h1"
                    variant="h3"
                    className={classes.subTitle}
                  >
                    {approvalStatusUpdate === "waiting_for_approval" ||
                    approvalStatusUpdate === "approved" ? (
                      <>Edit</>
                    ) : (
                      <>Create</>
                    )}{" "}
                    Submission
                  </Typography>
                </ThemeProvider>
                <ThemeProvider theme={theme}>
                  <p
                    style={{
                      marginTop: "5px",
                      color: "#8f8f8f",
                      fontSize: "14px",
                    }}
                  >
                    Features for contract editing validation purposes
                  </p>
                </ThemeProvider>
              </div>
            </Grid>
            <Grid container>
              <Grid item xs={12} md={8} lg={8} xl={8}>
                <Card
                  style={{
                    border: "1px solid #9AA2B1",
                    borderRadius: "6px",
                    boxShadow: "none",
                    marginBottom: "20px",
                  }}
                >
                  <CardContent>
                    <Typography className={classes.label}>Status</Typography>
                    {getChipStatus(approvalStatus) || "-"}
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={4} lg={4} xl={4}></Grid>
            </Grid>
          </div>
        )}

        <Grid container>
          <div>
            <ThemeProvider theme={theme}>
              <Typography
                component="h1"
                variant="h3"
                className={classes.subTitle}
              >
                Information Contract
              </Typography>
            </ThemeProvider>
            <ThemeProvider theme={theme}>
              <p
                style={{
                  marginTop: "5px",
                  color: "#8f8f8f",
                  fontSize: "14px",
                }}
              >
                Contract information that has been made.
              </p>
            </ThemeProvider>
          </div>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={8} lg={8} xl={8}>
            <Card
              style={{
                border: "1px solid #9AA2B1",
                borderRadius: "6px",
                boxShadow: "none",
              }}
            >
              <CardContent>
                <ThemeProvider theme={theme}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6} lg={6} xl={6}>
                      <Typography className={classes.label}>
                        Contract Number
                      </Typography>
                      <Typography className={classes.conTextBlack}>
                        {noKontrak || "-"}
                      </Typography>
                      <Typography className={classes.label}>
                        Start Date
                      </Typography>
                      <Typography className={classes.conTextBlack}>
                        {tglMulai || "-"}
                      </Typography>
                      <Typography className={classes.label}>
                        Publisher
                      </Typography>
                      <Typography className={classes.conTextBlack}>
                        {publisher || "-"}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6} xl={6}>
                      <Typography className={classes.label}>Status</Typography>
                      <Typography className={classes.conTextBlack}>
                        {status ? (
                          <Chip
                            avatar={
                              <FiberManualRecordIcon
                                style={{ width: "10px", color: "#34774C" }}
                              />
                            }
                            style={{
                              margin: "4px",
                              color: "#34774C",
                              backgroundColor: "#8DE5AB",
                            }}
                            label={"Active"}
                          />
                        ) : (
                          <Chip
                            style={{
                              margin: "4px",
                              color: "#F87171",
                              backgroundColor: "#FEE2E2",
                            }}
                            label={"Inactive"}
                          />
                        )}
                      </Typography>
                      <Typography className={classes.label}>
                        End Date
                      </Typography>
                      <Typography className={classes.conTextBlack}>
                        {tglSelesai || "-"}
                      </Typography>
                      <Typography className={classes.label}>
                        Publisher ID
                      </Typography>
                      <Typography className={classes.conTextBlack}>
                        {idPublisher || "-"}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} xl={12}>
                      <Typography className={classes.label}>
                        Description
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "16px",
                          fontWeight: 400,
                          color: "#687083",
                        }}
                      >
                        {keterangan || "-"}
                      </Typography>
                    </Grid>
                  </Grid>
                </ThemeProvider>
              </CardContent>
            </Card>
            <Divider className={classes.divider} />
          </Grid>
          <Grid item xs={12} md={4} lg={4} xl={4}></Grid>
        </Grid>
      </div>

      <Grid container>
        <div>
          <ThemeProvider theme={theme}>
            <Typography
              component="h1"
              variant="h3"
              className={classes.subTitle}
            >
              Contract Information
            </Typography>
          </ThemeProvider>
          <ThemeProvider theme={theme}>
            <p
              style={{
                marginTop: "5px",
                color: "#8f8f8f",
                fontSize: "14px",
              }}
            >
              Feature to set publisher share percentage
            </p>
          </ThemeProvider>
        </div>
      </Grid>
      <Grid container>
        <Grid item xs={8} md={8} sm={8} lg={8} xl={8}>
          <Grid container>
            <Grid item xs={12} md={12} sm={12} lg={12} xl={12}>
              <Card
                style={{
                  border: "1px solid #9AA2B1",
                  borderRadius: "6px",
                  boxShadow: "none",
                }}
              >
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <ThemeProvider theme={theme}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={classes.headText}
                          >
                            Composer/Author Name
                          </Typography>
                        </TableCell>
                      </ThemeProvider>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {penciptaListSlice !== null ? (
                      penciptaListSlice?.length > 0 ? (
                        penciptaListSlice.map(customer => (
                          <TableRow hover key={customer.composer_id}>
                            <TableCell>
                              <Typography
                                variant="subtitle2"
                                className={classes.bodyTextBlack}
                              >
                                {customer.sure_name}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={4}>
                            <center>
                              <ThemeProvider theme={theme}>
                                <Typography
                                  variant="h6"
                                  style={{
                                    fontWeight: 400,
                                    color: "#687083",
                                    fontSize: "14px",
                                  }}
                                >
                                  no data
                                </Typography>
                              </ThemeProvider>
                            </center>
                          </TableCell>
                        </TableRow>
                      )
                    ) : (
                      <TableRow>
                        <TableCell colSpan={3}>
                          <center>
                            <ThemeProvider theme={theme}>
                              <Typography
                                variant="h6"
                                style={{
                                  fontWeight: 400,
                                  color: "#687083",
                                  fontSize: "14px",
                                }}
                              >
                                no data
                              </Typography>
                            </ThemeProvider>
                          </center>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
                {penciptaListSlice?.length > 0 ? (
                  <CardActions className={classes.actions}>
                    <Grid
                      alignContent="space-between"
                      container
                      justifyContent="space-between"
                      spacing={3}
                    >
                      <Grid item>
                        <div style={{ display: "flex" }}>
                          <div style={{ marginTop: "7px" }}>
                            <ThemeProvider theme={theme}>
                              <Typography className={classes.paginationText}>
                                Item per page:
                              </Typography>
                            </ThemeProvider>
                          </div>{" "}
                          <div style={{ marginLeft: "10px" }}>
                            {pageSizePagination()}
                          </div>
                        </div>
                      </Grid>

                      <Grid item>
                        <Pagination
                          count={pagesCount}
                          shape="rounded"
                          className={classes.pagination}
                          page={page}
                          onChange={handleChangePage}
                        />
                      </Grid>
                    </Grid>
                  </CardActions>
                ) : null}
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        style={{
          marginTop: "20px",
        }}
      >
        <div>
          <ThemeProvider theme={theme}>
            <Typography
              component="h1"
              variant="h3"
              className={classes.subTitle}
            >
              Publisher Share Percentage
            </Typography>
          </ThemeProvider>
          <ThemeProvider theme={theme}>
            <p
              style={{
                marginTop: "5px",
                color: "#8f8f8f",
                fontSize: "14px",
              }}
            >
              Feature to set publisher share percentage
            </p>
          </ThemeProvider>
        </div>
      </Grid>
      <Grid container>
        <Grid item xs={8} md={8} sm={8} lg={8} xl={8}>
          <Grid container>
            <Grid item xs={12} md={12} sm={12} lg={12} xl={12}>
              <Card
                style={{
                  border: "1px solid #9AA2B1",
                  borderRadius: "6px",
                  boxShadow: "none",
                }}
              >
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <ThemeProvider theme={theme}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={classes.headText}
                          >
                            Composer/Author Name
                          </Typography>
                        </TableCell>
                        <TableCell></TableCell>
                      </ThemeProvider>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {publisherShareListSlice !== null ? (
                      publisherShareListSlice?.length > 0 ? (
                        publisherShareListSlice.map(customer => (
                          <TableRow hover key={customer.composer_id}>
                            <TableCell>
                              <Typography
                                variant="subtitle2"
                                className={classes.bodyTextBlack}
                              >
                                {customer.sure_name}
                              </Typography>
                            </TableCell>

                            <TableCell></TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={4}>
                            <center>
                              <ThemeProvider theme={theme}>
                                <Typography
                                  variant="h6"
                                  style={{
                                    fontWeight: 400,
                                    color: "#687083",
                                    fontSize: "14px",
                                  }}
                                >
                                  no data
                                </Typography>
                              </ThemeProvider>
                            </center>
                          </TableCell>
                        </TableRow>
                      )
                    ) : (
                      <TableRow>
                        <TableCell colSpan={3}>
                          <center>
                            <ThemeProvider theme={theme}>
                              <Typography
                                variant="h6"
                                style={{
                                  fontWeight: 400,
                                  color: "#687083",
                                  fontSize: "14px",
                                }}
                              >
                                no data
                              </Typography>
                            </ThemeProvider>
                          </center>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
                {publisherShareListSlice?.length > 0 ? (
                  <CardActions className={classes.actions}>
                    <Grid
                      alignContent="space-between"
                      container
                      justifyContent="space-between"
                      spacing={3}
                    >
                      <Grid item>
                        <div style={{ display: "flex" }}>
                          <div style={{ marginTop: "7px" }}>
                            <ThemeProvider theme={theme}>
                              <Typography className={classes.paginationText}>
                                Item per page:
                              </Typography>
                            </ThemeProvider>
                          </div>{" "}
                          <div style={{ marginLeft: "10px" }}>
                            {pageSizePaginationPublisherShare()}
                          </div>
                        </div>
                      </Grid>

                      <Grid item>
                        <Pagination
                          count={pagesCountPublisherShare}
                          shape="rounded"
                          className={classes.pagination}
                          page={pagePublisherShare}
                          onChange={handleChangePagePublisherShare}
                        />
                      </Grid>
                    </Grid>
                  </CardActions>
                ) : null}
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {}
    </div>
  );
};

export default Detail;
