import { useMediaQuery, useTheme } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const interFont = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});
export const CarouselCard = props => {
  const { item, selected, index } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <div
      style={{
        height: "640px",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "300px",
        }}
      >
        <div
          style={{
            position: "relative",
            zIndex: selected ? 5 : -1,
          }}
        >
          <img
            alt={`Image ${index + 1}`}
            src={item?.image}
            style={{
              height: selected ? "282px" : "228px",
              width: selected ? "474px" : "383px",
              display: "flex",
              justifyContent: "center",
            }}
          />
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              height: selected ? "282px" : "228px",
              width: selected ? "474px" : "383px",
              background: selected
                ? "linear-gradient(67.45deg, #652D8D 25.08%, #E80088 112.47%)"
                : "#000000",
              pointerEvents: "none",
              opacity: selected ? "50%" : "30%",
            }}
          />
        </div>
      </div>
      {selected && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            marginTop: isMobile ? "80px" : "120px",
          }}
        >
          <ThemeProvider theme={interFont}>
            <Typography
              style={{
                fontWeight: 400,
                fontSize: isMobile ? "28px" : "36px",
                lineHeight: isMobile ? "30px" : "44px",
                color: "#642C8C",
              }}
            >
              {item?.title}{" "}
              <span style={{ color: "#E80088" }}>{item?.titleHightlight}</span>
            </Typography>
            <Typography
              style={{
                fontWeight: 400,
                fontSize: isMobile ? "12px" : "18px",
                lineHeight: isMobile ? "20px" : "28px",
                color: "#642C8C",
                textAlign: "center",
                width: isMobile ? "300px" : "750px",
                marginTop: "14px",
              }}
            >
              {item?.content}
            </Typography>
          </ThemeProvider>
        </div>
      )}
    </div>
  );
};
